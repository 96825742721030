import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {DashboardService} from '../../../dashboard.service';
import { NotificationService } from "src/app/core/services/notification.service";

@Component({
    selector: 'app-data-upload',
    templateUrl: './data-upload.component.html',
    styleUrls: ['./data-upload.component.scss']
})
export class DataUploadComponent implements OnInit {
    fileUpload;

    constructor(
        private fb: FormBuilder,
        private service: DashboardService,
        private toastrService: NotificationService
        ) {

        this.fileUpload = this.fb.group({
            fileObj: ['']
        });
    }

    onFileChange(event) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.fileUpload.get('fileObj').setValue(file);
        }
    }

    onSubmit() {

        const file = this.fileUpload.get('fileObj').value;
        const fileName = file && file.name;
        this.service.uploadCSVFile(file).subscribe(resp => {
            if (resp && resp.data) {
                // console.log(resp);
            }
            this.toastrService.success(resp.msg)
        }, error=>{
            const err = error.error && error.error.msg;
            this.toastrService.error(err || 'Error while uploading data.');
        });
    }

    ngOnInit() {
    }

}
