import { Component, NgZone, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, NgForm } from "@angular/forms";
import { DashboardService } from "../../dashboard.service";
import { NotificationService } from "src/app/core/services/notification.service";
import * as moment from "moment";
import { merge, Observable, Subject } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MatTableDataSource } from "@angular/material/table";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { MatSort } from "@angular/material/sort";
// import {DropDownTreeComponent} from '@syncfusion/ej2-angular-dropdowns';
import { saveAs } from 'file-saver-es';
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { takeUntil } from "rxjs/operators";
import { Router } from "@angular/router";
import { AuthService } from "src/app/core/services/auth.service";

@Component({
  selector: "app-my-leave",
  templateUrl: "./my-leave.component.html",
  styleUrls: ["./my-leave.component.scss"],
})
export class MyLeaveComponent implements OnInit {
  employeeListfilterd: Array<any> = [];
  employeeListfilterd2: Array<any> = [];
  employeeListfilterd3: Array<any> = [];
  filteredNoimnees: Array<any> = [];
  filteredNoimnees2: Array<any> = [];
  filteredNoimnees3: Array<any> = [];

  constructor(
    private readonly authService: AuthService,
    private router: Router,
    private ngZone: NgZone,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private service: DashboardService,
    private toastr: NotificationService,
    private modalService: NgbModal
  ) {
    this.createCommentForm();
  }
  public commentForm: FormGroup;
  public isLodaingCo: boolean = false;
  public employee: FormControl = new FormControl(null);
  public leaveAction: string;
  public employeeList: Array<any> = [];
  public employeeDetailList: Array<any>;
  public leaveTypeList: Array<any> = [];
  public locationList: Array<any> = [];
  public structureList: Array<any> = [];
  public empId: number;
  public startDate;
  public endDate;
  public leaveList: Array<any> = [];
  public isLoading: boolean;
  public err: string;
  public modalRef: any;
  public today;
  public todayDate;
  public leaveComment: any;
  public leaveDetailComment: any;
  public leaveData: any;
  public leaveDetailData: any;
  public leaveListForm: FormGroup;
  public status = "";
  public employeeSelected: any = "";
  public currentLeaveType: any = "All";
  public currentLocationList: any = "All";
  public currentStructureList: any = "All";
  public selectStatus = "Select action";
  public costCenterList: Array<any> = [];
  public costCenterId: string = "";
  public includePastEmployees: boolean = false;
  public dataLoading: boolean = true;
  public selected = "";
  public isExpanded: boolean = true;
  public filteredEmployees: Array<any> = [];
  public filteredCostCenters: Array<any> = [];
  public employeeName: string = "";
  public employeeId: string = "";
  public leaveId: string = "";
  public dialogRef: any;
  public employeeImage: string = "";
  public commentList: Array<any> = [];
  public actionsList: Array<any> = [];
  protected _onDestroy = new Subject<void>();
  public userFilterCtrl: FormControl = new FormControl();
  protected _onDestroy1 = new Subject<void>();
  public userFilterCtrl1: FormControl = new FormControl();
  protected _onDestroy2 = new Subject<void>();
  public userFilterCtrl2: FormControl = new FormControl();
  protected _onDestroy3 = new Subject<void>();
  public userFilterCtrl3: FormControl = new FormControl();
  protected _onDestroy4 = new Subject<void>();
  public userFilterCtrl4: FormControl = new FormControl();
  public nomineesList: Array<any> = [];
  public noNominees: boolean = false;
  public isLoadingNom: boolean = false;
  public nominatedempid1 :any;
  public comment1 = "";
  public nominatedempid2 :any
  public comment2 = "";
  public nominatedempid3 :any
  public comment3 = "";
  public viewNominees: boolean = false;
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  selectedStatus: any[] = [];
  public statusList = [
    { value: "All", label: "All", checked: true },
    { value: "Cancelled", label: "Cancelled", checked: true },
    // {value: 'Approve', label: 'Approve', checked: false},
    { value: "Pending", label: "Pending Approval", checked: true },
    { value: "Scheduled", label: "Scheduled", checked: true },
    { value: "Taken", label: "Taken", checked: true },
    { value: "Rejected", label: "Rejected", checked: true },
    // {value: 'Holiday', label: 'Holiday', checked: false},
    // {value: 'Weekend', label: 'Weekend', checked: false}
  ];
  @ViewChild("form", { static: false }) ngForm: NgForm;
  @ViewChild("empDetail", { static: false }) empDetail: any;
  @ViewChild("empComment", { static: false }) empComment: any;
  @ViewChild("empDetailComment", { static: false }) empDetailComment: any;
  @ViewChild("checkboxs", { static: false }) checkboxes: any;
  @ViewChild("configure", { static: false }) configureModal;
  @ViewChild("addComments", { static: false }) addComments;
  @ViewChild("addNominees", { static: false }) addNominees;
  filteredOptions: Observable<string[]>;
  displayedLeaveListColumns = [
    "employeeName",
    "date",
    "leaveType",
    "appliedOn",
    "netLeaveBalance",
    "numberOfDays",
    "status",
    "actionTaken",
    "icons",
    "icons2",
  ];

  public validationError = {
    required: "Required",
    email: "Please provide a valid email",
    password: "Please provide a password of atleast 8 digits",
    postalcode: "Should be of 6 digits",
    alphabets: "Should contain only alphabets",
    contact: "Please provide a contact of 10 digit",
  };
  // regarding the configure work
  public configureForm: FormGroup = new FormGroup({
    EmployeeID: new FormControl(true),
    Employee: new FormControl(true),
    rowDate: new FormControl(true),
    rowLeaveType: new FormControl(true),
    rowappliedOn: new FormControl(true),
    netBalance: new FormControl(true),
    NoOfDays: new FormControl(true),
    rowStatus: new FormControl(true),
    Actions: new FormControl(true),
    buttons: new FormControl(true),
  });
  public EmployeeID = this.configureForm.get("EmployeeID");
  public Employee = this.configureForm.get("Employee");
  public rowDate = this.configureForm.get("rowDate");
  public rowLeaveType = this.configureForm.get("rowLeaveType");
  public rowappliedOn = this.configureForm.get("rowappliedOn");
  public netBalance = this.configureForm.get("rowappliedOn");
  public NoOfDays = this.configureForm.get("NoOfDays");
  public rowStatus = this.configureForm.get("rowStatus");
  public Actions = this.configureForm.get("Actions");
  public buttons = this.configureForm.get("buttons");
  public columnDefinitions = [
    { def: "EmployeeID", label: "Employee ID", hide: this.EmployeeID.value },
    { def: "Employee", label: "Employee", hide: this.Employee.value },
    { def: "rowDate", label: "Date", hide: this.rowDate.value },
    { def: "rowLeaveType", label: "Leave Type", hide: this.rowLeaveType.value },
    { def: "rowappliedOn", label: "Applied On", hide: this.rowappliedOn.value },
    { def: "netBalance", label: "Net Balance (Days)", hide: this.netBalance.value },
    { def: "NoOfDays", label: "No Of Days", hide: this.NoOfDays.value },
    { def: "rowStatus", label: "Status", hide: this.rowStatus.value },
    { def: "Actions", label: "Actions", hide: this.Actions.value },
    { def: "buttons", label: "Buttons", hide: this.buttons.value },
  ];
  // for the configure work
  public columns: string[] = [];

  pageSize: number = 10;
  pageIndex: number = 0;
  totalElements: number = 0;

  public getMinDate = () => {
    const date = new Date();
    const d = date.getDate();
    const m = date.getMonth() + 1;
    const y = date.getFullYear();
    let mStr: string;

    if (m < 10) {
      mStr = "0" + m;
    } else {
      mStr = m.toString();
    }

    return y + "-" + mStr + "-" + d;
  };

  public permissions: any;
  public read = false;
  public write = false;
  public update = false;
  public delete = false;

  checkPermission(module, tabs): void {
    this.permissions = JSON.parse(this.authService.getPermissions());

    this.read = false;
    this.write = false;
    this.delete = false;
    this.update = false;
    if (this.permissions != undefined && this.permissions != null) {
      for (let p of this.permissions) {
        if (p.id === module) {
          if (p.modules != undefined) {
            for (let tab of p.modules) {
              if (tab.id === tabs) {
                for (let move of tab.permits) {
                  if (move.id === "u") {
                    this.update = true;
                  } else if (move.id === "r") {
                    this.read = true;
                  } else if (move.id === "c") {
                    this.write = true;
                  } else if (move.id === "d") {
                    this.delete = true;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  ngOnInit() {
    // for the configure
    this.actionsList = [];
    this.checkPermission("leave", "le_ml");
    const o1: Observable<boolean> = this.EmployeeID.valueChanges;
    const o2: Observable<boolean> = this.Employee.valueChanges;
    const o3: Observable<boolean> = this.rowDate.valueChanges;
    const o4: Observable<boolean> = this.rowLeaveType.valueChanges;
    const o5: Observable<boolean> = this.rowappliedOn.valueChanges;
    const o6: Observable<boolean> = this.netBalance.valueChanges;
    const o7: Observable<boolean> = this.NoOfDays.valueChanges;
    const o8: Observable<boolean> = this.rowStatus.valueChanges;
    const o9: Observable<boolean> = this.Actions.valueChanges;
    const o10: Observable<boolean> = this.buttons.valueChanges;

    merge(o1, o2, o3, o4, o5, o6, o7, o8, o9, o10).subscribe((v) => {
      this.columnDefinitions[0].hide = this.EmployeeID.value;
      this.columnDefinitions[1].hide = this.Employee.value;
      this.columnDefinitions[2].hide = this.rowDate.value;
      this.columnDefinitions[3].hide = this.rowLeaveType.value;
      this.columnDefinitions[4].hide = this.rowappliedOn.value;
      this.columnDefinitions[5].hide = this.netBalance.value;
      this.columnDefinitions[6].hide = this.NoOfDays.value;
      this.columnDefinitions[7].hide = this.rowStatus.value;
      this.columnDefinitions[8].hide = this.Actions.value;
      this.columnDefinitions[9].hide = this.buttons.value;
      this.getDisplayedColumns();
    });
    this.getDisplayedColumns();
    // before configure

    // for setting the value for the starting date
    this.startDate = new Date();
    this.startDate.setDate(1);
    this.startDate.setMonth(0);
    this.startDate = this.startDate.toJSON().split("T")[0];
    // for setting the value for the end date
    this.endDate = new Date();
    this.endDate.setDate(30);
    this.endDate.setMonth(11);
    this.endDate = this.endDate.toJSON().split("T")[0];

    this.leaveListForm = new FormGroup({
      employee: this.employee,
    });
    this.todayDate = this.getMinDate();
    this.today = new Date();
    this.today.setDate(this.today.getDate());
    this.today = this.today.toJSON().split("T")[0];
    // here we are getting the structure for the subunit

    // here we are getting the values for the location
    // this.service.getLocationListAll().subscribe(
    //   (resp) => {
    //     if (resp && resp.data) {
    //       this.locationList = resp.data;
    //     }
    //   },
    //   (error) => {
    //     const err = error.error && error.error.msg;
    //     this.toastr.error(err || "Error in getting location");
    //   }
    // );
    // here we are getting the values for the leave type

    // here we are getting the values for the employees
    // this.service.getEmployeeDetailList().subscribe(
    //   (resp) => {
    //     if (resp && resp.data) {
    //       this.employeeList.push({ label: "--Select--", value: "" });
    //       resp.data.map((e) => {
    //         this.employeeList.push({ label: e.id + " " + e.name, value: e.id });
    //       });
    //       this.filteredEmployees = this.employeeList.slice();
    //       this.userFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
    //         this.filterUsers();
    //       });
    //     }
    //   },
    //   (error) => {
    //     const err = error.error && error.error.msg;
    //   }
    // );

    // this.service.getCostCenterDropDwon().subscribe(
    //   (resp) => {
    //     if (resp && resp.data) {
    //       //   resp.data.map(e => {
    //       //     this.employeeList.push({ label: e.name, value: e.id });
    //       //   });
    //       this.costCenterList.push({ label: "--Select--", value: "" });
    //       resp.data.map((e) => {
    //         this.costCenterList.push({ label: e.name, value: e.id });
    //       });

    //       this.filteredCostCenters = this.costCenterList.slice();
    //       this.userFilterCtrl1.valueChanges.pipe(takeUntil(this._onDestroy1)).subscribe(() => {
    //         this.filterCostCenters();
    //       });
    //     }
    //   },
    //   (error) => {
    //     const err = error.error && error.error.msg;
    //   }
    // );

    // this.service.getStructureListByIdAll().subscribe((resp) => {
    //   if (resp && resp.data) {
    //     this.structureList = resp.data;
    //     // resp.data.map(res => {
    //     //     this.structureList.push({label: res.name, value: res.id});
    //     // });
    //   }
    // });

    // this.service.getLeaveTypeListAll().subscribe(
    //   (resp) => {
    //     if (resp && resp.data) {
    //       this.leaveTypeList = resp.data;
    //       // resp.data.map(leave => {
    //       //     this.leaveTypeList.push({label: leave.leaveType, value: leave.id});
    //       // });
    //     }
    //   },
    //   (error) => {
    //     const err = error.error && error.error.msg;
    //   }
    // );

    this.search();
  }

//   protected filterUsers() {
//     if (!this.employeeList) {
//       return;
//     }
//     // get the search keyword
//     let search = this.userFilterCtrl.value;
//     if (!search) {
//       this.filteredEmployees = this.employeeList.slice();
//       return;
//     } else {
//       search = search.toLowerCase();
//     }
//     // filter the search
//     this.filteredEmployees = this.employeeList.filter((emp) => emp.label.toLowerCase().indexOf(search) > -1);
//   }

  public openNominnesDialog(leaveId, employeeId, employeeLeaveNominateds) {
    this.employeeId = "";
    this.leaveId = "";
    this.nominatedempid1 = {'id':'', 'name':''};
    this.nominatedempid2 = {'id':'', 'name':''};
    this.nominatedempid3 = {'id':'', 'name':''};
    this.comment1 = "";
    this.comment2 = "";
    this.comment3 = "";
    this.isLoadingNom = true;
    this.employeeId = employeeId;
    this.leaveId = leaveId;
    // this.filterEmployeeList(employeeId);
    this.dialogRef = this.dialog.open(this.addNominees, {
      width: "660px",
      autoFocus: false,
      maxHeight: "90vh",
    });
    this.service.getNomineesForLeave(leaveId, employeeId).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.nomineesList = resp.data;

          if (this.nomineesList.length === 0) {
            this.viewNominees = false;
            this.noNominees = false;
          } else {
            this.viewNominees = true;
            this.noNominees = true;
          }

          if (this.nomineesList.length === 1) {
            this.nominatedempid1 =  {'id':this.nomineesList[0].nominatedempid, 'name':this.nomineesList[0].nominatedempname};
            this.comment1 = this.nomineesList[0].comment;
          }

          if (this.nomineesList.length === 2) {
            this.nominatedempid1 =  {'id':this.nomineesList[0].nominatedempid, 'name':this.nomineesList[0].nominatedempname};
            this.comment1 = this.nomineesList[0].comment;
            this.nominatedempid2 =  {'id':this.nomineesList[1].nominatedempid, 'name':this.nomineesList[1].nominatedempname};
            this.comment2 = this.nomineesList[1].comment;
          }

          if (this.nomineesList.length === 3) {
            this.nominatedempid1 =  {'id':this.nomineesList[0].nominatedempid, 'name':this.nomineesList[0].nominatedempname};
            this.comment1 = this.nomineesList[0].comment;
            this.nominatedempid2 =  {'id':this.nomineesList[1].nominatedempid, 'name':this.nomineesList[1].nominatedempname};
            this.comment2 = this.nomineesList[1].comment;
            this.nominatedempid3 =  {'id':this.nomineesList[2].nominatedempid, 'name':this.nomineesList[2].nominatedempname};
            this.comment3 = this.nomineesList[2].comment;
          }
          this.isLoadingNom = false;
        }
      },
      (error) => {
        this.isLoadingNom = false;
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error in getting nominees !");
      }
    );
  }

  saveNominees(): void {
    var nominees = [];
    this.isLoadingNom = true;
    if (this.nominatedempid1.id === "" && this.nominatedempid2.id === "" && this.nominatedempid3.id === "") {
      this.isLoadingNom = false;
      this.service.saveNomineesForLeave(this.leaveId, this.employeeId, nominees).subscribe(
        (resp) => {
          if (resp && resp.data) {
            this.search();
            this.toastr.success("Updated successfully !");
            this.isLoadingNom = false;
            this.close();
          }
        },
        (error) => {
          const err = error.error && error.error.msg;
          this.toastr.error(err || "Error in updating !");
          this.isLoadingNom = false;
        }
      );

      return;
    }
    if (this.nominatedempid1.id != "") {
      var obj = {
        nominatedempid: this.nominatedempid1.id,
        comment: this.comment1,
      };

      nominees.push(obj);
    }
    if (this.nominatedempid2.id != "") {
      var obj = {
        nominatedempid: this.nominatedempid2.id,
        comment: this.comment2,
      };

      nominees.push(obj);
    }
    if (this.nominatedempid3.id != "") {
      var obj = {
        nominatedempid: this.nominatedempid3.id,
        comment: this.comment3,
      };

      nominees.push(obj);
    }

    this.service.saveNomineesForLeave(this.leaveId, this.employeeId, nominees).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.search();
          this.toastr.success("Updated successfully !");
          this.isLoadingNom = false;
          this.close();
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error in updating !");
        this.isLoadingNom = false;
      }
    );
  }

  public openLeaveDays(employeeId, leaveId): void {
    this.ngZone.run(() => {
      void this.router.navigate(["/leave/my-leave/" + employeeId + "/" + leaveId], { queryParams: { action: "my" } });
    });
  }

  viewNomineesEnable(): void {
    if (this.viewNominees) {
      this.viewNominees = false;
    } else {
      this.viewNominees = true;
    }
  }

  
  public addActionsToList(leaveId, employeeId, event) {
    var obj = {
      action: event,
      empId: employeeId,
      leaveId: leaveId,
    };
    this.actionsList.push(obj);
  }

  public saveBulkActions() {
    let temp = [];
    for (let ac of this.actionsList) {
      if (ac.action === "" || "") {
        ac.action = null;
      }
      temp.push(ac);
    }

    this.service.saveBulkActions(temp).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.toastr.success("Updated successfully !");
          this.search();
          this.actionsList = [];
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error in updating !");
      }
    );
  }
  public addComment(leaveId, employeeId, employeeName, imgPath): void {
    this.commentForm.reset();
    this.employeeImage = "";
    this.employeeId = "";
    this.leaveId = "";
    this.commentList = [];
    if (imgPath != undefined && imgPath != null && imgPath != "") {
      this.employeeImage = imgPath;
    }
    this.isLodaingCo = true;
    this.dialogRef = this.dialog.open(this.addComments, {
      width: "660px",
      autoFocus: false,
      maxHeight: "55vh",
    });
    this.employeeName = employeeName;
    this.employeeId = employeeId;
    this.leaveId = leaveId;
    this.service.getCommentsForLeave(this.leaveId, this.employeeId).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.commentList = resp.data;
          this.isLodaingCo = false;
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.isLodaingCo = false;
        this.toastr.error(err || "Error in getting comments");
      }
    );
  }

  public submitComment() {
    this.isLodaingCo = true;
    this.service.assignCommentToLeave(this.leaveId, this.employeeId, this.commentForm.value).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.toastr.success("Comment saved successfully");
          this.isLodaingCo = false;
          this.close();
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.isLodaingCo = false;
        this.toastr.error(err || "Error in saving comment");
      }
    );
  }

  public close(): void {
    this.dialogRef.close();
    // this.modalRef.close();
  }

  public createCommentForm(): void {
    this.commentForm = this.fb.group({
      commnet: "",
    });
  }
  public search(): void {
    this.isLoading = true;
    this.dataLoading = true;
    this.err = undefined;
    var statusList = [];
    for (let status of this.statusList) {
      if (status.checked) {
        statusList.push(status.value);
      }
    }

    var obj = {
      dateFrom: moment(new Date(this.startDate)).format("YYYY-MM-DD"),
      dateTo: moment(new Date(this.endDate)).format("YYYY-MM-DD"),
      employeeId: null,
      subUnitId: null,
      locatioId: null,
      leaveTypeId: null,
      costCenterId: null,
      includePastEmployees: false,
      leaveStatus: statusList,
    };

    this.service.getMyLeaveList(obj, this.pageIndex, this.pageSize).subscribe(
      (resp) => {
        if (resp && resp.data) {
          if (resp.data.totalElements == 0) {
            this.toastr.error("No records found!");
            this.leaveList = [];
            this.dataSource.data = this.leaveList;
            this.totalElements = 0;
          }else{
            this.leaveList = resp.data.content;
            this.totalElements = resp.data.totalElements ;
            this.dataSource.data = this.leaveList;
          }
          this.isLoading = false;
          this.dataLoading = false;
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.isLoading = false;
        this.err = "Error in getting Leaves";
        // this.toastr.error(err || 'Error in getting employee');
        this.toastr.error("Error in getting Leaves");
      }
    );
  }

  public getLeaveDetail(id): void {
    this.service.getEmployeeLeaveDetail(id).subscribe((resp) => {
      if (resp && resp.data) {
        this.employeeDetailList = resp.data;
        this.employeeDetailList.map((l) => {
          l.action = "";
          const eventStartTime = new Date(l.startdate);
          const eventEndTime = new Date(l.enddate);
          if (l.durationsTypeTime) {
            if (l.durationsTypeTime.fromtime && l.durationsTypeTime.endtime) {
              if (l.durationsTypeTime.endtime !== "None" && l.durationsTypeTime.fromtime !== "None") {
                l.duration = "";
              } else {
                const s = moment(l.durationsTypeTime.fromtime, "HH:mm:ss a");
                const e = moment(l.durationsTypeTime.endtime, "HH:mm:ss a");
                const duration1 = moment.duration(e.diff(s));
                const hours = parseInt(duration1.asHours().toString(), 10);
                const minutes = parseInt(duration1.asMinutes().toString(), 10) % 60;
                l.duration = `${hours} : ${minutes}`;
              }
            }
          }
          this.modalRef = this.modalService.open(this.empDetail, { size: "lg" });
        });
      }
    });
  }

  public setAny(): void {
    const data = [];
    this.leaveList.map((u) => {
      const obj = {};
      obj["employeeId"] = u.employeeid;
      obj["employeeLeaveId"] = u.id;
      obj["employeeLeaveListId"] = "";
      obj["leaveStatusType"] = u.action;
      data.push(obj);
    });
    this.service.approveLeave(data).subscribe(
      (resp) => {
        this.toastr.success("Changes applied successfully...");
      },
      (error) => {
        this.toastr.error("Changes not applied..");
      }
    );
  }

  public setAnyDetail(): void {
    const data = [];
    this.employeeDetailList.map((u) => {
      const obj = {};
      obj["employeeId"] = u.employeeid;
      obj["employeeLeaveId"] = "";
      obj["employeeLeaveListId"] = u.id;
      obj["leaveStatusType"] = u.action;
      data.push(obj);
    });
    this.service.approveLeave(data).subscribe((resp) => {
      console.log(resp);
      this.modalRef.close();
    });
  }

  public getLeaveComment(user): void {
    this.leaveData = user;
    this.modalRef = this.modalService.open(this.empComment, { size: "lg" });
  }

  public getLeaveDetailComment(user): void {
    this.leaveDetailData = user;
    this.modalRef = this.modalService.open(this.empDetailComment, { size: "lg" });
  }

  public saveLeaveComment(): void {
    const data = {
      commnet: this.leaveComment,
      employeeleaveid: this.leaveData.id,
    };
    this.service.saveLeaveComment(data).subscribe(
      (resp) => {
        this.modalRef.close();
        this.leaveComment = "";
      },
      (error) => {
        const _error = error.error && error.error.msg;
        this.toastr.error(_error.msg || "Error in saving comments");
      }
    );
  }

  public saveLeaveDetailComment(): void {
    const data = {
      commnet: this.leaveDetailComment,
      employeeleaveid: this.leaveDetailData.id,
    };
    this.service.saveLeaveDetailComment(data).subscribe(
      (resp) => {
        this.modalRef.close();
        this.leaveComment = "";
      },
      (error) => {
        const _error = error.error && error.error.msg;
        this.toastr.error(_error.msg || "Error in saving leave detail comments");
      }
    );
  }
  public OnStatusChanged(event, value) {
    if (event.checked) {
      if (value == "All") {
        this.statusList.forEach((element) => {
          element.checked = true;
        });
      } else {
        this.statusList.forEach((element) => {
          if (value == element.value) {
            element.checked = true;
          }
        });
      }
    } else {
      if (value == "All") {
        this.statusList.forEach((element) => {
          element.checked = false;
        });
      } else {
        this.statusList.forEach((element) => {
          if (value == element.value) {
            element.checked = false;
          }
        });
      }
    }
    this.selectedStatus = [];
    this.statusList.forEach((element) => {
      if (element.checked) {
        this.selectedStatus.push(element.value);
      }
    });

    return this.selectedStatus;
  }
  saveAsPdf() {
    this.dataSource = new MatTableDataSource<any>(this.leaveList);
    if (this.dataSource.filteredData.length <= 0) {
      this.toastr.error("No Data to export");
      return;
    }
    const body = [];
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      body.push([
        moment(this.dataSource.filteredData[i].startdate).format("YYYY-MM-DD") +
          "-" +
          moment(this.dataSource.filteredData[i].enddate).format("YYYY-MM-DD"),
        moment(this.dataSource.filteredData[i].appliedOn).format("YYYY-MM-DD"),
        this.dataSource.filteredData[i].employeeId,
        this.dataSource.filteredData[i].employeeName,
        this.dataSource.filteredData[i].leavetype.leaveType,
        this.dataSource.filteredData[i].noofdays,
        this.dataSource.filteredData[i].status,
        this.dataSource.filteredData[i].leavecomments,
      ]);
    }
    const doc = new jsPDF();
    autoTable(doc, {
      head: [
        [
          "Date",
          "AppliedOn",
          "Employee Id",
          "Employee Name",
          "Leave Type",
          "Net Leave Balance (Days)",
          "Status",
          "comments",
        ],
      ],
      body: body,
    });
    const exportDate = new Date();
    const exportFileName = `User-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}}`;
    doc.save(exportFileName);
  }

  exportToCsv() {
    const csv = this.leaveList.map((row) => {
      return [
        row.employeeId + "  " + row.employeeName,
        moment(row.startdate).format("YYYY-MM-DD") + " to " + moment(row.enddate).format("YYYY-MM-DD"),
        row.leaveType,
        moment(row.appliedOn).format("YYYY-MM-DD"),
        row.netLeaveBalance,
        row.numberOfDays,
        row.status.replaceAll(",", "-"),
      ].join(",");
    });
    csv.unshift(this.displayedLeaveListColumns.join(",") + "\n");
    const exportDate = new Date();
    // tslint:disable-next-line:max-line-length
    const exportFileName = `Csv-export-${exportDate.getFullYear()}-${
      exportDate.getMonth() + 1
    }-${exportDate.getDate()}.csv`;
    saveAs(new Blob(csv, { type: "text/csv;charset=utf-8" }), exportFileName);
  }

  onReset() {
    // for setting the value for the starting date
    this.startDate = new Date();
    this.startDate.setDate(1);
    this.startDate.setMonth(0);
    this.startDate = this.startDate.toJSON().split("T")[0];
    // for setting the value for the end date
    this.endDate = new Date();
    this.endDate.setDate(30);
    this.endDate.setMonth(11);
    this.endDate = this.endDate.toJSON().split("T")[0];
    // to set the other values
    this.employeeSelected = "";
    this.currentLeaveType = "";
    this.currentLocationList = "";
    this.currentStructureList = "";
    this.costCenterId = "";
    this.statusList = [
      { value: "All", label: "All", checked: true },
      { value: "Cancelled", label: "Cancelled", checked: true },
      // {value: 'Approve', label: 'Approve', checked: false},
      { value: "Pending", label: "Pending Approval", checked: true },
      { value: "Scheduled", label: "Scheduled", checked: true },
      { value: "Taken", label: "Taken", checked: true },
      { value: "Rejected", label: "Rejected", checked: true },
      // {value: 'Holiday', label: 'Holiday', checked: false},
      // {value: 'Weekend', label: 'Weekend', checked: false}
    ];
    this.search();
  }
  onConfigureClicked() {
    this.modalService.open(this.configureModal);
  }
  public getDisplayedColumns() {
    this.columns = this.columnDefinitions.filter((cd) => cd.hide).map((cd) => cd.def);
  }

  public updateNominatedempid1(event){
    this.nominatedempid1.id = event?event.id:'';
  }

  public updateNominatedempid2(event){
    this.nominatedempid2.id = event?event.id:'';
  }

  public updateNominatedempid3(event){
    this.nominatedempid3.id = event?event.id:'';
  }

  handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.search();
  }
}
