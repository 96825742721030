import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from '../../../dashboard.service';
import { AuthService } from '../../../../core/services/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotificationService } from "src/app/core/services/notification.service";
import { Router, ActivatedRoute } from '@angular/router';
import { LanguageConfig } from 'src/app/Language-config';
@Component({
  selector: 'app-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.scss']
})
export class GeneralInformationComponent implements OnInit {
  public nationalityList: Array<any>;
  public locationList = [];
  public costCenterList = [];
  public organizationForm: FormGroup;
  public action: string;
  public editId: string;
  public isLoading: boolean;
  public organizationErr: string;
  public filteredOptions:  Array<any>;
  public correct : boolean = true;
  public today=new Date();
  public lang = new Map();
  public validationError = {
    required: 'Required',
    web: 'Please provide a valid website',
    password: 'Please provide a password of atleast 8 digits',
    postalcode: 'Should be of 6 digits',
    alphabets: 'Should contain only alphabets',
    contact: 'Please provide a contact of 10 digit'
  };
  constructor(
    private service: DashboardService, private fb: FormBuilder,
    private toastr: NotificationService, private readonly authService: AuthService, private route: ActivatedRoute, private router: Router) {
    this.createForm();
    this.lang = LanguageConfig.map;
    }
    public permissions : any;
    public read = false;
    public write = false ;
    public update = false;
    public delete = false;
   
    checkPermission(module,tabs) : void 
    {
        this.permissions = JSON.parse(this.authService.getPermissions());

        this.read = false ;
        this.write = false ;
        this.delete = false ;
        this.update = false;
        if(this.permissions != undefined && this.permissions != null )
        {
            for(let p of this.permissions)
            {
               
                if(p.id === module)
                {
                   
                    if(p.modules != undefined)
                    {
                      
                        for(let tab of p.modules)
                        {
                            if(tab.id === tabs)
                            {
                              
                                for(let move of tab.permits)
                                {
                                    if(move.id === "u" || move.id === "c")
                                    {
                                        this.update = true;
                                    }
                                    else if(move.id === "r")
                                    {
                                        this.read = true;
                                    }
                                    
                                    else if(move.id === "d")
                                    {
                                        this.delete = true;
                                    }
                                }
                            }
                        }
                    }
                   
                }
             
            }
        }
       

    }
  ngOnInit() {
    this.isLoading = true;
    this.checkPermission("admin","ad_og");
    this.service.getLocationList().subscribe(resp => {
      if (resp && resp.data) {
        this.locationList = resp.data;
      }
    });
    this.service.getCountryList().subscribe(resp => {
      if (resp && resp.data) {
        this.nationalityList = resp.data;
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error in getting Countries list');
    });
    this.service.getCostCenterList().subscribe(resp => {
      if (resp && resp.data) {
        this.costCenterList = resp.data;
      }
    });

    this.service.getOrganizationList().subscribe(user => {
      if (user && user.data) {
        this.organizationErr = '';
        this.action = 'edit';
        this.editId = user.data.length > 0 && user.data[0] && user.data[0].id;
        if (this.editId) {
        this.service.getOrganizationById(this.editId).subscribe(resp => {
          if (resp && resp.data) {
            this.isLoading = false;
             this.organizationForm.patchValue(resp.data);
          }
        }, error => {
          this.isLoading = false;
        });
      } else {
        this.organizationErr = 'No Organization exist';
      }
      }
    }, error => {
      this.organizationErr = '';
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error while getting Holidays');
    });
    this.initValuesDetection();
  }


  public submitOrganization(): void {
    if (this.action === 'edit') {
      this.service.editOrganization(this.editId, this.organizationForm.value).subscribe(
        resp => {
          this.toastr.success('Organization edited successfully');
          this.router.navigate(['/admin/organization/general-information']);
        }, error => {
          const err = error.error && error.error.msg;
          this.toastr.error(err || 'Error in adding organization');
        }
      );
    } else if (this.action === 'add') {
      this.service.addOrganization(this.organizationForm.value).subscribe(
        resp => {
          this.toastr.success('Organization added successfully');
          this.router.navigate(['/admin/organization/general-information']);
        }, error => {
          const err = error.error && error.error.msg;
          this.toastr.error(err || 'Error in adding organization');
        }
      );
    }
  }

  public createForm(): void {
    const num = '^[0-9]*$';
    const alphabets = '^[a-zA-Z\\s]*$';
    this.organizationForm = this.fb.group({
      id: [''],
      locationid: [''],
      name: [''],
      taxid: [''],
      establishedDate:[],
      noofemployee: [''],
      registrationNumber: [''],
      organisationemail: [''],
      companytype: [''],
      status: [''],
      eeoEnabled: [],
      costcenterid: [''],
      addresslineone: [''],
      addresslinetwo: [''],
      addresslinthree: [''],
      city: [''],
      country: [''],
      landmark: [''],
      mobile: [''],
      telephone: [''],
      fax: [''],
      postalcode: [''],
      state: [''],
      website: [''],
      
        
        
        //type: ['', [Validators.required]],
       // workemail: ['', [Validators.required, Validators.email]]
   
    });
   
    // console.log(this.organizationForm.controls.costCenter['controls'].id.valid);
  }

  initValuesDetection()
  {
    this.filteredOptions=this.nationalityList;
    
    this.organizationForm.controls['country'].valueChanges.subscribe(change => {
      if(change == null || change == undefined)
      {
        this.correct = true;
      
      } // Value inside the input field as soon as it changes
      else{
     
      this.filterCountry(change);
      }
    });
  }

    protected filterCountry(change : String) {
      this.filteredOptions = this.nationalityList;
      if (!this.nationalityList) {
          return;
      }
      
      // get the search keyword
      if (!change) {
        this.correct = true;
        this.filteredOptions =this.nationalityList;
          return;
      } else {
        this.checkCountryChange(change);
        change = change.toLowerCase();
      }
      // filter the search
      this.filteredOptions =
          this.nationalityList.filter(co => co.name.toLowerCase().indexOf(change) > -1);
        
  }
  
  checkCountryChange(change )
  {
    var x = this.filteredOptions.find(x => x.code === change);
    if(!x)
    {
      this.correct=false;
    }
    
  }
  public displayCountryByCode(option) {
    
    this.correct =true;
  
  return this.nationalityList.find(x=>x.code === option).name;
}

  

  public fillCountryDetail(): void {
    const val = this.organizationForm.value;
    const c = val.addresses[0].country;
    const cl = this.nationalityList.filter(clist => {
      console.log(clist);
      return clist.code === c;
    });
    val.addresses[0].countrycode = cl[0].code;
    val.addresses[0].countryiso = cl[0].iso;
    this.organizationForm.patchValue(val);
  } 
}

