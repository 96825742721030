<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
    <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="page-header-title">
            <span class="ng-star-inserted"></span
            ><span class="ng-star-inserted">
              <h4 class="m-b-10 ng-star-inserted">Leave Calendar</h4>
            </span>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</div>

<mat-expansion-panel #expansionPanel [expanded]="true">
  <mat-expansion-panel-header class="right-aligned-header">
    <mat-panel-title>
      <span class="accordion-head panel-title"> Filter Leaves </span>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <form style="padding: 24px" class="form-material full-width-inputs" action="javascript:" [formGroup]="filterForm">
    <div class="row form-group">
      <div class="col-sm-4">
        <app-lazy-employee-dropdown
          title="Employee"
          required="false"
          multiTokens="false"
          [selectedEmployees]="employees"
          (employeesSelected)="employeesSelected($event)"
        ></app-lazy-employee-dropdown>
      </div>

      <div class="col-sm-4">
        <mat-form-field class="form-element">
          <mat-label>Employee Status</mat-label>
          <mat-select disableOptionCentering class="form-control" formControlName="employementStatus">
            <mat-option value="">--Select--</mat-option>
            <mat-option *ngFor="let item of EmployeeStatus" [value]="item.id">
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-4">
        <mat-form-field class="form-element">
          <mat-label>location</mat-label>
          <mat-select disableOptionCentering class="form-control" formControlName="locationId">
            <mat-option value="">--Select--</mat-option>
            <mat-option *ngFor="let item of locations" [value]="item.id">
              {{ item.location }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-sm-4">
        <mat-form-field class="form-element">
          <!-- <mat-select
            panelClass="myPanelClass"
            class="form-control"
            formControlName="subUnitId"
            multiple
            disableOptionCentering
          >
            <mat-select-trigger>
              <mat-chip-list>
                <mat-chip *ngFor="let topping of chipList.subUnitId" [selectable]="true" [removable]="false">
                  {{ topping?.name }}
                </mat-chip>
              </mat-chip-list>
            </mat-select-trigger>
            <ng-container *ngFor="let j of temporaryDepartment">
              <mat-option value="{{ j.id }}">{{ j.name }}</mat-option>
            </ng-container>
          </mat-select> -->
          <mat-label for="des">Sub Unit</mat-label>
          <mat-select formControlName="subUnitId" multiple>
            <mat-option *ngFor="let s of temporaryDepartment" [value]="s.id">{{ s.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-sm-4">
        <mat-form-field class="form-element">
          <!-- <mat-select class="form-control" formControlName="leaveStatus" multiple disableOptionCentering>
            <mat-select-trigger>
              <mat-chip-list>
                <mat-chip *ngFor="let topping of chipList.leaveStatus" [selectable]="true" [removable]="false">
                  {{ topping?.text }}
                  
                </mat-chip>
              </mat-chip-list>
            </mat-select-trigger>
            <ng-container *ngFor="let j of leaveStatus">
              <mat-option value="{{ j.id }}">{{ j.text }}</mat-option>
            </ng-container>
          </mat-select> -->
          <mat-label for="des">Leave Status</mat-label>
          <mat-select formControlName="leaveStatus" multiple>
            <mat-option *ngFor="let status of allLeaveStatus" [value]="status.id">{{ status.text }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-sm-4">
        <mat-form-field class="form-element">
          <!-- <mat-select class="form-control" formControlName="leaveTypeId" multiple disableOptionCentering>
            <mat-select-trigger>
              <mat-chip-list>
                <mat-chip *ngFor="let topping of chipList.leaveTypeId" [selectable]="true" [removable]="false">
                  {{ topping?.leaveType }}
                </mat-chip>
              </mat-chip-list>
            </mat-select-trigger>
            <ng-container *ngFor="let j of leaveTypes">
              <mat-option value="{{ j.id }}">{{ j.leaveType }}</mat-option>
            </ng-container>
          </mat-select> -->
          <mat-label for="des">Leave Type</mat-label>
          <mat-select formControlName="leaveTypeId" multiple>
            <mat-option *ngFor="let s of leaveTypes" [value]="s.id">{{ s.leaveType }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-sm-4">
        <mat-checkbox #includePastEmployees class="form-control" color="primary" formControlName="includePastEmployees">
          Include Past Employees
        </mat-checkbox>
      </div>
      <div class="col-sm-4">
        <mat-checkbox
          #hideLeavesOfSupervisors
          class="form-control"
          color="primary"
          formControlName="hideLeavesOfSupervisors"
        >
          Hide Leaves Of Supervisor(s)
        </mat-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="action-buttons multi-btns col-sm-3">
        <span style="font-weight: bold; color: #0d47a1" class="cursor-pointer" (click)="showLeaveDetails()"
          >Legend</span
        >
      </div>
      <div class="action-buttons multi-btns col-sm-9 text-right">
        <button mat-raised-button class="btn-danger" (click)="rest()"><i class="flaticon-cancel"></i>Reset</button>
        <button mat-raised-button class="btn btn-primary" color="primary" (click)="viewCalendar('curr')">
          <i class="flaticon-diskette"></i>View
        </button>
      </div>
    </div>
  </form>
</mat-expansion-panel>

<br /><br />

<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<div>
  <div class="card" style="padding: 20px">
    <full-calendar #calendar [options]="calendarOptions"></full-calendar>
  </div>
</div>

<ng-template #legendsDialog let-modal>
  <div class="mat-modal-header">
    <h4 class="mat-title-modal">
      <span>Legend</span>
    </h4>
  </div>
  <div class="mat-modal-body">
  
    <div>
      <div>
        <div *ngFor="let r of leaveTypes">
          <div style="margin-right: 30px; display: inline-block">
            <mcc-color-picker
              hideUsedColors
              [selectedColor]="r.calendarColor ? r.calendarColor : '#0d47a1'"
              hideButtons
              hideHexForms
              (change)="r.calendarColor = $event"
              (selected)="r.calendarColor = $event"
            >
            </mcc-color-picker>
          </div>
          <span>{{ r.leaveType }}</span>
        </div>
      </div>
    </div>
    <div style="margin-top: 30px" class="action-buttons multi-btns col-sm-12 text-right">
      <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i>Cancel</button>
      <button
        mat-raised-button
        class="btn btn-primary"
        color="primary"
        [class.spinner]="saveLoading"
        [disabled]="saveLoading"
        (click)="save()"
      >
        <i class="flaticon-diskette"></i>Save
      </button>
    </div>
  </div>
</ng-template>

<ng-template #leaveDetailsDialog let-modal>
  <div class="mat-modal-header"></div>
  <div class="mat-modal-body">
    <div *ngIf="!isChange">
      <div style="margin-bottom: 30px">
        <span style="font-weight: bold">{{ dataEvent.data.empId }} {{ dataEvent.data.empName }}</span>
      </div>
      <div>
        <div>
          <span style="margin-right: 71px">Leave Type :</span>
          <span>{{ dataEvent.data.leaveType }}</span>
        </div>
        <div>
          <span style="margin-right: 60px">Leave Status :</span>
          <span>{{ dataEvent.data.leaveStatus }}</span>
        </div>
        <div>
          <span style="margin-right: 54px">Duration Type :</span>
          <span *ngIf="dataEvent.data.fullDay">Full Day</span>
          <span *ngIf="!dataEvent.data.fullDay">Partial Day</span>
        </div>
        <div>
          <span style="margin-right: 54px">Duration (days) :</span>
          <span>{{ dataEvent.data.duration }}</span>
        </div>
        <div *ngIf="!dataEvent.data.fullDay">
          <span style="margin-right: 75px">Start Time :</span>
          <span>{{ dataEvent.data.startTime | date : "shortTime" }}</span>
        </div>
        <div *ngIf="!dataEvent.data.fullDay">
          <span style="margin-right: 82px">End Time :</span>
          <span>{{ dataEvent.data.endTime | date : "shortTime" }}</span>
        </div>
      </div>
    </div>

    <div style="margin-top: 30px" class="action-buttons multi-btns col-sm-12 text-right">
      <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i>Close</button>
    </div>
  </div>
</ng-template>
