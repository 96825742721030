import { ENTER } from "@angular/cdk/keycodes";
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { COMMA } from "@angular/cdk/keycodes";
import { of } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { startWith } from "rxjs/operators";

@Component({
  selector: "app-multi-select-token",
  templateUrl: "./multi-select-token.component.html",
  styleUrls: ["./multi-select-token.component.scss"],
})
export class MultiSelectTokenComponent implements OnInit {
  // selectedItems: Array<any> = [];
  filteredItems: Observable<string[]>;
  itemCtrl = new FormControl();
  itemList: Array<any> = [];
  allItems : Array<any>;

  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild("itemInput") itemInput: ElementRef;
  @Input() title: string;
  @Input() selectedItems: Array<any> = [];
  @Input() disabled: boolean;
  @Output() selectedItemsChange: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();

  constructor() {}

  ngOnInit(): void {
    
  }

  setData(allEmployee) :void{
    this.allItems = [];
    this.itemList = [];
    this.filteredItems = of([]);
    this.selectedItems = [];
    this.allItems = allEmployee;
    this.itemList = this.allItems;
    this.itemCtrl.valueChanges.subscribe((search) => {
      startWith(""),
        (this.filteredItems = of(this.itemList.filter((item) => item.name.toLowerCase().includes(search) && !this.selectedItems.includes(item))));
    });
  }

  removeItem(id): void {
    this.selectedItems = this.selectedItems?.filter((emp) => emp.id !== id);
    this.itemList = [];
    this.allItems?.filter((c) => !this.selectedItems.includes(c))?.map((c) => this.itemList.push(c));
    this.filteredItems = of([]);
    this.selectedItemsChange.emit(this.selectedItems);
  }

  addItem(event: MatAutocompleteSelectedEvent): void {
    this.selectedItems.push(event.option.value);
    this.itemInput.nativeElement.value = "";
    this.itemCtrl.setValue(null);
    this.itemList = this.itemList?.filter((emp) => emp.id != event.option.value.id);
    this.filteredItems = of([]);
    this.itemInput.nativeElement.blur();
    this.selectedItemsChange.emit(this.selectedItems);
  }
}
