<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
    <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="page-header-title">
            <span class="ng-star-inserted"></span
            ><span class="ng-star-inserted"
              ><h4 class="m-b-10 ng-star-inserted">My Leave Entitlements and Usage Report</h4></span
            >
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</div>

<div class="page-body no-title">
  <div class="row">
    <div class="col-sm-12">
      <app-card [title]="'Leave Usage'">
        <!-- <br />
        <div class="row top-tools">
          <div class="col-xs-12 col-sm-12 col-md-6 left-tools"></div>
          <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
            <div class="relative-position search-filter-con">
              <h3>
                <span class="mytooltip tooltip-effect-4" *ngIf="this.prev" (click)="onPrev()">
                  <span class="tooltip-item accordion-head inner-checked"
                    ><mat-icon aria-hidden="false"> navigate_before </mat-icon></span
                  >
                  <span class="tooltip-content clearfix">
                    <span class="tooltip-text">Previous Period</span>
                  </span>
                </span>

                <span class="mytooltip tooltip-effect-4" *ngIf="!this.prev" (click)="onPrev()">
                  <span class="tooltip-item"><mat-icon aria-hidden="false"> navigate_before </mat-icon></span>
                  <span class="tooltip-content clearfix">
                    <span class="tooltip-text">Previous Period</span>
                  </span>
                </span>
              </h3>
              &nbsp; &nbsp;
              <h5>
                {{ this.period }}
              </h5>
              &nbsp; &nbsp;
              <h3>
                <span class="mytooltip tooltip-effect-4" *ngIf="this.next" (click)="onNext()">
                  <span class="tooltip-item accordion-head inner-checked"
                    ><mat-icon aria-hidden="false">navigate_next </mat-icon></span
                  >
                  <span class="tooltip-content clearfix">
                    <span class="tooltip-text">Next Period</span>
                  </span>
                </span>

                <span class="mytooltip tooltip-effect-4" *ngIf="!this.next" (click)="onNext()">
                  <span class="tooltip-item"><mat-icon aria-hidden="false">navigate_next </mat-icon></span>
                  <span class="tooltip-content clearfix">
                    <span class="tooltip-text">Next Period</span>
                  </span>
                </span>
              </h3>
            </div>
          </div>
        </div>
        <br /> -->
        <div class="dt-responsive">
          <div class="table-responsive">
            <mat-card-content>
              <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
              <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8" (matSortChange)="sortData($event)">
                <ng-container matColumnDef="employeeId">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isLoading">
                    Employee <br />
                    Id
                  </th>
                  <td mat-cell *matCellDef="let element">{{ element.employeeId }}</td>
                </ng-container>

                <ng-container matColumnDef="employeeName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isLoading">Employee Name</th>
                  <td mat-cell *matCellDef="let element">{{ element.employeeName }}</td>
                </ng-container>

                <ng-container matColumnDef="leaveTypeName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isLoading">Leave Type</th>
                  <td mat-cell *matCellDef="let element">{{ element.leaveTypeName }}</td>
                </ng-container>

                <ng-container matColumnDef="leavePeriod">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isLoading">Leave Period</th>
                  <td mat-cell *matCellDef="let element">{{ element.leavePeriod }}</td>
                </ng-container>

                <ng-container matColumnDef="pending">
                  <th mat-header-cell *matHeaderCellDef >
                    Pending <br />
                    Approval <br />
                    (Days)
                  </th>
                  <td mat-cell *matCellDef="let element">{{ element.pending }}</td>
                </ng-container>
                <ng-container matColumnDef="entitlements">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isLoading">
                    Entitlements <br />
                    (Days)
                  </th>
                  <td mat-cell *matCellDef="let element">{{ element.entitlements }}</td>
                </ng-container>
                <ng-container matColumnDef="scheduled">
                  <th mat-header-cell *matHeaderCellDef >
                    Scheduled <br />
                    (Days)
                  </th>
                  <td mat-cell *matCellDef="let element">{{ element.scheduled }}</td>
                </ng-container>
                <ng-container matColumnDef="taken">
                  <th mat-header-cell *matHeaderCellDef >
                    Taken <br />
                    (Days)
                  </th>
                  <td mat-cell *matCellDef="let element">{{ element.taken }}</td>
                </ng-container>

                <ng-container matColumnDef="available">
                  <th mat-header-cell *matHeaderCellDef >
                    Available <br />
                    Balance <br />
                    (Days)
                  </th>
                  <td mat-cell *matCellDef="let element">{{ element.available }}</td>
                </ng-container>

                <ng-container matColumnDef="overdrawn">
                  <th mat-header-cell *matHeaderCellDef>
                    Total <br />
                    Overdrawn <br />
                    (Days)
                  </th>
                  <td mat-cell *matCellDef="let element">{{ element.overdrawn }}</td>
                </ng-container>

                <ng-container matColumnDef="netBalance">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isLoading">
                    Net <br />
                    Balance <br />
                    (Days)
                  </th>
                  <td mat-cell *matCellDef="let element">{{ element.netBalance }}</td>
                </ng-container>

                <!-- <ng-container matColumnDef="icons">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element">
                    <span
                      class="mytooltip tooltip-effect-4"
                      (click)="openTimeLine(element.employeeId, element.leaveTypeId, element.leavePeriod)"
                    >
                      <span class="tooltip-item"><mat-icon aria-hidden="false"> restore icon </mat-icon></span>
                      <span class="tooltip-content clearfix">
                        <span class="tooltip-text">Show<br />Time<br />Line</span>
                      </span>
                    </span>
                  </td>
                </ng-container> -->
                <!-- Header row first group -->
                <!-- <ng-container matColumnDef="header-row-first-group">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center" [attr.colspan]="5"></th>
                </ng-container> -->

                <!-- Header row second group -->
                <!-- <ng-container matColumnDef="header-row-second-group">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center" [attr.colspan]="3">
                    Usage of Entitlements
                  </th>
                </ng-container> -->

                <!-- <ng-container matColumnDef="header-row-third-group">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center" [attr.colspan]="4"></th>
                </ng-container>
                <tr
                  mat-header-row
                  *matHeaderRowDef="['header-row-first-group', 'header-row-second-group', 'header-row-third-group']"
                ></tr> -->
                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns"></tr>
              </table>
              
              <mat-paginator
                [pageSize]="pageSize"
                [pageIndex]="pageIndex"
                [pageSizeOptions]="[10, 25, 50]"
                showFirstLastButtons
                [length]="total"
                (page)="handlePageEvent($event)"
                [disabled]="isLoading"
              ></mat-paginator>
            </mat-card-content>
          </div>
          <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
        </div>
      </app-card>
    </div>
  </div>
</div>
