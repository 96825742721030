<app-loading *ngIf="isLoading" [isLoading]="isLoading"></app-loading>
<div *ngIf="!isLoading" class="form-config-chart">
    <form class="form-material full-width-inputs" action="javascript:"
          [formGroup]="courseForm"
          *ngIf="!isLoading">
        <div class="row form-group">
            <div class="col-sm-6">
                <mat-form-field class="form-element">
                    <mat-label>Name</mat-label>
                    <input class="form-control" formControlName="sessionName" id="title"
                           matInput maxlength="40"
                           required="" type="text">
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="form-element">
                    <mat-label>Course</mat-label>
                    <input class="form-control" type="text"
                           aria-label="courseId"
                           matInput
                           formControlName="courseId"
                           [matAutocomplete]="auto1">
                    <mat-autocomplete #auto1="matAutocomplete"
                                      [displayWith]="displayCourseByName.bind(this)">
                        <mat-option *ngFor="let r of filteredOptions1 " [value]="r.id">
                            {{r.title}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error
                            *ngIf="!courseForm.controls.courseId.valid && courseForm.controls.courseId.touched">
                        please enter course
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="form-element">
                    <input required="" [matDatepicker]="picker2" class="form-control"
                           formControlName="startDate" matInput>
                    <mat-datepicker-toggle [for]="picker2"
                                           matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                    <mat-label for="des">Start Date</mat-label>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="form-element">
                    <input [matDatepicker]="picker3" class="form-control"
                           formControlName="endDate" matInput>
                    <mat-datepicker-toggle [for]="picker3"
                                           matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #picker3></mat-datepicker>
                    <mat-label for="endDate">End Date</mat-label>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="form-element">
                    <mat-label>Delivery Location</mat-label>
                    <input class="form-control" formControlName="deliveryLocation"
                           id="deliveryLocation" matInput maxlength="40"
                           type="text">
                </mat-form-field>
            </div>

            <div class="col-sm-6">
                <div class="row">
                    <div class="col-6">
                        <mat-form-field class="form-element">
                            <mat-label>delivery Method</mat-label>
                            <mat-select
                                    class="form-control"
                                    formControlName="deliveryMethod">
                                <mat-option value="classRoom">CLass Room</mat-option>
                                <mat-option value="selfStudy">Self Study</mat-option>
                                <mat-option value="webEx">Web Ex</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field class="form-element">
                            <mat-label>Status</mat-label>
                            <mat-select
                                    class="form-control"
                                    formControlName="status">
                                <mat-option value="pending">Pending</mat-option>
                                <mat-option value="scheduled">Scheduled</mat-option>
                                <mat-option value="complete">Complete</mat-option>
                                <mat-option value="cancelled">Cancelled</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="col-sm-12">
                <mat-form-field class="form-element">
                    <mat-label>Description</mat-label>
                    <textarea class="form-control" matInput placeholder="Description"
                              formControlName="description"></textarea>
                </mat-form-field>
            </div>

        </div>
        <div class="action-buttons multi-btns col-sm-12 text-right">
            <button mat-raised-button class="btn-danger" (click)="close()">
                <i class="flaticon-cancel"></i>Cancel
            </button>
            <button mat-raised-button class="btn btn-primary" color="primary"
                    [disabled]="!courseForm.valid ||!this.correct"
                    (click)="submitAddCourse()"><i class="flaticon-diskette"></i>Save
            </button>
        </div>
    </form>
</div>



