<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
    <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="page-header-title">
            <span class="ng-star-inserted"></span
            ><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted">Employee List</h4></span>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</div>
<ng-container>
  <div class="page-body no-title">
    <div class="row">
      <div class="col-sm-12">
        <div class="row top-tools">
          <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6 left-tools">
            <span class="export-icon-btn csv" (click)="exportToCsv()"
              ><i class="material-icons">get_app</i><span>CSV</span>
            </span>
            <span (click)="saveAsPdf()" class="export-icon-btn pdf"
              ><i class="material-icons">get_app</i><span>PDF</span>
            </span>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
            <div class="relative-position search-filter-con">
              <span class="mytooltip cus-left tooltip-effect-4" [hidden]="!write">
                <span class="tooltip-item">
                  <button
                    mat-mini-fab
                    class="btn btn-primary light add-rounded margin-button-05"
                    (click)="addNewUser()"
                  >
                    <i class="fa fa-plus"></i>
                  </button>
                </span>
                <span class="tooltip-content clearfix">
                  <span class="tooltip-text">New</span>
                </span>
              </span>
              <span class="mytooltip cus-left tooltip-effect-4">
                <span class="tooltip-item">
                  <button
                    (click)="openFilterDialog()"
                    class="btn btn-primary light add-rounded margin-button-05"
                    mat-mini-fab
                  >
                    <i class="fa fa-filter"></i>
                  </button>
                </span>
                <span class="tooltip-content clearfix">
                  <span class="tooltip-text">filter</span>
                </span>
              </span>
            </div>
          </div>
        </div>

        <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>

        <app-card [title]="'Employee List'">
          <div *ngIf="employeeListDataSource" class="dt-responsive">
            <div class="table-responsive">
              <mat-card-content>
                <mat-table [dataSource]="employeeListDataSource" matSort>
                  <ng-container matColumnDef="photo">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let e">
                      <img *ngIf="e.image" class="img-p" src="{{ e.imageFullPath }}" />
                      <img *ngIf="!e.image" class="img-p" src="../../../../assets/images/user-avatar.jpg" />
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Employee ID</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      {{ row.id }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header="firstname">Employee Name </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      {{ row.name }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="jobtitle">
                    <mat-header-cell *matHeaderCellDef mat-sort-header="jobTitle">Job Title</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      {{ row.jobtitle }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="employeeStatus">
                    <mat-header-cell *matHeaderCellDef mat-sort-header="employeestatus">Employment Status </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      {{ row.employeeStatus }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="subunit">
                    <mat-header-cell *matHeaderCellDef mat-sort-header="temporarydepartment">Sub Unit</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      <span
                        [matTooltip]="row.fullSubUnit"
                        matTooltipClass="custom-tooltip-blue"
                        aria-label="Button that displays a tooltip when focused or hovered over"
                      >
                        {{ row.subunit }}
                      </span>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="location">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Location</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      {{ row.location }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="supervisor">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Suprevisor</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      {{ row.supervisor }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="edit">
                    <mat-header-cell *matHeaderCellDef>Edit</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      <span class="mytooltip tooltip-effect-4">
                        <span class="tooltip-item"
                          ><i (click)="editEmployee(row.id)" class="single-icon-click fa fa-pencil"></i
                        ></span>
                        <span class="tooltip-content clearfix">
                          <span class="tooltip-text">Edit</span>
                        </span>
                      </span>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="employeeListColumns"></mat-header-row>
                  <mat-row
                    (click)="editEmployee(row.id)"
                    *matRowDef="let row; columns: employeeListColumns"
                    class="cursor-pointer"
                  >
                  </mat-row>
                </mat-table>

                <mat-paginator
                  [pageSize]="pageSize"
                  [pageIndex]="pageIndex"
                  [pageSizeOptions]="[10, 25, 50]"
                  showFirstLastButtons
                  [length]="totalEmployess"
                  (page)="handlePageEvent($event)"
                  [disabled]="isLoading"
                ></mat-paginator>
              </mat-card-content>
            </div>
            <div *ngIf="!isLoading && err" class="error-msg">{{ err }}</div>
          </div>
        </app-card>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #filterEmp let-modal>
  <div class="mat-modal-header">
    <h4 class="mat-title-modal">Filter</h4>
  </div>
  <div class="mat-modal-body">
    <form [formGroup]="filterEmployeeForm" action="javascript:" class="form-material full-width-inputs">
      <div class="row form-group">
        <div class="col-md-6">
          <app-lazy-employee-dropdown
            title="Employee"
            required="false"
            [selectedEmployee]="filteredEmployee"
            (employeeSelected)="updateFilterEmployee($event)"
          ></app-lazy-employee-dropdown>
        </div>
        <div class="col-md-6">
          <mat-form-field class="form-element">
            <mat-label class="col-form-label">Employee Status</mat-label>
            <mat-select disableOptionCentering class="form-control" formControlName="employeeStatusSelected">
              <mat-option value="">--Select--</mat-option>
              <mat-option *ngFor="let r of employeeStatusList" [value]="r.value"> {{ r.label }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <app-lazy-employee-dropdown
            title="Supervisor"
            required="false"
            [selectedEmployee]="selectedSupervisor"
            (employeeSelected)="updateSelectedSupervisor($event)"
          ></app-lazy-employee-dropdown>
        </div>
        <div class="col-md-6">
          <mat-form-field class="form-element">
            <mat-label class="col-form-label">Job Title</mat-label>
            <mat-select disableOptionCentering class="form-control" formControlName="jobTitleSelected">
              <mat-option value="">--Select--</mat-option>
              <mat-option *ngFor="let t of jobTitleList" [value]="t.value"> {{ t.label }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-6">
          <mat-form-field class="form-element">
            <mat-label class="col-form-label">Sub Unit</mat-label>
            <mat-select disableOptionCentering class="form-control" formControlName="subunitSelected">
              <mat-option value="">--Select--</mat-option>
              <mat-option *ngFor="let m of structureList" [value]="m.value"> {{ m.label }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-6">
          <mat-form-field class="form-element">
            <mat-label class="col-form-label">Include</mat-label>
            <mat-select disableOptionCentering class="form-control" formControlName="includeSelected">
              <mat-option *ngFor="let t of includeList" [value]="t.value"> {{ t.label }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="form-element">
            <mat-label class="col-form-label">Location</mat-label>
            <mat-select disableOptionCentering class="form-control" formControlName="locationSelected">
              <mat-option value="">--Select--</mat-option>
              <mat-option *ngFor="let t of locationList" [value]="t.value"> {{ t.label }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row form-group">
        <div class="col-md-6">
          <mat-checkbox
            #makeDefaultFilter
            (click)="OnMakeDefaultClick($event)"
            class="form-control"
            color="primary"
            formControlName="isChecked"
          >
            Make Default Filter
          </mat-checkbox>
        </div>
      </div>
      <div class="row">
        <div class="action-buttons multi-btns col-sm-12 text-right">
          <button (click)="resetFilter()" class="btn-danger reset-btn" mat-raised-button>
            <i class="flaticon-refresh"></i>Reset
          </button>
          <button (click)="dialogRef.close()" class="btn-danger" mat-raised-button><i class="flaticon-cancel"></i>Cancel</button>
          <button
            (click)="filterEmployee()"
            [class.spinner]="searchInProgress"
            [disabled]="searchInProgress"
            class="btn btn-primary"
            color="primary"
            mat-raised-button
            type="submit"
          >
            <i class="flaticon-magnifiying-glass"></i>Search
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #deleteUser let-modal>
  <div class="card my-0">
    <div class="header">
      <h4 class="card-title card-title-modal">Delete Employee</h4>
    </div>
    <div class="card-content px-2 py-2">
      <div class="text-center are-u">Are you sure ?</div>
      <div class="row">
        <div class="col-sm-12 text-right">
          <button type="submit" class="btn btn-cancel btn-danger" (click)="dialogRef.close()">Cancel</button>
          <button type="submit" class="btn btn-save btn-primary" (click)="deleteJobCategory()">Delete</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
