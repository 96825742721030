import { Component, OnInit, ViewChild } from '@angular/core';
import { AnnouncementService } from "../../../../service/announcement.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { NotificationService } from "src/app/core/services/notification.service";
import { DashboardService } from "../../../dashboard.service";
import { map, startWith } from "rxjs/operators";
import { CropperSettings, ImageCropperComponent } from 'ngx-img-cropper';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/auth.service';


@Component({
    selector: 'app-add-assets',
    templateUrl: './add-assets.component.html',
    styleUrls: ['./add-assets.component.scss']
})
export class AddAssetsComponent implements OnInit {
    updateId = null;
    updateObj = null;

    newForm: FormGroup;
    isSaving = false;
    isLoadForm = true;

    tempStatus: string = "";
    public isLoading: boolean = true;
    imageSrc: string = "";
    statusForm: FormGroup;
    imageUploading = false;
    uploadedImage: any;
    vendors: Array<any> = [];
    brands: Array<any> = [];
    locations: Array<any> = [];
    categorys: Array<any> = [];
    public isShow: boolean = false;
    employees: Array<any> = [];
    statusList: Array<any> = [];
    isUserAdded: boolean = false;
    statusOption = [
        { id: 'InStock', name: 'InStock' },
        { id: 'Return', name: 'Return' },
        { id: 'MarkStolen', name: 'Mark Stolen' },
        { id: 'UpdateAssignment', name: 'Rectify Assignment' },
        { id: 'Decommission', name: 'Decommission' },
        { id: 'Assign', name: 'Assign' },
        { id: 'MarkAvailable', name: 'Mark Available' },
        { id: 'MarkFound', name: 'Mark Found' }
    ];
    @ViewChild('uploadImage', { static: false }) uploadImageDialog;
    @ViewChild('assignAssest', { static: false }) assignAssest;
    @ViewChild('returnAssest', { static: false }) returnAssest;
    @ViewChild('attach', { static: false }) attach;

    cropperSettings: CropperSettings;
    uploadDialogRef: any;
    filteredOptions: Array<any> = [];
    filteredOptions2: Array<any> = [];
    filteredOptions3: Array<any> = [];
    filteredOptions4: Array<any> = [];
    
    categories: Array<any> = [];

    @ViewChild('cropper') cropper: ImageCropperComponent;
    imgFile: string;
    uploadForm = new FormGroup({
        name: new FormControl(''),
        file: new FormControl(''),
        imgSrc: new FormControl('')
    });
    mainImage: string = "../../../../../assets/images/cloud-upload_15.svg";
    attacherId: string = "";
    attachmentList: Array<any> = [];
    comment: string;
    fileType: string;
    fileObj: any;
    uploadedFile: any;
    fileError: string;
    constructor(
        private router: Router,
        private dialog: MatDialog,
        private announcementService: AnnouncementService,
        private dashboardService: DashboardService,
        private activatedRoute: ActivatedRoute,
        private fb: FormBuilder,
        private toastr: NotificationService,
        private authService: AuthService
    ) {
        this.isLoadForm = true;
        this.imageSrc = this.mainImage;
        this.newForm = this.fb.group({
            vendorId: '',
            status: 'InStock',
            model: '',
            brandId: '',
            locationId: '',
            categoryId: '',
            description: '',
            serialNumber: '',
            warrantyEnd: '',
            warrantyStarts: '',
            aquired: '',
            id: '',
            imageLink: '',
            assetId: '',
            assignedToId: '',
            assignedToName: '',
            assignedFrom: '',
            hasAttachement: false,
            emp: '',
        });
        this.statusForm = this.fb.group({
            assetId: '',
            status: '',
            assignedToId: '',
            date: '',
            deleted: false
        });

        this.activatedRoute.params.subscribe(params => {
            if (params.updateId) {
                this.updateId = atob(params.updateId);
            }
            this.loadData()
        });






    }
    get uf() {
        return this.uploadForm.controls;
    }
    openUploadDialog() {
        this.uploadDialogRef = this.dialog.open(this.uploadImageDialog, {
            width: '560px',
            autoFocus: false,
            maxHeight: '60vh'
        });


    }






    public permissions: any;
    public read = false;
    public write = false;
    public update = false;
    public delete = false;

    checkPermission(module, tabs): void {
        this.permissions = JSON.parse(this.authService.getPermissions());
        this.read = false;
        this.write = false;
        this.delete = false;
        this.update = false;
        if (this.permissions != undefined && this.permissions != null) {
            for (let p of this.permissions) {

                if (p.id === module) {

                    if (p.modules != undefined) {

                        for (let tab of p.modules) {
                            if (tab.id === tabs) {

                                for (let move of tab.permits) {
                                    if (move.id === "u") {
                                        this.update = true;
                                    }
                                    else if (move.id === "r") {
                                        this.read = true;
                                    }
                                    else if (move.id === "c") {

                                        this.write = true;
                                    }
                                    else if (move.id === "d") {
                                        this.delete = true;
                                    }
                                }
                            }
                        }
                    }

                }

            }
        }


    }


    // getCategories() {

    //     var obj =
    //     {
    //         categoryId: null,
    //         name: null
    //     }
    //     this.announcementService.filterAssetsCategories(obj).subscribe(
    //         res => {

    //             console.log("res : ",res);
    //             if (res && res.data) {
    //                 this.categories=res.data;
    //             }

    //         }, error => {
    //             const err = error.error && error.error.msg;
    //             this.isLoading = false;
    //             this.toastr.error(err || "Error while getting categories.");
    //         });
    // }
    getCategories() 
    {
        this.announcementService.getAssetsCategories().subscribe(resp => {
            if (resp && resp.data) {
                resp.data.map(e => {
                    this.categories.push({label:e.name, value: e.id});
                });
            }
        }, error => {
            this.isLoading = false;
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error in getting categories list');
        });
    }
    ngOnInit() {
        this.checkPermission("assets", "assets_va");
        if (this.updateId === null || this.updateId === undefined && this.updateId === "") {
            this.update = true;
            this.imageSrc = this.mainImage;
        }

        this.getCategories();



    }


    changeStatus(event): void {
        this.statusList = [];
        if (event === "InStock") {
            this.statusList.push(
                {
                    value: "InStock",
                    label: "In-stock",
                    hidden: true

                },
                {
                    value: "Decommission",
                    label: "Decommissioned",
                    hidden: false

                },
                {
                    value: "Assign",
                    label: "Assign",
                    hidden: false

                },
                {
                    value: "MarkStolen",
                    label: "Mark Stolen",
                    hidden: false

                }
            )
        }

        else if (event === "InUse") {
            this.statusList.push(
                {
                    value: "InUse",
                    label: "In-use",
                    hidden: true

                },
                {
                    value: "Decommission",
                    label: "Decommissioned",
                    hidden: false

                },
                {
                    value: "MarkStolen",
                    label: "Stolen",
                    hidden: false

                },
                {
                    value: "Return",
                    label: "Return",
                    hidden: false

                },
                {
                    value: "Update",
                    label: "Rectify Assignment",
                    hidden: false

                }

            )
        }





        else if (event === "Decommission") {
            this.statusList.push(
                {
                    value: "Available",
                    label: "Make Available ",
                    hidden: false

                },
                {
                    value: "Decommission",
                    label: "Decommissioned",
                    hidden: true

                }

            )
        }

        else if (event === "MarkStolen") {
            this.statusList.push(
                {
                    value: "MarkStolen",
                    label: "Stolen",
                    hidden: true

                },
                {
                    value: "MarkFound",
                    label: "Mark Found",
                    hidden: false

                }

            )
        }


    }

    cancel(): void {
        this.router.navigate([`/assets/assets`]);
    }
    save() {
        if (this.newForm.invalid) {
            this.toastr.error("Please fill all required values.");
            return
        }

        if (this.imageSrc === this.mainImage) {
            this.newForm.patchValue({
                imageLink: ""
            });
        }
        else {
            this.newForm.patchValue({
                imageLink: this.imageSrc
            });
        }
        console.log("categories :",this.categories);




        (this.updateId ? this.announcementService.updateAsset(this.newForm.value) :
            this.announcementService.saveAsset(this.newForm.value)).subscribe(res => {
                if (res && res.data) {
                    if (this.newForm.value.id) {
                        this.toastr.success("Asset updated.")
                        this.getAssest();
                    } else {

                        this.router.navigate([`/assets/assets`]);
                        this.toastr.success("Asset saved.")
                    }
                }
                this.isSaving = false;
            }, error => {
                const err = error.error && error.error.msg;
                this.isSaving = false;
                this.toastr.error(err || "Error while saving asset.");
            });
    }

    canDeleteImage: boolean = false;
    loadData() {
        this.isLoadForm = true;
        this.isLoading = true;

        // this.updateId && join.push(this.dashboardService.getEmployeeDropdownOption());
        if (!this.updateObj) {
            this.announcementService.generateIDForAllAssets('assets').subscribe(user => {
                if (user && user.data) {
                    this.newForm.patchValue({
                        assetId: user.data
                    });
                    this.isSaving = false;

                }
            }, error => {
                this.isSaving = false;
                const err = error.error && error.error.msg;
                this.toastr.error(err || 'Error in generating Id');
            });
        }
        this.dashboardService.getLocationList().subscribe(resp => {
            if (resp && resp.data) {
                resp.data.map(e => {
                    this.locations.push({ label: e.location, value: e.id });
                });
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error in getting location list');
        });


        this.announcementService.getAssetsVendors().subscribe(resp => {
            if (resp && resp.data) {
                resp.data.map(e => {
                    this.vendors.push({ label: e.name, value: e.id });
                });
            }
        }, error => {
            this.isLoading = false;
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error in getting vendors list');
        });
        this.announcementService.getAssetsCategories().subscribe(resp => {
            if (resp && resp.data) {
                resp.data.map(e => {
                    this.categorys.push({ label: e.name, value: e.id });
                });
            }
        }, error => {
            this.isLoading = false;
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error in getting categories list');
        });
        this.announcementService.getAssetsBrands().subscribe(resp => {
            if (resp && resp.data) {
                resp.data.map(e => {
                    this.brands.push({ label: e.name, value: e.id });
                });
                if (!this.updateObj) {
                    this.isLoading = false;
                }
            }
        }, error => {
            this.isLoading = false;
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error in getting location list');
        });

        if (this.updateId) {
            this.dashboardService.getEmployeeDetailList().subscribe(resp => {
                if (resp && resp.data) {
                    resp.data.map(e => {
                        this.employees.push({ label: e.id + ' ' + e.name, value: e.id });

                    });

                }
            }, error => {
            });
            this.getAssest();

        }

        this.isLoadForm = false;


        // if (resArr[5]) {
        //     this.employees = this.getApiData(resArr[5]).map((item => {
        //         return {id: item.id, name: item.name} as Pair
        //     }));
        // }
        this.initializeFilter();
        this.initializeForm();

    }


    public temp: string = "";
    getAssest(): void {
        this.isLoading = true;
        this.canDeleteImage = false;
        this.announcementService.getAssetById(this.updateId).subscribe(resp => {
            if (resp && resp.data) {
                this.updateObj = resp.data;
                if (resp.data.assignedToId != undefined && resp.data.assignedToId != null && resp.data.assignedToId != '') {
                    if (resp.data.assignedToName != undefined && resp.data.assignedToName != null && resp.data.assignedToName != '') {
                        resp.data.emp = resp.data.assignedToId + ' ' + resp.data.assignedToName;
                    }
                }
                this.newForm.patchValue(this.updateObj);
                this.temp = this.updateObj.status;
                if (this.updateObj.imageLink === "" || this.updateObj.imageLink === undefined) {
                    this.imageSrc = this.mainImage;

                }
                else {
                    this.canDeleteImage = true;
                    this.imageSrc = this.updateObj.imageLink;
                    this.imgFile = this.updateObj.imageLink;
                }
                this.changeStatus(this.newForm.value.status);
                this.isLoading = false;
            }
        }, error => {
            this.isLoading = false;
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error in getting Assest Informations');
        });

    }

    deleteImage(): void {
        this.toastr.success("Image Removed successfully please save the informations ");
        this.imageSrc = this.mainImage;
        this.uploadForm.reset();
        this.imgFile = "";
        this.close();
        return;

    }
    callChangeStatus(event): void {
        this.tempStatus = "";
        if (event === "Assign") {
            this.tempStatus = event;
            this.newForm.patchValue({
                status: this.temp
            })
            this.changeStatus(this.temp);
            this.statusForm.reset();
            this.statusForm.patchValue({

                date: new Date()
            });
            this.uploadDialogRef = this.dialog.open(this.assignAssest, {
                width: '560px',
                autoFocus: false,
                maxHeight: '60vh'
            });
            return;
        }

        else if (event === "Update") {
            this.tempStatus = event;
            this.newForm.patchValue({
                status: this.temp
            })
            this.changeStatus(this.temp);
            this.statusForm.patchValue({
                assignedToId: this.newForm.value.assignedToId,
                date: this.newForm.value.assignedFrom,
                deleted: false
            })

            this.uploadDialogRef = this.dialog.open(this.assignAssest, {
                width: '560px',
                autoFocus: false,
                maxHeight: '60vh'
            });
            return;
        }
        else if (event === "Return") {
            this.tempStatus = event;
            this.newForm.patchValue({
                status: this.temp
            })
            this.changeStatus(this.temp);
            this.statusForm.reset();
            this.statusForm.patchValue({
                date: new Date()
            })
            this.uploadDialogRef = this.dialog.open(this.returnAssest, {
                width: '560px',
                autoFocus: false,
                maxHeight: '60vh'
            });
            return;

        }
        let obj = {
            assetId: this.newForm.value.assetId,
            status: event,
            assignedToId: '',
            date: '',
            deleted: false

        }
        this.announcementService.setAssestStatus(this.updateId, obj).subscribe(resp => {
            if (resp && resp.data) {
                this.getAssest();
                this.toastr.success('Updated successfully ');
                this.closeModal();
            }
        }, error => {

            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error in updating');
        });
    }

    callChangeStatuss() {
        this.isUserAdded = true;
        this.statusForm.patchValue({
            assetId: this.newForm.value.assetId,
            status: this.tempStatus,
            deleted: false
        });

        this.announcementService.setAssestStatus(this.updateId, this.statusForm.value).subscribe(resp => {
            if (resp && resp.data) {
                this.isUserAdded = false;
                this.closeModal();
                this.getAssest();

                this.toastr.success('Updated successfully ');
                this.isUserAdded = false;
            }
        }, error => {
            this.isUserAdded = false;
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error in updating');
        });
    }


    initializeForm() {


    }




    onImageChange(e) {
        const reader = new FileReader();

        if (e.target.files && e.target.files.length) {
            const [file] = e.target.files;
            reader.readAsDataURL(file);

            reader.onload = () => {
                this.imgFile = reader.result as string;
                this.uploadForm.patchValue({
                    imgSrc: reader.result
                });

            };
        }

    }

    upload(): void {

        this.imageSrc = this.uploadForm.value.imgSrc;
        var uname = new String(this.imageSrc);
        let x = (uname.length * (3 / 4)) - 2;
        if (x / 1000 > 1524) {
            this.toastr.error("The size of photo must be under 1.5 MB");
            this.imageSrc = this.mainImage;
            this.uploadForm.reset();
            this.imgFile = "";
            return;

        }

        this.closeModal();

    }


    public closeModal(): void {
        this.uploadDialogRef.close();
    }
    close(): void {
        this.uploadDialogRef.close();
    }
    private initializeFilter() {
        // Vendors
        this.filteredOptions = [];
        this.newForm.controls['vendorId'].valueChanges.subscribe(change => {
            startWith("");
            if (change == null || change == undefined) {
                this.filteredOptions = [];
            } else {

                this.filterVendors(change);
            }

        });
        //Brands
        this.filteredOptions2 = [];
        this.newForm.controls['brandId'].valueChanges.subscribe(change => {
            startWith("");
            if (change == null || change == undefined) {
                this.filteredOptions2 = [];
            } else {

                this.filterBrands(change);
            }

        });

        this.filteredOptions3 = [];
        this.newForm.controls['categoryId'].valueChanges.subscribe(change => {
            startWith("");
            if (change == null || change == undefined) {
                this.filteredOptions3 = [];
            } else {

                this.filterCategories(change);
            }

        });

        this.filteredOptions4 = [];
        this.statusForm.controls['assignedToId'].valueChanges.subscribe(change => {
            startWith("");
            if (change == null || change == undefined) {
                this.filteredOptions4 = [];
            } else {

                this.filterEmployees(change);
            }

        });
    }
    protected filterVendors(change: String) {
        this.filteredOptions = this.vendors;
        if (!this.vendors) {
            return;
        }

        // get the search keyword
        if (!change) {

            this.filteredOptions = [];
            return;
        } else {

            change = change.toLowerCase();
        }
        // filter the search
        this.filteredOptions =
            this.vendors.filter(emp => emp.label.toLowerCase().indexOf(change) > -1);

    }
    public displayVendor(option) {
        if (!option) {
            return '';
        }
        return this.vendors.find(x => x.value === option).label;
    }


    protected filterBrands(change: String) {
        this.filteredOptions2 = this.brands;
        if (!this.brands) {
            return;
        }

        // get the search keyword
        if (!change) {

            this.filteredOptions2 = [];
            return;
        } else {

            change = change.toLowerCase();
        }
        // filter the search
        this.filteredOptions2 =
            this.brands.filter(emp => emp.label.toLowerCase().indexOf(change) > -1);

    }

    public displayBrand(option) {
        if (!option) {
            return '';
        }
        return this.brands.find(x => x.value === option).label;
    }


    protected filterCategories(change: String) {
        this.filteredOptions3 = this.categorys;
        if (!this.brands) {
            return;
        }

        // get the search keyword
        if (!change) {

            this.filteredOptions3 = [];
            return;
        } else {

            change = change.toLowerCase();
        }
        // filter the search
        this.filteredOptions3 =
            this.categorys.filter(emp => emp.label.toLowerCase().indexOf(change) > -1);

    }

    public displayCategory(option) {
        if (!option) {
            return '';
        }
        return this.categorys.find(x => x.value === option).label;
    }

    protected filterEmployees(change: String) {
        this.filteredOptions4 = this.employees;
        if (!this.employees) {
            return;
        }

        // get the search keyword
        if (!change) {

            this.filteredOptions4 = [];
            return;
        } else {

            change = change.toLowerCase();
        }
        // filter the search
        this.filteredOptions4 =
            this.employees.filter(emp => emp.label.toLowerCase().indexOf(change) > -1);

    }

    public displayEmployees(option) {
        if (!option) {
            return '';
        }
        return this.employees.find(x => x.value === option).label;
    }


    onAddAttachment() {
        this.attacherId = this.updateId;
        this.isUserAdded = true;
        this.uploadDialogRef = this.dialog.open(this.attach, {
            width: '440px',
            height: 'auto'
        });
        this.dashboardService.getAttachmentList(this.updateId).subscribe(
            r => {
                if (r && r.data) {
                    this.attachmentList = r.data;
                    this.isUserAdded = false;

                }
            },
            error => {
                this.isUserAdded = false;
                this.toastr.error('Error in getting Attachments !');
            }
        );
        this.comment = '';
        this.fileType = '';
        this.fileObj = null;

    }

    fileChange($event, type): void {
        this.uploadedFile = undefined;
        const reader = new FileReader();
        const file = $event.target.files[0];
        this.uploadedFile = file;
        const fileName = file && file.name;
        if (file && (file.size / 1024) < 2048) {
            this.fileObj = {
                added: new Date(),
                addedBy: 'string',
                desc: this.comment,
                docType: 'dd',
                empId: this.attacherId,
                fileName: fileName,
                size: file.size,
                url: ''
            };
        } else {
            this.fileError = 'Max size 2 MB';
            this.toastr.error('Max 2Mb File allowed.');
        }
    }

    saveAttachment() {
        this.isUserAdded = true;
        if (this.fileObj !== null) {
            this.dashboardService.uploadFile(this.uploadedFile).subscribe(resp => {
                if (resp && resp.data) {
                    this.fileObj['url'] = resp.data.filename;
                    this.fileObj['desc'] = this.comment;
                    this.dashboardService.addAttachment(this.fileObj).subscribe(
                        res => {
                            this.dashboardService.getAttachmentList(this.attacherId).subscribe(
                                r => {
                                    this.attachmentList = r.data;
                                    this.getAssest();
                                    this.toastr.success('Attachment Added');
                                    this.isUserAdded = false;
                                    this.closeModal();
                                },
                                error => {
                                    this.toastr.error('Failed to add Attachment');
                                    this.isUserAdded = false;
                                    this.closeModal();
                                }
                            );
                        },
                        error => {
                            this.toastr.error('Failed to add Attachment');
                            this.isUserAdded = false;
                            this.closeModal();
                        }
                    );
                }
            }, error => {
                const err = error.error && error.error.msg;
                console.log(err);
                this.isUserAdded = false;
                this.toastr.error('Failed to save Attachment', 'Upload Unsuccessful');
                this.closeModal();
            });
        } else {
            this.toastr.error('No file Selected');
            this.isUserAdded = false;
        }
    }

    onDeleteClicked(attachment: any) {
        const ID = attachment['id'];
        this.isUserAdded = true;
        this.dashboardService.deleteAttachment(ID).subscribe(
            res => {
                this.dashboardService.getAttachmentList(this.attacherId).subscribe(
                    r => {
                        this.attachmentList = r.data;
                        this.toastr.success('Deleted successfully.');
                        this.isUserAdded = false;
                    },
                    error => {
                        console.log(error);
                        this.isUserAdded = false;
                        this.toastr.error('Failed to delete');
                    }
                );
            },
            error => {
                console.log(error);
                this.isUserAdded = false;
                this.toastr.error('Failed to delete');
            }

        );
    }

}

