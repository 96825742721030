<mat-form-field class="form-element">
  <mat-select [formControl]="itemCtrl" placeholder="{{ placeholder }}" [multiple]="true" #multiSelect>
    <!-- place ngx-mat-select-search inside mat-option to allow opening without initial selection -->
    <mat-option>
      <ngx-mat-select-search
        [formControl]="itemFilteringCtrl"
        [searching]="searching"
        placeholderLabel="Search"
        noEntriesFoundLabel="'No matching item found'"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let item of filteredItems | async" [value]="item">
      {{ item.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
