import {AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { NotificationService } from "src/app/core/services/notification.service";
import {DashboardService} from '../../../dashboard.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';


@Component({
    selector: 'App-course-details',
    templateUrl: './course-details.component.html',
    styleUrls: ['./course-details.component.scss']
})
export class CourseDetailsComponent implements OnInit {


    public course: string;
    public courseForm: FormGroup;
    public isLoading: boolean = true;
    public filteredOptions: Array<any>;
    public correct = true;
    public employeeList = [];
    public temporaryDepartment: Array<any>;
    public currencyList: Array<any> = [];
    public err: string;
    id: any;

    public dialogRef: any;
    public isTrainerAdded = false;





    constructor(private service: DashboardService, private routeA: ActivatedRoute, private route: Router, public dialog: MatDialog, private modalService: NgbModal, private fb: FormBuilder, private toastr: NotificationService) {
        this.createForm();

        this.service.getEmployeeDetailList().subscribe(resp => {
            if (resp && resp.data) {
                resp.data.map(e => {
                    this.employeeList.push({label: e.id + ' ' + e.name, value: e.id});
                });
            }
        });

        this.routeA.params.subscribe(params => {
            this.id = params['id'];
            console.log("id", this.id);
        });
    }

    public locationList: Array<any>;

    public numberOfEmployees : Array<any> = [];
    public locationId = '';
    public subUnitId = '';





    public createForm(): void {
        this.courseForm = this.fb.group({
            title: ['', Validators.required],
            coordinatorid: ['', Validators.required],
            subUnitId: [''],
            version: [''],
            subVersion: [''],
            currencyName: [''],
            cost: [''],
            duration: [''],
            companyName: [''],
            description: [''],
            startDate: [''],
            endDate: [''],
            status: ['']
        });
    }

    getCourseById(): void {
        this.isLoading = true;
        if (this.id) {
            this.service.getCourseById(this.id).subscribe(res => {
                if (res && res.data) {
                    this.course = res.data?.title;
                    this.courseForm.patchValue(res.data);
                    this.courseForm.patchValue({status: res?.data?.archived ? 'archived' : 'active'});
                    this.courseForm.patchValue({version: `${res?.data?.version}`});
                    this.courseForm.patchValue({subVersion: `${res?.data?.subVersion}`});

                    this.isLoading = false;
                }
            }, error => {
                const _err = error.error && error.error.msg;
                this.isLoading = false;

                this.err = 'Error while getting Course details';
                this.toastr.error(_err || this.err);
            });
        } else {
            this.toastr.error('Error while getting Course details');
        }

    }

    ngOnInit() {
        this.getCourseById();

        this.service.getLocationList().subscribe(user => {
            if (user && user.data ) {
                this.locationList = user.data;
            }
        });

        this.service.getStructureList().subscribe(resp => {
            if (resp && resp.data) {
                this.temporaryDepartment = resp.data;
            }
        });

        this.service.getPayGradeCurrencyList().subscribe(user => {
            if (user && user.data && user.data.currency) {
                user.data.currency.map(value => {
                    this.currencyList.push(value);
                });
                console.log("data c:  ", this.currencyList);
            }
        });

        this.initValuesDetection();
    }

    public displayEmployeeByName(option) {

        if (!option) {
            return '';
        }
        this.correct = true;
        let data = this.employeeList.find(x => x.value === option);
        return data?.label;
    }

    initValuesDetection() {
        this.filteredOptions = this.employeeList;

        this.courseForm.controls['coordinatorid'].valueChanges.subscribe(change => {

            if (change == null || change == undefined) {
                this.correct = true;

            } else {

                this.filterEmployees(change);
            }

        });

    }

    protected filterEmployees(change: String) {
        this.filteredOptions = this.employeeList;
        if (!this.employeeList) {
            return;
        }

        // get the search keyword
        if (!change) {

            this.filteredOptions = this.employeeList;
            return;
        } else {
            this.checkEmployeeChange(change);
            change = change.toLowerCase();
        }
        // filter the search
        this.filteredOptions =
            this.employeeList.filter(emp => emp.label.toLowerCase().indexOf(change) > -1);

    }

    checkEmployeeChange(change) {
        let x = this.filteredOptions.find(x => x.value === change);
        if (!x) {
            this.correct = false;
        }

    }

    close() {
        this.route.navigate(['/training/courses']);
    }

    submitAddCourse() {

        const body = {
            id: this.id,
            ...this.courseForm.value,
            archived: this.courseForm.value?.status === 'archived'
        };
        delete body.status;

        this.isLoading = true;
        this.service.updateCourse(body).subscribe(res => {
            if (res && res.data) {
                this.isLoading = false;
                this.err = '';
                this.courseForm.reset();
                this.toastr.success('Course updated successfully');
                this.getCourseById();
            }
        }, error => {
            const _err = error.error && error.error.msg;
            this.isLoading = false;

            this.err = 'Error while updating Course details';
            this.toastr.error(_err || this.err);
        });
    }


}
