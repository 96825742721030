<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
    <div class="page-block ng-star-inserted">
      <div class="row form-group align-items-center">
        <div class="col-md-8">
          <div class="page-header-title">
            <span class="ng-star-inserted"></span
            ><span class="ng-star-inserted">
              <h4 class="m-b-10 ng-star-inserted">Apply Leave For Employee</h4>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-loading ></app-loading>
<div class="card" >
  <div class="card-content table-responsive table-full-width">
    <form class="form-material full-width-inputs" [formGroup]="leaveForm" action="javascript:">
      <div class="row form-group">
        <div class="col-md-4">
          <mat-form-field>
            <mat-label> Leave Type</mat-label>
            <mat-select
              required
              disableOptionCentering
              name="leave"
              formControlName="leavetypeid"
              class="form-control"
              (ngModelChange)="selectLeaveType($event)"
            >
              <mat-option value="">--Select--</mat-option>
              <mat-option tion *ngFor="let t of leaveTypeList" [value]="t.id">
                {{ t.leaveType }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-4">
          <mat-form-field class="form-element">
            <mat-label for="des">Date From/To</mat-label>
            <br />
            <mat-date-range-input required [rangePicker]="picker1" [min]="minDate" [max]="maxDate">
              <input required matStartDate formControlName="startdate" />
              <input required matEndDate formControlName="enddate" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-date-range-picker #picker1></mat-date-range-picker>
          </mat-form-field>
        </div>
        </div>

      <div class="row form-group">
        <div class="col-md-4 flex-checkbox" *ngIf="this.leavetypeid != '' && showBalance">
          <!-- <button mat-raised-button class="check-balance" (click)="checkLeaveBalance()"> </button> 
          <a (click)="checkLeaveBalance()" class="check-balance cursor-pointer">
            Check Leave Balance</a>
          -->
          <span>
            Balance(days) <b>{{ this.checkbalanceArr.noOfDays }}</b>
          </span>
        </div>

        <!-- <div class="col-md-6">
            <mat-form-field class="form-element">
              <mat-label>Start Date</mat-label>
              <input   (dateChange)="checkStartDate()" required [ngModelOptions]="{standalone: true}" name="startDate" [matDatepicker]="picker1" class="form-control"
                  [(ngModel)]="startdate" id="dob" matInput>
              <mat-datepicker-toggle [for]="picker1"matSuffix></mat-datepicker-toggle>
              <mat-datepicker [touchUi]="true" #picker1></mat-datepicker>
            </mat-form-field>
            <mat-error  *ngIf="checkDate">Start date must Be before End date</mat-error>
        </div> -->

        <!-- <div class="col-md-6">
          <mat-form-field class="form-element">
            <input (dateChange)="checkEndDate()" required [ngModelOptions]="{standalone: true}"  name="endDate" id="dob" class="form-control" [matDatepicker]="picker2" [attr.min]="this.startdate"
            [min]="this.startdate" 
            placeholder="End Date" (dateChange)="dateDiff()"  [(ngModel)]="enddate"
                 matInput>
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker [touchUi]="true" #picker2></mat-datepicker>
            <mat-label for="dob">End Date</mat-label>
          </mat-form-field>
          <mat-error *ngIf="checkDate">
            End date must Be after Start date
         </mat-error>
      </div> -->
      </div>

      <!-- <div class="row form-group">
        <div class="col-md-4" *ngIf="leaveForm.value.startdate && leaveForm.value.enddate &&
            (leaveForm.value.startdate | date:'dd/MM/yyyy') != (leaveForm.value.enddate  | date:'dd/MM/yyyy') ">
          <mat-form-field class="form-element">
            <mat-label>Partial Days</mat-label>
            <mat-select (ngModelChange)="this.setDefaultValues()" disableOptionCentering name="partial"
              [ngModelOptions]="{standalone: true}" [(ngModel)]="partialDays" class="form-control">
              <mat-option [value]="'none'">None</mat-option>
              <mat-option [value]="'all'">All Days</mat-option>
              <mat-option [value]="'st'">Start Day</mat-option>
              <mat-option [value]="'en'">End Day</mat-option>
              <mat-option [value]="'sten'">Start and End Day</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div> -->

      <div
        class="row form-group"
        *ngIf="
          leaveForm.value.startdate &&
          leaveForm.value.enddate &&
          (leaveForm.value.startdate | date : 'dd/MM/yyyy') === (leaveForm.value.enddate | date : 'dd/MM/yyyy')
        "
      >
        <div class="col-md-2">
          <mat-form-field>
            <mat-label>Duration</mat-label>
            <mat-select
              disableOptionCentering
              name="duration1"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="durationsOne"
              class="form-control"
            >
              <mat-option [value]="'fullDay'">Full Day</mat-option>
              <!-- <mat-option [value]="'halfDay'">Half Day</mat-option> -->
              <mat-option [value]="'SpecificTime'">Specific Time</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-4" *ngIf="this.durationsOne === 'halfDay'">
          <mat-form-field>
            <mat-label></mat-label>
            <mat-select
              name="type"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="durationsTypedayOne"
              class="form-control"
            >
              <mat-option [value]="'morning'">Morning</mat-option>
              <mat-option [value]="'evening'">Evening</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-2" *ngIf="this.durationsOne === 'SpecificTime'">
          <mat-form-field class="form-element">
            <mat-label>From</mat-label>
            <input
              (ngModelChange)="calcDurationStartOne($event)"
              [ngModelOptions]="{ standalone: true }"
              name="from"
              matInput
              [ngxTimepicker]="picker3"
              id="from"
              [(ngModel)]="startTimeOne"
              [format]="24"
              class="form-control"
            />
          </mat-form-field>
          <ngx-material-timepicker #picker3></ngx-material-timepicker>
          <mat-error *ngIf="checkTime">From time should be before to time</mat-error>
        </div>

        <div class="col-md-2" *ngIf="this.durationsOne === 'SpecificTime'">
          <mat-form-field class="form-element">
            <mat-label>to</mat-label>
            <input
              (ngModelChange)="calcDurationEndOne($event)"
              [ngModelOptions]="{ standalone: true }"
              name="to"
              matInput
              [ngxTimepicker]="picker4"
              id="to"
              [(ngModel)]="endTimeOne"
              [format]="24"
              class="form-control"
              [min]="this.startTimeOne"
            />
          </mat-form-field>
          <ngx-material-timepicker #picker4></ngx-material-timepicker>
          <mat-error *ngIf="checkTime">To time should be after from time</mat-error>
        </div>

        <div class="col-md-2" *ngIf="this.durationsOne === 'SpecificTime'">
          <mat-form-field class="form-element">
            <mat-label>Duration</mat-label>
            <input
              [readonly]="true"
              [ngModelOptions]="{ standalone: true }"
              name="duration"
              [(ngModel)]="this.durationOne"
              matInput
              class="duration"
            />
          </mat-form-field>
        </div>
      </div>

      <div
        class="row form-group"
        *ngIf="
          leaveForm.value.startdate &&
          leaveForm.value.enddate &&
          this.partialDays === 'all' &&
          (leaveForm.value.startdate | date : 'dd/MM/yyyy') != (leaveForm.value.enddate | date : 'dd/MM/yyyy')
        "
      >
        <!--
          <div class="col-md-3">
          <mat-form-field>
            <mat-label>Duration</mat-label>
            <mat-select disableOptionCentering name="duration1" [ngModelOptions]="{standalone: true}"
              [(ngModel)]="durations" class="form-control">
              <mat-option [value]="'SpecificTime'">Specific Time</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        -->
        <div class="col-md-4" *ngIf="this.durations === 'halfDay'">
          <mat-form-field>
            <mat-label></mat-label>
            <mat-select
              name="type"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="durationsTypeday"
              class="form-control"
            >
              <mat-option [value]="'morning'">Morning</mat-option>
              <mat-option [value]="'evening'">Evening</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-2" *ngIf="this.durations === 'SpecificTime'">
          <mat-form-field class="form-element">
            <mat-label>From</mat-label>
            <input
              (ngModelChange)="calcDurationStart($event)"
              [ngModelOptions]="{ standalone: true }"
              name="from"
              matInput
              [ngxTimepicker]="picker3"
              id="from"
              [(ngModel)]="startTime"
              [format]="24"
              class="form-control"
            />
          </mat-form-field>
          <ngx-material-timepicker #picker3></ngx-material-timepicker>
          <mat-error *ngIf="checkTime">From time should be before to time</mat-error>
        </div>

        <div class="col-md-2" *ngIf="this.durations === 'SpecificTime'">
          <mat-form-field class="form-element">
            <mat-label>to</mat-label>
            <input
              (ngModelChange)="calcDurationEnd($event)"
              [ngModelOptions]="{ standalone: true }"
              name="to"
              matInput
              [ngxTimepicker]="picker4"
              id="to"
              [(ngModel)]="endTime"
              [format]="24"
              class="form-control"
              [min]="this.startTime"
            />
          </mat-form-field>
          <ngx-material-timepicker #picker4></ngx-material-timepicker>
          <mat-error *ngIf="checkTime">To time should be after from time</mat-error>
        </div>

        <div class="col-md-2" *ngIf="this.durations === 'SpecificTime'">
          <mat-form-field class="form-element">
            <mat-label>Duration</mat-label>
            <input
              [readonly]="true"
              [ngModelOptions]="{ standalone: true }"
              name="duration"
              [(ngModel)]="this.duration"
              matInput
              class="duration"
            />
          </mat-form-field>
        </div>
      </div>

      <div
        class="row form-group"
        *ngIf="
          leaveForm.value.startdate &&
          leaveForm.value.enddate &&
          this.partialDays === 'st' &&
          (leaveForm.value.startdate | date : 'dd/MM/yyyy') != (leaveForm.value.enddate | date : 'dd/MM/yyyy')
        "
      >
        <!--
         <div class="col-md-3">
          <mat-form-field>
            <mat-label>ٍStart Day</mat-label>
            <mat-select disableOptionCentering name="duration2" [ngModelOptions]="{standalone: true}"
              [(ngModel)]="durations" class="form-control">
              <mat-option [value]="'SpecificTime'">Specific Time</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        -->
        <div class="col-md-4" *ngIf="this.durations === 'halfDay'">
          <mat-form-field>
            <mat-label></mat-label>
            <mat-select
              disableOptionCentering
              name="type"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="durationsTypeday"
              class="form-control"
            >
              <mat-option [value]="'morning'">Morning</mat-option>
              <mat-option [value]="'evening'">Evening</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-2" *ngIf="this.durations === 'SpecificTime'">
          <mat-form-field class="form-element">
            <mat-label>From</mat-label>
            <input
              (ngModelChange)="calcDurationStart($event)"
              [ngModelOptions]="{ standalone: true }"
              name="from"
              matInput
              [ngxTimepicker]="picker5"
              id="from"
              [(ngModel)]="startTime"
              [format]="24"
              class="form-control"
            />
          </mat-form-field>
          <ngx-material-timepicker #picker5></ngx-material-timepicker>
          <mat-error *ngIf="checkTime">From time should be before to time</mat-error>
        </div>
        <div class="col-md-2" *ngIf="this.durations === 'SpecificTime'">
          <mat-form-field class="form-element">
            <mat-label>to</mat-label>
            <input
              (ngModelChange)="calcDurationEnd($event)"
              [ngModelOptions]="{ standalone: true }"
              name="to"
              matInput
              [ngxTimepicker]="picker6"
              id="to"
              [(ngModel)]="endTime"
              [format]="24"
              class="form-control"
              [min]="this.startTime"
            />
          </mat-form-field>
          <ngx-material-timepicker #picker6></ngx-material-timepicker>
          <mat-error *ngIf="checkTime">To time should be after from time</mat-error>
        </div>
        <div class="col-md-2" *ngIf="this.durations === 'SpecificTime'">
          <mat-form-field class="form-element">
            <mat-label>Duration</mat-label>
            <input
              [readonly]="true"
              [ngModelOptions]="{ standalone: true }"
              name="duration"
              [(ngModel)]="this.duration"
              matInput
              class="duration"
            />
          </mat-form-field>
        </div>
      </div>
      <div
        class="row form-group"
        *ngIf="
          leaveForm.value.startdate &&
          leaveForm.value.enddate &&
          this.partialDays === 'en' &&
          (leaveForm.value.startdate | date : 'dd/MM/yyyy') != (leaveForm.value.enddate | date : 'dd/MM/yyyy')
        "
      >
        <!--
          <div class="col-md-3">
          <mat-form-field>
            <mat-label>End Day</mat-label>
            <mat-select disableOptionCentering name="duration3" [ngModelOptions]="{standalone: true}"
              [(ngModel)]="durations" class="form-control">
              <mat-option [value]="'SpecificTime'">Specific Time</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        -->
        <div class="col-md-4" *ngIf="this.durations === 'halfDay'">
          <mat-form-field>
            <mat-label></mat-label>
            <mat-select
              disableOptionCentering
              name="type"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="durationsTypeday"
              class="form-control"
            >
              <mat-option [value]="'morning'">Morning</mat-option>
              <mat-option [value]="'evening'">Evening</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-2" *ngIf="this.durations === 'SpecificTime'">
          <mat-form-field class="form-element">
            <mat-label>From</mat-label>
            <input
              (ngModelChange)="calcDurationStart($event)"
              [ngModelOptions]="{ standalone: true }"
              name="from"
              matInput
              [ngxTimepicker]="picker7"
              id="from"
              [(ngModel)]="startTime"
              [format]="24"
              class="form-control"
            />
          </mat-form-field>
          <ngx-material-timepicker #picker7></ngx-material-timepicker>
          <mat-error *ngIf="checkTime">From time should be before to time</mat-error>
        </div>
        <div class="col-md-2" *ngIf="this.durations === 'SpecificTime'">
          <mat-form-field class="form-element">
            <mat-label>to</mat-label>
            <input
              (ngModelChange)="calcDurationEnd($event)"
              [ngModelOptions]="{ standalone: true }"
              name="to"
              matInput
              [ngxTimepicker]="picker8"
              id="to"
              [(ngModel)]="endTime"
              [format]="24"
              class="form-control"
              [min]="this.startTime"
            />
          </mat-form-field>
          <ngx-material-timepicker #picker8></ngx-material-timepicker>
          <mat-error *ngIf="checkTime">To time should be after from time</mat-error>
        </div>
        <div class="col-md-2" *ngIf="this.durations === 'SpecificTime'">
          <mat-form-field class="form-element">
            <mat-label>Duration</mat-label>
            <input
              [readonly]="true"
              [ngModelOptions]="{ standalone: true }"
              name="duration"
              [(ngModel)]="this.duration"
              matInput
              class="duration"
            />
          </mat-form-field>
        </div>
      </div>
      <div
        class="row form-group"
        *ngIf="
          leaveForm.value.startdate &&
          leaveForm.value.enddate &&
          this.partialDays === 'sten' &&
          (leaveForm.value.startdate | date : 'dd/MM/yyyy') != (leaveForm.value.enddate | date : 'dd/MM/yyyy')
        "
      >
        <!--
         <div class="col-md-3">
          <mat-form-field>
            <mat-label>Start Day</mat-label>
            <mat-select disableOptionCentering name="duration1" [ngModelOptions]="{standalone: true}"
              [(ngModel)]="durations" class="form-control">
              <mat-option [value]="'SpecificTime'">Specific Time</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      -->
        <div class="col-md-4" *ngIf="this.durations === 'halfDay'">
          <mat-form-field>
            <mat-label></mat-label>
            <mat-select
              disableOptionCentering
              name="type"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="durationsTypeday"
              class="form-control"
            >
              <mat-option [value]="'morning'">Morning</mat-option>
              <mat-option [value]="'evening'">Evening</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-2" *ngIf="this.durations === 'SpecificTime'">
          <mat-form-field class="form-element">
            <mat-label>Start day from</mat-label>
            <input
              (ngModelChange)="calcDurationStart($event)"
              [ngModelOptions]="{ standalone: true }"
              name="from"
              matInput
              [ngxTimepicker]="picker9"
              id="from"
              [(ngModel)]="startTime"
              [format]="24"
              class="form-control"
            />
          </mat-form-field>

          <ngx-material-timepicker #picker9></ngx-material-timepicker>
          <mat-error *ngIf="checkTime">From time should be before to time</mat-error>
        </div>

        <div class="col-md-2" *ngIf="this.durations === 'SpecificTime'">
          <mat-form-field class="form-element">
            <mat-label>to</mat-label>
            <input
              (ngModelChange)="calcDurationEnd($event)"
              [ngModelOptions]="{ standalone: true }"
              name="to"
              matInput
              [ngxTimepicker]="picker10"
              id="to"
              [(ngModel)]="endTime"
              [format]="24"
              class="form-control"
              [min]="this.startTime"
            />
          </mat-form-field>
          <ngx-material-timepicker #picker10></ngx-material-timepicker>
          <mat-error *ngIf="checkTime">To time should be after from time</mat-error>
        </div>
        <div class="col-md-2" *ngIf="this.durations === 'SpecificTime'">
          <mat-form-field class="form-element">
            <mat-label>Duration</mat-label>
            <input
              [readonly]="true"
              [ngModelOptions]="{ standalone: true }"
              name="duration"
              [(ngModel)]="this.duration"
              matInput
              class="duration"
            />
          </mat-form-field>
        </div>
      </div>

      <div
        class="row form-group"
        *ngIf="
          leaveForm.value.startdate &&
          leaveForm.value.enddate &&
          this.partialDays === 'sten' &&
          (leaveForm.value.startdate | date : 'dd/MM/yyyy') != (leaveForm.value.enddate | date : 'dd/MM/yyyy')
        "
      >
        <!--
          <div class="col-md-3">
          <mat-form-field>
            <mat-label>End Day</mat-label>
            <mat-select disableOptionCentering name="duration1" [ngModelOptions]="{standalone: true}"
              [(ngModel)]="durations2" class="form-control">
              <mat-option [value]="'SpecificTime'">Specific Time</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        -->
        <div class="col-md-4" *ngIf="this.durations2 === 'halfDay'">
          <mat-form-field>
            <mat-label></mat-label>
            <mat-select
              disableOptionCentering
              name="type"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="durationsTypeday2"
              class="form-control"
            >
              <mat-option [value]="'morning'">Morning</mat-option>
              <mat-option [value]="'evening'">Evening</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-2" *ngIf="this.durations2 === 'SpecificTime'">
          <mat-form-field class="form-element">
            <mat-label>End day from</mat-label>
            <input
              (ngModelChange)="calcDurationStart2($event)"
              [ngModelOptions]="{ standalone: true }"
              name="from"
              matInput
              [ngxTimepicker]="picker9"
              id="from"
              [(ngModel)]="startTime2"
              [format]="24"
              class="form-control"
            />
          </mat-form-field>
          <ngx-material-timepicker #picker9></ngx-material-timepicker>
          <mat-error *ngIf="checkTime2">From time should be before to time</mat-error>
        </div>

        <div class="col-md-2" *ngIf="this.durations2 === 'SpecificTime'">
          <mat-form-field class="form-element">
            <mat-label>to</mat-label>
            <input
              (ngModelChange)="calcDurationEnd2($event)"
              [ngModelOptions]="{ standalone: true }"
              name="to"
              matInput
              [ngxTimepicker]="picker10"
              id="to"
              [(ngModel)]="endTime2"
              [format]="24"
              class="form-control"
              [min]="this.startTime2"
            />
          </mat-form-field>
          <ngx-material-timepicker #picker10></ngx-material-timepicker>
          <mat-error *ngIf="checkTime2">To time should be after from time</mat-error>
        </div>

        <div class="col-md-2" *ngIf="this.durations === 'SpecificTime'">
          <mat-form-field class="form-element">
            <mat-label>Duration</mat-label>
            <input
              [readonly]="true"
              [ngModelOptions]="{ standalone: true }"
              name="duration"
              [(ngModel)]="this.duration2"
              matInput
              class="duration"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="row form-group" *ngIf="this.leavetypeid">
        <div class="col-md-8">
          <mat-form-field>
            <mat-label>Leave a comment</mat-label>
            <textarea
              [ngModelOptions]="{ standalone: true }"
              name="comment"
              matInput
              [(ngModel)]="leavecomments"
              class="form-control"
            >
            </textarea>
          </mat-form-field>
        </div>
      </div>

      <div class="row form-group" *ngIf="empNomineeEnabled && this.leavetypeid != ''">
        <div class="col-md-4">
          <app-lazy-employee-dropdown
            title="Employee"
            required="false"
            [selectedEmployee]="nominatedempid1"
            (employeeSelected)="updateNominatedempid1($event)"
          ></app-lazy-employee-dropdown>
        </div>

        <div class="col-md-4">
          <mat-form-field>
            <mat-label>Note</mat-label>
            <input
              [readonly]="nominatedempid1 === ''"
              [ngModelOptions]="{ standalone: true }"
              matInput
              name="note"
              [(ngModel)]="comment1"
              [readonly]="this.nominatedempid1 === ''"
              class="form-control"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="row form-group" *ngIf="empNomineeEnabled && this.leavetypeid != ''">
        <div class="col-md-4">
          <app-lazy-employee-dropdown
            title="Employee"
            required="false"
            [selectedEmployee]="nominatedempid2"
            (employeeSelected)="updateNominatedempid2($event)"
          ></app-lazy-employee-dropdown>
        </div>

        <div class="col-md-4">
          <mat-form-field>
            <mat-label>Note</mat-label>
            <input
              [readonly]="nominatedempid2 === ''"
              [ngModelOptions]="{ standalone: true }"
              matInput
              name="note2"
              [(ngModel)]="comment2"
              [readonly]="this.nominatedempid2 === ''"
              class="form-control"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="row form-group" *ngIf="empNomineeEnabled && this.leavetypeid != ''">
        <div class="col-md-4">
          <app-lazy-employee-dropdown
            title="Employee"
            required="false"
            [selectedEmployee]="nominatedempid3"
            (employeeSelected)="updateNominatedempid3($event)"
          ></app-lazy-employee-dropdown>
        </div>

        <div class="col-md-4">
          <mat-form-field>
            <mat-label>Note</mat-label>
            <input
              [readonly]="nominatedempid3 === ''"
              [ngModelOptions]="{ standalone: true }"
              matInput
              name="note3"
              [(ngModel)]="comment3"
              [readonly]="this.nominatedempid3 === ''"
              class="form-control"
            />
          </mat-form-field>
        </div>
      </div>

      <br /><br /><br />
      <div class="row form-group" [hidden]="!write">
        <div class="col-md-12 text-right">
          <button
            mat-raised-button
            class="btn-primary"
            (click)="submitLeave()"
            color="primary"
            [class.spinner]="submitLoading"
            [disabled]="
              this.leavetypeid === '' ||
              leaveForm.value.startdate === '' ||
              leaveForm.value.startdate === null ||
              leaveForm.value.enddate === '' ||
              leaveForm.value.enddate === null ||
              submitLoading
            "
          >
            <i class="flaticon-diskette"></i>Save
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<app-loading [isLoading]="!employeeList && employeeList.length > 0 && employeeList && !employeeListErr"></app-loading>

<ng-template #leaveBalance let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">Leave Balance</h4>
    </div>
    <div class="mat-modal-body">
      <mat-card-content>
        <div class="dt-responsive" *ngIf="dataSource">
          <div class="table-responsive">
            <mat-table [dataSource]="dataSource" matSort>
              <ng-container matColumnDef="noOfDays">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Balance (Days)</mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{ row.noOfDays }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="startDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>From</mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{ row.startDate | date : "dd-MM-yyyy" }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="endDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>To</mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{ row.endDate | date : "dd-MM-yyyy" }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="note">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Note</mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{ row.note }}
                </mat-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: columns"> </mat-row>
            </mat-table>
            <mat-progress-bar *ngIf="balanceLoading" mode="indeterminate"></mat-progress-bar>
          </div>
        </div>
        <br />
        <div class="row my-2 mx-2">
          <div class="action-buttons multi-btns col-sm-12 text-right">
            <button mat-raised-button class="btn-danger" (click)="closeModal()">
              <i class="flaticon-cancel"></i>Close
            </button>
            <!--<button mat-raised-button class="btn-danger" (click)="onPrev()"><i class="flaticon-left-arrow"></i>Previous</button>
                <button mat-raised-button class="btn-danger" (click)="onNext()"><i class="flaticon-right-arrow"></i>Next</button>-->
          </div>
        </div>
      </mat-card-content>
    </div>
  </div>
</ng-template>

<ng-template #leaveOverlapp let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">Overlapping Leave Request Found</h4>
    </div>
    <div class="mat-modal-body">
      <mat-card-content>
        <div class="dt-responsive">
          <div class="table-responsive">
            <mat-table [dataSource]="overSource" matSort>
              <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{ row.date | date : "yyyy-MM-dd" }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="duration">
                <mat-header-cell *matHeaderCellDef mat-sort-header>No of Hours</mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{ row.totalHrsDuration }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="leaveType">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Leave Type</mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{ row.leaveType }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{ row.status }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="comments">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Comments</mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{ row.comments }}
                </mat-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="overCloumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: overCloumns"> </mat-row>
            </mat-table>
          </div>
        </div>
        <br />
      </mat-card-content>

      <div class="row my-2 mx-2">
        <div class="action-buttons multi-btns col-sm-12 text-right">
          <button mat-raised-button class="btn-danger" (click)="closeModal()">
            <i class="flaticon-cancel"></i>Close
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #balanceDialog let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">Insufficient Balance</h4>
    </div>
    <div class="mat-modal-body">
      <div class="text-left are-u">
        The leave request includes {{ this.totalDaysDuration }} days of leave<br />Available balance for this period ({{
          this.balance
        }}) is not sufficient<br />Click OK to confirm leave request<br />
      </div>
      <div class="row">
        <div class="col-sm-12 text-right">
          <button mat-raised-button class="btn-danger" (click)="closeModal()">Close</button>
          <button mat-raised-button class="btn-primary" color="primary" (click)="submitBalanceLeave()">Ok</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #noentitlementDialog let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">No Entitlement</h4>
    </div>
    <div class="mat-modal-body">
      <div class="text-left are-u">
        The employee doesn't have entitlement for this leave type {{ this.leavetype.leaveType }}
      </div>
      <div class="row">
        <div class="col-sm-12 text-right">
          <button mat-raised-button class="btn-danger" (click)="closeModal()">Close</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
