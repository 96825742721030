<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
    <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="page-header-title">
            <span class="ng-star-inserted"></span><span class="ng-star-inserted">
              <h4 class="m-b-10 ng-star-inserted">Punch In/Out</h4>
            </span>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</div>

<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<app-card>
  <div *ngIf="canPunch || isAdmin">
    <form class="form-material full-width-inputs" [formGroup]="addForm">
      <div class="row form-group">
        <div class="col-sm-4">
          <mat-form-field class="form-element">
            <mat-label>{{ lang.get("location") }}</mat-label>
            <mat-select disableOptionCentering class="form-control" formControlName="locationId"
              [disabled]="status.lastCheckedIn && !status.lastCheckedOut">
              <mat-option value="">--Select--</mat-option>
              <mat-option *ngFor="let item of locations" [value]="item.id">
                {{ item.location }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-sm-2 action-buttons multi-btns" style="margin-top: 1.2em">
          <button mat-raised-button class="btn btn-primary" color="primary" (click)="save()" [disabled]="isLoading">
            <i class="flaticon-diskette"></i>
            {{ status.btnLabel }}
          </button>
        </div>
        <div class="col-sm-4" style="margin-top: 1.5em">
          <p id="message">{{ status.message }}</p>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-sm-5">
          <mat-form-field>
            <mat-label>{{ lang.get("comments") }}</mat-label>
            <textarea matInput placeholder="Comment" formControlName="comments"> </textarea>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="!canPunch && !isAdmin">
    <h6 style="color: red;">Only users with Admin role can CheckIn/CheckOut by web, please use the mobile App to CheckIn/CheckOut</h6>
  </div>
</app-card>

<div class="example-container mat-elevation-z8">
  <app-card title="Today Total duration {{ formatedTotalDuration }}">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ lang.get("type") }}</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row.type }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="time">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ lang.get("time") }}</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ displayTimeinOrginalTimezone(row.datetime, row.timezone) | date : "HH:mm" }} {{ row.timezone }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="by">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ lang.get("recordBy") }}</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.recordBy }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="location">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ lang.get("location") }}</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.location }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="duration">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ lang.get("duration") }}</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.type == "In" ? "-" : row.formatedDuration }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="comments">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ lang.get("comments") }}</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.comments }}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row [class]="'row' + row.typ" *matRowDef="let row; columns: displayedColumns"> </mat-row>
    </mat-table>
  </app-card>
</div>