<!-- <app-loading [isLoading]="this.isLoadingSw"></app-loading> -->
<!--<div [hidden]="isLoadingSw" class="ng-star-inserted header-page" id="header-page">-->
<div class="ng-star-inserted header-page" id="header-page">
  <div class="page-header ng-star-inserted">
    <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="page-header-title">
            <span class="ng-star-inserted"></span
            ><span class="ng-star-inserted">
              <h4 [hidden]="!this.isLoading" class="m-b-10 ng-star-inserted cus-flex-tools">
                <span *ngIf="this.editId != ''"> </span>
              </h4>

              <h4 [hidden]="this.isLoading" class="m-b-10 ng-star-inserted cus-flex-tools">
                <span *ngIf="this.editId != ''">
                  <img
                    *ngIf="this.employeeImage"
                    class="img-p"
                    (click)="this.update ? openUploadDialog() : doNothing()"
                    style="cursor: pointer"
                    src="{{ this.employeeImage }}"
                  />
                  <img
                    *ngIf="!this.employeeImage"
                    src="../../../../assets/images/user-avatar.jpg"
                    class="img-p"
                    (click)="this.update ? openUploadDialog() : doNothing()"
                    style="cursor: pointer"
                  />
                  {{ this.employeeName + " " + "(" + this.editId + ")" }}
                </span>
                <ng-container *ngIf="this.user.userRole === 'Admin' && showFind">
                  <span
                    (click)="openSearch()"
                    #search
                    class="header-edit cursor-pointer"
                    [mdePopoverTriggerFor]="appPopover"
                    mdePopoverTriggerOn="click"
                    #popoverTrigger="mdePopoverTrigger"
                  >
                    <mat-icon aria-hidden="false">find_replace</mat-icon>
                  </span>
                  <!-- <mde-popover #appPopover="mdePopover" [mdePopoverCloseOnClick]="false"
                                   
                                [mdePopoverOverlapTrigger]="false"> -->
                  <div class="cardimg" *ngIf="this.closeSearch">
                    <mdb-card>
                      <mdb-card-body>
                        <span class="employee-change">
                          <mat-form-field>
                            <mat-label style="color: #0e2a47"> Find Employee </mat-label>
                            <input
                              type="text"
                              [formControl]="userFilterCtrl"
                              [matAutocomplete]="auto5"
                              aria-label="Employee"
                              matInput
                              placeholder="Type for hints .."
                              style="color: #0e2a47"
                            />
                            <mat-autocomplete #auto5="matAutocomplete" [displayWith]="displayEmployeeByName.bind(this)">
                              <ng-container style="z-index: 100">
                                <mat-option *ngFor="let r of filteredOptions" [value]="r.value">
                                  {{ r.label }}
                                </mat-option>
                              </ng-container>
                            </mat-autocomplete>
                          </mat-form-field>
                        </span>
                      </mdb-card-body>
                    </mdb-card>
                  </div>

                  <!-- </mde-popover> -->
                </ng-container>
              </h4>
            </span>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</div>

<div [style.visibility]="!isLoadingSw ? 'visible' : 'hidden'" class="no-title editemployee-overall">
  <div class="row">
    <div class="col-sm-12">
      <app-card [title]="this.employeeName">
        <div class="row">
          <div class="col-md-12">
            <div class="tablist-collection md-tabs">
              <mat-tab-group dynamicHeight #demo3Tab animationDuration="0ms" #slider>
                <mat-tab>
                  <ng-template mat-tab-label>
                    <div class="flex-label iconic" (click)="test(1, '')">
                      <span class="flaticon-contact-information"></span>
                      Personal Detail
                    </div>
                  </ng-template>
                  <ng-template matTabContent>
                    <br />
                    <br />
                    <div class="card">
                      <app-personal [employeeId]="editId"></app-personal>
                    </div>
                  </ng-template>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    <div class="flex-label iconic" (click)="test(0, 'jo'); checkConfirmation()">
                      <span class="flaticon-suitcase"></span>
                      Job
                    </div>
                  </ng-template>
                  <ng-template matTabContent>
                    <br />
                    <br />
                    <div class="card">
                      <app-job [employeeId]="editId"></app-job>
                    </div>
                  </ng-template>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    <div class="flex-label iconic" (click)="test(0, 'sa'); checkConfirmation()">
                      <span class="flaticon-salary-1"></span>
                      Salary
                    </div>
                  </ng-template>
                  <ng-template matTabContent>
                    <br />
                    <br />
                    <div class="card">
                      <app-salary-info [employeeId]="editId"></app-salary-info>
                    </div>
                  </ng-template>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    <div class="flex-label iconic" (click)="test(1, '')">
                      <span class="flaticon-diploma-1"></span> Qualification
                    </div>
                  </ng-template>
                  <ng-template matTabContent>
                    <br />
                    <br />
                    <app-qualification [employeeId]="editId"></app-qualification>
                  </ng-template>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    <div class="flex-label iconic" (click)="test(1, '')">
                      <span class="flaticon-communicate"></span> Contact Details
                    </div>
                  </ng-template>
                  <ng-template matTabContent>
                    <br />
                    <br />
                    <div class="card">
                      <app-contact [employeeId]="editId"></app-contact>
                    </div>
                  </ng-template>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    <div class="flex-label iconic" (click)="test(1, '')">
                      <span class="flaticon-social-media"></span> Social Media Details
                    </div>
                  </ng-template>
                  <ng-template matTabContent>
                    <br />
                    <br />
                    <div class="card">
                      <app-social-media [employeeId]="editId"></app-social-media>
                    </div>
                  </ng-template>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    <div class="flex-label iconic" (click)="test(1, '')">
                      <span class="flaticon-contact"></span> Emergency Contacts
                    </div>
                  </ng-template>
                  <ng-template matTabContent>
                    <br />
                    <br />
                    <div class="card">
                      <app-emergency-contact [employeeId]="editId"></app-emergency-contact>
                    </div>
                  </ng-template>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    <div class="flex-label iconic" (click)="test(1, '')">
                      <span class="flaticon-family"></span> Dependents
                    </div>
                  </ng-template>
                  <ng-template matTabContent>
                    <br />
                    <br />
                    <div class="card">
                      <app-dependent [employeeId]="editId"></app-dependent>
                    </div>
                  </ng-template>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    <div class="flex-label iconic" (click)="test(1, '')">
                      <span class="flex-label iconic" class="flaticon-destination"></span>
                      Immigration
                    </div>
                  </ng-template>
                  <ng-template matTabContent>
                    <br />
                    <br />
                    <div class="card">
                      <app-immigration [employeeId]="editId"></app-immigration>
                    </div>
                  </ng-template>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    <div class="flex-label iconic" (click)="test(1, '')">
                      <span class="flex-label iconic" class="flaticon-profiles"></span> Report To
                    </div>
                  </ng-template>
                  <ng-template matTabContent>
                    <br />
                    <div class="card">
                      <app-report-to [employeeId]="editId"></app-report-to>
                    </div>
                  </ng-template>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    <div class="flex-label iconic" (click)="test(1, '')">
                      <span class="flaticon-salary-1"></span> Bank Info
                    </div>
                  </ng-template>
                  <ng-template matTabContent>
                    <br />
                    <br />
                    <div class="card">
                      <app-direct-deposit [employeeId]="editId"></app-direct-deposit>
                    </div>
                  </ng-template>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    <div class="flex-label iconic" (click)="test(1, '')">
                      <span class="flaticon-calendar"></span> Work week
                    </div>
                  </ng-template>
                  <ng-template matTabContent>
                    <div class="card">
                      <app-work-week [employeeId]="editId"></app-work-week>
                    </div>
                  </ng-template>
                </mat-tab>
              </mat-tab-group>
            </div>
          </div>
        </div>
      </app-card>
    </div>
  </div>
</div>

<ng-template #attach let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">Attachment</h4>
    </div>
    <div class="mat-modal-body">
      <!-- <div class="card-content"> -->
      <form class="form-material full-width-inputs">
        <div class="row form-group">
          <div class="col-sm-12">
            <div class="upload-btn-wrapper">
              <button class="upload-btn">Attach</button>
              <input
                (change)="fileChange($event, 'dd')"
                [(ngModel)]="fileClean"
                class="form-control"
                name="myfile"
                type="file"
              />
              <div class="fileName">
                {{ this.fileObj && this.fileObj.fileName }}<i *ngIf="fileUrl" class="fa fa-check success"></i>
              </div>
            </div>
            <div class="upload-btn-wrapper">
              <!-- <div class="fileUrl" *ngIf="fileUrl">
                            <button class="upload-btn">Replace</button>
                            <input type="file" name="myfile" class="form-control" (change)="fileReplaceChange($event, 'dd')" [(ngModel)]="fileClean">
                            <div class="fileName">{{fileName}}<i *ngIf="fileUrl" class="fa fa-check success"></i></div>
                            </div> -->
            </div>
            <div *ngIf="!fileUrl && fileError" class="file-error">{{ fileError }}</div>
            <div class="help-text">Accept upto 2MB</div>
          </div>
          <!--                    <div class="col-sm-12 mt-4">-->
          <!--                        <mat-form-field class="form-element">-->
          <!--                            <mat-select [(ngModel)]="fileType" class="form-control" name="fileType">-->
          <!--                                <ng-container>-->
          <!--                                    <mat-option value="dd">Dependent Attachment</mat-option>-->
          <!--                                    <mat-option value="cd">Contact Details Attachment</mat-option>-->
          <!--                                    <mat-option value="ec">Emergency Contacts Attachment</mat-option>-->
          <!--                                    <mat-option value="ai">"Assigned Immigration Records Attachment</mat-option>-->
          <!--                                    <mat-option value="rt">Report To Attachment</mat-option>-->
          <!--                                    <mat-option value="qu">Qualifications Attachment</mat-option>-->
          <!--                                    <mat-option value="ma">Memberships Attachment</mat-option>-->
          <!--                                </ng-container>-->
          <!--                            </mat-select>-->
          <!--                            <mat-label for="des">File Type</mat-label>-->
          <!--                        </mat-form-field>-->
          <!--                    </div>-->
          <div class="col-md-12">
            <mat-form-field class="form-element">
              <mat-label>File Name</mat-label>
              <input
                [(ngModel)]="fileName"
                [ngModelOptions]="{ standalone: true }"
                class="form-control"
                maxlength="40"
                matInput
              />
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <mat-form-field class="form-element">
              <mat-label>Comment</mat-label>
              <textarea [(ngModel)]="comment" class="form-control" matInput name="name"></textarea>
            </mat-form-field>
          </div>
          <div class="action-buttons multi-btns col-sm-12 text-right">
            <button
              (click)="saveAttachment()"
              [disabled]="!this.fileObj.fileName"
              class="btn-primary"
              color="primary"
              mat-raised-button
              name="button"
            >
              <i class="flaticon-diskette"></i>Save
            </button>
          </div>
        </div>
      </form>
      <!-- </div> -->
    </div>
  </div>
</ng-template>

<ng-template #uploadImage let-modal>
  <div class="mat-modal-header">
    <h4 class="mat-title-modal">Update Profile Picture</h4>
  </div>
  <app-loading *ngIf="imageUploading" [isLoading]="true"></app-loading>
  <div class="mat-modal-body" *ngIf="!imageUploading">
    <input type="file" class="file-input" (change)="fileChangeListener($event)" #fileUpload />

    <div class="file-upload">
      {{ this.picName || "No file uploaded yet." }}

      <button mat-mini-fab color="primary" class="upload-btn" (click)="fileUpload.click()">
        <mat-icon>attach_file</mat-icon>
      </button>
    </div>

    <div class="row" style="margin-top: 5%" *ngIf="!isShow && this.employeeImage">
      <div class="text-center col-md-8">
        <img [src]="data.image" class="img-l" />
      </div>
      <div class="text-center col-md-4">
        <span class="result rounded">
          <h4 class="mat-title-modal">preview</h4>
          <img
            [src]="data.image"
            [width]="cropperSettings.croppedWidth"
            [height]="cropperSettings.croppedHeight"
            style="width: 100px; height: 100px"
          />
        </span>
      </div>
    </div>
    <div class="row" style="margin-top: 5%" *ngIf="isShow">
      <div class="text-center col-md-8">
        <img-cropper #cropper [image]="data" [settings]="cropperSettings"></img-cropper>
      </div>
      <div class="text-center col-md-4">
        <span class="result rounded">
          <h4 class="mat-title-modal">preview</h4>
          <img
            [src]="data2.image"
            [width]="cropperSettings.croppedWidth"
            [height]="cropperSettings.croppedHeight"
            style="width: 100px; height: 100px"
          />
        </span>
      </div>
    </div>
    <br />
  </div>

  <div *ngIf="!imageUploading" class="action-buttons multi-btns col-sm-12 text-right">
    <button mat-raised-button (click)="deleteProfileImage()" class="btn-danger">
      <i class="flaticon-cancel"></i> cancel
    </button>
    <button
      mat-raised-button
      class="btn btn-primary"
      color="primary"
      [disabled]="!data.image && !imageUploading"
      (click)="uploadProfileImage()"
    >
      <i class="flaticon-diskette"></i> save
    </button>
  </div>
</ng-template>
