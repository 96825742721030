import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss']
})
export class TimeInputComponent implements OnInit {

  @Input() duration : number;
  @Output() durationChange : EventEmitter<number> = new EventEmitter();
  @Input() disabled : boolean;

  @ViewChild('hoursInput') hoursInput: Input;


  hours: number;
  minutes: number;

  constructor() { }

  ngOnInit(): void {
    if(this.duration){
      this.hours = Math.floor((this.duration*60) / 60);
      this.minutes = (this.duration*60) % 60;
    }
  } 

  ngOnChanges(changes: SimpleChanges) {
    this.hours = Math.floor((this.duration*60) / 60);
    this.minutes = (this.duration*60) % 60;
  }
  
  public updateHours(event){
    this.hours = Number(event.target.value);
    if(!this.minutes){
      this.minutes = 0;
    }
    this.duration = this.hours + (this.minutes/60);
    this.durationChange.emit(this.duration);
  }

  public updateMinutes(event){
    this.minutes = Number(event.target.value);
    if(!this.hours){
      this.hours = 0;
    }
    this.duration = this.hours + (this.minutes/60);
    this.durationChange.emit(this.duration);
  }

  public validateHours(event){
    
  }

  public validateMinutes(event){
   
  }

}
