
                                      <app-loading [isLoading]="this.isLoading === true"></app-loading>
                                      <div [hidden]="this.isLoading" class="card">
                                          <h4 class="mat-title-modal">
                                              Address
                                          </h4>
                                          <form [formGroup]="employeeForm" class="form-material full-width-inputs">
                                              <div *ngFor="let item of employeeForm.get('addresses')['controls']; let i = index;"
                                                   class="row form-group"
                                                   formArrayName="addresses">
                                                  <ng-container [formGroupName]="i">
                                                      <div class="col-md-4" [hidden]="!requiredContactDetails.addressStreet1Available">
                                                          <mat-form-field class="form-element">
                                                                <mat-label>Address Line 1</mat-label>
                                                                <input [readonly]="!update" class="form-control" maxlength="40" 
                                                                       formControlName="addresslineone"
                                                                       matInput required="{{requiredContactDetails.addressStreet1Required}}">
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-md-4" [hidden]="!requiredContactDetails.addressStreet2Available">
                                                            <mat-form-field class="form-element">
                                                                <mat-label>Address Line 2</mat-label>
                                                                <input [readonly]="!update" class="form-control" maxlength="40" 
                                                                       formControlName="addresslinetwo"
                                                                       matInput  required="{{requiredContactDetails.addressStreet2Required}}">
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-md-4" [hidden]="!requiredContactDetails.cityAvailable">
                                                            <mat-form-field class="form-element">
                                                                <mat-label>City</mat-label>
                                                                <input [readonly]="!update" class="form-control" formControlName="city" maxlength="40" 
                                                                       matInput  required="{{requiredContactDetails.cityRequired}}">
                                                            </mat-form-field>
                                                        </div>
                                                       
                                                        <div class="col-md-4" [hidden]="!requiredContactDetails.countryAvailable">
                                                            <mat-form-field class="form-element">
                                                                <mat-label>Country</mat-label>
                                                                <mat-select [disabled]="!update" disableOptionCentering (change)="fillCountryDetail()"
                                                                            class="form-control"
                                                                            formControlName="country"  required="{{requiredContactDetails.countryRequired}}">
                                                                    <mat-option *ngFor="let n of countryList"
                                                                                value="{{n.name}}">{{n.name}}
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </div>

                                                        <div class="col-md-4" [hidden]="!requiredContactDetails.stateAvailable">
                                                            <mat-form-field class="form-element">
                                                                <mat-label>State</mat-label>
                                                                <input [readonly]="!update" class="form-control" formControlName="state" maxlength="40" 
                                                                       matInput  required="{{requiredContactDetails.stateRequired}}">
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-md-4" [hidden]="!requiredContactDetails.postalCodeAvailable">
                                                            <mat-form-field class="form-element">
                                                                <mat-label>Zip Code</mat-label>
                                                                <input [readonly]="!update" class="form-control" formControlName="postalcode" maxlength="40" 
                                                                       matInput  required="{{requiredContactDetails.postalCodeRequired}}">
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-md-4" [hidden]="!requiredContactDetails.homeTelephoneAvailable">
                                                            <mat-form-field class="form-element">
                                                                <mat-label>Home Telephone Number</mat-label>
                                                                <input [readonly]="!update" class="form-control" disabled="" maxlength="40" 
                                                                       formControlName="contactone" matInput  required="{{requiredContactDetails.homeTelephoneRequired}}">
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-md-4" [hidden]="!requiredContactDetails.workTelephoneAvailable">
                                                            <mat-form-field class="form-element">
                                                                <mat-label>Work Telephone Number</mat-label>
                                                                <input [readonly]="!update" class="form-control" disabled="" maxlength="40" 
                                                                       formControlName="contacttwo" matInput  required="{{requiredContactDetails.workTelephoneRequired}}">
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-md-4" [hidden]="!requiredContactDetails.landMarkAvailable">
                                                            <mat-form-field class="form-element">
                                                                <mat-label>Landmark</mat-label>
                                                                <input [readonly]="!update" class="form-control" formControlName="landmark" maxlength="40" 
                                                                       matInput  required="{{requiredContactDetails.landMarkRequired}}">
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-md-4" [hidden]="!requiredContactDetails.mobileAvailable">
                                                            <mat-form-field class="form-element">
                                                                <mat-label>Mobile</mat-label>
                                                                <input [readonly]="!update" class="form-control" formControlName="mobile" maxlength="40" 
                                                                       matInput  required="{{requiredContactDetails.mobileRequired}}">
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-md-4" [hidden]="!requiredContactDetails.otherEmailAvailabl">
                                                            <mat-form-field class="form-element">
                                                                <mat-label>Other Email</mat-label>
                                                                <input [readonly]="!update" class="form-control" formControlName="otheremail" maxlength="40" 
                                                                       matInput  required="{{requiredContactDetails.otherEmailRequired}}">
                                                            </mat-form-field>
                                                        </div>
                                                        
                                                        
                                                        
                                                        <div class="col-md-4" [hidden]="!requiredContactDetails.workEmailAvailable">
                                                            <mat-form-field class="form-element">
                                                                <mat-label>Work Email</mat-label>
                                                                <input [readonly]="!update" class="form-control" formControlName="workemail" maxlength="40" 
                                                                       matInput  required="{{requiredContactDetails.workEmailRequired}}">
                                                            </mat-form-field>
                                                        </div>
                                                        <div [hidden]="!update" class="col-sm-12 text-right action-buttons multi-btns">
                                                            <button [disabled]="!this.employeeForm.valid" (click)="updateAddressDetail()" class="btn-primary"
                                                                    color="primary" mat-raised-button><i
                                                                    class="flaticon-diskette"></i>Save
                                                            </button>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </form>

                                            <div class="form-material full-width-inputs"  [hidden]="this.isLoading">
                                                <div *ngFor="let section of this.sectionsList">
                                                    <div *ngIf="section.customFields.length != 0" class="card-sub">
                                                        <h4>{{section.name}}</h4>
                                                        <ng-container>
                                                         
                                                            <div class="row form-group">
                                                                <div class="col-4" *ngFor="let custom of section.customFields; index as i">
                                                                    <mat-form-field class="form-element" *ngIf="custom.type === 'dd' ">
                                                                        <mat-select [disabled]="!update" disableOptionCentering class="form-control"
                                                                        [(ngModel)]="custom.value"  required="{{custom.required}}" (ngModelChange)="checkRequiredFieldsInCustomFields(section.id,section.customFields)" >
                                                                                    
                                                                            <ng-container>
                                                                                <mat-option value="">--Select--</mat-option>
                                                                                <mat-option *ngFor="let option of custom.options " [value]="option"  [(ngModel)]="custom.value">
                                                                                    {{option}}
                                                                                </mat-option>
                                                                            </ng-container>
                                                                        </mat-select>
                                                                        <mat-label>{{custom.name}}</mat-label>
                                                                    </mat-form-field>
                                            
                                            
                                                                    <mat-form-field class="form-element" *ngIf="custom.type === 'st' ">
                                                                        <mat-label>{{custom.name}}</mat-label>
                                                                        <input [readonly]="!update" matInput class="form-control"   [(ngModel)]="custom.value" required="{{custom.required}}"  (keyup)="checkRequiredFieldsInCustomFields(section.id,section.customFields)">
                                                                    </mat-form-field>
                                            
                                                                    <mat-form-field class="form-element" *ngIf="custom.type === 'num' ">
                                                                        <mat-label>{{custom.name}}</mat-label>
                                                                        <input [readonly]="!update" matInput class="form-control"   [(ngModel)]="custom.value" type="number" required="{{custom.required}}" (keyup)="checkRequiredFieldsInCustomFields(section.id,section.customFields)">
                                                                    </mat-form-field>
                                                                    <mat-form-field class="form-element" *ngIf="custom.type === 'dt' ">
                                                                        <mat-label >{{custom.name}}</mat-label>
                                                                    <input [readonly]="!update" class="form-control" matInput [matDatepicker]="i" [attr.id]="dtPicker + i" [(ngModel)]="custom.value" required="{{custom.required}}" (ngModelChange)="checkRequiredFieldsInCustomFields(section.id,section.customFields)">
                                                                    <mat-datepicker-toggle [disabled]="!update" matSuffix [for]="i"></mat-datepicker-toggle>
                                                                    <mat-datepicker #i></mat-datepicker>   
                                                                  </mat-form-field>
                                            
                                                                  <div *ngIf="custom.type === 'cb' " >
                                                                      <h5>{{custom.name}}:</h5>
                                                                    <div  *ngFor="let option of custom.values">
                                                                        <mat-checkbox [disabled]="!update" [(ngModel)]="option.booleanValue" >{{option.option}}</mat-checkbox> &nbsp;
                                                                    </div>
                                                                  </div>
                                                             </div>
                                            
                                                             
                                            
                                                                <div [hidden]="!update" class="action-buttons multi-btns col-sm-12 text-right">
                                                                    <button [disabled]="section.save" (click)="updateCustomField(section.id,section.customFields)"
                                                                            class="btn-primary"
                                                                            color="primary"
                                                                            mat-raised-button><i class="flaticon-diskette"></i>Save
                                                                    </button>
                                                                </div>    
                                                            </div>   
                                                                       
                                                            
                                                        </ng-container>
                                                    </div>
                                                   
                                                </div>
                                            
                                            
                                            </div>
                                        



                                            <div class="card-sub">
                                                <div class="row">
                                                    <div class="col-sm-10">
                                                        <h4>Contact Detail Attachment</h4>
                                                    </div>
                                                    <div class="col-sm-2" [hidden]="!update">
                                                        <div class="text-right">
																	<span class="mytooltip cus-left tooltip-effect-4">
																		<span class="tooltip-item">
																			<button (click)="addAttachment()"
                                                                                    class="btn btn-primary mat-button-base light add-rounded"
                                                                                    raised-button>
																				<i class="fa fa-plus"></i>
																			</button>
																		</span>
																		<span class="tooltip-content clearfix">
																		<span class="tooltip-text">New</span>
																		</span>
																	</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="table-responsive">
                                                    <mat-card-content>
                                                        <mat-table #table
                                                                   [dataSource]="contactDetailAttachmentList"
                                                                   matSort>
                                                            <ng-container matColumnDef="fileName">
                                                                <mat-header-cell *matHeaderCellDef
                                                                                 mat-sort-header>FileName
                                                                </mat-header-cell>
                                                                <mat-cell *matCellDef="let row">
                                                                    {{ row.fileName }}
                                                                </mat-cell>
                                                            </ng-container>

                                                            <ng-container matColumnDef="url">
                                                                <mat-header-cell *matHeaderCellDef
                                                                                 >File
                                                                </mat-header-cell>
                                                                <mat-cell *matCellDef="let row">
                                                                            <span class="mytooltip tooltip-effect-4">
                                            <span class="tooltip-item"><i (click)="open(row.url)"
                                                                          class="single-icon-click flaticon-attachment"
                                                                          style="color: #666"></i></span>
                                            <span class="tooltip-content clearfix">
                                            <span class="tooltip-text">Attachment</span>
                                            </span>
                                          </span>
                                                                </mat-cell>
                                                            </ng-container>

                                                            <ng-container matColumnDef="desc">
                                                                <mat-header-cell *matHeaderCellDef
                                                                                 mat-sort-header>Comment
                                                                </mat-header-cell>
                                                                <mat-cell *matCellDef="let row">
                                                                    {{ row.desc }}
                                                                </mat-cell>
                                                            </ng-container>

                                                            <ng-container matColumnDef="size">
                                                                <mat-header-cell *matHeaderCellDef>Size
                                                                </mat-header-cell>
                                                                <mat-cell *matCellDef="let row">
                                                                    {{ row.size }}
                                                                </mat-cell>
                                                            </ng-container>

                                                            <ng-container matColumnDef="added">
                                                                <mat-header-cell *matHeaderCellDef
                                                                                 mat-sort-header>Date Added
                                                                </mat-header-cell>
                                                                <mat-cell *matCellDef="let row">
                                                                    {{ row.added | date }}
                                                                </mat-cell>
                                                            </ng-container>

                                                            <ng-container matColumnDef="edit">
                                                                <mat-header-cell [hidden]="!update" *matHeaderCellDef
                                                                                 >Edit
                                                                </mat-header-cell>
                                                                <mat-cell [hidden]="!update" *matCellDef="let row">
                                                                        <span class="mytooltip tooltip-effect-4">
                                                                            <span class="tooltip-item"><i
                                                                                    (click)="editAttachment(row)"
                                                                                    class="single-icon-click fa fa-pencil"></i></span>
                                                                            <span class="tooltip-content clearfix">
                                                                            <span class="tooltip-text">Edit</span>
                                                                            </span>
                                                                        </span>
                                                                </mat-cell>
                                                            </ng-container>

                                                            <ng-container matColumnDef="delete">
                                                                <mat-header-cell [hidden]="!delete" *matHeaderCellDef
                                                                                 >Delete
                                                                </mat-header-cell>
                                                                <mat-cell [hidden]="!delete" *matCellDef="let row">
                                                                        <span class="mytooltip tooltip-effect-4">
                                                                            <span class="tooltip-item"><i
                                                                                    (click)="deleteAttachmentSelected(row.id)"
                                                                                    class="single-icon-click fa fa-trash"></i></span>
                                                                            <span class="tooltip-content clearfix">
                                                                            <span class="tooltip-text">Delete</span>
                                                                            </span>
                                                                        </span>
                                                                </mat-cell>
                                                            </ng-container>
                                                            <mat-header-row
                                                                    *matHeaderRowDef="columns"></mat-header-row>
                                                            <mat-row
                                                                    *matRowDef="let row; columns: columns;">
                                                            </mat-row>
                                                        </mat-table>
                                                        <mat-progress-bar 
                                                                          mode="indeterminate" *ngIf="this.isLoadingAttachment"></mat-progress-bar>
                                                        <mat-paginator [pageSizeOptions]="[10, 20]"
                                                                       showFirstLastButtons></mat-paginator>
                                                    </mat-card-content>
                                                </div>
                                            </div>
                                        </div>

                                        <ng-template #attach let-modal>
                                            <div mat-dialog-actions>
                                                <div class="mat-modal-header">
                                                    <h4 class="mat-title-modal">
                                                        Attachment
                                                    </h4>
                                                </div>
                                                <div class="mat-modal-body">
                                                    <!-- <div class="card-content"> -->
                                                        <app-loading *ngIf="loadAttach" [isLoading]="loadAttach"></app-loading>
                                                    <form class="form-material full-width-inputs" *ngIf="!loadAttach">
                                                        <div class="row form-group">
                                                            <div class="col-sm-12">
                                                                <div class="upload-btn-wrapper">
                                                                    <button class="upload-btn">Attach</button>
                                                                    <input (change)="fileChange($event, 'dd')" [(ngModel)]="fileClean" class="form-control"
                                                                           name="myfile"
                                                                           type="file">
                                                                    <div class="fileName">{{this.fileObj && this.fileObj.fileName}}<i *ngIf="fileUrl"
                                                                                                                                      class="fa fa-check success"></i>
                                                                    </div>
                                                                </div>
                                                                <div class="upload-btn-wrapper">
                                                                 
                                                                </div>
                                                                <div *ngIf="!fileUrl && fileError" class="file-error">{{fileError}}</div>
                                                                <div class="help-text">Accept upto 2MB</div>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <mat-form-field class="form-element">
                                                                    <mat-label>File Name</mat-label>
                                                                    <input [(ngModel)]="fileName" [ngModelOptions]="{standalone: true}" class="form-control" maxlength="40" 
                                                                           matInput>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <mat-form-field class="form-element">
                                                                    <mat-label>Comment</mat-label>
                                                                    <textarea [(ngModel)]="comment" maxlength="50" class="form-control" matInput name="name"></textarea>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="action-buttons multi-btns col-sm-12 text-right" *ngIf="!loadAttach">
                                                                <button (click)="saveAttachment()" [disabled]="!this.fileObj.fileName" class="btn-primary"
                                                                        color="primary" mat-raised-button
                                                                        name="button"><i class="flaticon-diskette"></i>Save
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <!-- </div> -->
                                                </div>
                                            </div>
                                        </ng-template>

                                        <ng-template #editAttach let-modal>
                                            <div mat-dialog-actions>
                                                <div class="mat-modal-header">
                                                    <h4 class="mat-title-modal">
                                                        Edit Attachment
                                                    </h4>
                                                </div>
                                                <div class="mat-modal-body">
                                                    <form class="form-material full-width-inputs">
                                                        <div class="row form-group">
                                                            <div class="col-md-12">
                                                                <mat-form-field class="form-element">
                                                                    <mat-label>File Name</mat-label>
                                                                    <input [(ngModel)]="fileName" [ngModelOptions]="{standalone: true}" class="form-control" maxlength="40" 
                                                                           matInput>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <mat-form-field class="form-element">
                                                                    <textarea [(ngModel)]="comment" maxlength="50" matInput class="form-control" name="name"></textarea>
                                                                    <mat-label>Comment</mat-label>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-sm-12 text-right action-buttons multi-btns">
                                                                <button mat-raised-button color="primary" (click)="updateAttachment()" class="btn-primary" name="button">
                                                                    <i class="flaticon-diskette"></i>
                                                                    Update
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </ng-template>
                                        <ng-template #deleteAttachment let-modal>
                                            <div mat-dialog-actions>
                                                <div class="mat-modal-header">
                                                    <h4 class="mat-title-modal">Delete Attachment</h4>
                                                </div>
                                                <div class="mat-modal-body">
                                                    <div class="text-left are-u">Are you sure ?</div>
                                                    <div class="row">
                                                        <div class="multi-btns col-sm-12 text-right">
                                                            <button (click)="this.dialogRef.close()" class="btn-danger" mat-raised-button>
                                                                <i class="flaticon-cancel"></i>Cancel
                                                            </button>
                                                            <button (click)="deleteAttachmentFrom()" class="btn-primary" color="primary" mat-raised-button>
                                                                <i class="flaticon-trash"></i>Delete
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
