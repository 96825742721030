import {ChangeDetectorRef, Component, Input , ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import {animate, AUTO_STYLE, state, style, transition, trigger} from '@angular/animations';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatStepper} from '@angular/material/stepper';
import {DashboardService} from '../../dashboard.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from "src/app/core/services/notification.service";
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { MatSelect } from '@angular/material/select';


import { ImageCropperComponent, CropperSettings } from 'ngx-img-cropper';
import { ImageUtilService } from 'src/app/service/image-util.service';
import {UsersService} from "../../user.service";
import { AuthService } from 'src/app/core/services/auth.service';
import { throwIfEmpty } from 'rxjs/operators';
import { CommonService } from 'src/app/common-service.component';

declare var jQuery: any;

@Component({
  selector: 'app-edit-employee',
  templateUrl: './edit-employee.component.html',
  styleUrls: ['./edit-employee.component.scss'],
  animations: [
    trigger('notificationBottom1', [
      state('an-off, void',
          style({
            overflow: 'hidden',
            height: '0px',
          })
      ),
      state('an-animate',
          style({
            overflow: 'visible',
            height: AUTO_STYLE,
          })
      ),
      transition('an-off <=> an-animate', [
        animate('0ms ease-in-out')
      ])
    ]),
    trigger('slideInOut', [
      state('in', style({
        width: '380px',
        // transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        width: '0',
        // transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('250ms ease-in-out')),
      transition('out => in', animate('250ms ease-in-out'))
    ]),
    trigger('mobileHeaderNavRight1', [
      state('nav-off, void',
          style({
            overflow: 'hidden',
            height: '0px',
          })
      ),
      state('nav-on',
          style({
            height: AUTO_STYLE,
          })
      ),
      transition('nav-off <=> nav-on', [
        animate('250ms ease-in-out')
      ])
    ]),
    trigger('fadeInOutTranslate', [
      transition(':enter', [
        style({opacity: 0}),
        animate('250ms ease-in-out', style({opacity: 1}))
      ]),
      transition(':leave', [
        style({transform: 'translate(0)'}),
        animate('250ms ease-in-out', style({opacity: 0}))
      ])
    ]),
    trigger('mobileMenuTop', [
      state('no-block, void',
          style({
            overflow: 'hidden',
            height: '0px',
          })
      ),
      state('yes-block',
          style({
            height: AUTO_STYLE,
          })
      ),
      transition('no-block <=> yes-block', [
        animate('250ms ease-in-out')
      ])
    ])
  ]
})
export class EditEmployeeComponent implements OnInit {
  @Input() employeeId: string;
// for the edit employee
  public section = 'pp';
  public fileName = '';
  public tmore: number;
  public isLoadingSw = true;
  public fileClean: string;
  public dropdownErr: string;
  public modalRef: any;
  public employeeForm: FormGroup;
  public jobForm: FormGroup;
  public personalForm: FormGroup;
  public salaryForm: FormGroup;
  public terminateForm: FormGroup;
  public nationalityList: Array<any> = [];
  public countryList: Array<any> = [];
  public payGradeList: Array<any> = [];
  public earningList: Array<any> = [];
  public deductionList: Array<any> = [];
  public currencyList: Array<any> = [];
  public locationList: Array<any> = [];
  public pid: string;
  public salaryList: Array<any> = [];
  public formStatus: Array<any> = [];
  public step: number;
  public fileUrl: string;
  public payrollList: Array<any> = [];
  public jobCategoryList: Array<any> = [];
  public costCenterList: Array<any> = [];
  public workShiftList: Array<any> = [];
  public companyList: Array<any> = [];
  public jobTitleList: Array<any> = [];
  public employeeStatusList: Array<any> = [];
  public regionList: Array<any> = [];
  public action: string;
  public editId = '';
  public createUser: boolean;
  public adminList = [];
  public essList = [];
  public supervisorList = [];
  uploadedImage: any;
  public icon = 'flaticon-more';
  // for the edit employee attachment
  public jobHistoryList: MatTableDataSource<any> = new MatTableDataSource([]);
  public attachmentList: MatTableDataSource<any> = new MatTableDataSource([]);
  public jobAttachmentList: MatTableDataSource<any> = new MatTableDataSource([]);
  public salaryAttachmentList: MatTableDataSource<any> = new MatTableDataSource([]);
  public contactDetailAttachmentList: MatTableDataSource<any> = new MatTableDataSource([]);
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  public columns = ['fileName', 'url', 'desc', 'size', 'added', 'edit', 'delete'];
  public columnss = ['historyType', 'effictiveDate', 'jobTitle', 'employementStatus', 'jobCategory', 'subUnit', 'location', 'contractStartDate', 'contractEndDate', 'comment'];
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild('singleSelect', {static: false}) singleSelect: MatSelect;
  @ViewChild('slider') slider: ElementRef;
  @ViewChild('search') search: ElementRef;
  public roleList: Array<any> = [];
  public fileError: string;
  public fileType = '';
  public employeeName = '';
  public static testVal = '';
  public employeeImage = '';
  public profileNotification1: string;
  public profileNotificationClass1: string;
  public newIdGenByServer: string;
  public showEmpNumberMessage: string;
  public userFilterCtrl: FormControl = new FormControl();


  // for the sake of getting sub unit
  @ViewChild('sample', {static: false})
  public subunitData: object[] = [];
  //
  public payCurrencyObj = {
    min: '',
    max: '',
    currencyCode: '',
    currencyName: ''
  };
  public activityList = [
    {value: 'arts', label: 'Arts'},
    {value: 'dancing', label: 'Dancing'},
    {value: 'music', label: 'Music'},
    {value: 'arts', label: 'Arts'}
  ];
  public sportsList = [
    {value: 'basketball', label: 'Basketball'},
    {value: 'baseball', label: 'Baseball'},
    {value: 'football', label: 'Football'},
    {value: 'golf', label: 'Golf'},
    {value: 'handball', label: 'Handball'},
    {value: 'rugger', label: 'Rugger'},
    {value: 'squash', label: 'Squash'}
  ];
  public sub = {
    id: '',
    reportingMethod: ''
  };
  public terminationList = [];
  public validationError = {
    required: 'Required',
    email: 'Please provide a valid email',
    password: 'Please provide a password of atleast 8 digits',
    contact: 'Please provide a contact of 10 digits'
  };
  public comment: string;
  public fileObj: any;
  public contactObj: any;
  public contactFile: any;
  public uploadedFile: any;
  public reportingTypes = [];
  public moreopt: string;
  public More = 'More';
  public editAttachmentId: string;
  public deleteAttachmentId: string;
  public basicValue = {
    sc: 'Basic',
    value: ''
  };
  public totalE = 0;
  public totalD = 0;
  public totalP = 0;
  public event: string;
  @ViewChild('attach', {static: false}) attach;
  @ViewChild('editAttach', {static: false}) editAttach;
  @ViewChild('deleteAttachment', {static: false}) deleteAttachment;
  @ViewChild('terminate', {static: false}) terminate;
  @ViewChild('saveChanges', {static: false}) saveChanges;
  @ViewChild('showEmpNumber', {static: false}) showEmpNumber;
  @ViewChild('stepper') stepper: MatStepper;
  public dialogRef: any;
  public moreTxt: string;
  imageUploading = false;
  public editAttachmentData: any;
  // for getting the department and the sub department
  public structureList: Array<any> = [];
  public temporaryDepartment: Array<any> = [];
  // @ViewChild('dept') selectDeparment: ElementRef;
  public CountryToDisable: any;
  public isCountry = true;
  public currencyCodeName: any;
  public currentPaygrade: any;
  // for the value change detection
  public isPersonalFormChanged = false;
  public isJobFormChanged = false;
  public isSalaryFormChanged = false;
  public isEmployeeFormChanged = false;
  public tempSalaryForm: any = {};
  public switch = false;
  public isShow : boolean = false;

  public showFind: boolean = true;

  data: any;
  cropperSettings: CropperSettings;


  @ViewChild(ImageCropperComponent) cropper:ImageCropperComponent;

  @ViewChild("demo3Tab", { static: false }) demo3Tab: MatTabGroup;

  @ViewChild('uploadImage', {static: false}) uploadImageDialog;
  filteredOptions=[];
  employeeList= [];
  correct: boolean;
  public user: any;
  constructor(private service: DashboardService,
              private authService : AuthService,
              private toastr: NotificationService,
              private router: Router,
              private ngZone: NgZone,
              private route: ActivatedRoute,
              private dialog: MatDialog,
              private imgUtil: ImageUtilService,
              private userService: UsersService,
              private Service: CommonService) {

    this.tmore = 0;
    this.isLoadingSw = true;

    this.cropperSettings = new CropperSettings();
    this.cropperSettings.width = 100;
    this.cropperSettings.height = 100;
    this.cropperSettings.croppedWidth = 100;
    this.cropperSettings.croppedHeight = 100;
    this.cropperSettings.canvasWidth = 300;
    this.cropperSettings.canvasHeight = 300;
    this.cropperSettings.rounded = true;

    this.cropperSettings.noFileInput = true;

    this.data = {};

  }


  ngOnDestroy() {
    
    this.employeeId = null ;
    this.section = null ;
    this.fileName = null;
    this.tmore = null;
    this.isLoadingSw = null; 
    this.fileClean = null ;
    this.personalForm = null ;
    this.modalRef = null ;
    this.dropdownErr = null ;
    this.jobForm = null ;
    this.salaryForm = null ;
    this.terminateForm = null ;
    this.employeeForm = null ;
    this.personalForm = null ;
    this.nationalityList = null ;
    this.countryList = null ;
    this.payGradeList = null ;
    this.earningList = null ;
    this.deductionList = null ;
    this.currencyList = null ;
    this.locationList = null ;
    this.pid = null ;
    this.salaryList = null ;
    this.formStatus = null ;
    this.step = null ;
    this.fileUrl = null ;
    this.payrollList = null ;
    this.jobCategoryList = null ;
    this.costCenterList = null ;
    this.workShiftList = null ;
    this.companyList = null ;
    this.jobTitleList = null ;
    this.employeeStatusList = null ;
    this.regionList = null ;
    this.action = null ;
    this.editId = null ;
    this.createUser = null ;
    this.adminList = null ;
    this.essList = null ;
    this.supervisorList = null ;
    this.uploadedImage = null ;
    this.icon = null ;
    this.jobHistoryList = null ;
    this.attachmentList = null ;
    this.jobAttachmentList = null ;
    this.salaryAttachmentList = null ;
    this.contactDetailAttachmentList = null ;
    this.dataSource = null ;
    this.columns = null ;
    this.columnss = null ;
    this.sort = null ;
    this.paginator = null ;
    this.table = null ;
    this.singleSelect = null ;
    this.slider = null ;
    this.search = null ;
    this.roleList = null ;
    this.fileError = null ;
    this.fileType = null ;
    this.employeeName = null ;
    this.employeeImage = null ;
    this.profileNotification1 = null ;
    this.profileNotificationClass1 = null ;
    this.newIdGenByServer = null ;
    this.showEmpNumberMessage = null ;
    this.userFilterCtrl = null ;
    this.subunitData = null ;
    this.payCurrencyObj = null ;
    this.activityList = null ;
    this.sportsList = null ;
    this.sub = null ;
    this.terminationList = null ;
    this.validationError = null ;
    this.comment = null ;
    this.fileObj = null ;
    this.contactObj = null ;
    this.contactFile = null ;
    this.uploadedFile = null ;
    this.reportingTypes = null ;
    this.moreopt = null ;
    this.More = null ;
    this.editAttachmentId = null ;
    this.deleteAttachmentId = null ;
    this.basicValue = null ;
    this.event = null ;
    this.attach = null ;
    this.editAttach = null ;
    this.deleteAttachment = null ;
    this.terminate = null ;
    this.saveChanges = null;
    this.showEmpNumber = null ;
    this.stepper = null ;
    this.dialogRef = null ;
    this.dialog = null ;
    this.cropperSettings = null ;
    this.cropper = null ;
    this.demo3Tab = null ;
    this.uploadImageDialog = null ;
    this.user = null ;
    this.service = null ;
    this.toastr = null ;
    this.route = null ;
    this.router = null ;
    this.dialogRef = null ;
    this.dialog = null ;
    this.ngZone = null ;
    this.imgUtil = null ;
    this.authService = null ;
  }

  public permissions : any;
  public read = false;
  public write = false ;
  public update = false;
  public delete = false;

  checkPermission(module,tabs) : void 
  {
      this.permissions = JSON.parse(this.authService.getPermissions());

      this.read = false ;
      this.write = false ;
      this.delete = false ;
      this.update = false;

      if(this.permissions != undefined && this.permissions != null )
      {
          for(let p of this.permissions)
          {
             
              if(p.id === module)
              {
                 
                  if(p.modules != undefined)
                  {
                    
                      for(let tab of p.modules)
                      {
                          if(tab.id === tabs)
                          {
                            
                              for(let move of tab.permits)
                              {
                                  if(move.id === "u")
                                  {
                                      this.update = true;
                                     
                                  }
                                  else if(move.id === "r")
                                  {
                                      this.read = true;
                                  }
                                 
                                  else if(move.id === "d")
                                  {
                                      this.delete = true;
                                  }
                              }
                          }
                      }
                  }
                 
              }
             
          }
      }
     

  }
  public isLoading : boolean = true;
  closeSearch : boolean = false ;
  doNothing() : void 
  {
    return ;
  }
  ngOnInit(): void {
    this.closeSearch = false ;
    this.checkPermission("pdm","pd_em");
    this.isLoading = true;
    this.user = JSON.parse(sessionStorage.getItem('currentUser'));
    this.route.params.subscribe(params => {
      if (params.id || this.employeeId) {
        this.userService.castUser.subscribe(user => (this.employeeName = user));
        this.editId = params.id || this.employeeId;
        if(this.employeeId){
          this.showFind = false;
        }
        this.filteredOptions = [];
        this.isLoadingSw = true;
        this.switch = false;
        this.employeeList = [];
        this.userFilterCtrl.reset();
        // to get the list of the attachment
        this.service.getEmployePersonalInfo(this.editId).subscribe(resp => {

          let dataUser = resp.data.firstname
              + ' ' + resp.data.middlename + ' ' + resp.data.lastname;

          this.employeeImage = resp.data.image;
          
          this.userService.editUser(dataUser);
          this.isLoading = false;
          this.goToNextTabIndex(this.demo3Tab);

          this.delay(25).then(any => {
            this.goToPrevTabIndex(this.demo3Tab);

            this.isLoadingSw = false;
          });


        });
      
        this.service.getEmployeeDetailList().subscribe(resp => {
          if (resp && resp.data) {
              resp.data.map(e => {
                  this.employeeList.push({ label: e.id + ' ' + e.name, value: e.id });
                
              });
              this.initValuesDetection();
          }
      });

      }
    });

  }




  public demo3BtnClick() {
    this.goToNextTabIndex(this.demo3Tab);
  }

  switchOn() {
    if (this.switch === true) {
      this.switch = false;
    } else {
      this.switch = true;
    }

  }

  initValuesDetection() {
    this.filteredOptions = [];
   

    this.userFilterCtrl.reset();
    this.userFilterCtrl.valueChanges.subscribe(change => {

      console.log(change);
      if (change == null || change == undefined) {
        this.correct = true;
      } else {

        this.filterEmployees(change);
      }

    });


  }
  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }


  public goForward(stepper: MatStepper, step) {
    if (step === 2) {
      if (this.personalForm.controls.firstname.invalid ||
          this.personalForm.controls.lastname.invalid ||
          this.personalForm.controls.empid.invalid ||
          (this.personalForm.controls.createlogindetail.value === true &&
              (this.personalForm.controls.roles.value[0]['userRole'] === '' ||
                  this.personalForm.controls.roles.value[1]['userRole'] === '' ||
                  this.personalForm.controls.password.invalid)
          )
      ) {
        let control;
        Object.keys(this.personalForm.controls).reverse().forEach((field) => {
          if (this.personalForm.get(field).invalid) {
            control = this.personalForm.get(field);
            control.markAsDirty();
          }
        });

        if (control) {
          const el = jQuery('.ng-invalid:not(form):first');
          if (el.length > 0) {
            jQuery('html,body').animate({scrollTop: (el.offset().top - 100)}, 'slow', () => {
              el.focus();
            });
          }
        }
        return;
      }
      stepper.next();
      this.step = step;
    } else if (step === 3) {
      if (this.jobForm.controls.effectivefrom.invalid ||
          this.jobForm.controls.locationid.invalid ||
          this.jobForm.controls.region.invalid ||
          this.jobForm.controls.temporarydepartment.invalid) {
        let control;
        Object.keys(this.jobForm.controls).reverse().forEach((field) => {
          if (this.jobForm.get(field).invalid) {
            control = this.jobForm.get(field);
            control.markAsDirty();
          }
        });

        if (control) {
          const el = jQuery('.ng-invalid:not(form):first');
          jQuery('html,body').animate({scrollTop: (el.offset().top - 100)}, 'slow', () => {
            el.focus();
          });
        }
        return;
      }
      this.step = step;
      this.employeeForm.controls.subunitid.setValue(this.jobForm.controls.temporarydepartment.value);
      stepper.next();
    } else if (step === 4) {
      this.employeeForm.controls.subunitid.setValue(this.jobForm.controls.temporarydepartment.value);
      stepper.next();
      this.step = step;
    }
  }

  public goBack(stepper: MatStepper) {
    stepper.previous();
    if (this.step !== 0) {
      this.step = this.step - 1;
    } else {
      this.step = 1;
    }
  }

  // public fillCountryDetail(): void {
  //   if (this.CountryToDisable) {
  //     this.isCountry = false;
  //   }
  //   const val = this.employeeForm.value;
  //   const c = val.addresses[0].country;
  //   const cl = this.countryList.filter(clist => {
  //     return clist.name === c;
  //   });
  //   val.addresses[0].countrycode = cl[0].code;
  //   val.addresses[0].countryiso = cl[0].iso;
  //   this.employeeForm.patchValue(val);
  // }

  public data2 : any = {image : ''}
  public picName = '' ;
  openUploadDialog() {
    
    this.uploadDialogRef = this.dialog.open(this.uploadImageDialog, {
      width: '500px',
      height: 'auto'
    });

    this.uploadDialogRef.afterOpened().subscribe(res => {
      if(this.employeeImage){
          const img = new Image();
         
          img.src = this.employeeImage;
          this.picName ='Upload a new image'
          this.data.image = this.employeeImage;
          
        
      }
  });
  }
    
  public fileChange1($event, type): void {
    const reader = new FileReader();
    const file = $event.target.files[0];
    this.contactFile = file;
    const fileName = file.name;
    if (file && (file.size / 1024) < 2048) {
      this.contactObj = {
        added: new Date(),
        addedBy: 'string',
        desc: this.comment,
        docType: this.fileType,
        empId: this.editId,
        fileName: fileName,
        size: file.size
      };
    } else {
      this.fileError = 'Max size 2 MB';
      this.toastr.error('Max 2Mb file allowed.');
    }
  }


  public notificationOutsideClick(ele: string) {
    if (this.profileNotification1 === 'an-animate' && !this.tmore) {
      this.toggleProfileNotification();
    }
  }

  public toggleProfileNotification() {
    this.profileNotification1 = this.profileNotification1 === 'an-off' ? 'an-animate' : 'an-off';
    this.profileNotificationClass1 = this.profileNotification1 === 'an-animate' ? 'active' : '';
  }

  public setAny(opt): void {

    const obj = {
      cd: 'Contact Detail',
      sd: 'Social Media Details',
      ec: 'Emergency Contacts',
      dt: 'Dependents',
      im: 'Immigration',
      rt: 'Report To',
      qq: 'Qualification',
      mm: 'Membership'
    };
    if (opt === 'qq') {
      this.More = 'Qualification';
      this.icon = 'flaticon-diploma-1';
    }
    if (opt === 'cd') {
      this.More = 'Contact Detail';
      this.icon = 'flaticon-communicate';
    }
    if (opt === 'sd') {
      this.More = 'Social Media Details';
      this.icon = 'flaticon-social-media';
    }
    if (opt === 'ec') {
      this.More = 'Emergency Contacts';
      this.icon = 'flaticon-contact';
    }
    if (opt === 'dt') {
      this.More = 'Dependents';
      this.icon = 'flaticon-family';
    }
    if (opt === 'im') {
      this.More = 'Immigration';
      this.icon = 'flaticon-destination';
    }
    if (opt === 'rt') {
      this.More = 'Report To';
      this.icon = 'flaticon-profiles';
    }
    if (opt === 'dd') {
      this.More = 'Direct Deposit';
      this.icon = 'flaticon-salary-1';
    }
    if (opt === 'ww') {
      this.More = 'Work week';
      this.icon = 'flaticon-calendar';
    }
    this.moreTxt = obj[opt];
    this.moreopt = opt;
    this.tmore = 0;
  }


  public test(t, section): void {
    this.section = section;
    this.tmore = t;
    this.profileNotification1 = 'an-animate';
    this.profileNotificationClass1 = this.profileNotification1 === 'an-animate' ? 'active' : '';
  }

  public onChange(args: any): void {
    this.employeeForm.controls.temporarydepartment.setValue('');
  }

  OnConfirmation() {
    this.dialogRef.close();
    this.ngZone.run(() => {
      void this.router.navigate(['/pim/employees']);
    });
  }


  checkConfirmation() {
    if (this.isPersonalFormChanged) {
      this.dialogRef = this.dialog.open(this.saveChanges, {
        width: '440px',
        height: 'auto'
      });
    } else if (this.isJobFormChanged) {
      this.dialogRef = this.dialog.open(this.saveChanges, {
        width: '440px',
        height: 'auto'
      });
    } else if (this.isSalaryFormChanged) {
      this.tempSalaryForm = this.salaryForm.value;
      this.dialogRef = this.dialog.open(this.saveChanges, {
        width: '440px',
        height: 'auto'
      });
    } else if (this.isEmployeeFormChanged) {
      this.dialogRef = this.dialog.open(this.saveChanges, {
        width: '440px',
        height: 'auto'
      });
    }
  }

  onClose() {
    this.dialogRef.close();
    this.isPersonalFormChanged = false;
    this.isSalaryFormChanged = false;
    this.isJobFormChanged = false;
    this.isEmployeeFormChanged = false;
  }

  OnConfirmationUpdate() {
    if (this.isPersonalFormChanged) {
      // this.updatePersonalDetail();
      this.dialogRef.close();
      this.isPersonalFormChanged = false;
      this.isSalaryFormChanged = false;
      this.isJobFormChanged = false;
      this.isEmployeeFormChanged = false;
    } else if (this.isJobFormChanged) {
      // this.updateJobDetail();
      this.dialogRef.close();
      this.isPersonalFormChanged = false;
      this.isSalaryFormChanged = false;
      this.isJobFormChanged = false;
      this.isEmployeeFormChanged = false;
    } else if (this.isSalaryFormChanged) {
      // this.updateSalaryDetail();
      this.dialogRef.close();
      this.isPersonalFormChanged = false;
      this.isSalaryFormChanged = false;
      this.isJobFormChanged = false;
      this.isEmployeeFormChanged = false;
    } else if (this.isEmployeeFormChanged) {
      // this.updateAddressDetail();
      this.dialogRef.close();
      this.isPersonalFormChanged = false;
      this.isSalaryFormChanged = false;
      this.isJobFormChanged = false;
      this.isEmployeeFormChanged = false;
    }
  }

  checkEmployeeChange(change) {
    let x = this.filteredOptions.find(x => x.value === change);
    if (!x) {
      this.correct = false;
    }

  }
  openSearch() : void 
  {
    if(this.closeSearch)
    {
      this.closeSearch = false ;
    }
    else 
    {
      this.closeSearch = true ;
    }
  }
  public displayEmployeeByName(option) {
    if (option === ' ' || option === null || option === undefined) {
      return;
    }

    this.correct = true;
    this.search.nativeElement.click();
    this.ngZone.run(() => {
      void this.router.navigate(['/pim/employees/' + option]);
    });

    
    return this.employeeList.find(x => x.value === option).label;
  }

  protected filterEmployees(change: String) {
   
    if (!this.employeeList) {
      return;
    }

    // get the search keyword
    if (!change) {

      this.filteredOptions = [];
      return;
    } else {
        this.checkEmployeeChange(change);
      change = change.toLowerCase();
    }
    // filter the search
    this.filteredOptions =
        this.employeeList.filter(emp => emp.label.toLowerCase().indexOf(change) > -1);

        console.log(this.filteredOptions);

  }

  private goToNextTabIndex(tabGroup: MatTabGroup) {
    if (!tabGroup || !(tabGroup instanceof MatTabGroup)) {
      return;
    }

    const tabCount = tabGroup._tabs.length;
    tabGroup.selectedIndex = (tabGroup.selectedIndex + 1) % tabCount;
  }

  private goToPrevTabIndex(tabGroup: MatTabGroup) {
    if (!tabGroup || !(tabGroup instanceof MatTabGroup)) {
      return;
    }

    const tabCount = tabGroup._tabs.length;
    tabGroup.selectedIndex = (tabGroup.selectedIndex - 1) % tabCount;
  }


  fileChangeListener(e: any) {
    
    this.imageUploading = false;
    var image: any = new Image();
    var file: File = e.target.files[0];
    var myReader: FileReader = new FileReader();
    var that = this;
    var data = {image : ''};
    this.picName =  e.target.files[0].name;
    myReader.onloadend = function(loadEvent: any) {
      image.src = loadEvent.target.result;
     
      that.data2.image = loadEvent.target.result;
      that.cropper.setImage(image);
    };
    

    this.isShow = true;

    myReader.readAsDataURL(file);

  }


  sendMessage(): void {
    // send message to subscribers via observable subject
    this.Service.sendUpdate(this.employeeImage);
}

  public uploadProfileImage() {

    this.uploadedImage =  this.cropper.cropper.getCroppedImage(true).src;
    const imageName = 'name.png';
    const imageBlob = this.imgUtil.dataURItoBlob(this.uploadedImage);
    const uploadedImageFile = new File([imageBlob], imageName, { type: 'image/png' });

    this.imageUploading = true;

    const reader = new FileReader();

    const file = uploadedImageFile;
    const profileFile = file;
    const fileName = file && file.name;
    if (file && (file.size / 1024) < 2048) {
      
      this.imageUploading = true;
      this.service.uploadFile(profileFile).subscribe(resp => {
        this.uploadDialogRef.close();
        this.imageUploading = false;

        if (resp && resp.data) {

          this.service.updateEmployeeProfilePicture(this.editId,resp.data.filename).subscribe(res => {
            if (res) {
              this.toastr.success('Profile Picture updated Successfully!..');

              //get new image
              this.service.getEmployePersonalInfo(this.editId).subscribe(resp => {
                this.employeeImage = resp.data.image;
                if(this.editId === sessionStorage.getItem('empId'))
                {
                  this.sendMessage();
                }
               
              });
            }
          }, error => {
            this.toastr.error('Error in  updating');
          });
        }
      }, err => {
        this.uploadDialogRef.close();
        this.imageUploading = false;
      });
    } else {
      this.uploadDialogRef.close();
      this.toastr.error('Max 2Mb Image allowed.');
    }
  }

  uploadDialogRef: any;
  close() {
    this.isShow = false;
    this.data = {};
    this.uploadDialogRef.close();
  }

  deleteProfileImage() {
    this.isShow = false;
    this.data = {};
    this.uploadDialogRef.close();
}

}
