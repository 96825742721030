import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { NotificationService } from "src/app/core/services/notification.service";
import { DashboardService } from "src/app/dashboard/dashboard.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { AuthService } from "src/app/core/services/auth.service";
@Component({
  selector: "app-leave-days",
  templateUrl: "./leave-days.component.html",
  styleUrls: ["./leave-days.component.scss"],
})
export class LeaveDaysComponent implements OnInit {
  public employeeId: string = "";
  public leaveId: string = "";
  public employeeImage: string = "";
  public employeeName: string = "";
  public requestedFor: string = "";
  public appliedOn: string = "";
  public commentForm: FormGroup;
  public commentList: Array<any> = [];
  public isLodaingCo: boolean = false;
  public dialogRef: any;
  public dayId: string = "";
  public actionsList: Array<any> = [];
  public action: string = "";
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("addComments", { static: false }) addComments;
  @ViewChild("addCommentss", { static: false }) addCommentss;
  public isLoadingDay: boolean = false;
  displayedDaysListColumns = ["date", "leaveType", "leaveBalanceDays", "duration", "status", "icons", "actionTaken"];
  public selected = "";
  public isSaving: boolean;

  constructor(
    private readonly authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private service: DashboardService,
    private toastr: NotificationService,
    private router: Router
  ) {
    this.createCommentForm();
  }

  public permissions: any;
  public read = false;
  public write = false;
  public update = false;
  public delete = false;

  checkPermission(module, tabs): void {
    this.permissions = JSON.parse(this.authService.getPermissions());

    this.read = false;
    this.write = false;
    this.delete = false;
    this.update = false;
    if (this.permissions != undefined && this.permissions != null) {
      for (let p of this.permissions) {
        if (p.id === module) {
          if (p.modules != undefined) {
            for (let tab of p.modules) {
              if (tab.id === tabs) {
                for (let move of tab.permits) {
                  if (move.id === "u") {
                    this.update = true;
                  } else if (move.id === "r") {
                    this.read = true;
                  } else if (move.id === "c") {
                    this.write = true;
                  } else if (move.id === "d") {
                    this.delete = true;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ngOnInit(): void {
    this.activatedRoute.queryParams
      .filter((params) => params.action)
      .subscribe((params) => {
        this.action = params.action;
      });
    if (this.action === "my") {
      this.checkPermission("leave", "le_ml");
    } else {
      this.checkPermission("leave", "le_ll");
    }
    this.activatedRoute.params.subscribe((params) => {
      if (params.id && params.empId) {
        this.employeeId = params.empId;
        this.leaveId = params.id;
        this.getLeaveDays();
      }
    });
  }

  getLeaveDays() {
    this.isLoadingDay = true;

    this.service.getLeaveDaysForLeave(this.leaveId, this.employeeId).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.employeeImage = resp.data.imgPath;
          this.employeeName = resp.data.empName;
          this.requestedFor = resp.data.requestedFor;
          this.appliedOn = resp.data.appliedOn;
          this.selected = "";
          this.dataSource = new MatTableDataSource<any>(resp.data.days);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;

          this.isLoadingDay = false;
        }
      },
      (error) => {
        this.isLoadingDay = false;
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error in getting leave days !");
      }
    );
  }
  public createCommentForm(): void {
    this.commentForm = this.fb.group({
      commnet: "",
    });
  }

  public close(): void {
    this.dialogRef.close();
  }
  public addComment(leaveDay): void {
    this.commentForm.reset();
    this.commentList = [];
    this.dayId = leaveDay;
    this.isLodaingCo = true;
    this.dialogRef = this.dialog.open(this.addComments, {
      width: "660px",
      autoFocus: false,
      maxHeight: "55vh",
    });

    this.service.getCommentsForLeaveDay(this.leaveId, this.employeeId, leaveDay).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.commentList = resp.data;
          this.isLodaingCo = false;
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.isLodaingCo = false;
        this.toastr.error(err || "Error in getting comments");
      }
    );
  }

  public submitComment() {
    this.isLodaingCo = true;
    this.service.assignCommentToLeaveDay(this.leaveId, this.employeeId, this.dayId, this.commentForm.value).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.toastr.success("Comment saved successfully");
          this.isLodaingCo = false;
          this.close();
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.isLodaingCo = false;
        this.toastr.error(err || "Error in saving comment");
      }
    );
  }

  public addActionsToList(dayId, event) {
    var obj = {
      action: event,
      empId: this.employeeId,
      leaveId: dayId,
    };
    this.actionsList.push(obj);
  }

  public saveBulkActions(): void {
    if (this.actionsList.length === 0) {
      this.toastr.success("Updated successfully !");
      return;
    }

    let temp = [];
    for (let ac of this.actionsList) {
      if (ac.action === "" || "") {
        ac.action = null;
      }
      temp.push(ac);
    }
    var object = {
      employeeId: this.employeeId,
      leaveId: this.leaveId,
      requests: temp,
    };
    this.isSaving = true;
    this.service.saveBulkActionsToDays(object).subscribe(
      (resp) => {
        this.isSaving = false;
        if (resp && resp.data) {
          this.toastr.success("Updated successfully !");
          this.getLeaveDays();
          this.actionsList = [];
        }
      },
      (error) => {
        this.isSaving = false;
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error in updating !");
      }
    );
  }

  public addCommentToLeave(): void {
    this.commentForm.reset();
    this.commentList = [];
    this.isLodaingCo = true;
    this.dialogRef = this.dialog.open(this.addCommentss, {
      width: "660px",
      autoFocus: false,
      maxHeight: "55vh",
    });
    this.service.getCommentsForLeave(this.leaveId, this.employeeId).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.commentList = resp.data;
          this.isLodaingCo = false;
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.isLodaingCo = false;
        this.toastr.error(err || "Error in getting comments");
      }
    );
  }

  public submitComments() {
    this.isLodaingCo = true;
    this.service.assignCommentToLeave(this.leaveId, this.employeeId, this.commentForm.value).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.toastr.success("Comment saved successfully");
          this.isLodaingCo = false;
          this.close();
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.isLodaingCo = false;
        this.toastr.error(err || "Error in saving comment");
      }
    );
  }

  public back() {
    if(this.action === 'my'){
      this.router.navigate(["/leave/my-leave"]);
    }else{
      this.router.navigate(["/leave/leave-list"]);
    }
    
  }
}
