import { throwError as observableThrowError, Observable, of } from "rxjs";
import { Injectable, Injector, NgZone } from "@angular/core";
import { HttpClient, HttpEvent, HttpHandler, HttpRequest } from "@angular/common/http";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router, NavigationEnd } from "@angular/router";
import { catchError, switchMap, tap } from "rxjs/operators";
import { environment } from "@env/environment";

const API_URL = environment.apiUrl;
@Injectable()
export class AuthService {
  public firstName: string;
  public lastName: string;

  constructor(
    private readonly http: HttpClient,
    private readonly injector: Injector,
    private readonly ngZone: NgZone
  ) {}

  private readonly jwtHelperService = new JwtHelperService();

  getApiUrl = () => {
    return `${API_URL}`;
  };

  isTokenExpired(): boolean {
    const token: string = this.getUserToken();
    if (!token) {
      return false;
    }

    return this.jwtHelperService.isTokenExpired(token);
  }

  isRefTokenExpired(): boolean {
    const token: string = this.getRefreshToken();
    if (!token) {
      return false;
    }

    return this.jwtHelperService.isTokenExpired(token);
  }

  public refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const tokenObj = JSON.parse(sessionStorage.getItem("currentUser"));
    const url = `${API_URL}api/auth/refreshToken`;
    const body = {
      refToken: tokenObj.refToken,
      client: tokenObj.client,
    };
    // if (tokenObj && tokenObj.refToken) {
    return this.http.post(url, body).pipe(
      switchMap(
        (resp) => {
          // if (resp) {
          this.postLoginRefresh(resp);
          request = this.addToken(request);
          return next.handle(request);
          // }
        }
        // (error) => {}
      )
    );

    // }
  }

  isAdmin(): boolean {
    try {
      return JSON.parse(sessionStorage.getItem("currentUser")).userRole.roleType === "Admin";
    } catch (err) {
      return false;
    }
  }

  public getUserRoleList(): Observable<any> {
    const url = `${API_URL}api/auth/roles`;
    return this.http.get(url);
  }

  getUserName(): string | undefined {
    try {
      const userName = JSON.parse(sessionStorage.getItem("currentUser")).username;
      return userName;
    } catch (err) {
      return undefined;
    }
  }

  private getUserToken(): string | undefined {
    try {
      const token = JSON.parse(sessionStorage.getItem("currentUser")).token;
      return token;
    } catch (err) {
      return undefined;
    }
  }

  private getRefreshToken(): string | undefined {
    try {
      const refToken = JSON.parse(sessionStorage.getItem("currentUser")).refToken;
      return refToken;
    } catch (err) {
      return undefined;
    }
  }

  public addToken(request: HttpRequest<any>) {
    const token = this.getUserToken();
    if (token !== undefined && token !== "") {
      const reqHeaders = { Authorization: `Bearer ${token}` };
      return request.clone({
        setHeaders: reqHeaders,
      });
    }
    return request;
  }

  public setPersmissions(dataGroup): void {
    sessionStorage.setItem("per", JSON.stringify(dataGroup));
  }

  getPermissions(): string | undefined {
    try {
      let getObject = sessionStorage.getItem("per");
      return getObject;
    } catch (err) {
      return undefined;
    }
  }

  postLoginRefresh(response): boolean {
    const data = response && response.data;
    if (data.token) {
      const token = data.token;
      const refToken = data.refreshToken;
      const userRole = data.role;
      // the below fetched from token itself
      const decodedToken = this.jwtHelperService.decodeToken(token);
      const username = decodedToken.sub;
      const client = decodedToken.client;
      const empId = decodedToken.empId;

      this.setPersmissions(userRole.dataGroup);
      sessionStorage.setItem("empId", empId);
      sessionStorage.setItem("username", username);
      sessionStorage.setItem(
        "currentUser",
        JSON.stringify({
          client: client,
          token,
          userRole,
          refToken: refToken,
          username: username,
        })
      );
      return true;
    } else {
      console.log("Did NOT receive token");
      return false;
    }
  }

  public login(body: any): Observable<any> {
    const url = `${API_URL}api/auth/login`;
    const reqBody = {
      email: body.username,
      password: body.password,
      client: body.client,
      //roleType: body.role
    };
    return this.http.post(url, reqBody).pipe();
  }

  public forgotPassword(inbody: any): Observable<any> {
    const url = `${this.getApiUrl()}api/auth/password/forgot`;
    const body = {
      email: inbody.username,
      client: inbody.client,
    };

    return this.http.post(url, body).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  public resetPassword(body: any): Observable<any> {
    const url = `${this.getApiUrl()}api/auth/password/reset`;

    return this.http.post(url, body).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  changePassword(body: any): Observable<any> {
    const url = `${this.getApiUrl()}api/auth/user/cp`;

    return this.http.post(url, body).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  clean(): void {
    sessionStorage.clear();
    localStorage.clear();
  }

  logout(message?: string): void {
    this.clean();
    const router = this.injector.get<Router>(Router);
    this.ngZone.run(() => {
      void router.navigate(["/login"], { queryParams: { message } });
    });
  }

  verifyEmail(token: string): Observable<any> {
    const url = `${this.getApiUrl()}user/verifyToken?token=${`${token}`}`;

    return this.http.get(url).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }
}
