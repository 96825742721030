<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
      <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
          <div class="col-md-8">
          <div class="page-header-title">
              <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted">{{lang.get("jobTitle")}}</h4></span>
          </div>
          </div>
          <div class="col-md-4"></div>
      </div>
      </div>
  </div>
  </div>


<div class="page-body no-title">
  <div class="row">
    <div class="col-sm-12">
      <div class="row top-tools">
        <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6 left-tools">
          <span class="export-icon-btn csv" (click)="exportToCsv()"><i
                  class="material-icons">get_app</i><span>{{lang.get("csv")}}</span>
          </span>
          <span class="export-icon-btn pdf" (click)="saveAsPdf()"><i class="material-icons">get_app</i><span>{{lang.get("pdf")}}</span>
          </span>
          <!-- <span (click)="demoClick()" class="export-icon-btn pdf"><i class="material-icons">get_app</i><span>PDF</span>
          </span> -->
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
          <div class="relative-position search-filter-con"> 
            <span class="icon material-icons ng-tns-c114-0"> search </span>
            <mat-form-field> 
              <input matInput #inputSearch type="search" [(ngModel)]="filterQuery" maxlength="40"
                     (ngModelChange)="onModelChange(filterQuery)"
                     class="form-control input-sm full-data-search" >
            </mat-form-field>
            <span class="mytooltip cus-left tooltip-effect-4" [hidden]="!write">
              <span class="tooltip-item">
                  <button mat-mini-fab class="btn btn-primary  light add-rounded margin-button-05" (click)="addNewUser()">
                      <i class="fa fa-plus"></i>
                  </button>
              </span>
              <span class="tooltip-content clearfix">
              <span class="tooltip-text">{{lang.get("new")}}</span>
              </span>
           </span>
          </div>
        </div>
      </div>
      <app-card [title]="'Job Title'">
        <div class="dt-responsive" *ngIf="dataSource">
          <div class="table-responsive table hrm-table-style-1 full-data-table table-cols-4" style="overflow:visible !important;">
            <mat-card-content>
              <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="select">
                  <mat-header-cell *matHeaderCellDef [hidden]="!delete"> 
                    <a [matMenuTriggerFor]="menu" class="check-all">
                                <span class="material-icons">
                                    more_horiz
                                </span>
                    </a>

                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="onSelectAllClicked()">{{lang.get("select all")}}</button>
                      <button (click)="onDeSelectAllClicked()" *ngIf="selectedCheckBoxList.length > 0" mat-menu-item>
                        {{lang.get("deselect all")}}
                      </button>
                      <button (click)="onDeleteSelected()" *ngIf="selectedCheckBoxList.length > 0" mat-menu-item>
                         {{lang.get("delete selected")}}
                        
                      </button>
                    </mat-menu>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" [hidden]="!delete">
                    <mat-checkbox #matCheckbox (change)="onCheckBoxChange($event, row.id)"
                                  value="{{row.id}}"></mat-checkbox>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="title">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>{{lang.get("title")}}</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.title }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="des">
                  <mat-header-cell *matHeaderCellDef>{{lang.get("jobDescription")}}</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <span *ngIf="!row.hasAttachment" class="mytooltip tooltip-effect-4">
                                            <span class="tooltip-item"><i (click)="onAddAttachment(row.id)"
                                                                          class="single-icon-click flaticon-attachment"
                                                                          style="color: #d0cdcd"></i></span>
                                            <span class="tooltip-content clearfix">
                                            <span class="tooltip-text">{{lang.get("noAttachment")}}</span>
                                            </span>
                                          </span>

                    <span *ngIf="row.hasAttachment" class="mytooltip tooltip-effect-4">
                                            <span class="tooltip-item"><i (click)="onAddAttachment(row.id)"
                                                                          class="single-icon-click flaticon-attachment"
                                                                          style="color: #666"></i></span>
                                            <span class="tooltip-content clearfix">
                                            <span class="tooltip-text">{{lang.get("includeAttachment")}}</span>
                                            </span>
                                          </span>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="edit"  >
                  <mat-header-cell [hidden]="!update" *matHeaderCellDef>{{lang.get("edit")}}</mat-header-cell>
                  <mat-cell [hidden]="!update" *matCellDef="let row">
                      <span class="mytooltip tooltip-effect-4">
                      <span class="tooltip-item"><i class="single-icon-click fa fa-pencil" (click)="editUser(row.id)"></i></span>
                      <span class="tooltip-content clearfix">
                      <span class="tooltip-text">{{lang.get("edit")}}</span>
                      </span>
                      </span>
                  </mat-cell>
                </ng-container>
                
                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columns;">
                </mat-row>
              </mat-table>
              <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
            </mat-card-content>
          </div>
          <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
        </div>
      </app-card>
    </div>
  </div>
</div>

<ng-template #addUser let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">
        <span *ngIf="!isEdit">{{lang.get("add")}}</span>
        <span *ngIf="isEdit">{{lang.get("edit")}}</span> 
        {{lang.get("jobTitle")}}
      </h4>
    </div>
    <div class="mat-modal-body">

    <!-- <div class="card my-0"> -->
      <!-- <div class="header">
        <h4 class="card-title card-title-modal">
          <span *ngIf="!isEdit">Add</span>
        <span *ngIf="isEdit">Edit</span> 
        Job Title</h4>
      </div> -->
     <!-- <div class="card-content"> -->
      <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>

      <form class="form-material full-width-inputs" action="javascript:" [formGroup]="userForm" *ngIf="!isUserAdded">
        <div class="row form-group">
          <div class="col-sm-6">
            <mat-form-field class="form-element">
              <mat-label>{{lang.get("title")}}</mat-label>
              <input pattern= "*" type="text" matInput class="form-control" maxlength="40" required="" id="title" formControlName="title" name="footer-email">
            </mat-form-field>    
          </div>
          <!--
          <div class="col-md-12"> 
            <mat-form-field class="form-element">
              <mat-label for="notes">Notes</mat-label>
              <input type="text" matInput class="form-control" maxlength="50"  id="notes" formControlName="note">
            </mat-form-field> 
          </div>
          -->
        </div>
      
      </form>
      <!--
        <div class="row mx-0" *ngIf="isEdit">
          <ng-container *ngIf="!replaceFile">
          <div class="col-md-12">Job Specification</div>
          <div class="fileName f-click col-sm-12" (click)="downloadFile()"><a href="{{fileUrl}}" target="_blank">{{fileName}}</a><i *ngIf="fileUrl" class="material-icons success">check</i></div>
          </ng-container>

          <div class="col-md-4">
            <input matInput type="radio" id="current" (change)="fileEdit()" [(ngModel)]="fileEditOption" name="fileR" value="keep"><span class="form-bar"></span><label class="float-label" for="current" class="r-label">Keep Current</label>
          </div>

          <div class=" col-md-4">
            <input matInput type="radio" id="delete" (change)="fileEdit()" [(ngModel)]="fileEditOption" name="fileR" value="delete"><span class="form-bar"></span><label class="float-label" for="delete" class="r-label">Delete Current</label>
          </div>

          <div class=" col-md-4">
            <input matInput type="radio" id="replace" (change)="fileEdit()" [(ngModel)]="fileEditOption" name="fileR" value="replace"><span class="form-bar"></span><label class="float-label" for="replace" class="r-label">Replace Current</label>
          </div>
        </div>
        -->
        <div class="row my-2 mx-2"> 
          <div class="action-buttons multi-btns col-sm-12 text-right">
            <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i>Cancel</button>
            <button (click)="submitAddJobTitle()" class="btn-primary" color="primary" mat-raised-button><i
                    class="flaticon-diskette"></i>Save
            </button>
          </div>
        </div>
 
      </div>
    </div>

</ng-template>


<ng-template #deleteUser let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">{{lang.get("delete")}} {{lang.get("jobTitle")}}</h4>
    </div>
    <div class="mat-modal-body">
      <div class="text-left are-u">{{lang.get("areyousure")}}</div>
      <div class="row">
            <div class="multi-btns col-sm-12 text-right">
              <button mat-raised-button class="btn-danger" (click)="close()">
                  <i class="flaticon-cancel"></i>{{lang.get("cancel")}}
              </button>
              <button mat-raised-button class="btn-primary" color="primary" (click)="deleteJobTitle()">
                  <i class="flaticon-trash"></i>{{lang.get("delete")}}
              </button>
          </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #demo let-modal>
  <div mat-dialog-actions>
      <div class="mat-modal-header">
       <h4 class="mat-title-modal">Modal Title</h4>
      </div>
    <div class="mat-modal-body">
      <div class="action-buttons multi-btns col-sm-12 text-right">
        <button class="btn btn-primary" color="primary" mat-raised-button>yes</button>
        <button (click)="onClose()" class="btn btn-cancel btn-danger" color="secondary" mat-raised-button>No</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #attach let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
     <h4 class="mat-title-modal">{{lang.get("attachment")}}</h4>
    </div>
  <div class="mat-modal-body">
    <app-loading *ngIf="this.loadAttach" [isLoading]="loadAttach"></app-loading>
  <!-- <div class="card my-0">
    <div class="header">
      <h4 class="card-title card-title-modal">
        Attachment
      </h4>
    </div>
    <div class="card-content"> -->
      <form class="form-material full-width-inputs" *ngIf="!loadAttach">
        <div class="row">
          <div class="col-md-3">
            <div class="upload-btn-wrapper">
              <button class="upload-btn"><i class="flaticon-attachment"></i>  {{lang.get("attach")}}</button>
              <input (change)="fileChange($event, 'dd')" class="form-control" name="myfile" type="file">
              <div class="fileName">{{this.fileObj && this.fileObj.fileName}}<i *ngIf="fileUrl"
                                                                                class="fa fa-check success"></i>
              </div>
            </div>
            <div class="upload-btn-wrapper">
            </div>
            <div *ngIf="!fileUrl && fileError" class="file-error text-danger">{{fileError}}</div>
         </div>
         <div class="help-text">{{lang.get("accepteUpTO2MB")}}</div>
        </div>
          <div class="row">
          <div class="col-md-12">
            <mat-form-field class="form-element">
              <input matInput [(ngModel)]="comment"  maxlength="40" class="form-control"  name="name" type="text">
              <span class="form-bar"></span>
              <mat-label for="title">{{lang.get("comment")}}</mat-label>
            </mat-form-field>
          </div>
          <!-- <div class="col-md-12 mt-1 border" style="height: 200px !important; overflow-y: auto"> -->
            <div class="col-md-12  border" style="height: 200px !important; overflow-y: auto">
            <mat-card *ngIf="this.attachmentList.length === 0">{{lang.get("noAttachmentFound")}}
            </mat-card>
            <ng-container *ngFor="let attachment of attachmentList; let i = index;">
              <mat-card class="mt-2 shadow-sm">
                <mat-card-title>
                  {{attachment.fileName}}
                </mat-card-title>
                <mat-card-content>
                  <b>{{lang.get("addedOn")}}</b> {{attachment.added}} <br>
                  <b>{{lang.get("comments")}}:</b> {{attachment.desc}}
                </mat-card-content>
                <mat-card-actions align="end">
                  <button (click)="onDeleteClicked(attachment)"
                          aria-label="Example icon button with a vertical three dot icon"
                          mat-icon-button>
                    <mat-icon color="warn">{{lang.get("delete")}}</mat-icon>
                  </button>
                  <button (click)="onDownloadClicked(attachment)"
                          aria-label="Example icon button with a vertical three dot icon"
                          mat-icon-button>
                    <mat-icon color="primary">cloud_download</mat-icon>
                  </button>
                </mat-card-actions>
              </mat-card>
            </ng-container>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-12 text-right">
            <!-- <mat-button (click)="saveAttachment()" class="btn btn-primary" name="button" type="button">
              <i class="flaticon-diskette"></i>Save
            </mat-button> -->

            <button mat-raised-button (click)="saveAttachment()" class="btn-primary" color="primary" name="button">
              <i class="flaticon-diskette"></i>
              {{lang.get("save")}}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
