import { Component, OnInit, NgZone, ViewChild, OnDestroy } from "@angular/core";
import { DashboardService } from "../../dashboard.service";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { NotificationService } from "src/app/core/services/notification.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { animate, style, transition, trigger, state, AUTO_STYLE } from "@angular/animations";
import { MatStepper } from "@angular/material/stepper";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ImageCropperComponent, CropperSettings } from "ngx-img-cropper";
import { ImageUtilService } from "src/app/service/image-util.service";
import { AuthService } from "src/app/core/services/auth.service";

declare var jQuery: any;

@Component({
  selector: "app-add-employee",
  templateUrl: "./add-employee.component.html",
  styleUrls: ["./add-employee.component.scss"],
  animations: [
    trigger("notificationBottom1", [
      state(
        "an-off, void",
        style({
          overflow: "hidden",
          height: "0px",
        })
      ),
      state(
        "an-animate",
        style({
          overflow: "visible",
          height: AUTO_STYLE,
        })
      ),
      transition("an-off <=> an-animate", [animate("0ms ease-in-out")]),
    ]),
    trigger("slideInOut", [
      state(
        "in",
        style({
          width: "380px",
          // transform: 'translate3d(0, 0, 0)'
        })
      ),
      state(
        "out",
        style({
          width: "0",
          // transform: 'translate3d(100%, 0, 0)'
        })
      ),
      transition("in => out", animate("250ms ease-in-out")),
      transition("out => in", animate("250ms ease-in-out")),
    ]),
    trigger("mobileHeaderNavRight1", [
      state(
        "nav-off, void",
        style({
          overflow: "hidden",
          height: "0px",
        })
      ),
      state(
        "nav-on",
        style({
          height: AUTO_STYLE,
        })
      ),
      transition("nav-off <=> nav-on", [animate("250ms ease-in-out")]),
    ]),
    trigger("fadeInOutTranslate", [
      transition(":enter", [style({ opacity: 0 }), animate("250ms ease-in-out", style({ opacity: 1 }))]),
      transition(":leave", [style({ transform: "translate(0)" }), animate("250ms ease-in-out", style({ opacity: 0 }))]),
    ]),
    trigger("mobileMenuTop", [
      state(
        "no-block, void",
        style({
          overflow: "hidden",
          height: "0px",
        })
      ),
      state(
        "yes-block",
        style({
          height: AUTO_STYLE,
        })
      ),
      transition("no-block <=> yes-block", [animate("250ms ease-in-out")]),
    ]),
  ],
})
export class AddEmployeeComponent implements OnInit, OnDestroy {
  public section = "pp";
  public fileName = "";
  public tmore: number;
  public isLoading: boolean;
  public fileClean: string;
  public dropdownErr: string;
  public modalRef: any;
  public employeeForm: FormGroup;
  public jobForm: FormGroup;
  public personalForm: FormGroup;
  public salaryForm: FormGroup;
  public terminateForm: FormGroup;
  public nationalityList: Array<any> = [];
  public countryList: Array<any> = [];
  public payGradeList: Array<any> = [];
  public earningList: Array<any> = [];
  public deductionList: Array<any> = [];
  public currencyList: Array<any> = [];
  public pid: string;
  public salaryList: Array<any> = [];
  public formStatus: Array<any> = [];
  public step: number;
  public fileUrl: string;
  public payrollList: Array<any> = [];
  public jobCategoryList: Array<any> = [];
  public costCenterList: Array<any> = [];
  public workShiftList: Array<any> = [];
  public companyList: Array<any> = [];
  public jobTitleList: Array<any> = [];
  public employeeStatusList: Array<any> = [];
  public regionList: Array<any> = [];
  public action: string;
  public editId: string;
  public createUser: boolean;
  public adminList = [];
  public essList = [];
  public supervisorList = [];
  public icon = "flaticon-more";
  public userRole: string = "";
  uploadedImage: any;

  public isShow: boolean = false;

  // for the edit employee attachment

  // public columns = ['fileName', 'url', 'desc', 'size', 'added', 'delete'];

  public locationList = [];
  public roleList: Array<any> = [];
  public fileError: string;
  public fileType = "";
  public employeeName = "";
  public employeeImage = "";
  public profileNotification1: string;
  public profileNotificationClass1: string;
  public newIdGenByServer: string;
  public showEmpNumberMessage: string;
  // for the sake of getting sub unit
  @ViewChild("sample", { static: false })
  public subunitData: object[] = [];
  //

  public activityList = [
    { value: "arts", label: "Arts" },
    { value: "dancing", label: "Dancing" },
    { value: "music", label: "Music" },
    { value: "arts", label: "Arts" },
  ];
  public sportsList = [
    { value: "basketball", label: "Basketball" },
    { value: "baseball", label: "Baseball" },
    { value: "football", label: "Football" },
    { value: "golf", label: "Golf" },
    { value: "handball", label: "Handball" },
    { value: "rugger", label: "Rugger" },
    { value: "squash", label: "Squash" },
  ];
  public sub = {
    id: "",
    reportingMethod: "",
  };
  public terminationList = [];
  public validationError = {
    required: "Required",
    email: "Please provide a valid email",
    password: "Please provide a password of atleast 8 digits",
    contact: "Please provide a contact of 10 digits",
  };
  public comment: string;
  public fileObj: any;
  public contactObj: any;
  public contactFile: any;
  public uploadedFile: any;
  public employeeList = [];
  public reportingTypes = [];
  public moreopt: string;
  public More = "More";
  public editAttachmentId: string;
  public deleteAttachmentId: string;
  public filteredOptions: Array<any> = [];
  public correct = true;
  public basicValue = {
    sc: "Basic",
    value: "",
  };
  public totalE = 0;
  public totalD = 0;
  public totalP = 0;
  public event: string;
  @ViewChild("attach", { static: false }) attach;
  @ViewChild("editAttach", { static: false }) editAttach;
  @ViewChild("deleteAttachment", { static: false }) deleteAttachment;
  @ViewChild("terminate", { static: false }) terminate;
  @ViewChild("showEmpNumber", { static: false }) showEmpNumber;
  @ViewChild("stepper") stepper: MatStepper;

  data: any;
  cropperSettings: CropperSettings;

  @ViewChild("cropper") cropper: ImageCropperComponent;
  @ViewChild("uploadImage", { static: false }) uploadImageDialog;

  public dialogRef: any;
  public uploadDialogRef: any;
  public requiredJob: any = {
    commentAvailable: true,
    commentRequired: false,
    contractEndDateAvailable: true,
    contractEndDateRequired: false,
    contractStartDateAvailable: true,
    contractStartDateRequired: false,
    costCenterAvailable: true,
    costCenterRequired: false,
    employmentStatusAvailable: true,
    employmentStatusRequired: false,
    jobCategoryAvailable: true,
    jobCategoryRequired: false,
    jobTitleAvailable: true,
    jobTitleRequired: false,
    probationEndDateAvailable: true,
    probationEndDateRequired: false,
    workShiftAvailable: true,
    workShiftRequired: false,
  };

  public requiredPersonal: any = {
    dateOfBirthAvailable: true,
    dateOfBirthRequired: false,
    driverLicenseNumberAvailable: true,
    driverLicenseNumberRequired: false,
    genderAvailable: true,
    genderRequired: false,
    licenseExpiryDateAvailable: true,
    licenseExpiryDateRequired: false,
    martialStatusAvailable: true,
    martialStatusRequired: false,
    middleNameAvailable: true,
    middleNameRequired: false,
    militaryServiceAvailable: true,
    militaryServiceRequired: false,
    nickNameAvailable: true,
    nickNameRequired: false,
    smokerAvailable: true,
    smokerRequired: false,
  };

  public moreTxt: string;
  imageUploading = false;
  public editAttachmentData: any;
  // for getting the department and the sub department
  public structureList: Array<any> = [];
  public temporaryDepartment: Array<any> = [];
  // @ViewChild('dept') selectDeparment: ElementRef;
  public CountryToDisable: any;
  public isCountry = true;
  public currencyCodeName: any;
  public currentPaygrade: any;

  public isLoadingReq: boolean = true;
  public statusSelected = "active";
  filteredOptions1: any[];

  public isInProgress:boolean;

  ngOnDestroy() {
    this.section = null;
    this.fileName = null;
    this.tmore = null;
    this.fileClean = null;
    this.personalForm = null;
    this.modalRef = null;
    this.dropdownErr = null;
    this.jobForm = null;
    this.salaryForm = null;
    this.terminateForm = null;
    this.employeeForm = null;
    this.personalForm = null;
    this.nationalityList = null;
    this.countryList = null;
    this.payGradeList = null;
    this.earningList = null;
    this.deductionList = null;
    this.currencyList = null;
    this.locationList = null;
    this.pid = null;
    this.salaryList = null;
    this.formStatus = null;
    this.step = null;
    this.fileUrl = null;
    this.payrollList = null;
    this.jobCategoryList = null;
    this.costCenterList = null;
    this.workShiftList = null;
    this.companyList = null;
    this.jobTitleList = null;
    this.employeeStatusList = null;
    this.regionList = null;
    this.action = null;
    this.editId = null;
    this.createUser = null;
    this.adminList = null;
    this.essList = null;
    this.supervisorList = null;
    this.uploadedImage = null;
    this.icon = null;
    this.roleList = null;
    this.fileError = null;
    this.fileType = null;
    this.employeeName = null;
    this.employeeImage = null;
    this.profileNotification1 = null;
    this.profileNotificationClass1 = null;
    this.newIdGenByServer = null;
    this.showEmpNumberMessage = null;
    this.subunitData = null;
    this.activityList = null;
    this.sportsList = null;
    this.sub = null;
    this.terminationList = null;
    this.validationError = null;
    this.comment = null;
    this.fileObj = null;
    this.contactObj = null;
    this.contactFile = null;
    this.uploadedFile = null;
    this.reportingTypes = null;
    this.moreopt = null;
    this.More = null;
    this.editAttachmentId = null;
    this.deleteAttachmentId = null;
    this.basicValue = null;
    this.event = null;
    this.attach = null;
    this.editAttach = null;
    this.deleteAttachment = null;
    this.terminate = null;
    this.showEmpNumber = null;
    this.stepper = null;
    this.cropperSettings = null;
    this.cropper = null;
    this.uploadImageDialog = null;
    this.service = null;
    this.fb = null;
    this.toastr = null;
    this.route = null;
    this.router = null;
    this.dialogRef = null;
    this.dialog = null;
    this.ngZone = null;
    this.imgUtil = null;
    this.authService = null;
  }
  constructor(
    private service: DashboardService,
    private fb: FormBuilder,
    private toastr: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private dialog: MatDialog,
    private ngZone: NgZone,
    private imgUtil: ImageUtilService,
    private authService: AuthService
  ) {
    this.createForm();
    this.tmore = 0;
    this.createTerminateForm();
    // getting the response for the department and department from the structure
    this.structureList = new Array<any>();
    this.temporaryDepartment = new Array<any>();

    this.cropperSettings = new CropperSettings();
    this.cropperSettings.width = 100;
    this.cropperSettings.height = 100;
    this.cropperSettings.croppedWidth = 100;
    this.cropperSettings.croppedHeight = 100;
    this.cropperSettings.canvasWidth = 300;
    this.cropperSettings.canvasHeight = 300;
    this.cropperSettings.rounded = true;

    this.cropperSettings.noFileInput = true;

    this.data = {};
  }

  reportingMethodName(id) {
    if (!id) {
      return "";
    }
    const item = this.reportingTypes.find((t) => t.value === id);
    if (!item) {
      return id;
    }
    return item.label;
  }
  public permissions: any;

  public write = false;

  checkPermission(module, tabs): void {
    this.permissions = JSON.parse(this.authService.getPermissions());

    this.write = false;

    if (this.permissions != undefined && this.permissions != null) {
      for (let p of this.permissions) {
        if (p.id === module) {
          if (p.modules != undefined) {
            for (let tab of p.modules) {
              if (tab.id === tabs) {
                for (let move of tab.permits) {
                  if (move.id === "c") {
                    this.write = true;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  ngOnInit() {
    this.userRole = "";
    this.isLoadingReq = true;

    this.checkPermission("pdm", "pd_ae");
    this.service.getRequiredFieldsBySection("pe").subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.requiredPersonal = resp.data;
          this.isLoadingReq = false;
        }
      },
      (error) => {
        this.isLoadingReq = false;
        const err = error.error && error.error.msg;
        this.toastr.error(err);
      }
    );

    this.service.getRequiredFieldsBySection("jb").subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.requiredJob = resp.data;
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.toastr.error(err);
      }
    );
    // getting the response for the department dropdown
    this.action = "add";
    this.route.queryParamMap.subscribe((data) => {
      if (data["params"]) {
        this.action = data["params"]["action"];
        if (this.action && this.action === "edit") {
          this.editId = data["params"]["id"];
          // to get the list of the attachment
        } else {
          this.action = "add";
        }
      }
      if (this.action === "add") {
        this.isLoading = false;
        this.service.generateId("employeeId").subscribe(
          (resp) => {
            if (resp && resp.data) {
              this.newIdGenByServer = resp.data.value;
              this.employeeForm.patchValue({ empid: this.newIdGenByServer });
              this.personalForm.patchValue({ empid: this.newIdGenByServer });
            }
          },
          (error) => {
            const err = error.error && error.error.msg;
            this.toastr.error(err || "Error in editing employee");
          }
        );
        this.service.getNationalityListDropDwon().subscribe((resp) => {
          if (resp && resp.data) {
            this.nationalityList = resp.data;
            this.filteredOptions1 = this.nationalityList;
            this.initValuesDetection();
          }
        });
        this.service.getEmployeeDropdown().subscribe((resp) => {
          this.jobCategoryList = resp[0].data;
          this.locationList = resp[1].data;
          this.costCenterList = resp[2].data;
          this.workShiftList = resp[3].data;
          this.jobTitleList = resp[4].data;
          this.employeeStatusList = resp[5].data;
          this.countryList = resp[6].data;
          this.regionList = resp[7].data;
        });

        this.service.getEmployeeDropdown2().subscribe(
          (resp) => {
            this.salaryList = resp[0] && resp[0].data && resp[0].data;
            this.terminationList = resp[1].data;
            if (resp[2] && resp[2].data) {
              this.roleList = resp[2].data;
              this.roleList.map((d) => {
                this.adminList.push(d);
              });
            }
            this.salaryList.map((m) => {
              m["value"] = "";
              m["status"] = true;
            });
            this.deductionList = this.salaryList.filter((s) => {
              return s.type === "D";
            });
            this.earningList = this.salaryList.filter((s) => {
              return s.type === "E";
            });
          },
          (error) => {
            this.isLoading = false;
            this.dropdownErr = "Error in getting dropdown value";
            this.toastr.error(this.dropdownErr);
          }
        );
      }
    });
  }
  initValuesDetection() {
    this.filteredOptions1 = this.nationalityList;

    this.personalForm.controls["nationality"].valueChanges.subscribe((change) => {
      if (change == null || change == undefined) {
        this.correct = true;
      } else {
        this.filterNationality(change);
      }
    });
  }
  protected filterNationality(change: String) {
    if (!this.nationalityList) {
      this.filteredOptions1 = [];
      return;
    }

    // get the search keyword
    if (!change) {
      this.correct = true;

      this.filteredOptions1 = [];
      return;
    } else {
      this.checkNationalityChange(change);
      change = change.toLowerCase();
    }
    // filter the search
    this.filteredOptions1 = this.nationalityList.filter((co) => co.name.toLowerCase().indexOf(change) > -1);
  }
  changeStatus(): void {
    this.correct = true;
  }
  checkNationalityChange(change) {
    const x = this.filteredOptions1.find((x) => x.id === change);
    if (!x) {
      this.correct = false;
    }
  }

  public displayNationlaityByID(option) {
    this.correct = true;

    return this.nationalityList.find((x) => x.id === option).name;
  }
  ngAfterViewInit() {
    this.service.getNationalityListDropDwon().subscribe((resp) => {
      if (resp && resp.data) {
        this.nationalityList = resp.data;
        this.initValuesDetection();
      }
    });
    this.service.getStructureList().subscribe((resp) => {
      if (resp && resp.data) {
        this.temporaryDepartment = resp.data;
      }
    });
    this.service.getEmployeeDropdown().subscribe((resp) => {
      this.jobCategoryList = resp[0].data;
      this.locationList = resp[1].data;
      this.costCenterList = resp[2].data;
      this.workShiftList = resp[3].data;
      this.jobTitleList = resp[4].data;
      this.employeeStatusList = resp[5].data;
      this.countryList = resp[6].data;
      this.regionList = resp[7].data;
      this.payrollList = resp[8].data;
      this.payGradeList = resp[9] && resp[9].data;
    });
    this.service.getEmployeeDropdown2().subscribe(
      (resp) => {
        this.salaryList = resp[0] && resp[0].data && resp[0].data;
        this.terminationList = resp[1].data;

        this.salaryList.map((m) => {
          m["value"] = "";
          m["status"] = true;
        });
        this.deductionList = this.salaryList.filter((s) => {
          return s.type === "D";
        });
        this.earningList = this.salaryList.filter((s) => {
          return s.type === "E";
        });
      },
      (error) => {
        this.isLoading = false;
        this.dropdownErr = "Error in getting dropdown value";
        this.toastr.error(this.dropdownErr);
      }
    );
  }
  public createTerminateForm(): void {
    this.terminateForm = this.fb.group({
      date: ["", Validators.required],
      reason: ["", Validators.required],
      note: [""],
      adjustLeaveEntitlement: [],
    });
  }
  OnTerminateEmployment() {
    this.dialogRef = this.dialog.open(this.terminate, {
      width: "560px",
      height: "auto",
    });
    this.terminateForm.reset();
  }
  public terminateEmployee(): void {
    this.service.terminateEmployment(this.terminateForm.value, this.editId).subscribe(
      (res) => {
        this.toastr.success("Employment Terminated successfully!");
        this.dialogRef.close();
        this.ngZone.run(() => {
          void this.router.navigate(["/pim/employees"]);
        });
      },
      (error) => {
        const msg = error && error.error.msg;
        this.toastr.error(msg || "Error in Employment Termination");
      }
    );
  }

  public hideTerminate(): void {
    this.dialogRef.close();
  }

  initProduct(obj: any) {
    return this.fb.group({
      roleType: obj.roleType,
      userRole: obj.userRole,
    });
  }
  public createForm(): void {
    this.personalForm = this.fb.group({
      firstname: [""],
      lastname: [""],
      middlename: [""],
      empid: [""],
      dob: [""],
      email: [""],
      username: [""],
      smoker: [false],
      smoking: [""],
      licenseexpirydate: [""],
      maritalstatus: [""],
      militaryservice: [""],
      nationality: [""],
      otherid: [""],
      drivinglicenseno: [""],
      gender: [""],
      password: [""],
      status: ["ACTIVE"],
      createlogindetail: [false],
      nickname: [""],
      roles: this.fb.array([]),
      roles2: this.fb.array([
        this.fb.group({
          roleType: "",
          userRole: "",
        }),
      ]),
      image: [""],
      fullPathImage: [""],
      bloodgroup: [""],
      activities: [""],
      sports: [""],
      food: [""],
      hobbies: [""],
    });
    this.jobForm = this.fb.group({
      jobcategoryid: [""],
      jobtitelid: [""],
      contractenddate: [""],
      contractstartdate: [""],
      costcenterid: [""],
      dateofpermanency: [""],
      effectivefrom: [""],
      employeestatus: [""],
      workshiftid: [""],
      joinedgdate: [""],
      endDate: [""],
      ppId: [""],
      locationid: [""],
      // paygradeid: [''],
      comment: [""],
      region: [""],
      fte: [""],
      temporarydepartment: [""],
    });
    this.employeeForm = this.fb.group({
      subId: [""],
      supId: [""],
      subReport: [""],
      supReport: [""],
      id: [""],
      companyid: [""],
      addresses: this.fb.array([
        this.fb.group({
          addresslineone: [""],
          addresslinetwo: [""],
          city: [""],
          contactone: ["", [Validators.minLength(10), Validators.maxLength(10)]],
          contacttwo: ["", [Validators.minLength(10), Validators.maxLength(10)]],
          country: [""],
          countrycode: [""],
          countryiso: [""],
          id: [""],
          landmark: [""],
          mobile: ["", [Validators.minLength(10), Validators.maxLength(10)]],
          otheremail: [""],
          postalcode: [""],
          state: [""],
          type: [""],
          workemail: [""],
        }),
      ]),
      // supervisors: this.fb.array([]),
      // subordinates: this.fb.array([])
    });
    this.salaryForm = this.fb.group({
      currencyCode: [""],
      currencyName: [""],
      max: [""],
      min: [""],
      paygradeid: [""],
    });
  }
  /**
   * testrami
   */
  public setRoleType(event): void {
    this.userRole = event;
    console.log(event);
  }
  public submitEmployee(): void {

    if (!this.correct) {
      this.toastr.error("Please Select Nationality from the dropdown in personal details");
      return;
    }
    let roles = [];

    console.log(this.userRole);
    console.log(this.adminList);
    var obj = {
      userRole: "",
      roleType: "",
    };
    for (let r of this.adminList) {
      if (this.userRole === r.id) {
        console.log("Wow!");
        obj = {
          userRole: r.id,
          roleType: r.roleType,
        };

        roles.push(obj);
      }
    }

    if (roles.length === 0) {
      obj = {
        userRole: "",
        roleType: "Admin",
      };
      roles.push(obj);
    }

    console.log(roles);

    const control = <FormArray>this.personalForm.controls["roles"];
    const productCtrl = this.initProduct(roles[0]);
    control.push(productCtrl);

    if (
      this.personalForm.value.email != "" &&
      this.personalForm.value.email != null &&
      this.personalForm.value.email != undefined
    ) {
      this.personalForm.patchValue({
        username: this.personalForm.value.email,
      });
    }

    if (
      this.jobForm.controls.effectivefrom.invalid ||
      this.jobForm.controls.locationid.invalid ||
      this.jobForm.controls.region.invalid ||
      this.jobForm.controls.temporarydepartment.invalid
    ) {
      let control;
      Object.keys(this.jobForm.controls)
        .reverse()
        .forEach((field) => {
          if (this.jobForm.get(field).invalid) {
            control = this.jobForm.get(field);
            control.markAsDirty();
          }
        });
      return;
    } else {
      if (!this.personalForm.value.activities) {
        this.personalForm.value.activities = null;
      }
      if (!this.personalForm.value.sports) {
        this.personalForm.value.sports = null;
      }

      this.personalForm.value.smoker = this.personalForm?.value?.smoking === "yes";
      delete this.personalForm?.value?.smoking;

      const employeeRequest = {
        employeeForm: this.employeeForm.value,
        jobForm: this.jobForm.value,
        personalForm: this.personalForm.value,
        salaryFrom: this.salaryForm.value,
      };

      if (this.action === "add") {
        this.isInProgress = true;
        this.service.addEmployeeForm(employeeRequest).subscribe(
          (resp) => {
            this.showEmpNumberMessage = resp.msg;
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true; 
            dialogConfig.width = "560px"
            dialogConfig.height = "auto";

            this.dialogRef = this.dialog.open(this.showEmpNumber, dialogConfig);
            
            this.action = "edit";
            if (resp && resp.data && resp.data.id) {
              this.editId = resp.data.id;
            }
            this.isInProgress = false;
            this.toastr.success("Employee added successfully");
          },
          (error) => {
            this.isInProgress = false;
            const err = error.error && error.error.msg;
            this.toastr.error(err || "Error in adding employee");
          }
        );
      }
    }
  }

  public goForward(stepper: MatStepper, step) {
    stepper.next();
    this.step = step;
  }

  public goBack(stepper: MatStepper) {
    stepper.previous();
    if (this.step !== 0) {
      this.step = this.step - 1;
    } else {
      this.step = 1;
    }
  }

  public fillCountryDetail(): void {
    if (this.CountryToDisable) {
      this.isCountry = false;
    }
    const val = this.employeeForm.value;
    const c = val.addresses[0].country;
    const cl = this.countryList.filter((clist) => {
      return clist.name === c;
    });
    val.addresses[0].countrycode = cl[0].code;
    val.addresses[0].countryiso = cl[0].iso;
    this.employeeForm.patchValue(val);
  }

  public addAttachment(): void {
    this.editAttachmentId = "";
    this.comment = "";
    this.fileType = "";
    this.fileObj = {};
    this.fileUrl = "";
    this.fileName = "";
    this.dialogRef = this.dialog.open(this.attach, {
      width: "440px",
      height: "auto",
    });
  }

  public profileChange($event) {
    const reader = new FileReader();
    const file = $event.target.files[0];
    const profileFile = file;
    const fileName = file && file.name;
    if (file && file.size / 1024 < 2048) {
      this.imageUploading = true;
      this.service.uploadFile(profileFile).subscribe(
        (resp) => {
          if (resp && resp.data) {
            const val = this.personalForm.value;
            val["image"] = resp.data.filename;
            val["fullPathImage"] = resp.data.fullPathFilename;
            this.personalForm.patchValue(val);
          }
          this.imageUploading = false;
        },
        (err) => {
          this.imageUploading = false;
        }
      );
    } else {
      this.fileError = "Max size 2 MB";
      this.toastr.error("Max 2Mb Image allowed.");
    }
  }

  public notificationOutsideClick(ele: string) {
    if (this.profileNotification1 === "an-animate" && !this.tmore) {
      this.toggleProfileNotification();
    }
  }

  public toggleProfileNotification() {
    this.profileNotification1 = this.profileNotification1 === "an-off" ? "an-animate" : "an-off";
    this.profileNotificationClass1 = this.profileNotification1 === "an-animate" ? "active" : "";
  }

  public setAny(opt): void {
    const obj = {
      cd: "Contact Detail",
      sd: "Social Media Details",
      ec: "Emergency Contacts",
      dt: "Dependents",
      im: "Immigration",
      rt: "Report To",
      qq: "Qualification",
      mm: "Membership",
    };
    if (opt === "qq") {
      this.More = "Qualification";
      this.icon = "flaticon-diploma-1";
    }
    if (opt === "cd") {
      this.More = "Contact Detail";
      this.icon = "flaticon-communicate";
    }
    if (opt === "sd") {
      this.More = "Social Media Details";
      this.icon = "flaticon-social-media";
    }
    if (opt === "ec") {
      this.More = "Emergency Contacts";
      this.icon = "flaticon-contact";
    }
    if (opt === "dt") {
      this.More = "Dependents";
      this.icon = "flaticon-family";
    }
    if (opt === "im") {
      this.More = "Immigration";
      this.icon = "flaticon-destination";
    }
    if (opt === "rt") {
      this.More = "Report To";
      this.icon = "flaticon-profiles";
    }
    if (opt === "dd") {
      this.More = "Direct Deposit";
      this.icon = "flaticon-salary-1";
    }
    if (opt === "ww") {
      this.More = "Work week";
      this.icon = "flaticon-calendar";
    }
    this.moreTxt = obj[opt];
    this.moreopt = opt;
    this.tmore = 0;
  }

  public open(url): void {
    window.open(url);
  }

  /**
   * setInitVal
   */

  public test(t, section): void {
    this.section = section;
    this.tmore = t;
    this.profileNotification1 = "an-animate";
    this.profileNotificationClass1 = this.profileNotification1 === "an-animate" ? "active" : "";
  }

  public onChange(args: any): void {
    this.employeeForm.controls.temporarydepartment.setValue("");
  }

  OnConfirmation() {
    this.dialogRef.close();
    this.ngZone.run(() => {
      void this.router.navigate(["/pim/employees"]);
    });
  }

  perminantImage: any;

  fileChangeListener(e: any) {
    this.imageUploading = false;
    var image: any = new Image();
    this.perminantImage = new Image();
    var file: File = e.target.files[0];
    this.fileName = file.name;
    var myReader: FileReader = new FileReader();
    var that = this;
    myReader.onloadend = function (loadEvent: any) {
      image.src = loadEvent.target.result;
      that.perminantImage.src = image.src;
      that.cropper.setImage(image);
    };

    this.isShow = true;

    myReader.readAsDataURL(file);
  }

  openUploadDialog() {
    // this.data = {};

    this.uploadDialogRef = this.dialog.open(this.uploadImageDialog, {
      width: "500px",
      height: "auto",
    });

    this.uploadDialogRef.afterOpened().subscribe((res) => {
      if (this.perminantImage != null) this.cropper.setImage(this.perminantImage);
    });
  }

  imageLoaded = false;

  deleteProfileImage() {
    this.data = {};
    this.isShow = false;
    this.uploadDialogRef.close();
  }

  public uploadProfileImage() {
    this.uploadedImage = this.cropper.cropper.getCroppedImage(true).src;
    const imageName = "name.png";
    const imageBlob = this.imgUtil.dataURItoBlob(this.uploadedImage);
    const uploadedImageFile = new File([imageBlob], imageName, { type: "image/png" });

    this.imageUploading = true;
    console.log("start uploading");
    const reader = new FileReader();

    const file = uploadedImageFile;
    const profileFile = file;
    const fileName = file && file.name;
    if (file && file.size / 1024 < 2048) {
      this.imageUploading = true;
      this.service.uploadFile(profileFile).subscribe(
        (resp) => {
          if (resp && resp.data) {
            console.log("Uploaded successfully");
            const val = this.personalForm.value;
            val["image"] = resp.data.filename;
            val["fullPathImage"] = resp.data.fullPathFilename;
            console.log(val);
            this.personalForm.patchValue(val);

            this.imageLoaded = true;
            this.uploadDialogRef.close();
            this.imageUploading = false;
          }
        },
        (err) => {
          this.uploadDialogRef.close();
          this.imageUploading = false;
        }
      );
    } else {
      this.uploadDialogRef.close();
      this.toastr.error("Max 2Mb Image allowed.");
    }
  }

  close() {
    this.isShow = false;
    this.data = {};
    this.uploadDialogRef.close();
  }

  getStyle() {
    let styles = {
      padding: !this.imageLoaded ? "35px" : "5px",
    };

    return styles;
  }
}
