import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { NotificationService } from "src/app/core/services/notification.service";
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {FormControl} from '@angular/forms';
import {MatSelect} from '@angular/material/select';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DashboardService} from '../../dashboard.service';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {saveAs} from 'file-saver-es';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox';
import { DatePipe } from '@angular/common';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-disciplinary-cases',
  templateUrl: './disciplinary-cases.component.html',
  styleUrls: ['./disciplinary-cases.component.scss']
})
export class DisciplinaryCasesComponent implements OnInit {

  public filterQuery = '';

  public typeForm: any;
  public disciplineForm: FormGroup;
  public editDataSave: any;

  disAction: any;

  actions: any[];

  displayedColumns = ['select', 'empId' ,'empName', 'caseName', 'createdBy', 'createdOn', 'dis_action', 'status' ,'actions'];
  displayedColumnsFile = ['employeeId' ,'employee', 'caseName', 'cretedBy', 'createdOn', 'status'];
  displayedColumns1 = ['documentName', 'cretedBy', 'createdOn'];
  userList: MatTableDataSource<any>;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  public userFilterCtrl: FormControl = new FormControl();
  employeeData: any[];
  filteredUsers: any[];
  public totalRecords: number;
  public activePage: number;
  public pageSize: number;
  public isEdit: boolean;
  public pageSizeOptions: number[] = [5, 10, 25, 100];
  public isLoading: boolean;
  public disciplineData: any[];
  public showTable: boolean;
  public empIds: any[];
  public empDisciplinesExtra: any;
  protected _onDestroy = new Subject<void>();
  @ViewChild('singleSelect', {static: false}) singleSelect: MatSelect;
  disciplineActionForm: FormGroup;
  @ViewChild('deleteUser', {static: false}) deleteUser;
  @ViewChild('addDisciplinary' , {static: false}) addDisciplinary;
  @ViewChild('filterUser', {static: false}) filterUser;
  @ViewChild('archive', {static: false}) archive;
  selectedCheckBoxList = [];
  @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  modalRef: any;
  deleteId: any;
  pageIndex: number;
  public dialogRef: any;
  public filterForm: FormGroup;

  public employeeList = [];
  public employeeOptions:  Array<any> = [] ;
  public employeeOptions2:  Array<any> = [] ;

  public correct = true;
  public correct2 = false ;

  public imagePath : any[];

  constructor(private activatedRoute: ActivatedRoute,private service: DashboardService,
              private route: Router,
              public dialog: MatDialog,
              private modalService: NgbModal,
              private fb: FormBuilder,
              private toastr: NotificationService,
              private authService : AuthService ,
              public datepipe: DatePipe) {
    this.showTable = false;
    this.empIds = [];
    this.disciplineData = [];
    this.employeeData = [];
    this.filteredUsers = [];
    this.actions = [];
    this.disciplineForm = this.fb.group({
      empId: ['', Validators.required],
      caseName: ['', Validators.required],
      desc: [''],
      createdBy: [''],
      createdOn: [new Date()],
      status: ['in_progress'],
      action : [''],
      dueDate : ['']
    });

    this.disciplineActionForm = this.fb.group({
      empDisId: ['', Validators.required],
      description: [''],
      actionBy: [''],
      dueDate: [''],
      owner : ['' , Validators.required],
      status: [''],
      action: ['' , Validators.required ],
      completedOn: [''],
      document: [''],
    });

    this.createFilterForm();
  }

  public createFilterForm () {
    this.filterForm = this.fb.group({
      empId: [''],
      createdDateFrom: [''],
      createdDateTo: [''],
      status: [''],
      include: [''],
    });
  }


  onArchiveClicked() {
    // this.modalRef = this.modalService.open(this.archive);
    this.dialogRef = this.dialog.open(this.archive, {
      width: '440px',
      height: 'auto'
    });
  }

  disciplineArchive() {

    this.service.DisciplineArchive(this.selectedCheckBoxList).subscribe(
        response => {
          if (response) {
            this.selectedCheckBoxList = [];
            this.close();
            this.disciplineCasesData();
            this.toastr.success('Archived Successfully');
            this.close();
          }
        },
        error => {
          this.toastr.error(`Error: ${error}`);
          this.close();
        }
    );
    this.onDeSelectAllClicked();
  }

  getDisciplineTemplateForm() {
    this.service.getDisciplineTemplateForm().subscribe(resp => {
      if (resp && resp.data) {
        this.actions = resp.data.actions;
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error in getting form detail');
    });

  }

  selectAction() {
    const data = {
      'id': this.editDataSave.id,
      'action': this.disAction,
      'actionBy': this.editDataSave.typeName,
      'actionById': this.editDataSave.createdBy,
      'empId': this.editDataSave.empId,
      'desc': this.editDataSave.desc,
      'caseName': this.editDataSave.caseName,
      'status': this.editDataSave.status
    };

    this.service.saveEditCaseData(this.editDataSave);
    sessionStorage.setItem('disActionSave', JSON.stringify(data));
    this.route.navigate(['/discipline/my-actions', 2]);
    this.modalRef.close();
  }

  public tableConfig = {
    title: true,
    desc: true
  };

  public permissions : any;
    public read = false;
    public write = false ;
    public update = false;
    public delete = false;
   
    checkPermission(module,tabs) : void 
    {
        this.permissions = JSON.parse(this.authService.getPermissions());

        this.read = false ;
        this.write = false ;
        this.delete = false ;
        this.update = false;
        if(this.permissions != undefined && this.permissions != null )
        {
            for(let p of this.permissions)
            {
               
                if(p.id === module)
                {
                   
                    if(p.modules != undefined)
                    {
                      
                        for(let tab of p.modules)
                        {
                            if(tab.id === tabs)
                            {
                              
                                for(let move of tab.permits)
                                {
                                    if(move.id === "u")
                                    {
                                        this.update = true;
                                    }
                                    else if(move.id === "r")
                                    {
                                        this.read = true;
                                    }
                                    else if(move.id === "c")
                                    {
                                      
                                        this.write = true;
                                    }
                                    else if(move.id === "d")
                                    {
                                        this.delete = true;
                                    }
                                }
                            }
                        }
                    }
                   
                }
               
            }
        }
       

    }
  ngOnInit() {
    this.checkPermission("discipline","ds_dc");
    this.activePage = 1;
    this.pageSize = 10;
    this.totalRecords = 0;
    this.pageIndex = 0;
    this.disciplineCasesData();
    this.getEmployeeList();
    this.getDisciplineTemplateForm();
    this.initValuesDetection();

   
 
  
  }
  ngOnDestroy() {
    this.disciplineForm = null ;
    this.filterForm = null ;
    this.employeeOptions = [] ;
    this.employeeOptions2 = [] ;
  }
  initValuesDetection() {
    this.employeeOptions = [] ;
    this.employeeOptions2 = [] ;
    this.disciplineForm.controls['empId'].valueChanges.subscribe(change => {

      // tslint:disable-next-line:triple-equals
      if (change == null || change == undefined) {
        this.correct = true;
      } else {

        this.filterEmployees2(change);
      }

    });

    this.filterForm.controls['empId'].valueChanges.subscribe(change => {

      // tslint:disable-next-line:triple-equals
      if (change == null || change == undefined) {
        this.correct2 = false;
      } else {

        this.filterEmployees3(change);
      }

    });


  }

  protected filterEmployees2(change: String) {

    if (!this.employeeList) {
      return;
    }

    // get the search keyword
    if (!change) {
      this.employeeOptions = [];
      return;
    } else {
      this.checkEmployeeChange2(change);
      change = change.toLowerCase();
    }
    // filter the search
    this.employeeOptions =
        this.employeeList.filter(emp => emp.label.toLowerCase().indexOf(change) > -1);

  }

  checkEmployeeChange2(change) {
    // tslint:disable-next-line:no-shadowed-variable
    const x = this.employeeOptions.find(x => x.value === change);
    if (!x) {
      this.correct = false;
    }

  }

  protected filterEmployees3(change: String) {

    if (!this.employeeList) {
      return;
    }

    // get the search keyword
    if (!change) {
      this.employeeOptions2 = [];
      this.correct2 = false;
      return;
    } else {
      this.checkEmployeeChange3(change);
      change = change.toLowerCase();
    }
    // filter the search
    this.employeeOptions2 =
        this.employeeList.filter(emp => emp.label.toLowerCase().indexOf(change) > -1);

  }

  checkEmployeeChange3(change) {
    // tslint:disable-next-line:no-shadowed-variable
    const x = this.employeeOptions2.find(x => x.value === change);
    if (!x) {
      this.correct2 = false;
    }

  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.userList.filter = filterValue;
  }

  public submitAddDisciplines() {

    this.isLoading = true;
    this.disciplineForm.value.status = 'open';
    let res = new Date(this.datepipe.transform(new Date() , 'dd-MM-yyyy'));
    let date  = res.setDate(res.getDate() + 1);

    let today = new Date();
    const data = {
      "caseName": this.disciplineForm.value.caseName,
      "desc": this.disciplineForm.value.desc,
      "empId": this.disciplineForm.value.empId,
      "status": this.disciplineForm.value.status,
      'createdOn' : new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0)
    };
    this.service.addDiscipline(data).subscribe(user => {
        this.toastr.success('Disciplinary added successfully');
        this.close();
        this.disciplineForm.reset();
        this.disciplineCasesData();
    }, error => {
        const err = error.error && error.error.msg;
        this.toastr.error(err || 'Error in adding Disciplinary.Please try again.');
        this.dialogRef.close();
      this.isLoading = false;
    });
  }


  public submitAddDisciplinesAction() {

    this.isLoading = true;
    const data = {
      "action": this.disciplineActionForm.value.action.id,
      "description": this.disciplineActionForm.value.description,
      "dueDate": this.disciplineActionForm.value.dueDate,
      "empDisId": this.disciplineActionForm.value.empDisId,
      "owner": this.disciplineActionForm.value.owner,
      "status": this.disciplineActionForm.value.status ? this.disciplineActionForm.value.status : 'in_progress'
    };
    this.service.addDisciplineAction(data).subscribe(user => {
      this.toastr.success('Disciplinary Action added successfully');
      this.close();
      this.disciplineActionForm.reset();
      this.disciplineCasesData();
      this.isLoading = false;
    }, error => {
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error in adding Disciplinary Action.Please try again.');
      this.dialogRef.close();
      this.isLoading = false;
    });
  }

  public takeDisciplineAction() {

  }

  public closeCase() {
    this.isLoading = true;
    this.service.closeDiscipline(this.editDataSave.id).subscribe(user => {
      this.toastr.success('Disciplinary closed successfully');
      this.editDataSave.close = true;
      this.close();
      this.disciplineCasesData();
    }, error => {
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error in closing Disciplinary.Please try again.');
      this.isLoading = false;
      this.close();
    });
  }

  public disciplineCasesData(): void {
    this.isLoading = true;
    this.employeeList = [];
    this.service.getEmployeeDetailList().subscribe(res => {
      this.isLoading = false;
      if (res && res.data) {
  
        res.data.map(e => {
          this.employeeList.push({label: e.id + ' ' + e.name, value: e.id});
        });
  
        this.employeeData = res.data;
        this.filteredUsers = this.employeeData.slice();
  
        // listen for search field value changes
        this.userFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterUsers();
          });
          this.isLoading = true;
          this.service.getDesciplines(this.pageIndex + 1, this.pageSize).subscribe(res => {
            if (res && res.data) {
              this.showTable = true;
              this.isLoading = false;
      
              this.disciplineData = res.data;
              this.empDisciplinesExtra = res.extras;
              this.activatedRoute.params.subscribe(params => {
                console.log("ok");
                if (params.id) {
                  console.log("ok");
              
                  for(let d of this.disciplineData)
                  {
                    console.log(d);
                    if(d.id === params.id )
                    {
                      this.addDisciplinesForm('editcase' , d);
          
                    }
          
                  }
          
                }
              });
              this.dataSource = new MatTableDataSource(this.disciplineData);
              this.dataSource.sort = this.sort;
              this.dataSource.paginator = this.paginator;
            }
          }, error => {
            const err = error.error && error.error.msg;
            this.isLoading = false;
            this.toastr.error(err || 'Error getting employee report');
          });
      }
    }, error => {
      this.isLoading = false;
    });

  }

  public deleteDisciplineById(): void {

    this.service.deleteDescipline(this.selectedCheckBoxList).subscribe(res => {
      if (res) {
        this.close();
        this.disciplineCasesData();
        this.toastr.success('Discipline Deleted Successfully !');
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error deleting Discipline');
    });
    this.selectedCheckBoxList = [];
  }

  addDisciplinesForm(val , data): void {
    this.typeForm = val;

    this.editDataSave = data;
    
    if(this.typeForm === 'add'){
        this.disciplineForm.reset();
    }

    if(this.typeForm === 'addAction'){

      this.editDataSave['case'] = `${data?.empName} - ${data?.caseName}`;
      const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
      this.editDataSave['role'] = currentUser.userRole;
      this.disciplineActionForm.patchValue({
        empDisId: this.editDataSave.id,
      });
    }
    if(this.typeForm === 'editcase' || this.typeForm === 'addAction'){
      this.imagePath = this.editDataSave.imagePath;
      this.disciplineForm.patchValue({
        empId: this.editDataSave.empId,
        caseName: this.editDataSave.caseName,
        desc: this.editDataSave.desc,
        createdBy: this.editDataSave.createdBy,
        createdOn: this.datepipe.transform(this.editDataSave.createdOn , 'dd-MM-yyyy'),
      });
    }


    this.dialogRef = this.dialog.open(this.addDisciplinary, {
      width: this.typeForm === 'add' ? '560px': this.typeForm === 'addAction' ? '860px' : '560px',
      data: {}
    });
  }

  public setSelected(ind, data, event) {

    if (event.checked == true) {
      this.empIds.push({ 'empId': data.empId });
    } else {
      const indexVal = this.empIds.findIndex(record => record.empId == data.empId);
      this.empIds.splice(indexVal, 1);
    }

  }

  public selectAll(event) {
    if (event.checked == true) {
      this.disciplineData.forEach((element, ind) => {
        element.checked = true;
        this.empIds.push({ 'empId': element.empId });
      });
    } else {
      this.disciplineData.forEach(element => {
        element.checked = false;
      });
      this.empIds = [];
    }

  }

  viewAction(id) {
    this.service.setDisciplineId(id);
    this.route.navigate(['/discipline/my-actions', id]);
  }

  public deleteDisciplinePopUp(id): void {
    this.deleteId = id;
    this.modalRef = this.modalService.open(this.deleteUser);
  }

  public close(): void {
    this.dialogRef.close();
  }
public getEmployeeList(): void {

}
protected filterUsers() {
  if (!this.employeeData) {
    return;
  }
  // get the search keyword
  let search = this.userFilterCtrl.value;
  if (!search) {
    this.filteredUsers = this.employeeData.slice();
    return;
  } else {
    search = search.toLowerCase();
  }
  // filter the search
  this.filteredUsers =
    this.employeeData.filter(emp => emp.name.toLowerCase().indexOf(search) > -1);
}
saveCase() {
  this.isLoading = true;
  this.editDataSave.empId = this.disciplineForm.value.empId;
  this.editDataSave.desc = this.disciplineForm.value.desc;
  this.editDataSave.caseName = this.disciplineForm.value.caseName;
  const data = {
    'empId': this.disciplineForm.value.empId,
    'desc': this.disciplineForm.value.desc,
    'caseName': this.disciplineForm.value.caseName,
    'status': this.editDataSave.status,

  };
  this.service.editDescipline(this.editDataSave.id, data).subscribe(user => {
    this.toastr.success('Disciplinary Updated successfully');
    this.disciplineForm.reset();
    this.close();
    this.disciplineCasesData();
  }, error => {
    const err = error.error && error.error.msg;
    this.toastr.error(err || 'Error in updating Disciplinary.Please try again.');
    this.close();
    this.isLoading = false;
  });
}
onNoClick() {

}
pageChange(event) {
  this.pageIndex = event.pageIndex;
  this.disciplineCasesData();
}

exportToCsv() {

  const csv = this.dataSource.filteredData.map(
      row => {
        return [row?.empId, row.empName, row.caseName, row.createdBy, this.datepipe.transform(row.createdOn , 'dd-MM-yyyy') , row.close? "Close" : "Open"].join(',') + '\n';
      }
  );
  csv.unshift(this.displayedColumnsFile.join(',') + '\n');
  const exportDate = new Date();
  // tslint:disable-next-line:max-line-length
  const exportFileName = `Csv-export-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}.csv`;
  saveAs(new Blob(csv, {type: 'text/csv;charset=utf-8'}), exportFileName);
}

saveAsPdf() {
  if (this.dataSource.filteredData.length <= 0) {
    this.toastr.error('No Data to export');
    return;
  }
  const body = [];
  for (let i = 0; i < this.dataSource.filteredData.length; i++) {
    body.push([this.dataSource.filteredData[i].empId,
      this.dataSource.filteredData[i].empName,
      this.dataSource.filteredData[i].caseName,
      this.dataSource.filteredData[i].createdBy,
      this.datepipe.transform(this.dataSource.filteredData[i].createdOn , 'dd-MM-yyyy'),
      this.dataSource.filteredData[i].close ? "Close" : "Open",]);
  }
  const doc = new jsPDF();
  autoTable(doc, {
    head: [['employeeId' ,'employee', 'caseName', 'cretedBy', 'createdOn', 'status']],
    body: body
  });
  const exportDate = new Date();
  const exportFileName = `Disciplinary-Cases-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}`;
  doc.save(exportFileName);
}

  onSelectAllClicked() {
    this.selectedCheckBoxList = [];
    this.matCheckBoxes.forEach(
        item => {
          this.selectedCheckBoxList.push(item.value);
          item.checked = true;
        }
    );
  }

  onDeSelectAllClicked() {
    this.selectedCheckBoxList = [];
    this.matCheckBoxes.forEach(
        item => {
          item.checked = false;
        }
    );
  }

  onDeleteSelected() {
    this.dialogRef = this.dialog.open(this.deleteUser, {
      width: '440px',
      height: 'auto'
    });
  }

  onCheckBoxChange($event: MatCheckboxChange, id: any) {
    if (!this.alreadySelected($event.source) && $event.checked) {
      this.selectedCheckBoxList.push($event.source.value);
    } else if (!$event.checked) {
      this.selectedCheckBoxList = this.selectedCheckBoxList.filter(
          value => {
            return value !== $event.source.value;
          }
      );
    }
  }

  alreadySelected(item: MatCheckbox): boolean {
    this.selectedCheckBoxList.forEach(
        value => {
          if (item === value) {
            return true;
          }
        }
    );
    return false;
  }

  filterUserList() {
    //this.resetForm();
    this.dialogRef = this.dialog.open(this.filterUser, {
      width: '560px',
      height: 'auto'
    });
  }

  showEmployee(id){

    this.route.navigate([`/pim/employees/${id}`]);
  }


  onModelChange(value: string) {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }


  isArchived : boolean = false;
  search(){


    if(this.filterForm.value.status === 'archived'){
      this.isArchived = true;
    } else {
      this.isArchived = false;
    }

    const body = {
      employeeId : this.filterForm.value.empId,
      createdDateFrom : this.filterForm.value.createdDateFrom,
      createdDateTo : this.filterForm.value.createdDateTo,
      status : this.filterForm.value.status,
      includeEmployees : this.filterForm.value.include,
      archived : this.filterForm.value.status === 'archived'
    };

    this.service.DisciplineFilterRequest(body).subscribe(res => {
      if (res.data && res) {
        this.dataSource = new MatTableDataSource<any>(res.data);
        this.dialogRef.close();
        this.toastr.success('filtered Successfully');
      }
    }, error => {
      this.toastr.error(error.error.msg || 'Error in getting the filter Request');
    });

  }

  resetForm() {
    this.isArchived = false;
    this.correct2 = false;
    this.createFilterForm();
    this.disciplineCasesData();
    this.initValuesDetection();
  }

  public displayEmployeeByName2(option) {

      if (!option) {
        console.log("op is null: " , option);
          return '';
      }

    this.correct = true;

    return this.employeeList.find(x => x.value === option).label;
  }

  public displayEmployeeByName3(option) {

    if (!option) {
     
        return '';
    }

  this.correct2 = true;

  return this.employeeList.find(x => x.value === option).label;
}

}
