import {Component, OnInit, ViewChild} from '@angular/core';
import {DashboardService} from '../../../dashboard.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { NotificationService } from "src/app/core/services/notification.service";
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {NestedTreeControl} from '@angular/cdk/tree';
import {Observable} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import { LanguageConfig } from 'src/app/Language-config';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
    selector: 'app-structure',
    templateUrl: './structure.component.html',
    styleUrls: ['./structure.component.scss',
        '../../../../../../node_modules/angular-tree-component/dist/angular-tree-component.css']
})
export class StructureComponent implements OnInit {

    treeControl = new NestedTreeControl<any>(node => node.children);
    dataSource = new MatTreeNestedDataSource<any>();
    public dialogRef: any;
    public data: Array<any>;
    public userList: Array<any>;
    public employeeList = [];
    public costCenterList = [];
    public isLoading: boolean;
    public isUserAdded: boolean;
    public err: string;
    public modalRef: any;
    public userForm: FormGroup;
    public isEdit: boolean;
    public editId: string;
    public deleteId: string;
    public lang = new Map();
    public validationError = {
        required: 'Required',
        email: 'Please provide a valid email',
        password: 'Please provide a password of atleast 8 digits',
        postalcode: 'Should be of 6 digits',
        alphabets: 'Should contain only alphabets'
    };
    public parentId: string;
    public filteredOptions: Observable<string[]>;
    public level: string;
    // public isEditStructure = false;
    @ViewChild('addUser', {static: false}) addUser;
    @ViewChild('deleteUser', {static: false}) deleteUser;

    constructor(private service: DashboardService,
        private  authService: AuthService,
                private fb: FormBuilder,
                private toastr: NotificationService,
                private dialog: MatDialog) {
        this.createForm();
        this.lang=LanguageConfig.map;
        this.dataSource.data = null;
    }

    hasChild = (_: number, node: any) => !!node.children && node.children.length > 0;
    public permissions : any;
    public read = false;
    public write = false ;
    public update = false;
    public delete = false;
  
    checkPermission(module,tabs) : void 
    {
    
        this.permissions = JSON.parse(this.authService.getPermissions());
  
        this.read = false ;
        this.write = false ;
        this.delete = false ;
        this.update = false;
        if(this.permissions != undefined && this.permissions != null )
        {
            for(let p of this.permissions)
            {
               
                if(p.id === module)
                {
                   
                    if(p.modules != undefined)
                    {
                      
                        for(let tab of p.modules)
                        {
                            if(tab.id === tabs)
                            {
                              
                                for(let move of tab.permits)
                                {
                                    if(move.id === "u")
                                    {
                                        this.update = true;
                                      
                                    }
                                    else if(move.id === "r")
                                    {
                                        this.read = true;
                                    }
                                    
                                    else if(move.id === "d")
                                    {
                                        this.delete = true;
                                    }
                                    else if( move.id === "c")
                                    {
                                      this.write = true;
                                    }
                                }
                            }
                        }
                    }
                   
                }
               
            }
        }
       
  
    }
    ngOnInit(): void {
        this.checkPermission("admin","ad_og");
        this.service.getCostCenterList().subscribe(resp => {
            if (resp && resp.data) {
                this.costCenterList = resp.data;
            }
        });
        this.isLoading = true;
        this.isEdit = false;
        this.getJobTitleList();
        this.isUserAdded = false;
        this.service.getEmployeeDetailList().subscribe(resp => {
            if (resp && resp.data) {
                resp.data.map(e => {
                    this.employeeList.push({label: e.id+' '+e.name, value: e.id});
                });
            }
        });
        this.service.getCostCenterList().subscribe(resp => {
            this.costCenterList = resp && resp.data;
        });

        this.service.getStructureListAsTree().subscribe(resp => {
            if (resp && resp.data) {
                this.data = resp.data;
                this.dataSource = resp.data;
            }
        });
    }

    public createForm(): void {
        this.userForm = this.fb.group({
            unitId: [''],
            costcenter: this.fb.group({
                costcenterid: ['']
            }),
            description: [''],
            hodempid: [''],
            level: ['1'],
            name: ['', Validators.required],
            parent: ['']
        });
    }

    public addNewUser(data) {
        this.isEdit = false;
        this.parentId = data.id;
        this.level = data.level + 1;
        // this.modalRef = this.modalService.open(this.addUser);
        this.dialogRef = this.dialog.open(this.addUser, {
            width: '560px',
            height: 'auto'
        });

        this.userForm.reset();
    }

    public close(): void {
        // this.modalRef.close();
        this.dialogRef.close();
    }

    public getJobTitleList(): void {
        this.service.getStructureListAsTree().subscribe(user => {
            if (user && user.data) {
                this.isLoading = false;
                this.data = user.data;
                this.dataSource = user.data;
                this.err = '';
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error while getting structure list');
            this.isLoading = false;
            this.err = 'Error while getting structure list';
        });
    }

    public submitAddJobTitle(): void {
        const val = this.userForm.value;
        val.parent = this.parentId;
        val.costcenter = val.costcenter.costcenterid;
        // val.level = parseInt(this.level, 10);
        val.level = 0;
        if (this.isEdit) {
            this.edit(val);
        } else {
            this.addStructure(val);
        }
    }
    public addStructure(val): void {
        this.isUserAdded = true;
        this.service.addNewStructure(val).subscribe(user => {
            this.isUserAdded = false;
            this.toastr.success('Structure added successfully');
            // this.modalRef.close();
            this.dialogRef.close();
            this.userForm.reset();
            this.getJobTitleList();
        }, error => {
            const err = error.error && error.error.msg;
            this.isUserAdded = false;
            this.toastr.error(err || 'Error in adding Structure.Please try again.');
        });
    }
    public edit(val): void {
        this.isUserAdded = true;
        this.service.editStructure(this.editId, val).subscribe(user => {
            this.isUserAdded = false;
            this.isEdit = false;
            this.userForm.reset();
            // this.modalRef.close();
            this.dialogRef.close();
            this.toastr.success('Structure updated successfully');
            this.getJobTitleList();
        }, error => {
            const err = error.error && error.error.msg;
            this.isUserAdded = false;
            this.toastr.error(err || 'Error in updating Structure.Please try again.');
        });
    }

    public editUser(id): void {
        this.isEdit = true;
        this.isUserAdded = true ;
        this.dialogRef = this.dialog.open(this.addUser, {
            width: '560px',
            height: 'auto'
        });
        this.service.getStructureById(id).subscribe(user => {
            if (user && user.data) {
                this.userForm.patchValue(user.data);
                // this.modalRef = this.modalService.open(this.addUser);
                this.isUserAdded = false ;
                this.editId = id;
                this.parentId = user.data.parent;
            }
        }, error => {
            this.isUserAdded = false ;
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error getting Structure');
        });
    }

    public deleteUserForm(id): void {
        this.deleteId = id;
        // this.modalRef = this.modalService.open(this.deleteUser);
        this.dialogRef = this.dialog.open(this.deleteUser, {
            width: '560px',
            height: 'auto'
        });

    }

    public deleteJobTitle(): void {
        this.service.deleteStructure(this.deleteId).subscribe(user => {
            if (user) {
                this.close();
                this.getJobTitleList();
                this.toastr.success('Structure deleted !');
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error deleting Structure');
        });
    }
    onAddUser(node: any) {
        this.isEdit = false;
        this.userForm.reset();
        if (!this.isEdit) {
            this.parentId = node.id;
        }
        // this.modalRef = this.modalService.open(this.addUser);
        this.dialogRef = this.dialog.open(this.addUser, {
            width: '560px',
            height: 'auto'
        });

    }
}
