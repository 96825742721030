<div id="header-page" class="ng-star-inserted header-page">
    <div class="page-header ng-star-inserted">
        <div class="page-block ng-star-inserted">
        <div class="row align-items-center">
            <div class="col-md-8">
            <div class="page-header-title">
                <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted">Occasion Details</h4></span>
            </div>
            </div>
            <div class="col-md-4"></div>
        </div>
        </div>
    </div>
</div>



<app-loading *ngIf="isLoading" [isLoading]="isLoading"></app-loading>
<div class="page-body" *ngIf="!isLoading">
    <div class="row">
      <div class="col-sm-12">
       
           
             
 <div class="dt-responsive">
            
     
        <app-card [title]="">
           <div>
            <div>
              <mat-card-content>
                <h3>{{this.userForm.value.name}}</h3>   
                <form class="form-material full-width-inputs" action="javascript:" [formGroup]="userForm" >
                    <div class="row form-group">
                        <div class="col-md-6">
                            <mat-form-field class="form-element">
                                <mat-label>Name</mat-label>
                                <input [readonly]="!isEdit" matInput name="name" class="form-control" required="" formControlName="name">
                                <mat-error class="validation-error"
                              *ngIf="!userForm.controls.name.valid ">{{ validationError.required }}</mat-error>
                            </mat-form-field>
                        </div>
    
                        <div class="col-md-6">
                            <mat-form-field class="form-element">
                                <mat-label>Location</mat-label>
                                <mat-select disabled disableOptionCentering formControlName="locationId" required="" class="form-control">
                                    <mat-option  value="">--Select--</mat-option>
                                    <mat-option *ngFor="let l of locationList" [value]="l.id">{{l.location}}</mat-option>
                                </mat-select>
                                <mat-error class="validation-error"
                                *ngIf="!userForm.controls.locationId.valid">{{ validationError.required }}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <br> 
                    <div class="row form-group"> 
                        <div class="col-md-6" >
                            <mat-form-field class="form-element">
                                
                                <input [disabled]="!isEdit" [min]="minDate" class="form-control" matInput [matDatepicker]="mPicker" name="dueDate" formControlName="dueDate" placeholder="Due Date" 
                                    required>
                                <mat-datepicker-toggle  matSuffix [for]="mPicker"></mat-datepicker-toggle>
                                <mat-datepicker  [touchUi]="false" #mPicker></mat-datepicker>
                                <mat-error class="validation-error"
                              *ngIf="!userForm.controls.dueDate.valid">{{ validationError.required }}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <br>
                    <div class="row form-group">
                        <div class="col-md-12">
                            <mat-form-field class="form-element">
                                <mat-label>Participants</mat-label>
                    <mat-select required [disabled]="!isEdit" panelClass="myPanelClass" disableOptionCentering  style="height: min-content"  class="form-control"
                                formControlName="participants" multiple>
                        <mat-select-trigger>
                            <mat-chip-list>
                                <mat-chip
                                        *ngFor="let topping of userForm.value.participants"
                                        [selectable]="false"
                                        [removable]="false"
                                      >
                                    {{ topping?.label }}
                                 
                                </mat-chip>
                            </mat-chip-list>
                        </mat-select-trigger>
                        <ng-container>
                            <mat-option>
                                <ngx-mat-select-search [formControl]="employeeFilterCtrl"
                                                       placeholderLabel="Search"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option
                                    *ngFor="let emp of filteredEmployees"
                                    [value]="emp">{{emp.label}}</mat-option>
                        </ng-container>
                    </mat-select>
                               
                           
                                <mat-error class="validation-error"
                                    *ngIf="!userForm.controls.participants.valid ">{{ validationError.required }}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <br>
                    <div class="row form-group">
                        <div class="col-md-12">
                            <mat-form-field class="form-element">
                                <mat-label>Occasion Owners</mat-label>
                    <mat-select [disabled]="!isEdit" panelClass="myPanelClass" disableOptionCentering required style="height: min-content"  class="form-control"
                                formControlName="owners" multiple
                                disableOptionCentering>
                        <mat-select-trigger>
                            <mat-chip-list>
                                <mat-chip
                                        *ngFor="let topping of userForm.value.owners"
                                        [selectable]="true"
                                        [removable]="isEdit"
                                        (removed)="remove(topping.value , 'ow')" 
                                        >
                                    {{ topping?.label }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </mat-select-trigger>
                        <ng-container>
                            <mat-option>
                                <ngx-mat-select-search [formControl]="employeeFilterCtrl2"
                                                       placeholderLabel="Search"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option
                                    *ngFor="let emp of filteredEmployees2"
                                    [value]="emp">{{emp.label}}</mat-option>
                        </ng-container>
                    </mat-select>
                                <mat-error class="validation-error"
                                    *ngIf="!userForm.controls.owners.valid ">{{ validationError.required }}</mat-error>
                            </mat-form-field>
                        </div>
    
                        </div>
                     
                    
                   
                </form>
                <div class="col-sm-12 text-right action-buttons multi-btns">
                    <button (click)="cancell()" mat-raised-button class="btn-danger"><i class="flaticon-cancel"></i>Cancel
                    </button>
                    <button *ngIf="activated" (click)="showTasks()"  class="btn btn-primary mr-2" mat-raised-button  color="primary">
                     View Tasks
                    </button>
                    <button *ngIf="!activated" (click)="openActivationTab()" class="btn btn-primary mr-2" mat-raised-button  color="primary">
                     Activate
                    </button>
                    <button *ngIf="isEdit" (click)="add()" mat-raised-button class="btn-primary" color="primary"
                             [disabled]="!userForm.valid"><i class="flaticon-diskette"></i>Save
                    </button>
                   
                    <button *ngIf="!isEdit" (click)="changeEdit()" mat-raised-button  class="btn btn-primary mr-2" color="primary">Edit</button>
                </div>

              </mat-card-content>
              
            
            </div>
          </div>
        </app-card>
      </div>
    </div>
  </div>
  <br>  <br>  <br> 
  <br>   <br>  <br>



  <ng-template #activationTab let-modal>
    <div mat-dialog-actions>
      <div class="mat-modal-header">
        <h4 class="mat-title-modal">
            Confirmation Required
        </h4>
      </div>
      <div class="mat-modal-body">
          <p>You have to select at least one type to generate tasks</p>
                <mat-card-content>
                  <div class="dt-responsive">
                    <div class="table-responsive">
                        <mat-table [dataSource]="activeSource" matSort>
                            <ng-container matColumnDef="select">
                              <mat-header-cell *matHeaderCellDef>
                                <a [matMenuTriggerFor]="menu" class="check-all">
                                            <span class="material-icons">
                                                more_horiz
                                            </span>
                                </a>
            
                                <mat-menu #menu="matMenu">
                                  <button mat-menu-item (click)="onSelectAllClicked()">Select all</button>
                                  <button (click)="onDeSelectAllClicked()" *ngIf="selectedCheckBoxList.length > 0" mat-menu-item>
                                    Deselect all
                                  </button>
                                 
                                </mat-menu>
                              </mat-header-cell>
                              <mat-cell *matCellDef="let row">
                                <mat-checkbox #matCheckbox (change)="onCheckBoxChange($event, row.id)"
                                              value="{{row.id}}"></mat-checkbox>
                              </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="name">
                              <mat-header-cell *matHeaderCellDef >Task Type</mat-header-cell>
                              <mat-cell *matCellDef="let row">
                                {{ row.name }}
                              </mat-cell>
                            </ng-container>
            
                           
                            
                            <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: columns;">
                            </mat-row>
                          </mat-table>
                          <mat-progress-bar mode="indeterminate" *ngIf="this.isLoadingAct"></mat-progress-bar>
                 
                </div>
              </div>
              <br>
              
       
                </mat-card-content>
           
                <div class="row my-2 mx-2"> 
                  <div class="action-buttons multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="closeModal()"><i class="flaticon-cancel"></i>Close</button>
   
                    <button  (click)="generateTasks()" class="btn-danger"
                    mat-raised-button>Generate Tasks </button>
                  </div>
                </div>
   
        </div>
      </div>
  
  </ng-template>

  <ng-template #generateTab let-modal>
    <div mat-dialog-actions>
    
      <div class="mat-modal-body">
        <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>
          <p>Following task type(s) will be generated</p>
                <mat-card-content *ngIf="!isUserAdded">
                    <div *ngFor="let name of this.taskNames">

                        <div class="row form-group" >
                    
                            <div class="col-sm-6">
                               
                                <p  class="m-b-10 ng-star-inserted cus-flex-tools">
                                    <span>
                                        • &nbsp; {{name}}
                                    </span>
                                </p>
                            </div>
                            
                          </div>
                     </div>
              
       
                </mat-card-content>
           
                <div class="row my-2 mx-2"> 
                  <div class="action-buttons multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="closeModal()">No,Cancel</button>
                  
                    <button  (click)="activateEvent()" class="btn-danger"
                    mat-raised-button>Yes,Generate </button>
                  </div>
                </div>
   
        </div>
      </div>
  
  </ng-template>