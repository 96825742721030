import { Component, OnInit, NgZone, ViewChild } from "@angular/core";
import { DashboardService } from "../../../dashboard.service";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { NotificationService } from "src/app/core/services/notification.service";
import { Router } from "@angular/router";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { startWith, takeUntil } from "rxjs/operators";
import { AuthService } from "src/app/core/services/auth.service";
import { MatFormFieldControl } from "@angular/material/form-field";
@Component({
  selector: "app-add-entitlement",
  templateUrl: "./add-entitlement.component.html",
  styleUrls: ["./add-entitlement.component.scss"],
  providers: [{ provide: MatFormFieldControl, useExisting: AddEntitlementComponent }],
})
export class AddEntitlementComponent implements OnInit {
  public isLoading = true;
  public leaveForm: FormGroup;
  public employeeList: Array<any> = [];
  public leavePeriodList = [];
  public leaveTypeList = [];
  public locationsList = [];
  public subUnitList = [];
  public modeselect = "All";
  public modeselect2 = "All";
  public count = 0;
  public ids: Array<any> = [];
  public check: boolean = true;
  public dialogRef: any;
  public userFilterCtrl: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();
  balanceSource: MatTableDataSource<any> = new MatTableDataSource([]);
  public balanceCloumns = ["employeeName", "oldEntitlement", "newEntitlement"];
  @ViewChild("balanceDialog", { static: false }) balanceDialog;
  public selectedEmployee: any;
  public submitLoading: boolean;

  constructor(
    private service: DashboardService,
    private fb: FormBuilder,
    private toastr: NotificationService,
    private ngZone: NgZone,
    private router: Router,
    private dialog: MatDialog,

    private readonly authService: AuthService
  ) {
    this.createForm();
  }
  public permissions: any;
  public read = false;
  public write = false;
  public update = false;
  public delete = false;

  checkPermission(module, tabs): void {
    this.permissions = JSON.parse(this.authService.getPermissions());

    this.read = false;
    this.write = false;
    this.delete = false;
    this.update = false;
    if (this.permissions != undefined && this.permissions != null) {
      for (let p of this.permissions) {
        if (p.id === module) {
          if (p.modules != undefined) {
            for (let tab of p.modules) {
              if (tab.id === tabs) {
                for (let move of tab.permits) {
                  if (move.id === "u") {
                    this.update = true;
                  } else if (move.id === "r") {
                    this.read = true;
                  } else if (move.id === "c") {
                    this.write = true;
                  } else if (move.id === "d") {
                    this.delete = true;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  ngOnInit() {
    this.isLoading = true;

    this.checkPermission("leave", "le_ets");
    this.selectedEmployee = { id: "", name: "" };
    this.leaveForm.patchValue({
      comment: "",
      employeeid: "",
      total: "",
      entitlementtype: "Added",
      locationId: "All",
      subUnit: "All",
      employeeIds: new Array(),
      leaveperiodid: "",
      leavetypeid: "",
      status: "active",
    });
    this.ids = [];
    this.count = 0;
    if (this.leaveForm.value.entitlementSituational == true) {
      this.service.getLocationListAll().subscribe(
        (resp) => {
          if (resp && resp.data) {
            this.locationsList = resp.data;
          }
        },
        (error) => {
          const err = error.error && error.error.msg;
        }
      );
      this.service.getStructureListWithAll().subscribe(
        (resp) => {
          if (resp && resp.data) {
            this.subUnitList = resp.data;
          }
        },
        (error) => {
          const err = error.error && error.error.msg;
        }
      );

      this.service.getLeaveTypes().subscribe(
        (resp) => {
          if (resp && resp.data) {
            this.leaveTypeList = resp.data;
            this.isLoading = false;
          }
        },
        (error) => {
          this.isLoading = false;
          this.toastr.error("Error in getting leave types");
          const err = error.error && error.error.msg;
        }
      );
    } else {
      this.leaveTypeList = [];
      this.isLoading = false;
    }

    this.service.getLeavePeriodForLeaveUsage().subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.leavePeriodList = resp.data;
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
      }
    );
  }

  public getLeaveTypeForEmployee() {
    const id = this.leaveForm.value.employeeid;
    this.service.getLeaveTypeListCountryWise(id).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.leaveTypeList = resp.data;

          this.leaveForm.patchValue({
            leavetypeid: this.leaveTypeList[0].id,
          });
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
      }
    );
  }

  public getLeaveTypeForEmp(ids) {
    const id = ids;
    this.service.getLeaveTypeListCountryWise(id).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.leaveTypeList = resp.data;

          this.leaveForm.patchValue({
            leavetypeid: this.leaveTypeList[0].id,
          });
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
      }
    );
  }

  getEmployeesCount() {
    var obj = {
      leaveTypeId: this.leaveForm.value.leavetypeid,
      locationId: this.leaveForm.value.locationId,
      subUnitId: this.leaveForm.value.subUnit,
    };
    this.service.getCountEmployeesEntitlments(obj).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.ids = [];
          this.ids = resp.data;
          this.count = this.ids.length;
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
      }
    );
  }

  public getLeaveTypeForLocation() {
    const id = this.leaveForm.value.locationId;
    this.service.getLeaveTypeListBulkCountryWise(id).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.leaveTypeList = resp.data;
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
      }
    );
  }

  public getLocationAndSubUnitForLeaveType() {
    const id = this.leaveForm.value.leavetypeid;
    this.service.getlocForLeaveType(id).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.locationsList = resp.data;
          this.modeselect = "All";
          this.leaveForm.patchValue({
            locationId: this.locationsList[0].id,
          });
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
      }
    );
    this.service.getSubForLeaveType(id).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.subUnitList = resp.data;
          this.modeselect2 = "All";
          this.leaveForm.patchValue({
            subUnit: this.subUnitList[0].id,
          });
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
      }
    );
  }

  public getSubUnitForLocation() {
    const id = this.leaveForm.value.locationId;
    this.service.getSubForloc(id).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.subUnitList = resp.data;
          this.modeselect2 = "All";
          this.leaveForm.patchValue({
            subUnit: this.subUnitList[0].id,
          });
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
      }
    );
  }

  public createForm(): void {
    this.leaveForm = this.fb.group({
      comment: "",
      employeeid: [""],
      total: [""],
      entitlementtype: ["Added"],
      locationId: [""],
      subUnit: [""],
      entitlementSituational: false,
      employeeIds: new Array(),
      leaveperiodid: [""],
      leavetypeid: [""],
      status: "active",
    });
    this.modeselect = "All";
    this.modeselect2 = "All";
  }

  public reset() {
    if (this.leaveForm.value.entitlementSituational == true) {
      this.leaveForm = this.fb.group({
        comment: "",
        employeeid: [""],
        total: [""],
        entitlementtype: ["Added"],
        locationId: [""],
        subUnit: [""],
        entitlementSituational: true,
        leaveperiodid: [""],
        leavetypeid: [""],
        status: "active",
        employeeIds: new Array(),
      });
      this.check = false;
    } else {
      this.createForm();
    }
    this.leavePeriodList = [];
    this.leaveTypeList = [];
    this.locationsList = [];
    this.subUnitList = [];

    this.ngOnInit();
  }

  public addEntitlement(): void {
    if (this.leaveForm.value.entitlementSituational) {
      for (let id of this.ids) {
        this.leaveForm.value.employeeIds.push(id);
      }
    } else {
      var ids = [];
      ids.push(this.selectedEmployee.id);
      this.leaveForm.patchValue({
        employeeIds: ids,
      });
    }
    this.submitLoading = true;
    this.service.checkEntitlement(this.leaveForm.value).subscribe(
      (resp) => {
        if (resp && resp.data) {
          if (resp.data.employeeEntitlements.length === 0) {
            this.service.addEntitlement(this.leaveForm.value).subscribe(
              (resp) => {
                if (resp && resp.data) {
                  this.toastr.success("Entitlement assigned successfully");
                  this.submitLoading = false;
                  this.reset();
                }
              },
              (error) => {
                this.submitLoading = false;
                const err = error.error && error.error.msg;
                this.toastr.error(err || "Error in adding Entitlement");
              }
            );
          } else {
            this.leaveForm.patchValue({
              employeeIds: resp.data.employeesIds,
            });

            this.balanceSource = new MatTableDataSource<any>(resp.data.employeeEntitlements);
            this.submitLoading = false;
            this.dialogRef = this.dialog.open(this.balanceDialog, {
              width: "660px",
              autoFocus: false,
              maxHeight: "90vh",
            });
            return;
          }
        }
      },
      (error) => {
        this.submitLoading = false;
        const err = error.error && error.error.msg;
        this.toastr.error(err);
      }
    );
  }
  close() {
    this.dialogRef.close();
  }

  saveEntitlments(): void {
    this.service.addEntitlement(this.leaveForm.value).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.toastr.success("Entitlement assigned successfully");
          this.ngZone.run(() => {
            void this.router.navigate(["/leave/entitlement/add-entitlement"], { queryParams: { action: "add" } });
          });
          this.close();
          this.reset();
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error in adding Entitlement");
      }
    );
  }

  public updateSelectedEmployee(event) {
    this.selectedEmployee.id = event ? event.id : "";
    this.selectedEmployee.name = event ? event.name : "";

    this.service.getLeaveTypeListForEmployee(this.selectedEmployee.id).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.leaveTypeList = resp.data;
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error in leave Type");
      }
    );
  }
}
