<mat-form-field class="form-element">
  <mat-label>{{title}}</mat-label>
  <br [hidden]="selectedEmployees.length" />
  <mat-chip-list #chipList6>
    <mat-chip
      *ngFor="let emp of selectedEmployees"
      [selectable]="selectable"
      [removable]="removable"
      (removed)="removeEmployee(emp.id)"
    >
      {{ emp.name }}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input
      placeholder="type for hints..."
      #empInput
      [formControl]="empCtrl"
      [matAutocomplete]="auto1"
      [matChipInputFor]="chipList6"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
    />
  </mat-chip-list>
  <mat-autocomplete #auto1="matAutocomplete" (optionSelected)="addEmployee($event)">
    <mat-option *ngFor="let emp of filteredEmployees | async" [value]="emp">
      {{ emp.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
