<div id="header-page" class="ng-star-inserted header-page">
    <div class="page-header ng-star-inserted">
        <div class="page-block ng-star-inserted">
            <div class="row align-items-center">
                <div class="col-md-8">
                    <div class="page-header-title">
                        <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4
                            class="m-b-10 ng-star-inserted">Disciplinary actions</h4></span>
                    </div>
                </div>
                <div class="col-md-4"></div>
            </div>
        </div>
    </div>
</div>
<div class="page-body no-title" *ngIf="!showForm">
    <div class="row">
        <div class="col-sm-12">
            <div class="row top-tools">
                <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6 left-tools">
                  <span class="export-icon-btn csv" (click)="exportToCsv()"><i class="material-icons">get_app</i><span>CSV</span>
                  </span>
                    <span class="export-icon-btn pdf" (click)="saveAsPdf()"><i class="material-icons">get_app</i><span>PDF</span>
                  </span>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
                    <div class="relative-position search-filter-con">
                        <!-- <span class="icon material-icons ng-tns-c114-0"> search </span>
                        <mat-form-field>
                            <input matInput #inputSearch type="search" [(ngModel)]="filterQuery" maxlength="40"
                                   (ngModelChange)="onModelChange(filterQuery)"
                                   class="form-control input-sm full-data-search">
                        </mat-form-field> -->
                    </div>
                </div>
            </div>
            <app-card [title]="'View My Actions'">
                <div class="dt-responsive" *ngIf="dataSource">
                    <div class="table-responsive">
                        <mat-table [dataSource]="dataSource" matSort>
                            <ng-container matColumnDef="employeeId">
                                <mat-header-cell *matHeaderCellDef mat-sort-header >Employee ID</mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                    {{ row.employeeId}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="employeeName">
                                <mat-header-cell *matHeaderCellDef  mat-sort-header>Employee</mat-header-cell>
                                <mat-cell *matCellDef="let row" (click)="editAction(row)">
                                    {{row.employeeName}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="ownerName">
                                <mat-header-cell *matHeaderCellDef  mat-sort-header>Owner</mat-header-cell>
                                <mat-cell  *matCellDef="let row" (click)="editAction(row)">
                                    <span class="cursor-pointer"> {{row?.ownerName}} </span>
                                 </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="actionName" >
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Action</mat-header-cell>
                                <mat-cell *matCellDef="let row" (click)="editAction(row)">
                                    <span class="cursor-pointer"> {{row.actionName}} </span>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="dueDate">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Due Date</mat-header-cell>
                                <mat-cell *matCellDef="let row" (click)="editAction(row)">
                                    <span class="cursor-pointer"> {{row.dueDate | date :'dd/MM/yyyy'}} </span>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="actionBy">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Action By</mat-header-cell>
                                <mat-cell *matCellDef="let row" (click)="editAction(row)">
                                    <span class="cursor-pointer">{{row.actionBy}}</span>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="statusName">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
                                <mat-cell *matCellDef="let row" (click)="editAction(row)">
                                    <span class="cursor-pointer"> {{row.statusName}} </span>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="completedOn">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Completed On</mat-header-cell>
                                <mat-cell *matCellDef="let row" (click)="editAction(row)">
                                    <span class="cursor-pointer"> {{row.completedOn | date :'dd/MM/yyyy'}} </span>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="attachments">
                                <mat-header-cell  *matHeaderCellDef>Documents</mat-header-cell>
                                <mat-cell class="cursor-pointer" *matCellDef="let row">
                                    {{row.attachments}}

                                    <span class="mytooltip tooltip-effect-4" *ngIf="!row.hasAttachment">
                                            <span class="tooltip-item">
                                                    <i (click)="onAddAttachment(row.id)" class="single-icon-click flaticon-attachment"
                                                   style="color: #d0cdcd"></i>
                                            </span>
                                            <span class="tooltip-content clearfix">
                                            <span class="tooltip-text"> No Documents</span>
                                            </span>
                                          </span>

                                    <span class="mytooltip tooltip-effect-4" *ngIf="row.hasAttachment">
                                            <span class="tooltip-item">
                                                <i class="single-icon-click flaticon-attachment" style="color: #666"
                                                   (click)="onAddAttachment(row.id)"></i></span>
                                            <span class="tooltip-content clearfix">
                                            <span class="tooltip-text">Include Documents</span>
                                            </span>
                                          </span>
                                </mat-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;"
                                     class="cursor-pointer">
                            </mat-row>
                        </mat-table>
                        <mat-paginator [length]="totalRecords" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                                       [pageIndex]="pageIndex" (page)="pageChange($event)">
                        </mat-paginator>
                    </div>
                </div>
            </app-card>
        </div>
    </div>
</div>


<div class="page-body no-title" *ngIf="showForm">
    <div *ngIf="!editActionForm" class="discipline-action-head">
        Add Action
    </div>
    <div *ngIf="editActionForm" class="discipline-action-head">
        Action
    </div>

    <div class="discipline-action-form">
        <form class="form-material full-width-inputs" [formGroup]="disciplineActionForm">
            <div class="row form-group">
                <div class="col-sm-4">
                    <mat-form-field class="form-element">
                        <input class="form-control" matInput placeholder="Disciplinary Case" formControlName="caseName"
                               readonly>
                    </mat-form-field>
                </div>
                <div class="col-sm-4">
                    <mat-form-field class="form-element">
                        <input class="form-control" matInput placeholder="Action" formControlName="action" readonly>
                    </mat-form-field>
                </div>
                <div class="col-sm-4">
                    <mat-form-field class="form-element">
                        <input class="form-control" matInput placeholder="Action By" formControlName="actionBy"
                               readonly>
                    </mat-form-field>
                </div>
                <div class="col-sm-4">
                    <mat-form-field class="form-element">
                        <input class="form-control" matInput placeholder="Owner" formControlName="ownerName" readonly>
                    </mat-form-field>
                </div>
                <div class="col-sm-4">
                    <mat-form-field>
                        <mat-label for="dueDate">Due Date</mat-label>
                        <input [readonly]="!update" [matDatepicker]="picker10" class="form-control"
                               formControlName="dueDate" id="dueDate" [(ngModel)]="disciplineActionForm.value.dueDate"
                               matInput>
                        <mat-datepicker-toggle [for]="picker10"
                                               matSuffix></mat-datepicker-toggle>
                        <mat-datepicker #picker10></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-sm-4">
                    <mat-form-field class="form-element">
                        <mat-select [disabled]="!update" class="form-control" formControlName="status" placeholder="Status" #singleSelect>
                            <mat-option [value]="'open'">Open</mat-option>
                            <mat-option [value]="'in_progress'">In Progress</mat-option>
                            <mat-option [value]="'completed'">Completed</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-sm-12">
                    <mat-form-field>
                        <textarea class="form-control" matInput placeholder="Description"
                                  formControlName="desc"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </form>
        <div class="discipline-action-footer">
            
            <button mat-raised-button color="primary" (click)="onNoClick()">Cancel</button>
            <button mat-raised-button color="primary" (click)="openViewDispline()">View Disciplinary Case</button>
            <button [hidden]="!update" *ngIf="editActionForm" mat-raised-button color="primary" (click)="editActionCase()">Save</button>
        </div>
    </div>


    <!--    <div style="margin-top: 40px;">-->
    <!--        <mat-table [dataSource]="documentData" matSort>-->
    <!--            <ng-container matColumnDef="documentName">-->
    <!--                <mat-header-cell *matHeaderCellDef>Document Name</mat-header-cell>-->
    <!--                <mat-cell *matCellDef="let row"> {{row.daName}}</mat-cell>-->
    <!--            </ng-container>-->
    <!--            <ng-container matColumnDef="cretedBy">-->
    <!--                <mat-header-cell *matHeaderCellDef mat-sort-header>Created By</mat-header-cell>-->
    <!--                <mat-cell *matCellDef="let row">{{row.ddtName}}-->
    <!--                </mat-cell>-->
    <!--            </ng-container>-->
    <!--            <ng-container matColumnDef="createdOn">-->
    <!--                <mat-header-cell *matHeaderCellDef mat-sort-header>Created On</mat-header-cell>-->
    <!--                <mat-cell *matCellDef="let row">{{row.createdOn | date :'dd/MM/yyyy hh:mm a'}}-->
    <!--                </mat-cell>-->
    <!--            </ng-container>-->
    <!--            <mat-header-row *matHeaderRowDef="displayedColumns1"></mat-header-row>-->
    <!--            <mat-row *matRowDef="let row; columns: displayedColumns1;">-->
    <!--            </mat-row>-->
    <!--        </mat-table>-->
    <!--    </div>-->
</div>

<ng-template #deleteUser let-modal>
    <div class="card my-0">
        <div class="header">
            <h4 class="card-title card-title-modal">Delete Action</h4>
        </div>
        <div class="card-content px-2 py-2">
            <div class="text-center are-u">Are you sure ?</div>
            <div class="row">
                <div class="action-buttons multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn btn-cancel btn-danger" (click)="close()"><i
                            class="flaticon-cancel"></i>Cancel
                    </button>
                    <button mat-raised-button class="btn btn-save btn-primary" (click)="deleteActionById(1)"><i
                            class="flaticon-delete"></i>Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #disActions let-modal>
    <div class="card my-0">
        <div class="header">
            <h4 class="card-title card-title-modal">Disciplinary Actions</h4>
        </div>
        <div class="card-content px-2 py-2">
            <label id="example-radio-group-label">Select at least one to proceed</label>
            <mat-radio-group
                    aria-labelledby="example-radio-group-label"
                    class="example-radio-group"
                    [(ngModel)]="disAction">
                <mat-radio-button class="example-radio-button" *ngFor="let war of actions;let i=index;" [value]="war"
                                  [checked]="i==0">
                    {{war.name}}
                </mat-radio-button>
            </mat-radio-group>

            <div class="row">
                <div class="action-buttons multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn btn-cancel btn-danger" (click)="close()"><i
                            class="flaticon-cancel"></i>Cancel
                    </button>
                    <button mat-raised-button class="btn btn-save btn-primary" [disabled]="disAction==null"
                            (click)="selectAction()"><i class="flaticon-diskette"></i>Select
                    </button>
                </div>
                <!-- <div class="col-sm-12 text-right">
                    <button type="submit" class="btn btn-cancel btn-danger" (click)="close()">Cancel</button>
                    <button type="submit" class="btn btn-save btn-primary" [disabled]="disAction==null"
                            (click)="selectAction()">Select
                    </button>
                </div> -->
            </div>
        </div>
    </div>
</ng-template>


<ng-template #disTemplate let-modal>
    <div class="card my-0">
        <div class="header">
            <h4 class="card-title card-title-modal">Document Templates</h4>
        </div>
        <div class="card-content px-2 py-2">
            <label id="example-radio-group-label">Select at least one to proceed</label>
            <mat-radio-group
                    aria-labelledby="example-radio-group-label"
                    class="example-radio-group"
                    [(ngModel)]="disTemplateData">
                <mat-radio-button class="example-radio-button" *ngFor="let war of DocumentTemp;let i=index;"
                                  [value]="war"> <!-- [checked]="i==0" -->
                    {{war.action}}
                </mat-radio-button>
            </mat-radio-group>

            <div class="row">
                <div class="action-buttons multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn btn-cancel btn-danger" (click)="close()"><i
                            class="flaticon-cancel"></i>Cancel
                    </button>
                    <button mat-raised-button class="btn btn-save btn-primary" [disabled]="disTemplateData==null"
                            (click)="selectDocument()"><i class="flaticon-diskette"></i>Select
                    </button>
                </div>
                <!-- <div class="col-sm-12 text-right">
                    <button type="submit" class="btn btn-cancel btn-danger" (click)="close()">Cancel</button>
                    <button type="submit" class="btn btn-save btn-primary" [disabled]="disTemplateData==null"
                            (click)="selectDocument()">Select
                    </button>
                </div> -->
            </div>
        </div>
    </div>
</ng-template>

<ng-template #attach let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">Documents</h4>
        </div>
        <div class="mat-modal-body">
            <app-loading *ngIf="load" [isLoading]="load"></app-loading>

            <form class="form-material full-width-inputs" [hidden]="load">
                <div class="row">
                    <div class="col-md-3">
                        <div class="upload-btn-wrapper">
                            <button class="upload-btn"><i style="padding-right: 5px" class="flaticon-attachment"></i>Document</button>
                            <input type="file" name="myfile" class="form-control" (change)="fileChange($event, 'dd')">
                            <div class="fileName">{{this.fileObj && this.fileObj.fileName}}
                                <i *ngIf="fileUrl" class="fa fa-check success"></i>
                            </div>
                        </div>
                        <div class="upload-btn-wrapper">
                        </div>
                        <div class="file-error text-danger" *ngIf="!fileUrl && fileError">{{fileError}}</div>

                    </div>
                    <div class="help-text">Accept Up TO 2MB</div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field class="form-element">
                            <mat-label>Comment</mat-label>
                            <textarea matInput name="name" [(ngModel)]="comment" class="form-control"></textarea>
                        </mat-form-field>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-12 border" style="height: 200px !important; overflow-y: auto">
                        <mat-spinner *ngIf="this.showSpinner"></mat-spinner>
                        <mat-card *ngIf="showSpinner == false && this.attachmentList.length === 0">
                            No Attachment Found
                        </mat-card>
                        <ng-container *ngFor="let attachment of attachmentList; let i = index;">
                            <mat-card class="mt-2 shadow-sm">
                                <mat-card-title>
                                    {{attachment.fileName}}
                                </mat-card-title>
                                <mat-card-content>
                                    <b>Added On :</b> {{attachment.added | date :'dd/MM/yyyy' }} <br>
                                    <b>comments :</b> {{attachment.desc}}
                                </mat-card-content>
                                <mat-card-actions align="end">
                                    <button [hidden]="!delete" (click)="onDeleteClicked(attachment)" mat-icon-button
                                            aria-label="Example icon button with a vertical three dot icon">
                                        <mat-icon color="warn">delete</mat-icon>
                                    </button>
                                    <button  (click)="onDownloadClicked(attachment)" mat-icon-button
                                            aria-label="Example icon button with a vertical three dot icon">
                                        <mat-icon color="primary">cloud_download</mat-icon>
                                    </button>
                                </mat-card-actions>
                            </mat-card>
                        </ng-container>
                    </div>
                </div>
                <br>
                <div class="row"  [hidden]="load">
                    <div class="action-buttons col-md-12 text-right">
                        <button [hidden]="!update" mat-raised-button class="btn-primary" (click)="saveAttachment()" color="primary"><i
                                class="flaticon-diskette"></i>Save
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<ng-template #addDisciplinary let-modal>
    <div mat-dialog-content>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">
        
                <span>View Disciplinary Case</span>
            </h4>
        </div>
        <div class="mat-modal-body">
            <app-loading *ngIf="isLoad" [isLoading]="isLoad"></app-loading>


            <form class="form-material full-width-inputs" [formGroup]="disciplineForm2" action="javascript:"
                  *ngIf="!isLoad">
                <div class="row form-group">
                    <div class="col-sm-6">
                        <mat-form-field class="form-element">
                            <input class="form-control" matInput placeholder="Case Name" formControlName="caseName">
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6">
                        <mat-form-field class="form-element">
                            <mat-label class="col-form-label">Employee</mat-label>
                            <input  readonly required class="form-control" type="text"
                                    aria-label="Employee"
                                    matInput
                                    formControlName="empId"
                                    [matAutocomplete]="auto2"

                            >
                            <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayEmployeeByName2.bind(this)">
                                <mat-option *ngFor="let r of employeeOptions " [value]="r.value">
                                    {{r.label}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6">
                        <mat-form-field class="form-element">
                            <input class="form-control" matInput placeholder="Created On" formControlName="createdOn"
                                   readonly>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6">
                        <mat-form-field class="form-element">
                            <input class="form-control" matInput placeholder="Created By" formControlName="createdBy"
                                   readonly>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12">
                        <mat-form-field class="form-element">
                            <textarea readonly class="form-control" matInput placeholder="Description"
                                      formControlName="desc"></textarea>
                            <mat-label>Description</mat-label>
                        </mat-form-field>
                    </div>
                </div>
            </form>


        


            <div  class="action-buttons multi-btns col-sm-12 text-right">
            

                <button mat-raised-button class="btn btn-primary" color="primary" (click)="closeDia()">
                   close
                </button>
            </div>


     
        </div>
    </div>
</ng-template>