<div class="wrapper">
    <div class="sidebar" data-color="danger" data-background-color="white" data-image="./assets/img/sidebar-1.jpg">
      <div class="logo">
          <a href="https://www.creative-tim.com" class="simple-text">
              <div class="logo-img">
                <img src="../../../assets/logo1.png">

              </div>
              Creative Tim
          </a>
      </div>
      <div class="sidebar-wrapper">
        <div *ngIf="isMobileMenu()">
          <form class="navbar-form">
            <span class="bmd-form-group">
              <div class="input-group no-border">
                <input type="text" value="" class="form-control" placeholder="Search...">
                <button raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
                  <i class="material-icons">search</i>
                  <div class="ripple-container"></div>
                </button>
              </div>
            </span>
          </form>
          <ul class="nav navbar-nav nav-mobile-menu">
              <li class="nav-item">
                  <a class="nav-link" href="#pablo">
                      <i class="material-icons">dashboard</i>
                      <p>
                          <span class="d-lg-none d-md-block">Stats</span>
                      </p>
                  </a>
              </li>
              <li class="nav-item dropdown">
                  <a class="nav-link" href="#pablo" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="material-icons">notifications</i>
                      <span class="notification">5</span>
                      <p>
                          <span class="d-lg-none d-md-block">Some Actions</span>
                      </p>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                      <a class="dropdown-item" href="#">Mike John responded to your email</a>
                      <a class="dropdown-item" href="#">You have 5 new tasks</a>
                      <a class="dropdown-item" href="#">You're now friend with Andrew</a>
                      <a class="dropdown-item" href="#">Another Notification</a>
                      <a class="dropdown-item" href="#">Another One</a>
                  </div>
              </li>
              <li class="nav-item">
                  <a class="nav-link" href="#pablo">
                      <i class="material-icons">person</i>
                      <p>
                          <span class="d-lg-none d-md-block">Account</span>
                      </p>
                  </a>
              </li>
          </ul>
        </div>
          <ul class="nav">
            <li>
            <a href="#admin" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle nav-link"><span class="material-icons left-menu-icon">supervised_user_circle</span>Admin</a>
            <ul class="collapse list-unstyled" id="admin">
              <li>
                  <a href="#usrMgmt" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">User Management</a>
                  <ul class="collapse list-unstyled" id="usrMgmt">
                      <li><a routerLink="/dashboard/admin/user">Users</a></li>
                      <li><a routerLink="/dashboard/admin/userRole">User Roles</a></li>
                  </ul>
              </li>
              <li>
                  <a href="#job" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Job</a>
                  <ul class="collapse list-unstyled" id="job">
                      <li><a href="#">Job Titles</a></li>
                      <li><a href="#">Salary Component</a></li>
                      <li><a href="#">Pay Grades</a></li>
                      <li><a href="#">Employment Status</a></li>
                      <li><a href="#">Job Categories</a></li>
                      <li><a href="#">Work Shifts</a></li>
                  </ul>
              </li>
              <li>
                  <a href="#org" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Organization</a>
                  <ul class="collapse list-unstyled" id="org">
                      <li><a href="#">General Information</a></li>
                      <li><a href="#">Locations</a></li>
                      <li><a href="#">Structure</a></li>
                      <li><a href="#">Cost Centers</a></li>
                      <li><a href="#">Process EEO Filing</a></li>
                  </ul>
              </li>
              <li>
                <a href="#comp" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Competencies</a>
                <ul class="collapse list-unstyled" id="comp">
                    <li><a href="#">Competency List</a></li>
                </ul>
            </li>
            <li>
                <a href="#qua" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Qualifications</a>
                <ul class="collapse list-unstyled" id="qua">
                    <li><a href="#">Skills</a></li>
                    <li><a href="#">Education</a></li>
                    <li><a href="#">Licenses</a></li>
                    <li><a href="#">Languages</a></li>
                    <li><a href="#">Memberships</a></li>
                </ul>
            </li>
            <li>
                <a href="#">Nationality</a>
            </li>
            <li>
                <a href="#ann" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Announcements</a>
                <ul class="collapse list-unstyled" id="ann">
                    <li><a href="#">News</a></li>
                    <li><a href="#">Documents</a></li>
                    <li><a href="#">Document Category</a></li>
                </ul>
            </li>
            <li>
                <a href="#con" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Configurations</a>
                <ul class="collapse list-unstyled" id="con">
                    <li><a href="#">Email Settings</a></li>
                    <li><a href="#">Email Notifications</a></li>
                    <li><a href="#">Localization</a></li>
                    <li><a href="#">Authentication</a></li>
                    <li><a href="#">Directory Configuration</a></li>
                    <li><a href="#">Canned Reports</a></li>
                    <li><a href="#">Theme Colors</a></li>
                    <li><a href="#">Theme Images</a></li>
                </ul>
            </li>
            <li>
                <a href="#">Audit Trail</a>
            </li>
            </ul>
        </li>
        <li>
            <a href="#homeSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle nav-link"><span class="material-icons left-menu-icon">perm_identity</span>PIM</a>
            <ul class="collapse list-unstyled li-level-2" id="homeSubmenu">
                <li>
                    <a href="#con1" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle nav-link">Configurations</a>
                    <ul class="collapse list-unstyled li-level-3" id="con1">
                        <li><a href="#">Optional Fields</a></li>
                        <li><a href="#">Custom Fields</a></li>
                        <li><a href="#">Reporting Methods</a></li>
                        <li><a href="#">Add Employee Wizard</a></li>
                        <li><a href="#">Termination Reasons</a></li>
                        <li><a href="#">Document Template</a></li>
                    </ul>
                </li>
                <li><a href="#">Employee List</a></li>
                <li><a href="#">Add Employee</a></li>
                <li><a href="#">Reports</a></li>
                <li>
                    <a href="#manageD" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle nav-link">Manage Data</a>
                    <ul class="collapse list-unstyled" id="manageD">
                        <li><a href="#">Data Import</a></li>
                        <li><a href="#">Bulk Update</a></li>
                        <li><a href="#">Employee Data Export</a></li>
                    </ul>
                </li>
            </ul>
        </li>
        <!-- <li>
            <a href="#">MyInfo</a>
        </li>
        <li>
            <a href="#pageSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle nav-link">Leaves</a>
            <ul class="collapse list-unstyled" id="pageSubmenu">
                <li><a href="#">Apply</a></li>
                <li><a href="#">My Leave</a></li>
                <li>
                    <a href="#ent" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle nav-link">Entitlements</a>
                    <ul class="collapse list-unstyled" id="ent">
                        <li><a href="#">Add Entitlements</a></li>
                        <li><a href="#">Entitlement List</a></li>
                        <li><a href="#">My Entitlements</a></li>
                    </ul>
                </li>
                <li>
                    <a href="#rep" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle nav-link">Reports</a>
                    <ul class="collapse list-unstyled" id="rep">
                        <li><a href="#">Leave Usage</a></li>
                        <li><a href="#">My Leave Usage</a></li>
                        <li><a href="#">Bradford Factor</a></li>
                    </ul>
                </li>
                <li>
                    <a href="#confi" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle nav-link">Configure</a>
                    <ul class="collapse list-unstyled" id="confi">
                        <li><a href="#">Leave Period</a></li>
                        <li><a href="#">Leave Types</a></li>
                        <li><a href="#">Work Week</a></li>
                        <li><a href="#">Holidays</a></li>
                        <li><a href="#">Bradford factor threshold</a></li>
                        <li><a href="#">Working Weekend</a></li>
                        <li><a href="#">Notification</a></li>
                        <li><a href="#">Leave Calendar</a></li>
                    </ul>
                </li>
                <li><a href="#">Leave List</a></li>
                <li><a href="#">Assign Leave</a></li>
                <li><a href="#">Leave Calendar</a></li>
                <li><a href="#">Bulk Assign</a></li>
            </ul>
        </li>
        <li>
            <a href="#pageSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle nav-link">Time</a>
            <ul class="collapse list-unstyled" id="pageSubmenu">
              <li>
                  <a href="#timesheets" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle nav-link">Timesheets</a>
                  <ul class="collapse list-unstyled" id="timesheets">
                      <li><a href="#">My Timesheets</a></li>
                      <li><a href="#">Employee Timesheet</a></li>
                      <li><a href="#">Export to CSV</a></li>
                  </ul>
              </li>
              <li>
                  <a href="#attendance" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle nav-link">Attendance</a>
                  <ul class="collapse list-unstyled" id="attendance">
                      <li><a href="#">My Records</a></li>
                      <li><a href="#">Punch In/Out</a></li>
                      <li><a href="#">Employee Records</a></li>
                      <li><a href="#">Configuration</a></li>
                      <li><a href="#">Export to CSV</a></li>
                      <li><a href="#">Data upload</a></li>
                  </ul>
              </li>
              <li>
                  <a href="#reports" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle nav-link">Reports</a>
                  <ul class="collapse list-unstyled" id="reports">
                      <li><a href="#">Project Time</a></li>
                      <li><a href="#">Employee Project Time</a></li>
                      <li><a href="#">Pay Code Report</a></li>
                      <li><a href="#">Attendance Summary</a></li>
                      <li><a href="#">Monthy Attendance</a></li>
                      <li><a href="#">My Monthy Attendance</a></li>
                  </ul>
              </li>
              <li>
                  <a href="#acInfo" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle nav-link">Activity Info</a>
                  <ul class="collapse list-unstyled" id="acInfo">
                      <li><a href="#">Customers</a></li>
                      <li><a href="#">Projects</a></li>
                      <li><a href="#">Common Activities</a></li>
                  </ul>
              </li>
              <li>
                  <a href="#config" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle nav-link">Configuration</a>
                  <ul class="collapse list-unstyled" id="config">
                      <li><a href="#">Timesheet Periods</a></li>
                  </ul>
              </li>
            </ul>
        </li>
        <li>
            <a href="#pageSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle nav-link">Recruitment</a>
        </li>
        <li>
            <a href="#discipline" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle nav-link">Discipline</a>
            <ul class="collapse list-unstyled" id="discipline">
                <li><a href="#">Disciplinary Cases</a></li>
                <li><a href="#">My Actions</a></li>
                <li>
                    <a href="#configus" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle nav-link">Configuration</a>
                    <ul class="collapse list-unstyled" id="configus">
                        <li><a href="#">Document Templates</a></li>
                    </ul>
                </li>
            </ul>
        </li>
        <li>
            <a href="#pageSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle nav-link">Training</a>
            <ul class="collapse list-unstyled" id="pageSubmenu">
                <li><a href="#">Courses</a></li>
                <li><a href="#">Sessions</a></li>
                <li><a href="#">Training Sessions</a></li>
                <li><a href="#">Participating Sessions</a></li>
                <li>
                    <a href="#reps" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle nav-link">Reports</a>
                    <ul class="collapse list-unstyled" id="reps">
                      <li><a href="#">Training Sessions</a></li>
                      <li><a href="#">Participating Sessions</a></li>
                    </ul>
                </li>
            </ul>
        </li>
        <li>
            <a href="#pageSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle nav-link">Performance</a>
            <ul class="collapse list-unstyled" id="pageSubmenu">
                <li>
                    <a href="#">Page 1</a>
                </li>
                <li>
                    <a href="#">Page 2</a>
                </li>
                <li>
                    <a href="#">Page 3</a>
                </li>
            </ul>
        </li>
        <li>
            <a href="#onboard" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle nav-link">On-boarding</a>
            <ul class="collapse list-unstyled" id="onboard">
                <li><a href="#">Task Types</a></li>
                <li><a href="#">Events</a></li>
                <li><a href="#">My Events</a></li>
                <li><a href="#">Employee task</a></li>
                <li><a href="#">My Tasks</a></li>
            </ul>
        </li>
        <li>
            <a href="#pageSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle nav-link">Expense</a>
            <ul class="collapse list-unstyled" id="pageSubmenu">
                <li>
                    <a href="#">Page 1</a>
                </li>
                <li>
                    <a href="#">Page 2</a>
                </li>
                <li>
                    <a href="#">Page 3</a>
                </li>
            </ul>
        </li>
        <li>
            <a href="#pageSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle nav-link">Reports Catalog</a>
            <ul class="collapse list-unstyled" id="pageSubmenu">
                <li>
                    <a href="#">Page 1</a>
                </li>
                <li>
                    <a href="#">Page 2</a>
                </li>
                <li>
                    <a href="#">Page 3</a>
                </li>
            </ul>
        </li>
        <li>
            <a href="#pageSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle nav-link">More</a>
            <ul class="collapse list-unstyled" id="pageSubmenu">
                <li>
                    <a href="#">Page 1</a>
                </li>
                <li>
                    <a href="#">Page 2</a>
                </li>
                <li>
                    <a href="#">Page 3</a>
                </li>
            </ul>
        </li>
        <li>
            <a href="#maintainence" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle nav-link">Maintainence</a>
            <ul class="collapse list-unstyled" id="maintainence">
              <li>
                  <a href="#prec" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle nav-link">Purge Records</a>
                  <ul class="collapse list-unstyled" id="prec">
                    <li><a href="#">Employee Records</a></li>
                    <li><a href="#">Candidate Records</a></li>
                  </ul>
              </li>
            </ul>
        </li>
      -->



              <!-- <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
                  <a class="nav-link" [routerLink]="[menuItem.path]">
                      <i class="material-icons">{{menuItem.icon}}</i>
                      <p>{{menuItem.title}}</p>
                  </a>
              </li> -->
          </ul>
      </div>














        <!-- <app-sidebar></app-sidebar> -->
        <div class="sidebar-background" ></div>
    </div>
    <div class="main-panel">


      <nav class="navbar navbar-expand-lg navbar-transparent  navbar-absolute fixed-top">
          <div class="container-fluid">
              <div class="navbar-wrapper">
                <a class="navbar-brand" href="#">{{getTitle()}}</a>
              </div>
              <button raised-button class="navbar-toggler" type="button" (click)="sidebarToggle()">
                  <span class="sr-only">Toggle navigation</span>
                  <span class="navbar-toggler-icon icon-bar"></span>
                  <span class="navbar-toggler-icon icon-bar"></span>
                  <span class="navbar-toggler-icon icon-bar"></span>
              </button>
              <div class="collapse navbar-collapse justify-content-end" id="navigation">
                  <form class="navbar-form">
                      <div class="input-group no-border">
                          <input type="text" value="" class="form-control" placeholder="Search...">
                          <button raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
                              <i class="material-icons">search</i>
                              <div class="ripple-container"></div>
                          </button>
                      </div>
                  </form>
                  <ul class="navbar-nav">
                      <li class="nav-item">
                          <a class="nav-link" href="#pablo">
                              <i class="material-icons">dashboard</i>
                              <p>
                                  <span class="d-lg-none d-md-block">Stats</span>
                              </p>
                          </a>
                      </li>
                      <li class="nav-item dropdown">
                          <a class="nav-link" href="#pablo" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="material-icons">notifications</i>
                              <span class="notification">5</span>
                              <p>
                                  <span class="d-lg-none d-md-block">Some Actions</span>
                              </p>
                          </a>
                          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                              <a class="dropdown-item" href="#">Mike John responded to your email</a>
                              <a class="dropdown-item" href="#">You have 5 new tasks</a>
                              <a class="dropdown-item" href="#">You're now friend with Andrew</a>
                              <a class="dropdown-item" href="#">Another Notification</a>
                              <a class="dropdown-item" href="#">Another One</a>
                          </div>
                      </li>
                      <li class="nav-item">
                          <a class="nav-link" href="#pablo">
                              <i class="material-icons">person</i>
                              <p>
                                  <span class="d-lg-none d-md-block">Account</span>
                              </p>
                          </a>
                      </li>
                  </ul>
              </div>
          </div>
      </nav>

      <div>
        </div>
        <!-- <router-outlet></router-outlet> -->
    </div>
</div>
