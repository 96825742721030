import { Component, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { DashboardService } from "../../../dashboard.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormBuilder, Validators, AbstractControl } from "@angular/forms";
import { NotificationService } from "src/app/core/services/notification.service";
import { saveAs } from 'file-saver-es';
import { MatTableDataSource } from "@angular/material/table";
import { MatSort, Sort } from "@angular/material/sort";
import jsPDF from "jspdf";
import { MatCheckbox, MatCheckboxChange } from "@angular/material/checkbox";
import autoTable from "jspdf-autotable";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatDialog, MatDialogState } from "@angular/material/dialog";
import { LanguageConfig } from "src/app/Language-config";
import { startWith } from "rxjs/operators";
import { AuthService } from "src/app/core/services/auth.service";

export interface Role {
  name: string;
  roleType: string;
}

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"],
})
export class UserComponent implements OnInit {
  public permissions: any;
  public dialogRef: any;
  public userList: Array<any>;
  public roleList: Array<any>;
  public locationList: Array<any> = [];
  public isLoading: boolean;
  public isUserAdded: boolean;
  public err: string;
  public modalRef: any;
  public editUserForm: FormGroup;
  public passwordForm: FormGroup;
  public filterUserForm: FormGroup;
  public selectedUserId: string;
  public isEdit: boolean;
  public deleteId: string;
  public rolesList = [];
  public checkAll = false;

  selectedUsers: any[] = [];

  public confirmHide = true;
  public passwordHide = true;

  @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
  @ViewChild("addUser", { static: false }) addUser;
  @ViewChild("deleteUser", { static: false }) deleteUser;
  // @ViewChild("configure", { static: false }) configure;
  @ViewChild("filterUser", { static: false }) filterUser;
  @ViewChild("enablePassword", { static: false }) enableNewPassword: HTMLInputElement;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public selectedNewsDocs = [];

  // material data table
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  public columns = ["select", "employeeid", "employeeName", "email", "roleName", "roleType", "status", "edit"];

  // public filteredOptions: Array<any>;
  // public filteredUserNames: Array<any>;
  // public filteredOptions2: Array<any>;
  public lang = new Map();

  public filterEmployee: any;
  public selectedEmployee: any;
  public rolesMap = new Map<string, Role>();
  public submitLoading: boolean;
  public closeOnSuccess: boolean;

  pageSize: number = 10;
  pageIndex: number = 0;
  totalUsers: number = 0;

  isValidFormToSubmit:boolean = false;
  employeeid = '';
  employeename = '';

  public sortBy = [];

  constructor(
    private service: DashboardService,
    private authService: AuthService,
    private fb: FormBuilder,
    private toastr: NotificationService,
    private dialog: MatDialog
  ) {
    this.lang = LanguageConfig.map;
    this.createForm();
  }

  public permissionss: any;
  public read = false;
  public write = false;
  public update = false;
  public delete = false;

  checkPermission(module, tabs): void {
    this.permissionss = JSON.parse(this.authService.getPermissions());

    this.read = false;
    this.write = false;
    this.delete = false;
    this.update = false;
    if (this.permissionss != undefined && this.permissionss != null) {
      for (let p of this.permissionss) {
        if (p.id === module) {
          if (p.modules != undefined) {
            for (let tab of p.modules) {
              if (tab.id === tabs) {
                for (let move of tab.permits) {
                  if (move.id === "u") {
                    this.update = true;
                  } else if (move.id === "r") {
                    this.read = true;
                  } else if (move.id === "c") {
                    this.write = true;
                  } else if (move.id === "d") {
                    this.delete = true;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  ngOnInit() {
    
    // this.isLoading = true;
    this.checkPermission("admin", "ad_um");
    this.searchUsers(true);
    this.isUserAdded = false;
    this.isEdit = false;

    this.service.getUserRole().subscribe((resp) => {
      if (resp && resp.data) {
        this.roleList = resp.data;
        this.roleList.map((d) => {
          this.rolesList.push(d);
          let role = { name: d.name, roleType: d.roleType };
          this.rolesMap.set(d.id, role);
        });
      }
    });

    // here we are getting the values for the location
    this.service.getLocationList().subscribe(
      (resp) => {
        if (resp && resp.data) {
          resp.data.map((location) => {
            this.locationList.push({ label: location.location, value: location.id });
          });
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error in getting location");
      }
    );
  }

  public createForm(): void {
    this.editUserForm = this.fb.group({
      id: [""],
      employeeid: [""],
      employeename: [""],
      username: ["", Validators.required],
      roleid: ["", Validators.required],
      roleType: [""],
      password: ["", Validators.required],
      confirmPassword: ["", Validators.required],
      status: ["", Validators.required],
    });

    this.passwordForm = this.fb.group({
      newPassword: ["", Validators.required],
      confirmNewPassword: ["", Validators.required],
    });

    this.filterUserForm = this.fb.group({
      employeeid: [""],
      email: [""],
      role: [""],
      status: [""],
      location: [""],
    });

    this.selectedEmployee = { id: "", name: "" };
    this.filterEmployee = { id: "", name: "" };
  }

  public addNewUser() {
    this.isEdit = false;
    this.editUserForm.reset();

    this.dialogRef = this.dialog.open(this.addUser, {
      width: "700px",
      height: "auto",
    });

    this.isValidFormToSubmit = false;
    this.selectedEmployee = { id: "", name: "" };
  }

  public editUser(id): void {
    this.editUserForm.reset();

    this.dialogRef = this.dialog.open(this.addUser, {
      width: "700px",
      height: "auto",
    });

    this.isEdit = true;
    this.isLoading = true;
    this.isValidFormToSubmit = true;
    this.service.getUserById(id).subscribe(
      (user) => {
        if (user && user.data) {
          this.editUserForm.patchValue(user.data);
          this.selectedEmployee = { id: user.data.employeeid, name: user.data.employeeName };
          this.editUserForm.get("roleid").setValue(user.data.roles[0].userRole);

          this.employeeid = this.selectedEmployee.id;
          this.employeename = this.selectedEmployee.name;

          this.isLoading = false;
          this.selectedUserId = id;
          this.err = "";
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.isLoading = false;
        this.isUserAdded = false;
        this.err = "Error while getting details";
        this.toastr.error(err || this.err);
      }
    );
  }

  public submitAddUser(): void {
    if (this.isEdit) {
      this.updateUser();
    } else {
      this.saveNewUser();
    }
  }

  public saveNewUser(): void {
    this.isUserAdded = true;

    if (this.editUserForm.value.confirmPassword != this.editUserForm.value.password) {
      this.toastr.error("The password and confirm password does not match");
      return;
    }

    const val = this.editUserForm.value;
    val.employeeid = this.employeeid;
    val.employeename = this.employeename;
    val.roleType = this.rolesMap.get(val.roleid).roleType;
    this.submitLoading = true;
    this.service.addNeWUser(val).subscribe(
      (user) => {
        this.isUserAdded = false;
        this.toastr.success("User added successfully");
        this.searchUsers(false);
        this.submitLoading = false;
        this.dialogRef.close();
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.isUserAdded = false;
        this.toastr.error(err || "Error in adding user");
        this.submitLoading = false;
      }
    );
  }

  public updateUser(): void {
    const val = this.editUserForm.value;
    val.employeeid = this.employeeid;
    val.employeename = this.employeename;
    val.id = this.selectedUserId;
    val.roleType = this.rolesMap.get(val.roleid).roleType;

    this.isUserAdded = true;
    this.submitLoading = true;
    this.service.editUser(val).subscribe(
      (user) => {
        this.isUserAdded = false;
        this.toastr.success("User updated successfully");
        this.searchUsers(false);
        this.submitLoading = false;
        this.dialogRef.close();
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.isUserAdded = false;
        this.toastr.error(err || "Error in updating User.Please try again.");
        this.submitLoading = false;
      }
    );
  }

  
  public updateEmployeeInForm(event) {
    this.employeename = event?event.name:'';
    this.employeeid = event?event.id:'';
    this.isValidFormToSubmit = (this.employeename != '' && this.employeeid !='');
  }

  public updateFilterEmployee(event){
    this.filterEmployee.id = event?event.id:'';
    this.filterEmployee.name = event?event.name:'';
  }

  
  public close(): void {
    this.dialogRef.close();
  }

  public selectAll(): void {
    this.userList.map((u) => {
      u.checked = true;
    });
  }

  public deleteMultiple(): void {
    this.service.deleteMultipleUser(this.selectedNewsDocs).subscribe(
      (user) => {
        if (user) {
          this.close();
          this.searchUsers(false);
          this.toastr.success("User deleted");
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error deleting User");
      }
    );
    this.selectedNewsDocs = [];
  }

  rolesValidator(control: AbstractControl) {
    if (control.value === null || control.value === "") {
      return { emptyValue: true };
    }
    return null;
  }

  submitPassword() {
    const cpBody = {
      np: this.passwordForm.value.newPassword,
      op: this.passwordForm.value.confirmNewPassword,
      userId: this.selectedUserId,
    };

    if (this.passwordForm.value.confirmNewPassword != this.passwordForm.value.newPassword) {
      this.toastr.error("The password and confirm password does not match");
      return;
    }
    this.service.resetPassword(cpBody).subscribe(
      (resp) => {
        this.toastr.success("The password has been updated successfuly");
        this.dialogRef.close();
      },
      (error) => {
        this.toastr.error("Failed to reset Password\n" + error);
      }
    );
  }

  onNewDocSelectChange($event: MatCheckboxChange, id: any) {
    if (!this.alreadySelected($event.source) && $event.checked) {
      this.selectedNewsDocs.push($event.source.value);
    } else if (!$event.checked) {
      this.selectedNewsDocs = this.selectedNewsDocs.filter((value) => {
        return value !== $event.source.value;
      });
    }
  }

  onDeSelectAllClicked() {
    this.selectedNewsDocs = [];
    this.matCheckBoxes.forEach((item) => {
      item.checked = false;
    });
  }

  alreadySelected(item: MatCheckbox): boolean {
    this.selectedNewsDocs.forEach((value) => {
      if (item === value) {
        return true;
      }
    });
    return false;
  }

  onSelectAllClicked() {
    this.selectedNewsDocs = [];
    this.matCheckBoxes.forEach((item) => {
      this.selectedNewsDocs.push(item.value);
      item.checked = true;
    });
  }

  onDeleteSelected() {
    // this.dmodalRef = this.modalService.open(this.deleteUser);
    this.dialogRef = this.dialog.open(this.deleteUser, {
      width: "400px",
      height: "auto",
    });
  }

  exportToCsv() {
    const csv = this.userList.map((row) => {
      return (
        [
          row.employeeid,
          row.employeeName,
          row.email,
          row?.roles[0]?.userRoleObj.name,
          row?.roles[0]?.roleType,
          row.status === "active" ? "Active" : "Inactive",
        ].join(",") + "\n"
      );
    });

    csv.unshift(["Employee Number", "Employee Name", "Email", "User Role", "Role Type", "Status"].join(",") + "\n");
    const exportDate = new Date();
    // tslint:disable-next-line:max-line-length
    const exportFileName = `Csv-export-${exportDate.getFullYear()}-${
      exportDate.getMonth() + 1
    }-${exportDate.getDate()}.csv`;
    saveAs(new Blob(csv, { type: "text/csv;charset=utf-8" }), exportFileName);
  }

  saveAsPdf() {
    if (this.dataSource.filteredData.length <= 0) {
      this.toastr.error("No Data to export");
      return;
    }
    const body = [];
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      body.push([
        this.dataSource.filteredData[i].employeeid,
        this.dataSource.filteredData[i].employeeName,
        this.dataSource.filteredData[i].email,
        this.dataSource.filteredData[i].roles[0]?.userRoleObj.name,
        this.dataSource.filteredData[i].roles[0]?.roleType,
        this.dataSource.filteredData[i].status === "active" ? "Active" : "Inactive",
      ]);
    }
    const doc = new jsPDF();
    autoTable(doc, {
      head: [["Employee Number", "Employee Name", "Email", "User Role", "Role Type", "Status"]],
      body: body,
    });
    const exportDate = new Date();
    const exportFileName = `User-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}}`;
    doc.save(exportFileName);
  }

  filterUserList() {
    this.dialogRef = this.dialog.open(this.filterUser, {
      width: "700px",
      height: "auto",
      autoFocus: false,
    });
  }

  handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.searchUsers(false);
  }

  filterUsers() {
    this.pageIndex = 0;
    this.closeOnSuccess = true;
    this.searchUsers(true);
  }

  sortData(sort: Sort) {
    
    if(sort.direction){
      this.sortBy = [{'sortBy': sort.active, 'sortDir' : sort.direction }];
    }else{
      this.sortBy = [];
    } 
    this.searchUsers(false);
  }

  searchUsers(resetSort:boolean) {
    const body = {
      role: this.filterUserForm.value.role ,
      employeeId: this.filterEmployee.id,
      location: this.filterUserForm.value.location ,
      status: this.filterUserForm.value.status ,
      email: this.filterUserForm.value.email,
    };

    if(resetSort && this.sort){
      this.sort.active = '';
      this.sort.direction = '';
    }

    this.isLoading = true;
    this.service.getUserList(body, this.pageIndex, this.pageSize, this.sortBy).subscribe(
      (res) => {
        if (res && res.data && res.data.totalElements > 0) {
          this.userList = res.data.content;
          this.userList.map((user) => {
            let role = this.rolesMap.get(user.roles[0].userRole);
            user.roles[0].name = role ? role.name : "";
          });

          this.dataSource.data = this.userList;
          this.dataSource.sort = this.sort;
          this.totalUsers = res.data.totalElements;
        } else {
          this.dataSource.data = [];
          this.totalUsers = 0;
          this.toastr.error("No search result found");
        }
        this.isLoading = false;

        if (this.closeOnSuccess) {
          this.dialogRef.close();
          this.closeOnSuccess = false;
        }
      },
      (error) => {
        this.toastr.error(error.error.msg || "Error in getting the filter Request");
        this.isLoading = false;
      }
    );
  }

  resetFilterForm() {
    this.pageIndex = 0;
    this.filterUserForm.reset();
    this.filterUserForm = this.fb.group({
      employeeid: [""],
      email: [""],
      role: [""],
      status: [""],
      location: [""],
    });
    this.filterEmployee = { id: "", name: "" };
    this.searchUsers(true);
  }

  onModelChange(value: string) {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
}
