<div class="row top-tools">
    <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6 left-tools">
      <span *ngIf="csvButton" class="export-icon-btn csv" (click)="exportToCsv()"><i
              class="material-icons">get_app</i><span>CSV</span>
      </span>
      <span *ngIf="pdfButton" class="export-icon-btn pdf" (click)="saveAsPdf()"><i class="material-icons">get_app</i><span>PDF</span>
      </span>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
      <div class="relative-position search-filter-con"> 
          
        <span class="icon material-icons ng-tns-c114-0"> search </span>
        <mat-form-field> 
          <input matInput #inputSearch type="search" [(ngModel)]="value" maxlength="30"
                 (ngModelChange)="onModelChange(value)"
                 class="form-control input-sm full-data-search" >
        </mat-form-field>

        <span *ngIf="addButton" class="mytooltip cus-left tooltip-effect-4">
          <span class="tooltip-item">
              <button mat-mini-fab class="btn btn-primary  light add-rounded margin-button-05" (click)="addNew()">
                  <i class="fa fa-plus"></i>
              </button>
          </span>
          <span class="tooltip-content clearfix">
          <span class="tooltip-text">New</span>
          </span>
       </span>

       <span *ngIf="filterButton" class="mytooltip cus-left tooltip-effect-4">
        <span class="tooltip-item">
            <button mat-mini-fab class="btn btn-primary light add-rounded margin-button-05" (click)="filterList()">
                <i class="fa fa-filter"></i>
            </button>
        </span>
        <span class="tooltip-content clearfix">
        <span class="tooltip-text">filter</span>
        </span>
     </span>

      </div>
    </div>
  </div>