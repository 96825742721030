import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NotificationService } from "src/app/core/services/notification.service";
import { LanguageConfig } from "src/app/Language-config";
import { ClientControlService } from "../client-control.service";
import { DashboardService } from "../../dashboard.service";
import { startWith } from "rxjs/operators";

@Component({
  selector: "app-add-client",
  templateUrl: "./add-client.component.html",
  styleUrls: ["./add-client.component.scss"],
})
export class AddClientComponent implements OnInit {
  public isLoading: boolean;
  public currentHide: boolean = true;

  lang: Map<string, string>;

  public requestForm: FormGroup;
  public countryList: any;
  public filteredOptions: Array<any>;
  correct: boolean;

  constructor(
    private fb: FormBuilder,
    private clientContrlService: ClientControlService,
    private notifySrvc: NotificationService,
    private service: DashboardService
  ) {
    this.lang = LanguageConfig.map;
  }

  ngOnInit(): void {
    this.createForm();
    this.service.getCountryList().subscribe((resp) => {
      this.countryList = resp && resp.data;
    });
  }

  ngAfterViewInit() {
    this.filteredOptions = this.countryList;

    this.requestForm.controls["countrycode"].valueChanges.subscribe((change) => {
      if (change == null || change == undefined) {
        startWith("");
      } // Value inside the input field as soon as it changes
      else {
        this.filterCountry(change);
      }
    });
  }

  public createForm(): void {
    this.requestForm = this.fb.group({
      securityCode: "",
      firstname: "",
      lastname: "",
      client: "",
      email: "",
      orgName: "",
      location: "",
      region: "Europe",
      countrycode: "",
      jobTitle: "Administrator",
      emloyementStatus: "Full time",
      department: "Administartion",
    });
  }

  public addClient() {
    this.isLoading = true;
    this.clientContrlService.add(this.requestForm.value).subscribe(
      (res) => {
        this.isLoading = false;
        this.notifySrvc.success("Domain is created with password:" + res.data);
        //reset the form
        this.createForm();
        this.filteredOptions = [];
        this.correct = false;
        this.ngAfterViewInit();
      },
      (error) => {
        this.isLoading = false;
        const err = error.error && error.error.msg;
        this.notifySrvc.error(err || "Error in create client");
      }
    );
  }

  protected filterCountry(change: String) {
    this.filteredOptions = this.countryList;
    if (!this.countryList) {
      return;
    }

    // get the search keyword
    if (!change) {
      this.filteredOptions = [];
      return;
    } else {
      this.checkCountryChange(change);
      change = change.toLowerCase();
    }
    // filter the search
    this.filteredOptions = this.countryList.filter((co) => co.name.toLowerCase().indexOf(change) > -1);
  }

  public getCountryByIso(option) {
    this.correct = true;
    if (this.countryList) {
      return this.countryList.find((x) => x.iso === option).name;
    }
  }

  checkCountryChange(change) {
    var x = this.filteredOptions.find((x) => x.iso === change);
    if (!x) {
      this.correct = false;
    }
  }
}
