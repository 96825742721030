import { Component, Injectable, OnInit } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ClientControlService } from "../client-control.service";

@Component({
  selector: "app-new-customer",
  templateUrl: "./new-customer.component.html",
  styleUrls: ["./new-customer.component.scss"],
})
@Injectable()
export class NewCustomerComponent implements OnInit {
  public requestForm: FormGroup;
  public isLoading: boolean = false;
  public showResult: boolean = false;

  constructor(private fb: FormBuilder, private clientContrlService: ClientControlService) {}

  ngOnInit(): void {
    this.createForm();
  }

  public createForm(): void {
    this.requestForm = this.fb.group({
      name: "",
      email: "",
      phone: "",
      company: "",
    });
  }

  public add() {
    this.isLoading = true;
    this.clientContrlService.newCustomer(this.requestForm.value).subscribe(
      (res) => {
        this.isLoading = false;
        this.showResult = true;
      },
      (error) => {
        this.isLoading = false;
        const err = error.error && error.error.msg;
        console.log(err);
      }
    );
  }
}
