import {Injectable} from '@angular/core';
import {environment} from "@env/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs/index";

const API_URL = environment.apiUrl;

@Injectable({
    providedIn: 'root'
})
export class CompetencyService {

    constructor(
        private http: HttpClient
    ) {
    }

    public saveCompetency(body): Observable<any> {
        const url = `api/auth/organisation/competency`;
        return this.http.post(`${API_URL}${url}`, body);
    }

    public deleteCompetency(body): Observable<any> {
        const url = `api/auth/organisation/competency/delete`;
        return this.http.post(`${API_URL}${url}`, body);
    }

    public updateCompetency(body, id): Observable<any> {
        const url = `api/auth/organisation/competency/${id}`;
        return this.http.put(`${API_URL}${url}`, body);
    }

    public getCompetencies(body): Observable<any> {
        const keyValues = Object.keys(body).filter(k => body[k] ? true : false).map(k => `${k}=${body[k]}`).join('&');
        const url = `api/auth/organisation/competencies?${keyValues}`;
        return this.http.get(`${API_URL}${url}`);
    }
}
