import {Component, OnInit, Inject, ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { NotificationService } from "src/app/core/services/notification.service";
import {DashboardService} from '../dashboard/dashboard.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';

@Component({
    selector: 'app-dialog-hrm',
    templateUrl: './dialog-hrm.component.html',
    styleUrls: ['./dialog-hrm.component.scss']
})
export class DialogHrmComponent implements OnInit {
    // message: any;
    @ViewChild('disActions', {static: false}) disActions;
    ifMsgs: boolean;
    decline: boolean;
    public userFilterCtrl: FormControl = new FormControl();
    public disciplineForm: FormGroup;
    isLoading: boolean = false;
    employeeData: any[];
    filteredUsers: any[];
    typeForm: any;
    @ViewChild('singleSelect', {static: false}) singleSelect: MatSelect;
    protected _onDestroy = new Subject<void>();
    editData: boolean;
    editDataSave: any;
    modalRef: any;
    disAction: any;
    actions: any[];
    config = {
        displayKey: 'description',
        search: true,
        height: 'auto',
        placeholder: 'Select',
        customComparator: () => {
        },
        moreText: 'more',
        noResultsFound: 'No results found!',
        searchPlaceholder: 'Search',
        searchOnKey: 'name',
        clearOnSelection: false
    };
    warnings: string[] = ['Give verbal warning', 'Give written warning', 'Have disciplinary hearing', 'Provide Counselling', 'Put on probation', 'Send on administrative leave', 'Suspend', 'Terminate'];

    constructor(private service: DashboardService,
                private route: Router,
                private modalService: NgbModal,
                public dialog: MatDialog,
                private fb: FormBuilder,
                private toastr: NotificationService,
                public dialogRej: MatDialogRef<DialogHrmComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.typeForm = data.view;
        this.actions = [];
        this.editDataSave = data.data;
        this.disId = data.data.id;
        this.employeeData = [];
        this.filteredUsers = [];

        this.disciplineForm = this.fb.group({
            empId: ['', Validators.required],
            caseName: ['', Validators.required],
            desc: [''],
            // actionBy: [''],
            createdBy: [''],
            // completedOn: [new Date()],
            createdOn: [new Date()],
            status: ['in_progress'],
            // dueDate: [''],
        });

        if (data.view === 'editcase') {
            // this.getDisciplinesById(this.disId);
            this.disciplineForm.patchValue({
                empId: this.editDataSave.empId,
                caseName: this.editDataSave.caseName,
                desc: this.editDataSave.desc,
                createdBy: this.editDataSave.typeName,
                createdOn: this.editDataSave.createdOn,
            });
        }

    }

    onNoClick(): void {
        this.dialogRej.close();
    }

    sendMsg() {

        this.dialogRej.close(true);

    }

    ngOnInit() {
        this.getEmployeeList();
        this.getDisciplineTemplateForm();
    }

    public submitAddDisciplines() {
        console.log("data : " , this.disciplineForm.value);
        this.isLoading = true
        // this.service.addDiscipline(this.disciplineForm.value).subscribe(user => {
        //     this.toastr.success('Disciplinary added successfully');
        //     this.disciplineForm.reset();
        //     this.dialogRej.close(true);
        // }, error => {
        //     const err = error.error && error.error.msg;
        //     this.toastr.error(err || 'Error in adding Disciplinary.Please try again.');
        // });
    }

    public getEmployeeList(): void {
        this.isLoading = true;
        this.service.getEmployeeDetailList().subscribe(res => {
            this.isLoading = false;
            if (res && res.data) {
                this.employeeData = res.data;
                this.filteredUsers = this.employeeData.slice();
                console.log('filterdata', this.filteredUsers);
                // listen for search field value changes
                this.userFilterCtrl.valueChanges
                    .pipe(takeUntil(this._onDestroy))
                    .subscribe(() => {
                        this.filterUsers();
                    });
            }
        }, error => {
            this.isLoading = false;
        });
    }

    protected filterUsers() {
        if (!this.employeeData) {
            return;
        }
        // get the search keyword
        let search = this.userFilterCtrl.value;
        if (!search) {
            this.filteredUsers = this.employeeData.slice();
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the search
        this.filteredUsers =
            this.employeeData.filter(emp => emp.name.toLowerCase().indexOf(search) > -1);
    }

    public editCase(): void {
        this.editData = true;
    }

    public saveCase(): void {
        this.editDataSave.empId = this.disciplineForm.value.empId;
        this.editDataSave.desc = this.disciplineForm.value.desc;
        this.editDataSave.caseName = this.disciplineForm.value.caseName;

        const data = {
            'empId': this.disciplineForm.value.empId,
            'desc': this.disciplineForm.value.desc,
            'caseName': this.disciplineForm.value.caseName,
            // 'createdOn':this.editDataSave.createdOn,
            // 'createdBy':this.editDataSave.createdBy,
            'status': this.editDataSave.status,
            // "action": "verbal_warning",
            // "actionBy": this.editDataSave.createdBy,
            // "completedOn":new Date(),
            //  "dueDate": new Date(),
        };
        this.service.editDescipline(this.editDataSave.id, data).subscribe(user => {
            this.toastr.success('Disciplinary Updated successfully');
            this.disciplineForm.reset();
            this.onNoClick();
        }, error => {
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error in updating Disciplinary.Please try again.');
        });
    }

    public takeDisciplineAction() {
        this.onNoClick();
        this.modalRef = this.modalService.open(this.disActions);
    }

    public closeCase() {
        this.service.closeDiscipline(this.editDataSave.id).subscribe(user => {
            this.toastr.success('Disciplinary closed successfully');
            this.editDataSave.close = true;
        }, error => {
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error in closing Disciplinary.Please try again.');
        });
    }

    public close(): void {
        this.modalRef.close();
    }

    selectAction() {
        const data = {
            'id': this.editDataSave.id,
            'action': this.disAction,
            'actionBy': this.editDataSave.typeName,
            'actionById': this.editDataSave.createdBy,
            'empId': this.editDataSave.empId,
            'desc': this.editDataSave.desc,
            'caseName': this.editDataSave.caseName,
            'status': this.editDataSave.status
        };
        console.log('data', data, 'this.ediusave', this.editDataSave);
        this.service.saveEditCaseData(this.editDataSave);
        sessionStorage.setItem('disActionSave', JSON.stringify(data));
        this.route.navigate(['/discipline/my-actions', 2]);
        this.modalRef.close();
    }

    getDisciplineTemplateForm() {
        this.service.getDisciplineTemplateForm().subscribe(resp => {
            if (resp && resp.data) {
                this.actions = resp.data.actions;
                console.log('actions', this.actions);
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error in getting form detail');
        });

    }

    public getDisciplinesById(id) {
        this.service.getDesciplineById(id).subscribe(res => {
            if (res && res.data) {
                console.log('data', res.data);
                // this.disciplineData = res.data;
                // this.empDisciplinesExtra = res.extras;
                // for(var i=0;i<this.disciplineData.length;i++)
                // {
                //   this.disciplineData[i]['firstname']=this.empDisciplinesExtra.employees[this.disciplineData[i].empId].firstname;
                //   this.disciplineData[i]['lastname']=this.empDisciplinesExtra.employees[this.disciplineData[i].empId].lastname;
                //  if(this.disciplineData[i].createdBy)
                //  {
                //   this.disciplineData[i]['typeName']=this.empDisciplinesExtra.users[this.disciplineData[i].createdBy].typeName;
                //  }

                // this.disciplineForm.patchValue({
                //   empId: this.editDataSave.empId,
                //   caseName: this.editDataSave.caseName,
                //   desc: this.editDataSave.desc,
                //   createdBy: this.editDataSave.typeName,
                //   createdOn: this.editDataSave.createdOn,
                // })
            }

        }, error => {
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error in getting Disciplinary.Please try again.');
        });
    }

    disId(disId: any) {
        throw new Error('Method not implemented.');
    }
}

