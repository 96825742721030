import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AnnouncementRoutingModule } from "./announcement-routing.module";
import { AnnouncementComponent } from "./announcement.component";
import { NewsListComponent } from "./news/news-list/news-list.component";
import { AddNewsComponent } from "./news/add-news/add-news.component";
import { CategoryListComponent } from "./category/category-list/category-list.component";
import { AddCategoryComponent } from "./category/add-category/add-category.component";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { MaterialModule } from "../../../module/material/material.module";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { HrmSharedModule } from "../../../hrmshared/hrmshared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
//import { MatSpinner } from '@angular/material/progress-spinner';
import { MatSortModule } from "@angular/material/sort";
import { SelectModule } from "ng-select";
import { SharedModule } from "../../../shared/shared.module";
//import {DataTableModule} from "angular2-datatable";
import { AddNewsTabComponent } from "./news/add-news-tab/add-news-tab.component";
import { PublishSettingComponent } from "./news/publish-setting/publish-setting.component";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { AcksListComponent } from "./news/ackList/ack-list.component";

@NgModule({
  declarations: [
    AnnouncementComponent,
    NewsListComponent,
    AddNewsComponent,
    AcksListComponent,
    CategoryListComponent,
    AddCategoryComponent,
    AddNewsTabComponent,
    PublishSettingComponent,
  ],
  exports: [AddCategoryComponent],
  imports: [
    CommonModule,
    AnnouncementRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HrmSharedModule,
    SelectModule,
    MatIconModule,
    SharedModule,
    MaterialModule,
    NgxMatSelectSearchModule,
    NgxMaterialTimepickerModule,
    AngularEditorModule,
    MatCardModule,
    MatSortModule,
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: "en-GB" }],
})
export class AnnouncementModule {}
