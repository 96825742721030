<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
    <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="page-header-title">
            <span class="ng-star-inserted"></span
            ><span class="ng-star-inserted">
              <h4 class="m-b-10 ng-star-inserted">Bulk Assign Leave</h4>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <app-loading [isLoading]="leaveTypeList && leaveTypeList.length === 0"></app-loading> -->
<!--  -->

<app-card *ngIf="leaveTypeList.length > 0 && !this.success">
  <div class="dt-responsive" *ngIf="dataSource">
    <div class="table hrm-table-style-1 full-data-table table-cols-4">
      <mat-card-content>
        <form class="form-material full-width-inputs" [formGroup]="assignForm" action="javascript:">
          <div class="row form-group">
            <div class="col-md-4">
              <h4>Bulk Assign Leave</h4>
            </div>
          </div>

          <br />
          <div class="row form-group">
            <div class="col-md-6">
              <mat-form-field>
                <mat-label> Leave Type</mat-label>
                <mat-select
                  required
                  disableOptionCentering
                  name="leave"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="leavetypeid"
                  class="form-control"
                  (ngModelChange)="getLocationAndSubUnitForLeaveType($event); getEmployeesCountForLeave($event)"
                >
                  <mat-option value="">--Select--</mat-option>
                  <mat-option *ngFor="let t of leaveTypeList" [value]="t.id">
                    {{ t.leaveType }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="form-element">
                <mat-label for="des">Date From/To</mat-label>
                <br />
                <mat-date-range-input required [rangePicker]="picker1">
                  <input required matStartDate formControlName="startdate" />
                  <input required matEndDate formControlName="enddate" />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-date-range-picker #picker1></mat-date-range-picker>
              </mat-form-field>
            </div>
          </div>

          <div class="row form-group">
            <div
              class="col-md-4"
              *ngIf="
                assignForm.value.startdate &&
                assignForm.value.enddate &&
                (assignForm.value.startdate | date : 'dd/MM/yyyy') != (assignForm.value.enddate | date : 'dd/MM/yyyy')
              "
            >
              <mat-form-field class="form-element">
                <mat-label>Partial Days</mat-label>
                <mat-select
                  (ngModelChange)="this.setDefaultValues()"
                  disableOptionCentering
                  name="partial"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="partialDays"
                  class="form-control"
                >
                  <mat-option [value]="'none'">None</mat-option>
                  <mat-option [value]="'all'">All Day</mat-option>
                  <mat-option [value]="'st'">Start Day</mat-option>
                  <mat-option [value]="'en'">End Day</mat-option>
                  <mat-option [value]="'sten'">Start and End Day</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div
            class="row form-group"
            *ngIf="
              assignForm.value.startdate &&
              assignForm.value.enddate &&
              (assignForm.value.startdate | date : 'dd/MM/yyyy') === (assignForm.value.enddate | date : 'dd/MM/yyyy')
            "
          >
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Duration</mat-label>
                <mat-select
                  disableOptionCentering
                  name="duration1"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="durationsOne"
                  class="form-control"
                >
                  <mat-option [value]="'fullDay'">Full Day</mat-option>
                  <mat-option [value]="'halfDay'">Half Day</mat-option>
                  <mat-option [value]="'SpecificTime'">Specific Time</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4" *ngIf="this.durationsOne === 'halfDay'">
              <mat-form-field>
                <mat-label></mat-label>
                <mat-select
                  name="type"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="durationsTypedayOne"
                  class="form-control"
                >
                  <mat-option [value]="'morning'">Morning</mat-option>
                  <mat-option [value]="'evening'">Evening</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-3" *ngIf="this.durationsOne === 'SpecificTime'">
              <mat-label>From</mat-label>
              <input
                (ngModelChange)="calcDurationStartOne($event)"
                [ngModelOptions]="{ standalone: true }"
                name="from"
                matinput
                [ngxTimepicker]="picker3"
                id="from"
                [(ngModel)]="startTimeOne"
                [format]="24"
                class="form-control"
              />
              <ngx-material-timepicker #picker3></ngx-material-timepicker>
              <mat-error *ngIf="checkTime">From time should be before to time</mat-error>
            </div>
            <div class="col-md-3" *ngIf="this.durationsOne === 'SpecificTime'">
              <mat-label>to</mat-label>
              <input
                (ngModelChange)="calcDurationEndOne($event)"
                [ngModelOptions]="{ standalone: true }"
                name="to"
                matInput
                [ngxTimepicker]="picker4"
                id="to"
                [(ngModel)]="endTimeOne"
                [format]="24"
                class="form-control"
                [min]="this.startTimeOne"
              />
              <ngx-material-timepicker #picker4></ngx-material-timepicker>
              <mat-error *ngIf="checkTime">To time should be after from time</mat-error>
            </div>

            <div class="col-md-3" *ngIf="this.durationsOne === 'SpecificTime'">
              <mat-label>Duration</mat-label>
              <mat-form-field class="form-element">
                <input
                  [readonly]="true"
                  [ngModelOptions]="{ standalone: true }"
                  name="duration"
                  [(ngModel)]="this.durationOne"
                  matInput
                />
              </mat-form-field>
            </div>
          </div>

          <div
            class="row form-group"
            *ngIf="
              assignForm.value.startdate &&
              assignForm.value.enddate &&
              this.partialDays === 'all' &&
              (assignForm.value.startdate | date : 'dd/MM/yyyy') != (assignForm.value.enddate | date : 'dd/MM/yyyy')
            "
          >
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Duration</mat-label>
                <mat-select
                  disableOptionCentering
                  name="duration1"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="durations"
                  class="form-control"
                >
                  <mat-option [value]="'halfDay'">Half Day</mat-option>
                  <mat-option [value]="'SpecificTime'">Specific Time</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4" *ngIf="this.durations === 'halfDay'">
              <mat-form-field>
                <mat-label></mat-label>
                <mat-select
                  name="type"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="durationsTypeday"
                  class="form-control"
                >
                  <mat-option [value]="'morning'">Morning</mat-option>
                  <mat-option [value]="'evening'">Evening</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-3" *ngIf="this.durations === 'SpecificTime'">
              <mat-label>From</mat-label>
              <input
                (ngModelChange)="calcDurationStart($event)"
                [ngModelOptions]="{ standalone: true }"
                name="from"
                matinput
                [ngxTimepicker]="picker3"
                id="from"
                [(ngModel)]="startTime"
                [format]="24"
                class="form-control"
              />
              <ngx-material-timepicker #picker3></ngx-material-timepicker>
              <mat-error *ngIf="checkTime">From time should be before to time</mat-error>
            </div>
            <div class="col-md-3" *ngIf="this.durations === 'SpecificTime'">
              <mat-label>to</mat-label>
              <input
                (ngModelChange)="calcDurationEnd($event)"
                [ngModelOptions]="{ standalone: true }"
                name="to"
                matInput
                [ngxTimepicker]="picker4"
                id="to"
                [(ngModel)]="endTime"
                [format]="24"
                class="form-control"
                [min]="this.startTime"
              />
              <ngx-material-timepicker #picker4></ngx-material-timepicker>
              <mat-error *ngIf="checkTime">To time should be after from time</mat-error>
            </div>

            <div class="col-md-3" *ngIf="this.durations === 'SpecificTime'">
              <mat-label>Duration</mat-label>
              <mat-form-field class="form-element">
                <input
                  [readonly]="true"
                  [ngModelOptions]="{ standalone: true }"
                  name="duration"
                  [(ngModel)]="this.duration"
                  matInput
                />
              </mat-form-field>
            </div>
          </div>

          <div
            class="row form-group"
            *ngIf="
              assignForm.value.startdate &&
              assignForm.value.enddate &&
              this.partialDays === 'st' &&
              (assignForm.value.startdate | date : 'dd/MM/yyyy') != (assignForm.value.enddate | date : 'dd/MM/yyyy')
            "
          >
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>ٍStart Day</mat-label>
                <mat-select
                  disableOptionCentering
                  name="duration2"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="durations"
                  class="form-control"
                >
                  <mat-option [value]="'halfDay'">Half Day</mat-option>
                  <mat-option [value]="'SpecificTime'">Specific Time</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4" *ngIf="this.durations === 'halfDay'">
              <mat-form-field>
                <mat-label></mat-label>
                <mat-select
                  disableOptionCentering
                  name="type"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="durationsTypeday"
                  class="form-control"
                >
                  <mat-option [value]="'morning'">Morning</mat-option>
                  <mat-option [value]="'evening'">Evening</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-3" *ngIf="this.durations === 'SpecificTime'">
              <mat-label>From</mat-label>
              <input
                (ngModelChange)="calcDurationStart($event)"
                [ngModelOptions]="{ standalone: true }"
                name="from"
                matinput
                [ngxTimepicker]="picker5"
                id="from"
                [(ngModel)]="startTime"
                [format]="24"
                class="form-control"
              />
              <ngx-material-timepicker #picker5></ngx-material-timepicker>
              <mat-error *ngIf="checkTime">From time should be before to time</mat-error>
            </div>
            <div class="col-md-3" *ngIf="this.durations === 'SpecificTime'">
              <mat-label>to</mat-label>
              <input
                (ngModelChange)="calcDurationEnd($event)"
                [ngModelOptions]="{ standalone: true }"
                name="to"
                matInput
                [ngxTimepicker]="picker6"
                id="to"
                [(ngModel)]="endTime"
                [format]="24"
                class="form-control"
                [min]="this.startTime"
              />
              <ngx-material-timepicker #picker6></ngx-material-timepicker>
              <mat-error *ngIf="checkTime">To time should be after from time</mat-error>
            </div>
            <div class="col-md-3" *ngIf="this.durations === 'SpecificTime'">
              <mat-label>Duration</mat-label>
              <mat-form-field class="form-element">
                <input
                  [readonly]="true"
                  [ngModelOptions]="{ standalone: true }"
                  name="duration"
                  [(ngModel)]="this.duration"
                  matInput
                />
              </mat-form-field>
            </div>
          </div>
          <div
            class="row form-group"
            *ngIf="
              assignForm.value.startdate &&
              assignForm.value.enddate &&
              this.partialDays === 'en' &&
              (assignForm.value.startdate | date : 'dd/MM/yyyy') != (assignForm.value.enddate | date : 'dd/MM/yyyy')
            "
          >
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>End Day</mat-label>
                <mat-select
                  disableOptionCentering
                  name="duration3"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="durations"
                  class="form-control"
                >
                  <mat-option [value]="'halfDay'">Half Day</mat-option>
                  <mat-option [value]="'SpecificTime'">Specific Time</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4" *ngIf="this.durations === 'halfDay'">
              <mat-form-field>
                <mat-label></mat-label>
                <mat-select
                  disableOptionCentering
                  name="type"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="durationsTypeday"
                  class="form-control"
                >
                  <mat-option [value]="'morning'">Morning</mat-option>
                  <mat-option [value]="'evening'">Evening</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-3" *ngIf="this.durations === 'SpecificTime'">
              <mat-label>From</mat-label>
              <input
                (ngModelChange)="calcDurationStart($event)"
                [ngModelOptions]="{ standalone: true }"
                name="from"
                matinput
                [ngxTimepicker]="picker7"
                id="from"
                [(ngModel)]="startTime"
                [format]="24"
                class="form-control"
              />
              <ngx-material-timepicker #picker7></ngx-material-timepicker>
              <mat-error *ngIf="checkTime">From time should be before to time</mat-error>
            </div>
            <div class="col-md-3" *ngIf="this.durations === 'SpecificTime'">
              <mat-label>to</mat-label>
              <input
                (ngModelChange)="calcDurationEnd($event)"
                [ngModelOptions]="{ standalone: true }"
                name="to"
                matInput
                [ngxTimepicker]="picker8"
                id="to"
                [(ngModel)]="endTime"
                [format]="24"
                class="form-control"
                [min]="this.startTime"
              />
              <ngx-material-timepicker #picker8></ngx-material-timepicker>
              <mat-error *ngIf="checkTime">To time should be after from time</mat-error>
            </div>
            <div class="col-md-3" *ngIf="this.durations === 'SpecificTime'">
              <mat-label>Duration</mat-label>
              <mat-form-field class="form-element">
                <input
                  [readonly]="true"
                  [ngModelOptions]="{ standalone: true }"
                  name="duration"
                  [(ngModel)]="this.duration"
                  matInput
                />
              </mat-form-field>
            </div>
          </div>
          <div
            class="row form-group"
            *ngIf="
              assignForm.value.startdate &&
              assignForm.value.enddate &&
              this.partialDays === 'sten' &&
              (assignForm.value.startdate | date : 'dd/MM/yyyy') != (assignForm.value.enddate | date : 'dd/MM/yyyy')
            "
          >
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>Start Day</mat-label>
                <mat-select
                  disableOptionCentering
                  name="duration1"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="durations"
                  class="form-control"
                >
                  <mat-option [value]="'halfDay'">Half Day</mat-option>
                  <mat-option [value]="'SpecificTime'">Specific Time</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4" *ngIf="this.durations === 'halfDay'">
              <mat-form-field>
                <mat-label></mat-label>
                <mat-select
                  disableOptionCentering
                  name="type"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="durationsTypeday"
                  class="form-control"
                >
                  <mat-option [value]="'morning'">Morning</mat-option>
                  <mat-option [value]="'evening'">Evening</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-3" *ngIf="this.durations === 'SpecificTime'">
              <mat-label>From</mat-label>
              <input
                (ngModelChange)="calcDurationStart($event)"
                [ngModelOptions]="{ standalone: true }"
                name="from"
                matinput
                [ngxTimepicker]="picker9"
                id="from"
                [(ngModel)]="startTime"
                [format]="24"
                class="form-control"
              />
              <ngx-material-timepicker #picker9></ngx-material-timepicker>
              <mat-error *ngIf="checkTime">From time should be before to time</mat-error>
            </div>

            <div class="col-md-3" *ngIf="this.durations === 'SpecificTime'">
              <mat-label>to</mat-label>
              <input
                (ngModelChange)="calcDurationEnd($event)"
                [ngModelOptions]="{ standalone: true }"
                name="to"
                matInput
                [ngxTimepicker]="picker10"
                id="to"
                [(ngModel)]="endTime"
                [format]="24"
                class="form-control"
                [min]="this.startTime"
              />
              <ngx-material-timepicker #picker10></ngx-material-timepicker>
              <mat-error *ngIf="checkTime">To time should be after from time</mat-error>
            </div>
            <div class="col-md-3" *ngIf="this.durations === 'SpecificTime'">
              <mat-label>Duration</mat-label>
              <mat-form-field class="form-element">
                <input
                  [readonly]="true"
                  [ngModelOptions]="{ standalone: true }"
                  name="duration"
                  [(ngModel)]="this.duration"
                  matInput
                />
              </mat-form-field>
            </div>
          </div>

          <div
            class="row form-group"
            *ngIf="
              assignForm.value.startdate &&
              assignForm.value.enddate &&
              this.partialDays === 'sten' &&
              (assignForm.value.startdate | date : 'dd/MM/yyyy') != (assignForm.value.enddate | date : 'dd/MM/yyyy')
            "
          >
            <div class="col-md-3">
              <mat-form-field>
                <mat-label>End Day</mat-label>
                <mat-select
                  disableOptionCentering
                  name="duration1"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="durations2"
                  class="form-control"
                >
                  <mat-option [value]="'halfDay'">Half Day</mat-option>
                  <mat-option [value]="'SpecificTime'">Specific Time</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4" *ngIf="this.durations2 === 'halfDay'">
              <mat-form-field>
                <mat-label></mat-label>
                <mat-select
                  disableOptionCentering
                  name="type"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="durationsTypeday2"
                  class="form-control"
                >
                  <mat-option [value]="'morning'">Morning</mat-option>
                  <mat-option [value]="'evening'">Evening</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-3" *ngIf="this.durations2 === 'SpecificTime'">
              <mat-label>From</mat-label>
              <input
                (ngModelChange)="calcDurationStart2($event)"
                [ngModelOptions]="{ standalone: true }"
                name="from"
                matinput
                [ngxTimepicker]="picker9"
                id="from"
                [(ngModel)]="startTime2"
                [format]="24"
                class="form-control"
              />
              <ngx-material-timepicker #picker9></ngx-material-timepicker>
              <mat-error *ngIf="checkTime2">From time should be before to time</mat-error>
            </div>

            <div class="col-md-3" *ngIf="this.durations2 === 'SpecificTime'">
              <mat-label>to</mat-label>
              <input
                (ngModelChange)="calcDurationEnd2($event)"
                [ngModelOptions]="{ standalone: true }"
                name="to"
                matInput
                [ngxTimepicker]="picker10"
                id="to"
                [(ngModel)]="endTime2"
                [format]="24"
                class="form-control"
                [min]="this.startTime2"
              />
              <ngx-material-timepicker #picker10></ngx-material-timepicker>
              <mat-error *ngIf="checkTime2">To time should be after from time</mat-error>
            </div>

            <div class="col-md-3" *ngIf="this.durations === 'SpecificTime'">
              <mat-label>Duration</mat-label>
              <mat-form-field class="form-element">
                <input
                  [readonly]="true"
                  [ngModelOptions]="{ standalone: true }"
                  name="duration"
                  [(ngModel)]="this.duration2"
                  matInput
                />
              </mat-form-field>
            </div>
          </div>

          <div class="row form-group">
            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Leave a comment</mat-label>
                <input
                  [ngModelOptions]="{ standalone: true }"
                  name="comment"
                  matInput
                  [(ngModel)]="leavecomments"
                  class="form-control"
                />
              </mat-form-field>
            </div>
          </div>

          <hr />

          <div class="row form-group">
            <div class="col-md-4">
              <h4>Employee Selection</h4>
            </div>
          </div>

          <div class="row form-group">
            <div class="col-md-4">
              <mat-form-field>
                <mat-label> Location</mat-label>
                <mat-select
                  (ngModelChange)="getSubUnitForLocation($event); getEmployeesCountForLocation($event)"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="locationId"
                  disableOptionCentering
                  name="currentLocationList"
                  class="form-control"
                >
                  <mat-option *ngFor="let t of locationsList" [value]="t.id">{{ t.location }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-4">
              <ng-container>
                <mat-form-field>
                  <mat-label> Subunit </mat-label>
                  <mat-select
                    (ngModelChange)="getEmployeesCountForSubUnit($event)"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="subUnit"
                    disableOptionCentering
                    name="subunitId"
                    class="form-control"
                  >
                    <mat-option *ngFor="let n of subUnitList" [value]="n.id">{{ n.name }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>
            </div>
            <div class="col-md-4 form-check">
              <section>
                <span> Matches {{ this.count }} employee(s)</span>
              </section>
            </div>
          </div>

          <!-- <div class="row form-group">
            <div class="col-md-4">
              <app-lazy-employee-dropdown
                title="Additional Employees to Include"
                required="false"
                multiTokens="true"
                [selectedEmployees]="selectedEmployees"
                (employeesSelected)="updateSelectedEmployees($event)"
              ></app-lazy-employee-dropdown>
            </div>
          </div> -->
        </form>

        <div class="row form-group" [hidden]="!write">
          <div class="col-md-12 text-right">
            <button
              mat-raised-button
              class="btn-primary"
              (click)="submitLeave()"
              color="primary"
              [disabled]="
                this.leavetypeid === '' ||
                this.leavetypeid === null ||
                assignForm.value.startdate === '' ||
                assignForm.value.startdate === null ||
                assignForm.value.enddate === '' ||
                assignForm.value.enddate === null
              "
            >
              Assign
            </button>
          </div>
        </div>
      </mat-card-content>
    </div>
  </div>
</app-card>

<app-card *ngIf="this.success">
  <div class="dt-responsive">
    <div class="table-responsive">
      <mat-card-content>
        <mat-table [dataSource]="overSource">
          <ng-container matColumnDef="employeeName">
            <mat-header-cell *matHeaderCellDef>Employee Name </mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{ row.employeeName }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="result">
            <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{ row.result }}
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="balanceCloumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: balanceCloumns"> </mat-row>
        </mat-table>
        <mat-progress-bar *ngIf="assignLoading" mode="indeterminate"></mat-progress-bar>
      </mat-card-content>
    </div>
  </div>
</app-card>
<!-- <div class="card min-h-600" *ngIf="employeeList && employeeList.length > 0 && !employeeListErr">
   
   <div class="card-content table-responsive table-full-width">
      <form class="form-material full-width-inputs" action="javascript:" [formGroup]="leaveForm" >

        <div class="row form-group ">
            <div class="col-md-4">
               <mat-form-field>
                 <mat-label> Leave Type</mat-label>
                  <mat-select (change)="getEmployeeByLeaveType()" class="form-control" formControlName="leavetypeid">
                     <mat-option *ngFor="let t of leaveTypeList" value="{{t.id}}">{{t.leaveType}}</mat-option>
                 </mat-select>
                 </mat-form-field>
             </div>
         </div>


         <div class="row form-group ">
            <div class="col-md-4">
               <mat-form-field class="form-element">
                 <mat-label>Start Date</mat-label>
                 <input [matDatepicker]="picker1" class="form-control"
                      formControlName="startdate" id="dob" matInput>
                 <mat-datepicker-toggle [for]="picker1"matSuffix></mat-datepicker-toggle>
                 <mat-datepicker #picker1></mat-datepicker>
               </mat-form-field>
           </div>
   
           <div class="col-md-4">
             <mat-form-field class="form-element">
               <input   id="dob" class="form-control" [matDatepicker]="picker2" [attr.min]="leaveForm.value.startdate && leaveForm.value.startdate"
               [min]="leaveForm.value.startdate && leaveForm.value.startdate" 
               placeholder="End Date" (dateChange)="dateDiff()" formControlName="enddate"
                    matInput>
               <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
               <mat-datepicker #picker2></mat-datepicker>
               <mat-label for="dob">End Date</mat-label>
             </mat-form-field>
         </div>
            </div>

         <div class="row form-group ">
            <div class="col-md-4" *ngIf="leaveForm.value.startdate && leaveForm.value.enddate">
            <mat-form-field>
               <mat-label>Partial Days</mat-label>
               <mat-select formControlName="partialdays" class="form-control">
                  <mat-option [value]="'None'">None</mat-option>
                  <mat-option [value]="'Everyday'">All Day</mat-option>
                  <mat-option [value]="'Startday'">Start Day</mat-option>
                  <mat-option [value]="'Endday'">End Day</mat-option>
                  <mat-option [value]="'StartandEndday'">Start and End Day</mat-option>
               </mat-select>
               </mat-form-field>
            </div>
         </div>
         <ng-container *ngIf="leaveForm.value.startdate && leaveForm.value.enddate">
            <div formArrayName="employeeLeaveLists" *ngFor="let item of leaveForm.get('employeeLeaveLists')['controls']; let i = index;">
               <ng-container [formGroupName]="i">
                  <div class="row form-group ">
                     
                     <ng-container *ngIf="leaveForm.value.partialdays && leaveForm.value.partialdays !== 'None' && ((leaveForm.value.partialdays === 'Startday' && i === 0) || (leaveForm.value.partialdays === 'Everyday' && i === 0) ||  (leaveForm.value.partialdays === 'Endday' && i === leaveForm.value.employeeLeaveLists.length - 1) || (leaveForm.value.partialdays === 'StartandEndday' && (i === 0 || i === leaveForm.value.employeeLeaveLists.length - 1)))">
                        
                        <div class="col-md-4">
                           <mat-form-field>
                              <mat-label>Duration</mat-label>
                           <mat-select formControlName="durations" class="form-control">
                              <mat-option [value]="'Halfday'">Half Day</mat-option>
                              <mat-option [value]="'SpecificTime'">Specific Time</mat-option>
                           </mat-select>
                           </mat-form-field>
                        </div>
                     
                        <ng-container *ngIf="item.value.durations === 'Halfday'">
                           <div class="col-md-4">
                              <mat-form-field>
                                 <mat-label></mat-label>
                              <mat-select class="form-control" formControlName="durationsTypeday" class="form-control">
                                 <mat-option [value]="'Morning'">Morning</mat-option>
                                 <mat-option [value]="'Evening'">Afternoon</mat-option>
                              </mat-select>
                          </mat-form-field>
                           </div>
                        </ng-container>

                        <ng-container *ngIf="item.value.durations === 'SpecificTime'" formGroupName="durationsTypeTime">
                           <div class="col-md-4">
                              <mat-form-field>
                                 <mat-label>From</mat-label>
                              <input matInput [ngxTimepicker]="picker" id="from"  class="form-control" formControlName="fromtime">
                              <ngx-material-timepicker #picker></ngx-material-timepicker>
                              </mat-form-field>
                           
                           </div>
                           <div class="col-md-4">
                              <mat-form-field>
                                 <mat-label>To</mat-label>
                              <input matInput [ngxTimepicker]="picker1" id="to"  formControlName="endtime" class="form-control" (change)="timeDiff(i)">
                              <ngx-material-timepicker #picker1></ngx-material-timepicker>
                              </mat-form-field>
                           
                           </div>
                        </ng-container>
                     </ng-container>
                  </div>
               </ng-container>
            </div>
         </ng-container>
         <ng-container *ngIf="leaveForm.value.leavetypeid">
            <div formArrayName="leavecomments" *ngFor="let item of leaveForm.get('leavecomments')['controls']; let i = index;">
               <ng-container [formGroupName]="i">
                  <div class="row form-group">
                     
                     <div class="col-md-4">
                        <mat-form-field>
                           <mat-label>Leave a comment</mat-label>
                        <textarea  matInput formControlName="commnet" class="form-control">
                        </textarea>
                        </mat-form-field>
                     <span class="form-bar"></span>
                     </div>

                  </div>
               </ng-container>
            </div>
         </ng-container>


         <div class="row form-group ">
            <div class="col-md-4">
               <mat-form-field>
                  <mat-label> Location</mat-label>
                  <mat-select [(ngModel)]="currentLocationList" name="currentLocationList" class="form-control">
                        <mat-option *ngFor="let n of locationList" [value]="n.label">{{n.label}}</mat-option>
                     </mat-select>  
                     </mat-form-field>
            </div>

           
            <div class="col-md-4">
               <ng-container>
                  <mat-form-field>
                  <mat-label> Subunit </mat-label>
                  <mat-select [(ngModel)]="subunitId" name="subunitId" class="form-control">
                     <mat-option *ngFor="let n of subUnitList" [value]="n.id">{{n.name}}</mat-option>
                   </mat-select>
               </mat-form-field>
               </ng-container>
            </div>


         
         </div>
         
         <div class="row form-group ">
         <div class="col-md-4">
         
            <mat-form-field>
               <mat-label> Employee</mat-label>
               <mat-select [(ngModel)]="employeeSelected" name="employeeSelected" class="form-control">
               <mat-option *ngFor="let t of employeeList" [value]="t.label">
                  {{t.label}}
               </mat-option>
            </mat-select>
          </mat-form-field>
                  
         <ng-container>
               <label class="float-label select-label label-after"> Employee</label>
               <ng-select *ngIf="employeeList" [ngClass]="'ng-select mt-10'" [options]="employeeList" formControlName="employeeIds" [multiple]="true"></ng-select>
            </ng-container>-->
<!-- </div>

         <div class="flex-checkbox col-md-4">
            
         {{ matchedEmployees.length }} match found
            
       </div>
      </div>


         <div class="row form-group ">
            <div class="col-md-12 text-right">
               <button mat-raised-button class="btn-primary" (click)="submitLeave()" color="primary" [disabled]="!leaveForm.valid"><i class="flaticon-diskette"></i>Save</button>
            </div>
         </div>
      </form>
   </div>
</div> -->
