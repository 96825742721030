import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { NotificationService } from "src/app/core/services/notification.service";
import { DashboardService } from "src/app/dashboard/dashboard.service";
import { MatTableDataSource } from "@angular/material/table";

import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import * as moment from "moment";
import { UsersService } from "../../../user.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/core/services/auth.service";

@Component({
  selector: "app-personal",
  templateUrl: "./personal-component.html",
  styleUrls: ["./personal-component.scss"],
})
export class PersonalComponent implements OnInit {
  @Input() employeeId: string;
  public personalForm: FormGroup;
  public nationalityList: Array<any> = [];
  public imageUploading = false;
  public comment: string;
  public editAttachmentId: string;
  public fileType = "";
  public fileObj: any;
  public fileUrl: string;
  public fileName = "";
  public dialogRef: any;
  public employeeImage = "";
  public uploadedFile: any;
  public attachmentList: MatTableDataSource<any> = new MatTableDataSource([]);
  public deleteAttachmentId: string;
  public fileClean: string;
  public isLoadingattach = true;
  public isLoading = true;
  public showHealth: boolean = true;
  public showPrefrence: boolean = true;
  public sectionsList: Array<any> = [];
  public tempSectionsList: Array<any> = [];
  public saveCutomList: Array<any> = [];
  public saveCBCutomList: Array<any> = [];
  public dataForm = {};

  public id: any;

  public requiredPersonal: any = {
    dateOfBirthAvailable: true,
    dateOfBirthRequired: false,
    driverLicenseNumberAvailable: true,
    driverLicenseNumberRequired: false,
    genderAvailable: true,
    genderRequired: false,
    licenseExpiryDateAvailable: true,
    licenseExpiryDateRequired: false,
    martialStatusAvailable: true,
    martialStatusRequired: false,
    middleNameAvailable: true,
    middleNameRequired: false,
    militaryServiceAvailable: true,
    militaryServiceRequired: false,
    nickNameAvailable: true,
    nickNameRequired: false,
    selected: true,
    smokerAvailable: true,
    smokerRequired: false,
  };
  public isLoadingRequired: boolean = true;
  @ViewChild("attach", { static: false }) attach;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("editAttach", { static: false }) editAttach;
  @ViewChild("deleteAttachment", { static: false }) deleteAttachment;
  public activityList = [
    { value: "arts", label: "Arts" },
    { value: "dancing", label: "Dancing" },
    { value: "music", label: "Music" },
    { value: "arts", label: "Arts" },
  ];
  public sportsList = [
    { value: "basketball", label: "Basketball" },
    { value: "baseball", label: "Baseball" },
    { value: "football", label: "Football" },
    { value: "golf", label: "Golf" },
    { value: "handball", label: "Handball" },
    { value: "rugger", label: "Rugger" },
    { value: "squash", label: "Squash" },
  ];
  public columns = ["fileName", "url", "desc", "size", "added", "edit", "delete"];
  filteredOptions: any[];
  correct: boolean;

  constructor(
    private authService: AuthService,
    private routeA: ActivatedRoute,
    private route: Router,
    private service: DashboardService,
    private fb: FormBuilder,
    private toastr: NotificationService,
    private dialog: MatDialog,
    private userService: UsersService
  ) {
    this.createForm();

    this.routeA.params.subscribe((params) => {
      this.id = params["id"];
    });
  }

  ngOnDestroy() {
    this.attachmentList = null;
    this.dialog = null;
    this.dialogRef = null;
    this.authService = null;
    this.route = null;
    this.routeA = null;
    this.service = null;
    this.fb = null;
    this.userService = null;
    this.toastr = null;
    this.attach = null;
    this.sort = null;
    this.paginator = null;
    this.editAttach = null;
    this.deleteAttachment = null;
    this.personalForm = null;
    this.dataForm = null;
  }

  onTypeChange(section, custom, event) {
    for (let sec of this.sectionsList) {
      if (section.id === sec.id) {
        for (let cus of sec.customFields) {
          if (cus.id === custom.id) {
            custom.value = event.target.value;
          }
        }
      }
    }

    custom.value = event;
  }

  checkRequiredFieldsInCustomFields(sectionID, customFields) {
    for (let section of this.sectionsList) {
      if (section.id === sectionID) {
        for (let custom of customFields) {
          if (custom.required) {
            if (custom.value === "" || custom.value === null || custom.value === undefined) {
              section.save = true;
              return;
            }
          }
        }

        section.save = false;
      }
    }
  }

  updateCustomField(sectionId, customFields): void {
    this.saveCutomList = [];
    this.saveCBCutomList = [];
    for (let custom of customFields) {
      if (custom.required) {
        if (custom.value === "") {
          this.toastr.error("Please fill the required fields");
          return;
        }
      }
      var obj = {
        fieldId: "",
        fieldValue: "",
      };
      if (custom.type != "cb") {
        let id: string = custom.id;
        let value: string = custom.value;
        if (custom.type == "dt") {
          value = moment(custom.value).format("YYYY-MM-DD").toString();
        }

        obj.fieldId = id;
        obj.fieldValue = value;
        this.saveCutomList.push(obj);
      }

      if (custom.type == "cb") {
        var obj2 = {
          fieldId: "",
          values: [],
        };
        obj2.fieldId = custom.id;
        let values: Array<any> = [];
        obj2.values = values;
        for (let c of custom.values) {
          if (c.booleanValue) {
            obj2.values.push(c.option);
          }
        }
        this.saveCBCutomList.push(obj2);
      }
    }

    this.service.saveCustomFieldsInEditEmployee(sectionId, this.employeeId, this.saveCutomList).subscribe(
      (res) => {
        if (res) {
          this.service
            .saveCustomFieldsCheckBoxesInEditEmployee(sectionId, this.employeeId, this.saveCBCutomList)
            .subscribe(
              (res) => {
                if (res) {
                  this.toastr.success("Updated Successfully!..");
                }
              },
              (error) => {
                this.toastr.error("Error in  updating");
              }
            );
        }
      },
      (error) => {}
    );
  }

  getRequiredFileds(): void {
    this.isLoadingRequired = true;
    this.service.getRequiredFieldsBySection("pe").subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.requiredPersonal = resp.data;
          this.isLoadingRequired = false;
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.toastr.error(err || "No Internt Connection");
      }
    );
  }

  public permissions: any;
  public read = false;
  public write = false;
  public update = false;
  public delete = false;

  checkPermission(module, tabs): void {
    this.permissions = JSON.parse(this.authService.getPermissions());

    this.read = false;
    this.write = false;
    this.delete = false;
    this.update = false;

    if (this.permissions != undefined && this.permissions != null) {
      for (let p of this.permissions) {
        if (p.id === module) {
          if (p.modules != undefined) {
            for (let tab of p.modules) {
              if (tab.id === tabs) {
                for (let move of tab.permits) {
                  if (move.id === "u") {
                    this.update = true;
                  } else if (move.id === "r") {
                    this.read = true;
                  } else if (move.id === "d") {
                    this.delete = true;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ngOnInit(): void {
    this.checkPermission("pdm", "pd_em");
    this.getRequiredFileds();
    this.service.getCustomFieldsForEmployee("pe", this.employeeId).subscribe((res) => {
      if (res && res.data) {
        this.sectionsList = res.data;
      }
    });

    this.service.getOptionalFields().subscribe((res) => {
      if (res) {
        this.showHealth = res.data.health;
        this.showPrefrence = res.data.preference;
      }
    });

    this.service.getNationalityListDropDwon().subscribe((resp) => {
      if (resp && resp.data) {
        this.nationalityList = resp.data;
        this.filteredOptions = this.nationalityList;
      }
    });

    this.service.getEmployePersonalInfo(this.employeeId).subscribe((resp) => {
      this.isLoading = true;
      this.dataForm = { ...resp.data, smoking: resp?.data?.smoker ? "yes" : "no" };
      this.personalForm.patchValue(this.dataForm);
      this.isLoading = false;
    });

    this.service.getNewAttachmentList(this.employeeId, "pp").subscribe((resp) => {
      if (resp && resp.data) {
        this.isLoadingattach = true;
        this.attachmentList = new MatTableDataSource<any>(resp.data);
        this.attachmentList.data = resp.data;
        this.attachmentList.paginator = this.paginator;
        this.attachmentList.sort = this.sort;

        this.isLoadingattach = false;
      }
    });
    this.initValuesDetection();
  }

  initValuesDetection() {
    this.filteredOptions = this.nationalityList;

    this.personalForm.controls["nationality"].valueChanges.subscribe((change) => {
      if (change == null || change == undefined) {
        this.correct = true;
      } else {
        this.filterNationality(change);
      }
    });
  }

  checkNationalityChange(change) {
    const x = this.filteredOptions.find((x) => x.id === change);
    if (!x) {
      this.correct = false;
    }
  }

  public displayNationlaityByID(option) {
    if (option) {
      this.correct = true;
      return this.nationalityList.find((x) => x.id === option).name;
    }
  }

  createForm() {
    this.personalForm = this.fb.group({
      firstname: [""],
      medicalIssues: [""],
      lastname: [""],
      middlename: [""],
      empid: [""],
      dob: [""],
      email: [""],
      username: [""],
      smoker: [],
      licenseexpirydate: [""],
      maritalstatus: [""],
      militaryservice: [""],
      smoking: [""],
      nationality: [""],
      otherid: [""],
      drivinglicenseno: [""],
      gender: [""],
      password: [""],
      status: ["ACTIVE"],
      createlogindetail: [false],
      nickname: [""],
      disability: [""],
      roles: this.fb.array([
        this.fb.group({
          roleType: ["ESS"],
          userRole: [""],
        }),
        this.fb.group({
          roleType: ["Admin"],
          userRole: [""],
        }),
      ]),

      image: [""],
      bloodgroup: [""],
      activities: [""],
      sports: [""],
      food: [""],
      hobbies: [""],
    });
  }

  deleteAttachmentSelected(id): void {
    this.deleteAttachmentId = id;
    this.dialogRef = this.dialog.open(this.deleteAttachment, {
      width: "440px",
      height: "auto",
    });
  }

  updatePersonalDetail() {
    this.personalForm.value.smoker = this.personalForm?.value?.smoking === "yes";
    delete this.personalForm?.value?.smoking;
    this.service.updateEmployeePersonalDetail(this.employeeId, this.personalForm.value).subscribe(
      (res) => {
        this.toastr.success("Updated Successfully!..");
        if (res) {
          let dataUser =
            this.personalForm.value.firstname +
            " " +
            this.personalForm.value.middlename +
            " " +
            this.personalForm.value.lastname;

          this.userService.editUser(dataUser);
          const currentUser = JSON.parse(sessionStorage.getItem("empId"));
          if (Number(currentUser) === Number(this.id)) {
            this.userService.editUserSaid(dataUser);
          }
        }
      },
      (error) => {
        this.toastr.error("Error in  updating");
      }
    );
  }

  getPersonalInformationAttachmentList() {
    this.service.getNewAttachmentList(this.employeeId, "pp").subscribe((resp) => {
      if (resp && resp.data) {
        this.attachmentList = new MatTableDataSource<any>(resp.data);
        this.attachmentList.data = resp.data;
        this.attachmentList.paginator = this.paginator;
        this.attachmentList.sort = this.sort;
      }
    });
  }
  public profileChange($event) {
    const reader = new FileReader();
    const file = $event.target.files[0];
    const profileFile = file;
    const fileName = file && file.name;
    if (file && file.size / 1024 < 2048) {
      this.imageUploading = true;
      this.service.uploadFile(profileFile).subscribe(
        (resp) => {
          if (resp && resp.data) {
            const val = this.personalForm.value;
            val["image"] = resp.data.filename;
            val["fullPathImage"] = resp.data.fullPathFilename;
            this.personalForm.patchValue(val);
          }
          this.imageUploading = false;
        },
        (err) => {
          this.imageUploading = false;
        }
      );
    } else {
      this.toastr.error("Max 2Mb Image allowed.");
    }
  }
  public addAttachment(): void {
    this.editAttachmentId = "";
    this.comment = "";
    this.fileType = "";
    this.fileObj = {};
    this.fileUrl = "";
    this.fileName = "";
    this.dialogRef = this.dialog.open(this.attach, {
      width: "440px",
      height: "auto",
    });
  }
  public open(url): void {
    window.open(url);
  }
  public editattach(row): void {
    this.editAttachmentId = row.id;
    this.fileName = row.fileName;
    this.comment = row.desc;
    this.dialogRef = this.dialog.open(this.editAttach, {
      width: "440px",
      height: "auto",
    });
  }

  public isDone = true;

  public saveAttachment(): void {
    // this is for the save attachment
    this.isDone = false;
    this.service.uploadFile(this.uploadedFile).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.fileObj["url"] = resp.data.filename;
          this.fileObj["desc"] = this.comment;
          this.fileObj.section = "pp";
          this.fileObj.fileName = this.fileName;
          if (this.editAttachmentId) {
            // this.service.editAttachment(this.fileObj, this.editAttachmentId).subscribe(resp1 => {
            //     this.modalRef.close();
            //     this.service.getAttachmentList(this.editId).subscribe(resp2 => {
            //         if (resp2 && resp2.data) {
            //             this.attachmentList = resp2.data;
            //         }
            //     });
            // });
          } else {
            this.service.newAddAttachment(this.fileObj).subscribe((resp1) => {
              // this.attachmentList.filteredData.push(resp1.data);

              this.getPersonalInformationAttachmentList();

              this.isDone = true;
              this.toastr.success("file added successfully!");
              this.dialogRef.close();
            });
          }
        }
      },
      (error) => {
        this.isDone = true;
        const err = error.error && error.error.msg;
        this.toastr.error("error in adding file!");
      }
    );
  }

  public fileChange($event, type): void {
    this.uploadedFile = undefined;
    const reader = new FileReader();
    const file = $event.target.files[0];
    this.uploadedFile = file;
    const fileName = file && file.name;
    if (file && file.size / 1024 < 2048) {
      this.fileObj = {
        added: new Date(),
        addedBy: "string",
        desc: this.comment,
        docType: type,
        empId: this.employeeId,
        fileName: fileName,
        size: file.size,
        section: "",
      };
    } else {
      this.toastr.error("Max 2Mb File allowed.");
    }
  }

  public deleteAttachmentFrom(): void {
    this.service.deleteAttachment(this.deleteAttachmentId).subscribe(
      (resp) => {
        if (resp) {
          this.getPersonalInformationAttachmentList();

          this.dialogRef.close();
          this.toastr.success("Attachment Deleted Successfully...");
        }
      },
      (error) => {
        this.toastr.error("Error in deleting the Attachment");
      }
    );
  }

  public updateAttachment() {
    this.service.updateNewAttachmentList(this.comment, this.fileName, this.editAttachmentId).subscribe(
      (res) => {
        if (res) {
          this.getPersonalInformationAttachmentList();
          this.toastr.success("Attachment updated Successfully.");
          this.dialogRef.close();
        }
      },
      (error) => {
        this.toastr.error("Error in updating attachment");
      }
    );
  }

  protected filterNationality(change: String) {
    this.filteredOptions = this.nationalityList;
    if (!this.nationalityList) {
      return;
    }

    // get the search keyword
    if (!change) {
      this.correct = true;
      this.filteredOptions = this.nationalityList;
      return;
    } else {
      this.checkNationalityChange(change);
      change = change.toLowerCase();
    }
    // filter the search
    this.filteredOptions = this.nationalityList.filter((co) => co.name.toLowerCase().indexOf(change) > -1);
  }
  changeStatus(): void {
    this.correct = true;
  }
}
