<section class="login-block">
  <div class="container">
    <div class="row">
      <div class="col-md-12" style="display: flex; justify-content: center">
        <img id="login-header" class="img-fluid" src="assets/team.png" />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <form class="md-float-material form-material" action="javascript:" method="post" #loginform="ngForm">
          <div class="auth-box card">
            <div class="card-block">
              <div class="row">
                <div class="col-md-12">
                  <span class="err text-center">{{alertMessage}}</span>
                  <h3 *ngIf="!forgetFlag; else loggedOut" class="text-center b-title">Sign In</h3>
                  <ng-template #loggedOut>
                    <h3 class="text-center b-title">Forgot Password</h3>
                  </ng-template>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <mat-form-field class="example-full-width">
                    <mat-label>Your Email Address</mat-label>
                    <input matInput name="txtUsername" id="txtUsername" type="text" class="form-control"
                      [(ngModel)]="loginDetail.username" #name="ngModel" required email
                      [ngClass]="{ error: name.touched && !name.valid }" />
                    <!-- <span class="form-bar"></span> -->
                    <!-- <label class="float-label">Your Email Address</label> -->
                    <mat-error *ngIf="name.touched && !name.valid">Please provide a valid email</mat-error>
                  </mat-form-field>
                </div>
              </div>

              <ng-container *ngIf="!forgetFlag">
                <div class="row">
                  <div class="col-md-12">
                    <mat-form-field class="example-full-width">
                      <mat-label>Password</mat-label>
                      <input matInput name="txtPassword" id="txtPassword" [type]="passwordHide ? 'password' : 'text'"
                        class="form-control" [(ngModel)]="loginDetail.password" #pass="ngModel" required
                        [ngClass]="{ error: pass.touched && !pass.valid }" />
                      <mat-icon style="cursor: pointer;" matSuffix (click)="passwordHide = !passwordHide">{{
                        passwordHide ? "visibility_off" : "visibility"
                        }}</mat-icon>
                      <!-- <span class="form-bar"></span> -->
                      <!-- <label class="float-label">Password</label> -->
                      <mat-error *ngIf="pass.touched && !pass.valid">Required</mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </ng-container>

              <span class="err" *ngIf="loginError">{{ loginError }}</span>

              <div class="row m-t-15">
                <div class="col-md-12">
                  <div class="forgot-pass text-right f-right">
                    <!-- [routerLink]="['/auth/forgot']"  -->
                    <a *ngIf="!forgetFlag" class="text-right f-w-600 click-sort" (click)="changeFlag(true)">
                      Forgot Password?</a>
                  </div>
                </div>
              </div>
              <div class="row m-t-20">
                <div class="col-md-12" *ngIf="!forgetFlag">
                  <button type="submit" class="btn btn-primary btn-md btn-block waves-light text-center m-b-20 ripple"
                    (click)="login()" [disabled]="!loginDetail.username || !loginDetail.password || isLoading" #loginBtn
                    [class.spinner]="isLoading">
                    Sign In
                  </button>
                </div>

                <ng-container *ngIf="forgetFlag">
                  <div class="col-md-6">
                    <button type="button"
                      class="back-button btn btn-primary btn-md btn-block waves-light text-center m-b-20 ripple"
                      (click)="changeFlag(false)">
                      Back
                    </button>
                  </div>
                  <div class="col-md-6">
                    <button type="submit" class="btn btn-primary btn-md btn-block waves-light text-center m-b-20 ripple"
                      (click)="getEmailLink()" [disabled]="!loginDetail.username || isLoading"
                      [class.spinner]="isLoading">
                      Submit
                    </button>
                  </div>
                </ng-container>
              </div>
              <hr />
              <div class="row copyright-row">
                <div class="col-md-12">
                  <p class="text-inverse text-left m-b-0">Screen Hrm</p>
                  <p class="text-inverse text-left m-b-0">©2019 SCREEN HRM. All rights reserved.</p>
                </div>
                <div class="col-md-2">
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>