import {Component, NgZone, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-my-info',
  templateUrl: './my-info.component.html',
  styleUrls: ['./my-info.component.scss']
})
export class MyInfoComponent implements OnInit {

  public empId = "";
  constructor(private router: Router,
              private route: ActivatedRoute,
              private ngZone: NgZone) { }

  ngOnInit() {
    // here we need to get the id that is stored locally
    this.empId = sessionStorage.getItem('empId');
   
    //  = empId;
    // this.moveToMyInfo(empId);
  }
  public moveToMyInfo(id): void {
    this.ngZone.run(() => {
      void this.router.navigate([`/pim/employees/${id}`]);
    });
  }
}
