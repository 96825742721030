<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
    <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="page-header-title">
            <span class="ng-star-inserted"></span
            ><span class="ng-star-inserted"
              ><h4 class="m-b-10 ng-star-inserted">{{ title }}</h4></span
            >
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</div>
<!-- <app-loading [isLoading]="isLoadings"></app-loading> -->
<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<div class="page-body" [hidden]="this.isLoadings">
  <div class="row">
    <div class="col-sm-12">
      <div class="row top-tools">
        <div class="col-xs-12 col-sm-12 col-md-6 left-tools">
          <span class="export-icon-btn csv" (click)="exportToCsv()" *ngIf="myEntitlement"
            ><i class="material-icons" *ngIf="myEntitlement">get_app</i><span *ngIf="myEntitlement">CSV</span>
          </span>
          <span class="export-icon-btn pdf" (click)="saveAsPdf()" *ngIf="myEntitlement"
            ><i class="material-icons" *ngIf="myEntitlement">get_app</i><span *ngIf="myEntitlement">PDF</span>
          </span>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
          <div class="relative-position search-filter-con">
            <span class="mytooltip cus-left tooltip-effect-4" *ngIf="!myEntitlement"> </span>
            <span class="mytooltip cus-left tooltip-effect-4" *ngIf="myEntitlement">
              <span class="tooltip-item">
                <button
                  mat-mini-fab
                  class="btn btn-primary light add-rounded margin-button-05"
                  (click)="filterUserList()"
                >
                  <i class="fa fa-filter"></i>
                </button>
              </span>
              <span class="tooltip-content clearfix">
                <span class="tooltip-text">filter</span>
              </span>
            </span>
          </div>
        </div>
      </div>

      <br />

      <mat-expansion-panel #expansionPanel [expanded]="isExpanded" *ngIf="!this.myEntitlement">
        <mat-expansion-panel-header class="right-aligned-header">
          <mat-panel-title>
            <span class="accordion-head panel-title"> <b>Search </b> (Please specify your search) </span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <form
          class="form-material full-width-inputs"
          action="javascript:"
          *ngIf="!myEntitlement"
          [formGroup]="searchForm"
        >
          <div class="row form-group">
            <div class="col-md-6">
              <app-lazy-employee-dropdown
                title="Employee"
                required="true"
                [selectedEmployee]="selectedEmployee"
                (employeeSelected)="updateSelectedEmployee($event)"
              ></app-lazy-employee-dropdown>
            </div>

            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Leave Type</mat-label>
                <mat-select
                  formControlName="leavetypeid"
                  [ngModelOptions]="{ standalone: true }"
                  disableOptionCentering
                  class="form-control"
                  name="leavetypeid"
                  [(ngModel)]="leavetypeid"
                >
                  <mat-option value="">--Select--</mat-option>
                  <mat-option *ngFor="let l of leaveTypeList" [value]="l.id">{{ l.leaveType }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row form-group">
            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Leave Period ID</mat-label>

                <mat-select
                  (ngModelChange)="changeLeavePeriodValue($event)"
                  formControlName="leaveperiodid"
                  required
                  [ngModelOptions]="{ standalone: true }"
                  disableOptionCentering
                  class="form-control"
                  name="leaveperiodid"
                  [(ngModel)]="leaveperiodid"
                >
                  <mat-option *ngFor="let l of leavePeriodList" value="{{ l.id }}">{{
                    l.currentleaveperiod
                  }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </form>

        <div class="action-buttons multi-btns col-sm-12 text-right">
          <button
            [disabled]="!(leaveperiodid && selectedEmployee.id)"
            mat-raised-button
            class="btn btn-primary"
            (click)="search()"
            color="primary"
          >
            <i class="flaticon-magnifiying-glass"></i>Search
          </button>
        </div>
      </mat-expansion-panel>

      <div class="row top-tools">
        <div class="col-xs-12 col-sm-12 col-md-6 left-tools" *ngIf="entitlementList && !myEntitlement">
          <span class="export-icon-btn csv" (click)="exportToCsv()"
            ><i class="material-icons">get_app</i><span>CSV</span>
          </span>
          <span class="export-icon-btn pdf" (click)="saveAsPdf()"
            ><i class="material-icons">get_app</i><span>PDF</span>
          </span>
        </div>
      </div>

      <div class="dt-responsive" [hidden]="this.isUserAdded">
        <app-loading *ngIf="dataSource" [isLoading]="dataSource"></app-loading>
        <app-card [title]="">
          <div class="dt-responsive" *ngIf="dataSource">
            <div class="table-responsive">
              <mat-card-content>
                <mat-table [dataSource]="dataSource" matSort>
                  <ng-container matColumnDef="delete">
                    <mat-header-cell [hidden]="!delete" *matHeaderCellDef>
                      <a [matMenuTriggerFor]="menu" class="check-all">
                        <span class="material-icons"> more_horiz </span>
                      </a>

                      <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="onSelectAllClicked()">Select All</button>
                        <button (click)="onDeSelectAllClicked()" *ngIf="selectedCheckBoxList.length > 0" mat-menu-item>
                          Deselect All
                        </button>
                        <button (click)="onDeleteSelected()" *ngIf="selectedCheckBoxList.length > 0" mat-menu-item>
                          Delete Selected
                        </button>
                      </mat-menu>
                    </mat-header-cell>
                    <mat-cell [hidden]="!delete" *matCellDef="let row">
                      <mat-checkbox
                        #matCheckbox
                        (change)="onCheckBoxChange($event, row.entitlementNumber)"
                        value="{{ row.entitlementNumber }}"
                      ></mat-checkbox>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="employeeId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Employee Id</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      {{ row.employeeId }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="employeeName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Employee Name</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      {{ row.employeeName }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="leavetype">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Leave Type</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      {{ row.leavetype }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="location">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Location</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      {{ row.location }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="entitlementtype">
                    <mat-header-cell *matHeaderCellDef>Entitlement Type</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      {{ row.entitlementtype }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="credited_date">
                    <mat-header-cell *matHeaderCellDef>Credited On</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      {{ row.credited_date | date : "dd/MM/YYYY" }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="leaveperiod">
                    <mat-header-cell *matHeaderCellDef>Leave Period</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      {{ row.leaveperiod }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="expired">
                    <mat-header-cell *matHeaderCellDef>Expired</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      {{ row.expired }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="entitlementday">
                    <mat-header-cell *matHeaderCellDef>Entitlement Day</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      {{ row.total }}
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: columns"> </mat-row>
                </mat-table>
                <!-- <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar> -->
                <mat-paginator [pageSizeOptions]="[20, 50]" showFirstLastButtons></mat-paginator>
              </mat-card-content>

              <h3>Total: {{ this.totall }}</h3>
            </div>
            <div *ngIf="this.entitlementList.length === 0" class="error-msg">No records found</div>
          </div>
        </app-card>
      </div>
    </div>
  </div>

  <ng-template #filterUser let-modal>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">Filter My Entitlments</h4>
    </div>
    <div class="mat-modal-body">
      <form class="form-material full-width-inputs" action="javascript:" [formGroup]="filterUserForm">
        <div class="row form-group">
          <div class="col-sm-6">
            <mat-form-field class="form-element">
              <mat-label>Leave Type</mat-label>
              <mat-select class="form-control" formControlName="leavetypeId" [(ngModel)]="leavetypeid">
                <mat-option *ngFor="let l of leaveTypeList" [value]="l.id">{{ l.leaveType }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="action-buttons multi-btns col-sm-12 text-right">
          <button mat-raised-button class="btn-danger reset-btn" (click)="onResetForm()">
            <i class="flaticon-refresh"></i>Reset
          </button>
          <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i>Cancel</button>
          <button (click)="SearchFilterUser()" class="btn btn-primary" color="primary" mat-raised-button>
            <i class="flaticon-magnifiying-glass"></i>Search
          </button>
        </div>
      </form>
    </div>
  </ng-template>

  <ng-template #addUser let-modal>
    <div mat-dialog-actions>
      <div class="mat-modal-header">
        <h4 class="mat-title-modal">
          <span>Edit</span>
          Entitlment
        </h4>
      </div>
      <div class="mat-modal-body">
        <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>

        <form class="form-material full-width-inputs" action="javascript:" [formGroup]="userForm" *ngIf="!isUserAdded">
          <div class="row form-group">
            <div class="col-sm-6">
              <mat-form-field class="form-element">
                <mat-label>Employee</mat-label>
                <input
                  type="text"
                  matInput
                  class="form-control"
                  maxlength="30"
                  required=""
                  id="employeeName"
                  formControlName="employeeName"
                  name="footer-email"
                  readonly
                />
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field class="form-element">
                <mat-label>Leave Type</mat-label>
                <input
                  type="text"
                  matInput
                  class="form-control"
                  maxlength="30"
                  required=""
                  id="leavetypeName"
                  formControlName="leavetypeName"
                  name="footer-email"
                  readonly
                />
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field class="form-element">
                <mat-label>Leave Period</mat-label>
                <input
                  type="text"
                  matInput
                  class="form-control"
                  maxlength="30"
                  required=""
                  id="leaveperiodName"
                  formControlName="leaveperiodName"
                  name="footer-email"
                  readonly
                />
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field class="form-element">
                <mat-label>Entitlement Type</mat-label>
                <input
                  type="text"
                  matInput
                  class="form-control"
                  maxlength="30"
                  required=""
                  id="entitlementtype"
                  formControlName="entitlementtype"
                  name="footer-email"
                  readonly
                />
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field class="form-element">
                <mat-label>Credited Date</mat-label>
                <input
                  type="text"
                  matInput
                  class="form-control"
                  maxlength="30"
                  required=""
                  id="credited_date"
                  formControlName="credited_date"
                  name="footer-email"
                  readonly
                />
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field class="form-element">
                <mat-label>Entitlment Days</mat-label>
                <input
                  type="text"
                  matInput
                  class="form-control"
                  maxlength="30"
                  required=""
                  id="total"
                  formControlName="total"
                  name="footer-email"
                />
              </mat-form-field>
            </div>
            <div class="col-sm-12">
              <mat-form-field class="form-element">
                <mat-label>Comment</mat-label>
                <input
                  type="text"
                  matInput
                  class="form-control"
                  maxlength="30"
                  id="comment"
                  formControlName="comment"
                  name="footer-email"
                />
              </mat-form-field>
            </div>
          </div>
        </form>
        <div class="row my-2 mx-2">
          <div class="action-buttons multi-btns col-sm-12 text-right">
            <button mat-raised-button class="btn-danger" (click)="close()">
              <i class="flaticon-cancel"></i>Cancel
            </button>
            <button
              mat-raised-button
              class="btn-primary"
              color="primary"
              [disabled]="!userForm.valid"
              (click)="submitAddJobTitle()"
            >
              <i class="flaticon-diskette"></i>Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #deleteUser let-modal>
    <div mat-dialog-actions>
      <div class="mat-modal-header">
        <h4 class="mat-title-modal">Delete Entitlments</h4>
      </div>
      <div class="mat-modal-body">
        <div class="text-left are-u">Are you sure ?</div>
        <div class="row">
          <div class="multi-btns col-sm-12 text-right">
            <button mat-raised-button class="btn-danger" (click)="close()">
              <i class="flaticon-cancel"></i>Cancel
            </button>
            <button mat-raised-button class="btn-primary" (click)="deleteMultiple()" color="primary">
              <i class="flaticon-trash"></i>Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
