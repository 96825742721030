<!-- <div class="row">
  <div class="col-sm-12 text-center">
    <div class="loading text-center" *ngIf="isLoading">
      <i class="fa fa-spinner fa-spin"></i>
    </div>
  </div>
</div> -->

<div class="loading-data text-center" *ngIf="isLoading == true">
    <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
</div>