import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { DashboardService } from '../../../dashboard.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NotificationService } from "src/app/core/services/notification.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-manage-organization',
  templateUrl: './manage-organization.component.html',
  styleUrls: ['./manage-organization.component.scss']
})
export class ManageOrganizationComponent implements OnInit {

  public userList: Array<any>;
  public isLoading: boolean;
  public isUserAdded: boolean;
  public err: string;
  public modalRef: any;
  public userForm: FormGroup;
  public isEdit: boolean;
  public editId: string;
  public deleteId: string;
  public totalRecords: number;
  public activePage: number;
  public pageSize: number;

  public locationList: Array<any>;
  @ViewChild('addUser', {static: false}) addUser;
  @ViewChild('deleteUser', {static: false}) deleteUser;

  constructor(private service: DashboardService, private modalService: NgbModal, private fb: FormBuilder, private toastr: NotificationService, public router: Router, private ngZone: NgZone) {
    this.createForm();
  }

  public tableConfig = {
    name: true,
    organisationemail: true,
    locationid: true,
    region: true,
    status: true,
    costcenterenabled: true,
    eeoenabled: true,
    address: true
  };


  ngOnInit() {
    this.isLoading = true;
    this.isEdit = false;
    this.getJobTitleList();
    this.activePage = 1;
    this.pageSize = 10;
    this.totalRecords = 10;
    this.isUserAdded = false;
  }

  public createForm(): void {
    this.userForm = this.fb.group({
      description: [''],
      holiday: [''],
      holidaydate: [''],
      length: [''],
      locations: [

      ],
      operational_country_code: [''],
      recurring: [''],
      status: ['ACTIVE']
    });
  }

  public addNewUser() {
    this.router.navigate(['dashboard/admin/manage-organization']);
    this.ngZone.run(() => {
      void this.router.navigate(['dashboard/admin/manage-organization'], { queryParams: { action: 'add' } });
    });
  }

  public editUser(id): void {
    this.ngZone.run(() => {
      void this.router.navigate(['/dashboard/admin/organization'], { queryParams: { action: 'edit', id } });
    });
  }

  public close(): void {
    this.modalRef.close();
  }

  public getJobTitleList(): void {
    this.service.getOrganizationList().subscribe(user => {
      console.log('User');
      if (user && user.data) {
        this.isLoading = false;
        this.userList = user.data;
        console.log(this.userList);
        this.userList.map(u => {
           u.address = '';
           if (u.addressInfo) {
             for (let i = 0; i < u.addressInfo.length; i++) {
               if (u.address !== '') {
                 u.address = u.address + ',' + u.addressInfo[i].type;  // this fails since the
               } else {
                 u.address = u.addressInfo[i].type;
               }
             }
           }
        });
        this.err = '';
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error while getting Holidays');
      this.isLoading = false;
      this.err = 'Error while getting Holidays';
    });
  }

  public submitAddJobTitle(): void {
    if (this.isEdit) {
      this.edit();
    } else {
      this.add();
    }
  }

  public add(): void {
    this.isUserAdded = true;
    this.service.addHoliday(this.userForm.value).subscribe(user => {
      this.isUserAdded = false;
      this.toastr.success('Holiday added successfully');
      this.modalRef.close();
      this.userForm.reset();
      this.getJobTitleList();
    }, error => {
      const err = error.error && error.error.msg;
      this.isUserAdded = false;
      this.toastr.error(err || 'Error in adding Holiday.Please try again.');
    });
  }

  public edit(): void {
    // this.isUserAdded = true;
    // this.service.editHoliday(this.userForm.value, this.editId).subscribe(user => {
    //   this.isUserAdded = false;
    //   this.toastr.success('User added successfully');
    //   this.modalRef.close();
    //   this.getJobTitleList();
    // }, error => {
    //   this.isUserAdded = false;
    //   this.toastr.error('Error in adding user.Please try again.');
    // });
  }

  public deleteUserForm(id): void {
    this.deleteId = id;
    this.modalRef = this.modalService.open(this.deleteUser);
  }

  public deleteJobTitle(): void {
  //   this.service.deleteHoliday(this.deleteId).subscribe(user => {
  //     if (user) {
  //       this.close();
  //       this.getJobTitleList();
  //     }
  //   }, error => {
  //     this.toastr.error('Error getting detail');
  //   });
  }

 public getNextPage(event): void {
  this.activePage = event.activePage;
  this.pageSize = event.pageSize;
  this.getJobTitleList();
}

}

