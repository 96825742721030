<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
    <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="page-header-title">
            <span class="ng-star-inserted"></span
            ><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted">Attendance Configuration</h4></span>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</div>

<div class="page-body no-title">
  <div class="row">
    <div class="col-sm-12">
      <app-card>
        <div class="dt-responsive">
          <app-loading *ngIf="isLoading && !err" [isLoading]="isLoading && !err"></app-loading>
          <form [formGroup]="configList" *ngIf="!isLoading">
            <div class="card-content table-responsive table-full-width">
              <div class="row mx-4 my-4">
                <div class="col-sm-12">
                  <mat-checkbox formControlName="attendance_employeePermission" id="attendance_employeePermission">
                    Employee can add/edit/delete own attendance.
                  </mat-checkbox>
                </div>
                <div class="col-sm-12">
                  <mat-checkbox formControlName="attendance_supervisiorPermission" id="attendance_supervisiorPermission">
                    Supervisor can add/edit/delete attendance records of his employees.
                  </mat-checkbox>
                </div>
                <div class="col-sm-12">
                  <mat-checkbox formControlName="attendance_forceEmployeePunchByApp" id="attendance_forceEmployeePunchByApp">
                    Force employee to punch in/out in mobile app only.
                  </mat-checkbox>
                </div>

                <div class="col-sm-12 multi-btns action-buttons text-right">
                  <button
                    (click)="saveConfig()"
                    mat-raised-button
                    class="btn btn-primary"
                    color="primary"
                    [disabled]="configList.value.punchIpEnable && !configList.valid"
                  >
                    <i class="flaticon-diskette"></i> Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </app-card>
    </div>
  </div>
</div>
