<div style="margin-top: 33px; width: 99%; margin-left: 1px" class="row">
    <div class="col-sm-12">
        <div class="row top-tools">
            <div class="col-xs-12 col-sm-12 col-md-6 left-tools">

            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
                <div class="relative-position search-filter-con">
                    <span class="icon material-icons ng-tns-c114-0"> search </span>
                    <mat-form-field>
                        <input matInput #inputSearch type="search" [(ngModel)]="filterQuery1"
                               maxlength="40"
                               (ngModelChange)="onModelChange1(filterQuery1)"
                               class="form-control input-sm full-data-search">
                    </mat-form-field>
                    <span class="mytooltip cus-left tooltip-effect-4">
                                            <span class="tooltip-item">
                                                <button mat-mini-fab
                                                        class="btn btn-primary light add-rounded margin-button-05"
                                                        (click)="addNewSession()">
                                                    <i class="fa fa-plus"></i>
                                                </button>
                                            </span>
                                            <span class="tooltip-content clearfix">
                                                <span class="tooltip-text">New</span>
                                            </span>
                                        </span>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-12" *ngIf="dataSource">
        <mat-card-content>
            <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="select">
                    <mat-header-cell *matHeaderCellDef>
                        <a [matMenuTriggerFor]="menu" class="check-all">
                                            <span class="material-icons">
                                                more_vert
                                            </span>
                        </a>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="onSelectAllClicked2()">Select All</button>
                            <button (click)="onDeSelectAllClicked2()" *ngIf="selectedNewsDocs2.length > 0"
                                    mat-menu-item>Deselect All
                            </button>
                            <button (click)="onDeleteSelected2()" *ngIf="selectedNewsDocs2.length > 0"
                                    mat-menu-item>Delete Selected
                            </button>
                        </mat-menu>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <mat-checkbox #matCheckbox2 (change)="onNewDocSelectChange2($event, row.id)"
                                      value="{{row.id}}"></mat-checkbox>
                        <!-- <input [value]="row.id" class="user-input" #userSelect type="checkbox" (change)="onUserChanged(userSelect)"/> -->
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="sessionName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Session Name</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <span class="cursor-pointer" (click)="viewAction(row?.id)">{{ row.sessionName }}</span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="startDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        {{row.startDate  | date :'dd/MM/yyyy'}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <div *ngIf="row.status == 'pending'">Pending</div>
                        <div *ngIf="row.status == 'scheduled'">Scheduled</div>
                        <div *ngIf="row.status == 'complete'">Complete</div>
                        <div *ngIf="row.status == 'cancelled'">Cancelled</div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="attachments">
                    <mat-header-cell *matHeaderCellDef>Attachments</mat-header-cell>
                    <mat-cell class="cursor-pointer" *matCellDef="let row">
                                        <span class="mytooltip tooltip-effect-4" *ngIf="!row.hasAttachment">
                                            <span class="tooltip-item">
                                                    <i class="single-icon-click flaticon-attachment"
                                                       style="color: #d0cdcd"
                                                       (click)="onAddAttachment(row.id , true)"></i>
                                            </span>
                                            <span class="tooltip-content clearfix">
                                            <span class="tooltip-text"> No Attachments</span>
                                            </span>
                                          </span>

                        <span class="mytooltip tooltip-effect-4" *ngIf="row.hasAttachment">
                                            <span class="tooltip-item">
                                                <i class="single-icon-click flaticon-attachment" style="color: #666"
                                                   (click)="onAddAttachment(row.id , true)"></i></span>
                                            <span class="tooltip-content clearfix">
                                            <span class="tooltip-text">Include Attachments</span>
                                            </span>
                                          </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="part">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Participants</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <span class="cursor-pointer" (click)="viewAction1(row.id)">View</span>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="columns2"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columns2;">
                </mat-row>
            </mat-table>
            <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
            <mat-paginator
                #TableOnePaginator1="matPaginator"
                [pageSize]="5"
                [pageSizeOptions]="[10, 20]"
            ></mat-paginator>
        </mat-card-content>
        <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
    </div>
</div>



<ng-template #addSession let-modal>
    <!-- <div class="card my-0"> -->
    <!-- <div class="header"> -->
    <div class="mat-modal-header">
        <!-- <h4 class="card-title card-title-modal"> -->
        <h4 class="mat-title-modal">
            <span>Add Session</span>
        </h4>
    </div>
    <!-- <div class="card-content"> -->
    <div class="mat-modal-body">
        <app-loading *ngIf="isSessionAdded" [isLoading]="isSessionAdded"></app-loading>
        <!-- <form class="form-material mt-4" action="javascript:" [formGroup]="userForm" *ngIf="!isUserAdded"> -->
        <form class="form-material full-width-inputs" action="javascript:" [formGroup]="sessionForm"
              *ngIf="!isSessionAdded">
            <div class="row form-group">
                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <mat-label>Name</mat-label>
                        <input class="form-control" formControlName="sessionName" matInput maxlength="40"
                               required="" type="text">
                    </mat-form-field>
                </div>
                <div class="col-sm-6" style="margin-top: 20px">
                    <mat-checkbox #useCourseData [(ngModel)]="useCourseData.checked" color="primary">Use Course Data
                    </mat-checkbox>
                </div>
                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <input required="" [matDatepicker]="picker2" class="form-control"
                               formControlName="startDate" matInput>
                        <mat-datepicker-toggle [for]="picker2"
                                               matSuffix></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                        <mat-label for="des">Start Date</mat-label>
                    </mat-form-field>
                </div>
                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <input [matDatepicker]="picker3" class="form-control"
                               formControlName="endDate" matInput>
                        <mat-datepicker-toggle [for]="picker3"
                                               matSuffix></mat-datepicker-toggle>
                        <mat-datepicker #picker3></mat-datepicker>
                        <mat-label for="endDate">End Date</mat-label>
                    </mat-form-field>
                </div>
                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <mat-label>Delivery Location</mat-label>
                        <input class="form-control" formControlName="deliveryLocation" id="deliveryLocation" matInput
                               maxlength="40"
                               type="text">
                    </mat-form-field>
                </div>

                <div class="col-sm-6">
                    <div class="row">
                        <div class="col-6">
                            <mat-form-field class="form-element">
                                <mat-label>delivery Method</mat-label>
                                <mat-select
                                        class="form-control"
                                        formControlName="deliveryMethod">
                                    <mat-option value="classRoom">CLass Room</mat-option>
                                    <mat-option value="selfStudy">Self Study</mat-option>
                                    <mat-option value="webEx">Web Ex</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-6">
                            <mat-form-field class="form-element">
                                <mat-label>Status</mat-label>
                                <mat-select
                                        class="form-control"
                                        formControlName="status">
                                    <mat-option value="pending">Pending</mat-option>
                                    <mat-option value="scheduled">Scheduled</mat-option>
                                    <mat-option value="complete">Complete</mat-option>
                                    <mat-option value="cancelled">Cancelled</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12">
                    <mat-form-field class="form-element">
                        <mat-label>Description</mat-label>
                        <textarea class="form-control" matInput placeholder="Description"
                                  formControlName="description"></textarea>
                    </mat-form-field>
                </div>

            </div>
            <div class="action-buttons multi-btns col-sm-12 text-right">
                <button mat-raised-button class="btn-danger" (click)="close1()">
                    <i class="flaticon-cancel"></i>Cancel
                </button>
                <button mat-raised-button class="btn btn-primary" color="primary"
                        [disabled]="!sessionForm.valid"
                        (click)="submitAddUser2()"><i class="flaticon-diskette"></i>Save
                </button>
            </div>
        </form>
    </div>
    <!-- </div> -->
</ng-template>

<ng-template #deleteSession let-modal>
    <!-- <div class="card my-0"> -->
    <div mat-dialog-actions>
        <!-- <div class="header">  -->
        <div class="mat-modal-header">
            <!-- <h4 class="card-title card-title-modal">Delete User</h4>  -->
            <h4 class="mat-title-modal">Delete Session</h4>
        </div>
        <!-- <div class="card-content px-2 py-2"> -->
        <div class="mat-modal-body">
            <div class="text-left are-u">Are you sure ?</div>
            <div class="row">
                <div class="multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close1()">
                        <i class="flaticon-cancel"></i>Cancel
                    </button>
                    <button class="btn-primary" color="primary" mat-raised-button
                            (click)="deleteMultiple2()"><i class="flaticon-trash"></i>Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>







<ng-template #attach let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">Attachments</h4>
        </div>
        <div class="mat-modal-body">
            <form class="form-material full-width-inputs">
                <div class="row">
                    <div class="col-md-12 border" style="height: 200px !important; overflow-y: auto">
                        <mat-spinner *ngIf="this.showSpinner"></mat-spinner>
                        <mat-card *ngIf="showSpinner == false && this.attachmentList.length === 0">
                            No Attachment Found
                        </mat-card>
                        <ng-container *ngFor="let attachment of attachmentList; let i = index;">
                            <mat-card class="mt-2 shadow-sm">
                                <mat-card-title>
                                    {{attachment.fileName}}
                                </mat-card-title>
                                <mat-card-content>
                                    <b>Added On :</b> {{attachment.added | date :'dd/MM/yyyy' }} <br>
                                    <b>comments :</b> {{attachment.desc}}
                                </mat-card-content>
                                <mat-card-actions align="end">
                                    <button (click)="onDownloadClicked(attachment)" mat-icon-button
                                            aria-label="Example icon button with a vertical three dot icon">
                                        <mat-icon color="primary">cloud_download</mat-icon>
                                    </button>
                                </mat-card-actions>
                            </mat-card>
                        </ng-container>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="action-buttons col-md-12 text-right">
                        <button mat-raised-button class="btn-danger" (click)="close1()">
                            <i class="flaticon-cancel"></i>Cancel
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<ng-template #attach1 let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">Attachments</h4>
        </div>
        <div class="mat-modal-body">
            <form class="form-material full-width-inputs">
                <div class="row">
                    <div class="col-md-3">
                        <div class="upload-btn-wrapper">
                            <button class="upload-btn"><i style="padding-right: 5px" class="flaticon-attachment"></i>Attachment
                            </button>
                            <input type="file" name="myfile" class="form-control" (change)="fileChange($event, 'dd')">
                            <div class="fileName">{{this.fileObj && this.fileObj.fileName}}
                                <i *ngIf="fileUrl" class="fa fa-check success"></i>
                            </div>
                        </div>
                        <div class="upload-btn-wrapper">
                        </div>
                        <div class="file-error text-danger" *ngIf="!fileUrl && fileError">{{fileError}}</div>

                    </div>
                    <div class="help-text">Accept Up TO 2MB</div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field class="form-element">
                            <mat-label>Comment</mat-label>
                            <textarea matInput name="name" [(ngModel)]="comment" class="form-control"></textarea>
                        </mat-form-field>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-12 border" style="height: 200px !important; overflow-y: auto">
                        <mat-spinner *ngIf="this.showSpinner"></mat-spinner>
                        <mat-card *ngIf="showSpinner == false && this.attachmentList.length === 0">
                            No Attachment Found
                        </mat-card>
                        <ng-container *ngFor="let attachment of attachmentList; let i = index;">
                            <mat-card class="mt-2 shadow-sm">
                                <mat-card-title>
                                    {{attachment.fileName}}
                                </mat-card-title>
                                <mat-card-content>
                                    <b>Added On :</b> {{attachment.added | date :'dd/MM/yyyy' }} <br>
                                    <b>comments :</b> {{attachment.desc}}
                                </mat-card-content>
                                <mat-card-actions align="end">
                                    <button (click)="onDeleteClicked(attachment)" mat-icon-button
                                            aria-label="Example icon button with a vertical three dot icon">
                                        <mat-icon color="warn">delete</mat-icon>
                                    </button>
                                    <button (click)="onDownloadClicked(attachment)" mat-icon-button
                                            aria-label="Example icon button with a vertical three dot icon">
                                        <mat-icon color="primary">cloud_download</mat-icon>
                                    </button>
                                </mat-card-actions>
                            </mat-card>
                        </ng-container>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="action-buttons col-md-12 text-right">
                        <button [disabled]="isClicked" mat-raised-button class="btn-primary" (click)="saveAttachment()"
                                color="primary"><i
                                class="flaticon-diskette"></i>Save
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>