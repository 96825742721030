<div id="header-page" class="ng-star-inserted header-page">
    <div class="page-header ng-star-inserted">
        <div class="page-block ng-star-inserted">
            <div class="row align-items-center">
                <div class="col-md-8">
                    <div class="page-header-title">
                        <span class="ng-star-inserted"></span><span class="ng-star-inserted">
                            <h4 class="m-b-10 ng-star-inserted">Training Session List</h4>
                        </span>
                    </div>
                </div>
                <div class="col-md-4"></div>
            </div>
        </div>
    </div>
</div>

<div class="page-body no-title">
    <div class="row">
        <div class="col-sm-12">

            <div class="row top-tools">
                <div class="col-xs-12 col-sm-12 col-md-6 left-tools">
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
                    <div class="relative-position search-filter-con">
                        <span class="mytooltip cus-left tooltip-effect-4">
                            <span class="tooltip-item" style="padding: 10px">
                                <button mat-mini-fab class="btn btn-primary light add-rounded margin-button-05"
                                    (click)="filterCourseList()">
                                    <i class="fa fa-filter"></i>
                                </button>
                            </span>
                            <span class="tooltip-content clearfix">
                                <span class="tooltip-text">Filter</span>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <app-card [title]="'Course'">
                <div class="dt-responsive" *ngIf="dataSource">
                    <div class="table-responsive">
                        <mat-card-content>
                            <mat-table [dataSource]="dataSource" matSort>
                                <ng-container matColumnDef="sessionName">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Session Name</mat-header-cell>
                                    <mat-cell *matCellDef="let row" (click)="viewAction1(row)"> 
                                        <span class="cursor-pointer" >{{
                                            row.sessionName}}</span>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="trainingCourse">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Training Course</mat-header-cell>
                                    <mat-cell *matCellDef="let row" (click)="viewAction1(row)">
                                        {{ row.courseName }}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="startDate">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</mat-header-cell>
                                    <mat-cell *matCellDef="let row" (click)="viewAction1(row)"> 
                                        {{ row.startDate | date :'dd/MM/yyyy' }}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="Status">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
                                    <mat-cell *matCellDef="let row"  (click)="viewAction1(row)">
                                        <div *ngIf="row.status == 'pending'">Pending</div>
                                        <div *ngIf="row.status == 'scheduled'">Scheduled</div>
                                        <div *ngIf="row.status == 'complete'">Complete</div>
                                        <div *ngIf="row.status == 'cancelled'">Cancelled</div>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="attachments">
                                    <mat-header-cell *matHeaderCellDef>Attachments</mat-header-cell>
                                    <mat-cell class="cursor-pointer" *matCellDef="let row">
                                        <span class="mytooltip tooltip-effect-4" *ngIf="!row.hasAttachment">
                                            <span class="tooltip-item">
                                                <i class="single-icon-click flaticon-attachment"
                                                    style="color: #d0cdcd"></i>
                                            </span>
                                            <span class="tooltip-content clearfix">
                                                <span class="tooltip-text"> No Attachments</span>
                                            </span>
                                        </span>

                                        <span class="mytooltip tooltip-effect-4" *ngIf="row.hasAttachment">
                                            <span class="tooltip-item">
                                                <i class="single-icon-click flaticon-attachment" style="color: #666"
                                                    (click)="onAddAttachment(row.id)"></i></span>
                                            <span class="tooltip-content clearfix">
                                                <span class="tooltip-text">Include Attachments</span>
                                            </span>
                                        </span>
                                    </mat-cell>
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: columns;" class="cursor-pointer">
                                </mat-row>
                            </mat-table>
                            <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
                            <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                        </mat-card-content>
                    </div>
                    <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
                </div>
            </app-card>
        </div>
    </div>
</div>


<ng-template #filterCourse let-modal>
    <div mat-dialog-content>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">
                <span>Filter Courses</span>
            </h4>
        </div>
        <div class="mat-modal-body">
            <form [formGroup]="filterForm" action="javascript:" class="form-material full-width-inputs">
                <div class="row form-group">
                    <div class="col-sm-6">
                        <mat-form-field class="form-element">
                            <mat-label>Session Name</mat-label>
                            <input class="form-control" formControlName="sessionName" matInput maxlength="40"
                                type="text">
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6">
                        <mat-form-field class="form-element">
                            <mat-select required="" class="form-control" formControlName="courseName"
                                disableOptionCentering>
                                <mat-option value="">--Select--</mat-option>
                                <ng-container *ngFor="let j of courseList1">
                                    <mat-option value="{{j.title}}">{{ j.title }}</mat-option>
                                </ng-container>
                            </mat-select>
                            <mat-label for="courseId">Course</mat-label>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6">
                        <mat-form-field class="form-element">
                            <input required="" [matDatepicker]="picker2" class="form-control"
                                formControlName="startDate" matInput>
                            <mat-datepicker-toggle [for]="picker2" matSuffix></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                            <mat-label for="des">Start Date</mat-label>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field class="form-element">
                            <mat-label>Status</mat-label>
                            <mat-select class="form-control" formControlName="status">
                                <mat-option value="">All</mat-option>
                                <mat-option value="pending">Pending</mat-option>
                                <mat-option value="scheduled">Scheduled</mat-option>
                                <mat-option value="complete">Complete</mat-option>
                                <mat-option value="cancelled">Cancelled</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </form>
            <div class="action-buttons multi-btns col-sm-12 text-right">
                <button (click)="resetForm()" class="btn-danger reset-btn" mat-raised-button>
                    <i class="flaticon-refresh"></i>Reset
                </button>
                <button (click)="close()" class="btn-danger" mat-raised-button>
                    <i class="flaticon-cancel"></i>Cancel
                </button>
                <button (click)="search()" class="btn btn-primary" color="primary" mat-raised-button><i
                        class="flaticon-magnifiying-glass"></i>Search
                </button>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #addCourse1 let-modal>
    <!-- <div class="card my-0"> -->
    <!-- <div class="header"> -->
    <div class="mat-modal-header">
        <!-- <h4 class="card-title card-title-modal"> -->
        <h4 class="mat-title-modal">
            <span>Session Rating</span>
        </h4>
    </div>
    <!-- <div class="card-content"> -->
    <div class="mat-modal-body">
        <app-loading *ngIf="isTrainerAdded" [isLoading]="isTrainerAdded"></app-loading>
        <!-- <form class="form-material mt-4" action="javascript:" [formGroup]="userForm" *ngIf="!isUserAdded"> -->
        <form class="form-material full-width-inputs" action="javascript:" [formGroup]="participantForm"
            *ngIf="!isTrainerAdded">
            <div class="row form-group">
                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <mat-label>Trainer</mat-label>
                        <input class="form-control" formControlName="trainerName" id="participantName" matInput
                            maxlength="40" readonly type="text">
                    </mat-form-field>
                </div>
                <div class="col-sm-6">
                    <mat-checkbox style="padding-top: 25px" [disabled]="isSessionStatus1"
                        formControlName="sessionStatus">Session Status</mat-checkbox>
                </div>
                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <mat-label>Rating </mat-label>
                        <mat-select [disabled]="isSessionStatus1" class="form-control" formControlName="rating">
                            <mat-option value="poor">Poor</mat-option>
                            <mat-option value="fair">Fair</mat-option>
                            <mat-option value="good">Good</mat-option>
                            <mat-option value="veryGood">VeryGood</mat-option>
                            <mat-option value="excellent">Excellent</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-sm-12">
                    <mat-form-field class="form-element">
                        <mat-label>Comment </mat-label>
                        <textarea [readonly]="isSessionStatus1" class="form-control" matInput placeholder="Comment"
                            formControlName="comment"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="action-buttons multi-btns col-sm-12 text-right">
                <button mat-raised-button class="btn-danger" (click)="close()">
                    <i class="flaticon-cancel"></i>Cancel
                </button>
                <button mat-raised-button class="btn btn-primary" color="primary"
                    [disabled]="!participantForm.valid ||!this.correct || isSessionStatus1" (click)="submitAddUser()"><i
                        class="flaticon-diskette"></i>Save
                </button>
            </div>
        </form>
    </div>
    <!-- </div> -->
</ng-template>


<ng-template #attach let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">Attachments</h4>
        </div>
        <div class="mat-modal-body">
            <form class="form-material full-width-inputs">
                <div class="row">
                    <div class="col-md-12 border" style="height: 200px !important; overflow-y: auto">
                        <mat-spinner *ngIf="this.showSpinner"></mat-spinner>
                        <mat-card *ngIf="showSpinner == false && this.attachmentList.length === 0">
                            No Attachment Found
                        </mat-card>
                        <ng-container *ngFor="let attachment of attachmentList; let i = index;">
                            <mat-card class="mt-2 shadow-sm">
                                <mat-card-title>
                                    {{attachment.fileName}}
                                </mat-card-title>
                                <mat-card-content>
                                    <b>Added On :</b> {{attachment.added | date :'dd/MM/yyyy' }} <br>
                                    <b>comments :</b> {{attachment.desc}}
                                </mat-card-content>
                                <mat-card-actions align="end">
                                    <button (click)="onDownloadClicked(attachment)" mat-icon-button
                                        aria-label="Example icon button with a vertical three dot icon">
                                        <mat-icon color="primary">cloud_download</mat-icon>
                                    </button>
                                </mat-card-actions>
                            </mat-card>
                        </ng-container>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="action-buttons col-md-12 text-right">
                        <button mat-raised-button class="btn-danger" (click)="close()">
                            <i class="flaticon-cancel"></i>Cancel
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>