import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

// Module
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { AppRoutingModule } from "./app-routing.module";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { CoreModule } from "./core/core.module";
import { DashboardModule } from "./dashboard/dashboard.module";
import { ToastrModule } from "ngx-toastr";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "./shared/shared.module";
import { HrmSharedModule } from "./hrmshared/hrmshared.module";
// Component
import { AppComponent } from "./app.component";
import { LoginComponent } from "./core/login/login.component";

import { AdminComponent } from "./layout/admin/admin.component";
import { AuthComponent } from "./layout/auth/auth.component";
// import {SharedModule} from './shared/shared.module';
import { MenuItems } from "./shared/menu-items/menu-items";
import { BreadcrumbsComponent } from "./layout/admin/breadcrumbs/breadcrumbs.component";
import { DialogHrmModule } from "./dialog-hrm/dialog-hrm.module";
import { DialogTrainerModule } from "./trainer-dialog/trainer-dialog.module";
import { MatButtonModule } from "@angular/material/button";
import { MatRippleModule } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { LanguageConfig } from "./Language-config";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { ViewReportComponent } from "./dashboard/pim/reports/view-report/view-report.component";
import { CookieService } from "ngx-cookie-service";
import { CommonService } from "./common-service.component";
import { NgxQRCodeModule } from "@techiediaries/ngx-qrcode";
import { ControlAdminComponent } from "./layout/control-admin/control-admin.component";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    BreadcrumbsComponent,
    LoginComponent,
    ControlAdminComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HrmSharedModule,
    DashboardModule,
    ToastrModule.forRoot(),
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    HttpClientModule,
    CoreModule,
    DialogHrmModule,
    DialogTrainerModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    NgxQRCodeModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
  ],
  providers: [
    CommonService,
    LanguageConfig,
    ViewReportComponent,
    CookieService,
    LoginComponent,
    MenuItems,
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
