import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import {AnnouncementService} from "../../../../service/announcement.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import { NotificationService } from "src/app/core/services/notification.service";

import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox';
import {MatDialog} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {saveAs} from 'file-saver-es';
import { AuthService } from 'src/app/core/services/auth.service';
@Component({
    selector: 'app-brands',
    templateUrl: './brands.component.html',
    styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit {
    @ViewChild('brandForm',{static:false}) brandForm;
    @ViewChild('deleteUserSelected',{static:false}) deleteUserSelected;
    @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
    @ViewChild('FilterForm',{static:false}) FilterForm;
    public dialogRef: any;
    public modalRef: any;
    public newForm: FormGroup;
    public isEdit : boolean = false ;
    public filterForm: FormGroup;
    public filterQuery = '';

    public selectedBrands = [];

    dataSource: MatTableDataSource<any> = new MatTableDataSource([]);;
    displayedColumns = ['select','brandId', 'name' , 'edit'];
    isLoading = false;
    isSaving = false;
    @ViewChild(MatSort, {static: false}) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(
        private authService : AuthService ,
        private announcementService: AnnouncementService,
        private fb: FormBuilder,
        private toastr: NotificationService,
        private dialog: MatDialog
    ) {
        this.newForm = this.fb.group({
            name: '',
            brandId: '',
            id: ''
        });

        this.filterForm = this.fb.group({
            name: '',
            id : ''
        });
    }


    public permissions : any;
    public read = false;
    public write = false ;
    public update = false;
    public delete = false;
   
    checkPermission(module,tabs) : void 
    {
        this.permissions = JSON.parse(this.authService.getPermissions());

        this.read = false ;
        this.write = false ;
        this.delete = false ;
        this.update = false;
        if(this.permissions != undefined && this.permissions != null )
        {
            for(let p of this.permissions)
            {
               
                if(p.id === module)
                {
                   
                    if(p.modules != undefined)
                    {
                      
                        for(let tab of p.modules)
                        {
                            if(tab.id === tabs)
                            {
                              
                                for(let move of tab.permits)
                                {
                                    if(move.id === "u")
                                    {
                                        this.update = true;
                                    }
                                    else if(move.id === "r")
                                    {
                                        this.read = true;
                                    }
                                    else if(move.id === "c")
                                    {
                                      
                                        this.write = true;
                                    }
                                    else if(move.id === "d")
                                    {
                                        this.delete = true;
                                    }
                                }
                            }
                        }
                    }
                   
                }
               
            }
        }
       

    }
    openFilterForm() : void 
    {
        this.dialogRef=this.dialog.open(this.FilterForm,{
            width:'580px',
            height:'auto'
        });
    }
    onResetForm() : void 
    {
        this.filterForm.reset();
        this.getCategories();
    }

    filterVendor () : void 
    {
        this.closeModel();
        this.isLoading = true;
        this.announcementService.filterAssetsBrands(this.filterForm.value).subscribe(res => {
            this.isLoading = false;
            if (res && res.data) {
                let list = res.data;
              
                this.dataSource = new MatTableDataSource(list);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            }
         
        }, error => {
            const err = error.error && error.error.msg;
            this.isLoading = false;
            this.toastr.error(err || "Error while getting Brands.") ;
        });
    }
    ngOnInit() {
        this.dataSource = new MatTableDataSource([]);
        this.checkPermission("assets","assets_br");
        this.getCategories()
    }

    getCategories() {
        this.isLoading = true;
        this.announcementService.getAssetsBrands().subscribe(res => {
            this.isLoading = false;
            if (res && res.data) {
                let list = res.data;
               
                this.dataSource = new MatTableDataSource(list);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            }
          
        }, error => {
            const err = error.error && error.error.msg;
            this.isLoading = false;
        });
    }

    itemClick(item) {
        this.isEdit = true ;
        this.newForm.reset();
        this.newForm.patchValue({id:item.id,brandId: item.brandId , name: item.name})
        this.dialogRef = this.dialog.open(this.brandForm,{
            width:'440px',
            height:'auto'
        });
        
    }


    openModel() {
        this.isEdit = false ;
        this.newForm.reset();
        this.dialogRef = this.dialog.open(this.brandForm,{
            width:'440px',
            height:'auto'
        });
        this.isSaving = true ;
        this.announcementService.generateIDForAllAssets('brands').subscribe(user => {
            if (user && user.data) {
             this.newForm.patchValue({
                brandId : user.data 
             });
             this.isSaving = false ;
             
            }
        }, error => {
            this.isSaving = false ;
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error in generating Id');
        });
      
    }

    closeModel() {
        this.newForm.patchValue({id: null, name: ''})
        // this.modalRef && this.modalRef.close();
        this.dialogRef && this.dialogRef.close();
    }

    save() {
        this.newForm.markAsTouched();
        if (this.newForm.invalid) {
            return;
        }
        this.isSaving = true;
        (this.isEdit ?
                this.announcementService.updateAssetsBrand(this.newForm.value) :
                this.announcementService.saveAssetsBrand(this.newForm.value)
        ).subscribe(res => {
            if (res && res.data) {
                if (this.isEdit) {
                    this.toastr.success("Brand updated successfully ..!")
                    // this.dataSource.data.map(item => {
                    //     if (item.id === this.newForm.value.id) {
                    //         item.name = res.data.name;
                    //     }
                    // });
                } else {
                    this.toastr.success("Brand updated successfully ..!");
                   
                }
                this.closeModel();
                this.getCategories();
            }
            this.isSaving = false;
        }, error => {
            const err = error.error && error.error.msg;
            this.isSaving = false;
            if(this.isEdit)
            {
                this.toastr.error(err || "Error while updating brand.");
            }
            else
            {
                this.toastr.error(err || "Error while saving brand.");
            }
            
        });
    }

    
    onSelectAllClicked() {
        this.selectedBrands = [];
        this.matCheckBoxes.forEach(
            item => {
                this.selectedBrands.push(item.value);
                item.checked = true;
            }
        );
    }

    public close(): void {
        if(this.dialogRef!=null)
            this.dialogRef.close();
    }

    onDeSelectAllClicked() {
        this.selectedBrands = [];
        this.matCheckBoxes.forEach(
            item => {
                item.checked = false;
            }
        );
    }

    public deleteMultiple(): void {
        this.announcementService.deleteBrand(this.selectedBrands).subscribe(user => {
            if (user) {
                this.close();
                this.toastr.success('Brands deleted successfully ');
                this.getCategories();
             
            }
        }, error => {
            this.getCategories();
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error in deleting Brands');
        });
        this.onDeSelectAllClicked();
        this.selectedBrands = [];
    }

    onNewDocSelectChange($event: MatCheckboxChange, id: any) {
        if (!this.alreadySelected($event.source) && $event.checked) {
            this.selectedBrands.push($event.source.value);
        } else if (!$event.checked) {
            this.selectedBrands = this.selectedBrands.filter(
                value => {
                    return value !== $event.source.value;
                }
            );
        }
    }

    alreadySelected(item: MatCheckbox): boolean {
        this.selectedBrands.forEach(
            value => {
                if (item === value) {
                    return true;
                }
            }
        );
        return false;
    }

    onDeleteSelected() {
        // this.dmodalRef = this.modalService.open(this.deleteUser);
        this.dialogRef = this.dialog.open(this.deleteUserSelected, {
            width: '440px',
            height: 'auto'
        });
    }

    
    exportToCsv() {
        const csv = this.dataSource.filteredData.map(
            row => {
                return [row.brandId , row.name].join(',') + '\n';
            }
        );
        csv.unshift(['Id','Name'].join(',') + '\n');
        const exportDate = new Date();
        // tslint:disable-next-line:max-line-length
        const exportFileName = `Csv-export-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}.csv`;
        saveAs(new Blob(csv, {type: 'text/csv;charset=utf-8'}), exportFileName);
    }

    saveAsPdf() {
        if (this.dataSource.filteredData.length <= 0) {
            this.toastr.error('No Data to export');
            return;
        }
        const body = [];
        for (let i = 0; i < this.dataSource.filteredData.length; i++) {
            body.push(
                [
                    this.dataSource.filteredData[i].brandId,
                    this.dataSource.filteredData[i].name
                ]);
        }
        const doc = new jsPDF();
        autoTable(doc, {
            head: [['Id','name']],
            body: body
        });
        const exportDate = new Date();
        const exportFileName = `Brands-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}}`;
        doc.save(exportFileName);
    }

    onModelChange(value: string) {
        this.dataSource.filter = value.trim().toLocaleLowerCase();
    }
}
