import {Component, ElementRef, OnInit} from '@angular/core';
import {DashboardService} from "../dashboard.service";


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class HrmAdminComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}
