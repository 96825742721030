import { Component, NgZone, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { NotificationService } from "src/app/core/services/notification.service";
import { DashboardService } from "src/app/dashboard/dashboard.service";
import { OnboardingService } from "../../onboarding.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import { MultiSelectTokenComponent } from "src/app/hrmshared/multi-select-token/multi-select-token.component";
@Component({
  selector: "app-events",
  templateUrl: "./add-events.component.html",
  styleUrls: ["./add-events.component.scss"],
})
export class AddEventsComponent implements OnInit {
  public validationError = {
    required: "Required",
  };
  public userForm: FormGroup;
  public employeeList: Array<any> = [];
  public employeeList2: Array<any> = [];
  public locationList: Array<any> = [];
  // public filteredEmployees: Array<any> = [];
  // public employeeFilterCtrl: FormControl = new FormControl();
  // protected _onDestroy = new Subject<void>();
  // public filteredEmployees2: Array<any> = [];
  // public employeeFilterCtrl2: FormControl = new FormControl();
  // protected _onDestroy2 = new Subject<void>();
  public minDate: Date = new Date();

  participants: any[] = [];
  @ViewChild("participantsDropDown") participantsDropDown: MultiSelectTokenComponent;
  owners: any[] = [];
  @ViewChild("ownersDropDown") ownersDropDown: MultiSelectTokenComponent;

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private service: DashboardService,
    private onboardingService: OnboardingService,
    private fb: FormBuilder,
    private toastr: NotificationService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.service.getLocationList().subscribe((resp) => {
      this.locationList = resp && resp.data;
    });
    this.service.getEmployeeDetailList().subscribe((resp) => {
      if (resp && resp.data) {
        resp.data.map((e) => {
          this.employeeList.push({ name: e.id + " " + e.name, id: e.id });
        });
        this.ownersDropDown.setData(this.employeeList);
      }
    });

    //this.getEmployeeByLocation();
  }

  getEmployeeByLocation(event): void {
    this.participants = [];
    
    this.onboardingService.getEmployeesByLocation(event).subscribe(
      (resp) => {
        this.employeeList2 = [];
        if (resp && resp.data) {
          resp.data.map((e) => {
            this.employeeList2.push({ name: e.id + " " + e.name, id: e.id });
          });

          this.participantsDropDown.setData(this.employeeList);
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error in adding events.Please try again.");
      }
    );
  }

  public createForm(): void {
    this.userForm = this.fb.group({
      dueDate: ["", [Validators.required]],
      locationId: ["", [Validators.required]],
      name: ["", [Validators.required]],
    });
  }

  public add(): void {

    let participantsIds = this.participants.map((e) => e.id)
    let ownersIds = this.owners.map((e) => e.id)
    
    let obj = {
      dueDate: this.userForm.value.dueDate,
      name: this.userForm.value.name,
      locationId: this.userForm.value.locationId,
      participants: participantsIds,
      owners: ownersIds,
    };
    this.onboardingService.addNeWOnboardingEvents(obj).subscribe(
      (user) => {
        if (user && user.data) {
          this.toastr.success("Events added successfully");
          this.ediEvent(user.data.id);
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error in adding events.Please try again.");
      }
    );
  }

  public cancell() {
    this.ngZone.run(() => {
      void this.router.navigate(["/onboarding/occasions"], { queryParams: { action: "add" } });
    });
  }

  ediEvent(id): void {
    this.ngZone.run(() => {
      void this.router.navigate(["/onboarding/occasions/view/" + id]);
    });
  }
}
