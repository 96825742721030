<!-- title="Appraisal configuration" -->
<app-loading *ngIf="isLoadingCustom" [isLoading]="this.isLoadingCustom === true"></app-loading>
<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
    <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="page-header-title">
            <span class="ng-star-inserted"></span><span class="ng-star-inserted">
              <h4 class="m-b-10 ng-star-inserted">Custom Field Section > {{this.sectionForm.value.name}} </h4>
            </span>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</div>

<div class="page-body no-title" [hidden]="isLoadingCustom">
  <div class="row">

    <div class="col-sm-12">
      <div class="row top-tools">
        <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6 left-tools">
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
          <div class="relative-position search-filter-con">
            <span class="mytooltip cus-left tooltip-effect-4" [hidden]="!write">
              <span class="tooltip-item">
                <button mat-mini-fab class="btn btn-primary  light add-rounded margin-button-05"
                  (click)="addNewCustomField()">
                  <i class="fa fa-plus"></i>
                </button>
              </span>
              <span class="tooltip-content clearfix">
                <span class="tooltip-text">New</span>
              </span>
            </span>
          </div>
        </div>
      </div>
      <br>
      <app-card>
        <h4>Edit Custom Field Section</h4>
        <form class="form-material full-width-inputs" action="javascript:" [formGroup]="sectionForm">
          <div class="row form-group">

            <div class="col-sm-6">
              <mat-form-field class="form-element">
                <mat-label>Name</mat-label>
                <input [readonly]="!update" pattern="*" type="text" matInput class="form-control" maxlength="40"
                  required="" id="name" formControlName="name">
              </mat-form-field>
            </div>

            <div class="col-sm-6">
              <mat-form-field class="form-element">
                <mat-label>Screen</mat-label>
                <mat-select [disabled]="!update" disableOptionCentering class="form-control" formControlName="header"
                  required="" disabled>
                  <mat-option value="">
                    --Select--
                  </mat-option>
                  <mat-option *ngFor="let s of screenList" [value]="s.value">
                    {{s.label}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>


          </div>

          <div class="row my-2 mx-2">
            <div class="action-buttons multi-btns col-sm-12 text-right">
              <button mat-raised-button class="btn-danger" (click)="cancelSectionDetails()"><i
                  class="flaticon-cancel"></i>Cancel</button>
              <button [hidden]="!update" mat-raised-button class="btn-primary" color="primary" (click)="updateSection()"
                [disabled]="!this.sectionForm.valid"><i class="flaticon-diskette"></i>Save</button>
            </div>
          </div>
        </form>
      </app-card>
    </div>
    <br>
    <div class="col-sm-12">
      <!-- <div class="row top-tools">
          <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6 left-tools">
            <span class="export-icon-btn csv" (click)="exportToCsv()"><i
                    class="material-icons">get_app</i><span>CSV</span>
            </span>
            <span class="export-icon-btn pdf" (click)="saveAsPdf()"><i class="material-icons">get_app</i><span>PDF</span>
            </span> -->
      <!-- <span (click)="demoClick()" class="export-icon-btn pdf"><i class="material-icons">get_app</i><span>PDF</span>
            </span> -->
      <!-- </div>
          <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
            <div class="relative-position search-filter-con"> 
              <span class="icon material-icons ng-tns-c114-0"> search </span>
              <mat-form-field> 
                <input matInput #inputSearch type="search" [(ngModel)]="filterQuery" maxlength="40"
                       (ngModelChange)="onModelChange(filterQuery)"
                       class="form-control input-sm full-data-search" >
              </mat-form-field>
            </div>
          </div>
        </div> -->
      <app-card [title]="'Job Title'">
        <div class="dt-responsive" *ngIf="dataSource">
          <div class="table-responsive table hrm-table-style-1 full-data-table table-cols-4"
            style="overflow:visible !important;">
            <mat-card-content>
              <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="select">
                  <mat-header-cell [hidden]="!delete" *matHeaderCellDef>
                    <a [matMenuTriggerFor]="menu" class="check-all">
                      <span class="material-icons">
                        more_horiz
                      </span>
                    </a>

                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="onSelectAllClicked()">Select All</button>
                      <button (click)="onDeSelectAllClicked()" *ngIf="selectedCheckBoxList.length > 0" mat-menu-item>
                        Deselect All
                      </button>
                      <button (click)="onDeleteSelected()" *ngIf="selectedCheckBoxList.length > 0" mat-menu-item>
                        Delete Selected
                      </button>
                    </mat-menu>
                  </mat-header-cell>
                  <mat-cell [hidden]="!delete" *matCellDef="let row">
                    <mat-checkbox #matCheckbox (change)="onCheckBoxChange($event, row.id)" value="{{row.id}}">
                    </mat-checkbox>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="name">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Custom Field Name</mat-header-cell>
                  <mat-cell (click)="this.update ? updateCustomField(row.id) : doNothing() "
                    class="{{this.classSelected}}" *matCellDef="let row">
                    {{ row.name }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="type">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Field Type</mat-header-cell>
                  <mat-cell (click)="this.update ? updateCustomField(row.id) : doNothing() "
                    class="{{this.classSelected}}" *matCellDef="let row">
                    {{ row.type }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="required">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Required</mat-header-cell>
                  <mat-cell (click)="this.update ? updateCustomField(row.id) : doNothing() "
                    class="{{this.classSelected}}" *matCellDef="let row">
                    {{ row.required }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="allLocations">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Availability</mat-header-cell>
                  <mat-cell (click)="this.update ? updateCustomField(row.id) : doNothing() "
                    class="{{this.classSelected}}" *matCellDef="let row">
                    {{ row.allLocations }}
                  </mat-cell>
                </ng-container>


                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columns;">
                </mat-row>
              </mat-table>
              <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
              <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
            </mat-card-content>
          </div>
          <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
        </div>
      </app-card>
    </div>


  </div>
</div>

<ng-template #addCustomField let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">
        <span *ngIf="!this.isEdit">Add</span>
        <span *ngIf="this.isEdit">Edit</span>
        Custom Field
      </h4>
    </div>
    <div class="mat-modal-body">

      <app-loading [isLoading]="this.isLoadingEdit === true"></app-loading>
      <form class="form-material full-width-inputs" action="javascript:" [formGroup]="customFieldForm"
        [hidden]="isLoadingEdit">
        <div class="row form-group">

          <div class="col-sm-6">
            <mat-form-field class="form-element">
              <mat-label>Field Name</mat-label>
              <input pattern="*" type="text" matInput class="form-control" maxlength="40" required="" id="name"
                formControlName="name" (keyup)="checkFieldName($event)">
            </mat-form-field>
            <mat-error class="error-messages" *ngIf="validCheck">
              Field Name already exists
            </mat-error>
          </div>

          <div class="col-sm-6">
            <mat-form-field class="form-element">
              <mat-label>Type</mat-label>
              <mat-select disableOptionCentering class="form-control" formControlName="type" required=""
                [(ngModel)]="typeSelected" [disabled]="isEdit">
                <mat-option value="">
                  --Select--
                </mat-option>
                <mat-option *ngFor="let s of typesList" [value]="s.value">
                  {{s.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>


        </div>
        <div class="row form-group"
          *ngIf="this.customFieldForm.value.type ==='dd' || this.customFieldForm.value.type ==='cb' ">

          <div class="col-sm-6">
            <mat-form-field class="form-element">
              <mat-label>Options</mat-label>
              <input type="text" matInput class="form-control" maxlength="512" required="" id="name"
                formControlName="options" [(ngModel)]="options">
            </mat-form-field>
          </div>
        </div>
        <div class="row form-group">

          <div class="col-sm-6" *ngIf="this.customFieldForm.value.type !='cb' ">
            <mat-checkbox formControlName="required">Required</mat-checkbox>
          </div>
        </div>

        <div class="row form-group">


          <div class="col-sm-6">
            <mat-form-field class="form-element">
              <mat-label>Available</mat-label>
              <mat-select disableOptionCentering class="form-control" formControlName="allLocations" required=""
                [(ngModel)]="availableSelected" [disabled]="isEdit">
                <mat-option *ngFor="let s of availablesList" [value]="s.value">
                  {{s.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-sm-6" *ngIf="!this.customFieldForm.value.allLocations">
            <mat-form-field class="form-element">
              <mat-label>Locations</mat-label>


              <mat-select disableOptionCentering class="form-control" formControlName="locations" required="" multiple
              (selectionChange)="changeLoc($event)"
                [disabled]="isEdit">
                <mat-select-trigger>
                  <mat-chip-list>
                    <mat-chip *ngFor="let s of tempLocations" [selectable]="true" >
                      {{s.location}}
                    </mat-chip>
                  </mat-chip-list>
                </mat-select-trigger>
                <ng-container *ngFor="let s of locationList">
                          <mat-option [value]="s.id"> {{s.location}}</mat-option>
                        </ng-container>
              </mat-select>


              <!-- <mat-select disableOptionCentering  class="form-control"
                                    formControlName="locations" required="" multiple [disabled]="isEdit">
                            <mat-option *ngFor="let s of locationList" [value]="s.id">
                                {{s.location}}
                            </mat-option>
                        </mat-select> -->
            </mat-form-field>
          </div>


        </div>

        <div class="row my-2 mx-2">
          <div class="action-buttons multi-btns col-sm-12 text-right">
            <button mat-raised-button class="btn-danger" (click)="close()"><i
                class="flaticon-cancel"></i>Cancel</button>
            <button *ngIf="!this.isEdit" mat-raised-button class="btn-primary" color="primary"
              (click)="addCustomFields()" [disabled]="!this.customFieldForm.valid"><i
                class="flaticon-diskette"></i>Save</button>
            <button *ngIf="this.isEdit" mat-raised-button class="btn-primary" color="primary" (click)="updateCustom()"
              [disabled]="!this.customFieldForm.valid"><i class="flaticon-diskette"></i>Update</button>

          </div>
        </div>
      </form>


    </div>
  </div>

</ng-template>

<ng-template #deleteCustomFields let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">Delete Cutom Fields</h4>
    </div>
    <div class="mat-modal-body">
      <app-loading [isLoading]="this.isLoadingEdit === true"></app-loading>
      <div [hidden]="this.isLoadingEdit" class="text-left are-u">Are you sure ?</div>
      <div [hidden]="this.isLoadingEdit" class="row">
        <div class="multi-btns col-sm-12 text-right">
          <button mat-raised-button class="btn-danger" (click)="close()">
            <i class="flaticon-cancel"></i>Cancel
          </button>
          <button mat-raised-button class="btn-primary" color="primary" (click)="deleteCustomField()">
            <i class="flaticon-trash"></i>Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>