<div>
  <h1 *ngIf="typeForm=='add'" mat-dialog-title class="header-dialog">Add Trainer</h1>
  <h1 *ngIf="typeForm=='parti'" mat-dialog-title class="header-dialog">Add Participants</h1>
  <div mat-dialog-content>
      <form class="form-material" action="javascript:" [formGroup]="trainerForm" *ngIf="typeForm=='add'">
          <div class="form-row">
            <div class="width-for-form">
              <mat-form-field>
                <mat-select formControlName="empId" placeholder="Add to list *" #singleSelect>
                  <mat-option>
                    <ngx-mat-select-search [formControl]="userFilterCtrl"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let item of filteredUsers" [value]="item">
                    {{item.name}} 
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            </div>
    
          </form>

          <form class="form-material" action="javascript:" [formGroup]="trainerForm" *ngIf="typeForm=='parti'">
            <div class="form-row">
              <div class="width-for-form">
                <mat-form-field>
                  <mat-select formControlName="empId" placeholder="Add to list *" #singleSelect>
                    <mat-option>
                      <ngx-mat-select-search [formControl]="userFilterCtrl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let item of filteredUsers" [value]="item">
                      {{item.name}} 
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              </div>
      
            </form>
  </div>
  <div mat-dialog-actions class="text-right">
    <button mat-raised-button color="warn" [mat-dialog-close]="true" (click)="onNoClick()">Cancel</button>
   <!--  *ngIf="typeForm=='add'" -->  <button  mat-raised-button color="primary" [disabled]="trainerForm.invalid" (click)="submitAddTrainer()">Save</button>
  </div>  
  </div>
