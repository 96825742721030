<div id="header-page" class="ng-star-inserted header-page">
    <div class="page-header ng-star-inserted">
        <div class="page-block ng-star-inserted">
            <div class="row align-items-center">
                <div class="col-md-8">
                    <div class="page-header-title">
                        <span class="ng-star-inserted"></span><span class="ng-star-inserted">
                            <h4 class="m-b-10 ng-star-inserted">Assets</h4>
                        </span>
                    </div>
                </div>
                <div class="col-md-4"></div>
            </div>
        </div>
    </div>
</div>

<div class="page-body no-title">
    <div class="row">
        <div class="col-sm-12">

            <div class="row top-tools">

                <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6 left-tools">
                    <span class="export-icon-btn csv" (click)="exportToCsv()"><i
                            class="material-icons">get_app</i><span>CSV</span>
                    </span>
                    <span class="export-icon-btn pdf" (click)="saveAsPdf()"><i
                            class="material-icons">get_app</i><span>PDF</span>
                    </span>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 right-tools form-material form-group">
                    <div class="relative-position search-filter-con action-buttons">
                        <!-- <span class="icon material-icons ng-tns-c114-0"> search </span> -->
                        <!-- <mat-form-field>
                            <input matInput #inputSearch type="search" [(ngModel)]="filterQuery" maxlength="30"
                            (ngModelChange)="onModelChange(filterQuery)"
                            class="form-control input-sm full-data-search">    
                        </mat-form-field> -->

                        <span class="mytooltip cus-left tooltip-effect-4" [hidden]="!write">
                            <span class="tooltip-item">
                                <button mat-mini-fab class="btn btn-primary light add-rounded margin-button-05"
                                    (click)="openModel()">
                                    <i class="fa fa-plus"></i>
                                </button>
                            </span>
                            <span class="tooltip-content clearfix">
                                <span class="tooltip-text">New</span>
                            </span>
                        </span>
                        <span class="mytooltip cus-left tooltip-effect-4">
                            <span class="tooltip-item">
                                <button mat-mini-fab class="btn btn-primary light add-rounded margin-button-05"
                                    (click)="openFilterForm()">
                                    <i class="fa fa-filter"></i>
                                </button>
                            </span>
                            <span class="tooltip-content clearfix">
                                <span class="tooltip-text">Filter</span>
                            </span>
                        </span>
                    </div>
                </div>
            </div>

            <app-card>
                <!-- <mat-card-header class="d-flex justify-content-between">
                    <mat-card-title>Assets</mat-card-title>
                    <button mat-mini-fab aria-label="Add category" (click)="openModel()" color="primary">
                        <mat-icon>add</mat-icon>
                    </button>
                </mat-card-header> -->

                <mat-card-content>
                    <mat-table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="select">
                            <mat-header-cell *matHeaderCellDef [hidden]="!delete">
                                <a [matMenuTriggerFor]="menu" class="check-all">
                                    <span class="material-icons">
                                        more_vert
                                    </span>
                                </a>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item (click)="onSelectAllClicked()">Select All</button>
                                    <button (click)="onDeSelectAllClicked()" *ngIf="selectedAssets.length > 0"
                                        mat-menu-item>Deselect All
                                    </button>
                                    <button (click)="onDeleteSelected()" *ngIf="selectedAssets.length > 0"
                                        mat-menu-item>Delete Selected
                                    </button>
                                </mat-menu>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row" [hidden]="!delete">
                                <mat-checkbox #matCheckbox (change)="onNewDocSelectChange($event, row.id)"
                                    value="{{row.id}}"></mat-checkbox>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="assetId">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Asset Id</mat-header-cell>
                            <mat-cell (click)="itemClick(row) " class="cursor-pointer" *matCellDef="let row">
                                {{row.assetId}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="catName">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Category</mat-header-cell>
                            <mat-cell (click)="itemClick(row)" class="cursor-pointer" *matCellDef="let row">
                                {{row.catName}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="locationName">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Location</mat-header-cell>
                            <mat-cell (click)="itemClick(row)" class="cursor-pointer" *matCellDef="let row">
                                {{row.locationName}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="serialNumber">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Serial Number</mat-header-cell>
                            <mat-cell (click)="itemClick(row) " class="cursor-pointer" *matCellDef="let row">
                                {{row.serialNumber}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="model">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Model</mat-header-cell>
                            <mat-cell (click)="itemClick(row) " class="cursor-pointer" *matCellDef="let row">
                                {{row.model}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="status">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Status </mat-header-cell>
                            <mat-cell (click)="itemClick(row) " class="cursor-pointer" *matCellDef="let row">
                                {{row.status}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="assignedToId">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Assigned <br> Employee Id
                            </mat-header-cell>
                            <mat-cell (click)="itemClick(row) " class="cursor-pointer" *matCellDef="let row">
                                {{row.assignedToId}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="assignedToName">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Assigned<br>Employee</mat-header-cell>
                            <mat-cell (click)="itemClick(row) " class="cursor-pointer" *matCellDef="let row">
                                {{row.assignedToName}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="assignedFrom">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Assigned On </mat-header-cell>
                            <mat-cell (click)="itemClick(row) " class="cursor-pointer" *matCellDef="let row">
                                {{row.assignedFrom | date : 'dd/MM/YYYY'}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="warrantyEnd">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Warranty Ends </mat-header-cell>
                            <mat-cell (click)="itemClick(row) " class="cursor-pointer" *matCellDef="let row">
                                {{row.warrantyEnd | date : 'dd/MM/YYYY'}}
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;">
                        </mat-row>
                    </mat-table>
                    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
                    <!-- <mat-paginator [length]="paging.totalRecords" [pageSize]="paging.pageSize"
                                [pageSizeOptions]="paging.pageSizeOptions"
                                [pageIndex]="paging.pageIndex" > -->
                    <!-- </mat-paginator> -->
                    <mat-paginator [pageSizeOptions]="[25, 50,100]" showFirstLastButtons></mat-paginator>
                </mat-card-content>
            </app-card>
        </div>
    </div>
</div>



<ng-template #deleteUserSelected let-modal>
    <!-- <div class="card my-0"> -->
    <div mat-dialog-actions>
        <!-- <div class="header">  -->
        <div class="mat-modal-header">
            <!-- <h4 class="card-title card-title-modal">Delete User</h4>  -->
            <h4 class="mat-title-modal">Delete Asset</h4>
        </div>
        <!-- <div class="card-content px-2 py-2"> -->
        <div class="mat-modal-body">
            <div class="text-left are-u">Are you sure ?</div>
            <div class="row">
                <div class="multi-btns action-buttons col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close()">
                        <i class="flaticon-cancel"></i> Cancel</button>
                    <button class="btn-primary" color="primary" mat-raised-button (click)="deleteMultiple()"><i
                            class="flaticon-trash"></i> Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>



<ng-template #deleteUser let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">Delete User</h4>
        </div>
        <div class="mat-modal-body">
            <div class="text-left are-u">Are you sure ?</div>
            <div class="row">
                <div class="col-sm-12 action-buttons multi-btns text-right">
                    <button mat-raised-button class="btn-danger" (click)="close()">
                        <i class="flaticon-cancel"></i> Cancel</button>
                    <button class="btn-primary" color="primary" mat-raised-button (click)="deleteAsset()"><i
                            class="flaticon-trash"></i> Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #FilterForm let-modal>
    <div class="mat-modal-header">
        <h4 class="mat-title-modal">Filter Assets</h4>
    </div>
    <div class="mat-modal-body">
        <form class="form-material full-width-inputs" action="javascript:" [formGroup]="filterForm">
            <div class="row form-group">


                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <mat-label class="col-form-label"> Asset Id </mat-label>
                        <input placeholder="Type for hints .." class="form-control" type="text" aria-label="Asset Id"
                            matInput formControlName="assetId" [matAutocomplete]="auto2">
                        <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayAssetByName.bind(this)">
                            <mat-option *ngFor="let r of filteredOptions " [value]="r">
                                {{r}}
                            </mat-option>
                        </mat-autocomplete>

                    </mat-form-field>
                    <mat-error *ngIf="!this.correct1" class="error-messages">
                        Invalid Input
                    </mat-error>
                </div>

                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <mat-label class="col-form-label"> Serial Number </mat-label>
                        <input placeholder="Type for hints .." class="form-control" type="text"
                            aria-label="Serial Number" matInput formControlName="serialNumber" [matAutocomplete]="auto1"
                            matAutocompletePosition="below">
                        <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displaySerialByName.bind(this)">
                            <mat-option *ngFor="let r of filteredOptions2 " [value]="r">
                                {{r}}
                            </mat-option>
                        </mat-autocomplete>

                    </mat-form-field>
                    <mat-error *ngIf="!this.correct2" class="error-messages">
                        Invalid Input
                    </mat-error>
                </div>

                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <mat-label> Location </mat-label>
                        <mat-select disableOptionCentering class="form-control" formControlName="locationId">
                            <mat-option value="">All</mat-option>
                            <mat-option *ngFor="let r of locationList" [value]="r.id">
                                {{r.location}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>


                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <mat-label> Status </mat-label>
                        <mat-select disableOptionCentering class="form-control" formControlName="status">
                            <mat-option value="">All</mat-option>
                            <mat-option *ngFor="let r of statusList" [value]="r.value">
                                {{r.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>


                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <mat-label class="col-form-label"> Model </mat-label>
                        <input class="form-control" type="text" aria-label="Serial Number" matInput
                            formControlName="model">
                    </mat-form-field>

                </div>


                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <mat-label class="col-form-label"> Category </mat-label>
                        <input placeholder="Type for hints .." class="form-control" type="text" aria-label="Category"
                            matInput formControlName="catId" [matAutocomplete]="auto3">
                        <mat-autocomplete #auto3="matAutocomplete" [displayWith]="displayCategoryByName.bind(this)">
                            <mat-option *ngFor="let r of filteredOptions3 " [value]="r.value">
                                {{r.label}}
                            </mat-option>
                        </mat-autocomplete>

                    </mat-form-field>
                    <mat-error *ngIf="!this.correct3" class="error-messages">
                        Invalid Input
                    </mat-error>
                </div>
                <div class="col-sm-6">
                    <h3>Assigned To:</h3>
                </div>
                <div class="col-sm-6">

                </div>


                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <mat-label class="col-form-label"> Subordinate of </mat-label>
                        <input placeholder="Type for hints .." class="form-control" type="text"
                            aria-label="Subordinate of" matInput formControlName="subordinateOfId"
                            [matAutocomplete]="auto4" matAutocompletePosition="below">
                        <mat-autocomplete #auto4="matAutocomplete" [displayWith]="displaySubByName.bind(this)">
                            <mat-option *ngFor="let r of filteredOptions4 " [value]="r.value">
                                {{r.label}}
                            </mat-option>
                        </mat-autocomplete>

                    </mat-form-field>
                    <mat-error *ngIf="!this.correct4" class="error-messages">
                        Invalid Input
                    </mat-error>
                </div>

                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <mat-label class="col-form-label"> Employee </mat-label>
                        <input placeholder="Type for hints .." class="form-control" type="text" aria-label="Employee"
                            matInput formControlName="employeeId" [matAutocomplete]="auto5"
                            matAutocompletePosition="below">
                        <mat-autocomplete #auto5="matAutocomplete" [displayWith]="displayEmployeeByName.bind(this)">
                            <mat-option *ngFor="let r of filteredOptions5 " [value]="r.value">
                                {{r.label}}
                            </mat-option>
                        </mat-autocomplete>

                    </mat-form-field>
                    <mat-error *ngIf="!this.correct5" class="error-messages">
                        Invalid Input
                    </mat-error>
                </div>

            </div>


            <!-- <div class="col-sm-6">
                    <mat-form-field class="form-element">
                      <mat-label> Serial Number </mat-label>
                      <mat-select disableOptionCentering class="form-control" formControlName="subUnitId">
                          <mat-option value="" >All</mat-option>
                          <mat-option *ngFor="let r of temporaryDepartment" [value]="r.id">
                          {{r.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                      <mat-label> Location </mat-label>
                      <mat-select disableOptionCentering class="form-control" formControlName="locationId">
                          <mat-option value="" >All</mat-option>
                          <mat-option *ngFor="let r of locationList" [value]="r.id">
                          {{r.location}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                </div>
            
                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <mat-label class="col-form-label"> Employee </mat-label>
                        <input placeholder="Type for hints .." class="form-control" type="text"
                        aria-label="Employee"
                        matInput
                        formControlName="employeeId"
                        [matAutocomplete]="auto2">
                     <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayEmployeeByName.bind(this)">
                       <mat-option *ngFor="let r of filteredOptions " [value]="r.value">
                     {{r.label}}
                      </mat-option>
                     </mat-autocomplete>
                   
                    </mat-form-field>
                    <mat-error *ngIf="!this.correct" class="error-messages">
                        Please select the employee from the dropdown
                      </mat-error>
                </div> -->



            <div class="action-buttons multi-btns col-sm-12 text-right">
                <button (click)="resetFilter()" mat-raised-button class="btn-danger reset-btn">
                    <i class="flaticon-refresh"></i>Reset</button>
                <button (click)="close()" mat-raised-button class="btn-danger">
                    <i class="flaticon-cancel"></i>Cancel</button>
                <button
                    [disabled]="!this.correct1 || !this.correct2 || !this.correct3 || !this.correct4 || !this.correct5"
                    (click)="filterAsset()" class="btn btn-primary" color="primary" mat-raised-button><i
                        class="flaticon-magnifiying-glass"></i>Search
                </button>
            </div>


        </form>
    </div>
    <!-- </div> -->
</ng-template>