import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { DashboardService } from "../../../dashboard.service";
import { NotificationService } from "src/app/core/services/notification.service";
import {
  CalendarOptions,
  EventClickArg,
  FullCalendarComponent,
} from "@fullcalendar/angular";

import { DatePipe } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { COMMA, ENTER } from "@angular/cdk/keycodes";

@Component({
  selector: "app-leave-calendar",
  templateUrl: "./leave-calendar.component.html",
  styleUrls: ["./leave-calendar.component.scss"],
})
export class LeaveCalendarComponent implements OnInit {
  public dialogRef: any;
  @ViewChild("legendsDialog", { static: false }) legendsDialog;
  @ViewChild("leaveDetailsDialog", { static: false }) leaveDetailsDialog;
  public filterForm: FormGroup;
  public employeeList = [];
  public temporaryDepartment: Array<any>;
  public EmployeeStatus: Array<any>;
  public leaveTypes: Array<any>;
  public locations: Array<any>;
  public holidays: Array<any>;
  public correct = false;
  public err = "";
  public isLoading = false;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  selectable = true;
  removable = true;
  addOnBlur = true;
  employees: Array<any> = [];

  
  public chipList: any = {
    empId: [],
    subUnitId: [],
    leaveTypeId: [],
    leaveStatus: [],
  };

  @ViewChild("calendar", { static: false }) calendarComponent: FullCalendarComponent;

  calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: "dayGridMonth,listMonth",
      center: "title",
      right: "prevButton,nextButton"
    },
    customButtons: {
      prevButton: {
        text: "prev",
        hint : "Previous month",
        icon : "chevron-left",
        click: () => this.prevMonth(),
      },
      nextButton: {
        text: "next",
        hint : "Next month",
        icon : "chevron-right",
        click: () => this.nextMonth(),
      },
    },
    initialView: "dayGridMonth",
    // events: events,
    weekends: true,
    editable: false,
    selectable: false,
    selectMirror: false,
    dayMaxEvents: true,
    eventClick: this.handleEventClick.bind(this),
  };

  currentDate: Date;

  constructor(
    public dialog: MatDialog,
    private service: DashboardService,
    private fb: FormBuilder,
    private toastr: NotificationService,
    public datepipe: DatePipe
  ) {
    this.createForm();
  }

  public createForm(): void {
    this.filterForm = this.fb.group({
      employeeArr: [],
      includePastEmployees: [false],
      year: null,
      empId: [[]],
      makeDefaultFilter: [false],
      hideLeavesOfSupervisors: [false],
      subUnitId: [[]],
      month: [null],
      leaveTypeId: [[]],
      employementStatus: [""],
      locationId: [""],
      leaveStatus: [],
    });
  }

  ngOnInit() {
    this.service.getHolidayList(null, null).subscribe((resp) => {
      if (resp && resp.data) {
        this.holidays = resp.data;
      }
    });

    this.service.getStructureList().subscribe((resp) => {
      if (resp && resp.data) {
        this.temporaryDepartment = resp.data;
      }
    });

    this.service.getLeaveTypeList().subscribe((resp) => {
      if (resp && resp.data) {
        this.leaveTypes = resp.data;
      }
    });

    this.service.getEmployeeStatusList().subscribe((resp) => {
      if (resp && resp.data) {
        this.EmployeeStatus = resp.data;
      }
    });

    this.service.getLocationList().subscribe((resp) => {
      if (resp && resp.data) {
        this.locations = resp.data;
      }
    });

    this.service.getCalandarFilter("M").subscribe((res) => {
      if (res && res.data) {
        if (res?.data?.makeDefaultFilter) {
          // let result = [];
          // result = this.employeeList?.filter((item) => res.data.empId?.includes(item.id));
          // this.chipList["empId"] = result;
          // result = this.temporaryDepartment?.filter((item) => res.data.subUnitId?.includes(item.id));
          // this.chipList["subUnitId"] = result;
          // result = this.leaveTypes?.filter((item) => res.data.leaveTypeId?.includes(item.id));
          // this.chipList["leaveTypeId"] = result;
          // result = this.leaveStatus?.filter((item) => res.data.leaveTypeId?.includes(item.id));
          // this.chipList["leaveStatus"] = result;

          // this.filterForm.patchValue(res.data);
          // this.filterForm.patchValue({ makeDefaultFilter: false });
        }
      }
    });

    this.currentDate = new Date();
    this.viewCalendar("curr");
  }

  viewCalendar(move) {
    this.isLoading = true;

    // var date = new Date(),
    //   y = this.filterForm.value.year || date.getFullYear(),
    //   m = this.filterForm.value.month || date.getMonth();
    // var firstDay = new Date(y, m, 1);
    // var lastDay = new Date(y, m + 1, 0);

    var employeesIds = [];
    this.employees?.map((value) => {
      employeesIds.push(value.id);
    });
    this.filterForm.patchValue({
      empId: employeesIds,
    });
    let events = [];
    this.service
      .getLeaveCalendar({
        ...this.filterForm.value,
        currentDate: this.currentDate,
        mod: "M",
      })
      .subscribe(
        (res) => {
          if (res && res.data) {
            this.isLoading = false;
            
            res.data.map((d) => {
              var endDate = new Date(d.endTime);
              if (d.fullDay) {
                endDate.setDate(endDate.getDate() + 1);
              }

              const obj = {
                title: `${d?.empName} / ${d?.leaveStatus}`,
                allDay: d?.fullDay,
                start: new Date(d?.startTime),
                end: endDate,
                color: d?.color,
                // borderColor : "red",
                data: d,
              };
              events.push(obj);
            });

            this.holidays.forEach((h) => {
              var endDate = new Date(h.holidaydate);
              endDate.setDate(endDate.getDate() + 1);
              const obj = {
                title: h?.holiday,
                start: new Date(h?.holidaydate),
                allDay: true,
                display: "background",
                overlap: true,
                color: "#ff9f89",
              };
              events.push(obj);
            });

            // let dataOptions: CalendarOptions = {
            //   headerToolbar: {
            //     left: "dayGridMonth,timeGridWeek,listMonth",
            //     center: "title",
            //     right: "prevButton,nextButton"
            //   },
            //   customButtons: {
            //     prevButton: {
            //       text: "prev",
            //       hint : "PPPPP",
            //       icon : "chevron-left",
            //       click: () => this.prevMonth(),
            //     },
            //     nextButton: {
            //       text: "next",
            //       click: () => this.nextMonth(),
            //     },
            //   },
            //   initialView: "dayGridMonth",
            //   events: events,
            //   weekends: true,
            //   editable: false,
            //   selectable: false,
            //   selectMirror: false,
            //   dayMaxEvents: true,
            //   eventClick: this.handleEventClick.bind(this),
            // };

            this.calendarOptions.events = events;
            if(move === 'next'){
              this.calendarComponent.getApi().next();
            }else if(move === 'prev'){
              this.calendarComponent.getApi().prev();
            }

            // this.isNotShow = false;
          }
        },
        (error) => {
          const _err = error.error && error.error.msg;
          this.err = "Error while adding Calender";
          this.toastr.error(_err || this.err);
          this.isLoading = false;
          //   this.isNotShow = false;
        }
      );
  }

  nextMonth(): void {
    this.currentDate.setMonth(this.currentDate.getMonth() + 1);
    this.viewCalendar("next");
  }

  prevMonth(): void {
    this.currentDate.setMonth(this.currentDate.getMonth() - 1);
    this.viewCalendar("prev");
  }

  public rest() {
    this.filterForm.reset();
    this.filterForm = this.fb.group({
      includePastEmployees: false,
      year: null,
      empId: [[]],
      makeDefaultFilter: true,
      hideLeavesOfSupervisors: false,
      subUnitId: [[]],
      month: null,
      leaveTypeId: [],
      employementStatus: "",
      locationId: "",
      leaveStatus: [],
    });

    this.currentDate = new Date();
    this.viewCalendar("curr");

    this.filterForm = this.fb.group({
      includePastEmployees: false,
      year: null,
      empId: [[]],
      makeDefaultFilter: false,
      hideLeavesOfSupervisors: false,
      subUnitId: [[]],
      month: null,
      leaveTypeId: [],
      employementStatus: "",
      locationId: "",
      leaveStatus: [],
    });
  }

  public allLeaveStatus = [
    { text: "Cancelled", id: "Cancelled" },
    { text: "Pending", id: "Pending" },
    { text: "Scheduled", id: "Scheduled" },
    { text: "Taken", id: "Taken" },
    { text: "Rejected", id: "Rejected" },
  ];

  dataEvent: any = {
    data: {},
  };

  handleEventClick(clickInfo: EventClickArg) {
    this.dataEvent = clickInfo.event.extendedProps;
    if (this.dataEvent.data.display == "background")
      // holiday
      return;

    this.dialogRef = this.dialog.open(this.leaveDetailsDialog, {
      width: "420px",
      height: "auto",
    });
  }

  showLeaveDetails() {
    this.dialogRef = this.dialog.open(this.legendsDialog, {
      width: "420px",
      height: "auto",
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public saveLoading: boolean = false;

  public save(): void {
    this.saveLoading = true;
    this.service.changeLegend(this.leaveTypes).subscribe(
      (res) => {
        if (res && res.data) {
          this.dialogRef.close();
          this.saveLoading = false;
          this.toastr.success("Legends color updated sucessfully.");
        }
      },
      (error) => {
        const _err = error.error && error.error.msg;
        this.err = "Error while changing Legend";
        this.toastr.error(_err || this.err);
        this.saveLoading = false;
        // this.isLoading = false;
        // this.isNotShow = true;
      }
    );
  }

  public employeesSelected(event) {
    this.employees = event;
  }
}
