<div id="header-page" class="ng-star-inserted header-page">
    <div class="page-header ng-star-inserted">
        <div class="page-block ng-star-inserted">
        <div class="row align-items-center">
            <div class="col-md-8">
            <div class="page-header-title">
                <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted" > {{typeName}} </h4></span>
            </div>
            </div>
            <div class="col-md-4"></div>
        </div>
        </div>
    </div>
</div>
<div class="page-body no-title ">
    <div class="row">
        <div class="col-sm-12">
            <div class="row top-tools">
                <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6 left-tools">
                    <span class="export-icon-btn csv" (click)="exportToCsv()"><i class="material-icons">get_app</i><span>{{lang.get("csv")}}</span>
                    </span>
                    <span class="export-icon-btn pdf" (click)="saveNewsAsPdf()"><i class="material-icons">get_app</i><span>{{lang.get("pdf")}}</span>
                    </span>   
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
                    <div class="relative-position search-filter-con"> 
                        <!-- <span class="icon material-icons ng-tns-c114-0"> search </span>
                        <mat-form-field>
                          <input matInput #inputSearch type="search" [(ngModel)]="filterQuery" maxlength="40"
                          (ngModelChange)="onModelChange(filterQuery)"
                          class="form-control input-sm full-data-search">    
                      </mat-form-field> -->
                        <span [hidden]="!write" class="mytooltip cus-left tooltip-effect-4">
                            <span class="tooltip-item">
                                <button mat-mini-fab class="btn btn-primary light add-rounded margin-button-05" (click)="addNews()">
                                    <i class="fa fa-plus"></i>
                                </button>
                            </span>
                            <span class="tooltip-content clearfix">
                            <span class="tooltip-text">{{lang.get("new")}}</span>
                            </span>
                        </span>
                        <span class="mytooltip cus-left tooltip-effect-4">
                            <span class="tooltip-item">
                                <button mat-mini-fab class="btn btn-primary light add-rounded margin-button-05" (click)="filterNewsAndDocument()">
                                    <i class="fa fa-filter"></i>
                                </button>
                            </span>
                            <span class="tooltip-content clearfix">
                            <span class="tooltip-text">{{lang.get("new")}}</span>
                            </span>
                        </span>

                    </div>
                </div>
            </div>
            <app-card [title]="typeName">
                <div class="dt-responsive" *ngIf="dataSource">
                    <div class="table-responsive">
                        <mat-card-content>
                            <mat-table [dataSource]="dataSource" matSort >
                                <ng-container matColumnDef="select">
                                    <mat-header-cell [hidden]="!delete" *matHeaderCellDef>
                                        <button mat-icon-button [matMenuTriggerFor]="menu">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                        <mat-menu #menu="matMenu">
                                            <button mat-menu-item (click)="onConfigureClicked()">{{lang.get("configure")}}</button>
                                            <button mat-menu-item (click)="onSelectAllClicked()">{{lang.get("select all")}} </button>
                                            <button mat-menu-item *ngIf="this.selectedNewsDocs.length > 0"
                                                    (click)="onArchiveClicked()">{{lang.get("archive")}}
                                            </button>
                                            <button mat-menu-item *ngIf="this.selectedNewsDocs.length > 0"
                                                    (click)="onDeSelectAllClicked()">{{lang.get("deselect all")}} 
                                            </button>
                                            <button mat-menu-item *ngIf="this.selectedNewsDocs.length > 0"
                                                    (click)="deleteRow()">{{lang.get("delete selected")}} 
                                            </button>
                                        </mat-menu>
                                    </mat-header-cell>
                                    <mat-cell [hidden]="!delete" *matCellDef="let row">
                                        <mat-checkbox #matCheckbox value="{{row.id}}" (change)="onNewDocSelectChange($event, row.id)"></mat-checkbox>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="topic">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{lang.get("topic")}}</mat-header-cell>
                                    <mat-cell  (click)="editNews(row) "  class="{{this.editClass}}" *matCellDef="let row">
                                            {{row.topic}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="publishDate">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{lang.get("publishDate")}} </mat-header-cell>
                                    <mat-cell  (click)="editNews(row) "  class="{{this.editClass}}" *matCellDef="let row" style="font-size: 12px">
                                        {{row.publishDate | date : 'dd-MM-yyyy'}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="category">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{lang.get("category")}}</mat-header-cell>
                                    <mat-cell  (click)="editNews(row) " *matCellDef="let row" class="{{this.editClass}}" style="font-size: 12px">
                                        {{row.category}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="published">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{lang.get("status")}}</mat-header-cell>
                                    <mat-cell  (click)="editNews(row) " class="{{this.editClass}}" *matCellDef="let row" style="font-size: 12px">
                                        {{row.published}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="ptLocations">
                                    <mat-header-cell *matHeaderCellDef>{{lang.get("publishedLocations")}} </mat-header-cell>
                                    <mat-cell  (click)="editNews(row) " class="{{this.editClass}}" *matCellDef="let row" style="font-size: 12px">
                                        {{row.ptLocations}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="ptUserRoles">
                                    <mat-header-cell *matHeaderCellDef>{{lang.get("publishedUserRoles")}}  </mat-header-cell>
                                    <mat-cell  (click)="editNews(row) " class="{{this.editClass}}" *matCellDef="let row" style="font-size: 12px">
                                        {{row.ptUserRoles}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="ptJobTitles">
                                    <mat-header-cell *matHeaderCellDef>{{lang.get("publishedJobTitles")}}</mat-header-cell>
                                    <mat-cell  (click)="editNews(row) " class="{{this.editClass}}" *matCellDef="let row" style="font-size: 12px">
                                        {{row.ptJobTitles ? row.ptJobTitles : ''}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="ptSubUnits">
                                    <mat-header-cell *matHeaderCellDef>{{lang.get("publishedSubunits")}}</mat-header-cell>
                                    <mat-cell  (click)="editNews(row) " class="{{this.editClass}}" *matCellDef="let row" style="font-size: 12px">
                                        {{row.ptSubUnits ? row.ptSubUnits : ''}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="ptEmployees">
                                    <mat-header-cell *matHeaderCellDef>{{lang.get("publishedEmployees")}}</mat-header-cell>
                                    <mat-cell  (click)="editNews(row) " class="{{this.editClass}}" *matCellDef="let row" style="font-size: 12px">
                                        {{row.ptEmployees ? row.ptEmployees : ''}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="attachments">
                                    <mat-header-cell *matHeaderCellDef >{{lang.get("attachments")}}</mat-header-cell>
                                    <mat-cell  class="cursor-pointer" *matCellDef="let row">
                                        {{row.attachments}}

                                        <span class="mytooltip tooltip-effect-4" *ngIf="!row.hasAttachment">
                                            <span class="tooltip-item"><i class="single-icon-click flaticon-attachment" style="color: #d0cdcd" (click)="onAddAttachment(row.id)"></i></span>
                                            <span class="tooltip-content clearfix">
                                            <span class="tooltip-text">{{lang.get("noAttachment")}}</span>
                                            </span>
                                          </span>
                                          
                                          <span class="mytooltip tooltip-effect-4" *ngIf="row.hasAttachment">
                                            <span class="tooltip-item"><i class="single-icon-click flaticon-attachment" style="color: #666" (click)="onAddAttachment(row.id)"></i></span>
                                            <span class="tooltip-content clearfix">
                                            <span class="tooltip-text">{{lang.get("includeAttachment")}}</span>
                                            </span>
                                          </span>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="ptEmployeeStatus">
                                    <mat-header-cell *matHeaderCellDef >{{lang.get("employmentStatus")}}</mat-header-cell>
                                    <mat-cell (click)="editNews(row)" class="cursor-pointer" *matCellDef="let row" style="font-size: 12px">
                                        {{row.ptEmployeeStatus ? row.ptEmployeeStatus : ''}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="AcknowledgmentStatus">
                                    <mat-header-cell *matHeaderCellDef >{{lang.get("ackStatus")}}</mat-header-cell>
                                    <mat-cell *matCellDef="let row">
            
                                        <span class="mytooltip tooltip-effect-4" *ngIf="!row.acknowledgement">
                                            <span class="tooltip-item"><i (click)="viewAcknowledgement(row)" class="material-icons" style="color: #d0cdcd"
                                                >playlist_add_check</i></span>
                                            <span class="tooltip-content clearfix">
                                            <span class="tooltip-text">No Records</span>
                                            </span>
                                          </span>
                                          
                                          <span class="mytooltip tooltip-effect-4" *ngIf="row.acknowledgement">
                                            <span class="tooltip-item">  <i (click)="viewAcknowledgement(row)" class="material-icons" style="color: #666"
                                                >playlist_add_check</i></span>
                                            <span class="tooltip-content clearfix">
                                            <span class="tooltip-text">Has Records</span>
                                            </span>
                                          </span>
                                     </mat-cell>
                                </ng-container>
                                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: columns;">
                                </mat-row>
                            </mat-table>
                            <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
                            <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                        </mat-card-content>
                    </div>
                </div>
            </app-card>
        </div>
    </div>
</div>

<ng-template #attach let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
          <h4 class="mat-title-modal">{{lang.get("attachment")}}</h4>
        </div>
        <div class="mat-modal-body">
    <!-- <div class="card my-0">
        <div class="header">
            <h4 class="card-title card-title-modal">
                Attachment
            </h4>
        </div>
        <div class="card-content"> -->
            <app-loading *ngIf="this.loadAttach" [isLoading]="loadAttach"></app-loading>
            <form  *ngIf="!loadAttach" class="form-material full-width-inputs">
                <div class="row">
                    <div class="col-md-3">
                        <div class="upload-btn-wrapper">
                            <button class="upload-btn"><i class="flaticon-attachment"></i> {{lang.get("attach")}}</button>
                            <input type="file" name="myfile" class="form-control" (change)="fileChange($event, 'dd')">
                            <div class="fileName">{{this.fileObj && this.fileObj.fileName}}
                                <i *ngIf="fileUrl" class="fa fa-check success"></i>
                            </div>
                        </div>
                        <div class="upload-btn-wrapper">
                        </div>
                        <div class="file-error text-danger" *ngIf="!fileUrl && fileError">{{fileError}}</div>
                        
                    </div>
                    <div class="help-text">{{lang.get("accepteUpTO2MB")}}</div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field class="form-element">
                            <mat-label>{{lang.get("comment")}}</mat-label>
                            <textarea matInput name="name"  [(ngModel)]="comment" class="form-control"></textarea>
                        </mat-form-field>
                    </div>
                    <!-- <div class="col-sm-12 mt-4">
                        <textarea name="name" rows="2" [(ngModel)]="comment" class="form-control"></textarea>
                        <label class="float-label">Comment</label>
                    </div> -->
                </div>
                <div class="row"> 
                    <div class="col-md-12 border" style="height: 200px !important; overflow-y: auto">
                        <mat-spinner *ngIf="this.showSpinner"></mat-spinner>
                        <mat-card *ngIf="showSpinner == false && this.attachmentList.length === 0">
                            {{lang.get("noAttachmentFound")}}
                        </mat-card>
                        <ng-container *ngFor="let attachment of attachmentList; let i = index;">
                            <mat-card class="mt-2 shadow-sm">
                                <mat-card-title>
                                    {{attachment.fileName}}
                                </mat-card-title>
                                <mat-card-content>
                                    <b>{{lang.get("addedOn")}}:</b> {{attachment.added}} <br>
                                    <b>{{lang.get("comments")}}:</b> {{attachment.desc}}
                                </mat-card-content>
                                <mat-card-actions align="end">
                                    <button (click)="onDeleteClicked(attachment)" mat-icon-button
                                            aria-label="Example icon button with a vertical three dot icon">
                                        <mat-icon color="warn">{{lang.get("delete")}}</mat-icon>
                                    </button>
                                    <button (click)="onDownloadClicked(attachment)" mat-icon-button
                                            aria-label="Example icon button with a vertical three dot icon">
                                        <mat-icon color="primary">cloud_download</mat-icon>
                                    </button>
                                </mat-card-actions>
                            </mat-card>
                        </ng-container>
                    </div>
                </div>
                <br>
                <div class="row">
                        <div class="action-buttons col-md-12 text-right">
                            <button mat-raised-button class="btn-primary"(click)="saveAttachment()" color="primary"><i class="flaticon-diskette"></i>Save</button>
                        </div>
                    <!-- <div class="col-sm-12">
                        <button type="button" name="button" class="btn btn-primary" (click)="saveAttachment()">Save
                        </button>
                    </div> -->
                    </div>  
            </form>
        </div>
    </div>
</ng-template>

<ng-template #ack let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
          <h4 class="mat-title-modal">Document Acknowledgement Status</h4>
        </div>
        <div class="mat-modal-body">
    <!-- <div class="card my-0">
        <div class="header">
            <h4 class="card-title card-title-modal">
                Document Acknowledgement Status
            </h4>
        </div> -->
        <!-- <div class="card-content"> -->
            <mat-card>
                <mat-card-content>
                    <mat-table  [dataSource]="this.acknowledgementList" matSort>
                        <ng-container matColumnDef="employeeName">
                            <mat-header-cell *matHeaderCellDef>Employee Name</mat-header-cell>
                            <mat-cell *matCellDef="let element">{{element.empName}}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="acknowledged">
                            <mat-header-cell *matHeaderCellDef>Acknowledged</mat-header-cell>
                            <mat-cell *matCellDef="let element">{{element.date != null ? 'yes':'no' }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="dateAcknowledged">
                            <mat-header-cell *matHeaderCellDef>Date Acknowledged</mat-header-cell>
                            <mat-cell *matCellDef="let element">{{element.date | date : 'dd-MM-yyyy' }}</mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedAckColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedAckColumns"></mat-row>
                    </mat-table>
                </mat-card-content>
            </mat-card>
                <!-- <mat-card-actions align ="end"> -->
            <br>
            <div class="row">
                <div class="action-buttons multi-btns col-md-12 text-right">
                    <button (click)="saveAsCsv()" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
                        <mat-icon color="warn">picture_as_pdf</mat-icon>
                    </button>
                    <button (click)="saveAsPdf()" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
                        <mat-icon color="primary">picture_as_pdf</mat-icon>
                    </button>
                </div>
            </div>
                <!-- </mat-card-actions> -->
          
        <!-- </div> -->
    </div>
</div>
</ng-template>

<ng-template #deleteUser let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
          <h4 class="mat-title-modal">Delete Company</h4>
        </div>
        <div class="mat-modal-body">
    <!-- <div class="card my-0">
        <div class="header">
            <h4 class="card-title card-title-modal">Delete Company</h4>
        </div>
        <div class="card-content px-2 py-2"> -->
            <div class="text-left are-u">Are you sure ?</div>
            <div class="row">
                <div class="col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i> Cancel</button>
                    <button (click)="onDeleteAllClicked()" class="btn-primary" color="primary" mat-raised-button><i
                            class="flaticon-trash"></i> Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #archive let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
          <h4 class="mat-title-modal">Archive</h4>
        </div>
        <div class="mat-modal-body">
    <!-- <div class="card my-0">
        <div class="header">
            <h4 class="card-title card-title-modal">Archive</h4>
        </div>
        <div class="card-content px-2 py-2"> -->
            <div class="text-left are-u">Are you sure ?</div>
            <div class="row">
                <div class="action-buttons multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i>Cancel</button>
                    <button mat-raised-button class="btn-primary" (click)="archiveNewsAndDocuments()" color="primary" ><i class="flaticon-open"></i>Archive</button>
                </div>
                    <!-- <div class="col-sm-12 text-right">
                    <button type="submit" class="btn btn-cancel btn-danger" (click)="close()">Cancel</button>
                    <button type="submit" class="btn btn-save btn-primary" (click)="archiveNewsAndDocuments()">Archive</button>
                </div> -->
            </div>
        </div>
    </div>
</ng-template>

<ng-template #configure >
  <div mat-dialog-actions>
        <div class="mat-modal-header">
          <h4 class="mat-title-modal">Configure</h4>
        </div>
        <div class="mat-modal-body">

    <!-- <h2 matDialogTitle>Configure</h2> -->
    <mat-dialog-content>
            <form [formGroup]="configureForm">
                <div class="row">
                    <ng-container *ngFor="let cd of columnDefinitions; let i = index">
                        <div [hidden]="cd.hidden" *ngIf="i != 0 && i != 13" class="col-md-4">
                            <mat-checkbox [formControlName]="cd.def">{{cd.label}}</mat-checkbox>
                        </div>
                    </ng-container>
                </div>
            </form>
    </mat-dialog-content>
  </div>
  </div>
</ng-template>

<ng-template #filterNews let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
          <h4 class="mat-title-modal">Filter {{this.typeName}}</h4>
        </div>
        <div class="mat-modal-body">
    <!-- <div class="card my-0">
        <div class="header">
            <h4 class="card-title card-title-modal">Filter {{this.typeName}}</h4>
        </div>
        <div class="card-content"> -->
            <form [formGroup]="filterNewsForm" action="javascript:" class="full-width-inputs form-material">
                <div class="row form-group">
                    <div class="col-md-6">
                        <mat-form-field>
                        <mat-label for="title">Topic</mat-label>
                        <input matInput class="form-control" formControlName="topic" id="title" maxlength="40"
                                type="text">
                        <!-- <span class="form-bar"></span> -->
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <!-- <mat-form-field class="percent-width-fuoptionSelectedll"> -->
                        <mat-form-field>
                            <input matInput class="form-control" [matDatepicker]="picker" formControlName="publishDate" 
                                   placeholder="Published Date">
                            <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-md-6">
                        <mat-form-field class="form-element">
                            <mat-label>Status</mat-label>
                            <mat-select disableOptionCentering class="form-control" formControlName="status">
                                <mat-option value="" >All</mat-option>
                                <mat-option value="pu">Published</mat-option>
                                <mat-option value="sa">Save</mat-option>
                                <mat-option value="ar">Archeive</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field class="form-element">
                            <mat-label for="des">Location</mat-label>
                            <mat-select disableOptionCentering class="form-control"
                                        formControlName="locations" multiple>
                                <ng-container>
                                    <mat-option value="" >All</mat-option>
                                    <mat-option
                                            *ngFor="let location of locationList"
                                            [value]="location.id">{{location.name}}</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="this.type === 'D'" class="form-group form-default">
                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field class="form-element">
                                <mat-select disableOptionCentering class="form-control" formControlName="category">
                                    <mat-option value="all" >All</mat-option>
                                    <ng-container *ngFor="let n of categoryList">
                                        <mat-option value="{{n.id}}">{{n.name}}</mat-option>
                                    </ng-container>
                                </mat-select>
                                <mat-label for="des">Category</mat-label>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="action-buttons multi-btns col-sm-12 text-right">
                        <button mat-raised-button class="btn-primary"(click)="OnFilterReset()" color="primary"><i class="flaticon-refresh"></i>Reset</button>
                        <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i>Cancel</button>
                        <button mat-raised-button class="btn-primary" (click)="Search()" color="primary" ><i class="flaticon-magnifiying-glass"></i>Search</button>
                    </div>
                </div>
                <!-- <div class="mt-4 mb-2">
                    <button (click)="OnFilterReset()" class="btn btn-cancel btn-dribbble">Reset</button>
                    <button (click)="close()" class="btn btn-cancel btn-danger">Cancel</button>
                    <button (click)="Search()" class="btn btn-save btn-primary" type="submit">Search</button>
                </div> -->
            </form>
        </div>
    </div>
</ng-template>
