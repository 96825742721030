<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
      <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
          <div class="col-md-8">
          <div class="page-header-title">
              <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted">Bradford Threshold Factor</h4></span>
          </div>
          </div>
          <div class="col-md-4"></div>
      </div>
      </div>
  </div>
</div>

<div class="page-body no-title">
  <div class="row">
    <div class="col-sm-12">
        <div class="col-sm-12 text-right">
          <i class="fa fa-file-pdf-o mr-2" aria-hidden="true" (click)="getPdf()"></i>
          <i class="fa fa-file" (click)="exportAsCsv()"></i>
<!--            <i class="fa fa-file" (click)="exportBradfactorReport()"></i>-->
        </div>
        <div class="dt-responsive">
          <!-- <form [formGroup]="filterForm" class="d-n-p"> -->
            <form [formGroup]="filterForm" class="form-material full-width-inputs">
              <div class="row form-group">
              <div class="col-sm-6">
                <mat-form-field class="form-element">
                  <mat-label> Employee Name</mat-label>
                  <mat-select formControlName="empIds" class="form-control">
                    <mat-option *ngFor="let n of employeeList" [value]="n.value">
                      {{n.label}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <!-- <label>Employee Name</label>
              <ng-select [ngClass]="'ng-select'" [options]="employeeList" [multiple]="true" formControlName="empIds"></ng-select> -->
              </div>
              <div class="col-sm-6">
                <mat-form-field class="form-element">
                  <mat-label> Location</mat-label>
                  <mat-select formControlName="locationIds" class="form-control">
                     <mat-option *ngFor="let n of locationList" [value]="n.label">{{n.label}}</mat-option>
                  </mat-select>  
                </mat-form-field>
  
               <!-- <label>Location</label>
               <ng-select [ngClass]="'ng-select'" [options]="locationList" [multiple]="true" formControlName="locationIds"></ng-select> -->
              </div>
              <div class="col-sm-6">
                <mat-form-field class="form-element">
                  <mat-label>Category Job</mat-label>
                  <mat-select  class="form-control" formControlName="jobCategoryIds">
                    <mat-option *ngFor="let t of jobCategoryList" value="{{t.label}}">{{t.label}}</mat-option>
                  </mat-select>
               </mat-form-field>
  
                <!-- <label>Job Category</label>
                <ng-select [ngClass]="'ng-select'" [options]="jobCategoryList" [multiple]="true" formControlName="jobCategoryIds"></ng-select> -->
              </div>
              <div class="col-sm-6">
                <mat-form-field class="form-element">
                  <mat-label> Sub Unit</mat-label>
                    <mat-select formControlName="subUnitId" class="form-control">
                      <mat-option *ngFor="let n of subUnitList" [value]="n.label">{{n.label}}</mat-option>
                    </mat-select>  
                </mat-form-field>
  
                <!-- <label>Sub Unit</label>
                <ng-select [ngClass]="'ng-select'" [options]="subUnitList" [multiple]="true" formControlName="subunitIds"></ng-select> -->
              </div>
              <div class="form-group col-sm-6">
                <mat-form-field class="form-element">
                  <mat-label> Job Title</mat-label>
                  <mat-select  class="form-control" formControlName="jobTitleIds">
                    <mat-option *ngFor="let t of jobTitleList" value="{{t.label}}">{{t.label}}</mat-option>
                  </mat-select>
               </mat-form-field>
  
                <!-- <label>Job Title</label>
                <ng-select [ngClass]="'ng-select'" [options]="jobTitleList" [multiple]="true" formControlName="jobTitleIds"></ng-select> -->
              </div>
              <div class="col-sm-6">
                <mat-form-field class="form-element">
                  <mat-label>Supervisor</mat-label>
                  <mat-select  class="form-control" formControlName="supervisorIds">
                    <mat-option *ngFor="let t of supervisorList" value="{{t.label}}">{{t.label}}</mat-option>
                  </mat-select>
               </mat-form-field>
  
                <!-- <label>Supervisor</label>
                <ng-select [ngClass]="'ng-select'" [options]="supervisorList" [multiple]="true" formControlName="supervisorIds"></ng-select> -->
              </div>
              <div class="col-sm-6">
                <mat-form-field class="form-element">
                  <mat-label> Include</mat-label>
                  <mat-select class="form-control" formControlName="include">
                    <mat-option [value]='"past"'>Current and Past Employees</mat-option>
                    <mat-option [value]='"current"'>Curren Employees Only</mat-option>
                    <mat-option [value]='"pastEmp"'>Past Employee only</mat-option>
                  </mat-select>
                </mat-form-field>

                 <!-- <label>Include</label>
                <select formControlName="include" class="form-control">
                  <option value="past">Current and Past Employees</option>
                  <option value="current">Curren Employees Only</option>
                  <option value="pastEmp">Past Employee only</option>
                </select>  -->
              </div>
            </div>
            <div class="text-right">
              <button class="btn btn-primary" (click)="getBFTList()">Generate</button>
            </div>
          </form>
          <div class="row d-n-p">
            <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6">
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6">
               <div class="text-right"> 
                <span class="icon material-icons ng-tns-c114-0"> search </span>
                <mat-form-field class="form-element">
                  <input matInput type="search" #inputSearch [(ngModel)]="filterQuery" maxlength="30" 
                  class="form-control input-sm full-data-search" >
                </mat-form-field>
              </div>
            </div>
          </div>
          <!-- <app-loading *ngIf="isLoading && !err" [isLoading]="isLoading && !err"></app-loading> -->
          <app-card [title]="'Leave Bradford Factor Report'">
            <div class="dt-responsive" *ngIf="dataSource">

          <div class="table-responsive">
            <mat-card-content>
              <mat-table [dataSource]="dataSource" matSort>
                  <ng-container matColumnDef="employee">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>Employee</mat-header-cell>
                      <mat-cell *matCellDef="let row">
                          {{ row.employee }}
                      </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="jobtitle">
                      <mat-header-cell *matHeaderCellDef>Job Title</mat-header-cell>
                      <mat-cell *matCellDef="let row">
                          {{ row.jobtitle}}
                      </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="location">
                      <mat-header-cell *matHeaderCellDef>Location</mat-header-cell>
                      <mat-cell *matCellDef="let row">
                          {{ row.location}}
                      </mat-cell>
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: columns;">
                  </mat-row>
              </mat-table>
              <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
              <mat-paginator [length]="paging.totalRecords" [pageSize]="paging.pageSize"
                             [pageSizeOptions]="paging.pageSizeOptions"
                             [pageIndex]="paging.pageIndex" (page)="pageChange($event)">
              </mat-paginator>
          </mat-card-content>
            
          </div>
          <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
        </div>
      </app-card>
    </div>
  </div>
</div>
