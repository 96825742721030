import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {MatDialog, MatDialogRef} from '@angular/material/dialog'
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth.service';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
    isFiltering = false;
    public validationError = {
        required: 'Required',
        email: 'Please provide a valid email',
        password: 'Please provide a password of atleast 8 digits',
        ess: 'Please provide a valid ESS role',
        supervisor: 'Please provide a valid Supervisor role'
    };

    displayedColumns = ['Select', 'notificationName', 'mailSubject'];
    dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
    @ViewChild(MatSort,{static:false}) sort: MatSort;
    @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
    @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;
    @ViewChild('createNotification',{static:false}) createNotification;
    paging = {
        pageSize: 10,
        totalRecords: 0,
        pageIndex: 0,
        pageSizeOptions: [2, 25, 50, 100]
    };
    isLoading = false;
    form: FormGroup;
    private selectedNotifications = [];
    // private modalRef: NgbModalRef;
    private dialogRef:  any;
    private optionsSendOn = [];
    private optionsNumber = [];
    private optionsJobTitle = [];
    private optionsSubUnit = [];
    private optionsLocation = [];


    constructor( private authService : AuthService,
        private service: DashboardService,
                private dialog:MatDialog,
                private fb: FormBuilder,
                private toastr: NotificationService) {
    }
    public permissions : any;
    public read = false;
    public write = false ;
    public update = false;
    public delete = false;
    public editClass = "";
    checkPermission(module,tabs) : void 
    {
        this.editClass = "";
        this.permissions = JSON.parse(this.authService.getPermissions());

        this.read = false ;
        this.write = false ;
        this.delete = false ;
        this.update = false;
        if(this.permissions != undefined && this.permissions != null )
        {
            for(let p of this.permissions)
            {
               
                if(p.id === module)
                {
                   
                    if(p.modules != undefined)
                    {
                      
                        for(let tab of p.modules)
                        {
                            if(tab.id === tabs)
                            {
                              
                                for(let move of tab.permits)
                                {
                                    if(move.id === "u")
                                    {
                                        this.update = true;
                                     
                                    }
                                    else if(move.id === "r")
                                    {
                                        this.read = true;
                                    }
                                    else if(move.id === "c")
                                    {
                                      
                                        this.write = true;
                                    }
                                    else if(move.id === "d")
                                    {
                                        this.delete = true;
                                    }
                                }
                            }
                        }
                    }
                   
                }
               
            }
        }
        this.editClass = "cursor-pointer";

    }
   
    editUser (id : any ) : void 
    {
        this.isUserAdded = true ;
        this.isEdit = true ;
        this.form = this.fb.group({
            id : '',
            notificationName: ['', Validators.required],
            sendOn: ['all', Validators.required],
            leaveForNext: ['', Validators.required],
            mailSubject: ['', Validators.required],
            sendJobTitle: [''],
            sendSubUnit: [''],
            sendLocation: [''],
            includeJobTitle: [''],
            includeSubUnit: [''],
            includeLocation: [''],
            includeLeaveType : true 
        });
        this.dialogRef = this.dialog.open(this.createNotification,{
            width: '660px',
            autoFocus: false,
            maxHeight: '90vh' 
        });
        // this.service.getMoticationbyIdForLeave(id).subscribe(resp => {
        //     if (resp && resp.data) {
        //         this.form.patchValue(resp.data);
        //         this.isUserAdded = false;
        //     }
        //   }, error => {
        //     this.isUserAdded = false;
        //     const err = error.error && error.error.msg;
        //     this.toastr.error(err || 'Error in getting informations');
        // });
    }
    public errors : string = '';
    getNotications() : void 
    {
        this.isLoading = true ;
        this.errors = '';
        // this.service.getAllNoticationsLeave().subscribe(resp => {
        //     if (resp && resp.data) {
        //         this.errors = ''; 
        //         let test = [] ;
        //          test = resp.data ; 
        //          if(test.length === 0)
        //          {
        //             this.errors = 'No Records Found !'; 
        //          }
        //       this.isLoading = false ;
        //       this.dataSource = new MatTableDataSource<any>(resp.data);
        //       this.dataSource.paginator = this.paginator;
        //       this.dataSource.sort = this.sort;
        //     }
        //   }, error => {
        //       this.errors = 'Error';
        //     this.isLoading = false ;
        //     const err = error.error && error.error.msg;
        //     this.toastr.error(err || 'Error in getting notifcations');
        // });
    }
    ngOnInit() {
        this.checkPermission("leave","le_ccnf");

       this.getNotications();
        this.optionsSendOn = [
   
            {value: 'all', label: 'All'},
            {value: 'mon', label: 'Monday'},
            {value: 'tue', label: 'Tuesday'},
            {value: 'wed', label: 'Wednesday'},
            {value: 'thu', label: 'Thursday'},
            {value: 'fri', label: 'Friday'},
            {value: 'sat', label: 'Saturday'},
            {value: 'sun', label: 'Sunday'}];

        this.optionsNumber = [
            {value: 0, label: '0'},
            {value: 1, label: '1'},
            {value: 2, label: '2'},
            {value: 3, label: '3'},
            {value: 4, label: '4'},
            {value: 5, label: '5'},
            {value: 6, label: '6'},
            {value: 7, label: '7'},
            {value: 8, label: '8'},
            {value: 9, label: '9'},
            {value: 10, label: '10'},
            {value: 11, label: '11'},
            {value: 12, label: '12'},
            {value: 13, label: '13'},
            {value: 14, label: '14'},
            {value: 15, label: '15'},
            {value: 16, label: '16'},
            {value: 17, label: '17'},
            {value: 18, label: '18'},
            {value: 19, label: '19'},
            {value: 20, label: '20'},
            {value: 21, label: '21'},
            {value: 22, label: '22'},
            {value: 23, label: '23'},
            {value: 24, label: '24'},
            {value: 25, label: '25'},
            {value: 26, label: '26'},
            {value: 27, label: '27'},
            {value: 28, label: '28'},
            {value: 29, label: '29'},
            {value: 30, label: '30'}
        ]


      
            this.service.getStructureList().subscribe(resp => {
                if (resp && resp.data) {
                  this.optionsSubUnit = resp.data;
                }
              });

              this.service.getLocationList().subscribe(resp => {
                if (resp && resp.data) {
                  this.optionsLocation = resp.data;
                }
              });
              this.service.getJobTitleList().subscribe(resp => {
                if (resp && resp.data) {
                  this.optionsJobTitle = resp.data;
                }
              });
        

       

        this.form = this.fb.group({
            id : '' ,
            notificationName: ['', Validators.required],
            sendOn: ['', Validators.required],
            leaveForNext: ['', Validators.required],
            mailSubject: ['', Validators.required],
            sendJobTitle: [''],
            sendSubUnit: [''],
            sendLocation: [''],
            includeJobTitle: [''],
            includeSubUnit: [''],
            includeLocation: [''],
            includeLeaveType : true 
        });
    }

    onFilter() {
        this.isFiltering = true;
    }

    reloadData($event) {
    }

    onCheckBoxChange($event: MatCheckboxChange, id: any) {
        if (!this.alreadySelected($event.source) && $event.checked) {
            this.selectedNotifications.push($event.source.value);
        } else if (!$event.checked) {
            this.selectedNotifications = this.selectedNotifications.filter(
                value => {
                    return value !== $event.source.value;
                }
            );
        }
    }

    alreadySelected(item: MatCheckbox): boolean {
        this.selectedNotifications.forEach(
            value => {
                if (item === value) {
                    return true;
                }
            }
        );
        return false;
    }

    pageChange(event) {
        this.paging.pageIndex = event.pageIndex;
        this.paging.pageSize = event.pageSize;
        // this.getCategories(event.pageIndex + 1, event.pageSize);
    }

    onSelectAllClicked() {
        this.selectedNotifications = [];
        this.matCheckBoxes.forEach(
            item => {
                this.selectedNotifications.push(item.value);
                item.checked = true;
            }
        );
    }

    onDeSelectAllClicked() {
        this.selectedNotifications = [];
        this.matCheckBoxes.forEach(
            item => {
                item.checked = false;
            }
        );
    }
    @ViewChild('deleteUser',{static:false}) deleteUser;
    onDeleteSeclted() : void 
    {
        this.dialogRef = this.dialog.open(this.deleteUser, {
            width: '440px',
            height: 'auto'
        });
    }

    onDeleteAllClicked() {
        //  this.service.deleteNoticationsLeave(this.selectedNotifications).subscribe(
        //      response => {
        //          if (response) {
        //              this.selectedNotifications = [];
                  
        //              this.getNotications();
        //              this.toastr.success('Notfcations deleted Successfully');
        //          }
        //      },
        //      error => {
        //         this.selectedNotifications = [];
        //          this.toastr.error(`Error: ${error}`);
        //      }
        //  );
         this.onDeSelectAllClicked();
    }

    public isEdit = false ;
    addNewUser() {
        this.isEdit = false ;
        this.form = this.fb.group({
            id : '' ,
            notificationName: ['', Validators.required],
            sendOn: ['all', Validators.required],
            leaveForNext: ['', Validators.required],
            mailSubject: ['', Validators.required],
            sendJobTitle: [''],
            sendSubUnit: [''],
            sendLocation: [''],
            includeJobTitle: [''],
            includeSubUnit: [''],
            includeLocation: [''],
            includeLeaveType : true 
        });
        this.dialogRef = this.dialog.open(this.createNotification,{
            width: '660px',
            autoFocus: false,
            maxHeight: '90vh' 
        });
    }
    close () : void 
    {
        this.dialogRef.close();
    }


    public isUserAdded = false ;
    submitNotifcation () : void 
    {
        this.isUserAdded = true ;
        // this.service.addNoticationLeave(this.form.value).subscribe(resp => {
        //     if (resp && resp.data) {
             
        //       this.toastr.success('Notication added successfully ..!');
        //       this.getNotications();
        //       this.isUserAdded = false ;
        //       this.close();
        //     }
        //   }, error => {
        //     const err = error.error && error.error.msg;
        //     this.toastr.error(err || 'Error in adding notifcation');
        // });
    }

    updateNotifcation () : void 
    {
        this.isUserAdded = true ;
        // this.service.updateNoticationLeave(this.form.value).subscribe(resp => {
        //     if (resp && resp.data) {
             
        //       this.toastr.success('Notication edited successfully ..!');
        //       this.getNotications();
        //       this.isUserAdded = false ;
        //       this.close();
        //     }
        //   }, error => {
        //     const err = error.error && error.error.msg;
        //     this.toastr.error(err || 'Error in editing notifcation');
        // });
    }
}
