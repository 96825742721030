import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../../dashboard.service';
import { AuthService } from '../../../../core/services/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotificationService } from "src/app/core/services/notification.service";
import * as moment from 'moment';

@Component({
  selector: 'app-leave-period',
  templateUrl: './leave-period.component.html',
  styleUrls: ['./leave-period.component.scss']
})
export class LeavePeriodComponent implements OnInit {
  public leaveForm: FormGroup;
  public leavePeriodList : Array<any> = [];
  public leavePeriod = [
   
  ];
  public month = [
    {label: 'January', value: "1"},
    {label: 'Febraury', value: "2"},
    {label: 'March', value: "3"},
    {label: 'April', value: "4"},
    {label: 'May', value: "5"},
    {label: 'June', value: "6"},
    {label: 'July', value: "7"},
    {label: 'August', value: "8"},
    {label: 'September', value: "9"},
    {label: 'Octomber', value: "10"},
    {label: 'November', value: "11"},
    {label: 'December', value: "12"},
  ];
  public days: Array<any> = [];
  constructor(private service: DashboardService, private fb: FormBuilder,
    private toastr: NotificationService, private readonly authService: AuthService) {
    this.createForm();
    }

  ngOnInit() {


    this.service.getCurrentLeavePeriod().subscribe(resp => {
      if (resp && resp.data) {
      
        this.leaveForm.patchValue(resp.data); 
        this.leaveForm.patchValue({
          currentenddate :  moment(resp.data.currentenddate).format('MMMM-DD') 
        })
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error in getting leave periods');
    });
  }
    public createForm(): void {
      const date = `${moment().year()}-01-01 to ${moment().year()}-12-31`;
      this.leaveForm = this.fb.group({
      cmbStartdate: ['',Validators.required],
      cmbStartmonth: ['',Validators.required],
      cmbEnd: [''],
      currentenddate: '',
      currentleaveperiod: [''],
      currentstartdate: [moment().startOf('year').toDate()],
      editable: [true],
      leaveperioddefined: [true],
      nextenddate: [''],
      nextstartdate: [''],
      status: ['ACTIVE']
    });
  }
  get f() { return this.leaveForm.controls; }

  public submitLeave(): void {
    if(this.leaveForm.value.cmbStartmonth!='' && this.leaveForm.value.cmbStartmonth!='')
    {
    const val = this.leaveForm.value;
    this.service.addLeavePeriod(val).subscribe(resp => {
      if (resp && resp.data) {
        this.toastr.success('Leave Period assigned successfully');
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error in assigning Leave Period');
    });
  }
  else
  {
// this.leaveForm.markAsTouched();
// this.leaveForm.markAsDirty();
this.markFormGroupTouched(this.leaveForm);
  }
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  isLeapYear(year) { 
    return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0)); 
}

getDaysInMonth(year, month) {
    return [31, (this.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
}

addMonths(date, value) {
    var d = new Date(date),
        n = date.getDate();
    d.setDate(1);
    d.setMonth(d.getMonth() + value);
    d.setDate(Math.max(n, this.getDaysInMonth(d.getFullYear(), d.getMonth())));
    return d;
}
  
  getLeavePeriod(event) {
    var month = event.target.value.toString();
    if (month.length == 1) {
      month = `0${month}`
    }
    var date = `${moment().year()}-${month}-01`;
    var date1=new Date(date);
    var nextYear= this.addMonths(date1, 11);
    var year=moment(new Date(nextYear)).format('YYYY-MM-DD');
    date=`${moment().year()}-${month}-01 to ${year}`
    this.leaveForm.patchValue({"currentleaveperiod":date,"cmbStartdate":`${moment().year()}-${month}-01`,"cmbEnd":year,"currentstartdate":`${moment().year()}-${month}-01`,"currentenddate":year});
   
  }
  public getDays($event): void {
    this.days = [];
    const date = `${moment().year()}-${$event.target.value}`;
    const noOfDays = moment(date, 'YYYY-MM').daysInMonth();
    for (let i = 1; i <= noOfDays; i++) {
      if (i.toString().length > 1) {
        this.days.push(i);
      } else {
        const j = '0' + i;
        this.days.push(j);
      }
    }

    const val = this.leaveForm.value;
    let nextDate;
    if (this.leaveForm.value.cmbStartmonth && this.leaveForm.value.cmbStartdate) {
      const _date = `${moment().year()}-${parseInt(this.leaveForm.value.cmbStartmonth, 10)}-${this.leaveForm.value.cmbStartdate}`;
      nextDate = moment(_date).add(1, 'years').subtract(1, 'days');
      const endMonth = moment(date).add(1, 'years').subtract(1, 'days').month();
      const endDate = moment(date).add(1, 'years').subtract(1, 'days').date();
      const month = endMonth === -1 ? 11 : (endMonth);
      val['cmbEnd'] = this.month[month].label + ' ' + (endDate)  + ' (Following Year)';
      this.leaveForm.patchValue(val);
      //this.leaveForm.pristine;
    }
  }

  public getEndDate($event): void {
    const val = this.leaveForm.value;
    let nextDate;
    if (this.leaveForm.value.cmbStartmonth && $event.target.value) {
      const date = `${moment().year()}-${parseInt(this.leaveForm.value.cmbStartmonth, 10)}-${$event.target.value}`;
      nextDate = moment(date).add(1, 'years').subtract(1, 'days');
      const endMonth = moment(date).add(1, 'years').subtract(1, 'days').month();
      const endDate = moment(date).add(1, 'years').subtract(1, 'days').date();
      const month = endMonth === -1 ? 11 : (endMonth);
      val['cmbEnd'] = this.month[month].label + ' ' + (endDate)  + ' (Following Year)';
      this.leaveForm.patchValue(val);
    }
  }
}
