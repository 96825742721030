import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DashboardService } from "../../../dashboard.service";
import { NotificationService } from "src/app/core/services/notification.service";
import { AuthService } from "src/app/core/services/auth.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
@Component({
  selector: "app-work-week-leave",
  templateUrl: "./work-week.component.html",
  styleUrls: ["./work-week.component.scss"],
})
export class WorkWeekLeaveComponent implements OnInit {
  public workForm: FormGroup;
  public countryList: Array<any> = [];
  public workingType: Array<string> = ["Working Day", "Non-Working Day"];
  //   public isLoading: boolean;
  //   public validationError = {
  //     required: "Required",
  //   };
  //   constructor(
  //     private readonly authService: AuthService,
  //     private service: DashboardService,
  //     private fb: FormBuilder,
  //     private toastr: NotificationService
  //   ) {
  //     this.createForm();
  //   }
  //   public permissions: any;
  //   public read = false;
  //   public write = false;
  //   public update = false;
  //   public delete = false;

  //   public columns = ["day", "type", "shiftIn", "shiftOut", "lunchIn", "lunchOut", "duration"];
  //   dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  //   public workWeekList: Array<any> = [];

  //   checkPermission(module, tabs): void {
  //     this.permissions = JSON.parse(this.authService.getPermissions());

  //     this.read = false;
  //     this.write = false;
  //     this.delete = false;
  //     this.update = false;
  //     if (this.permissions != undefined && this.permissions != null) {
  //       for (let p of this.permissions) {
  //         if (p.id === module) {
  //           if (p.modules != undefined) {
  //             for (let tab of p.modules) {
  //               if (tab.id === tabs) {
  //                 for (let move of tab.permits) {
  //                   if (move.id === "u") {
  //                     this.update = true;
  //                   } else if (move.id === "r") {
  //                     this.read = true;
  //                   } else if (move.id === "c") {
  //                     this.write = true;
  //                   } else if (move.id === "d") {
  //                     this.delete = true;
  //                   }
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  //   ngOnInit() {
  //     this.isLoading = true;
  //     this.checkPermission("leave", "le_ccnf");
  //     this.service
  //       .getCountriesList()

  //       .subscribe(
  //         (resp) => {
  //           if (resp && resp.data) {
  //             this.countryList = resp.data;
  //             if (resp.data[0]) {
  //               this.workForm.patchValue({
  //                 countryCode: resp.data[0].iso,
  //               });
  //               this.getWorkWeeks(this.workForm.value.countryCode);
  //             }
  //           }
  //         },
  //         (error) => {
  //           const err = error && error.error && error.error.msg;
  //           this.toastr.success(err || "Error in getting Countries List");
  //           this.isLoading = false;
  //         }
  //       );
  //   }

  
  //   public getWorkWeeks(event): void {
  //     this.service.getWorkWeek(event).subscribe(
  //       (resp) => {
  //         if (resp && resp.data) {
  //           this.workWeekList = resp.data.workWeekDays;

  //           this.dataSource = new MatTableDataSource<any>(this.workWeekList);
  //           // this.workForm.patchValue(resp.data[0]);
  //           this.isLoading = false;
  //         }
  //       },
  //       (error) => {
  //         const err = error && error.error && error.error.msg;
  //         this.isLoading = false;
  //         this.toastr.success(err || "Error in getting work week data");
  //       }
  //     );
  //   }
  // }

  public userList: Array<any>;
  public mondayForm: FormGroup;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public columns = ["day", "type", "shiftIn", "shiftOut", "lunchIn", "lunchOut", "duration"];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  public workWeekList: Array<any> = [];
  type = "Working day";
  public isLoading = true;
  @Input() employeeId: string;

  constructor(
    private service: DashboardService,
    private authService: AuthService,
    private toastr: NotificationService,
    private fb: FormBuilder
  ) {}

  ngOnDestroy() {
    this.authService = null;
    this.service = null;
    this.toastr = null;
    this.sort = null;
    this.paginator = null;
    this.dataSource = null;
  }

  public permissions: any;
  public read = false;
  public write = false;
  public update = false;
  public delete = false;

  checkPermission(module, tabs): void {
    this.permissions = JSON.parse(this.authService.getPermissions());

    this.read = false;
    this.write = false;
    this.delete = false;
    this.update = false;

    if (this.permissions != undefined && this.permissions != null) {
      for (let p of this.permissions) {
        if (p.id === module) {
          if (p.modules != undefined) {
            for (let tab of p.modules) {
              if (tab.id === tabs) {
                for (let move of tab.permits) {
                  if (move.id === "u") {
                    this.update = true;
                  } else if (move.id === "r") {
                    this.read = true;
                  } else if (move.id === "d") {
                    this.delete = true;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  ngOnInit() {
    this.createForm();
    this.checkPermission("pdm", "pd_em");
    this.service.getCountriesList().subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.countryList = resp.data;
          if (resp.data[0]) {
            let countryCode = resp.data[0].iso;
            this.workForm.patchValue({countryCode : resp.data[0].iso});
            this.getWorkWeek(countryCode);
          }
        }
      },
      (error) => {
        const err = error && error.error && error.error.msg;
        this.toastr.success(err || "Error in getting Countries List");
        this.isLoading = false;
      }
    );
  }

  public createForm(): void {
    this.workForm = this.fb.group({
      countryCode: ["", [Validators.required]],
    });
  }

  // public submitWorkWeek(): void {
  //   this.service.saveWorkWeek(this.workForm.value).subscribe(
  //     (resp) => {
  //       this.toastr.success("Work week saved!");
  //     },
  //     (error) => {
  //       const err = error && error.error && error.error.msg;
  //       this.toastr.success(err || "Error in saving Work week");
  //     }
  //   );
  // }

  getWorkWeek(countryCode: any) {
    this.isLoading = true;
    this.service.getWorkWeek(countryCode).subscribe(
      (res) => {
        if (res) {
          this.workWeekList = res.data.workWeekDays;
          this.dataSource = new MatTableDataSource<any>(this.workWeekList);
          this.isLoading = false;
        }
      },
      (error) => {
        this.toastr.error("Error in getting work week");
      }
    );
  }

  onTypeChange(row, event) {
    for (const w of this.workWeekList) {
      if (w.day === row.day) {
        w.type = event;
      }
    }
  }

  shiftInChanged(event, workWeek) {
    const shiftInTimeInMinutes = this.convertTimeToMinutes(event);
    const shiftOutTimeInMinutes = this.convertTimeToMinutes(workWeek.shiftOut);
    const lunchInTimeInMinutes = this.convertTimeToMinutes(workWeek.lunchIn);
    const lunchOutTimeInMinutes = this.convertTimeToMinutes(workWeek.lunchOut);
    const shiftTime = shiftOutTimeInMinutes - shiftInTimeInMinutes;
    const lunchTime = lunchOutTimeInMinutes - lunchInTimeInMinutes;
    const duration = shiftTime - lunchTime;
    const durationTime = this.convertMinutesToTime(duration);
    this.workWeekList.map((res) => {
      if (res.day === workWeek.day) {
        res.shiftIn = event;
        if (shiftOutTimeInMinutes < shiftInTimeInMinutes) {
          res.duration = "Invalid";
          res.s = true;
        } else if (lunchOutTimeInMinutes < lunchInTimeInMinutes) {
          res.duration = "Invalid";
        } else {
          res.duration = durationTime;
          res.s = false;
        }
        this.dataSource = new MatTableDataSource<any>(this.workWeekList);
      }
    });
  }

  shiftOutChanged(event, workWeek) {
    const shiftInTimeInMinutes = this.convertTimeToMinutes(workWeek.shiftIn);
    const shiftOutTimeInMinutes = this.convertTimeToMinutes(event);
    const lunchInTimeInMinutes = this.convertTimeToMinutes(workWeek.lunchIn);
    const lunchOutTimeInMinutes = this.convertTimeToMinutes(workWeek.lunchOut);

    const shiftTime = shiftOutTimeInMinutes - shiftInTimeInMinutes;
    const lunchTime = lunchOutTimeInMinutes - lunchInTimeInMinutes;
    const duration = shiftTime - lunchTime;
    const durationTime = this.convertMinutesToTime(duration);
    this.workWeekList.map((res) => {
      if (res.day === workWeek.day) {
        res.shiftOut = event;
        if (shiftOutTimeInMinutes < shiftInTimeInMinutes) {
          res.duration = "Invalid";
          res.s = true;
        } else if (lunchOutTimeInMinutes < lunchInTimeInMinutes) {
          res.duration = "Invalid";
        } else {
          res.duration = durationTime;
          res.s = false;
        }

        this.dataSource = new MatTableDataSource<any>(this.workWeekList);
      }
    });
  }

  lunchInChanged(event, workWeek) {
    const shiftInTimeInMinutes = this.convertTimeToMinutes(workWeek.shiftIn);
    const shiftOutTimeInMinutes = this.convertTimeToMinutes(workWeek.shiftOut);
    const lunchInTimeInMinutes = this.convertTimeToMinutes(event);
    const lunchOutTimeInMinutes = this.convertTimeToMinutes(workWeek.lunchOut);
    const shiftTime =
      shiftOutTimeInMinutes > shiftInTimeInMinutes
        ? shiftOutTimeInMinutes - shiftInTimeInMinutes
        : shiftInTimeInMinutes - shiftOutTimeInMinutes;
    const lunchTime = lunchOutTimeInMinutes - lunchInTimeInMinutes;
    const duration = shiftTime - lunchTime;
    const durationTime = this.convertMinutesToTime(duration);
    this.workWeekList.map((res) => {
      if (res.day === workWeek.day) {
        res.lunchIn = event;
        if (shiftOutTimeInMinutes < shiftInTimeInMinutes) {
          res.duration = "Invalid";
          res.s = true;
        } else if (lunchOutTimeInMinutes < lunchInTimeInMinutes) {
          res.duration = "Invalid";
        } else {
          res.duration = durationTime;
          res.s = false;
        }
        this.dataSource = new MatTableDataSource<any>(this.workWeekList);
      }
    });
  }

  lunchOutChanged(event, workWeek) {
    const shiftInTimeInMinutes = this.convertTimeToMinutes(workWeek.shiftIn);
    const shiftOutTimeInMinutes = this.convertTimeToMinutes(workWeek.shiftOut);
    const lunchInTimeInMinutes = this.convertTimeToMinutes(workWeek.lunchIn);
    const lunchOutTimeInMinutes = this.convertTimeToMinutes(event);
    const shiftTime =
      shiftOutTimeInMinutes > shiftInTimeInMinutes
        ? shiftOutTimeInMinutes - shiftInTimeInMinutes
        : shiftInTimeInMinutes - shiftOutTimeInMinutes;
    const lunchTime = lunchOutTimeInMinutes - lunchInTimeInMinutes;
    const duration = shiftTime - lunchTime;
    const durationTime = this.convertMinutesToTime(duration);
    this.workWeekList.map((res) => {
      if (res.day === workWeek.day) {
        res.lunchOut = event;
        if (shiftOutTimeInMinutes < shiftInTimeInMinutes) {
          res.duration = "Invalid";
          res.s = true;
        } else if (lunchOutTimeInMinutes < lunchInTimeInMinutes) {
          res.duration = "Invalid";
        } else {
          res.duration = durationTime;
          res.s = false;
        }
        this.dataSource = new MatTableDataSource<any>(this.workWeekList);
      }
    });
  }

  save(): void {
    for (let w of this.workWeekList) {
      if (w.duration === "Invalid") {
        this.toastr.error(
          "Please Sort Duration Problems :" +
            "\n" +
            "Shift In Must Be Before Shift Out" +
            "\n" +
            "and Lunch In must be Before Lunch Out ..!"
        );
        return;
      }
    }
    const body = {
      countryCode: this.workForm.value.countryCode,
      workWeekDays: this.workWeekList,
    };
    this.service.saveWorkWeek(body).subscribe(
      (res) => {
        if (res) {
          this.toastr.success("Work Week Saved");
        }
      },
      (error) => {
        this.toastr.error("Error in saving Work Week");
      }
    );
  }

  convertTimeToMinutes(time) {
    const hourMinutes = time.split(":")[0] * 60;
    return hourMinutes + parseInt(time.split(":")[1]);
  }

  convertMinutesToTime(minutes) {
    let time = "";
    const hours = minutes / 60;
    const tempNewHour = Math.floor(hours);
    const newMinutes = (hours - tempNewHour) * 60;
    const tempMinutes = Math.round(newMinutes);

    if (tempNewHour < 10 && tempMinutes < 10) {
      time = "0" + tempNewHour + ":0" + tempMinutes;
    } else if (tempNewHour < 10) {
      time = "0" + tempNewHour + ":" + tempMinutes;
    } else if (tempMinutes < 10) {
      time = tempNewHour + ":0" + tempMinutes;
    }

    if (time.charAt(1) === "-") {
      let str = time.substr(2, time.length);
      return str;
    }

    return time;
  }
}
