<div class="card my-0">
    <div class="card-content">
        <app-loading *ngIf="isSaving" [isLoading]="isSaving"></app-loading>
        <form class="form-material" action="javascript:" [formGroup]="userForm">
            <div class="form-row">
                <div class="form-group form-default col-6">
                    <input maxlength="40"  type="text" class="form-control" id="title" formControlName="topic" required="">
                    <span class="form-bar"></span>
                    <label class="float-label" for="title">Name</label>
                    <span class="validation-error"
                          *ngIf="!userForm.controls.topic.valid && userForm.touched">{{ validationError.required }}
                    </span>
                </div>
                <div class="form-group form-default col-6" *ngIf="this.type === 'D'">
                    <mat-form-field class="percent-width-full" [style.fontSize.px]="15">
                        <input maxlength="40"  type="text" placeholder="Category" aria-label="Category" matInput
                               [formControl]="userForm.controls.category"
                               [matAutocomplete]="categoryAuto">
                        <mat-autocomplete #categoryAuto="matAutocomplete" [displayWith]="displayFn">
                            <mat-option *ngFor="let option of category.fi | async" [value]="option">
                                {{option.name}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-error *ngIf="userForm.controls.category.touched && userForm.controls.category.invalid">
                        Please select category
                    </mat-error>
                </div>
            </div>
            <div class="form-row">
                <label class="">Description</label><br>
                <div class="form-group form-default">
                    <angular-editor formControlName="description" [config]="config"></angular-editor>
                    <span class="form-bar"></span>
                    <span class="validation-error"
                          *ngIf="!userForm.controls.description.valid && userForm.controls.description.touched">
                        {{ validationError.required }}
                    </span>
                </div>

                <div class="form-group col-md-4">
                    <input class="" style="margin: 12px" type="checkbox" formControlName="acknowledgement">
                    <label>Request Acknowledgment</label>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 text-right">
                    <button type="submit" class="btn btn-cancel btn-danger" (click)="close()">Cancel</button>
                    <button type="submit" class="btn btn-save btn-primary" (click)="save()" *ngIf="!updateObj">Save
                    </button>
                    <button type="submit" class="btn btn-save btn-primary" (click)="save()" *ngIf="updateObj">Update
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>

