import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormGroupDirective, ControlValueAccessor, NG_VALUE_ACCESSOR, FormControlDirective } from '@angular/forms';

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide:  NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ToolbarTemplateComponent),
  multi: true
};

@Component({
  selector: 'toolbarTemplate',
  templateUrl: './toolbar-template.component.html',
  styleUrls: ['./toolbar-template.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class ToolbarTemplateComponent implements  ControlValueAccessor {

  @Input()
  filterButton: boolean = false;

  @Input()
  addButton: boolean = false;

  @Input()
  csvButton: boolean = false;

  @Input()
  pdfButton: boolean = false;

  @Input()
  search: boolean = false;

  @Output()
  pdfClick: EventEmitter<any> = new EventEmitter();

  @Output()
  csvClick: EventEmitter<any> = new EventEmitter();

  @Output()
  addClick: EventEmitter<any> = new EventEmitter();

  @Output()
  onTextChange: EventEmitter<any> = new EventEmitter();

  @Output()
  filterClick: EventEmitter<any> = new EventEmitter();

  constructor() { }

  exportToCsv() {
    this.csvClick.emit();
  }

  saveAsPdf() {
    this.pdfClick.emit();
  }

  addNew() {
    this.addClick.emit();
  }

  filterList() {
    this.filterClick.emit();
  }

  //The internal data model
  private innerValue: any = '';

  //Placeholders for the callbacks which are later provided
  //by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  //get accessor
  get value(): any {
      return this.innerValue;
  };

  //set accessor including call the onchange callback
  set value(v: any) {
      if (v !== this.innerValue) {
          this.innerValue = v;
          this.onChangeCallback(v);
      }
  }

  //Set touched on blur
  onBlur() {
      this.onTouchedCallback();
  }

  //From ControlValueAccessor interface
  writeValue(value: any) {
      if (value !== this.innerValue) {
          this.innerValue = value;
      }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
      this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
      this.onTouchedCallback = fn;
  }

  onModelChange(val: string) {
    this.onTextChange.emit(val);
  }

}
