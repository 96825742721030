<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
      <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
          <div class="col-md-8">
          <div class="page-header-title">
              <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted">Required Fields</h4></span>
          </div>
          </div>
          <div class="col-md-4"></div>
      </div>
      </div>
  </div>
</div>
<app-loading [isLoading]="isLodaingRequired" ></app-loading>
<mat-card [hidden]="isLodaingRequired">
  <mat-card-title>
    Configure Required Fields 
  </mat-card-title>
  <mat-card-content>
    <mat-checkbox [(ngModel)]="generalState" >Enable Required Fields</mat-checkbox>
  </mat-card-content>
  <mat-card-actions align="end">
    <button [hidden]="!update" (click)="saveState()" mat-raised-button color="primary">
      Save
    </button>
  </mat-card-actions>
</mat-card>

<mat-card [hidden]="isLodaingRequired">
  <mat-card-title>
    PIM Field Configuration
  </mat-card-title>
  <mat-card-content>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <mat-checkbox disabled [(ngModel)]="personalSelected" (change)="setPersonalSelected()" (click)="$event.stopPropagation()">Personal Details</mat-checkbox>
            </mat-panel-title>
                <button  mat-raised-button class="hide btn-expand">Expand</button>
                <button  mat-raised-button class="hide btn-collapse">Collapse</button>
        </mat-expansion-panel-header>
            <form  [formGroup]="personalDetailFromGroup"  #bodyContent>
                <ng-template matExpansionPanelContent>
                    <div class="table-style" style="padding: 0px;">
                        <div style="background: white;">
                            First Name
                            <mat-checkbox [disabled]="true" (change)="checkValidaty()" formControlName="firstNameRequired">Required</mat-checkbox>
                            <mat-checkbox [disabled]="true" (change)="checkValidaty2()" formControlName="firstNameAvailable">Display</mat-checkbox>
                        </div>
                        <div style="background: white">
                            Last Name
                            <mat-checkbox [disabled]="true" (change)="checkValidaty()" formControlName="lastNameRequired">Required</mat-checkbox>
                            <mat-checkbox [disabled]="true" (change)="checkValidaty2()" formControlName="lastNameAvailable">Display</mat-checkbox>
                        </div>
                        <div style="background: white">
                            Middle Name
                            <mat-checkbox (change)="checkValidaty()" formControlName="middleNameRequired">Required</mat-checkbox>
                            <mat-checkbox (change)="checkValidaty2()" formControlName="middleNameAvailable">Display</mat-checkbox>
                        </div>
                     
                        <div style="background: white">
                            Date Of Birth
                            <mat-checkbox (change)="checkValidaty()" formControlName="dateOfBirthRequired">Required</mat-checkbox>
                            <mat-checkbox (change)="checkValidaty2()" formControlName="dateOfBirthAvailable">Display</mat-checkbox>
                        </div>
                        <div style="background: white">
                            Martial Status
                            <mat-checkbox (change)="checkValidaty()" formControlName="martialStatusRequired">Required</mat-checkbox>
                            <mat-checkbox (change)="checkValidaty2()" formControlName="martialStatusAvailable">Display</mat-checkbox>
                        </div>
                        <div style="background: white">
                            Gender
                            <mat-checkbox (change)="checkValidaty()" formControlName="genderRequired">Required</mat-checkbox>
                            <mat-checkbox (change)="checkValidaty2()" formControlName="genderAvailable">Display</mat-checkbox>
                        </div>
                        <div style="background: white">
                            Nationality
                            <mat-checkbox [disabled]="true" (change)="checkValidaty()" formControlName="nationalityRequired">Required</mat-checkbox>
                            <mat-checkbox [disabled]="true" (change)="checkValidaty2()" formControlName="nationalityAvailable">Display</mat-checkbox>
                        </div>
                        <div style="background: white">
                            Driver License Number
                            <mat-checkbox (change)="checkValidaty()" formControlName="driverLicenseNumberRequired">Required</mat-checkbox>
                            <mat-checkbox (change)="checkValidaty2()" formControlName="driverLicenseNumberAvailable">Display</mat-checkbox>
                        </div>
                        <div style="background: white">
                            License Expiry Date
                            <mat-checkbox  (change)="checkValidaty()" formControlName="licenseExpiryDateRequired">Required</mat-checkbox>
                            <mat-checkbox (change)="checkValidaty2()" formControlName="licenseExpiryDateAvailable">Display</mat-checkbox>
                        </div>

                        <div style="background: white">
                            Nick Name
                            <mat-checkbox  (change)="checkValidaty()" formControlName="nickNameRequired">Required</mat-checkbox>
                            <mat-checkbox (change)="checkValidaty2()" formControlName="nickNameAvailable">Display</mat-checkbox>
                        </div>
                        <div style="background: white">
                            Smoker
                            <mat-checkbox  (change)="checkValidaty()" formControlName="smokerRequired">Required</mat-checkbox>
                            <mat-checkbox (change)="checkValidaty2()" formControlName="smokerAvailable">Display</mat-checkbox>
                        </div>
                        <div style="background: white">
                            Military Service
                            <mat-checkbox  (change)="checkValidaty()" formControlName="militaryServiceRequired">Required</mat-checkbox>
                            <mat-checkbox (change)="checkValidaty2()" formControlName="militaryServiceAvailable">Display</mat-checkbox>
                        </div>
                    </div>
                </ng-template>
            </form>
    </mat-expansion-panel>
      <mat-expansion-panel>
          <mat-expansion-panel-header>
              <mat-panel-title>
                  <mat-checkbox [(ngModel)]="jobSelected" disabled (change)="setJobSelected()"  (click)="$event.stopPropagation()">Job</mat-checkbox>
              </mat-panel-title>
                <button  mat-raised-button class="hide btn-expand">Expand</button>
                <button  mat-raised-button class="hide btn-collapse">Collapse</button>
          </mat-expansion-panel-header>
          <form [formGroup]="jobFromGroup" #bodyContent>
              <ng-template matExpansionPanelContent>
                <div class="table-style" style="padding: 0px;">
                 
                    <div style="background: white">
                        Probation End Date
                        <mat-checkbox (change)="checkValidatyJob()" formControlName="probationEndDateRequired">Required</mat-checkbox>
                        <mat-checkbox (change)="checkValidatyJob2()" formControlName="probationEndDateAvailable">Display</mat-checkbox>
                    </div>
                    
                    <div style="background: white">
                        Job Title
                        <mat-checkbox (change)="checkValidatyJob()" formControlName="jobTitleRequired">Required</mat-checkbox>
                        <mat-checkbox (change)="checkValidatyJob2()" formControlName="jobTitleAvailable">Display</mat-checkbox>
                    </div>
                    <div style="background: white">
                        Employment Status
                        <mat-checkbox (change)="checkValidatyJob()" formControlName="employmentStatusRequired">Required</mat-checkbox>
                        <mat-checkbox (change)="checkValidatyJob2()" formControlName="employmentStatusAvailable">Display</mat-checkbox>
                    </div>
                    <div style="background: white">
                        Job Category
                        <mat-checkbox (change)="checkValidatyJob()" formControlName="jobCategoryRequired">Required</mat-checkbox>
                        <mat-checkbox (change)="checkValidatyJob2()" formControlName="jobCategoryAvailable">Display</mat-checkbox>
                    </div>
                    <div style="background: white">
                        Sub Unit
                        <mat-checkbox disabled (change)="checkValidatyJob()" formControlName="subUnitRequired">Required</mat-checkbox>
                        <mat-checkbox disabled (change)="checkValidatyJob2()" formControlName="subUnitAvailable">Display</mat-checkbox>
                    </div>
                    <div style="background: white">
                        Location
                        <mat-checkbox disabled (change)="checkValidatyJob()" formControlName="locationRequired">Required</mat-checkbox>
                        <mat-checkbox disabled (change)="checkValidatyJob2()" formControlName="locationAvailable">Display</mat-checkbox>
                    </div>
                    <div style="background: white">
                        Region
                        <mat-checkbox disabled (change)="checkValidatyJob()" formControlName="regionRequired">Required</mat-checkbox>
                        <mat-checkbox disabled (change)="checkValidatyJob2()" formControlName="regionAvailable">Display</mat-checkbox>
                    </div>
                    <div style="background: white">
                        Work Shift
                        <mat-checkbox (change)="checkValidatyJob()" formControlName="workShiftRequired">Required</mat-checkbox>
                        <mat-checkbox (change)="checkValidatyJob2()" formControlName="workShiftAvailable">Display</mat-checkbox>
                    </div>
                    <div style="background: white">
                        Contract Start Date
                        <mat-checkbox (change)="checkValidatyJob()" formControlName="contractStartDateRequired">Required</mat-checkbox>
                        <mat-checkbox (change)="checkValidatyJob2()" formControlName="contractStartDateAvailable">Display</mat-checkbox>
                    </div>
                    <div style="background: white">
                        Contract End Date
                        <mat-checkbox (change)="checkValidatyJob()" formControlName="contractEndDateRequired">Required</mat-checkbox>
                        <mat-checkbox (change)="checkValidatyJob2()" formControlName="contractEndDateAvailable">Display</mat-checkbox>
                    </div>
                    <div style="background: white">
                        Comment
                        <mat-checkbox (change)="checkValidatyJob()" formControlName="commentRequired">Required</mat-checkbox>
                        <mat-checkbox (change)="checkValidatyJob2()" formControlName="commentAvailable">Display</mat-checkbox>
                    </div>
                    <div style="background: white">
                        Effective From
                        <mat-checkbox disabled (change)="checkValidatyJob()" formControlName="effectiveFromRequired">Required</mat-checkbox>
                        <mat-checkbox disabled (change)="checkValidatyJob2()" formControlName="effectiveFromAvailable">Display</mat-checkbox>
                    </div>
                    <div style="background: white">
                       Cost Center
                        <mat-checkbox (change)="checkValidatyJob()" formControlName="costCenterRequired">Required</mat-checkbox>
                        <mat-checkbox (change)="checkValidatyJob2()" formControlName="costCenterAvailable">Display</mat-checkbox>
                    </div>
                  
                </div>
              </ng-template>
          </form>
      </mat-expansion-panel>
      <mat-expansion-panel>
          <mat-expansion-panel-header>
              <mat-panel-title>
                  <mat-checkbox [(ngModel)]="salarySelected" disabled (change)="setSalarySelected()" (click)="$event.stopPropagation()">Salary</mat-checkbox>
              </mat-panel-title>
                <button  mat-raised-button class="hide btn-expand">Expand</button>
                <button  mat-raised-button class="hide btn-collapse">Collapse</button>
          </mat-expansion-panel-header>
          <form [formGroup]="salaryFromGroup" #bodyContent>
              <ng-template matExpansionPanelContent>
                <div class="table-style" style="padding: 0px;">
                
                  <div style="background: white">
                      Pay Grade
                      <mat-checkbox  [disabled]="true" formControlName="payGradeRequired">Required</mat-checkbox>
                      <mat-checkbox  [disabled]="true" formControlName="payGradeAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Currency
                      <mat-checkbox  [disabled]="true" formControlName="currencyRequired">Required</mat-checkbox>
                      <mat-checkbox   [disabled]="true" formControlName="currencyAvailable">Display</mat-checkbox>
                  </div>
                
                  </div>
              </ng-template>
          </form>
      </mat-expansion-panel>
      <mat-expansion-panel>
          <mat-expansion-panel-header>
              <mat-panel-title>
                  <mat-checkbox [checked]="contactSelected" [(ngModel)]="contactSelected" (click)="$event.stopPropagation()">Contact Details</mat-checkbox>
              </mat-panel-title>
                <button  mat-raised-button class="hide btn-expand">Expand</button>
                <button  mat-raised-button class="hide btn-collapse">Collapse</button>
          </mat-expansion-panel-header>
          <form [formGroup]="contactDetailsFromGroup" #bodyContent>
              <ng-template matExpansionPanelContent>
                <div class="table-style" style="padding: 0px;">
                  <div style="background: white">
                      Address Street 1
                      <mat-checkbox (change)="checkContact()" formControlName="addressStreet1Required">Required</mat-checkbox>
                      <mat-checkbox (change)="checkContact2()" formControlName="addressStreet1Available">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Address Street 2
                      <mat-checkbox (change)="checkContact()" formControlName="addressStreet2Required">Required</mat-checkbox>
                      <mat-checkbox (change)="checkContact2()" formControlName="addressStreet2Available">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      City
                      <mat-checkbox (change)="checkContact()" formControlName="cityRequired">Required</mat-checkbox>
                      <mat-checkbox (change)="checkContact2()" formControlName="cityAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Country
                      <mat-checkbox (change)="checkContact()" formControlName="countryRequired">Required</mat-checkbox>
                      <mat-checkbox (change)="checkContact2()" formControlName="countryAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Zip Code
                      <mat-checkbox (change)="checkContact()" formControlName="postalCodeRequired">Required</mat-checkbox>
                      <mat-checkbox (change)="checkContact2()" formControlName="postalCodeAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Home Telephone
                      <mat-checkbox (change)="checkContact()" formControlName="homeTelephoneRequired">Required</mat-checkbox>
                      <mat-checkbox (change)="checkContact2()" formControlName="homeTelephoneAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Mobile
                      <mat-checkbox (change)="checkContact()" formControlName="mobileRequired">Required</mat-checkbox>
                      <mat-checkbox (change)="checkContact2()" formControlName="mobileAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Work Telephone
                      <mat-checkbox (change)="checkContact()" formControlName="workTelephoneRequired">Required</mat-checkbox>
                      <mat-checkbox (change)="checkContact2()" formControlName="workTelephoneAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      State/Province
                      <mat-checkbox (change)="checkContact()" formControlName="stateRequired">Required</mat-checkbox>
                      <mat-checkbox (change)="checkContact2()" formControlName="stateAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Work Email
                      <mat-checkbox (change)="checkContact()" formControlName="workEmailRequired">Required</mat-checkbox>
                      <mat-checkbox (change)="checkContact2()" formControlName="workEmailAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Other Email
                      <mat-checkbox (change)="checkContact()" formControlName="otherEmailRequired">Required</mat-checkbox>
                      <mat-checkbox (change)="checkContact2()" formControlName="otherEmailAvailabl">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                   Landmark
                    <mat-checkbox (change)="checkContact()" formControlName="landMarkRequired">Required</mat-checkbox>
                    <mat-checkbox (change)="checkContact2()" formControlName="landMarkAvailable">Display</mat-checkbox>
                </div>
                  </div>
              </ng-template>
          </form>
      </mat-expansion-panel>
      <mat-expansion-panel>
          <mat-expansion-panel-header>
              <mat-panel-title>
                  <mat-checkbox disabled [(ngModel)]="socialSelected" (click)="$event.stopPropagation()">Social Media Details</mat-checkbox>
              </mat-panel-title>
                <button  mat-raised-button class="hide btn-expand">Expand</button>
                <button  mat-raised-button class="hide btn-collapse">Collapse</button>
          </mat-expansion-panel-header>
          <form [formGroup]="socialMediaDetailsFromGroup" #bodyContent>
              <ng-template matExpansionPanelContent>
                <div class="table-style" style="padding: 0px;">
                    <div style="background: white">
                        Name
                        <mat-checkbox disabled formControlName="nameRequired">Required</mat-checkbox>
                        <mat-checkbox disabled formControlName="nameAvailable">Display</mat-checkbox>
                    </div>
                  <div style="background: white">
                      Type
                      <mat-checkbox disabled formControlName="typeRequired">Required</mat-checkbox>
                      <mat-checkbox disabled formControlName="typeAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Link
                      <mat-checkbox disabled formControlName="linkRequired">Required</mat-checkbox>
                      <mat-checkbox disabled  formControlName="linkAvailable">Display</mat-checkbox>
                  </div>
                  
                  
                  </div>
              </ng-template>
          </form>
      </mat-expansion-panel>
      <mat-expansion-panel>
          <mat-expansion-panel-header>
              <mat-panel-title>
                  <mat-checkbox disabled [(ngModel)]="emergencySelected" (click)="$event.stopPropagation()">Emergency Contacts</mat-checkbox>
              </mat-panel-title>
                <button  mat-raised-button class="hide btn-expand">Expand</button>
                <button  mat-raised-button class="hide btn-collapse">Collapse</button>
          </mat-expansion-panel-header>
          <form [formGroup]="emergencyContactsFromGroup" #bodyContent>
              <ng-template matExpansionPanelContent>
                <div class="table-style" style="padding: 0px;">
                  <div style="background: white">
                      Name
                      <mat-checkbox disabled formControlName="nameRequired">Required</mat-checkbox>
                      <mat-checkbox disabled formControlName="nameAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      RelationShip
                      <mat-checkbox disabled formControlName="relationShipRequired">Required</mat-checkbox>
                      <mat-checkbox disabled formControlName="relationShipAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Home Telephone
                      <mat-checkbox disabled formControlName="homeTelephoneRequired">Required</mat-checkbox>
                      <mat-checkbox disabled formControlName="homeTelephoneAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Mobile
                      <mat-checkbox disabled formControlName="mobileRequired">Required</mat-checkbox>
                      <mat-checkbox disabled  formControlName="mobileAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Work Telephone
                      <mat-checkbox disabled formControlName="workTelephoneRequired">Required</mat-checkbox>
                      <mat-checkbox disabled formControlName="workTelephoneAvailable">Display</mat-checkbox>
                  </div>
                  </div>
              </ng-template>
          </form>
      </mat-expansion-panel>
      <mat-expansion-panel>
          <mat-expansion-panel-header>
              <mat-panel-title>
                  <mat-checkbox disabled [(ngModel)]="dependentSelected" (click)="$event.stopPropagation()">Dependents</mat-checkbox>
              </mat-panel-title>
                <button  mat-raised-button class="hide btn-expand">Expand</button>
                <button  mat-raised-button class="hide btn-collapse">Collapse</button>
          </mat-expansion-panel-header>
          <form [formGroup]="dependentsFromGroup" #bodyContent>
              <ng-template matExpansionPanelContent>
                <div class="table-style" style="padding: 0px;">
                  <div style="background: white">
                      Name
                      <mat-checkbox disabled formControlName="nameRequired">Required</mat-checkbox>
                      <mat-checkbox disabled formControlName="nameAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      RelationShip
                      <mat-checkbox disabled formControlName="relationShipRequired">Required</mat-checkbox>
                      <mat-checkbox disabled formControlName="relationShipAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Date Of Birth
                      <mat-checkbox disabled formControlName="dateOfBirthRequired">Required</mat-checkbox>
                      <mat-checkbox disabled formControlName="dateOfBirthAvailable">Display</mat-checkbox>
                  </div>
                  </div>
              </ng-template>
          </form>
      </mat-expansion-panel>
      <mat-expansion-panel>
          <mat-expansion-panel-header>
              <mat-panel-title>
                  <mat-checkbox disabled [checked]="immigrationSelected" [(ngModel)]="immigrationSelected" (click)="$event.stopPropagation()">Immigration</mat-checkbox>
              </mat-panel-title>
                <button  mat-raised-button class="hide btn-expand">Expand</button>
                <button  mat-raised-button class="hide btn-collapse">Collapse</button>
          </mat-expansion-panel-header>
          <form [formGroup]="immigrationFromGroup" #bodyContent>
              <ng-template matExpansionPanelContent>
                <div class="table-style" style="padding: 0px;">
                  <div style="background: white">
                      Document
                      <mat-checkbox disabled  formControlName="documentRequired">Required</mat-checkbox>
                      <mat-checkbox disabled  formControlName="documentAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Number
                      <mat-checkbox disabled  formControlName="numberRequired">Required</mat-checkbox>
                      <mat-checkbox disabled formControlName="numberAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Eligible Status
                      <mat-checkbox (change)="checkImmigration()" formControlName="eligibleStatusRequired">Required</mat-checkbox>
                      <mat-checkbox (change)="checkImmigration2()" formControlName="eligibleStatusAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Issued By
                      <mat-checkbox (change)="checkImmigration()" formControlName="issuedByRequired">Required</mat-checkbox>
                      <mat-checkbox (change)="checkImmigration2()" formControlName="issuedByAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Issued Date
                      <mat-checkbox (change)="checkImmigration()" formControlName="issuedDateRequired">Required</mat-checkbox>
                      <mat-checkbox (change)="checkImmigration2()" formControlName="issuedDateAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Expiry Date
                      <mat-checkbox (change)="checkImmigration()" formControlName="expiryDateRequired">Required</mat-checkbox>
                      <mat-checkbox (change)="checkImmigration2()" formControlName="expiryDateAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Eligible Review Date
                      <mat-checkbox (change)="checkImmigration()" formControlName="eligibleReviewDateRequired">Required</mat-checkbox>
                      <mat-checkbox (change)="checkImmigration2()" formControlName="eligibleReviewDateAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                    Comment
                    <mat-checkbox (change)="checkImmigration()" formControlName="commentRequired">Required</mat-checkbox>
                    <mat-checkbox (change)="checkImmigration2()" formControlName="commentAvailable">Display</mat-checkbox>
                </div>
                  </div>
              </ng-template>
          </form>
      </mat-expansion-panel>
      <mat-expansion-panel>
          <mat-expansion-panel-header>
              <mat-panel-title>
                  <mat-checkbox disabled [checked]="superVisiorSelected" (click)="$event.stopPropagation()">Supervisor</mat-checkbox>
              </mat-panel-title>
                <button  mat-raised-button class="hide btn-expand">Expand</button>
                <button  mat-raised-button class="hide btn-collapse">Collapse</button>
          </mat-expansion-panel-header>
          <form [formGroup]="supervisorFromGroup" #bodyContent>
              <ng-template matExpansionPanelContent>
                <div class="table-style" style="padding: 0px;">
                  <div style="background: white">
                      Name
                      <mat-checkbox disabled formControlName="nameRequired">Required</mat-checkbox>
                      <mat-checkbox disabled formControlName="nameAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Reporting Method
                      <mat-checkbox disabled formControlName="reportingMethodRequired">Required</mat-checkbox>
                      <mat-checkbox disabled formControlName="reportingMethodAvailable">Display</mat-checkbox>
                  </div>
                  </div>
              </ng-template>
          </form>
      </mat-expansion-panel>
      <mat-expansion-panel>
          <mat-expansion-panel-header>
              <mat-panel-title>
                  <mat-checkbox disabled [checked]="subordinateSelected" (click)="$event.stopPropagation()">Subordinate</mat-checkbox>
              </mat-panel-title>
                <button  mat-raised-button class="hide btn-expand">Expand</button>
                <button  mat-raised-button class="hide btn-collapse">Collapse</button>
          </mat-expansion-panel-header>
          <form [formGroup]="subOrdinateFromGroup" #bodyContent>
              <ng-template matExpansionPanelContent>
                <div class="table-style" style="padding: 0px;">
                  <div style="background: white">
                      Name
                      <mat-checkbox disabled formControlName="nameRequired">Required</mat-checkbox>
                      <mat-checkbox disabled formControlName="nameAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Reporting Method
                      <mat-checkbox disabled formControlName="reportingMethodRequired">Required</mat-checkbox>
                      <mat-checkbox disabled  formControlName="reportingMethodAvailable">Display</mat-checkbox>
                  </div>
                  </div>
              </ng-template>
          </form>
      </mat-expansion-panel>
      <mat-expansion-panel>
          <mat-expansion-panel-header>
              <mat-panel-title>
                  <mat-checkbox disabled [checked]="workExperinceSelected" (click)="$event.stopPropagation()">Work Experience</mat-checkbox>
              </mat-panel-title>
                <button  mat-raised-button class="hide btn-expand">Expand</button>
                <button  mat-raised-button class="hide btn-collapse">Collapse</button>
          </mat-expansion-panel-header>
          <form [formGroup]="workExperienceFromGroup" #bodyContent>
              <ng-template matExpansionPanelContent>
                <div class="table-style" style="padding: 0px;">
                  <div style="background: white">
                      Company
                      <mat-checkbox  disabled formControlName="companyRequired">Required</mat-checkbox>
                      <mat-checkbox disabled formControlName="companyAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Job Title
                      <mat-checkbox disabled formControlName="jobTitleRequired">Required</mat-checkbox>
                      <mat-checkbox disabled formControlName="jobTitleAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Comments
                      <mat-checkbox  (change)="checkWorkEx()" formControlName="commentsRequired">Required</mat-checkbox>
                      <mat-checkbox  (change)="checkWorkEx2()" formControlName="commentsAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      From
                      <mat-checkbox disabled formControlName="fromRequired">Required</mat-checkbox>
                      <mat-checkbox disabled formControlName="fromAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      To
                      <mat-checkbox disabled formControlName="toRequired">Required</mat-checkbox>
                      <mat-checkbox disabled formControlName="toAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Creditable
                      <mat-checkbox  (change)="checkWorkEx()" formControlName="creditableRequired">Required</mat-checkbox>
                      <mat-checkbox  (change)="checkWorkEx2()" formControlName="creditableAvailable">Display</mat-checkbox>
                  </div>
                  </div>
              </ng-template>
          </form>
      </mat-expansion-panel>
      <mat-expansion-panel>
          <mat-expansion-panel-header>
              <mat-panel-title>
                  <mat-checkbox disabled [checked]="educationSelected" (click)="$event.stopPropagation()">Education</mat-checkbox>
              </mat-panel-title>
                <button  mat-raised-button class="hide btn-expand">Expand</button>
                <button  mat-raised-button class="hide btn-collapse">Collapse</button>
          </mat-expansion-panel-header>
          <form [formGroup]="educationFromGroup" #bodyContent>
              <ng-template matExpansionPanelContent>
                <div class="table-style" style="padding: 0px;">
                  <div style="background: white">
                      Level
                      <mat-checkbox disabled formControlName="levelRequired">Required</mat-checkbox>
                      <mat-checkbox disabled formControlName="levelAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Institute
                      <mat-checkbox disabled formControlName="instituteRequired">Required</mat-checkbox>
                      <mat-checkbox disabled formControlName="instituteAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Major/Specialization
                      <mat-checkbox disabled formControlName="majorRequired">Required</mat-checkbox>
                      <mat-checkbox disabled formControlName="majorAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Year
                      <mat-checkbox disabled formControlName="yearRequired">Required</mat-checkbox>
                      <mat-checkbox disabled formControlName="yearAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      GPA/Score
                      <mat-checkbox disabled formControlName="gpaRequired">Required</mat-checkbox>
                      <mat-checkbox disabled formControlName="gpaAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Start Date
                      <mat-checkbox (change)="checkWorkEducation()" formControlName="startDateRequired">Required</mat-checkbox>
                      <mat-checkbox (change)="checkWorkEducation2()" formControlName="startDateAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      End Date
                      <mat-checkbox (change)="checkWorkEducation()" formControlName="endDateRequired">Required</mat-checkbox>
                      <mat-checkbox (change)="checkWorkEducation2()" formControlName="endDateAvailable">Display</mat-checkbox>
                  </div>
                  </div>
              </ng-template>
          </form>
      </mat-expansion-panel>
      <mat-expansion-panel>
          <mat-expansion-panel-header>
              <mat-panel-title>
                  <mat-checkbox [checked]="skillsSelected" (click)="$event.stopPropagation()">Skills</mat-checkbox>
              </mat-panel-title>
                <button  mat-raised-button class="hide btn-expand">Expand</button>
                <button  mat-raised-button class="hide btn-collapse">Collapse</button>
          </mat-expansion-panel-header>
          <form [formGroup]="skillsFromGroup" #bodyContent>
              <ng-template matExpansionPanelContent>
                <div class="table-style" style="padding: 0px;">
                  <div style="background: white">
                      Skills
                      <mat-checkbox (change)="checkSkills()" formControlName="skillsRequired">Required</mat-checkbox>
                      <mat-checkbox (change)="checkSkills2()" formControlName="skillsAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Year Of Experience
                      <mat-checkbox (change)="checkSkills()" formControlName="yearOfExperienceRequired">Required</mat-checkbox>
                      <mat-checkbox (change)="checkSkills2()" formControlName="yearOfExperienceAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Comments
                      <mat-checkbox (change)="checkSkills()" formControlName="commentsRequired">Required</mat-checkbox>
                      <mat-checkbox (change)="checkSkills2()" formControlName="commentsAvailable">Display</mat-checkbox>
                  </div>
                  </div>
              </ng-template>
          </form>
      </mat-expansion-panel>
      <mat-expansion-panel>
          <mat-expansion-panel-header>
              <mat-panel-title>
                  <mat-checkbox [checked]="languageSelected" (click)="$event.stopPropagation()">Languages</mat-checkbox>
              </mat-panel-title>
                <button  mat-raised-button class="hide btn-expand">Expand</button>
                <button  mat-raised-button class="hide btn-collapse">Collapse</button>
          </mat-expansion-panel-header>
          <form [formGroup]="languagesFromGroup" #bodyContent>
              <ng-template matExpansionPanelContent>
                <div class="table-style" style="padding: 0px;">
                  <div style="background: white">
                      Language
                      <mat-checkbox (change)="checkLanguage()" formControlName="languagesRequired">Required</mat-checkbox>
                      <mat-checkbox (change)="checkLanguage2()" formControlName="languagesAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Skill
                      <mat-checkbox (change)="checkLanguage()" formControlName="skillRequired">Required</mat-checkbox>
                      <mat-checkbox (change)="checkLanguage2()" formControlName="skillAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Fluency Level
                      <mat-checkbox (change)="checkLanguage()" formControlName="fluencyLevelRequired">Required</mat-checkbox>
                      <mat-checkbox (change)="checkLanguage2()" formControlName="fluencyLevelAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Comments
                      <mat-checkbox (change)="checkLanguage()" formControlName="commentsRequired">Required</mat-checkbox>
                      <mat-checkbox (change)="checkLanguage2()" formControlName="commentsAvailable">Display</mat-checkbox>
                  </div>
                  </div>
              </ng-template>
          </form>
      </mat-expansion-panel>
      <mat-expansion-panel>
          <mat-expansion-panel-header>
              <mat-panel-title>
                  <mat-checkbox [checked]="licensesSelected" (click)="$event.stopPropagation()">License</mat-checkbox>
              </mat-panel-title>
                <button  mat-raised-button class="hide btn-expand">Expand</button>
                <button  mat-raised-button class="hide btn-collapse">Collapse</button>
          </mat-expansion-panel-header>
          <form [formGroup]="licenseFromGroup" #bodyContent>
              <ng-template matExpansionPanelContent>
                <div class="table-style" style="padding: 0px;">
                  <div style="background: white">
                      License Type
                      <mat-checkbox (change)="checkLicenses()" formControlName="licenseTypeRequired">Required</mat-checkbox>
                      <mat-checkbox (change)="checkLicenses2()" formControlName="licenseTypeAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      License Number
                      <mat-checkbox (change)="checkLicenses()" formControlName="licenseNumberRequired">Required</mat-checkbox>
                      <mat-checkbox (change)="checkLicenses2()" formControlName="licenseNumberAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Issued Date
                      <mat-checkbox (change)="checkLicenses()" formControlName="issuedDateRequired">Required</mat-checkbox>
                      <mat-checkbox (change)="checkLicenses2()" formControlName="issuedDateAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Expiry Date
                      <mat-checkbox (change)="checkLicenses()" formControlName="expiryDateRequired">Required</mat-checkbox>
                      <mat-checkbox (change)="checkLicenses2()" formControlName="expiryDateAvailable">Display</mat-checkbox>
                  </div>
                  </div>
              </ng-template>
          </form>
      </mat-expansion-panel>
      <mat-expansion-panel>
          <mat-expansion-panel-header>
              <mat-panel-title>
                  <mat-checkbox [checked]="memberShipSelected" (click)="$event.stopPropagation()">Memberships</mat-checkbox>
              </mat-panel-title>
                <button  mat-raised-button class="hide btn-expand">Expand</button>
                <button  mat-raised-button class="hide btn-collapse">Collapse</button>
          </mat-expansion-panel-header>
          <form [formGroup]="membershipsFromGroup" #bodyContent>
              <ng-template matExpansionPanelContent>
                <div class="table-style" style="padding: 0px;">
                  <div style="background: white">
                      MemberShip
                      <mat-checkbox (change)="checkMemberShip()" formControlName="memberShipRequired">Required</mat-checkbox>
                      <mat-checkbox (change)="checkMemberShip2()" formControlName="memberShipAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Subscription Paid By
                      <mat-checkbox (change)="checkMemberShip()" formControlName="subscriptionPaidByRequired">Required</mat-checkbox>
                      <mat-checkbox (change)="checkMemberShip2()" formControlName="subscriptionPaidByAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Currency
                      <mat-checkbox (change)="checkMemberShip()" formControlName="currencyRequired">Required</mat-checkbox>
                      <mat-checkbox (change)="checkMemberShip2()" formControlName="currencyAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Subscription Renewal Date
                      <mat-checkbox (change)="checkMemberShip()" formControlName="subscriptionRenewalDateRequired">Required</mat-checkbox>
                      <mat-checkbox (change)="checkMemberShip2()" formControlName="subscriptionRenewalDateAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Subscription Commence Date
                      <mat-checkbox (change)="checkMemberShip()" formControlName="subscriptionCommenceDateRequired">Required</mat-checkbox>
                      <mat-checkbox (change)="checkMemberShip2()" formControlName="subscriptionCommenceDateAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Subscription Amount
                      <mat-checkbox (change)="checkMemberShip()" formControlName="subscriptionAmountRequired">Required</mat-checkbox>
                      <mat-checkbox (change)="checkMemberShip2()" formControlName="subscriptionAmountAvailable">Display</mat-checkbox>
                  </div>
                  </div>
              </ng-template>
          </form>
      </mat-expansion-panel>
      <mat-expansion-panel>
          <mat-expansion-panel-header>
              <mat-panel-title>
                  <mat-checkbox [checked]="directSelected" disabled (click)="$event.stopPropagation()">Bank Info </mat-checkbox>
              </mat-panel-title>
                <button  mat-raised-button class="hide btn-expand">Expand</button>
                <button  mat-raised-button class="hide btn-collapse">Collapse</button>
          </mat-expansion-panel-header>
          <form [formGroup]="directDepositFromGroup" #bodyContent>
              <ng-template matExpansionPanelContent>
                <div class="table-style" style="padding: 0px;">
                  <div style="background: white">
                      Amount
                      <mat-checkbox disabled formControlName="amountRequired">Required</mat-checkbox>
                      <mat-checkbox disabled formControlName="amountAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Account Number
                      <mat-checkbox disabled formControlName="accountNumberRequired">Required</mat-checkbox>
                      <mat-checkbox disabled formControlName="accountNumberAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Account Type
                      <mat-checkbox disabled formControlName="accountTypeRequired">Required</mat-checkbox>
                      <mat-checkbox disabled formControlName="accountTypeAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Financial Institute
                      <mat-checkbox disabled formControlName="financialInstituteRequired">Required</mat-checkbox>
                      <mat-checkbox disabled formControlName="financialInstituteAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Branch Location
                      <mat-checkbox disabled formControlName="branchLocationRequired">Required</mat-checkbox>
                      <mat-checkbox disabled formControlName="branchLocationAvailable">Display</mat-checkbox>
                  </div>
                  <div style="background: white">
                      Routing Number
                      <mat-checkbox disabled formControlName="routingNumberRequired">Required</mat-checkbox>
                      <mat-checkbox disabled formControlName="routingNumberAvailable">Display</mat-checkbox>
                  </div>
                  </div>
              </ng-template>
          </form>
      </mat-expansion-panel>
  </mat-card-content>
  <mat-card-actions align="end">
    <button [hidden]="!update" (click)="saveRequireds()" mat-raised-button color="primary">
      Save
    </button>
  </mat-card-actions>
</mat-card>
