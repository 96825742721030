<div id="header-page" class="ng-star-inserted header-page">
    <div class="page-header ng-star-inserted">
        <div class="page-block ng-star-inserted">
            <div class="row align-items-center">
                <div class="col-md-8">
                    <div class="page-header-title">
                        <span class="ng-star-inserted"></span><span class="ng-star-inserted">
                            <h4 *ngIf="selected==0" class="m-b-10 ng-star-inserted">Course Details</h4>
                        </span>
                        <span class="ng-star-inserted"></span><span class="ng-star-inserted">
                            <h4 *ngIf="selected==1" class="m-b-10 ng-star-inserted">Session</h4>
                        </span>
                        <span class="ng-star-inserted"></span><span class="ng-star-inserted">
                            <h4 *ngIf="selected==2" class="m-b-10 ng-star-inserted">Trainers</h4>
                        </span>
                        <span class="ng-star-inserted"></span><span class="ng-star-inserted">
                            <h4 *ngIf="selected==3" class="m-b-10 ng-star-inserted">Employees</h4>
                        </span>
                        <span class="ng-star-inserted"></span><span class="ng-star-inserted">
                            <h4 *ngIf="selected==4" class="m-b-10 ng-star-inserted">Resources</h4>
                        </span>
                    </div>
                </div>
                <div class="col-md-4"></div>
            </div>
        </div>
    </div>
</div>

<div class="page-body no-title">

    <mat-card class="example-card">
        <!--        <app-loading *ngIf="isLoading" [isLoading]="isLoading"></app-loading>-->
        <mat-card-content>
            <mat-tab-group mat-align-tabs="center" #tabGroup (selectedTabChange)="tabChanged($event)">
                <mat-tab label="Course Details">
                    <mat-card class="example-card">
                        <mat-card-content>
                            <App-course-details></App-course-details>
                        </mat-card-content>
                    </mat-card>
                </mat-tab>
                <mat-tab label="Internal Trainers">
                    <div style="margin-top: 33px; width: 99%; margin-left: 1px" class="row">
                        <div class="col-sm-12">
                            <div class="row top-tools">
                                <div class="col-xs-12 col-sm-12 col-md-6 left-tools">
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
                                    <div class="relative-position search-filter-con">
                                        <span class="icon material-icons ng-tns-c114-0"> search </span>
                                        <mat-form-field>
                                            <input matInput #inputSearch type="search" [(ngModel)]="filterQuery"
                                                maxlength="40" (ngModelChange)="onModelChange(filterQuery , false)"
                                                class="form-control input-sm full-data-search">
                                        </mat-form-field>
                                        <span class="mytooltip cus-left tooltip-effect-4">
                                            <span class="tooltip-item">
                                                <button mat-mini-fab
                                                    class="btn btn-primary light add-rounded margin-button-05"
                                                    (click)="addNewCourse(false)">
                                                    <i class="fa fa-plus"></i>
                                                </button>
                                            </span>
                                            <span class="tooltip-content clearfix">
                                                <span class="tooltip-text">New</span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="dataSource2">
                                <div>
                                    <mat-card-content>
                                        <mat-table [dataSource]="dataSource2" matSort>
                                            <ng-container matColumnDef="select">
                                                <mat-header-cell *matHeaderCellDef>
                                                    <a [matMenuTriggerFor]="menu" class="check-all">
                                                        <span class="material-icons">
                                                            more_vert
                                                        </span>
                                                    </a>
                                                    <mat-menu #menu="matMenu">
                                                        <button mat-menu-item (click)="onSelectAllClicked()">
                                                            Select All
                                                        </button>
                                                        <button (click)="onDeSelectAllClicked()"
                                                            *ngIf="selectedNewsDocs.length > 0" mat-menu-item>Deselect
                                                            All
                                                        </button>
                                                        <button (click)="onDeleteSelected(false)"
                                                            *ngIf="selectedNewsDocs.length > 0" mat-menu-item>Delete
                                                            Selected
                                                        </button>

                                                    </mat-menu>
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row">
                                                    <mat-checkbox #matCheckbox
                                                        (change)="onNewDocSelectChange($event, row.trainerId)"
                                                        value="{{row.trainerId}}"></mat-checkbox>
                                                    <!-- <input [value]="row.id" class="user-input" #userSelect type="checkbox" (change)="onUserChanged(userSelect)"/> -->
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="id">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Trainer Id
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row">
                                                    <span> {{ row.participantId }} </span>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="trainer">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Trainer
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row">
                                                    {{ row.participantName }}
                                                </mat-cell>
                                            </ng-container>

                                            <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                                            <mat-row *matRowDef="let row; columns: columns;">
                                            </mat-row>
                                        </mat-table>
                                        <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
                                        <mat-paginator #TableOnePaginator="matPaginator" [pageSize]="5"
                                            [pageSizeOptions]="[10, 20]"></mat-paginator>

                                    </mat-card-content>
                                </div>
                                <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Employees">
                    <div style="margin-top: 33px; width: 99%; margin-left: 1px" class="row">
                        <div class="col-sm-12">
                            <div class="row top-tools">
                                <div class="col-xs-12 col-sm-12 col-md-6 left-tools">
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
                                    <div class="relative-position search-filter-con">
                                        <span class="icon material-icons ng-tns-c114-0"> search </span>
                                        <mat-form-field>
                                            <input matInput #inputSearch type="search" [(ngModel)]="filterQuery"
                                                maxlength="40" (ngModelChange)="onModelChange(filterQuery , true)"
                                                class="form-control input-sm full-data-search">
                                        </mat-form-field>
                                        <span class="mytooltip cus-left tooltip-effect-4">
                                            <span class="tooltip-item">
                                                <button mat-mini-fab
                                                    class="btn btn-primary light add-rounded margin-button-05"
                                                    (click)="addNewCourse(true)">
                                                    <i class="fa fa-plus"></i>
                                                </button>
                                            </span>
                                            <span class="tooltip-content clearfix">
                                                <span class="tooltip-text">New</span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="dataSource1">
                                <div>
                                    <mat-card-content>
                                        <mat-table [dataSource]="dataSource1" matSort>
                                            <ng-container matColumnDef="select">
                                                <mat-header-cell *matHeaderCellDef>
                                                    <a [matMenuTriggerFor]="menu" class="check-all">
                                                        <span class="material-icons">
                                                            more_vert
                                                        </span>
                                                    </a>
                                                    <mat-menu #menu="matMenu">
                                                        <button mat-menu-item (click)="onSelectAllClicked1()">
                                                            Select All
                                                        </button>
                                                        <button (click)="onDeSelectAllClicked1()"
                                                            *ngIf="selectedNewsDocs1.length > 0" mat-menu-item>Deselect
                                                            All
                                                        </button>
                                                        <button (click)="onDeleteSelected(true)"
                                                            *ngIf="selectedNewsDocs1.length > 0" mat-menu-item>Delete
                                                            Selected
                                                        </button>

                                                    </mat-menu>
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row">
                                                    <mat-checkbox #matCheckbox1
                                                        (change)="onNewDocSelectChange1($event, row.participantId)"
                                                        value="{{row.participantId}}"></mat-checkbox>
                                                    <!-- <input [value]="row.id" class="user-input" #userSelect type="checkbox" (change)="onUserChanged(userSelect)"/> -->
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="id">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Employee Id
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row">
                                                    <span> {{ row.participantId }} </span>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="participants">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Employee
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row">
                                                    {{ row.participantName }}
                                                </mat-cell>
                                            </ng-container>

                                            <mat-header-row *matHeaderRowDef="columns1"></mat-header-row>
                                            <mat-row *matRowDef="let row; columns: columns1;">
                                            </mat-row>
                                        </mat-table>
                                        <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
                                        <mat-paginator #tableTwoePaginator="matPaginator" [pageSize]="5"
                                            [pageSizeOptions]="[10, 20]"></mat-paginator>
                                    </mat-card-content>
                                </div>
                                <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Resources">
                    <div style="margin-top: 33px; width: 99%; margin-left: 1px" class="row">
                        <div class="col-sm-12">
                            <div class="row top-tools">
                                <div class="col-xs-12 col-sm-12 col-md-6 left-tools">
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
                                    <div class="relative-position search-filter-con">
                                        <span class="icon material-icons ng-tns-c114-0"> search </span>
                                        <mat-form-field>
                                            <input matInput #inputSearch type="search" [(ngModel)]="filterQuery3"
                                                maxlength="40" (ngModelChange)="onModelChange2(filterQuery3)"
                                                class="form-control input-sm full-data-search">
                                        </mat-form-field>
                                        <span class="mytooltip cus-left tooltip-effect-4">
                                            <span class="tooltip-item">
                                                <button mat-mini-fab
                                                    class="btn btn-primary light add-rounded margin-button-05"
                                                    (click)="addNewSession1()">
                                                    <i class="fa fa-plus"></i>
                                                </button>
                                            </span>
                                            <span class="tooltip-content clearfix">
                                                <span class="tooltip-text">New</span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="dataSource3">
                                <div>
                                    <mat-card-content>
                                        <mat-table [dataSource]="dataSource3" matSort>
                                            <ng-container matColumnDef="select">
                                                <mat-header-cell *matHeaderCellDef>
                                                    <a [matMenuTriggerFor]="menu" class="check-all">
                                                        <span class="material-icons">
                                                            more_vert
                                                        </span>
                                                    </a>
                                                    <mat-menu #menu="matMenu">
                                                        <button mat-menu-item (click)="onSelectAllClicked3()">
                                                            Select All
                                                        </button>
                                                        <button (click)="onDeSelectAllClicked3()"
                                                            *ngIf="selectedNewsDocs3.length > 0" mat-menu-item>Deselect
                                                            All
                                                        </button>
                                                        <button (click)="onDeleteSelected1()"
                                                            *ngIf="selectedNewsDocs3.length > 0" mat-menu-item>Delete
                                                            Selected
                                                        </button>

                                                    </mat-menu>
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row">
                                                    <mat-checkbox #matCheckbox1
                                                        (change)="onNewDocSelectChange3($event, row.id)"
                                                        value="{{row.id}}"></mat-checkbox>
                                                    <!-- <input [value]="row.id" class="user-input" #userSelect type="checkbox" (change)="onUserChanged(userSelect)"/> -->
                                                </mat-cell>
                                            </ng-container>

                                            <ng-container matColumnDef="name">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Name
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row">
                                                    <span class="cursor-pointer" (click)="viewAction2(row)">{{ row.name
                                                        }}</span>
                                                </mat-cell>
                                            </ng-container>

                                            <ng-container matColumnDef="type">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Type
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row">
                                                    {{ row.type }}
                                                </mat-cell>
                                            </ng-container>

                                            <ng-container matColumnDef="link">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Link
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row">
                                                    <a target="_blank" [href]="row.link">{{ row.link }}</a>
                                                </mat-cell>
                                            </ng-container>
                                            <!-- <ng-container matColumnDef="description">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Descriptionnnnn
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row">
                                                    {{ row.description   }}
                                                </mat-cell>
                                            </ng-container> -->
                                            <ng-container matColumnDef="order">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Order
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row">
                                                    {{ row.order }}
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="attachments">
                                                <mat-header-cell *matHeaderCellDef>Attachments</mat-header-cell>
                                                <mat-cell class="cursor-pointer" *matCellDef="let row">
                                                    <span class="mytooltip tooltip-effect-4" *ngIf="!row.hasAttachment">
                                                        <span class="tooltip-item">
                                                            <i (click)="onAddAttachment(row.id , true)"
                                                                class="single-icon-click flaticon-attachment"
                                                                style="color: #d0cdcd"></i>
                                                        </span>
                                                        <span class="tooltip-content clearfix">
                                                            <span class="tooltip-text"> No Attachments</span>
                                                        </span>
                                                    </span>

                                                    <span class="mytooltip tooltip-effect-4" *ngIf="row.hasAttachment">
                                                        <span class="tooltip-item">
                                                            <i class="single-icon-click flaticon-attachment"
                                                                style="color: #666"
                                                                (click)="onAddAttachment(row.id , true)"></i></span>
                                                        <span class="tooltip-content clearfix">
                                                            <span class="tooltip-text">Include Attachments</span>
                                                        </span>
                                                    </span>
                                                </mat-cell>
                                            </ng-container>
                                            <mat-header-row *matHeaderRowDef="columns3"></mat-header-row>
                                            <mat-row *matRowDef="let row; columns: columns3;">
                                            </mat-row>
                                        </mat-table>
                                        <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
                                        <mat-paginator #tablePaginator="matPaginator" [pageSize]="5"
                                            [pageSizeOptions]="[10, 20]"></mat-paginator>
                                    </mat-card-content>
                                </div>
                                <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-card-content>
    </mat-card>
</div>


<ng-template #addCourse let-modal>
    <!-- <div class="card my-0"> -->
    <!-- <div class="header"> -->
    <div class="mat-modal-header">
        <!-- <h4 class="card-title card-title-modal"> -->
        <h4 class="mat-title-modal">
            <span>Add Trainer</span>
        </h4>
    </div>
    <!-- <div class="card-content"> -->
    <div class="mat-modal-body">
        <app-loading *ngIf="isTrainerAdded" [isLoading]="isTrainerAdded"></app-loading>
        <!-- <form class="form-material mt-4" action="javascript:" [formGroup]="userForm" *ngIf="!isUserAdded"> -->
        <form class="form-material full-width-inputs" action="javascript:" [formGroup]="trainerForm"
            *ngIf="!isTrainerAdded">
            <div class="row form-group">
                <div class="col-sm-12">
                    <mat-form-field class="form-element">
                        <mat-label>Trainer</mat-label>
                        <input required="" class="form-control" type="text" aria-label="trainerId" matInput
                            #trigger="matAutocompleteTrigger" formControlName="trainerId" [matAutocomplete]="auto">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                            [displayWith]="displayEmployeeByName.bind(this)">
                            <mat-option *ngFor="let r of filteredOptions1 " [value]="r.value">
                                {{r.label}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error
                            *ngIf="!trainerForm.controls.trainerId.valid && trainerForm.controls.trainerId.touched">
                            please enter trainer
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="action-buttons multi-btns col-sm-12 text-right">
                <button mat-raised-button class="btn-danger" (click)="close1()">
                    <i class="flaticon-cancel"></i>Cancel
                </button>
                <button mat-raised-button class="btn btn-primary" color="primary"
                    [disabled]="!trainerForm.valid ||!this.correct" (click)="submitAddUser(false)"><i
                        class="flaticon-diskette"></i>Save
                </button>
            </div>
        </form>
    </div>
    <!-- </div> -->
</ng-template>


<ng-template #addCourse1 let-modal>
    <!-- <div class="card my-0"> -->
    <!-- <div class="header"> -->
    <div class="mat-modal-header">
        <!-- <h4 class="card-title card-title-modal"> -->
        <h4 class="mat-title-modal">
            <span>Add Employee</span>
        </h4>
    </div>
    <!-- <div class="card-content"> -->
    <div class="mat-modal-body">
        <app-loading *ngIf="isTrainerAdded" [isLoading]="isTrainerAdded"></app-loading>

        <div class="row" *ngIf="!isTrainerAdded">
            <div class="col-sm-6">
                <mat-checkbox (change)="onChangeCheck($event)" color="primary">Add Multiple
                    Employees
                </mat-checkbox>
            </div>
            <div class="col-sm-6" *ngIf="bulkAddParticipant">
                Matches ({{numberOfEmployees?.length}}) Employee(s)
            </div>
        </div>

        <div *ngIf="bulkAddParticipant && !isTrainerAdded">
            <form class="form-material full-width-inputs" action="javascript:">
                <div class="row form-group">
                    <div class="col-6">
                        <mat-form-field class="form-element">
                            <mat-select (selectionChange)="changeLocation($event)" class="form-control"
                                disableOptionCentering>
                                <mat-option value="">--Select--</mat-option>
                                <ng-container *ngFor="let n of locationList">
                                    <mat-option value="{{n.id}}"> {{ n.location }} </mat-option>
                                </ng-container>
                            </mat-select>
                            <mat-label>Location</mat-label>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field class="form-element">
                            <mat-select (selectionChange)="changesubUnit($event)" class="form-control"
                                disableOptionCentering>
                                <mat-option value="">--Select--</mat-option>
                                <ng-container *ngFor="let j of temporaryDepartment">
                                    <mat-option value="{{j.id}}">{{ j.name }}</mat-option>
                                </ng-container>
                            </mat-select>
                            <mat-label for="des">Sub Unit</mat-label>
                        </mat-form-field>
                    </div>
                </div>
                <div class="action-buttons multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close1()">
                        <i class="flaticon-cancel"></i>Cancel
                    </button>
                    <button mat-raised-button class="btn btn-primary" color="primary"
                        [disabled]="numberOfEmployees.length <= 0" (click)="submitAddUser4()"><i
                            class="flaticon-diskette"></i>Save
                    </button>
                </div>
            </form>
        </div>


        <form class="form-material full-width-inputs" action="javascript:" [formGroup]="participantForm"
            *ngIf="!isTrainerAdded && !bulkAddParticipant">
            <div class="row form-group">
                <div class="col-sm-12">
                    <mat-form-field class="form-element">
                        <mat-label>Employee</mat-label>
                        <!-- <input  placeholder="Type for hints .." required="" class="form-control" type="text"
                               aria-label="participantId"
                               matInput
                               formControlName="participantId"
                               [matAutocomplete]="auto1"

                        >
                        <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displayEmployeeByName.bind(this)">
                            <mat-option *ngFor="let r of filteredOptions2 " [value]="r.value">
                                {{r.label}}
                            </mat-option>
                        </mat-autocomplete> -->
                        <input required placeholder="Type for hints .." class="form-control" type="text"
                            aria-label="Employee" matInput [matAutocomplete]="auto"
                            [ngModelOptions]="{standalone: true}" [(ngModel)]="participantId" [formControl]="empCtrl1">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayEmployeeByName.bind(this)">
                            <mat-option *ngFor="let n of filteredOptions4" [value]="n.value">
                                {{n.label}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error
                            *ngIf="!participantForm.controls.participantId.valid && participantForm.controls.participantId.touched">
                            please enter Employee
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="action-buttons multi-btns col-sm-12 text-right">
                <button mat-raised-button class="btn-danger" (click)="close1()">
                    <i class="flaticon-cancel"></i>Cancel
                </button>
                <button mat-raised-button class="btn btn-primary" color="primary"
                    [disabled]="this.participantId==='' ||!this.correct" (click)="submitAddUser(true)"><i
                        class="flaticon-diskette"></i>Save
                </button>
            </div>
        </form>
    </div>
    <!-- </div> -->
</ng-template>


<ng-template #deleteCourse let-modal>
    <!-- <div class="card my-0"> -->
    <div mat-dialog-actions>
        <!-- <div class="header">  -->
        <div class="mat-modal-header">
            <!-- <h4 class="card-title card-title-modal">Delete User</h4>  -->
            <h4 class="mat-title-modal">Delete </h4>
        </div>
        <!-- <div class="card-content px-2 py-2"> -->
        <div class="mat-modal-body">
            <div class="text-left are-u">Are you sure ?</div>
            <div class="row">
                <div class="multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close1()">
                        <i class="flaticon-cancel"></i>Cancel
                    </button>
                    <button class="btn-primary" color="primary" mat-raised-button (click)="deleteMultiple(false)"><i
                            class="flaticon-trash"></i>Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #deleteCourse1 let-modal>
    <!-- <div class="card my-0"> -->
    <div mat-dialog-actions>
        <!-- <div class="header">  -->
        <div class="mat-modal-header">
            <!-- <h4 class="card-title card-title-modal">Delete User</h4>  -->
            <h4 class="mat-title-modal">Delete </h4>
        </div>
        <!-- <div class="card-content px-2 py-2"> -->
        <div class="mat-modal-body">
            <div class="text-left are-u">Are you sure ?</div>
            <div class="row">
                <div class="multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close1()">
                        <i class="flaticon-cancel"></i>Cancel
                    </button>
                    <button class="btn-primary" color="primary" mat-raised-button (click)="deleteMultiple(true)"><i
                            class="flaticon-trash"></i>Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #addSession let-modal>
    <!-- <div class="card my-0"> -->
    <!-- <div class="header"> -->
    <div class="mat-modal-header">
        <!-- <h4 class="card-title card-title-modal"> -->
        <h4 class="mat-title-modal">
            <span>Add Session</span>
        </h4>
    </div>
    <!-- <div class="card-content"> -->
    <div class="mat-modal-body">
        <app-loading *ngIf="isSessionAdded" [isLoading]="isSessionAdded"></app-loading>
        <!-- <form class="form-material mt-4" action="javascript:" [formGroup]="userForm" *ngIf="!isUserAdded"> -->
        <form class="form-material full-width-inputs" action="javascript:" [formGroup]="sessionForm"
            *ngIf="!isSessionAdded">
            <div class="row form-group">
                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <mat-label>Name</mat-label>
                        <input class="form-control" formControlName="sessionName" matInput maxlength="40" required=""
                            type="text">
                    </mat-form-field>
                </div>
                <div class="col-sm-6" style="margin-top: 20px">
                    <mat-checkbox #useCourseData [(ngModel)]="useCourseData.checked" color="primary">Use Course Data
                    </mat-checkbox>
                </div>
                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <input required="" [matDatepicker]="picker2" class="form-control" formControlName="startDate"
                            matInput>
                        <mat-datepicker-toggle [for]="picker2" matSuffix></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                        <mat-label for="des">Start Date</mat-label>
                    </mat-form-field>
                </div>
                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <input [matDatepicker]="picker3" class="form-control" formControlName="endDate" matInput>
                        <mat-datepicker-toggle [for]="picker3" matSuffix></mat-datepicker-toggle>
                        <mat-datepicker #picker3></mat-datepicker>
                        <mat-label for="endDate">End Date</mat-label>
                    </mat-form-field>
                </div>
                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <mat-label>Delivery Location</mat-label>
                        <input class="form-control" formControlName="deliveryLocation" id="deliveryLocation" matInput
                            maxlength="40" type="text">
                    </mat-form-field>
                </div>

                <div class="col-sm-6">
                    <div class="row">
                        <div class="col-6">
                            <mat-form-field class="form-element">
                                <mat-label>delivery Method</mat-label>
                                <mat-select class="form-control" formControlName="deliveryMethod">
                                    <mat-option value="classRoom">CLass Room</mat-option>
                                    <mat-option value="selfStudy">Self Study</mat-option>
                                    <mat-option value="webEx">Web Ex</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-6">
                            <mat-form-field class="form-element">
                                <mat-label>Status</mat-label>
                                <mat-select class="form-control" formControlName="status">
                                    <mat-option value="pending">Pending</mat-option>
                                    <mat-option value="scheduled">Scheduled</mat-option>
                                    <mat-option value="complete">Complete</mat-option>
                                    <mat-option value="cancelled">Cancelled</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12">
                    <mat-form-field class="form-element">
                        <mat-label>Description</mat-label>
                        <textarea class="form-control" matInput placeholder="Description"
                            formControlName="description"></textarea>
                    </mat-form-field>
                </div>

            </div>
            <div class="action-buttons multi-btns col-sm-12 text-right">
                <button mat-raised-button class="btn-danger" (click)="close1()">
                    <i class="flaticon-cancel"></i>Cancel
                </button>
                <button mat-raised-button class="btn btn-primary" color="primary" [disabled]="!sessionForm.valid"
                    (click)="submitAddUser2()"><i class="flaticon-diskette"></i>Save
                </button>
            </div>
        </form>
    </div>
    <!-- </div> -->
</ng-template>

<ng-template #deleteSession let-modal>
    <!-- <div class="card my-0"> -->
    <div mat-dialog-actions>
        <!-- <div class="header">  -->
        <div class="mat-modal-header">
            <!-- <h4 class="card-title card-title-modal">Delete User</h4>  -->
            <h4 class="mat-title-modal">Delete Session</h4>
        </div>
        <!-- <div class="card-content px-2 py-2"> -->
        <div class="mat-modal-body">
            <div class="text-left are-u">Are you sure ?</div>
            <div class="row">
                <div class="multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close1()">
                        <i class="flaticon-cancel"></i>Cancel
                    </button>
                    <button class="btn-primary" color="primary" mat-raised-button (click)="deleteMultiple2()"><i
                            class="flaticon-trash"></i>Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #addSession1 let-modal>
    <!-- <div class="card my-0"> -->
    <!-- <div class="header"> -->
    <div class="mat-modal-header">
        <!-- <h4 class="card-title card-title-modal"> -->
        <h4 class="mat-title-modal">
            <span>Add Resource</span>
        </h4>
    </div>
    <!-- <div class="card-content"> -->
    <div class="mat-modal-body">
        <app-loading *ngIf="isSessionAdded" [isLoading]="isSessionAdded"></app-loading>
        <!-- <form class="form-material mt-4" action="javascript:" [formGroup]="userForm" *ngIf="!isUserAdded"> -->
        <form class="form-material full-width-inputs" action="javascript:" [formGroup]="resourceForm"
            *ngIf="!isSessionAdded">
            <div class="row form-group">
                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <mat-label>Name</mat-label>
                        <input class="form-control" formControlName="name" matInput maxlength="40" required=""
                            type="text">
                    </mat-form-field>
                </div>

                <div class="col-6">
                    <mat-form-field class="form-element">
                        <mat-label>Type</mat-label>
                        <mat-select class="form-control" formControlName="type">
                            <mat-option value="video">Video</mat-option>
                            <mat-option value="document">Document</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <mat-label>Link</mat-label>
                        <input class="form-control" formControlName="link" id="link" matInput maxlength="40"
                            type="text">
                    </mat-form-field>
                </div>
                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <mat-label>Order</mat-label>
                        <input class="form-control" formControlName="order" id="order" matInput maxlength="40"
                            type="text">
                    </mat-form-field>
                </div>

                <div class="col-sm-12">
                    <mat-form-field class="form-element">
                        <mat-label>Description</mat-label>
                        <textarea class="form-control" matInput placeholder="Description"
                            formControlName="description"></textarea>
                    </mat-form-field>
                </div>

            </div>
            <div class="action-buttons multi-btns col-sm-12 text-right">
                <button mat-raised-button class="btn-danger" (click)="close1()">
                    <i class="flaticon-cancel"></i>Cancel
                </button>
                <button mat-raised-button class="btn btn-primary" color="primary" [disabled]="!resourceForm.valid"
                    (click)="submitAddUser3()"><i class="flaticon-diskette"></i>Save
                </button>
            </div>
        </form>
    </div>
    <!-- </div> -->
</ng-template>


<ng-template #deleteSession3 let-modal>
    <!-- <div class="card my-0"> -->
    <div mat-dialog-actions>
        <!-- <div class="header">  -->
        <div class="mat-modal-header">
            <!-- <h4 class="card-title card-title-modal">Delete User</h4>  -->
            <h4 class="mat-title-modal">Delete Resource</h4>
        </div>
        <!-- <div class="card-content px-2 py-2"> -->
        <div class="mat-modal-body">
            <div class="text-left are-u">Are you sure ?</div>
            <div class="row">
                <div class="multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close1()">
                        <i class="flaticon-cancel"></i>Cancel
                    </button>
                    <button class="btn-primary" color="primary" mat-raised-button (click)="deleteMultiple3()"><i
                            class="flaticon-trash"></i>Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #attach let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">Attachments</h4>
        </div>
        <div class="mat-modal-body">
            <form class="form-material full-width-inputs">
                <div class="row">
                    <div class="col-md-12 border" style="height: 200px !important; overflow-y: auto">
                        <mat-spinner *ngIf="this.showSpinner"></mat-spinner>
                        <mat-card *ngIf="showSpinner == false && this.attachmentList.length === 0">
                            No Attachment Found
                        </mat-card>
                        <ng-container *ngFor="let attachment of attachmentList; let i = index;">
                            <mat-card class="mt-2 shadow-sm">
                                <mat-card-title>
                                    {{attachment.fileName}}
                                </mat-card-title>
                                <mat-card-content>
                                    <b>Added On :</b> {{attachment.added | date :'dd/MM/yyyy' }} <br>
                                    <b>comments :</b> {{attachment.desc}}
                                </mat-card-content>
                                <mat-card-actions align="end">
                                    <button (click)="onDownloadClicked(attachment)" mat-icon-button
                                        aria-label="Example icon button with a vertical three dot icon">
                                        <mat-icon color="primary">cloud_download</mat-icon>
                                    </button>
                                </mat-card-actions>
                            </mat-card>
                        </ng-container>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="action-buttons col-md-12 text-right">
                        <button mat-raised-button class="btn-danger" (click)="close1()">
                            <i class="flaticon-cancel"></i>Cancel
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<ng-template #attach1 let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">Attachments</h4>
        </div>
        <div class="mat-modal-body">
            <form class="form-material full-width-inputs">
                <div class="row">
                    <div class="col-md-3">
                        <div class="upload-btn-wrapper">
                            <button class="upload-btn"><i style="padding-right: 5px"
                                    class="flaticon-attachment"></i>Attachment</button>
                            <input type="file" name="myfile" class="form-control" (change)="fileChange($event, 'dd')">
                            <div class="fileName">{{this.fileObj && this.fileObj.fileName}}
                                <i *ngIf="fileUrl" class="fa fa-check success"></i>
                            </div>
                        </div>
                        <div class="upload-btn-wrapper">
                        </div>
                        <div class="file-error text-danger" *ngIf="!fileUrl && fileError">{{fileError}}</div>

                    </div>
                    <div class="help-text">Accept Up TO 2MB</div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field class="form-element">
                            <mat-label>Comment</mat-label>
                            <textarea matInput name="name" [(ngModel)]="comment" class="form-control"></textarea>
                        </mat-form-field>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-12 border" style="height: 200px !important; overflow-y: auto">
                        <mat-spinner *ngIf="this.showSpinner"></mat-spinner>
                        <mat-card *ngIf="showSpinner == false && this.attachmentList.length === 0">
                            No Attachment Found
                        </mat-card>
                        <ng-container *ngFor="let attachment of attachmentList; let i = index;">
                            <mat-card class="mt-2 shadow-sm">
                                <mat-card-title>
                                    {{attachment.fileName}}
                                </mat-card-title>
                                <mat-card-content>
                                    <b>Added On :</b> {{attachment.added | date :'dd/MM/yyyy' }} <br>
                                    <b>comments :</b> {{attachment.desc}}
                                </mat-card-content>
                                <mat-card-actions align="end">
                                    <button (click)="onDeleteClicked(attachment)" mat-icon-button
                                        aria-label="Example icon button with a vertical three dot icon">
                                        <mat-icon color="warn">delete</mat-icon>
                                    </button>
                                    <button (click)="onDownloadClicked(attachment)" mat-icon-button
                                        aria-label="Example icon button with a vertical three dot icon">
                                        <mat-icon color="primary">cloud_download</mat-icon>
                                    </button>
                                </mat-card-actions>
                            </mat-card>
                        </ng-container>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="action-buttons col-md-12 text-right">
                        <button [disabled]="isClicked" mat-raised-button class="btn-primary" (click)="saveAttachment()"
                            color="primary"><i class="flaticon-diskette"></i>Save
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>