import { Component, OnInit } from "@angular/core";
import { DashboardService } from "../../../dashboard.service";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { NotificationService } from "src/app/core/services/notification.service";
import { CrudService } from "src/app/core/services/base-crud.service";
import { Configuration_AttendanceId } from "src/app/service/constants";

@Component({
  selector: "app-configuration",
  templateUrl: "./configuration.component.html",
  styleUrls: ["./configuration.component.scss"],
  providers: [CrudService],
})
export class ConfigurationComponent implements OnInit {
  public configList: FormGroup;
  public isLoading: boolean;
  public err: string;

  constructor(private genericConfigurationService: CrudService<any>, private fb: FormBuilder, private toastr: NotificationService) {
    this.createForm();
    this.genericConfigurationService.setEntityName("genericConfiguration");
  }

  ngOnInit() {
    this.getConfig();
  }

  public getConfig(): void {
    if (this.genericConfigurationService.attendanceConfigCache != null) {
      this.configList.patchValue(this.genericConfigurationService.attendanceConfigCache);
    } else {
      this.isLoading = true;
      this.genericConfigurationService.getById(Configuration_AttendanceId).subscribe(
        (resp) => {
          if (resp && resp.data) {
            this.genericConfigurationService.attendanceConfigCache = resp.data;
            this.configList.patchValue(resp.data);
            this.isLoading = false;
            this.err = "";
          }
        },
        (error) => {
          const err = error && error.error.msg;
          this.toastr.error(err || "Error in getting configuration");
          this.isLoading = false;
          this.err = err;
        }
      );
    }
  }

  public createForm(): void {
    this.configList = this.fb.group({
      attendance_employeePermission: [true],
      attendance_supervisiorPermission: [true],
      attendance_forceEmployeePunchByApp : [false]
    });
  }

  public saveConfig(): void {
    this.isLoading = true;
    this.genericConfigurationService.update(this.configList.value, Configuration_AttendanceId).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.genericConfigurationService.attendanceConfigCache = resp.data;
          this.configList.patchValue(resp.data);
          this.isLoading = false;
          this.toastr.success("Saved successfully.");
        }
      },
      (error) => {
        const err = error && error.error.msg;
        this.isLoading = false;
        this.toastr.error(err || "Error in getting configuration");
      }
    );
  }
}
