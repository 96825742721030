import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DashboardService } from "../../dashboard.service";
import { AuthService } from "../../../core/services/auth.service";
import { FormGroup, FormBuilder, FormArray, Validators, FormControl } from "@angular/forms";
import { NotificationService } from "src/app/core/services/notification.service";
import { Observable, Subject } from "rxjs";
import { MatTableDataSource } from "@angular/material/table";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { startWith, takeUntil } from "rxjs/operators";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-apply-leave",
  templateUrl: "./apply-leave.component.html",
  styleUrls: ["./apply-leave.component.scss"],
})
export class ApplyLeaveComponent implements OnInit {
  @ViewChild("datediff", { static: false }) datediff: ElementRef;
  @ViewChild("timediff", { static: false }) timediff: ElementRef;
  @ViewChild("leaveBalance", { static: false }) leaveBalance;
  @ViewChild("leaveOverlapp", { static: false }) leaveOverlapp;
  @ViewChild("balanceDialog", { static: false }) balanceDialog;
  @ViewChild("noentitlementDialog", { static: false }) noentitlementDialog;

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public filterForm: FormGroup;

  public leaveForm: FormGroup;
  public employeeList = [];
  public leaveTypeList = [];
  public paginator;

  public today;
  public todayDate;
  public dialogRef: any;
  public employeeListErr: string;
  public leaveBalanceRemaining: any;
  public modalRef: any;
  public empNomineeEnabled: boolean = false;
  public showBalance: boolean = false;
  public index: number;
  public checkbalanceArr = [];
  public checkbalanceArr2 = [];
  public columns = ["noOfDays", "startDate", "endDate", "note"];
  public overCloumns = ["date", "duration", "leaveType", "status", "comments"];
  public balanceLoading: boolean = false;
  public balanceRequest: any = [];

  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  overSource: MatTableDataSource<any> = new MatTableDataSource([]);
  public leavetypeid = "";
  public leavetype = "";
  public duration = "";
  public duration2 = "";

  public durationOne = "";
  // private List<EmployeeLeaveNominated> employeeLeaveNominateds;
  public startdate: Date = null;
  public enddate: Date = null;
  public leavecomments = "";
  public employeeid = "";
  public partialDays = "none";
  public durations = "";
  public durationsTypeday = "";
  public durationsOne = "";
  public durationsTypedayOne = "morning";
  public durations2 = "";
  public durationsTypeday2 = "";

  public startTimeOne = "";
  public endTimeOne = "";
  public startTime = "";
  public endTime = "";
  public startTime2 = "";
  public endTime2 = "";

  public nominatedempid1: any;
  public comment1 = "";
  public nominatedempid2: any;
  public comment2 = "";
  public nominatedempid3: any;
  public comment3 = "";
  public checkTime: boolean = false;
  public checkTime2: boolean = false;

  public mainId = "";
  public checkDate: boolean = false;
  public balance: number = 0;
  public totalDaysDuration: number = 0;

  public submitLoading: boolean;

  minDate: Date;
  maxDate: Date;

  constructor(
    private service: DashboardService,
    private fb: FormBuilder,
    private toastr: NotificationService,
    private dialog: MatDialog,
    private readonly authService: AuthService,
    private modalService: NgbModal
  ) {
    this.createForm();
    this.setDefaultValues();
  }

  public permissions: any;
  public read = false;
  public write = false;
  public update = false;
  public delete = false;

  checkPermission(module, tabs): void {
    this.permissions = JSON.parse(this.authService.getPermissions());

    this.read = false;
    this.write = false;
    this.delete = false;
    this.update = false;
    if (this.permissions != undefined && this.permissions != null) {
      for (let p of this.permissions) {
        if (p.id === module) {
          if (p.modules != undefined) {
            for (let tab of p.modules) {
              if (tab.id === tabs) {
                for (let move of tab.permits) {
                  if (move.id === "u") {
                    this.update = true;
                  } else if (move.id === "r") {
                    this.read = true;
                  } else if (move.id === "c") {
                    this.write = true;
                  } else if (move.id === "d") {
                    this.delete = true;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  getMinDate = () => {
    const date = new Date();
    const d = date.getDate();
    const m = date.getMonth() + 1;
    const y = date.getFullYear();
    let mStr: string;
    if (m < 10) {
      mStr = "0" + m;
    } else {
      mStr = m.toString();
    }
    return y + "-" + mStr + "-" + d;
  };

  setDefaultValues() {
    this.duration = "08:00";
    this.duration2 = "08:00";
    this.durationOne = "08:00";
    this.durations = "SpecificTime";
    this.durationsTypeday = "morning";
    this.durationsOne = "fullDay";
    this.durationsTypedayOne = "morning";
    this.durations2 = "SpecificTime";
    this.durationsTypeday2 = "morning";
    this.checkTime = false;
    this.checkTime2 = false;
    this.startTimeOne = "09:00";
    this.endTimeOne = "17:00";
    this.startTime = "09:00";
    this.endTime = "17:00";
    this.startTime2 = "09:00";
    this.endTime2 = "17:00";
  }

  ngOnInit() {
    this.checkPermission("leave", "le_asl");
    this.todayDate = this.getMinDate();
    this.today = new Date();
    this.today.setDate(this.today.getDate());
    this.today = this.today.toJSON().split("T")[0];

    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear , 0);
    this.maxDate = new Date(currentYear , 11, 31);

    this.service.getLeaveTypeList().subscribe((resp) => {
      if (resp && resp.data) {
        this.leaveTypeList = resp.data;

        this.service.getLeaveTypeListForEmployee("").subscribe(
          (resp) => {
            if (resp && resp.data) {
              let leaves = resp.data;
              let temp = [];
              for (let leave of this.leaveTypeList) {
                for (let l of leaves) {
                  if (leave.id === l.id) {
                    temp.push(leave);
                  }
                }
              }
              this.leaveTypeList = temp;
            }
          },
          (error) => {
            const err = error.error && error.error.msg;
            this.toastr.error(err || "Error in leave Type");
          }
        );
      }
    });
  }

  changeLeavetypeByEmployeenumber(id) {
    this.leavetypeid = "";
    if (id != undefined) {
      this.service.getLeaveTypeListForEmployee(id).subscribe(
        (resp) => {
          if (resp && resp.data) {
            this.leaveTypeList = resp.data;
          }
        },
        (error) => {
          const err = error.error && error.error.msg;
          this.toastr.error(err || "Error in leave Type");
        }
      );
    }
  }

  

  public checkStartDate(): void {
    if (this.startdate != null && this.startdate != undefined && this.enddate != null && this.enddate != undefined) {
      var date1 = new Date(this.startdate);
      var date2 = new Date(this.enddate);

      if (date1.setHours(0, 0, 0, 0).valueOf() > date2.setHours(0, 0, 0, 0).valueOf()) {
        this.checkDate = true;
        return;
      }
    }

    this.checkDate = false;
  }

  public checkEndDate(): void {
    if (this.startdate != null && this.startdate != undefined && this.enddate != null && this.enddate != undefined) {
      var date1 = new Date(this.startdate);
      var date2 = new Date(this.enddate);
      if (date1.setHours(0, 0, 0, 0) > date2.setHours(0, 0, 0, 0)) {
        this.checkDate = true;
        return;
      }
    }

    this.checkDate = false;
  }

  public createForm(): void {
    this.leaveForm = this.fb.group({
      employeeid: [""],
      enddate: ["", Validators.required],
      leavecategory: [""],
      leavestatustype: ["Pending"],
      leavetype: [""],
      leavetypeid: ["", Validators.required],
      startdate: ["", Validators.required],
      partialdays: [""],
      employeeLeaveLists: this.fb.array([]),
      employeeLeaveNominateds: this.fb.array([
        this.fb.group({
          comment: [""],
          employeeid: [""],
          nominatedempid: [""],
          nominatedempname: [""],
        }),
        this.fb.group({
          comment: [""],
          employeeid: [""],
          id: [""],
          nominatedempid: [""],
          nominatedempname: [""],
        }),
        this.fb.group({
          comment: [""],
          employeeid: [""],
          id: [""],
          nominatedempid: [""],
          nominatedempname: [""],
        }),
      ]),

      leavecomments: this.fb.array([
        this.fb.group({
          commentby: [""],
          commentdate: [null],
          commnet: [""],
          commnetbyid: [""],
        }),
      ]),
    });
  }

  public dateDiff() {
    const start = new Date(this.leaveForm.value.startdate);
    const end = new Date(this.leaveForm.value.enddate);
    const diff = Math.abs(start.getTime() - end.getTime());
    const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    for (let i = 0; i <= diffDays; i = i + 1) {
      this.empList.push(this.getFormGroup());
    }
  }

  public get empList(): FormArray {
    return this.leaveForm.get("employeeLeaveLists") as FormArray;
  }
  public getLeaveTypeForEmployee() {
    const id = this.leaveForm.value.employeeid;
    this.service.getLeaveTypeListCountryWise(id).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.leaveTypeList = resp.data;
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error in getting leave type");
      }
    );
  }

  public selectLeaveType(event) {
    const id = event;
    for (let val of this.leaveTypeList) {
      console.log(val);
      if (val.id == id) {
        this.leavetypeid = val.id;
        this.empNomineeEnabled = val.empNomineeEnabled;
        this.showBalance = val.showBalance;
      }
    }
    if (this.showBalance) {
      this.getBalance();
    }
  }

  public getFormGroup(): FormGroup {
    return this.fb.group({
      durations: [""],
      durationsTypeTime: this.fb.group({
        durationtime: [""],
        endtime: [""],
        fromtime: [""],
      }),
      durationsTypeday: [""],
      employeeleaveid: [""],
      id: [""],
      leavebalance: [0.0],
      leavecategory: [""],
      leavecomments: this.fb.array([
        this.fb.group({
          commentby: [""],
          commentdate: [null],
          commnet: [""],
          commnetbyid: [""],
          id: [""],
        }),
      ]),
      leavedate: [null],
      leavestatustype: ["Pending"],
      leavetype: [""],
      leavetypeid: [""],
      partialdays: [""],
    });
  }

  calcDurationStart(event): void {
    if (this.endTime === "") {
      this.duration = "Invalid";
      return;
    }
    const shiftInTimeInMinutes = this.convertTimeToMinutes(event);
    const shiftOutTimeInMinutes = this.convertTimeToMinutes(this.endTime);
    if (shiftOutTimeInMinutes < shiftInTimeInMinutes) {
      this.checkTime = true;
      this.duration = "Invalid";
      return;
    }
    this.checkTime = false;
    const duration = shiftOutTimeInMinutes - shiftInTimeInMinutes;
    const durationTime = this.convertMinutesToTime(duration);
    this.duration = durationTime;
  }

  calcDurationEnd(event) {
    if (this.startTime === "") {
      this.duration = "Invalid";
      return;
    }

    const shiftInTimeInMinutes = this.convertTimeToMinutes(this.startTime);
    const shiftOutTimeInMinutes = this.convertTimeToMinutes(event);

    if (shiftOutTimeInMinutes < shiftInTimeInMinutes) {
      this.checkTime = true;
      this.duration = "Invalid";
      return;
    }
    this.checkTime = false;

    const duration = shiftOutTimeInMinutes - shiftInTimeInMinutes;
    const durationTime = this.convertMinutesToTime(duration);
    this.duration = durationTime;
  }

  calcDurationStart2(event): void {
    if (this.endTime2 === "") {
      this.duration2 = "Invalid";
      return;
    }
    this.checkTime2 = false;
    const shiftInTimeInMinutes = this.convertTimeToMinutes(event);
    const shiftOutTimeInMinutes = this.convertTimeToMinutes(this.endTime2);
    if (shiftOutTimeInMinutes < shiftInTimeInMinutes) {
      this.checkTime2 = true;
      this.duration2 = "Invalid";
      return;
    }
    this.checkTime2 = false;
    const duration = shiftOutTimeInMinutes - shiftInTimeInMinutes;
    const durationTime = this.convertMinutesToTime(duration);
    this.duration2 = durationTime;
  }

  calcDurationEnd2(event) {
    if (this.startTime2 === "") {
      this.duration2 = "Invalid";
      return;
    }

    const shiftInTimeInMinutes = this.convertTimeToMinutes(this.startTime2);
    const shiftOutTimeInMinutes = this.convertTimeToMinutes(event);

    if (shiftOutTimeInMinutes < shiftInTimeInMinutes) {
      this.checkTime2 = true;
      this.duration2 = "Invalid";
      return;
    }
    this.checkTime2 = false;
    console.log(shiftInTimeInMinutes);
    console.log(shiftOutTimeInMinutes);
    const duration = shiftOutTimeInMinutes - shiftInTimeInMinutes;
    const durationTime = this.convertMinutesToTime(duration);
    this.duration2 = durationTime;
  }

  calcDurationStartOne(event): void {
    if (this.endTimeOne === "") {
      this.duration = "Invalid";
      return;
    }
    const shiftInTimeInMinutes = this.convertTimeToMinutes(event);
    const shiftOutTimeInMinutes = this.convertTimeToMinutes(this.endTimeOne);
    if (shiftOutTimeInMinutes < shiftInTimeInMinutes) {
      this.checkTime = true;
      this.durationOne = "Invalid";
      return;
    }
    this.checkTime = false;
    const duration = shiftOutTimeInMinutes - shiftInTimeInMinutes;
    const durationTime = this.convertMinutesToTime(duration);
    this.durationOne = durationTime;
  }

  calcDurationEndOne(event) {
    if (this.startTimeOne === "") {
      this.durationOne = "Invalid";
      return;
    }

    const shiftInTimeInMinutes = this.convertTimeToMinutes(this.startTimeOne);
    const shiftOutTimeInMinutes = this.convertTimeToMinutes(event);

    if (shiftOutTimeInMinutes < shiftInTimeInMinutes) {
      this.checkTime = true;
      this.durationOne = "Invalid";
      return;
    }
    this.checkTime = false;
    const duration = shiftOutTimeInMinutes - shiftInTimeInMinutes;
    const durationTime = this.convertMinutesToTime(duration);
    this.durationOne = durationTime;
  }

  convertTimeToMinutes(time) {
    const hourMinutes = time.split(":")[0] * 60;
    return hourMinutes + parseInt(time.split(":")[1]);
  }

  convertMinutesToTime(minutes) {
    let time = "";
    const hours = minutes / 60;
    const tempNewHour = Math.floor(hours);
    const newMinutes = (hours - tempNewHour) * 60;
    const tempMinutes = Math.round(newMinutes);
    if (tempNewHour < 10 && tempMinutes < 10) {
      time = "0" + tempNewHour + ":0" + tempMinutes;
    } else if (tempNewHour < 10) {
      time = "0" + tempNewHour + ":" + tempMinutes;
    } else if (tempMinutes < 10) {
      time = tempNewHour + ":0" + tempMinutes;
    }

    return time;
  }

  public getTotalHrs(from, to): any {
    if (from && to) {
      const f = moment(from, ["h:mm A"]).format("HH:mm");
      const t = moment(to, ["h:mm A"]).format("HH:mm");

      const startTime = moment(f, "hh:mm");
      const endTime = moment(t, "hh:mm");
      const min = endTime.diff(startTime, "minutes") / 60;
      return ("0" + endTime.diff(startTime, "hours")).slice(-2) + ":" + ("0" + (min - Math.floor(min)) * 60).slice(-2);
    }
  }

  public submitLeave(): void {
    this.balanceRequest = null;
    this.balance = 0;
    if (this.checkDate) {
      this.toastr.error("Start date must be before End date !");
      return;
    }

    if (this.checkTime || this.checkTime2) {
      this.toastr.error("From time should be before to time !");
      return;
    }

    var date1 = new Date(this.leaveForm.value?.startdate);
    var date2 = new Date(this.leaveForm.value?.enddate);
    console.log("date1 : ", date1, "  ,date2 : " + date1);

    var obj = {
      employeeLeaveNominateds: [],
      employeeid: "",
      enddate: new Date(),
      leavetypeid: "",
      partialDays: "",
      partialDaysList: [],
      startdate: new Date(),
      startdate_string: "",
      enddate_string: "",
      comment: null,
    };
    obj.employeeid = this.employeeid;
    obj.leavetypeid = this.leavetypeid;
    obj.startdate = date1;
    obj.enddate = date2;
    obj.startdate_string = date1.getDate() + "/" + (date1.getMonth() + 1) + "/" + date1.getFullYear();
    obj.enddate_string = date2.getDate() + "/" + (date2.getMonth() + 1) + "/" + date2.getFullYear();
    // console.log("startdate : ",this.startdate, "  ,enddate : "+this.enddate);
    obj.partialDays = this.partialDays;
    var partialDay = {
      durations: "",
      durationsTypeday: "",
      endTime: "",
      startTime: "",
    };

    if (date1.setHours(0, 0, 0, 0).valueOf() === date2.setHours(0, 0, 0, 0).valueOf()) {
      obj.partialDays = "all";
      partialDay.durations = this.durationsOne;
      partialDay.durationsTypeday = this.durationsTypedayOne;
      partialDay.endTime = this.endTimeOne;
      partialDay.startTime = this.startTimeOne;
      obj.partialDaysList.push(partialDay);
    } else {
      if (this.partialDays === "none") {
        obj.partialDaysList = [];
      } else if (this.partialDays === "sten") {
        partialDay.durations = this.durations;
        partialDay.durationsTypeday = this.durationsTypeday;
        partialDay.endTime = this.endTime;
        partialDay.startTime = this.startTime;
        obj.partialDaysList.push(partialDay);
        var partialDay = {
          durations: "",
          durationsTypeday: "",
          endTime: "",
          startTime: "",
        };
        partialDay.durations = this.durations2;
        partialDay.durationsTypeday = this.durationsTypeday2;
        partialDay.endTime = this.endTime2;
        partialDay.startTime = this.startTime2;
        obj.partialDaysList.push(partialDay);
      } else if (this.partialDays === "en" || this.partialDays === "all" || this.partialDays === "st") {
        partialDay.durations = this.durations;
        partialDay.durationsTypeday = this.durationsTypeday;
        partialDay.endTime = this.endTime;
        partialDay.startTime = this.startTime;
        obj.partialDaysList.push(partialDay);
      }
    }

    if (this.nominatedempid1) {
      var nomi = {
        comment: "",
        nominatedempid: "",
      };
      nomi.comment = this.comment1;
      nomi.nominatedempid = this.nominatedempid1.id;
      obj.employeeLeaveNominateds.push(nomi);
    }

    if (this.nominatedempid2) {
      var nomi = {
        comment: "",
        nominatedempid: "",
      };
      nomi.comment = this.comment2;
      nomi.nominatedempid = this.nominatedempid2.id;
      obj.employeeLeaveNominateds.push(nomi);
    }

    if (this.nominatedempid3) {
      var nomi = {
        comment: "",
        nominatedempid: "",
      };
      nomi.comment = this.comment3;
      nomi.nominatedempid = this.nominatedempid3.id;
      obj.employeeLeaveNominateds.push(nomi);
    }
    if (this.leavecomments != "") {
      obj.comment = this.leavecomments;
    }

    this.submitLoading = true;
    this.service.applyLeaves(obj).subscribe(
      (resp) => {
        if (resp && resp.data) {
          if (resp.data.result === "ok") {
            this.toastr.success("Leaves applied successfully");
            this.reset();
          } else if (resp.data.result === "overlap") {
            this.overSource = new MatTableDataSource<any>(resp.data.overlapped);
            this.dialogRef = this.dialog.open(this.leaveOverlapp, {
              width: "660px",
              autoFocus: false,
              maxHeight: "90vh",
            });
          } else if (resp.data.result === "balance") {
            this.balance = resp.data.balance;
            this.totalDaysDuration = resp.data.totalDurationDays;
            this.balanceRequest = resp.data;
            this.dialogRef = this.dialog.open(this.balanceDialog, {
              width: "560px",
              height: "auto",
            });
          } else if (resp.data.result === "noEntitlement") {
            this.leavetype = resp.data.leaveType;
            this.dialogRef = this.dialog.open(this.noentitlementDialog, {
              width: "560px",
              height: "auto",
            });
          }
          this.submitLoading = false;
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error in applying leaves");
        this.submitLoading = false;
      }
    );
  }

  private reset() {
    this.leavetypeid = null;
    this.leaveForm.reset();
    this.leaveForm.markAsPristine();
    this.leaveForm.markAsUntouched();
    this.showBalance = false;
  }

  submitBalanceLeave(): void {
    this.service.assignLeavesWithBalance(this.balanceRequest).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.toastr.success("Leaves applied successfully");
          this.closeModal();

          this.leaveForm.reset();
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error in applying leaves");
      }
    );
  }

  public getBalance(): void {
    this.service.checkLeaveBalance(this.employeeid, this.leavetypeid, moment().format("YYYY-MM-DD")).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.checkbalanceArr = resp.data;
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error in getting leave balance !");
      }
    );
  }

  public checkLeaveBalance(): void {
    this.balanceLoading = true;
    this.dialogRef = this.dialog.open(this.leaveBalance, {
      width: "560px",
      height: "auto",
    });
    this.checkbalanceArr2 = [];
    this.index = 0;
    this.service.checkLeaveBalance(this.employeeid, this.leavetypeid, moment().format("YYYY-MM-DD")).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.checkbalanceArr = resp.data;
          if (this.checkbalanceArr.length == 0) {
            this.dataSource = new MatTableDataSource<any>(resp.data);
          } else {
            this.checkbalanceArr2.push(this.checkbalanceArr[this.index]);
            this.dataSource = new MatTableDataSource<any>(this.checkbalanceArr2);
          }

          this.balanceLoading = false;
        }
      },
      (error) => {
        this.balanceLoading = false;
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error in getting leave balance !");
      }
    );
  }
  public onNext() {
    if (this.checkbalanceArr[this.index + 1] != null) {
      this.checkbalanceArr2 = [];
      this.index = this.index + 1;
      this.checkbalanceArr2.push(this.checkbalanceArr[this.index]);
      this.dataSource = new MatTableDataSource<any>(this.checkbalanceArr2);
    }
  }

  public onPrev() {
    if (this.checkbalanceArr[this.index - 1] != null) {
      this.checkbalanceArr2 = [];
      this.index = this.index - 1;
      this.checkbalanceArr2.push(this.checkbalanceArr[this.index]);
      this.dataSource = new MatTableDataSource<any>(this.checkbalanceArr2);
    }
  }
  public closeModal(): void {
    this.dialogRef.close();
  }

  public updateNominatedempid1(event) {
    this.nominatedempid1.id = event ? event.id : "";
  }

  public updateNominatedempid2(event) {
    this.nominatedempid2.id = event ? event.id : "";
  }

  public updateNominatedempid3(event) {
    this.nominatedempid3.id = event ? event.id : "";
  }
}
