import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs/index';
import {environment} from '@env/environment';
import {DomSanitizer} from '@angular/platform-browser';
import {map} from 'rxjs/operators';

const API_URL = environment.apiUrl;

@Injectable({
    providedIn: 'root'
})
export class AnnouncementService {

    constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

    public DownloadFile(url: string): Observable<any> {
        const heads = new HttpHeaders()
            .append('Content-Type', 'application/json')
            .append('Access-Control-Allow-Headers', 'Content-Type')
            .append('Access-Control-Allow-Methods', 'GET')
            .append('Access-Control-Allow-Origin', '*');
        return this.http.get(url,
            {
                responseType: 'blob',
                observe: 'body',
                headers: heads
            });
    }
    public saveNewsAndDoc(body): Observable<any> {
        const url = `api/auth/announce/newsAndDocument`;
        return this.http.request('POST', `${API_URL}${url}`, {body: body});
    }
    public updateNewsAndDoc(body, id): Observable<any> {
        const url = `api/auth/announce/newsAndDocument/${id}`;
        return this.http.request('PUT', `${API_URL}${url}`, {body: body});
    }

    public getNewsAndDocAck(id): Observable<any> {
        const url = `api/auth/announce/newsAndDocument/${id}/ack`;
        return this.http.put(`${API_URL}${url}`, {});
    }

    public deleteNewsAndDocuments(ids): Observable<any> {
        const url = `api/auth/announce/newsAndDocument/delete`;
        return this.http.request('POST' , `${API_URL}${url}`, {body: ids});
    }
    public archiveNewsAndDocuments(ids): Observable<any> {
        const url = `api/auth/announce/newsAndDocument/Archieve`;
        return this.http.request('POST' , `${API_URL}${url}`, {body: ids});
    }

    public getAcknowledgmentListById(id): Observable<any> {
        const url = `api/auth/announce/newsAndDocument/${id}/ack`;
        return this.http.get(`${API_URL}${url}`, {});
    }


    public getAcknowledgmentsById(body): Observable<any> {
        const url = `api/auth/announce/newsAndDocument/getState`;
        return this.http.post(`${API_URL}${url}`, body);
    }

    public updateNewsAndDocPublishSetting(body): Observable<any> {
        const url = `api/auth/announce/newsAndDocumentPublishDetail`;
        return this.http.put(`${API_URL}${url}`, body);
    }

    public publishSetting(id, published): Observable<any> {
        const url = `api/auth/announce/newsAndDocument/${id}/${published}`;
        return this.http.put(`${API_URL}${url}`, {});
    }

    public getNewsAndDocById(id): Observable<any> {
        const url = `api/auth/announce/newsAndDocument/${id}`;
        return this.http.request('GET', `${API_URL}${url}`);
    }

    public getNewsAndDocList(type): Observable<any> {
        const url = `api/auth/announce/newsAndDocument?type=${type}`;
        return this.http.request('GET', `${API_URL}${url}`);
    }

    public getMyNews(type, newsId): Observable<any> {
        const url = `api/auth/announce/dashBoard/getMyNews/${newsId}`;
        return this.http.request('GET', `${API_URL}${url}`);
    }

    public getMyNewsForDash(): Observable<any> {
        const url = `api/auth/announce/dashBoard/getMyNewsForDash`;
        return this.http.request('GET', `${API_URL}${url}`);
    }
    
    public getMyInCompleteTasks(): Observable<any> {
        const url = `api/auth/onboarding/Tasks/myIncompleteTasks`;
        return this.http.request('GET', `${API_URL}${url}`);
    }

    public getPendingLeaves(): Observable<any> {
        const url = `api/auth/leave/dashBoard/pendingLeaves`;
        return this.http.request('GET', `${API_URL}${url}`);
    }
    public getEmployeesOnLeave(id): Observable<any> {
        const url = `api/auth/leave/dashBoard/employeeOnleaveToday/`+id;
        return this.http.request('GET', `${API_URL}${url}`);
    }

    public getMyDocuments(type, newsId): Observable<any> {
        const url = `api/auth/announce/dashBoard/getMyDocuments/${newsId}`;
        return this.http.request('GET', `${API_URL}${url}`);
    }

    public getMyDocumentsForDash(): Observable<any> {
        const url = `api/auth/announce/dashBoard/getMyDocumentsForDash`;
        return this.http.request('GET', `${API_URL}${url}`);
    }

    public changeAckState(id): Observable<any> {
        const url = `api/auth/announce/newsAndDocument/setState/`+id;
        return this.http.get(`${API_URL}${url}`);
    }


    public getNewsAndDoc(id): Observable<any> {
        const url = `api/auth/announce/newsAndDocument/${id}`;
        return this.http.request(' GET', `${API_URL}${url}`);
        // const keyValues = Object.keys(body).map(key => {
        //     if (body[key]) {
        //         return `${key}=${body[key]}`;
        //     }
        //     return '';
        // }).join('&');
        // const url = `api/auth/announce/newsAndDocuments?${keyValues}`;
        // return this.http.get(`${API_URL}${url}`);
    }

    public filterNewsAndDocuments(request): Observable<any> {
        const url = `api/auth/announce/newsAndDocuments`;
        return this.http.request('POST', `${API_URL}${url}`, {body: request});
    }

    public deleteDocumentCategoriesByIds(ids): Observable<any> {
        const url = `api/auth/announce/documentCategory/delete`;
        return this.http.request('POST', `${API_URL}${url}`, {body: ids});
    }

    public saveDocumentCategory(body): Observable<any> {
        const url = `api/auth/announce/documentCategory`;
        return this.http.post(`${API_URL}${url}`, body);
    }

    public updateDocumentCategory(body): Observable<any> {
        const url = `api/auth/announce/documentCategory`;
        return this.http.put(`${API_URL}${url}`, body);
    }

    public getCategories(body): Observable<any> {
        const keyValues = Object.keys(body).map(key => {
            if (body[key]) {
                return `${key}=${body[key]}`;
            }
            return '';
        }).join('&');
        const url = `api/auth/announce/documentCategories?${keyValues}`;
        return this.http.get(`${API_URL}${url}`);
    }

    public getEmployeeCountBySubUnit(): Observable<any> {
        const url = `api/auth/employee/graph/employee/count/bySubUnit`;
        return this.http.get(`${API_URL}${url}`);
    }


    public getSubUnitMultiChart(): Observable<any> {
        const url = `api/auth/employee/charts/subUnitMultiChart`;
        return this.http.get(`${API_URL}${url}`);
    }

    public employeeLeaveCountBySubUnit(): Observable<any> {
        const url = `api/auth/employee/graph/employeeLeave/count/bySubUnit`;
        return this.http.get(`${API_URL}${url}`);
    }
    public getLocationGroup(): Observable<any> {
        const url = `api/auth/oth/location/group`;
        return this.http.request('GET', `${API_URL}${url}`);
    }
    public getEmploymentStatusChart(): Observable<any> {
        const url = `api/auth/employee_status_chart`;
        return this.http.request('GET', `${API_URL}${url}`);
    }
    public getjobCategoryGraph(): Observable<any> {
        const url = `api/auth/jobCategory/group`;
        return this.http.request('GET', `${API_URL}${url}`);
    }

    public getAgeGraph(): Observable<any> {
        const url = `api/auth/employee/graph/employee/ageGraph`;
        return this.http.request('GET', `${API_URL}${url}`);
    }

    public getDirectory(body): Observable<any> {
        const keyValues = Object.keys(body).filter(k => body[k] ? true : false).map(k => `${k}=${body[k]}`).join('&');
        const url = `api/auth/directory/list?${keyValues}`;
        return this.http.get(`${API_URL}${url}`);
    }


    public filterDirectory(body): Observable<any> {
        const url = `api/auth/directory/list`;
        return this.http.post(`${API_URL}${url}`,body);
    }


    // public getDirectory(): Observable<any> {
    //     const url = `api/auth/directory/list`;
    //     return this.http.get(`${API_URL}${url}`);
    // }
    public getAssetsCategories(): Observable<any> {
        const url = `api/auth/assets/categories/all`;
        return this.http.get(`${API_URL}${url}`);
    }

    public filterAssetsCategories(body): Observable<any> {
        const url = `api/auth/assets/categories/filter`;
        return this.http.post(`${API_URL}${url}`,body);
    }

    public saveAssetsCategory(body): Observable<any> {
        const url = `api/auth/assets/categories`;
        return this.http.post(`${API_URL}${url}`, body);
    }

    public updateAssetsCategory(body): Observable<any> {
        const url = `api/auth/assets/categories`;
        return this.http.put(`${API_URL}${url}`, body);
    }

    public getAssetsVendors(): Observable<any> {
        const url = `api/auth/assets/vendors/all`;
        return this.http.get(`${API_URL}${url}`);
    }
    public getAssetsVendorsById(id): Observable<any> {
        const url = `api/auth/assets/vendors/`+id;
        return this.http.get(`${API_URL}${url}`);
    }

    public filterAssetsVendors(body): Observable<any> {
        const url = `api/auth/assets/vendors/filter`;
        return this.http.post(`${API_URL}${url}`,body);
    }

    public saveAssetsVendor(body): Observable<any> {
        const url = `api/auth/assets/vendors`;
        return this.http.post(`${API_URL}${url}`, body);
    }

    public updateAssetsVendor(body): Observable<any> {
        const url = `api/auth/assets/vendors`;
        return this.http.put(`${API_URL}${url}`, body);
     
    }

    public deleteVendorById(body): Observable<any> {
        const url = `api/auth/assets/vendors/delete`;
        return this.http.post(`${API_URL}${url}`, body);
    }
    public deleteVendors(body): Observable<any> {
        const url = `api/auth/assets/vendors/bulkDelete`;
        return this.http.post(`${API_URL}${url}`, body);
    }

    public getAssetsBrands(): Observable<any> {
     
        const url = `api/auth/assets/brands/all`;
        return this.http.get(`${API_URL}${url}`);
    }

    public filterAssetsBrands(body): Observable<any> {
        const url = `api/auth/assets/brands/filter`;
        return this.http.post(`${API_URL}${url}`,body);
    }

    public saveAssetsBrand(body): Observable<any> {
        
        const url = `api/auth/assets/brands`;
        return this.http.post(`${API_URL}${url}`, body);
    }

    public deleteBrand(body): Observable<any> {
        
        const url = `api/auth/assets/brands/bulkDelete`;
        return this.http.post(`${API_URL}${url}`, body);
    }

    public deleteCategories(body): Observable<any> {
        
        const url = `api/auth/assets/categories/bulkDelete`;
        return this.http.post(`${API_URL}${url}`, body);
    }

    public deleteAssetsHistory(id,body): Observable<any> {
        
        const url = `api/auth/assets/assets/history/bulkDelete/`+id;
        return this.http.post(`${API_URL}${url}`, body);
    }

    public deleteAssets(body): Observable<any> {
        
        const url = `api/auth/assets/assets/bulkDelete`;
        return this.http.post(`${API_URL}${url}`, body);
    }

    public updateAssetsBrand(body): Observable<any> {
        const url = `api/auth/assets/brands`;
        return this.http.put(`${API_URL}${url}`, body);
    }

    public saveAsset(body): Observable<any> {
       
        const url = `api/auth/assets/assets`;
        return this.http.post(`${API_URL}${url}`, body);
    }


    public updateAsset(body): Observable<any> {
        const url = `api/auth/assets/assets`;
        return this.http.put(`${API_URL}${url}`, body);
    }

    public getAssets(): Observable<any> {
        const url = `api/auth/assets/assets/all`;
        return this.http.get(`${API_URL}${url}`);
    }

    public filterAssest(body): Observable<any> {
        const url = `api/auth/assets/assets/filter `;
        return this.http.post(`${API_URL}${url}`,body);
    }

    public getAssetById(id): Observable<any> {
        const url = `api/auth/assets/assets/${id}`;
        return this.http.get(`${API_URL}${url}`);
    }

    public getAssetIdAndSerialNUmbers(): Observable<any> {
        const url = `api/auth/assets/getAssetsLists`;
        return this.http.get(`${API_URL}${url}`);
    }
    public getAssetHistory(id): Observable<any> {
        const url = `api/auth/assets/assets/history/${id}`;
        return this.http.get(`${API_URL}${url}`);
    }

    public setAssestStatus(id,body): Observable<any> {
        const url = `api/auth/assets/assets/status/${id}`;
        return this.http.post(`${API_URL}${url}`,body);
    }
    public deleteAssetById(body): Observable<any> {
        const url = `api/auth/assets/assets/delete`;
        return this.http.post(`${API_URL}${url}`, body);
    }


    generateIDForAllAssets(id) : Observable<any>
    {
        const url = `api/auth/assets/sequence/${id}`;
        return this.http.get(`${API_URL}${url}`);
    }


    //Define postions

    getAllPostions() : Observable<any>
    {
        const url = `api/auth/more/getPositions`;
        return this.http.get(`${API_URL}${url}`);
    }


    savePostion(body) : Observable<any>
    {
        const url = `api/auth/more/addPosition`;
        return this.http.post(`${API_URL}${url}`,body);
    }


    deletePostion(id) : Observable<any>
    {
        const url = `api/auth/more/removeTitle/`+id;
        return this.http.delete(`${API_URL}${url}`);
    }


}
