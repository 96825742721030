<div id="header-page" class="ng-star-inserted header-page">
    <div class="page-header ng-star-inserted">
        <div class="page-block ng-star-inserted">
            <div class="row align-items-center">
                <div class="col-md-8">
                    <div class="page-header-title">
                        <span class="ng-star-inserted"></span><span class="ng-star-inserted">
                            <h4 class="m-b-10 ng-star-inserted">Disciplinary Cases</h4>
                        </span>
                    </div>
                </div>
                <div class="col-md-4"></div>
            </div>
        </div>
    </div>
</div>


<div class="page-body no-title">
    <div class="row">
        <div class="col-sm-12">

            <div class="row top-tools">
                <div class="col-xs-12 col-sm-12 col-md-6 left-tools">
                    <span class="export-icon-btn csv" (click)="exportToCsv()">
                        <i class="material-icons">get_app</i><span>CSV</span>
                    </span>
                    <span class="export-icon-btn pdf" (click)="saveAsPdf()">
                        <i class="material-icons">get_app</i><span>PDF</span>
                    </span>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
                    <div class="relative-position search-filter-con">

                        <span class="mytooltip cus-left tooltip-effect-4" [hidden]="!write">
                            <span class="tooltip-item">
                                <button mat-mini-fab class="btn btn-primary light add-rounded margin-button-05"
                                    (click)="addDisciplinesForm('add' , null)">
                                    <i class="fa fa-plus"></i>
                                </button>
                            </span>
                            <span class="tooltip-content clearfix">
                                <span class="tooltip-text">New</span>
                            </span>
                        </span>
                        <span class="mytooltip cus-left tooltip-effect-4">
                            <span class="tooltip-item">
                                <button mat-mini-fab class="btn btn-primary light add-rounded margin-button-05"
                                    (click)="filterUserList()">
                                    <i class="fa fa-filter"></i>
                                </button>
                            </span>
                            <span class="tooltip-content clearfix">
                                <span class="tooltip-text">Filter</span>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <app-card [title]="'Disciplinary Cases'">
                <div class="dt-responsive" *ngIf="dataSource">
                    <div class="table-responsive">
                        <mat-card-content>
                            <mat-table [dataSource]="dataSource" matSort>
                                <ng-container matColumnDef="select">
                                    <mat-header-cell *matHeaderCellDef>
                                        <a [matMenuTriggerFor]="menu" class="check-all">
                                            <span class="material-icons">
                                                more_horiz
                                            </span>
                                        </a>

                                        <mat-menu #menu="matMenu">
                                            <button (click)="onSelectAllClicked()" mat-menu-item>select all</button>
                                            <button (click)="onDeSelectAllClicked()"
                                                *ngIf="selectedCheckBoxList.length > 0" mat-menu-item>
                                                deselect all
                                            </button>
                                            <button [hidden]="!delete" (click)="onDeleteSelected()"
                                                *ngIf="selectedCheckBoxList.length > 0" mat-menu-item>
                                                delete selected
                                            </button>
                                            <button [hidden]="!update" mat-menu-item
                                                *ngIf="this.selectedCheckBoxList.length > 0 && !isArchived"
                                                (click)="onArchiveClicked()">Archive
                                            </button>
                                        </mat-menu>
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        <mat-checkbox #matCheckbox (change)="onCheckBoxChange($event, row.id)"
                                            value="{{row.id}}"></mat-checkbox>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="empId">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Employee ID</mat-header-cell>
                                    <mat-cell *matCellDef="let row" (click)="addDisciplinesForm('editcase' , row)">
                                        <span class="cursor-pointer">{{ row?.empId}}</span>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="empName">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Employee</mat-header-cell>
                                    <mat-cell *matCellDef="let row" (click)="addDisciplinesForm('editcase' , row)">
                                        <span class="cursor-pointer">{{row?.empName}}</span>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="caseName">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Case Name</mat-header-cell>
                                    <mat-cell *matCellDef="let row" (click)="addDisciplinesForm('editcase' , row)"
                                        style="cursor: pointer"><a>{{row.caseName}}</a></mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="createdBy">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Created By</mat-header-cell>
                                    <mat-cell *matCellDef="let row" (click)="addDisciplinesForm('editcase' , row)">
                                        <span class="cursor-pointer">{{row.createdBy}}</span>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="createdOn">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Created On</mat-header-cell>
                                    <mat-cell *matCellDef="let row" (click)="addDisciplinesForm('editcase' , row)">
                                        <span class="cursor-pointer">{{row.createdOn | date :'dd-MM-yyyy'}}</span>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="status">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
                                    <mat-cell *matCellDef="let row" (click)="addDisciplinesForm('editcase' , row)">
                                        <div class="cursor-pointer" *ngIf="!row.close && row.status=='open'">Open</div>
                                        <div class="cursor-pointer" *ngIf="!row.close && row.status=='in_progress'">Open
                                        </div>
                                        <div class="cursor-pointer" *ngIf="!row.close && row.status=='completed'">
                                            Completed</div>
                                        <div class="cursor-pointer" *ngIf="row.close">Close</div>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="dis_action">
                                    <mat-header-cell *matHeaderCellDef>Disciplinary Actions
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        <div *ngIf="!row.actionTaken">
                                            <span class="mytooltip tooltip-effect-4">
                                                <span class="tooltip-item">
                                                    <button mat-button class="btn-view-dis"> View </button>
                                                </span>
                                                <span class="tooltip-content clearfix">
                                                    <span class="tooltip-text">No Actions</span>
                                                </span>
                                            </span>
                                        </div>
                                        <div *ngIf="row.actionTaken">
                                            <button mat-button class="btn-view-dis" (click)="viewAction(row.id)">View
                                            </button>
                                        </div>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="actions">
                                    <mat-header-cell [hidden]="!write" *matHeaderCellDef>Actions</mat-header-cell>
                                    <mat-cell [hidden]="!write" *matCellDef="let row">
                                        <span class="mytooltip tooltip-effect-4">
                                            <span class="tooltip-item">
                                                <button mat-button class="btn-view-dis"
                                                    (click)="addDisciplinesForm('addAction' , row)">
                                                    Add
                                                </button>
                                            </span>
                                            <span class="tooltip-content clearfix">
                                                <span class="tooltip-text">AddActions</span>
                                            </span>
                                        </span>
                                    </mat-cell>
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumns;">
                                </mat-row>
                            </mat-table>
                            <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
                            <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>

                        </mat-card-content>
                    </div>
                </div>
            </app-card>
        </div>
    </div>
</div>


<ng-template #deleteUser let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">Delete Disciplinary Cases</h4>
        </div>
        <div class="mat-modal-body">
            <div class="text-left are-u">Are you sure ?</div>
            <div class="row">
                <div class="multi-btns col-sm-12 text-right">
                    <button (click)="close()" class="btn-danger" mat-raised-button>
                        <i class="flaticon-cancel"></i>cancel
                    </button>
                    <button (click)="deleteDisciplineById()" class="btn-primary" color="primary" mat-raised-button>
                        <i class="flaticon-trash"></i>delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #addDisciplinary let-modal>
    <div mat-dialog-content>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">
                <span *ngIf="typeForm=='addAction'">Add Disciplinary Actions</span>
                <span *ngIf="typeForm=='add'">Add Disciplinary Case</span>
                <span *ngIf="typeForm=='editcase'">Edit Disciplinary Case</span>
            </h4>
        </div>
        <div class="mat-modal-body">
            <app-loading *ngIf="isLoading" [isLoading]="isLoading"></app-loading>
            <form class="form-material full-width-inputs" *ngIf="typeForm=='add' && !isLoading"
                [formGroup]="disciplineForm" action="javascript:">
                <div class="row form-group">
                    <div class="col-sm-6">
                        <mat-form-field class="form-element">
                            <input class="form-control" matInput placeholder="Case Name" formControlName="caseName">
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6">
                        <mat-form-field class="form-element">
                            <mat-label class="col-form-label">Employee</mat-label>
                            <input class="form-control" type="text" aria-label="Employee" matInput
                                formControlName="empId" [matAutocomplete]="auto3" required
                                placeholder="Type For Hints .. ">
                            <mat-autocomplete #auto3="matAutocomplete"
                                [displayWith]="displayEmployeeByName2.bind(this)">
                                <mat-option *ngFor="let r of employeeOptions " [value]="r.value">
                                    {{r.label}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12">
                        <mat-form-field class="form-element">
                            <textarea class="form-control" matInput placeholder="Description"
                                formControlName="desc"></textarea>
                            <mat-label>Description</mat-label>
                        </mat-form-field>
                    </div>
                </div>
            </form>


            <form class="form-material full-width-inputs" [formGroup]="disciplineForm" action="javascript:"
                *ngIf="typeForm=='editcase' && !isLoading">
                <div class="row form-group">
                    <div class="col-sm-12">
                        
                           <img [src] = "this.imagePath" class="employee-image" width="200" height="200"/> 
                           
                        
                    </div>
                    <div class="col-sm-6">
                        <mat-form-field class="form-element">
                            <input class="form-control" matInput placeholder="Case Name" formControlName="caseName">
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6">
                        <mat-form-field class="form-element">
                            <mat-label class="col-form-label">Employee</mat-label>
                            <input required class="form-control" type="text" aria-label="Employee" matInput
                                formControlName="empId" [matAutocomplete]="auto2" [readonly]="!update">
                            <mat-autocomplete #auto2="matAutocomplete"
                                [displayWith]="displayEmployeeByName2.bind(this)">
                                <mat-option *ngFor="let r of employeeOptions " [value]="r.value">
                                    {{r.label}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6">
                        <mat-form-field class="form-element">
                            <input class="form-control" matInput placeholder="Created On" formControlName="createdOn"
                                readonly>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6">
                        <mat-form-field class="form-element">
                            <input class="form-control" matInput placeholder="Created By" formControlName="createdBy"
                                readonly>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12">
                        <mat-form-field class="form-element">
                            <textarea [readonly]="!update" class="form-control" matInput placeholder="Description"
                                formControlName="desc"></textarea>
                            <mat-label>Description</mat-label>
                        </mat-form-field>
                    </div>
                </div>
            </form>


            <form class="form-material full-width-inputs" [formGroup]="disciplineActionForm" action="javascript:"
                *ngIf="typeForm=='addAction' && !isLoading">
                <div class="row form-group">
                    <div class="col-sm-4">
                        <mat-form-field class="form-element">
                            <input class="form-control" matInput placeholder="Case Name"
                                [defaultValue]="editDataSave?.case" readonly>
                            <mat-label>Disciplinary Case</mat-label>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-4">
                        <mat-form-field class="form-element">
                            <mat-label>Action Type</mat-label>
                            <mat-select class="form-control" disableOptionCentering formControlName="action" required>
                                <mat-option *ngFor="let war of actions" [value]="war">
                                    {{war.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-4">
                        <mat-form-field class="form-element">
                            <input class="form-control" matInput placeholder="ActionCreatedBy"
                                [defaultValue]="editDataSave?.role" readonly>
                            <mat-label>Action Created By</mat-label>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-4">
                        <mat-form-field class="form-element">
                            <mat-select class="form-control" formControlName="owner" placeholder="Owner" #singleSelect
                                required>
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="userFilterCtrl" placeholderLabel="Search"
                                        noEntriesFoundLabel="Options Not Found"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let item of filteredUsers" [value]="item.id">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-4">
                        <mat-form-field class="form-element">
                            <mat-label for="dob">Due Date</mat-label>
                            <input [matDatepicker]="picker2" class="form-control" formControlName="dueDate" matInput>
                            <mat-datepicker-toggle [for]="picker2" matSuffix></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-4">
                        <mat-form-field class="form-element">
                            <mat-label>Status</mat-label>
                            <mat-select class="form-control" disableOptionCentering formControlName="status">
                                <mat-option value="open">Open</mat-option>
                                <mat-option value="in_progress">In Progress</mat-option>
                                <mat-option value="closed">Close</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12">
                        <mat-form-field class="form-element">
                            <textarea class="form-control" matInput placeholder="Description"
                                formControlName="description"></textarea>
                            <mat-label>Description</mat-label>
                        </mat-form-field>
                    </div>
                </div>
            </form>

            <div *ngIf="typeForm=='add'" class="action-buttons multi-btns col-sm-12 text-right">
                <button mat-raised-button class="btn-danger" (click)="close()" [mat-dialog-close]="true">
                    <i class="flaticon-cancel"></i> Cancel
                </button>

                <button mat-raised-button class="btn btn-primary" color="primary" (click)="submitAddDisciplines()"
                    [disabled]="disciplineForm.invalid">
                    <i class="flaticon-diskette"></i>Save
                </button>
            </div>

            <div *ngIf="typeForm=='editcase'" class="action-buttons multi-btns col-sm-12 text-right">
                <button mat-raised-button class="btn-danger" (click)="close()" [mat-dialog-close]="true">
                    <i class="flaticon-cancel"></i> Cancel
                </button>

                <button [hidden]="!update" (click)="closeCase()" *ngIf="editDataSave && !editDataSave.close"
                    color="warn" mat-raised-button>Close Case
                </button>

                <button [hidden]="!update" mat-raised-button class="btn btn-primary" color="primary"
                    (click)="saveCase()" [disabled]="disciplineForm.invalid">
                    <i class="flaticon-diskette"></i>Save
                </button>
            </div>

            <div *ngIf="typeForm=='addAction'" class="action-buttons multi-btns col-sm-12 text-right">
                <button mat-raised-button class="btn-danger" (click)="close()" [mat-dialog-close]="true">
                    <i class="flaticon-cancel"></i> Cancel
                </button>

                <button mat-raised-button class="btn btn-primary" color="primary" (click)="submitAddDisciplinesAction()"
                    [disabled]="disciplineActionForm.invalid">
                    <i class="flaticon-diskette"></i>Save
                </button>
            </div>

            <!--            <div *ngIf="typeForm=='editcase'" class="row my-2 mx-2">-->
            <!--                <div class="action-buttons multi-btns col-sm-12 text-right">-->
            <!--                    <button (click)="onNoClick()" [mat-dialog-close]="true" class="btn-danger" mat-raised-button>-->
            <!--                        Cancel-->
            <!--                    </button>-->
            <!--                    <button (click)="takeDisciplineAction()" *ngIf="typeForm=='editcase'" color="warn"-->
            <!--                            mat-raised-button>Take Disciplinary Action-->
            <!--                    </button>-->
            <!--                    <button (click)="closeCase()" *ngIf="editDataSave && !editDataSave.close" color="warn"-->
            <!--                            mat-raised-button>Close Case-->
            <!--                    </button>-->
            <!--                    <button (click)="editCase()" *ngIf="typeForm=='editcase' && !editData " class="btn-primary"-->
            <!--                            color="primary" mat-raised-button>Edit-->
            <!--                    </button>-->
            <!--                    <button (click)="saveCase()" *ngIf="typeForm=='editcase' && editData" class="btn-primary"-->
            <!--                            color="primary" mat-raised-button>Save-->
            <!--                    </button>-->
            <!--                    <button (click)="submitAddDisciplines()" *ngIf="typeForm=='add'" [disabled]="disciplineForm.invalid"-->
            <!--                            class="btn-primary" color="primary" mat-raised-button>Save-->
            <!--                    </button>-->
            <!--                </div>-->
            <!--            </div>-->
        </div>
    </div>
</ng-template>


<ng-template #filterUser let-modal>
    <div mat-dialog-content>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">
                <span>Filter Disciplinary Case</span>
            </h4>
        </div>
        <div class="mat-modal-body">
            <form [formGroup]="filterForm" action="javascript:" class="form-material full-width-inputs">
                <div class="row form-group">
                    <div class="col-sm-6">
                        <mat-form-field class="form-element">
                            <mat-label class="col-form-label">Employee</mat-label>
                            <input class="form-control" type="text" aria-label="Employee" matInput
                                formControlName="empId" [matAutocomplete]="auto3" placeholder="type for hints ..">
                            <mat-autocomplete #auto3="matAutocomplete"
                                [displayWith]="displayEmployeeByName3.bind(this)">
                                <mat-option *ngFor="let r of employeeOptions2 " [value]="r.value">
                                    {{r.label}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6">
                        <mat-form-field class="form-element">
                            <mat-label for="dob">Created Date From</mat-label>
                            <input [matDatepicker]="picker1" class="form-control" formControlName="createdDateFrom"
                                id="dob" matInput>
                            <mat-datepicker-toggle [for]="picker1" matSuffix></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6">
                        <mat-form-field class="form-element">
                            <mat-label for="dob">Created Date To</mat-label>
                            <input [matDatepicker]="picker2" class="form-control" formControlName="createdDateTo"
                                matInput>
                            <mat-datepicker-toggle [for]="picker2" matSuffix></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6">
                        <mat-form-field class="form-element">
                            <mat-label>Status</mat-label>
                            <mat-select class="form-control" disableOptionCentering formControlName="status">
                                <mat-option value="">All</mat-option>
                                <mat-option value="in_progress">Open</mat-option>
                                <mat-option value="closed">Close</mat-option>
                                <mat-option value="archived">Archive</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6">
                        <mat-form-field class="form-element">
                            <mat-label>Include</mat-label>
                            <mat-select class="form-control" disableOptionCentering formControlName="include">
                                <mat-option value="">All</mat-option>
                                <mat-option value="ce">Current Employees Only</mat-option>
                                <mat-option value="cpe">Current And Past Employees Only</mat-option>
                                <mat-option value="pe">Past Employees Only</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </form>
            <div class="action-buttons multi-btns col-sm-12 text-right">
                <button (click)="resetForm()" class="btn-danger reset-btn" mat-raised-button>
                    <i class="flaticon-refresh"></i>Reset
                </button>
                <button (click)="close()" class="btn-danger" mat-raised-button>
                    <i class="flaticon-cancel"></i>Cancel
                </button>
                <button (click)="search()" class="btn btn-primary"  color="primary" mat-raised-button><i
                        class="flaticon-magnifiying-glass"></i>Search
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #disActions let-modal>
    <div class="card my-0">
        <div class="header">
            <h4 class="card-title card-title-modal">Disciplinary Actions</h4>
        </div>
        <div class="card-content px-2 py-2">
            <label id="example-radio-group-label">Select at least one to proceed</label>
            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                [(ngModel)]="disAction">
                <mat-radio-button class="example-radio-button" *ngFor="let war of actions" [value]="war">
                    {{war.name}}
                </mat-radio-button>
            </mat-radio-group>

            <div class="row">
                <div class="col-sm-12 text-right">
                    <button type="submit" class="btn btn-cancel btn-danger" (click)="close()">Cancel</button>
                    <button type="submit" class="btn btn-save btn-primary" [disabled]="disAction==null"
                        (click)="selectAction()">Select
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>



<ng-template #archive let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">Archive</h4>
        </div>
        <div class="mat-modal-body">
            <div class="text-left are-u">Are you sure ?</div>
            <div class="row">
                <div class="action-buttons multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close()"><i
                            class="flaticon-cancel"></i>Cancel</button>
                    <button mat-raised-button class="btn-primary" (click)="disciplineArchive()" color="primary"><i
                            class="flaticon-open"></i>Archive</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>