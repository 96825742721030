<div id="header-page" class="ng-star-inserted header-page">
    <div class="page-header ng-star-inserted">
        <div class="page-block ng-star-inserted">
        <div class="row align-items-center">
            <div class="col-md-8">
            <div class="page-header-title">
                <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted">Custom Fields Sections</h4></span>
            </div>
            </div>
            <div class="col-md-4"></div>
        </div>
        </div>
    </div>
</div>

<div class="page-body no-title">
    <div class="row">
      <div class="col-sm-12">
        <div class="row top-tools">
          <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6 left-tools">
            <span class="export-icon-btn csv" (click)="exportToCsv()"><i
                    class="material-icons">get_app</i><span>CSV</span>
            </span>
            <span class="export-icon-btn pdf" (click)="saveAsPdf()"><i class="material-icons">get_app</i><span>PDF</span>
            </span>
            <!-- <span (click)="demoClick()" class="export-icon-btn pdf"><i class="material-icons">get_app</i><span>PDF</span>
            </span> -->
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
            <div class="relative-position search-filter-con"> 
              <span class="icon material-icons ng-tns-c114-0"> search </span>
              <mat-form-field> 
                <input matInput #inputSearch type="search" [(ngModel)]="filterQuery" maxlength="40"
                       (ngModelChange)="onModelChange(filterQuery)"
                       class="form-control input-sm full-data-search" >
              </mat-form-field>
              <span [hidden]="!write" class="mytooltip cus-left tooltip-effect-4">
                <span class="tooltip-item">
                    <button mat-mini-fab class="btn btn-primary  light add-rounded margin-button-05" (click)="addNewSection()">
                        <i class="fa fa-plus"></i>
                    </button>
                </span>
                <span class="tooltip-content clearfix">
                <span class="tooltip-text">New</span>
                </span>
             </span>
            </div>
          </div>
        </div>
        <app-card [title]="'Job Title'">
          <div class="dt-responsive" *ngIf="dataSource">
            <div class="table-responsive table hrm-table-style-1 full-data-table table-cols-4" style="overflow:visible !important;">
              <mat-card-content>
                <mat-table [dataSource]="dataSource" matSort>
                  <ng-container matColumnDef="select">
                    <mat-header-cell [hidden]="!delete" *matHeaderCellDef>
                      <a [matMenuTriggerFor]="menu" class="check-all">
                                  <span class="material-icons">
                                      more_horiz
                                  </span>
                      </a>
  
                      <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="onSelectAllClicked()">Select All</button>
                        <button (click)="onDeSelectAllClicked()" *ngIf="selectedCheckBoxList.length > 0" mat-menu-item>
                          Deselect All
                        </button>
                        <button (click)="onDeleteSelected()" *ngIf="selectedCheckBoxList.length > 0" mat-menu-item>
                          Delete Selected
                        </button>
                      </mat-menu>
                    </mat-header-cell>
                    <mat-cell [hidden]="!delete"  *matCellDef="let row">
                      <mat-checkbox #matCheckbox (change)="onCheckBoxChange($event, row.id)"
                                    value="{{row.id}}"></mat-checkbox>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
                    <mat-cell (click)="updateSection(row.id)" class="cursor-pointer" *matCellDef="let row">
                      {{ row.name }}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="screen">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Screen</mat-header-cell>
                    <mat-cell (click)="updateSection(row.id)" class="cursor-pointer" *matCellDef="let row">
                      {{ row.header }}
                    </mat-cell>
                  </ng-container>
  
                
                  <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: columns;">
                  </mat-row>
                </mat-table>
                <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
                  <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
              </mat-card-content>
            </div>
            <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
          </div>
        </app-card>
      </div>
    </div>
  </div>

  <ng-template #addSection let-modal>
    <div mat-dialog-actions>
      <div class="mat-modal-header">
        <h4 class="mat-title-modal">
          <span>Add</span>
          Section
        </h4>
      </div>
      <div class="mat-modal-body">
        <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>
  
  
        <form *ngIf="!isUserAdded" class="form-material full-width-inputs" action="javascript:" [formGroup]="sectionForm" >
            <div class="row form-group"> 

                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <mat-label>Name</mat-label>
                        <input pattern= "*" type="text" matInput class="form-control" maxlength="40" required="" id="name" formControlName="name" >
                      </mat-form-field>    
                      <!-- <mat-error *ngIf="this.validation" class="error-messages">
                        Aggregate cannot be the same as the Group By
                    </mat-error> -->
                </div>

                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <mat-label>Screen</mat-label>
                        <mat-select disableOptionCentering  class="form-control"
                                    formControlName="header" required="" [(ngModel)]="this.selectedValue">
                                    <mat-option value="">
                                        --Select--
                                    </mat-option>
                            <mat-option *ngFor="let s of screenList" [value]="s.value">
                                {{s.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

               
            </div>

            <div class="row my-2 mx-2"> 
                <div class="action-buttons multi-btns col-sm-12 text-right">
                  <button mat-raised-button class="btn-danger" (click)="close()" ><i class="flaticon-cancel"></i>Cancel</button>
                  <button mat-raised-button class="btn-primary" color="primary" (click)="addSections()" [disabled]= "!this.sectionForm.valid" ><i class="flaticon-diskette"></i>Save</button>
                </div>
              </div>
          </form>
          
   
        </div>
      </div>
  
  </ng-template>

  <ng-template #deleteSection let-modal>
    <div mat-dialog-actions>
      <div class="mat-modal-header">
        <h4 class="mat-title-modal">Delete Sections</h4>
      </div>
      <div class="mat-modal-body">
        <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>
        <div [hidden]="isUserAdded" class="text-left are-u">Are you sure ?</div>
        <div [hidden]="isUserAdded" class="row">
              <div class="multi-btns col-sm-12 text-right">
                <button mat-raised-button class="btn-danger" (click)="close()">
                    <i class="flaticon-cancel"></i>Cancel
                </button>
                <button mat-raised-button class="btn-primary" color="primary" (click)="deleteSections()">
                    <i class="flaticon-trash"></i>Delete
                </button>
            </div>
        </div>
      </div>
    </div>
  </ng-template>