import {Component, OnInit, ViewChild,ViewChildren,QueryList, Input} from '@angular/core';
import {DashboardService} from '../../../dashboard.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { NotificationService } from "src/app/core/services/notification.service";
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import jsPDF from 'jspdf';
import {saveAs} from 'file-saver-es';
import autoTable from 'jspdf-autotable';
import {MatDialog} from '@angular/material/dialog';
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox';
import * as moment from 'moment';
import { AuthService } from 'src/app/core/services/auth.service';
@Component({
  selector: 'app-emergency-contact',
  templateUrl: './emergency-contact.component.html',
  styleUrls: ['./emergency-contact.component.scss']
})
export class EmergencyContactComponent implements OnInit {
  public filterQuery = '';
  selectedUsers: any[] = [];
  public dialogRef: any;
  public userList: Array<any>;
  public isLoading = true;
  public isUserAdded: boolean;
  public err: string;
  public modalRef: any;
  public userForm: FormGroup;
  public isEdit: boolean;
  public editId: string;
  public deleteId: string;
  public attacherId: string;
  public attachmentList = [];
  comment: string;
  selectedCheckBoxList = [];
  public fileType: string;
  public fileObj: any;
  public uploadedFile: any;
  public fileError: string;
  public validationError = {
    required: 'Required'
  };

  public sectionsList : Array<any> = [];
  public tempSectionsList : Array<any> = [];
  public saveCutomList : Array<any> = [];
  public saveCBCutomList : Array<any> = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  public columns = ['select', 'name', 'relation', 'ht', 'wt', 'mo', 'attachment', 'edit'];
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() employeeId: string;
  @ViewChild('addUser', {static: false}) addUser;
  @ViewChild('deleteUser', {static: false}) deleteUser;
  @ViewChild('attach', {static: false}) attach;
  @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
  constructor(private authService : AuthService, private service: DashboardService, private modalService: NgbModal, private fb: FormBuilder, private toastr: NotificationService,  private dialog: MatDialog) {
    this.createForm();
  }

  ngOnDestroy() {
    this.dialog = null ;
    this.dialogRef = null ;
    this.authService = null ;
    this.service = null ;
    this.fb = null ;
    this.toastr = null ;
    this.sort = null ;
    this.paginator = null ;
    this.userForm = null ;
    this.matCheckBoxes = null ;
    this.dataSource = null ;
    this.addUser = null ;
    this.deleteUser = null ;
    this.attach = null ;
    this.userForm = null ;
    this.dataSource = null ;
  }
  public permissions : any;
  public read = false;
  public write = false ;
  public update = false;
  public delete = false;

  checkPermission(module,tabs) : void 
  {
      this.permissions = JSON.parse(this.authService.getPermissions());

      this.read = false ;
      this.write = false ;
      this.delete = false ;
      this.update = false;

      if(this.permissions != undefined && this.permissions != null )
      {
          for(let p of this.permissions)
          {
             
              if(p.id === module)
              {
                 
                  if(p.modules != undefined)
                  {
                    
                      for(let tab of p.modules)
                      {
                          if(tab.id === tabs)
                          {
                            
                              for(let move of tab.permits)
                              {
                                  if(move.id === "u")
                                  {
                                      this.update = true;
                                     
                                  }
                                  else if(move.id === "r")
                                  {
                                      this.read = true;
                                  }
                                 
                                  else if(move.id === "d")
                                  {
                                      this.delete = true;
                                  }
                              }
                          }
                      }
                  }
                 
              }
             
          }
      }
     

  }
  ngOnInit() {
    this.checkPermission("pdm","pd_em");
    this.service.getCustomFieldsForEmployee('ec',this.employeeId).subscribe(res => {
      if (res && res.data) {
      
        this.sectionsList = res.data;

       
    
      }
  });   
    this.isEdit = false;
    this.getJobCategoryList();
    this.isUserAdded = false;

  }

  checkRequiredFieldsInCustomFields(sectionID,customFields)
  {
    
    for(let section of this.sectionsList)
    {
      if(section.id === sectionID)
     {
      for(let custom of customFields)
      {
        if(custom.required)
        {
          if(custom.value === "" || custom.value === null || custom.value === undefined)
          {
            section.save=true;
            return ;
          }
        }
      }

      section.save=false;
     } 
    }
   

  }

  updateCustomField(sectionId ,customFields) : void
  {
    
  
  
    this.saveCutomList = [];
    this.saveCBCutomList = [];
    for(let custom of customFields)
    {
      if(custom.required)
      {
        if(custom.value === "")
        {
          this.toastr.error('Please fill the required fields');
          return ;
        }
      }
      var obj = 
      {
         fieldId : "",
	       fieldValue: ""  
      }
      if(custom.type != "cb")
      {
        let id : string = custom.id;
        let value : string = custom.value;
        if(custom.type == "dt")
        {
          value = moment(custom.value).format('YYYY-MM-DD').toString();
        }
        
        obj.fieldId = id;
        obj.fieldValue = value;
        this.saveCutomList.push(obj);
      }


      if(custom.type == "cb")
      {

        var obj2 = 
        {
           fieldId : "",
           values: []
        }
        obj2.fieldId = custom.id;
        let values : Array<any> = [];
        obj2.values = values;
        for(let c of custom.values)
        {
          if(c.booleanValue)
          {
            
            obj2.values.push(c.option);

          }
        }
        this.saveCBCutomList.push(obj2);
        
      }

     
    }
    
    this.service.saveCustomFieldsInEditEmployee(sectionId,this.employeeId,this.saveCutomList).subscribe(res => {
      if (res) {
      
        this.service.saveCustomFieldsCheckBoxesInEditEmployee(sectionId,this.employeeId,this.saveCBCutomList).subscribe(res => {
          if (res) {
            this.toastr.success('Updated Successfully!..');
          }
        }, error => {
          this.toastr.error('Error in  updating');
        });
      }
    }, error => {
      
    });
  }


  public createForm(): void {
    this.userForm = this.fb.group({
      ht: [''],
      mo: [''],
      name: ['', [Validators.required]],
      relation: ['', [Validators.required]],
      wt: ['']
    });
  }

  public addNewUser() {
    this.isEdit = false;
    this.dialogRef = this.dialog.open(this.addUser, {
      width: '560px',
      height: 'auto'
  });
    this.userForm.reset();
  }

  public close(): void {
    this.dialogRef.close();
  }

  public closeAttachment()
  {
    this.modalRef.close();
  }

  public getJobCategoryList(): void {
    this.isLoading = true;
    this.service.getEmergencyContactList(this.employeeId).subscribe(user => {
      if (user && user.data) {
        this.isLoading = false;
        this.dataSource = new MatTableDataSource<any>(user.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.err = '';
      }
    }, error => {
      this.isLoading = false;
      this.err = 'Error while getting Emergency Contact';
    });
  }

  public submitAddJobCategory(): void {
    if (this.isEdit) {
      this.edit();
    } else {
      this.add();
    }
  }

  public add(): void {
    this.userForm.value['empId'] = this.employeeId;
    this.isUserAdded = true;
    this.service.addEmergencyContact(this.userForm.value).subscribe(user => {
      this.isUserAdded = false;
      this.toastr.success('Emergency Contact added successfully');
      this.dialogRef.close();
      this.userForm.reset();
      this.getJobCategoryList();
    }, error => {
      const err = error.error && error.error.msg;
      this.isUserAdded = false;
      this.toastr.error(err || 'Error in adding Emergency Contact.Please try again.');
    });
  }

  public edit(): void {
    this.isUserAdded = true;
    this.userForm.value['empId'] = this.employeeId;
    this.service.editEmergencyContact(this.userForm.value, this.editId).subscribe(user => {
      this.isUserAdded = false;
      this.toastr.success('Emergency Contact updated successfully');
     this.dialogRef.close();
      this.getJobCategoryList();
    }, error => {
      const err = error.error && error.error.msg;
      this.isUserAdded = false;
      this.toastr.error(err || 'Error in updating Emergency Contact.Please try again.');
    });
  }

  public editUser(id): void {
    this.isEdit = true;
    this.service.getEmergencyContactById(this.employeeId,id).subscribe(user => {
      if (user && user.data) {
        this.userForm.patchValue(user.data);
        this.dialogRef = this.dialog.open(this.addUser, {
          width: '560px',
          height: 'auto'
      });
        this.editId = id;
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error getting Emergency Contact');
    });
  }

  public deleteUserForm(id): void {
    this.deleteId = id;
    this.modalRef = this.modalService.open(this.deleteUser);
  }

  public deleteJobCategory(): void {
    
    this.service.deleteMultipleEmergencyContact(this.employeeId,this.selectedCheckBoxList).subscribe(user => {
      if (user) {
        this.close();
        this.getJobCategoryList();
        this.toastr.success('Emergency Contact deleted !');
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error in deleting Emergency Contact');
    });
    this.onDeSelectAllClicked();
  }

  onSelectAllClicked() {
    this.selectedCheckBoxList = [];
    this.matCheckBoxes.forEach(
        item => {
            this.selectedCheckBoxList.push(item.value);
            item.checked = true;
        }
    );
}

  onDeSelectAllClicked() {
    this.selectedCheckBoxList = [];
    this.matCheckBoxes.forEach(
        item => {
            item.checked = false;
        }
    );
}
onCheckBoxChange($event: MatCheckboxChange, id: any) {
  if (!this.alreadySelected($event.source) && $event.checked) {
      this.selectedCheckBoxList.push($event.source.value);
  } else if (!$event.checked) {
      this.selectedCheckBoxList = this.selectedCheckBoxList.filter(
          value => {
              return value !== $event.source.value;
          }
      );
  }
}
alreadySelected(item: MatCheckbox): boolean {
  this.selectedCheckBoxList.forEach(
      value => {
          if (item === value) {
              return true;
          }
      }
  );
  return false;
}

  onDeleteSelected() {
    this.dialogRef = this.dialog.open(this.deleteUser, {
      width: '440px',
      height: 'auto'
  });
  }

  onUserChanged(user: HTMLInputElement) {
    if (user.checked) {
      this.selectedUsers.push(user);
      return;
    }
    this.selectedUsers = this.selectedUsers.filter(value => value !== user);
  }

  onModelChange(value: string) {
    console.log(value);
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  exportToCsv() {
    const csv = this.dataSource.filteredData.map(
        row => {
          return [
            row.name,
            row.relation,
            row.ht,
            row.wt,
            row.mo
          ].join(',') + '\n';
        }
    );
    csv.unshift(['name', 'relation', 'home Telephone', 'office Telephone', 'Mobile phone'].join(',') + '\n');
    const exportDate = new Date();
    // tslint:disable-next-line:max-line-length
    const exportFileName = `Csv-export-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}.csv`;
    saveAs(new Blob(csv, {type: 'text/csv;charset=utf-8'}), exportFileName);
  }

  saveAsPdf() {
    if (this.dataSource.filteredData.length <= 0) {
      this.toastr.error('No Data to export');
      return;
    }
    const body = [];
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      body.push(
          [
            this.dataSource.filteredData[i].name,
            this.dataSource.filteredData[i].relation,
            this.dataSource.filteredData[i].ht,
            this.dataSource.filteredData[i].wt,
            this.dataSource.filteredData[i].mo
          ]);
    }
    const doc = new jsPDF();
    autoTable(doc, {
      head: [['name', 'relation', 'home Telephone', 'office Telephone', 'Mobile phone']],
      body: body
    });
    const exportDate = new Date();
    const exportFileName = `job titles-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}}`;
    doc.save(exportFileName);
  }

  onAddAttachment(id) {
    this.attacherId = id;
    this.service.getAttachmentList(this.attacherId).subscribe(
        r => {
          if (r && r.data) {
            this.attachmentList = r.data;
            
            this.dialogRef = this.dialog.open(this.attach, {
              width: '440px',
              height: 'auto'
          });
          }
        },
        error => {
        }
    );
    this.comment = '';
    this.fileType = '';
    this.fileObj = null;
   
  }

  fileChange($event, type): void {
    this.uploadedFile = undefined;
    const reader = new FileReader();
    const file = $event.target.files[0];
    this.uploadedFile = file;
    const fileName = file && file.name;
    if (file && (file.size / 1024) < 2048) {
      this.fileObj = {
        added: new Date(),
        addedBy: 'string',
        desc: this.comment,
        docType: 'dd',
        empId: this.attacherId,
        fileName: fileName,
        size: file.size,
        url: ''
      };
    } else {
      this.fileError = 'Max size 2 MB';
      this.toastr.error('Max 2Mb File allowed.');
    }
  }

  onDownloadClicked(attachment: any) {
    const URL = attachment['url'];
    const FILE_NAME = attachment['fileName'];
    window.open(URL);
  }

  
  onDeleteClicked(attachment: any) {
    const ID = attachment['id'];
    this.service.deleteAttachment(ID).subscribe(
        res => {
          this.service.getAttachmentList(this.attacherId).subscribe(
              r => {
                this.attachmentList = r.data;
                this.getJobCategoryList();
                this.toastr.success('Deleted');
              },
              error => {
                console.log(error);
                this.toastr.error('Failed to delete');
              }
          );
        }
    );
  }
  
  saveAttachment() {
    if (this.fileObj !== null) {
      this.service.uploadFile(this.uploadedFile).subscribe(resp => {
        if (resp && resp.data) {
          this.fileObj['url'] = resp.data.filename;
          this.fileObj['desc'] = this.comment;
          this.service.addAttachment(this.fileObj).subscribe(
              res => {
                this.service.getAttachmentList(this.attacherId).subscribe(
                    r => {
                      this.attachmentList = r.data;
                      this.toastr.success('Attachment Added');
                      this.getJobCategoryList();
                      this.close();
                    },
                    error => {
                      this.toastr.error('Failed to add Attachment');
                      this.close();
                    }
                );
              },
              error => {
                this.toastr.error('Failed to add Attachment');
                this.close();
              }
          );
        }
      }, error => {
        const err = error.error && error.error.msg;
        console.log(err);
        this.toastr.error('Failed to save Attachment', 'Upload Unsuccessful');
        this.close();
      });
    } else {
      this.toastr.error('No file Selected');
    }
  }
}

