import { Component, OnInit } from "@angular/core";
import { DashboardService } from "../../../dashboard.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NotificationService } from "src/app/core/services/notification.service";
import * as moment from "moment";
import { LanguageConfig } from "src/app/Language-config";
import { MatTableDataSource } from "@angular/material/table";
import { CrudService } from "src/app/core/services/base-crud.service";
import { Configuration_AttendanceId } from "src/app/service/constants";
import { AuthService } from "src/app/core/services/auth.service";

@Component({
  selector: "app-punch-in",
  templateUrl: "./punch-in.component.html",
  styleUrls: ["./punch-in.component.scss"],
  providers: [CrudService],
})
export class PunchInComponent implements OnInit {
  public status = {
    lastCheckedIn: "",
    lastCheckedOut: "",
    lastCheckedInLocation: "",
    btnLabel: "",
    message: "",
  };
  public isLoading: boolean;
  public punchInDate: string;
  public punchInTime: string;

  public lang = new Map();
  displayedColumns = ["type", "time", "by", "location", "duration", "comments"];
  dataSource: MatTableDataSource<any>;
  locations: any;
  formatedTotalDuration: string;

  public addForm: FormGroup;
  canPunch: boolean = false;
  isAdmin: boolean = false;

  constructor(
    private service: DashboardService,
    private fb: FormBuilder,
    private toastr: NotificationService,
    public genericConfigurationService: CrudService<any>,
    private readonly authService: AuthService,
  ) {
    this.lang = LanguageConfig.map;
    this.genericConfigurationService.setEntityName("genericConfiguration");
  }

  ngOnInit() {
    this.addForm = this.fb.group({
      locationId: [""],
      comments: [""],
    });

    this.service.getLocationList().subscribe((resp) => {
      if (resp && resp.data) {
        this.locations = resp.data;
      }
    });

    this.isAdmin = this.authService.isAdmin();

    if (this.genericConfigurationService.attendanceConfigCache != null) {
      this.canPunch = !this.genericConfigurationService.attendanceConfigCache.attendance_forceEmployeePunchByApp;
    } else {
      this.genericConfigurationService.getById(Configuration_AttendanceId).subscribe((resp) => {
        if (resp && resp.data) {
          this.genericConfigurationService.attendanceConfigCache = resp.data;
          this.canPunch = !this.genericConfigurationService.attendanceConfigCache.attendance_forceEmployeePunchByApp;
        }
      });
    }

    this.getStatus();
    this.dataSource = new MatTableDataSource();
  }

  public displayTimeinOrginalTimezone(date, timezone) {
    if (timezone) {
      return new Date(date).toLocaleString("en-US", { timeZone: timezone });
    } else {
      return date;
    }
  }

  public getStatus() {
    this.isLoading = true;
    this.service.getAttendanceStatus().subscribe((resp) => {
      if (resp && resp.data) {
        // this.status.lastCheckedIn = resp.data.lastCheckedIn;
        // this.status.lastCheckedOut = resp.data.lastCheckedOut;
        // this.status.lastCheckedInLocation = resp.data.lastCheckedInLocation;
        // this.status.btnLabel = this.status.lastCheckedIn && !this.status.lastCheckedOut ? "Check Out" : "Check In";

        // this.status.message = this.status.lastCheckedOut ? "Last CheckOut is " +  moment(this.status.lastCheckedOut).format("HH:mm") :
        //                       this.status.lastCheckedIn ? "Last CheckIn is " + moment(this.status.lastCheckedIn).format("HH:mm") : "";

        this.status = resp.data;
        if (this.status.lastCheckedIn) {
          this.addForm.patchValue({ locationId: this.status.lastCheckedInLocation });
        }

        let totalDuration = 0;
        resp.data.todayRecords.forEach((row) => {
          if (row.type === "Out") {
            totalDuration = totalDuration + row.duration;
          }
          let hours = Math.floor(row.duration / 60);
          let minutes = row.duration % 60;
          let formatedDuration = hours + ":" + (minutes > 9 ? minutes : "0" + minutes);
          row.formatedDuration = formatedDuration;
        });

        let hours = Math.floor(totalDuration / 60);
        let minutes = totalDuration % 60;
        this.formatedTotalDuration = hours + ":" + (minutes > 9 ? minutes : "0" + minutes);

        this.dataSource.data = resp.data.todayRecords;
        this.isLoading = false;
      }
    });
  }

  public save(): void {
    this.isLoading = true;
    this.service.recordAttendance(this.addForm.value).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.toastr.success("Record saved");
          this.getStatus();
        }
        this.isLoading = false;
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error in saving Punch In");
        this.isLoading = false;
      }
    );
  }
}
