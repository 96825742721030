<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
      <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
          <div class="col-md-8">
          <div class="page-header-title">
              <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted">Leave Percentage</h4></span>
          </div>
          </div>
          <div class="col-md-4"></div>
      </div>
      </div>
  </div>
</div>

<div class="page-body no-title">
  <div class="row">
    <div class="col-sm-12">
      <app-card>
        <form class="form-material" action="javascript:" [formGroup]="leaveForm" class="form-material full-width-inputs">
          <div class="row form-group">
            <div class="col-md-4">
              <mat-form-field class="form-element">
                <mat-label> Leave Type</mat-label>
                <mat-select formControlName="leavetypeid" class="form-control">
                  <mat-option  *ngFor="let t of leaveTypeList" [value]="t.id">
                    {{t.leaveType}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!-- <select formControlName="leavetypeid" class="form-control">
                <ng-container *ngFor="let t of leaveTypeList">
                  <option [value]="t.id">{{t.leaveType}}</option>
                </ng-container>
              </select>
              <label class="label-after float-label">Leave Type</label> -->
            </div>
          <!-- </div> -->
          <!-- <div class="row form-group">   -->
            <div class="col-md-4">
              <mat-form-field class="form-element">
                <mat-label>Start Date</mat-label>
                <input [matDatepicker]="picker1" class="form-control"
                    formControlName="startdate" id="dob" matInput>
                <mat-datepicker-toggle [for]="picker1"matSuffix></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>

              <!-- <input type="date"  class="form-control" id="dob"
                    formControlName="startdate">
              <span class="form-bar"></span>
              <label class="float-label  label-after" for="dob">Start Date</label> -->
            </div>
            <div class="col-md-4">
              <mat-form-field class="form-element">
                <input   id="dob" class="form-control" [matDatepicker]="picker2" 
                    [attr.min]="leaveForm.value.startdate && leaveForm.value.startdate"
                    [min]="leaveForm.value.startdate && leaveForm.value.startdate" 
                    placeholder="End Date" formControlName="enddate" matInput>
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
                <mat-label for="dob">End Date</mat-label>
              </mat-form-field>

              <!-- <input type="date" [attr.min]="leaveForm.value.startdate && leaveForm.value.startdate"
                    [min]="leaveForm.value.startdate && leaveForm.value.startdate" class="form-control"
                    placeholder="End Date" formControlName="enddate">
              <span class="form-bar"></span>
              <label class="float-label label-after" for="dob">End Date</label> -->
            </div>
          </div> 
          <div class="row">
            <div class="col-sm-12 text-right">
              <button type="submit" class="btn btn-save btn-primary" (click)="submitLeave()">Generate</button>
            </div>
          </div>
        </form>
      </app-card>
      
      <div class="row d-n-p">
        <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6">
        </div>
      </div>
          <!-- <app-loading *ngIf="isLoading && !err" [isLoading]="isLoading && !err"></app-loading> -->
          <app-card [title]="'Leave Percentage'">
            <div class="dt-responsive">
          <div class="table-responsive">
            <mat-card-content>
              <mat-table [dataSource]="dataSource" matSort>
                  <ng-container matColumnDef="leaveType">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>LeaveType</mat-header-cell>
                      <mat-cell *matCellDef="let row">
                          {{ row.name }}
                      </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="percentage">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>percentage(%)</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        {{ row.percentage }}
                    </mat-cell>
                </ng-container>
                  
                  <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: columns;">
                  </mat-row>
              </mat-table>
              <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
              <mat-paginator [length]="paging.totalRecords" [pageSize]="paging.pageSize"
                             [pageSizeOptions]="paging.pageSizeOptions"
                             [pageIndex]="paging.pageIndex" (page)="pageChange($event)">
              </mat-paginator>
          </mat-card-content>
            
          </div>
          <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
        </div>
      </app-card>
    </div>
  </div>
</div>





