    <div mat-dialog-content>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">
                <span *ngIf="typeForm=='add'">Add Disciplinary Case</span>
                <span *ngIf="typeForm=='editcase'">Edit Disciplinary Case</span>
            </h4>
        </div>
        <div class="mat-modal-body">
            <app-loading *ngIf="isLoading" [isLoading]="isLoading"></app-loading>
            <form class="form-material full-width-inputs" *ngIf="typeForm=='add'" [formGroup]="disciplineForm" action="javascript:">
                <div class="row form-group">
                    <div class="col-sm-6">
                        <mat-form-field class="form-element">
                            <mat-select class="form-control" formControlName="empId" placeholder="Employee" #singleSelect>
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="userFilterCtrl" placeholderLabel="Search"
                                                           noEntriesFoundLabel="Options Not Found"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let item of filteredUsers" [value]="item.id">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6">
                        <mat-form-field class="form-element">
                            <input class="form-control" matInput placeholder="Case Name"  formControlName="caseName">
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12">
                        <textarea class="form-control" matInput placeholder="Description" formControlName="desc"></textarea>
                    </div>
                </div>
            </form>



            <form class="form-material" action="javascript:" [formGroup]="disciplineForm" *ngIf="typeForm=='editcase'">
                <div class="form-row">
                  <div class="width-for-form">
                    <mat-form-field>
                      <mat-select formControlName="empId" placeholder="Employee *" #singleSelect [disabled]='!editData'>
                        <mat-option>
                          <ngx-mat-select-search [formControl]="userFilterCtrl"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let item of filteredUsers" [value]="item.id">
                          {{item.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div style="width: 10%;"></div>
                  <div  class="width-for-form">
                      <mat-form-field class="example-full-width">
                          <input matInput placeholder="Case Name *"  formControlName="caseName" [readonly]='!editData'>
                        </mat-form-field>
                  </div>
                  <div class="width-for-form">
                      <mat-form-field class="example-full-width">
                          <input matInput placeholder="Created On"  formControlName="createdOn" readonly>
                        </mat-form-field>
                    </div>
                    <div style="width: 10%;"></div>
                    <div  class="width-for-form">
                        <mat-form-field class="example-full-width">
                            <input matInput placeholder="Created By" formControlName="createdBy" readonly>
                        </mat-form-field>
                    </div>
                    <mat-form-field class="example-full-width" style="width: 100%;">
                        <textarea matInput placeholder="Description" formControlName="desc"></textarea>
                    </mat-form-field>
                </div>

            </form>

            <div *ngIf="typeForm=='add'" class="action-buttons multi-btns col-sm-12 text-right">
                <button mat-raised-button class="btn-danger" (click)="onNoClick()" [mat-dialog-close]="true" >
                    <i class="flaticon-cancel"></i> Cancel
                </button>

                <button mat-raised-button class="btn btn-primary"  color="primary" (click)="submitAddDisciplines()"  [disabled]="disciplineForm.invalid">
                    <i class="flaticon-diskette"></i>Save
                </button>
            </div>

            <div *ngIf="typeForm=='editcase'" class="row my-2 mx-2">
                <div class="action-buttons multi-btns col-sm-12 text-right">
                    <button (click)="onNoClick()" [mat-dialog-close]="true" class="btn-danger" mat-raised-button>
                        Cancel
                    </button>
                    <button (click)="takeDisciplineAction()" *ngIf="typeForm=='editcase'" color="warn"
                            mat-raised-button>Take Disciplinary Action
                    </button>
                    <button (click)="closeCase()" *ngIf="editDataSave && !editDataSave.close" color="warn"
                            mat-raised-button>Close Case
                    </button>
                    <button (click)="editCase()" *ngIf="typeForm=='editcase' && !editData " class="btn-primary"
                            color="primary" mat-raised-button>Edit
                    </button>
                    <button (click)="saveCase()" *ngIf="typeForm=='editcase' && editData" class="btn-primary"
                            color="primary" mat-raised-button>Save
                    </button>
                    <button (click)="submitAddDisciplines()" *ngIf="typeForm=='add'" [disabled]="disciplineForm.invalid"
                            class="btn-primary" color="primary" mat-raised-button>Save
                    </button>
                </div>
            </div>
        </div>
    </div>
  

    <ng-template #disActions let-modal>
        <div class="card my-0">
          <div class="header">
            <h4 class="card-title card-title-modal">Disciplinary Actions</h4>
          </div>
          <div class="card-content px-2 py-2">
              <label id="example-radio-group-label">Select at least one to proceed</label>
              <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group"
                [(ngModel)]="disAction">
                <mat-radio-button class="example-radio-button" *ngFor="let war of actions" [value]="war">
                  {{war.name}}
                </mat-radio-button>
              </mat-radio-group>
          
            <div class="row">
              <div class="col-sm-12 text-right">
                <button type="submit" class="btn btn-cancel btn-danger" (click)="close()">Cancel</button>
                <button type="submit" class="btn btn-save btn-primary" [disabled]="disAction==null" (click)="selectAction()">Select</button>
              </div>
            </div>
          </div>
        </div>
    </ng-template>
