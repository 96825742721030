import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import moment from "moment";
import { DashboardService } from "../../dashboard.service";
import { MatTableDataSource } from "@angular/material/table";
import { PageEvent } from "@angular/material/paginator";
import { LanguageConfig } from "src/app/Language-config";
import { MatDialog } from "@angular/material/dialog";
import { saveAs } from 'file-saver-es';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { PayrollService } from "../payroll.service";

@Component({
  selector: "app-payslip",
  templateUrl: "./payslip.component.html",
  styleUrls: ["./payslip.component.scss"],
})
export class PayslipComponent implements OnInit {
  public selectedEmpId: any;
  public isLoading: boolean;
  public hasResult: boolean = false;
  public months = moment.months();
  public years: Array<any>;
  public selectedMonth = "";
  public selectedYear = "";

  displayedColumns = ["employeeId", "employeeName", "date", "grossSalary", "totalDeductions", "netPay"];
  dataSource: MatTableDataSource<any>;
  public totalRecords: number;
  public activePage: number;
  public pageSize: number;

  public lang = new Map();
  @ViewChild("viewPayslipModal", { static: false }) viewPayslipModal;
  public dialogRef: any;

  public payslip: any;
  public org:any;
  public payslipDisplayedColumns: string[] = ["earningDesc", "earningAmount", "deductionDesc", "deductionAmount"];
  payslipLines: MatTableDataSource<any>;

  @ViewChild('payslipContent', { static: false }) payslipContent: ElementRef;

  constructor(private payrollService: PayrollService, private dialog: MatDialog) {
    this.lang = LanguageConfig.map;
  }

  ngOnInit(): void {
    this.years = new Array();
    this.years.push(new Date().getFullYear());
    this.selectedEmpId = { id: "", name: "" };
  }

  public updateSelectedEmployee(event) {
    this.selectedEmpId.id = event ? event.id : "";
    this.selectedEmpId.name = event ? event.name : "";
  }

  public getEmployeePayslips() {
    this.isLoading = true;
    // let month = this.selectedMonth? this.selectedMonth : '';
    // let year = this.selectedYear? this.selectedYear: '';
    this.payrollService.getEmployeePayslips(this.selectedEmpId.id, this.selectedMonth, this.selectedYear).subscribe((res) => {
      if (res && res.data) {
        this.dataSource = new MatTableDataSource<any>(res.data);
        this.hasResult = true;
        this.isLoading = false;
      }
    });
  }

  handlePageEvent(event: PageEvent) {
    // this.pageSize = event.pageSize;
    // this.activePage = event.pageIndex;
    // this.viewAttendanceSummary();
  }

  viewPayslip(payslip) {
    this.isLoading = true;
    this.payrollService.getPayslipDetails(payslip.id).subscribe((res) => {
      if (res && res.data) {
        this.payslip = res.data.payslip;
        this.org = res.data.org;
        this.payslipLines = new MatTableDataSource(res.data.lines);
        this.isLoading = false;
        this.dialogRef = this.dialog.open(this.viewPayslipModal, {
          width: "900px",
          height: "auto",
        });
      }
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public download() {
  
    this.isLoading = true;
    const pdf = new jsPDF();
    const htmlContent = this.payslipContent.nativeElement;
    html2canvas(htmlContent, { scale: 2 }).then(canvas => {
      const imgData = canvas.toDataURL('image/png', 1.0);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
  
      // Add header text
      pdf.setFontSize(18);
      pdf.setFont('helvetica', 'bold');
      pdf.text(this.org.name, pdfWidth / 2, 30, { align: 'center' });
      
      pdf.setFontSize(14);
      pdf.text("Payslip", pdfWidth / 2, 40, { align: 'center' });
  
      // Add image with padding
      const imgWidth = pdfWidth - 20; // Adjust padding
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, 'PNG', 10, 60, imgWidth, imgHeight);
      pdf.save(this.payslip.forDate +  '_payslip.pdf');
      this.isLoading = false;
    });

    // pdf.html(htmlContent, {
    //   callback: (pdf) => {
    //     pdf.save('exported-document.pdf');
    //   }
    // });
  }

}
