<div id="header-page" class="ng-star-inserted header-page">
    <div class="page-header ng-star-inserted">
        <div class="page-block ng-star-inserted">
        <div class="row align-items-center">
            <div class="col-md-8">
            <div class="page-header-title">
                <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted">{{lang.get("skills")}}</h4></span>
            </div>
            </div>
            <div class="col-md-4"></div>
        </div>
        </div>
    </div>
    </div>
    
    <div class="page-body no-title">
    <div class="row">
        <div class="col-sm-12">
            <div class="row top-tools">
                <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6 left-tools">
                    <span class="export-icon-btn csv" (click)="exportToCsv()">
                        <i class="material-icons">get_app</i><span>{{lang.get("csv")}}</span>
                    </span>
                    <span class="export-icon-btn pdf" (click)="saveAsPdf()">
                        <i class="material-icons">get_app</i><span>{{lang.get("pdf")}}</span>
                    </span>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
                    <div class="relative-position search-filter-con"> 
                        <span class="icon material-icons ng-tns-c114-0"> search </span>
                        <mat-form-field>
                            <input matInput #inputSearch type="search" [(ngModel)]="filterQuery" maxlength="40"
                            (ngModelChange)="onModelChange(filterQuery)"
                            class="form-control input-sm full-data-search">    
                        </mat-form-field>
                        <span [hidden]="!write" class="mytooltip cus-left tooltip-effect-4">
                            <span class="tooltip-item">
                                <button mat-mini-fab class="btn btn-primary light add-rounded margin-button-05" (click)="addNewUser()">
                                    <i class="fa fa-plus"></i>
                                </button>
                            </span>
                            <span class="tooltip-content clearfix">
                            <span class="tooltip-text">{{lang.get("new")}}</span>
                            </span>
                        </span>
                    </div>
                </div>
            </div>

            
            <app-card [title]="'Skills'">
                <div class="dt-responsive" *ngIf="dataSource">
                    <div class="table-responsive table hrm-table-style-1 full-data-table table-cols-4">
                      <mat-card-content>
                        <mat-table [dataSource]="dataSource" matSort>
                            <ng-container matColumnDef="select">
                                <mat-header-cell [hidden]="!delete" *matHeaderCellDef>
                                    <a [matMenuTriggerFor]="menu" class="check-all">
                                <span class="material-icons">
                                    more_vert
                                </span>
                                    </a>

                                    <mat-menu #menu="matMenu">
                                        <button (click)="onSelectAllClicked()" mat-menu-item>{{lang.get("select all")}} </button>
                                        <button (click)="onDeSelectAllClicked()" *ngIf="selectedCheckBoxList.length > 0"
                                                mat-menu-item>{{lang.get("deselect all")}} 
                                        </button>
                                        <button (click)="onDeleteSelected()" *ngIf="selectedCheckBoxList.length > 0"
                                                mat-menu-item>{{lang.get("delete selected")}} 
                                        </button>
                                    </mat-menu>
                                </mat-header-cell>
                                <mat-cell [hidden]="!delete" *matCellDef="let row">
                                    <mat-checkbox #matCheckbox (change)="onCheckBoxChange($event, row.id)"
                                                  value="{{row.id}}"></mat-checkbox>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="name">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>{{lang.get("name")}}</mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                    {{ row.name }}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="desc">
                                <mat-header-cell *matHeaderCellDef >{{lang.get("description")}}</mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                    {{ row.desc}}
                            </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="edit">
                            <mat-header-cell [hidden]="!update" *matHeaderCellDef>{{lang.get("edit")}}</mat-header-cell>
                            <mat-cell [hidden]="!update" *matCellDef="let row">
                                <span class="mytooltip tooltip-effect-4">
                                    <span class="tooltip-item"><i class="single-icon-click fa fa-pencil" (click)="editUser(row.id)"></i></span>
                                    <span class="tooltip-content clearfix">
                                    <span class="tooltip-text">{{lang.get("edit")}}</span>
                                    </span>
                                </span>
                            </mat-cell>
                          </ng-container>
                          <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                          <mat-row *matRowDef="let row; columns: columns;">
                          </mat-row>
                        </mat-table>
                        <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
                          <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                      </mat-card-content>
                    </div>
                    <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
                  </div>
                </app-card>
        </div>
    </div>
</div>

<ng-template #addUser let-modal>
    <!-- <div class="card my-0"> -->
    <!-- <div class="header"> -->
    <div class="mat-modal-header">
        <!-- <h4 class="card-title card-title-modal"> -->
        <h4 class="mat-title-modal">
            <span *ngIf="!isEdit">{{lang.get("add")}}</span>
            <span *ngIf="isEdit">{{lang.get("edit")}}</span> {{lang.get("skills")}}</h4>
    </div>
    <!-- <div class="card-content"> -->
    <div class="mat-modal-body">
        <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>
        <form *ngIf="!isUserAdded" [formGroup]="userForm" action="javascript:" class="form-material full-width-inputs">
            <div class="row form-group">
                <div class="col-sm-12">
                    <mat-form-field class="form-element">
                        <mat-label>{{lang.get("name")}}</mat-label>
                        <input pattern= "*" class="form-control" formControlName="name" id="name" matInput maxlength="40"
                               type="text" required="">
                        <mat-error
                                *ngIf="!userForm.controls.name.valid && userForm.controls.name.touched">{{ validationError.required }}</mat-error>
                    </mat-form-field>


                    <!-- <input type="text" class="form-control" id="name" formControlName="name" maxlength="40">
                <span class="form-bar"></span>
                <label class="float-label" for="name">Name</label>
                <span class="validation-error"
                      *ngIf="!userForm.controls.name.valid && userForm.controls.name.touched">{{ validationError.required }}</span> -->
                </div>

                <div class="col-sm-12">
                    <mat-form-field class="form-element">
                        <mat-label>{{lang.get("description")}}</mat-label>
                      
                               <textarea formControlName="desc" maxlength="50" matInput class="form-control"
                               name="name"></textarea>
                    </mat-form-field>


                    <!-- <input type="text" class="form-control" id="desc" formControlName="desc" maxlength="40">
                    <span class="form-bar"></span>
                    <label class="float-label" for="desc">Description</label> -->
                </div>
            </div>
            <!-- <div class="form-group form-default">
                <input type="text" class="form-control" id="desc" formControlName="desc" maxlength="40">
                <span class="form-bar"></span>
                <label class="float-label" for="desc">Description</label>
            </div> -->
            <div class="row">
                <div class="action-buttons multi-btns col-sm-12 text-right">
                    <button (click)="close()" class="btn-danger" mat-raised-button><i class="flaticon-cancel"></i>{{lang.get("cancel")}}
                    </button>
                    <button (click)="submitAddJobCategory()" [disabled]="!userForm.valid" class="btn btn-primary"
                            color="primary"
                            mat-raised-button><i class="flaticon-diskette"></i>{{lang.get("save")}}
                    </button>
                </div>
            </div>
        </form>
        </div>
    <!-- </div> -->
</ng-template>

<ng-template #deleteUser let-modal>
    <!-- <div class="card my-0"> -->
    <!-- <div class="header"> -->
    <div class="mat-modal-header">
        <!-- <h4 class="card-title card-title-modal">Delete Skills</h4> -->
        <h4 class="mat-title-modal">{{lang.get("delete")}} {{lang.get("skills")}}</h4>
    </div>
    <!-- <div class="card-content px-2 py-2"> -->
    <div class="mat-modal-body">
        <div class="text-left are-u">{{lang.get("areyousure")}}</div>
        <div class="row">
            <div class="multi-btns col-sm-12 text-right">
                <button (click)="close()" class="btn-danger" mat-raised-button><i class="flaticon-cancel"></i>{{lang.get("cancel")}}
                </button>
                <button (click)="deleteJobCategory()" class="btn-primary" color="primary" mat-raised-button>
                    <i class="flaticon-trash"></i>{{lang.get("delete")}}
                </button>
            </div>
        </div>
    </div>
    <!-- </div> -->
</ng-template>



