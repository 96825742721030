import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MatDialog} from '@angular/material/dialog'
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { NotificationService } from "src/app/core/services/notification.service";
import {AnnouncementService} from '../../../../../service/announcement.service';

@Component({
    selector: 'app-add-category',
    templateUrl: './add-category.component.html',
    styleUrls: ['./add-category.component.scss']
})
export class AddCategoryComponent implements OnInit {
    constructor(
        // private modalService: NgbModal,
        private dialog: MatDialog,
        private fb: FormBuilder,
        private toastr: NotificationService,
        private announcementService: AnnouncementService
    ) {
        this.userForm = this.fb.group({
            name: ['', [Validators.required]]
        });
    }
    @ViewChild('addCategory', {static: false}) addCategory;
    @Output() reloadData = new EventEmitter<{}>();
    @Output() exportCsv = new EventEmitter<{}>();
    @Output() exportPdf = new EventEmitter<{}>();
    @Output() onChange  = new EventEmitter<string>();
    // public modalRef: any;
    public dialogRef: any;
    isEdit = false;
    apiCalling = false;
    public filterQuery = '';

    public userForm: FormGroup;
    public validationError = {
        required: 'Required'
    };

    category = null;

    ngOnInit() {
        this.userForm = this.fb.group({
            name: ['']
        });
    }

    public close(): void {
        this.userForm.patchValue({name: ''});
        this.category = null;
        this.dialogRef.close();
    }

    public editCategory(obj) {
        this.isEdit = true;
        this.category = obj;
        this.userForm.patchValue({name: this.category.name});
        this.dialogRef = this.dialog.open(this.addCategory,{
            width: '440px',
            height: 'auto'
        });
    }

    public addNewCategory() {
        this.category = null;
        this.isEdit = false ;
        this.dialogRef = this.dialog.open(this.addCategory,{
            width: '440px',
            height: 'auto'  
        });
    }

    public submitAddJobCategory(): void {
        this.userForm.markAsTouched();
        if (this.userForm.invalid) {
            this.toastr.error('Please fill all required field.');
            return;
        }
        const obj = this.userForm.value;
        this.category && (obj['id'] = this.category.id);
        this.apiCalling = true;
        console.log(obj);
        (this.category ? this.announcementService.updateDocumentCategory(obj) : this.announcementService.saveDocumentCategory(obj))
            .subscribe(
                res => {
                    this.apiCalling = false;
                    if (res && res.data) {
                        this.category && (this.category['name'] = res.data.name);
                        this.toastr.success('Category successfully saved.');
                    } else {
                        this.toastr.error(res.msg);
                    }
                    !this.category && this.reloadData.emit({});
                    this.close();
                }, error => {
                    const err = error.error.msg;
                    this.apiCalling = false;
                    this.toastr.error(err);
                    this.close();
                });
    }
    exportToCsv() {
        this.exportCsv.emit({});
    }
    saveAsPdf() {
        this.exportPdf.emit({});
    }
    
    onModelChange(value:string){
        this.onChange.emit(value);
        console.log('add'+value);
    }
    
}

