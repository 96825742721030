<div id="header-page" class="ng-star-inserted header-page">
    <div class="page-header ng-star-inserted">
        <div class="page-block ng-star-inserted">
        <div class="row align-items-center">
            <div class="col-md-8">
            <div class="page-header-title">
                <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted">Edit Employee Task Information</h4></span>
            </div>
            </div>
            <div class="col-md-4"></div>
        </div>
        </div>
    </div>
</div>


<div class="page-body">
    <div class="row">
      <div class="col-sm-12">
       
           
<app-loading *ngIf="isLoading" [isLoading]="isLoading"></app-loading>
 <div class="dt-responsive" *ngIf="!isLoading">
            
     
        <app-card [title]="">
           <div>
            <div>
              <mat-card-content>
                <h3>On-boarding Task Details</h3>   
                <form class="form-material full-width-inputs" action="javascript:" [formGroup]="userForm">
                    <div class="row form-group">
                        <div class="col-md-6">
                          <mat-form-field class="form-element">
                              <mat-label>Occasions</mat-label>
                              <mat-select [disabled]="isEdit" (ngModelChange) ="getEmployeesByEvents($event)" required disableOptionCentering *ngIf="eventsList" [ngClass]="'ng-select'" class="form-control"
                              formControlName="eventId" [multiple]="false">
                              <mat-option  value="">--Select--</mat-option>
                                <mat-option *ngFor="let el of eventsList" [value]="el.value">{{el.label}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="form-element">
                                <mat-label>Participant</mat-label>
                                <mat-select [disabled]="isEdit" required disableOptionCentering *ngIf="employeeList" [ngClass]="'ng-select'" class="form-control"
                                       formControlName="participantId">
                                       <mat-option>
                                        <ngx-mat-select-search [formControl]="userFilterCtrl2"
                                                            placeholderLabel="Search"></ngx-mat-select-search>
                                    </mat-option>
                                       <mat-option  value="">--Select--</mat-option>
                                    <mat-option *ngFor="let empl of filteredEmployees2" [value]="empl.value">{{empl.label}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-md-6">
                            <mat-form-field class="form-element">
                                <mat-label> Responsible Person</mat-label>
                                <mat-select [disabled]="isEdit" required disableOptionCentering *ngIf="employeeList" [ngClass]="'ng-select'" class="form-control"
                                       formControlName="ownerId" >
                                       <mat-option>
                                        <ngx-mat-select-search [formControl]="userFilterCtrl"
                                                            placeholderLabel="Search"></ngx-mat-select-search>
                                    </mat-option>
                                       <mat-option  value="">--Select--</mat-option>
                                       <mat-option *ngFor="let empl of filteredEmployees" [value]="empl.value">{{empl.label}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                   
                    <div class="row form-group">
                        <div class="col-md-6">
                            <mat-form-field class="form-element">
                                <mat-label>Task Name</mat-label>
                                <input [readonly]="isEdit" required matInput  name="name"  class="form-control" required="" formControlName="name">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-md-12">
                            <mat-form-field class="form-element">
                                <mat-label>Description</mat-label>
                                <textarea [readonly]="isEdit" matInput name="name" class="form-control"formControlName="description"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-md-6">
                            <mat-form-field class="form-element">
                                <mat-label>Also notify Email</mat-label>
                                <input [readonly]="isEdit" matInput  name="notifyEmail" class="form-control" formControlName="notifyEmail">
                                <mat-error class="validation-error"  *ngIf="!userForm.controls.notifyEmail.valid && userForm.controls.notifyEmail.touched">{{ validationError.email }}</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="form-element">
                                <input [disabled]="isEdit" matInput  class="form-control" id="dueDate" [matDatepicker]="mPicker2" formControlName="dueDate" placeholder="Due Date" >
                                <mat-datepicker-toggle matSuffix [for]="mPicker2"></mat-datepicker-toggle>
                                <mat-datepicker #mPicker2></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-md-6">
                            <mat-form-field class="form-element">
                                <input disabled class="form-control" id="effectiveDate" matInput [matDatepicker]="mPicker1"  formControlName="effictiveDate" placeholder="Effective Date" >
                                <mat-datepicker-toggle matSuffix [for]="mPicker1"></mat-datepicker-toggle>
                                <mat-datepicker #mPicker1></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div class="col-md-6">
                            <mat-form-field class="form-element">
                                <mat-label>Priority</mat-label>
                                <mat-select [disabled]="isEdit" required disableOptionCentering class="form-control" formControlName="priority">
                                    <mat-option value="low">Low</mat-option>
                                    <mat-option value="medium">Medium</mat-option>
                                    <mat-option value="high">High</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-md-4">
                            <mat-form-field class="form-element">
                                <mat-label>Notified Before</mat-label>
                                <input [readonly]="isEdit"  name="notifiedBefore" matInput class="form-control"
                                   formControlName="notifiedBefore">
                                <mat-error class="validation-error"
                                  *ngIf="!userForm.controls.notifiedBefore.valid && userForm.controls.notifiedBefore.touched">{{ validationError.required }}</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-2">
                            <mat-form-field class="form-element">
                                <mat-label></mat-label>
                                <mat-select [disabled]="isEdit" class="form-control" formControlName="notifiedBeforePeriod">
                                <mat-option value="days">Days</mat-option>
                                <mat-option value="weeks">Weeks</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="col-md-6">
                            <mat-form-field class="form-element">
                                <mat-label>Completed Date</mat-label>
                                <input readonly  matInput  name="name"  class="form-control" formControlName="completedDate">

                            </mat-form-field>
                        </div>
                       
                    </div>
                    <div class="row form-group">
                        <div class="col-md-12">
                            <mat-form-field class="form-element">
                                <mat-label>Comment</mat-label>
                                <textarea [readonly]="isEdit" matInput name="name"  class="form-control"
                                      formControlName="comment"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                    
                     
                       
                       
    
                       
    
                        
                       
                      
                        
    
                         
                        
                        
                      
    
                    
                        
                     
                
                </form>
                <div class="col-sm-12 text-right action-buttons multi-btns">
                    <button *ngIf="!this.complete" (click)="completeTask()" mat-raised-button  class="btn btn-primary mr-2" color="primary">Complete</button>
                    <button *ngIf="this.complete" (click)="inCompleteTask()" mat-raised-button  class="btn btn-primary mr-2" color="primary"> Mark Incomplete</button>
                    <button (click)="cancel()" mat-raised-button class="btn-danger"><i class="flaticon-cancel"></i>Cancel
                    </button>
                    <button *ngIf="isEdit" (click)="openEdit()" mat-raised-button  class="btn btn-primary mr-2" color="primary">Edit</button>
                     <button *ngIf="!isEdit" (click)="saveEmployeeTask()" mat-raised-button class="btn-primary" color="primary"
                             [disabled]="!userForm.valid"><i class="flaticon-diskette"></i>Save
                    </button>
                </div>

              </mat-card-content>
              
            
            </div>
          </div>
        </app-card>
      </div>
    </div>
  </div>
  <br>  <br>  <br> 
  <br>   <br>  <br>