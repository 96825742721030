<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
      <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
          <div class="col-md-8">
          <div class="page-header-title">
              <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted">Leave Period</h4></span>
          </div>
          </div>
          <div class="col-md-4"></div>
      </div>
      </div>
  </div>
</div>
<div class="card" >
    <!-- <div class="page-body no-title">
        <div class="row">
            <div class="col-sm-12">
                <app-card [title]="'Leave Period for employee'">
                </app-card>
            </div>
        </div>
    </div> -->
  <div class="card-content table-responsive table-full-width">
      
    <form class="form-material full-width-inputs" action="javascript:" [formGroup]="leaveForm" >
        <div class="row form-group">
            <div class="col-md-6">
              <mat-form-field class="form-element">
                <mat-label>Start Month</mat-label>
                <mat-select disabled disableOptionCentering class="form-control"  formControlName="cmbStartmonth" >
                  <mat-option *ngFor="let m of month" [value]="m.value">{{m.label}}</mat-option>
                </mat-select>
                <mat-error  *ngIf="(f.cmbStartmonth.invalid && (f.cmbStartmonth.dirty || f.cmbStartmonth.touched)) && f.cmbStartmonth.errors.required " class="error-class-red">
                  Start Month is  Required.
                </mat-error>
              </mat-form-field> 
           
            </div>

            <div class="col-md-6">
                <ng-container>
                  <mat-form-field class="form-element">
                    <mat-label>Start Date</mat-label>
                    <input matInput type="text" class="form-control"  formControlName="cmbStartdate" readonly>
                  </mat-form-field> 

                  
                </ng-container>
             
            </div>
            <div class="col-md-6">
              <mat-form-field class="form-element">
                <mat-label>End Date</mat-label>
                <input type="text" matInput class="form-control" readonly formControlName="currentenddate">
              </mat-form-field> 

                
            </div>
            <div class="col-md-6">
              <mat-form-field class="form-element">
                <mat-label>Current Leave Period</mat-label>
                <input type="text" matInput class="form-control" readonly formControlName="currentleaveperiod">
              </mat-form-field> 
              
            </div>
        </div>
       
     </form>
  </div>
</div>
