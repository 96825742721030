<div id="header-page" class="ng-star-inserted header-page">
    <div class="page-header ng-star-inserted">
        <div class="page-block ng-star-inserted">
        <div class="row align-items-center">
            <div class="col-md-8">
            <div class="page-header-title">
                <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted">Document Categories</h4></span>
            </div>
            </div>
            <div class="col-md-4"></div>
        </div>
        </div>
    </div>
</div>

<div class="page-body no-title">
    <div class="row">
        <div class="col-sm-12">  
         <app-add-category #addCategoryComponent (reloadData)="reloadData($event)"  (exportCsv)="exportToCsv()" (exportPdf)="saveAsPdf()" (onChange)="onModelChange($event)"  *ngIf="dataSource"></app-add-category>
            <app-card [title]="'Document categories'">
                <div class="dt-responsive" *ngIf="dataSource">
                    <div class="table-responsive table hrm-table-style-1 full-data-table table-cols-3">
                        <mat-card-content>
                            <mat-table mat-table [dataSource]="dataSource" matSort>
                                <ng-container matColumnDef="select">
                                    <mat-header-cell *matHeaderCellDef>
                                        <a [matMenuTriggerFor]="menu" class="check-all">
                                <span class="material-icons">
                                    more_vert
                                </span>
                                        </a>

                                        <mat-menu #menu="matMenu">
                                            <button (click)="onSelectAllClicked()" mat-menu-item>Select All</button>
                                            <button (click)="onDeSelectAllClicked()"
                                                    *ngIf="selectedCheckBoxList.length > 0" mat-menu-item>Deselect All
                                            </button>
                                            <button (click)="onDeleteSelected()" *ngIf="selectedCheckBoxList.length > 0"
                                                    mat-menu-item>Delete Selected
                                            </button>
                                        </mat-menu>
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        <mat-checkbox #matCheckbox (change)="onCheckBoxChange($event, row.id)"
                                                      value="{{row.id}}"></mat-checkbox>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="name">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        {{row.name}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="Edit">
                                    <mat-header-cell *matHeaderCellDef>Edit</mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        <span class="mytooltip tooltip-effect-4">
                                        <span class="tooltip-item"><i class="single-icon-click fa fa-pencil" (click)="editCategory(row)"></i></span>
                                        <span class="tooltip-content clearfix">
                                        <span class="tooltip-text">Edit</span>
                                        </span>
                                        </span>
                                    </mat-cell>
                                  </ng-container>
                                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumns;">
                                </mat-row>
                            </mat-table>
                            <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
                            <mat-paginator [pageSizeOptions]="[25, 50,75]" showFirstLastButtons></mat-paginator>
                        </mat-card-content>
                    </div>
                    <div class="error-msg" *ngIf="!isLoading"></div>
                </div>
            </app-card>
        </div>
    </div>
</div>
    <ng-template #deleteUser let-modal>
        <div mat-dialog-actions>
            <div class="mat-modal-header">
                <h4 class="mat-title-modal">Delete Company</h4>               
            </div>
          <div class="mat-modal-body">
                <div class="text-left are-u">Are you sure ?</div>
                <div class="row">
                    <div class="action-buttons multi-btns col-md-12 text-right">
                        <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i>Cancel</button>
                        <button mat-raised-button class="btn-primary" (click)="deleteJobTitle()" color="primary" ><i class="flaticon-trash"></i>Delete</button>
                    </div>

                    <!-- <div class="col-sm-12 text-right">
                        <button type="submit" class="btn btn-cancel btn-danger" (click)="close()">Cancel</button>
                        <button type="submit" class="btn btn-save btn-primary" (click)="deleteJobTitle()">Delete</button>
                    </div> -->
                </div>
            </div>
        </div>
    </ng-template>

