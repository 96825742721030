import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DashboardService } from '../../dashboard.service';
import { NotificationService } from "src/app/core/services/notification.service";
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-leave-c',
  templateUrl: './leave-c.component.html',
  styleUrls: ['./leave-c.component.scss']
})
export class LeaveCComponent implements OnInit {

  public calendarForm: FormGroup;
  public leaveType: Array<any> = [];
  public isLoading: boolean;
  public type = [
    { value: '0', label: 'Cancelled' },
    { value: '1', label: 'Pending Approval' },
    { value: '2', label: 'Scheduled' },
    { value: '3', label: 'Taken' },
    { value: '-1', label: 'Rejected' }
  ];
  constructor(
    private readonly authService: AuthService, private service: DashboardService, private fb: FormBuilder, private toastr: NotificationService) {
    this.createForm();
  }
  public permissions : any;
  public read = false;
  public write = false ;
  public update = false;
  public delete = false;
 
  checkPermission(module,tabs) : void 
  {
      this.permissions = JSON.parse(this.authService.getPermissions());

      this.read = false ;
      this.write = false ;
      this.delete = false ;
      this.update = false;
      if(this.permissions != undefined && this.permissions != null )
      {
          for(let p of this.permissions)
          {
             
              if(p.id === module)
              {
                 
                  if(p.modules != undefined)
                  {
                    
                      for(let tab of p.modules)
                      {
                          if(tab.id === tabs)
                          {
                            
                              for(let move of tab.permits)
                              {
                                  if(move.id === "u")
                                  {
                                      this.update = true;
                                  }
                                  else if(move.id === "r")
                                  {
                                      this.read = true;
                                  }
                                  else if(move.id === "c")
                                  {
                                    
                                      this.write = true;
                                  }
                                  else if(move.id === "d")
                                  {
                                      this.delete = true;
                                  }
                              }
                          }
                      }
                  }
                 
              }
             
          }
      }
     

  }
  ngOnInit() {
    this.checkPermission("leave","le_ccnf");
    this.service.getLeaveTypeList().subscribe(resp => {
      if (resp && resp['data']) {
        this.leaveType = resp['data'];
        this.leaveType.map(l => {
          l.label = l.leaveType;
          l.id = l.id;
        });
      }
     this.service.getCalendarConfig().subscribe(resp1 => {
       if (resp1 && resp1.data) {
         this.calendarForm.patchValue(resp1.data);
       }
     }, error => {
     });
    }, error => {
      const msg = error && error.error && error.error.msg;
      this.toastr.error(msg || 'Error in getting leave calendar config');
    });
  }

  public createForm(): void {
    this.calendarForm = this.fb.group({
      showEmployeesLeavesOfEmloyees: [''],
      showEmployeesLeavesOfEmloyeesExclude: [[]],
      showEmployeesLeaveOfSupervisor: [false],
      showEmployeesLeaveOfSupervisorExclude: [[]],
      showSupervisorLeaveOfSuubordinate: [false],
      showSupervisorLeaveOfSuubordinateExclude: [[]],
      showAdmin: [false],
      showAdminExclude: [[]],
      excludeLeavesHavingLeaveType: [[]],
    });
  }

  public submitCalendarConfig(): void {
    this.service.saveCalendarConfig(this.calendarForm.value).subscribe(resp => {
      if (resp) {
        this.toastr.success('Calendar config saved successfully');
      }
    }, error => {
      const err =  error  && error.error && error.error.msg;
      this.toastr.error(err || 'Error in saving calendar config');
    });
  }

  public getCalendarConfig(): void {
    this.service.getCalendarConfig().subscribe(resp => {
      if (resp && resp.data) {
        this.calendarForm.patchValue(resp.data);
      }
    }, error => {
      const err =  error  && error.error && error.error.msg;
      this.toastr.error(err || 'Error in getting calendar config');
    });
  }


  toTable(word: any) {

    var __FOUND = this.leaveType?.find(function(post, index) {
      if(post.id == word)
        return true;
    });

    return __FOUND?.label;
  }

  remove(id , name) {
    if (name === 'showEmployeesLeaveOfSupervisorExclude') {
      let res = this.calendarForm.value?.showEmployeesLeaveOfSupervisorExclude?.filter(item => item !== id);
      this.calendarForm.patchValue({showEmployeesLeaveOfSupervisorExclude: res});
    } else if (name === 'showEmployeesLeavesOfEmloyeesExclude') {
      let res = this.calendarForm.value?.showEmployeesLeavesOfEmloyeesExclude?.filter(item => item !== id);
      this.calendarForm.patchValue({showEmployeesLeavesOfEmloyeesExclude: res});
    }else if(name === 'showSupervisorLeaveOfSuubordinateExclude'){
      let res = this.calendarForm.value?.showSupervisorLeaveOfSuubordinateExclude?.filter(item => item !== id);
      this.calendarForm.patchValue({showSupervisorLeaveOfSuubordinateExclude: res});
    }else if(name === 'showAdminExclude'){
      let res = this.calendarForm.value?.showAdminExclude?.filter(item => item !== id);
      this.calendarForm.patchValue({showAdminExclude: res});
    }else if(name === 'excludeLeavesHavingLeaveType'){
      let res = this.calendarForm.value?.excludeLeavesHavingLeaveType?.filter(item => item !== id);
      this.calendarForm.patchValue({excludeLeavesHavingLeaveType: res});
    }
  }

}
