export default class TimeUtils {

/**
 * Formats the given minutes to HH:MM format
 * @param {*} totalMinutes 
 * @returns String of format HH:MM
 */
    static toHoursAndMinutes(totalMinutes) {
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);
  
    return `${TimeUtils.padTo2Digits(hours)}:${TimeUtils.padTo2Digits(minutes)}`;
  }
 
 static  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

}