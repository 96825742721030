import {AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { NotificationService } from "src/app/core/services/notification.service";
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import * as moment from 'moment';
import {FormControl} from '@angular/forms';
import {MatSelect} from '@angular/material/select';
import {ReplaySubject, Subject} from 'rxjs';
import {startWith, take, takeUntil} from 'rxjs/operators';
import {DashboardService} from '../../dashboard.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogHrmComponent} from 'src/app/dialog-hrm/dialog-hrm.component';
import {ActivatedRoute, Router} from '@angular/router';
import {TrainerDialogComponent} from 'src/app/trainer-dialog/trainer-dialog.component';
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
    selector: 'app-view-course',
    templateUrl: './view-course.component.html',
    styleUrls: ['./view-course.component.scss']
})
export class ViewCourseComponent implements OnInit {


    public course: string;
    public courseForm: FormGroup;
    public sessionForm: FormGroup;
    public resourceForm: FormGroup;
    public isLoading: boolean = true;
    public filteredOptions: Array<any>;
    public filteredOptions1: Array<any>;
    public filteredOptions2: Array<any>;
    public correct = true;
    public employeeList = [];
    public temporaryDepartment: Array<any>;
    public currencyList: Array<any> = [];
    public err: string;
    id: any;
    public filterQuery = '';
    public filterQuery1 = '';
    public filterQuery3 = '';
    public dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
    public dataSource2: MatTableDataSource<any> = new MatTableDataSource([]);
    public dataSource1: MatTableDataSource<any> = new MatTableDataSource([]);
    public dataSource3: MatTableDataSource<any> = new MatTableDataSource([]);

    public selectedNewsDocs = [];
    public selectedNewsDocs1 = [];
    public selectedNewsDocs3 = [];
    public trainerForm: FormGroup;
    public participantForm: FormGroup;
    public dialogRef: any;
    public isTrainerAdded = false;
    public sessionList = [];
    public selectedNewsDocs2 = [];
    public isSessionAdded: boolean;
    empCtrl1 = new FormControl();
    
    public participantId = "";
    @ViewChild('tablePaginator', {static: false}) tablePaginator: MatPaginator;
    @ViewChild('TableOnePaginator1', {static: false}) TableOnePaginator1: MatPaginator;
    @ViewChild('TableOnePaginator', {static: false}) tableOnePaginator: MatPaginator;
    @ViewChild('tableTwoePaginator', {static: false}) tableTwoePaginator: MatPaginator;
    @ViewChild('TableOneSort', {static: false}) tableOneSort: MatSort;
    @ViewChild('deleteCourse', {static: false}) deleteCourse;
    @ViewChild('deleteSession3', {static: false}) deleteSession3;
    @ViewChild('deleteCourse1', {static: false}) deleteCourse1;
    @ViewChild('addCourse', {static: false}) addCourse;
    @ViewChild('addCourse1', {static: false}) addCourse1;
    @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
    @ViewChildren(MatCheckbox) matCheckBoxes1: QueryList<MatCheckbox>;
    @ViewChildren(MatCheckbox) matCheckBoxes2: QueryList<MatCheckbox>;
    @ViewChildren(MatCheckbox) matCheckBoxes3: QueryList<MatCheckbox>;

    @ViewChild('addSession', {static: false}) addSession;
    @ViewChild('addSession1', {static: false}) addSession1;
    @ViewChild('deleteSession', {static: false}) deleteSession;
    @ViewChild('useCourseData', {static: false}) useCourseData: HTMLInputElement;

    public columns = ['select', 'id' , 'trainer'];

    public columns1 = ['select', 'id' , 'participants'];

    public columns2 = ['select', 'sessionName', 'startDate', 'status', 'part' , 'attachments'];
//description delete
    public columns3 = ['select', 'name', 'type', 'link',  'order' , 'attachments'];

    public selected:any;

    @ViewChild('tabGroup') tabGroup;
    ngAfterViewInit() {
        console.log('afterViewInit => ', this.tabGroup.selectedIndex);
        
        //course details
        if (this.tabGroup.selectedIndex == 0) {
            this.selected = 0;
        }
        //session
        else if (this.tabGroup.selectedIndex == 1){
            this.selected = 1;
        }
        //trainers
        else if (this.tabGroup.selectedIndex == 2){
            this.selected = 2;
        }
        //employee
        else if (this.tabGroup.selectedIndex == 3){
            this.selected = 3;
        }
        //resources
        else if (this.tabGroup.selectedIndex == 4){
            this.selected = 4;
        }

    }
    tabChanged(tabChangeEvent: MatTabChangeEvent): void {

        console.log('tabChangeEvent => ', tabChangeEvent);
        console.log('index => ', tabChangeEvent.index);
         //course details
         if (tabChangeEvent.index == 0) {
            this.selected = 0;
        }
        //session
        else if (tabChangeEvent.index == 1){
            this.selected = 1;
        }
        //trainers
        else if (tabChangeEvent.index == 2){
            this.selected = 2;
        }
        //employee
        else if (tabChangeEvent.index == 3){
            this.selected = 3;
        }
        //resources
        else if (tabChangeEvent.index == 4){
            this.selected = 4;
        }
    }

    constructor(private service: DashboardService, private routeA: ActivatedRoute, private route: Router, public dialog: MatDialog, private modalService: NgbModal, private fb: FormBuilder, private toastr: NotificationService) {
        this.createForm();

        this.service.getEmployeeDetailList().subscribe(resp => {
            if (resp && resp.data) {
                resp.data.map(e => {
                    this.employeeList.push({label: e.id + ' ' + e.name, value: e.id});
                });
                this.empCtrl1.valueChanges.subscribe(change => {
                    startWith('');
                    if (change == null || change == undefined) {
          
                      this.filteredOptions4 = [];
                      this.correct1 = true;
                    } else {
          
                      this.filterEmployees4(change);
                    }
          
                  });
            }
        });

        this.routeA.params.subscribe(params => {
            this.id = params['id'];
            console.log("id", this.id);
        });
    }

    public locationList: Array<any>;
    public bulkAddParticipant = false;
    public numberOfEmployees : Array<any> = [];
    public locationId = '';
    public subUnitId = '';

    onChangeCheck($event: MatCheckboxChange){
        this.bulkAddParticipant = $event.checked;
        if($event.checked){
            this.service.getEmployeeNumber({locationId : '' , subUnitId  : ''}).subscribe(resp => {
                if (resp && resp.data) {
                    console.log("resp  : ",resp);
                    this.numberOfEmployees = resp.data;
                }
            });
        }else {
            this.numberOfEmployees = [];
        }
    }

    changeLocation(op){
        console.log(op.value);
        this.locationId = op.value;
        this.numberOfEmployees = [];
        this.service.getEmployeeNumber({locationId : this.locationId , subUnitId  : this.subUnitId}).subscribe(resp => {
            if (resp && resp.data) {
                this.numberOfEmployees = resp.data;
            }
        });
    }

    changesubUnit(op){
        console.log(op.value);
        this.numberOfEmployees = [];
        this.subUnitId = op.value;
        this.service.getEmployeeNumber({locationId : this.locationId , subUnitId  : this.subUnitId}).subscribe(resp => {
            if (resp && resp.data) {
                this.numberOfEmployees = resp.data;
            }
        });
    }

    submitAddUser4() {
        this.isTrainerAdded = true;
        this.service.bulkAddParticipantToCourse(this.id, this.numberOfEmployees).subscribe(res => {
            if (res && res.data) {
                this.isTrainerAdded = false;
                this.err = '';
                this.close1();
                this.toastr.success('Participants added successfully');
                this.getCourseById(false , false);
            }
        }, error => {
            const _err = error.error && error.error.msg;
            this.isLoading = false;
            this.isTrainerAdded = false;
            this.err = 'Error while adding Participants ';
            this.toastr.error(_err || this.err);
        });
    }


    public createForm(): void {
        this.courseForm = this.fb.group({
            title: ['', Validators.required],
            coordinatorid: ['', Validators.required],
            subUnitId: [''],
            version: [''],
            subVersion: [''],
            currencyName: [''],
            cost: [''],
            duration: [''],
            companyName: [''],
            description: [''],
            status: ['']
        });

        this.trainerForm = this.fb.group({
            trainerId: ['', Validators.required],
        });

        this.participantForm = this.fb.group({
            participantId: [''],
        });

        this.sessionForm = this.fb.group({
            sessionName: ['', Validators.required],
            courseId: [this.id],
            startDate: ['', Validators.required],
            endDate: [''],
            deliveryLocation: [''],
            deliveryMethod: [''],
            status: [''],
            description: [''],
            useCourseData: [false]
        });

        this.resourceForm = this.fb.group({
            name: ['', Validators.required],
            type: ['', Validators.required],
            link: [''],
            description: [''],
            order: [null],
        });


    }

    getCourseById(withS: boolean, withR: boolean): void {
        this.isLoading = true;
        if (this.id) {
            this.service.getCourseById(this.id).subscribe(res => {
                if (res && res.data) {
                    this.course = res.data?.title;
                    this.courseForm.patchValue(res.data);
                    this.courseForm.patchValue({status: res?.data?.archived ? 'archived' : 'active'});
                    this.courseForm.patchValue({version: `${res?.data?.version}`});
                    this.courseForm.patchValue({subVersion: `${res?.data?.subVersion}`});
                    this.dataSource2.data = res.data?.trainers;
                    this.dataSource2.sort = this.tableOneSort;
                    setTimeout(() => this.dataSource2.paginator = this.tableOnePaginator);
                    this.dataSource1 = new MatTableDataSource<any>(res.data?.participants);
                    setTimeout(() => this.dataSource1.paginator = this.tableTwoePaginator);
                    if (withS) {
                        this.service.getSessionListByCourseId(this.id).subscribe(res => {
                            if (res && res.data) {
                                this.dataSource = new MatTableDataSource<any>(res.data);
                                setTimeout(() => this.dataSource.paginator = this.TableOnePaginator1);
                            }
                        });
                    }
                    if (withR) {
                        this.service.getCourseResource(this.id).subscribe(res => {
                            if (res && res.data) {
                                this.dataSource3 = new MatTableDataSource<any>(res.data);
                                setTimeout(() => this.dataSource3.paginator = this.tablePaginator);
                            }
                        });
                    }

                    this.isLoading = false;
                }
            }, error => {
                const _err = error.error && error.error.msg;
                this.isLoading = false;

                this.err = 'Error while getting Course details';
                this.toastr.error(_err || this.err);
            });
        } else {
            this.toastr.error('Error while getting Course details');
        }

    }

    ngOnInit() {
        this.selected=0;
        this.getCourseById(true, true);

        this.service.getLocationList().subscribe(user => {
            if (user && user.data ) {
                this.locationList = user.data;
            }
        });

        this.service.getStructureList().subscribe(resp => {
            if (resp && resp.data) {
                this.temporaryDepartment = resp.data;
            }
        });

        this.service.getPayGradeCurrencyList().subscribe(user => {
            if (user && user.data && user.data.currency) {
                user.data.currency.map(value => {
                    this.currencyList.push(value);
                });
                console.log("data c:  ", this.currencyList);
            }
        });

        this.initValuesDetection();
    }

    public displayEmployeeByName(option) {

        if (!option) {
            return '';
        }
        this.correct = true;
        let data = this.employeeList.find(x => x.value === option);
        return data?.label;
    }

    initValuesDetection() {
        this.filteredOptions = this.employeeList;
        this.filteredOptions1 = this.employeeList;
        this.filteredOptions2 = this.employeeList;

        this.courseForm.controls['coordinatorid'].valueChanges.subscribe(change => {

            if (change == null || change == undefined) {
                this.correct = true;

            } else {

                this.filterEmployees(change);
            }

        });

        this.trainerForm.controls['trainerId'].valueChanges.subscribe(change => {

            if (change == null || change == undefined) {
                this.correct = true;

            } else {

                this.filterEmployees1(change);
            }

        });

        this.participantForm.controls['participantId'].valueChanges.subscribe(change => {

            if (change == null || change == undefined) {
                this.correct = true;

            } else {

                this.filterEmployees2(change);
            }

        });
    }

    protected filterEmployees(change: String) {
        this.filteredOptions = this.employeeList;
        if (!this.employeeList) {
            return;
        }

        // get the search keyword
        if (!change) {

            this.filteredOptions = this.employeeList;
            return;
        } else {
            this.checkEmployeeChange(change);
            change = change.toLowerCase();
        }
        // filter the search
        this.filteredOptions =
            this.employeeList.filter(emp => emp.label.toLowerCase().indexOf(change) > -1);

    }

    protected filterEmployees1(change: String) {
        this.filteredOptions1 = this.employeeList;
        if (!this.employeeList) {
            return;
        }

        // get the search keyword
        if (!change) {

            this.filteredOptions1 = this.employeeList;
            return;
        } else {
            this.checkEmployeeChange1(change);
            change = change.toLowerCase();
        }
        // filter the search
        this.filteredOptions1 =
            this.employeeList.filter(emp => emp.label.toLowerCase().indexOf(change) > -1);

    }

    protected filterEmployees2(change: String) {
        this.filteredOptions2 = this.employeeList;
        if (!this.employeeList) {
            return;
        }

        // get the search keyword
        if (!change) {

            this.filteredOptions2 = this.employeeList;
            return;
        } else {
            this.checkEmployeeChange1(change);
            change = change.toLowerCase();
        }
        // filter the search
        this.filteredOptions2 =
            this.employeeList.filter(emp => emp.label.toLowerCase().indexOf(change) > -1);

    }

    checkEmployeeChange(change) {
        let x = this.filteredOptions.find(x => x.value === change);
        if (!x) {
            this.correct = false;
        }

    }

    checkEmployeeChange1(change) {
        let x = this.filteredOptions1.find(x => x.value === change);
        if (!x) {
            this.correct = false;
        }

    }

    checkEmployeeChange2(change) {
        let x = this.filteredOptions2.find(x => x.value === change);
        if (!x) {
            this.correct = false;
        }

    }

    close() {
        this.route.navigate(['/training/courses']);
    }

    submitAddCourse() {

        const body = {
            id: this.id,
            ...this.courseForm.value,
            archived: this.courseForm.value?.status === 'archived'
        };
        delete body.status;

        this.isLoading = true;
        this.service.updateCourse(body).subscribe(res => {
            if (res && res.data) {
                this.isLoading = false;
                this.err = '';
                this.courseForm.reset();
                this.toastr.success('Course updated successfully');
                this.getCourseById(false, false);
            }
        }, error => {
            const _err = error.error && error.error.msg;
            this.isLoading = false;

            this.err = 'Error while updating Course details';
            this.toastr.error(_err || this.err);
        });
    }

    onModelChange(value: string, isOk: boolean) {
        if (!isOk) {
            this.dataSource2.filter = value.trim().toLocaleLowerCase();
        } else {
            this.dataSource1.filter = value.trim().toLocaleLowerCase();
        }
    }

    onModelChange1(value: string) {
        this.dataSource.filter = value.trim().toLocaleLowerCase();
    }

    onModelChange2(value: string) {
        this.dataSource3.filter = value.trim().toLocaleLowerCase();
    }

    addNewCourse(isOk: boolean) {
        if (!isOk) {
            this.trainerForm.reset();
            this.dialogRef = this.dialog.open(this.addCourse, {
                width: '620px',
                height: 'auto',
                autoFocus: false
            });
        } else {
            this.participantForm.reset();
            this.dialogRef = this.dialog.open(this.addCourse1, {
                width: '620px',
                height: 'auto',
                autoFocus: false
            });
        }
    }

    onSelectAllClicked() {
        this.selectedNewsDocs = [];
        this.matCheckBoxes.forEach(
            item => {
                this.selectedNewsDocs.push(item.value);
                item.checked = true;
            }
        );
    }

    onDeSelectAllClicked() {
        this.selectedNewsDocs = [];
        this.matCheckBoxes.forEach(
            item => {
                item.checked = false;
            }
        );
    }

    onDeleteSelected(isOk) {
        if (!isOk) {
            this.dialogRef = this.dialog.open(this.deleteCourse, {
                width: '400px',
                height: 'auto'
            });
        } else {
            this.dialogRef = this.dialog.open(this.deleteCourse1, {
                width: '400px',
                height: 'auto'
            });
        }
    }

    onDeleteSelected1() {
        this.dialogRef = this.dialog.open(this.deleteSession3, {
            width: '400px',
            height: 'auto'
        });
    }

    onNewDocSelectChange($event: MatCheckboxChange, id: any) {
        if (!this.alreadySelected($event.source) && $event.checked) {
            this.selectedNewsDocs.push($event.source.value);
        } else if (!$event.checked) {
            this.selectedNewsDocs = this.selectedNewsDocs.filter(
                value => {
                    return value !== $event.source.value;
                }
            );
        }
    }

    alreadySelected(item: MatCheckbox): boolean {
        this.selectedNewsDocs.forEach(
            value => {
                if (item === value) {
                    return true;
                }
            }
        );
        return false;
    }

    onSelectAllClicked1() {
        this.selectedNewsDocs1 = [];
        this.matCheckBoxes1.forEach(
            item => {
                this.selectedNewsDocs1.push(item.value);
                item.checked = true;
            }
        );
    }

    onSelectAllClicked3() {
        this.selectedNewsDocs3 = [];
        this.matCheckBoxes3.forEach(
            item => {
                this.selectedNewsDocs3.push(item.value);
                item.checked = true;
            }
        );
    }

    onDeSelectAllClicked1() {
        this.selectedNewsDocs1 = [];
        this.matCheckBoxes1.forEach(
            item => {
                item.checked = false;
            }
        );
    }

    onDeSelectAllClicked3() {
        this.selectedNewsDocs3 = [];
        this.matCheckBoxes3.forEach(
            item => {
                item.checked = false;
            }
        );
    }

    onNewDocSelectChange1($event: MatCheckboxChange, id: any) {
        if (!this.alreadySelected1($event.source) && $event.checked) {
            this.selectedNewsDocs1.push($event.source.value);
        } else if (!$event.checked) {
            this.selectedNewsDocs1 = this.selectedNewsDocs1.filter(
                value => {
                    return value !== $event.source.value;
                }
            );
        }
    }

    onNewDocSelectChange3($event: MatCheckboxChange, id: any) {
        if (!this.alreadySelected3($event.source) && $event.checked) {
            this.selectedNewsDocs3.push($event.source.value);
        } else if (!$event.checked) {
            this.selectedNewsDocs3 = this.selectedNewsDocs3.filter(
                value => {
                    return value !== $event.source.value;
                }
            );
        }
    }

    alreadySelected1(item: MatCheckbox): boolean {
        this.selectedNewsDocs1.forEach(
            value => {
                if (item === value) {
                    return true;
                }
            }
        );
        return false;
    }

    alreadySelected3(item: MatCheckbox): boolean {
        this.selectedNewsDocs3.forEach(
            value => {
                if (item === value) {
                    return true;
                }
            }
        );
        return false;
    }

    public close1(): void {
        this.dialogRef.close();
        this.trainerForm.reset();
        this.participantForm.reset();
    }

    submitAddUser(isOk: boolean) {
        if (!isOk) {
            this.isTrainerAdded = true;
            this.service.addTrainerToCourse(this.id, this.trainerForm.value.trainerId).subscribe(res => {
                if (res && res.data) {
                    this.isTrainerAdded = false;
                    this.err = '';
                    this.close1();
                    this.trainerForm.reset();
                    this.toastr.success('Trainer added successfully');
                    this.getCourseById(false, false);
                }
            }, error => {
                const _err = error.error && error.error.msg;
                this.isLoading = false;
                this.err = 'Error while adding Trainer ';
                this.toastr.error(_err || this.err);
            });

        } else {
            this.isTrainerAdded = true;

            this.service.addParticipantToCourse(this.id, this.participantId).subscribe(res => {
                if (res && res.data) {
                    this.isTrainerAdded = false;
                    this.err = '';
                    this.close1();
                    this.participantForm.reset();
                    this.toastr.success('Participant added successfully');
                    this.getCourseById(false, false);
                }
            }, error => {
                const _err = error.error && error.error.msg;
                this.isLoading = false;

                this.err = 'Error while adding Participant ';
                this.toastr.error(_err || this.err);
            });
        }
    }


    public deleteMultiple(isOk: boolean): void {
        if (!isOk) {
            this.service.deleteMultipleeTrainersFromCourse(this.id, this.selectedNewsDocs).subscribe(user => {
                if (user) {
                    this.close1();
                    this.toastr.success('deleted !');
                    this.getCourseById(false, false);
                }
            }, error => {
                const err = error.error && error.error.msg;
                this.toastr.error(err || 'Error deleting ');
            });
            this.selectedNewsDocs = [];
        } else {
            this.service.deleteMultipleeParticipantsFromCourse(this.id, this.selectedNewsDocs1).subscribe(user => {
                if (user) {
                    this.close1();
                    this.toastr.success('deleted !');
                    this.getCourseById(false, false);
                }
            }, error => {
                const err = error.error && error.error.msg;
                this.toastr.error(err || 'Error deleting ');
            });
            this.selectedNewsDocs1 = [];
        }
    }

    viewAction1(id) {
        this.route.navigate(['/training/sessions/viewTrainer', id]);
    }

    viewAction(id) {
        this.route.navigate(['/training/sessions', id]);
    }

    onSelectAllClicked2() {
        this.selectedNewsDocs2 = [];
        this.matCheckBoxes2.forEach(
            item => {
                this.selectedNewsDocs2.push(item.value);
                item.checked = true;
            }
        );
    }

    onDeSelectAllClicked2() {
        this.selectedNewsDocs2 = [];
        this.matCheckBoxes2.forEach(
            item => {
                item.checked = false;
            }
        );
    }

    onNewDocSelectChange2($event: MatCheckboxChange, id: any) {
        if (!this.alreadySelected2($event.source) && $event.checked) {
            this.selectedNewsDocs2.push($event.source.value);
        } else if (!$event.checked) {
            this.selectedNewsDocs2 = this.selectedNewsDocs2.filter(
                value => {
                    return value !== $event.source.value;
                }
            );
        }
    }

    alreadySelected2(item: MatCheckbox): boolean {
        this.selectedNewsDocs2.forEach(
            value => {
                if (item === value) {
                    return true;
                }
            }
        );
        return false;
    }

    onDeleteSelected2() {
        this.dialogRef = this.dialog.open(this.deleteSession, {
            width: '400px',
            height: 'auto'
        });
    }

    addNewSession() {
        this.sessionForm.reset();
        this.dialogRef = this.dialog.open(this.addSession, {
            width: '720px',
            height: 'auto'
        });
    }

    addNewSession1() {
        this.isEdit = false;
        this.resourceForm.reset();
        this.dialogRef = this.dialog.open(this.addSession1, {
            width: '720px',
            height: 'auto'
        });
    }

    isEdit = false;
    resourceId = null;

    viewAction2(data) {
        this.isEdit = true;
        this.resourceForm.patchValue(data);
        this.resourceId = data?.id;
        this.dialogRef = this.dialog.open(this.addSession1, {
            width: '720px',
            height: 'auto'
        });
    }


    submitAddUser2() {
        console.log("data : ", this.sessionForm.value);
        this.isSessionAdded = true;
        this.sessionForm.patchValue({'courseId': this.id});
        this.sessionForm.patchValue({useCourseData: this.useCourseData.checked});
        this.service.addSession(this.sessionForm.value).subscribe(res => {
            if (res && res.data) {
                this.isSessionAdded = false;
                this.err = '';
                this.close1();
                this.sessionForm.reset();
                this.toastr.success('Session added successfully');
                this.getCourseById(true, false);
            }
        }, error => {
            const _err = error.error && error.error.msg;
            this.isLoading = false;
            this.isSessionAdded = false;
            this.close1();
            this.err = 'Error while adding Session List details';
            this.toastr.error(_err || this.err);
        });

    }

    public deleteMultiple2(): void {
        this.service.deleteMultipleSession(this.selectedNewsDocs2).subscribe(user => {
            if (user) {
                this.close1();
                this.getCourseById(true, false);
                this.toastr.success('Session deleted !');
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error deleting Session');
        });
        this.selectedNewsDocs = [];
    }


    public deleteMultiple3(): void {
        this.service.deleteMultipleResource(this.id, this.selectedNewsDocs3).subscribe(user => {
            if (user) {
                this.close1();
                this.getCourseById(false, true);
                this.toastr.success('Resource deleted !');
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error deleting Resource');
        });
        this.selectedNewsDocs3 = [];
    }


    submitAddUser3() {
        console.log("data : ", this.resourceForm.value);
        this.isSessionAdded = true;
        if (!this.isEdit) {
            this.service.addCourseResource(this.id, this.resourceForm.value).subscribe(res => {
                if (res && res.data) {
                    this.isSessionAdded = false;
                    this.err = '';
                    this.close1();
                    this.resourceForm.reset();
                    this.toastr.success('Resource added successfully');
                    this.getCourseById(false, true);
                }
            }, error => {
                const _err = error.error && error.error.msg;
                this.isLoading = false;
                this.isSessionAdded = false;
                this.close1();
                this.err = 'Error while adding Resource List details';
                this.toastr.error(_err || this.err);
            });
        } else {
            const body = {
                ...this.resourceForm.value,
                id: this.resourceId
            };
            this.service.editCourseResource(this.id, body).subscribe(res => {
                if (res && res.data) {
                    this.isSessionAdded = false;
                    this.err = '';
                    this.close1();
                    this.resourceForm.reset();
                    this.toastr.success('Resource Updated successfully');
                    this.getCourseById(false, true);
                }
            }, error => {
                const _err = error.error && error.error.msg;
                this.isLoading = false;
                this.isSessionAdded = false;
                this.close1();
                this.err = 'Error while editing Resource List details';
                this.toastr.error(_err || this.err);
            });
        }
    }

    @ViewChild('attach', {static: false}) attach;
    @ViewChild('attach1', {static: false}) attach1;

    public attacherId: string;
    public attachmentList = [];
    public fileType: string;
    public fileObj: any;
    public comment: string;
    showSpinner = false;
    public uploadedFile: any;
    public fileError : any;

    onAddAttachment(id , withA) {
        this.attacherId = id;
        this.showSpinner = true;

        this.service.getAttachmentList(this.attacherId).subscribe(
            r => {
                if (r && r.data) {
                    this.attachmentList = r.data;
                    if(withA){
                        this.dialogRef = this.dialog.open(this.attach1, {
                            width: '560px',
                            height: 'auto'
                        });
                    }else {
                        this.dialogRef = this.dialog.open(this.attach, {
                            width: '560px',
                            height: 'auto'
                        });
                    }
                }
                this.showSpinner = false;
            },
            error => {
                this.showSpinner = false;
            }
        );
        this.comment = '';
        this.fileType = '';
        this.fileObj = null;

    }

    onDownloadClicked(attachment: any) {
        const URL = attachment['url'];
        const FILE_NAME = attachment['fileName'];
        window.open(URL);
    }

    fileChange($event, type): void {
        this.uploadedFile = undefined;
        const reader = new FileReader();
        const file = $event.target.files[0];
        this.uploadedFile = file;
        const fileName = file && file.name;
        if (file && (file.size / 1024) < 2048) {
            this.fileObj = {
                added: new Date(),
                addedBy: 'string',
                desc: this.comment,
                docType: 'dd',
                empId: this.attacherId,
                fileName: fileName,
                size: file.size,
                url: ''
            };
        } else {
            this.fileError = 'Max size 2 MB';
            this.toastr.error('Max 2Mb File allowed.');
        }
    }

    onDeleteClicked(attachment: any) {
        const ID = attachment['id'];
        this.service.deleteAttachment(ID).subscribe(
            res => {
                this.service.getAttachmentList(this.attacherId).subscribe(
                    r => {
                        this.attachmentList = r.data;
                        this.toastr.success('Deleted');
                        this.service.getCourseResource(this.id).subscribe(res => {
                            if (res && res.data) {
                                this.dataSource3 = new MatTableDataSource<any>(res.data);
                                setTimeout(() => this.dataSource3.paginator = this.tablePaginator);
                            }
                        });

                    },
                    error => {
                        console.log(error);
                        this.toastr.error('Failed to delete');
                    }
                );
            }
        );

    }

    public isClicked = false;

    saveAttachment() {
        this.isClicked = true;
        if (this.fileObj !== null) {
            this.service.uploadFile(this.uploadedFile).subscribe(resp => {
                if (resp && resp.data) {
                    this.fileObj['url'] = resp.data.filename;
                    this.fileObj['desc'] = this.comment;
                    this.service.addAttachment(this.fileObj).subscribe(
                        res => {
                            this.service.getAttachmentList(this.attacherId).subscribe(
                                r => {
                                    this.attachmentList = r.data;
                                    this.toastr.success('Attachment Added');
                                    this.dialogRef.close();
                                    this.getCourseById(true , true);
                                },
                                error => {
                                    this.toastr.error('Failed to add Attachment');
                                    this.dialogRef.close();
                                }
                            );
                            this.isClicked = false;
                        },
                        error => {
                            this.toastr.error('Failed to add Attachment');
                            this.isClicked = false;
                            this.dialogRef.close();
                        }
                    );
                }
            }, error => {
                this.isClicked = false;
                const err = error.error && error.error.msg;
                console.log(err);
                this.toastr.error('Failed to save Attachment', 'Upload Unsuccessful');
                this.dialogRef.close();
            });
        } else {
            this.isClicked = false;
            this.toastr.error('No file Selected');
        }
    }

    filteredOptions4 = [];
    correct1 = true;
  
    protected filterEmployees4(change: String) {
      this.filteredOptions4 = this.employeeList;
      if (!this.employeeList) {
        return;
      }
  
      // get the search keyword
      if (!change) {
  
        this.correct1 = true;
        this.filteredOptions4 = [];
        return;
      } else {
        this.checkEmployeeChange4(change);
        change = change.toLowerCase();
      }
      // filter the search
      this.filteredOptions4 =
        this.employeeList.filter(emp => emp.label.toLowerCase().indexOf(change) > -1);
  
    }
    checkEmployeeChange4(change) {
      let x = this.filteredOptions4.find(x => x.value === change);
      if (!x) {
        this.correct1 = false;
      }
  
    }

}
