import {AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { NotificationService } from "src/app/core/services/notification.service";
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import * as moment from 'moment';
import {FormControl} from '@angular/forms';
import {MatSelect} from '@angular/material/select';
import {ReplaySubject, Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {DashboardService} from '../../../dashboard.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogHrmComponent} from 'src/app/dialog-hrm/dialog-hrm.component';
import {ActivatedRoute, Router} from '@angular/router';
import {TrainerDialogComponent} from 'src/app/trainer-dialog/trainer-dialog.component';
import {MatCheckbox, MatCheckboxChange} from "@angular/material/checkbox";

@Component({
    selector: 'app-view-trainers',
    templateUrl: './trainers.component.html',
    styleUrls: ['./trainers.component.scss']
})
export class TrainersComponent implements OnInit {

    public name : string;
    public courseList = [];
    public courseForm: FormGroup;
    public trainerForm: FormGroup;
    public participantForm: FormGroup;
    public isLoading: boolean = true;
    public filteredOptions: Array<any>;
    public filteredOptions1: Array<any>;
    public correct = true;
    public employeeList = [];
    public temporaryDepartment: Array<any>;
    public currencyList: Array<any> = [];
    public err: string;
    id: any;
    public dialogRef: any;
    public filterQuery = '';
    public dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
    public dataSource1: MatTableDataSource<any> = new MatTableDataSource([]);
    public selectedNewsDocs = [];
    public selectedNewsDocs1 = [];
    public isTrainerAdded = false;
    public bulkAddParticipant = false;

    @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
    @ViewChildren(MatCheckbox) matCheckBoxes1: QueryList<MatCheckbox>;
    @ViewChild('deleteCourse', {static: false}) deleteCourse;
    @ViewChild('deleteCourse1', {static: false}) deleteCourse1;
    @ViewChild('addCourse', {static: false}) addCourse;
    @ViewChild('addCourse1', {static: false}) addCourse1;

    @ViewChild(MatSort, {static: false}) sort : MatSort;
    @ViewChild('TableOnePaginator', {static: false}) tableOnePaginator: MatPaginator;
    @ViewChild('tableTwoePaginator', {static: false}) tableTwoePaginator: MatPaginator;
    @ViewChild('TableOneSort', {static: false}) tableOneSort: MatSort;

    public columns = ['select', 'id' ,  'trainer'];

    public columns1 = ['select', 'participants'];

    constructor(private service: DashboardService, private routeA: ActivatedRoute, private route: Router, public dialog: MatDialog, private modalService: NgbModal, private fb: FormBuilder, private toastr: NotificationService) {

        this.service.getcourseList().subscribe(resp => {
            if (resp && resp.data) {
                console.log("res : ", resp.data);
                this.courseList = resp.data;
            }
        });

        this.createForm();
        this.routeA.params.subscribe(params => {
            this.id = params['id'];
            console.log("id", this.id);
        });
        this.dataSource = new MatTableDataSource;
    }

    public createForm(): void {
        this.courseForm = this.fb.group({
            sessionName: ['', Validators.required],
            courseId: ['', Validators.required],
            startDate: [new Date(), Validators.required],
            endDate: [new Date()],
            deliveryLocation: [''],
            deliveryMethod: [''],
            status: [''],
            description: ['']
        });

        this.trainerForm = this.fb.group({
            trainerId: ['', Validators.required],
        });

        this.participantForm = this.fb.group({
            participantId: ['', Validators.required],
        });
    }

    getCourseById(): void {
        this.isLoading = true;
        if (this.id) {
            this.service.getSessionById(this.id).subscribe(res => {
                if (res && res.data) {
                    this.courseForm.patchValue(res.data);
                    this.name = res?.data?.sessionName;
                    console.log('form : ', this.courseForm.value);
                    this.isLoading = false;
                    this.dataSource.data = res.data?.trainers;
                    this.dataSource.sort = this.tableOneSort;
                    setTimeout(() => this.dataSource.paginator = this.tableOnePaginator);


                    this.dataSource1 = new MatTableDataSource<any>(res.data?.participants);
                    setTimeout(() => this.dataSource1.paginator = this.tableTwoePaginator);
                    // console.log(this.dataSource1.filteredData);
                    // this.dataSource1.sort = this.tableOneSort;
                    // this.dataSource1.paginator = this.paginator;

                }
            }, error => {
                const _err = error.error && error.error.msg;
                this.isLoading = false;

                this.err = 'Error while getting Session details';
                this.toastr.error(_err || this.err);
            });
        } else {
            this.toastr.error('Error while getting Session details');
        }

    }

    public locationList: Array<any>;
    public numberOfEmployees : Array<any> = [];
    public locationId = '';
    public subUnitId = '';

    changeLocation(op){
        console.log(op.value);
        this.locationId = op.value;
        this.numberOfEmployees = [];
        this.service.getEmployeeNumber({locationId : this.locationId , subUnitId  : this.subUnitId}).subscribe(resp => {
            if (resp && resp.data) {
                this.numberOfEmployees = resp.data;
            }
        });
    }

    changesubUnit(op){
        console.log(op.value);
        this.numberOfEmployees = [];
        this.subUnitId = op.value;
        this.service.getEmployeeNumber({locationId : this.locationId , subUnitId  : this.subUnitId}).subscribe(resp => {
            if (resp && resp.data) {
                this.numberOfEmployees = resp.data;
            }
        });
    }

    ngOnInit() {
        this.getCourseById();



        this.service.getEmployeeDetailList().subscribe(resp => {
            if (resp && resp.data) {
                resp.data.map(e => {
                    this.employeeList.push({label: e.id + ' ' + e.name, value: e.id});
                });
            }
        });

        this.service.getStructureList().subscribe(resp => {
            if (resp && resp.data) {
                this.temporaryDepartment = resp.data;
            }
        });

        this.service.getLocationList().subscribe(user => {
            if (user && user.data ) {
                this.locationList = user.data;
            }
        });

        this.service.getPayGradeCurrencyList().subscribe(user => {
            if (user && user.data && user.data.currency) {
                user.data.currency.map(value => {
                    this.currencyList.push(value);
                });
            }
        });

        this.initValuesDetection();
    }

    public displayEmployeeByName(option) {
        if (!option) {
            return '';
        }
        this.correct = true;
        let data = this.employeeList.find(x => x.value === option);
        return data?.label;
    }

    initValuesDetection() {
        this.filteredOptions = this.employeeList;

        this.courseForm.controls['courseId'].valueChanges.subscribe(change => {

            if (change == null || change == undefined) {
                this.correct = true;

            } else {

                this.filterCourse(change);
            }

        });


        this.trainerForm.controls['trainerId'].valueChanges.subscribe(change => {

            if (change == null || change == undefined) {
                this.correct = true;

            } else {

                this.filterEmployees(change);
            }

        });

        this.participantForm.controls['participantId'].valueChanges.subscribe(change => {

            if (change == null || change == undefined) {
                this.correct = true;

            } else {

                this.filterEmployees(change);
            }

        });
    }

    protected filterCourse(change: String) {
        this.filteredOptions1 = this.courseList;
        if (!this.courseList) {
            return;
        }

        // get the search keyword
        if (!change) {

            this.filteredOptions1 = this.courseList;
            return;
        } else {
            this.checkCourseChange(change);
            change = change.toLowerCase();
        }
        // filter the search
        this.filteredOptions1 =
            this.courseList.filter(emp => emp.title.toLowerCase().indexOf(change) > -1);

    }

    protected filterEmployees(change: String) {
        this.filteredOptions = this.employeeList;
        if (!this.employeeList) {
            return;
        }

        // get the search keyword
        if (!change) {

            this.filteredOptions = this.employeeList;
            return;
        } else {
            this.checkEmployeeChange(change);
            change = change.toLowerCase();
        }
        // filter the search
        this.filteredOptions =
            this.employeeList.filter(emp => emp.label.toLowerCase().indexOf(change) > -1);

    }

    checkCourseChange(change) {
        let x = this.filteredOptions1.find(x => x.title === change);
        if (!x) {
            this.correct = false;
        }

    }

    checkEmployeeChange(change) {
        let x = this.filteredOptions.find(x => x.value === change);
        if (!x) {
            this.correct = false;
        }

    }

    public displayCourseByName(option) {
        if (!option) {
            return '';
        }
        this.correct = true;
        const date =  this.courseList.find(x => x.id === option);

        return  date?.title;
    }

    close() {
        this.route.navigate(['/training/sessions']);
    }

    submitAddCourse() {

        this.isLoading = true;
        const body = {
            id: this.id,
            ...this.courseForm.value
        };
        this.service.editSessionById(body).subscribe(res => {
            if (res && res.data) {
                this.isLoading = false;
                this.err = '';
                this.courseForm.reset();
                this.toastr.success('Session Updated successfully');
                this.getCourseById();
            }
        }, error => {
            const _err = error.error && error.error.msg;
            this.isLoading = false;

            this.err = 'Error while editing Session details';
            this.toastr.error(_err || this.err);
        });
    }

    onNewDocSelectChange($event: MatCheckboxChange, id: any) {
        if (!this.alreadySelected($event.source) && $event.checked) {
            this.selectedNewsDocs.push($event.source.value);
        } else if (!$event.checked) {
            this.selectedNewsDocs = this.selectedNewsDocs.filter(
                value => {
                    return value !== $event.source.value;
                }
            );
        }
    }

    onNewDocSelectChange1($event: MatCheckboxChange, id: any) {
        if (!this.alreadySelected1($event.source) && $event.checked) {
            this.selectedNewsDocs1.push($event.source.value);
        } else if (!$event.checked) {
            this.selectedNewsDocs1 = this.selectedNewsDocs1.filter(
                value => {
                    return value !== $event.source.value;
                }
            );
        }
    }

    onChangeCheck($event: MatCheckboxChange){
        this.bulkAddParticipant = $event.checked;
        if($event.checked){
            this.service.getEmployeeNumber({locationId : '' , subUnitId  : ''}).subscribe(resp => {
                if (resp && resp.data) {
                    this.numberOfEmployees = resp.data;
                }
            });
        }else {
            this.numberOfEmployees = [];
        }
    }

    alreadySelected(item: MatCheckbox): boolean {
        this.selectedNewsDocs.forEach(
            value => {
                if (item === value) {
                    return true;
                }
            }
        );
        return false;
    }

    alreadySelected1(item: MatCheckbox): boolean {
        this.selectedNewsDocs1.forEach(
            value => {
                if (item === value) {
                    return true;
                }
            }
        );
        return false;
    }

    onSelectAllClicked() {
        this.selectedNewsDocs = [];
        this.matCheckBoxes.forEach(
            item => {
                this.selectedNewsDocs.push(item.value);
                item.checked = true;
            }
        );
    }

    onSelectAllClicked1() {
        this.selectedNewsDocs1 = [];
        this.matCheckBoxes1.forEach(
            item => {
                this.selectedNewsDocs1.push(item.value);
                item.checked = true;
            }
        );
    }

    onDeSelectAllClicked() {
        this.selectedNewsDocs = [];
        this.matCheckBoxes.forEach(
            item => {
                item.checked = false;
            }
        );
    }

    onDeSelectAllClicked1() {
        this.selectedNewsDocs1 = [];
        this.matCheckBoxes1.forEach(
            item => {
                item.checked = false;
            }
        );
    }

    onModelChange(value: string, isOk: boolean) {
        if (!isOk) {
            this.dataSource.filter = value.trim().toLocaleLowerCase();
        } else {
            this.dataSource1.filter = value.trim().toLocaleLowerCase();
        }

    }

    onDeleteSelected( isOk) {
        if(!isOk){
            this.dialogRef = this.dialog.open(this.deleteCourse, {
                width: '400px',
                height: 'auto'
            });
        }else {
            this.dialogRef = this.dialog.open(this.deleteCourse1, {
                width: '400px',
                height: 'auto'
            });
        }
    }

    addNewCourse(isOk: boolean) {
        if (!isOk) {
            this.trainerForm.reset();
            this.dialogRef = this.dialog.open(this.addCourse, {
                width: '620px',
                height: 'auto'
            });
        } else {
            this.participantForm.reset();
            this.dialogRef = this.dialog.open(this.addCourse1, {
                width: '620px',
                height: 'auto'
            });
        }
    }

    public close1(): void {
        this.dialogRef.close();
        this.trainerForm.reset();
        this.participantForm.reset();
    }

    submitAddUser4() {
            this.isTrainerAdded = true;
            this.service.bulkAddParticipantToSession(this.id, this.numberOfEmployees).subscribe(res => {
                if (res && res.data) {
                    this.isTrainerAdded = false;
                    this.err = '';
                    this.close1();
                    this.toastr.success('Participants added successfully');
                    this.getCourseById();
                }
            }, error => {
                const _err = error.error && error.error.msg;
                this.isLoading = false;
                this.isTrainerAdded = false;
                this.err = 'Error while adding Participants ';
                this.toastr.error(_err || this.err);
            });
    }

    submitAddUser(isOk: boolean) {
        if (!isOk) {
            this.isTrainerAdded = true;
            this.service.addTrainerToSession(this.id, this.trainerForm.value.trainerId).subscribe(res => {
                if (res && res.data) {
                    this.isTrainerAdded = false;
                    this.err = '';
                    this.close1();
                    this.trainerForm.reset();
                    this.toastr.success('Trainer added successfully');
                    this.getCourseById();
                }
            }, error => {
                const _err = error.error && error.error.msg;
                this.isLoading = false;
                this.isTrainerAdded = false;
                this.err = 'Error while adding Trainer ';
                this.toastr.error(_err || this.err);
            });

        }else {
            this.isTrainerAdded = true;
            this.service.addParticipantToSession(this.id, this.participantForm.value.participantId).subscribe(res => {
                if (res && res.data) {
                    this.isTrainerAdded = false;
                    this.err = '';
                    this.close1();
                    this.participantForm.reset();
                    this.toastr.success('Participant added successfully');
                    this.getCourseById();
                }
            }, error => {
                const _err = error.error && error.error.msg;
                this.isLoading = false;
                this.isTrainerAdded = false;
                this.err = 'Error while adding Participant ';
                this.toastr.error(_err || this.err);
            });
        }
    }


    public deleteMultiple(isOk : boolean): void {
        if(!isOk){
            this.service.deleteMultipleeTrainersFromSession( this.id , this.selectedNewsDocs).subscribe(user => {
                if (user) {
                    this.close1();
                    this.toastr.success('deleted !');
                    this.getCourseById();
                }
            }, error => {
                const err = error.error && error.error.msg;
                this.toastr.error(err || 'Error deleting ');
            });
            this.selectedNewsDocs = [];
        }else {
            this.service.deleteMultipleeParticipantsFromSession( this.id, this.selectedNewsDocs1).subscribe(user => {
                if (user) {
                    this.close1();
                    this.toastr.success('deleted !');
                    this.getCourseById();
                }
            }, error => {
                const err = error.error && error.error.msg;
                this.toastr.error(err || 'Error deleting ');
            });
            this.selectedNewsDocs1 = [];
        }
    }
}
