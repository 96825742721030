import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dataFilterMultipleKeys'
})
export class DataFilterMultipleKeysPipe implements PipeTransform {

  transform(array: any[], query: string, search: string[]): any {
    if (query) {
      return array.filter(
          value => {
            for (const val in search) {
              if ((value[search[val]] as string).toLowerCase().indexOf(query.toLowerCase()) > -1) {
                return value;
              }
            }
          }
      );
    }
    return array;
  }

}
