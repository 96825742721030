import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { NotificationService } from "src/app/core/services/notification.service";
import { DashboardService } from "src/app/dashboard/dashboard.service";
import { AnnouncementService } from "src/app/service/announcement.service";


@Component({
    selector: 'app-ack-list-tab',
    templateUrl: './ack-list.component.html',
    styleUrls: ['./ack-list.component.scss']
})
export class AcksListComponent implements OnInit {
    columns = ['empName','state','ackDate']
    public ackForm: FormGroup;
    public name : string = "";
    public type : string = "";
    public isLoading : boolean = false ;
    public checkEmp : boolean = false ;
    public isLoad : boolean = false ;
    dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort: MatSort;
    constructor(
        private route: ActivatedRoute,
        private service: DashboardService,
        private announcementService: AnnouncementService,
        public dialog: MatDialog,
        private toastr: NotificationService,
        private fb: FormBuilder) { this.createAckFrom(); }
    ngOnInit(): void {
     
        this.route.params.subscribe(params => {
            if (params.id) {
                this.ackForm.patchValue({
                    id : params.id 
                });

                this.getNandD();
                this.getAcks() ;
      
        }
    });
}
    


    createAckFrom() : void 
    {
        this.ackForm = this.fb.group({
            id : '' ,
            includePastEmployees : false 
          });
    }

    generateAsk()
    {
        this.ackForm.patchValue({includePastEmployees : this.checkEmp});
        this.getAcks()
    }

    getNandD() : void 
    {
        this.isLoad = true ;
        this.announcementService.getNewsAndDocById(this.ackForm.value.id).subscribe(res => {
            if (res && res.data) {
               
             this.name = res.data.generalinfomation.topic;
             this.type = res.data.publishSetting.type ;
             this.isLoad = false  ;
             
            } else {
                this.toastr.error(res.msg);
            }
        }, error => {
            this.isLoad = false ;
            const err = error.error && error.error.msg;
            this.toastr.error(err);
        });
    }
    getAcks() : void 
    {
        this.isLoading = true ;
        
         this.announcementService.getAcknowledgmentsById(this.ackForm.value).subscribe(
            res => {
                if (res && res.data) {
                    this.dataSource = new MatTableDataSource([]);
                    this.dataSource.data = res.data;
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                    this.isLoading = false ;
                }
                
            },
            error => {
                const err = error.error && error.error.msg;
                this.isLoading = false ;
                this.toastr.error(err||'Failed in getting Acknowldgments ..!');
            }
        );

    }

}