import { Component, OnInit, ViewChild } from "@angular/core";
import { DashboardService } from "../../../dashboard.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NotificationService } from "src/app/core/services/notification.service";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import * as moment from "moment";
import { LanguageConfig } from "src/app/Language-config";
import { MatDialog } from "@angular/material/dialog";
import { CrudService } from "src/app/core/services/base-crud.service";
import { Configuration_AttendanceId } from "src/app/service/constants";

export class Group {
  expanded = false;
  totalCounts = 0;
  rows = [];
  date: Date;
  formattedDate = "";
  formatedTotalDuration = "";
  totalDuration = 0;
  lastIn: any;
  lastOut: any;
  dayOff = "";
}

@Component({
  selector: "app-my-record",
  templateUrl: "./my-record.component.html",
  styleUrls: ["./my-record.component.scss"],
  providers: [CrudService],
})
export class MyRecordComponent implements OnInit {
  displayedColumns = ["type", "time", "by", "location", "duration", "comments", "edit", "delete"];
  dataSource: MatTableDataSource<any>;
  public totalRecords: number;
  public activePage: number;
  public pageSize: number;
  public pageSizeOptions: number[] = [5, 10, 25, 100];
  public err: string;
  public modalRef: any;
  public userForm: FormGroup;

  allData: any[];
  allGroup: any[];

  hasResults = false;

  public update = true;

  public lang = new Map();
  public locations: Array<any>;
  public addForm: FormGroup;

  public formattedDate: any;
  addButtonlabel = "";
  dialogHeader = "";
  editMode: boolean;

  btnInProgress: Array<string> = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  @ViewChild("addRecord", { static: false }) addRecord;
  public dialogRef: any;
  hideLocation: boolean;
  config: any;

  constructor(
    private service: DashboardService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private toastr: NotificationService,
    public dialog: MatDialog,
    public genericConfigurationService: CrudService<any>
  ) {
    this.lang = LanguageConfig.map;
    this.genericConfigurationService.setEntityName("genericConfiguration");
  }

  ngOnInit() {
    this.userForm = this.fb.group({
      startDate: ["", Validators.required],
      endDate: ["", Validators.required],
    });

    this.addForm = this.fb.group({
      date: ["", Validators.required],
      time: ["", Validators.required],
      locationId: [""],
      comments: [""],
      id: [""],
      datestring: [""],
    });

    this.service.getLocationList().subscribe((resp) => {
      if (resp && resp.data) {
        this.locations = resp.data;
      }
    });

    if (this.genericConfigurationService.attendanceConfigCache != null) {
      this.config = this.genericConfigurationService.attendanceConfigCache;
    } else {
      this.genericConfigurationService.getById(Configuration_AttendanceId).subscribe((resp) => {
        if (resp && resp.data) {
          this.genericConfigurationService.attendanceConfigCache = resp.data;
          this.config = this.genericConfigurationService.attendanceConfigCache;
        }
      });
    }
  }

  public getAttendanceRecords(groups): void {
    let date = new Date(this.userForm.value.startDate);
    let endDate = new Date(this.userForm.value.endDate);
    // let groups = this.createGroupList(day);
    this.service
      .getAttendanceRecords("", moment(date).format("DD/MM/YYYY"), moment(endDate).format("DD/MM/YYYY"))
      .subscribe(
        (user) => {
          if (user && user.data) {
            user.data.forEach((row) => {
              let hours = Math.floor(row.duration / 60);
              let minutes = row.duration % 60;
              let formatedDuration = hours + ":" + (minutes > 9 ? minutes : "0" + minutes);
              row.formatedDuration = formatedDuration;
            });

            this.allData = user.data;
            this.fillGroupData(this.allData, groups);

            this.hasResults = groups.size > 0;
            this.allGroup = Array.from(groups.values());

            this.endProgress("search");
            // this.hasResults = user.data.length > 0;
          }
        },
        (error) => {
          const err = error.error && error.error.msg;
          this.toastr.error(err || "Error while getting Activity");
          this.endProgress("search");
          this.err = "Error while getting Activity";
        }
      );
  }

  groupHeaderClick(group) {
    this.dataSource = new MatTableDataSource(group.rows);
  }

  getMyAttendanceRecords(day) {
    if (!this.config.employeePermission) {
      this.displayedColumns.splice(-2);
    }
    this.startProgress("search");

    let groups = new Map<string, Group>();
    let date = new Date(this.userForm.value.startDate);
    let endDate = new Date(this.userForm.value.endDate);

    this.service.getDaysOff("", moment(date).format("DD/MM/YYYY"), moment(endDate).format("DD/MM/YYYY")).subscribe(
      (user) => {
        if (user && user.data) {
          while (date <= endDate) {
            let formattedDate = moment(date).format("dddd MMM Do");
            let date_ddMMyyyy = moment(date).format("DDMMYYYY");
            let group = new Group();
            let dayOff = user.data.find((d) => moment(d.dayDate).format("DDMMYYYY") === date_ddMMyyyy);
            if (dayOff) {
              group.dayOff = dayOff.type;
            }
            group.formattedDate = formattedDate;
            group.date = new Date(date);
            group.expanded = day === formattedDate;
            groups.set(formattedDate, group);

            date.setDate(date.getDate() + 1);
          }

          this.getAttendanceRecords(groups);
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error while getting days off");
        this.endProgress("search");
        this.err = "Error while getting Activity";
      }
    );

    // return groups;
  }

  fillGroupData(data: any[], groups) {
    data.map((row) => {
      let d = new Date(row.datetime);
      let timestamp = d.getTime() + d.getTimezoneOffset() * 60000;
      let date = moment(new Date(timestamp)).format("dddd MMM Do");
      let group = groups.get(date);
      group.rows.push(row);
      return group;
    });

    groups.forEach((group: Group) => {
      let totalDuration = 0;
      group.rows
        //.filter((r) => r.type === "Out")
        .map((row) => {
          if (row.type === "Out") {
            totalDuration = totalDuration + row.duration;
            group.lastOut = row;
          } else {
            group.lastIn = row;
            group.lastOut = "";
          }
        });

      let hours = Math.floor(totalDuration / 60);
      let minutes = totalDuration % 60;
      let formatedDuration = hours + "h " + (minutes > 9 ? minutes : "0" + minutes) + "m";

      group.formatedTotalDuration = formatedDuration;
      group.totalDuration = totalDuration;
      group.totalCounts = group.rows.length;
    });

    this.allGroup = Array.from(groups.values());
    return this.allGroup;
  }

  addNewRecord(group) {
    this.editMode = false;
    this.formattedDate = group.formattedDate;
    this.dialogHeader = "Add record - " + this.formattedDate;
    this.addButtonlabel = group.lastIn && !group.lastOut ? "Check Out" : "Check In";
    this.hideLocation = group.lastIn && !group.lastOut;
    this.addForm.patchValue({
      date: group.date,
      time: "",
      locationId: "",
      comments: "",
      id: "",
      datestring: moment(new Date(group.date)).format("DD-MM-YYYY"),
    });

    this.dialogRef = this.dialog.open(this.addRecord, {
      width: "550px",
      height: "auto",
    });
  }

  editRecord(group, row) {
    this.editMode = true;
    this.formattedDate = group.formattedDate;
    this.dialogHeader = "Edit record - " + this.formattedDate;
    this.addButtonlabel = "Update";
    let time = moment(new Date(row.datetime).toLocaleString("en-US", { timeZone: row.timezone })).format("HH:mm");

    this.addForm.patchValue({
      date: group.date,
      time: time,
      locationId: row.locationId,
      comments: row.comments,
      id: row.id,
      datestring: moment(new Date(group.date)).format("DD-MM-YYYY"),
    });

    this.dialogRef = this.dialog.open(this.addRecord, {
      width: "550px",
      height: "auto",
    });
  }

  public saveRecord() {
    if (this.editMode) {
      this.updateRecord();
    } else {
      this.saveNewRecord();
    }
  }

  deleteRecord(row) {
    this.startProgress("delete");
    this.service.deleteAttendanceRecord(row.id).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.toastr.success("Record saved");
          this.getMyAttendanceRecords(this.formattedDate);
        }
        this.endProgress("delete");
        this.close();
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error in saving Punch In");
        this.endProgress("delete");
      }
    );
  }

  saveNewRecord() {
    this.startProgress("update");
    this.service.recordAttendance(this.addForm.value).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.toastr.success("Record saved");
          this.getMyAttendanceRecords(this.formattedDate);
        }
        this.endProgress("update");
        this.close();
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error in saving Punch In");
        this.endProgress("update");
      }
    );
  }

  updateRecord() {
    this.startProgress("update");
    this.service.updateAttendance(this.addForm.value).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.toastr.success("Record saved");
          this.getMyAttendanceRecords(this.formattedDate);
        }
        this.endProgress("update");
        this.close();
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error in saving Punch In");
        this.endProgress("update");
      }
    );
  }

  public close(): void {
    this.dialogRef.close();
  }

  public displayTimeinOrginalTimezone(date, timezone) {
    if (timezone) {
      return new Date(date).toLocaleString("en-US", { timeZone: timezone });
    } else {
      return date;
    }
  }

  public isInProgress(btnId) {
    return this.btnInProgress.includes(btnId);
  }

  public startProgress(btnId) {
    this.btnInProgress.push(btnId);
  }

  public endProgress(btnId) {
    this.btnInProgress = this.btnInProgress.filter((i) => i !== btnId);
  }
}
