import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SelectModule} from 'ng-select';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MatChipsModule} from '@angular/material/chips';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import {DialogHrmComponent} from './dialog-hrm.component';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../module/material/material.module';
import { RouterModule, Routes } from '@angular/router';
import { HrmSharedModule } from '../hrmshared/hrmshared.module';
const routes: Routes = [
    {
        path     : '',
        component: DialogHrmComponent,
    }
];
@NgModule({
    declarations: [DialogHrmComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HrmSharedModule,
        SelectModule,
        MatIconModule,
        MatInputModule,
        SharedModule,
        MaterialModule,
        MatSelectModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatButtonModule,
        MatFormFieldModule,
        NgxMatSelectSearchModule,
        MatChipsModule,
        NgxMaterialTimepickerModule,
        RouterModule.forChild(routes),
        
    ]
})
export class DialogHrmModule {
}




    


