import {Component, OnInit, ViewChild, QueryList, ViewChildren} from '@angular/core';
import {DashboardService} from '../../../dashboard.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormGroup, FormBuilder} from '@angular/forms';
import { NotificationService } from "src/app/core/services/notification.service";
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {saveAs} from 'file-saver-es';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/auth.service';



@Component({
    selector: 'app-user-role',
    templateUrl: './user-role.component.html',
    styleUrls: ['./user-role.component.scss']
})
export class UserRoleComponent implements OnInit {

    public userList: Array<any>;
    public isLoading: boolean;
    public isUserAdded: boolean;
    public err: string;
    // public modalRef: any;
    public dialogRef: any;
    public userForm: FormGroup;
    public isEdit: boolean;
    public editId: string;
    public deleteId: string;
    public rolesList: Array<any>;
    public employeeAction: Array<any> = [];
    public workflowAction: Array<any>;
    public dataGroup: Array<any>;
    public role: string;
    public selectedRole: string;
    public apiPermissionList: any;
    public permissionList: any;
    public name: string;
    selectedUsers: any[] = [];
    public filterQuery = '';
    selectedCheckBoxList = [];
    @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
    @ViewChild('addUser', {static: false}) addUser;
    @ViewChild('deleteUser', {static: false}) deleteUser;
    @ViewChild(MatSort, {static: false}) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
    public columns = ['select', 'name', 'roleType', 'edit'];



    
    constructor(private service: DashboardService,
                // private modalService: NgbModal,
                
                private dialog: MatDialog,
                private fb: FormBuilder,
                private toastr: NotificationService,
                private authService : AuthService) {
        this.createForm();
      
    }
    public permissions : any;
    public read = false;
    public write = false ;
    public update = false;
    public delete = false;
   
    checkPermission(module,tabs) : void 
    {
        this.permissions = JSON.parse(this.authService.getPermissions());

        this.read = false ;
        this.write = false ;
        this.delete = false ;
        this.update = false;
        if(this.permissions != undefined && this.permissions != null )
        {
            for(let p of this.permissions)
            {
               
                if(p.id === module)
                {
                   
                    if(p.modules != undefined)
                    {
                      
                        for(let tab of p.modules)
                        {
                            if(tab.id === tabs)
                            {
                              
                                for(let move of tab.permits)
                                {
                                    if(move.id === "u")
                                    {
                                        this.update = true;
                                    }
                                    else if(move.id === "r")
                                    {
                                        this.read = true;
                                    }
                                    else if(move.id === "c")
                                    {
                                      
                                        this.write = true;
                                    }
                                    else if(move.id === "d")
                                    {
                                        this.delete = true;
                                    }
                                }
                            }
                        }
                    }
                   
                }
           
            }
        }
       

    }
    getPer(role)
    {
        this.service.getRolePermission(role).subscribe(resp => {
            if (resp && resp.data) {
                this.apiPermissionList = [];
                const data = resp.data;
                this.apiPermissionList = data;
               
                this.getRolePermission(role);
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error in getting permission');
        });
    }
    ngOnInit() {
        this.isLoading = true;
        this.isEdit = false;
        this.checkPermission("admin","ad_um");
        this.getUserRoleList();
        this.selectedRole = 'Admin';
        this.getPer(this.selectedRole);
        this.isUserAdded = false;
    }

    public getRolePermission(role?: string): void {
        this.permissionList = this.apiPermissionList;
        this.dataGroup = new Array();
        this.rolesList = ['ESS', 'Admin'];
        if (role) {
            // this.employeeAction = this.permissionList.employeeAction && this.permissionList.employeeAction.modules.filter(emp => {
            //     emp.checked = false;
            //     return emp.roles.indexOf(role) !== -1;
            // });
            this.workflowAction = this.permissionList.workflowManagement && this.permissionList.workflowManagement.modules.filter(emp => {
                emp.checked = false;
                return emp.roles.indexOf(role) !== -1;
            });

            this.permissionList.dataGroup.level.map(emp => {
                emp.checked = '';
                const obj = {
                    id: '',
                    name: '',
                    modules: []
                };
                obj.id = emp.id;
                obj.name = emp.name;
                obj.modules = [];
                emp.modules.map(modules => {
                
                        modules.permits.map(p => {
                            p.checked = '';
                            p.disabled = false ;
                        });
                        obj.modules.push({id: modules.id, name: modules.name, permits: modules.permits});
                    
                });
                if (obj.modules.length > 0) {
                    this.dataGroup.push(obj);
                }
            });
        } else {
            this.employeeAction = this.permissionList.employeeAction && this.permissionList.employeeAction.modules;
            this.workflowAction = this.permissionList.workflowManagement && this.permissionList.workflowManagement.modules;
            this.dataGroup = this.permissionList.dataGroup && this.permissionList.dataGroup.level;
        }
       
    }


    checkPermList()
    {
        for(let data of this.dataGroup)
        {
            for(let module of data.modules)
            {
                for (let p of module.permits)
                {
                
                
                    if(p.id != 'r')
                    {
                      if(p.checked)
                      {
                        for (let per of module.permits)
                        {
                            if(per.id === 'r')
                            {
                                per.checked = true ;
                                per.disabled = true ;
                              break;
                            }
                        }

                      }
                    }
                
                   
                }
            }
        }
        console.log(this.dataGroup);
    }

    public createForm(): void {
        this.userForm = this.fb.group({
            title: [''],
            des: [''],
            notes: [''],
            url: [''],
            userRole: ['']
        });
    }

    public addNewUser() {
        this.getRolePermission(this.selectedRole);
        this.isEdit = false;
        this.dialogRef = this.dialog.open(this.addUser, {
            width: '850px',
            autoFocus: false,
            maxHeight: '80vh' 
        });
        // this.modalRef = this.modalService.open(this.addUser, {size: 'lg'});
        this.userForm.reset();
    }

    public close(): void {
        this.dialogRef.close();
        // this.modalRef.close();
    }

    public getUserRoleList(): void {
        this.service.getUserRole().subscribe(user => {
            if (user && user.data) {
                this.isLoading = false;
                this.dataSource = new MatTableDataSource<any>(user.data);
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
                this.err = '';
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error in getting permission');
            this.isLoading = false;
            this.err = 'Error while getting User Role';
        });
    }

    public submitAddJobTitle(): void {
        if (this.isEdit) {
            this.edit();
        } else {
            this.add();
        }
    }

    public prepareData(): any {
        const _dataGroup = [];
        const _empGroup = [];
        const _workGroup = [];
        this.dataGroup.map(emp => {
            const obj = {
                id: '',
                name: '',
                modules: []
            };
            obj.id = emp.id;
            obj.name = emp.name;
            obj.modules = [];
            emp.modules.map(modules => {
                const permits = [];
                modules.permits.map(p => {
                    if (p.checked) {
                        permits.push(p);
                    }
                });
                if (permits.length > 0) {
                    obj.modules.push({id: modules.id, name: modules.name, permits});
                }
            });
            if (obj.modules.length > 0) {
                
                _dataGroup.push(obj);
            }
        });
        if(this.employeeAction != undefined)
        {
            this.employeeAction.map(e => {
                if (e.checked) {
                    _empGroup.push(e);
                }
            });
        }
       
        this.workflowAction.map(w => {
            if (w.checked) {
                _workGroup.push(w);
            }
        });
        return {
            dataGroup: _dataGroup,
            empAction: _empGroup,
            wfManage: _workGroup,
            name: this.name,
            roleType: this.selectedRole
        };
    }

    public add(): void {
        this.isUserAdded = true;
        this.service.addRole(this.prepareData()).subscribe(user => {
            this.isUserAdded = false;
            this.toastr.success('User Role added successfully');
            this.dialogRef.close();
            // this.modalRef.close();
            this.userForm.reset();
            this.getUserRoleList();
        }, error => {
            this.isUserAdded = false;
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error in adding User Role.Please try again.');
        });
    }

    public edit(): void {
        this.isUserAdded = true;

        this.service.editUserRole(this.editId, this.prepareData()).subscribe(user => {
            this.isUserAdded = false;
            this.toastr.success('User Role updated successfully');
            this.dialogRef.close();
            // this.modalRef.close();
            this.getUserRoleList();
        }, error => {
            this.isUserAdded = false;
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error in updating User Role.Please try again.');
        });
    }

    public editUser(id,role): void {
        this.isEdit = true;  
        this.isUserAdded = true;
        this.dialogRef = this.dialog.open(this.addUser, {
            width: '850px',
            autoFocus: false,
            maxHeight: '80vh' 
        });
        this.userForm.reset();
        this.getPer(role);
        
        this.service.editRole(id, this.userForm.value).subscribe(user => {
            if (user && user.data) {
                this.editId = id;
                this.isUserAdded = false;
                this.setData(user.data);
              
                // this.modalRef = this.modalService.open(this.addUser, {size: 'lg'});
            }
        }, error => {
            this.isUserAdded = false;
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error getting User Role');
        });
    }

    public setData(data): void {
        this.selectedRole = data.roleType;
        this.name = data.name;
        this.getRolePermission();
        data.dataGroup.map(b1 => {
            this.dataGroup.map(a1 => {
                if (b1.id === a1.id) {
                    let count = 0;
                    b1.modules.map(b2 => {
                        a1.modules.map(a2 => {
                            if (b2.id === a2.id) {
                                b2.permits.map(b3 => {
                                    a2.permits.map(a3 => {
                                        if (b3.id === a3.id) {
                                            a3.checked = true;
                                            count++;
                                        }
                                    });
                                });
                            }
                        });
                    });
                    if (((a1.modules.length) * 4) === count) {
                        a1.checked = true;
                    }
                }
            });
        });
        data.empAction.map(emp => {
            this.employeeAction.map(emp1 => {
                if (emp.id === emp1.id) {
                    emp1.checked = true;
                }
            });
        });

        data.wfManage.map(emp => {
            this.workflowAction.map(emp1 => {
                if (emp.id === emp1.id) {
                    emp1.checked = true;
                }
            });
        });

        this.checkPermList() ;
    }

    public deleteJobTitle(): void {
        this.service.deleteMultipleRoles(this.selectedCheckBoxList).subscribe(user => {
            if (user) {
                this.toastr.success('User Role deleted successfully !');
                this.close();
                this.getUserRoleList();
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.close();
            this.toastr.error(err || 'Error deleting User Role');
        });
        this.selectedCheckBoxList = [];
    }

    public changeRole(): void {
        this.getRolePermission(this.selectedRole);
    }


    public checkAll(e: MatCheckbox, obj): void {
        if (e.checked) {
            obj.modules.forEach(
                value => {
                    value.permits.forEach(
                        val => {
                            val.checked = true;
                            if(val.id === 'r')
                            {
                                val.disabled = true ;
                            }
                        }
                    );
                }
            );
        } else if (!e.checked) {
            obj.modules.forEach(
                value => {
                    value.permits.forEach(
                        val => {
                            val.checked = false;
                            if(val.id === 'r')
                            {
                                val.disabled = false ;
                            }
                        }
                    );
                }
            );
        }
    }

    checkChildren(p: any , perm : Array<any>) 
    {    let found : boolean = false ;
        if(p.id != 'r')
        {
            if(p.checked)
            {
                found = true ;
                for (let per of perm)
                {
                    if(per.id === 'r')
                    {
                        per.checked = true ;
                        per.disabled = true ;
                    }
                }
            }
            else 
            {
               
              
            }
            if(!found)
            {
                for (let per of perm)
                {
                  if(per.id === 'r')
                     {
                            per.disabled = false ;
                             break;
                      }
                }
            }
        }
    }
    checkChild(p: any , perm : Array<any>) {
        let found : boolean = false ;
        if (p.checked) {
            p.checked = false;
        } else {
            p.checked = true;
        }

        if(p.id != 'r')
        {
            if(p.checked)
            {
                found = true ;
                for (let per of perm)
                {
                    if(per.id === 'r')
                    {
                        per.checked = true ;
                        per.disabled = true ;
                    }
                }
            }
            else 
            {
               
                for (let per of perm)
                {
                    if(per.id != 'r')
                    {
                      if(per.checked)
                      {
                        for (let per of perm)
                        {
                            if(per.id === 'r')
                            {
                                per.checked = true ;
                                per.disabled = true ;
                                found = true ;
                                break;
                            }
                        }

                      }
                    }
                }
            }
            if(!found)
            {
                for (let per of perm)
                {
                  if(per.id === 'r')
                     {
                            per.disabled = false ;
                             break;
                      }
                }
            }
        }
    }

    isChecked(p: any) {
        return p.checked === true;
    }

    checkAllChecked(d: any) {
        let allChecked = true;
        d.modules.forEach(
            value => {
                value.permits.forEach(
                    val => {
                        if (val.checked !== true) {
                            allChecked = false;
                        }
                    }
                );
            }
        );
        return allChecked;
    }


    checkForIndeterminate(d: any) {
        let anyChecked = false;
        d.modules.forEach(
            value => {
                value.permits.forEach(
                    val => {
                        if (val.checked === true) {
                            anyChecked = true;
                        }
                    }
                );
            }
        );
        return anyChecked;
    }

    // selectedCheckBoxList = [];

    onCheckBoxChange($event: MatCheckboxChange, id: any) {
        if (!this.alreadySelected($event.source) && $event.checked) {
            this.selectedCheckBoxList.push($event.source.value);
        } else if (!$event.checked) {
            this.selectedCheckBoxList = this.selectedCheckBoxList.filter(
                value => {
                    return value !== $event.source.value;
                }
            );
        }
    }

    alreadySelected(item: MatCheckbox): boolean {
        this.selectedCheckBoxList.forEach(
            value => {
                if (item === value) {
                    return true;
                }
            }
        );
        return false;
    }

    onSelectAllClicked() {
        this.selectedCheckBoxList = [];
        this.matCheckBoxes.forEach(
            item => {
                this.selectedCheckBoxList.push(item.value);
                item.checked = true;
            }
        );
    }

    onDeSelectAllClicked() {
        this.selectedCheckBoxList = [];
        this.matCheckBoxes.forEach(
            item => {
                item.checked = false;
            }
        );
    }


    onDeleteSelected() {
        this.dialogRef = this.dialog.open(this.deleteUser, {
            width: '440px',
            height: 'auto'
        });
        // this.modalRef = this.modalService.open(this.deleteUser);
    }

    onUserChanged(user: HTMLInputElement) {
        if (user.checked) {
            this.selectedUsers.push(user);
            return;
        }
        this.selectedUsers = this.selectedUsers.filter(value => value !== user);
    }
    onModelChange(value: string) {
        this.dataSource.filter = value.trim().toLocaleLowerCase();
    }
    exportToCsv() {
        const csv = this.dataSource.filteredData.map(
            row => {
                return [row.name, row.roleType].join(',') + '\n';
            }
        );
        csv.unshift(['name', 'roleType'].join(',') + '\n');
        const exportDate = new Date();
        const exportFileName = `Csv-export-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}.csv`;
        saveAs(new Blob(csv, {type: 'text/csv;charset=utf-8'}), exportFileName);
    }
    saveAsPdf() {
        if (this.dataSource.filteredData.length <= 0) {
            this.toastr.error('No Data to export');
            return;
        }
        const body = [];
        for (let i = 0; i < this.dataSource.filteredData.length; i++) {
            body.push(
                [
                    this.dataSource.filteredData[i].name,
                    this.dataSource.filteredData[i].roleType
                ]);
        }
        const doc = new jsPDF();
        autoTable(doc, {
            head: [['name', 'roleType']],
            body: body
        });
        const exportDate = new Date();
        const exportFileName = `User-Roles-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}}`;
        doc.save(exportFileName);

    }
}
