import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eeo-filling',
  templateUrl: './eeo-filling.component.html',
  styleUrls: ['./eeo-filling.component.scss']
})
export class EeoFillingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
