
<div [hidden]="this.isLoading">
    <form style="padding: 24px" class="form-material full-width-inputs" action="javascript:" [formGroup]="filterYear">
        <div class="row form-group">
            <div class="col-sm-4">
                <mat-form-field class="form-element">
                    <mat-label>Employee</mat-label>
                    <input required="" class="form-control" type="text"
                           aria-label="empId"
                           matInput
                           formControlName="empId"
                           [matAutocomplete]="auto"
                           placeholder="Type for hints .."

                    >
                    <mat-autocomplete #auto="matAutocomplete"
                                      [displayWith]="displayEmployeeByName.bind(this)">
                        <mat-option *ngFor="let r of filteredOptions " [value]="r.value">
                            {{r.label}}
                        </mat-option>
                    </mat-autocomplete>
                
                </mat-form-field>
                <!-- <mat-error
                *ngIf="!filterYear.controls.empId .valid && filterYear.controls.empId .touched">
            please enter Employee
        </mat-error> -->
        <mat-error
        [hidden]="this.correct">
           please select employee from the dropdown 
          </mat-error>
            </div>
            

            <div class="col-sm-4">
                <mat-form-field class="form-element">
                    <mat-select  disableOptionCentering class="form-control"
                                formControlName="year" >
                        <ng-container
                                *ngFor="let t of this.yearsList">
                            <mat-option
                                    [value]="t.value">{{t.label}}</mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-label for="des">Year</mat-label>
                </mat-form-field>

            </div>
            <div class="col-sm-4">
                <mat-checkbox style="margin-top: 25px" #employeeNameCheck class="form-control" color="primary"
                              formControlName="makeDefaultFilter">
                    Make Default Filter
                </mat-checkbox>
            </div>
        </div>
        <div class="row">
            <div class="action-buttons multi-btns col-sm-3">
                <span style="font-weight: bold; color: #0d47a1;" class="cursor-pointer" (click)="addNewCourse()">Legend</span>
            </div>
            <div class="action-buttons multi-btns col-sm-9 text-right">
                <button mat-raised-button class="btn-danger" (click)="rest()">
                    <i class="flaticon-cancel"></i>Reset
                </button>
                <button mat-raised-button class="btn btn-primary" color="primary"
                        [disabled]="!filterYear.valid ||!this.correct"
                        (click)="submitAddUser()"><i class="flaticon-diskette"></i>View
                </button>
            </div>
        </div>
    </form>
</div>
<app-loading *ngIf="isLoading" [isLoading]="isLoading"></app-loading>
<div *ngIf="!isLoading && !isNotShow" class="control-section">
    <div class="col-lg-10 content-wrapper" style="max-width: 100%">
        <ejs-schedule [selectedDate]="selectedDate" [showHeaderBar]="false" #scheduleObj width='100%' height='650px'
                      cssClass="year-view"
                      [eventSettings]="eventSettings"
                      [firstMonthOfYear]='0' [monthsCount]='12'
                      (eventRendered)="onEventRendered($event)">

            <e-views>
                <e-view option="Year" [isSelected]="isSelected"></e-view>
            </e-views>
        </ejs-schedule>
    </div>
</div>


<ng-template #addCourse let-modal>
    <!-- <div class="card my-0"> -->
    <!-- <div class="header"> -->
    <div class="mat-modal-header">
        <!-- <h4 class="card-title card-title-modal"> -->
        <h4 class="mat-title-modal">
            <span>Legend</span>
        </h4>
    </div>
    <!-- <div class="card-content"> -->
    <div class="mat-modal-body">
        <div *ngIf="!isChange">
        <div style="margin-bottom: 30px">
            <span style="font-weight: bold">Status</span>
        </div>
        <div>
            <div>
                <span style="margin-right: 30px">A</span>
                <span >Approved</span>
            </div>
            <div>
                <span style="margin-right: 30px">C</span>
                <span >Cancelled</span>
            </div>
            <div>
                <span style="margin-right: 30px">PA</span>
                <span >Pending Approval</span>
            </div>
            <div>
                <span style="margin-right: 30px">R</span>
                <span >Rejected</span>
            </div>
            <div>
                <span style="margin-right: 30px">S</span>
                <span >Scheduled</span>
            </div>
            <div>
                <span style="margin-right: 30px">T</span>
                <span >Taken</span>
            </div>

            <div style="margin-bottom: 30px; margin-top: 20px">
                <span style="font-weight: bold">Leave Type</span>
            </div>
            <div>
                <div *ngFor="let r of legends">
                    <div style="border: 1px solid #DDDDDD; width: 15px; height: 15px; margin-right: 30px; display: inline-block" [ngStyle]="{'background' : r.color}"></div>
                    <span >{{r.laeveTypeName}}</span>
                </div>
            </div>
        </div>
        </div>
        <div *ngIf="isChange">
            <div>
                <div *ngFor="let r of legends">
                    <div style="margin-right: 30px; display: inline-block">
                        <mcc-color-picker hideUsedColors [selectedColor]="r.color" hideButtons hideHexForms (change)="r.color = $event" (selected)="r.color = $event"> </mcc-color-picker>
                    </div>
                    <span >{{r.laeveTypeName}}</span>
                </div>
            </div>
        </div>
        <div style="margin-top: 30px" class="action-buttons multi-btns col-sm-12 text-right">
            <button mat-raised-button class="btn-danger" (click)="close()">
                <i class="flaticon-cancel"></i>Cancel
            </button>
            <button *ngIf="!isChange" mat-raised-button class="btn btn-primary" color="primary"
                    (click)="save()"><i class="flaticon-diskette"></i>Change Legend Colors
            </button>

            <button *ngIf="isChange" mat-raised-button class="btn btn-primary" color="primary"
                    (click)="save1()"><i class="flaticon-diskette"></i>Save
            </button>
        </div>
    </div>
</ng-template>


