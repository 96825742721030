<div (window:resize)="onResize($event)" [attr.fream-type]="freamType" [attr.layout-type]="layoutType"
  [attr.nav-type]="navType" [attr.pcoded-device-type]="pcodedDeviceType" [attr.sidebar-img-type]="sidebarImgType"
  [attr.sidebar-img]="sidebarImg" [attr.theme-layout]="themeLayout" [attr.vertical-effect]="verticalEffect"
  [attr.vertical-layout]="verticalLayout" [attr.vertical-nav-type]="verticalNavType"
  [attr.vertical-placement]="verticalPlacement" [attr.vnavigation-view]="vnavigationView" [ngClass]="animateSidebar"
  class="pcoded" id="pcoded" (window:beforeunload)="doBeforeUnload()">
  <div class="pcoded-overlay-box"></div>
  <div class="pcoded-container navbar-wrapper">
    <nav class="navbar header-navbar pcoded-header" [attr.header-theme]="headerTheme"
      [attr.pcoded-header-position]="pcodedHeaderPosition" style="z-index: 100000">
      <div class="navbar-wrapper">
        <div #navLogo class="navbar-logo">
          <a #imageLogo class="light logo-img-container">
            <img class="img-fluid logo-img" src="assets/team.png" alt="Team Hrm" />
          </a>
          <a #toggleMenu class="mobile-menu light" id="mobile-collapse" href="javascript:"
            (click)="toggleOpened($event)" (clickOutside)="onClickedOutsideSidebar($event)" [exclude]="'#main_navbar'">
            <span class="material-icons"> menu_open </span>
          </a>
        </div>
        <div class="navbar-container">
          <ul class="nav-left">
            <li class="light" appToggleFullScreen>
              <a href="javascript:">
                <span class="material-icons"> fullscreen </span>
              </a>
            </li>
          </ul>
          <!--                   <ul [@mobileHeaderNavRight]="navRight" [ngClass]="navRight" class="nav-right">-->

          <!--        <nav class="navbar header-navbar pcoded-header" [attr.header-theme]="headerTheme"-->
          <!--             [attr.pcoded-header-position]="pcodedHeaderPosition">-->
          <!--            <div class="navbar-wrapper">-->
          <!--                <div #navLogo class="navbar-logo">-->
          <!--                    <a #toggleMenu class="mobile-menu ripple light" id="mobile-collapse" href="javascript:"-->
          <!--                       (click)="toggleOpened($event)" [exclude]="'#main_navbar'"-->
          <!--                       (clickOutside)="onClickedOutsideSidebar($event)">-->
          <!--                        <i class="icon-menu"></i>-->
          <!--                    </a>-->
          <!--                    <a #imageLogo class="ripple light logo-img-container">-->
          <!--                        <img class="img-fluid logo-img" src="assets/team.png" alt="Team Hrm"/>-->
          <!--                    </a>-->
          <!--                    <a href="javascript:" class="mobile-options ripple light" (click)="toggleHeaderNavRight()">-->
          <!--                        <i class="icon-more"></i>-->
          <!--                    </a>-->
          <!--                </div>-->
          <!--                <div class="navbar-container container-fluid">-->
          <!--                    <ul class="nav-left">-->
          <!--                        <li class="ripple light" appToggleFullScreen>-->
          <!--                            <a href="javascript:">-->
          <!--                                <i class="icon-fullscreen"></i>-->
          <!--                            </a>-->
          <!--                        </li>-->
          <!--                    </ul>-->
          <!--                    <ul [@mobileHeaderNavRight]="navRight" [ngClass]="navRight" class="nav-right">-->

          <!--                        <li (clickOutside)="notificationOutsideClick('profile')"-->
          <!--                            class="user-profile header-notification ripple light" [ngClass]="profileNotificationClass"-->
          <!--                            (click)="toggleProfileNotification()">-->
          <!--                            <a href="javascript:">-->
          <!--                                <img src="{{this.empImage}}" class="img-radius" alt="User-Profile-Image">-->
          <!--                                <span>{{username}}</span>-->
          <!--                                <i class="icon-angle-down"></i>-->
          <!--                            </a>-->
          <!--                            <ul [@notificationBottom]="profileNotification"-->
          <!--                                class="show-notification profile-notification" [ngClass]="profileNotificationClass">-->
          <!--                                <li class="ripple">-->
          <!--                                    <a (click)="about()">-->
          <!--                                        <i class="icon-user"></i> About-->
          <!--                                    </a>-->
          <!--                                </li>-->
          <!--                                <li class="ripple">-->
          <!--                                    <a routerLink="/change-password">-->
          <!--                                        <i class="icon-user"></i> Change Password-->
          <!--                                    </a>-->
          <!--                                </li>-->
          <!--                                <li class="ripple">-->
          <!--                                    <a (click)="logout()">-->
          <!--                                        <i class="icon-layout-sidebar-left"></i> Logout-->
          <!--                                    </a>-->
          <!--                                </li>-->

          <!--                            </ul>-->
          <!--                        </li>-->
          <!--                    </ul>-->
          <!--                </div>-->
          <!--            </div>-->
        </div>
      </div>
    </nav>

    <div class="pcoded-main-container" [style.margin-top]="headerFixedMargin">
      <div class="pcoded-wrapper">
        <nav id="main_navbar" class="pcoded-navbar sidebar-nav" style="background: white"
          (clickOutside)="onClickedOutsideSidebar($event)" [exclude]="'#mobile-collapse'"
          [attr.navbar-theme]="navBarTheme" [attr.active-item-theme]="activeItemTheme" sub-item-theme="theme2"
          active-item-style="style0" [attr.pcoded-header-position]="pcodedHeaderPosition"
          [attr.pcoded-navbar-position]="pcodedSidebarPosition" [style.top]="headerFixedTop"
          [style.height]="sidebarFixedNavHeight">
          <!-- <perfect-scrollbar id="p-scrollbar" [style.max-width]="'235px'"
                                       [style.max-height]="sidebarFixedHeight" 
                                       [config]="config" [ngClass]="perfectDisable"> -->

          <div appAccordion class="pcoded-inner-navbar main-menu" style="background: transparent">
            <!-- navbar Header -->
            <div id="sidebar-userinfo" class="custom-perfect-scrollbar">
              <div class="custom-scrollbar">
                <div class="main-menu-header">
                  <img class="img-menu-user img-radius" src="{{ this.empImage }}" />
                  <div class="user-details">
                    <span id="more-details" (click)="toggleOpenedSidebar()">
                      {{ empName }}
                      <i class="fa fa-caret-down m-l-10"></i>
                    </span>
                  </div>
                </div>

                <div class="main-menu-content">
                  <ul>
                    <li [@mobileMenuTop]="isCollapsedSideBar" class="more-details" [ngClass]="isCollapsedSideBar">
                      <a (click)="about()"> <i class="flaticon-profile-1"></i> About </a>
                    </li>
                    <li [@mobileMenuTop]="isCollapsedSideBar" class="more-details" [ngClass]="isCollapsedSideBar">
                      <a routerLink="/change-password"> <i class="flaticon-unlock"></i> Change Password </a>
                    </li>
                    <li [@mobileMenuTop]="isCollapsedSideBar" class="more-details" [ngClass]="isCollapsedSideBar">
                      <a (click)="logout()" class="ripple" target="_blank"> <i class="flaticon-logout-1"></i>Logout</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- navbar -->
            <perfect-scrollbar [config]="config" [ngClass]="perfectDisable"
              [style.max-height]="scrollSidebarFixedHeight" [style.max-width]="'235px'" [style.scrollbar-width]="'40px'"
              id="p-scrollbar" style="background: white">
              <div #navContent id="navDiv" *ngFor="let asideItems of navBar" class="d-color"
                [class]="desktopScrollHeight">
                <div class="pcoded-trigger vv"></div>
                <!--                                for the shortcut-->
                <div (click)="open(clickMenuTrigger)"
                  style="color: #666; font-size: 14px; padding: 1px 19px; font-weight: normal"
                  class="pcoded-navigation-label adjustment">
                  <button style="width: 31px" mat-icon-button>
                    <mat-icon>favorite_border</mat-icon>
                  </button>
                  {{ asideItems.shortcuts }}
                  <span #clickMenuTrigger="matMenuTrigger" (click)="$event.stopPropagation()" [matMenuTriggerFor]="menu"
                    class="float-right span-adjustment">
                  </span>
                </div>
                <mat-menu #menu="matMenu" xPosition="after">
                  <button (click)="goToUrl('apl')" mat-menu-item>Apply Leave</button>
                  <button (click)="goToUrl('al')" mat-menu-item>Assign Leave</button>
                  <button (click)="goToUrl('ll')" mat-menu-item>Leave List</button>
                  <button (click)="goToUrl('lc')" mat-menu-item>Leave Calendar</button>
                </mat-menu>

                <!--                                <div class="pcoded-navigation-label hover-mouse"-->
                <!--                                     [attr.menu-title-theme]="menuTitleTheme" [routerLink]="['/', 'info']">{{asideItems.infoLabel}}</div>-->
                <!--                                <div class="pcoded-navigation-label hover-mouse"-->
                <!--                                     [attr.menu-title-theme]="menuTitleTheme" [routerLink]="['/', 'more', 'dashboard']">{{asideItems.label}}</div>-->

                <ul id="nav-main-item" class="pcoded-item pcoded-left-item" [attr.item-border]="itemBorder"
                  [attr.item-border-style]="itemBorderStyle" [attr.subitem-border]="subItemBorder"
                  *ngFor="let asideItem of asideItems.main" appAccordionLink group="{{ asideItem.state }}">
                  <li #liMain routerLinkActive="active pcoded-trigger" *ngIf="asideItem.type === 'external'"
                    appAccordionLink group="{{ asideItem.state }}">
                    <a [routerLinkActive]="['active']" class="" href="{{ asideItem.external }}"
                      target="{{ asideItem.target ? '_blank' : '_self' }}" appAccordionToggle>
                      <span class="pcoded-micon"><i class="{{ asideItem.icon }}"></i><b>{{ asideItem.short_label
                          }}</b></span>
                      <span class="pcoded-mtext">{{ asideItem.name }}</span>
                      <span *ngFor="let asideBadge of asideItem.badge"
                        class="pcoded-badge label label-{{ asideBadge.type }}">{{ asideBadge.value }}</span>
                      <span class="pcoded-mcaret"></span>
                    </a>
                  </li>
                  <li #linkType routerLinkActive="active pcoded-trigger" *ngIf="asideItem.type === 'link'"
                    appAccordionLink group="{{ asideItem.state }}" (click)="onLinkTypeClicked(linkType)">
                    <a [routerLinkActive]="['active']" class=""
                      [routerLink]="['/', asideItem.main_state, asideItem.state]"
                      target="{{ asideItem.target ? '_blank' : '_self' }}" appAccordionToggle
                      *ngIf="asideItem.main_state; else mainContent">
                      <span class="pcoded-micon"><i class="{{ asideItem.icon }}"></i><b>{{ asideItem.short_label
                          }}</b></span>
                      <span class="pcoded-mtext">{{ asideItem.name }}</span>
                      <span *ngFor="let asideBadge of asideItem.badge"
                        class="pcoded-badge label label-{{ asideBadge.type }}">{{ asideBadge.value }}</span>
                      <span class="pcoded-mcaret"></span>
                    </a>
                    <ng-template #mainContent>
                      <a [routerLinkActive]="['active']" class="" [routerLink]="['/', asideItem.state]"
                        target="{{ asideItem.target ? '_blank' : '_self' }}" appAccordionToggle>
                        <span class="pcoded-micon"><i class="{{ asideItem.icon }}"></i><b>{{ asideItem.short_label
                            }}</b></span>
                        <span class="pcoded-mtext">{{ asideItem.name }}</span>
                        <span *ngFor="let asideBadge of asideItem.badge"
                          class="pcoded-badge label label-{{ asideBadge.type }}">{{ asideBadge.value }}</span>
                        <span class="pcoded-mcaret"></span>
                      </a>
                    </ng-template>
                  </li>
                  <li (click)="onLiClickCustom($event)" (mousedown)="b4ClickCustom($event)"
                    routerLinkActive="active pcoded-trigger" *ngIf="asideItem.type === 'sub'"
                    [attr.dropdown-icon]="dropDownIcon" [attr.subitem-icon]="subItemIcon" appAccordionLink
                    [hidden]="!asideItem.role.includes(this.currentUserRole)" class="pcoded-hasmenu"
                    group="{{ asideItem.state }}">
                    <a [routerLinkActive]="['active']" href="javascript:;" appAccordionToggle>
                      <div class="menu-ripple"></div>
                      <span class="menu-item-icon pcoded-micon">
                        <!--<i class="{{ asideItem.icon }}"></i>
                                                <img src="{{ asideItem.icon }}"/>-->
                        <span class="{{ asideItem.icon }}"></span>
                        <b>{{ asideItem.short_label }}</b>
                      </span>
                      <!--                                            this is the parent item like admin-->
                      <span class="pcoded-mtext">{{ asideItem.name }}</span>
                      <span *ngFor="let asideBadge of asideItem.badge"
                        class="pcoded-badge label label-{{ asideBadge.type }}">{{ asideBadge.value }}</span>
                      <span class="pcoded-mcaret"></span>
                    </a>
                    <ul class="pcoded-submenu">
                      <ng-template ngFor let-asideChildren [ngForOf]="asideItem.children">
                        <!--                                                this is like audit trial with no sub child-->
                        <li #noStateChild (click)="onNoStateClicked(noStateChild)"
                          routerLinkActive="active pcoded-trigger"
                          [hidden]="asideChildren.role && !asideChildren.role.includes(this.currentUserRole)"
                          *ngIf="asideChildren.type !== 'sub'">
                          <a [routerLinkActive]="['active']" [routerLink]="['/', asideItem.state, asideChildren.state]"
                            target="{{ asideChildren.target ? '_blank' : '_self' }}">
                            <div class="menu-ripple"></div>
                            <span class="pcoded-micon">
                              <i class="icon-angle-right"></i>
                            </span>
                            <span class="pcoded-mtext">{{ asideChildren.name }} </span>
                            <span *ngFor="let asideChildrenBadge of asideChildren.badge"
                              class="pcoded-badge label label-{{ asideChildrenBadge.type }}">{{ asideChildrenBadge.value
                              }}</span>
                            <span class="pcoded-mcaret"></span>
                          </a>
                        </li>
                        <!--                                                this is like with sub sub menu like job organization-->
                        <li #target class="pcoded-hasmenu" routerLinkActive="active pcoded-trigger"
                          [attr.dropdown-icon]="dropDownIcon" [attr.subitem-icon]="subItemIcon"
                          [hidden]="asideChildren.role && !asideChildren.role.includes(this.currentUserRole)"
                          *ngIf="asideChildren.type === 'sub'" appAccordionLink group="sub-toggled">
                          <a [routerLinkActive]="['active']" appAccordionToggle href="javascript:;">
                            <!--                                                    *ngIf="asideChildren.role === 'admin'">-->
                            <div class="menu-ripple"></div>
                            <span class="pcoded-micon"><i class="icon-direction-alt"></i></span>
                            <span class="pcoded-mtext">{{ asideChildren.name }}</span>
                            <span *ngFor="let asideChildrenBadge of asideChildren.badge"
                              class="pcoded-badge label label-{{ asideChildrenBadge.type }}">{{ asideChildrenBadge.value
                              }}</span>
                            <span class="pcoded-mcaret"></span>
                          </a>
                          <ul class="pcoded-submenu">
                            <ng-template ngFor let-asideChildrenSub [ngForOf]="asideChildren.children">
                              <li (click)="onLiClick($event, target)" [hidden]="
                                  asideChildrenSub.role && !asideChildrenSub.role.includes(this.currentUserRole)
                                " routerLinkActive="active pcoded-trigger">
                                <!--                                                            *ngIf="asideChildrenSub.role === 'admin'">-->
                                <a [routerLinkActive]="['active']"
                                  [routerLink]="['/', asideItem.state, asideChildren.state, asideChildrenSub.state]"
                                  target="{{ asideChildrenSub.target ? '_blank' : '_self' }}">
                                  <div class="menu-ripple"></div>
                                  <span class="pcoded-micon"><i class="icon-angle-right"></i></span>
                                  <span class="pcoded-mtext">{{ asideChildrenSub.name }}</span>
                                  <span *ngFor="let asideChildrenSubBadge of asideChildrenSub.badge"
                                    class="pcoded-badge label label-{{ asideChildrenSubBadge.type }}">{{
                                    asideChildrenSubBadge.value }}</span>
                                  <span class="pcoded-mcaret"></span>
                                </a>
                              </li>
                            </ng-template>
                          </ul>
                        </li>
                      </ng-template>
                    </ul>
                  </li>
                </ul>
              </div>
            </perfect-scrollbar>
          </div>
          <!-- </perfect-scrollbar> -->
        </nav>
        <div class="pcoded-content">
          <app-breadcrumbs></app-breadcrumbs>
          <div class="pcoded-inner-content">
            <!-- Main-body start -->
            <div class="main-body">
              <div class="page-wrapper">
                <router-outlet>
                  <title></title>
                  <app-spinner></app-spinner>
                </router-outlet>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #aboutModal let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">About</h4>
    </div>
    <div class="mat-modal-body">
      <div class="txt">Company Name: {{ aboutData.organasation }}</div>
      <div class="txt">Version: ScreenHRM 1.0</div>
      <div class="txt">Employees: {{ aboutData.employees }}</div>
      <div class="txt">Users: {{ aboutData.users }}</div>

      <div class="action-buttons text-right col-sm-12">
        <button color="primary" mat-raised-button class="btn-danger" (click)="closeAbout()">
          <i class="flaticon-cancel"></i>Close
        </button>
      </div>
    </div>
  </div>
</ng-template>