import {Component, OnInit, ViewChild, Input} from '@angular/core';
import {DashboardService} from '../../../dashboard.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { NotificationService } from "src/app/core/services/notification.service";
import * as moment from 'moment';
import jsPDF from 'jspdf';
import {saveAs} from 'file-saver-es';
import autoTable from 'jspdf-autotable';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-emp-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.scss']
})
export class EmpMembershipComponent implements OnInit {

  public filterQuery = '';
  selectedUsers: any[] = [];

  public userList: Array<any>;
  public isLoading: boolean;
  public isUserAdded: boolean;
  public err: string;
  public modalRef: any;
  public userForm: FormGroup;
  public isEdit: boolean;
  public editId: string;
  public deleteId: string;
  public attacherId: string;
  public attachmentList = [];
  comment: string;
  public fileType: string;
  public fileObj: any;
  public uploadedFile: any;
  public fileError: string;
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  public columns = ['select', 'membership', 'spb', 'amount', 'currency', 'scd', 'srd', 'attachment', 'edit'];
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public validationError = {
    required: 'Required'
  };
  public payGradeCurrencyList = [];
  public membershipList = [];
  @Input() employeeId: string;
  @ViewChild('addUser', {static: false}) addUser;
  @ViewChild('deleteUser', {static: false}) deleteUser;
  @ViewChild('attach', {static: false}) attach;


  constructor( private authService : AuthService,private service: DashboardService, private modalService: NgbModal, private fb: FormBuilder, private toastr: NotificationService) {
    this.createForm();
  }
  public permissions : any;
  public read = false;
  public write = false ;
  public update = false;
  public delete = false;

  checkPermission(module,tabs) : void 
  {
      this.permissions = JSON.parse(this.authService.getPermissions());

      this.read = false ;
      this.write = false ;
      this.delete = false ;
      this.update = false;

      if(this.permissions != undefined && this.permissions != null )
      {
          for(let p of this.permissions)
          {
             
              if(p.id === module)
              {
                 
                  if(p.modules != undefined)
                  {
                    
                      for(let tab of p.modules)
                      {
                          if(tab.id === tabs)
                          {
                            
                              for(let move of tab.permits)
                              {
                                  if(move.id === "u")
                                  {
                                      this.update = true;
                                     
                                  }
                                  else if(move.id === "r")
                                  {
                                      this.read = true;
                                  }
                                 
                                  else if(move.id === "d")
                                  {
                                      this.delete = true;
                                  }
                              }
                          }
                      }
                  }
                 
              }
             
          }
      }
     

  }
  ngOnInit() {
    this.isLoading = true;
    this.isEdit = false;
    this.checkPermission("pdm","pd_em");
    this.getJobCategoryList();
    this.isUserAdded = false;
    this.service.getPayGradeCurrencyList().subscribe(resp => {
      if (resp && resp.data && resp.data.currency) {
        this.payGradeCurrencyList = resp.data.currency;
      }
    });
    this.service.getMembershipsList().subscribe(resp => {
      if (resp && resp.data) {
        this.membershipList = resp.data;
      }
    });
  }

  public createForm(): void {
    this.userForm = this.fb.group({
      amount: [''],
      currency: [''],
      memberShip: ['', [Validators.required]],
      scd: [''],
      srd: [''],
      spb: ['']
    });
   
  }

  public addNewUser() {
    this.isEdit = false;
    this.modalRef = this.modalService.open(this.addUser);
    this.userForm.reset();
  }

  public close(): void {
    this.modalRef.close();
  }

  public getJobCategoryList(): void {
    this.service.getEmpMembership(this.employeeId).subscribe(user => {
      if (user && user.data) {
        this.isLoading = false;
        this.userList = user.data;
        if (user.extras.memberships) {
          this.userList.map(u => {
            if (user.extras.memberships[u.memberShip]) {
              u.membership = user.extras.memberships[u.memberShip].name;
            }
          });
        }
        this.dataSource = new MatTableDataSource<any>(this.userList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.err = '';
      }
    }, error => {
      this.isLoading = false;
      this.err = 'Error while getting Membership';
    });
  }

  public submitAddJobCategory(): void {
    if (this.isEdit) {
      this.edit();
    } else {
      this.add();
    }
  }

  public add(): void {
    this.userForm.value['empId'] = this.employeeId;
    this.isUserAdded = true;
    this.service.addEmpMembership(this.userForm.value).subscribe(user => {
      this.isUserAdded = false;
      this.toastr.success('Membership added successfully');
      this.modalRef.close();
      this.userForm.reset();
      this.getJobCategoryList();
    }, error => {
      this.isUserAdded = false;
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error in adding Membership.Please try again.');
    });
  }

  public edit(): void {
    this.isUserAdded = true;
    this.userForm.value['empId'] = this.employeeId;
    this.service.editEmpMembership(this.userForm.value, this.editId).subscribe(user => {
      this.isUserAdded = false;
      this.toastr.success('Membership updated successfully');
      this.modalRef.close();
      this.getJobCategoryList();
    }, error => {
      this.isUserAdded = false;
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error in updating Membership.Please try again.');
    });
  }

  public editUser(id): void {
    this.isEdit = true;
    this.service.getEmpMembershipById(id).subscribe(user => {
      if (user && user.data) {
        user.data['scd'] = moment(user.data['scd']).format('YYYY-MM-DD');
        user.data['srd'] = moment(user.data['srd']).format('YYYY-MM-DD');
        this.userForm.patchValue(user.data);
        this.modalRef = this.modalService.open(this.addUser);
        this.editId = id;
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error getting Membership');
    });
  }

  public deleteUserForm(id): void {
    this.deleteId = id;
    this.modalRef = this.modalService.open(this.deleteUser);
  }

  public deleteJobCategory(): void {
    const temp = [];
    this.selectedUsers.map(res => {
      temp.push(res.defaultValue);
    });
    this.service.deleteMultipleEmpMembership(temp).subscribe(user => {
      if (user) {
        this.close();
        this.getJobCategoryList();
        this.toastr.success('Membership deleted !');
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error in deleting Membership');
    });
    this.onDeselectAllUsers();
  }

  onSelectAllUsers() {
    this.selectedUsers = [];
    const inputs: HTMLCollection = document.getElementsByClassName('user-input');
    Array.from(inputs).forEach(
        value => {
          this.selectedUsers.push(value);
          (value as HTMLInputElement).checked = true;
        }
    );
  }

  onDeselectAllUsers() {
    this.selectedUsers = [];
    const inputs: HTMLCollection = document.getElementsByClassName('user-input');
    Array.from(inputs).forEach(
        value => {
          (value as HTMLInputElement).checked = false;
        }
    );
  }

  onDeleteSelected() {
    this.modalRef = this.modalService.open(this.deleteUser);
  }

  onUserChanged(user: HTMLInputElement) {
    if (user.checked) {
      this.selectedUsers.push(user);
      return;
    }
    this.selectedUsers = this.selectedUsers.filter(value => value !== user);
  }

  onModelChange(value: string) {
    console.log(value);
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  exportToCsv() {
    const csv = this.dataSource.filteredData.map(
        row => {
          return [
            row.membership,
            row.spb,
            row.amount,
            row.currency,
            row.scd,
            row.srd
          ].join(',') + '\n';
        }
    );
    csv.unshift(['membership', 'Subscription Paid By', 'amount', 'currency',
      'Subscription Commence Date', 'Subscription Renewal Date'].join(',') + '\n');
    const exportDate = new Date();
    // tslint:disable-next-line:max-line-length
    const exportFileName = `Csv-export-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}.csv`;
    saveAs(new Blob(csv, {type: 'text/csv;charset=utf-8'}), exportFileName);
  }

  saveAsPdf() {
    if (this.dataSource.filteredData.length <= 0) {
      this.toastr.error('No Data to export');
      return;
    }
    const body = [];
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      body.push(
          [
            this.dataSource.filteredData[i].membership,
            this.dataSource.filteredData[i].spb,
            this.dataSource.filteredData[i].amount,
            this.dataSource.filteredData[i].currency,
            this.dataSource.filteredData[i].scd,
            this.dataSource.filteredData[i].srd
          ]);
    }
    const doc = new jsPDF();
    autoTable(doc, {
      head: [['membership', 'Subscription Paid By', 'amount', 'currency', 'Subscription Commence Date', 'Subscription Renewal Date']],
      body: body
    });
    const exportDate = new Date();
    const exportFileName = `memberShip-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}}`;
    doc.save(exportFileName);
  }

  onAddAttachment(id) {
    this.attacherId = id;
    this.service.getAttachmentList(this.attacherId).subscribe(
        r => {
          if (r && r.data) {
            this.attachmentList = r.data;
          }
        },
        error => {
        }
    );
    this.comment = '';
    this.fileType = '';
    this.fileObj = null;
    this.modalRef = this.modalService.open(this.attach);
  }

  fileChange($event, type): void {
    this.uploadedFile = undefined;
    const reader = new FileReader();
    const file = $event.target.files[0];
    this.uploadedFile = file;
    const fileName = file && file.name;
    if (file && (file.size / 1024) < 2048) {
      this.fileObj = {
        added: new Date(),
        addedBy: 'string',
        desc: this.comment,
        docType: 'dd',
        empId: this.attacherId,
        fileName: fileName,
        size: file.size,
        url: ''
      };
    } else {
      this.fileError = 'Max size 2 MB';
      this.toastr.error('Max 2Mb File allowed.');
    }
  }

  onDownloadClicked(attachment: any) {
    const URL = attachment['url'];
    const FILE_NAME = attachment['fileName'];
    window.open(URL);
  }

  onDeleteClicked(attachment: any) {
    const ID = attachment['id'];
    this.service.deleteAttachment(ID).subscribe(
        res => {
          this.service.getAttachmentList(this.attacherId).subscribe(
              r => {
                this.attachmentList = r.data;
                this.toastr.success('Deleted');
              },
              error => {
                console.log(error);
                this.toastr.error('Failed to delete');
              }
          );
        }
    );
  }

  saveAttachment() {
    if (this.fileObj !== null) {
      this.service.uploadFile(this.uploadedFile).subscribe(resp => {
        if (resp && resp.data) {
          this.fileObj['url'] = resp.data.filename;
          this.fileObj['desc'] = this.comment;
          this.service.addAttachment(this.fileObj).subscribe(
              res => {
                this.service.getAttachmentList(this.attacherId).subscribe(
                    r => {
                      this.attachmentList = r.data;
                      this.toastr.success('Attachment Added');
                      this.modalRef.close();
                    },
                    error => {
                      this.toastr.error('Failed to add Attachment');
                      this.modalRef.close();
                    }
                );
              },
              error => {
                this.toastr.error('Failed to add Attachment');
                this.modalRef.close();
              }
          );
        }
      }, error => {
        const err = error.error && error.error.msg;
        console.log(err);
        this.toastr.error('Failed to save Attachment', 'Upload Unsuccessful');
        this.modalRef.close();
      });
    } else {
      this.toastr.error('No file Selected');
    }
  }
}


