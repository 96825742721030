<div id="header-page" class="ng-star-inserted header-page">
    <div class="page-header ng-star-inserted">
        <div class="page-block ng-star-inserted">
        <div class="row align-items-center">
            <div class="col-md-8">
            <div class="page-header-title">
                <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted">{{lang.get("competencyList")}}</h4></span>
            </div>
            </div>
            <div class="col-md-4"></div>
        </div>
        </div>
    </div>
</div>


<div class="page-body no-title">
    <div class="row">
      <div class="col-sm-12">
    <div class="row top-tools">
        <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6 left-tools">
            <span class="export-icon-btn csv" (click)="exportToCsv()"><i class="material-icons">get_app</i><span>{{lang.get("csv")}}</span>
            </span>
            <span class="export-icon-btn pdf" (click)="saveAsPdf()" ><i class="material-icons">get_app</i><span>{{lang.get("pdf")}}</span>
            </span>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
            <div class="relative-position search-filter-con"> 
              <span class="icon material-icons ng-tns-c114-0"> search </span>
                <mat-form-field>
                    <input matInput type="search"  maxlength="40" 
                           (keyup)="filterChanged($event.target.value)"
                           class="form-control input-sm full-data-search">
                </mat-form-field>
                <span class="mytooltip cus-left tooltip-effect-4" [hidden]="!write">
                    <span class="tooltip-item">
                        <button mat-mini-fab class="btn btn-primary light add-rounded margin-button-05" (click)="openModel()">
                            <i class="fa fa-plus"></i>
                        </button>
                    </span>
                    <span class="tooltip-content clearfix">
                    <span class="tooltip-text">{{lang.get("new")}}</span>
                    </span>
                </span>
            </div>
        </div>
    </div>

    <div class="dt-responsive">
        <div class="table-responsive table-similar">
            <mat-card-content>
                <div class="d-flex full-width header-cell" [hidden]="!delete">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item  (click)="changeAll(true)">
                            <span>{{lang.get("select all")}} </span>
                        </button>
                        <button mat-menu-item *ngIf="isAnySelected()" (click)="changeAll(false)">
                            <span>{{lang.get("deselect all")}} </span>
                        </button>
                        <button (click)="onDeleteSelected()" *ngIf="isAnySelected()" mat-menu-item>
                            <span>{{lang.get("delete selected")}} </span>
                        </button>
                    </mat-menu>
                    <div class="d-flex-item">{{lang.get("name")}}</div>
                    <div class="d-flex-item">{{lang.get("description")}}</div>
                </div>
                <mat-divider></mat-divider>
                <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                    <mat-tree-node *matTreeNodeDef="let node" class="border-bottom isNotPrent"  matTreeNodePadding>
                    <mat-checkbox [hidden]="!delete" class="tree-checkbox" [checked]="selectedItem[node.node.id]"
                                    (change)="checkChange($event, node.node)"
                                    [disabled]="node.node.noEditable"></mat-checkbox>
                        
                        <button mat-icon-button disabled></button>
                        <div class="d-flex full-width" matTreeNodePadding>
                            <div class="d-flex-item s-pad">
                                <a (click)="this.update ? itemClick(node.node) : doNothing()"
                                [ngClass]="{'': node.node.markAsGroup, 'cursor-pointer': this.update }">
                                    {{node.name}}
                                </a>
                            </div>
                            <div class="d-flex-item">{{node.node.desc}}</div>
                        </div>
                    </mat-tree-node>
                    <mat-tree-node *matTreeNodeDef="let node;when: hasChild" class="border-bottom isPrent" matTreeNodePadding>
                        <mat-checkbox class="tree-checkbox" [checked]="selectedItem[node.node.id]"
                                    (change)="checkChange($event, node.node)" [ngModel]="node.checked"></mat-checkbox>
                        <button mat-icon-button matTreeNodeToggle
                        [attr.aria-label]="'Toggle ' + node.name">
                            <mat-icon class="mat-icon-rtl-mirror">
                                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                            </mat-icon>
                        </button>
                        <div class="d-flex full-width" matTreeNodePadding>
                            <div class="d-flex-item s-pad">
                                <a (click)="this.update ? itemClick(node.node) : doNothing()"
                                [ngClass]="{'': node.node.markAsGroup, 'cursor-pointer': this.update }">
                                    {{node.name}}
                                </a>
                            </div>
                            <div class="d-flex-item">{{node.node.desc}}</div>
                        </div>
                    </mat-tree-node>
                </mat-tree>
            </mat-card-content>
        </div>
    </div>
</div>
</div>
</div>

<ng-template #competencyForm let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
         <h4 class="mat-title-modal">{{lang.get("competency")}}</h4>
        </div>
        <div class="mat-modal-body"> 
                <form class="form-material full-width-inputs" action="javascript:" [formGroup]="newForm">
                    <div class="row form-group">
                        <div class="col-md-12" *ngIf="!isEdit">
                            <mat-form-field>
                                <mat-label for="name">{{lang.get("name")}}</mat-label>
                                <input pattern= "*" type="text" matInput class="form-control" maxlength="40" required="" id="name" formControlName="name">
                               
                            </mat-form-field>    
                        </div>
                        <div class="col-md-12" *ngIf="!isEdit">
                            <mat-form-field>
                                <mat-label for="name">{{lang.get("description")}}</mat-label>
                                <input type="text" matInput class="form-control" maxlength="40"  id="description" formControlName="desc">
                            </mat-form-field>    
                        </div>

                        
                            
                         
                        <div class="col-md-12">
                            <mat-form-field *ngIf="!this.newForm.controls.markAsGroup.value">
                                <mat-label>{{lang.get("parent")}}</mat-label>
                                <mat-select disableOptionCentering formControlName="parentComp" class="form-control" >
                                  <mat-option *ngFor="let option of parentComp.fi | async" [value]="option">
                                    {{option.name}}
                                  </mat-option>
                                </mat-select>
                            </mat-form-field>                       
                        </div>

                       
                        <div class="col-md-12">
                        <mat-checkbox formControlName="markAsGroup" class="form-control">{{lang.get("markAsGroup")}} </mat-checkbox>
                    </div> 
                    </div>
                </form>
            <app-loading *ngIf="isSaving" [isLoading]="isSaving"></app-loading>
            <div class="d-flex justify-content-end">
                <button mat-raised-button color="primary" (click)="save()" *ngIf="!isSaving"
                        [disabled]="!newForm.valid"><i class="flaticon-diskette"></i>{{lang.get("save")}}
                </button>
            </div>
            <!-- </div> -->
        </div>
    </div>
</ng-template>

<ng-template #deleteUser let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">{{lang.get("delete")}} {{lang.get("competency")}} </h4>
        </div>
        <div class="mat-modal-body">
            <div class="text-left are-u">{{lang.get("areyousure")}}</div>
            <div class="row">
                <div class="multi-btns col-sm-12 text-right">
                    <button (click)="this.dialogRef.close();" class="btn-danger" mat-raised-button>
                        <i class="flaticon-cancel"></i>{{lang.get("cancel")}}
                    </button>
                    <button (click)="deleteSelected()" class="btn-primary" color="primary" mat-raised-button>
                        <i class="flaticon-trash"></i>{{lang.get("delete")}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
