import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router, NavigationEnd, Event } from '@angular/router';
import * as _ from 'underscore';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Output() readonly getNextPage = new EventEmitter();
  @Input() activePage;
  @Input() totalRecords;
  @Input() nextPageToken;
  @Input() selectedLeg;

  // pagination
  public paginators = [];
  public pagination: number;
  public paginationSize: number;
  public firstVisibleIndex = 1;
  public lastVisibleIndex = 5;
  public lastPageNo: number;
  public firstPageNo: number;
  public recordPerPage: number;
  public prevPageLabel: string;
  public nextPageLabel: string;

  constructor(private readonly router: Router) { }

  ngOnInit(): void {
    this.prevPageLabel = 'First';
    this.nextPageLabel = 'Last';
    this.recordPerPage = 10;
    console.log(this.totalRecords);
    this.firstPageNo = 1;
    this.lastPageNo = Math.ceil(this.totalRecords / this.recordPerPage);
    this.paginators = this.getPager(this.totalRecords, this.activePage, this.recordPerPage);
    this.router.events.subscribe((event: Event) => {
      this.activePage = 1;
      if (event instanceof NavigationEnd) {
        this.paginators = this.getPager(this.totalRecords, this.activePage, this.recordPerPage);
      }
    });
  }

  setRecordsPerPage(r) {
    this.recordPerPage = r;
    this.getNextPage.emit({
      activePage: this.activePage,
      firstVisibleIndex: this.firstVisibleIndex,
      lastVisibleIndex: this.lastVisibleIndex,
      pageSize: this.recordPerPage
    });
    this.paginators = this.getPager(this.totalRecords, this.activePage, this.recordPerPage);
  }
  nextPage(event: any): void {
    if (this.activePage === this.lastPageNo) {
      return;
    }
    this.activePage += 1;
    this.getNextPage.emit({
      activePage: this.activePage,
      firstVisibleIndex: this.firstVisibleIndex,
      lastVisibleIndex: this.lastVisibleIndex,
      pageSize: this.recordPerPage
    });
    this.paginators = this.getPager(this.totalRecords, this.activePage, this.recordPerPage);
  }

  previousPage(event: any): void {
    if (this.activePage === this.firstPageNo) {
      return;
    }
    this.activePage -= 1;
    this.getNextPage.emit({
      activePage: this.activePage,
      firstVisibleIndex: this.firstVisibleIndex,
      lastVisibleIndex: this.lastVisibleIndex,
      pageSize: this.recordPerPage
    });
    this.paginators = this.getPager(this.totalRecords, this.activePage, this.recordPerPage);
  }

  changePage(event: any): void {
    this.activePage = +event.target.text;
    this.getNextPage.emit({
      activePage: this.activePage,
      firstVisibleIndex: this.firstVisibleIndex,
      lastVisibleIndex: this.lastVisibleIndex,
      pageSize: this.recordPerPage
    });
    this.paginators = this.getPager(this.totalRecords, this.activePage, this.recordPerPage);
  }

  firstPage(): void {
    if (this.activePage === this.firstPageNo) {
      return;
    }
    this.activePage = this.firstPageNo;
    this.getNextPage.emit({
      activePage: this.activePage,
      firstVisibleIndex: this.firstVisibleIndex,
      lastVisibleIndex: this.lastVisibleIndex,
      pageSize: this.recordPerPage
    });
    this.paginators = this.getPager(this.totalRecords, this.activePage, this.recordPerPage);
  }

  lastPage(): void {
    if (this.activePage === this.lastPageNo) {
      return;
    }
    this.activePage = this.lastPageNo;
    this.getNextPage.emit({
      activePage: this.activePage,
      firstVisibleIndex: this.firstVisibleIndex,
      lastVisibleIndex: this.lastVisibleIndex,
      pageSize: this.recordPerPage
    });
    this.paginators = this.getPager(this.totalRecords, this.activePage, this.recordPerPage);
  }

  getPager(totalItems: number, currentPage: number = 1, pageSize: number = this.recordPerPage): Array<number> {

    const totalPages = Math.ceil(totalItems / pageSize);
    let startPage: number;
    let endPage: number;

    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage <= 3) {
      startPage = 1;
      endPage = 5;
    } else if (currentPage + 1 >= totalPages) {
      startPage = totalPages - 4;
      endPage = totalPages;
    } else if ((totalPages - (currentPage - 2)) === 5) {
      startPage = currentPage - 1;
      endPage = currentPage + 3;
    } else {
      startPage = currentPage - 2;
      endPage = currentPage + 2;
    }

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    const pages = _.range(startPage, endPage + 1);

    return pages;
  }
}
