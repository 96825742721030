<div id="header-page" class="ng-star-inserted header-page">
    <div class="page-header ng-star-inserted">
        <div class="page-block ng-star-inserted">
            <div class="row align-items-center">
                <div class="col-md-8">
                    <div class="page-header-title">
                        <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4
                            class="m-b-10 ng-star-inserted">{{name}}</h4></span>
                    </div>
                </div>
                <div class="col-md-4"></div>
            </div>
        </div>
    </div>
</div>

<div class="page-body no-title">
    <mat-card class="example-card">
        <mat-card-content>
            <app-loading *ngIf="isLoading" [isLoading]="isLoading"></app-loading>
            <div class="form-config-chart">
                <form class="form-material full-width-inputs" action="javascript:"
                      [formGroup]="courseForm"
                      *ngIf="!isLoading">
                    <div class="row form-group">
                        <div class="col-sm-6">
                            <mat-form-field class="form-element">
                                <mat-label>Name</mat-label>
                                <input readonly class="form-control" formControlName="sessionName" id="title"
                                       matInput maxlength="40"
                                        type="text">
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6">
                            <mat-form-field class="form-element">
                                <mat-label>Course</mat-label>
                                <input readonly class="form-control" formControlName="courseId" id="courseId"
                                       matInput maxlength="40"
                                       type="text">
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6">
                            <mat-form-field class="form-element">
                                <mat-label for="des">Start Date</mat-label>
                                <input readonly class="form-control" formControlName="startDate" id="startDate"
                                       matInput maxlength="40"
                                       type="text">
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6">
                            <mat-form-field class="form-element">
                                <mat-label for="endDate">End Date</mat-label>
                                <input readonly class="form-control" formControlName="endDate" id="endDate"
                                       matInput maxlength="40"
                                       type="text">
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6">
                            <mat-form-field class="form-element">
                                <mat-label>Delivery Location</mat-label>
                                <input readonly class="form-control" formControlName="deliveryLocation"
                                       id="deliveryLocation" matInput maxlength="40"
                                        type="text">
                            </mat-form-field>
                        </div>

                        <div class="col-sm-6">
                            <div class="row">
                                <div class="col-6">
                                    <mat-form-field class="form-element">
                                        <mat-label>delivery Method</mat-label>
                                        <input readonly class="form-control" formControlName="deliveryMethod"
                                               id="deliveryMethod" matInput maxlength="40"
                                               type="text">
                                    </mat-form-field>
                                </div>
                                <div class="col-6">
                                    <mat-form-field class="form-element">
                                        <mat-label>Status</mat-label>
                                        <input readonly class="form-control" formControlName="status"
                                               id="status" matInput maxlength="40"
                                               type="text">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <mat-form-field class="form-element">
                                <mat-label>Description</mat-label>
                                <textarea readonly class="form-control" matInput placeholder="Description"
                                          formControlName="description"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </form>
            </div>
        </mat-card-content>
    </mat-card>
</div>

<div class="page-body no-title" style="margin-top: 22px">
    <mat-card class="example-card">
        <mat-card-content>
            <app-loading *ngIf="isTrainerAdded" [isLoading]="isTrainerAdded"></app-loading>
            <div class="form-config-chart">
                <form class="form-material full-width-inputs" action="javascript:" [formGroup]="participantForm"
                      *ngIf="!isTrainerAdded">
                    <div class="row form-group">
                        <div class="col-sm-4">
                            <mat-form-field class="form-element">
                                <mat-label>participant</mat-label>
                                <input class="form-control" formControlName="participantName" id="participantName"
                                       matInput maxlength="40" readonly type="text">
                            </mat-form-field>
                        </div>
                        <div class="col-sm-4">
                            <mat-form-field class="form-element">
                                <mat-label>Rating </mat-label>
                                <mat-select
                                        required = ''
                                        [disabled]="isSessionStatus1"
                                        class="form-control"
                                        formControlName="rating">
                                    <mat-option value="poor">Poor</mat-option>
                                    <mat-option value="fair">Fair</mat-option>
                                    <mat-option value="good">Good</mat-option>
                                    <mat-option value="veryGood">VeryGood</mat-option>
                                    <mat-option value="excellent">Excellent</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-4">
                            <mat-checkbox style="padding-top: 25px" [checked]="isSessionStatus1" [disabled]="isSessionStatus1" formControlName="sessionStatus" >Session Status</mat-checkbox>
                        </div>

                        <div class="col-sm-12">
                            <mat-form-field class="form-element">
                                <mat-label>Comment </mat-label>
                                <textarea [readonly]="isSessionStatus1" class="form-control" matInput placeholder="Comment"
                                          formControlName="comment"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="action-buttons multi-btns col-sm-12 text-right">
                        <button mat-raised-button class="btn-danger" (click)="close()">
                            <i class="flaticon-cancel"></i>Cancel
                        </button>
                        <button mat-raised-button class="btn btn-primary" color="primary"
                                [disabled]="!participantForm.valid || isSessionStatus1"
                                (click)="submitAddUser()"><i class="flaticon-diskette"></i>Save
                        </button>
                    </div>
                </form>
            </div>
        </mat-card-content>
    </mat-card>
</div>


