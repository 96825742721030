<div id="header-page" class="ng-star-inserted header-page">
    <div class="page-header ng-star-inserted">
        <div class="page-block ng-star-inserted">
            <div class="row align-items-center">
                <div class="col-md-8">
                    <div class="page-header-title">
                        <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4
                            class="m-b-10 ng-star-inserted">Course</h4></span>
                    </div>
                </div>
                <div class="col-md-4"></div>
            </div>
        </div>
    </div>
</div>

<div class="page-body no-title">

    <mat-card class="example-card">
        <app-loading *ngIf="isLoading" [isLoading]="isLoading"></app-loading>
        <mat-card-header *ngIf="!isLoading">
            <mat-card-title>{{course}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="form-config-chart">
                <form class="form-material full-width-inputs" action="javascript:" [formGroup]="courseForm"
                      *ngIf="!isLoading">
                    <div class="row form-group">
                        <div class="col-sm-6">
                            <mat-form-field class="form-element">
                                <mat-label>Title</mat-label>
                                <input class="form-control" formControlName="title" id="title" matInput maxlength="40"
                                       required="" type="text">
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6">
                            <mat-form-field class="form-element">
                                <mat-label>Coordinator</mat-label>
                                <input required="" class="form-control" type="text"
                                       aria-label="Coordinator"
                                       matInput
                                       formControlName="coordinatorid"
                                       [matAutocomplete]="auto"

                                >
                                <mat-autocomplete #auto="matAutocomplete"
                                                  [displayWith]="displayEmployeeByName.bind(this)">
                                    <mat-option *ngFor="let r of filteredOptions " [value]="r.value">
                                        {{r.label}}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-error
                                        *ngIf="!courseForm.controls.coordinatorid.valid && courseForm.controls.coordinatorid.touched">
                                    please enter coordinator
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6">
                            <div class="row">
                                <div class="col-5">
                                    <mat-form-field class="form-element">
                                        <mat-select class="form-control"
                                                    formControlName="subUnitId"
                                                    disableOptionCentering>
                                            <mat-option value="">--Select--</mat-option>
                                            <ng-container
                                                    *ngFor="let j of temporaryDepartment">
                                                <mat-option
                                                        value="{{j.id}}">{{ j.name }}</mat-option>
                                            </ng-container>
                                        </mat-select>
                                        <mat-label for="des">Sub Unit</mat-label>
                                    </mat-form-field>
                                </div>
                                <div class="col-3">
                                    <mat-form-field class="form-element">
                                        <mat-select class="form-control"
                                                    formControlName="version" disableOptionCentering>
                                            <mat-option value=1>1</mat-option>
                                            <mat-option value=2>2</mat-option>
                                            <mat-option value=3>3</mat-option>
                                            <mat-option value=4>4</mat-option>
                                            <mat-option value=5>5</mat-option>
                                            <mat-option value=6>6</mat-option>
                                            <mat-option value=7>7</mat-option>
                                            <mat-option value=8>8</mat-option>
                                            <mat-option value=9>9</mat-option>
                                            <mat-option value=10>10</mat-option>
                                        </mat-select>
                                        <mat-label for="des">Version</mat-label>
                                    </mat-form-field>
                                </div>
                                <div class="col-4">
                                    <mat-form-field class="form-element">
                                        <mat-select class="form-control"
                                                    formControlName="subVersion" disableOptionCentering>
                                            <mat-option value=0>0</mat-option>
                                            <mat-option value=2>2</mat-option>
                                            <mat-option value=3>3</mat-option>
                                            <mat-option value=4>4</mat-option>
                                            <mat-option value=5>5</mat-option>
                                            <mat-option value=6>6</mat-option>
                                            <mat-option value=7>7</mat-option>
                                            <mat-option value=8>8</mat-option>
                                            <mat-option value=9>9</mat-option>
                                        </mat-select>
                                        <mat-label>Sub Version</mat-label>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="row">
                                <div class="col-6">
                                    <mat-form-field class="form-element">
                                        <mat-label>Currency</mat-label>
                                        <mat-select
                                                class="form-control"
                                                formControlName="currencyName">
                                            <mat-option *ngFor="let n of currencyList" [value]="n.name">
                                                {{ n.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-6">
                                    <mat-form-field class="form-element">
                                        <mat-label>Cost</mat-label>
                                        <input class="form-control" formControlName="cost" matInput maxlength="40"
                                               type="text">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <mat-form-field class="form-element">
                                <mat-label>Company</mat-label>
                                <input class="form-control" formControlName="companyName" matInput maxlength="40"
                                       type="text">
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6">
                            <div class="row">
                                <div class="col-sm-6">
                                    <mat-form-field class="form-element">
                                        <mat-label>duration</mat-label>
                                        <input class="form-control" formControlName="duration"
                                               [format]="24"
                                               [ngxTimepicker]="picker2" matInput>
                                        <ngx-material-timepicker #picker2></ngx-material-timepicker>
                                    </mat-form-field>
                                </div>

                                <div class="col-6">
                                    <mat-form-field class="form-element">
                                        <mat-label>Status</mat-label>
                                        <mat-select
                                                class="form-control"
                                                formControlName="status">
                                            <mat-option value="active">Active</mat-option>
                                            <mat-option value="archived">Archived</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12">
                            <mat-form-field class="form-element">
                                <mat-label>Description</mat-label>
                                <textarea class="form-control" matInput placeholder="Description"
                                          formControlName="description"></textarea>
                            </mat-form-field>
                        </div>

                    </div>
                    <div class="action-buttons multi-btns col-sm-12 text-right">
                        <button mat-raised-button class="btn-danger" (click)="close()">
                            <i class="flaticon-cancel"></i>Cancel
                        </button>
                        <button mat-raised-button class="btn btn-primary" color="primary"
                                [disabled]="!courseForm.valid ||!this.correct"
                                (click)="submitAddCourse()"><i class="flaticon-diskette"></i>Save
                        </button>
                    </div>
                </form>
            </div>
        </mat-card-content>
    </mat-card>

</div>