<div id="header-page" class="ng-star-inserted header-page">
    <div class="page-header ng-star-inserted">
        <div class="page-block ng-star-inserted">
        <div class="row align-items-center">
            <div class="col-md-8">
            <div class="page-header-title">
                <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted">{{lang.get("documentTemplates")}}</h4></span>
            </div>
            </div>
            <div class="col-md-4"></div>
        </div>
        </div>
    </div>
    </div>
<div class="page-body no-title">
    <div class="row">
        <div class="col-sm-12">
            <div class="row top-tools">
                <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6 left-tools">
                    <span class="export-icon-btn csv" (click)="exportToCsv()"><i class="material-icons">get_app</i><span>{{lang.get("csv")}}</span>
                </span>
                <span class="export-icon-btn pdf" (click)="saveAsPdf()"><i class="material-icons">get_app</i><span>{{lang.get("pdf")}}</span>
                </span>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
                    <div class="relative-position search-filter-con"> 
                      <span class="icon material-icons ng-tns-c114-0"> search </span>
                        <mat-form-field>
                          <input matInput #inputSearch type="search" [(ngModel)]="filterQuery" maxlength="40"
                                 (ngModelChange)="onModelChange(filterQuery)"
                                 class="form-control input-sm full-data-search">
                        </mat-form-field>
                        <span class="mytooltip cus-left tooltip-effect-4" [hidden]="!write">
                          <span class="tooltip-item">
                              <button mat-mini-fab class="btn btn-primary light add-rounded margin-button-05" (click)="addNewUser()">
                                  <i class="fa fa-plus"></i>
                              </button>
                          </span>
                          <span class="tooltip-content clearfix">
                          <span class="tooltip-text">{{lang.get("new")}}</span>
                          </span>
                       </span>
            
                      </div>
                </div>
            </div>
            <app-card [title]="'Document Template'">
                <div class="dt-responsive" *ngIf="dataSource">
                    <div class="table-responsive table hrm-table-style-1 full-data-table table-cols-3">
                        <mat-card-content>
                            <mat-table [dataSource]="dataSource" matSort>
                                <ng-container matColumnDef="select">
                                    <mat-header-cell *matHeaderCellDef [hidden]="!delete">
                                        <a [matMenuTriggerFor]="menu" class="check-all">
                                <span class="material-icons">
                                    more_vert
                                </span>
                                        </a>
                                        <mat-menu #menu="matMenu">
                                            <button mat-menu-item (click)="onSelectAllClicked()">{{lang.get("select all")}} </button>
                                            <button (click)="onDeSelectAllClicked()"
                                                    *ngIf="selectedCheckBoxList.length > 0" mat-menu-item> {{lang.get("deselect all")}}
                                            </button>
                                            <button (click)="onDeleteSelected()" *ngIf="selectedCheckBoxList.length > 0"
                                                    mat-menu-item>{{lang.get("delete selected")}} 
                                            </button>
                                        </mat-menu>
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let row" [hidden]="!delete">
                                        <mat-checkbox #matCheckbox (change)="onCheckBoxChange($event, row.id)"
                                                    value="{{row.id}}"></mat-checkbox>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="name">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{lang.get("templateName")}}</mat-header-cell>
                                    <mat-cell (click)="editUser(row.id)" class="{{this.editClass}}" *matCellDef="let row">
                                        {{ row.name }}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="category">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{lang.get("category")}}</mat-header-cell>
                                    <mat-cell (click)="editUser(row.id)" class="{{this.editClass}}" *matCellDef="let row">
                                        {{ row.category }}
                                    </mat-cell>
                                </ng-container>
                                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: columns;">
                                </mat-row>
                            </mat-table>
                            <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
                            <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                        </mat-card-content>
                    </div>
                    <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
                </div>
            </app-card>
        </div>
    </div>
</div>


<ng-template #addUser let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">
                <span *ngIf="!isEdit">{{lang.get("add")}}</span>
                <span *ngIf="isEdit">{{lang.get("edit")}}</span> {{lang.get("documentTemplate")}} 
               </h4>
        </div>
        <div class="mat-modal-body">
            <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>

            <form class="form-material full-width-inputs modal-scroll" action="javascript:" [formGroup]="userForm"
                  *ngIf="!isUserAdded">
                <div class="row form-group">
                    <div class="col-md-6">
                        <mat-form-field>
                            <mat-label>{{lang.get("name")}}</mat-label>
                            <input pattern="*" type="text" matInput class="form-control" maxlength="40"
                                   required="" id="name" formControlName="name">
                            <mat-error *ngIf="!userForm.controls.name.valid && userForm.controls.name.touched">
                                {{ validationError.required }}
                        </mat-error>
                    </mat-form-field> 
                    </div>

                    <div class="col-md-6">
                        <mat-form-field>
                            <mat-label >{{lang.get("description")}}</mat-label>
                            <input type="text" matInput class="form-control" maxlength="40" required="" id="des" formControlName="desc">
                            <mat-error   *ngIf="!userForm.controls.desc.valid && userForm.controls.desc.touched">
                                {{ validationError.required }}
                            </mat-error>
                        </mat-form-field> 
                    </div>
                </div>
                    <div class="row form-group">
                        <div class="col-md-12">
                            <span class="form-bar"></span>
                            <mat-label><h5>{{lang.get("template")}}</h5></mat-label>
                            <angular-editor formControlName="template" [config]="config"></angular-editor>
                            <span class="validation-error"
                                *ngIf="!userForm.controls.template.valid && userForm.controls.template.touched">{{ validationError.required }}</span>
                        </div>
                    </div>
                    <div class="row form-group">
                    <div class="col-md-6">
                        <mat-form-field>
                            <mat-label>Token</mat-label>
                            <mat-select disableOptionCentering formControlName="usedToken" (selectionChange)="placeToken()">
                                <mat-option *ngFor="let t of tokenList" [value]="t.id">
                                    {{t.name}}
                                </mat-option>
                              </mat-select>                            
                        </mat-form-field>    
                    </div>

                    <div class="col-md-6">
                        <mat-form-field>
                            <mat-label>Section</mat-label>
                            <mat-select disableOptionCentering formControlName="section">
                                <mat-option *ngFor="let s of sectionList" [value]="s.id">
                                    {{s.name}}
                                </mat-option>
                              </mat-select>                            
                            <mat-error  *ngIf="!userForm.controls.section.valid && userForm.controls.section.touched">
                                {{ validationError.required }}
                            </mat-error>
                        </mat-form-field>                       
                    </div>
                </div>
                    <ng-container formGroupName="pageProperty">
                        <div class="col-md-6">
                            <mat-form-field>
                                <mat-label>Page Size</mat-label>
                                <mat-select disableOptionCentering class="form-control" required="" formControlName="size" >
                                  <mat-option value="" disabled></mat-option>
                                  <mat-option value="A4">A4</mat-option>
                                  <mat-option value="A3">A3</mat-option>
                                  <mat-option value="B5">B5</mat-option>
                                </mat-select>
                            </mat-form-field>    
                        </div>
                        <div class="col-md-6">
                            <mat-form-field>
                                <mat-label>Margin left</mat-label>
                                <input type="number" matInput class="form-control"  maxlength="40" required="" id="ml" formControlName="ml">
                            </mat-form-field>                       
                        </div>
                        <div class="col-md-6">
                            <mat-form-field>
                                <mat-label>Margin Right</mat-label>
                                <input type="number" matInput class="form-control"  maxlength="40" required="" id="mr" formControlName="mr">
                            </mat-form-field>                       
                        </div>
                        <div class="col-md-6">
                            <mat-form-field>
                                <mat-label>Margin Top</mat-label>
                                <input type="number" matInput class="form-control" maxlength="40"  required="" id="mt" formControlName="mt" >
                            </mat-form-field>                       
                        </div>
                        <div class="col-md-6">
                            <mat-form-field>
                                <mat-label>Margin Bottom</mat-label>
                                <input type="number" matInput class="form-control" required="" id="mb" formControlName="mb" maxlength="40" 
                                       required="">
                            </mat-form-field>                       
                        </div>
                    </ng-container>
                <div class="row">
                    <div class="multi-btns col-md-12 text-right">
                        <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i> Cancel</button>
                        <button mat-raised-button class="btn-primary"  (click)="submitAddJobTitle()" color="primary" [disabled]="!userForm.valid"><i class="flaticon-diskette"></i> Save</button>
                    </div>
                </div>
            </form>
        </div>
    </div>

</ng-template>


<ng-template #deleteUser let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
         <h4 class="mat-title-modal">{{lang.get("delete")}} {{lang.get("documentTemplate")}} </h4>
        </div>
        <div class="mat-modal-body">
            <div class="text-left are-u">{{lang.get("areyousure")}}</div>
            <div class="row">
                <div class="multi-btns col-md-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i>
                        {{lang.get("cancel")}}
                    </button>
                    <button mat-raised-button class="btn-primary" (click)="deleteJobTitle()" color="primary"><i
                            class="flaticon-trash"></i> {{lang.get("delete")}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>



