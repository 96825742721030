import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { Observable } from "rxjs";
import { CrudService } from "src/app/core/services/base-crud.service";

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class PayrollService extends CrudService<any> {
  constructor(httpClient: HttpClient) {
    super(httpClient);
    this.setEntityName("payroll");
  }

  public generatePayslips(payrollId, month, year): Observable<any> {
    const url = `api/auth/payroll/generate`;
    return this.httpClient.get(`${API_URL}${url}?payrollId=${payrollId}&month=${month}&year=${year}`);
  }

  public getEmployeePayslips(empId, month, year): Observable<any> {
    const url = `api/auth/payroll/payslips`;
    return this.httpClient.get(`${API_URL}${url}?empId=${empId}&month=${month}&year=${year}`);
  }

  public getPayslipDetails(id): Observable<any> {
    const url = `api/auth/payroll/payslip`;
    return this.httpClient.get(`${API_URL}${url}/${id}`);
  }

  public getPayrollReport(payrollId, month, year): Observable<any> {
    const url = `api/auth/payroll/report`;
    return this.httpClient.get(`${API_URL}${url}?payrollId=${payrollId}&month=${month}&year=${year}`);
  }
}
