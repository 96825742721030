import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { SelectModule } from 'ng-select';
import { MatSelectModule } from '@angular/material/select';
import { MaterialModule } from '../module/material/material.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

import { InputTemplateComponent } from './input-template/input-template.component';
import { SelectTemplateComponent } from './select-template/select-template.component';
import { HeaderTemplateComponent } from './header-template/header-template.component';
import { ToolbarTemplateComponent } from './toolbar-template/toolbar-template.component';
import { NewTemplateComponent } from './new-template/new-template.component';
import { InputSearchTemplateComponent } from './input-search-template/input-search-template.component';

@NgModule({
  declarations: [
    InputTemplateComponent,
    SelectTemplateComponent,
    HeaderTemplateComponent,
    ToolbarTemplateComponent,
    NewTemplateComponent,
    InputSearchTemplateComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    SelectModule,
    MatSelectModule,
    MaterialModule,
    MatAutocompleteModule,
    MatSortModule,
    MatTableModule
  ],
  exports: [
    InputTemplateComponent,
    SelectTemplateComponent,
    HeaderTemplateComponent,
    ToolbarTemplateComponent,
    NewTemplateComponent,
    InputSearchTemplateComponent
  ]
})
export class TemplateModule { }
