import {Component, OnInit} from '@angular/core';
import {DashboardService} from '../../../dashboard.service';
import { NotificationService } from "src/app/core/services/notification.service";
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
    selector: 'app-optional-fields',
    templateUrl: './optional-fields.component.html',
    styleUrls: ['./optional-fields.component.scss']
})
export class OptionalFieldsComponent implements OnInit {
    body = {
        'id': null,
        'health': false,
        'preference': false
    };
    constructor(public service: DashboardService, private toastr: NotificationService,
        private authService : AuthService) {
    }

    public permissions : any;
    public read = false;
    public write = false ;
    public update = false;
    public delete = false;
   

    checkPermission(module,tabs) : void 
    {
        this.permissions = JSON.parse(this.authService.getPermissions());

        this.read = false ;
        this.write = false ;
        this.delete = false ;
        this.update = false;

        if(this.permissions != undefined && this.permissions != null )
        {
            for(let p of this.permissions)
            {
               
                if(p.id === module)
                {
                   
                    if(p.modules != undefined)
                    {
                      
                        for(let tab of p.modules)
                        {
                            if(tab.id === tabs)
                            {
                              
                                for(let move of tab.permits)
                                {
                                    if(move.id === "u" || move.id === "c")
                                    {
                                        this.update = true;
                                       
                                    }
                                    else if(move.id === "r")
                                    {
                                        this.read = true;
                                    }
                                   
                                    else if(move.id === "d")
                                    {
                                        this.delete = true;
                                    }
                                }
                            }
                        }
                    }
                   
                }
               
            }
        }
       

    }
    ngOnInit() {
        this.checkPermission("pdm","pd_cf");
        this.service.getOptionalFields().subscribe(res => {
            if (res) {
                this.body.health = res.data.health;
                this.body.preference = res.data.preference;
                this.body.id = res.data.id;
            }
        });
    }
    submit() {
            this.service.updateOptionalFields(this.body).subscribe(res => {
                if (res) {
                    this.toastr.success('Configuration update successfully...');
                }else {
                    this.toastr.error('Failed to update Configuration')
                }
            });
    }
}
