<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
      <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
          <div class="col-md-8">
          <div class="page-header-title">
              <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted">My Monthly Attendance</h4></span>
          </div>
          </div>
          <div class="col-md-4"></div>
      </div>
      </div>
  </div>
</div>
