<div id="header-page" class="ng-star-inserted header-page">
    <div class="page-header ng-star-inserted">
        <div class="page-block ng-star-inserted">
            <div class="row align-items-center">
                <div class="col-md-8">
                    <div class="page-header-title">
                        <span class="ng-star-inserted"></span><span class="ng-star-inserted">
                            <h4 class="m-b-10 ng-star-inserted">{{updateId? 'Update': 'Add'}} Asset</h4>
                        </span>
                    </div>
                </div>
                <div class="col-md-4"></div>
            </div>
        </div>
    </div>
</div>
<app-loading [isLoading]="this.isLoading"></app-loading>
<mat-card class="add-assets" [hidden]="this.isLoading">
    <!-- <mat-card-header class="d-flex justify-content-between">
        <mat-card-title>
            {{updateId? 'Update': 'Add'}} Asset
        </mat-card-title>
    </mat-card-header> -->
    <form [formGroup]="newForm" class="form-material full-width-inputs">
        <mat-card-content>
            <div class="row form-group">
                <div class="col-md-8">
                    <div class="column">
                        <div class="row form-group">
                            <div class="col-md-6">
                                <mat-form-field class="form-element">
                                    <mat-label>Asset Id</mat-label>
                                    <input [readonly]="!this.update" required matInput class="form-control"
                                        formControlName="assetId">
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field class="form-element">
                                    <mat-label>Serial Number</mat-label>
                                    <input [readonly]="!this.update" matInput class="form-control"
                                        formControlName="serialNumber">
                                </mat-form-field>
                            </div>

                        </div>
                        <div class="row form-group">
                            <div class="col-md-6">
                                <mat-form-field class="form-element">
                                    <mat-label>Brand</mat-label>
                                    <input [readonly]="!this.update" placeholder="Type for hints .."
                                        class="form-control" type="text" aria-label="Brand" matInput
                                        formControlName="brandId" [matAutocomplete]="auto1">
                                    <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displayBrand.bind(this)">
                                        <mat-option *ngFor="let r of filteredOptions2 " [value]="r.value">
                                            {{r.label}}
                                        </mat-option>
                                    </mat-autocomplete>

                                </mat-form-field>
                            </div>
                            <div class="col-md-6">

                                <mat-form-field class="form-element">
                                    <mat-label>Vendor</mat-label>
                                    <input [readonly]="!this.update" placeholder="Type for hints .."
                                        class="form-control" type="text" aria-label="Vendor" matInput
                                        formControlName="vendorId" [matAutocomplete]="auto2">
                                    <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayVendor.bind(this)">
                                        <mat-option *ngFor="let r of filteredOptions " [value]="r.value">
                                            {{r.label}}
                                        </mat-option>
                                    </mat-autocomplete>

                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="col-md-4">
                    <div class="column">
                        <div class="row form-group">
                            <div class="col-md-3" [hidden]="!update">
                                <div *ngIf="this.updateId && !this.newForm.value.hasAttachement"
                                    (click)="onAddAttachment()" matTooltip="No Attachments" class="icon cursor-pointer">
                                    <mat-icon> attach_file</mat-icon>
                                </div>
                                <div class="in-stock-icon" *ngIf="this.updateId && this.newForm.value.hasAttachement"
                                    (click)="onAddAttachment()" matTooltip="Include Attachments"
                                    class="icon cursor-pointer">
                                    <mat-icon> attach_file</mat-icon>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div matTooltip="Upload image" (click)="openUploadDialog()" class="grid cursor-pointer">

                                    <div>
                                        <img src="{{this.imageSrc}}" alt="image">
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>






            <div class="row form-group">

                <div class="col-md-4">
                    <mat-form-field class="form-element">
                        <mat-label>Model</mat-label>
                        <input required [readonly]="!this.update" matInput class="form-control" formControlName="model">
                    </mat-form-field>
                </div>
                <div class="col-md-4">

                    <!-- <mat-form-field class="form-element">
                        <mat-label>Category</mat-label>
                        <input required [readonly]="!this.update" placeholder="Type for hints .." class="form-control"
                            type="text" aria-label="Category" matInput formControlName="categoryId"
                            [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayCategory.bind(this)">
                            <mat-option *ngFor="let r of filteredOptions3 " [value]="r.value">
                                {{r.label}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field> -->

                    <mat-form-field class="form-element">
                        <mat-label>Category</mat-label>
                        <mat-select required disableOptionCentering class="form-control" formControlName="categoryId">
                            <!-- <mat-option value="">All</mat-option> -->
                            <mat-option *ngFor="let r of categories " [value]="r.value">
                                {{r.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>

                <div class="col-md-4">

                    <mat-form-field class="form-element">
                        <mat-label>Location</mat-label>
                        <mat-select required [disabled]="!this.update" disableOptionCentering class="form-control"
                            formControlName="locationId">
                            <mat-option *ngFor="let n of locations" [value]="n.value">{{n.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>



                </div>
            </div>


            <div class="row form-group" *ngIf="this.updateId">
                <div class="col-md-4">

                    <mat-form-field class="form-element">
                        <mat-label>Status</mat-label>
                        <mat-select [disabled]="!this.update" disableOptionCentering class="form-control"
                            formControlName="status">
                            <mat-option (click)="callChangeStatus(s.value)" *ngFor="let s of statusList"
                                [hidden]="s.hidden" [value]="s.value">{{s.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div *ngIf="this.newForm.value.status === 'InUse'" class="col-md-4">


                    <mat-form-field class="form-element">
                        <mat-label>Assigned To</mat-label>
                        <input readonly required matInput class="form-control" formControlName="emp">
                    </mat-form-field>

                </div>

                <div *ngIf="this.newForm.value.status === 'InUse' " class="col-md-4">


                    <mat-form-field class="form-element">
                        <input readonly matInput class="form-control" [matDatepicker]="pickerAcquire2"
                            placeholder="From" formControlName="assignedFrom">
                        <mat-datepicker-toggle disabled matSuffix [for]="pickerAcquire2"></mat-datepicker-toggle>
                        <mat-datepicker #pickerAcquire2></mat-datepicker>
                    </mat-form-field>

                </div>
            </div>
            <!-- </div> -->
            <div class="row form-group">
                <!-- <div class="row"> -->
                <div class="col-md-4">
                    <mat-form-field class="form-element">
                        <input [readonly]="!this.update" matInput class="form-control" [matDatepicker]="pickerAcquire"
                            placeholder="Date of purchase" formControlName="aquired">
                        <mat-datepicker-toggle [disabled]="!this.update" matSuffix [for]="pickerAcquire">
                        </mat-datepicker-toggle>
                        <mat-datepicker #pickerAcquire></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field class="form-element">
                        <input [readonly]="this.update" matInput [matDatepicker]="pickerWarrantyStart"
                            placeholder="Warranty starts" class="form-control" formControlName="warrantyStarts">
                        <mat-datepicker-toggle [disabled]="!this.update" matSuffix [for]="pickerWarrantyStart">
                        </mat-datepicker-toggle>
                        <mat-datepicker #pickerWarrantyStart></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field class="form-element">
                        <input [readonly]="!this.update" matInput [matDatepicker]="pickerWarrantyEnd"
                            placeholder="Warranty end" class="form-control" formControlName="warrantyEnd">
                        <mat-datepicker-toggle [disabled]="!this.update" matSuffix [for]="pickerWarrantyEnd">
                        </mat-datepicker-toggle>
                        <mat-datepicker #pickerWarrantyEnd></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="row form-group">

                <div class="col-md-12">
                    <mat-form-field class="form-element">
                        <mat-label>Description</mat-label>
                        <textarea [readonly]="!this.update" class="form-control" maxlength="50" matInput
                            formControlName="description" name="name"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
    </form>
    <app-loading *ngIf="isSaving || isLoadForm" [isLoading]="isSaving || isLoadForm"></app-loading>

    <mat-card-actions *ngIf="!isSaving && !isLoadForm">
        <div class="row">
            <div class="col-sm-12 text-right multi-btns action-buttons">
                <button mat-raised-button class="btn-danger" (click)="cancel()" *ngIf="!isSaving"><i
                        class="flaticon-cancel"></i>Cancel</button>
                <button [disabled]="!this.newForm.valid" mat-raised-button color="primary" class="btn-primary"
                    (click)="save()" *ngIf="!updateId"><i class="flaticon-diskette"></i>Save</button>
                <button [disabled]="!this.newForm.valid" mat-raised-button color="primary" (click)="save()"
                    class="btn-primary" *ngIf="updateId && this.update"><i class="flaticon-diskette"></i>Update</button>
            </div>
        </div>
    </mat-card-actions>
</mat-card>

<!--<mat-card class="add-assets" *ngIf="updateId || false">-->
<!--<mat-card-title>-->
<!--Status-->
<!--</mat-card-title>-->
<!--<form [formGroup]="statusForm">-->
<!--<div class="row">-->
<!--<div class="col-4">-->
<!--<mat-form-field>-->
<!--<mat-label>Status</mat-label>-->
<!--<mat-select>-->
<!--<mat-option *ngFor="let item of statusOption" [value]="item.id">-->
<!--{{item.name}}-->
<!--</mat-option>-->
<!--</mat-select>-->
<!--</mat-form-field>-->
<!--</div>-->
<!--<div class="col-4">-->
<!--<mat-form-field class="example-full-width">-->
<!--<input type="text" placeholder="Employee" aria-label="Employee" matInput formControlName="employee"-->
<!--[matAutocomplete]="employeeAuto">-->
<!--<mat-autocomplete #employeeAuto="matAutocomplete" [displayWith]="displayFn">-->
<!--<mat-option *ngFor="let option of category.fi | async" [value]="option">-->
<!--{{option.name}}-->
<!--</mat-option>-->
<!--</mat-autocomplete>-->
<!--</mat-form-field>-->
<!--</div>-->
<!--<div class="col-4">-->
<!--<mat-form-field class="example-full-width">-->
<!--<input matInput [matDatepicker]="Assign From" placeholder="Assign From"-->
<!--formControlName="assignFromDate">-->
<!--<mat-datepicker-toggle matSuffix [for]="pickerAssignFrom"></mat-datepicker-toggle>-->
<!--<mat-datepicker #pickerAssignFrom></mat-datepicker>-->
<!--</mat-form-field>-->
<!--<mat-form-field class="example-full-width">-->
<!--<input matInput [matDatepicker]="Assign To" placeholder="Assign To" formControlName="assignToDate">-->
<!--<mat-datepicker-toggle matSuffix [for]="pickerAssignTo"></mat-datepicker-toggle>-->
<!--<mat-datepicker #pickerAssignTo></mat-datepicker>-->
<!--</mat-form-field>-->
<!--</div>-->
<!--</div>-->
<!--</form>-->
<!--<mat-card-actions *ngIf="!isStatusUpdating">-->
<!--<div class="d-flex justify-content-end">-->
<!--<button mat-raised-button color="primary" (click)="updateAssign()" *ngIf="updateId">Update</button>-->
<!--</div>-->
<!--</mat-card-actions>-->
<!--</mat-card>-->

<app-asset-assign-history [hidden]="!this.update" [assetsId]="this.updateId" *ngIf="updateId && !isLoading">
</app-asset-assign-history>



<ng-template #uploadImage let-modal>
    <div class="mat-modal-header">
        <h4 class="mat-title-modal">Update Profile Picture</h4>
    </div>
    <app-loading *ngIf="imageUploading" [isLoading]="true"></app-loading>
    <div class="mat-modal-body" *ngIf="!imageUploading">

        <form [formGroup]="uploadForm">

            <div class="preview" *ngIf="imgFile">
                <img [src]="imgFile" style="width:400px; height:250px">
            </div>


            <div class="form-group" [hidden]="!this.update">
                <label for="file">File</label>
                <input required type="file" class="form-control" id="file" formControlName="file"
                    (change)="onImageChange($event)">
            </div>


        </form>

        <div class="row my-2 mx-2">
            <div class="action-buttons multi-btns col-sm-12 text-right">
                <button (click)="closeModal()" class="btn-danger" mat-raised-button><i
                        class="flaticon-cancel"></i>Cancel
                </button>
                <button [hidden]="!canDeleteImage || !this.update " (click)="deleteImage()" class="btn-danger"
                    mat-raised-button>Remove Image
                </button>

                <button [hidden]="!this.update" [disabled]="!this.uploadForm.valid" mat-raised-button
                    class="btn btn-primary" color="primary" (click)="upload()"> Upload File</button>
            </div>
        </div>

    </div>


</ng-template>


<ng-template #assignAssest let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">
                Assign Asset
            </h4>
        </div>
        <div class="mat-modal-body">
            <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>
            <form *ngIf="!isUserAdded" [formGroup]="statusForm" action="javascript:"
                class="form-material full-width-inputs">
                <div class="row form-group">


                    <div class="col-sm-6">
                        <mat-form-field class="form-element">
                            <mat-label>Employee</mat-label>
                            <input placeholder="Type for hints .." required="" class="form-control" type="text"
                                aria-label="Employee" matInput formControlName="assignedToId" [matAutocomplete]="auto">
                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayEmployees.bind(this)">
                                <mat-option *ngFor="let r of filteredOptions4 " [value]="r.value">
                                    {{r.label}}
                                </mat-option>
                            </mat-autocomplete>

                        </mat-form-field>
                    </div>

                    <div class="col-md-6">
                        <mat-form-field class="form-element">
                            <input required matInput [matDatepicker]="picker3" placeholder="From" class="form-control"
                                formControlName="date">
                            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                            <mat-datepicker #picker3></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
            </form>
            <div class="row my-2 mx-2">
                <div class="action-buttons multi-btns col-sm-12 text-right">
                    <button (click)="closeModal()" class="btn-danger" mat-raised-button><i
                            class="flaticon-cancel"></i>Cancel
                    </button>
                    <button [disabled]="!this.statusForm.valid" mat-raised-button class="btn btn-primary"
                        color="primary" (click)="callChangeStatuss()"> Confirm</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #returnAssest let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">
                Return Asset
            </h4>
        </div>
        <div class="mat-modal-body">
            <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>
            <form *ngIf="!isUserAdded" [formGroup]="statusForm" action="javascript:"
                class="form-material full-width-inputs">
                <div class="row form-group">


                    <div class="col-md-6">
                        <mat-form-field class="form-element">
                            <input required matInput [matDatepicker]="picker3" placeholder="Returned Date"
                                class="form-control" formControlName="date">
                            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                            <mat-datepicker #picker3></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
            </form>
            <div class="row my-2 mx-2">
                <div class="action-buttons multi-btns col-sm-12 text-right">
                    <button (click)="closeModal()" class="btn-danger" mat-raised-button><i
                            class="flaticon-cancel"></i>Cancel
                    </button>
                    <button [disabled]="!this.statusForm.value.date" mat-raised-button class="btn btn-primary"
                        color="primary" (click)="callChangeStatuss()"> Confirm</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #attach let-modal>
    <div class="mat-modal-header">
        <h4 class="mat-title-modal">
            Attachment
        </h4>
    </div>

    <div class="mat-modal-body">
        <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>
        <form [hidden]="isUserAdded" class="form-material full-width-inputs">
            <div class="form-row">
                <div class="col-sm-12">
                    <div class="upload-btn-wrapper">
                        <button class="upload-btn">Attach</button>
                        <input (change)="fileChange($event, 'dd')" class="form-control" name="myfile" type="file">
                        <div class="fileName">{{this.fileObj && this.fileObj.fileName}}<i *ngIf="fileUrl"
                                class="fa fa-check success"></i>
                        </div>
                    </div>
                    <div class="upload-btn-wrapper">
                    </div>
                    <div *ngIf="!fileUrl && fileError" class="file-error text-danger">{{fileError}}</div>
                    <div class="help-text">Accept upto 2MB</div>
                </div>
                <div class="col-sm-12">
                    <mat-form-field class="form-element">
                        <input [(ngModel)]="comment" class="form-control" matInput name="name" maxlength="40"
                            type="text">
                        <span class="form-bar"></span>
                        <mat-label for="title">Comment</mat-label>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 mt-1 border" style="height: 200px !important; overflow-y: auto">
                    <mat-card *ngIf="this.attachmentList.length === 0">No Attachments
                        Found
                    </mat-card>
                    <ng-container *ngFor="let attachment of attachmentList; let i = index;">
                        <mat-card class="mt-2 shadow-sm">
                            <mat-card-title>
                                {{attachment.fileName}}
                            </mat-card-title>
                            <mat-card-content>
                                <b>Added On:</b> {{attachment.added}} <br>
                                <b>Comments:</b> {{attachment.desc}}
                            </mat-card-content>
                            <mat-card-actions align="end">
                                <button (click)="onDeleteClicked(attachment)"
                                    aria-label="Example icon button with a vertical three dot icon" mat-icon-button>
                                    <mat-icon color="warn">delete</mat-icon>
                                </button>
                                <button (click)="onDownloadClicked(attachment)"
                                    aria-label="Example icon button with a vertical three dot icon" mat-icon-button>
                                    <mat-icon color="primary">cloud_download</mat-icon>
                                </button>
                            </mat-card-actions>
                        </mat-card>
                    </ng-container>
                </div>
                <div class="action-buttons multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="closeModal()">
                        <i class="flaticon-cancel"></i> Cancel</button>
                    <button mat-raised-button class="btn btn-primary" color="primary" (click)="saveAttachment()"><i
                            class="flaticon-diskette"></i> Save</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>