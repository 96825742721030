import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {DashboardService} from '../../../dashboard.service';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import { NotificationService } from "src/app/core/services/notification.service";
import { LanguageConfig } from 'src/app/Language-config';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-add-document-template',
  templateUrl: './add-document-template.component.html',
  styleUrls: ['./add-document-template.component.scss']
})
export class AddDocumentTemplateComponent implements OnInit {
  public filterQuery = '';
  public userList: Array<any>;
  public isLoading: boolean;
  public isUserAdded: boolean;
  public err: string;
  public dialogRef: any;
  public userForm: FormGroup;
  public isEdit: boolean;
  public editId: string;
  public sectionList: Array<any>;
  public tokenList: Array<any>;
  public loadData: boolean = false;
  public lang = new Map();
  selectedUsers: any[] = [];
  selectedCheckBoxList = [];
  @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  public columns = ['select' , 'name', 'desc', 'addToString', 'section', 'edit' ];
  public validationError = {
    required: 'Required',
    email: 'Please provide a valid email',
    password: 'Please provide a password of atleast 8 digits',
    postalcode: 'Should be of 6 digits',
    alphabets: 'Should contain only alphabets'
  };
  public config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '412px',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  @ViewChild('addUser', {static: false}) addUser;
  @ViewChild('deleteUser', {static: false}) deleteUser;

  constructor(private service: DashboardService,
              private dialog: MatDialog,
              private route: Router,
              private activatedRoute: ActivatedRoute,
              private fb: FormBuilder, 
              private toastr: NotificationService,
              private authService : AuthService
              ) {
    this.createForm();
    this.lang= LanguageConfig.map;
  }


  public sortDirection = 'asc';

  public tableConfig = {
    name: true,
    des: true,
    category: true
  };

  public permissions : any;
  public read = false;
  public write = false ;
  public update = false;
  public delete = false;
 
  public editClass = "" ;

  checkPermission(module,tabs) : void 
  {
    
      this.permissions = JSON.parse(this.authService.getPermissions());

      this.read = true ;
      this.write = true ;
      this.delete = true ;
      
      if(this.permissions != undefined && this.permissions != null )
      {
          for(let p of this.permissions)
          {
             
              if(p.id === module)
              {
                 
                  if(p.modules != undefined)
                  {
                    
                      for(let tab of p.modules)
                      {
                          if(tab.id === tabs)
                          {
                            
                              for(let move of tab.permits)
                              {
                                  if(move.id === "u")
                                  {
                                      this.update = true;
                                  }
                                  else if(move.id === "r")
                                  {
                                      this.read = true;
                                  }
                                  else if(move.id === "c")
                                  {
                                    
                                      this.write = true;
                                  }
                                  else if(move.id === "d")
                                  {
                                      this.delete = true;
                                  }
                              }
                          }
                      }
                  }
                 
              }
              
          }
      }
  
       
    

  }
  ngOnInit() {
    this.isEdit = false;
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.loadData = true;
        this.editUser(params.id);
        this.update = false;
        this.checkPermission("admin","ad_jb");
        this.isEdit = true;
      }
      else 
      {
        this.update = true;
      }
    });
    this.isLoading = true;
    this.isUserAdded = false;
  }

  public getSectionList(): void {
    this.service.getDocumentTemplateForm().subscribe(resp => {
      if (resp && resp.data) {
        this.sectionList = resp.data.sections;
        this.tokenList = resp.data.tokens;
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error in getting form detail');
    });
  }

  public placeToken(): void {
    const usedToken = this.userForm.value.usedToken;
    const val = this.userForm.value;
    val.template = val.template ? `${val.template} ${usedToken}` : `${usedToken}`;
    this.userForm.patchValue(val);
  }

  public createForm(): void {
    this.userForm = this.fb.group({
      name: [''],
      template: [''],
      category: [''],
    });
  }

  public addNewUser() {
    this.route.navigate([`admin/job/document-template/add`]);
  }

  public close(): void {
    this.route.navigate([`admin/job/document-template`]);
  }

  public getDocumentTemplateList(): void {
    this.service.getDocumentTemplateList().subscribe(user => {
      console.log('User');
      if (user && user.data) {
        this.isLoading = false;
        this.dataSource = new MatTableDataSource<any>(user.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.err = '';
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.isLoading = false;

      this.toastr.error(err || err);
      this.err = 'Error while getting document template details';
    });
  }

  public submitAddJobTitle(): void {
    console.log(this.userForm.value);
    if (this.isEdit) {
      this.edit();
    } else {
      this.add();
    }
  }

  public add(): void {
    this.isUserAdded = true;
    delete this.userForm.value.usedToken;
    this.service.addDocumentTemplate(this.userForm.value).subscribe(user => {
      this.isUserAdded = false;
      this.toastr.success('Document Template added successfully');
      // this.dialogRef.close();
      this.userForm.reset();
      // this.getDocumentTemplateList();
      this.route.navigate([`admin/job/document-template`]);
    }, error => {
      const err = error.error && error.error.msg;
      this.isUserAdded = false;
      this.toastr.error(err || 'Error in adding Document Template.Please try again.');
    });
  }

  public edit(): void {
    this.isUserAdded = true;
    delete this.userForm.value.usedToken;
    this.service.editDocumentTemplate(this.userForm.value, this.editId).subscribe(user => {
      this.isUserAdded = false;
      this.route.navigate([`admin/job/document-template`]);
      this.toastr.success('Document Template updating successfully');
      // this.dialogRef.close();
      // this.getDocumentTemplateList();
     
    }, error => {
      const err = error.error && error.error.msg;
      this.isUserAdded = false;
      this.toastr.error(err || 'Error in updating Document Template.Please try again.');
    });
  }

  public editUser(id): void {
    this.isEdit = true;
    this.service.getDocumentTemplateById(id).subscribe(user => {
      if (user && user.data) {
        this.userForm.patchValue(user.data);
        // this.dialogRef = this.dialog.open(this.addUser, {
        //   width: '560px',
        //   height: 'auto'
        // });
        this.editId = id;
        this.loadData = false;
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error getting document template details');
    });
  }

  public deleteJobTitle(): void {
    this.service.deleteMultipleDocumentTemplate(this.selectedCheckBoxList).subscribe(user => {
      if (user) {
        this.close();
        this.toastr.success('Successfully Deleted..');
        this.getDocumentTemplateList();
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.close();
      this.toastr.error(err || 'Error getting document template detail');
    });
    this.selectedCheckBoxList = [];
  }

  onCheckBoxChange($event: MatCheckboxChange, id: any) {
    if (!this.alreadySelected($event.source) && $event.checked) {
      this.selectedCheckBoxList.push($event.source.value);
    } else if (!$event.checked) {
      this.selectedCheckBoxList = this.selectedCheckBoxList.filter(
          value => {
            return value !== $event.source.value;
          }
      );
    }
  }

  alreadySelected(item: MatCheckbox): boolean {
    this.selectedCheckBoxList.forEach(
        value => {
          if (item === value) {
            return true;
          }
        }
    );
    return false;
  }

  onSelectAllClicked() {
    this.selectedCheckBoxList = [];
    this.matCheckBoxes.forEach(
        item => {
          this.selectedCheckBoxList.push(item.value);
          item.checked = true;
        }
    );
  }

  onDeSelectAllClicked() {
    this.selectedCheckBoxList = [];
    this.matCheckBoxes.forEach(
        item => {
          item.checked = false;
        }
    );
  }

  onDeleteSelected() {
    this.dialogRef = this.dialog.open(this.deleteUser, {
      width: '440px',
      height: 'auto'
    });
  }
  onModelChange(value: string) {
    console.log(value);
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
}
