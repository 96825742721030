import * as _ from 'lodash';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'dataFilter'
})

export class DataFilterPipe implements PipeTransform {

    transform(array: any[], query: string, search: string): any {
        if (query) {
            // array.map(a => {
            //   a[search] = a[search].toLowerCase();
            // });
            return _.filter(array, row => row[search] && row[search].toLowerCase().indexOf(query.toLowerCase()) > -1);
        }
        return array;
    }
}
