import { Component, NgZone, OnInit, ViewChild } from "@angular/core";
import { DashboardService } from "../../../dashboard.service";
import { NotificationService } from "src/app/core/services/notification.service";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { saveAs } from "file-saver-es";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort, Sort } from "@angular/material/sort";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { Router } from "@angular/router";
import { startWith } from "rxjs/operators";

@Component({
  selector: "app-leave-usage",
  templateUrl: "./leave-usage.component.html",
  styleUrls: ["./leave-usage.component.scss"],
})
export class LeaveUsageComponent implements OnInit {
  public filterForm: FormGroup;
  // public employeeList = [];
  public leaveTypeList = [];
  public jobTitleList = [];
  public locationList = [];
  public subUnitList = [];
  public leavePeriodList = [];
  // public leaveUsageList = [];
  // public rowsOnPage = 100;
  // public filterQuery = '';
  public isLoading: boolean;
  // public sortOrder = 'desc';
  // public sortBy = '';
  public err: string;
  public isExpanded: boolean = true;
  public generateFirst: boolean = false;
  public period: string = "";
  public periodEnum: string = "";
  public next: boolean = true;
  public prev: boolean = true;
  // empCtrl1 = new FormControl();
  // public employeeId = "";

  // @ViewChild(MatSort, { static: false }) sort: MatSort;
  // @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  // paging = {
  //   pageSize: 10,
  //   totalRecords: 0,
  //   pageIndex: 0,
  //   pageSizeOptions: [10, 25, 50, 100]
  // };

  public columns = [
    "employeeId",
    "employeeName",
    "leaveTypeName",
    "leavePeriod",
    "entitlements",
    "pending",
    "scheduled",
    "taken",
    "available",
    "overdrawn",
    "netBalance",
  ];

  pageSize: number = 10;
  pageIndex: number = 0;
  total: number = 0;

  public selectedEmployee: any;
  public submitLoading: boolean;

  public sortBy = [];

  constructor(
    private router: Router,
    private ngZone: NgZone,
    public toastr: NotificationService,
    public service: DashboardService,
    public fb: FormBuilder
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.selectedEmployee = { id: "", name: "" };

    this.period = " Current Period";
    this.periodEnum = "cp";
    this.next = true;
    this.prev = true;

    this.service.getJobTitleList().subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.jobTitleList = resp.data;
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
      }
    );

    this.service.getLeaveTypes().subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.leaveTypeList = resp.data;
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
      }
    );

    this.service.getLocationList().subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.locationList = resp.data;
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
      }
    );

    this.service.getStructureList().subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.subUnitList = resp.data;
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
      }
    );

    this.service.getLeavePeriodForLeaveUsage().subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.leavePeriodList = resp.data;
          this.filterForm.patchValue({
            leavePeriodId: this.leavePeriodList[1].id,
          });
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
      }
    );
  }

  public createForm(): void {
    this.filterForm = this.fb.group({
      employeeId: "",
      eligibleEmployeeOnly: false,
      includePastEmployees: false,
      jobTitleId: "",
      leaveTypeId: "",
      locationId: "",
      subUnitId: "",
      generateFor: "",
      leavePeriodId: "",
    });
  }

  public getLeaveUsage(): void {
    this.isLoading = true;
    this.filterForm.patchValue({
      employeeId: this.selectedEmployee.id,
    });

    this.generateFirst = true;
    this.err = "";

    this.service.getLeaveUsage(this.filterForm.value, this.pageIndex, this.pageSize, this.sortBy).subscribe(
      (resp) => {
        if (resp && resp.data && resp.data.totalElements > 0) {
          this.total = resp.data.totalElements;
          this.dataSource.data = resp.data.content;
        } else {
          this.dataSource.data = [];
          this.toastr.error("No search result found");
        }
        this.isLoading = false;
      },
      (error) => {
        this.toastr.error("Error in getting leave usage");
        this.isLoading = false;
      }
    );
  }

  downloadFile = (data): void => {
    const blob = new Blob([data], { type: "text/csv" });
    saveAs(blob, "leaveusage.csv");
  };

  public generateCSV(): void {
    this.filterForm.patchValue({
      employeeId: this.selectedEmployee.id,
    });
    this.isLoading = true;
    this.service.exportLeaveUsage(this.filterForm.value).subscribe(
      (resp) => {
        if (resp) {
          this.downloadFile(resp);
        }
        this.isLoading = false;
      },
      (error) => {
        this.toastr.error("Error in getting leave usage");
        this.isLoading = false;
      }
    );
  }

  public getData(label, value, data): any {
    const arr = [];
    data.map((d) => {
      const obj = {};
      obj["label"] = d[label];
      obj["value"] = d[value];
      arr.push(obj);
    });

    return arr;
  }

  handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getLeaveUsage();
  }

  sortData(sort: Sort) {
    if (sort.direction) {
      this.sortBy = [{ sortBy: sort.active, sortDir: sort.direction }];
    } else {
      this.sortBy = [];
    }
    this.getLeaveUsage();
  }

  public onNext(): void {
    if (this.periodEnum === "pr") {
      this.period = " Current Period";
      this.periodEnum = "cp";
      this.next = true;
      this.prev = true;
      this.getLeaveUsage();
    } else if (this.periodEnum === "cp") {
      this.period = "Next Period";
      this.periodEnum = "np";
      this.next = false;
      this.prev = true;
      this.getLeaveUsage();
    } else if (this.periodEnum === "np") {
      this.period = "Next Period";
      this.periodEnum = "np";
      this.next = false;
      this.prev = true;
    }
  }

  public onPrev(): void {
    if (this.periodEnum === "pr") {
      this.period = " Previous Period";
      this.periodEnum = "pr";
      this.prev = false;
      this.next = true;
    } else if (this.periodEnum === "cp") {
      this.period = " Previous Period";
      this.periodEnum = "pr";
      this.prev = false;
      this.next = true;
      this.getLeaveUsage();
    } else if (this.periodEnum === "np") {
      this.period = " Current Period";
      this.periodEnum = "cp";
      this.prev = true;
      this.next = true;
      this.getLeaveUsage();
    }
  }

  public openTimeLine(empId, LeaveId): void {
    this.ngZone.run(() => {
      void this.router.navigate(["/leave/reports/leave-usage/" + empId + "/" + LeaveId]);
    });
  }

  public employeesSelected(event) {
    this.selectedEmployee.id = event ? event.id : "";
    this.selectedEmployee.name = event ? event.name : "";
  }

  onGenerateForChange() {
    const selected = this.filterForm.get("generateFor").value;
    if (selected === "leaveType") {
      this.filterForm.patchValue({
        leavePeriodId: this.leavePeriodList[1].id,
      });
    } else {
      this.filterForm.patchValue({
        leavePeriodId: "",
      });
    }
  }
}
