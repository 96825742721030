<div class="card">
  <div id="header-page" class="ng-star-inserted header-page">
    <div class="page-header ng-star-inserted">
      <div class="page-block ng-star-inserted">
        <div class="row align-items-center">
          <div class="col-md-8">
            <div class="page-header-title">
              <span class="ng-star-inserted"></span><span class="ng-star-inserted">
                <h4 class="m-b-10 ng-star-inserted">{{lang.get("generalInformation")}} </h4>
              </span>
            </div>
          </div>
          <div class="col-md-4"></div>
        </div>
      </div>
    </div>
  </div>


  <app-loading *ngIf="isLoading" [isLoading]="isLoading"></app-loading>
  <div class="card-content table-responsive table-full-width" *ngIf="!organizationErr">
    <form class="form-material full-width-inputs" [formGroup]="organizationForm" action="javascript:"
      *ngIf="!isLoading">
      <div class="row form-group">
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>{{lang.get("name")}}</mat-label>
            <input [readonly]="!this.update" matInput type="text" class="form-control" maxlength="40" required=""
              id="des" formControlName="name">

          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>{{lang.get("taxId")}} </mat-label>
            <input [readonly]="!this.update" matInput type="text" maxlength="40" class="form-control"
              formControlName="taxid">

          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>{{lang.get("nof")}} </mat-label>
            <input [readonly]="!this.update" pattern="[0-9]*" matInput type="text" maxlength="40" class="form-control"
              required="" formControlName="noofemployee">

          </mat-form-field>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>{{lang.get("registrationNumber")}} </mat-label>
            <input [readonly]="!this.update" matInput maxlength="40" type="text" class="form-control"
              formControlName="registrationNumber">

          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="form-element">
            <mat-label>{{lang.get("establishedDate")}} </mat-label>
            <input [disabled]="!this.update" [matDatepicker]="picker1" class="form-control" [max]="this.today"
              formControlName="establishedDate" matInput>
            <mat-datepicker-toggle [for]="picker1" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>{{lang.get("companyType")}} </mat-label>
            <input [readonly]="!this.update" matInput type="text" maxlength="40" class="form-control" id="ct"
              formControlName="companytype">
          </mat-form-field>
        </div>
      </div>

      <div class="row form-group">
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>{{lang.get("organizationEmail")}}</mat-label>
            <input [readonly]="!this.update" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" matInput type="email"
              maxlength="40" class="form-control" required="" id="title" formControlName="organisationemail"
              placeholder="info@teamhrm.org">

          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>{{lang.get("website")}}</mat-label>
            <input [readonly]="!this.update" type="url" matInput maxlength="40" formControlName="website"
              class="form-control" placeholder="https://example.com">

          </mat-form-field>
        </div>

        <div class="col-md-4">
          <mat-form-field class="form-element">
            <mat-label>{{lang.get("country")}}</mat-label>
            <input [readonly]="!this.update" required="" class="form-control" type="text" aria-label="Country" matInput
              formControlName="country" [matAutocomplete]="auto01">
            <mat-autocomplete #auto01="matAutocomplete" [displayWith]="displayCountryByCode.bind(this)">
              <mat-option *ngFor="let r of filteredOptions " [value]="r.code">
                {{r.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>



      </div>
      <div class="row form-group">
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>{{lang.get("addressLine1")}}</mat-label>
            <input [readonly]="!this.update" matInput type="text" maxlength="40" class="form-control" id="title"
              formControlName="addresslineone">

          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>{{lang.get("addressLine2")}}</mat-label>
            <input [readonly]="!this.update" matInput type="text" maxlength="40" class="form-control" id="ct"
              formControlName="addresslinetwo">
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>{{lang.get("addressLine3")}}</mat-label>
            <input [readonly]="!this.update" matInput type="text" maxlength="40" formControlName="addresslinthree"
              class="form-control">
          </mat-form-field>
        </div>

      </div>

      <div class="row form-group">

        <div class="col-md-4">
          <mat-form-field>
            <mat-label>{{lang.get("city")}}</mat-label>
            <input [readonly]="!this.update" matInput maxlength="40" formControlName="city" class="form-control">

          </mat-form-field>
        </div>


        <div class="col-md-4">
          <mat-form-field>
            <mat-label>{{lang.get("state")}}</mat-label>
            <input [readonly]="!this.update" matInput maxlength="40" formControlName="state" class="form-control">

          </mat-form-field>
        </div>

        <div class="col-md-4">
          <mat-form-field>
            <mat-label>{{lang.get("postalCode")}}</mat-label>
            <input [readonly]="!this.update" matInput maxlength="40" formControlName="postalcode" class="form-control">

          </mat-form-field>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>{{lang.get("landmark")}}</mat-label>
            <input [readonly]="!this.update" matInput maxlength="40" formControlName="landmark" class="form-control">

          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>{{lang.get("telephoneNumber")}}</mat-label>
            <input [readonly]="!this.update" type="text" pattern="[+0-9]*" matInput maxlength="40"
              formControlName="telephone" class="form-control" mask="0000000000">

          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>{{lang.get("mobile")}}</mat-label>
            <input [readonly]="!this.update" pattern="[+0-9]*" matInput maxlength="40" formControlName="mobile"
              class="form-control" mask="0000000000">

          </mat-form-field>
        </div>

      </div>
      <div class="row form-group">
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>{{lang.get("faxNumber")}}</mat-label>
            <input [readonly]="!this.update" matInput maxlength="40" formControlName="fax" class="form-control"
              mask="0000000000">

          </mat-form-field>
        </div>

        <div class="col-md-4">
          <mat-form-field>
            <mat-label>{{lang.get("costCenter")}} </mat-label>
            <mat-select [disabled]="!!this.update" disableOptionCentering formControlName="costcenterid"
              class="form-control">
              <mat-option *ngFor="let n of costCenterList" [value]="n.id">
                {{ n.name }}
              </mat-option>
            </mat-select>

          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>{{lang.get("costCenterStatus")}}</mat-label>
            <mat-select [disabled]="!this.update" disableOptionCentering formControlName="status" class="form-control">
              <mat-option [value]="'enable'">
                {{lang.get("enable")}}
              </mat-option>
              <mat-option [value]="'disable'">
                {{lang.get("disable")}}
              </mat-option>
            </mat-select>

          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 text-right">

          <button *ngIf="this.update" (click)="submitOrganization()" class="btn btn-primary" color="primary"
            mat-raised-button type="submit" [disabled]="!organizationForm.valid||!this.correct">
            <i class="flaticon-diskette"></i>Save
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="text-center" *ngIf="organizationErr">
    No Organization found
  </div>
</div>