<app-loading [isLoading]="this.isLoading === true"></app-loading>
<div [hidden]="isLoading" class="page-body no-title minus-top-margin-1">
    <div class="row">
        <div class="col-sm-12">
            <div class="row top-tools">
                <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6 left-tools">
          <span (click)="exportToCsv()" class="export-icon-btn csv"><i
                  class="material-icons">get_app</i><span>CSV</span>
          </span>
                    <span (click)="saveAsPdf()" class="export-icon-btn pdf"><i class="material-icons">get_app</i><span>PDF</span>
          </span>
                </div>
        <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
        <div class="relative-position search-filter-con"> 
                  <span class="icon material-icons ng-tns-c114-0"> search </span>
                        <mat-form-field>
                            <input matInput #inputSearch type="search" [(ngModel)]="filterQuery" maxlength="40"
                            (ngModelChange)="onModelChange(filterQuery)"
                            class="form-control input-sm full-data-search">    
                        </mat-form-field> 
                      <span class="mytooltip cus-left tooltip-effect-4" [hidden]="!update">
                        <span class="tooltip-item">
                            <button mat-mini-fab class="btn btn-primary light add-rounded margin-button-05" (click)="addNewUser()">
                                <i class="fa fa-plus"></i>
                            </button>
                        </span>
                        <span class="tooltip-content clearfix">
                        <span class="tooltip-text">New</span>
                        </span>
                      </span>
          </div>
        </div>
      </div>
      <app-card  [hidden]="isLoading" [title]="'Dependent'">
        <div *ngIf="dataSource" class="dt-responsive">
          <div class="table-responsive">
            <mat-card-content>
              <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="select">
                  <mat-header-cell [hidden]="!delete" *matHeaderCellDef>
                    <a [matMenuTriggerFor]="menu" class="check-all">
                      <span class="material-icons">
                        more_vert
                      </span>
                    </a>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="onSelectAllClicked()">Select All</button>
                      <button (click)="onDeSelectAllClicked()" *ngIf="selectedCheckBoxList.length > 0" mat-menu-item>
                        Deselect All
                      </button>
                      <button (click)="onDeleteSelected()" *ngIf="selectedCheckBoxList.length > 0" mat-menu-item>Delete
                        Selected
                      </button>
                    </mat-menu>
                  </mat-header-cell>
                  <mat-cell [hidden]="!delete" *matCellDef="let row">
                    <mat-checkbox #matCheckbox (change)="onCheckBoxChange($event, row.id)"
                    value="{{row.id}}"></mat-checkbox>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="name">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.name }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="relation">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Relationship</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.relation }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="nationalityName">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Nationality</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.nationalityName }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="dob">
                  <mat-header-cell *matHeaderCellDef>Date Of Birth</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.dob |date : 'dd/MM/yyyy' }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="attachment">
                  <mat-header-cell *matHeaderCellDef >Attachment</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <span class="mytooltip tooltip-effect-4" *ngIf="!row.hasAttachment">
                      <span class="tooltip-item"><i class="single-icon-click flaticon-attachment" style="color: #d0cdcd" (click)="onAddAttachment(row.id)"></i></span>
                      <span class="tooltip-content clearfix">
                      <span class="tooltip-text">No attachment</span>
                      </span>
                    </span>
                    
                    <span class="mytooltip tooltip-effect-4" *ngIf="row.hasAttachment">
                      <span class="tooltip-item"><i class="single-icon-click flaticon-attachment" style="color: #666" (click)="onAddAttachment(row.id)"></i></span>
                      <span class="tooltip-content clearfix">
                      <span class="tooltip-text">Include attachment</span>
                      </span>
                    </span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="edit">
                  <mat-header-cell [hidden]="!update" *matHeaderCellDef>Edit</mat-header-cell>
                  <mat-cell [hidden]="!update" *matCellDef="let row">
                      <span class="mytooltip tooltip-effect-4">
                      <span class="tooltip-item"><i (click)="editUser(row.id)"
                                                    class="single-icon-click fa fa-pencil"></i></span>
                      <span class="tooltip-content clearfix">
                      <span class="tooltip-text">Edit</span>
                      </span>
                      </span>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columns;">
                </mat-row>
              </mat-table>
              <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
              <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
            </mat-card-content>
          </div>
          <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
        </div>
      </app-card>
    </div>
  </div>
</div>


<div class="form-material full-width-inputs"  [hidden]="this.isLoading">
  <div *ngFor="let section of this.sectionsList">
      <div *ngIf="section.customFields.length != 0" class="card-sub">
          <h4>{{section.name}}</h4>
          <ng-container>
           
              <div class="row form-group">
                  <div class="col-4" *ngFor="let custom of section.customFields; index as i">

                     
                      <mat-form-field class="form-element" *ngIf="custom.type === 'dd' ">
                          <mat-select [disabled]="!update" disableOptionCentering class="form-control"
                          [(ngModel)]="custom.value"  required="{{custom.required}}" (ngModelChange)="checkRequiredFieldsInCustomFields(section.id,section.customFields)" >
                                      
                              <ng-container>
                                  <mat-option value="">--Select--</mat-option>
                                  <mat-option *ngFor="let option of custom.options " [value]="option"  [(ngModel)]="custom.value">
                                      {{option}}
                                  </mat-option>
                              </ng-container>
                          </mat-select>
                          <mat-label>{{custom.name}}</mat-label>
                      </mat-form-field>


                      <mat-form-field class="form-element" *ngIf="custom.type === 'st' ">
                          <mat-label>{{custom.name}}</mat-label>
                          <input [readonly]="!update" matInput class="form-control"   [(ngModel)]="custom.value" required="{{custom.required}}"  (keyup)="checkRequiredFieldsInCustomFields(section.id,section.customFields)">
                      </mat-form-field>

                      <mat-form-field class="form-element" *ngIf="custom.type === 'num' ">
                          <mat-label>{{custom.name}}</mat-label>
                          <input [readonly]="!update" matInput class="form-control"   [(ngModel)]="custom.value" type="number" required="{{custom.required}}" (keyup)="checkRequiredFieldsInCustomFields(section.id,section.customFields)">
                      </mat-form-field>
                      <mat-form-field class="form-element" *ngIf="custom.type === 'dt' ">
                          <mat-label >{{custom.name}}</mat-label>
                      <input [readonly]="!update" class="form-control" matInput [matDatepicker]="i" [attr.id]="dtPicker + i" [(ngModel)]="custom.value" required="{{custom.required}}" (ngModelChange)="checkRequiredFieldsInCustomFields(section.id,section.customFields)">
                      <mat-datepicker-toggle [disabled]="!update" matSuffix [for]="i"></mat-datepicker-toggle>
                      <mat-datepicker #i></mat-datepicker>   
                    </mat-form-field>

                    <div *ngIf="custom.type === 'cb' " >
                        <h5>{{custom.name}}:</h5>
                      <div  *ngFor="let option of custom.values">
                          <mat-checkbox [disabled]="!update" [(ngModel)]="option.booleanValue" >{{option.option}}</mat-checkbox> &nbsp;
                      </div>
                      
                    </div>


                  
               </div>

               

                  <div class="action-buttons multi-btns col-sm-12 text-right">
                      <button [hidden]="!update" [disabled]="section.save" (click)="updateCustomField(section.id,section.customFields)"
                              class="btn-primary"
                              color="primary"
                              mat-raised-button><i class="flaticon-diskette"></i>Save
                      </button>
                  </div>    
              </div>   
                         
              
          </ng-container>
      </div>
     
  </div>


</div>

<ng-template #addUser let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">
        <span *ngIf="!isEdit">Add</span>
        <span *ngIf="isEdit">Edit</span> 
        Dependent
      </h4>
    </div>
    <div class="mat-modal-body">


      <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>

      <form class="form-material full-width-inputs" action="javascript:" [formGroup]="userForm" *ngIf="!isUserAdded">
        <div class="row form-group">
          <div class="col-sm-6">
            <mat-form-field class="form-element">
              <mat-label>Name</mat-label>
              <input type="text" matInput class="form-control" maxlength="40" required="" id="name" formControlName="name" name="footer-email">
            </mat-form-field>    
          </div>
          <div class="col-sm-6">
            <mat-form-field class="form-element">
              <mat-select disableOptionCentering class="form-control"
                          formControlName="relation">
                <ng-container>
                  <mat-option value="hu">Husband</mat-option>
                  <mat-option value="wi">Wife</mat-option>
                  <mat-option value="ch">Child</mat-option>
                  <mat-option value="oth">Other</mat-option>
                </ng-container>
              </mat-select>
              <mat-label for="des">Relation</mat-label>
            </mat-form-field>

          </div>
          <div class="col-sm-6">
            <mat-form-field class="form-element">
              <input required [matDatepicker]="picker"
                     class="form-control"
                     formControlName="dob" matInput>
              <mat-datepicker-toggle [for]="picker" matSuffix>
              </mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-label for="expiry">DOB
              </mat-label>

            </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field class="form-element">
                <mat-label>Nationality</mat-label>
                <input  required="" class="form-control" type="text"
                       aria-label="Nationality" maxlength="40"
                       matInput
                       placeholder="type for hints .."
                       formControlName="nationality"
                       [matAutocomplete]="auto"

                >
                <mat-autocomplete #auto="matAutocomplete"
                                  [displayWith]="displayNationlaityByID.bind(this)">
                    <mat-option (click)="changeStatus()" *ngFor="let r of filteredOptions1 " [value]="r.id">
                        {{r.name}}
                    </mat-option>
                </mat-autocomplete>
               
            </mat-form-field>
            <mat-error class="error-messages" [hidden]="this.correct">
                please Select nationality from the dropdown !
              </mat-error>
        </div>
        </div>
      
      </form>
        <div class="row my-2 mx-2"> 
          <div class="action-buttons multi-btns col-sm-12 text-right">
            <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i>Cancel</button>
            <button mat-raised-button class="btn-primary" color="primary" [disabled]="!userForm.valid || !this.correct" (click)="submitAddJobCategory()"><i class="flaticon-diskette"></i>Save</button>
          </div>
        </div>
 
      </div>
    </div>

</ng-template>


<ng-template #deleteUser let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">Delete Job Title</h4>
    </div>
    <div class="mat-modal-body">
      <div class="text-left are-u">Are you sure ?</div>
      <div class="row">
            <div class="multi-btns col-sm-12 text-right">
              <button mat-raised-button class="btn-danger" (click)="close()">
                  <i class="flaticon-cancel"></i>Cancel
              </button>
              <button mat-raised-button class="btn-primary" color="primary" (click)="deleteJobCategory()">
                  <i class="flaticon-trash"></i>Delete
              </button>
          </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #attach let-modal>
  <div class="mat-modal-header">
    <h4 class="mat-title-modal">
      Attachment
    </h4>
  </div>
      
      <div class="mat-modal-body">

        <app-loading *ngIf="loadAttach" [isLoading]="loadAttach"></app-loading>
      <form class="form-material full-width-inputs" *ngIf="!loadAttach">
        <div class="form-row">
          <div class="col-sm-12" [hidden]="!update">
            <div class="upload-btn-wrapper">
              <button class="upload-btn">Attach</button>
              <input (change)="fileChange($event, 'dd')" class="form-control" name="myfile" type="file">
              <div class="fileName">{{this.fileObj && this.fileObj.fileName}}<i *ngIf="fileUrl"
                                                                                class="fa fa-check success"></i>
              </div>
            </div>
            <div class="upload-btn-wrapper">
            </div>
            <div *ngIf="!fileUrl && fileError" class="file-error text-danger">{{fileError}}</div>
            <div class="help-text">Accept upto 2MB</div>
          </div>
          <div class="col-sm-12" [hidden]="!update">
            <mat-form-field class="form-element">
              <input [(ngModel)]="comment" class="form-control"  maxlength="40" matInput name="name" type="text">
              <span class="form-bar"></span>
              <mat-label for="title">Comment</mat-label>
            </mat-form-field>
          </div>
          <div class="col-sm-12 mt-1 border" style="height: 200px !important; overflow-y: auto">
            <mat-card *ngIf="this.attachmentList.length === 0">No Attachments
              Found
            </mat-card>
            <ng-container *ngFor="let attachment of attachmentList; let i = index;">
              <mat-card class="mt-2 shadow-sm">
                <mat-card-title>
                  {{attachment.fileName}}
                </mat-card-title>
                <mat-card-content>
                  <b>Added On:</b> {{attachment.added}} <br>
                  <b>Comments:</b> {{attachment.desc}}
                </mat-card-content>
                <mat-card-actions align="end">
                  <button [hidden]="!delete" (click)="onDeleteClicked(attachment)"
                          aria-label="Example icon button with a vertical three dot icon"
                          mat-icon-button>
                    <mat-icon color="warn">delete</mat-icon>
                  </button>
                  <button (click)="onDownloadClicked(attachment)"
                          aria-label="Example icon button with a vertical three dot icon"
                          mat-icon-button>
                    <mat-icon color="primary">cloud_download</mat-icon>
                  </button>
                </mat-card-actions>
              </mat-card>
            </ng-container>
          </div>
          <div class="action-buttons multi-btns col-sm-12 text-right">
            <button mat-raised-button  class="btn-danger" (click)="close()">
              <i class="flaticon-cancel"></i> Cancel</button>
          <button [hidden]="!update" mat-raised-button  class="btn btn-primary" color="primary"   (click)="saveAttachment()"><i class="flaticon-diskette"></i> Save</button>
          </div>
        </div>
      </form>
  </div>
</ng-template>



