import { Component, Input, OnDestroy, OnInit, EventEmitter, Output, AfterViewInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { ReplaySubject, Subject } from "rxjs";
import { filter, tap, takeUntil, debounceTime, map, delay, take } from "rxjs/operators";
import { Item } from "./Item";

@Component({
  selector: "lazy-multi-select-list",
  templateUrl: "./lazy-multi-select-list.component.html",
  styleUrls: ["./lazy-multi-select-list.component.scss"],
})
export class LazyMultiSelectListComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() placeholder: String;
  @Input() title: String;
  @Input() selectedItems: [Item];
  @Output() filterItems = new EventEmitter<Array<String>>();
  @Output() selectedItemsChange = new EventEmitter<Array<Item>>();

  @ViewChild('multiSelect') multiSelect: MatSelect;

  public items: Item[];

  public itemCtrl: FormControl = new FormControl();
  public itemFilteringCtrl: FormControl = new FormControl();
  public searching: boolean = false;
  public filteredItems: ReplaySubject<Item[]> = new ReplaySubject<Item[]>(1);
  protected _onDestroy = new Subject<void>();

  ngOnInit() {
    this.itemFilteringCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterItemsOnServer();
    });

    this.itemCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.selectItems();
    });

    this.itemCtrl.setValue(this.selectedItems);
  }

  public filterItemsOnServer() {
    let search = this.itemFilteringCtrl.value;
    return this.filterItems.emit(search);
  }

  setFilterdItems(filteredItems) {
    this.filteredItems.next(filteredItems);
  }

  public selectItems() {
    this.selectedItemsChange.emit(this.itemCtrl.value);
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  protected setInitialValue() {
    this.filteredItems
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.multiSelect.compareWith = (a: Item, b: Item) => a && b && a.id === b.id;
      });
  }
}
