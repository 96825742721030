<mat-toolbar color="primary">
        Login
      <span class="flex-spacer"></span>
      <button mat-button mat-dialog-close>&times;</button>
</mat-toolbar>

<form novalidate>
  <mat-dialog-content>
    <div class="file-upload">
      <span class="text">upload</span>
      <input type="file" id="custom-input" (change)="fileChangeListener($event)"/>
    </div>
    
    <img-cropper #cropper [image]="data" [settings]="cropperSettings"></img-cropper>
    <br/>
    <span class="result rounded" *ngIf="data.image">
      <img
        [src]="data.image"
        [width]="cropperSettings.croppedWidth"
        [height]="cropperSettings.croppedHeight"
      />
    </span>
  </mat-dialog-content>
  <div class="action-buttons multi-btns col-sm-12 text-right">
    <button mat-raised-button  class="btn-danger">
      <i class="flaticon-cancel"></i> cancel</button>
    <button mat-raised-button  class="btn btn-primary" color="primary"
                   ><i class="flaticon-diskette"></i> save</button>
  </div>
</form>