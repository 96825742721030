import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { AnnouncementService } from "../../../../../service/announcement.service";
import { NotificationService } from "src/app/core/services/notification.service";
import { MatStepper } from "@angular/material/stepper";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { getAngularEditorConfig } from "../../../../performance/helper-obj";
import { forkJoin, Observable, of, Subject } from "rxjs";
import { DashboardService } from "../../../../dashboard.service";
import { startWith, takeUntil } from "rxjs/operators";
import { AuthService } from "src/app/core/services/auth.service";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";

declare var jQuery: any;

@Component({
  selector: "app-add-news-tab",
  templateUrl: "./add-news-tab.component.html",
  styleUrls: ["./add-news-tab.component.scss"],
})
export class AddNewsTabComponent implements OnInit {
  type = "";
  typeName = "";
  action = "";

  updateId = "";
  updateObj = null;
  // for the add news and documents
  public generalInformationForm: FormGroup;
  public publishSettingsForm: FormGroup;
  public config;
  public categoryList: Array<any> = [];
  public userRoleList: Array<any> = [];
  public employeeList: Array<any> = [];
  public locationList: Array<any> = [];
  public employeeStatusList: Array<any> = [];
  public jobTitleList: Array<any> = [];
  public subUnitList: Array<any> = [];
//   public employees: Array<any> = [];
//   public filteredEmployees: Array<any> = [];
//   public employeeFilterCtrl: FormControl = new FormControl();
  public filteredLocations: Array<any> = [];
  public locationFilterCtrl: FormControl = new FormControl();
  public filteredEmployeeStatus: Array<any> = [];
  public employeeStatusFilterCtrl: FormControl = new FormControl();
  public filteredJobTitle: Array<any> = [];
  public jobTitleFilterCtrl: FormControl = new FormControl();
  public filteredSubUnit: Array<any> = [];
  public subUnitFilterCtrl: FormControl = new FormControl();
  public correct = true;
  public loadingData = false;
  public filteredCategory: Array<any> = [];
//   protected _onDestroy = new Subject<void>();
//   @ViewChild("fruitInput") fruitInput: ElementRef;
  isLoadForm = true;
  validationError = {
    required: "Required",
    email: "Please provide a valid email",
    password: "Please provide a password of atleast 8 digits",
    postalcode: "Should be of 6 digits",
    alphabets: "Should contain only alphabets",
  };
  public permissions: any;
  public read = false;
  public write = false;
  public update = false;
  public delete = false;
  public editClass = "";
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
//   fruits: Array<any> = [];
//   tempEmployees: Array<any> = [];
//   fruitCtrl = new FormControl();
//   filteredFruits: Observable<string[]>;
 
  checkPermission(module, tabs): void {
    this.editClass = "";
    this.permissions = JSON.parse(this.authService.getPermissions());

    this.read = false;
    this.write = false;
    this.delete = false;
    this.update = false;
    if (this.permissions != undefined && this.permissions != null) {
      for (let p of this.permissions) {
        if (p.id === module) {
          if (p.modules != undefined) {
            for (let tab of p.modules) {
              if (tab.id === tabs) {
                for (let move of tab.permits) {
                  if (move.id === "u") {
                    this.update = true;
                  } else if (move.id === "r") {
                    this.read = true;
                  } else if (move.id === "c") {
                    this.write = true;
                  } else if (move.id === "d") {
                    this.delete = true;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  constructor(
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private announcementService: AnnouncementService,
    private dashboardService: DashboardService,
    private fb: FormBuilder,
    private toastr: NotificationService,
    private authService: AuthService
  ) {
    this.config = getAngularEditorConfig();
    this.activatedRoute.params.subscribe((params) => {
      if (params.action) {
        this.action = params.action;
      }
      if (params.type) {
        if (params.type === "news") {
          this.type = "N";
          this.typeName = "News";
        }
        if (params.type === "documents") {
          this.type = "D";
          this.typeName = "Documents";
        }
      }
      if (params.updateId) {
        this.updateId = atob(params.updateId);
        this.loadingData = true;
        this.update = false;
        this.checkPermission("admin", "ad_an");
        this.loadData();
      } else {
        this.loadForm();
        this.isLoadForm = false;
        this.update = true;
      }
    });

    if (this.type === "D") {
      this.getCategories();
    }
    this.createForm();
  }

  remove(id, name) {
    let res = this.publishSettingsForm.value[name]?.filter((item) => item?.id !== id);
    let newObj = {};
    newObj[name] = res;
    this.publishSettingsForm.patchValue(newObj);
  }

  ngOnInit() {
    // this.toDetectChanges();
    this.filteredCategory = this.categoryList;
  }

//   toDetectChanges() {
//     this.filteredCategory = this.categoryList;
//     this.generalInformationForm.controls["category"].valueChanges.subscribe((change) => {
//       // Value inside the input field as soon as it changes
//       if (change == null || change == undefined) {
//         this.correct = true;
//       } else {
//         this.filterCategory(change);
//       }
//     });
//     this.filteredEmployees = this.employeeList;

//     this.employeeFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
//       this.filterEmployees();
//     });
//     this.filteredLocations = this.locationList;
//     this.locationFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
//       this.filterLocations();
//     });
//     this.filteredEmployeeStatus = this.employeeStatusList;
//     this.employeeStatusFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
//       this.filterEmployeesStatus();
//     });
//     this.filteredJobTitle = this.jobTitleList;
//     this.jobTitleFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
//       this.filterJobTitle();
//     });

//     this.filteredSubUnit = this.subUnitList;
//     this.subUnitFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
//       this.filterSubUnit();
//     });
//   }

  checkCategoryChange(change) {
    let x = this.filteredCategory.find((x) => x.id === change);
    if (!x) {
      this.correct = false;
    }
  }

  public displayCategoryNamebyId(option) {
    this.correct = true;

    return this.categoryList.find((x) => x.id === option)?.name;
  }

  protected filterCategory(change: String) {
    this.filteredCategory = this.categoryList;
    if (!this.categoryList) {
      return;
    }

    // get the search keyword
    if (!change) {
      this.filteredCategory = this.categoryList;
      return;
    } else {
      this.checkCategoryChange(change);
      change = change.toLowerCase();
    }
    // filter the search
    this.filteredCategory = this.categoryList.filter((ca) => ca.name.toLowerCase().indexOf(change) > -1);
  }
  protected filterSubUnit() {
    if (!this.subUnitList) {
      return;
    }
    // get the search keyword
    let search = this.subUnitFilterCtrl.value;
    if (!search) {
      this.filteredSubUnit = this.subUnitList;
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the search
    this.filteredSubUnit = this.subUnitList.filter((sub) => sub.name.toLowerCase().indexOf(search) > -1);
  }
  protected filterJobTitle() {
    if (!this.jobTitleList) {
      return;
    }
    // get the search keyword
    let search = this.jobTitleFilterCtrl.value;
    if (!search) {
      this.filteredJobTitle = this.jobTitleList;
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the search
    this.filteredJobTitle = this.jobTitleList.filter((job) => job.name.toLowerCase().indexOf(search) > -1);
  }
  protected filterEmployeesStatus() {
    if (!this.employeeStatusList) {
      return;
    }
    // get the search keyword
    let search = this.employeeStatusFilterCtrl.value;
    if (!search) {
      this.filteredEmployeeStatus = this.employeeStatusList;
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the search
    this.filteredEmployeeStatus = this.employeeStatusList.filter(
      (stat) => stat.name.toLowerCase().indexOf(search) > -1
    );
  }
  protected filterLocations() {
    if (!this.locationList) {
      return;
    }
    // get the search keyword
    let search = this.locationFilterCtrl.value;
    if (!search) {
      this.filteredLocations = this.locationList;
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the search
    this.filteredLocations = this.locationList.filter((loc) => loc.name.toLowerCase().indexOf(search) > -1);
  }

//   protected filterEmployees() {
//     if (!this.employeeList) {
//       return;
//     }
//     // get the search keyword
//     let search = this.employeeFilterCtrl.value;
//     if (!search) {
//       this.filteredEmployees = this.employeeList;
//       return;
//     } else {
//       search = search.toLowerCase();
//     }
//     // filter the search
//     this.filteredEmployees = this.employeeList.filter((emp) => emp.name.toLowerCase().indexOf(search) > -1);
//   }

  public createForm(): void {
    this.generalInformationForm = this.fb.group({
      topic: [""],
      description: [""],
      category: [""],
      acknowledgement: [false],
    });
    let emp: Array<any> = [];
    this.publishSettingsForm = this.fb.group({
      publishDate: [],
      ptAllUserRole: [false],
      ptEmployees: emp,
      ptAllLocation: [false],
      ptEmployeeStatus: emp,
      ptJobTitles: emp,
      ptSubUnits: emp,
      ptUserRoles: emp,
      ptLocations: emp,
      status: "",
    });
  }

  loadData() {
    this.announcementService.getNewsAndDocById(this.updateId).subscribe(
      (res) => {
        if (res && res.data) {
          this.generalInformationForm.patchValue(res.data.generalinfomation);
          this.updateId = res.data.id;
          res.data.publishSetting.publishDate = new Date(res.data.publishSetting.publishDate);

          this.publishSettingsForm.patchValue(res.data.publishSetting);

          this.loadingData = false;
          this.loadForm();
        } else {
          this.toastr.error(res.msg);
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.toastr.error(err);
      }
    );
  }

  getCategories() {
    this.announcementService.getCategories({ limit: 100000 }).subscribe(
      (res) => {
        if (res && res.data && res.data.length > 0) {
          res.data.map((item) => {
            this.categoryList.push({ id: item.id, name: item.name });
          });
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.toastr.error(err);
      }
    );
  }

  public goForward(stepper: MatStepper) {
    if (this.generalInformationForm.controls.topic.invalid) {
      if (this.type === "D") {
        if (this.generalInformationForm.controls.category.invalid) {
          let control;
          Object.keys(this.generalInformationForm.controls)
            .reverse()
            .forEach((field) => {
              if (this.generalInformationForm.get(field).invalid) {
                control = this.generalInformationForm.get(field);
                control.markAsDirty();
              }
            });

          if (control) {
            const el = jQuery(".ng-invalid:not(form):first");
            if (el.length > 0) {
              jQuery("html,body").animate({ scrollTop: el.offset().top - 100 }, "slow", () => {
                el.focus();
              });
            }
          }
          return;
        }
      } else {
        let control;
        Object.keys(this.generalInformationForm.controls)
          .reverse()
          .forEach((field) => {
            if (this.generalInformationForm.get(field).invalid) {
              control = this.generalInformationForm.get(field);
              control.markAsDirty();
            }
          });

        if (control) {
          const el = jQuery(".ng-invalid:not(form):first");
          if (el.length > 0) {
            jQuery("html,body").animate({ scrollTop: el.offset().top - 100 }, "slow", () => {
              el.focus();
            });
          }
        }
        return;
      }
    }
    stepper.next();
  }

  public goBack(stepper: MatStepper) {
    stepper.previous();
  }

  close() {
    this.route.navigate([`/admin/announcement/${this.type === "N" ? "news" : "documents"}`]);
  }

  submitNewsAndDocuments(status) {
    console.log(status);
    let generalInformation;
    let empIds = [];
    this.employeeList?.map((value) => {
      empIds.push(value.id);
    });

    let subUnitIds = [];
    if (this.publishSettingsForm.value.ptSubUnits && this.publishSettingsForm.value.ptSubUnits.length != 0) {
      this.publishSettingsForm.value.ptSubUnits?.map((value) => {
        if (value.id != undefined) {
          subUnitIds.push(value.id);
        }
      });
    }

    let locationsIds = [];
    if (this.publishSettingsForm.value.ptLocations && this.publishSettingsForm.value.ptLocations.length != 0) {
      this.publishSettingsForm.value.ptLocations?.map((value) => {
        if (value.id != undefined) {
          locationsIds.push(value.id);
        }
      });
    }

    let jobTitlesIds = [];

    if (this.publishSettingsForm.value.ptJobTitles && this.publishSettingsForm.value.ptJobTitles.length != 0) {
      this.publishSettingsForm.value.ptJobTitles?.map((value) => {
        if (value.id != undefined) {
          jobTitlesIds.push(value.id);
        }
      });
    }

    let userRolesIds = [];
    if (this.publishSettingsForm.value.ptUserRoles && this.publishSettingsForm.value.ptUserRoles.length != 0) {
      this.publishSettingsForm.value.ptUserRoles?.map((value) => {
        if (value.id != undefined) {
          userRolesIds.push(value.id);
        }
      });
    }

    let employeeStatusIds = [];
    if (
      this.publishSettingsForm.value.ptEmployeeStatus &&
      this.publishSettingsForm.value.ptEmployeeStatus.length != 0
    ) {
      this.publishSettingsForm.value.ptEmployeeStatus?.map((value) => {
        if (value.id != undefined) {
          employeeStatusIds.push(value.id);
        }
      });
    }

    if (!this.updateId) {
      this.publishSettingsForm.patchValue({ status: status });
    }

    const publishSetting = {
      created: 0,
      deleted: true,
      ptAllLocation: this.publishSettingsForm.value.ptAllLocation,
      ptAllUserRole: this.publishSettingsForm.value.ptAllUserRole,
      ptEmployeeStatus: employeeStatusIds,
      ptEmployees: empIds,
      ptJobTitles: jobTitlesIds,
      ptLocations: locationsIds,
      ptSubUnits: subUnitIds,
      ptUserRoles: userRolesIds,
      publishDate: this.publishSettingsForm.value.publishDate,
      status: status === "pu" ? "pu" : this.publishSettingsForm.value.status,
      type: this.type,
      updated: 0,
    };
    if (this.type === "N") {
      generalInformation = {
        acknowledgement: this.generalInformationForm.value.acknowledgement,
        description: this.generalInformationForm.value.description,
        topic: this.generalInformationForm.value.topic,
      };
    } else {
      generalInformation = {
        acknowledgement: this.generalInformationForm.value.acknowledgement,
        description: this.generalInformationForm.value.description,
        topic: this.generalInformationForm.value.topic,
        category: this.generalInformationForm.value.category,
      };
    }

    const body = {
      generalinfomation: generalInformation,
      id: this.updateId,
      publishSetting: publishSetting,
    };
    if (this.updateId === "") {
      this.announcementService.saveNewsAndDoc(body).subscribe(
        (res) => {
          if (res) {
            this.toastr.success(this.typeName + "Added Successfully");
            this.generalInformationForm.reset();
            this.publishSettingsForm.reset();
            this.route.navigate([`/admin/announcement/${this.type === "N" ? "news" : "documents"}`]);
          }
        },
        (error) => {
          this.toastr.error(error.error.msg);
        }
      );
    } else {
      this.announcementService.updateNewsAndDoc(body, this.updateId).subscribe(
        (res) => {
          if (res) {
            this.toastr.success(this.typeName + "Added Successfully");
            this.generalInformationForm.reset();
            this.publishSettingsForm.reset();
            this.updateId = "";
            this.route.navigate([`/admin/announcement/${this.type === "N" ? "news" : "documents"}`]);
          }
        },
        (error) => {
          this.toastr.error(error.error.msg);
        }
      );
    }
  }

  private loadForm() {
    this.isLoadForm = true;
    const join = [
      this.dashboardService.getUserRole(),
    //   this.dashboardService.getEmployeeDropdownOption(),
      this.dashboardService.getLocationList(),
      this.dashboardService.getEmployeeStatusList(),
      this.dashboardService.getJobTitleList(),
      this.dashboardService.getStructureList(),
    //   this.dashboardService.getEmployeeDetailList(),
    ];
    forkJoin(join).subscribe(
      (resArr) => {
        this.isLoadForm = false;
        this.getApiData(resArr[0]).map((item) => {
          this.userRoleList.push({ id: item.id, name: item.name });
        });
        this.getApiData(resArr[2]).map((item) => {
          this.locationList.push({ id: item.id, name: item.location });
        });
        this.getApiData(resArr[3]).map((item) => {
          this.employeeStatusList.push({ id: item.id, name: item.name });
        });
        this.getApiData(resArr[4]).map((item) => {
          this.jobTitleList.push({ id: item.id, name: item.title });
        });
        this.getApiData(resArr[5]).map((item) => {
          this.subUnitList.push({ id: item.id, name: item.name });
        });
        // this.getApiData(resArr[6]).map((item) => {
        //   this.employees.push({ id: item.id, name: item.id + " " + item.name });
        // });

        let subUnits = [];
        this.subUnitList?.map((value) => {
          if (this.publishSettingsForm.value.ptSubUnits?.includes(value.id)) {
            subUnits.push(value);
          }
        });
        // let emp = [];
        // this.employees?.map((value) => {
        //   if (this.publishSettingsForm.value.ptEmployees?.includes(value.id)) {
        //     emp.push(value);
        //   }
        // });

        let locations = [];
        this.locationList?.map((value) => {
          if (this.publishSettingsForm.value.ptLocations?.includes(value.id)) {
            locations.push(value);
          }
        });

        let jobTitles = [];
        this.jobTitleList?.map((value) => {
          if (this.publishSettingsForm.value.ptJobTitles?.includes(value.id)) {
            jobTitles.push(value);
          }
        });

        let userRoles = [];
        this.userRoleList?.map((value) => {
          if (this.publishSettingsForm.value.ptUserRoles?.includes(value.id)) {
            userRoles.push(value);
          }
        });

        let employeeStatus = [];
        this.employeeStatusList?.map((value) => {
          if (this.publishSettingsForm.value.ptEmployeeStatus?.includes(value.id)) {
            employeeStatus.push(value);
          }
        });

        this.publishSettingsForm.patchValue({ ptSubUnits: subUnits });
        // this.publishSettingsForm.patchValue({ ptEmployees: emp });
        this.publishSettingsForm.patchValue({ ptLocations: locations });
        this.publishSettingsForm.patchValue({ ptJobTitles: jobTitles });
        this.publishSettingsForm.patchValue({ ptUserRoles: userRoles });
        this.publishSettingsForm.patchValue({ ptEmployeeStatus: employeeStatus });
        // this.dashboardService.getEmployeeDetailList().subscribe((res) => {
        //   if (res && res.data) {
        //     res.data.map((e) => {
        //       this.employeeList.push({ name: e.id + " " + e.name, id: e.id });
        //     });

        //     if (this.publishSettingsForm.value.ptEmployees.length != 0) {
        //       for (let emp of this.publishSettingsForm.value.ptEmployees) {
        //         this.selectEmployee(emp);
        //       }
        //     }

        //     this.fruitCtrl.valueChanges.subscribe((search) => {
        //       startWith(""),
        //         (this.filteredFruits = of(
        //           this.employeeList.filter((item) => item.name.toLowerCase().includes(search))
        //         ));
        //     });
        //     if (!this.updateId) {
        //       res.data.map((e) => {
        //         this.employeeList.push({ name: e.id + " " + e.name, id: e.id });
        //       });
        //       this.fruitCtrl.valueChanges.subscribe((search) => {
        //         startWith(""),
        //           (this.filteredFruits = of(
        //             this.employeeList.filter((item) => item.name.toLowerCase().includes(search))
        //           ));
        //       });
        //     }
        //   }
        // });

        this.isLoadForm = false;
      },
      (error) => {
        this.isLoadForm = false;
      }
    );
  }

  private getApiData(res) {
    return res && res.data && res.data.length > 0 ? res.data : [];
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1.name === o2.name && o1.id === o2.id;
  }

//   removes(id): void {
//     let res = this.fruits?.filter((item) => item.id !== id);
//     this.fruits = res;
//     let res2 = [];
//     if (this.tempEmployees.length != 0) {
//       for (let emp of this.tempEmployees) {
//         if (emp.id === id) {
//           this.employeeList.push(emp);
//         } else {
//           res2.push(emp);
//         }
//       }
//       this.tempEmployees = res2;
//     }
//   }
//   selected(event: MatAutocompleteSelectedEvent): void {
//     this.fruits.push(event.option.value);
//     this.tempEmployees.push(event.option.value);
//     this.fruitInput.nativeElement.value = "";
//     this.fruitCtrl.setValue(null);
//     let res = this.employeeList?.filter((item) => item.id !== event.option.value.id);
//     this.employeeList = res;

//     this.fruitInput.nativeElement.blur();
//     console.log(event.option.value);
//   }

//   selectEmployee(obj): void {
//     this.fruits.push(obj);
//     this.tempEmployees.push(obj);
//     this.fruitInput.nativeElement.value = "";
//     this.fruitCtrl.setValue(null);
//     let res = this.employeeList?.filter((item) => item.id !== obj.id);
//     this.employeeList = res;

//     this.fruitInput.nativeElement.blur();
//   }

public updateEmployee(event) {
    this.employeeList = event;
  }
}
