import {Component, OnInit, ViewChild, Input, QueryList, ViewChildren} from '@angular/core';
import {DashboardService} from '../../../dashboard.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { NotificationService } from "src/app/core/services/notification.service";
import {animate, style, transition, trigger, state, AUTO_STYLE} from '@angular/animations';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import * as moment from 'moment';
import { AuthService } from 'src/app/core/services/auth.service';
@Component({
  selector: 'app-qualification',
  templateUrl: './qualification.component.html',
  styleUrls: ['./qualification.component.scss'],
  animations: [
    trigger('notificationBottom1', [
      state('an-off, void',
          style({
            overflow: 'hidden',
          height: '0px',
        })
      ),
      state('an-animate',
        style({
          overflow: 'visible',
          height: AUTO_STYLE,
        })
      ),
      transition('an-off <=> an-animate', [
        animate('150ms ease-in-out')
      ])
    ]),
    trigger('slideInOut', [
      state('in', style({
        width: '180px',
        // transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        width: '0',
        // transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('150ms ease-in-out')),
      transition('out => in', animate('150ms ease-in-out'))
    ]),
    trigger('mobileHeaderNavRight1', [
      state('nav-off, void',
        style({
          overflow: 'hidden',
          height: '0px',
        })
      ),
      state('nav-on',
        style({
          height: AUTO_STYLE,
        })
      ),
      transition('nav-off <=> nav-on', [
        animate('150ms ease-in-out')
      ])
    ]),
    trigger('fadeInOutTranslate', [
      transition(':enter', [
        style({opacity: 0}),
        animate('150ms ease-in-out', style({opacity: 1}))
      ]),
      transition(':leave', [
        style({transform: 'translate(0)'}),
        animate('150ms ease-in-out', style({opacity: 0}))
      ])
    ]),
    trigger('mobileMenuTop', [
      state('no-block, void',
        style({
          overflow: 'hidden',
          height: '0px',
        })
      ),
      state('yes-block',
        style({
          height: AUTO_STYLE,
        })
      ),
      transition('no-block <=> yes-block', [
        animate('150ms ease-in-out')
      ])
    ])
  ]
})
export class QualificationComponent implements OnInit {

  public rowsOnPage = 10;
  public filterQuery = '';
  public sortBy = '';
  public sortOrder = 'desc';

  public userList: Array<any>;
  public educationList: Array<any>;
  public workExperienceList: MatTableDataSource<any> = new MatTableDataSource([]);
  public licenseList: Array<any>;
  public licenseListData: MatTableDataSource<any> = new MatTableDataSource([]);
  public educationListData: MatTableDataSource<any> = new MatTableDataSource([]);
  public languageListData: MatTableDataSource<any> = new MatTableDataSource([]);
  public skillsListData: MatTableDataSource<any> = new MatTableDataSource([]);
  public membershipListData: MatTableDataSource<any> = new MatTableDataSource([]);
  public trainingListData: MatTableDataSource<any> = new MatTableDataSource([]);
  public publicationListData: MatTableDataSource<any> = new MatTableDataSource([]);
  public awardListData: MatTableDataSource<any> = new MatTableDataSource([]);
  workExperienceListColumns = ['company', 'from', 'to', 'jobTitle', 'comment', 'attachment', 'delete'];
  educationListDataColumns = ['level', 'from', 'to', 'institute', 'major', 'score', 'attachment', 'delete'];
  skillsListDataColumns = ['skill', 'year', 'comment', 'attachment', 'delete'];
  licenseListDataColumns = ['license', 'licenseNo', 'issDate', 'expDate', 'attachment', 'delete'];
  languageListDataColumns = ['language', 'fluencyLevel', 'skill', 'comment', 'attachment', 'delete'];
  membershipListDataColumns = ['name', 'spb', 'amount', 'currency', 'scd', 'srd', 'attachment', 'delete'];
  trainingDataColumns = ['name', 'provider', 'sector', 'dateFrom', 'dateTo', 'attachment', 'delete'];
  publicationDataColumns = ['name', 'journal', 'sector', 'date', 'attachment', 'delete'];
  awardDataColumns = ['name', 'donor', 'sector', 'date', 'attachment', 'delete'];
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChild('eduPagination', {static: false}) eduPaginator: MatPaginator;
  @ViewChild('workPaginator', {read: MatPaginator}) workPaginator: MatPaginator;
  @ViewChild('skillPaginator') skillPaginator: MatPaginator;
  @ViewChild('licensePaginator') licensePaginator: MatPaginator;
  @ViewChild('languagePaginator') languagePaginator: MatPaginator;
  @ViewChild('memberShipPaginator') memberShipPaginator: MatPaginator;
  @ViewChild('trainingPaginator') trainingPaginator: MatPaginator;
  @ViewChild('publicationPaginator') publicationPaginator: MatPaginator;
  @ViewChild('awardPaginator') awardPaginator: MatPaginator;
  public languageList: Array<any>;
  public jobTitleList: Array<any>;
  public skillsList: Array<any>;
  public membershipList: Array<any>;
    public isLoading: boolean = true;
    public isUserAdded: boolean;
  public err: string;
  public modalRef: any;

  public payGradeCurrencyList: Array<any>;
  public workForm: FormGroup;
  public licenseForm: FormGroup;
  public educationForm: FormGroup;
  public languageForm: FormGroup;
  public skillsForm: FormGroup;
  public membershipForm: FormGroup;
  public trainingForm: FormGroup;
  public publicationsForm: FormGroup;
  public awardForm: FormGroup;
  public profileNotification1: string;
  public profileNotificationClass1: string;

  public isEdit: boolean;
  public editId: string;
  public deleteId: string;
  public attacherId: string;
  public attachmentList = [];
  comment: string;
  public fileType: string;
  public fileObj: any;
  public uploadedFile: any;
  public fileError: string;
  public sectionsList : Array<any> = [];
  public tempSectionsList : Array<any> = [];
  public saveCutomList : Array<any> = [];
  public saveCBCutomList : Array<any> = [];
  public dialogRef: any;
  @ViewChild('attach', {static: false}) attach;

  public validationError = {
    required: 'Required'
  };

  public choice: string;
  
  @Input() employeeId: string;
  @ViewChild('addLanguage', {static: false}) addLanguage;
  @ViewChild('addEducation', {static: false}) addEducation;
  @ViewChild('addLicense', {static: false}) addLicense;
  @ViewChild('addSkills', {static: false}) addSkills;
  @ViewChild('addWork', {static: false}) addWork;
  @ViewChild('addmembership', {static: false}) addmembership;
  @ViewChild('addTraining', {static: false}) addTraining;
  @ViewChild('deleteUser', {static: false}) deleteUser;
  @ViewChild('addPublications', {static: false}) addPublications;
  @ViewChild('addaward', {static: false}) addaward;



  public sortDirection = 'asc';
  public tableConfig = {
    name: true
  };


public requirededu : any = 
{
endDateAvailable: true,
endDateRequired: false,
levelAvailable: true,
levelRequired: true,
selected: true,
startDateAvailable: true,
startDateRequired: false,
}

public immigrationFromGroup : any = 
{
commentAvailable: true,
commentRequired: false,
eligibleReviewDateAvailable: true,
eligibleReviewDateRequired: false,
eligibleStatusAvailable: true,
eligibleStatusRequired: false,
expiryDateAvailable: true,
expiryDateRequired: false,
issuedByAvailable: true,
issuedByRequired: false,
issuedDateAvailable: true,
issuedDateRequired: false

}


public languagesFromGroup : any = 
{
commentsAvailable: true,
commentsRequired: false,
fluencyLevelAvailable: true,
fluencyLevelRequired: false,
languagesAvailable: true,
languagesRequired: false,
skillAvailable: true,
skillRequired: false
}

public licenseFromGroup : any = 
{
expiryDateAvailable: true,
expiryDateRequired: false,
issuedDateAvailable: true,
issuedDateRequired: false,
licenseNumberAvailable: true,
licenseNumberRequired: false,
licenseTypeAvailable: true,
licenseTypeRequired: false

}

public membershipsFromGroup : any = 
{
currencyAvailable: true,
currencyRequired: false,
memberShipAvailable: true,
memberShipRequired: false,
subscriptionAmountAvailable: true,
subscriptionAmountRequired: false,
subscriptionCommenceDateAvailable: true,
subscriptionCommenceDateRequired: false,
subscriptionPaidByAvailable: true,
subscriptionPaidByRequired: false,
subscriptionRenewalDateAvailable: true,
subscriptionRenewalDateRequired: false,
}

public skillsFromGroup : any = 
{
commentsAvailable: true,
commentsRequired: false,
skillsAvailable: true,
skillsRequired: false,
yearOfExperienceAvailable: true,
yearOfExperienceRequired: false
}

public requiredWork : any = 
{
commentsAvailable: true,
commentsRequired: false,
creditableAvailable: true,
creditableRequired: false
}
  ngAfterViewInit()
  {


    this.service.getRequiredFields().subscribe(
      resp => {
         
          if (resp && resp.data) {
  
            this.requiredWork=resp.data.workExperienceFromGroup;
            this.skillsFromGroup=resp.data.skillsFromGroup;
            this.membershipsFromGroup=resp.data.membershipsFromGroup;
            this.licenseFromGroup=resp.data.licenseFromGroup;
            this.languagesFromGroup=resp.data.languagesFromGroup;
            this.immigrationFromGroup=resp.data.immigrationFromGroup;
            this.requirededu=resp.data.educationFromGroup;
          }
         
      }, error => {
       
          const err = error.error && error.error.msg;
          this.toastr.error(err);
      }
  );
    this.service.getRequiredFieldsBySection('we').subscribe(
      resp => {
         
          if (resp && resp.data) {
          this.requiredWork=resp.data;
          }
         
      }, error => {
       
          const err = error.error && error.error.msg;
          this.toastr.error(err);
      }
  );

  this.service.getRequiredFieldsBySection('edu').subscribe(
    resp => {
       
        if (resp && resp.data) {
        this.requirededu=resp.data;
        }
       
    }, error => {
     
        const err = error.error && error.error.msg;
        this.toastr.error(err);
    }
);
  }

  constructor(private service: DashboardService,
              private authService : AuthService,
              private dialog: MatDialog,
              private fb: FormBuilder,
              private toastr: NotificationService) {
    this.createWorkForm();
    this.createLanguageForm();
    this.createLicenseForm();
    this.createEducationForm();
    this.createSkillsForm();
    this.createMembershipForm();
    this.createTrainingForm();
    this.createPublicationsForm();
    this.createAwardForm();
  }

  ngOnDestroy() {
    this.attachmentList = null ;
    this.dialog = null ;
    this.dialogRef = null ;
    this.authService = null ;
    this.service = null ;
    this.fb = null ;
    this.toastr = null ;
    this.attach = null ;
    this.sort = null ;
    this.workForm = null ;
    this.awardForm = null ;
    this.skillsForm = null ;
    this.licenseForm = null ;
    this.languageForm = null ;
    this.trainingForm = null ;
    this.educationForm = null ;
    this.membershipForm = null ;
    this.eduPaginator = null ;
    this.workPaginator = null ;
    this.awardPaginator = null ;
    this.skillPaginator = null ;
    this.licensePaginator = null;
    this.languagePaginator = null ;
    this.trainingPaginator = null ;
    this.memberShipPaginator = null ;
    this.publicationPaginator = null ;
    this.addEducation = null ;
    this.addLanguage = null 
    this.addLicense = null ;
    this.addWork = null ;
    this.addSkills = null ;
    this.addTraining = null ;
    this.addPublications = null ;
    this.addaward = null ;
    this.addmembership = null ;
  }

  public createSkillsForm(): void {
    this.skillsForm = this.fb.group({
      comment: [''],
      skill: [''],
      year: ['']
    });
  }

  public createWorkForm(): void {
    this.workForm = this.fb.group({
      comment: [''],
      company: [''],
      creditable: [false],
      from: [''],
      id: [''],
      jobTitle: [''],
      to: ['']
    });
  }

  public createLanguageForm(): void {
    this.languageForm = this.fb.group({
      comment: [''],
      fluencyLevel: [''],
      language: [''],
      skill: ['']
    });
  }

  public createLicenseForm(): void {
    this.licenseForm = this.fb.group({
      expDate: [''],
      issDate: [''],
      license: [''],
      licenseNo: ['']
    });
  }

  public createEducationForm(): void {
    this.educationForm = this.fb.group({
      from: [''],
      id: [''],
      institute: [''],
      level: [''],
      major: [''],
      score: [''],
      to: [''],
      year: ['']
    });
  }
  public createMembershipForm(): void {
    this.membershipForm = this.fb.group({
      amount: [''],
      currency: [''],
      memberShip: [''],
      scd: [''],
      srd: [''],
      spb: ['']
    });
  }

  public createTrainingForm(): void {
    this.trainingForm = this.fb.group({
      empId: [''],
      name : [''],
      provider  : [''],
      sector : [''],
      dateFrom : [''],
      dateTo : ['']
    });
  }

  public createPublicationsForm(): void {
    this.publicationsForm = this.fb.group({
      empId: [''],
      name : [''],
      journal: [''],
      sector : [''],
      date  : ['']
    });
  }

  public createAwardForm(): void {
    this.awardForm = this.fb.group({
      empId: [''],
      name : [''],
      donor: [''],
      sector : [''],
      date  : ['']
    });
  }


  public addNewUser() {
    this.isEdit = false;
    // this.modalRef = this.modalService.open(this.addUser);
    // this.userForm.reset();
  }

  public close(): void {
    this.dialogRef.close();
    this.educationForm.reset();
      this.languageForm.reset();
    this.skillsForm.reset();
    this.licenseForm.reset();
    this.workForm.reset();
    this.createWorkForm();
    this.membershipForm.reset();
    this.trainingForm.reset();
    this.awardForm.reset();
    this.publicationsForm.reset();

  }

  checkRequiredFieldsInCustomFields(sectionID,customFields)
  {
    
    for(let section of this.sectionsList)
    {
      if(section.id === sectionID)
     {
      for(let custom of customFields)
      {
        if(custom.required)
        {
          if(custom.value === "" || custom.value === null || custom.value === undefined)
          {
            section.save=true;
            return ;
          }
        }
      }

      section.save=false;
     } 
    }
   

  }

  updateCustomField(sectionId ,customFields) : void
  {
    
  
  
    this.saveCutomList = [];
    this.saveCBCutomList = [];
    for(let custom of customFields)
    {
      if(custom.required)
      {
        if(custom.value === "")
        {
          this.toastr.error('Please fill the required fields');
          return ;
        }
      }
      var obj = 
      {
         fieldId : "",
	       fieldValue: ""  
      }
      if(custom.type != "cb")
      {
        let id : string = custom.id;
        let value : string = custom.value;
        if(custom.type == "dt")
        {
          value = moment(custom.value).format('YYYY-MM-DD').toString();
        }
        
        obj.fieldId = id;
        obj.fieldValue = value;
        this.saveCutomList.push(obj);
      }


      if(custom.type == "cb")
      {

        var obj2 = 
        {
           fieldId : "",
           values: []
        }
        obj2.fieldId = custom.id;
        let values : Array<any> = [];
        obj2.values = values;
        for(let c of custom.values)
        {
          if(c.booleanValue)
          {
            
            obj2.values.push(c.option);

          }
        }
        this.saveCBCutomList.push(obj2);
        
      }

     
    }
    
    this.service.saveCustomFieldsInEditEmployee(sectionId,this.employeeId,this.saveCutomList).subscribe(res => {
      if (res) {
      
        this.service.saveCustomFieldsCheckBoxesInEditEmployee(sectionId,this.employeeId,this.saveCBCutomList).subscribe(res => {
          if (res) {
            this.toastr.success('Updated Successfully!..');
          }
        }, error => {
          this.toastr.error('Error in  updating');
        });
      }
    }, error => {
      
    });
  }
  public permissions : any;
  public read = false;
  public write = false ;
  public update = false;
  public delete = false;
  public editClass = "";
  doNOthing() : void 
  {
    return ;
  }
  checkPermission(module,tabs) : void 
  {
      this.permissions = JSON.parse(this.authService.getPermissions());

      this.read = false ;
      this.write = false ;
      this.delete = false ;
      this.update = false;
      this.editClass = "";
      if(this.permissions != undefined && this.permissions != null )
      {
          for(let p of this.permissions)
          {
             
              if(p.id === module)
              {
                 
                  if(p.modules != undefined)
                  {
                    
                      for(let tab of p.modules)
                      {
                          if(tab.id === tabs)
                          {
                            
                              for(let move of tab.permits)
                              {
                                  if(move.id === "u")
                                  {
                                      this.update = true;
                                      this.editClass = "cursor-pointer";
                                     
                                  }
                                  else if(move.id === "r")
                                  {
                                      this.read = true;
                                  }
                                 
                                  else if(move.id === "d")
                                  {
                                      this.delete = true;
                                  }
                              }
                          }
                      }
                  }
                 
              }
             
          }
      }
     

  }
  
  ngOnInit() {
      this.isLoading = true;
      this.checkPermission("pdm","pd_em");
      this.service.getCustomFieldsForEmployee('qu',this.employeeId).subscribe(res => {
        if (res && res.data) {
        
          this.sectionsList = res.data;

         
      
        }
    });
      this.getAllQuali();
      this.isEdit = false;
    this.isUserAdded = false;
    this.service.getEmpQualificationFormList().subscribe(resp => {
      this.jobTitleList = resp && resp[0].data;
      this.educationList = resp && resp[1].data;
      this.skillsList = resp && resp[2].data;
      this.languageList = resp && resp[3].data;
      this.licenseList = resp && resp[4].data;
    });
    this.service.getPayGradeCurrencyList().subscribe(resp => {
      if (resp && resp.data && resp.data.currency) {
        this.payGradeCurrencyList = resp.data.currency;
      }
    });
    this.service.getMembershipsList().subscribe(resp => {
      if (resp && resp.data) {
        this.membershipList = resp.data;
      }
    });

    // this.getAllQuali();
    this.isUserAdded = false;
      // this.isLoading = false;
    this.toggleProfileNotification();
  }

  public getAllQuali(): void {
    this.service.getAllQualifactions(this.employeeId).subscribe(user => {
      if (user && user.data) {
        user.data.languagesList.forEach((item => {
          if (item.skill === 'r') {
            item.skill = 'Reading';
          } else if (item.skill === 'w') {
            item.skill = 'Writing';
          } else if (item.skill === 's') {
            item.skill = 'Speaking';
          }
          if (item.fluencyLevel === 'b') {
            item.fluencyLevel = 'Basic';
          } else if (item.fluencyLevel === 'p') {
            item.fluencyLevel = 'Poor';
          } else if (item.fluencyLevel === 'g') {
            item.fluencyLevel = 'Good';
          }
        }));
        this.workExperienceList = new MatTableDataSource<any>(user.data.workExperienceList);
        this.workExperienceList.paginator = this.workPaginator;
        this.workExperienceList.sort = this.sort.toArray()[0];
        this.educationListData = new MatTableDataSource<any>(user.data.educationsList);
        this.educationListData.paginator = this.eduPaginator;
        this.educationListData.sort = this.sort.toArray()[1];
        this.skillsListData = new MatTableDataSource<any>(user.data.skillsList);
        this.skillsListData.paginator = this.skillPaginator;
        this.skillsListData.sort = this.sort.toArray()[2];
        this.licenseListData = new MatTableDataSource<any>(user.data.licenseList);
        this.licenseListData.paginator = this.licensePaginator;
        this.licenseListData.sort = this.sort.toArray()[3];
        this.languageListData = new MatTableDataSource<any>(user.data.languagesList);
        this.languageListData.paginator = this.languagePaginator;
        this.languageListData.sort = this.sort.toArray()[4];
        this.membershipListData = new MatTableDataSource<any>(user.data.memberShipList);
        this.membershipListData.paginator = this.memberShipPaginator;
        this.membershipListData.sort = this.sort.toArray()[5];
        this.trainingListData = new MatTableDataSource<any>(user.data.trainingList);
        this.trainingListData.paginator = this.trainingPaginator;
        this.trainingListData.sort = this.sort.toArray()[6];
        this.publicationListData = new MatTableDataSource<any>(user.data.publicationList);
        this.publicationListData.paginator = this.publicationPaginator;
        this.publicationListData.sort = this.sort.toArray()[7];
        this.awardListData = new MatTableDataSource<any>(user.data.awardList);
        this.awardListData.paginator = this.awardPaginator;
          this.awardListData.sort = this.sort.toArray()[8];
          this.isLoading = false;
          this.err = '';
      }
    }, error => {
      this.isLoading = false;
      this.toastr.error('Error...');
    });
  }
  public getEmpWe(): void {
    this.service.getEmpWe(this.employeeId).subscribe(user => {
      if (user && user.data) {
        this.isLoading = false;
        this.workExperienceList = new MatTableDataSource<any>(user.data.workExperienceList);
        this.workExperienceList.paginator = this.workPaginator;
        this.workExperienceList.sort = this.sort.toArray()[0];
        this.err = '';
      }
    }, error => {
      this.isLoading = false;
      this.toastr.error('Error...');
    });
  }
  public getEmpEdu(): void {
    this.service.getEmpEdu(this.employeeId).subscribe(user => {
      if (user && user.data) {
        this.isLoading = false;
        user.data.map(d => {
          if (this.educationList && this.educationList.length > 0) {
            const job = this.educationList.filter(f => {
              return f.id === d.level;
            })[0];
            d['level'] = job && job.name;
          }
        });
        this.educationListData = new MatTableDataSource<any>(user.data.educationsList);
        this.educationListData.paginator = this.eduPaginator;
        this.educationListData.sort = this.sort.toArray()[1];
        this.err = '';
      }
    }, error => {
      const _error = error.error && error.error.msg;
      this.isLoading = false;
    });
  }
  public getEmpLang(): void {
    const s = {
      r: 'Reading',
      w: 'Writing',
      s: 'Speaking'
    };
    const f1 = {
      b: 'Basic',
      g: 'Good',
      p: 'Poor'
    };

    this.service.getEmpLang(this.employeeId).subscribe(user => {
      if (user && user.data) {
        this.isLoading = false;
        user.data.map(d => {
          if (this.languageList.length) {
            const job = this.languageList.filter(f => {
              return f.id === d.language;
            })[0];
            d['language'] = job && job.name;
            d['fluencyLevel'] = f1[d['fluencyLevel']];
            d['skill'] = s[d['skill']];
          }
        });
        this.languageListData = new MatTableDataSource<any>(user.data.languagesList);
        this.languageListData.paginator = this.languagePaginator;
        this.languageListData.sort = this.sort.toArray()[4];
        this.err = '';
      }
    }, error => {
      const _error = error.error && error.error.msg;
      this.isLoading = false;
      // this.toastr.error(_error || 'Error in getting Work Experience');
    });
  }
  public getEmpLicense(): void {
    this.service.getEmpLicense(this.employeeId).subscribe(user => {
      if (user && user.data) {
        this.isLoading = false;
        user.data.map(d => {
          if (this.licenseList.length) {
            const job = this.licenseList.filter(f => {
              return f.id === d.license;
            })[0];
            d['license'] = job && job.name;
          }
        });
        this.licenseListData = new MatTableDataSource<any>(user.data.licenseList);
        this.licenseListData.paginator = this.licensePaginator;
        this.licenseListData.sort = this.sort.toArray()[3];
        this.err = '';
      }
    }, error => {
      const _error = error.error && error.error.msg;
      this.isLoading = false;
      // this.toastr.error(_error || 'Error in getting Work Experience');
    });
  }
  public getEmpSkill(): void {
    this.service.getEmpSkill(this.employeeId).subscribe(user => {
      if (user && user.data) {
        this.isLoading = false;
        user.data.map(d => {
          if (this.skillsList) {
            const job = this.skillsList.filter(f => {
              return f.id === d.skill;
            })[0];
            d['skill'] = job.name;
          }
        });
        this.skillsListData = new MatTableDataSource<any>(user.data.skillsList);
        this.skillsListData.paginator = this.skillPaginator;
        // this.skillsListData.sort = this.sort;
        this.err = '';
      }
    }, error => {
      const _error = error.error && error.error.msg;
      this.isLoading = false;
      // this.toastr.error(_error || 'Error in getting Work Experience');
    });
  }

  public submitAddJobCategory(opt): void {
    if (this.isEdit) {
      this.edit(opt);
    } else {
      this.add(opt);
    }
  }

  public add(opt): void {
    let url = '';
    let val;
    let error = '';
    let success = '';
    if (opt === 'we') {
      url = 'api/auth/employee/workExperience';
      val = this.workForm.value;
      error = 'Error in adding Work Experience';
      success = 'Work Experience added successfully';
    } else if (opt === 'edu') {
      url = 'api/auth/employee/education';
      val = this.educationForm.value;
      error = 'Error in adding Education';
      success = 'Education added successfully';
    } else if (opt === 'skills') {
      url = 'api/auth/employee/skill';
      val = this.skillsForm.value;
      success = 'Skills added successfully';
      error = 'Error in adding Skills';
    } else if (opt === 'lang') {
      url = 'api/auth/employee/language';
      error = 'Error in adding Language';
      success = 'Language added successfully';
      val = this.languageForm.value;
    } else if (opt === 'license') {
      url = 'api/auth/employee/license';
      error = 'Error in adding License';
      success = 'License added successfully';
      val = this.licenseForm.value;
    } else if (opt === 'membership') {
      url = 'api/auth/employee/memberShip';
      error = 'Error in adding Membership';
      success = 'MemberShip added successfully';
      val = this.membershipForm.value;
    } else if (opt === 'training') {
      url = 'api/auth/employee/EmpTraining';
      error = 'Error in adding Training';
      success = 'Training added successfully';
      this.trainingForm.value.empId = this.employeeId;
      val = this.trainingForm.value;
    } else if (opt === 'publication') {
      url = 'api/auth/employee/EmpPublication';
      error = 'Error in adding Publication';
      success = 'Publication added successfully';
      this.publicationsForm.value.empId = this.employeeId;
      val = this.publicationsForm.value;
    } else if (opt === 'award') {
      url = 'api/auth/employee/EmpAward';
      error = 'Error in adding Award';
      success = 'Award added successfully';
      this.awardForm.value.empId = this.employeeId;
      val = this.awardForm.value;
    }
    val['empId'] = this.employeeId;
    this.service.addEmpQualification(url, val).subscribe(user => {
      this.isUserAdded = false;
      this.toastr.success(success);
      this.dialogRef.close();
      this.educationForm.reset();
      this.languageForm.reset();
      this.skillsForm.reset();
      this.licenseForm.reset();
      this.workForm.reset();
      this.createWorkForm();
      this.membershipForm.reset();
      this.trainingForm.reset();
      this.awardForm.reset();
      this.getJobCategoryList(opt);
    }, error1 => {
      const err = error1.error && error1.error.msg;
      this.isUserAdded = false;
      this.toastr.error(err || error);
    });
  }

  public edit(opt): void {
    let url = '';
    let val;
    let error = '';
    let success = '';
    if (opt === 'we') {
      url = 'api/auth/employee/workExperience';
      val = this.workForm.value;
      error = 'Error in Editing Work Experience';
      success = 'Work Experience Edited successfully';
    } else if (opt === 'edu') {
      url = 'api/auth/employee/education';
      val = this.educationForm.value;
      error = 'Error in Editing Education';
      success = 'Education Edited successfully';
    } else if (opt === 'skills') {
      url = 'api/auth/employee/skill';
      val = this.skillsForm.value;
      success = 'Skills Editing successfully';
      error = 'Error in Edited Skills';
    } else if (opt === 'lang') {
      url = 'api/auth/employee/language';
      error = 'Error in Editing Language';
      success = 'Language Edited successfully';
      val = this.languageForm.value;
    } else if (opt === 'license') {
      url = 'api/auth/employee/license';
      error = 'Error in Editing License';
      success = 'License Edited successfully';
      val = this.licenseForm.value;
    } else if (opt === 'membership') {
      url = 'api/auth/employee/memberShip';
      error = 'Error in Editing Membership';
      success = 'Membership Edited successfully';
      val = this.membershipForm.value;
    } else if (opt === 'training') {
      url = 'api/auth/employee/EmpTraining';
      error = 'Error in Editing Training';
      success = 'Training Edited successfully';
      val = this.trainingForm.value;
    } else if (opt === 'publication') {
      url = 'api/auth/employee/EmpPublication';
      error = 'Error in Editing publication';
      success = 'Publication Edited successfully';
      val = this.publicationsForm.value;
    } else if (opt === 'award') {
      url = 'api/auth/employee/EmpAward';
      error = 'Error in Editing Award';
      success = 'Award Edited successfully';
      val = this.awardForm.value;
    }

    val['empId'] = this.employeeId;
      this.isUserAdded = true;
    this.service.editEmpQualification(url, val, this.editId).subscribe(user => {
      this.isUserAdded = false;
      this.toastr.success(success);
      this.dialogRef.close();
      this.educationForm.reset();
      this.languageForm.reset();
      this.skillsForm.reset();
      this.licenseForm.reset();
      this.workForm.reset();
      this.createWorkForm();
      this.membershipForm.reset();
      this.trainingForm.reset();
      this.publicationsForm.reset();
      this.awardForm.reset();
      this.getJobCategoryList(opt);
    }, error1 => {
      const err = error1.error && error1.error.msg;
      this.isUserAdded = false;
      this.toastr.error(err || error);
    });
  }

  public editUser(id, opt): void {
    this.isEdit = true;
    this.editId = id;
    let url = '';
    let val;
    let error = '';
    if (opt === 'we') {
      url = 'api/auth/employee/workExperience';
      val = this.workForm.value;
      error = 'Error in getting Work Experience';
    } else if (opt === 'edu') {
      url = 'api/auth/employee/education';
      val = this.educationForm.value;
      error = 'Error in getting Education';
    } else if (opt === 'skills') {
      url = 'api/auth/employee/skill';
      val = this.skillsForm.value;
      error = 'Error in getting Skills';
    } else if (opt === 'lang') {
      url = 'api/auth/employee/language';
      error = 'Error in getting Language';
      val = this.languageForm.value;
    } else if (opt === 'license') {
      url = 'api/auth/employee/license';
      error = 'Error in getting License';
      val = this.licenseForm.value;
    } else if (opt === 'membership') {
      url = 'api/auth/employee/memberShip';
      error = 'Error in getting Membership';
      val = this.membershipForm.value;
    } else if (opt === 'training') {
      url = 'api/auth/employee/EmpTraining';
      error = 'Error in getting Training';
      val = this.trainingForm.value;
    } else if (opt === 'publication') {
      url = 'api/auth/employee/EmpPublication';
      error = 'Error in getting Publication';
      val = this.publicationsForm.value;
    } else if (opt === 'award') {
      url = 'api/auth/employee/EmpAward';
      error = 'Error in getting Award';
      val = this.awardForm.value;
    }
    val['empId'] = this.employeeId;
    this.service.getEmpQualificationById(url, this.editId, this.employeeId).subscribe(user => {
      const value = user.data;

      if (opt === 'we') {
  
        value.from = value.from ? moment(value.from).format('YYYY-MM-DD') : '';
        value.to = value.to ? moment(value.to).format('YYYY-MM-DD') : '';
      } else if (opt === 'edu') {
        value.from = value.from ? moment(value.from).format('YYYY-MM-DD') : '';
        value.to = value.to ? moment(value.to).format('YYYY-MM-DD') : '';
      } else if (opt === 'license') {
        value.expDate = value.expDate ? moment(value.expDate).format('YYYY-MM-DD') : '';
        value.issDate =  value.issDate ? moment(value.issDate).format('YYYY-MM-DD') : '';
      } else if (opt === 'membership') {
        value.scd = value.scd ? moment(value.expDate).format('YYYY-MM-DD') : '';
        value.srd = value.srd ? moment(value.issDate).format('YYYY-MM-DD') : '';
      }

      this.educationForm.reset();
      this.languageForm.reset();
      this.skillsForm.reset();
      this.licenseForm.reset();
      this.workForm.reset();
      this.createWorkForm();
      this.membershipForm.reset();
      this.publicationsForm.reset();
      this.trainingForm.reset();
      this.awardForm.reset();
      this.setAny(opt, user.data);
      // this.getJobCategoryList(opt);
    }, error1 => {
   
      const err = error1.error && error1.error.msg;
      this.isUserAdded = false;
      this.toastr.error(err || error);
    });
  }

  public deleteUserForm(id, opt): void {
    this.deleteId = id;
    this.choice = opt;
    this.dialogRef = this.dialog.open(this.deleteUser , {
      width: '440px',
      height: 'auto'
  });
  }

  public deleteJobCategory(): void {
    let url = '';
    let val;
    let error = '';
    let success = '';
    if (this.choice === 'we') {
      url = 'api/auth/employee/workExperience';
      val = this.workForm.value;
      error = 'Error in deleting Work Experience';
      success = 'Work Experience Deleted successfully';
    } else if (this.choice === 'edu') {
      url = 'api/auth/employee/education';
      val = this.educationForm.value;
      error = 'Error in deleting Education';
      success = 'Education Deleted successfully';
    } else if (this.choice === 'skills') {
      url = 'api/auth/employee/skill';
      val = this.skillsForm.value;
      error = 'Error in deleting Skills';
      success = 'Skills Deleted successfully';
    } else if (this.choice === 'lang') {
      url = 'api/auth/employee/language';
      error = 'Error in Deleted Language';
      val = this.languageForm.value;
      success = 'Language Deleted successfully';
    } else if (this.choice === 'license') {
      url = 'api/auth/employee/license';
      error = 'Error in deleting License';
      val = this.licenseForm.value;
      success = 'License Deleted successfully';
    } else if (this.choice === 'membership') {
      url = 'api/auth/employee/memberShip';
      error = 'Error in deleting Membership';
      val = this.membershipForm.value;
      success = 'Membership Deleted successfully';
    } else if (this.choice === 'training') {
      url = 'api/auth/employee/EmpTraining';
      error = 'Error in deleting Training';
      val = this.trainingForm.value;
      success = 'Training Deleted successfully';
    } else if (this.choice === 'publication') {
      url = 'api/auth/employee/EmpPublication';
      error = 'Error in deleting Publication';
      val = this.publicationsForm.value;
      success = 'Publication Deleted successfully';
    } else if (this.choice === 'award') {
      url = 'api/auth/employee/EmpAward';
      error = 'Error in deleting Award';
      val = this.awardForm.value;
      success = 'Award Deleted successfully';
    }

    this.service.deleteEmpQualification(url, this.employeeId, this.deleteId).subscribe(user => {
      if (user) {
        this.close();
        this.getJobCategoryList(this.choice);
        this.toastr.success(success);
      }
    }, error1 => {
      const err = error1.error && error1.error.msg;
      this.toastr.error(err || error);
    });
  }

  public setAny(opt, patchData?: any): void {
    this.educationForm.reset();
      this.languageForm.reset();
      this.skillsForm.reset();
      this.licenseForm.reset();
      this.workForm.reset();
      this.createWorkForm();
      this.membershipForm.reset();
      this.trainingForm.reset();
      this.awardForm.reset();
      this.publicationsForm.reset();
    if (opt === 'we') {
      if (patchData) {
        this.workForm.patchValue(patchData);
      }
      this.dialogRef = this.dialog.open(this.addWork, {
        width: '560px',
        height: 'auto'
    });
    } else if (opt === 'edu') {
      if (patchData) {
        this.educationForm.patchValue(patchData);
      }
      this.dialogRef = this.dialog.open(this.addEducation, {
        width: '560px',
        height: 'auto'
    });
    } else if (opt === 'skills') {
      if (patchData) {
        this.skillsForm.patchValue(patchData);
      }
      this.dialogRef = this.dialog.open(this.addSkills, {
        width: '560px',
        height: 'auto'
    });
    } else if (opt === 'lang') {
      if (patchData) {
        this.languageForm.patchValue(patchData);
      }
      this.dialogRef = this.dialog.open(this.addLanguage, {
        width: '560px',
        height: 'auto'
    });
    } else if (opt === 'license') {
      if (patchData) {
        this.licenseForm.patchValue(patchData);
      }
      this.dialogRef = this.dialog.open(this.addLicense, {
        width: '560px',
        height: 'auto'
      });
    } else if (opt === 'membership') {
      if (patchData) {
        this.membershipForm.patchValue(patchData);
      }
      this.dialogRef = this.dialog.open(this.addmembership, {
        width: '560px',
        height: 'auto'
      });
    } else if (opt === 'training') {
      if (patchData) {
        this.trainingForm.patchValue(patchData);
      }
      this.dialogRef = this.dialog.open(this.addTraining, {
        width: '560px',
        height: 'auto'
      });
    } else if (opt === 'publication') {
      if (patchData) {
        this.publicationsForm.patchValue(patchData);
      }
      this.dialogRef = this.dialog.open(this.addPublications, {
        width: '560px',
        height: 'auto'
      });
    } else if (opt === 'award') {
      if (patchData) {
        this.awardForm.patchValue(patchData);
      }
      this.dialogRef = this.dialog.open(this.addaward, {
        width: '560px',
        height: 'auto'
      });
    }
  }

  public notificationOutsideClick(ele: string) {
    if (this.profileNotification1 === 'an-animate') {
      this.toggleProfileNotification();
    }
  }

  public toggleProfileNotification() {
    this.profileNotification1 = this.profileNotification1 === 'an-off' ? 'an-animate' : 'an-off';
    this.profileNotificationClass1 = this.profileNotification1 === 'an-animate' ? 'active' : '';
  }

  public getJobCategoryList(opt): void {
    this.getAllQuali();
  }

  public loadAttach = false;
  onAddAttachment(id) {
    this.loadAttach  = true;
    this.attacherId = id;
    this.dialogRef = this.dialog.open(this.attach, {
      width: '440px',
      height: 'auto'
  });
    this.service.getAttachmentList(this.attacherId).subscribe(
        r => {
          if (r && r.data) {
            this.attachmentList = r.data;
            this.loadAttach  = false;
           
          }
        },
        error => {
          this.loadAttach  = false;
        }
    );
    this.comment = '';
    this.fileType = '';
    this.fileObj = null;

  }

  fileChange($event, type): void {
    this.uploadedFile = undefined;
    const reader = new FileReader();
    const file = $event.target.files[0];
    this.uploadedFile = file;
    const fileName = file && file.name;
    if (file && (file.size / 1024) < 2048) {
      this.fileObj = {
        added: new Date(),
        addedBy: 'string',
        desc: this.comment,
        docType: 'dd',
        empId: this.attacherId,
        fileName: fileName,
        size: file.size,
        url: ''
      };
    } else {
      this.fileError = 'Max size 2 MB';
      this.toastr.error('Max 2Mb File allowed.');
    }
  }

  onDownloadClicked(attachment: any) {
    const URL = attachment['url'];
    const FILE_NAME = attachment['fileName'];
    window.open(URL);
  }

  onDeleteClicked(attachment: any) {
    const ID = attachment['id'];
    this.service.deleteAttachment(ID).subscribe(
        res => {
          this.service.getAttachmentList(this.attacherId).subscribe(
              r => {
                this.attachmentList = r.data;
                this.getAllQuali();
                this.toastr.success('Deleted');
              },
              error => {
                console.log(error);
                this.toastr.error('Failed to delete');
              }
          );
        }
    );
  }

  saveAttachment() {
    this.loadAttach = true;
    if (this.fileObj !== null) {
      this.service.uploadFile(this.uploadedFile).subscribe(resp => {
        if (resp && resp.data) {
          this.fileObj['url'] = resp.data.filename;
          this.fileObj['desc'] = this.comment;
          this.service.addAttachment(this.fileObj).subscribe(
              res => {
                this.service.getAttachmentList(this.attacherId).subscribe(
                    r => {
                      this.attachmentList = r.data;
                      this.toastr.success('Attachment Added');
                      this.loadAttach = false;
                      this.dialogRef.close();
                      this.getAllQuali();
                     
                    },
                    error => {
                      this.toastr.error('Failed to add Attachment');
                      this.loadAttach = false;
                      this.dialogRef.close();
                    }
                );
              },
              error => {
                this.toastr.error('Failed to add Attachment');
                this.loadAttach = false;
                this.dialogRef.close();
              }
          );
        }
      }, error => {
        const err = error.error && error.error.msg;
        console.log(err);
        this.toastr.error('Failed to save Attachment', 'Upload Unsuccessful');
        this.dialogRef.close();
      });
    } else {
      this.toastr.error('No file Selected');
    }
  }
}

