import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DashboardService} from '../../../../dashboard.service';
import { NotificationService } from "src/app/core/services/notification.service";

import {
    DayService,
    DragAndDropService,
    EventSettingsModel,
    ResizeService,
    ScheduleComponent,
    TimelineYearService,
    YearService
} from "@syncfusion/ej2-angular-schedule";
import {DatePipe} from "@angular/common";
import {MatDialog} from "@angular/material/dialog";

@Component({
    selector: 'app-leave-calendar-year',
    templateUrl: './leave-calendar-year.component.html',
    styleUrls: ['./leave-calendar-year.component.scss'],
    providers: [DayService, YearService, TimelineYearService, ResizeService, DragAndDropService]
})
export class LeaveCalendarYearComponent implements OnInit {

   public yearsList : Array<any> = [
    {
        label: "2022", 
        value :2022},
        {label: "2021", 
       value :2021 },
        {label: "2020", 
        value :2020 },
        {label: "2019", 
        value :2019 },
    ]

    public dialogRef: any;
    @ViewChild('addCourse', {static: false}) addCourse;
    public filterYear: FormGroup;
    public filteredOptions: Array<any>;
    public employeeList = [];
    public correct = false;
    public err = "";
    public selectedDate = new Date(2021, 0, 1);
    @ViewChild('scheduleObj')
    public scheduleObj: ScheduleComponent;
    public eventSettings: EventSettingsModel = {};
    public isSelected: Boolean = true;
    public isLoading = false;
    public isNotShow = true;
    public legends = [];
    constructor( public dialog: MatDialog , private service: DashboardService, private fb: FormBuilder, private toastr: NotificationService, public datepipe: DatePipe) {
        this.createForm();

      
        this.service.getLegend().subscribe(resp => {
            if (resp && resp.data) {
                console.log("col : " , resp.data);
               this.legends = resp?.data;
            }
        });
    }

    public createForm(): void {
        this.filterYear = this.fb.group({
            year: [''],
            empId: ['', Validators.required],
            makeDefaultFilter: false
        });
    }
    
    ngOnInit() {

        this.isLoading = true ;
        this.service.getEmployeeDetailList().subscribe(resp => {
            if (resp && resp.data) {
                resp.data.map(e => {
                    this.employeeList.push({label: e.id + ' ' + e.name, value: e.id});
                });
                this.initValuesDetection();
                this.service.getCalandarFilter('Y').subscribe(res => {
                    if (res && res.data) {
                        if(res.data.makeDefaultFilter){
                            this.filterYear.patchValue({
                                year: res?.data?.year,
                                empId: res?.data?.empId[0],
                                makeDefaultFilter: false 
                            })
                           
                        }
                        this.isLoading = false ;
                       
                    }
                });
                
            }
        }, error => {
            this.isLoading = false ;
            this.toastr.error('Error in Getting Informations .. !');
          });

      
       
    }

    initValuesDetection() {
        this.filteredOptions = [];

        this.filterYear.controls['empId'].valueChanges.subscribe(change => {

            if (change == null || change == undefined) {
                this.correct = true;

            } else {

                this.filterEmployees(change);
            }

        });
    }


    protected filterEmployees(change: String) {
        this.filteredOptions =[];
        if (!this.employeeList) {
            return;
        }

        // get the search keyword
        if (!change) {
            this.correct = true;
            this.filteredOptions = [];
            return;
        } else {
            this.checkEmployeeChange(change);
            
        }
        // filter the search
        this.filteredOptions =
            this.employeeList.filter(emp => emp.label.toLowerCase().indexOf(change) > -1);

    }

    checkEmployeeChange(change) {
        let x = this.filteredOptions.find(x => x.value === change);
        if (!x) {
            this.correct = false;
        }

    }

    public displayEmployeeByName(option) {
        if (!option) {
            return '';
        }
        this.correct = true;

        return this.employeeList.find(x => x.value === option).label;
    }

    public rest() {
        this.filterYear = this.fb.group({
            year: '',
            empId: '',
            makeDefaultFilter: true
        });
        this.submitAddUser();
        this.filterYear = this.fb.group({
            year: '',
            empId: '',
            makeDefaultFilter: false
        });
        this.filteredOptions = this.employeeList;
    }

    getCalander () {
        this.isLoading = true;
        this.selectedDate = new Date(this.filterYear.value.year ? parseInt(this.filterYear.value.year) : 2021, 0, 1);
        var  y = this.selectedDate.getFullYear(), m = this.selectedDate.getMonth();
        var firstDay = new Date(y, m, 0);
        var lastDay = new Date(y, m + 11, 0);

        const body = {
            year: this.filterYear.value?.year ? parseInt(this.filterYear.value?.year) : null,
            empId: [this.filterYear.value?.empId],
            makeDefaultFilter: this.filterYear.value?.makeDefaultFilter,
            mod : 'Y',
            startDate : firstDay,
            endDate : lastDay,
            includePastEmployees : false,
            hideLeavesOfSupervisors : false
        };
        this.service.getLeaveCalendar(body).subscribe(res => {
            if (res && res.data) {
                let arr = [{
                    Id: 403,
                    Subject: `${'Test'} / ${'welcome test'}`,
                    StartTime: new Date('2021-06-02T21:00:00.000+0000'),
                    EndTime: new Date('2021-06-02T21:00:00.000+0000'),
                    IsAllDay: true,
                    EventColor: '#748ffc',
                }];

                res?.data?.map(value => {
                    let obj: any = {
                        Id: value?.empId,
                        Subject: `${value?.leaveType} / ${value?.leaveStatus}`,
                        StartTime: new Date(value?.date),
                        EndTime: new Date(value?.date),
                        IsAllDay: true,
                        EventColor: value?.color ? value?.color : '#748ffc',
                    };
                    arr.push(obj);
                });
                this.eventSettings = {
                    dataSource: arr,
                    editFollowingEvents: false,
                    allowAdding: false,
                    allowDeleting: false,
                    allowEditing: false,
                };
                this.isLoading = false;
                this.isNotShow = false;
            }
        }, error => {
            const _err = error.error && error.error.msg;

            this.err = 'Error while adding Calender';
            this.toastr.error(_err || this.err);
            this.isLoading = false;
            this.isNotShow = true;
        });
    }
    submitAddUser() {
        this.getCalander();
    }


    public months: { [key: string]: Object }[] = [
        {text: 'January', value: 0},
        {text: 'February', value: 1},
        {text: 'March', value: 2},
        {text: 'April', value: 3},
        {text: 'May', value: 4},
        {text: 'June', value: 5},
        {text: 'July', value: 6},
        {text: 'August', value: 7},
        {text: 'September', value: 8},
        {text: 'October', value: 9},
        {text: 'November', value: 10},
        {text: 'December', value: 11}
    ];


    public onEventRendered(args: any): void {
        const eventColor: string = args.data.EventColor as string;
        if (!args.element || !eventColor) {
            return;
        } else {
            args.element.style.backgroundColor = eventColor;
            console.log("args : ", args);
        }
    }

    addNewCourse() {
        this.dialogRef = this.dialog.open(this.addCourse, {
            width: '420px',
            height: 'auto'
        });

    }
    public close(): void {
            this.dialogRef.close();
    }

    public isChange : boolean = false;
    public save(): void {
        this.isChange = true;
    }

    public save1(): void {
        this.service.changeLegend(this.legends).subscribe(res => {
            if (res && res.data) {
                this.dialogRef.close();
                if(this.filterYear.value?.empId){
                    this.getCalander();
                }
                this.isChange = false;
            }
        }, error => {
            const _err = error.error && error.error.msg;
            this.err = 'Error while changing Legend';
            this.toastr.error(_err || this.err);
            this.isLoading = false;
            this.isNotShow = true;
        });

    }



}
