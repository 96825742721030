import { Component, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { DashboardService } from "../../../dashboard.service";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NotificationService } from "src/app/core/services/notification.service";
import * as moment from "moment";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatCheckbox, MatCheckboxChange } from "@angular/material/checkbox";
import { AuthService } from "src/app/core/services/auth.service";

@Component({
  selector: "app-holidays",
  templateUrl: "./holidays.component.html",
  styleUrls: ["./holidays.component.scss"],
})
export class HolidaysComponent implements OnInit {
  public rowsOnPage = 10;
  public filterQuery = "";
  public sortBy = "";
  public sortOrder = "desc";

  public userList: Array<any>;
  public isLoading: boolean;

  public totalRecords: number;
  public activePage: number;
  public pageSize: number;
  public isUserAdded: boolean;
  public err: string;
  // public modalRef: any;
  public dialogRef: any;
  public userForm: FormGroup;
  public isEdit: boolean;
  public editId: string;
  public countrySelected: string = "";
  public lengthSelected: string = "";
  public countrySelectedFilter: string = "";
  public deleteId: string;
  public locationList: Array<any>;
  public filterForm: FormGroup;
  public showDate = "";
  selectedCheckBoxList = [];
  @ViewChild("addUser", { static: false }) addUser;
  @ViewChild("deleteUser", { static: false }) deleteUser;
  @ViewChild("areYouSure", { static: false }) areYouSure;
  @ViewChild("areYouSureDelete", { static: false }) areYouSureDelete;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
  @ViewChild("filterHolidays", { static: false }) filterHolidays;
  public countryList = [];
  submitted = false;
  ngbModalOptions: NgbModalOptions = {
    backdrop: "static",
    keyboard: false,
  };
  ngbModalOptionsWithSize: NgbModalOptions = {
    backdrop: "static",
    keyboard: false,
    size: "lg",
  };

  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  paging = {
    pageSize: 10,
    totalRecords: 0,
    pageIndex: 0,
    pageSizeOptions: [10, 25, 50, 100],
  };
  public columns = ["delete", "holiday", "holidaydate", "recurring", "countryName", "locationName", "edit"];

  constructor(
    private service: DashboardService,
    private readonly authService: AuthService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private toastr: NotificationService
  ) {
    this.createForm();
  }
  public permissions: any;
  public read = false;
  public write = false;
  public update = false;
  public delete = false;

  checkPermission(module, tabs): void {
    this.permissions = JSON.parse(this.authService.getPermissions());

    this.read = false;
    this.write = false;
    this.delete = false;
    this.update = false;
    if (this.permissions != undefined && this.permissions != null) {
      for (let p of this.permissions) {
        if (p.id === module) {
          if (p.modules != undefined) {
            for (let tab of p.modules) {
              if (tab.id === tabs) {
                for (let move of tab.permits) {
                  if (move.id === "u") {
                    this.update = true;
                  } else if (move.id === "r") {
                    this.read = true;
                  } else if (move.id === "c") {
                    this.write = true;
                  } else if (move.id === "d") {
                    this.delete = true;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  public sortDirection = "asc";

  public tableConfig = {
    holiday: true,
    description: true,
    recurring: true,
    holidaydate: true,
    length: true,
    operational_country_code: true,
    status: true,
  };
  public chipList: any = {
    locations: [],
    locationss: [],
  };
  remove(id, name) {
    this.chipList[name] = this.chipList[name]?.filter((item) => item?.id !== id);
    if (name === "locations") {
      let res = this.userForm.value[name]?.filter((item) => item !== id);
      let newObj = {};
      newObj[name] = res;
      this.userForm.patchValue(newObj);
    } else name === "locationss";
    {
      let res = this.filterForm.value[name]?.filter((item) => item !== id);
      let newObj = {};
      newObj[name] = res;
      this.filterForm.patchValue(newObj);
    }
  }
  change(op, name) {
    if (name === "locations") {
      var result = this.locationList?.filter((item) => op?.value?.includes(item.id));
      this.chipList[name] = result;
    } else if (name === "locationss") {
      var result = this.locationList?.filter((item) => op?.value?.includes(item.id));
      this.chipList[name] = result;
    }
  }
  ngOnInit() {
    this.isLoading = true;
    this.checkPermission("leave", "le_ccnf");
    this.isEdit = false;
    this.activePage = 1;
    this.pageSize = 10;
    this.totalRecords = 10;
    this.getJobTitleList(this.paging.pageIndex + 1, this.paging.pageSize);
    this.isUserAdded = false;
    this.service.getLocationList().subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.locationList = resp.data;
          this.locationList.map((l) => {
            l["text"] = l["location"];
            l["id"] = l["id"];
          });
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error in getting location list");
      }
    );
    this.service.getCountriesList().subscribe((resp) => {
      if (resp && resp.data) {
        this.countryList = resp.data;
      }
    });
  }

  public createForm(): void {
    this.userForm = this.fb.group({
      description: [""],
      holiday: ["", Validators.required],
      holidaydate: ["", Validators.required],
      length: [""],
      locations: [],
      operational_country_code: [""],
      recurring: false,
    });

    this.filterForm = this.fb.group({
      countryIso: [""],
      dateFrom: [""],
      dateTo: [""],
      locations: [],
    });
  }

  onCountryChange(): void {
    this.locationList = [];
    this.userForm.patchValue({
      locations: [],
    });
    this.locationList.push({
      text: "Loading ..",
      id: null,
    });

    if (this.userForm.value.operational_country_code != null && this.userForm.value.operational_country_code != "") {
      this.service.filterLocationByCountry(this.userForm.value.operational_country_code).subscribe(
        (res) => {
          if (res && res.data) {
            this.locationList = res.data;
            this.locationList.map((l) => {
              l["text"] = l["location"];
              l["id"] = l["id"];
            });
            console.log("tempLocations = ", this.tempLocations);

            if (this.tempLocations.length != 0) {
              let temp = [];
              temp = this.locationList.filter((loc) => this.tempLocations.includes(loc.id));
              if (temp.length != 0) {
                this.userForm.patchValue({
                  locations: this.tempLocations,
                });
                this.chipList.locations = temp;
              }
              console.log("tempLocations = ", this.chipList.locations);
            }
            console.log("hipList.locations = ", this.chipList.locations);
          }
        },
        (error) => {
          const err = error.error && error.error.msg;
          this.toastr.error(err || "Error while getting Locations by Country");
          this.locationList = [];
        }
      );
    } else {
      this.locationList = [];
      return;
    }
  }
  onDateChanged(): void {
    this.showDate = "on " + moment(this.userForm.value.holidaydate).format("MMM DD");
  }
  public addNewUser() {
    this.showDate = "";
    this.countrySelected = "";
    this.lengthSelected = "";
    this.isEdit = false;
    this.dialogRef = this.dialog.open(this.addUser, {
      width: "768px",
      height: "auto",
    });
    this.userForm.reset();
    this.chipList.locations = [];
  }

  public close(): void {
    this.dialogRef.close();
  }

  public getJobTitleList(pageIndex, pageSize): void {
    this.service.getHolidayList(this.activePage, this.pageSize).subscribe(
      (user) => {
        if (user && user.data) {
          this.isLoading = false;
          this.userList = user.data;
          this.userList.map((u) => {
            u.locationName = "";
            if (u.locationsNames) {
              u.locationsNames.map((v) => {
                u.locationName = u.locationName + v + " , ";
              });
              u.locationName = u.locationName.slice(0, -2);
            }

            if (u.recurring) {
              u.recurring = "Yes";
            }

            if (!u.recurring) {
              u.recurring = "No";
            }

            if (u.length === "FullDay") {
              u.length = "Full Day";
            }
            if (u.length === "HalfDay") {
              u.length = "Half Day";
            }
          });
          this.err = "";
          this.dataSource = new MatTableDataSource<any>(this.userList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error while getting Holidays");
        this.isLoading = false;
        this.err = "Error while getting Holidays";
      }
    );
  }

  public submitAddJobTitle(): void {
    if (this.isEdit) {
      this.edit();
    } else {
      this.add();
    }
  }

  public add(): void {
    this.submitted = true;
    if (this.userForm.invalid === true) {
      return;
    }

    if (this.userForm.value.locations.length === 0) {
      this.toastr.error("No Locations Selected !");
      return;
    }
    this.isUserAdded = true;
    this.userForm.value["status"] = "ACTIVE";
    this.service.addHoliday(this.userForm.value).subscribe(
      (user) => {
        this.isUserAdded = false;
        this.toastr.success("Holiday added successfully");
        this.dialogRef.close();
        this.userForm.reset();
        this.getJobTitleList(this.paging.pageIndex + 1, this.paging.pageSize);
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.isUserAdded = false;
        this.toastr.error(err || "Error in adding Holiday.Please try again.");
      }
    );
  }

  public edit(): void {
    if (this.userForm.value.locations.length === 0) {
      this.toastr.error("No Locations Selected !");
      return;
    }
    this.isUserAdded = true;

    this.service.editHoliday(this.userForm.value, this.editId).subscribe(
      (user) => {
        this.isUserAdded = false;
        this.toastr.success("Holiday updated successfully");
        this.dialogRef.close();
        this.getJobTitleList(this.paging.pageIndex + 1, this.paging.pageSize);
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.isUserAdded = false;
        this.toastr.error(err || "Error in updating Holiday.Please try again.");
      }
    );
  }

  public tempLocations = [];
  editHoliday(id) {
    this.editId = "";
    this.isUserAdded = true;
    this.isEdit = true;
    this.dialogRef = this.dialog.open(this.addUser, {
      width: "768px",
      height: "auto",
    });
    this.service.getHolidayListById(id).subscribe(
      (user) => {
        if (user && user.data) {
          this.tempLocations = [];
          this.chipList.locations = [];
          this.tempLocations = user.data.locations;
          user.data["holidaydate"] = moment(user.data["holidaydate"]).format("YYYY-MM-DD");
          this.userForm.patchValue(user.data);
          this.showDate = "on " + moment(this.userForm.value.holidaydate).format("MMM DD");
          this.countrySelected = user.data.operational_country_code;
          this.lengthSelected = user.data.length;
          this.userForm.value.locations = user.data.locations;

          this.editId = id;
          this.isUserAdded = false;
        }
      },
      (error) => {
        this.isUserAdded = false;
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error getting Holiday");
      }
    );
  }

  editHolidays() {
    this.close();
    this.isUserAdded = true;
    var id = this.editId;
    this.dialogRef = this.dialog.open(this.addUser, {
      width: "768px",
      height: "auto",
    });
    this.service.getHolidayListById(id).subscribe(
      (user) => {
        if (user && user.data) {
          this.tempLocations = [];
          this.chipList.locations = [];
          this.tempLocations = user.data.locations;
          user.data["holidaydate"] = moment(user.data["holidaydate"]).format("YYYY-MM-DD");
          this.userForm.patchValue(user.data);
          this.showDate = "on " + moment(this.userForm.value.holidaydate).format("MMM DD");
          this.countrySelected = user.data.operational_country_code;
          this.lengthSelected = user.data.length;
          this.editId = id;
          this.isUserAdded = false;
        }
      },
      (error) => {
        this.isUserAdded = false;
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error getting Holiday");
      }
    );
  }

  public editUser(id, state): void {
    this.isEdit = true;
    this.countrySelected = "";
    this.lengthSelected = "";
    if (state === "Yes") {
      this.editId = id;
      this.dialogRef = this.dialog.open(this.areYouSure, {
        width: "auto",
        height: "auto",
      });
    } else {
      this.editHoliday(id);
    }
  }

  public deleteUserForm(id): void {
    this.deleteId = id;
    this.dialogRef = this.dialog.open(this.deleteUser, {
      width: "440px",
      height: "auto",
    });
  }

  onCheckBoxChange($event: MatCheckboxChange, id: any) {
    if (!this.alreadySelected($event.source) && $event.checked) {
      this.selectedCheckBoxList.push($event.source.value);
    } else if (!$event.checked) {
      this.selectedCheckBoxList = this.selectedCheckBoxList.filter((value) => {
        return value !== $event.source.value;
      });
    }
  }

  alreadySelected(item: MatCheckbox): boolean {
    this.selectedCheckBoxList.forEach((value) => {
      if (item === value) {
        return true;
      }
    });
    return false;
  }

  onSelectAllClicked() {
    this.selectedCheckBoxList = [];
    this.matCheckBoxes.forEach((item) => {
      this.selectedCheckBoxList.push(item.value);
      item.checked = true;
    });
  }

  onDeSelectAllClicked() {
    this.selectedCheckBoxList = [];
    this.matCheckBoxes.forEach((item) => {
      item.checked = false;
    });
  }

  onDeleteSelectedAre(): void {
    for (let box of this.selectedCheckBoxList) {
      for (let holiday of this.userList) {
        if (box === holiday.id) {
          if (holiday.recurring === "Yes") {
            this.dialogRef = this.dialog.open(this.areYouSureDelete, {
              width: "440px",
              height: "auto",
            });
            return;
          }
        }
      }
    }

    this.dialogRef = this.dialog.open(this.deleteUser, {
      width: "440px",
      height: "auto",
    });
  }

  public deleteMultiple(): void {
    this.service.deleteMultipleHolidays(this.selectedCheckBoxList).subscribe(
      (user) => {
        if (user) {
          this.close();
          this.getJobTitleList(this.paging.pageIndex + 1, this.paging.pageSize);
          this.toastr.success("Holidays deleted successfully !");
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.close();
        this.getJobTitleList(this.paging.pageIndex + 1, this.paging.pageSize);
        this.toastr.error(err || "Error deleting Holidays");
      }
    );
    this.selectedCheckBoxList = [];
  }

  invalidHolidayName() {
    return this.submitted && this.userForm.controls.holiday.errors != null;
  }

  invalidHolidayDuration() {
    return this.submitted && this.userForm.controls.length.errors != null;
  }

  invalidHolidayDate() {
    return this.submitted && this.userForm.controls.holidaydate.errors != null;
  }

  exportToCsv() {}

  saveAsPdf() {}
  onModelChange(value: string) {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  openfilter() {
    this.dialogRef = this.dialog.open(this.filterHolidays, {
      width: "560px",
      height: "auto",
    });
    this.onCountryChangeForFilter();
  }

  OnFilterReset() {
    this.filterForm.reset();
    this.chipList.locationss = [];
    this.countrySelectedFilter = "";
    this.getJobTitleList(this.activePage, this.pageSize);
    this.toastr.success("Reseted Successfully");
  }
  onCountryChangeForFilter(): void {
    this.filterForm.value.locations = [];
    this.service.filterLocationByCountry(this.filterForm.value.countryIso).subscribe(
      (res) => {
        if (res && res.data) {
          this.locationList = res.data;
          this.locationList.map((l) => {
            l["text"] = l["location"];
            l["id"] = l["id"];
          });
        }
      },
      (error) => {}
    );
  }
  Search() {
    this.isLoading = true;
    if (this.filterForm.value.countryIso === "" || this.filterForm.value.countryIso === null) {
      this.filterForm.value.countryIso = null;
    }
    if (this.filterForm.value.dateFrom === "" || this.filterForm.value.dateFrom === null) {
      this.filterForm.value.dateFrom = null;
    }
    if (this.filterForm.value.dateTo === "" || this.filterForm.value.dateTo === null) {
      this.filterForm.value.dateTo = null;
    }
    if (
      this.filterForm.value.locations[0] === "" ||
      this.filterForm.value.locations[0] === undefined ||
      this.filterForm.value.locations === null
    ) {
      this.filterForm.value.locations = null;
    }
    this.service.filterHolidays(this.filterForm.value).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.dialogRef.close();
          this.toastr.success("Holidays Filtered Successfully");
          this.userList = resp.data;
          this.userList.map((u) => {
            u.locationName = "";
            if (u.locationsNames) {
              u.locationsNames.map((v) => {
                u.locationName = u.locationName + v + " , ";
              });
              u.locationName = u.locationName.slice(0, -2);
            }
          });
          this.err = "";
          this.isLoading = false;
          this.dataSource = new MatTableDataSource<any>(this.userList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      },
      (error) => {
        this.isLoading = false;
        this.toastr.error("Error while filtering Request");
      }
    );
  }
}
