
<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
      <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
          <div class="col-md-8">
          <div class="page-header-title">
              <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted">Audit Trail</h4></span>
          </div>
          </div>
          <div class="col-md-4"></div>
      </div>
      </div>
  </div>
  </div>

<div class="page-body no-title">
  <div class="row">
    <div class="col-sm-12">
      <div class="row top-tools">
        <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6 left-tools">
          <span class="export-icon-btn csv" (click)="exportToCsv()"><i class="material-icons">get_app</i><span>CSV</span>
          </span>
          <span class="export-icon-btn pdf" (click)="saveAsPdf()"><i class="material-icons">get_app</i><span>PDF</span>
          </span>
  
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
          <div class="relative-position search-filter-con"> 
            <span class="mytooltip cus-left tooltip-effect-4">
              <span class="tooltip-item">
                  <button mat-mini-fab class="btn btn-primary  light add-rounded margin-button-05" (click)="filterAuditList()">
                      <i class="fa fa-filter"></i>
                  </button>
              </span>
              <span class="tooltip-content clearfix">
              <span class="tooltip-text">filter</span>
              </span>
           </span>

          </div>
        </div>
      </div>
      <app-card [title]="'Audit Trial'">
        <div class="dt-responsive" *ngIf="dataSource" style="white-space: pre-line">
          <div class="table-responsive">
            <mat-card-content>
              <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="dateAndTime">
                  <mat-header-cell style="width:15%"*matHeaderCellDef mat-sort-header >Date and Time</mat-header-cell>
                  <mat-cell style="width:15%" *matCellDef="let row" >
                    {{row.timeStamp |date : 'yyyy-MM-dd'}}
                    {{row.timeStamp |date : 'HH:mm:ss'}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actionOwner">
                  <mat-header-cell style="width:15%" *matHeaderCellDef mat-sort-header >Action Owner</mat-header-cell>
                  <mat-cell style="width:15%" *matCellDef="let row">
                    {{row.actionOwner}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Action">
                  <mat-header-cell style="width:15%" *matHeaderCellDef >Action</mat-header-cell>
                  <mat-cell style="width:15%" *matCellDef="let row">
                    {{row.action}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="emp">
                  <mat-header-cell style="width:15%" *matHeaderCellDef >Employee</mat-header-cell>
                  <mat-cell style="white-space: pre;" style="width:15%" *matCellDef="let row">
                    {{row.effectedEmpsAsString }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="sec">
                  <mat-header-cell style="width:9%" *matHeaderCellDef >Section</mat-header-cell>
                  <mat-cell style="width:9%" *matCellDef="let row">
                    {{row.section}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actionDesc">
                  <mat-header-cell style="width:30%" *matHeaderCellDef >Action Description</mat-header-cell>
                  <mat-cell  style="width:30%" *matCellDef="let row">
                    {{row.actionDesc}}
                  </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columns;">
                </mat-row>
              </mat-table>
              <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
              <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
            </mat-card-content>
          </div>
          <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
        </div>
      </app-card>
    </div>
  </div>
</div>

<ng-template #filterAuditTrial let-modal>
  <div class="mat-modal-header">
    <h4 class="mat-title-modal">Filter</h4>
</div>
<div class="mat-modal-body">
    <form [formGroup]="auditFilterForm" action="javascript:" class="form-material full-width-inputs">
        <div class="row form-group">
            <div class="col-md-6">
                <mat-form-field class="form-element">
                    <mat-label class="col-form-label">Module</mat-label>
                    <mat-select disableOptionCentering class="form-control" formControlName="module" (ngModelChange)="getSectionOnModuleChange()">
                        <mat-option  [value]="'Admin'">
                           Admin</mat-option>
                           <mat-option  [value]="'pdm'">
                            PDM</mat-option>
                            <mat-option  [value]="'leave'">
                              Leave</mat-option>
                              <mat-option  [value]="'time'">
                                Time</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="form-element">
                    <mat-label class="col-form-label">Section</mat-label>
                    <mat-select disableOptionCentering  class="form-control" formControlName="section">
                        <mat-option *ngFor="let r of sectionsList" [value]="r">
                            {{r}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            

        </div>
        <div class="row form-group">
          
        <div class="col-md-6">
          <mat-form-field class="form-element">
            <mat-label>From</mat-label>
            <input [matDatepicker]="picker1" class="form-control"
            [(ngModel)]= "this.fromDate" formControlName="from" id="from" matInput>
            <mat-datepicker-toggle [for]="picker1"matSuffix></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="form-element">
            <mat-label>To</mat-label>
            <input  [matDatepicker]="picker2" class="form-control"
            [min]="fromDate" formControlName="to" id="to" matInput>
            <mat-datepicker-toggle [for]="picker2"matSuffix></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>   
          </div>
        </div>  
        <div class="row form-group">
          <div class="col-md-6">
            <mat-form-field class="form-element">
              <mat-label>Action Owner</mat-label>
              <input  class="form-control" type="text"
              aria-label="Action Owner"
              matInput
              formControlName="ownerId"
              [matAutocomplete]="auto2"
              placeholder="Type for hints.."
             >
           <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayUserByName.bind(this)">
             <mat-option *ngFor="let r of filteredOptions" [value]="r.value">
           {{r.label}}
            </mat-option>
           </mat-autocomplete>
          </mat-form-field>       
         </div>
          <div class="col-md-6">
            <mat-form-field class="form-element">
                <mat-label class="col-form-label">Action</mat-label>
                <mat-select disableOptionCentering class="form-control" formControlName="action">
                  <mat-option  [value]="'add'">Add</mat-option>
                  <mat-option  [value]="'update'">Update</mat-option>
                  <mat-option  [value]="'delete'">Delete</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    
    </div>  
       
    </form>
    <br>
    <br>
    <div class="row">
      <div class="action-buttons multi-btns col-sm-12 text-right">
          <button (click)="OnReset()" class="btn-danger reset-btn" mat-raised-button>
              <i class="flaticon-refresh"></i>Reset
          </button>
          <button (click)="close()" class="btn-danger" mat-raised-button>
              <i class="flaticon-cancel"></i>Cancel
          </button>
          <button [disabled]="!this.correct" (click)="OnAuditSearch()" class="btn btn-primary" color="primary" mat-raised-button type="submit">
              <i class="flaticon-magnifiying-glass"></i>Search
          </button>
      </div>
  </div>
</div>
 
</ng-template>
