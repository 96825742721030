import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from '../../../dashboard.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotificationService } from "src/app/core/services/notification.service";
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-bft',
  templateUrl: './bft.component.html',
  styleUrls: ['./bft.component.scss']
})
export class BftComponent implements OnInit {

  public dialogRef: any;
  public rowsOnPage = 5;
  public filterQuery = '';
  public userList: Array<any> = [];
  public isLoading: boolean;
  public isUserAdded: boolean;
  public err: string;
  public modalRef: any;
  public userForm: FormGroup;
  public isEdit: boolean;
  public editId: string;
  public deleteId: string;
  public totalRecords: number;
  public activePage: number;
  public pageSize: number;
  public validationError = {
    required: 'Required',
    number: 'Please provide a valid number'
  };
  @ViewChild('addUser',{static:false}) addUser;
  @ViewChild('deleteUser',{static:false}) deleteUser;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  

  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  paging = {
        pageSize: 10,
        totalRecords: 0,
        pageIndex: 0,
        pageSizeOptions: [10, 25, 50, 100]
    };
    public columns = ['delete','name','threshold','edit'];

  
  constructor(private service: DashboardService, 
    private modalService: NgbModal, 
    private fb: FormBuilder, 
    private toastr: NotificationService,
    private dialog: MatDialog) {
    this.createForm();
  }

  ngOnInit() {
    this.activePage = 1;
    this.pageSize = 10;
    this.isLoading = true;
    this.isEdit = false;
    this.getNationalityList(this.paging.pageIndex + 1, this.paging.pageSize);
    this.isUserAdded = false;
  }

  public createForm(): void {
    const number = '^[0-9]*$';
    this.userForm = this.fb.group({
      name: ['', Validators.required],
      threshold: ['', [Validators.required, Validators.pattern(number)]]
    });
  }

  public addNewUser() {
    this.isEdit = false;
    // this.modalRef = this.modalService.open(this.addUser);

    this.dialogRef = this.dialog.open(this.addUser, {
      width: '560px',
      height: 'auto'
  });
    this.userForm.reset();
  }

  public close(): void {
    // this.modalRef.close();
    this.dialogRef.close();
  }

  public getNationalityList(pageIndex, pageSize): void {
    this.service.getBFTList(this.activePage, this.pageSize).subscribe(user => {
      if (user && user.data) {
        this.isLoading = false;
        this.userList = user.data;
        this.userList.map(u => {
          u.checked = false;
        });
        this.totalRecords = this.userList.length;
        this.err = '';
        this.dataSource = new MatTableDataSource<any>(this.userList);
        this.dataSource.sort = this.sort;
            }
      if (user && user.paging) {
        this.totalRecords = user.paging.count || 10;
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.isLoading = false;
      this.err = 'Error while getting Bradfator Threshold';
      this.toastr.error(err || this.err);
    });
  }

  public submitAddJobTitle(): void {
    if (this.isEdit) {
      this.edit();
    } else {
      this.add();
    }
  }

  public add(): void {
    this.isUserAdded = true;
    this.service.addNeWBFT(this.userForm.value).subscribe(user => {
      this.isUserAdded = false;
      this.toastr.success('Bradfator Threshold added successfully');
      // this.modalRef.close();
      this.dialogRef.close();
      this.userForm.reset();
      this.getNationalityList(this.paging.pageIndex + 1, this.paging.pageSize);
    }, error => {
      const err = error.error && error.error.msg;
      this.isUserAdded = false;
      this.toastr.error(err || 'Error in adding Bradfator Threshold.Please try again.');
    });
  }

  public edit(): void {
    this.isUserAdded = true;
    this.service.editBFT(this.editId, this.userForm.value).subscribe(user => {
      this.isUserAdded = false;
      this.toastr.success('Bradfator Threshold updated successfully');
      // this.modalRef.close();
      this.dialogRef.close();
      this.getNationalityList(this.paging.pageIndex + 1, this.paging.pageSize);
    }, error => {
      const err = error.error && error.error.msg;
      this.isUserAdded = false;
      this.toastr.error(err || 'Error in updating Bradfator Threshold.Please try again.');
    });
  }

  public editUser(id): void {
    this.isEdit = true;
    this.service.getBFTById(id).subscribe(user => {
      if (user && user.data) {
        this.userForm.patchValue(user.data);
        // this.modalRef = this.modalService.open(this.addUser);
        this.dialogRef = this.dialog.open(this.addUser, {
          width: '560px',
          height: 'auto'
      });
        this.editId = id;
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error getting Bradfator Threshold');
    });
  }

  public deleteUserForm(id): void {
    this.deleteId = id;
    // this.modalRef = this.modalService.open(this.deleteUser);
    this.dialogRef = this.dialog.open(this.deleteUser, {
      width: '400px',
      height: 'auto'
  });
  }

  public deleteJobTitle(): void {
    this.service.deleteBFT(this.deleteId).subscribe(user => {
      if (user) {
        this.close();
        this.getNationalityList(this.paging.pageIndex + 1, this.paging.pageSize);
        this.toastr.success('Bradfator Threshold deleted!');
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error deleting Bradfator Threshold');
    });
  }

  public selectAll(): void {
    this.userList.map(u => {
      u.checked = true;
    });
  }

  public disSelectAll(): void {
    this.userList.map(u => {
      u.checked = false;
    });
  }

  public deleteSingle(): void {
    this.service.deleteBFT(this.deleteId).subscribe(user => {
      if (user) {
        this.close();
        this.getNationalityList(this.paging.pageIndex + 1, this.paging.pageSize);
        this.toastr.success('Bradfator Threshold deleted!');
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error deleting Bradfator Threshold');
    });
  }

  exportToCsv(){

  }

  saveAsPdf(){

  }

  onSelectAllUsers(){

  }

  onDeselectAllUsers(){

  }
  onbtfChanged(){
    
  }
  onModelChange(value: string) {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
}


}


