<div>
    <form style="padding: 24px" class="form-material full-width-inputs" action="javascript:" [formGroup]="filterYear">
        <div class="row form-group">
            <div class="col-sm-4">
                <mat-checkbox #includePastEmployees class="form-control" color="primary"
                              formControlName="includePastEmployees">
                    Include Past Employees
                </mat-checkbox>
            </div>
            <div class="col-sm-4">
                <mat-checkbox #hideLeavesOfSupervisors class="form-control" color="primary"
                              formControlName="hideLeavesOfSupervisors">
                    Hide Leaves Of Supervisor(s)
                </mat-checkbox>
            </div>
            <div class="col-sm-4">

            </div>
            <div class="col-sm-4">
                <mat-form-field class="example-full-width form-element">
                    <mat-label>Employee</mat-label>
                    <mat-select style="height: min-content" style="height: min-content" class="form-control"
                                formControlName="empId" multiple disableOptionCentering
                                (selectionChange)="change($event , 'empId')">
                        <mat-select-trigger>
                            <mat-chip-list>
                                <mat-chip
                                        *ngFor="let topping of chipList.empId"
                                        [selectable]="true"
                                        [removable]="true"
                                        (removed)="remove(topping.id , 'empId')">
                                    {{ topping?.label }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </mat-select-trigger>
                        <mat-option>
                            <ngx-mat-select-search [formControl]="employeeFilterCtrl"
                                                   placeholderLabel="Search"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let item of filteredEmployees" [value]="item.id">
                            {{item.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-4">
                <mat-form-field class="form-element">
                    <mat-select panelClass="myPanelClass" style="height: min-content" class="form-control"
                                formControlName="subUnitId"
                                multiple disableOptionCentering
                                (selectionChange)="change($event , 'subUnitId')">
                        <mat-select-trigger>
                            <mat-chip-list>
                                <mat-chip
                                        *ngFor="let topping of chipList.subUnitId"
                                        [selectable]="true"
                                        [removable]="true"
                                        (removed)="remove(topping.id , 'subUnitId')">
                                    {{ topping?.name }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </mat-select-trigger>
                        <ng-container
                                *ngFor="let j of temporaryDepartment">
                            <mat-option
                                    value="{{j.id}}">{{ j.name }}</mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-label for="des">Sub Unit</mat-label>
                </mat-form-field>
            </div>
            <div class="col-sm-4">
                <div class="row">
                    <div class="col-sm-6">
                        <mat-form-field class="form-element">
                            <mat-label>Year</mat-label>
                            <mat-select disableOptionCentering class="form-control"
                                        formControlName="year">
                                <ng-container
                                        *ngFor="let j of years">
                                    <mat-option
                                            [value]="j.value">{{ j.text }}</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6">
                        <mat-form-field class="form-element">
                            <mat-label>Month</mat-label>
                            <mat-select disableOptionCentering class="form-control"
                                        formControlName="month">
                                <ng-container
                                        *ngFor="let j of months">
                                    <mat-option
                                            [value]="j.value">{{ j.text }}</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="col-sm-4" *ngIf="hideLeavesOfSupervisors.checked">
                <mat-form-field class="form-element">
                    <mat-select style="height: min-content" class="form-control"
                                formControlName="leaveTypeId"
                                multiple disableOptionCentering
                                (selectionChange)="change($event , 'leaveTypeId')">
                        <mat-select-trigger>
                            <mat-chip-list>
                                <mat-chip
                                        *ngFor="let topping of chipList.leaveTypeId"
                                        [selectable]="true"
                                        [removable]="true"
                                        (removed)="remove(topping.id , 'leaveTypeId')">
                                    {{ topping?.leaveType }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </mat-select-trigger>
                        <ng-container
                                *ngFor="let j of leaveTypes">
                            <mat-option
                                    value="{{j.id}}">{{ j.leaveType }}</mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-label for="des">Leave Type</mat-label>
                </mat-form-field>
            </div>
            <div class="col-sm-4" *ngIf="hideLeavesOfSupervisors.checked">
                <mat-form-field class="form-element">
                    <mat-select style="height: min-content" class="form-control"
                                formControlName="leaveStatus"
                                multiple disableOptionCentering
                                (selectionChange)="change($event , 'leaveStatus')">
                        <mat-select-trigger>
                            <mat-chip-list>
                                <mat-chip
                                        *ngFor="let topping of chipList.leaveStatus"
                                        [selectable]="true"
                                        [removable]="true"
                                        (removed)="remove(topping.id , 'leaveStatus')">
                                    {{ topping?.text }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </mat-select-trigger>
                        <ng-container
                                *ngFor="let j of leaveStatus">
                            <mat-option
                                    value="{{j.id}}">{{ j.text }}</mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-label for="des">Leave Status</mat-label>
                </mat-form-field>
            </div>
            <div class="col-sm-4">
                <mat-form-field class="form-element">
                    <mat-label>Employee Status</mat-label>
                    <mat-select style="height: min-content" disableOptionCentering class="form-control"
                                formControlName="employementStatus">
                        <mat-option value=''>--Select--</mat-option>
                        <mat-option *ngFor="let item of EmployeeStatus"
                                    [value]="item.id">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-4">
                <mat-form-field class="form-element">
                    <mat-label>location</mat-label>
                    <mat-select style="height: min-content" disableOptionCentering class="form-control"
                                formControlName="locationId">
                        <mat-option value=''>--Select--</mat-option>
                        <mat-option *ngFor="let item of locations"
                                    [value]="item.id">
                            {{item.location}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-4">
                <mat-checkbox style="margin-top: 25px" #employeeNameCheck class="form-control" color="primary"
                              formControlName="makeDefaultFilter">
                    Make Default Filter
                </mat-checkbox>
            </div>
        </div>
        <div class="row">
            <div class="action-buttons multi-btns col-sm-3">
                <span style="font-weight: bold; color: #0d47a1;" class="cursor-pointer" (click)="addNewCourse()">Legend</span>
            </div>
            <div class="action-buttons multi-btns col-sm-9 text-right">
                <button mat-raised-button class="btn-danger" (click)="rest()">
                    <i class="flaticon-cancel"></i>Reset
                </button>
                <button mat-raised-button class="btn btn-primary" color="primary"
                        (click)="submitAddUser()"><i class="flaticon-diskette"></i>View
                </button>
            </div>
        </div>
    </form>
</div>
<app-loading *ngIf="isLoading" [isLoading]="isLoading"></app-loading>
<div *ngIf="!isLoading && !isNotShow">
    <div class="card">
        <div class="container">

            <full-calendar #calendar [options]='calendarOptions'
            ></full-calendar>
        </div>
    </div>
</div>


<ng-template #addCourse let-modal>
    <!-- <div class="card my-0"> -->
    <!-- <div class="header"> -->
    <div class="mat-modal-header">
        <!-- <h4 class="card-title card-title-modal"> -->
        <h4 class="mat-title-modal">
            <span>Legend</span>
        </h4>
    </div>
    <!-- <div class="card-content"> -->
    <div class="mat-modal-body">
        <div *ngIf="!isChange">
            <div style="margin-bottom: 30px">
                <span style="font-weight: bold">Status</span>
            </div>
            <div>
                <div>
                    <span style="margin-right: 30px">A</span>
                    <span >Approved</span>
                </div>
                <div>
                    <span style="margin-right: 30px">C</span>
                    <span >Cancelled</span>
                </div>
                <div>
                    <span style="margin-right: 30px">PA</span>
                    <span >Pending Approval</span>
                </div>
                <div>
                    <span style="margin-right: 30px">R</span>
                    <span >Rejected</span>
                </div>
                <div>
                    <span style="margin-right: 30px">S</span>
                    <span >Scheduled</span>
                </div>
                <div>
                    <span style="margin-right: 30px">T</span>
                    <span >Taken</span>
                </div>

                <div style="margin-bottom: 30px; margin-top: 20px">
                    <span style="font-weight: bold">Leave Type</span>
                </div>
                <div>
                    <div *ngFor="let r of legends">
                        <div style="border: 1px solid #DDDDDD; width: 15px; height: 15px; margin-right: 30px; display: inline-block" [ngStyle]="{'background' : r.color}"></div>
                        <span >{{r.laeveTypeName}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="isChange">
            <div>
                <div *ngFor="let r of legends">
                    <div style="margin-right: 30px; display: inline-block">
                        <mcc-color-picker hideUsedColors [selectedColor]="r.color" hideButtons hideHexForms (change)="r.color = $event" (selected)="r.color = $event"> </mcc-color-picker>
                    </div>
                    <span >{{r.laeveTypeName}}</span>
                </div>
            </div>
        </div>
        <div style="margin-top: 30px" class="action-buttons multi-btns col-sm-12 text-right">
            <button mat-raised-button class="btn-danger" (click)="close()">
                <i class="flaticon-cancel"></i>Cancel
            </button>
            <button *ngIf="!isChange" mat-raised-button class="btn btn-primary" color="primary"
                    (click)="save()"><i class="flaticon-diskette"></i>Change Legend Colors
            </button>

            <button *ngIf="isChange" mat-raised-button class="btn btn-primary" color="primary"
                    (click)="save1()"><i class="flaticon-diskette"></i>Save
            </button>
        </div>
    </div>
</ng-template>


<ng-template #addCourse1 let-modal>
    <!-- <div class="card my-0"> -->
    <!-- <div class="header"> -->
    <div class="mat-modal-header">
        <!-- <h4 class="card-title card-title-modal"> -->
        <h4 class="mat-title-modal">
            <span>Leave</span>
        </h4>
    </div>
    <!-- <div class="card-content"> -->
    <div class="mat-modal-body">
        <div *ngIf="!isChange">
            <div style="margin-bottom: 30px">
                <span style="font-weight: bold">{{dataEvent.data.empId}} {{dataEvent.data.empName}}</span>
            </div>
            <div>
                <div>
                    <span style="margin-right: 71px">Leave Type :</span>
                    <span >{{dataEvent.data.leaveType}}</span>
                </div>
                <div>
                    <span style="margin-right: 60px">Leave Status :</span>
                    <span >{{dataEvent.data.leaveStatus}}</span>
                </div>
                <div>
                    <span style="margin-right: 112px">Date :</span>
                    <span >{{dataEvent.data.date | date :'dd-MM-yyyy'}}</span>
                </div>
            </div>
        </div>

        <div style="margin-top: 30px" class="action-buttons multi-btns col-sm-12 text-right">
            <button mat-raised-button class="btn-danger" (click)="close()">
                <i class="flaticon-cancel"></i>Close
            </button>
        </div>
    </div>
</ng-template>

