<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
    <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="page-header-title">
            <span class="ng-star-inserted"></span
            ><span class="ng-star-inserted"
              ><h4 class="m-b-10 ng-star-inserted">{{ lang.get("users") }}</h4></span
            >
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</div>

<div class="page-body no-title">
  <div class="row">
    <div class="col-sm-12">
      <div class="row top-tools">
        <div class="col-xs-12 col-sm-12 col-md-6 left-tools">
          <span class="export-icon-btn csv" (click)="exportToCsv()"
            ><i class="material-icons">get_app</i><span>{{ lang.get("csv") }}</span>
          </span>
          <span class="export-icon-btn pdf" (click)="saveAsPdf()"
            ><i class="material-icons">get_app</i><span>{{ lang.get("pdf") }}</span>
          </span>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
          <div class="relative-position search-filter-con">
            <span class="mytooltip cus-left tooltip-effect-4" [hidden]="!write">
              <span class="tooltip-item">
                <button mat-mini-fab class="btn btn-primary light add-rounded margin-button-05" (click)="addNewUser()">
                  <i class="fa fa-plus"></i>
                </button>
              </span>
              <span class="tooltip-content clearfix">
                <span class="tooltip-text">{{ lang.get("new") }}</span>
              </span>
            </span>
            <span class="mytooltip cus-left tooltip-effect-4">
              <span class="tooltip-item">
                <button
                  mat-mini-fab
                  class="btn btn-primary light add-rounded margin-button-05"
                  (click)="filterUserList()"
                >
                  <i class="fa fa-filter"></i>
                </button>
              </span>
              <span class="tooltip-content clearfix">
                <span class="tooltip-text">{{ lang.get("filter") }}</span>
              </span>
            </span>
          </div>
        </div>
      </div>

      <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
      <app-card [title]="'Users'">
        <div class="dt-responsive" *ngIf="dataSource">
          <div class="table-responsive">
            <mat-card-content>
              <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
                <ng-container matColumnDef="select">
                  <mat-header-cell *matHeaderCellDef [hidden]="!delete">
                    <a [matMenuTriggerFor]="menu" class="check-all">
                      <span class="material-icons"> more_vert </span>
                    </a>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="onSelectAllClicked()">{{ lang.get("select all") }}</button>
                      <button (click)="onDeSelectAllClicked()" *ngIf="selectedNewsDocs.length > 0" mat-menu-item>
                        {{ lang.get("deselect all") }}
                      </button>
                      <button (click)="onDeleteSelected()" *ngIf="selectedNewsDocs.length > 0" mat-menu-item>
                        {{ lang.get("delete selected") }}
                      </button>
                    </mat-menu>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" [hidden]="!delete">
                    <mat-checkbox
                      #matCheckbox
                      (change)="onNewDocSelectChange($event, row.id)"
                      value="{{ row.id }}"
                    ></mat-checkbox>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="employeeid">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>{{ lang.get("employee number") }}</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.employeeid }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="employeeName">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>{{ lang.get("employee name") }}</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.employeeName }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="email">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>{{ lang.get("email") }}</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.email }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="roleName">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>{{ lang.get("user role") }}</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.roleName }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="roleType">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>{{ lang.get("role type") }}</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.roleType }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="status">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>{{ lang.get("status") }}</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <div *ngIf="row.status == 'active'">Active</div>
                    <div *ngIf="row.status == 'deactive'">Inactive</div>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="edit">
                  <mat-header-cell [hidden]="!update" *matHeaderCellDef>{{ lang.get("edit") }}</mat-header-cell>
                  <mat-cell [hidden]="!update" *matCellDef="let row">
                    <span class="mytooltip tooltip-effect-4">
                      <span class="tooltip-item"
                        ><i class="single-icon-click fa fa-pencil" (click)="editUser(row.id)"></i
                      ></span>
                      <span class="tooltip-content clearfix">
                        <span class="tooltip-text">Edit</span>
                      </span>
                    </span>
                  </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columns"> </mat-row>
              </mat-table>
              <!-- <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar> -->
              <mat-paginator
                [pageSize]="pageSize"
                [pageIndex]="pageIndex"
                [pageSizeOptions]="[10, 25, 50]"
                showFirstLastButtons
                [length]="totalUsers"
                (page)="handlePageEvent($event)"
                [disabled]="isLoading"
              ></mat-paginator>
            </mat-card-content>
          </div>
          <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
        </div>
      </app-card>
    </div>
  </div>
</div>

<ng-template #addUser let-modal>
  <div class="mat-modal-header">
    <h4 class="mat-title-modal">
      <span *ngIf="!isEdit">{{ lang.get("add") }}</span>
      <span *ngIf="isEdit">{{ lang.get("edit") }}</span>
      User
    </h4>
  </div>
  <!-- <div class="card-content"> -->
  <div class="mat-modal-body">
    <!-- <app-loading
      *ngIf="isUserAdded || (employeeList && employeeList.length < 0)"
      [isLoading]="isUserAdded"
    ></app-loading> -->
    <form class="form-material full-width-inputs" action="javascript:" [formGroup]="editUserForm">
      <div class="row form-group">
        <div class="col-sm-6">
          <mat-form-field class="form-element">
            <mat-label>{{ lang.get("email") }}</mat-label>
            <input
              class="form-control"
              formControlName="username"
              id="username"
              matInput
              maxlength="40"
              required=""
              type="text"
            />
          </mat-form-field>
        </div>

        <div class="col-sm-6">
          <app-lazy-employee-dropdown
            title="Employee"
            required="true"
            [selectedEmployee]="selectedEmployee"
            (employeeSelected)="updateEmployeeInForm($event)"
          ></app-lazy-employee-dropdown>
        </div>
        <div class="col-sm-6">
          <mat-form-field class="form-element">
            <mat-label>Status</mat-label>
            <mat-select
              disableOptionCentering
              class="form-control"
              formControlName="status"
              [(value)]="selected"
              required
            >
              <mat-option value="active">{{ lang.get("active") }}</mat-option>
              <mat-option value="deactive">{{ lang.get("inactive") }}</mat-option>
            </mat-select>
            <!-- <mat-error *ngIf="!editUserForm.controls.status.valid && editUserForm.controls.status.touched">
              {{ validationError.required }}
            </mat-error> -->
          </mat-form-field>
        </div>

        <div class="col-md-6">
          <mat-form-field class="form-element">
            <mat-label>{{ lang.get("user role") }}</mat-label>
            <mat-select disableOptionCentering class="form-control" formControlName="roleid" required>
              <mat-option *ngFor="let r of rolesList" [value]="r.id"> {{ r.name }} [{{ r.roleType }}] </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-sm-6" *ngIf="!isEdit">
          <mat-form-field class="form-element">
            <mat-label>{{ lang.get("password") }}</mat-label>
            <input
              [type]="passwordHide ? 'password' : 'text'"
              matInput
              class="form-control"
              maxlength="40"
              required=""
              formControlName="password"
            />
            <mat-icon matSuffix (click)="passwordHide = !passwordHide">{{
              passwordHide ? "visibility_off" : "visibility"
            }}</mat-icon>
            <mat-error *ngIf="!editUserForm.controls.password.valid && editUserForm.controls.password.touched">
              {{ validationError.password }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-6" *ngIf="!isEdit">
          <mat-form-field class="form-element">
            <mat-label>{{ lang.get("Confirm Password") }}</mat-label>
            <input
              [type]="confirmHide ? 'password' : 'text'"
              matInput
              class="form-control"
              maxlength="40"
              required=""
              formControlName="confirmPassword"
            />
            <mat-icon matSuffix (click)="confirmHide = !confirmHide">{{
              confirmHide ? "visibility_off" : "visibility"
            }}</mat-icon>
            <mat-error *ngIf="!editUserForm.controls.confirmPassword.valid && editUserForm.controls.confirmPassword.touched">
              {{ validationError.password }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-12" [hidden]="!isEdit">
          <section>
            <mat-checkbox #enablePassword [color]="primary">{{ lang.get("reset password") }}</mat-checkbox>
          </section>
        </div>

        <form [formGroup]="passwordForm" class="form-row form-default col-sm-12" *ngIf="enablePassword.checked">
          <div class="col-sm-6">
            <mat-form-field class="form-element">
              <mat-label for="newPassword">{{ lang.get("password") }}</mat-label>
              <input
                matInput
                class="form-control"
                [type]="passwordHide ? 'password' : 'text'"
                formControlName="newPassword"
                required
              />
              <mat-icon matSuffix (click)="passwordHide = !passwordHide">{{
                passwordHide ? "visibility_off" : "visibility"
              }}</mat-icon>
              <mat-error
                *ngIf="!passwordForm.controls.newPassword.valid && passwordForm.controls.confirmNewPassword.touched"
              >
                Required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <mat-form-field class="form-element">
              <mat-label for="confirmNewPassword">{{ lang.get("Confirm Password") }}</mat-label>
              <input
                matInput
                class="form-control"
                [type]="confirmHide ? 'password' : 'text'"
                formControlName="confirmNewPassword"
                required
              />
              <mat-icon matSuffix (click)="confirmHide = !confirmHide">{{
                confirmHide ? "visibility_off" : "visibility"
              }}</mat-icon>
              <mat-error *ngIf="!passwordForm.controls.newPassword.valid && passwordForm.controls.newPassword.touched">
                Required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-12 text-left">
            <button
              mat-raised-button
              class="btn-danger rest-btn"
              [disabled]="!passwordForm.valid"
              (click)="submitPassword()"
            >
              <i class="flaticon-refresh"></i> {{ lang.get("reset password") }}
            </button>
          </div>
        </form>
      </div>
      <div class="row form-group">
        <div class="action-buttons multi-btns col-sm-12 text-right">
          <button mat-raised-button class="btn-danger" (click)="close()">
            <i class="flaticon-cancel"></i> {{ lang.get("cancel") }}
          </button>
          <button
            mat-raised-button
            class="btn btn-primary"
            color="primary"
            [disabled]="submitLoading || !isValidFormToSubmit || enablePassword.checked"
            [class.spinner]="submitLoading"
            (click)="submitAddUser()"
          >
            <i class="flaticon-diskette"></i> {{ lang.get("save") }}
          </button>
        </div>
      </div>
    </form>
  </div>
  <!-- </div> -->
</ng-template>

<ng-template #deleteUser let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">{{ lang.get("delete") }} {{ lang.get("user") }}</h4>
    </div>
    <div class="mat-modal-body">
      <div class="text-left are-u">{{ lang.get("areyousure") }}</div>
      <div class="row">
        <div class="multi-btns col-sm-12 text-right">
          <button mat-raised-button class="btn-danger" (click)="close()">
            <i class="flaticon-cancel"></i> {{ lang.get("cancel") }}
          </button>
          <button class="btn-primary" color="primary" mat-raised-button (click)="deleteMultiple()">
            <i class="flaticon-trash"></i> {{ lang.get("delete") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #filterUser let-modal>
  <div class="mat-modal-header">
    <h4 class="mat-title-modal">{{ lang.get("filter") }} {{ lang.get("user") }}</h4>
  </div>
  <div class="mat-modal-body">
    <form class="form-material full-width-inputs" action="javascript:" [formGroup]="filterUserForm">
      <div class="row form-group">
        <div class="col-sm-6">
          <mat-form-field class="form-element">
            <mat-label class="col-form-label">{{ lang.get("email") }}</mat-label>
            <input class="form-control" type="text" matInput formControlName="email" autofocus />
          </mat-form-field>
        </div>

        <div class="col-sm-6">
          <app-lazy-employee-dropdown
            title="Employee"
            required="false"
            [selectedEmployee]="filterEmployee"
            (employeeSelected)="updateFilterEmployee($event)"
          ></app-lazy-employee-dropdown>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-sm-6">
          <mat-form-field class="form-element">
            <mat-label> {{ lang.get("location") }}</mat-label>
            <mat-select disableOptionCentering class="form-control" formControlName="location">
              <mat-option value="">--Select--</mat-option>
              <mat-option *ngFor="let r of locationList" [value]="r.value">
                {{ r.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-6">
          <mat-form-field class="form-element">
            <mat-label>{{ lang.get("status") }}</mat-label>
            <mat-select disableOptionCentering class="form-control" formControlName="status">
              <mat-option value="">--Select--</mat-option>
              <mat-option [value]="'active'">{{ lang.get("active") }}</mat-option>
              <mat-option [value]="'deactive'">{{ lang.get("inactive") }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-6">
          <mat-form-field class="form-element">
            <mat-label>{{ lang.get("user role") }}</mat-label>
            <mat-select disableOptionCentering class="form-control" formControlName="role">
              <mat-option value="">--Select--</mat-option>
              <mat-option *ngFor="let r of rolesList" [value]="r.id"> {{ r.name }} [{{ r.roleType }}] </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row form-group">
        <div class="action-buttons multi-btns col-sm-12 text-right">
          <button mat-raised-button class="btn-danger reset-btn" (click)="resetFilterForm()">
            <i class="flaticon-refresh"></i>{{ lang.get("reset") }}
          </button>
          <button mat-raised-button class="btn-danger" (click)="close()">
            <i class="flaticon-cancel"></i>{{ lang.get("cancel") }}
          </button>
          <button
            (click)="filterUsers()"
            class="btn btn-primary"
            color="primary"
            mat-raised-button
            [class.spinner]="isLoading"
            [disabled]="isLoading"
          >
            <i class="flaticon-magnifiying-glass"></i>{{ lang.get("search") }}
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
