import { AfterViewInit, Component, HostListener, NgZone, OnInit, ViewChild } from "@angular/core";
import { DashboardService } from "../../dashboard.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NotificationService } from "src/app/core/services/notification.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import jsPDF from "jspdf";
import { saveAs } from 'file-saver-es';
import autoTable from "jspdf-autotable";
import { MatSort, Sort } from "@angular/material/sort";
import { MatDialog } from "@angular/material/dialog";
import { AuthService } from "src/app/core/services/auth.service";

@Component({
  selector: "app-employee-list",
  templateUrl: "./employee-list.component.html",
  styleUrls: ["./employee-list.component.scss"],
})
export class EmployeeListComponent implements OnInit, AfterViewInit {
  // protected _onDestroy = new Subject<void>();
  filteredUsers: any[];
  public isLoading: boolean;
  public modalRef: any;
  public jobTitleList: Array<any> = [];
  public employeeStatusList: Array<any> = [];

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  // @ViewChild(MatPaginator) paginator: MatPaginator;

  public dialogRef: any;
  public structureList: Array<any> = [];
  public filterEmployeeForm: FormGroup;
  public includeList: Array<any> = [
    { value: "ce", label: "Current Employees Only" },
    { value: "cp", label: "Current and Past Employees" },
    { value: "pe", label: "Past Employees Only" },
  ];
  public locationList: Array<any> = [];
  public isEmployeeAdded: boolean;
  public err: string;
  public makeFilterChecked = false;

  private initFilterList: boolean = true;

  @ViewChild("makeDefaultFilter", { static: false }) makeDefaultFilter: any;
  @ViewChild("filterEmp", { static: false }) filterEmp;

  employeeListDataSource: MatTableDataSource<any> = new MatTableDataSource([]);

  public employeeListColumns = [
    "photo",
    "id",
    "name",
    "jobtitle",
    "employeeStatus",
    "subunit",
    "location",
    "supervisor",
  ];

  pageSize: number = 10;
  pageIndex: number = 0;
  totalEmployess: number = 0;

  public filteredEmployee: any;
  public selectedSupervisor: any;

  public searchInProgress: boolean;

  constructor(
    private service: DashboardService,
    private authService: AuthService,
    private toastr: NotificationService,
    private router: Router,
    private ngZone: NgZone,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {
    this.createForm();
  }

  //   ngOnDestroy() {
  //     this.dialog = null;
  //     this.dialogRef = null;
  //     this.authService = null;
  //     this.service = null;
  //     this.fb = null;
  //     this.toastr = null;
  //     this.sort = null;
  //     this.paginator = null;
  //     this.ngZone = null;
  //     this.router = null;
  //     this.cdRef = null;
  //     this.deleteUser = null;
  //     // this.userFilterCtrl = null;
  //     this.dialogRef = null;
  //     this.filterEmp = null;
  //     this.filterEmployeeForm = null;
  //     this.table = null;
  //     this.paginator = null;
  //   }

  public permissions: any;

  public write = false;
  public update = false;
  public editClass = "";

  checkPermissionForAdd(module, tabs): void {
    this.permissions = JSON.parse(this.authService.getPermissions());

    this.write = false;

    if (this.permissions != undefined && this.permissions != null) {
      for (let p of this.permissions) {
        if (p.id === module) {
          if (p.modules != undefined) {
            for (let tab of p.modules) {
              if (tab.id === tabs) {
                for (let move of tab.permits) {
                  if (move.id === "c") {
                    this.write = true;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  checkPermission(module, tabs): void {
    this.permissions = JSON.parse(this.authService.getPermissions());

    this.editClass = "";
    this.update = false;

    if (this.permissions != undefined && this.permissions != null) {
      for (let p of this.permissions) {
        if (p.id === module) {
          if (p.modules != undefined) {
            for (let tab of p.modules) {
              if (tab.id === tabs) {
                for (let move of tab.permits) {
                  if (move.id === "r") {
                    this.update = true;
                    this.editClass = "cursor-pointer";
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  ngOnInit() {
    this.filteredEmployee = { name: "", id: "" };
    this.selectedSupervisor = { name: "", id: "" };

    this.employeeListDataSource.sort = this.sort;

    this.isEmployeeAdded = false;
    this.checkPermissionForAdd("pdm", "pd_ae");
    this.checkPermission("pdm", "pd_em");
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.getEmployeeList();
    });

    this.getEmployeeList();

    this.filterEmployeeForm.get("employeeID").valueChanges.subscribe((x) => {
      if (this.filterEmployeeForm.value.isChecked) {
        this.filterEmployeeForm.patchValue({ isChecked: false });
      }
    });
    this.filterEmployeeForm.get("employeeSelected").valueChanges.subscribe((x) => {
      if (this.filterEmployeeForm.value.isChecked) {
        this.filterEmployeeForm.patchValue({ isChecked: false });
      }
    });
    this.filterEmployeeForm.get("employeeStatusSelected").valueChanges.subscribe((x) => {
      if (this.filterEmployeeForm.value.isChecked) {
        this.filterEmployeeForm.patchValue({ isChecked: false });
      }
    });
    this.filterEmployeeForm.get("supervisorSelected").valueChanges.subscribe((x) => {
      if (this.filterEmployeeForm.value.isChecked) {
        this.filterEmployeeForm.patchValue({ isChecked: false });
      }
    });
    this.filterEmployeeForm.get("jobTitleSelected").valueChanges.subscribe((x) => {
      if (this.filterEmployeeForm.value.isChecked) {
        this.filterEmployeeForm.patchValue({ isChecked: false });
      }
    });
    this.filterEmployeeForm.get("includeSelected").valueChanges.subscribe((x) => {
      if (this.filterEmployeeForm.value.isChecked) {
        this.filterEmployeeForm.patchValue({ isChecked: false });
      }
    });

    this.filterEmployeeForm.get("locationSelected").valueChanges.subscribe((x) => {
      if (this.filterEmployeeForm.value.isChecked) {
        this.filterEmployeeForm.patchValue({ isChecked: false });
      }
    });

    this.filterEmployeeForm.get("subunitSelected").valueChanges.subscribe((x) => {
      if (this.filterEmployeeForm.value.isChecked) {
        this.filterEmployeeForm.patchValue({ isChecked: false });
      }
    });
  }

  // @HostListener("matSortChange", ["$event"])
  // onSortChange(event: Sort) {
  //   this.sort.direction = event.direction;
  // }

  handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;

    this.getEmployeeList();
  }

  createForm() {
    this.filterEmployeeForm = this.fb.group({
      employeeStatusSelected: [""],
      jobTitleSelected: [""],
      includeSelected: ["ce"],
      locationSelected: [""],
      subunitSelected: [""],
      isChecked: [false],
    });
  }

  public getEmployeeList(): void {
    const body = {
      empId: this.filteredEmployee ? this.filteredEmployee.id : "",
      supervisorId: this.selectedSupervisor ? this.selectedSupervisor.id : "",
      jobTitleId: this.filterEmployeeForm.value.jobTitleSelected,
      locationId: this.filterEmployeeForm.value.locationSelected,
      status: this.filterEmployeeForm.value.includeSelected,
      subUnitId: this.filterEmployeeForm.value.subunitSelected,
    };

    this.isLoading = true;
    const sortBy = this.sort.active ? this.sort.active : "";
    const sortDir = this.sort.active ? this.sort.direction : "";
    this.service.getFilteredEmployeePages(body, this.pageIndex, this.pageSize, sortBy, sortDir).subscribe(
      (res) => {
        if (res && res.data) {
          this.employeeListDataSource.data = res.data.content;
          this.totalEmployess = res.data.totalElements;
          this.isLoading = false;
          if (this.searchInProgress) {
            this.dialogRef.close();
          }
          this.searchInProgress = false;
        }
      },
      (error) => {
        this.isLoading = false;
        this.searchInProgress = false;
        this.toastr.error("Error while getting employee list");
        this.err = "Error while getting employee list";
      }
    );
  }

  public addNewUser(): void {
    this.ngZone.run(() => {
      void this.router.navigate(["/pim/add-employee"], { queryParams: { action: "add" } });
    });
  }

  public editEmployee(id): void {
    // this.editId = id;
    this.router.navigate([`${id}`], { relativeTo: this.route });
  }

  public isFirst = true;

  openFilterDialog() {
    // here we are getting the value for the employee status
    if (this.initFilterList) {
      this.service.getEmployeeStatusList().subscribe(
        (user) => {
          if (user && user.data) {
            user.data.map((res) => {
              this.employeeStatusList.push({ label: res.name, value: res.id });
            });
            this.err = "";
          }
        },
        (error) => {
          this.err = "Error while getting Employee Status";
        }
      );
      // here we are getting the value for the job titles
      this.service.getJobTitleList().subscribe(
        (user) => {
          if (user && user.data) {
            user.data.map((res) => {
              this.jobTitleList.push({ label: res.title, value: res.id });
            });
            this.err = "";
          }
        },
        (error) => {
          const err = error.error && error.error.msg;
          this.toastr.error(err || "Error while getting Job Title");
          this.err = "Error while getting Job Title";
        }
      );
      // getting data for the structure
      this.service.getStructureList().subscribe((resp) => {
        if (resp && resp.data) {
          resp.data.map((res) => {
            this.structureList.push({ label: res.name, value: res.id });
          });
        }
      });
      // here getting the location
      this.service.getLocationList().subscribe(
        (user) => {
          if (user && user.data) {
            user.data.map((res) => {
              this.locationList.push({ label: res.location, value: res.id });
            });
          }
        },
        (err) => {
          this.err = "Error while getting Location";
        }
      );

      this.initFilterList = false;
    }

    if (this.isFirst) {
      const filterEmployeeForm: any = JSON.parse(sessionStorage.getItem("filterEmployeeForm"));
      if (filterEmployeeForm) {
        this.filterEmployeeForm.patchValue(filterEmployeeForm);
        this.filterEmployeeForm.patchValue({ isChecked: true });
      }
    }

    this.dialogRef = this.dialog.open(this.filterEmp, {
      width: "700px",
      height: "auto",
    });
  }

  filterEmployee() {
    this.searchInProgress = true;
    this.getEmployeeList();
  }

  resetFilter() {
    sessionStorage.removeItem("filterEmployeeForm");
    this.resetForm();
    this.getEmployeeList();
  }

  OnMakeDefaultClick(event: any) {
    this.makeFilterChecked = event.target.checked;
    if (this.makeDefaultFilter) {
      this.isFirst = true;
      sessionStorage.setItem("filterEmployeeForm", JSON.stringify(this.filterEmployeeForm.value));
    } else {
      this.filterEmployeeForm.patchValue({ isChecked: false });
      sessionStorage.removeItem("filterEmployeeForm");
    }
  }

  resetForm() {
    this.isFirst = false;

    this.filterEmployeeForm = this.fb.group({
      employeeStatusSelected: [""],
      jobTitleSelected: [""],
      includeSelected: ["ce"],
      locationSelected: [""],
      subunitSelected: [""],
      isChecked: [false],
    });
    this.filteredEmployee = { name: "", id: "" };
    this.selectedSupervisor = { name: "", id: "" };
  }

  exportToCsv() {
    const csv = this.employeeListDataSource.filteredData.map((row) => {
      return (
        [row.id, row.name, row.jobtitle, row.employeeStatus, row.subunit, row.location, row.supervisor].join(",") + "\n"
      );
    });
    csv.unshift(
      ["employeeId", "name", "jobtitle", "employmentStatus", "Subunit", "location", "suprevisor"].join(",") + "\n"
    );
    const exportDate = new Date();
    // tslint:disable-next-line:max-line-length
    const exportFileName = `Csv-export-${exportDate.getFullYear()}-${
      exportDate.getMonth() + 1
    }-${exportDate.getDate()}.csv`;
    saveAs(new Blob(csv, { type: "text/csv;charset=utf-8" }), exportFileName);
  }

  saveAsPdf() {
    if (this.employeeListDataSource.filteredData.length <= 0) {
      this.toastr.error("No Data to export");
      return;
    }
    const body = [];
    for (let i = 0; i < this.employeeListDataSource.filteredData.length; i++) {
      body.push([
        this.employeeListDataSource.filteredData[i].id,
        this.employeeListDataSource.filteredData[i].name,
        this.employeeListDataSource.filteredData[i].jobtitle,
        this.employeeListDataSource.filteredData[i].employeeStatus,
        this.employeeListDataSource.filteredData[i].subunit,
        this.employeeListDataSource.filteredData[i].location,
        this.employeeListDataSource.filteredData[i].supervisor,
      ]);
    }
    const doc = new jsPDF();
    autoTable(doc, {
      head: [["Employee Id", "Name", "Job Title", "Employment Status", "Subunit", "Location", "Supervisor"]],
      body: body,
    });
    const exportDate = new Date();
    const exportFileName = `Employee List-${exportDate.getFullYear()}-${
      exportDate.getMonth() + 1
    }-${exportDate.getDate()}}`;
    doc.save(exportFileName);
  }

  public updateFilterEmployee(event) {
    this.filteredEmployee = { id: event ? event.id : "", name: event ? event.name : "" };
  }

  public updateSelectedSupervisor(event) {
    this.selectedSupervisor = { id: event ? event.id : "", name: event ? event.name : "" };
  }
}
