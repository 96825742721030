import {CommonModule} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
// Module
import {RouterModule} from '@angular/router';
import {CoreModule} from '../core/core.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
// component
import {LoadingComponent} from './loading/loading.component';
import {PaginationComponent} from './pagination/pagination.component';
import {OnlyNumberDirective} from './directive/only-number.directive';
import {MaterialModule} from "../module/material/material.module";
import { MultiSelectTokenComponent } from './multi-select-token/multi-select-token.component';
import { LazyMultiSelectListComponent } from './lazy-multi-select-list/lazy-multi-select-list.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSelectModule } from '@angular/material/select';
import { TimeInputComponent } from './time-input/time-input.component';
import { LazyEmployeeDropdownComponent } from './lazy-employee-dropdown/lazy-employee-dropdown.component';
import { CountryDropdownComponent } from './country-dropdown/country-dropdown.component';

@NgModule({
    declarations: [
        LoadingComponent,
        PaginationComponent,
        OnlyNumberDirective,
        MultiSelectTokenComponent,
        LazyMultiSelectListComponent,
        TimeInputComponent,
        LazyEmployeeDropdownComponent,
        CountryDropdownComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        CoreModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        MatSelectModule,
        NgxMatSelectSearchModule
    ],
    providers: [],
    exports: [LoadingComponent, PaginationComponent, OnlyNumberDirective, MultiSelectTokenComponent,
        LazyMultiSelectListComponent,TimeInputComponent,LazyEmployeeDropdownComponent, CountryDropdownComponent],
    schemas: []
})
export class HrmSharedModule {
}
