<div class="page-body no-title">
  <div class="row">
    <div class="col-sm-12">
      <div class="row margin-buttom-15">
        <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6">
          <span (click)="exportToCsv()" class="export-icon-btn csv"><i
                  class="material-icons">get_app</i><span>CSV</span>
          </span>
          <span (click)="saveAsPdf()" class="export-icon-btn pdf"><i class="material-icons">get_app</i><span>PDF</span>
          </span>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6">
          <div style="text-align: right;">
            <label class="">Search:
              <input (ngModelChange)="onModelChange(filterQuery)" [(ngModel)]="filterQuery"
                     class="form-control input-sm full-data-search"
                     maxlength="40"
                     placeholder="Search name" type="search">
            </label>
            <span class="mytooltip cus-left tooltip-effect-4">
              <span class="tooltip-item">
                  <button (click)="addNewUser()" class="btn btn-primary  light add-rounded margin-button-05"
                          raised-button>
                      <i class="fa fa-plus"></i>
                  </button>
              </span>
              <span class="tooltip-content clearfix">
              <span class="tooltip-text">New</span>
              </span>
           </span>
          </div>
        </div>
      </div>
      <app-card [title]="'MemberShip'">
        <div *ngIf="dataSource" class="dt-responsive">
          <div class="table-responsive">
            <mat-card-content>
              <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="select">
                  <mat-header-cell *matHeaderCellDef>
                    <a [matMenuTriggerFor]="menu" class="check-all">
                      <span class="material-icons">
                        more_vert
                      </span>
                    </a>
                    <mat-menu #menu="matMenu">
                      <button (click)="onSelectAllUsers()" mat-menu-item>Select All</button>
                      <button (click)="onDeselectAllUsers()" *ngIf="selectedUsers.length > 0" mat-menu-item>Deselect
                        All
                      </button>
                      <button (click)="onDeleteSelected()" *ngIf="selectedUsers.length > 0" mat-menu-item>Delete
                        Selected
                      </button>
                    </mat-menu>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <input #userSelect (change)="onUserChanged(userSelect)" [value]="row.id" class="user-input"
                           type="checkbox"/>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="membership">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Membership</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.membership }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="spb">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Subscription Paid By</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.spb || '-' }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="amount">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Subscription amount</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.amount }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="currency">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Currency</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.currency }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="scd">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Subscription Commence Date</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.scd | date }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="srd">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Subscription Renewal Date</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.srd | date }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="attachment">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Attachment</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <span class="mytooltip tooltip-effect-4">
                      <span class="tooltip-item"><i (click)="onAddAttachment(row.id)"
                                                    class="single-icon-click flaticon-attachment"
                                                    style="color: #666"></i></span>
                      <span class="tooltip-content clearfix">
                        <span class="tooltip-text">Include attachment</span>
                      </span>
                    </span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="edit">
                  <mat-header-cell *matHeaderCellDef>Edit</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                      <span class="mytooltip tooltip-effect-4">
                      <span class="tooltip-item"><i (click)="editUser(row.id)"
                                                    class="single-icon-click fa fa-pencil"></i></span>
                      <span class="tooltip-content clearfix">
                      <span class="tooltip-text">Edit</span>
                      </span>
                      </span>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columns;">
                </mat-row>
              </mat-table>
              <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
              <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
            </mat-card-content>
          </div>
          <div *ngIf="!isLoading && err" class="error-msg">{{ err }}</div>
        </div>
      </app-card>
    </div>
  </div>
</div>
<ng-template #addUser let-modal>
  <div class="card my-0">
    <div class="header">
      <h4 class="card-title card-title-modal"><span *ngIf="!isEdit">Add</span>
        <span *ngIf="isEdit">Edit</span> Membership</h4>
    </div>
    <div class="card-content">
      <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>

      <form class="form-material" action="javascript:" [formGroup]="userForm" *ngIf="!isUserAdded">
        <div class="form-group form-default">
            <mat-form-field class="form-element">
                <mat-select disableOptionCentering class="form-control" formControlName="memberShip">
                    <ng-container
                            *ngFor="let n of membershipList">
                        <mat-option
                                value="{{n.id}}">{{n.name}}</mat-option>
                    </ng-container>
                </mat-select>
                <mat-label for="des">MemberShip</mat-label>
            </mat-form-field>

            <!--          <select class="form-control" formControlName="memberShip">-->
            <!--            <ng-container *ngFor="let m of membershipList">-->
            <!--              <option value="{{m.id}}">{{m.name}}</option>-->
            <!--            </ng-container>-->
            <!--          </select>-->
            <!--          <label class="float-label" class="float-label">MemberShip</label>-->
        </div>
          <div class="form-group form-default">
              <mat-form-field class="form-element">
                  <mat-select disableOptionCentering class="form-control" formControlName="spb">
                      <mat-option value="company">Company</mat-option>
                      <mat-option value="individual">Individual</mat-option>
                  </mat-select>
                  <mat-label for="des">Subscription paid by</mat-label>
              </mat-form-field>

              <!--          <select class="" name="" class="form-control" formControlName="spb">-->
              <!--           <option value="company">Company</option>-->
              <!--           <option value="individual">Individual</option>-->
              <!--          </select>-->
              <!--          <label class="float-label" class="float-label">Subscription paid by</label>-->
          </div>
          <div class="form-group form-default">
              <mat-form-field class="form-element">
                  <input class="form-control" formControlName="amount" matInput type="number" maxlength="40" >
                  <span class="form-bar"></span>
                  <mat-label for="title">Subscription amount</mat-label>
              </mat-form-field>

              <!--          <input type="number" class="form-control" formControlName="amount">-->
              <!--          <span class="form-bar"></span>-->
              <!--          <label class="float-label" class="float-label">Subscription amount</label>-->
          </div>

          <div class="form-group form-default">
              <mat-form-field class="form-element">
                  <mat-select disableOptionCentering class="form-control" formControlName="currency">
                      <ng-container *ngFor="let n of payGradeCurrencyList">
                          <mat-option value="{{n.id}}">{{n.name}}</mat-option>
                      </ng-container>
                  </mat-select>
                  <mat-label for="des">Currency</mat-label>
              </mat-form-field>

              <!--          <select class="form-control" formControlName="currency">-->
              <!--            <ng-container *ngFor="let c of payGradeCurrencyList">-->
              <!--              <option value="{{c.id}}">{{c.name}}</option>-->
              <!--            </ng-container>-->
              <!--          </select>-->

              <!--          <label class="float-label" class="float-label">Currency</label>-->
          </div>

          <div class="form-group form-default">
              <mat-form-field class="form-element">
                  <input [matDatepicker]="picker"
                         class="form-control"
                         formControlName="scd" matInput>
                  <mat-datepicker-toggle [for]="picker" matSuffix>
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-label for="expiry">Subscription Commence Date
                  </mat-label>
              </mat-form-field>

              <!--          <input type="date" class="form-control" formControlName="scd">-->
              <!--          <span class="form-bar"></span>-->
              <!--          <label class="float-label">Subscription Commence Date</label>-->
          </div>

          <div class="form-group form-default">
              <mat-form-field class="form-element">
                  <input [matDatepicker]="picker1"
                         class="form-control"
                         formControlName="srd" matInput>
                  <mat-datepicker-toggle [for]="picker1" matSuffix>
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                  <mat-label for="expiry">Subscription Renewal Date
                  </mat-label>
              </mat-form-field>

              <!--          <input type="date" class="form-control" formControlName="srd">-->
              <!--          <span class="form-bar"></span>-->
              <!--          <label class="float-label"> Subscription Renewal Date</label>-->
          </div>


        <div class="row">
            <div class="col-sm-12 text-right">
                <mat-button (click)="close()" class="btn btn-cancel btn-danger btn-danger" type="submit">Cancel
                </mat-button>
                <mat-button (click)="submitAddJobCategory()" [disabled]="!userForm.valid"
                            class="btn btn-save btn-primary btn-primary" type="submit">Save
                </mat-button>
            </div>
        </div>
      </form>
      </div>
    </div>

</ng-template>


<ng-template #deleteUser let-modal>
    <div class="card my-0">
      <div class="header">
        <h4 class="card-title card-title-modal">Delete Membership</h4>
      </div>
      <div class="card-content px-2 py-2">
        <div class="text-center are-u">Are you sure ?</div>
        <div class="row">
            <div class="col-sm-12 text-right">
                <mat-button (click)="close()" class="btn btn-cancel btn-danger btn-danger" type="submit">Cancel
                </mat-button>
                <mat-button (click)="deleteJobCategory()" class="btn btn-save btn-primary btn-primary" type="submit">
                    Delete
                </mat-button>
            </div>
        </div>
      </div>
    </div>
</ng-template>


<ng-template #attach let-modal>
  <div class="card my-0">
    <div class="header">
      <h4 class="card-title card-title-modal">
        Attachment
      </h4>
    </div>
    <div class="card-content">
      <form class="form-material">
        <div class="form-row">
          <div class="col-sm-12">
            <div class="upload-btn-wrapper">
              <button class="upload-btn">Attach</button>
              <input (change)="fileChange($event, 'dd')" class="form-control" name="myfile" type="file">
              <div class="fileName">{{this.fileObj && this.fileObj.fileName}}<i *ngIf="fileUrl"
                                                                                class="fa fa-check success"></i>
              </div>
            </div>
            <div class="upload-btn-wrapper">
            </div>
            <div *ngIf="!fileUrl && fileError" class="file-error text-danger">{{fileError}}</div>
            <div class="help-text">Accept upto 2MB</div>
          </div>
            <div class="col-sm-12 mt-4">
                <mat-form-field class="form-element">
                    <input [(ngModel)]="comment" class="form-control" matInput name="name" type="text" maxlength="50" >
                    <span class="form-bar"></span>
                    <mat-label for="title">Comment</mat-label>
                </mat-form-field>
            </div>
          <div class="col-sm-12 mt-1 border" style="height: 200px !important; overflow-y: auto">
            <mat-card *ngIf="this.attachmentList.length === 0">No Attachments
              Found
            </mat-card>
            <ng-container *ngFor="let attachment of attachmentList; let i = index;">
              <mat-card class="mt-2 shadow-sm">
                <mat-card-title>
                  {{attachment.fileName}}
                </mat-card-title>
                <mat-card-content>
                  <b>Added On:</b> {{attachment.added}} <br>
                  <b>Comments:</b> {{attachment.desc}}
                </mat-card-content>
                <mat-card-actions align="end">
                  <button (click)="onDeleteClicked(attachment)"
                          aria-label="Example icon button with a vertical three dot icon"
                          mat-icon-button>
                    <mat-icon color="warn">delete</mat-icon>
                  </button>
                  <button (click)="onDownloadClicked(attachment)"
                          aria-label="Example icon button with a vertical three dot icon"
                          mat-icon-button>
                    <mat-icon color="primary">cloud_download</mat-icon>
                  </button>
                </mat-card-actions>
              </mat-card>
            </ng-container>
          </div>
            <div class="col-sm-12">
                <mat-button (click)="saveAttachment()" class="btn btn-primary" name="button" type="button">Save
                </mat-button>
            </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
