<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
    <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="page-header-title">
            <span class="ng-star-inserted"></span
            ><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted">Occasion Details</h4></span>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</div>

<div class="page-body">
  <div class="row">
    <div class="col-sm-12">
      <div class="dt-responsive">
        <app-card [title]="">
          <div>
            <div>
              <mat-card-content>
                <h3>Add Occasion</h3>
                <form class="form-material full-width-inputs" action="javascript:" [formGroup]="userForm">
                  <div class="row form-group">
                    <div class="col-md-6">
                      <mat-form-field class="form-element">
                        <mat-label>Name</mat-label>
                        <input matInput name="name" class="form-control" required="" formControlName="name" />
                        <mat-error class="validation-error" *ngIf="!userForm.controls.name.valid">{{
                          validationError.required
                        }}</mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-md-6">
                      <mat-form-field class="form-element">
                        <mat-label>Location</mat-label>
                        <mat-select
                          disableOptionCentering
                          formControlName="locationId"
                          required=""
                          class="form-control"
                          (ngModelChange)="getEmployeeByLocation($event)"
                        >
                          <mat-option value="">--Select--</mat-option>
                          <mat-option *ngFor="let l of locationList" [value]="l.id">{{ l.location }}</mat-option>
                        </mat-select>
                        <mat-error class="validation-error" *ngIf="!userForm.controls.locationId.valid">{{
                          validationError.required
                        }}</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <br />
                  <div class="row form-group">
                    <div class="col-md-6">
                      <mat-form-field class="form-element">
                        <input
                          [min]="minDate"
                          class="form-control"
                          matInput
                          [matDatepicker]="mPicker"
                          name="dueDate"
                          formControlName="dueDate"
                          placeholder="Due Date"
                          required
                        />
                        <mat-datepicker-toggle matSuffix [for]="mPicker"></mat-datepicker-toggle>
                        <mat-datepicker [touchUi]="false" #mPicker></mat-datepicker>
                        <mat-error class="validation-error" *ngIf="!userForm.controls.dueDate.valid">{{
                          validationError.required
                        }}</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <br />
                  <div class="row form-group">
                    <div class="col-md-6">
                            <app-multi-select-token
                              title="Participants"
                              #participantsDropDown
                              [(selectedItems)]="participants"
                            ></app-multi-select-token>
                      <!-- <mat-form-field class="form-element">
                        <mat-label>Participants</mat-label>
                        <mat-select
                          panelClass="myPanelClass"
                          disableOptionCentering
                          required
                          style="height: min-content"
                          class="form-control"
                          formControlName="participants"
                          multiple
                          disableOptionCentering
                        >
                          <mat-select-trigger>
                            <mat-chip-list>
                              <mat-chip
                                *ngFor="let topping of userForm.value.participants"
                                [selectable]="true"
                                [removable]="true"
                                (removed)="remove(topping.value, 'parti')"
                              >
                                {{ topping?.label }}
                                <mat-icon matChipRemove>cancel</mat-icon>
                              </mat-chip>
                            </mat-chip-list>
                          </mat-select-trigger>
                          <ng-container>
                            <mat-option>
                              <ngx-mat-select-search
                                [formControl]="employeeFilterCtrl"
                                placeholderLabel="Search"
                              ></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let emp of filteredEmployees" [value]="emp">{{ emp.label }}</mat-option>
                          </ng-container>
                        </mat-select>

                        <mat-error class="validation-error" *ngIf="!userForm.controls.participants.valid">{{
                          validationError.required
                        }}</mat-error>
                      </mat-form-field> -->
                    </div>
                  
                    <div class="col-md-6">
                        <app-multi-select-token
                        title="Occasion Owners"
                        #ownersDropDown
                        [(selectedItems)]="owners"
                      ></app-multi-select-token>
                      <!-- <mat-form-field class="form-element">
                        <mat-label>Occasion Owners</mat-label>
                        <mat-select
                          panelClass="myPanelClass"
                          disableOptionCentering
                          required
                          style="height: min-content"
                          class="form-control"
                          formControlName="owners"
                          multiple
                          disableOptionCentering
                        >
                          <mat-select-trigger>
                            <mat-chip-list>
                              <mat-chip
                                *ngFor="let topping of userForm.value.owners"
                                [selectable]="true"
                                [removable]="true"
                                (removed)="remove(topping.value, 'ow')"
                              >
                                {{ topping?.label }}
                                <mat-icon matChipRemove>cancel</mat-icon>
                              </mat-chip>
                            </mat-chip-list>
                          </mat-select-trigger>
                          <ng-container>
                            <mat-option>
                              <ngx-mat-select-search
                                [formControl]="employeeFilterCtrl2"
                                placeholderLabel="Search"
                              ></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let emp of filteredEmployees2" [value]="emp">{{
                              emp.label
                            }}</mat-option>
                          </ng-container>
                        </mat-select>
                        <mat-error class="validation-error" *ngIf="!userForm.controls.owners.valid">{{
                          validationError.required
                        }}</mat-error>
                      </mat-form-field> -->
                    </div>
                  </div>
                </form>
                <div class="col-sm-12 text-right action-buttons multi-btns">
                  <button (click)="cancell()" mat-raised-button class="btn-danger">
                    <i class="flaticon-cancel"></i>Cancel
                  </button>
                  <button
                    (click)="add()"
                    mat-raised-button
                    class="btn-primary"
                    color="primary"
                    [disabled]="!userForm.valid"
                  >
                    <i class="flaticon-diskette"></i>Save
                  </button>
                </div>
              </mat-card-content>
            </div>
          </div>
        </app-card>
      </div>
    </div>
  </div>
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
</div>
