import {AngularEditorConfig} from "@kolkov/angular-editor";
import {AbstractControl} from "@angular/forms";

export interface Employee {
    id: string;
    name: string;
}

export interface GoalObj {
    id: string;
    name: string;
}

export interface Gender {
    label: string;
    value: string;
}

export interface ComponentType {
    type?: string,
    updateId?: string,
    updateObj?: {
        assignTo?: string
    },
    typeName?: string,
}

export const getAngularEditorConfig = (): AngularEditorConfig => {
    return {
        editable: true,
        spellcheck: true,
        height: '10rem',
        minHeight: '5rem',
        placeholder: 'Enter text here...',
        translate: 'no',
        customClasses: [
            {
                name: 'quote',
                class: 'quote',
            },
            {
                name: 'redText',
                class: 'redText'
            },
            {
                name: 'titleText',
                class: 'titleText',
                tag: 'h1',
            },
        ]
    } as AngularEditorConfig;
};
export const RequireMatch = (control: AbstractControl) => {
    const selection: any = control.value;
    if (typeof selection === 'string') {
        return {incorrect: true};
    }
    return null;
}
export const EVALUATOR_GROUP = () => [
    {key: 'ME', value: 'Main Evaluator'},
    {key: 'SE', value: 'Self'},
    {key: 'SU', value: 'Supervisors'},
    {key: 'PE', value: 'Peers'}
];
export const STATUS_LIST = () => [
    {key: null, value: 'Select'},
    {key: 'A', value: 'Archive'},
    {key: 'I', value: 'In Process'},
    {key: 'P', value: 'Pending'},
]
export const FORMATES = (): { key: string; value: string }[] => [
    {key: '0*.00', value: 'Number xxx.xx'},
    {key: '0*', value: 'Number xxxxx'},
    {key: '00.00', value: 'Number Percent'},
]
export const OBJECT_TYPES = () => [{
    key: "B",
    value: "Business"
}, {
    key: "I",
    value: "Individual"
}];

export interface GoalBulkAssign {
    jobTitles: string[];
    locations: string[];
    subUnits: string[];
}

export interface GoalObj {
    id: string;
    assignTo: string;
    bulkAssign: GoalBulkAssign;
    description: string;
    dueDateStr: string;
    evaluatorGroups: string[];
    library: boolean;
    name: string;
    parentId: string;
    readOnly: boolean;
    type: string;
    startValue: number;
    targetValue: number;
    currentValue: number;
    status: string;
    unit: string;
    format: string;
    dueDateNum: number;
    percent: number;
}