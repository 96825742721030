
<div class="card" >
    <div class="card-header card-header-warning" >
      <h4 class="card-title">Manage Organisation</h4>
      <p class="card-category">List of Organization</p>
    </div>
    <div class="card-content table-responsive table-full-width">
      <div class="add-user"><a routerLink="/dashboard/admin/organization">
        <button raised-button class="btn btn-primary ripple light" > + </button></a>
      </div>

      <app-loading *ngIf="isLoading && !err" [isLoading]="isLoading && !err"></app-loading>
      <table class="table" *ngIf="userList && userList.length > 0 && !err && !isLoading">
        <thead>

          <th *ngIf="tableConfig.name"><div (click)="sortData('name')" class="table-heading" >
              Name <i class="fa fa-sort ml-1"></i></div></th>

          <th *ngIf="tableConfig.organisationemail"><div (click)="sortData('organisationemail')" class="table-heading" >
              Organisation Email <i class="fa fa-sort ml-1"></i></div></th>

          <th *ngIf="tableConfig.locationid"><div (click)="sortData('locationid')" class="table-heading" >
              Location ID <i class="fa fa-sort ml-1"></i></div></th>

          <th *ngIf="tableConfig.region"><div (click)="sortData('region')" class="table-heading" >
              Region <i class="fa fa-sort ml-1"></i></div></th>

          <th *ngIf="tableConfig.status"><div (click)="sortData('status')" class="table-heading" >
              Status <i class="fa fa-sort ml-1"></i></div></th>
          <!-- <th>Location</th> -->

          <th *ngIf="tableConfig.costcenterenabled"><div (click)="sortData('costcenterenabled')" class="table-heading" >
              Cost Center Enabled <i class="fa fa-sort ml-1"></i></div></th>

          <th *ngIf="tableConfig.eeoenabled"><div (click)="sortData('eeoenabled')" class="table-heading" >
              EEO Enabled <i class="fa fa-sort ml-1"></i></div></th>

              <th *ngIf="tableConfig.address"><div (click)="sortData('address')" class="table-heading" >
                  Addresses <i class="fa fa-sort ml-1"></i></div></th>

        </thead>
        <tbody>
          <tr *ngFor="let user of userList">

            <td>{{ user.name }}</td>
            <td>{{ user.organisationemail }}</td>
            <td>{{ user.locationid }}</td>
            <td>{{ user.region }}</td>
            <td>{{user.status}}</td>
            <!-- <td *ngFor="let l of user.location">{{ l }}</td> -->
            <td>{{ user.costcenterenabled }}</td>
            <td>{{ user.eeoenabled }}</td>
            <td>{{ user.address }}</td>
            <td><i class="fa fa-edit" (click)="editUser(user.id)"></i></td>
            <!-- <td><i class="fa fa-trash" (click)="deleteUserForm(user.id)"></i></td> -->
          </tr>
        </tbody>
      </table>
      <app-pagination (getNextPage)="getNextPage($event)" [activePage]="activePage" [totalRecords]="totalRecords" *ngIf="!isLoading && totalRecords"></app-pagination>


      <div class="error-msg text-center" *ngIf="userList && userList.length === 0">No data found</div>
    </div>
    <!-- <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div> -->
  </div>

  <ng-template #addUser let-modal>
      <div class="card my-0">
        <div class="header">
          <h4 class="card-title card-title-modal"><span *ngIf="!isEdit">Add</span>
          <span *ngIf="isEdit">Edit</span> Holiday</h4>
        </div>
        <div class="card-content">
        <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>

        <form class="form-material" action="javascript:" [formGroup]="userForm" *ngIf="!isUserAdded">
          <div class="form-row">
            <div class="form-group col-md-4">
              <span class="form-bar"></span><label class="float-label" for="description">Description</label>
              <input type="text" class="form-control" maxlength="40" id="description"  formControlName="description">
            </div>
            <div class="form-group col-md-4">
              <span class="form-bar"></span><label class="float-label" for="title">Holiday</label>
              <input type="text" class="form-control"  maxlength="40" id="holiday"  formControlName="holiday">
            </div>
            <div class="form-group col-md-4">
              <span class="form-bar"></span><label class="float-label" for="holidaydate">Holiday Date</label>
              <input type="date" class="form-control" id="holidaydate"  maxlength="40"  formControlName="holidaydate">
            </div>
            <div class="form-group col-md-4">
              <span class="form-bar"></span><label class="float-label" for="title">Location</label>
              
                <label>Location</label>
                <select [formControl]="locations" multiple>
                  <option *ngFor="let n of locationList" [value]="n.location">{{n.location}}</option>
                </select>
              
              <!-- <select class="form-control dropdown-height" formControlName="locations" multiple>
                 <ng-container *ngFor="let n of locationList">
                   <option value="{{n.location}}">{{n.location}}</option>
                 </ng-container>
              </select> -->
            </div>

            <div class="form-group col-md-4">
              <span class="form-bar"></span><label class="float-label" for="length">Length</label>
              <input type="text" class="form-control" id="holidaydate"  maxlength="40"  formControlName="length">
            </div>
            <div class="form-group col-md-4">
              <span class="form-bar"></span><label class="float-label" for="operational_country_code">Country Code</label>
              <input type="text" class="form-control" maxlength="40"  id="operational_country_code"  formControlName="operational_country_code">
            </div>
            <div class="form-group col-md-4">
              <span class="form-bar"></span><label class="float-label" for="recurring">Recurring</label>
              <select name="" formControlName="recurring" class="form-control">
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
              <!-- <input type="text" class="form-control" id="recurring"  > -->
            </div>
            <!-- <div class="form-group col-md-4">
              <span class="form-bar"></span><label class="float-label" for="status">Status</label>
              <input type="text" class="form-control" id="status"  formControlName="status">
            </div> -->
          </div>

          <div class="row">
            <div class="col-sm-12 text-right">
              <button type="submit" class="btn btn-cancel btn-danger" (click)="close()">Cancel</button>
              <button type="submit" class="btn btn-save btn-primary" (click)="submitAddJobTitle()">Save</button>
            </div>
          </div>
        </form>
        </div>
      </div>

  </ng-template>


  <ng-template #deleteUser let-modal>
      <div class="card my-0">
        <div class="header">
          <h4 class="card-title card-title-modal">Delete Holiday</h4>
        </div>
        <div class="card-content px-2 py-2">
          <div class="text-left are-u">Are you sure ?</div>
          <div class="row">
            <div class="col-sm-12 text-right">
              <button type="submit" class="btn btn-cancel btn-danger" (click)="close()">Cancel</button>
              <button type="submit" class="btn btn-save btn-primary" (click)="deleteJobTitle()">Delete</button>
            </div>
          </div>
        </div>
      </div>
  </ng-template>



