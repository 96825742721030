import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import { NotificationService } from "src/app/core/services/notification.service";
import {AnnouncementService} from '../../../../../service/announcement.service';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import {Observable} from 'rxjs/Observable';
import {map, startWith} from 'rxjs/operators';
import {RequireMatch} from '../../../../performance/helper-obj';

@Component({
    selector: 'app-add-news',
    templateUrl: './add-news.component.html',
    styleUrls: ['./add-news.component.scss']
})
export class AddNewsComponent implements OnInit {
    @Output('nextTab') nextTabEvent: EventEmitter<number>;
    userForm: FormGroup;
    config = getAngularEditorConfig();
    isSaving = false;
    validationError = {
        required: 'Required',
        email: 'Please provide a valid email',
        password: 'Please provide a password of atleast 8 digits',
        postalcode: 'Should be of 6 digits',
        alphabets: 'Should contain only alphabets'
    };

    type = '';
    typeName = '';

    updateObj = null;
    category: {
        l: Pair[];
        f: FormControl;
        fi?: Observable<Pair[]>;
    } = {l: [], f: new FormControl(undefined, [Validators.required, RequireMatch])};

    // } = {l: [], f: new FormControl(undefined, [Validators.required])};


    constructor(
        private route: Router,
        private fb: FormBuilder,
        private toastr: NotificationService,
        private announcementService: AnnouncementService,
        private activatedRoute: ActivatedRoute,
    ) {
        this.nextTabEvent = new EventEmitter<number>();
        this.activatedRoute.params.subscribe(params => {
            if (params.type) {
                if (params.type === 'news') {
                    this.type = 'N';
                    this.typeName = 'News';
                }
                if (params.type === 'documents') {
                    this.type = 'D';
                    this.typeName = 'Documents';
                }
                this.initData();
            }
        });
    }

    ngOnInit() {
    }

    public update(obj) {
        this.updateObj = obj;
        this.userForm.patchValue({
            topic: '' + obj.topic,
            description: obj.description,
            acknowledgement: obj.acknowledgement ? true : false,
            type: obj.type
        });
        this.updateCategorySelecter();
    }

    initData() {
        const formControllers = {
            topic: ['', [Validators.required, Validators.minLength(2)]],
            description: [''],
            acknowledgement: [false],
            type: this.type
        };
        if (this.type === 'D') {
            this.category.fi = this.vco(this.category.f.valueChanges, this.category.l.slice());
            formControllers['category'] = this.category.f;
        }
        this.userForm = this.fb.group(formControllers);
        this.getCategories();
    }

    save() {
        this.userForm.markAsTouched();
        if (this.userForm.invalid) {
            this.toastr.error('Please fill all required field.');
            return;
        }
        const obj = this.userForm.value;
        this.type === 'D' && (obj.category = obj.category.id);
        !this.updateObj && (obj.publishDate = moment().format('YYYY-MM-DD'));
        this.isSaving = true;
        (this.updateObj ?
                this.announcementService.updateNewsAndDoc(obj, this.updateObj.id) :
                this.announcementService.saveNewsAndDoc(obj)
        ).subscribe(res => {
            this.isSaving = false;
            if (res && res.data) {
                this.toastr.success('Data successfully saved.');
                this.updateObj && Object.keys(res.data).forEach((key) => {
                    this.updateObj[key] = res.data[key];
                });
                !this.updateObj && this.route.navigate([
                    `/admin/announcement/do/${this.type === 'N' ? 'news' : 'documents'}/update/${btoa(res.data.id)}`
                ]);
                this.nextTabEvent.emit(1);
            } else {
                this.toastr.error(res.msg);
            }
            // this.route.navigate([`/admin/announcement/${this.type == 'N' ? 'news' : 'documents'}`]);
        }, error => {
            const err = error.error && error.error.msg;
            this.isSaving = false;
            this.toastr.error(err);
        });
    }

    getCategories() {
        this.announcementService.getCategories({limit: 100000}).subscribe(res => {
            this.isSaving = false;
            if (res && res.data && res.data.length > 0) {
                res.data.map((item => {
                    this.category.l.push({id: item.id, name: item.name} as Pair);
                }));
                this.updateCategorySelecter();
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.toastr.error(err);
        });
    }

    updateCategorySelecter() {
        if (this.type === 'D') {
            this.category.fi = this.vco(this.category.f.valueChanges, this.category.l.slice());
            this.updateObj && this.category.l && this.category.l.forEach((pair: Pair) => {
                if (pair.id === this.updateObj.category) {
                    this.userForm.patchValue({category: pair});
                }
            });
        }
    }

    private _filter(options: Pair[], name: string): Pair[] {
        const filterValue = name.toLowerCase();
        return options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }

    vco(controllerChange: Observable<any>, options: Pair[]): Observable<any> {
        return controllerChange.pipe(
            startWith(''),
            map(value => {
                if (typeof value === 'string') {
                    return value;
                }
                if (typeof value === 'object') {
                    const emp = value as Pair;
                    return emp.name;
                }
                return '';
            }),
            map(name => name ? this._filter(options, name) : options.slice())
        );
    }

    displayFn(user?: Pair): string | undefined {
        return user ? user.name : undefined;
    }

    close() {
        this.route.navigate([
            `/admin/announcement/${this.type === 'N' ? 'news' : 'documents'}`
        ]);
    }
}

const getAngularEditorConfig = (): AngularEditorConfig => {
    return {
        editable: true,
        spellcheck: true,
        height: '10rem',
        minHeight: '5rem',
        placeholder: 'Enter text here...',
        translate: 'no',
        customClasses: [
            {
                name: 'quote',
                class: 'quote',
            },
            {
                name: 'redText',
                class: 'redText'
            },
            {
                name: 'titleText',
                class: 'titleText',
                tag: 'h1',
            },
        ]
    } as AngularEditorConfig;
};


interface Pair {
    name: string;
    id: string;
}
