import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../services/auth.service";
import { NotificationService } from "src/app/core/services/notification.service";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit {
  public error: boolean;
  public isPasswordMatch: boolean;
  public email: string;
  public updateForm: FormGroup;
  public passwordValidationError = {
    letterAndNumber: "",
    specialSymbol: "",
    alphabet: "",
    upper: "",
    length: "",
  };
  public isReset: boolean;
  public token: string;
  public currentHide: boolean = true;
  public passwordHide: boolean = true;
  public confirmHide: boolean = true;

  errorMsg = '';
  isLoading:boolean;

  constructor(
    private readonly formBuilder: FormBuilder,
    private service: AuthService,
    private toastr: NotificationService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.updateForm.valueChanges.subscribe(() => {
      const password = this.updateForm.get("password").value;
      this.validatePassword(password);
      this.isPasswordMatch = this.validateConfirmPassword();
    });
    this.route.queryParamMap.subscribe((data) => {
      if (data["params"] && data["params"]["token"]) {
        this.isReset = true;
        this.token = data["params"]["token"];
      }
    });
  }

  public createForm(): void {
    const pattern =
      "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[./\\,(){}^_$@$!%*?&#+~-])[A-Za-zd./,(){}^_$@$!%*?&#+~-].{8,}";
    if (!this.isReset) {
      this.updateForm = this.formBuilder.group({
        currentPassword: ["", [Validators.required]],
        password: ["", [Validators.required, Validators.pattern(pattern)]],
        confirmPassword: ["", [Validators.required, Validators.pattern(pattern)]],
      });
    } else {
      this.updateForm = this.formBuilder.group({
        password: ["", [Validators.required, Validators.pattern(pattern)]],
        confirmPassword: ["", [Validators.required, Validators.pattern(pattern)]],
      });
    }
  }

  private validatePassword(password): void {
    const regexPattern = {
      letterAndNumber: "^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9./\\,(){}^_$@$!%*?&#+~-]+)$",
      specialSymbol: "(?=.*[./\\,(){}^_$@$!%*?&#+~-])",
      alphabet: "^(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9./\\,(){}^_$@$!%*?&#+~-]+)$",
      length: "^.{8,14}$",
    };

    this.passwordValidationError["letterAndNumber"] =
      password && new RegExp(regexPattern.letterAndNumber).test(password)
        ? "Should contain atleast one number and one alphabet"
        : "";

    this.passwordValidationError["specialSymbol"] =
      password && new RegExp(regexPattern.specialSymbol).test(password) ? "Should contain atleast one number" : "";

    this.passwordValidationError["alphabet"] =
      password && new RegExp(regexPattern.alphabet).test(password) ? "Mix cases" : "";

    this.passwordValidationError["length"] =
      password && new RegExp(regexPattern.length).test(password) ? "Length between 8 - 14" : "";
  }

  private validateConfirmPassword(): boolean {
    if (this.updateForm.value.password === this.updateForm.value.confirmPassword) {
      return true;
    } else {
      return false;
    }
  }
  public updatePassword(): void {
    if (
      !this.passwordValidationError.length ||
      !this.passwordValidationError.letterAndNumber ||
      !this.passwordValidationError.specialSymbol ||
      !this.passwordValidationError.alphabet
    ) {
      this.toastr.error("Please Add a valid Password .. !");
      return;
    }
    const val = this.updateForm.value;
    let data;
    this.isLoading = true;
    if (!this.isReset) {
      data = {
        np: val.password,
        op: val.currentPassword,
      };
      this.service.changePassword(data).subscribe((resp) => {
        this.isLoading = false;
        this.toastr.success("Password changed successfully....");
        this.router.navigate(["/"]);
      },(error) => {
        this.isLoading = false;
        this.errorMsg = error.error && error.error.msg;
      });

    } else {

      var domain = document.location.host;
      console.log("domain : " + domain);
      let clientId = 'dev';
      if (domain) {
        clientId = domain.split(".")[0];
      }
      data = {
        pw: val.password,
        token: this.token,
        client:clientId
      };
      
      this.service.resetPassword(data).subscribe((resp) => {
        this.toastr.success("Password reset successfully....");
        this.router.navigate(["/"]);
        this.isLoading = false;
      },(error) => {
        this.isLoading = false;
        this.errorMsg = error.error && error.error.msg;
      });
    }
    
  }

  public back() {
    this.router.navigate(["/"]);
  }
}
