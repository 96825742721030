import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {DashboardService} from "../../dashboard.service";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import { NotificationService } from "src/app/core/services/notification.service";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import {saveAs} from 'file-saver-es';

@Component({
  selector: 'app-training-session-report',
  templateUrl: './training-session-report.component.html',
  styleUrls: ['./training-session-report.component.scss']
})
export class TrainingSessionReportComponent implements OnInit {

  public reportForm: FormGroup;
  public sessionsist = [];


  @ViewChild('addCourse1', {static: false}) addCourse1;

  public isSessionStatus1: boolean;
  public participantForm: FormGroup;
  public courseList: Array<any>;
  public correct = true;
  public dialogRef: any;
  public filterQuery = '';
  public dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  public selectedNewsDocs = [];
  public isArchived: boolean = false;
  public isLoading: boolean = true;
  public err: string;
  public employeeList = [];
  public participatedStatusList = [];
  public sessionDeliveryMethodsList = [];
  public sessionStatusList = [];
  public coursesList = [];
  public courseVersionList = [];
  public courseStatusList = [];
  public filteredOptions: Array<any>;
  public temporaryDepartment: Array<any>;
  public currencyList: Array<any> = [];
  public attacherId: string;
  public attachmentList = [];
  public fileType: string;
  public fileObj: any;
  public comment: string;
  showSpinner = false;
  public uploadedFile: any;
  public fileError: any;




  public displayColumns = ['participantName' , 'participationStatus','sessionRating' , 'sessionName' , 'startDate' , 'deliveryMethod' , 'sessionStatus' , 'createdUser' , 'createdDate' , 'courseTitle','version','courseCoordinator','subunit','company','Cost','currency','courseStatus','courseCreatedUser','courseCreatedDate' ];

  public columns = ['sessionName', 'trainingCourse', 'startDate', 'Status'];


  @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('filterCourse', {static: false}) filterCourse;

  public filterForm: FormGroup;

  constructor(private service: DashboardService, private route: Router, public dialog: MatDialog, private modalService: NgbModal, private fb: FormBuilder, private toastr: NotificationService) {

    this.participatedStatusList.push({
      name: 'Trained',
      value: true
    });

    this.sessionDeliveryMethodsList.push({
      value: "classRoom",
      name: 'CLass Room'
    }, {
      value: "selfStudy",
      name: 'Self Study'
    },{
      value: "webEx",
      name: 'Web Ex'
    });
    this.sessionStatusList.push({
      value: "pending", name: 'Pending'
    }, {
      value: "scheduled", name: 'Scheduled'
    }, {
      value: "complete", name: 'Complete'
    }, {
      value: "cancelled", name: 'Cancelled'
    });

    this.courseStatusList.push({
      value: true , name: 'Active',
    } , {value: false , name: 'Archived'});

    for(let i = 1 ; i <= 10 ; i++){
      for(let j = 0 ; j <= 9 ; j++){
        this.courseVersionList.push(`${i}.${j}`);
      }
    }

    this.createForm();
  }

  remove(id, name) {
    if (name === 'participantsIds') {
      let res = this.reportForm.value?.participantsIds?.filter(item => item.value !== id);
      this.reportForm.patchValue({participantsIds: res});
    } else if (name === 'participatedStatus') {
      let res = this.reportForm.value?.participatedStatus?.filter(item => item.value !== id);
      this.reportForm.patchValue({participatedStatus: res});
    } else if (name === 'sessionsIds') {
      let res = this.reportForm.value?.sessionsIds?.filter(item => item.id !== id);
      this.reportForm.patchValue({sessionsIds: res});
    } else if (name === 'sessionDeliveryMethods') {
      let res = this.reportForm.value?.sessionDeliveryMethods?.filter(item => item.value !== id);
      this.reportForm.patchValue({sessionDeliveryMethods: res});
    } else if (name === 'sessionStatus') {
      let res = this.reportForm.value?.sessionStatus?.filter(item => item.value !== id);
      this.reportForm.patchValue({sessionStatus: res});
    } else if (name === 'courseId') {
      let res = this.reportForm.value?.courseId?.filter(item => item.id !== id);
      this.reportForm.patchValue({courseId: res});
    } else if (name === 'courseVersion') {
      let res = this.reportForm.value?.courseVersion?.filter(item => item !== id);
      this.reportForm.patchValue({courseVersion: res});
    } else if (name === 'courseCoordinator') {
      let res = this.reportForm.value?.courseCoordinator?.filter(item => item.value !== id);
      this.reportForm.patchValue({courseCoordinator: res});
    } else if (name === 'currency') {
      let res = this.reportForm.value?.currency?.filter(item => item !== id);
      this.reportForm.patchValue({currency: res});
    } else if (name === 'courseStatus') {
      let res = this.reportForm.value?.courseStatus?.filter(item => item.value !== id);
      this.reportForm.patchValue({courseStatus: res});
    }
  }

  public createForm(): void {
    this.reportForm = this.fb.group({
      sessionsIds: [[]],
      SessionStartDateFrom: [''],
      SessionStartDateTo: [''],
      SessionEndDateFrom: [''],
      SessionEndtDateTo: [''],
      sessionDeliveryMethods: [[]],
      sessionDeliveryLocations: [[]],
      sessionStatus: [[]],
      sessionCreatedBy: [[]],
      sessionCreatedDateFrom: [''],
      sessionCreatedDateTo: [''],
      participantsIds: [[]],
      participatedStatus: [[]],
      courseId: [[]],
      courseCoordinator: [[]],
      subUnit: [[]],
      courseCostMin: [null],
      courseCostMax: [null],
      currency: [[]],
      courseCreatedBy: [[]],
      courseCreatedDateFrom: [''],
      courseCreatedDateTo: [''],
      courseStatus: [[]],
      courseVersion: [[]],
    });

  }

  exportToCsv() {
    const csv = this.courseList.map(
        row => {
          return [row?.participantName , row?.participationStatus , row?.sessionRating , row?.sessionName  , row?.startDate , row?.deliveryMethod , row?.sessionStatus , row?.createdUser  , row?.createdDate , row?.courseTitle , row?.version , row?.courseCoordinator , row?.subunit , row?.company ,  row?.cost , row?.currency , row?.courseStatus , row?.courseCreatedUser , row?.courseCreatedDate ].join(',') + '\n';
        }
    );
    csv.unshift(this.displayColumns.join(',') + '\n');
    const exportDate = new Date();
    // tslint:disable-next-line:max-line-length
    const exportFileName = `Csv-export-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}.csv`;
    saveAs(new Blob(csv, {type: 'text/csv;charset=utf-8'}), exportFileName);
  }

  saveAsPdf(){
    if (this.dataSource.filteredData.length <= 0) {
      this.toastr.error('No Data to export');
      return;
    }
    const body = [];
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      body.push([
        this.dataSource.filteredData[i].participantName,
        this.dataSource.filteredData[i].participationStatus,
        this.dataSource.filteredData[i].sessionRating,
        this.dataSource.filteredData[i].sessionName,
        this.dataSource.filteredData[i].startDate,
        this.dataSource.filteredData[i].deliveryMethod,
        this.dataSource.filteredData[i].sessionStatus,
        this.dataSource.filteredData[i].createdUser,
        this.dataSource.filteredData[i].createdDate,
        this.dataSource.filteredData[i].courseTitle,
        this.dataSource.filteredData[i].version,
        this.dataSource.filteredData[i].courseCoordinator,
        this.dataSource.filteredData[i].subunit,
        this.dataSource.filteredData[i].company,
        this.dataSource.filteredData[i].cost,
        this.dataSource.filteredData[i].currency,
        this.dataSource.filteredData[i].courseStatus,
        this.dataSource.filteredData[i].courseCreatedUser,
        this.dataSource.filteredData[i].courseCreatedDate,]);
    }
    const doc = new jsPDF();
    autoTable(doc, {
      styles: {
        fontSize: 3
      },
      head: [this.displayColumns],
      body: body
    });
    const exportDate = new Date();
    const exportFileName = `Reports-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}`;
    doc.save(exportFileName);
  }


  ngOnInit() {
    this.getcourseList();

    this.service.getcourseList().subscribe(resp => {
      if (resp && resp.data) {
        this.coursesList = resp.data;
      }
    });

    this.service.getEmployeeDetailList().subscribe(resp => {
      if (resp && resp.data) {
        resp.data.map(e => {
          this.employeeList.push({label: e.id + ' ' + e.name, value: e.id});
        });
      }
    });

    this.service.getsessionList().subscribe(resp => {
      if (resp && resp.data) {
        this.sessionsist = resp.data;
      }
    });

    this.service.getStructureList().subscribe(resp => {
      if (resp && resp.data) {
        this.temporaryDepartment = resp.data;
      }
    });

    this.service.getPayGradeCurrencyList().subscribe(user => {
      if (user && user.data && user.data.currency) {
        user.data.currency.map(value => {
          this.currencyList.push(value.name);
        });
        console.log("data c:  ", this.currencyList);
      }
    });

    this.initValuesDetection();
  }

  initValuesDetection() {
    this.filteredOptions = this.employeeList;

    // this.filterForm.controls['coordinatorId'].valueChanges.subscribe(change => {
    //
    //     if (change == null || change == undefined) {
    //         this.correct = true;
    //
    //     } else {
    //
    //         this.filterEmployees(change);
    //     }
    //
    // });
  }

  protected filterEmployees(change: String) {
    this.filteredOptions = this.employeeList;
    if (!this.employeeList) {
      return;
    }

    // get the search keyword
    if (!change) {

      this.filteredOptions = this.employeeList;
      return;
    } else {
      this.checkEmployeeChange(change);
      change = change.toLowerCase();
    }
    // filter the search
    this.filteredOptions =
        this.employeeList.filter(emp => emp.label.toLowerCase().indexOf(change) > -1);

  }

  checkEmployeeChange(change) {
    let x = this.filteredOptions.find(x => x.value === change);
    if (!x) {
      this.correct = false;
    }

  }

  public isTrainerAdded = false;

  public getcourseList(): void {
    this.courseList = [];
    this.isLoading = true;
    this.service.gettrainingSessionsReports(this.reportForm.value).subscribe(res => {
      if (res && res.data) {
        this.isLoading = false;
        this.courseList = res.data;
        console.log("ddd : ", res.data);
        this.err = '';
        this.dataSource = new MatTableDataSource<any>(this.courseList);
        // this.dataSource.sort = this.sort;
        // this.dataSource.paginator = this.paginator;
      }
    }, error => {
      const _err = error.error && error.error.msg;
      this.isLoading = false;

      this.err = 'Error while getting Report List details';
      this.toastr.error(_err || this.err);
    });
  }

  public displayEmployeeByName(option) {
    if (!option) {
      return '';
    }
    this.correct = true;

    return this.employeeList.find(x => x.value === option).label;
  }


  onModelChange(value: string) {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }


  resetForm() {
    this.filterForm.reset();
    this.getcourseList();
  }

  search() {

    this.service.filterSessionPrticipating(this.filterForm.value).subscribe(res => {
      if (res.data && res) {
        this.dataSource = new MatTableDataSource<any>(res.data);
        this.dialogRef.close();
        this.toastr.success('filtered Successfully');
      }
    }, error => {
      this.toastr.error(error.error.msg || 'Error in getting the filter Request');
    });

  }

  filterCourseList() {
    this.dialogRef = this.dialog.open(this.filterCourse, {
      width: '560px',
      height: 'auto'
    });
  }


  public close(): void {
    this.dialogRef.close();
    this.filterForm.reset();
    this.participantForm.reset();
  }

  viewAction(id) {
    this.route.navigate(['/training/course', id]);
  }

  viewAction1(data) {

    const currentUser = JSON.parse(sessionStorage.getItem('empId'));
    console.log("uer ; ", data?.participants);
    const part = data?.participants?.find(element => element.participantId === `${currentUser}`);

    console.log("uer 11 ; ", part);
    if (part) {
      this.participantForm.patchValue({
        participantId: currentUser,
        participantName: part?.participantName,
        sessionId: data?.id,
        rating: part?.rating,
        sessionStatus: part?.sessionStatus,
        comment: part?.comment
      });
      this.isSessionStatus1 = data?.sessionStatus;
      this.dialogRef = this.dialog.open(this.addCourse1, {
        width: '620px',
        height: 'auto'
      });
    }
  }


  submitAddUser() {

    console.log("data data : " , this.reportForm.value);

    let courseCoordinator = [];
    let courseId = [];
    let courseStatus = [];
    let participantsIds = [];
    let participatedStatus = [];
    let sessionDeliveryMethods = [];
    let sessionStatus = [];
    let sessionsIds = [];

    this.reportForm.value.courseCoordinator?.map(value => {
      courseCoordinator.push(value.value);
    });

    this.reportForm.value.courseId?.map(value => {
      courseId.push(value.id);
    });

    this.reportForm.value.courseStatus?.map(value => {
      courseStatus.push(value.value);
    });

    this.reportForm.value.participantsIds?.map(value => {
      participantsIds.push(value.value);
    });

    this.reportForm.value.participatedStatus?.map(value => {
      participatedStatus.push(value.value);
    });

    this.reportForm.value.sessionDeliveryMethods?.map(value => {
      sessionDeliveryMethods.push(value.value);
    });

    this.reportForm.value.sessionStatus?.map(value => {
      sessionStatus.push(value.value);
    });

    this.reportForm.value.sessionsIds?.map(value => {
      sessionsIds.push(value.id);
    });

    let body = {
      sessionsIds: sessionsIds,
      SessionStartDateFrom: this.reportForm.value?.SessionStartDateFrom,
      SessionStartDateTo: this.reportForm.value?.SessionStartDateTo,
      SessionEndDateFrom: this.reportForm.value?.SessionEndDateFrom,
      SessionEndtDateTo: this.reportForm.value?.SessionEndtDateTo,
      sessionDeliveryMethods: sessionDeliveryMethods,
      sessionDeliveryLocations: [],
      sessionStatus: sessionStatus,
      sessionCreatedBy: [],
      sessionCreatedDateFrom: this.reportForm.value?.sessionCreatedDateFrom,
      sessionCreatedDateTo: this.reportForm.value?.sessionCreatedDateTo,
      participantsIds: participantsIds,
      participatedStatus: participatedStatus,
      courseId: courseId,
      courseCoordinator: courseCoordinator,
      subUnit: [],
      courseCostMin: this.reportForm.value?.courseCostMin,
      courseCostMax: this.reportForm.value?.courseCostMax,
      currency: this.reportForm.value?.currency,
      courseCreatedBy: [],
      courseCreatedDateFrom: this.reportForm.value?.courseCreatedDateFrom,
      courseCreatedDateTo: this.reportForm.value?.courseCreatedDateTo,
      courseStatus: courseStatus,
      courseVersion: this.reportForm.value?.courseVersion,
    };

    console.log("data data : " , body);

    this.courseList = [];
    this.isLoading = true;
    this.service.getparticipatingSessionsReports(body).subscribe(res => {
      if (res && res.data) {
        this.isLoading = false;
        this.courseList = res.data;
        console.log("ddd : ", res.data);
        this.err = '';
        this.dataSource = new MatTableDataSource<any>(this.courseList);
        // this.dataSource.sort = this.sort;
        // this.dataSource.paginator = this.paginator;
      }
    }, error => {
      const _err = error.error && error.error.msg;
      this.isLoading = false;

      this.err = 'Error while getting Report List details';
      this.toastr.error(_err || this.err);
    });

    // this.isTrainerAdded = true;
    // this.service.addSessionRatePrticipating(this.participantForm.value).subscribe(res => {
    //     if (res && res.data) {
    //         this.isTrainerAdded = false;
    //         this.err = '';
    //         this.close();
    //         this.participantForm.reset();
    //         this.toastr.success('Participant Rated successfully');
    //         this.getcourseList();
    //     }
    // }, error => {
    //     const _err = error.error && error.error.msg;
    //     this.isLoading = false;
    //     this.isTrainerAdded = false;
    //     this.err = 'Error while adding Participant ';
    //     this.toastr.error(_err || this.err);
    // });
  }

}
