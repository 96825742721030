<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
    <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="page-header-title">
            <span class="ng-star-inserted"></span
            ><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted">Employee Record</h4></span>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</div>

<div class="page-body">
  <div class="row">
    <div class="col-sm-12">
      <app-card>
        <div class="dt-responsive form-material full-width-inputs">
          <form class="full-width-inputs form-material" action="javascript:" [formGroup]="userForm">
            <div class="row form-group">
              <div class="col-md-4">
                <app-lazy-employee-dropdown
                  title="Employee"
                  required="true"
                  [selectedEmployee]="selectedEmpId"
                  (employeeSelected)="updateSelectedEmployee($event)"
                ></app-lazy-employee-dropdown>
              </div>
              <div class="col-md-4">
                <mat-form-field class="form-element screen-date-picker">
                  <mat-label>Date From/To</mat-label>
                  <mat-date-range-input required [rangePicker]="picker">
                    <input matStartDate formControlName="startDate" required />
                    <input matEndDate formControlName="endDate" required />
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                  <mat-error> Please select start and end dates </mat-error>
                </mat-form-field>
              </div>

              <div class="col-sm-6">
                <div>
                  <span class="dot-set-holiday"></span>
                  <span class="holiday-color">H </span>
                  - Holiday &nbsp;
                  <span class="dot-set-leave"></span>
                  <span class="leave-color">L</span>
                  - Leave &nbsp;
                  <span class="dot-set-weekend"></span>
                  <span class="Weekend-color">W</span>
                  - Weekend &nbsp;
                </div>
              </div>
              <div class="col-sm-6 text-right action-buttons multi-btns">
                <button
                  mat-raised-button
                  color="primary"
                  class="btn-primary"
                  (click)="getEmployeeAttendanceRecords('')"
                  [class.spinner]="isLoading"
                  [disabled]="
                    userForm.value.startDate === '' ||
                    userForm.value.startDate === null ||
                    userForm.value.endDate === '' ||
                    userForm.value.endDate === null ||
                    selectedEmpId.id === '' ||
                    selectedEmpId.id === null ||
                    isLoading
                  "
                >
                  Search
                </button>
              </div>
            </div>
          </form>
        </div>
      </app-card>

      <div class="example-container mat-elevation-z8" *ngIf="hasResults">
        <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
        <app-card>
          <mat-accordion>
            <mat-expansion-panel
              class="panel-row"
              *ngFor="let group of allGroup"
              [afterExpand]="groupHeaderClick(group)"
              [expanded]="group.expanded"
            >
              <mat-expansion-panel-header
                [ngClass]="group.rows.length === 0 ? 'panel-row-header' : 'panel-row-header-data'"
              >
                <mat-panel-title>
                  <p id="dateHeader" [ngClass]="group.dayOff">{{ group.formattedDate }} {{ group.dayOff }}</p>
                  <p id="durationHeader">{{ group.formatedTotalDuration }}</p>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <ng-template matExpansionPanelContent>
                <mat-table [dataSource]="dataSource" matSort>
                  <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ lang.get("type") }}</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      {{ row.type }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="time">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ lang.get("time") }}</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      {{ displayTimeinOrginalTimezone(row.datetime, row.timezone) | date : "HH:mm" }} {{ row.timezone }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="by">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ lang.get("recordBy") }}</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{ row.recordBy }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="location">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ lang.get("location") }}</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{ row.location }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="duration">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{ lang.get("duration") }}</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{ row.type == "In" ? "-" : row.formatedDuration }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="comments">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{ lang.get("comments") }}</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      <div>
                        <div>{{ row.comments }}</div>
                        <div *ngIf="row.updatedBy" class="audit-info">Last updated by {{ row.updatedBy }}</div>
                        <div *ngIf="row.updatedBy" class="audit-info">
                          Last updated on {{ row.updatedOn | date : "dd/MMM/yyyy HH:mm" }}
                        </div>
                      </div>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="edit" >
                    <mat-header-cell [hidden]="!update" *matHeaderCellDef>{{ lang.get("edit") }}</mat-header-cell>
                    <mat-cell [hidden]="!update" *matCellDef="let row">
                      <span class="mytooltip tooltip-effect-4">
                        <span class="tooltip-item">
                          <i class="single-icon-click fa fa-pencil" (click)="editRecord(group, row)"></i>
                        </span>
                        <span class="tooltip-content clearfix">
                          <span class="tooltip-text">{{ lang.get("edit") }}</span>
                        </span>
                      </span>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="delete">
                    <mat-header-cell [hidden]="!update" *matHeaderCellDef>{{ lang.get("delete") }}</mat-header-cell>
                    <mat-cell [hidden]="!update" *matCellDef="let row">
                      <span class="mytooltip tooltip-effect-4">
                        <span class="tooltip-item">
                          <i class="single-icon-click fa fa-trash" (click)="deleteRecord(row)"></i>
                        </span>
                        <span class="tooltip-content clearfix">
                          <span class="tooltip-text">{{ lang.get("delete") }}</span>
                        </span>
                      </span>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row [class]="'row' + row.typ" *matRowDef="let row; columns: displayedColumns"> </mat-row>
                </mat-table>
              </ng-template>

              <mat-action-row>
                <button
                  mat-raised-button
                  color="primary"
                  class="btn-primary"
                  (click)="addNewRecord(group)"
                  [hidden]="!config.attendance_supervisiorPermission"
                >
                  Add
                </button>
              </mat-action-row>
            </mat-expansion-panel>
          </mat-accordion>
        </app-card>
      </div>
    </div>
  </div>
</div>

<ng-template #addRecord let-modal>
  <div class="mat-modal-header">
    <h4 class="mat-title-modal">
      <span>{{ dialogHeader }}</span>
    </h4>
  </div>
  <div class="mat-modal-body">
    <form class="form-material full-width-inputs" [formGroup]="addForm">
      <div class="row form-group">
        <div class="col-sm-6">
          <mat-form-field class="form-element">
            <mat-label>Time</mat-label>
            <input
              matInput
              [ngxTimepicker]="picker"
              id="time"
              formControlName="time"
              [format]="24"
              class="form-control"
            />
          </mat-form-field>
          <ngx-material-timepicker #picker></ngx-material-timepicker>
        </div>
        <div class="col-sm-6">
          <mat-form-field class="form-element" [hidden]="hideLocation || editMode">
            <mat-label>{{ lang.get("location") }}</mat-label>
            <mat-select disableOptionCentering class="form-control" formControlName="locationId">
              <mat-option value="">--Select--</mat-option>
              <mat-option *ngFor="let item of locations" [value]="item.id">
                {{ item.location }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-sm-12">
          <mat-form-field class="form-element">
            <mat-label>Comments</mat-label>
            <input type="textarea" matInput class="form-control" id="comments" formControlName="comments" />
          </mat-form-field>
        </div>
      </div>
    </form>
    <div class="row my-2 mx-2">
      <div class="action-buttons multi-btns col-sm-12 text-right">
        <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i>Cancel</button>
        <button mat-raised-button class="btn-primary" color="primary" (click)="saveRecord()">
          <i class="flaticon-diskette"></i>{{ addButtonlabel }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
