<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
    <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="page-header-title">
            <span class="ng-star-inserted"></span><span class="ng-star-inserted">
              <h4 class="m-b-10 ng-star-inserted">Leave Entitlements and Usage Report</h4>
            </span>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</div>

<mat-expansion-panel #expansionPanel [expanded]="isExpanded" *ngIf="leaveTypeList.length > 0">
  <mat-expansion-panel-header class="right-aligned-header">
    <mat-panel-title>
      <span class="accordion-head panel-title"> <b>Search </b> (Please specify your search) </span>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <form [formGroup]="filterForm" class="form-material full-width-inputs">
    <div class="row form-group">
      <div class="col-sm-4">
        <mat-form-field class="form-element">
          <mat-label> Generate For</mat-label>
          <mat-select (ngModelChange)="onGenerateForChange()" required disableOptionCentering class="form-control"
            formControlName="generateFor">
            <mat-option value="">--Select--</mat-option>
            <mat-option [value]="'employee'">Employee</mat-option>
            <mat-option [value]="'leaveType'">Leave Type</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-4" *ngIf="filterForm.value.generateFor == 'employee'">
        <app-lazy-employee-dropdown title="Employee Name" required="true" [selectedEmployee]="selectedEmployee"
          (employeeSelected)="employeesSelected($event)"></app-lazy-employee-dropdown>
      </div>
      <div class="col-sm-4" *ngIf="filterForm.value.generateFor == 'leaveType'">
        <mat-form-field class="form-element">
          <mat-label> Job Title</mat-label>
          <mat-select disableOptionCentering class="form-control" formControlName="jobTitleId">
            <mat-option value="">--Select--</mat-option>
            <mat-option *ngFor="let t of jobTitleList" value="{{ t.id }}">{{ t.title }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-4" *ngIf="filterForm.value.generateFor == 'leaveType'">
        <mat-form-field class="form-element">
          <mat-label>Location</mat-label>
          <mat-select disableOptionCentering formControlName="locationId" class="form-control">
            <mat-option value="">--Select--</mat-option>
            <mat-option *ngFor="let n of locationList" [value]="n.id">{{ n.location }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-4" *ngIf="filterForm.value.generateFor == 'leaveType'">
        <mat-form-field class="form-element">
          <mat-label>Sub Unit</mat-label>
          <mat-select disableOptionCentering formControlName="subUnitId" class="form-control">
            <mat-option value="">--Select--</mat-option>
            <mat-option *ngFor="let n of subUnitList" [value]="n.id">{{ n.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-sm-4" *ngIf="filterForm.value.generateFor == 'leaveType'">
        <mat-form-field class="form-element">
          <mat-label>Leave Type</mat-label>
          <mat-select disableOptionCentering class="form-control" formControlName="leaveTypeId">
            <!-- <mat-option value="">All Leave Types Having the Default Leave Period</mat-option> -->
            <mat-option value="">--Select--</mat-option>
            <mat-option *ngFor="let t of leaveTypeList" value="{{ t.id }}">{{ t.leaveType }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-4" *ngIf="filterForm.value.generateFor != ''">
        <mat-form-field>
          <mat-label>Leave Period ID</mat-label>
          <mat-select formControlName="leavePeriodId" required disableOptionCentering class="form-control">
            <mat-option value="" *ngIf="filterForm.value.generateFor == 'employee'">All</mat-option>
            <mat-option *ngFor="let l of leavePeriodList" value="{{ l.id }}">{{
              l.currentleaveperiod
              }}</mat-option>


          </mat-select>
        </mat-form-field>
      </div>

    </div>
    <div class="row form-group">
      <div class="col-sm-4" *ngIf="filterForm.value.generateFor == 'leaveType'">
        <mat-label></mat-label>
        <mat-checkbox class="form-control" formControlName="includePastEmployees" color="primary">Include Past
          Employee</mat-checkbox>
      </div>
    </div>

    <div class="text-right">
      <button *ngIf="filterForm.value.generateFor"
        [disabled]="filterForm.value.generateFor === 'employee' && !selectedEmployee.id || isLoading"
        (click)="generateCSV()" class="btn btn-primary mr-2" color="primary" mat-raised-button>
        Export To CSV
      </button>

      <button *ngIf="filterForm.value.generateFor"
        [disabled]="(filterForm.value.generateFor === 'employee' && !selectedEmployee.id) || isLoading"
        (click)="getLeaveUsage()" class="btn btn-primary" color="primary" mat-raised-button [class.spinner]="isLoading">
        <i class="flaticon-magnifiying-glass"></i>View
      </button>
    </div>
  </form>
</mat-expansion-panel>
<br /><br />
<div class="page-body no-title" [hidden]="!generateFirst">
  <div class="row">
    <div class="col-sm-12">
      <!-- <app-loading *ngIf="isLoading && !err" [isLoading]="isLoading && !err"></app-loading> -->
      <app-card [title]="'Leave Usage'">
        <br />
        <!-- <div class="row top-tools">
          <div class="col-xs-12 col-sm-12 col-md-6 left-tools"></div>
          <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
            <div class="relative-position search-filter-con">
              <h3>
                <span class="mytooltip tooltip-effect-4" *ngIf="this.prev" (click)="onPrev()">
                  <span class="tooltip-item accordion-head inner-checked">
                    <mat-icon aria-hidden="false"> navigate_before </mat-icon>
                  </span>
                  <span class="tooltip-content clearfix">
                    <span class="tooltip-text">Previous Period</span>
                  </span>
                </span>

                <span class="mytooltip tooltip-effect-4" *ngIf="!this.prev" (click)="onPrev()">
                  <span class="tooltip-item">
                    <mat-icon aria-hidden="false"> navigate_before </mat-icon>
                  </span>
                  <span class="tooltip-content clearfix">
                    <span class="tooltip-text">Previous Period</span>
                  </span>
                </span>
              </h3>
              &nbsp; &nbsp;
              <h5>
                {{ this.period }}
              </h5>
              &nbsp; &nbsp;
              <h3>
                <span class="mytooltip tooltip-effect-4" *ngIf="this.next" (click)="onNext()">
                  <span class="tooltip-item accordion-head inner-checked">
                    <mat-icon aria-hidden="false">navigate_next </mat-icon>
                  </span>
                  <span class="tooltip-content clearfix">
                    <span class="tooltip-text">Next Period</span>
                  </span>
                </span>

                <span class="mytooltip tooltip-effect-4" *ngIf="!this.next" (click)="onNext()">
                  <span class="tooltip-item">
                    <mat-icon aria-hidden="false">navigate_next </mat-icon>
                  </span>
                  <span class="tooltip-content clearfix">
                    <span class="tooltip-text">Next Period</span>
                  </span>
                </span>
              </h3>
            </div>
          </div>
        </div> -->
        <br />
        <div class="dt-responsive">
          <div class="table-responsive">
            <mat-card-content>
              <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
              <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8"
                (matSortChange)="sortData($event)">
                <ng-container matColumnDef="employeeId">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isLoading">
                    Employee <br />
                    Id
                  </th>
                  <td mat-cell *matCellDef="let element">{{ element.employeeId }}</td>
                </ng-container>

                <ng-container matColumnDef="employeeName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isLoading">Employee Name</th>
                  <td mat-cell *matCellDef="let element">{{ element.employeeName }}</td>
                </ng-container>

                <ng-container matColumnDef="leaveTypeName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isLoading">Leave Type</th>
                  <td mat-cell *matCellDef="let element">{{ element.leaveTypeName }}</td>
                </ng-container>

                <ng-container matColumnDef="leavePeriod">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isLoading">Leave Period</th>
                  <td mat-cell *matCellDef="let element">{{ element.leavePeriod }}</td>
                </ng-container>

                <ng-container matColumnDef="pending">
                  <th mat-header-cell *matHeaderCellDef>
                    Pending <br />
                    Approval <br />
                    (Days)
                  </th>
                  <td mat-cell *matCellDef="let element">{{ element.pending }}</td>
                </ng-container>
                <ng-container matColumnDef="entitlements">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isLoading">
                    Entitlements <br />
                    (Days)
                  </th>
                  <td mat-cell *matCellDef="let element">{{ element.entitlements }}</td>
                </ng-container>
                <ng-container matColumnDef="scheduled">
                  <th mat-header-cell *matHeaderCellDef>
                    Scheduled <br />
                    (Days)
                  </th>
                  <td mat-cell *matCellDef="let element">{{ element.scheduled }}</td>
                </ng-container>
                <ng-container matColumnDef="taken">
                  <th mat-header-cell *matHeaderCellDef>
                    Taken <br />
                    (Days)
                  </th>
                  <td mat-cell *matCellDef="let element">{{ element.taken }}</td>
                </ng-container>

                <ng-container matColumnDef="available">
                  <th mat-header-cell *matHeaderCellDef>
                    Available <br />
                    Balance <br />
                    (Days)
                  </th>
                  <td mat-cell *matCellDef="let element">{{ element.available }}</td>
                </ng-container>

                <ng-container matColumnDef="overdrawn">
                  <th mat-header-cell *matHeaderCellDef>
                    Total <br />
                    Overdrawn <br />
                    (Days)
                  </th>
                  <td mat-cell *matCellDef="let element">{{ element.overdrawn }}</td>
                </ng-container>

                <ng-container matColumnDef="netBalance">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isLoading">
                    Net <br />
                    Balance <br />
                    (Days)
                  </th>
                  <td mat-cell *matCellDef="let element">{{ element.netBalance }}</td>
                </ng-container>

                <!-- Header row first group -->
                <!-- <ng-container matColumnDef="header-row-first-group">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center" [attr.colspan]="5"></th>
                </ng-container> -->

                <!-- Header row second group -->
                <!-- <ng-container matColumnDef="header-row-second-group">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center" [attr.colspan]="3">
                    Usage of Entitlements
                  </th>
                </ng-container> -->

                <!-- <ng-container matColumnDef="header-row-third-group">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center" [attr.colspan]="4"></th>
                </ng-container> -->
                <!-- <tr
                  mat-header-row
                  *matHeaderRowDef="['header-row-first-group', 'header-row-second-group', 'header-row-third-group']"
                ></tr> -->
                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns"></tr>
              </table>

              <mat-paginator [pageSize]="pageSize" [pageIndex]="pageIndex" [pageSizeOptions]="[10, 25, 50]"
                showFirstLastButtons [length]="total" (page)="handlePageEvent($event)"
                [disabled]="isLoading"></mat-paginator>
            </mat-card-content>
          </div>
          <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
        </div>
      </app-card>
    </div>
  </div>
</div>