<div id="header-page" class="ng-star-inserted header-page">
    <div class="page-header ng-star-inserted">
        <div class="page-block ng-star-inserted">
            <div class="row align-items-center">
                <div class="col-md-8">
                    <div class="page-header-title">
                        <span class="ng-star-inserted"></span><span class="ng-star-inserted">
                            <h4 class="m-b-10 ng-star-inserted">My Courses</h4>
                        </span>
                    </div>
                </div>
                <div class="col-md-4"></div>
            </div>
        </div>
    </div>
</div>

<div class="page-body no-title" style="margin-top: 15px">
    <div class="row">
        <div class="col-sm-12">

            <div class="row top-tools">
                <div class="col-xs-12 col-sm-12 col-md-6 left-tools">
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
                    <div class="relative-position search-filter-con">
                        <span class="mytooltip cus-left tooltip-effect-4">
                            <span class="tooltip-item" style="padding: 10px">
                                <button mat-mini-fab class="btn btn-primary light add-rounded margin-button-05"
                                    (click)="filterCourseList()">
                                    <i class="fa fa-filter"></i>
                                </button>
                            </span>
                            <span class="tooltip-content clearfix">
                                <span class="tooltip-text">Filter</span>
                            </span>
                        </span>
                    </div>
                </div>
            </div>

            <app-card [title]="'Course'">
                <div class="dt-responsive" *ngIf="dataSource">
                    <div class="table-responsive">
                        <mat-card-content>
                            <mat-table [dataSource]="dataSource" matSort>
                                <ng-container matColumnDef="title">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Title</mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        <span class="cursor-pointer">{{ row.title}}</span>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="subunit">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Subunit</mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        {{ row.subUnitName }}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="coordinator">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Coordinator</mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        {{row?.coordinatorid}} {{ row.coordinatorName }}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="date">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        {{ row.startDate | date :'dd/MM/yyyy'}} - {{row.endDate | date :'dd/MM/yyyy'}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="company">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Company</mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        {{ row.companyName }}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="status">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        <div>{{row?.archived ? 'Archived' : 'Active'}}</div>
                                    </mat-cell>
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: columns;" (click)="viewAction(row?.id)"
                                    class="cursor-pointer">
                                </mat-row>
                            </mat-table>
                            <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
                            <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                        </mat-card-content>
                    </div>
                    <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
                </div>
            </app-card>
        </div>
    </div>
</div>

<ng-template #addCourse let-modal>
    <!-- <div class="card my-0"> -->
    <!-- <div class="header"> -->
    <div class="mat-modal-header">
        <!-- <h4 class="card-title card-title-modal"> -->
        <h4 class="mat-title-modal">
            <span>Add Course</span>
        </h4>
    </div>
    <!-- <div class="card-content"> -->
    <div class="mat-modal-body">
        <app-loading *ngIf="isCourseAdded" [isLoading]="isCourseAdded"></app-loading>
        <!-- <form class="form-material mt-4" action="javascript:" [formGroup]="userForm" *ngIf="!isUserAdded"> -->
        <form class="form-material full-width-inputs" action="javascript:" [formGroup]="courseForm"
            *ngIf="!isCourseAdded">
            <div class="row form-group">
                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <mat-label>Title</mat-label>
                        <input class="form-control" formControlName="title" id="title" matInput maxlength="40"
                            required="" type="text">
                    </mat-form-field>
                </div>
                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <mat-label>Coordinator</mat-label>
                        <input required="" class="form-control" type="text" aria-label="Coordinator" matInput
                            formControlName="coordinatorid" [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayEmployeeByName.bind(this)">
                            <mat-option *ngFor="let r of filteredOptions " [value]="r.value">
                                {{r.label}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error
                            *ngIf="!courseForm.controls.coordinatorid.valid && courseForm.controls.coordinatorid.touched">
                            please enter coordinator
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-6">
                    <div class="row">
                        <div class="col-5">
                            <mat-form-field class="form-element">
                                <mat-select class="form-control" formControlName="subUnitId" disableOptionCentering>
                                    <mat-option value="">--Select--</mat-option>
                                    <ng-container *ngFor="let j of temporaryDepartment">
                                        <mat-option value="{{j.id}}">{{ j.name }}</mat-option>
                                    </ng-container>
                                </mat-select>
                                <mat-label for="des">Sub Unit</mat-label>
                            </mat-form-field>
                        </div>
                        <div class="col-3">
                            <mat-form-field class="form-element">
                                <mat-label for="des">Version</mat-label>
                                <input class="form-control" formControlName="version" matInput>
                            </mat-form-field>
                        </div>
                        <div class="col-4">
                            <mat-form-field class="form-element">
                                <mat-label>Sub Version</mat-label>
                                <input class="form-control" formControlName="subVersion" matInput>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6">
                    <div class="row">
                        <div class="col-6">
                            <mat-form-field class="form-element">
                                <mat-label>Currency</mat-label>
                                <!-- <mat-select class="form-control" formControlName="currencyName">
                                    <mat-option *ngFor="let n of currencyList" [value]="n.name">
                                        {{ n.name }}
                                    </mat-option>
                                </mat-select> -->
                                <input class="form-control" formControlName="currencyName" matInput>
                            </mat-form-field>
                        </div>
                        <div class="col-6">
                            <mat-form-field class="form-element">
                                <mat-label>Cost</mat-label>
                                <input class="form-control" formControlName="cost" matInput maxlength="40" type="text">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <mat-label>Company</mat-label>
                        <input class="form-control" formControlName="companyName" matInput maxlength="40" type="text">
                    </mat-form-field>
                </div>
                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <mat-label>duration</mat-label>
                        <input class="form-control" formControlName="duration" matInput maxlength="40" type="text">
                    </mat-form-field>
                </div>

                <div class="col-sm-12">
                    <mat-form-field class="form-element">
                        <mat-label>Description</mat-label>
                        <textarea class="form-control" matInput placeholder="Description"
                            formControlName="description"></textarea>
                    </mat-form-field>
                </div>

            </div>
            <div class="action-buttons multi-btns col-sm-12 text-right">
                <button mat-raised-button class="btn-danger" (click)="close()">
                    <i class="flaticon-cancel"></i>Cancel
                </button>
                <button mat-raised-button class="btn btn-primary" color="primary"
                    [disabled]="!courseForm.valid ||!this.correct" (click)="submitAddUser()"><i
                        class="flaticon-diskette"></i>Save
                </button>
            </div>
        </form>
    </div>
    <!-- </div> -->
</ng-template>

<ng-template #attach let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">Attachments</h4>
        </div>
        <div class="mat-modal-body">
            <form class="form-material full-width-inputs">
                <div class="row">
                    <div class="col-md-12 border" style="height: 200px !important; overflow-y: auto">
                        <mat-spinner *ngIf="this.showSpinner"></mat-spinner>
                        <mat-card *ngIf="showSpinner == false && this.attachmentList.length === 0">
                            No Attachment Found
                        </mat-card>
                        <ng-container *ngFor="let attachment of attachmentList; let i = index;">
                            <mat-card class="mt-2 shadow-sm">
                                <mat-card-title>
                                    {{attachment.fileName}}
                                </mat-card-title>
                                <mat-card-content>
                                    <b>Added On :</b> {{attachment.added | date :'dd/MM/yyyy' }} <br>
                                    <b>comments :</b> {{attachment.desc}}
                                </mat-card-content>
                                <mat-card-actions align="end">
                                    <!--                                    <button (click)="onDeleteClicked(attachment)" mat-icon-button-->
                                    <!--                                            aria-label="Example icon button with a vertical three dot icon">-->
                                    <!--                                        <mat-icon color="warn">delete</mat-icon>-->
                                    <!--                                    </button>-->
                                    <button (click)="onDownloadClicked(attachment)" mat-icon-button
                                        aria-label="Example icon button with a vertical three dot icon">
                                        <mat-icon color="primary">cloud_download</mat-icon>
                                    </button>
                                </mat-card-actions>
                            </mat-card>
                        </ng-container>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="action-buttons col-md-12 text-right">
                        <button mat-raised-button class="btn-danger" (click)="close()">
                            <i class="flaticon-cancel"></i>Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<ng-template #deleteCourse let-modal>
    <!-- <div class="card my-0"> -->
    <div mat-dialog-actions>
        <!-- <div class="header">  -->
        <div class="mat-modal-header">
            <!-- <h4 class="card-title card-title-modal">Delete User</h4>  -->
            <h4 class="mat-title-modal">Delete Course</h4>
        </div>
        <!-- <div class="card-content px-2 py-2"> -->
        <div class="mat-modal-body">
            <div class="text-left are-u">Are you sure ?</div>
            <div class="row">
                <div class="multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close()">
                        <i class="flaticon-cancel"></i>Cancel</button>
                    <button class="btn-primary" color="primary" mat-raised-button (click)="deleteMultiple()"><i
                            class="flaticon-trash"></i>Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #archive let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">Archive</h4>
        </div>
        <div class="mat-modal-body">
            <div class="text-left are-u">Are you sure ?</div>
            <div class="row">
                <div class="action-buttons multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close()"><i
                            class="flaticon-cancel"></i>Cancel</button>
                    <button mat-raised-button class="btn-primary" (click)="courseArchive()" color="primary"><i
                            class="flaticon-open"></i>Archive</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #filterCourse let-modal>
    <div mat-dialog-content>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">
                <span>Filter Courses</span>
            </h4>
        </div>
        <div class="mat-modal-body">
            <form [formGroup]="filterForm" action="javascript:" class="form-material full-width-inputs">
                <div class="row form-group">
                    <div class="col-sm-6">
                        <mat-form-field class="form-element">
                            <mat-label>Title</mat-label>
                            <input class="form-control" formControlName="title" matInput maxlength="40" type="text">
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6">
                        <mat-form-field class="form-element">
                            <input class="form-control" formControlName="version" matInput>
                            <mat-label for="des">Version</mat-label>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6">
                        <mat-form-field class="form-element">
                            <mat-label>Coordinator</mat-label>
                            <input placeholder="Type for hints .." class="form-control" type="text"
                                aria-label="Employee" matInput [matAutocomplete]="auto"
                                [ngModelOptions]="{standalone: true}" [(ngModel)]="coordinatorId"
                                [formControl]="empCtrl">
                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayEmployeeByName.bind(this)">
                                <mat-option *ngFor="let n of filteredOptions" [value]="n.value">
                                    {{n.label}}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error
                                *ngIf="!filterForm.controls.coordinatorId.valid && filterForm.controls.coordinatorId.touched">
                                please enter coordinator
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6">
                        <mat-form-field class="form-element">
                            <mat-select class="form-control" formControlName="subUnitId" disableOptionCentering>
                                <mat-option value="">--Select--</mat-option>
                                <ng-container *ngFor="let j of temporaryDepartment">
                                    <mat-option value="{{j.id}}">{{ j.name }}</mat-option>
                                </ng-container>
                            </mat-select>
                            <mat-label for="des">Sub Unit</mat-label>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6">
                        <mat-form-field class="form-element">
                            <mat-label>Company</mat-label>
                            <input class="form-control" formControlName="companyName" matInput maxlength="40"
                                type="text">
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6">
                        <mat-checkbox #includeArchived [(ngModel)]="includeArchived.checked" color="primary">Include
                            Archived </mat-checkbox>
                    </div>

                </div>
            </form>
            <div class="action-buttons multi-btns col-sm-12 text-right">
                <button (click)="resetForm()" class="btn-danger reset-btn" mat-raised-button>
                    <i class="flaticon-refresh"></i>Reset
                </button>
                <button (click)="close()" class="btn-danger" mat-raised-button>
                    <i class="flaticon-cancel"></i>Cancel
                </button>
                <button (click)="search()" class="btn btn-primary" color="primary" mat-raised-button><i
                        class="flaticon-magnifiying-glass"></i>Search
                </button>
            </div>
        </div>
    </div>
</ng-template>