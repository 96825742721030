<div id="header-page" class="ng-star-inserted header-page">
    <div class="page-header ng-star-inserted">
        <div class="page-block ng-star-inserted">
        <div class="row align-items-center">
            <div class="col-md-8">
            <div class="page-header-title">
                <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted">Optional Fields</h4></span>
            </div>
            </div>
            <div class="col-md-4"></div>
        </div>
        </div>
    </div>
</div>

<div class="page-body">
    <div class="row">
        <div class="col-sm-12">
            <app-card>
                <h3>Show Fields in Personal Detail</h3>
                <mat-checkbox  [(ngModel)]="body.health"  class="tree-checkbox">Show Health Section in Personal Details 
                 </mat-checkbox>
                 <br>
                <mat-checkbox  [(ngModel)]="body.preference"  class="tree-checkbox">Show Preference  in Personal Details
                </mat-checkbox> 
                <div class="row my-2 mx-2" [hidden] = "!this.update"> 
                    <div class="action-buttons multi-btns col-sm-12 text-right">
                        <button mat-raised-button class="btn-primary" color="primary"  (click)="submit()" ><i class="flaticon-diskette"></i> Save </button>
                    </div>
                  </div>
            </app-card>
        </div>
    </div>
</div>



