<app-loading *ngIf="isLoading && !err" [isLoading]="isLoading && !err"></app-loading>
<div [hidden]="isLoading" class="add-btn-circle">
    <div class="row" style="position: relative">
        <div class="col-sm-10"></div>
        <div class="col-sm-2 position-specific float-add" style="text-align: right;">
            <button class="btn-primary" color="primary" mat-raised-button [matMenuTriggerFor]="menu">
                Add Qualifcation<mat-icon>arrow_drop_down</mat-icon>
               </button>
               <!-- #enddocregion mat-menu-trigger-for -->
               <mat-menu #menu="matMenu" [overlapTrigger]="false">
                 <button (click)="setAny('we')" (click)="addNewUser()"  mat-menu-item>
                    Work Experience
                 </button>
                 <button (click)="setAny('edu')" (click)="addNewUser()"  mat-menu-item>
                  Education
                 </button>
                 <button (click)="setAny('skills')" (click)="addNewUser()"  mat-menu-item>
                    Skills
                 </button>
                 <button (click)="setAny('license')" (click)="addNewUser()"  mat-menu-item>
                    License
                 </button>
                 <button (click)="setAny('lang')" (click)="addNewUser()"  mat-menu-item>
                     Language
                 </button>
                 <button (click)="setAny('membership')" (click)="addNewUser()"  mat-menu-item>
                    Membership
                 </button>
                 <button (click)="setAny('training')" (click)="addNewUser()"  mat-menu-item>
                    Training
                 </button>
                 <button (click)="setAny('publication')" (click)="addNewUser()"  mat-menu-item>
                    Publication
                 </button>
                 <button (click)="setAny('award')" (click)="addNewUser()"  mat-menu-item>
                    Award
                 </button>
               </mat-menu>

        </div>
    </div>
</div>

<div [hidden]="isLoading" class="page-body multi-data-tables  minus-top-margin-1">
    <div class="row">
        <div class="col-sm-12">
            <h4 class="mat-title-modal">Work Experience</h4>
            <div class="dt-responsive">
                <app-loading *ngIf="isLoading && !err" [isLoading]="isLoading && !err"></app-loading>
                <div class="dt-responsive">
                    <div class="table-responsive">
                        <mat-card-content>
                            <mat-table #workExperienceTable [dataSource]="workExperienceList" matSort>
                                <ng-container matColumnDef="company">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>company</mat-header-cell>
                                    <mat-cell (click)="this.update ? editUser(row.id, 'we') : doNOthing()" class="{{this.editClass}}"
                                              *matCellDef="let row">
                                        {{ row.company }}
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="from">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>From</mat-header-cell>
                                    <mat-cell (click)="this.update ? editUser(row.id, 'we') : doNOthing()" class="{{this.editClass}}"
                                              *matCellDef="let row">
                                        {{ row.from | date : 'dd-MM-yyyy' }}
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="to">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>To</mat-header-cell>
                                    <mat-cell (click)="this.update ? editUser(row.id, 'we') : doNOthing()" class="{{this.editClass}}"
                                              *matCellDef="let row">
                                        {{ row.to | date : 'dd-MM-yyyy' }}
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="jobTitle">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Job Title</mat-header-cell>
                                    <mat-cell (click)="this.update ? editUser(row.id, 'we') : doNOthing()" class="{{this.editClass}}"
                                              *matCellDef="let row">
                                        {{ row.jobTitle }}
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="comment">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Comment</mat-header-cell>
                                    <mat-cell (click)="this.update ? editUser(row.id, 'we') : doNOthing()" class="{{this.editClass}}"
                                              *matCellDef="let row">
                                        {{ row.comment }}
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="attachment">
                                    <mat-header-cell *matHeaderCellDef>Attachment</mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                      <span class="mytooltip tooltip-effect-4" *ngIf="!row.hasAttachment">
                        <span class="tooltip-item"><i class="single-icon-click flaticon-attachment"
                                                      style="color: #d0cdcd"
                                                      (click)="onAddAttachment(row.id)"></i></span>
                        <span class="tooltip-content clearfix">
                        <span class="tooltip-text">No attachment</span>
                        </span>
                      </span>

                                        <span class="mytooltip tooltip-effect-4" *ngIf="row.hasAttachment">
                        <span class="tooltip-item"><i class="single-icon-click flaticon-attachment" style="color: #666"
                                                      (click)="onAddAttachment(row.id)"></i></span>
                        <span class="tooltip-content clearfix">
                        <span class="tooltip-text">Include attachment</span>
                        </span>
                      </span>
                                    </mat-cell>
                                </ng-container>


                                <ng-container matColumnDef="delete">
                                    <mat-header-cell [hidden]="!delete" *matHeaderCellDef>Delete</mat-header-cell>
                                    <mat-cell [hidden]="!delete" *matCellDef="let row">
                      <span class="mytooltip tooltip-effect-4">
                      <span class="tooltip-item"><i (click)="deleteUserForm(row.id, 'we')"
                                                    class="single-icon-click fa fa-trash"></i></span>
                      <span class="tooltip-content clearfix">
                      <span class="tooltip-text">Delete</span>
                      </span>
                      </span>
                                    </mat-cell>
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="workExperienceListColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: workExperienceListColumns;">
                                </mat-row>
                            </mat-table>
                            <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
                            <mat-paginator #workPaginator [pageSizeOptions]="[5, 10, 15]"
                                           showFirstLastButtons></mat-paginator>
                        </mat-card-content>
                    </div>
                    <div *ngIf="!isLoading && err" class="error-msg">{{ err }}</div>
                </div>
            </div>
        </div>

        <div class="col-sm-12">
            <h4 class="mat-title-modal">Education</h4>
            <div class="dt-responsive" *ngIf="educationListData">
                <app-loading *ngIf="isLoading && !err" [isLoading]="isLoading && !err"></app-loading>
                <div class="table-responsive">
                    <mat-card-content>
                        <mat-table [dataSource]="educationListData" matSort>
                            <ng-container matColumnDef="level">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Level</mat-header-cell>
                                <mat-cell (click)="this.update ? editUser(row.id, 'edu') : doNOthing()" class="{{this.editClass}}"
                                          *matCellDef="let row">
                                    {{ row.name }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="from">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>From</mat-header-cell>
                                <mat-cell (click)="this.update ? editUser(row.id, 'edu') : doNOthing()" class="{{this.editClass}}"
                                          *matCellDef="let row">
                                    {{ row.from | date : 'dd-MM-yyyy' }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="to">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>To</mat-header-cell>
                                <mat-cell (click)="this.update ? editUser(row.id, 'edu') : doNOthing()" class="{{this.editClass}}"
                                          *matCellDef="let row">
                                    {{ row.to | date : 'dd-MM-yyyy' }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="institute">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Institute</mat-header-cell>
                                <mat-cell (click)="this.update ? editUser(row.id, 'edu') : doNOthing()" class="{{this.editClass}}"
                                          *matCellDef="let row">
                                    {{ row.institute }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="major">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Major</mat-header-cell>
                                <mat-cell (click)="this.update ? editUser(row.id, 'edu') : doNOthing()"  class="{{this.editClass}}"
                                          *matCellDef="let row">
                                    {{ row.major }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="score">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Score</mat-header-cell>
                                <mat-cell (click)="this.update ? editUser(row.id, 'edu') : doNOthing()" class="{{this.editClass}}"
                                          *matCellDef="let row">
                                    {{ row.score }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="attachment">
                                <mat-header-cell *matHeaderCellDef>Attachment</mat-header-cell>
                                <mat-cell *matCellDef="let row">
                    <span class="mytooltip tooltip-effect-4" *ngIf="!row.hasAttachment">
                      <span class="tooltip-item"><i class="single-icon-click flaticon-attachment" style="color: #d0cdcd"
                                                    (click)="onAddAttachment(row.id)"></i></span>
                      <span class="tooltip-content clearfix">
                      <span class="tooltip-text">No attachment</span>
                      </span>
                    </span>

                                    <span class="mytooltip tooltip-effect-4" *ngIf="row.hasAttachment">
                      <span class="tooltip-item"><i class="single-icon-click flaticon-attachment" style="color: #666"
                                                    (click)="onAddAttachment(row.id)"></i></span>
                      <span class="tooltip-content clearfix">
                      <span class="tooltip-text">Include attachment</span>
                      </span>
                    </span>
                                </mat-cell>
                            </ng-container>


                            <ng-container matColumnDef="delete">
                                <mat-header-cell [hidden]="!delete" *matHeaderCellDef>Delete</mat-header-cell>
                                <mat-cell [hidden]="!delete" *matCellDef="let row">
                      <span class="mytooltip tooltip-effect-4">
                      <span class="tooltip-item"><i (click)="deleteUserForm(row.id, 'edu')"
                                                    class="single-icon-click fa fa-trash"></i></span>
                      <span class="tooltip-content clearfix">
                      <span class="tooltip-text">Delete</span>
                      </span>
                      </span>
                                </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="educationListDataColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: educationListDataColumns;">
                            </mat-row>
                        </mat-table>
                        <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
                        <mat-paginator #eduPagination [pageSizeOptions]="[5, 10, 15]"
                                       showFirstLastButtons></mat-paginator>
                    </mat-card-content>
                </div>
                <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
            </div>
        </div>

        <div class="col-sm-12">
            <h4 class="mat-title-modal">Skills</h4>
            <div class="dt-responsive">
                <app-loading *ngIf="isLoading && !err" [isLoading]="isLoading && !err"></app-loading>
                <div class="table-responsive" *ngIf="skillsListData">
                    <div class="table-responsive">
                        <mat-card-content>
                            <mat-table [dataSource]="skillsListData" matSort>
                                <ng-container matColumnDef="skill">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Skill</mat-header-cell>
                                    <mat-cell (click)="this.update ? editUser(row.id, 'skills') : doNOthing()" class="{{this.editClass}}"
                                              *matCellDef="let row">
                                        {{ row.name }}
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="year">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Year</mat-header-cell>
                                    <mat-cell (click)="this.update ? editUser(row.id, 'skills') : doNOthing()" class="{{this.editClass}}"
                                              *matCellDef="let row">
                                        {{ row.year }}
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="comment">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>comment</mat-header-cell>
                                    <mat-cell (click)="this.update ? editUser(row.id, 'skills') : doNOthing()" class="{{this.editClass}}"
                                              *matCellDef="let row">
                                        {{ row.comment }}
                                    </mat-cell>
                                </ng-container>


                                <ng-container matColumnDef="attachment">
                                    <mat-header-cell *matHeaderCellDef>Attachment</mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                      <span class="mytooltip tooltip-effect-4" *ngIf="!row.hasAttachment">
                        <span class="tooltip-item"><i class="single-icon-click flaticon-attachment"
                                                      style="color: #d0cdcd"
                                                      (click)="onAddAttachment(row.id)"></i></span>
                        <span class="tooltip-content clearfix">
                        <span class="tooltip-text">No attachment</span>
                        </span>
                      </span>

                                        <span class="mytooltip tooltip-effect-4" *ngIf="row.hasAttachment">
                        <span class="tooltip-item"><i class="single-icon-click flaticon-attachment" style="color: #666"
                                                      (click)="onAddAttachment(row.id)"></i></span>
                        <span class="tooltip-content clearfix">
                        <span class="tooltip-text">Include attachment</span>
                        </span>
                      </span>
                                    </mat-cell>
                                </ng-container>


                                <ng-container matColumnDef="delete">
                                    <mat-header-cell [hidden]="!delete" *matHeaderCellDef>Delete</mat-header-cell>
                                    <mat-cell [hidden]="!delete" *matCellDef="let row">
                      <span class="mytooltip tooltip-effect-4">
                      <span class="tooltip-item"><i (click)="deleteUserForm(row.id, 'skills')"
                                                    class="single-icon-click fa fa-trash"></i></span>
                      <span class="tooltip-content clearfix">
                      <span class="tooltip-text">Delete</span>
                      </span>
                      </span>
                                    </mat-cell>
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="skillsListDataColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: skillsListDataColumns;">
                                </mat-row>
                            </mat-table>
                            <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
                            <mat-paginator #skillPaginator [pageSizeOptions]="[5, 10, 15]"
                                           showFirstLastButtons></mat-paginator>
                        </mat-card-content>
                    </div>
                    <div *ngIf="!isLoading && err" class="error-msg">{{ err }}</div>
                </div>
            </div>
        </div>

        <div class="col-sm-12">
            <h4 class="mat-title-modal">License</h4>
            <div class="dt-responsive" *ngIf="licenseListData">
                <app-loading *ngIf="isLoading && !err" [isLoading]="isLoading && !err"></app-loading>
                <div class="table-responsive">
                    <mat-card-content>
                        <mat-table [dataSource]="licenseListData" matSort>
                            <ng-container matColumnDef="license">
                                <mat-header-cell  *matHeaderCellDef mat-sort-header>License</mat-header-cell>
                                <mat-cell (click)="this.update ? editUser(row.id, 'license') : doNOthing()" class="{{this.editClass}}"
                                          *matCellDef="let row">
                                    {{ row.name }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="licenseNo">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>License No</mat-header-cell>
                                <mat-cell (click)="this.update ? editUser(row.id, 'license') : doNOthing()" class="{{this.editClass}}"
                                          *matCellDef="let row">
                                    {{ row.licenseNo }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="issDate">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>issue Date</mat-header-cell>
                                <mat-cell (click)="this.update ? editUser(row.id, 'license') : doNOthing()" class="{{this.editClass}}"
                                          *matCellDef="let row">
                                    {{ row.issDate | date : 'dd-MM-yyyy' }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="expDate">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>expire Date</mat-header-cell>
                                <mat-cell (click)="this.update ? editUser(row.id, 'license') : doNOthing()" class="{{this.editClass}}"
                                          *matCellDef="let row">
                                    {{ row.expDate | date : 'dd-MM-yyyy' }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="attachment">
                                <mat-header-cell *matHeaderCellDef>Attachment</mat-header-cell>
                                <mat-cell (click)="editUser(row.id, 'license')" *matCellDef="let row">
                    <span class="mytooltip tooltip-effect-4" *ngIf="!row.hasAttachment">
                      <span class="tooltip-item"><i class="single-icon-click flaticon-attachment" style="color: #d0cdcd"
                                                    (click)="onAddAttachment(row.id)"></i></span>
                      <span class="tooltip-content clearfix">
                      <span class="tooltip-text">No attachment</span>
                      </span>
                    </span>

                                    <span class="mytooltip tooltip-effect-4" *ngIf="row.hasAttachment">
                      <span class="tooltip-item"><i class="single-icon-click flaticon-attachment" style="color: #666"
                                                    (click)="onAddAttachment(row.id)"></i></span>
                      <span class="tooltip-content clearfix">
                      <span class="tooltip-text">Include attachment</span>
                      </span>
                    </span>
                                </mat-cell>
                            </ng-container>


                            <ng-container matColumnDef="delete">
                                <mat-header-cell [hidden]="!delete" *matHeaderCellDef>Delete</mat-header-cell>
                                <mat-cell [hidden]="!delete" *matCellDef="let row">
                      <span class="mytooltip tooltip-effect-4">
                      <span class="tooltip-item"><i (click)="deleteUserForm(row.id, 'license')"
                                                    class="single-icon-click fa fa-trash"></i></span>
                      <span class="tooltip-content clearfix">
                      <span class="tooltip-text">Delete</span>
                      </span>
                      </span>
                                </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="licenseListDataColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: licenseListDataColumns;">
                            </mat-row>
                        </mat-table>
                        <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
                        <mat-paginator #licensePaginator [pageSizeOptions]="[5, 10, 15]"
                                       showFirstLastButtons></mat-paginator>
                    </mat-card-content>
                </div>
                <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
            </div>
        </div>

        <div class="col-sm-12">
            <h4 class="mat-title-modal">Language</h4>
            <div class="dt-responsive" *ngIf="languageListData">
                <app-loading *ngIf="isLoading && !err" [isLoading]="isLoading && !err"></app-loading>
                <div class="table-responsive">
                    <mat-card-content>
                        <mat-table [dataSource]="languageListData" matSort>
                            <ng-container matColumnDef="language">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Language</mat-header-cell>
                                <mat-cell  (click)="this.update ? editUser(row.id, 'lang') : doNOthing()" class="{{this.editClass}}"
                                          *matCellDef="let row">
                                    {{ row.name }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="fluencyLevel">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Fluency Level</mat-header-cell>
                                <mat-cell (click)="this.update ? editUser(row.id, 'lang') : doNOthing()" class="{{this.editClass}}"
                                          *matCellDef="let row">
                                    {{ row.fluencyLevel }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="skill">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Skill</mat-header-cell>
                                <mat-cell (click)="this.update ? editUser(row.id, 'lang') : doNOthing()" class="{{this.editClass}}"
                                          *matCellDef="let row">
                                    {{ row.skill }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="comment">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Comment</mat-header-cell>
                                <mat-cell (click)="this.update ? editUser(row.id, 'lang') : doNOthing()" class="{{this.editClass}}"
                                          *matCellDef="let row">
                                    {{ row.comment }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="attachment">
                                <mat-header-cell *matHeaderCellDef>Attachment</mat-header-cell>
                                <mat-cell *matCellDef="let row">
                    <span class="mytooltip tooltip-effect-4" *ngIf="!row.hasAttachment">
                      <span class="tooltip-item"><i class="single-icon-click flaticon-attachment" style="color: #d0cdcd"
                                                    (click)="onAddAttachment(row.id)"></i></span>
                      <span class="tooltip-content clearfix">
                      <span class="tooltip-text">No attachment</span>
                      </span>
                    </span>

                                    <span class="mytooltip tooltip-effect-4" *ngIf="row.hasAttachment">
                      <span class="tooltip-item"><i class="single-icon-click flaticon-attachment" style="color: #666"
                                                    (click)="onAddAttachment(row.id)"></i></span>
                      <span class="tooltip-content clearfix">
                      <span class="tooltip-text">Include attachment</span>
                      </span>
                    </span>
                                </mat-cell>
                            </ng-container>


                            <ng-container matColumnDef="delete">
                                <mat-header-cell [hidden]="!delete" *matHeaderCellDef>Delete</mat-header-cell>
                                <mat-cell [hidden]="!delete" *matCellDef="let row">
                      <span class="mytooltip tooltip-effect-4">
                      <span class="tooltip-item"><i (click)="deleteUserForm(row.id, 'lang')"
                                                    class="single-icon-click fa fa-trash"></i></span>
                      <span class="tooltip-content clearfix">
                      <span class="tooltip-text">Delete</span>
                      </span>
                      </span>
                                </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="languageListDataColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: languageListDataColumns;">
                            </mat-row>
                        </mat-table>
                        <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
                        <mat-paginator #languagePaginator [pageSizeOptions]="[5, 10, 15]"
                                       showFirstLastButtons></mat-paginator>
                    </mat-card-content>
                </div>
                <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
            </div>
        </div>

        <div class="col-sm-12">
            <h4 class="mat-title-modal">Membership</h4>
            <div class="dt-responsive" *ngIf="membershipListData">
                <app-loading *ngIf="isLoading && !err" [isLoading]="isLoading && !err"></app-loading>
                <div class="table-responsive">
                    <mat-card-content>
                        <mat-table [dataSource]="membershipListData" matSort>
                            <ng-container matColumnDef="name">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Membership</mat-header-cell>
                                <mat-cell (click)="this.update ? editUser(row.id, 'membership') : doNOthing()"  class="{{this.editClass}}"
                                          *matCellDef="let row">
                                    {{ row.name }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="spb">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Subscription Paid By
                                </mat-header-cell>
                                <mat-cell (click)="this.update ? editUser(row.id, 'membership') : doNOthing()" class="{{this.editClass}}"
                                          *matCellDef="let row">
                                    {{ row.spb }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="amount">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Subscription amount
                                </mat-header-cell>
                                <mat-cell (click)="this.update ? editUser(row.id, 'membership') : doNOthing()" class="{{this.editClass}}"
                                          *matCellDef="let row">
                                    {{ row.amount }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="currency">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Currency</mat-header-cell>
                                <mat-cell (click)="this.update ? editUser(row.id, 'membership') : doNOthing()" class="{{this.editClass}}"
                                          *matCellDef="let row">
                                    {{ row.currency }}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="scd">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Subscription Commence Date
                                </mat-header-cell>
                                <mat-cell (click)="this.update ? editUser(row.id, 'membership') : doNOthing()" class="{{this.editClass}}"
                                          *matCellDef="let row">
                                    {{  row.scd | date : 'dd-MM-yyyy' }}

                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="srd">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Subscription Renewal Date
                                </mat-header-cell>
                                <mat-cell (click)="this.update ? editUser(row.id, 'membership') : doNOthing()" class="{{this.editClass}}"
                                          *matCellDef="let row">
                                    {{  row.srd | date : 'dd-MM-yyyy' }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="attachment">
                                <mat-header-cell *matHeaderCellDef>Attachment</mat-header-cell>
                                <mat-cell *matCellDef="let row">
                    <span class="mytooltip tooltip-effect-4" *ngIf="!row.hasAttachment">
                      <span class="tooltip-item"><i class="single-icon-click flaticon-attachment" style="color: #d0cdcd"
                                                    (click)="onAddAttachment(row.id)"></i></span>
                      <span class="tooltip-content clearfix">
                      <span class="tooltip-text">No attachment</span>
                      </span>
                    </span>

                                    <span class="mytooltip tooltip-effect-4" *ngIf="row.hasAttachment">
                      <span class="tooltip-item"><i class="single-icon-click flaticon-attachment" style="color: #666"
                                                    (click)="onAddAttachment(row.id)"></i></span>
                      <span class="tooltip-content clearfix">
                      <span class="tooltip-text">Include attachment</span>
                      </span>
                    </span>
                                </mat-cell>
                            </ng-container>


                            <ng-container matColumnDef="delete">
                                <mat-header-cell [hidden]="!delete" *matHeaderCellDef>Delete</mat-header-cell>
                                <mat-cell [hidden]="!delete" *matCellDef="let row">
                      <span class="mytooltip tooltip-effect-4">
                      <span class="tooltip-item"><i (click)="deleteUserForm(row.id, 'membership')"
                                                    class="single-icon-click fa fa-trash"></i></span>
                      <span class="tooltip-content clearfix">
                      <span class="tooltip-text">Delete</span>
                      </span>
                      </span>
                                </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="membershipListDataColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: membershipListDataColumns;">
                            </mat-row>
                        </mat-table>
                        <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
                        <mat-paginator #memberShipPaginator [pageSizeOptions]="[5, 10, 15]"
                                       showFirstLastButtons></mat-paginator>
                    </mat-card-content>
                </div>
                <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
            </div>
        </div>

        <div class="col-sm-12">
            <h4 class="mat-title-modal">Training</h4>
            <div class="dt-responsive" *ngIf="trainingListData">
                <app-loading *ngIf="isLoading && !err" [isLoading]="isLoading && !err"></app-loading>
                <div class="table-responsive">
                    <mat-card-content>
                        <mat-table [dataSource]="trainingListData" matSort>
                            <ng-container matColumnDef="name">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Training</mat-header-cell>
                                <mat-cell (click)="this.update ? editUser(row.id, 'training') : doNOthing()"  class="{{this.editClass}}"
                                          *matCellDef="let row">
                                    {{ row.name }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="provider">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Provider
                                </mat-header-cell>
                                <mat-cell (click)="this.update ? editUser(row.id, 'training') : doNOthing()" class="{{this.editClass}}"
                                          *matCellDef="let row">
                                    {{ row.provider }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="sector">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Sector
                                </mat-header-cell>
                                <mat-cell (click)="this.update ? editUser(row.id, 'training') : doNOthing()" class="{{this.editClass}}"
                                          *matCellDef="let row">
                                    {{ row.sector }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="dateFrom">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Date From
                                </mat-header-cell>
                                <mat-cell (click)="this.update ? editUser(row.id, 'training') : doNOthing()" class="{{this.editClass}}"
                                          *matCellDef="let row">
                                    {{  row.dateFrom | date : 'dd-MM-yyyy' }}

                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="dateTo">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Date To
                                </mat-header-cell>
                                <mat-cell (click)="this.update ? editUser(row.id, 'training') : doNOthing()" class="{{this.editClass}}"
                                          *matCellDef="let row">
                                    {{  row.dateTo | date : 'dd-MM-yyyy' }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="attachment">
                                <mat-header-cell *matHeaderCellDef>Attachment</mat-header-cell>
                                <mat-cell *matCellDef="let row">
                    <span class="mytooltip tooltip-effect-4" *ngIf="!row.hasAttachment">
                      <span class="tooltip-item"><i class="single-icon-click flaticon-attachment" style="color: #d0cdcd"
                                                    (click)="onAddAttachment(row.id)"></i></span>
                      <span class="tooltip-content clearfix">
                      <span class="tooltip-text">No attachment</span>
                      </span>
                    </span>

                                    <span class="mytooltip tooltip-effect-4" *ngIf="row.hasAttachment">
                      <span class="tooltip-item"><i class="single-icon-click flaticon-attachment" style="color: #666"
                                                    (click)="onAddAttachment(row.id)"></i></span>
                      <span class="tooltip-content clearfix">
                      <span class="tooltip-text">Include attachment</span>
                      </span>
                    </span>
                                </mat-cell>
                            </ng-container>


                            <ng-container matColumnDef="delete">
                                <mat-header-cell [hidden]="!delete" *matHeaderCellDef>Delete</mat-header-cell>
                                <mat-cell [hidden]="!delete" *matCellDef="let row">
                      <span class="mytooltip tooltip-effect-4">
                      <span class="tooltip-item"><i (click)="deleteUserForm(row.id, 'training')"
                                                    class="single-icon-click fa fa-trash"></i></span>
                      <span class="tooltip-content clearfix">
                      <span class="tooltip-text">Delete</span>
                      </span>
                      </span>
                                </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="trainingDataColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: trainingDataColumns;">
                            </mat-row>
                        </mat-table>
                        <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
                        <mat-paginator #trainingPaginator [pageSizeOptions]="[5, 10, 15]"
                                       showFirstLastButtons></mat-paginator>
                    </mat-card-content>
                </div>
                <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
            </div>
        </div>

        <div class="col-sm-12">
            <h4 class="mat-title-modal">Publications</h4>
            <div class="dt-responsive" *ngIf="publicationListData">
                <app-loading *ngIf="isLoading && !err" [isLoading]="isLoading && !err"></app-loading>
                <div class="table-responsive">
                    <mat-card-content>
                        <mat-table [dataSource]="publicationListData" matSort>
                            <ng-container matColumnDef="name">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Publication</mat-header-cell>
                                <mat-cell (click)="this.update ? editUser(row.id, 'publication') : doNOthing()"  class="{{this.editClass}}"
                                          *matCellDef="let row">
                                    {{ row.name }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="journal">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>journal
                                </mat-header-cell>
                                <mat-cell (click)="this.update ? editUser(row.id, 'publication') : doNOthing()"  class="{{this.editClass}}"
                                          *matCellDef="let row">
                                    {{ row.journal }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="sector">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Sector
                                </mat-header-cell>
                                <mat-cell (click)="this.update ? editUser(row.id, 'publication') : doNOthing()"  class="{{this.editClass}}"
                                          *matCellDef="let row">
                                    {{ row.sector }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="date">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Date
                                </mat-header-cell>
                                <mat-cell (click)="this.update ? editUser(row.id, 'publication') : doNOthing()"  class="{{this.editClass}}"
                                          *matCellDef="let row">
                                    {{  row.date | date : 'dd-MM-yyyy' }}

                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="attachment">
                                <mat-header-cell *matHeaderCellDef>Attachment</mat-header-cell>
                                <mat-cell *matCellDef="let row">
                    <span class="mytooltip tooltip-effect-4" *ngIf="!row.hasAttachment">
                      <span class="tooltip-item"><i class="single-icon-click flaticon-attachment" style="color: #d0cdcd"
                                                    (click)="onAddAttachment(row.id)"></i></span>
                      <span class="tooltip-content clearfix">
                      <span class="tooltip-text">No attachment</span>
                      </span>
                    </span>

                                    <span class="mytooltip tooltip-effect-4" *ngIf="row.hasAttachment">
                      <span class="tooltip-item"><i class="single-icon-click flaticon-attachment" style="color: #666"
                                                    (click)="onAddAttachment(row.id)"></i></span>
                      <span class="tooltip-content clearfix">
                      <span class="tooltip-text">Include attachment</span>
                      </span>
                    </span>
                                </mat-cell>
                            </ng-container>


                            <ng-container matColumnDef="delete">
                                <mat-header-cell [hidden]="!delete" *matHeaderCellDef>Delete</mat-header-cell>
                                <mat-cell [hidden]="!delete" *matCellDef="let row">
                      <span class="mytooltip tooltip-effect-4">
                      <span class="tooltip-item"><i (click)="deleteUserForm(row.id, 'publication')"
                                                    class="single-icon-click fa fa-trash"></i></span>
                      <span class="tooltip-content clearfix">
                      <span class="tooltip-text">Delete</span>
                      </span>
                      </span>
                                </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="publicationDataColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: publicationDataColumns;">
                            </mat-row>
                        </mat-table>
                        <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
                        <mat-paginator #publicationPaginator [pageSizeOptions]="[5, 10, 15]"
                                       showFirstLastButtons></mat-paginator>
                    </mat-card-content>
                </div>
                <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
            </div>
        </div>


        <div class="col-sm-12">
            <h4 class="mat-title-modal">Awards</h4>
            <div class="dt-responsive" *ngIf="awardListData">
                <app-loading *ngIf="isLoading && !err" [isLoading]="isLoading && !err"></app-loading>
                <div class="table-responsive">
                    <mat-card-content>
                        <mat-table [dataSource]="awardListData" matSort>
                            <ng-container matColumnDef="name">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Award</mat-header-cell>
                                <mat-cell (click)="this.update ? editUser(row.id, 'award') : doNOthing()"   class="{{this.editClass}}"
                                          *matCellDef="let row">
                                    {{ row.name }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="donor">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Donor
                                </mat-header-cell>
                                <mat-cell (click)="this.update ? editUser(row.id, 'award') : doNOthing()" class="{{this.editClass}}"
                                          *matCellDef="let row">
                                    {{ row.donor }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="sector">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Sector
                                </mat-header-cell>
                                <mat-cell (click)="this.update ? editUser(row.id, 'award') : doNOthing()" class="{{this.editClass}}"
                                          *matCellDef="let row">
                                    {{ row.sector }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="date">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Date
                                </mat-header-cell>
                                <mat-cell (click)="this.update ? editUser(row.id, 'award') : doNOthing()" class="{{this.editClass}}"
                                          *matCellDef="let row">
                                    {{  row.date | date : 'dd-MM-yyyy' }}

                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="attachment">
                                <mat-header-cell *matHeaderCellDef>Attachment</mat-header-cell>
                                <mat-cell *matCellDef="let row">
                    <span class="mytooltip tooltip-effect-4" *ngIf="!row.hasAttachment">
                      <span class="tooltip-item"><i class="single-icon-click flaticon-attachment" style="color: #d0cdcd"
                                                    (click)="onAddAttachment(row.id)"></i></span>
                      <span class="tooltip-content clearfix">
                      <span class="tooltip-text">No attachment</span>
                      </span>
                    </span>

                                    <span class="mytooltip tooltip-effect-4" *ngIf="row.hasAttachment">
                      <span class="tooltip-item"><i class="single-icon-click flaticon-attachment" style="color: #666"
                                                    (click)="onAddAttachment(row.id)"></i></span>
                      <span class="tooltip-content clearfix">
                      <span class="tooltip-text">Include attachment</span>
                      </span>
                    </span>

                                </mat-cell>
                            </ng-container>


                            <ng-container matColumnDef="delete">
                                <mat-header-cell [hidden]="!delete" *matHeaderCellDef>Delete</mat-header-cell>
                                <mat-cell [hidden]="!delete" *matCellDef="let row">
                      <span class="mytooltip tooltip-effect-4">
                      <span class="tooltip-item"><i (click)="deleteUserForm(row.id, 'award')"
                                                    class="single-icon-click fa fa-trash"></i></span>
                      <span class="tooltip-content clearfix">
                      <span class="tooltip-text">Delete</span>
                      </span>
                      </span>
                                </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="awardDataColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: awardDataColumns;">
                            </mat-row>
                        </mat-table>
                        <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
                        <mat-paginator #awardPaginator [pageSizeOptions]="[5, 10, 15]"
                                       showFirstLastButtons></mat-paginator>
                    </mat-card-content>
                </div>
                <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
            </div>
        </div>
    </div>

    <div class="form-material full-width-inputs" [hidden]="this.isLoading">
        <div *ngFor="let section of this.sectionsList">
            <div *ngIf="section.customFields.length != 0" class="card-sub">
                <h4>{{section.name}}</h4>
                <ng-container>

                    <div class="row form-group">
                        <div class="col-4" *ngFor="let custom of section.customFields; index as i">
                            <mat-form-field class="form-element" *ngIf="custom.type === 'dd' ">
                                <mat-select [disabled]="!update" disableOptionCentering class="form-control"
                                            [(ngModel)]="custom.value" required="{{custom.required}}"
                                            (ngModelChange)="checkRequiredFieldsInCustomFields(section.id,section.customFields)">

                                    <ng-container>
                                        <mat-option value="">--Select--</mat-option>
                                        <mat-option *ngFor="let option of custom.options " [value]="option"
                                                    [(ngModel)]="custom.value">
                                            {{option}}
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                                <mat-label>{{custom.name}}</mat-label>
                            </mat-form-field>


                            <mat-form-field class="form-element" *ngIf="custom.type === 'st' ">
                                <mat-label>{{custom.name}}</mat-label>
                                <input  [readonly]="!update" matInput class="form-control" [(ngModel)]="custom.value"
                                       required="{{custom.required}}"
                                       (keyup)="checkRequiredFieldsInCustomFields(section.id,section.customFields)">
                            </mat-form-field>

                            <mat-form-field class="form-element" *ngIf="custom.type === 'num' ">
                                <mat-label>{{custom.name}}</mat-label>
                                <input  [readonly]="!update" matInput class="form-control" [(ngModel)]="custom.value" type="number"
                                       required="{{custom.required}}"
                                       (keyup)="checkRequiredFieldsInCustomFields(section.id,section.customFields)">
                            </mat-form-field>
                            <mat-form-field class="form-element" *ngIf="custom.type === 'dt' ">
                                <mat-label>{{custom.name}}</mat-label>
                                <input  [readonly]="!update" class="form-control" matInput [matDatepicker]="i" [attr.id]="dtPicker + i"
                                       [(ngModel)]="custom.value" required="{{custom.required}}"
                                       (ngModelChange)="checkRequiredFieldsInCustomFields(section.id,section.customFields)">
                                <mat-datepicker-toggle  [disabled]="!update" matSuffix [for]="i"></mat-datepicker-toggle>
                                <mat-datepicker #i></mat-datepicker>
                            </mat-form-field>

                            <div *ngIf="custom.type === 'cb' ">
                                <h5>{{custom.name}}:</h5>
                                <div *ngFor="let option of custom.values">
                                    <mat-checkbox [disabled]="!update" [(ngModel)]="option.booleanValue">{{option.option}}</mat-checkbox>
                                    &nbsp;
                                </div>

                            </div>


                        </div>


                        <div  [hidden]="!update" class="action-buttons multi-btns col-sm-12 text-right">
                            <button [disabled]="section.save"
                                    (click)="updateCustomField(section.id,section.customFields)"
                                    class="btn-primary"
                                    color="primary"
                                    mat-raised-button><i class="flaticon-diskette"></i>Save
                            </button>
                        </div>
                    </div>


                </ng-container>
            </div>

        </div>


    </div>

</div>

<ng-template #addWork let-modal>

    <div class="mat-modal-header">
        <h4 class="mat-title-modal">
            <span *ngIf="!isEdit">Add</span>
            <span *ngIf="isEdit">Edit</span>
            Work Experience
        </h4>
    </div>

    <div class="mat-modal-body">
        <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>

        <form class="form-material full-width-inputs" action="javascript:" [formGroup]="workForm">
            <div class="row form-group">
                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <input class="form-control" formControlName="company" matInput type="text" maxlength="40"
                               required="">
                        <span class="form-bar"></span>
                        <mat-label for="title">Company</mat-label>
                    </mat-form-field>
                </div>
                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <input class="form-control" formControlName="jobTitle" maxlength="40" matInput type="text"
                               required="">
                        <span class="form-bar"></span>
                        <mat-label for="title">Job Title</mat-label>
                    </mat-form-field>
                </div>
                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <input [matDatepicker]="picker"
                               class="form-control"
                               formControlName="from" matInput required="">
                        <mat-datepicker-toggle [for]="picker" matSuffix>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-label for="expiry">From
                        </mat-label>
                    </mat-form-field>
                </div>
                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <input [matDatepicker]="picker1"
                               class="form-control"
                               formControlName="to" matInput required="" [min]="this.workForm.value.from">
                        <mat-datepicker-toggle [for]="picker1" matSuffix>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                        <mat-label for="expiry">To
                        </mat-label>
                    </mat-form-field>
                </div>
                <div class="col-sm-12" [hidden]="!requiredWork.creditableAvailable">
                    <mat-checkbox required="{{requiredWork.creditableRequired}}" name="name"
                                  formControlName="creditable">Creditable
                    </mat-checkbox>
                </div>
                <div class="col-sm-12 mt-2" [hidden]="!requiredWork.commentsAvailable">
                    <mat-form-field class="form-element">
                        <input required="{{requiredWork.commentsRequired}}" class="form-control" maxlength="50"
                               formControlName="comment" matInput type="text">
                        <span class="form-bar"></span>
                        <mat-label for="title">Comment</mat-label>
                    </mat-form-field>
                </div>

                <div class="action-buttons multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close()">
                        <i class="flaticon-cancel"></i> Cancel
                    </button>
                    <button mat-raised-button class="btn btn-primary" color="primary"
                            (click)="submitAddJobCategory('we')" [disabled]="!workForm.valid"><i
                            class="flaticon-diskette"></i> Save
                    </button>
                </div>

            </div>
        </form>
    </div>
</ng-template>
<ng-template #addLicense let-modal>
    <div class="mat-modal-header">
        <h4 class="mat-title-modal">
            <span *ngIf="!isEdit">Add</span>
            <span *ngIf="isEdit">Edit</span>
            License
        </h4>
    </div>

    <div class="mat-modal-body">
        <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>

        <form class="form-material full-width-inputs" action="javascript:" [formGroup]="licenseForm">
            <div class="row">
                <div class="col-sm-6" [hidden]="!licenseFromGroup.licenseTypeAvailable">
                    <mat-form-field class="form-element">
                        <mat-select  required="{{licenseFromGroup.licenseTypeRequired}}" disableOptionCentering class="form-control" formControlName="license">
                            <ng-container
                                    *ngFor="let n of licenseList">
                                <mat-option
                                        value="{{n.id}}">{{n.name}}</mat-option>
                            </ng-container>
                        </mat-select>
                        <mat-label for="des">License Type</mat-label>
                    </mat-form-field>
                </div>
                <div class="col-sm-6" [hidden]="!licenseFromGroup.licenseNumberAvailable">
                    <mat-form-field class="form-element">
                        <input required="{{licenseFromGroup.licenseNumberRequired}}" class="form-control" formControlName="licenseNo" maxlength="40" matInput type="text">
                        <span class="form-bar"></span>
                        <mat-label for="title">License Number</mat-label>
                    </mat-form-field>
                </div>
                <div class="col-sm-6" [hidden]="!licenseFromGroup.issuedDateAvailable">
                    <mat-form-field class="form-element">
                        <input required="{{licenseFromGroup.issuedDateRequired}}" [matDatepicker]="picker"
                               class="form-control"
                               formControlName="issDate" matInput>
                        <mat-datepicker-toggle [for]="picker" matSuffix>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-label for="expiry">Issue Date
                        </mat-label>
                    </mat-form-field>
                </div>
                <div class="col-sm-6" [hidden]="!licenseFromGroup.expiryDateAvailable">
                    <mat-form-field class="form-element">
                        <input required="{{licenseFromGroup.expiryDateRequired}}" [matDatepicker]="picker1"
                               class="form-control"
                               formControlName="expDate" matInput [min]="this.licenseForm.value.issDate">
                        <mat-datepicker-toggle [for]="picker1" matSuffix>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                        <mat-label for="expiry">Expiry Date
                        </mat-label>
                    </mat-form-field>
                </div>

                <div class="action-buttons multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close()">
                        <i class="flaticon-cancel"></i> Cancel
                    </button>
                    <button mat-raised-button class="btn btn-primary" color="primary"
                            (click)="submitAddJobCategory('license')"><i class="flaticon-diskette"></i> Save
                    </button>
                </div>

            </div>
        </form>

    </div>

</ng-template>
<ng-template #addLanguage let-modal>
    <div class="mat-modal-header">
        <h4 class="mat-title-modal">
            <span *ngIf="!isEdit">Add</span>
            <span *ngIf="isEdit">Edit</span>
            Language
        </h4>
    </div>

    <div class="mat-modal-body">
        <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>

        <form class="form-material full-width-inputs" action="javascript:" [formGroup]="languageForm">
            <div class="row">
                <div class="col-sm-6" [hidden]="!languagesFromGroup.languagesAvailable">
                    <mat-form-field class="form-element">
                        <mat-select  required="{{languagesFromGroup.languagesRequired}}" disableOptionCentering class="form-control" formControlName="language">
                            <ng-container
                                    *ngFor="let n of languageList">
                                <mat-option
                                        value="{{n.id}}">{{n.name}}</mat-option>
                            </ng-container>
                        </mat-select>
                        <mat-label for="des">Language</mat-label>
                    </mat-form-field>
                </div>
                <div class=" col-sm-6" [hidden]="!languagesFromGroup.skillAvailable">
                    <mat-form-field class="form-element">
                        <mat-select required="{{languagesFromGroup.skillRequired}}" disableOptionCentering class="form-control" formControlName="skill">
                            <mat-option value="r">Reading</mat-option>
                            <mat-option value="w">Writing</mat-option>
                            <mat-option value="s">Speaking</mat-option>
                        </mat-select>
                        <mat-label for="des">Skills</mat-label>
                    </mat-form-field>
                </div>
                <div class=" col-sm-6" [hidden]="!languagesFromGroup.fluencyLevelAvailable">
                    <mat-form-field class="form-element">
                        <mat-select required="{{languagesFromGroup.fluencyLevelRequired}}" disableOptionCentering class="form-control" formControlName="fluencyLevel">
                            <mat-option value="b">Basic</mat-option>
                            <mat-option value="p">Poor</mat-option>
                            <mat-option value="g">Good</mat-option>
                        </mat-select>
                        <mat-label for="des">Fluency Level</mat-label>
                    </mat-form-field>
                </div>
                <div class="col-sm-6" [hidden]="!languagesFromGroup.commentsAvailable">
                    <mat-form-field class="form-element">
                        <input required="{{languagesFromGroup.commentsRequired}}" class="form-control" maxlength="50" formControlName="comment" matInput type="text">
                        <span class="form-bar"></span>
                        <mat-label for="title">Comment</mat-label>
                    </mat-form-field>
                </div>

                <div class="action-buttons multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close()">
                        <i class="flaticon-cancel"></i> Cancel
                    </button>
                    <button mat-raised-button class="btn btn-primary" color="primary"
                            (click)="submitAddJobCategory('lang')" [disabled]="!languageForm.valid"><i
                            class="flaticon-diskette"></i> Save
                    </button>
                </div>

            </div>
        </form>

    </div>

</ng-template>
<ng-template #addEducation let-modal>
    <div class="mat-modal-header">
        <h4 class="mat-title-modal">
            <span *ngIf="!isEdit">Add</span>
            <span *ngIf="isEdit">Edit</span>
            Education
        </h4>
    </div>

    <div class="mat-modal-body">
        <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>

        <form class="form-material full-width-inputs" action="javascript:" [formGroup]="educationForm">
            <div class="row">
                <div class=" col-sm-6">
                    <mat-form-field class="form-element">
                        <mat-select disableOptionCentering class="form-control" formControlName="level" required>
                            <ng-container
                                    *ngFor="let n of educationList">
                                <mat-option
                                        value="{{n.id}}">{{n.name}}</mat-option>
                            </ng-container>
                        </mat-select>
                        <mat-label for="des">Level</mat-label>
                    </mat-form-field>
                </div>
                <div class=" col-sm-6">
                    <mat-form-field class="form-element">
                        <input class="form-control" formControlName="institute" maxlength="40" matInput required=""
                               type="text">
                        <span class="form-bar"></span>
                        <mat-label for="title">Institute</mat-label>
                    </mat-form-field>
                </div>
                <div class=" col-sm-6">
                    <mat-form-field class="form-element">
                        <input class="form-control" formControlName="major" matInput required="" maxlength="40"
                               type="text">
                        <span class="form-bar"></span>
                        <mat-label for="title">Major Specialization</mat-label>
                    </mat-form-field>
                </div>
                <div class=" col-sm-6">
                    <mat-form-field class="form-element">
                        <input class="form-control" formControlName="year" matInput required="" maxlength="40"
                               type="number">
                        <span class="form-bar"></span>
                        <mat-label for="title">Year</mat-label>
                    </mat-form-field>
                </div>
                <div class=" col-sm-6">
                    <mat-form-field class="form-element">
                        <input class="form-control" formControlName="score" maxlength="40" matInput 
                               type="number">
                        <span class="form-bar"></span>
                        <mat-label for="title">GPA/Score</mat-label>
                    </mat-form-field>
                </div>
                <div class=" col-sm-6" [hidden]="!requirededu.startDateAvailable">
                    <mat-form-field class="form-element">
                        <input required="{{requirededu.startDateRequired}}" [matDatepicker]="picker"
                               class="form-control"
                               formControlName="from" matInput>
                        <mat-datepicker-toggle [for]="picker" matSuffix>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-label for="expiry">Start Date
                        </mat-label>
                    </mat-form-field>
                </div>
                <div class="col-sm-6" [hidden]="!requirededu.endDateAvailable">
                    <mat-form-field class="form-element">
                        <input required="{{requirededu.endDateRequired}}" [matDatepicker]="picker1"
                               class="form-control"
                               formControlName="to" matInput [min]="this.educationForm.value.from">
                        <mat-datepicker-toggle [for]="picker1" matSuffix>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                        <mat-label for="expiry">End Date
                        </mat-label>
                    </mat-form-field>
                </div>


                <div *ngIf="!this.isUserAdded" class="action-buttons multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close()">
                        <i class="flaticon-cancel"></i> Cancel
                    </button>
                    <button mat-raised-button class="btn btn-primary" color="primary"
                            (click)="submitAddJobCategory('edu')" [disabled]="!educationForm.valid"><i
                            class="flaticon-diskette"></i> Save
                    </button>
                </div>

            </div>
        </form>

    </div>
</ng-template>
<ng-template #addmembership let-modal>
    <div class="mat-modal-header">
        <h4 class="mat-title-modal">
            <span *ngIf="!isEdit">Add</span>
            <span *ngIf="isEdit">Edit</span>
            Membership
        </h4>
    </div>

    <div class="mat-modal-body">
        <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>

        <form class="form-material full-width-inputs" action="javascript:" [formGroup]="membershipForm">
            <div class="row">
                <div class=" col-sm-6" [hidden]="!membershipsFromGroup.memberShipAvailable">
                    <mat-form-field class="form-element">
                        <mat-select required="{{membershipsFromGroup.memberShipRequired}}" disableOptionCentering class="form-control" formControlName="memberShip">
                            <ng-container
                                    *ngFor="let n of membershipList">
                                <mat-option
                                        value="{{n.id}}">{{n.name}}</mat-option>
                            </ng-container>
                        </mat-select>
                        <mat-label>Membership</mat-label>
                    </mat-form-field>
                </div>
                <div class=" col-sm-6" [hidden]="!membershipsFromGroup.subscriptionPaidByAvailable">
                    <mat-form-field class="form-element">
                        <mat-select required="{{membershipsFromGroup.subscriptionPaidByRequired}}" disableOptionCentering class="form-control" formControlName="spb">
                            <mat-option value="Company">Company</mat-option>
                            <mat-option value="Individual">Individual</mat-option>
                        </mat-select>
                        <mat-label for="des">Subscription paid by</mat-label>
                    </mat-form-field>
                </div>
                <div class=" col-sm-6" [hidden]="!membershipsFromGroup.subscriptionAmountAvailable">
                    <mat-form-field class="form-element">
                        <input required="{{membershipsFromGroup.subscriptionAmountRequired}}" class="form-control" formControlName="amount" maxlength="40" matInput type="number">
                        <span class="form-bar"></span>
                        <mat-label for="title">Subscription amount</mat-label>
                    </mat-form-field>
                </div>
                <div class=" col-sm-6" [hidden]="!membershipsFromGroup.currencyAvailable">
                    <mat-form-field class="form-element">
                        <mat-select required="{{membershipsFromGroup.currencyRequired}}" disableOptionCentering class="form-control" formControlName="currency">
                            <ng-container *ngFor="let n of payGradeCurrencyList">
                                <mat-option value="{{n.id}}">{{n.name}}</mat-option>
                            </ng-container>
                        </mat-select>
                        <mat-label for="des">Currency</mat-label>
                    </mat-form-field>
                </div>
                <div class=" col-sm-6" [hidden]="!membershipsFromGroup.subscriptionCommenceDateAvailable">
                    <mat-form-field class="form-element">
                        <input required="{{membershipsFromGroup.subscriptionCommenceDateRequired}}" [matDatepicker]="picker"
                               class="form-control"
                               formControlName="scd" matInput>
                        <mat-datepicker-toggle [for]="picker" matSuffix>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-label for="expiry">Subscription Commence Date
                        </mat-label>
                    </mat-form-field>
                </div>
                <div class=" col-sm-6" [hidden]="!membershipsFromGroup.subscriptionRenewalDateAvailable">
                    <mat-form-field class="form-element">
                        <input required="{{membershipsFromGroup.subscriptionRenewalDateRequired}}" [matDatepicker]="picker1"
                               class="form-control"
                               formControlName="srd" matInput>
                        <mat-datepicker-toggle [for]="picker1" matSuffix>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                        <mat-label for="expiry">Subscription Renewal Date
                        </mat-label>
                    </mat-form-field>
                </div>

                <div *ngIf="!this.isUserAdded" class="action-buttons multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close()">
                        <i class="flaticon-cancel"></i> Cancel
                    </button>
                    <button mat-raised-button class="btn btn-primary" color="primary"
                            (click)="submitAddJobCategory('membership')" [disabled]="!membershipForm.valid"><i
                            class="flaticon-diskette"></i> Save
                    </button>
                </div>

            </div>
        </form>
    </div>
</ng-template>
<ng-template #addSkills let-modal>
    <div class="mat-modal-header">
        <h4 class="mat-title-modal">
            <span *ngIf="!isEdit">Add</span>
            <span *ngIf="isEdit">Edit</span>
            Skills
        </h4>
    </div>

    <div class="mat-modal-body">
        <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>
        <form class="form-material full-width-inputs" action="javascript:" [formGroup]="skillsForm">
            <div class="form-row">
                <div class=" col-sm-6" [hidden]="!skillsFromGroup.skillsAvailable">
                    <mat-form-field class="form-element">
                        <mat-select required="{{skillsFromGroup.skillsRequired}}" disableOptionCentering class="form-control" formControlName="skill">
                            <ng-container
                                    *ngFor="let n of skillsList">
                                <mat-option
                                        value="{{n.id}}">{{n.name}}</mat-option>
                            </ng-container>
                        </mat-select>
                        <mat-label for="des">Skills</mat-label>
                    </mat-form-field>
                </div>
                <div class=" col-sm-6" [hidden]="!skillsFromGroup.yearOfExperienceAvailable">
                    <mat-form-field class="form-element">
                        <input required="{{skillsFromGroup.yearOfExperienceRequired}}" class="form-control" formControlName="year" maxlength="40" matInput type="number">
                        <span class="form-bar"></span>
                        <mat-label for="title">Year of Experience</mat-label>
                    </mat-form-field>
                </div>
                <div class="col-sm-6" [hidden]="!skillsFromGroup.commentsAvailable">
                    <mat-form-field class="form-element">
                        <input  required="{{skillsFromGroup.commentsRequired}}" class="form-control" formControlName="comment" maxlength="50" maxlength="40" matInput
                               type="text">
                        <span class="form-bar"></span>
                        <mat-label for="title">Comment</mat-label>
                    </mat-form-field>
                </div>

            </div>

            <div class="action-buttons multi-btns col-sm-12 text-right">
                <button mat-raised-button class="btn-danger" (click)="close()">
                    <i class="flaticon-cancel"></i> Cancel
                </button>
                <button mat-raised-button class="btn btn-primary" color="primary"
                        (click)="submitAddJobCategory('skills')" [disabled]="!skillsForm.valid"><i
                        class="flaticon-diskette"></i> Save
                </button>
            </div>

        </form>
    </div>
</ng-template>


<ng-template #deleteUser let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">Delete Job Department</h4>
        </div>
        <div class="mat-modal-body">
            <div class="text-left are-u">Are you sure ?</div>
            <div class="row">
                <div class="action-buttons multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close()">
                        <i class="flaticon-cancel"></i> Cancel
                    </button>
                    <button class="btn-primary" color="primary" mat-raised-button
                            (click)="deleteJobCategory()"><i class="flaticon-trash"></i> Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #attach let-modal>
    <div class="mat-modal-header">
        <h4 class="mat-title-modal">
            Attachment
        </h4>
    </div>

    <div class="mat-modal-body">
        <form class="form-material full-width-inputs">
            <app-loading *ngIf="loadAttach" [isLoading]="loadAttach"></app-loading>

            <div class="form-row" *ngIf="!loadAttach" >
                <div class="col-sm-12" [hidden]="!update">
                    <div class="upload-btn-wrapper">
                        <button class="upload-btn">Attach</button>
                        <input (change)="fileChange($event, 'dd')" class="form-control" name="myfile" type="file">
                        <div class="fileName">{{this.fileObj && this.fileObj.fileName}}<i *ngIf="fileUrl"
                                                                                          class="fa fa-check success"></i>
                        </div>
                    </div>
                    <div class="upload-btn-wrapper">
                    </div>
                    <div *ngIf="!fileUrl && fileError" class="file-error text-danger">{{fileError}}</div>
                    <div class="help-text">Accept upto 2MB</div>
                </div>
                <div class="col-sm-12" [hidden]="!update">
                    <mat-form-field class="form-element">
                        <input [(ngModel)]="comment" class="form-control" maxlength="50" matInput name="name"
                               type="text">
                        <span class="form-bar"></span>
                        <mat-label for="title">Comment</mat-label>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 mt-1 border" style="height: 200px !important; overflow-y: auto">
                    <mat-card *ngIf="this.attachmentList.length === 0">No Attachments
                        Found
                    </mat-card>
                    <ng-container *ngFor="let attachment of attachmentList; let i = index;">
                        <mat-card class="mt-2 shadow-sm">
                            <mat-card-title>
                                {{attachment.fileName}}
                            </mat-card-title>
                            <mat-card-content>
                                <b>Added On:</b> {{attachment.added}} <br>
                                <b>Comments:</b> {{attachment.desc}}
                            </mat-card-content>
                            <mat-card-actions align="end">
                                <button [hidden]="!delete" (click)="onDeleteClicked(attachment)"
                                        aria-label="Example icon button with a vertical three dot icon"
                                        mat-icon-button>
                                    <mat-icon color="warn">delete</mat-icon>
                                </button>
                                <button (click)="onDownloadClicked(attachment)"
                                        aria-label="Example icon button with a vertical three dot icon"
                                        mat-icon-button>
                                    <mat-icon color="primary">cloud_download</mat-icon>
                                </button>
                            </mat-card-actions>
                        </mat-card>
                    </ng-container>
                </div>
                <div class="action-buttons multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close()">
                        <i class="flaticon-cancel"></i> Cancel
                    </button>
                    <button [hidden]="!update" mat-raised-button class="btn btn-primary" color="primary" (click)="saveAttachment()"><i
                            class="flaticon-diskette"></i> Save
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #addTraining let-modal>
    <div class="mat-modal-header">
        <h4 class="mat-title-modal">
            <span *ngIf="!isEdit">Add</span>
            <span *ngIf="isEdit">Edit</span>
            Training
        </h4>
    </div>

    <div class="mat-modal-body">
        <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>

        <form class="form-material full-width-inputs" action="javascript:" [formGroup]="trainingForm">
            <div class="row">
                <div class=" col-sm-6">
                    <mat-form-field class="form-element">
                        <input class="form-control" formControlName="name" matInput required="" maxlength="40"
                               type="text">
                        <span class="form-bar"></span>
                        <mat-label for="title">Name</mat-label>
                    </mat-form-field>
                </div>
                <div class=" col-sm-6">
                    <mat-form-field class="form-element">
                        <input class="form-control" formControlName="provider" maxlength="40" matInput required=""
                               type="text">
                        <span class="form-bar"></span>
                        <mat-label for="title">Provider</mat-label>
                    </mat-form-field>
                </div>

                <div class=" col-sm-6">
                    <mat-form-field class="form-element">
                        <input class="form-control" formControlName="sector" maxlength="40" matInput required=""
                               type="text">
                        <span class="form-bar"></span>
                        <mat-label for="title">Sector</mat-label>
                    </mat-form-field>
                </div>

                <div class=" col-sm-6">
                    <mat-form-field class="form-element">
                        <input [matDatepicker]="picker"
                               class="form-control"
                               formControlName="dateFrom" matInput required="">
                        <mat-datepicker-toggle [for]="picker" matSuffix>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-label for="expiry">From
                        </mat-label>
                    </mat-form-field>
                </div>

                <div class=" col-sm-6">
                    <mat-form-field class="form-element">
                        <input [matDatepicker]="picker2"
                               class="form-control"
                               formControlName="dateTo" matInput required="" [min]="this.trainingForm.value.dateFrom">
                        <mat-datepicker-toggle [for]="picker2" matSuffix>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                        <mat-label for="expiry">To
                        </mat-label>
                    </mat-form-field>
                </div>


                <div *ngIf="!this.isUserAdded" class="action-buttons multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close()">
                        <i class="flaticon-cancel"></i> Cancel
                    </button>
                    <button mat-raised-button class="btn btn-primary" color="primary"
                            (click)="submitAddJobCategory('training')" [disabled]="!trainingForm.valid"><i
                            class="flaticon-diskette"></i> Save
                    </button>
                </div>

            </div>
        </form>

    </div>
</ng-template>


<ng-template #addPublications let-modal>
    <div class="mat-modal-header">
        <h4 class="mat-title-modal">
            <span *ngIf="!isEdit">Add</span>
            <span *ngIf="isEdit">Edit</span>
            Publication
        </h4>
    </div>

    <div class="mat-modal-body">
        <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>

        <form class="form-material full-width-inputs" action="javascript:" [formGroup]="publicationsForm">
            <div class="row">
                <div class=" col-sm-6">
                    <mat-form-field class="form-element">
                        <input class="form-control" formControlName="name" maxlength="40" matInput required=""
                               type="text">
                        <span class="form-bar"></span>
                        <mat-label for="title">Name</mat-label>
                    </mat-form-field>
                </div>
                <div class=" col-sm-6">
                    <mat-form-field class="form-element">
                        <input class="form-control" formControlName="journal" matInput required="" maxlength="40"
                               type="text">
                        <span class="form-bar"></span>
                        <mat-label for="title">Journal</mat-label>
                    </mat-form-field>
                </div>

                <div class=" col-sm-6">
                    <mat-form-field class="form-element">
                        <input class="form-control" formControlName="sector" matInput maxlength="40" required=""
                               type="text">
                        <span class="form-bar"></span>
                        <mat-label for="title">Sector</mat-label>
                    </mat-form-field>
                </div>

                <div class=" col-sm-6">
                    <mat-form-field class="form-element">
                        <input [matDatepicker]="picker"
                               class="form-control"
                               formControlName="date" matInput required="">
                        <mat-datepicker-toggle [for]="picker" matSuffix>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-label for="expiry">Date
                        </mat-label>
                    </mat-form-field>
                </div>

                <div *ngIf="!this.isUserAdded" class="action-buttons multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close()">
                        <i class="flaticon-cancel"></i> Cancel
                    </button>
                    <button mat-raised-button class="btn btn-primary" color="primary"
                            (click)="submitAddJobCategory('publication')" [disabled]="!publicationsForm.valid"><i
                            class="flaticon-diskette"></i> Save
                    </button>
                </div>

            </div>
        </form>

    </div>
</ng-template>


<ng-template #addaward let-modal>
    <div class="mat-modal-header">
        <h4 class="mat-title-modal">
            <span *ngIf="!isEdit">Add</span>
            <span *ngIf="isEdit">Edit</span>
            Award
        </h4>
    </div>

    <div class="mat-modal-body">
        <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>

        <form class="form-material full-width-inputs" action="javascript:" [formGroup]="awardForm">
            <div class="row">
                <div class=" col-sm-6">
                    <mat-form-field class="form-element">
                        <input class="form-control" formControlName="name" maxlength="40" matInput required=""
                               type="text">
                        <span class="form-bar"></span>
                        <mat-label for="title">Name</mat-label>
                    </mat-form-field>
                </div>
                <div class=" col-sm-6">
                    <mat-form-field class="form-element">
                        <input class="form-control" formControlName="donor" matInput maxlength="40" required=""
                               type="text">
                        <span class="form-bar"></span>
                        <mat-label for="title">Donor</mat-label>
                    </mat-form-field>
                </div>

                <div class=" col-sm-6">
                    <mat-form-field class="form-element">
                        <input class="form-control" formControlName="sector" maxlength="40" matInput required=""
                               type="text">
                        <span class="form-bar"></span>
                        <mat-label for="title">Sector</mat-label>
                    </mat-form-field>
                </div>

                <div class=" col-sm-6">
                    <mat-form-field class="form-element">
                        <input [matDatepicker]="picker"
                               class="form-control"
                               formControlName="date" matInput required="">
                        <mat-datepicker-toggle [for]="picker" matSuffix>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-label for="expiry">Date
                        </mat-label>
                    </mat-form-field>
                </div>

                <div *ngIf="!this.isUserAdded" class="action-buttons multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close()">
                        <i class="flaticon-cancel"></i> Cancel
                    </button>
                    <button mat-raised-button class="btn btn-primary" color="primary"
                            (click)="submitAddJobCategory('award')" [disabled]="!awardForm.valid"><i
                            class="flaticon-diskette"></i> Save
                    </button>
                </div>

            </div>
        </form>

    </div>
</ng-template>
