<div id="header-page" class="ng-star-inserted header-page">
    <div class="page-header ng-star-inserted">
        <div class="page-block ng-star-inserted">
        <div class="row align-items-center">
            <div class="col-md-8">
                <div class="page-header-title">
                    <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4
                        class="m-b-10 ng-star-inserted">Bulk Management</h4></span>
                </div>
            </div>
            <div class="col-md-4"></div>
        </div>
        </div>
    </div>
</div>

<div class="card">
    <div class="card-content table-responsive table-full-width">
        <mat-horizontal-stepper #stepper [linear]="false" labelPosition="bottom" (selectionChange)="submitBulkUpdate()">
            <mat-step [stepControl]="userForm" errorMessage="Please Select the feild to update" label="Selection">
                <form [formGroup]="userForm" action="javascript:" class="form-material full-width-inputs">
                    <ng-container>
                        <div class="row form-group">
                            <div class="col-md-4">
                            

                                <mat-form-field class="form-element example-chip-list">
                                    <mat-label>Employee
                                    </mat-label>
                                    <mat-chip-list  #chipList>
                                      <mat-chip
                                        *ngFor="let fruit of fruits"
                                        [selectable]="selectable"
                                        [removable]="removable"
                                        (removed)="remove(fruit.id)">
                                        {{fruit.name}}
                                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                      </mat-chip>
                                      <input 
                                        placeholder="type for hints..."
                                        #fruitInput
                                        [formControl]="fruitCtrl"
                                        [matAutocomplete]="auto"
                                        [matChipInputFor]="chipList"
                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                        [matChipInputAddOnBlur]="addOnBlur">
                                    </mat-chip-list>
                                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                                      <mat-option *ngFor="let fruit of filteredFruits | async" [value]="fruit">
                                        {{fruit.name}}
                                      </mat-option>
                                    </mat-autocomplete>
                                  </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <mat-form-field class="form-element">
                                    <mat-label> Supervisor Name </mat-label>
                                    <input placeholder="Type for hints .." class="form-control" type="text"
                                    aria-label="Supervisor Name"
                                    matInput
                                    formControlName="supervisorsIds"
                                    [matAutocomplete]="auto2">
                                 <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayEmployeeByName2.bind(this)">
                                   <mat-option *ngFor="let r of filteredOptions2 " [value]="r.value">
                                 {{r.label}}
                                  </mat-option>
                                 </mat-autocomplete>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <mat-form-field>
                                    <mat-label>include</mat-label>
                                    <mat-select disableOptionCentering class="form-control" formControlName="include" >
                                        <mat-option *ngFor="let include of this.includeList" [value]="include.value">
                                            {{include.viewValue}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <mat-form-field>
                                    <mat-label>Job catagory</mat-label>
                                    <mat-select disableOptionCentering class="form-control" formControlName="jobCategoryId" >
                                        <mat-option  [value]="'all'">All</mat-option>
                                        <mat-option *ngFor="let jobCatagory of this.jobCatagoryList"
                                                    [value]="jobCatagory.id">
                                            {{jobCatagory.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <mat-form-field>
                                    <mat-label>Employee Status</mat-label>
                                    <mat-select disableOptionCentering class="form-control" formControlName="employeeStatusId" >
                                        <mat-option  [value]="'all'">All</mat-option>
                                        <mat-option *ngFor="let employeeStatus of this.employeeStatusList"
                                                    [value]="employeeStatus.id">
                                            {{employeeStatus.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <mat-form-field>
                                    <mat-label>Job Title</mat-label>
                                    <mat-select disableOptionCentering class="form-control" formControlName="jobTitleId" >
                                        <mat-option  [value]="'all'">All</mat-option>
                                        <mat-option *ngFor="let jobTitle of this.jobTitleList" [value]="jobTitle.id">
                                            {{jobTitle.title}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <mat-form-field>
                                    <mat-label>Sub unit</mat-label>
                                    <mat-select disableOptionCentering class="form-control" formControlName="subUnitId" >
                                        <mat-option  [value]="'all'">All</mat-option>
                                        <mat-option *ngFor="let structure of this.structureList"
                                                    [value]="structure.value">
                                            {{structure.label}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <mat-form-field>
                                    <mat-label>Location</mat-label>
                                    <mat-select disableOptionCentering class="form-control" formControlName="locationId" >
                                        <mat-option  [value]="'all'">All</mat-option>
                                        <mat-option *ngFor="let loc of this.locationList" [value]="loc.id">
                                            {{loc.location}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <mat-form-field>
                                    <mat-label>Work Shift</mat-label>
                                    <mat-select disableOptionCentering class="form-control" formControlName="workShiftId" >
                                        <mat-option  [value]="'all'">All</mat-option>
                                        <mat-option *ngFor="let workShift of this.workShiftList" [value]="workShift.id">
                                            {{workShift.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-4">
                                <mat-form-field>
                                    <mat-label>Effective from</mat-label>
                                    <mat-select disableOptionCentering #joiningDate class="form-control" formControlName="createdDate"
                                                >
                                                <mat-option  [value]="'all'">All</mat-option>
                                        <mat-option *ngFor="let joinDate of this.joinedDateList"
                                                    [value]="joinDate.value">
                                            {{joinDate.label}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div *ngIf="(joiningDate.value == 'After')" class="col-md-4">
                                <mat-form-field class="form-element">
                                    <mat-label>Date</mat-label>
                                    <input [matDatepicker]="picker" class="form-control"
                                           formControlName="createdDateBa" id="dob" matInput>
                                    <mat-datepicker-toggle [for]="picker"
                                                           matSuffix></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div *ngIf="(joiningDate.value == 'Before')" class="col-md-4">
                                <mat-form-field>
                                    <mat-label>Date</mat-label>
                                    <input [matDatepicker]="picker1" class="form-control"
                                           formControlName="createdDateBa" matInput>
                                    <mat-datepicker-toggle [for]="picker1" matSuffix></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div *ngIf="(joiningDate.value == 'Between')" class="col-md-4">
                                <mat-form-field>
                                    <mat-label>From</mat-label>
                                    <input [matDatepicker]="picker2" class="form-control"
                                           formControlName="createdDateFrom" matInput>
                                    <mat-datepicker-toggle [for]="picker2" matSuffix></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div *ngIf="(joiningDate.value == 'Between')" class="col-md-4">
                                <mat-form-field>
                                    <mat-label>To</mat-label>
                                    <input [matDatepicker]="picker3" class="form-control"
                                           formControlName="createdDateTo" matInput>
                                    <mat-datepicker-toggle [for]="picker3" matSuffix></mat-datepicker-toggle>
                                    <mat-datepicker #picker3></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-4">
                                <mat-form-field>
                                    <mat-label>Probation end date</mat-label>
                                    <mat-select disableOptionCentering #probationDate class="form-control" formControlName="probationDate"
                                                >
                                                <mat-option  [value]="'all'">All</mat-option>
                                        <mat-option *ngFor="let joinDate of this.joinedDateList"
                                                    [value]="joinDate.value">
                                            {{joinDate.label}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div *ngIf="(probationDate.value == 'After')" class="col-md-4">
                                <mat-form-field>
                                    <mat-label>Date</mat-label>
                                    <input [matDatepicker]="picker4" class="form-control"
                                           formControlName="probationDateBa" matInput>
                                    <mat-datepicker-toggle [for]="picker4" matSuffix></mat-datepicker-toggle>
                                    <mat-datepicker #picker4></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div *ngIf="(probationDate.value == 'Before')" class="col-md-4">
                                <mat-form-field>
                                    <mat-label>Date</mat-label>
                                    <input [matDatepicker]="picker5" class="form-control"
                                           formControlName="probationDateBa" matInput>
                                    <mat-datepicker-toggle [for]="picker5" matSuffix></mat-datepicker-toggle>
                                    <mat-datepicker #picker5></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div *ngIf="(probationDate.value == 'Between')" class="col-md-4">
                                <mat-form-field>
                                    <mat-label>From</mat-label>
                                    <input [matDatepicker]="picker6" class="form-control"
                                           formControlName="probationDateFrom" matInput>
                                    <mat-datepicker-toggle [for]="picker6" matSuffix></mat-datepicker-toggle>
                                    <mat-datepicker #picker6></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div *ngIf="(probationDate.value == 'Between')" class="col-md-4">
                                <mat-form-field>
                                    <mat-label>To</mat-label>
                                    <input [matDatepicker]="picker7" class="form-control"
                                           formControlName="probationDateTo" matInput>
                                    <mat-datepicker-toggle [for]="picker7" matSuffix></mat-datepicker-toggle>
                                    <mat-datepicker #picker7></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                       <br><br>
                        <!-- <button (click)="submitBulkUpdate(stepper)" class="btn btn-save btn-primary" mat-raised-button
                                type="submit">Next
                        </button> -->
                        <div class="row my-2 mx-2"> 
                            <div class="action-buttons multi-btns col-sm-12 text-right">
                                <button mat-raised-button class="btn-primary" color="primary" matStepperNext (click)="submitBulkUpdate()" ><i class="flaticon-right-arrow"></i> Next </button>
                            </div>
                          </div>
                    </ng-container>
                </form>
            </mat-step>
            <mat-step [stepControl]="userForm" errorMessage="Please Select the feild to update" label="Updatation">
                <mat-expansion-panel  #expansionPanel [expanded]="isExpanded">
                    <mat-expansion-panel-header class="right-aligned-header" >
                      <mat-panel-title>
                        <span class="accordion-head panel-title"> Select Fields to Update  </span> 
                      </mat-panel-title>
                    </mat-expansion-panel-header>  
                    <form [formGroup]="updateForm" action="javascript:" class="form-material full-width-inputs">
                        <ng-container>   
                    <div class="row">
                        <div class="col-md-3 ">
                            <mat-checkbox #matCheckbox1 color="primary" formControlName="subUnitCheckBox" (change)="onCheckBoxChangeSub($event)" #matCheckBoxesSub>Sub Unit</mat-checkbox>
                        </div>
                       
                        <div class="col-md-3">
                            <mat-checkbox color="primary" formControlName="jobCategoryCheckBox" (change)="onCheckBoxChangeJobCat($event)">Job Category
                            </mat-checkbox>
                        </div>
                        <div class="col-md-3 ">
                            <mat-checkbox color="primary" formControlName="employeeStatusCheckBox" (change)="onCheckBoxChangeStatus($event)">Employment
                                Status
                            </mat-checkbox>
                        </div>
                        <div class="col-md-3 ">
                            <mat-checkbox color="primary" formControlName="jobTitleCheckBox" (change)="onCheckBoxChangeJobTitle($event)">Job Title</mat-checkbox>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <mat-checkbox color="primary" formControlName="probationEndDateCheckBox" (change)="onCheckBoxChangeProbation($event)">Probation end date
                            </mat-checkbox>
                        </div>
                        <div class="col-md-3">
                            <mat-checkbox color="primary" formControlName="locationCheckBox" (change)="onCheckBoxChangeLocation($event)">Location</mat-checkbox>
                        </div>
                        <div class="col-md-3">
                            <mat-checkbox color="primary" formControlName="workShiftCheckBox" (change)="onCheckBoxChangeWorkShif($event)">Work Shift
                            </mat-checkbox>
                        </div>
                        <div class="col-md-3">
                            <mat-checkbox color="primary" formControlName="effictiveFromCheckBox" (change)="onCheckBoxChangeEffectiveFrom($event)">Effective from
                            </mat-checkbox>
                        </div>
                    </div>
                </ng-container> 
            </form>
                </mat-expansion-panel>

<br><br>
                <mat-expansion-panel  #expansionPanel [expanded]="isExpanded">
                    <mat-expansion-panel-header class="right-aligned-header" >
                      <mat-panel-title>
                        <span class="accordion-head panel-title"> Bulk Update ({{this.size}} matches)  </span> 
                      </mat-panel-title>
                    </mat-expansion-panel-header>   
                    
                    <form [formGroup]="updateForm"  action="javascript:" class="form-material full-width-inputs">
                          <ng-container>
                              <div class="row form-group">
                                  <div class="col-md-12" *ngIf="this.updateForm.value.jobCategoryCheckBox">
                                      <mat-form-field>
                                          <mat-label>Job catagory</mat-label>
                                          <mat-select disableOptionCentering class="form-control" formControlName="jobCategoryId" [(ngModel)]="jobCategorySelected">
                                              <mat-option  [value]="''">--Select--</mat-option>
                                              <mat-option *ngFor="let jobCatagory of this.jobCatagoryList"
                                                          [value]="jobCatagory.id">
                                                  {{jobCatagory.name}}
                                              </mat-option>
                                          </mat-select>
                                      </mat-form-field>
                                  </div>
                                  <div class="col-md-12" *ngIf="this.updateForm.value.employeeStatusCheckBox">
                                      <mat-form-field>
                                          <mat-label>Employee Status</mat-label>
                                          <mat-select disableOptionCentering class="form-control" formControlName="employeeStatusId" [(ngModel)]="employeeStatusSlected">
                                            <mat-option  [value]="''">--Select--</mat-option>
                                              <mat-option *ngFor="let employeeStatus of this.employeeStatusList"
                                                          [value]="employeeStatus.id">
                                                  {{employeeStatus.name}}
                                              </mat-option>
                                          </mat-select>
                                      </mat-form-field>
                                  </div>
                                  <div class="col-md-12" *ngIf="this.updateForm.value.jobTitleCheckBox">
                                      <mat-form-field>
                                          <mat-label>Job Title</mat-label>
                                          <mat-select disableOptionCentering class="form-control" formControlName="jobTitleId" [(ngModel)]="jobTitleSelected">
                                            <mat-option  [value]="''">--Select--</mat-option>
                                              <mat-option *ngFor="let jobTitle of this.jobTitleList" [value]="jobTitle.id">
                                                  {{jobTitle.title}}
                                              </mat-option>
                                          </mat-select>
                                      </mat-form-field>
                                  </div>
                                  <div class="col-md-12" *ngIf="this.updateForm.value.subUnitCheckBox">
                                      <mat-form-field>
                                          <mat-label>Sub unit</mat-label>
                                          <mat-select disableOptionCentering class="form-control" formControlName="subUnitId" [(ngModel)]="subSelected">
                                            <mat-option  [value]="''">--Select--</mat-option>
                                              <mat-option *ngFor="let structure of this.structureList"
                                                          [value]="structure.value">
                                                  {{structure.label}}
                                              </mat-option>
                                          </mat-select>
                                      </mat-form-field>
                                  </div>
                                  <div class="col-md-12" *ngIf="this.updateForm.value.locationCheckBox">
                                      <mat-form-field>
                                          <mat-label>Location</mat-label>
                                          <mat-select disableOptionCentering class="form-control" formControlName="locationId" [(ngModel)]="locationSelected">
                                            <mat-option  [value]="''">--Select--</mat-option>
                                              <mat-option *ngFor="let loc of this.locationList" [value]="loc.id">
                                                  {{loc.location}}
                                              </mat-option>
                                          </mat-select>
                                      </mat-form-field>
                                  </div>
                                  <div class="col-md-12" *ngIf="this.updateForm.value.workShiftCheckBox ">
                                      <mat-form-field>
                                          <mat-label>Work Shift</mat-label>
                                          <mat-select disableOptionCentering class="form-control" formControlName="workShiftId" [(ngModel)]="workShiftSelected">
                                            <mat-option  [value]="''">--Select--</mat-option>
                                              <mat-option *ngFor="let workShift of this.workShiftList" [value]="workShift.id">
                                                  {{workShift.name}}
                                              </mat-option>
                                          </mat-select>
                                      </mat-form-field>
                                  </div>
                              </div>
                                  <div class="col-md-13" *ngIf="this.updateForm.value.effictiveFromCheckBox">
                                      <mat-form-field class="form-element">
                                          <mat-label>Effective from</mat-label>
                                          <input [matDatepicker]="picker2" class="form-control"
                                                 formControlName="createdAfterDate" id="dob" matInput formControlName="effictiveFrom" [(ngModel)]="effictiveFromSelected">
                                          <mat-datepicker-toggle [for]="picker2"
                                                                 matSuffix></mat-datepicker-toggle>
                                          <mat-datepicker #picker2></mat-datepicker>
                                      </mat-form-field>                    
                                  </div>
                                  <div  class="col-md-13" *ngIf="this.updateForm.value.probationEndDateCheckBox">
                                      <mat-form-field>
                                          <mat-label>Probation end date</mat-label>
                                          <input [matDatepicker]="picker3" class="form-control"
                                                 formControlName="createdBeforeDate" matInput formControlName="probationEndDate" [(ngModel)]="probationEndDateSelected">
                                          <mat-datepicker-toggle [for]="picker3" matSuffix></mat-datepicker-toggle>
                                          <mat-datepicker #picker3></mat-datepicker>
                                      </mat-form-field>
                                  </div>
                               
                             <br><br>
                             <div class="row my-2 mx-2"> 
                                <div class="action-buttons multi-btns col-sm-12 text-right">
                                    <button  mat-raised-button class="btn-primary" color="primary"  (click)="enableIndUpdate()">Individual update</button>
                                  <button mat-raised-button class="btn-primary" color="primary"  [hidden]="!this.update" (click)="executeBulkUpdate()">Bulk update</button>
                                </div>
                              </div>
                          </ng-container>
      
                      </form>
                </mat-expansion-panel>
                
               

                  <br><br>
                  <app-card [title]="'Individual update'" [hidden]="!enableIndividual">

                    <div class="row my-2 mx-2"> 
                        <div class="action-buttons multi-btns col-sm-12 text-right">
                            <button  mat-raised-button class="btn-primary" color="primary" (click)="disableIndUpdate()" >Cancel</button>
                            <button mat-raised-button class="btn-danger" color="primary"  [hidden]="!this.update" (click)="executeIndivdulaUpdate()">Update</button>
                        </div>
                      </div>
                   <br>
                    <div class="dt-responsive" *ngIf="dataSource">
                      <div class="table-responsive table hrm-table-style-1 full-data-table table-cols-4">
                        <mat-card-content>
                          <mat-table [dataSource]="dataSource" matSort>
                            <ng-container matColumnDef="select">
                              <mat-header-cell *matHeaderCellDef>
                                <a [matMenuTriggerFor]="menu" class="check-all">
                                            <span class="material-icons">
                                                more_horiz
                                            </span>
                                </a>
            
                                <mat-menu #menu="matMenu">
                                  <button mat-menu-item (click)="onSelectAllClicked()">Select All</button>
                                  <button (click)="onDeSelectAllClicked()" *ngIf="selectedCheckBoxList.length > 0" mat-menu-item>
                                    Deselect All
                                  </button>
                                </mat-menu>
                              </mat-header-cell>
                              <mat-cell *matCellDef="let row">
                                <mat-checkbox #matCheckbox (change)="onCheckBoxChange($event)"
                                              value="{{row.id}}"></mat-checkbox>
                              </mat-cell>
                            </ng-container>
                            
                            <ng-container matColumnDef="id">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Employee ID</mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                  {{ row.id }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="firstLastName">
                              <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
                              <mat-cell *matCellDef="let row">
                                {{ row.firstLastName }}
                              </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="temporarydepartment" >
                                <mat-header-cell [hidden]="!this.updateForm.value.subUnitCheckBox" *matHeaderCellDef mat-sort-header>Sub Unit</mat-header-cell>
                                <mat-cell [hidden]="!this.updateForm.value.subUnitCheckBox" *matCellDef="let row">
                                  {{ row.temporarydepartment }}
                                </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="jobcategoryid"  >
                                <mat-header-cell [hidden]="!this.updateForm.value.jobCategoryCheckBox" *matHeaderCellDef mat-sort-header>Job Category</mat-header-cell>
                                <mat-cell [hidden]="!this.updateForm.value.jobCategoryCheckBox" *matCellDef="let row">
                                  {{ row.jobcategoryid }}
                                </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="jobtitelid">
                                <mat-header-cell [hidden]="!this.updateForm.value.jobTitleCheckBox" *matHeaderCellDef mat-sort-header>Job Title</mat-header-cell>
                                <mat-cell [hidden]="!this.updateForm.value.jobTitleCheckBox" *matCellDef="let row">
                                  {{ row.jobtitelid }}
                                </mat-cell>
                              </ng-container>
                              <ng-container  matColumnDef="workshiftid">
                                <mat-header-cell [hidden]="!this.updateForm.value.workShiftCheckBox" *matHeaderCellDef mat-sort-header>Work Shift</mat-header-cell>
                                <mat-cell [hidden]="!this.updateForm.value.workShiftCheckBox" *matCellDef="let row">
                                  {{ row.workshiftid }}
                                </mat-cell>
                              </ng-container>
                              <ng-container  matColumnDef="employeestatus">
                                <mat-header-cell [hidden]="!this.updateForm.value.employeeStatusCheckBox" *matHeaderCellDef mat-sort-header>Employee Status</mat-header-cell>
                                <mat-cell [hidden]="!this.updateForm.value.employeeStatusCheckBox" *matCellDef="let row">
                                  {{ row.employeestatus }}
                                </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="locationid">
                                <mat-header-cell [hidden]="!this.updateForm.value.locationCheckBox" *matHeaderCellDef mat-sort-header>Location</mat-header-cell>
                                <mat-cell [hidden]="!this.updateForm.value.locationCheckBox" *matCellDef="let row">
                                  {{ row.locationid }}
                                </mat-cell>
                              </ng-container>


                              <ng-container  matColumnDef="effectivefrom">
                                <mat-header-cell [hidden]="!this.updateForm.value.effictiveFromCheckBox" *matHeaderCellDef mat-sort-header>Effective From</mat-header-cell>
                                <mat-cell [hidden]="!this.updateForm.value.effictiveFromCheckBox" *matCellDef="let row">
                                  {{ row.effectivefrom | date : 'dd-MM-yyyy' }}
                                </mat-cell>
                              </ng-container>

                              <ng-container  matColumnDef="endDate">
                                <mat-header-cell [hidden]="!this.updateForm.value.probationEndDateCheckBox" *matHeaderCellDef mat-sort-header>Probation End Date</mat-header-cell>
                                <mat-cell [hidden]="!this.updateForm.value.probationEndDateCheckBox" *matCellDef="let row">
                                  {{ row.endDate | date : 'dd-MM-yyyy' }}
                                </mat-cell>
                              </ng-container>

              
                            
                            <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: columns;">
                            </mat-row>
                          </mat-table>
                          <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
                            <mat-paginator [pageSizeOptions]="[10, 25,50,100]" showFirstLastButtons></mat-paginator>
                        </mat-card-content>
                      </div>
                      <!-- <div class="error-msg" *ngIf="this.employeeTable.length === 0" >No Data Found</div> -->
                    </div>
                  </app-card>
              <br><br>
              <div class="row my-2 mx-2"> 
                <div class="action-buttons multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" color="primary" matStepperPrevious><i class="flaticon-left-arrow"></i> Back</button>
                </div>
              </div>
            </mat-step>
        </mat-horizontal-stepper>
    </div>
</div>


<!--<div class="card my-0">-->
<!--    <div class="card-content">-->
<!--    </div>-->
<!--</div>-->
