import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import {MatDialog} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-leave-notification',
  templateUrl: './leave-notification.component.html',
  styleUrls: ['./leave-notification.component.scss']
})
export class LeaveNotificationComponent implements OnInit {
  displayedColumns = ['notification', 'checked', 'templates'];
  actionBasedData = [];
  dateBasedData = [];
  allReportingMethods = [];
  public form: FormGroup;
  public dialogRef: any;
  @ViewChild('editNotification',{static:false}) editNotification;

  constructor(private service: DashboardService, private dialog: MatDialog,
              private fb: FormBuilder, private toastr: NotificationService) { 
    this.form = this.fb.group({
      code: [''],
      reportingMethods: ['', [Validators.required]],
      template: [''],
      varaibles: [''],
      enabled: ['']
    })
  }

  ngOnInit(): void {
    this.service.getLeavesNotification().subscribe(
      response => {
          if (response && response.data) {
              this.actionBasedData = response.data
              //this.DateBasedData = response.data;
          }
      },
      error1 => {
          console.error(error1);
      }
    );

    this.service.getReportingMehtodList().subscribe(
      response => {
          if (response && response.data) {
              this.allReportingMethods = response.data
          }
      },
      error1 => {
          console.error(error1);
      }
    );
  }

  getChecked(element: any) {
    return element.enabled;
  } 

  onStatusChange(ob: MatCheckboxChange, id: String) {
    console.log("checked: " + ob.checked + " id: " + id);
    this.form.patchValue({code : id, enabled: ob.checked});
    this.service.updateLeaveNoticationStatus(this.form.value).subscribe(user => {
      this.toastr.success('Leave notification status updated successfully');
      this.form.reset();
    }, error => {
      const err  = error && error.error && error.error.msg;
      this.toastr.error(err || 'Error in updating leave notification status. Please try again.');
    });
 }

  edit(code){
    this.service.getLeavesNotificationDetails(code).subscribe(
      response => {
        if (response && response.data) {
          this.form.patchValue(response.data)
        }
      } ,
      error1 => {
          console.error(error1);
      }
    );

    this.dialogRef = this.dialog.open(this.editNotification, {
      width: '660px',
      autoFocus: false,
      maxHeight: '55vh' 
    });

  }

  public close(): void {
    this.dialogRef.close();
  }

  public submit(): void {
    this.service.updateLeaveNotication(this.form.value).subscribe(user => {
      this.toastr.success('Leave notification updated successfully');
      this.dialogRef.close();
      this.form.reset();
    }, error => {
      const err  = error && error.error && error.error.msg;
      this.toastr.error(err || 'Error in updating leave notification.Please try again.');
    });
  }
}
