<div id="header-page" class="ng-star-inserted header-page">
    <div class="page-header ng-star-inserted">
        <div class="page-block ng-star-inserted">
            <div class="row align-items-center">
                <div class="col-md-8">
                    <div class="page-header-title">
                        <ng-container *ngIf="this.reportName == ''">
                            <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4
                                class="m-b-10 ng-star-inserted">Reports</h4></span>
                        </ng-container>
                        <ng-container *ngIf="this.reportName != ''">
                            <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4
                                class="m-b-10 ng-star-inserted">{{this.reportName}}</h4></span>
                        </ng-container>
                    </div>
                </div>
                <div class="col-md-4"></div>
            </div>
        </div>
    </div>
</div>
<app-loading [isLoading]="this.loadingReport"></app-loading>




<div style="margin-top: -15px" class="row top-tools editTool" [hidden]="loadingReport">
    <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6 left-tools">
      <span (click)="saveAsCSV()" class="export-icon-btn csv"><i class="material-icons">get_app</i>
          <span>CSV</span>
      </span>

        <span class="export-icon-btn pdf" (click)="saveAsPdf()"><i class="material-icons">get_app</i>
            <span>PDF</span>
        </span>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
        <div class="relative-position search-filter-con">

            <span (click)="openGenerateSettings()" class="mytooltip cus-left tooltip-effect-4">
                <span class="tooltip-item">
                    <button mat-mini-fab class="btn btn-primary  light add-rounded margin-button-05">
                        <mat-icon>settings</mat-icon>
                    </button>
                </span>
                <span class="tooltip-content clearfix">
                    <span class="tooltip-text">Settings</span>
                </span>
            </span>

        <span [hidden]="!write" (click)="addNewUser()" [hidden]="chartGenerated" class="mytooltip cus-left tooltip-effect-4">
            <span class="tooltip-item">
                <button mat-mini-fab class="btn btn-primary  light add-rounded margin-button-05"
                >
                        <mat-icon>insert_chart_outlined</mat-icon>
                </button>
            </span>
            <span class="tooltip-content clearfix">
            <span class="tooltip-text">Add Chart</span>
            </span>
         </span>

            <span (click)="hideAndShowChart()" [hidden]="!chartGenerated || !chartHidden" class="mytooltip cus-left tooltip-effect-4">
          <span class="tooltip-item">
              <button mat-mini-fab class="btn btn-primary  light add-rounded margin-button-05"
              >
                      <mat-icon>insert_chart_outlined</mat-icon>
              </button>
          </span>
          <span class="tooltip-content clearfix">
          <span class="tooltip-text">Show Chart</span>
          </span>
       </span>

            <span (click)="hideAndShowChart()" [hidden]="!chartGenerated || chartHidden" class="mytooltip cus-left tooltip-effect-4">
        <span class="tooltip-item">
            <button mat-mini-fab class="btn btn-primary  light add-rounded margin-button-05"
            >
                    <mat-icon>insert_chart_outlined</mat-icon>
            </button>
        </span>
        <span class="tooltip-content clearfix">
        <span class="tooltip-text">Hide Chart</span>
        </span>
     </span>

            <span class="mytooltip cus-left tooltip-effect-4" [hidden]="!update">
          <span class="tooltip-item">
              <button mat-mini-fab class="btn btn-primary  light add-rounded margin-button-05"
                      (click)="onEditReportClicked()">
                  <i class="fa fa-pencil" ></i>
              </button>

          </span>
          <span class="tooltip-content clearfix">
          <span class="tooltip-text">Edit</span>
          </span>
       </span>



        </div>
    </div>
</div>


<app-card [hidden]="loadingReport">
    <div class="dt-responsive" *ngIf="dataSource">
        <div class="table-responsive" style="overflow: hidden">
            <mat-card-content>
                <div *ngIf="!chartHidden" [hidden]="!chartGenerated || loadingReport">
                    <div class="row my-2 mx-2" [hidden]="!update && !delete">
                        <div class="action-buttons multi-btns col-sm-12 text-right">
                            <a [matMenuTriggerFor]="menu" class="check-all">
                                <span class="material-icons">more_horiz</span>
                            </a>
                            <mat-menu #menu="matMenu">
                                <button [hidden]="!update" mat-menu-item (click)="getChartDataForEdit()">Edit</button>
                                <button [hidden]="!delete" mat-menu-item (click)="onDeleteClicked()">
                                    Delete
                                </button>
                            </mat-menu>
                        </div>
                    </div>

                    <br><br>

                    <div class="row" *ngIf="messageSuccess">

                            <mat-progress-bar style="z-index: 1000; margin-bottom: 3px" color="primary" mode="indeterminate"   ></mat-progress-bar>

                    </div>

                    <div class="row" *ngIf="!messageSuccess">
                        <div class="col-8">
                            <div   style="top: -13%; " class='box' [hidden]="this.chartType">
                                <div id="chart_div" class='content'>
                                    <google-chart #chart
                                                  style="width: 95%; height: 80%"
                                                  [type]="'ColumnChart'"
                                                  [data]="myData"
                                                  [options]="myOptions"
                                                  (select)="onSelect($event)"
                                                  (mouseleave)="onMouseLeave()"
                                                  (mouseenter)="onMouseEnter()"
                                    >
                                    </google-chart>
                                </div>
                            </div>

                            <div style="top: -15%;" class='box' [hidden]="!this.chartType">
                                <div id="chart_div_2" class='content'>
                                    <google-chart #chart2
                                                  style="width: 95%; height: 80%"
                                                  [type]="'PieChart'"
                                                  [data]="myData"
                                                  [options]="myOptions"
                                                  (select)="onSelect($event)"
                                                  (mouseleave)="onMouseLeave()"
                                                  (mouseenter)="onMouseEnter()"
                                    >
                                    </google-chart>
                                </div>
                            </div>

                        </div>
                        <div class="col-4">
                            <div [hidden]="!chartGenerated || !this.chartSettingForm.value.summaryTable">
                                <div *ngIf="chartListDataSource" class="dt-responsive">
                                    <div class="table-responsive">
                                        <div>
                                            <mat-table [dataSource]="chartListDataSource" matSort>

                                                <ng-container matColumnDef="name">
                                                    <mat-header-cell *matHeaderCellDef  >Name
                                                    </mat-header-cell>
                                                    <mat-cell *matCellDef="let row">
                                                        {{ row.name}}
                                                    </mat-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="count">
                                                    <mat-header-cell *matHeaderCellDef >{{this.typeOfAggregate}}
                                                    </mat-header-cell>
                                                    <mat-cell *matCellDef="let row">
                                                        {{ row.count }}
                                                    </mat-cell>
                                                </ng-container>

                                                <mat-header-row *matHeaderRowDef="summaryColumns"></mat-header-row>
                                                <mat-row
                                                        *matRowDef="let row; columns: summaryColumns;">
                                                </mat-row>
                                            </mat-table>
                                            <mat-paginator [pageSizeOptions]="[5]" showFirstLastButtons></mat-paginator>
                                            <!-- <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar> -->

                                        </div>

                                    </div>
                                    <!-- <div *ngIf="!isLoading && err" class="error-msg">{{ err }}</div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div [style]="messageSuccess ? 'margin-top: 1px' : (!chartGenerated || chartHidden) ? 'margin-top: 0px' :  'margin-top: -20%'" [hidden]="loadingReport || this.detailedTable" class="page-body no-title">
                    <div class="row">
                        <div class="col-sm-12">
                            <mat-card style="padding: 0px; margin: 0px">
                                <div class="horizental-scroll-area">
                                    <ng-container *ngFor="let data of dataSource2; let i = index">
                                        <table [dataSource]="data"  mat-table class={{this.divder}}>
                                            <ng-container *ngFor="let column of displayedColumns[i]; let j = index" [matColumnDef]="column">
                                                <th *matHeaderCellDef mat-header-cell> {{displayedColumns2[i][j]}} </th>
                                                <td *matCellDef="let element" mat-cell> {{element[column]}} </td>
                                            </ng-container>
                                            <ng-container *ngIf="showHeader">
                                                <ng-container  matColumnDef="header-row-first-group">
                                                    <th *matHeaderCellDef [attr.colspan]="displayedColumns[i].length" mat-header-cell
                                                        style="text-align: center">
                                                        {{headers[i]}}
                                                    </th>
                                                </ng-container>
                                                <tr *matHeaderRowDef="['header-row-first-group']" mat-header-row></tr>
                                            </ng-container>
                                            <tr *matHeaderRowDef="displayedColumns[i]" mat-header-row></tr>
                                            <tr *matRowDef="let row; columns: displayedColumns[i];" mat-row></tr>
                                        </table>
                                    
                                    </ng-container>
                                </div>
                            </mat-card>
                            <br>
                            <mat-paginator [length]="paging.totalRecords" [pageSize]="paging.pageSize"
                            [pageIndex]="paging.pageIndex" (page)="pageChange($event)">
                           </mat-paginator>
                        </div>
                    </div>
                </div>


            </mat-card-content>
        </div>
    </div>
</app-card>






<ng-template #addUser let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">
                <span *ngIf="!isEdit">Add</span>
                <span *ngIf="isEdit">Edit</span>
                Chart
            </h4>
        </div>
        <div class="mat-modal-body">

            <app-loading *ngIf="loadingReport" [isLoading]="loadingReport"></app-loading>
            <h5 color="gray">Chart Type</h5>

            <form class="form-material full-width-inputs" action="javascript:" *ngIf="!loadingReport">
                <div class="row form-group">
                    <div class="col-sm-6" style="display: inline-flex;">
                        <span>
                            <button style="text-align: start; border: 1px solid #DDD;" class="btn  btn-outline-primary  buttonbigger " [ngClass]="{'active': !isClicked}"
                                    (click)="onChartTypeChange()">
                                <mat-icon class="bigmaticon"> bar_chart</mat-icon>
                            </button>
                            <small>Bar Chart</small>
                        </span>
                        <span>
                            <button style="text-align: start; border: 1px solid #DDD;" class="btn btn-outline-primary buttonbigger" [ngClass]="{'active': isClicked}"
                                    (click)="onChartTypeChange()">
                                <mat-icon class="bigmaticon">pie_chart</mat-icon>
                            </button>
                            <small>Pie Chart</small>
                        </span>
                    </div>
                </div>
            </form>
            <hr>
            <h5 color="gray">Chart Settings</h5>
            <form class="form-material full-width-inputs" action="javascript:" [formGroup]="chartSettingForm" *ngIf="!loadingReport">
                <div class="row form-group">
                    <div class="col-sm-6">

                        <mat-form-field class="form-element">
                            <mat-label>Group by</mat-label>
                            <mat-select panelClass="myPanelClass" class="form-control" formControlName="groupByOption" disableOptionCentering
                                        required="">
                                <mat-option value="">--select--</mat-option>
                                <mat-optgroup *ngFor="let group of grouByList" [label]="group.header"
                                              [disabled]="group.disabled">
                                    <mat-option *ngFor="let elem of group.elements" [value]="elem.value"
                                                (click)="onGroupByHeaderChange(group.header);validateChartOne(group.header,elem.value)">
                                        {{elem.name}}
                                    </mat-option>
                                </mat-optgroup>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-sm-6">
                        <mat-form-field class="form-element" [formGroup]="chartSettingForm">
                            <mat-label for="des">Aggregate</mat-label>
                            <mat-select class="form-control" formControlName="aggregateOption" disableOptionCentering>
                                <mat-option (click)="validateFalse();onAggChange();" value="">Records</mat-option>
                                <mat-optgroup *ngFor="let aggre of aggregateByList" [label]="aggre.header"
                                              [disabled]="aggre.disabled">
                                    <mat-option *ngFor="let elem of aggre.aggregators" [value]="elem.name"
                                                (click)="onAggregateByHeaderChange(elem.header,elem.name);validateChartTwo(elem.header,elem.name)">
                                        {{elem.label}}
                                    </mat-option>
                                </mat-optgroup>
                            </mat-select>
                        </mat-form-field>
                        <mat-error *ngIf="this.validation" class="error-messages">
                            Aggregate cannot be the same as the Group By
                        </mat-error>
                    </div>
                </div>
                <div class="row form-group">

                    <div class="col-sm-12">
                        <mat-form-field class="form-element">
                            <mat-select class="form-control" [(ngModel)]="this.aggregateBySelected"
                                        formControlName="aggregateType" disableOptionCentering>

                                <mat-option *ngFor="let elem of aggregateTypeList" [value]="elem">
                                    {{elem}}
                                </mat-option>
                            </mat-select>
                            <mat-label for="des">Aggregate Type</mat-label>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-sm-6">
                        <mat-checkbox name="summTable" formControlName="summaryTable">Show Summary Table</mat-checkbox>
                    </div>
                    <div class="col-sm-6">
                        <mat-checkbox name="detailedTable" formControlName="detailedTable">Hide Detailed Data
                        </mat-checkbox>
                    </div>
                </div>

                <hr>

            </form>
            <div class="row my-2 mx-2" *ngIf="!loadingReport">
                <div class="action-buttons multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i>Cancel
                    </button>
                    <button mat-raised-button class="btn-primary" color="primary" (click)="saveChart()"
                            [disabled]="this.chartSettingForm.value.groupByOption === '' || this.validation"><i
                            class="flaticon-diskette"></i>Save
                    </button>
                </div>
            </div>

        </div>
    </div>

</ng-template>

<ng-template #generateSettings let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">Generate Settings</h4>
        </div>
        <div class="mat-modal-body">
            <form style="margin-bottom: 20px" class="form-material full-width-inputs" action="javascript:">
                <mat-form-field class="form-element">
                    <mat-label class="col-form-label">Include</mat-label>
                    <mat-select (selectionChange)="changeIncludeSelected($event)" class="form-control" [(ngModel)]="this.includeSelected" disableOptionCentering>
                        <mat-option *ngFor="let t of includeList" [value]="t.value">
                            {{t.label}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </form>
            <div class="row">
                <div class="multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close()">
                        <i class="flaticon-cancel"></i>Cancel
                    </button>
                    <button mat-raised-button class="btn-primary" color="primary" (click)="generateReport()">
                        <i class="flaticon-settings"></i>Generate
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #deleteChart let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">Delete Chart</h4>
        </div>
        <div class="mat-modal-body">
            <div class="text-left are-u">Are you sure ?</div>
            <div class="row">
                <div class="multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close()">
                        <i class="flaticon-cancel"></i>Cancel
                    </button>
                    <button mat-raised-button class="btn-primary" color="primary" (click)="deleteCharts()">
                        <i class="flaticon-trash"></i>Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

