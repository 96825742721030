<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
    <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="page-header-title">
            <span class="ng-star-inserted"></span
            ><span class="ng-star-inserted">
              <h4 class="m-b-10 ng-star-inserted">
                <span *ngIf="this.action === 'add'">Add Employee</span>
                <span *ngIf="this.action != 'add'">
                  <img *ngIf="this.employeeImage" class="img-p" src="{{ this.employeeImage }}" />
                  {{ this.employeeName }}</span
                >
              </h4></span
            >
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</div>

<app-loading [isLoading]="isInProgress"></app-loading>
<ng-container >
  <div class="card" *ngIf="!editId">
    <div class="card-content table-responsive table-full-width">
      <mat-horizontal-stepper labelPosition="bottom" [linear]="true" #stepper>
        <mat-step
          [completed]="false"
          [stepControl]="personalForm"
          label="Personal Details"
          errorMessage="Name and location is required."
          disabled
        >
          <br /><br />
          <form [formGroup]="personalForm" action="javascript:" class="form-material full-width-inputs">
            <ng-container>
              <div class="row">
                <div class="col-md-10">
                  <div class="form-group row">
                    <div class="col-md-4">
                      <mat-form-field class="form-element">
                        <mat-label>First Name</mat-label>
                        <input
                          class="form-control"
                          formControlName="firstname"
                          matInput
                          maxlength="40"
                          type="text"
                          required=""
                        />
                      </mat-form-field>
                    </div>
                    <div class="col-md-4" [hidden]="!requiredPersonal.middleNameAvailable">
                      <mat-form-field class="form-element">
                        <mat-label>Middle Name</mat-label>
                        <input
                          required="{{ requiredPersonal.middleNameRequired }}"
                          class="form-control"
                          formControlName="middlename"
                          matInput
                          maxlength="40"
                          type="text"
                        />
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field class="form-element">
                        <mat-label>Last Name</mat-label>
                        <input
                          class="form-control"
                          formControlName="lastname"
                          matInput
                          maxlength="40"
                          type="text"
                          required=""
                        />
                      </mat-form-field>
                    </div>
                    <div class="col-md-4" [hidden]="!requiredPersonal.genderAvailable">
                      <mat-form-field class="form-element">
                        <mat-select
                          required="{{ requiredPersonal.genderRequired }}"
                          disableOptionCentering
                          class="form-control"
                          formControlName="gender"
                        >
                          <mat-option value="">--Select--</mat-option>
                          <mat-option value="Male">Male</mat-option>
                          <mat-option value="Female">Female</mat-option>
                          <mat-option value="Other">Other</mat-option>
                        </mat-select>
                        <mat-label for="des">Gender</mat-label>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4" [hidden]="!requiredPersonal.militaryServiceAvailable">
                      <mat-form-field class="form-element">
                        <mat-label>Military Service</mat-label>
                        <mat-select
                          required="{{ requiredPersonal.militaryServiceRequired }}"
                          disableOptionCentering
                          class="form-control"
                          formControlName="militaryservice"
                        >
                          <mat-option value="">--Select--</mat-option>
                          <mat-option [value]="'yes'"> Yes</mat-option>
                          <mat-option [value]="'no'">No</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4" [hidden]="!requiredPersonal.martialStatusAvailable">
                      <mat-form-field class="form-element">
                        <mat-label>Maritial Status</mat-label>
                        <mat-select
                          required="{{ requiredPersonal.martialStatusRequired }}"
                          disableOptionCentering
                          class="form-control"
                          formControlName="maritalstatus"
                        >
                          <mat-option value="">--Select--</mat-option>
                          <mat-option value="Single">Single</mat-option>
                          <mat-option value="Married">Married</mat-option>
                          <mat-option value="Other">Other</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="col-md-2" (click)="openUploadDialog()">
                  <mat-spinner
                    *ngIf="imageUploading"
                    style="z-index: 5; position: absolute; width: 100px; height: 100px; margin: 30px"
                  >
                  </mat-spinner>
                  <div class="upload-btn-wrapper border" [ngStyle]="getStyle()">
                    <img
                      *ngIf="personalForm.value.image"
                      class="profile-h"
                      src="{{ personalForm.value.fullPathImage }}"
                    />
                    <i *ngIf="!personalForm.value.image" class="fa fa-user"></i>
                    <!-- <input (change)="profileChange($event)" class="form-control profile-img"
                                               name="image"
                                               type="file"> -->
                  </div>
                  <div *ngIf="personalForm.value.image"></div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="row form-group">
                    <div class="col-md-4" [hidden]="!requiredPersonal.dateOfBirthAvailable">
                      <mat-form-field class="form-element">
                        <mat-label for="dob">DOB</mat-label>
                        <input
                          [matDatepicker]="picker1"
                          class="form-control"
                          formControlName="dob"
                          id="dob"
                          matInput
                          required="{{ requiredPersonal.dateOfBirthRequired }}"
                        />
                        <mat-datepicker-toggle [for]="picker1" matSuffix></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4" [hidden]="!requiredPersonal.driverLicenseNumberAvailable">
                      <mat-form-field class="form-element">
                        <input
                          class="form-control"
                          formControlName="drivinglicenseno"
                          matInput
                          maxlength="40"
                          type="text"
                          required="{{ requiredPersonal.driverLicenseNumberRequired }}"
                        />
                        <span class="form-bar"></span>
                        <mat-label for="title">Driver's License No</mat-label>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4" [hidden]="!requiredPersonal.licenseExpiryDateAvailable">
                      <mat-form-field class="form-element">
                        <input
                          [matDatepicker]="picker"
                          class="form-control"
                          formControlName="licenseexpirydate"
                          matInput
                          required="{{ requiredPersonal.licenseExpiryDateRequired }}"
                        />
                        <mat-datepicker-toggle [for]="picker" matSuffix> </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-label for="expiry">Driving License Expiry Date</mat-label>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field class="form-element">
                        <mat-label>Nationality</mat-label>
                        <input
                          required=""
                          class="form-control"
                          type="text"
                          aria-label="Nationality"
                          maxlength="40"
                          matInput
                          placeholder="type for hints .."
                          formControlName="nationality"
                          [matAutocomplete]="auto"
                          readonly=""
                          onfocus="this.removeAttribute('readonly');"
                        />
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayNationlaityByID.bind(this)">
                          <mat-option (click)="changeStatus()" *ngFor="let r of filteredOptions1" [value]="r.id">
                            {{ r.name }}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                      <mat-error class="error-messages" [hidden]="this.correct">
                        please Select nationality from the dropdown !
                      </mat-error>
                    </div>
                    <div class="col-md-4" [hidden]="!requiredPersonal.nickNameAvailable">
                      <mat-form-field class="form-element">
                        <input
                          class="form-control"
                          formControlName="nickname"
                          matInput
                          maxlength="40"
                          type="text"
                          required="{{ requiredPersonal.nickNameRequired }}"
                          readonly=""
                          onfocus="this.removeAttribute('readonly');"
                        />
                        <span class="form-bar"></span>
                        <mat-label for="title">Nickname</mat-label>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4 flex-checkbox" [hidden]="!requiredPersonal.smokerAvailable">
                      <mat-form-field class="form-element">
                        <mat-label>Smoker</mat-label>
                        <mat-select
                          disableOptionCentering
                          class="form-control"
                          formControlName="smoking"
                          required="{{ requiredPersonal.smokerRequired }}"
                        >
                          <mat-option value="">--Select--</mat-option>
                          <mat-option value="yes">Yes</mat-option>
                          <mat-option value="no">No</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row form-group">
                <div class="col-sm-12 flex-checkbox">
                  <mat-checkbox class="form-control" formControlName="createlogindetail">
                    Create Login Details
                  </mat-checkbox>
                </div>
              </div>
              <div class="row form-group" [hidden]="!this.personalForm.value.createlogindetail">
                <ng-container>
                  <div class="col-md-6">
                    <mat-form-field class="form-element">
                      <input
                        class="form-control"
                        formControlName="email"
                        id="email"
                        matInput
                        maxlength="40"
                        type="email"
                        required="{{ this.personalForm.value.createlogindetail }}"
                      />
                      <mat-label>Email</mat-label>
                    </mat-form-field>
                  </div>

                  <div *ngIf="!editId" class="col-md-6">
                    <mat-form-field class="form-element">
                      <input
                        class="form-control"
                        formControlName="password"
                        id="pass"
                        matInput
                        maxlength="40"
                        type="password"
                        required="{{ this.personalForm.value.createlogindetail }}"
                      />
                      <mat-label>Password</mat-label>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <mat-form-field class="form-element">
                      <mat-select
                        disableOptionCentering
                        class="form-control"
                        formControlName="status"
                        [(ngModel)]="statusSelected"
                      >
                        <mat-option [value]="'active'">ACTIVE</mat-option>
                        <mat-option [value]="'deactive'">Inactive</mat-option>
                      </mat-select>
                      <mat-label>Status</mat-label>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-6">
                    <ng-container
                      formArrayName="roles"
                      *ngFor="let role of personalForm.get('roles2').controls; let i = index"
                    >
                      <ng-container [formGroupName]="i">
                        <mat-form-field class="form-element">
                          <mat-label for="inputState{{ i }}"> Role</mat-label>
                          <mat-select
                            (ngModelChange)="setRoleType($event)"
                            disableOptionCentering
                            class="form-control"
                            id="inputState{{ i }}"
                            [ngModelOptions]="{ standalone: true }"
                            [(ngModel)]="this.userRole"
                          >
                            <mat-option value=""> --Select-- </mat-option>
                            <mat-option
                              *ngFor="let r of adminList; let i = index"
                              value="{{ r.id }}"
                              [attr.selected]="i == 0"
                            >
                              {{ r.name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <!-- </div> -->
                      </ng-container>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
              <br /><br /><br />
              <div class="action-buttons multi-btns col-sm-12 text-right">
                <button
                  (click)="goForward(stepper, 2)"
                  [disabled]="!this.personalForm.valid || !this.correct"
                  class="btn-primary"
                  color="primary"
                  mat-raised-button
                  name="button"
                >
                  <i class="flaticon-right-arrow"></i>Next
                </button>
              </div>
            </ng-container>
          </form>
        </mat-step>
        <mat-step [stepControl]="jobForm" label="Job information" errorMessage="required fileds are missing">
          <br /><br />
          <form [formGroup]="jobForm" action="javascript:" class="form-material full-width-inputs cus-actions-margin">
            <ng-container>
              <div class="row form-group">
                <div class="col-md-4" [hidden]="!requiredJob.jobCategoryAvailable">
                  <mat-form-field class="form-element">
                    <mat-select
                      disableOptionCentering
                      class="form-control"
                      formControlName="jobcategoryid"
                      placeholder="--Select--"
                      name="jobCatagory"
                      class="form-control"
                      required="{{ requiredJob.jobCategoryRequired }}"
                    >
                      <mat-option value=""> --Select--</mat-option>
                      <ng-container *ngFor="let w of jobCategoryList">
                        <mat-option value="{{ w.id }}">{{ w.name }}</mat-option>
                      </ng-container>
                    </mat-select>
                    <mat-label for="contractenddate">Job Catagory</mat-label>
                  </mat-form-field>
                </div>
                <div class="col-md-4" [hidden]="!requiredJob.contractStartDateAvailable">
                  <mat-form-field class="form-element">
                    <mat-label for="contractstartdate">Contract Start Date</mat-label>
                    <input
                      matInput
                      [matDatepicker]="contractstartdate"
                      id="contractstartdate"
                      class="form-control"
                      formControlName="contractstartdate"
                      required="{{ requiredJob.contractStartDateRequired }}"
                    />
                    <mat-datepicker-toggle matSuffix [for]="contractstartdate"> </mat-datepicker-toggle>
                    <mat-datepicker
                      #contractstartdate
                      [settings]="{
                        bigBanner: true,
                        timePicker: true,
                        format: 'dd-MMM-yyyy hh:mm a'
                      }"
                    ></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col-md-4" [hidden]="!requiredJob.contractEndDateAvailable">
                  <mat-form-field class="form-element">
                    <mat-label for="contractenddate">Contract End Date</mat-label>
                    <input
                      [matDatepicker]="contractenddate"
                      matInput
                      class="form-control"
                      formControlName="contractenddate"
                      required="{{ requiredJob.contractEndDateRequired }}"
                    />
                    <mat-datepicker-toggle matSuffix [for]="contractenddate"> </mat-datepicker-toggle>
                    <mat-datepicker #contractenddate></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col-md-4" [hidden]="!requiredJob.costCenterAvailable">
                  <mat-form-field class="form-element">
                    <mat-label for="companyid">Cost Center</mat-label>
                    <mat-select
                      disableOptionCentering
                      class="form-control"
                      formControlName="costcenterid"
                      placeholder="--Select--"
                      name="companyid"
                      required="{{ requiredJob.costCenterRequired }}"
                    >
                      <mat-option value=""> --Select--</mat-option>
                      <ng-container *ngFor="let e of costCenterList">
                        <mat-option value="{{ e.id }}">{{ e.name }}</mat-option>
                      </ng-container>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field class="form-element">
                    <mat-label for="effectivefrom">Effective From</mat-label>
                    <input
                      [matDatepicker]="effectivefrom"
                      matInput
                      class="form-control"
                      formControlName="effectivefrom"
                      required=""
                    />
                    <mat-datepicker-toggle matSuffix [for]="effectivefrom"></mat-datepicker-toggle>
                    <mat-datepicker #effectivefrom></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col-md-4" [hidden]="!requiredJob.employmentStatusAvailable">
                  <mat-form-field class="form-element">
                    <mat-select
                      disableOptionCentering
                      class="form-control"
                      formControlName="employeestatus"
                      placeholder="--Select--"
                      name="employeestatusid"
                      required="{{ requiredJob.employmentStatusRequired }}"
                    >
                      <mat-option value=""> --Select--</mat-option>
                      <ng-container *ngFor="let e of employeeStatusList">
                        <mat-option value="{{ e.id }}">{{ e.name }}</mat-option>
                      </ng-container>
                    </mat-select>
                    <mat-label for="employeestatusid">Employment Status</mat-label>
                  </mat-form-field>
                </div>
                <div class="col-md-4" [hidden]="!requiredJob.jobTitleAvailable">
                  <mat-form-field class="form-element">
                    <mat-label for="contractenddate">Job Title</mat-label>

                    <mat-select
                      disableOptionCentering
                      class="form-control"
                      formControlName="jobtitelid"
                      placeholder="--Select--"
                      name="jobtitelid"
                      required="{{ requiredJob.jobTitleRequired }}"
                    >
                      <mat-option value=""> --Select--</mat-option>
                      <ng-container *ngFor="let j of jobTitleList">
                        <mat-option value="{{ j.id }}">{{ j.title }}</mat-option>
                      </ng-container>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-4" [hidden]="!requiredJob.probationEndDateAvailable">
                  <mat-form-field class="form-element">
                    <mat-label for="endDate">Probition End date</mat-label>
                    <input
                      [matDatepicker]="endDate"
                      formControlName="endDate"
                      class="form-control"
                      matInput
                      required="{{ requiredJob.probationEndDateRequired }}"
                    />
                    <mat-datepicker-toggle [for]="endDate" matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #endDate></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col-md-4" [hidden]="!requiredJob.workShiftAvailable">
                  <mat-form-field class="form-element">
                    <mat-select
                      disableOptionCentering
                      class="form-control"
                      formControlName="workshiftid"
                      placeholder="--Select--"
                      name="workshift"
                      required="{{ requiredJob.workShiftRequired }}"
                    >
                      <mat-option value=""> --Select--</mat-option>
                      <ng-container *ngFor="let w of workShiftList">
                        <mat-option value="{{ w.id }}">{{ w.name }}</mat-option>
                      </ng-container>
                    </mat-select>
                    <mat-label for="contractenddate">Work Shift</mat-label>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field class="form-element">
                    <mat-select
                      disableOptionCentering
                      class="form-control"
                      formControlName="region"
                      placeholder="--Select--"
                      required=""
                    >
                      <mat-option value=""> --Select--</mat-option>
                      <ng-container *ngFor="let r of regionList">
                        <mat-option value="{{ r.id }}">{{ r.name }}</mat-option>
                      </ng-container>
                    </mat-select>
                    <mat-label>Region</mat-label>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field class="form-element">
                    <mat-select
                      disableOptionCentering
                      class="form-control"
                      formControlName="locationid"
                      placeholder="--Select--"
                      required=""
                    >
                      <mat-option value=""> --Select--</mat-option>
                      <ng-container *ngFor="let n of locationList">
                        <mat-option value="{{ n.id }}"> {{ n.location }} </mat-option>
                      </ng-container>
                    </mat-select>

                    <mat-label>Location</mat-label>
                    <!-- <span *ngIf="!jobForm.controls.locationid.valid && jobForm.controls.locationid.touched"
                                              class="validation-error">{{ validationError.required }}</span> -->
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field class="form-element">
                    <mat-select
                      disableOptionCentering
                      class="form-control"
                      formControlName="temporarydepartment"
                      placeholder="--Select--"
                      required=""
                    >
                      <mat-option value=""> --Select--</mat-option>
                      <ng-container *ngFor="let n of temporaryDepartment">
                        <mat-option value="{{ n.id }}"> {{ n.name }} </mat-option>
                      </ng-container>
                    </mat-select>
                    <mat-label>Sub Unit</mat-label>
                  </mat-form-field>
                </div>
                <div class="col-sm-12" [hidden]="!requiredJob.commentAvailable">
                  <mat-form-field class="form-element">
                    <textarea
                      class="form-control textarea-height"
                      formControlName="comment"
                      matInput
                      required="{{ requiredJob.commentRequired }}"
                    ></textarea>
                    <mat-label>Leave a comment</mat-label>
                  </mat-form-field>
                </div>
              </div>
              <br /><br /><br />
              <div class="row">
                <div class="action-buttons multi-btns col-sm-12 text-right">
                  <button
                    (click)="goBack(stepper)"
                    class="btn-danger btn-cancel"
                    color="red"
                    mat-raised-button
                    name="button"
                  >
                    <i class="flaticon-left-arrow"></i>Previous
                  </button> 

                  <button
                    [hidden]="!this.write"
                    (click)="submitEmployee()"
                    [disabled]="!this.personalForm.valid || !this.jobForm.valid || isInProgress"
                    class="btn-primary"
                    color="primary"
                    mat-raised-button
                    name="button"
                  >
                    <i class="flaticon-diskette"></i>Save
                  </button>
                </div>
              </div>
            </ng-container>
          </form>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!isLoading && dropdownErr">
  <div class="error">{{ dropdownErr }}</div>
</ng-container>

<ng-template #showEmpNumber let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">
        <span>Alert</span>
      </h4>
    </div>
    <div class="mat-modal-body">
      <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>

      <form class="form-material full-width-inputs" action="javascript:">
        <div class="row form-group">
          <div class="col-sm-12">
            {{ this.showEmpNumberMessage }}
          </div>
        </div>
      </form>

      <div class="row">
        <div class="action-buttons multi-btns col-sm-12 text-right">
          <button mat-raised-button class="btn-primary" color="primary" (click)="OnConfirmation()">
            <i class="flaticon-progress"></i>Done
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #uploadImage let-modal>
  <div class="mat-modal-header">
    <h4 class="mat-title-modal">Update Profile Picture</h4>
  </div>
  <app-loading *ngIf="imageUploading" [isLoading]="true"></app-loading>
  <div class="mat-modal-body" *ngIf="!imageUploading">
    <input type="file" class="file-input" (change)="fileChangeListener($event)" #fileUpload />

    <div class="file-upload">
      {{ fileName || "No file uploaded yet." }}

      <button mat-mini-fab color="primary" class="upload-btn" (click)="fileUpload.click()">
        <mat-icon>attach_file</mat-icon>
      </button>
    </div>

    <div class="row" style="margin-top: 5%" *ngIf="isShow">
      <div class="text-center col-md-8">
        <img-cropper #cropper [image]="data" [settings]="cropperSettings"></img-cropper>
      </div>
      <div class="text-center col-md-4">
        <span class="result rounded">
          <h4 class="mat-title-modal">preview</h4>
          <img
            [src]="data.image"
            [width]="cropperSettings.croppedWidth"
            [height]="cropperSettings.croppedHeight"
            style="width: 100px; height: 100px"
          />
        </span>
      </div>
    </div>
    <br />
  </div>
  <br /><br />
  <div *ngIf="!imageUploading" class="action-buttons multi-btns col-sm-12 text-right">
    <button mat-raised-button (click)="deleteProfileImage()" class="btn-danger">
      <i class="flaticon-cancel"></i> cancel
    </button>
    <button
      mat-raised-button
      class="btn btn-primary"
      color="primary"
      [disabled]="!data.image && !imageUploading"
      (click)="uploadProfileImage()"
    >
      <i class="flaticon-diskette"></i> save
    </button>
  </div>
</ng-template>
