import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DashboardService } from '../../../dashboard.service';
import { NotificationService } from "src/app/core/services/notification.service";
import { saveAs } from 'file-saver-es';
import {consoleTestResultHandler} from "tslint/lib/test";
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'app-bradford-threshold',
  templateUrl: './bradford-threshold.component.html',
  styleUrls: ['./bradford-threshold.component.scss']
})
export class BradfordThresholdComponent implements OnInit {

  public rowsOnPage = 10;
  public sortOrder = 'desc';
  public sortBy = '';
  public filterQuery = '';
  public userList: Array<any>;
  public isLoading: boolean;
  public isUserAdded: boolean;
  public err: string;
  public isEdit: boolean;
  public editId: string;
  public deleteId: string;
  public totalRecords: number;
  public activePage: number;
  public pageSize: number;
  public filterForm: FormGroup;
  public employeeList = [];
  public jobCategoryList = [];
  public jobTitleList = [];
  public locationList = [];
  public subUnitList = [];
  public supervisorList = [];
  displayedBradfordColumns = ['employee', 'jobtitle', 'location'];
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  paging = {
      pageSize: 10,
      totalRecords: 0,
      pageIndex: 0,
      pageSizeOptions: [10, 25, 50, 100]
  };
  public columns = ['employee', 'jobtitle', 'location'];

  
  constructor(private service: DashboardService, private toastr: NotificationService, public fb: FormBuilder) {
    this.createForm();
  }

  ngOnInit() {
    this.activePage = 1;
    this.pageSize = 10;
    this.isLoading = true;
    this.isEdit = false;
    this.getBFTList();
    this.getDropdownList();
  }

  public createForm(): void {
    this.filterForm = this.fb.group({
      empIds: [''],
      jobCategoryIds: [''],
      jobTitleIds: [''],
      locationIds: [''],
      subunitIds: [''],
      supervisorIds: [''],
      include: ['']
    });
  }

  public getDropdownList(): void {
    this.service.getBFTFilterDropdown().subscribe(resp => {
      if (resp) {
        this.employeeList = this.getData('name', 'id', resp[0] && resp[0].data);
        this.supervisorList = this.getData('name', 'id', resp[0] && resp[0].data);
        this.jobCategoryList = this.getData('name', 'id', resp[1] && resp[1].data);
        this.jobTitleList = this.getData('title', 'id', resp[2] && resp[2].data);
        this.locationList = this.getData('location', 'id', resp[3] && resp[3].data);
        this.subUnitList = this.getData('name', 'id', resp[4] && resp[4].data);
      }
    });
  }

  public getBFTList(): void {
    this.service.getBradfactorReport(this.filterForm.value).subscribe(user => {
      if (user && user.data) {
        this.isLoading = false;
        this.userList = user.data;
        this.totalRecords = this.userList.length;
        this.err = '';
        this.dataSource = new MatTableDataSource<any>(this.userList);
        this.dataSource.sort = this.sort;
      }
      if (user && user.paging) {
        this.totalRecords = user.paging.count || 10;
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.isLoading = false;
      this.err = 'Error while getting Nationality';
      this.toastr.error(err || this.err);
    });
  }

  public getData(label, value, data): any {
    const arr = [];
    data.map(d => {
      const obj = {};
      obj['label'] = d[label];
      obj['value'] = d[value];
      arr.push(obj);
    });

    return arr;
  }

  public getPdf(): void {
    window.print();
  }

  downloadFile = (data): void => {
    const blob = new Blob([data], { type: 'text/csv' });
    saveAs(blob, 'bradford.csv');
  }

  public exportBradfactorReport(): void {
    this.service.exportBradfactorReport(this.filterForm.value).subscribe(user => {
      this.downloadFile(user);
    }, error => {
      const err = error.error && error.error;
      console.log(err);
    });
  }
  public exportAsCsv() {
    const csv = this.userList.map(
        row => {
          return [row.employee, row.jobtitle, row.location].join(',') + '\n';
        }
    );
    csv.unshift(this.displayedBradfordColumns.join(',') + '\n');
    const exportDate = new Date();
    // tslint:disable-next-line:max-line-length
    const exportFileName = `Csv-export-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}.csv`;
    saveAs(new Blob(csv, {type: 'text/csv;charset=utf-8'}), exportFileName);
  }
}

