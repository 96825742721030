<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
    <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="page-header-title">
            <span class="ng-star-inserted"></span
            ><span class="ng-star-inserted">
              <h4 class="m-b-10 ng-star-inserted">Leave Request Details</h4>
            </span>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</div>

<div class="page-body no-title">
  <div class="row">
    <div class="col-sm-12">
      <!-- <app-loading *ngIf="isLoading && !err" [isLoading]="isLoading && !err"></app-loading> -->
      <app-card [title]="'Leave Days'">
        <div class="row top-tools">
          <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6">
            <h4 class="m-b-10 ng-star-inserted cus-flex-tools">
              <span>
                <img *ngIf="this.employeeImage" class="img-p" src="{{ this.employeeImage }}" />
                <img *ngIf="!this.employeeImage" class="img-p" />
                &nbsp;
                {{ this.employeeName }}
              </span>
            </h4>
          </div>
        </div>

        <div class="row top-tools">
          <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6">
            <p class="m-b-10 ng-star-inserted cus-flex-tools">
              <span> <b> Requested for: </b> {{ this.requestedFor }} </span>
              <span> </span>
            </p>
          </div>
        </div>
        <br />
        <div class="row top-tools">
          <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6">
            <p class="m-b-10 ng-star-inserted cus-flex-tools">
              <span> <b> Applied on: </b> {{ this.appliedOn }} </span>
              <span> </span>
            </p>
          </div>
        </div>
        <br />
        <div class="dt-responsive" *ngIf="dataSource">
          <div class="table-responsive">
            <mat-card-content>
              <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="date">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.date }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="leaveType">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Leave Type </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.leaveType }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="leaveBalanceDays">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Net Leave Balance (Days)</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.leaveBalanceDays }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="duration">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Duration(Hours)</mat-header-cell>
                  <mat-cell (click)="openLeaveDays(row.employeeId, row.leaveId)" *matCellDef="let row">
                    {{ row.duration }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="status">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
                  <mat-cell
                    (click)="openLeaveDays(row.employeeId, row.leaveId)"
                    *matCellDef="let row"
                    [class.icon_has_value]="row.status == 'Weekend'"
                  >
                    {{ row.status }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="icons">
                  <mat-header-cell *matHeaderCellDef></mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <span class="mytooltip tooltip-effect-4">
                      <span class="tooltip-item" (click)="addComment(row.dayId)">
                        <mat-icon aria-hidden="false" [class.icon_has_value]="row.comments.length > 0">
                          add_comment
                        </mat-icon>
                      </span>
                      <span class="tooltip-content clearfix">
                        <span class="tooltip-text">Comments</span>
                      </span>
                    </span>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actionTaken">
                  <mat-header-cell [hidden]="!update" *matHeaderCellDef>Actions</mat-header-cell>
                  <mat-cell [hidden]="!update" *matCellDef="let row">
                    <mat-form-field class="form-element" *ngIf="row.status === 'Pending' && action != 'my'">
                      <mat-select
                        (ngModelChange)="this.addActionsToList(row.dayId, $event)"
                        ngModel="{{ selected }}"
                        disableOptionCentering
                      >
                        <mat-option value="">Select Action</mat-option>
                        <mat-option value="Approve">Approve</mat-option>
                        <mat-option value="Cancelled">Cancel</mat-option>
                        <mat-option value="Rejected">Reject</mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="form-element" *ngIf="row.status === 'Scheduled' || ( row.status === 'Pending' && action === 'my')">
                      <mat-select
                        (ngModelChange)="this.addActionsToList(row.dayId, $event)"
                        ngModel="{{ selected }}"
                        disableOptionCentering
                      >
                        <mat-option value="">Select Action</mat-option>
                        <mat-option value="Cancelled">Cancel</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedDaysListColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedDaysListColumns" class="cursor-pointer"> </mat-row>
              </mat-table>
              <mat-progress-bar mode="indeterminate" *ngIf="isLoadingDay"></mat-progress-bar>
              <mat-paginator [pageSizeOptions]="[20, 50]" showFirstLastButtons></mat-paginator>
            </mat-card-content>
          </div>
          <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
        </div>

        <div class="row my-2 mx-2" [hidden]="!update">
          <div class="action-buttons multi-btns col-sm-12 text-right">
            <button (click)="back()" class="btn-danger" mat-raised-button>
              <i class="flaticon-left-arrow"></i>Back
            </button>
            <button
              (click)="saveBulkActions()"
              [class.spinner]="isSaving"
              [disabled]="isSaving"
              class="btn-primary"
              color="primary"
              mat-raised-button
            >
              <i class="flaticon-diskette"></i>Save
            </button>
          </div>
        </div>

        <div class="row my-2 mx-2">
          <div class="action-buttons multi-btns col-sm-12 text-right">
            <!-- <button mat-raised-button class="check-balance" (click)="checkLeaveBalance()"> </button> -->
            <a (click)="addCommentToLeave()" class="check-balance cursor-pointer"> View Leave Request Comments</a>
          </div>
        </div>
      </app-card>
    </div>
  </div>
</div>

<ng-template #addComments let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">Leave Request Comments</h4>
    </div>
    <div class="mat-modal-body">
      <app-loading *ngIf="isLodaingCo" [isLoading]="isLodaingCo"></app-loading>

      <form
        class="form-material full-width-inputs"
        action="javascript:"
        [formGroup]="this.commentForm"
        *ngIf="!isLodaingCo"
      >
        <div class="row form-group">
          <div class="col-sm-6">
            <p class="m-b-10 ng-star-inserted cus-flex-tools">
              <span>
                <img *ngIf="this.employeeImage" class="img-p" src="{{ this.employeeImage }}" />
                <img *ngIf="!this.employeeImage" class="img-p" />
                {{ this.employeeName }}
              </span>
            </p>
          </div>
        </div>
        <div class="row form-group" [hidden]="!update">
          <div class="col-sm-12">
            <mat-form-field class="form-element">
              <mat-label>Comment</mat-label>
              <textarea formControlName="commnet" maxlength="500" matInput required></textarea>
            </mat-form-field>
          </div>
        </div>
      </form>

      <div class="row my-2 mx-2">
        <div class="action-buttons multi-btns col-sm-12 text-right">
          <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i>Cancel</button>
          <button
            [hidden]="!update"
            [disabled]="!this.commentForm.valid"
            (click)="submitComment()"
            class="btn-primary"
            color="primary"
            mat-raised-button
          >
            <i class="flaticon-diskette"></i>Save
          </button>
        </div>
      </div>

      <div *ngFor="let comment of this.commentList">
        <div class="row form-group" *ngIf="comment.commnet != undefined">
          <div class="col-sm-6">
            <p class="m-b-10 ng-star-inserted cus-flex-tools">
              <span>
                <mat-icon aria-hidden="false" aria-label="Example home icon">comment</mat-icon> &nbsp;
                {{ comment.commentby }}: {{ comment.commentdate | date : "E, d MMM yyyy HH:mm" }}
              </span>
            </p>
            <p class="m-b-10 ng-star-inserted cus-flex-tools">
              <span>
                {{ comment.commnet }}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #addCommentss let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">Leave Request Comments</h4>
    </div>
    <div class="mat-modal-body">
      <app-loading *ngIf="isLodaingCo" [isLoading]="isLodaingCo"></app-loading>

      <form
        class="form-material full-width-inputs"
        action="javascript:"
        [formGroup]="this.commentForm"
        *ngIf="!isLodaingCo"
      >
        <div class="row form-group">
          <div class="col-sm-6">
            <p class="m-b-10 ng-star-inserted cus-flex-tools">
              <span>
                <img *ngIf="this.employeeImage" class="img-p" src="{{ this.employeeImage }}" />
                <img *ngIf="!this.employeeImage" class="img-p" />
                {{ this.employeeName }}
              </span>
            </p>
          </div>
        </div>
        <div class="row form-group" [hidden]="!update">
          <div class="col-sm-12">
            <mat-form-field class="form-element">
              <mat-label>Comment</mat-label>
              <textarea formControlName="commnet" maxlength="500" matInput required></textarea>
            </mat-form-field>
          </div>
        </div>
      </form>

      <div class="row my-2 mx-2" [hidden]="!update">
        <div class="action-buttons multi-btns col-sm-12 text-right">
          <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i>Cancel</button>
          <button
            [disabled]="!this.commentForm.valid"
            (click)="submitComments()"
            class="btn-primary"
            color="primary"
            mat-raised-button
          >
            <i class="flaticon-diskette"></i>Save
          </button>
        </div>
      </div>

      <div *ngFor="let comment of this.commentList">
        <div class="row form-group" *ngIf="comment.commnet != undefined">
          <div class="col-sm-6">
            <p class="m-b-10 ng-star-inserted cus-flex-tools">
              <span>
                <mat-icon aria-hidden="false" aria-label="Example home icon">comment</mat-icon> &nbsp;
                {{ comment.commentby }}: {{ comment.commentdate | date : "E, d MMM yyyy HH:mm" }}
              </span>
            </p>
            <p class="m-b-10 ng-star-inserted cus-flex-tools">
              <span>
                {{ comment.commnet }}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
