import { CommonModule, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
// Module
import { TreeModule } from "@circlon/angular-tree-component";
import { LightboxModule } from "ngx-lightbox";
import { RouterModule } from "@angular/router";
import { CoreModule } from "../core/core.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HrmSharedModule } from "../hrmshared/hrmshared.module";
import { SharedModule } from "../shared/shared.module";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatTreeModule } from "@angular/material/tree";
import { MatTableModule } from "@angular/material/table";
// component
import { DashboardComponent } from "./dashboard.component";
import { HrmAdminComponent } from "./admin/admin.component";
import { PimComponent } from "./pim/pim.component";
import { MyInfoComponent } from "./my-info/my-info.component";
import { LeaveComponent } from "./leave/leave.component";
import { DisciplineComponent } from "./discipline/discipline.component";

import { MaintainenceComponent } from "./maintainence/maintainence.component";
import { UserComponent } from "./admin/user-management/user/user.component";
import { UserRoleComponent } from "./admin/user-management/user-role/user-role.component";
// service
import { GoogleChartsModule } from "angular-google-charts";
import { DashboardService } from "./dashboard.service";
import { EmployeeListComponent } from "./pim/employee-list/employee-list.component";
import { AddEmployeeComponent } from "./pim/add-employee/add-employee.component";
import { JobTitleComponent } from "./admin/job/job-title/job-title.component";
import { JobCategoryComponent } from "./admin/job/job-category/job-category.component";
import { PayGradeComponent } from "./admin/job/pay-grade/pay-grade.component";
import { SalaryComponent } from "./admin/job/salary/salary.component";
import { WorkShiftComponent } from "./admin/job/work-shift/work-shift.component";
import { EmployeeStatusComponent } from "./admin/job/employee-status/employee-status.component";
import { NationalitiesComponent } from "./admin/nationalities/nationalities.component";
import { ReportsComponent } from "./pim/reports/reports.component";
import { ReportingMethodComponent } from "./pim/configuration/reporting-method/reporting-method.component";
import { TerminationReasonComponent } from "./pim/configuration/termination-reason/termination-reason.component";
import { AssignLeaveComponent } from "./leave/assign-leave/assign-leave.component";
import { ApplyLeaveComponent } from "./leave/apply-leave/apply-leave.component";
import { GeneralInformationComponent } from "./admin/organization/general-information/general-information.component";
import { DocumentTemplateComponent } from "./pim/configuration/document-template/document-template.component";
import { PayrollComponent } from "./payroll/payroll/payroll.component";
import { LeavePeriodComponent } from "./leave/configure/leave-period/leave-period.component";
import { HolidaysComponent } from "./admin/organization/holidays/holidays.component";
import { LeaveListComponent } from "./leave/leave-list/leave-list.component";
import { EntitlementListComponent } from "./leave/entitlement/entitlement-list/entitlement-list.component";
import { AddEntitlementComponent } from "./leave/entitlement/add-entitlement/add-entitlement.component";
import { ManageOrganizationComponent } from "./admin/organization/manage-organization/manage-organization.component";
import { StructureComponent } from "./admin/organization/structure/structure.component";
import { CostCenterComponent } from "./admin/organization/cost-center/cost-center.component";
import { CompanyComponent } from "./admin/company/company.component";
import { DisciplinaryCasesComponent } from "./discipline-admin/disciplinary-cases/disciplinary-cases.component";
import { MyActionsComponent } from "./discipline-admin/my-actions/my-actions.component";
import { SkillsComponent } from "./admin/qualification/skills/skills.component";
import { LicenseComponent } from "./admin/qualification/license/license.component";
import { EducationComponent } from "./admin/qualification/education/education.component";
import { MembershipComponent } from "./admin/qualification/membership/membership.component";
import { LanguagesComponent } from "./admin/qualification/languages/languages.component";
import { SelectModule } from "ng-select";
import { SocialMediaComponent } from "./pim/add-employee/social-media/social-media.component";
import { EmergencyContactComponent } from "./pim/add-employee/emergency-contact/emergency-contact.component";
import { DependentComponent } from "./pim/add-employee/dependent/dependent.component";
import { ImmigrationComponent } from "./pim/add-employee/immigration/immigration.component";
import { WorkWeekComponent } from "./pim/add-employee/work-week/work-week.component";
import { QualificationComponent } from "./pim/add-employee/qualification/qualification.component";
import { DirectDepositComponent } from "./pim/add-employee/direct-deposit/direct-deposit.component";
import { EmpMembershipComponent } from "./pim/add-employee/membership/membership.component";
import { LocationsComponent } from "./admin/organization/locations/locations.component";
import { RegionComponent } from "./admin/organization/region/region.component";
import { LeaveTypeComponent } from "./leave/configure/leave-type/leave-type.component";
import { MyLeaveComponent } from "./leave/my-leave/my-leave.component";
import { BftComponent } from "./leave/configure/bft/bft.component";
import { WorkWeekLeaveComponent } from "./admin/organization/work-week/work-week.component";
import { WorkingWeekendComponent } from "./leave/configure/working-weekend/working-weekend.component";
import { NotificationComponent } from "./leave/configure/notification/notification.component";
import { LeaveCalendarComponent } from "./leave/configure/leave-calendar/leave-calendar.component";
import { LeaveCComponent } from "./leave/leave-c/leave-c.component";
import { BulkAssignComponent } from "./leave/bulk-assign/bulk-assign.component";
import { LeaveUsageComponent } from "./leave/reports/leave-usage/leave-usage.component";
import { BradfordThresholdComponent } from "./leave/reports/bradford-threshold/bradford-threshold.component";
import { FullCalendarModule } from "@fullcalendar/angular";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGrigPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import { MyLeaveUsageComponent } from "./leave/reports/my-leave-usage/my-leave-usage.component";
import { BulkUpdateComponent } from "./pim/manage-data/bulk-update/bulk-update.component";
import { EmployeeDataExportComponent } from "./pim/manage-data/employee-data-export/employee-data-export.component";
import { MaterialModule } from "../module/material/material.module";
import { OptionalFieldsComponent } from "./pim/configuration/optional-fields/optional-fields.component";
import { CustomeFieldsComponent } from "./pim/configuration/custome-fields/custome-fields.component";
import { AddEmployeeWizardComponent } from "./pim/configuration/add-employee-wizard/add-employee-wizard.component";
import { PurgeRecordsComponent } from "./maintainence/purge-records/purge-records.component";
import { EmployeeRecordsComponent } from "./maintainence/purge-records/employee-records/employee-records.component";
import { CandidateRecordsComponent } from "./maintainence/purge-records/candidate-records/candidate-records.component";
import { ViewReportComponent } from "./pim/reports/view-report/view-report.component";
import { AddReportComponent } from "./pim/reports/add-report/add-report.component";
import { EeoFillingComponent } from "./admin/organization/eeo-filling/eeo-filling.component";
import { ManageDataComponent } from "./pim/manage-data/manage-data.component";
import { AuditTrailComponent } from "./admin/audit-trail/audit-trail.component";
import { AnnouncementModule } from "./admin/announcement/announcement.module";
import { LeavePercentageComponent } from "./leave/reports/leave-percentage/leave-percentage.component";
import { CompetencyListComponent } from "./admin/competency/competency-list/competency-list.component";
import { MatSortModule } from "@angular/material/sort";
import { MatFormFieldModule } from "@angular/material/form-field";
import { ReportToComponent } from "./pim/add-employee/report-to/report-to.component";
import { EditEmployeeComponent } from "./pim/edit-employee/edit-employee.component";
import { ChangePasswordComponent } from "../core/change-password/change-password.component";
import { AddDocumentTemplateComponent } from "./pim/configuration/add-document-template/add-document-template.component";
import { PersonalComponent } from "./pim/add-employee/personalDetails/personal-component";
import { JobInfo } from "./pim/add-employee/job-information/job-info.component";
import { SalaryInfo } from "./pim/add-employee/salary-info/salary-info.component";
import { ContactDetails } from "./pim/add-employee/contact-details.component.ts/contact-details.component";
import { TemplateModule } from "../template/template.module";
import { UploadImageComponent } from "./pim/upload-image/upload-image.component";
import { ImageCropperModule } from "ngx-img-cropper";
import { ImageUtilService } from "../service/image-util.service";
import { MatMenuModule } from "@angular/material/menu";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { EditCustomeFieldsComponent } from "./pim/configuration/custome-fields/edit-custom-field/edit-custome-fields.component";
import { DisciplineAdminComponent } from "./discipline-admin/discipline-admin.component";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { CoursesComponent } from "./training/courses/courses.component";
import { SessionsComponent } from "./training/sessions/sessions.component";
import { TrainingSessionComponent } from "./training/training-session/training-session.component";
import { ParticipatingSessionComponent } from "./training/participating-session/participating-session.component";
import { ParticipatingSessionReportComponent } from "./training/participating-session-report/participating-session-report.component";
import { TrainingSessionReportComponent } from "./training/training-session-report/training-session-report.component";
import { TrainingComponent } from "./training/training.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { ViewCourseComponent } from "./training/view-course/view-course.component";
import { ViewSessionsComponent } from "./training/view-sessions/view-sessions.component";
import { MatPaginatorModule } from "@angular/material/paginator";
import { ViewParticipatingSessionComponent } from "./training/view-participating-session/view-participating-session.component";
import { MyCoursesComponent } from "./training/my-courses/my-courses.component";
import { MyTrainingSessionComponent } from "./training/my-training-session/my-training-session.component";
import { ViewMyCourseComponent } from "./training/view-my-course/view-my-course.component";
import { LeaveDaysComponent } from "./leave/leave-list/leave-days/leave-days.component";
import { UsersService } from "./user.service";
import { LeavePeriodsComponent } from "./leave/reports/leave-usage/leave-period/leave-period.component";
import { RateParticipatingSessionComponent } from "./training/participating-session/rate-participating-session/rate-participating-session.component";
import { RateTrainingSessionComponent } from "./training/my-training-session/rate-trainig-session/rate-training-session.component";
import { MyTrainingCoursesComponent } from "./training/my-training-courses/my-training-courses.component";
import { AddEventsComponent } from "./onboarding/events/add-events/add-events.component";
import { ParticipatingCoursesComponent } from "./training/courses/participating-courses/participating-courses.component";
import { CourseDetailsComponent } from "./training/view-course/course-details/course-details.component";
import { CourseSessionComponent } from "./training/view-course/course-session/course-session.component";
import { RecurrenceEditorAllModule, ScheduleAllModule } from "@syncfusion/ej2-angular-schedule";
import { LeaveCalendarYearComponent } from "./leave/configure/leave-calendar/leave-calendar-year/leave-calendar-year.component";
import { LeaveCalendarMonthComponent } from "./leave/configure/leave-calendar/app-leave-calendar-month/leave-calendar-month.component";
import { LeaveCalendarWeekComponent } from "./leave/configure/leave-calendar/app-leave-calendar-week/leave-calendar-week.component";
import { EditEventsComponent } from "./onboarding/events/edit-events/edit-events.component";
import { SessionDetailsComponent } from "./training/view-sessions/session-details/session-details.component";
import { TrainersComponent } from "./training/view-sessions/trainers/trainers.component";
import { SessionsEmployeesComponent } from "./training/view-sessions/employees/sessions-employees.component";
import { AddEmployeeTasksComponent } from "./onboarding/employee-tasks/add-task/add-tasks.component";
import { EditEmployeeTasksComponent } from "./onboarding/employee-tasks/edit-task/edit-tasks.component";
import { MccColorPickerModule } from "material-community-components/color-picker";
import { MDBBootstrapModule } from "angular-bootstrap-md";
import { LeaveNotificationComponent } from "./leave/configure/leave-notification/leave-notification.component";
import { GetEmployeeComponent } from "./pim/get-employee/get-employee.component";
import { NgxQRCodeModule } from "@techiediaries/ngx-qrcode";
import { PayslipComponent } from "./payroll/payslip/payslip.component";
import { PayrollReportComponent } from "./payroll/payroll-report/payroll-report.component";
import { AttendancePolicyComponent } from "./payroll/attendance-policy/attendance-policy.component";
import { ViewClientsComponent } from "./control/view-clients/view-clients.component";
import { AddClientComponent } from "./control/add-client/add-client.component";
import { NewCustomerComponent } from "./control/new-customer/new-customer.component";

FullCalendarModule.registerPlugins([
  // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  timeGrigPlugin,
  listPlugin,
]);

@NgModule({
  declarations: [
    WorkingWeekendComponent,
    DashboardComponent,
    HrmAdminComponent,
    PimComponent,
    MyInfoComponent,
    LeaveComponent,
    DisciplineComponent,
    // ReportCatalogComponent,
    MaintainenceComponent,
    UserComponent,
    UserRoleComponent,
    EmployeeListComponent,
    AddEmployeeComponent,
    JobTitleComponent,
    JobCategoryComponent,
    PayGradeComponent,
    SalaryComponent,
    WorkShiftComponent,
    EmployeeStatusComponent,
    NationalitiesComponent,
    ReportsComponent,
    ReportingMethodComponent,
    TerminationReasonComponent,
    AssignLeaveComponent,
    ApplyLeaveComponent,
    GeneralInformationComponent,
    DocumentTemplateComponent,
    PayrollComponent,
    LeavePeriodComponent,
    HolidaysComponent,
    LeaveListComponent,
    EntitlementListComponent,
    AddEntitlementComponent,
    ManageOrganizationComponent,
    StructureComponent,
    CostCenterComponent,
    CompanyComponent,
    DisciplinaryCasesComponent,
    MyActionsComponent,
    SkillsComponent,
    LicenseComponent,
    EducationComponent,
    MembershipComponent,
    LanguagesComponent,
    SocialMediaComponent,
    PersonalComponent,
    JobInfo,
    SalaryInfo,
    ContactDetails,
    EmergencyContactComponent,
    DependentComponent,
    ImmigrationComponent,
    WorkWeekComponent,
    QualificationComponent,
    DirectDepositComponent,
    LocationsComponent,
    RegionComponent,
    EmpMembershipComponent,
    LeaveTypeComponent,
    MyLeaveComponent,
    BftComponent,
    WorkingWeekendComponent,
    NotificationComponent,
    LeaveCalendarComponent,
    LeaveCComponent,
    BulkAssignComponent,
    LeaveUsageComponent,
    BradfordThresholdComponent,
    WorkWeekLeaveComponent,
    MyLeaveUsageComponent,
    BulkUpdateComponent,
    EmployeeDataExportComponent,
    OptionalFieldsComponent,
    CustomeFieldsComponent,
    AddEmployeeWizardComponent,
    PurgeRecordsComponent,
    EmployeeRecordsComponent,
    CandidateRecordsComponent,
    ViewReportComponent,
    AddEventsComponent,
    EditEventsComponent,
    AddEmployeeTasksComponent,
    EditEmployeeTasksComponent,
    EditCustomeFieldsComponent,
    LeaveDaysComponent,
    LeavePeriodsComponent,
    AddReportComponent,
    EeoFillingComponent,
    ManageDataComponent,
    AuditTrailComponent,
    LeavePercentageComponent,
    CompetencyListComponent,
    ReportToComponent,
    EditEmployeeComponent,
    ChangePasswordComponent,
    AddDocumentTemplateComponent,
    UploadImageComponent,
    // LoadingComponent
    DisciplineAdminComponent,
    DisciplinaryCasesComponent,
    MyActionsComponent,
    TrainingComponent,
    CoursesComponent,
    SessionsComponent,
    TrainingSessionComponent,
    ParticipatingSessionComponent,
    ParticipatingSessionReportComponent,
    TrainingSessionReportComponent,
    ViewCourseComponent,
    ViewSessionsComponent,
    ViewParticipatingSessionComponent,
    MyCoursesComponent,
    MyTrainingSessionComponent,
    ViewMyCourseComponent,
    RateParticipatingSessionComponent,
    RateTrainingSessionComponent,
    MyTrainingCoursesComponent,
    ParticipatingCoursesComponent,
    CourseDetailsComponent,
    SessionDetailsComponent,
    TrainersComponent,
    SessionsEmployeesComponent,
    CourseSessionComponent,
    LeaveCalendarYearComponent,
    LeaveCalendarMonthComponent,
    LeaveCalendarWeekComponent,
    LeaveNotificationComponent,
    GetEmployeeComponent,
    PayslipComponent,
    PayrollReportComponent,
    AttendancePolicyComponent,
    ViewClientsComponent,
    AddClientComponent,
    NewCustomerComponent,
  ],
  exports: [GetEmployeeComponent],
  imports: [
    MDBBootstrapModule.forRoot(),
    LightboxModule,
    FullCalendarModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HrmSharedModule,
    CoreModule,
    NgxMaterialTimepickerModule,
    AngularEditorModule,
    MatAutocompleteModule,
    MatInputModule,
    MatSelectModule,
    SharedModule,
    SelectModule,
    MaterialModule,
    MatTreeModule,
    TreeModule,
    AnnouncementModule,
    MatTableModule,
    MatSortModule,
    MatFormFieldModule,
    TemplateModule,
    GoogleChartsModule.forRoot(),
    ImageCropperModule,
    MatMenuModule,
    NgxMatSelectSearchModule,
    MatCheckboxModule,
    MatPaginatorModule,
    ScheduleAllModule,
    RecurrenceEditorAllModule,
    NgxQRCodeModule,
    MccColorPickerModule.forRoot({
      used_colors: ["#000000", "#123456", "#777666"],
      empty_color: "",
    }),
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
    DashboardService,
    ImageUtilService,
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
    DatePipe,
    UsersService,
  ],
  entryComponents: [UploadImageComponent],
  schemas: [],
})
export class DashboardModule {}
