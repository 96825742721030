import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { DashboardService } from "../../../dashboard.service";
import { FormGroup, FormBuilder } from "@angular/forms";
import { NotificationService } from "src/app/core/services/notification.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { AuthService } from "src/app/core/services/auth.service";

@Component({
  selector: "app-work-week",
  templateUrl: "./work-week.component.html",
  styleUrls: ["./work-week.component.scss"],
})
export class WorkWeekComponent implements OnInit {
  public userList: Array<any>;
  public mondayForm: FormGroup;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public enabled = false;
  public columns = ["day", "type", "shiftIn", "shiftOut", "lunchIn", "lunchOut", "duration"];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  public workWeekList: Array<any> = [];
  type = "Working day";
  public isLoading = true;
  public workingType: Array<string> = ["Working day", "Non-Working Day"];
  @Input() employeeId: string;

  constructor(
    private service: DashboardService,
    private authService: AuthService,
    private toastr: NotificationService
  ) {}
  ngOnDestroy() {
    this.authService = null;
    this.service = null;
    this.toastr = null;
    this.sort = null;
    this.paginator = null;
    this.dataSource = null;
  }
  public permissions: any;
  public read = false;
  public write = false;
  public update = false;
  public delete = false;

  checkPermission(module, tabs): void {
    this.permissions = JSON.parse(this.authService.getPermissions());

    this.read = false;
    this.write = false;
    this.delete = false;
    this.update = false;

    if (this.permissions != undefined && this.permissions != null) {
      for (let p of this.permissions) {
        if (p.id === module) {
          if (p.modules != undefined) {
            for (let tab of p.modules) {
              if (tab.id === tabs) {
                for (let move of tab.permits) {
                  if (move.id === "u") {
                    this.update = true;
                  } else if (move.id === "r") {
                    this.read = true;
                  } else if (move.id === "d") {
                    this.delete = true;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ngOnInit() {
    this.checkPermission("pdm", "pd_em");
    this.service.getWorkWeekById(this.employeeId).subscribe(
      (res) => {
        if (res) {
          this.isLoading = true;
          this.enabled = res.data.enabled;
          this.workWeekList = res.data.days;
          for (let w of this.workWeekList) {
            w.s = false;
            w.l = false;
          }
          this.dataSource = new MatTableDataSource<any>(this.workWeekList);
          this.isLoading = false;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      },
      (error) => {
        this.toastr.error("Error in getting work week");
      }
    );
  }

  onTypeChange(row, event) {
    for (const w of this.workWeekList) {
      if (w.day === row.day) {
        w.type = event;
      }
    }
  }

  shiftInChanged(event, workWeek) {
    const shiftInTimeInMinutes = this.convertTimeToMinutes(event);
    const shiftOutTimeInMinutes = this.convertTimeToMinutes(workWeek.shiftOut);
    const lunchInTimeInMinutes = this.convertTimeToMinutes(workWeek.lunchIn);
    const lunchOutTimeInMinutes = this.convertTimeToMinutes(workWeek.lunchOut);
    const shiftTime = shiftOutTimeInMinutes - shiftInTimeInMinutes;
    const lunchTime = lunchOutTimeInMinutes - lunchInTimeInMinutes;
    const duration = shiftTime - lunchTime;
    const durationTime = this.convertMinutesToTime(duration);
    this.workWeekList.map((res) => {
      if (res.day === workWeek.day) {
        res.shiftIn = event;
        if (shiftOutTimeInMinutes < shiftInTimeInMinutes) {
          res.duration = "Invalid";
          res.s = true;
        } else if (lunchOutTimeInMinutes < lunchInTimeInMinutes) {
          res.duration = "Invalid";
        } else {
          res.duration = durationTime;
          res.s = false;
        }
        this.dataSource = new MatTableDataSource<any>(this.workWeekList);
      }
    });
  }

  shiftOutChanged(event, workWeek) {
    const shiftInTimeInMinutes = this.convertTimeToMinutes(workWeek.shiftIn);
    const shiftOutTimeInMinutes = this.convertTimeToMinutes(event);
    const lunchInTimeInMinutes = this.convertTimeToMinutes(workWeek.lunchIn);
    const lunchOutTimeInMinutes = this.convertTimeToMinutes(workWeek.lunchOut);

    const shiftTime = shiftOutTimeInMinutes - shiftInTimeInMinutes;
    const lunchTime = lunchOutTimeInMinutes - lunchInTimeInMinutes;
    const duration = shiftTime - lunchTime;
    const durationTime = this.convertMinutesToTime(duration);
    this.workWeekList.map((res) => {
      if (res.day === workWeek.day) {
        res.shiftOut = event;
        if (shiftOutTimeInMinutes < shiftInTimeInMinutes) {
          res.duration = "Invalid";
          res.s = true;
        } else if (lunchOutTimeInMinutes < lunchInTimeInMinutes) {
          res.duration = "Invalid";
        } else {
          res.duration = durationTime;
          res.s = false;
        }

        this.dataSource = new MatTableDataSource<any>(this.workWeekList);
      }
    });
  }

  lunchInChanged(event, workWeek) {
    const shiftInTimeInMinutes = this.convertTimeToMinutes(workWeek.shiftIn);
    const shiftOutTimeInMinutes = this.convertTimeToMinutes(workWeek.shiftOut);
    const lunchInTimeInMinutes = this.convertTimeToMinutes(event);
    const lunchOutTimeInMinutes = this.convertTimeToMinutes(workWeek.lunchOut);
    const shiftTime =
      shiftOutTimeInMinutes > shiftInTimeInMinutes
        ? shiftOutTimeInMinutes - shiftInTimeInMinutes
        : shiftInTimeInMinutes - shiftOutTimeInMinutes;
    const lunchTime = lunchOutTimeInMinutes - lunchInTimeInMinutes;
    const duration = shiftTime - lunchTime;
    const durationTime = this.convertMinutesToTime(duration);
    this.workWeekList.map((res) => {
      if (res.day === workWeek.day) {
        res.lunchIn = event;
        if (shiftOutTimeInMinutes < shiftInTimeInMinutes) {
          res.duration = "Invalid";
          res.s = true;
        } else if (lunchOutTimeInMinutes < lunchInTimeInMinutes) {
          res.duration = "Invalid";
        } else {
          res.duration = durationTime;
          res.s = false;
        }
        this.dataSource = new MatTableDataSource<any>(this.workWeekList);
      }
    });
  }

  lunchOutChanged(event, workWeek) {
    const shiftInTimeInMinutes = this.convertTimeToMinutes(workWeek.shiftIn);
    const shiftOutTimeInMinutes = this.convertTimeToMinutes(workWeek.shiftOut);
    const lunchInTimeInMinutes = this.convertTimeToMinutes(workWeek.lunchIn);
    const lunchOutTimeInMinutes = this.convertTimeToMinutes(event);
    const shiftTime =
      shiftOutTimeInMinutes > shiftInTimeInMinutes
        ? shiftOutTimeInMinutes - shiftInTimeInMinutes
        : shiftInTimeInMinutes - shiftOutTimeInMinutes;
    const lunchTime = lunchOutTimeInMinutes - lunchInTimeInMinutes;
    const duration = shiftTime - lunchTime;
    const durationTime = this.convertMinutesToTime(duration);
    this.workWeekList.map((res) => {
      if (res.day === workWeek.day) {
        res.lunchOut = event;
        if (shiftOutTimeInMinutes < shiftInTimeInMinutes) {
          res.duration = "Invalid";
          res.s = true;
        } else if (lunchOutTimeInMinutes < lunchInTimeInMinutes) {
          res.duration = "Invalid";
        } else {
          res.duration = durationTime;
          res.s = false;
        }
        this.dataSource = new MatTableDataSource<any>(this.workWeekList);
      }
    });
  }

  save(): void {
    for (let w of this.workWeekList) {
      if (w.duration === "Invalid") {
        this.toastr.error(
          "Please Sort Duration Problems :" +
            "\n" +
            "Shift In Must Be Before Shift Out" +
            "\n" +
            "and Lunch In must be Before Lunch Out ..!"
        );
        return;
      }
    }
    const body = {
      days: this.workWeekList,
      enabled: this.enabled,
    };
    this.service.saveWorkWeekById(this.employeeId, body).subscribe(
      (res) => {
        if (res) {
          this.toastr.success("Work Week Saved");
        }
      },
      (error) => {
        this.toastr.error("Error in saving Work Week");
      }
    );
  }

  convertTimeToMinutes(time) {
    const hourMinutes = time.split(":")[0] * 60;
    return hourMinutes + parseInt(time.split(":")[1]);
  }

  convertMinutesToTime(minutes) {
    let time = "";
    const hours = minutes / 60;
    const tempNewHour = Math.floor(hours);
    const newMinutes = (hours - tempNewHour) * 60;
    const tempMinutes = Math.round(newMinutes);

    if (tempNewHour < 10 && tempMinutes < 10) {
      time = "0" + tempNewHour + ":0" + tempMinutes;
    } else if (tempNewHour < 10) {
      time = "0" + tempNewHour + ":" + tempMinutes;
    } else if (tempMinutes < 10) {
      time = tempNewHour + ":0" + tempMinutes;
    }

    if (time.charAt(1) === "-") {
      let str = time.substr(2, time.length);
      return str;
    }

    return time;
  }
}
