<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
      <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
          <div class="col-md-8">
          <div class="page-header-title">
              <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted">Leave Calendar Configuration</h4></span>
          </div>
          </div>
          <div class="col-md-4"></div>
      </div>
      </div>
  </div>
</div>

<div class="card my-0">
  <!-- <div class="header">
    <h4 class="card-title card-title-modal">
     Leave Calendar Configuration</h4>
  </div> -->
  <div class="card-content">
  <form class="form-material full-width-inputs" action="javascript:" [formGroup]="calendarForm">
    <div class="row form-group">

      <div class="col-md-6">
        <mat-form-field class="form-element">
          <mat-label>Show Employee, Leave of Employees of</mat-label>
          <mat-select [disabled]="!update" formControlName="showEmployeesLeavesOfEmloyees" class="form-control">
              <mat-option value="sameSubunit">Same Sub Unit</mat-option>
              <mat-option value="sameLocation">Same Location</mat-option>
              <mat-option value="sameCountry">Same Country</mat-option>
              <mat-option value="sameSupervisor">Same Supervisor</mat-option>
              <mat-option value="all">Entire organization</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <mat-form-field class="form-element">
          <mat-label>Hide leave with status</mat-label>
          <mat-select [disabled]="!update" panelClass="myPanelClass" style="height: min-content"
                      disableOptionCentering
                      class="form-control"
                      formControlName="showEmployeesLeavesOfEmloyeesExclude"
                      multiple>
              <mat-select-trigger>
                  <mat-chip-list>
                      <mat-chip
                              *ngFor="let topping of calendarForm?.value?.showEmployeesLeavesOfEmloyeesExclude"
                              [selectable]="true"
                              [removable]="this.update"
                              (removed)="remove(topping , 'showEmployeesLeavesOfEmloyeesExclude')">
                          {{topping}}
                          <mat-icon matChipRemove>cancel</mat-icon>
                      </mat-chip>
                  </mat-chip-list>
              </mat-select-trigger>
              <mat-option value="Cancelled">Cancelled</mat-option>
              <mat-option value="Pending">Pending</mat-option>
              <mat-option value="Scheduled">Scheduled</mat-option>
              <mat-option value="Taken">Taken</mat-option>
              <mat-option value="Rejected">Rejected</mat-option>
              <mat-option value="Holiday">Holiday</mat-option>
              <mat-option value="Weekend">Weekend</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-6">
          <mat-checkbox [disabled]="!update" style="margin-top: 25px" class="form-control" color="primary" name="showEmployeesLeaveOfSupervisor" formControlName="showEmployeesLeaveOfSupervisor" >
            Show Employee, Leave of Supervisor(s)
          </mat-checkbox>
      </div>

        <div class="col-md-6">
            <mat-form-field class="form-element">
                <mat-label>Hide leave with status</mat-label>
                <mat-select [disabled]="!update" panelClass="myPanelClass" style="height: min-content"
                            disableOptionCentering
                            class="form-control"
                            formControlName="showEmployeesLeaveOfSupervisorExclude"
                            multiple>
                    <mat-select-trigger>
                        <mat-chip-list>
                            <mat-chip
                                    *ngFor="let topping of calendarForm?.value?.showEmployeesLeaveOfSupervisorExclude"
                                    [selectable]="true"
                                    [removable]="this.update"
                                    (removed)="remove(topping , 'showEmployeesLeaveOfSupervisorExclude')">
                                {{topping}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                        </mat-chip-list>
                    </mat-select-trigger>
                    <mat-option value="Cancelled">Cancelled</mat-option>
                    <mat-option value="Pending">Pending</mat-option>
                    <mat-option value="Scheduled">Scheduled</mat-option>
                    <mat-option value="Taken">Taken</mat-option>
                    <mat-option value="Rejected">Rejected</mat-option>
                    <mat-option value="Holiday">Holiday</mat-option>
                    <mat-option value="Weekend">Weekend</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-md-6">
            <mat-checkbox  [disabled]="!update" style="margin-top: 25px" class="form-control" color="primary" name="showSupervisorLeaveOfSuubordinate" formControlName="showSupervisorLeaveOfSuubordinate" >
                Show Supervisor Leave Of Subordinate
            </mat-checkbox>
        </div>

        <div class="col-md-6">
            <mat-form-field class="form-element">
                <mat-label>Hide leave with status</mat-label>
                <mat-select [disabled]="!update" panelClass="myPanelClass" style="height: min-content"
                            disableOptionCentering
                            class="form-control"
                            formControlName="showSupervisorLeaveOfSuubordinateExclude"
                            multiple>
                    <mat-select-trigger>
                        <mat-chip-list>
                            <mat-chip
                                    *ngFor="let topping of calendarForm?.value?.showSupervisorLeaveOfSuubordinateExclude"
                                    [selectable]="true"
                                    [removable]="this.update"
                                    (removed)="remove(topping , 'showSupervisorLeaveOfSuubordinateExclude')">
                                {{topping}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                        </mat-chip-list>
                    </mat-select-trigger>
                    <mat-option value="Cancelled">Cancelled</mat-option>
                    <mat-option value="Pending">Pending</mat-option>
                    <mat-option value="Scheduled">Scheduled</mat-option>
                    <mat-option value="Taken">Taken</mat-option>
                    <mat-option value="Rejected">Rejected</mat-option>
                    <mat-option value="Holiday">Holiday</mat-option>
                    <mat-option value="Weekend">Weekend</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <!--
        <div class="col-md-6">
            <mat-checkbox [disabled]="!update" style="margin-top: 25px" class="form-control" color="primary" name="showAdmin" formControlName="showAdmin" >
                Show Admins, Leave of Employees in their Administrative Region
            </mat-checkbox>
        </div>

        <div class="col-md-6">
            <mat-form-field class="form-element">
                <mat-label>Hide leave with status</mat-label>
                <mat-select [disabled]="!update" panelClass="myPanelClass" style="height: min-content"
                            disableOptionCentering
                            class="form-control"
                            formControlName="showAdminExclude"
                            multiple>
                    <mat-select-trigger>
                        <mat-chip-list>
                            <mat-chip
                                    *ngFor="let topping of calendarForm?.value?.showAdminExclude"
                                    [selectable]="true"
                                    [removable]="this.update"
                                    (removed)="remove(topping , 'showAdminExclude')">
                                {{topping}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                        </mat-chip-list>
                    </mat-select-trigger>
                    <mat-option value="All">All</mat-option>
                    <mat-option value="Cancelled">Cancelled</mat-option>
                    <mat-option value="Approve">Approve</mat-option>
                    <mat-option value="Pending">Pending</mat-option>
                    <mat-option value="Scheduled">Scheduled</mat-option>
                    <mat-option value="Taken">Taken</mat-option>
                    <mat-option value="Rejected">Rejected</mat-option>
                    <mat-option value="Holiday">Holiday</mat-option>
                    <mat-option value="Weekend">Weekend</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        -->

        <div class="col-md-12">
            <mat-form-field class="form-element">
                <mat-label>Exclude Leave Having: Leave Type</mat-label>
                <mat-select [disabled]="!update" panelClass="myPanelClass" style="height: min-content"
                            disableOptionCentering
                            class="form-control"
                            formControlName="excludeLeavesHavingLeaveType"
                            multiple>
                    <mat-select-trigger>
                        <mat-chip-list>
                            <mat-chip
                                    *ngFor="let topping of calendarForm?.value?.excludeLeavesHavingLeaveType"
                                    [selectable]="true"
                                    [removable]="this.update"
                                    (removed)="remove(topping , 'excludeLeavesHavingLeaveType')">
                                {{ toTable(topping) }}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                        </mat-chip-list>
                    </mat-select-trigger>
                    <mat-option *ngFor="let c of leaveType" [value]="c.id">{{c.label}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
      </div>

    <div class="row" [hidden]="!update">
      <div class="col-sm-12 text-right">
        <button mat-raised-button class="btn-primary" [disabled]="!calendarForm.valid" color="primary" (click)="submitCalendarConfig()"><i class="flaticon-diskette"></i>Save</button>
        <!-- <button type="submit" class="btn btn-save btn-primary" (click)="submitCalendarConfig()" [disabled]="!calendarForm.valid">Save</button> -->
      </div>
    </div>
  </form>
  </div>
</div>
