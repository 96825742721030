import {
    AfterViewInit,
    Component,
    TemplateRef,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewChildren,
    QueryList
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotificationService } from "src/app/core/services/notification.service";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { startWith, take, takeUntil } from 'rxjs/operators';
import { DashboardService } from '../../dashboard.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogHrmComponent } from 'src/app/dialog-hrm/dialog-hrm.component';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { saveAs } from 'file-saver-es';
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

@Component({
    selector: 'app-courses',
    templateUrl: './my-courses.component.html',
    styleUrls: ['./my-courses.component.scss']
})
export class MyCoursesComponent implements OnInit {

    public courseList: Array<any>;
    public correct = true;
    public dialogRef: any;
    public filterQuery = '';
    public dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
    public selectedNewsDocs = [];
    public isArchived: boolean = false;
    public isLoading: boolean = true;
    public err: string;
    public employeeList = [];
    public filteredOptions: Array<any>;
    public temporaryDepartment: Array<any>;
    public currencyList: Array<any> = [];
    public attacherId: string;
    public attachmentList = [];
    public fileType: string;
    public fileObj: any;
    public comment: string;
    showSpinner = false;
    public uploadedFile: any;
    public fileError: any;
    public columns = ['title', 'subunit', 'coordinator', 'date', 'company', 'status'];
    public displayColumns = ['Title', 'Subunit', 'Coordinator', 'date', 'Company', 'Status'];
    public coordinatorId = "";
    public empCtrl = new FormControl();

    @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('addCourse', { static: false }) addCourse;
    @ViewChild('deleteCourse', { static: false }) deleteCourse;
    @ViewChild('attach', { static: false }) attach;
    @ViewChild('archive', { static: false }) archive;
    @ViewChild('filterCourse', { static: false }) filterCourse;
    @ViewChild('includeArchived', { static: false }) includeArchived: HTMLInputElement;

    public isCourseAdded: boolean;
    public courseForm: FormGroup;
    public filterForm: FormGroup;
    public courseVersionList = [];

    constructor(private service: DashboardService,
        private route: Router,
        public dialog: MatDialog,
        private modalService: NgbModal,
        private fb: FormBuilder,
        private toastr: NotificationService) {

        for (let i = 1; i <= 10; i++) {
            for (let j = 0; j <= 9; j++) {
                this.courseVersionList.push(`${i}.${j}`);
            }
        }

        this.createForm();

    }

    public createForm(): void {
        this.courseForm = this.fb.group({
            title: ['', Validators.required],
            coordinatorid: ['', Validators.required],
            subUnitId: [''],
            version: [''],
            subVersion: [''],
            currencyName: [''],
            cost: [''],
            duration: [''],
            companyName: [''],
            description: ['']
        });

        this.filterForm = this.fb.group({
            title: [''],
            coordinatorId: [''],
            subUnitId: [''],
            version: [''],
            subVersion: [''],
            companyName: [''],
            includeArchived: [false],
            participantCoursesOnly:[true],
            trainerCoursesOnly:[false]
        });
    }


    ngOnInit() {
        this.getcourseList();

        this.service.getEmployeeDetailList().subscribe(resp => {
            if (resp && resp.data) {
                resp.data.map(e => {
                    this.employeeList.push({ label: e.id + ' ' + e.name, value: e.id });
                });

                this.empCtrl.valueChanges.subscribe(change => {
                    startWith('');
                    if (change == null || change == undefined) {

                        this.filteredOptions = [];
                        this.correct = true;
                    } else {

                        this.filterEmployees(change);
                    }

                });
            }
        });

        this.service.getStructureList().subscribe(resp => {
            if (resp && resp.data) {
                this.temporaryDepartment = resp.data;
            }
        });

        this.initValuesDetection();
    }

    initValuesDetection() {

        this.filterForm.controls['coordinatorId'].valueChanges.subscribe(change => {

            if (change == null || change == undefined) {
                this.correct = true;

            } else {

                this.filterEmployees(change);
            }

        });
    }

    protected filterEmployees(change: String) {
        this.filteredOptions = this.employeeList;

        if (!this.employeeList) {
            return;
        }

        // get the search keyword
        if (!change) {

            this.filteredOptions = [];
            return;
        } else {
            this.checkEmployeeChange(change);
            change = change.toLowerCase();
        }
        // filter the search
        this.filteredOptions =
            this.employeeList.filter(emp => emp.label.toLowerCase().indexOf(change) > -1);

    }

    checkEmployeeChange(change) {
        let x = this.filteredOptions.find(x => x.value === change);
        if (!x) {
            this.correct = false;
        }

    }

    public getcourseList(): void {
        this.courseList = [];
        this.isLoading = true;
        this.service.getMyCourseList().subscribe(res => {
            if (res && res.data) {
                this.isLoading = false;
                this.courseList = res.data;

                this.err = '';
                this.dataSource = new MatTableDataSource<any>(this.courseList);
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
            }
        }, error => {
            const _err = error.error && error.error.msg;
            this.isLoading = false;

            this.err = 'Error while getting Course List details';
            this.toastr.error(_err || this.err);
        });
    }

    public displayEmployeeByName(option) {
        if (!option) {
            return '';
        }
        this.correct = true;

        return this.employeeList.find(x => x.value === option).label;
    }



    exportToCsv() {
        const csv = this.courseList.map(
            row => {
                return [row?.title, row?.subUnitName, row?.coordinatorName, row?.companyName, row?.status].join(',') + '\n';
            }
        );
        csv.unshift(this.displayColumns.join(',') + '\n');
        const exportDate = new Date();
        // tslint:disable-next-line:max-line-length
        const exportFileName = `Csv-export-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}.csv`;
        saveAs(new Blob(csv, { type: 'text/csv;charset=utf-8' }), exportFileName);
    }

    saveAsPdf() {
        if (this.dataSource.filteredData.length <= 0) {
            this.toastr.error('No Data to export');
            return;
        }
        const body = [];
        for (let i = 0; i < this.dataSource.filteredData.length; i++) {
            body.push([
                this.dataSource.filteredData[i].title,
                this.dataSource.filteredData[i].subUnitName,
                this.dataSource.filteredData[i].coordinatorName,
                this.dataSource.filteredData[i].companyName,
                this.dataSource.filteredData[i].status]);
        }
        const doc = new jsPDF();
        autoTable(doc, {
            head: [this.displayColumns],
            body: body
        });
        const exportDate = new Date();
        const exportFileName = `Courses-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}`;
        doc.save(exportFileName);
    }

    onModelChange(value: string) {
        this.dataSource.filter = value.trim().toLocaleLowerCase();
    }

    addNewCourse() {
        this.courseForm.reset();
        this.dialogRef = this.dialog.open(this.addCourse, {
            width: '820px',
            height: 'auto'
        });
    }

    resetForm() {
        this.isArchived = false;
        this.filterForm.reset();
        this.getcourseList();
        this.filterForm = this.fb.group({
            title: [''],
            coordinatorId: [''],
            subUnitId: [''],
            version: [''],
            subVersion: [''],
            companyName: [''],
            includeArchived: [false],
            participantCoursesOnly:[true],
            trainerCoursesOnly:[false]
        });
        this.coordinatorId = "";
    }

    search() {

        this.filterForm.patchValue({ includeArchived: this.includeArchived.checked });
        this.isArchived = this.includeArchived.checked;

        this.filterForm.patchValue(
            {
                coordinatorId: this.coordinatorId,
            }
        );
        this.filterForm.patchValue(
            {
                title: this.filterForm.value.title.trim(),
            }
        );

        this.service.courseFilterRequest(this.filterForm.value).subscribe(res => {
            if (res.data && res) {
                this.dataSource = new MatTableDataSource<any>(res.data);
                this.dialogRef.close();
                this.toastr.success('filtered Successfully');
            }
        }, error => {
            this.toastr.error(error.error.msg || 'Error in getting the filter Request');
        });

    }

    filterCourseList() {
        this.dialogRef = this.dialog.open(this.filterCourse, {
            width: '560px',
            height: 'auto'
        });
    }

    onSelectAllClicked() {
        this.selectedNewsDocs = [];
        this.matCheckBoxes.forEach(
            item => {
                this.selectedNewsDocs.push(item.value);
                item.checked = true;
            }
        );
    }

    onDeSelectAllClicked() {
        this.selectedNewsDocs = [];
        this.matCheckBoxes.forEach(
            item => {
                item.checked = false;
            }
        );
    }

    onDeleteSelected() {
        this.dialogRef = this.dialog.open(this.deleteCourse, {
            width: '400px',
            height: 'auto'
        });
    }

    public deleteMultiple(): void {
        this.service.deleteMultipleCourse(this.selectedNewsDocs).subscribe(user => {
            if (user) {
                this.close();
                this.getcourseList();
                this.toastr.success('Course deleted !');
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error deleting Course');
        });
        this.selectedNewsDocs = [];
    }

    onArchiveClicked() {
        this.dialogRef = this.dialog.open(this.archive, {
            width: '440px',
            height: 'auto'
        });
    }

    courseArchive() {
        this.service.courseArchive(this.selectedNewsDocs).subscribe(
            response => {
                if (response) {
                    this.selectedNewsDocs = [];
                    this.close();
                    this.getcourseList();
                    this.toastr.success('Archived Successfully');
                    this.close();
                }
            },
            error => {
                this.toastr.error(`Error: ${error}`);
                this.close();
            }
        );
        this.onDeSelectAllClicked();
    }

    onNewDocSelectChange($event: MatCheckboxChange, id: any) {
        if (!this.alreadySelected($event.source) && $event.checked) {
            this.selectedNewsDocs.push($event.source.value);
        } else if (!$event.checked) {
            this.selectedNewsDocs = this.selectedNewsDocs.filter(
                value => {
                    return value !== $event.source.value;
                }
            );
        }
    }

    alreadySelected(item: MatCheckbox): boolean {
        this.selectedNewsDocs.forEach(
            value => {
                if (item === value) {
                    return true;
                }
            }
        );
        return false;
    }

    submitAddUser() {
        console.log("data : ", this.courseForm.value);
        this.isCourseAdded = true;

        const body = {
            title: this.courseForm.value.title,
            coordinatorid: this.courseForm.value.coordinatorid,
            subUnitId: this.courseForm.value.subUnitId,
            version: parseInt(this.courseForm.value.version),
            subVersion: parseInt(this.courseForm.value.subVersion),
            currencyName: this.courseForm.value.currencyName,
            cost: parseInt(this.courseForm.value.cost),
            duration: this.courseForm.value.duration,
            companyName: this.courseForm.value.companyName,
            description: this.courseForm.value.description
        };
        this.service.addCourse(this.courseForm.value).subscribe(res => {
            if (res && res.data) {
                this.isCourseAdded = false;
                this.err = '';
                this.close();
                this.courseForm.reset();
                this.toastr.success('Course added successfully');
                this.getcourseList();
            }
        }, error => {
            const _err = error.error && error.error.msg;
            this.isLoading = false;

            this.err = 'Error while adding Course List details';
            this.toastr.error(_err || this.err);
        });

    }

    public close(): void {
        this.dialogRef.close();
        this.courseForm.reset();
    }

    onAddAttachment(id) {
        this.attacherId = id;
        this.showSpinner = true;

        this.service.getAttachmentList(this.attacherId).subscribe(
            r => {
                if (r && r.data) {
                    this.attachmentList = r.data;
                    this.dialogRef = this.dialog.open(this.attach, {
                        width: '560px',
                        height: 'auto'
                    });
                }
                this.showSpinner = false;
            },
            error => {
                this.showSpinner = false;
            }
        );
        this.comment = '';
        this.fileType = '';
        this.fileObj = null;

    }

    fileChange($event, type): void {
        this.uploadedFile = undefined;
        const reader = new FileReader();
        const file = $event.target.files[0];
        this.uploadedFile = file;
        const fileName = file && file.name;
        if (file && (file.size / 1024) < 2048) {
            this.fileObj = {
                added: new Date(),
                addedBy: 'string',
                desc: this.comment,
                docType: 'dd',
                empId: this.attacherId,
                fileName: fileName,
                size: file.size,
                url: ''
            };
        } else {
            this.fileError = 'Max size 2 MB';
            this.toastr.error('Max 2Mb File allowed.');
        }
    }

    onDeleteClicked(attachment: any) {
        const ID = attachment['id'];
        this.service.deleteAttachment(ID).subscribe(
            res => {
                this.service.getAttachmentList(this.attacherId).subscribe(
                    r => {
                        this.attachmentList = r.data;
                        this.toastr.success('Deleted');
                    },
                    error => {
                        console.log(error);
                        this.toastr.error('Failed to delete');
                    }
                );
            }
        );

    }

    onDownloadClicked(attachment: any) {
        const URL = attachment['url'];
        const FILE_NAME = attachment['fileName'];
        window.open(URL);
    }

    saveAttachment() {
        if (this.fileObj !== null) {
            this.service.uploadFile(this.uploadedFile).subscribe(resp => {
                if (resp && resp.data) {
                    this.fileObj['url'] = resp.data.filename;
                    this.fileObj['desc'] = this.comment;
                    this.service.addAttachment(this.fileObj).subscribe(
                        res => {
                            this.service.getAttachmentList(this.attacherId).subscribe(
                                r => {
                                    this.attachmentList = r.data;
                                    this.toastr.success('Attachment Added');
                                    this.dialogRef.close();
                                    this.getcourseList();
                                },
                                error => {
                                    this.toastr.error('Failed to add Attachment');
                                    this.dialogRef.close();
                                }
                            );
                        },
                        error => {
                            this.toastr.error('Failed to add Attachment');
                            this.dialogRef.close();
                        }
                    );
                }
            }, error => {
                const err = error.error && error.error.msg;
                console.log(err);
                this.toastr.error('Failed to save Attachment', 'Upload Unsuccessful');
                this.dialogRef.close();
            });
        } else {
            this.toastr.error('No file Selected');
        }
    }

    viewAction(id) {
        this.route.navigate(['/training/my-courses', id]);
    }



}
