import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs/Observable';
import {map, startWith} from 'rxjs/operators';
import {DashboardService} from '../../../../dashboard.service';
import {forkJoin} from 'rxjs/index';
import { NotificationService } from "src/app/core/services/notification.service";
import * as moment from 'moment';
import {AnnouncementService} from '../../../../../service/announcement.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';


@Component({
    selector: 'app-publish-setting',
    templateUrl: './publish-setting.component.html',
    styleUrls: ['./publish-setting.component.scss']
})
export class PublishSettingComponent implements OnInit {


    isLoadForm = true;
    updateObj = null;
    isSaving = false;

    formGroup: FormGroup;
    userRole: AutoCompleteObj = {l: [], f: new FormControl(), selectedMap: {}};
    employee: AutoCompleteObj = {l: [], f: new FormControl(), selectedMap: {}};
    location: AutoCompleteObj = {l: [], f: new FormControl(), selectedMap: {}};
    emStatus: AutoCompleteObj = {l: [], f: new FormControl(), selectedMap: {}};
    jobTitle: AutoCompleteObj = {l: [], f: new FormControl(), selectedMap: {}};
    subUnitt: AutoCompleteObj = {l: [], f: new FormControl(), selectedMap: {}};

    constructor(
        private fb: FormBuilder,
        private dashboardService: DashboardService,
        private announcementService: AnnouncementService,
        private toastr: NotificationService,
        private route: Router,
        private activatedRoute: ActivatedRoute,
        private backlocation: Location
    ) {
        this.formGroup = this.fb.group({
            userRole: this.userRole.f,
            employee: this.employee.f,
            location: this.location.f,
            emStatus: this.emStatus.f,
            jobTitle: this.jobTitle.f,
            subUnitt: this.subUnitt.f,
            ptAllLocation: [false],
            ptAllUserRole: [false],
            publishDateStr: [moment().format('YYYY-MM-DD'), [Validators.required]]
        });
    }

    ngOnInit() {
        // ptEmployeeStatus
        // ptEmployees
        // ptJobTitles
        // ptLocations
        // ptSubUnits
        // ptUserRoles
    }

    public update(obj) {
        this.updateObj = obj;
        this.loadForm();
    }

    initializeForm() {
        if (this.updateObj) {
            this.formGroup.patchValue({
                publishDateStr: this.updateObj.publishDate ? '' + this.updateObj.publishDate : moment().format('YYYY-MM-DD')
            });
            this.formGroup.patchValue({ptAllLocation: this.updateObj.ptAllLocation ? true : false});
            this.formGroup.patchValue({ptAllUserRole: this.updateObj.ptAllUserRole ? true : false});
            this.updateObj.ptUserRoles && this.setIdDataInMap(this.updateObj.ptUserRoles, this.userRole);
            this.updateObj.ptEmployees && this.setIdDataInMap(this.updateObj.ptEmployees, this.employee);
            this.updateObj.ptLocations && this.setIdDataInMap(this.updateObj.ptLocations, this.location);
            this.updateObj.ptEmployeeStatus && this.setIdDataInMap(this.updateObj.ptEmployeeStatus, this.emStatus);
            this.updateObj.ptJobTitles && this.setIdDataInMap(this.updateObj.ptJobTitles, this.jobTitle);
            this.updateObj.ptSubUnits && this.setIdDataInMap(this.updateObj.ptSubUnits, this.subUnitt);
        }
    }

    setIdDataInMap(idArr: string[], aco: AutoCompleteObj) {
        const pairMap = {};
        !aco.l && aco.l.length > 0 ? {} : Object.assign(pairMap, aco.l.map((pair: Pair) => ({[pair.id]: pair})));
        idArr.filter(idVal => idVal ? true : false).forEach(idVal => {
            if (pairMap[idVal]) {
                aco.selectedMap[idVal] = pairMap[idVal];
            }
        });
    }

    isObjectEmpty = function (card) {
        return card ? Object.keys(card).length === 0 : true;
    };

    saveForm() {
        const formValue = this.formGroup.value;
        const userRoleArr = !this.userRole.selectedMap ? [] : Object.keys(this.userRole.selectedMap).map(key => key);
        const employeeArr = !this.employee.selectedMap ? [] : Object.keys(this.employee.selectedMap).map(key => key);
        const locationArr = !this.location.selectedMap ? [] : Object.keys(this.location.selectedMap).map(key => key);
        const emStatusArr = !this.emStatus.selectedMap ? [] : Object.keys(this.emStatus.selectedMap).map(key => key);
        const jobTitleArr = !this.jobTitle.selectedMap ? [] : Object.keys(this.jobTitle.selectedMap).map(key => key);
        const subUnittArr = !this.subUnitt.selectedMap ? [] : Object.keys(this.subUnitt.selectedMap).map(key => key);

        if (this.formGroup.invalid
            || (!formValue.ptAllUserRole && (!userRoleArr || userRoleArr.length < 1))
            || (!formValue.ptAllLocation && (!locationArr || locationArr.length < 1))
        ) {

            (!formValue.ptAllUserRole && (!userRoleArr || userRoleArr.length < 1)) && this.formGroup.controls.location.markAsTouched();
            (!formValue.ptAllLocation && (!locationArr || locationArr.length < 1)) && this.formGroup.controls.userRole.markAsTouched();
            this.toastr.error('Please fill required field.');
            return;
        }

        const obj = {
            id: this.updateObj.id,
            ptAllLocation: formValue.ptAllLocation ? true : false,
            ptAllUserRole: formValue.ptAllUserRole ? true : false,
            ptEmployeeStatus: emStatusArr,
            ptEmployees: employeeArr,
            ptJobTitles: jobTitleArr,
            ptLocations: locationArr,
            ptSubUnits: subUnittArr,
            ptUserRoles: userRoleArr,
            publishDate: !formValue.publishDateStr ? moment().format('YYYY-MM-DD') : moment(formValue).format('YYYY-MM-DD')
        };
        this.isSaving = true;
        this.updateObj && this.announcementService.updateNewsAndDocPublishSetting(obj).subscribe(res => {
            this.isSaving = false;
            if (res && res.data) {
                this.toastr.success('Data successfully saved.');
                Object.assign(this.updateObj, res.data);
            } else {
                this.toastr.error(res.msg);
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.isSaving = false;
            this.toastr.error(err);
        });
        this.route.navigate([`/admin/announcement/news`]);
    }

    publish() {
        this.updateObj && this.announcementService.publishSetting(this.updateObj.id,
            this.updateObj.published ? false : true).subscribe(res => {
            this.isSaving = false;
            if (res && res.data) {
                this.toastr.success('Data successfully saved.');
                this.updateObj.published = res.data.published;
            } else {
                this.toastr.error(res.msg);
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.isSaving = false;
            this.toastr.error(err);
        });
        this.route.navigate([`/admin/announcement/news`]);
    }

    vco(controllerChange: Observable<any>, options: Pair[], selectedMap: {}): Observable<any> {
        return controllerChange.pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name ? this._filter(options, name, selectedMap) : options.filter(t => !selectedMap[t.id]).slice())
        );
    }

    displayFn(user?: Pair): string | undefined {
        return user ? user.name : undefined;
    }

    optionSelected($event, controller, selectedMap) {
        if ($event.option.value) {
            const value = $event.option.value;
            selectedMap[value.id] = value;
        }
        controller.setValue('');
    }

    removeSelectedItem(item, selectedMap: {}) {
        delete selectedMap[item.id];
    }

    private _filter(options: Pair[], name: string, selectedMap): Pair[] {
        const filterValue = name.toLowerCase();
        return options.filter(t => !selectedMap[t.id]).filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }

    private loadForm() {
        this.isLoadForm = true;
        const join = [
            this.dashboardService.getUserRole(),
            this.dashboardService.getEmployeeDropdownOption(),
            this.dashboardService.getLocationList(),
            this.dashboardService.getEmployeeStatusList(),
            this.dashboardService.getJobTitleList(),
            this.dashboardService.getStructureList()
        ];
        forkJoin(join).subscribe((resArr) => {
            this.isLoadForm = false;
            this.userRole.l = this.getApiData(resArr[0]).map((item => {
                return {id: item.id, name: item.name} as Pair;
            }));
            this.employee.l = this.getApiData(resArr[1]).map((item => {
                return {id: item.id, name: item.name} as Pair;
            }));
            this.location.l = this.getApiData(resArr[2]).map((item => {
                return {id: item.id, name: item.location} as Pair;
            }));
            this.emStatus.l = this.getApiData(resArr[3]).map((item => {
                return {id: item.id, name: item.name} as Pair;
            }));
            this.jobTitle.l = this.getApiData(resArr[4]).map((item => {
                return {id: item.id, name: item.title} as Pair;
            }));
            this.subUnitt.l = this.getApiData(resArr[5]).map((item => {
                return {id: item.id, name: item.name} as Pair;
            }));
            this.initializeFilter();
            this.initializeForm();
        }, error => {
            this.isLoadForm = false;
        });
    }

    private getApiData(res) {
        return (res && res.data && res.data.length > 0) ? res.data : [];
    }

    private initializeFilter() {
        this.userRole.fi = this.vco(this.userRole.f.valueChanges, this.userRole.l.slice(), this.userRole.selectedMap);
        this.employee.fi = this.vco(this.employee.f.valueChanges, this.employee.l.slice(), this.employee.selectedMap);
        this.location.fi = this.vco(this.location.f.valueChanges, this.location.l.slice(), this.location.selectedMap);
        this.emStatus.fi = this.vco(this.emStatus.f.valueChanges, this.emStatus.l.slice(), this.emStatus.selectedMap);
        this.jobTitle.fi = this.vco(this.jobTitle.f.valueChanges, this.jobTitle.l.slice(), this.jobTitle.selectedMap);
        this.subUnitt.fi = this.vco(this.subUnitt.f.valueChanges, this.subUnitt.l.slice(), this.subUnitt.selectedMap);
    }
    Back() {
        this.backlocation.back();
    }
}

interface Pair {
    name: string;
    id: string;
}

interface AutoCompleteObj {
    l: Pair[];
    f: FormControl;
    fi?: Observable<Pair[]>;
    selectedMap: {};
}

