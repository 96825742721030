<app-loading [isLoading]="this.isLoading === true"></app-loading>
<div [hidden]="isLoading" class="page-body no-title minus-top-margin-1">
    <div class="row">
        <div class="col-sm-12">
            <div class="row top-tools">
                <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6 left-tools">
          <span (click)="exportToCsv()" class="export-icon-btn csv"><i
                  class="material-icons">get_app</i><span>CSV</span>
          </span>
                    <span (click)="saveAsPdf()" class="export-icon-btn pdf"><i class="material-icons">get_app</i><span>PDF</span>
          </span>
                </div>
        <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
        <div class="relative-position search-filter-con"> 
                  <span class="icon material-icons ng-tns-c114-0"> search </span>
                        <mat-form-field>
                            <input matInput #inputSearch type="search" [(ngModel)]="filterQuery" maxlength="40"
                            (ngModelChange)="onModelChange(filterQuery)"
                            class="form-control input-sm full-data-search">    
                        </mat-form-field> 
                      <span [hidden]="!update" class="mytooltip cus-left tooltip-effect-4">
                        <span class="tooltip-item">
                            <button mat-mini-fab class="btn btn-primary light add-rounded margin-button-05" (click)="addNewUser()">
                                <i class="fa fa-plus"></i>
                            </button>
                        </span>
                        <span class="tooltip-content clearfix">
                        <span class="tooltip-text">New</span>
                        </span>
                      </span>
          </div>
        </div>
      </div>
      <app-card [title]="'Immigration'">
        <div *ngIf="dataSource" class="dt-responsive">
          <div class="table-responsive">
            <mat-card-content>
              <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="select">
                  <mat-header-cell [hidden]="!delete" *matHeaderCellDef>
                    <a [matMenuTriggerFor]="menu" class="check-all">
                      <span class="material-icons">
                        more_vert
                      </span>
                    </a>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="onSelectAllClicked()">Select All</button>
                      <button (click)="onDeSelectAllClicked()" *ngIf="selectedCheckBoxList.length > 0" mat-menu-item>
                        Deselect All
                      </button>
                      <button (click)="onDeleteSelected()" *ngIf="selectedCheckBoxList.length > 0" mat-menu-item>Delete
                        Selected
                      </button>
                    </mat-menu>
                  </mat-header-cell>
                  <mat-cell [hidden]="!delete" *matCellDef="let row">
                    <mat-checkbox #matCheckbox (change)="onCheckBoxChange($event, row.id)"
                    value="{{row.id}}"></mat-checkbox>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="docType">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Document</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.docType }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="number">
                  <mat-header-cell *matHeaderCellDef>Number</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.number }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="issuedBy">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Issued By</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.issuedBy }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="issuedDate">
                  <mat-header-cell *matHeaderCellDef >Issued Date</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.issuedDate |date : 'dd-MM-yyyy'}}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="expireDate">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Expired Date</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.expireDate |date : 'dd-MM-yyyy'}}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="attachment">
                  <mat-header-cell *matHeaderCellDef >Attachment</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <span class="mytooltip tooltip-effect-4" *ngIf="!row.hasAttachment">
                      <span class="tooltip-item"><i class="single-icon-click flaticon-attachment" style="color: #d0cdcd" (click)="onAddAttachment(row.id)"></i></span>
                      <span class="tooltip-content clearfix">
                      <span class="tooltip-text">No attachment</span>
                      </span>
                    </span>
                    
                    <span class="mytooltip tooltip-effect-4" *ngIf="row.hasAttachment">
                      <span class="tooltip-item"><i class="single-icon-click flaticon-attachment" style="color: #666" (click)="onAddAttachment(row.id)"></i></span>
                      <span class="tooltip-content clearfix">
                      <span class="tooltip-text">Include attachment</span>
                      </span>
                    </span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="edit">
                  <mat-header-cell [hidden]="!update" *matHeaderCellDef>Edit</mat-header-cell>
                  <mat-cell [hidden]="!update" *matCellDef="let row">
                      <span class="mytooltip tooltip-effect-4">
                      <span class="tooltip-item"><i (click)="editUser(row.id)"
                                                    class="single-icon-click fa fa-pencil"></i></span>
                      <span class="tooltip-content clearfix">
                      <span class="tooltip-text">Edit</span>
                      </span>
                      </span>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columns;">
                </mat-row>
              </mat-table>
              <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
              <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
            </mat-card-content>
          </div>
          <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
        </div>
      </app-card>
    </div>
  </div>
</div>


<div class="form-material full-width-inputs"  [hidden]="this.isLoading">
  <div *ngFor="let section of this.sectionsList">
      <div *ngIf="section.customFields.length != 0" class="card-sub">
          <h4>{{section.name}}</h4>
          <ng-container>
           
              <div class="row form-group">
                  <div class="col-4"  *ngFor="let custom of section.customFields; index as i">

                     
                      <mat-form-field class="form-element" *ngIf="custom.type === 'dd' ">
                          <mat-select [disabled]="!update" disableOptionCentering class="form-control"
                          [(ngModel)]="custom.value"  required="{{custom.required}}" (ngModelChange)="checkRequiredFieldsInCustomFields(section.id,section.customFields)" >
                                      
                              <ng-container>
                                  <mat-option value="">--Select--</mat-option>
                                  <mat-option *ngFor="let option of custom.options " [value]="option"  [(ngModel)]="custom.value">
                                      {{option}}
                                  </mat-option>
                              </ng-container>
                          </mat-select>
                          <mat-label>{{custom.name}}</mat-label>
                      </mat-form-field>


                      <mat-form-field class="form-element" *ngIf="custom.type === 'st' ">
                          <mat-label>{{custom.name}}</mat-label>
                          <input [readonly]="!update" matInput class="form-control"   [(ngModel)]="custom.value" required="{{custom.required}}"  (keyup)="checkRequiredFieldsInCustomFields(section.id,section.customFields)">
                      </mat-form-field>

                      <mat-form-field class="form-element" *ngIf="custom.type === 'num' ">
                          <mat-label>{{custom.name}}</mat-label>
                          <input [readonly]="!update" matInput class="form-control"   [(ngModel)]="custom.value" type="number" required="{{custom.required}}" (keyup)="checkRequiredFieldsInCustomFields(section.id,section.customFields)">
                      </mat-form-field>
                      <mat-form-field class="form-element" *ngIf="custom.type === 'dt' ">
                          <mat-label >{{custom.name}}</mat-label>
                      <input [readonly]="!update" class="form-control" matInput [matDatepicker]="i" [attr.id]="dtPicker + i" [(ngModel)]="custom.value" required="{{custom.required}}" (ngModelChange)="checkRequiredFieldsInCustomFields(section.id,section.customFields)">
                      <mat-datepicker-toggle [disabled]="!update" matSuffix [for]="i"></mat-datepicker-toggle>
                      <mat-datepicker #i></mat-datepicker>   
                    </mat-form-field>

                    <div *ngIf="custom.type === 'cb' " >
                        <h5>{{custom.name}}:</h5>
                      <div  *ngFor="let option of custom.values">
                          <mat-checkbox [disabled]="!update" [(ngModel)]="option.booleanValue" >{{option.option}}</mat-checkbox> &nbsp;
                      </div>
                      
                    </div>

               </div>

               

                  <div class="action-buttons multi-btns col-sm-12 text-right">
                      <button [disabled]="section.save" (click)="updateCustomField(section.id,section.customFields)"
                              class="btn-primary"
                              color="primary"
                              mat-raised-button><i class="flaticon-diskette"></i>Save
                      </button>
                  </div>    
              </div>   
                         
              
          </ng-container>
      </div>
     
  </div>


</div>

<ng-template #addUser let-modal>
  <div class="mat-modal-header">
  <h4 class="mat-title-modal">
    <span *ngIf="!isEdit">Add</span>
    <span *ngIf="isEdit">Edit</span>
    Immigration
  </h4>
</div>
    
    <div class="mat-modal-body">
    <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>

    <form class="form-material full-width-inputs" action="javascript:" [formGroup]="userForm" *ngIf="!isUserAdded">
      <div class="row form-group">
      <div class="col-sm-6">
        <mat-form-field class="form-element">
          <mat-select disableOptionCentering class="form-control" formControlName="docType" required>
              <mat-option value="pass">Passport</mat-option>
              <mat-option value="visa">Visa</mat-option>
          </mat-select>
          <mat-label for="des">Document Type</mat-label>
      </mat-form-field>
      </div>

        <div class="col-sm-6">
          <mat-form-field class="form-element">
            <input class="form-control" formControlName="number" matInput type="text" maxlength="40" required>
            <span class="form-bar"></span>
            <mat-label for="title">number</mat-label>
            <span *ngIf="userForm.controls.number.touched && !userForm.controls.number.valid"
                  class="validation-error">Please enter number</span>
        </mat-form-field>
        </div>

        <div class="col-sm-6" [hidden]="!requiredImmi.issuedByAvailable">
          <mat-form-field class="form-element">
            <mat-select required="{{requiredImmi.issuedByRequired}}" disableOptionCentering class="form-control" formControlName="issuedBy">
                <ng-container
                        *ngFor="let n of countryList">
                    <mat-option
                            value="{{n.name}}">{{n.name}}</mat-option>
                </ng-container>
            </mat-select>
            <mat-label for="des">Issued By</mat-label>
        </mat-form-field>
        </div>
        <div class="col-sm-6" [hidden]="!requiredImmi.issuedDateAvailable">
          <mat-form-field class="form-element">
            <input [matDatepicker]="picker"
                   class="form-control"
                   formControlName="issuedDate" matInput  required="{{requiredImmi.issuedDateRequired}}">
            <mat-datepicker-toggle [for]="picker" matSuffix>
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-label for="expiry">Issued Date
            </mat-label>
        </mat-form-field>
        </div>
        <div class="col-sm-6" [hidden]="!requiredImmi.expiryDateAvailable">
          <mat-form-field class="form-element">
            <input [matDatepicker]="picker1"
                   [min]="getMin()"
                   class="form-control" formControlName="expireDate"
                   matInput  required="{{requiredImmi.expiryDateRequired}}"
            >
            <mat-datepicker-toggle [for]="picker1" matSuffix>
            </mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
            <mat-label for="expiry">Expiry Date
            </mat-label>
        </mat-form-field>
        </div>
        <div class="col-sm-6" [hidden]="!requiredImmi.eligibleReviewDateAvailable">
          <mat-form-field class="form-element">
            <input [matDatepicker]="picker2"
                   class="form-control"
                   formControlName="reviewDate" matInput  required="{{requiredImmi.eligibleReviewDateRequired}}">
            <mat-datepicker-toggle [for]="picker2" matSuffix>
            </mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
            <mat-label for="expiry">Review Date
            </mat-label>
        </mat-form-field>
        </div>
        <div class="col-sm-6" [hidden]="!requiredImmi.eligibleStatusAvailable">
          <mat-form-field>
            <input  required="{{requiredImmi.eligibleStatusRequired}}" class="form-control" formControlName="status" matInput type="text" maxlength="40" >
            <span class="form-bar"></span>
            <mat-label for="title">Review Status</mat-label>
        </mat-form-field>
        </div>
        <div class="col-sm-6" [hidden]="!requiredImmi.commentAvailable">
          <mat-form-field>
            <input  required="{{requiredImmi.commentRequired}}" class="form-control" formControlName="comment" matInput type="text" maxlength="40" >
            <span class="form-bar"></span>
            <mat-label for="title">Comment</mat-label>
        </mat-form-field>
        </div>
      
        <div class="action-buttons multi-btns col-sm-12 text-right">
          <button mat-raised-button  class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i> Cancel</button>
        <button mat-raised-button  class="btn btn-primary" color="primary"  (click)="submitAddJobCategory()" [disabled]="!userForm.valid"><i class="flaticon-diskette"></i> Save</button>
      </div>
      </div>
    </form>
    </div>
</ng-template>




<ng-template #deleteUser let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">Delete Social Media</h4>
    </div>
    <div class="mat-modal-body">
        <div class="text-left are-u">Are you sure ?</div>
        <div class="row">
          <div class="action-buttons multi-btns col-sm-12 text-right">
            <button mat-raised-button  class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i> Cancel</button>
          <button mat-raised-button  class="btn btn-primary" color="primary" (click)="deleteJobCategory()"><i class="flaticon-diskette"></i> Save</button>
            </div>
        </div>
      </div>
    </div>
</ng-template>
<ng-template #attach let-modal>
  <div class="mat-modal-header">
    <h4 class="mat-title-modal">
      Attachment
    </h4>
  </div>
      
      <div class="mat-modal-body">
      <form class="form-material full-width-inputs">
        <div class="form-row">
          <div class="col-sm-12" [hidden]="!update">
            <div class="upload-btn-wrapper">
              <button class="upload-btn">Attach</button>
              <input (change)="fileChange($event, 'dd')" class="form-control" name="myfile" type="file">
              <div class="fileName">{{this.fileObj && this.fileObj.fileName}}<i *ngIf="fileUrl"
                                                                                class="fa fa-check success"></i>
              </div>
            </div>
            <div class="upload-btn-wrapper">
            </div>
            <div *ngIf="!fileUrl && fileError" class="file-error text-danger">{{fileError}}</div>
            <div class="help-text">Accept upto 2MB</div>
          </div>
          <div class="col-sm-12" [hidden]="!update">
            <mat-form-field class="form-element">
              <input [(ngModel)]="comment" class="form-control" matInput name="name" type="text" maxlength="40" >
              <span class="form-bar"></span>
              <mat-label for="title">Comment</mat-label>
            </mat-form-field>
          </div>
          <div class="col-sm-12 mt-1 border" style="height: 200px !important; overflow-y: auto">
            <mat-card *ngIf="this.attachmentList.length === 0">No Attachments
              Found
            </mat-card>
            <ng-container *ngFor="let attachment of attachmentList; let i = index;">
              <mat-card class="mt-2 shadow-sm">
                <mat-card-title>
                  {{attachment.fileName}}
                </mat-card-title>
                <mat-card-content>
                  <b>Added On:</b> {{attachment.added}} <br>
                  <b>Comments:</b> {{attachment.desc}}
                </mat-card-content>
                <mat-card-actions align="end">
                  <button [hidden]="!delete" (click)="onDeleteClicked(attachment)"
                          aria-label="Example icon button with a vertical three dot icon"
                          mat-icon-button>
                    <mat-icon color="warn">delete</mat-icon>
                  </button>
                  <button (click)="onDownloadClicked(attachment)"
                          aria-label="Example icon button with a vertical three dot icon"
                          mat-icon-button>
                    <mat-icon color="primary">cloud_download</mat-icon>
                  </button>
                </mat-card-actions>
              </mat-card>
            </ng-container>
          </div>
          <div class="action-buttons multi-btns col-sm-12 text-right">
            <button mat-raised-button  class="btn-danger" (click)="close()">
              <i class="flaticon-cancel"></i> Cancel</button>
          <button [hidden]="!update" mat-raised-button  class="btn btn-primary" color="primary"   (click)="saveAttachment()"><i class="flaticon-diskette"></i> Save</button>
          </div>
        </div>
      </form>
  </div>
</ng-template>



