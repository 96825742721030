<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
    <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="page-header-title">
            <span class="ng-star-inserted"></span
            ><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted">Leave Notifcations</h4></span>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</div>
<div class="page-body no-title">
  <div class="row">
    <div class="col-sm-12">
      <div class="row top-tools">
        <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6 left-tools"></div>
        <div class="col-xs-12 col-sm-12 col-md-6 right-tools" [hidden]="!this.write">
          <div class="relative-position search-filter-con">
            <span [hidden]="!write" class="mytooltip cus-left tooltip-effect-4">
              <span class="tooltip-item">
                <button mat-mini-fab class="btn btn-primary light add-rounded margin-button-05" (click)="addNewUser()">
                  <i class="fa fa-plus"></i>
                </button>
              </span>
              <span class="tooltip-content clearfix">
                <span class="tooltip-text">New</span>
              </span>
            </span>
          </div>
        </div>
      </div>

      <br />

      <app-card [title]="'Document categories'">
        <div class="dt-responsive" *ngIf="dataSource">
          <div class="table-responsive">
            <mat-card-content>
              <!-- <div class="row col-12" style="justify-content: flex-end">
                                <button raised-button class="btn btn-primary ripple light" (click)="addNewUser()"> +
                                </button>
                            </div> -->
              <mat-table mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="Select">
                  <mat-header-cell [hidden]="!delete" *matHeaderCellDef>
                    <a [matMenuTriggerFor]="menu" class="check-all">
                      <span class="material-icons"> more_horiz </span>
                    </a>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="onSelectAllClicked()">Select All</button>

                      <button
                        mat-menu-item
                        *ngIf="this.selectedNotifications.length > 0"
                        (click)="onDeSelectAllClicked()"
                      >
                        Deselect All
                      </button>
                      <button mat-menu-item *ngIf="this.selectedNotifications.length > 0" (click)="onDeleteSeclted()">
                        Delete Selected
                      </button>
                    </mat-menu>
                  </mat-header-cell>
                  <!--                                    (change)="onNewDocSelectChange($event, row.id)-->
                  <mat-cell [hidden]="!delete" *matCellDef="let row">
                    <mat-checkbox
                      [hidden]="!delete"
                      #matCheckbox
                      (change)="onCheckBoxChange($event, row.id)"
                      value="{{ row.id }}"
                    ></mat-checkbox>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="notificationName">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
                  <mat-cell (click)="editUser(row.id)" class="{{ this.editClass }}" *matCellDef="let row">
                    {{ row.notificationName }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="mailSubject">
                  <mat-header-cell *matHeaderCellDef>Subject</mat-header-cell>
                  <mat-cell (click)="editUser(row.id)" class="{{ this.editClass }}" *matCellDef="let row">
                    <!--                                        (click)="editCategory(row)"-->
                    {{ row.mailSubject }}
                  </mat-cell>
                </ng-container>
                <!--                                <ng-container matColumnDef="Delete">-->
                <!--                                    <mat-header-cell *matHeaderCellDef>Remove</mat-header-cell>-->
                <!--                                    <mat-cell *matCellDef="let row">-->
                <!--                                        <i class="fa fa-trash" (click)="deleteUserForm(row.id)"></i>-->
                <!--                                    </mat-cell>-->
                <!--                                </ng-container>-->'

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
              </mat-table>
              <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
              <mat-paginator [pageSizeOptions]="[20, 50]" showFirstLastButtons></mat-paginator>
            </mat-card-content>
          </div>
          <div class="error-msg" *ngIf="!isLoading">{{ this.errors }}</div>
        </div>
      </app-card>
    </div>
  </div>

  <ng-template #createNotification>
    <div mat-dialog-actions>
      <div class="mat-modal-header">
        <h4 class="mat-title-modal">
          <span *ngIf="!isEdit">Create</span> <span *ngIf="isEdit">Edit</span> Notification
        </h4>
      </div>
      <div class="mat-modal-body">
        <!-- <div class="card my-0">
            <div class="header">
                <h4 class="card-title card-title-modal">Create Notification</h4>
            </div>
            <div class="card-body"> -->
        <!-- scrolling-div -->
        <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>

        <form class="form-material full-width-inputs" action="javascript:" [formGroup]="form" [hidden]="isUserAdded">
          <div class="row form-group">
            <div class="col-md-6">
              <mat-form-field class="form-element">
                <mat-label>Notification Name</mat-label>
                <input
                  matInput
                  maxlength="30"
                  type="text"
                  class="form-control"
                  id="notificationName"
                  formControlName="notificationName"
                  required=""
                />
                <mat-error *ngIf="!form.controls.notificationName.valid && form.controls.notificationName.touched">
                  {{ validationError.email }}
                </mat-error>
              </mat-form-field>

              <!-- <input type="text" class="form-control" required="" id="notificationName"
                                   formControlName="notificationName"
                                   required="">
                            <span class="form-bar"></span>
                            <label class="float-label label-after">Notification Name</label>
                            <span class="validation-error"
                                  *ngIf="!form.controls.notificationName.valid && form.controls.notificationName.touched">{{ validationError.email }}</span> -->
            </div>
            <div class="col-md-6">
              <ng-container>
                <mat-form-field class="form-element">
                  <mat-label>Send On</mat-label>
                  <mat-select class="form-control" formControlName="sendOn" required="">
                    <mat-option *ngFor="let r of optionsSendOn" [value]="r.value">
                      {{ r.label }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="!form.controls.sendOn.valid && form.controls.sendOn.touched">
                    {{ validationError.required }}
                  </mat-error>
                </mat-form-field>
                <!-- <label class="float-label label-after select-label">Send On</label>
                                <ng-select [ngClass]="'ng-select'" [options]="optionsSendOn"
                                           formControlName="sendOn" required=""></ng-select> -->
              </ng-container>

              <!-- <span class="validation-error"
                                  *ngIf="!form.controls.sendOn.valid && form.controls.sendOn.touched">{{ validationError.required }}</span> -->
            </div>
            <div class="col-md-6">
              <ng-container>
                <mat-form-field class="form-element">
                  <mat-label>Leave For Next</mat-label>
                  <mat-select class="form-control" formControlName="leaveForNext" required="">
                    <mat-option *ngFor="let r of optionsNumber" [value]="r.value">
                      {{ r.label }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="!form.controls.leaveForNext.valid && form.controls.leaveForNext.touched">
                    {{ validationError.required }}
                  </mat-error>
                </mat-form-field>
                <!-- <label class="float-label label-after select-label">Leave For Next</label>
                                <ng-select [ngClass]="'ng-select'" [options]="optionsNumber"
                                           formControlName="leaveForNext" required=""></ng-select> -->
              </ng-container>

              <!-- <span class="validation-error"
                                  *ngIf="!form.controls.leaveForNext.valid && form.controls.leaveForNext.touched">{{ validationError.required }}</span> -->
            </div>
            <div class="col-md-6">
              <mat-form-field class="form-element">
                <mat-label>Mail Subject</mat-label>
                <input
                  matInput
                  maxlength="30"
                  type="text"
                  class="form-control"
                  id="notificationName"
                  formControlName="mailSubject"
                  required=""
                />
                <mat-error *ngIf="!form.controls.mailSubject.valid && form.controls.mailSubject.touched">
                  {{ validationError.email }}
                </mat-error>
              </mat-form-field>

              <!-- <input type="text" class="form-control" required="" id="mailSubject"
                                   formControlName="mailSubject"
                                   required="">
                            <span class="form-bar"></span>
                            <label class="float-label label-after">Mail Subject</label>
                            <span class="validation-error"
                                  *ngIf="!form.controls.mailSubject.valid && form.controls.mailSubject.touched">{{ validationError.email }}</span> -->
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-12">
              <mat-checkbox formControlName="includeLeaveType" #enablePassword>Include Leave Type</mat-checkbox>
            </div>
            <!-- <input style="margin-top: 5px;margin-right: 10px;" #enablePassword type="checkbox"
                               [defaultChecked]="false">
                        <label class="">Include Leave Type</label> -->
          </div>
          <br />
          <h4><b>Send to Employees with</b></h4>
          <div class="row form-group">
            <div class="col-md-4">
              <ng-container>
                <mat-form-field class="form-element">
                  <mat-label>Job Title</mat-label>
                  <mat-select class="form-control" formControlName="sendJobTitle">
                    <mat-option *ngFor="let r of optionsJobTitle" [value]="r.id">
                      {{ r.title }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <!-- <label class="float-label label-after select-label">Job Title</label>
                                <ng-select [ngClass]="'ng-select'" [options]="optionsJobTitle"
                                           formControlName="sendJobTitle"></ng-select> -->
              </ng-container>
            </div>
            <div class="col-md-4">
              <ng-container>
                <mat-form-field class="form-element">
                  <mat-label>Sub Unit</mat-label>
                  <mat-select class="form-control" formControlName="sendSubUnit">
                    <mat-option *ngFor="let r of optionsSubUnit" [value]="r.id">
                      {{ r.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <!-- <label class="float-label label-after select-label">Sub Unit</label>
                                <ng-select [ngClass]="'ng-select'" [options]="optionsSubUnit"
                                           formControlName="sendSubUnit"></ng-select> -->
              </ng-container>
            </div>
            <div class="col-md-4">
              <ng-container>
                <mat-form-field class="form-element">
                  <mat-label>Location</mat-label>
                  <mat-select class="form-control" formControlName="sendLocation">
                    <mat-option *ngFor="let r of optionsLocation" [value]="r.id">
                      {{ r.location }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <!-- <label class="float-label label-after select-label">Location</label>
                                <ng-select [ngClass]="'ng-select'" [options]="optionsLocation"
                                           formControlName="sendLocation"></ng-select> -->
              </ng-container>
            </div>
          </div>
          <br />
          <h4><b>Include Leave of Employees with</b></h4>
          <div class="row form-group">
            <div class="col-md-4">
              <ng-container>
                <mat-form-field class="form-element">
                  <mat-label>Job Title</mat-label>
                  <mat-select class="form-control" formControlName="includeJobTitle">
                    <mat-option *ngFor="let r of optionsJobTitle" [value]="r.id">
                      {{ r.title }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <!-- <label class="float-label label-after select-label">Job Title</label>
                                <ng-select [ngClass]="'ng-select'" [options]="optionsJobTitle"
                                           formControlName="includeJobTitle"></ng-select> -->
              </ng-container>
            </div>
            <div class="col-md-4">
              <ng-container>
                <mat-form-field class="form-element">
                  <mat-label>Sub Unit</mat-label>
                  <mat-select class="form-control" formControlName="includeSubUnit">
                    <mat-option *ngFor="let r of optionsSubUnit" [value]="r.id">
                      {{ r.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <!-- <label class="float-label label-after select-label">Sub Unit</label>
                                <ng-select [ngClass]="'ng-select'" [options]="optionsSubUnit"
                                           formControlName="includeSubUnit"></ng-select> -->
              </ng-container>
            </div>
            <div class="col-md-4">
              <ng-container>
                <mat-form-field class="form-element">
                  <mat-label>Location</mat-label>
                  <mat-select class="form-control" formControlName="includeLocation">
                    <mat-option *ngFor="let r of optionsLocation" [value]="r.id">
                      {{ r.location }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <!-- <label class="float-label label-after select-label">Location</label>
                                <ng-select [ngClass]="'ng-select'" [options]="optionsLocation"
                                           formControlName="includeLocation"></ng-select> -->
              </ng-container>
            </div>
          </div>
        </form>
        <br />
        <br />
        <br />
        <br />

        <div class="row">
          <div class="multi-btns col-sm-12 text-right">
            <button mat-raised-button class="btn btn-cancel btn-danger" (click)="close()">
              <i class="flaticon-cancel"></i>Cancel
            </button>
            <button
              [hidden]="!this.update || !this.isEdit"
              mat-raised-button
              class="btn btn-save btn-primary"
              color="primary"
              *ngIf="!editForm"
              [disabled]="!form.valid"
              (click)="updateNotifcation()"
            >
              <i class="flaticon-diskette"></i>Save
            </button>
            <button
              [hidden]="this.isEdit"
              mat-raised-button
              class="btn btn-save btn-primary"
              color="primary"
              *ngIf="!editForm"
              [disabled]="!form.valid"
              (click)="submitNotifcation()"
            >
              <i class="flaticon-diskette"></i>Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<ng-template #deleteUser let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">Delete Notications</h4>
    </div>
    <div class="mat-modal-body">
      <div class="text-left are-u">Are you sure ?</div>
      <div class="row">
        <div class="multi-btns col-sm-12 text-right">
          <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i>Cancel</button>
          <button mat-raised-button class="btn-primary" (click)="onDeleteAllClicked()" color="primary">
            <i class="flaticon-trash"></i>Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
