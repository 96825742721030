<div id="header-page" class="ng-star-inserted header-page">
    <div class="page-header ng-star-inserted">
        <div class="page-block ng-star-inserted">
            <div class="row align-items-center">
                <div class="col-md-8">
                    <div class="page-header-title">
                        <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4
                            class="m-b-10 ng-star-inserted">Training Session Report</h4></span>
                    </div>
                </div>
                <div class="col-md-4"></div>
            </div>
        </div>
    </div>
</div>

<div class="page-body no-title">
    <div class="row top-tools">
        <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6 left-tools">
                <span class="export-icon-btn csv" (click)="exportToCsv()"><i
                        class="material-icons">get_app</i><span>CSV</span>
                </span>
            <span (click)="saveAsPdf()" class="export-icon-btn pdf"><i
                    class="material-icons">get_app</i><span>PDF</span>
                </span>
        </div>
    </div>
</div>

<mat-expansion-panel style="margin-bottom: 25px">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <h4 class="mat-title-modal">
                Training Session Information
            </h4>

        </mat-panel-title>
        <button mat-raised-button class="hide btn-expand">Expand</button>
        <button mat-raised-button class="hide btn-collapse">Collapse</button>
    </mat-expansion-panel-header>

    <form [formGroup]="reportForm" action="javascript:" class="form-material full-width-inputs">
        <div class="row form-group">
            <div class="col-6">
                <mat-form-field class="form-element">
                    <mat-label for="des">Trainer's Name</mat-label>
                    <mat-select style="height: min-content" disableOptionCentering class="form-control"
                                formControlName="participantsIds" multiple
                                disableOptionCentering>
                        <mat-select-trigger>
                            <mat-chip-list>
                                <mat-chip
                                        *ngFor="let topping of reportForm.value.participantsIds"
                                        [selectable]="true"
                                        [removable]="true"
                                        (removed)="remove(topping.value , 'participantsIds')">
                                    {{ topping?.label }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </mat-select-trigger>
                        <ng-container>
                            <mat-option
                                    *ngFor="let userRole of employeeList"
                                    [value]="userRole">{{userRole.label}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="form-element">
                    <mat-label for="des">Training Status</mat-label>
                    <mat-select style="height: min-content" disableOptionCentering class="form-control"
                                formControlName="participatedStatus" multiple
                                disableOptionCentering>
                        <mat-select-trigger>
                            <mat-chip-list>
                                <mat-chip
                                        *ngFor="let topping of reportForm.value.participatedStatus"
                                        [selectable]="true"
                                        [removable]="true"
                                        (removed)="remove(topping.value , 'participatedStatus')">
                                    {{ topping?.name }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </mat-select-trigger>
                        <ng-container>
                            <mat-option
                                    *ngFor="let userRole of participatedStatusList"
                                    [value]="userRole">{{userRole.name}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="form-element">
                    <mat-label for="des">Session Name</mat-label>
                    <mat-select style="height: min-content" disableOptionCentering class="form-control"
                                formControlName="sessionsIds" multiple
                                disableOptionCentering>
                        <mat-select-trigger>
                            <mat-chip-list>
                                <mat-chip
                                        *ngFor="let topping of reportForm.value.sessionsIds"
                                        [selectable]="true"
                                        [removable]="true"
                                        (removed)="remove(topping.id , 'sessionsIds')">
                                    {{ topping?.sessionName }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </mat-select-trigger>
                        <ng-container>
                            <mat-option
                                    *ngFor="let userRole of sessionsist"
                                    [value]="userRole">{{userRole.sessionName}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-6">

            </div>
            <div class="col-sm-6">
                <mat-form-field class="form-element">
                    <input [matDatepicker]="picker4" class="form-control"
                           formControlName="SessionStartDateFrom" matInput>
                    <mat-datepicker-toggle [for]="picker4"
                                           matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #picker4></mat-datepicker>
                    <mat-label for="des">Session Start Date From</mat-label>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="form-element">
                    <input [matDatepicker]="picker5" class="form-control"
                           formControlName="SessionStartDateTo" matInput>
                    <mat-datepicker-toggle [for]="picker5"
                                           matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #picker5></mat-datepicker>
                    <mat-label for="endDate">Session Start Date To</mat-label>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="form-element">
                    <input [matDatepicker]="picker6" class="form-control"
                           formControlName="SessionEndDateFrom" matInput>
                    <mat-datepicker-toggle [for]="picker6"
                                           matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #picker6></mat-datepicker>
                    <mat-label for="des">Session End Date From</mat-label>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="form-element">
                    <input [matDatepicker]="picker7" class="form-control"
                           formControlName="SessionEndtDateTo" matInput>
                    <mat-datepicker-toggle [for]="picker7"
                                           matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #picker7></mat-datepicker>
                    <mat-label for="endDate">Session End Date To</mat-label>
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="form-element">
                    <mat-label for="des">Delivery Methods</mat-label>
                    <mat-select style="height: min-content" disableOptionCentering class="form-control"
                                formControlName="sessionDeliveryMethods" multiple
                                disableOptionCentering>
                        <mat-select-trigger>
                            <mat-chip-list>
                                <mat-chip
                                        *ngFor="let topping of reportForm.value.sessionDeliveryMethods"
                                        [selectable]="true"
                                        [removable]="true"
                                        (removed)="remove(topping.value , 'sessionDeliveryMethods')">
                                    {{ topping?.name }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </mat-select-trigger>
                        <ng-container>
                            <mat-option
                                    *ngFor="let userRole of sessionDeliveryMethodsList"
                                    [value]="userRole">{{userRole.name}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="form-element">
                    <mat-label for="des">Session Status</mat-label>
                    <mat-select style="height: min-content" disableOptionCentering class="form-control"
                                formControlName="sessionStatus" multiple
                                disableOptionCentering>
                        <mat-select-trigger>
                            <mat-chip-list>
                                <mat-chip
                                        *ngFor="let topping of reportForm.value.sessionStatus"
                                        [selectable]="true"
                                        [removable]="true"
                                        (removed)="remove(topping.value , 'sessionStatus')">
                                    {{ topping?.name }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </mat-select-trigger>
                        <ng-container>
                            <mat-option
                                    *ngFor="let userRole of sessionStatusList"
                                    [value]="userRole">{{userRole.name}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="form-element">
                    <input [matDatepicker]="picker8" class="form-control"
                           formControlName="sessionCreatedDateFrom" matInput>
                    <mat-datepicker-toggle [for]="picker8"
                                           matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #picker8></mat-datepicker>
                    <mat-label for="des">Session Created Date From</mat-label>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="form-element">
                    <input [matDatepicker]="picker9" class="form-control"
                           formControlName="sessionCreatedDateTo" matInput>
                    <mat-datepicker-toggle [for]="picker9"
                                           matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #picker9></mat-datepicker>
                    <mat-label for="endDate">Session Created Date To</mat-label>
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="form-element">
                    <mat-label for="des">Courses</mat-label>
                    <mat-select style="height: min-content" disableOptionCentering class="form-control"
                                formControlName="courseId" multiple
                                disableOptionCentering>
                        <mat-select-trigger>
                            <mat-chip-list>
                                <mat-chip
                                        *ngFor="let topping of reportForm.value.courseId"
                                        [selectable]="true"
                                        [removable]="true"
                                        (removed)="remove(topping.id , 'courseId')">
                                    {{ topping?.title }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </mat-select-trigger>
                        <ng-container>
                            <mat-option
                                    *ngFor="let userRole of coursesList"
                                    [value]="userRole">{{userRole.title}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-6">
                <mat-form-field class="form-element">
                    <mat-label for="des">Course Version</mat-label>
                    <mat-select style="height: min-content" disableOptionCentering class="form-control"
                                formControlName="courseVersion" multiple
                                disableOptionCentering>
                        <mat-select-trigger>
                            <mat-chip-list>
                                <mat-chip
                                        *ngFor="let topping of reportForm.value.courseVersion"
                                        [selectable]="true"
                                        [removable]="true"
                                        (removed)="remove(topping , 'courseVersion')">
                                    {{ topping }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </mat-select-trigger>
                        <ng-container>
                            <mat-option
                                    *ngFor="let userRole of courseVersionList"
                                    [value]="userRole">{{userRole}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-6">
                <mat-form-field class="form-element">
                    <mat-label for="des">Course Coordinator</mat-label>
                    <mat-select style="height: min-content" disableOptionCentering class="form-control"
                                formControlName="courseCoordinator" multiple
                                disableOptionCentering>
                        <mat-select-trigger>
                            <mat-chip-list>
                                <mat-chip
                                        *ngFor="let topping of reportForm.value.courseCoordinator"
                                        [selectable]="true"
                                        [removable]="true"
                                        (removed)="remove(topping.value , 'courseCoordinator')">
                                    {{ topping?.label }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </mat-select-trigger>
                        <ng-container>
                            <mat-option
                                    *ngFor="let userRole of employeeList"
                                    [value]="userRole">{{userRole.label}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="form-element">
                    <mat-label for="des">currency</mat-label>
                    <mat-select style="height: min-content" disableOptionCentering class="form-control"
                                formControlName="currency" multiple
                                disableOptionCentering>
                        <mat-select-trigger>
                            <mat-chip-list>
                                <mat-chip
                                        *ngFor="let topping of reportForm.value.currency"
                                        [selectable]="true"
                                        [removable]="true"
                                        (removed)="remove(topping , 'currency')">
                                    {{ topping }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </mat-select-trigger>
                        <ng-container>
                            <mat-option
                                    *ngFor="let userRole of currencyList"
                                    [value]="userRole">{{userRole}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-6">
                <div class="row" style="margin-top: -30px">
                    <div class="col-6">
                        <mat-form-field class="form-element">
                            <mat-label>Min Cost</mat-label>
                            <input  class="form-control" formControlName="courseCostMin" maxlength="40"
                                    matInput  type="number">
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field class="form-element">
                            <mat-label>Max Cost</mat-label>
                            <input  class="form-control" formControlName="courseCostMax" maxlength="40"
                                    matInput  type="number">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <mat-form-field class="form-element">
                    <mat-label for="des">Course Status</mat-label>
                    <mat-select style="height: min-content" disableOptionCentering class="form-control"
                                formControlName="courseStatus" multiple
                                disableOptionCentering>
                        <mat-select-trigger>
                            <mat-chip-list>
                                <mat-chip
                                        *ngFor="let topping of reportForm.value.courseStatus"
                                        [selectable]="true"
                                        [removable]="true"
                                        (removed)="remove(topping.value , 'courseStatus')">
                                    {{ topping?.name }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </mat-select-trigger>
                        <ng-container>
                            <mat-option
                                    *ngFor="let userRole of courseStatusList"
                                    [value]="userRole">{{userRole.name}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="form-element">
                    <input [matDatepicker]="picker10" class="form-control"
                           formControlName="courseCreatedDateFrom" matInput>
                    <mat-datepicker-toggle [for]="picker10"
                                           matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #picker10></mat-datepicker>
                    <mat-label for="des">Course Created Date From</mat-label>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="form-element">
                    <input [matDatepicker]="picker11" class="form-control"
                           formControlName="courseCreatedDateTo" matInput>
                    <mat-datepicker-toggle [for]="picker11"
                                           matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #picker11></mat-datepicker>
                    <mat-label for="endDate">Course Created Date To</mat-label>
                </mat-form-field>
            </div>
        </div>
        <div class="action-buttons multi-btns col-sm-12 text-right">
            <button mat-raised-button class="btn btn-primary" color="primary"
                    [disabled]="!reportForm.valid"
                    (click)="submitAddUser()"><i class="flaticon-diskette"></i>Save
            </button>
        </div>
    </form>
</mat-expansion-panel>


<app-card>
    <div class="dt-responsive" *ngIf="dataSource">
        <div class="table-responsive">
            <mat-card-content>
                <app-loading *ngIf="isLoading" [isLoading]="isLoading"></app-loading>
                <table *ngIf="!isLoading" mat-table [dataSource]="dataSource" multiTemplateDataRows>

                    <ng-container matColumnDef="participantName">
                        <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}">Trainer's Name</th>
                        <td mat-cell *matCellDef="let element"> {{element.participantName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="No">
                        <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">Trainer's Name</th>
                    </ng-container>


                    <ng-container matColumnDef="summer">
                        <th mat-header-cell *matHeaderCellDef colspan="2">Trainer Session Information</th>
                    </ng-container>
                    <ng-container matColumnDef="participationStatus">
                        <th mat-header-cell *matHeaderCellDef>Participation Status</th>
                        <td mat-cell *matCellDef="let element">{{element?.participationStatus}}</td>
                    </ng-container>
                    <ng-container matColumnDef="sessionRating">
                        <th mat-header-cell *matHeaderCellDef>Session Rating</th>
                        <td mat-cell *matCellDef="let element">{{element?.sessionRating }}</td>
                    </ng-container>

                    <ng-container matColumnDef="session">
                        <th style="text-align: center" mat-header-cell *matHeaderCellDef colspan="7">Session
                            Information
                        </th>
                    </ng-container>

                    <ng-container matColumnDef="sessionName">
                        <th mat-header-cell *matHeaderCellDef>Session Name</th>
                        <td mat-cell *matCellDef="let element">{{element?.sessionName}}</td>
                    </ng-container>
                    <ng-container matColumnDef="startDate">
                        <th mat-header-cell *matHeaderCellDef>Start Date</th>
                        <td mat-cell *matCellDef="let element">{{element?.startDate }}</td>
                    </ng-container>

                    <ng-container matColumnDef="endDate">
                        <th mat-header-cell *matHeaderCellDef>End Date</th>
                        <td mat-cell *matCellDef="let element">{{element?.endDate}}</td>
                    </ng-container>
                    <ng-container matColumnDef="deliveryMethod">
                        <th mat-header-cell *matHeaderCellDef>Delivery Method</th>
                        <td mat-cell *matCellDef="let element">{{element?.deliveryMethod }}</td>
                    </ng-container>

                    <ng-container matColumnDef="sessionStatus">
                        <th mat-header-cell *matHeaderCellDef>Session Status</th>
                        <td mat-cell *matCellDef="let element">{{element?.sessionStatus}}</td>
                    </ng-container>
                    <ng-container matColumnDef="createdUser">
                        <th mat-header-cell *matHeaderCellDef>Created User</th>
                        <td mat-cell *matCellDef="let element">{{element?.createdUser }}</td>
                    </ng-container>

                    <ng-container matColumnDef="createdDate">
                        <th mat-header-cell *matHeaderCellDef>Created Date</th>
                        <td mat-cell *matCellDef="let element">{{element?.createdDate }}</td>
                    </ng-container>


                    <ng-container matColumnDef="course">
                        <th style="text-align: center" mat-header-cell *matHeaderCellDef colspan="10">Course
                            Information
                        </th>
                    </ng-container>

                    <ng-container matColumnDef="courseTitle">
                        <th mat-header-cell *matHeaderCellDef>Course Title</th>
                        <td mat-cell *matCellDef="let element">{{element?.courseTitle }}</td>
                    </ng-container>
                    <ng-container matColumnDef="version">
                        <th mat-header-cell *matHeaderCellDef>Version</th>
                        <td mat-cell *matCellDef="let element">{{element?.version  }}</td>
                    </ng-container>

                    <ng-container matColumnDef="courseCoordinator">
                        <th mat-header-cell *matHeaderCellDef>Coordinator</th>
                        <td mat-cell *matCellDef="let element">{{element?.courseCoordinator }}</td>
                    </ng-container>
                    <ng-container matColumnDef="subunit">
                        <th mat-header-cell *matHeaderCellDef>Subunit</th>
                        <td mat-cell *matCellDef="let element">{{element?.subunit  }}</td>
                    </ng-container>

                    <ng-container matColumnDef="company">
                        <th mat-header-cell *matHeaderCellDef>Company</th>
                        <td mat-cell *matCellDef="let element">{{element?.company }}</td>
                    </ng-container>
                    <ng-container matColumnDef="Cost">
                        <th mat-header-cell *matHeaderCellDef>Cost</th>
                        <td mat-cell *matCellDef="let element">{{element?.cost  }}</td>
                    </ng-container>

                    <ng-container matColumnDef="currency">
                        <th mat-header-cell *matHeaderCellDef>Currency</th>
                        <td mat-cell *matCellDef="let element">{{element?.currency  }}</td>
                    </ng-container>

                    <ng-container matColumnDef="courseStatus">
                        <th mat-header-cell *matHeaderCellDef>Status</th>
                        <td mat-cell *matCellDef="let element">{{element?.courseStatus  }}</td>
                    </ng-container>
                    <ng-container matColumnDef="courseCreatedUser">
                        <th mat-header-cell *matHeaderCellDef>Created User</th>
                        <td mat-cell *matCellDef="let element">{{element?.courseCreatedUser}}</td>
                    </ng-container>

                    <ng-container matColumnDef="courseCreatedDate">
                        <th mat-header-cell *matHeaderCellDef>Created Date</th>
                        <td mat-cell *matCellDef="let element">{{element?.courseCreatedDate}}</td>
                    </ng-container>


                    <tr mat-row *matHeaderRowDef="[ 'No' , 'summer' , 'session' , 'course']"></tr>
                    <tr mat-row
                        *matHeaderRowDef="displayColumns"></tr>
                    <tr mat-row
                        *matRowDef="let element; columns:displayColumns"></tr>
                </table>
            </mat-card-content>
        </div>
    </div>
</app-card>
