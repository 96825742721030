import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {DashboardService} from '../../../dashboard.service';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { NotificationService } from "src/app/core/services/notification.service";
import {Observable} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { AuthService } from 'src/app/core/services/auth.service';


@Component({
    selector: 'app-working-weekend',
    templateUrl: './working-weekend.component.html',
    styleUrls: ['./working-weekend.component.scss']
})


export class WorkingWeekendComponent implements OnInit {

    public userList: Array<any>;
    public roleList: Array<any>;
    public isLoading: boolean;
    public isUserAdded: boolean;
    public err: string;
    // public modalRef: any;
    public dialogRef: any;
    public userForm: FormGroup;
    public filterForm : FormGroup;
    public editId: string;
    public isEdit: boolean;
    public deleteId: string;
    public deleteSelected: boolean;
    public adminList = [];
    public essList = [];
    public supervisorList = [];
    public employeeList = [];
    public countryList = [];
    public activePage: number;
    public countrySelected : string = "";
    public countrySelectedFilter : string = "";
    public fullSelected : string = "FullDay"
    @ViewChild(MatSort, {static: false}) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('filterWorkWeek', {static: false}) filterWorkWeek;
    public validationError = {
        required: 'Required',
        email: 'Please provide a valid email',
        password: 'Please provide a password of atleast 8 digits'
    };
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortBy = '';
    public sortOrder = 'desc';
    public locationList: Array<any> = [];
    public locationFilterList: Array<any>;

    public regionList: Array<any>;
    @ViewChild('addUser',{static:false}) addUser;
    @ViewChild('deleteUser',{static:false}) deleteUser;
    @ViewChild('configure',{static:false}) configure;
    selectedCheckBoxList = [];
    @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
    public filteredOptions: Observable<string[]>;
    dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
   
    public columns = [ 'delete','name','date','dayType','countryName','locationName','edit'];

    constructor(private service: DashboardService, 
        private readonly authService: AuthService, 
        private dialog:MatDialog,
        private fb: FormBuilder, 
        private toastr: NotificationService) {
        this.createForm();
    }
    public permissions : any;
    public read = false;
    public write = false ;
    public update = false;
    public delete = false;
   
    checkPermission(module,tabs) : void 
    {
        this.permissions = JSON.parse(this.authService.getPermissions());
  
        this.read = false ;
        this.write = false ;
        this.delete = false ;
        this.update = false;
        if(this.permissions != undefined && this.permissions != null )
        {
            for(let p of this.permissions)
            {
               
                if(p.id === module)
                {
                   
                    if(p.modules != undefined)
                    {
                      
                        for(let tab of p.modules)
                        {
                            if(tab.id === tabs)
                            {
                              
                                for(let move of tab.permits)
                                {
                                    if(move.id === "u")
                                    {
                                        this.update = true;
                                    }
                                    else if(move.id === "r")
                                    {
                                        this.read = true;
                                    }
                                    else if(move.id === "c")
                                    {
                                      
                                        this.write = true;
                                    }
                                    else if(move.id === "d")
                                    {
                                        this.delete = true;
                                    }
                                }
                            }
                        }
                    }
                   
                }
               
            }
        }
       
  
    }
    public tableConfig = {
        userName: true,
        userRole: true,
        email: true,
        status: true,
        region: true
    };

    ngOnInit() {
        this.activePage = 1;
        this.isLoading = true;
        this.checkPermission("leave","le_ccnf");
        this.isUserAdded = false;
        this.isEdit = false;
        this.getUserList();
        // this.service.getLocationList().subscribe(resp => {
        //     if (resp && resp.data) {
        //         this.locationList = resp.data;
        //         this.locationFilterList = resp.data;
        //         this.locationList.map(l => {
        //             l['label'] = l['location'];
        //             l['value'] = l['id'];
        //         });
        //         
        //     }
        // }, error => {
        //     const err = error.error && error.error.msg;
        //     this.toastr.error(err || 'Error in getting location list');
        // });

        this.service.getCountriesList().subscribe(resp => {
            if (resp && resp.data) {
                this.countryList = resp.data;
            }
        });
    }

    public createForm(): void {
        this.userForm = this.fb.group({
            date: ['', Validators.required],
            countryISO: ['', Validators.required],
            locations: [[]],
            dayType: ['', Validators.required],
            name: ['', Validators.required],

        });

       
            this.filterForm = this.fb.group({
               countryIso :[''],
               dateFrom :[''],
               dateTo :[''],
               locations :[[]]
            });
          
    }

    OnFilterReset() {
        this.filterForm.reset();
        this.countrySelectedFilter = "";
        this.getUserList();
        this.close();
        this.toastr.success('Reseted Successfully');
      }

      Search() {
          if(this.filterForm.value.countryIso === "" || this.filterForm.value.countryIso === null)
          {
            this.filterForm.value.countryIso = null;
          }
          if(this.filterForm.value.dateFrom === "" || this.filterForm.value.dateFrom === null)
          {
            this.filterForm.value.dateFrom = null;
          }
          if(this.filterForm.value.dateTo === "" || this.filterForm.value.dateTo === null)
          {
            this.filterForm.value.dateTo = null;
          }
          if(this.filterForm.value.locations != null)
          {
            if(this.filterForm.value.locations[0] === "" || this.filterForm.value.locations[0] === undefined  )
            {
              this.filterForm.value.locations = null;
            }
          }
         
        this.service.filterWorkingWeekEnd(this.filterForm.value)
            .subscribe(resp => {
              if (resp && resp.data) {
                this.dialogRef.close();
                this.toastr.success('Working Weekend Filtered Successfully');
                this.userList = resp.data;
                this.userList.map(u => {
                    u.locationName = '';
                    if (u.locationsNames) {
                      u.locationsNames.map(v => {
                        u.locationName = u.locationName + v + ' , ' ;
                      });
                      u.locationName= u.locationName.slice(0, -2);
                    }
                   
                  });
                this.err = '';
                this.isLoading = false;
                this.dataSource = new MatTableDataSource<any>(this.userList);
                this.dataSource.paginator = this.paginator; 
                this.dataSource.sort = this.sort;
              }
            }
              , error => {
                this.toastr.error('Error while filtering Request');
              });
      }
    get f() { return this.userForm.controls; }
    
    public addNewUser() {
        this.isEdit = false;
        this.userForm.reset();
        this.locationList = [];
        this.countrySelected = "";
        this.fullSelected = "fullDay"
        this.dialogRef = this.dialog.open(this.addUser,{
            width:'560px',
            height:'auto'
        });
    }

    openfilter() {
        this.dialogRef = this.dialog.open(this.filterWorkWeek, {
          width: '560px',
          height: 'auto'
        });
        this.onCountryChangeForFilter();
      }
    onCountryChange() : void
    {
        this.userForm.value.locations = [];
        this.service.filterLocationByCountry(this.userForm.value.countryISO).subscribe(res => {
           if(res && res.data)
           {
               this.locationList = res.data;
               this.locationList.map(l => {
                l['label'] = l['location'];
                l['value'] = l['id'];
            });

           }
        
        }, error => {
          
        });
    }


    onCountryChangeForFilter() : void
    {
        this.filterForm.value.locations = [];
        this.service.filterLocationByCountry(this.filterForm.value.countryIso).subscribe(res => {
           if(res && res.data)
           {
               this.locationList = res.data;
               this.locationList.map(l => {
                l['label'] = l['location'];
                l['value'] = l['id'];
            });

           }
        
        }, error => {
          
        });
    }
    public close(): void {
        this.dialogRef.close();
    }


    
    public getUserList(): void {
        this.isLoading = true;
        this.activePage = 1;
        this.service.getWorkingWeekendsList().subscribe(data => {
            this.userList = data.data;
            this.userList.map(u => {
                u.locationName = '';
                if (u.locationsNames) {
                  u.locationsNames.map(v => {
                    u.locationName = u.locationName + v + ' , ' ;
                  });
                  u.locationName= u.locationName.slice(0, -2);
                }

                if (u.dayType === "FullDay") {
                    u.dayType = "Full Day"
                  }

                  if (u.dayType === "HalfDay") {
                    u.dayType = "Half Day"
                  }
               
              });
            this.err = '';
            this.isLoading = false;
            this.dataSource = new MatTableDataSource<any>(this.userList);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
        }, error => {
            const _err = error.error && error.error.msg;
            this.isLoading = false;

            this.err = 'Error while getting user details';
            this.toastr.error(_err || this.err);
        });
    }

    onCheckBoxChange($event: MatCheckboxChange, id: any) {
        if (!this.alreadySelected($event.source) && $event.checked) {
            this.selectedCheckBoxList.push($event.source.value);
        } else if (!$event.checked) {
            this.selectedCheckBoxList = this.selectedCheckBoxList.filter(
                value => {
                    return value !== $event.source.value;
                }
            );
        }
    }

    alreadySelected(item: MatCheckbox): boolean {
        this.selectedCheckBoxList.forEach(
            value => {
                if (item === value) {
                    return true;
                }
            }
        );
        return false;
    }

    onSelectAllClicked() {
        this.selectedCheckBoxList = [];
        this.matCheckBoxes.forEach(
            item => {
                this.selectedCheckBoxList.push(item.value);
                item.checked = true;
            }
        );
    }

    onDeSelectAllClicked() {
        this.selectedCheckBoxList = [];
        this.matCheckBoxes.forEach(
            item => {
                item.checked = false;
            }
        );
    }

    onDeleteSelected() {
        this.dialogRef = this.dialog.open(this.deleteUser, {
            width: '440px',
            height: 'auto'
        });
    }

    public add(): void {

        this.isUserAdded = true;
        this.service.addWorkingWeekends(this.userForm.value).subscribe(user => {
            this.toastr.success('Working Weekend added successfully');
            this.dialogRef.close();
            this.isUserAdded = false;
            this.getUserList();
        }, error => {
            const err = error.error && error.error.msg;
            this.isUserAdded = false;
            this.toastr.error(err || 'Error in adding Working Weekend');
        });
    }

    public submitAddUser(): void {
        if(this.userForm.valid)
        {
            if (this.isEdit) {
                this.edit();
            } else {
                this.add();
            }
        }
        else{
            this.markFormGroupTouched(this.userForm);
        }
        
    }

    private markFormGroupTouched(formGroup: FormGroup) {
        (<any>Object).values(formGroup.controls).forEach(control => {
          control.markAsTouched();
    
          if (control.controls) {
            this.markFormGroupTouched(control);
          }
        });
      }

    public editUser(id): void {
        this.isEdit = true;
        this.isUserAdded = true;
        this.dialogRef = this.dialog.open(this.addUser,{
            width:'560px',
            height:'auto'
        });
        this.service.getWorkingWeekendsById(id).subscribe(user => {
            if (user && user.data) {
                this.userForm.patchValue(user.data);
                this.countrySelected =  user.data.countryISO;
                this.fullSelected =  user.data.dayType;
                this.isUserAdded = false;
                this.onCountryChange();
                this.isLoading = false;
                this.editId = id;
                this.err = '';
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.isLoading = false;
            this.isUserAdded = false;
            this.err = 'Error while getting details';
            this.toastr.error(err || this.err);
        });
    }

    public edit(): void {
        const val = this.userForm.value;
        this.isUserAdded = true;
        this.service.editWorkingWeekends(this.editId, val).subscribe(user => {
            this.dialogRef.close();
            this.isUserAdded = false;
            this.toastr.success('Work Weekend updated successfully');
            this.getUserList();
        }, error => {
            const err = error.error && error.error.msg;
            this.isUserAdded = false;
            this.toastr.error(err || 'Error in updating Work Weekend.Please try again.');
        });
    }

    

    public deleteJobTitle(): void {
       
            this.deleteMultiple();
      
    }

  

    public deleteMultiple(): void {
        
            this.service.deleteMultipleWorkingWeekend(this.selectedCheckBoxList).subscribe(user => {
                if (user) {
                    this.close();
                    this.getUserList();
                    this.toastr.success('Working Weekend deleted successfully !');
                }
            }, error => {
                const err = error.error && error.error.msg;
                this.close();
                this.getUserList();
                this.toastr.error(err || 'Error deleting Job Title');
            });
            this.selectedCheckBoxList = [];
        
    }

    onPaginatorClick(mf) {
        if (mf) {
            this.activePage = mf.activePage;
            this.rowsOnPage = mf.rowsOnPage;
        }
    }

    exportToCsv(){

    }

    saveAsPdf(){

    }
    
    filterUserList(){

    }

    onModelChange(value: string) {
        this.dataSource.filter = value.trim().toLocaleLowerCase();
    }
    onUserChanged(user: HTMLInputElement) {
       
    }

}
