<mat-form-field class="form-element">
    <mat-label>{{title}}</mat-label>
    <br [hidden]="selectedItems && selectedItems.length" />
    <mat-chip-list #chipList [disabled] = "disabled">
      <mat-chip
        *ngFor="let item of selectedItems"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="removeItem(item.id)"
      >
        {{ item.name }}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input
        placeholder="type for hints..."
        #itemInput
        [formControl]="itemCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        [disabled] = "disabled"
      />
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addItem($event)">
      <mat-option *ngFor="let item of filteredItems | async" [value]="item">
        {{ item.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>