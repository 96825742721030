<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
    <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="page-header-title">
            <span class="ng-star-inserted"></span
            ><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted">Work Week</h4></span>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</div>


<div class="card my-0">
  <div class="card-content">
    <form class="form-material full-width-inputs" action="javascript:" [formGroup]="workForm">
      <div class="row form-group">
        <div class="col-md-6">
          <mat-form-field class="form-element">
            <mat-label>Country</mat-label>
            <mat-select
              disableOptionCentering
              (valueChange)="getWorkWeek($event)"
              formControlName="countryCode"
              class="form-control"
            >
              <mat-option *ngFor="let n of countryList" value="{{ n.iso }}">{{ n.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </form>

    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <div class="dt-responsive" *ngIf="dataSource">
      <div class="table-responsive">
        <mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="day">
            <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-200'">Day</mat-header-cell>
            <mat-cell *matCellDef="let row" [ngClass]="'w-200'">
              {{ row.day }}
              <br />
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <mat-form-field class="form-element" style="width: 75%">
                <mat-select
                  [disabled]="!update"
                  disableOptionCentering
                  ngModel="{{ row.type }}"
                  (valueChange)="onTypeChange(row, $event)"
                >
                  <mat-option *ngFor="let t of workingType" [value]="t">
                    {{ t }}
                  </mat-option>
                  "
                </mat-select>
              </mat-form-field>
              <br />
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="shiftIn">
            <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-90'"
              ><i class="flaticon-clock-time"> Shift In</i></mat-header-cell
            >
            <mat-cell *matCellDef="let row" [ngClass]="'w-90'">
              <ng-container *ngIf="row.type !== 'Non-Working Day'">
                <mat-form-field class="form-element" style="width: 50%">
                  <input
                    [readonly]="!update"
                    (ngModelChange)="shiftInChanged($event, row)"
                    [format]="24"
                    [ngxTimepicker]="picker"
                    matInput
                    ngModel="{{ row.shiftIn }}"
                  />
                  <ngx-material-timepicker #picker></ngx-material-timepicker>
                </mat-form-field>
                <!-- <mat-error  *ngIf="row.s">Shift in should be before Shift out</mat-error>   -->
              </ng-container>
              <br />
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="shiftOut">
            <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-90'"
              ><i class="flaticon-clock-time"> Shift Out </i></mat-header-cell
            >
            <mat-cell *matCellDef="let row" [ngClass]="'w-90'">
              <ng-container *ngIf="row.type !== 'Non-Working Day'">
                <mat-form-field class="form-element" style="width: 50%">
                  <input
                    [min]="row.shiftIn"
                    [readonly]="!update"
                    (ngModelChange)="shiftOutChanged($event, row)"
                    [format]="24"
                    [ngxTimepicker]="picker2"
                    matInput
                    ngModel="{{ row.shiftOut }}"
                  />
                  <ngx-material-timepicker #picker2></ngx-material-timepicker>
                </mat-form-field>
                <!-- <mat-error  *ngIf="row.s">Shift out should be after  Shift in</mat-error> -->
              </ng-container>
              <br />
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="lunchIn" [ngClass]="'w-90'">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              ><i class="flaticon-clock-time">Lunch In </i></mat-header-cell
            >
            <mat-cell *matCellDef="let row" [ngClass]="'w-90'">
              <ng-container *ngIf="row.type !== 'Non-Working Day'">
                <mat-form-field class="form-element" style="width: 50%">
                  <input
                    
                    [readonly]="!update"
                    (ngModelChange)="lunchInChanged($event, row)"
                    [format]="24"
                    [ngxTimepicker]="picker3"
                    matInput
                    ngModel="{{ row.lunchIn }}"
                  />
                  <ngx-material-timepicker #picker3></ngx-material-timepicker>
                </mat-form-field>
              </ng-container>
              <br />
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="lunchOut">
            <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-90'"
              ><i class="flaticon-clock-time">Lunch Out </i>
            </mat-header-cell>
            <mat-cell *matCellDef="let row" [ngClass]="'w-90'">
              <ng-container *ngIf="row.type !== 'Non-Working Day'">
                <mat-form-field class="form-element" style="width: 50%">
                  <input
                    
                    [readonly]="!update"
                    (ngModelChange)="lunchOutChanged($event, row)"
                    [format]="24"
                    [ngxTimepicker]="picker4"
                    matInput
                    ngModel="{{ row.lunchOut }}"
                  />
                  <ngx-material-timepicker #picker4></ngx-material-timepicker>
                </mat-form-field>
              </ng-container>
              <br />
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="duration">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              ><i class="flaticon-clock-time">Duration</i>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <ng-container *ngIf="row.type !== 'Non-Working Day'">
                <mat-form-field class="form-element">
                  <input [readonly]="true" [value]="row.duration" matInput />
                </mat-form-field>
              </ng-container>
              <br />
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: columns"> </mat-row>
        </mat-table>
      </div>
    </div>

    <div class="row">
      <div [hidden]="!update" class="action-buttons multi-btns col-sm-12 text-right">
        <button mat-raised-button class="btn btn-primary" color="primary" (click)="save()">
          <i class="flaticon-diskette"></i>Save
        </button>
      </div>
    </div>
  </div>
</div>
