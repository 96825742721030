<router-outlet></router-outlet>
<!-- <div class="card" >
  <div class="card-header card-header-warning" >
    <h4 class="card-title">Discipline</h4>
    <p class="card-category">Disciplinary Cases</p>
  </div>
  <div class="card-content table-responsive table-full-width">
    <div class="add-user">
      <button raised-button class="btn btn-primary ripple light" (click)="addNewUser()"> + </button>
    </div>

    <app-loading *ngIf="isLoading && !err" [isLoading]="isLoading && !err"></app-loading>
    <table class="table table-hover" *ngIf="userList && !err && !isLoading">
      <thead>
        <th>
          <ul class="pl-0 list-s mb-0">
          <li class="nav-item dropdown">
              <a class="nav-link" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  ..
              </a>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                  <a class="dropdown-item" (click)="selectAll()">Select All</a>
                  <a class="dropdown-item" (click)="disSelectAll()">DisSelect All</a>
                  <a class="dropdown-item" (click)="deleteSelectedUser()">Delete Selected</a>
                  <a class="dropdown-item" (click)="configureTable()">Configure</a>
              </div>
          </li>
          </ul>
        </th>
        <th *ngIf="tableConfig.empId"><div (click)="sortData('empId')" class="table-heading" >
          Emp ID <i class="fa fa-sort ml-1"></i>
        </div></th>
        <th *ngIf="tableConfig.caseName"><div (click)="sortData('caseName')" class="table-heading" >
          Case Name <i class="fa fa-sort ml-1"></i>
        </div></th>
        <th *ngIf="tableConfig.desc"><div (click)="sortData('desc')" class="table-heading" >
          Desc <i class="fa fa-sort ml-1"></i>
        </div></th>
        <th *ngIf="tableConfig.createdBy"><div (click)="sortData('createdBy')" class="table-heading" >
          Created By <i class="fa fa-sort ml-1"></i>
        </div></th>
        <th *ngIf="tableConfig.createdOn"><div (click)="sortData('createdOn')" class="table-heading" >
          Created On <i class="fa fa-sort ml-1"></i>
        </div></th>
        <th *ngIf="tableConfig.action"><div (click)="sortData('action')" class="table-heading" >
          Action <i class="fa fa-sort ml-1"></i>
        </div></th>
        <th *ngIf="tableConfig.status"><div (click)="sortData('status')" class="table-heading" >
          Status <i class="fa fa-sort ml-1"></i>
        </div></th>
        <th></th>
        <th></th>
      </thead>
      <tbody>
        <tr *ngFor="let user of userList; let i = index;">
          <td class="text-center"><input type="checkbox" class="" [(ngModel)]="user.checked"></td>
          <td *ngIf="tableConfig.empId">{{ user.name }}</td>
          <td *ngIf="tableConfig.caseName" (click)="takeDisciplinaryAction(user)">{{ user.caseName }}</td>
          <td *ngIf="tableConfig.desc">{{ user.desc }}</td>
          <td *ngIf="tableConfig.createdBy">{{ user.createdByDesignation }}</td>
          <td *ngIf="tableConfig.createdOn">{{ user.createdOn }}</td>
          <td *ngIf="tableConfig.action">{{ user.action }}</td>
          <td *ngIf="tableConfig.status">{{ user.status }}</td>
          <td><i class="fa fa-edit" (click)="editUser(user)"></i></td>
          <td><i class="fa fa-trash" (click)="deleteUserForm(user.id)"></i></td>
        </tr>
      </tbody>
    </table>
    <app-pagination (getNextPage)="getNextPage($event)" [activePage]="activePage" [totalRecords]="totalRecords" *ngIf="!isLoading && totalRecords"></app-pagination>
  </div>
  <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
</div> -->

<!-- <ng-template #addUser let-modal>
    <div class="card my-0">
      <div class="header">
        <h4 class="card-title card-title-modal">
          <span *ngIf="!isEdit">Add</span>
          <span *ngIf="isEdit">Edit</span>
          User</h4>
      </div>
      <div class="card-content">
      <app-loading *ngIf="isUserAdded || (employeeList && employeeList.length < 0)" [isLoading]="isUserAdded"></app-loading>
      <form class="form-material" action="javascript:" [formGroup]="userForm" *ngIf="!isUserAdded">
        <div class="form-row">

            <div class="form-group col-md-6">
                <span class="form-bar"></span><label class="float-label" for="name" class="label-after">Case Name</label>
                <input type="email" class="form-control" id="email" placeholder="Case Name" formControlName="caseName">
                <span class="validation-error" *ngIf="!userForm.controls.caseName.valid && userForm.controls.caseName.touched">{{ validationError.required }}</span>
              </div>
              <div class="form-group col-md-6">
                  <span class="form-bar"></span><label class="float-label" for="name" class="label-after">Description</label>
                  <input type="email" class="form-control" id="email" placeholder="Description" formControlName="desc">
                  <span class="validation-error" *ngIf="!userForm.controls.desc.valid && userForm.controls.desc.touched">{{ validationError.required }}</span>
                </div>
                <div class="form-group col-md-6">
                  <ng-container>
                      <span class="form-bar"></span><label class="float-label" class="label-after">Employee Name</label>
                      <input type="text" aria-label="Number" matInput formControlName="empId" [matAutocomplete]="auto" [readonly]="isEdit" class="form-control">
                      <autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                        <option *ngFor="let option of filteredOptions | async" [value]="option">
                          {{ option.name }}
                        </option>
                      </autocomplete>
                  </ng-container>
                </div>
        </div>
        <div class="mt-4 mb-2">
          <button type="submit" class="btn btn-cancel btn-danger" (click)="close()">Cancel</button>
          <button type="submit" class="btn btn-save btn-primary" (click)="takeDisciplinaryAction()" *ngIf="isEdit">Take Disciplinary Action</button>
          <button type="submit" class="btn btn-save btn-primary" [disabled]="!userForm.valid" (click)="submitAddUser()">Save</button>
        </div>
      </form>
      </div>
    </div>
</ng-template> -->

<!-- <ng-template #deleteUser let-modal>
    <div class="card my-0">
      <div class="header">
        <h4 class="card-title card-title-modal">Delete User</h4>
      </div>
      <div class="card-content px-2 py-2">
        <div class="text-center are-u">Are you sure ?</div>
        <div class="row">
          <div class="col-sm-12 text-right">
            <button type="submit" class="btn btn-cancel btn-danger" (click)="close()">Cancel</button>
            <button type="submit" class="btn btn-save btn-primary" (click)="deleteJobTitle()">Delete</button>
          </div>
        </div>
      </div>
    </div>
</ng-template> -->

<!-- <ng-template #action let-modal>
    <div class="card my-0">
      <div class="header">
        <h4 class="card-title card-title-modal">Disciplinary Action</h4>
      </div>

      <form class="form-material" action="javascript:" [formGroup]="actionForm">
        <div class="form-row">
          <div class="form-group col-md-6">
              <span class="form-bar"></span><label class="float-label" for="name" class="label-after">Description</label>
              <input type="email" class="form-control" id="email" placeholder="Description" formControlName="description">
              <span class="validation-error" *ngIf="!actionForm.controls.description.valid && actionForm.controls.description.touched">{{ validationError.required }}</span>
          </div>
          <div class="form-group col-md-6">
            
              <label for="name" class="label-after">Link to Action</label>
              <select class="dropdown-height" formControlName="action">
                  <ng-container *ngFor="let action of actions">
                  <option [value]="action.id">{{action.name}}</option>
                  </ng-container>
              </select>
              
              <span class="validation-error" *ngIf="!actionForm.controls.action.valid && actionForm.controls.action.touched">{{ validationError.required }}</span>
          </div>
          <div class="form-group col-md-6">
            <span class="form-bar"></span><label class="float-label" for="pass" class="label-after">Due Date</label>
            <input type="date" class="form-control" id="pass" placeholder="Due Date" formControlName="dueDate">
            <span class="validation-error" *ngIf="!actionForm.controls.dueDate.valid && actionForm.controls.dueDate.touched">{{ validationError.required }}</span>
          </div>
          <div class="form-group col-md-6">
            
              <label for="name" class="label-after">Status</label>
              <select class="dropdown-height" formControlName="status">
                <option [value]="'open'">Open</option>
                <option [value]="'in_progress'">In Progress</option>
                <option [value]="'completed'">Completed</option>
              </select>
              
              <span class="validation-error" *ngIf="!actionForm.controls.action.valid && actionForm.controls.action.touched">{{ validationError.required }}</span>

          </div>
          <div class="form-group col-md-6">
            <ng-container>
                <span class="form-bar"></span><label class="float-label" class="label-after">Owner</label>
                <input type="text" aria-label="Number" matInput formControlName="owner" [matAutocomplete]="auto" class="form-control">
                <autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                  <option *ngFor="let option of filteredOptions | async" [value]="option">
                    {{ option.name }}
                  </option>
                </autocomplete>
            </ng-container>
            <span class="validation-error" *ngIf="!actionForm.controls.owner.valid && actionForm.controls.owner.touched">{{ validationError.required }}</span>


          </div>
        </div>

        <div class="mt-4 mb-2">
          <button type="submit" class="btn btn-cancel btn-danger" (click)="close()">Cancel</button>
          <button type="submit" class="btn btn-save btn-primary" [disabled]="!actionForm.valid" (click)="submitAction()">Save</button>
        </div>
      </form>
    </div>
</ng-template> -->


<!-- <ng-template #configure let-modal>
    <div class="card my-0">
      <div class="header">
        <h4 class="card-title card-title-modal">Configure</h4>
      </div>
      <div class="card-content px-2 py-2">
        <div class="row">
          <div class="col-sm-3">
            <input type="checkbox" class="" [(ngModel)]="tableConfig.userName"
            id="tuserName">
            <span class="form-bar"></span><label class="float-label" for="tuserName">User Name</label>
          </div>
          <div class="col-sm-3">
            <input type="checkbox" class="" [(ngModel)]="tableConfig.status"
            id="tstatus">
            <span class="form-bar"></span><label class="float-label" for="tstatus">Status</label>
          </div>
          <div class="col-sm-3">
            <input type="checkbox" class="" [(ngModel)]="tableConfig.region"
            id="tregion">
            <span class="form-bar"></span><label class="float-label" for="tregion">Region</label>
          </div>
          <div class="col-sm-3">
            <input type="checkbox" class="" [(ngModel)]="tableConfig.userRole"
            id="tuserRole">
            <span class="form-bar"></span><label class="float-label" for="tuserRole">User Role</label>
          </div>
          <div class="col-sm-3">
            <input type="checkbox" class="" [(ngModel)]="tableConfig.email" id="temail">
            <span class="form-bar"></span><label class="float-label" for="temail">Email</label>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-right">
            <button type="submit" class="btn btn-cancel btn-danger" (click)="close()">Ok</button>
          </div>
        </div>
      </div>
    </div>
</ng-template> -->

