import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";

import jsPDF from "jspdf";
import html2canvas from 'html2canvas';
import {AnnouncementService} from "../../../../service/announcement.service";

import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { NotificationService } from "src/app/core/services/notification.service";
import {saveAs} from 'file-saver-es';
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox';
import {MatDialog} from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { AuthService } from 'src/app/core/services/auth.service';
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
    @ViewChild('categoryForm',{static:false}) categoryForm;
    @ViewChild('deleteUserSelected',{static:false}) deleteUserSelected;
    @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
    
    public dialogRef: any;
    public modalRef: any;
    public newForm: FormGroup;

    public selectedCategories = [];

    public isEdit : boolean = false ;
    public filterQuery = '';
    dataSource: MatTableDataSource<any> =  new MatTableDataSource([]); ;
    displayedColumns = ['select','categoryId', 'name', 'total', 'Count','edit'];
    isLoading = false;
    isSaving = false;
    public filterForm : FormGroup ;
    paging = {
        pageSize: 10,
        totalRecords: 0,
        pageIndex: 0,
        pageSizeOptions: [10, 25, 50, 100]
    }
    @ViewChild(MatSort, {static: false}) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('FilterForm',{static:false}) FilterForm;
    constructor(
        private announcementService: AnnouncementService,
        private authService : AuthService ,
        private fb: FormBuilder,
        private toastr: NotificationService,
        private dialog: MatDialog
    ) {
        this.newForm = this.fb.group({
            name: '',
            id: '',
            categoryId  : ''
        });

        this.filterForm = this.fb.group({
            name: '',
            id : ''
        });
    }

    public permissions : any;
    public read = false;
    public write = false ;
    public update = false;
    public delete = false;
   
    checkPermission(module,tabs) : void 
    {
        this.permissions = JSON.parse(this.authService.getPermissions());

        this.read = false ;
        this.write = false ;
        this.delete = false ;
        this.update = false;
        if(this.permissions != undefined && this.permissions != null )
        {
            for(let p of this.permissions)
            {
               
                if(p.id === module)
                {
                   
                    if(p.modules != undefined)
                    {
                      
                        for(let tab of p.modules)
                        {
                            if(tab.id === tabs)
                            {
                              
                                for(let move of tab.permits)
                                {
                                    if(move.id === "u")
                                    {
                                        this.update = true;
                                    }
                                    else if(move.id === "r")
                                    {
                                        this.read = true;
                                    }
                                    else if(move.id === "c")
                                    {
                                      
                                        this.write = true;
                                    }
                                    else if(move.id === "d")
                                    {
                                        this.delete = true;
                                    }
                                }
                            }
                        }
                    }
                   
                }
               
            }
        }
       

    }
    ngOnInit() {
        
        this.dataSource = new MatTableDataSource([]);
        this.checkPermission("assets","assets_cat");
        this.getCategories()
    }
    public calPercentage(total,number): number | undefined {
        try {
          return (number*100) / total ;
        } catch (err) {
          return undefined;
        }
      }
    getCategories() {
        this.isLoading = true;
      
        var obj = 
        {
            categoryId : null , 
            name: null
        }
        this.announcementService.filterAssetsCategories(obj).subscribe (
            res => {
      
            if (res && res.data) {
              
                
             
                for (let cat of res.data ) 
                {
                    cat.isStock = false ;
                    cat.isDec = false ;
                    cat.isInUse = false ;
                    cat.isStole = false ;
                    cat.inStockCount = 0;
                    cat.inStockWidth = 0;
                    cat.decommissionCount = 0;
                    cat.decommissionWidth = 0;
                    cat.markStolenCount = 0;
                    cat.markStolenWidth = 0;
                    cat.inUseCount = 0;
                    cat.inUseWidth = 0;
                    cat.hideBar = false;
                    if(cat.total === 0)
                    {
                        cat.hideBar = true;
                    }
                   else if(cat.counts != undefined)
                   {
                      
               
                       for(let count of cat.counts)
                       {
                           if(count.status === "InStock")
                           {
                            cat.inStockCount = count.count;
                            cat.isStock = true;
                            cat.inStockWidth = this.calPercentage(cat.total,cat.inStockCount);
                         
                           }
                           else if(count.status === "Decommission")
                           {
                            cat.decommissionCount = count.count;
                            cat.isDec = true ;
                            cat.decommissionWidth = this.calPercentage(cat.total,cat.decommissionCount);
                            
                           }
                           else if(count.status === "MarkStolen")
                           {
                            cat.markStolenCount = count.count;
                            cat.isStole = true ;
                            cat.markStolenWidth = this.calPercentage(cat.total,cat.markStolenCount);
                           
                           }
                           else if(count.status === "InUse")
                           {
                            cat.inUseCount = count.count;
                            cat.inUseWidth = this.calPercentage(cat.total,cat.inUseCount);
                            cat.isInUse = true ;
                          
                           }
                       }
                   }
                  }
            
              
                this.dataSource = new MatTableDataSource( res.data);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                this.isLoading = false;
            }
          
        }, error => {
            const err = error.error && error.error.msg;
            this.isLoading = false;
            this.toastr.error(err || "Error while getting categories.");
        });
    }


    addBorderRadiusToBar(obj : any)
    {
       

    }

    itemClick(item) {
       this.isEdit = true ;
       this.newForm.reset();
    this.newForm.patchValue({id: item.id,categoryId : item.categoryId , name: item.name});
    this.dialogRef = this.dialog.open(this.categoryForm,{
        width:'440px',
        height:'auto'
    });
    }

    pageChange(event) {
        this.paging.pageIndex = event.pageIndex + 1;
        this.paging.pageSize = event.pageSize;
        this.getCategories();
    }

    openModel() {
        this.isEdit = false ;
        this.newForm.reset();
        
        this.dialogRef = this.dialog.open(this.categoryForm,{
            width:'440px',
            height:'auto'
        });
        this.isSaving = true ;
        this.announcementService.generateIDForAllAssets('cats').subscribe(user => {
            if (user && user.data) {
             this.newForm.patchValue({
                categoryId : user.data 
             });
             this.isSaving = false ;
             
            }
        }, error => {
            this.isSaving = false ;
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error in generating Id');
        });
    }

    closeModel() {
        this.newForm.patchValue({id: null, name: ''})
        // this.modalRef && this.modalRef.close();
        this.dialogRef.close();
    }

    public close(): void {
        if(this.dialogRef!=null)
            this.dialogRef.close();
    }

    save() {
        this.newForm.markAsTouched();
        if (this.newForm.invalid) {
            return;
        }
        this.isSaving = true;
        (this.isEdit ?
                this.announcementService.updateAssetsCategory(this.newForm.value) :
                this.announcementService.saveAssetsCategory(this.newForm.value)
        ).subscribe(res => {
            if (res && res.data) {
                if (this.isEdit) {
                    this.toastr.success("Category updated successfully")
                  
                } else {
                    this.toastr.success("Category saved successfully")
                    
                }
                this.closeModel()
                this.getCategories();
              
            }
            this.isSaving = false;
        }, error => {
            const err = error.error && error.error.msg;
            this.isSaving = false;
            (this.isEdit ?  this.toastr.error(err || "Error while editing category.") 
            :  this.toastr.error(err || "Error while saving category."));
           
        });
    }

    
    onSelectAllClicked() {
        this.selectedCategories = [];
        this.matCheckBoxes.forEach(
            item => {
                this.selectedCategories.push(item.value);
                item.checked = true;
            }
        );
    }

    onDeSelectAllClicked() {
        this.selectedCategories = [];
        this.matCheckBoxes.forEach(
            item => {
                item.checked = false;
            }
        );
    }

    public deleteMultiple(): void {
        this.announcementService.deleteCategories(this.selectedCategories).subscribe(user => {
            if (user) {
                this.close();
               this.getCategories();
                this.toastr.success('Categories deleted successfully');
            }
        }, error => {
            this.getCategories();
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error deleting Categories');
        });
        this.onDeSelectAllClicked();
        this.selectedCategories = [];
    }

    onNewDocSelectChange($event: MatCheckboxChange, id: any) {
        if (!this.alreadySelected($event.source) && $event.checked) {
            this.selectedCategories.push($event.source.value);
        } else if (!$event.checked) {
            this.selectedCategories = this.selectedCategories.filter(
                value => {
                    return value !== $event.source.value;
                }
            );
        }
    }

    alreadySelected(item: MatCheckbox): boolean {
        this.selectedCategories.forEach(
            value => {
                if (item === value) {
                    return true;
                }
            }
        );
        return false;
    }

    onDeleteSelected() {
        // this.dmodalRef = this.modalService.open(this.deleteUser);
        this.dialogRef = this.dialog.open(this.deleteUserSelected, {
            width: '440px',
            height: 'auto'
        });
    }

    
    exportToCsv() {
        const csv = this.dataSource.filteredData.map(
            row => {
                return [row.categoryId,row.name,row.total,row.inStockCount,row.inUseCount,row.decommissionCount,row.markStolenCount].join(',') + '\n';
            }
        );
        csv.unshift(['Id','Name','Total','In-stock','In-use','Decommissioned','Stolen'].join(',') + '\n');
        const exportDate = new Date();
        // tslint:disable-next-line:max-line-length
        const exportFileName = `Csv-export-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}.csv`;
        saveAs(new Blob(csv, {type: 'text/csv;charset=utf-8'}), exportFileName);
    }

    saveAsPdf() {
        if (this.dataSource.filteredData.length <= 0) {
            this.toastr.error('No Data to export');
            return;
        }
        const body = [];
        for (let i = 0; i < this.dataSource.filteredData.length; i++) {
            body.push([
           
            this.dataSource.filteredData[i].categoryId,
            this.dataSource.filteredData[i].name,
            this.dataSource.filteredData[i].total,
            this.dataSource.filteredData[i].inStockCount,
            this.dataSource.filteredData[i].inUseCount,
            this.dataSource.filteredData[i].decommissionCount,
            this.dataSource.filteredData[i].markStolenCount,
        ]);
        }
        const doc = new jsPDF();
        autoTable(doc, {
            head: [['Id','Name','Total','In-stock','In-use','Decommissioned','Stolen']],
            body: body
        });
        const exportDate = new Date();
        const exportFileName = `User-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}}`;
        doc.save(exportFileName);

    }


    onModelChange(value: string) {
        this.dataSource.filter = value.trim().toLocaleLowerCase();
    }


    openFilterForm() : void 
    {
        this.dialogRef=this.dialog.open(this.FilterForm,{
            width:'580px',
            height:'auto'
        });
    }
    onResetForm() : void 
    {
        this.filterForm.reset();
        this.getCategories();
    }

    filterVendor () : void 
    {
        this.closeModel();
        this.isLoading = true;
        this.announcementService.filterAssetsCategories(this.filterForm.value).subscribe(res => {
            this.isLoading = false;
            if (res && res.data) {
                let list = res.data;
              
                this.dataSource = new MatTableDataSource(list);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            }
          
        }, error => {
            const err = error.error && error.error.msg;
            this.isLoading = false;
            this.toastr.error(err || "Error while getting Categories.") ;
        });
    }
}

