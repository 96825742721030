<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
    <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="page-header-title">
            <span class="ng-star-inserted"></span
            ><span class="ng-star-inserted"
              ><h4 class="m-b-10 ng-star-inserted">Attendance Total Summary Report</h4></span
            >
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</div>

<div class="page-body no-title">
  <div class="row">
    <div class="col-sm-12">
      <app-card>
        <div class="dt-responsive">
          <form [formGroup]="attendanceSummaryForm" class="form-material full-width-inputs">
            <div class="row form-group">
              <div class="col-md-4" lazy-dropdown-padding>

                <app-lazy-employee-dropdown
                  title="Employee"
                  required="true"
                  multiTokens="true"
                  (employeesSelected)="updateEmployeeInForm($event)"
                ></app-lazy-employee-dropdown>
              </div>

              <div class="col-md-4">
                <app-multi-select-token
                  title="Job Title"
                  #jobTitleDropdown
                  [(selectedItems)]="selectedJobTitles"
                ></app-multi-select-token>
              </div>

              <div class="col-md-4">
                <app-multi-select-token
                  title="Sub Unit"
                  #subunitDropdown
                  [(selectedItems)]="selectedSubunits"
                ></app-multi-select-token>
              </div>
            </div>

            <div class="row form-group">
              <div class="col-md-4">
                <app-multi-select-token
                  title="Employement Status"
                  #employeeStatusDropdown
                  [(selectedItems)]="selectedEmployeeStatuses"
                ></app-multi-select-token>
              </div>

              <div class="col-md-4">
                <mat-form-field class="form-element">
                  <mat-label for="des">Date From/To</mat-label>
                  <br />
                  <mat-date-range-input required [rangePicker]="picker1">
                    <input required matStartDate formControlName="fromDate" />
                    <input required matEndDate formControlName="toDate" />
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker1></mat-date-range-picker>
                </mat-form-field>
                <!-- <mat-form-field class="form-element">
                  <input
                    matInput
                    class="form-control"
                    [matDatepicker]="picker"
                    placeholder="From *"
                    formControlName="fromDate"
                  />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field> -->
              </div>

              <!-- <div class="col-md-4">
                <mat-form-field class="form-element">
                  <input
                    matInput
                    [matDatepicker]="picker1"
                    class="form-control"
                    placeholder="To end of *"
                    formControlName="toDate"
                  />
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
              </div> -->
            </div>

            <div class="row">

              <div class="col-xs-12 col-sm-12 col-md-12 multi-btns action-buttons">
                <div><div>* Required field</div></div>
                <div class="text-align-right">
                  <button
                  mat-raised-button
                  color="primary"
                  class="btn btn-save btn-primary"
                  (click)="export()"
                  [class.spinner]="isLoading"
                  [disabled]="attendanceSummaryForm.invalid || isLoading"
                >
                  Export as CSV
                </button>                 
                  <button
                  mat-raised-button
                  color="primary"
                  class="btn btn-save btn-primary"
                  (click)="viewAttendanceSummary(true)"
                  [class.spinner]="isLoading"
                  [disabled]="attendanceSummaryForm.invalid || isLoading"
                >
                  <i class="flaticon-diskette"></i> Search
                </button>                  
                </div>
              </div>
            </div>
          </form>
        </div>
      </app-card>

      <!-- <app-loading *ngIf="isLoading" [isLoading]="isLoading"></app-loading> -->
      <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
      <div class="example-container mat-elevation-z8" *ngIf="showTable">
        <app-card>
          <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
            <ng-container matColumnDef="employeeId">
              <mat-header-cell *matHeaderCellDef >Employee Id</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.employeeId }}</mat-cell>
            </ng-container>            
            <ng-container matColumnDef="employeeName">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Employee Name</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.employeeName }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="totalDurationInMinutes">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Total Time</mat-header-cell>
              <mat-cell *matCellDef="let row">{{ row.durationHHmm}} </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
          </mat-table>

          <mat-paginator
           
            [pageSize]="pageSize"
            [pageIndex]="activePage"
            [pageSizeOptions]="pageSizeOptions"
            showFirstLastButtons
            [length]="totalRecords"
            (page)="handlePageEvent($event)"
            [disabled]="isLoading"
          >
          </mat-paginator>
        </app-card>
      </div>
    </div>
  </div>
</div>
