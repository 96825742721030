import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from '../../dashboard.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotificationService } from "src/app/core/services/notification.service";

@Component({
  selector: 'app-nationalities',
  templateUrl: './nationalities.component.html',
  styleUrls: ['./nationalities.component.scss']
})
export class NationalitiesComponent implements OnInit {

  public rowsOnPage = 10;
  public filterQuery = '';
  public userList: Array<any>;
  public isLoading: boolean;
  public isUserAdded: boolean;
  public err: string;
  public modalRef: any;
  public userForm: FormGroup;
  public isEdit: boolean;
  public editId: string;
  public deleteId: string;
  public totalRecords: number;
  public activePage: number;
  public pageSize: number;
  // public deleteSelected: boolean;
  @ViewChild('addUser',{static:false}) addUser;
  @ViewChild('deleteUser',{static:false}) deleteUser;
  constructor(private service: DashboardService, private modalService: NgbModal, private fb: FormBuilder, private toastr: NotificationService) {
    this.createForm();
  }

  ngOnInit() {
    this.activePage = 1;
    this.pageSize = 10;
    this.isLoading = true;
    this.isEdit = false;
    this.getNationalityList();
    this.isUserAdded = false;
  }

  public createForm(): void {
    this.userForm = this.fb.group({
      name: ['', Validators.required]
    });
  }

  public addNewUser() {
    this.isEdit = false;
    this.modalRef = this.modalService.open(this.addUser);
    this.userForm.reset();
  }

  public close(): void {
    this.modalRef.close();
  }

  public getNationalityList(): void {
    this.service.getNationalityList(this.activePage, this.pageSize).subscribe(user => {
      console.log(user);
      if (user && user.data) {
        this.isLoading = false;
        this.userList = user.data;
        this.userList.map(u => {
          u.checked = false;
        });
        this.totalRecords = this.userList.length;
        this.err = '';
      }
      if (user && user.paging) {
        this.totalRecords = user.paging.count || 10;
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.isLoading = false;
      this.err = 'Error while getting Nationality';
      this.toastr.error(err || this.err);
    });
  }

  public submitAddJobTitle(): void {
    if (this.isEdit) {
      this.edit();
    } else {
      this.add();
    }
  }

  public add(): void {
    this.isUserAdded = true;
    this.service.addNationality(this.userForm.value).subscribe(user => {
      this.isUserAdded = false;
      this.toastr.success('Nationality added successfully');
      this.modalRef.close();
      this.userForm.reset();
      this.getNationalityList();
    }, error => {
      const err = error.error && error.error.msg;
      this.isUserAdded = false;
      this.toastr.error(err || 'Error in adding Nationality.Please try again.');
    });
  }

  public edit(): void {
    this.isUserAdded = true;
    this.service.editNationality(this.userForm.value, this.editId).subscribe(user => {
      this.isUserAdded = false;
      this.toastr.success('Nationality updated successfully');
      this.modalRef.close();
      this.getNationalityList();
    }, error => {
      const err = error.error && error.error.msg;
      this.isUserAdded = false;
      this.toastr.error(err || 'Error in updating Nationality.Please try again.');
    });
  }

  public editUser(id): void {
    this.isEdit = true;
    this.service.getNationalityById(id).subscribe(user => {
      if (user && user.data) {
        this.userForm.patchValue(user.data);
        this.modalRef = this.modalService.open(this.addUser);
        this.editId = id;
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error getting Nationality');
    });
  }

  public deleteUserForm(id): void {
    this.deleteId = id;
    this.modalRef = this.modalService.open(this.deleteUser);
  }

  public deleteJobTitle(): void {
    this.service.deleteNationality(this.deleteId).subscribe(user => {
      if (user) {
        this.close();
        this.getNationalityList();
        this.toastr.success('Nationality deleted!');
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error deleting Nationality');
    });
  }

  public selectAll(): void {
    this.userList.map(u => {
      u.checked = true;
    });
  }

  public disSelectAll(): void {
    this.userList.map(u => {
      u.checked = false;
    });
  }

  public deleteSingle(): void {
    this.service.deleteNationality(this.deleteId).subscribe(user => {
      if (user) {
        this.close();
        this.getNationalityList();
        this.toastr.success('Nationality deleted!');
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error deleting Nationality');
    });
  }

  // public deleteMultiple(): void {
  //   const body = [];
  //   this.userList.map(u => {
  //     if (u.checked) {
  //       body.push(u.id);
  //     }
  //   });
  //   this.service.deleteMultipleNationality(body).subscribe(user => {
  //     if (user) {
  //       this.close();
  //       this.getNationalityList();
  //       this.toastr.success('Nationality deleted !');
  //     }
  //   }, error => {
  //     const err = error.error && error.error.msg;
  //     this.toastr.error(err || 'Nationality deleting User');
  //   });
  // }

  // public deleteSelectedUser(): void {
  // this.deleteSelected = true;
  //   this.modalRef = this.modalService.open(this.deleteUser);
  // }
}

