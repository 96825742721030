import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import * as moment from 'moment';
import { NotificationService } from "src/app/core/services/notification.service";
import { AuthService } from 'src/app/core/services/auth.service';
import {DashboardService} from 'src/app/dashboard/dashboard.service';

@Component({
    selector: 'app-contact',
    templateUrl: './contact-details.component.html',
    styleUrls: ['./contact-details.component.scss']
})
export class ContactDetails implements OnInit {
    public employeeForm: FormGroup;
    @Input() employeeId: string;
    public editAttachmentId: string;
    public comment: string;
    public fileType = '';
    public fileObj: any;
    public fileUrl: string;
    public fileName = '';
    public dialogRef: any;
    public deleteAttachmentId: string;
    public isLoading = true;
    public isLoadingAttachment = true;
    public requiredContactDetails : any = 
    {
      addressStreet1Available: true,
addressStreet1Required: false,
addressStreet2Available: true,
addressStreet2Required: false,
cityAvailable: true,
cityRequired: false,
countryAvailable: true,
countryRequired: false,
homeTelephoneAvailable: true,
homeTelephoneRequired: false,
landMarkAvailable: true,
landMarkRequired: false,
mobileAvailable: true,
mobileRequired: false,
otherEmailAvailabl: true,
otherEmailRequired: false,
postalCodeAvailable: true,
postalCodeRequired: false,
selected: true,
stateAvailable: true,
stateRequired: false,
workEmailAvailable: true,
workEmailRequired: false,
workTelephoneAvailable: true,
workTelephoneRequired: false,
    } ;
    public contactDetailAttachmentList: MatTableDataSource<any> = new MatTableDataSource([]);
    @ViewChild(MatSort, {static: false}) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('attach', {static: false}) attach;
    @ViewChild('editAttach', {static: false}) editAttach;
    @ViewChild('deleteAttachment', {static: false}) deleteAttachment;
    public columns = ['fileName', 'url', 'desc', 'size', 'added', 'edit', 'delete'];
    uploadedFile: any;
    fileError: string;
    CountryToDisable: any;
    isCountry: boolean;
    countryList: any;
    public sectionsList : Array<any> = [];
    public tempSectionsList : Array<any> = [];
    public saveCutomList : Array<any> = [];
    public saveCBCutomList : Array<any> = [];
    ngOnDestroy() {
      this.dialog = null ;
      this.dialogRef = null ;
      this.authService = null ;
      this.service = null ;
      this.fb = null ;
      this.toastr = null ;
      this.attach = null ;
      this.sort = null ;
      this.paginator = null ;
      this.editAttach = null ;
      this.deleteAttachment = null ;
      this.contactDetailAttachmentList = null ;
      this.employeeForm = null ;
 
    
    }
    constructor(private service: DashboardService,
        private toastr: NotificationService,
        private fb: FormBuilder,
        private dialog: MatDialog,
        private authService : AuthService) {
        this.isLoading = true;
        this.createForm();
        }
        public permissions : any;
        public read = false;
        public write = false ;
        public update = false;
        public delete = false;
      
        checkPermission(module,tabs) : void 
        {
            this.permissions = JSON.parse(this.authService.getPermissions());
      
            this.read = false ;
            this.write = false ;
            this.delete = false ;
            this.update = false;
      
            if(this.permissions != undefined && this.permissions != null )
            {
                for(let p of this.permissions)
                {
                   
                    if(p.id === module)
                    {
                       
                        if(p.modules != undefined)
                        {
                          
                            for(let tab of p.modules)
                            {
                                if(tab.id === tabs)
                                {
                                  
                                    for(let move of tab.permits)
                                    {
                                        if(move.id === "u")
                                        {
                                            this.update = true;
                                           
                                        }
                                        else if(move.id === "r")
                                        {
                                            this.read = true;
                                        }
                                       
                                        else if(move.id === "d")
                                        {
                                            this.delete = true;
                                        }
                                    }
                                }
                            }
                        }
                       
                    }
                   
                }
            }
           
      
        }
        checkRequiredFieldsInCustomFields(sectionID,customFields)
        {
          
          for(let section of this.sectionsList)
          {
            if(section.id === sectionID)
           {
            for(let custom of customFields)
            {
              if(custom.required)
              {
                if(custom.value === "" || custom.value === null || custom.value === undefined)
                {
                  section.save=true;
                  return ;
                }
              }
            }
      
            section.save=false;
           } 
          }
         
      
        }
      
        updateCustomField(sectionId ,customFields) : void
        {
          
        
        
          this.saveCutomList = [];
          this.saveCBCutomList = [];
          for(let custom of customFields)
          {
            if(custom.required)
            {
              if(custom.value === "")
              {
                this.toastr.error('Please fill the required fields');
                return ;
              }
            }
            var obj = 
            {
               fieldId : "",
               fieldValue: ""  
            }
            if(custom.type != "cb")
            {
              let id : string = custom.id;
              let value : string = custom.value;
              if(custom.type == "dt")
              {
                value = moment(custom.value).format('YYYY-MM-DD').toString();
              }
              
              obj.fieldId = id;
              obj.fieldValue = value;
              this.saveCutomList.push(obj);
            }
      
      
            if(custom.type == "cb")
            {
      
              var obj2 = 
              {
                 fieldId : "",
                 values: []
              }
              obj2.fieldId = custom.id;
              let values : Array<any> = [];
              obj2.values = values;
              for(let c of custom.values)
              {
                if(c.booleanValue)
                {
                  
                  obj2.values.push(c.option);
      
                }
              }
              this.saveCBCutomList.push(obj2);
              
            }
      
           
          }
          
          this.service.saveCustomFieldsInEditEmployee(sectionId,this.employeeId,this.saveCutomList).subscribe(res => {
            if (res) {
            
              this.service.saveCustomFieldsCheckBoxesInEditEmployee(sectionId,this.employeeId,this.saveCBCutomList).subscribe(res => {
                if (res) {
                  this.toastr.success('Updated Successfully!..');
                }
              }, error => {
                this.toastr.error('Error in  updating');
              });
            }
          }, error => {
            
          });
        }
      
    ngOnInit(): void {
      this.isLoading = true;
      this.checkPermission("pdm","pd_em");
      this.service.getRequiredFieldsBySection('cd').subscribe(
        resp => {
           
            if (resp && resp.data) {
            this.requiredContactDetails=resp.data;
            }
           
        }, error => {
         
            const err = error.error && error.error.msg;
            this.toastr.error(err);
        }
    );

      this.service.getCustomFieldsForEmployee('cd',this.employeeId).subscribe(res => {
        if (res && res.data) {
        
          this.sectionsList = res.data;

         
      
        }
    });
        this.service.getContactDetails(this.employeeId).subscribe(resp => {
                if (resp && resp.data) {
                    this.isLoading = true;
                    this.employeeForm.patchValue(resp.data);
                    this.isLoading = false;
                }
            }, error => {
                this.isLoading = false;
                this.toastr.error('Error in Getting Contact Details');

            }
        );
        this.service.getCountries().subscribe(resp => {
                if (resp && resp.data) {
                    this.countryList = resp.data;

                }
        }
        );
        this.getContactDetailAttachmentList();
    }
    public fillCountryDetail(): void {
        const val = this.employeeForm.value;
        const c = val.addresses[0].country;
        const cl = this.countryList.filter(clist => {
          return clist.name === c;
        });
        val.addresses[0].countrycode = cl[0].code;
        val.addresses[0].countryiso = cl[0].iso;
        this.employeeForm.patchValue(val);
      }
    createForm() {
        this.employeeForm = this.fb.group({
          subId: [''],
          supId: [''],
          subReport: [''],
          supReport: [''],
          id: [''],
          companyid: [''],
          addresses: this.fb.array([
            this.fb.group({
              addresslineone: [''],
              addresslinetwo: [''],
              addresslinethree: [''],
              addresslinefour: [''],
              hometelephonenumber: [''],
              worktelephonenumber: [''],
              city: [''],
              contactone: [''],
              contacttwo: [''],
              country: [''],
              countrycode: [''],
              countryiso: [''],
              id: [''],
              landmark: [''],
              mobile: [''],
              otheremail: [''],
              postalcode: [''],
              state: [''],
              type: [''],
              workemail: ['']
            })]),
        });

      }

      public addAttachment(): void {
        this.editAttachmentId = '';
        this.comment = '';
        this.fileType = '';
        this.fileObj = {};
        this.fileUrl = '';
        this.fileName = '';
        this.dialogRef = this.dialog.open(this.attach, {
          width: '440px',
          height: 'auto'
        });
      }
      updateAddressDetail() {
        this.service.updateEmployeeAddressDetail(this.employeeId, this.employeeForm.value).subscribe(res => {
          if (res) {
            this.toastr.success('Contact Details Updated Successfully!..');
          }
        }, error => {
          this.toastr.error('Error in  updating');
        });
      }
      getContactDetailAttachmentList() {
        this.service.getNewAttachmentList(this.employeeId, 'lo').subscribe(resp => {
          if (resp && resp.data) {
              this.isLoadingAttachment = true;
              this.contactDetailAttachmentList = new MatTableDataSource<any>(resp.data);
              this.contactDetailAttachmentList.data = resp.data;
              this.contactDetailAttachmentList.paginator = this.paginator;
              this.contactDetailAttachmentList.sort = this.sort;
              this.isLoadingAttachment = false;

          }
        });
      }
      public open(url): void {
        window.open(url);
      }
      public editAttachment(row): void {
        this.editAttachmentId = row.id;
        this.fileName = row.fileName;
        this.comment = row.desc;
        this.dialogRef = this.dialog.open(this.editAttach, {
          width: '440px',
          height: 'auto'
        });
      }
      deleteAttachmentSelected(id) {
        this.deleteAttachmentId = id;
        this.dialogRef = this.dialog.open(this.deleteAttachment, {
          width: '440px',
          height: 'auto'
        });
      }
      public fileChange($event, type): void {
        this.uploadedFile = undefined;
        const reader = new FileReader();
        const file = $event.target.files[0];
        this.uploadedFile = file;
        const fileName = file && file.name;
        if (file && (file.size / 1024) < 2048) {
          this.fileObj = {
            added: new Date(),
            addedBy: 'string',
            desc: this.comment,
            docType: type,
            empId: this.employeeId,
            fileName: fileName,
            size: file.size,
            section: ''
          };
        } else {
          this.fileError = 'Max size 2 MB';
          this.toastr.error('Max 2Mb File allowed.');
        }
      }
      public updateAttachment() {
        this.service.updateNewAttachmentList(this.comment, this.fileName, this.editAttachmentId).subscribe(res => {
          if (res) {

              this.getContactDetailAttachmentList();
            }
            this.toastr.success('Attachment updated Successfully.');
            this.dialogRef.close();

        }, error => {
          this.toastr.error('Error in updating attachment');
        });
      }
      public deleteAttachmentFrom(): void {
        this.service.deleteAttachment(this.deleteAttachmentId).subscribe(resp => {


            this.getContactDetailAttachmentList();

            this.dialogRef.close();
            this.toastr.success('Attachment Deleted Successfully...');

        }, error => {
          this.toastr.error('Error in deleting the Attachment');
        });
      }


      public loadAttach = false ;
      public saveAttachment(): void {
        this.loadAttach = true;
        // this is for the save attachment
        this.service.uploadFile(this.uploadedFile).subscribe(resp => {
          if (resp && resp.data) {

              this.fileObj['url'] = resp.data.filename;
            this.fileObj['desc'] = this.comment;
            this.fileObj.section = 'lo';
            this.fileObj.fileName = this.fileName;
            if (this.editAttachmentId) {

            } else {
              this.service.newAddAttachment(this.fileObj).subscribe(resp1 => {
                this.loadAttach = false;
                this.toastr.success('file added successfully!');
                this.dialogRef.close();
                  this.getContactDetailAttachmentList();
               
              });
            }
          }
        }, error => {
          this.loadAttach = false;
          const err = error.error && error.error.msg;
          this.toastr.error('error in adding file!');
        });
      }


}
