import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {DashboardService} from '../../../dashboard.service';
import {FormGroup, FormBuilder} from '@angular/forms';
import { NotificationService } from "src/app/core/services/notification.service";
import {saveAs} from 'file-saver-es';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import { LanguageConfig } from 'src/app/Language-config';
import { AuthService } from 'src/app/core/services/auth.service';
@Component({
    selector: 'app-job-title',
    templateUrl: './job-title.component.html',
    styleUrls: ['./job-title.component.scss']
})
export class JobTitleComponent implements OnInit {
    public filterQuery = '';
    public isLoading: boolean;
    public isUserAdded: boolean;
    selectedUsers: any[] = [];
    public err: string;
    // public modalRef: any;
    public userForm: FormGroup;
    public isEdit: boolean;
    public editId: string;
    public deleteId: string;
    public fileName: string;
    public fileUrl: string;
    public fileError: string;
    public replaceFile: boolean;
    public fileEditOption: string;
    public fileToEdit: string;
    public submitted = false;
    public attacherId: string;
    public attachmentList = [];
    comment: string;
    public fileType: string;
    public fileObj: any;
    public uploadedFile: any;
    // for the material dialog
    public dialogRef: any;
    @ViewChild('attach', {static: false}) attach;
    // for material data table
    dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
    public columns = ['select', 'title', 'des', 'edit'];
    public lang = new Map();
    public validationError = {
        required: 'Required',
        email: 'Please provide a valid email',
        password: 'Please provide a password of atleast 8 digits'
    };
    selectedCheckBoxList = [];
    @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
    @ViewChild('addUser', {static: false}) addUser;
    @ViewChild('deleteUser', {static: false}) deleteUser;
    @ViewChild('demo', {static: false}) demo;
    @ViewChild(MatSort, {static: false}) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    
   
    constructor(private service: DashboardService,
                // private modalService: NgbModal,
                private fb: FormBuilder,
                private dialog: MatDialog,
                private toastr: NotificationService,
                private authService : AuthService) {
        this.createForm();
        this.lang = LanguageConfig.map;
       
    }
    public permissions : any;
    public read = false;
    public write = false ;
    public update = false;
    public delete = false;
   
    checkPermission(module,tabs) : void 
    {
        this.permissions = JSON.parse(this.authService.getPermissions());

        this.read = false ;
        this.write = false ;
        this.delete = false ;
        this.update = false;
        if(this.permissions != undefined && this.permissions != null )
        {
            for(let p of this.permissions)
            {
               
                if(p.id === module)
                {
                   
                    if(p.modules != undefined)
                    {
                      
                        for(let tab of p.modules)
                        {
                            if(tab.id === tabs)
                            {
                              
                                for(let move of tab.permits)
                                {
                                    if(move.id === "u")
                                    {
                                        this.update = true;
                                    }
                                    else if(move.id === "r")
                                    {
                                        this.read = true;
                                    }
                                    else if(move.id === "c")
                                    {
                                      
                                        this.write = true;
                                    }
                                    else if(move.id === "d")
                                    {
                                        this.delete = true;
                                    }
                                }
                            }
                        }
                    }
                   
                }
               
            }
        }
       

    }
    ngOnInit() {
        this. read = false;
        this. write = false ;
        this. update = false;
        this. delete = false;
        this.checkPermission("admin","ad_jb");
        this.isLoading = true;
        this.isEdit = false;
        this.getJobTitleList();
        this.isUserAdded = false;
    }
    public createForm(): void {
        this.userForm = this.fb.group({
            title: [''],
            des: [''],
            note: [''],
            url: ['']
        });
    }

    public addNewUser() {
        this.isEdit = false;
        this.dialogRef = this.dialog.open(this.addUser, {
            width: '560px',
            height: 'auto'
        });
        // this.modalRef = this.modalService.open(this.addUser);
        this.userForm.reset();
        this.fileError = '';
        this.fileName = '';
    }

    public close(): void {
        this.dialogRef.close();
        // this.modalRef.close();
    }

    public getJobTitleList(): void {
        this.service.getJobTitleList().subscribe(user => {
            if (user && user.data) {
                this.isLoading = false;
                this.dataSource = new MatTableDataSource<any>(user.data);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                this.err = '';
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error while getting Job Title');
            this.isLoading = false;
            this.err = 'Error while getting Job Title';
        });
    }

    invalidTitle() {
        return (this.submitted && this.userForm.controls.title.errors != null);
    }

    invalidDesc() {
        return (this.submitted && this.userForm.controls.des.errors != null);
    }

    invalidNote() {
        return (this.submitted && this.userForm.controls.note.errors != null);
    }

    public submitAddJobTitle(): void {
        this.submitted = true;

        if (this.userForm.invalid === true) {
            return;
        }
        if (this.isEdit) {
            this.edit();
        } else {
            this.add();
        }
    }

    public add(): void {
        this.isUserAdded = true;
        this.service.addJobTitle(this.userForm.value).subscribe(user => {
            this.isUserAdded = false;
            this.toastr.success('Job Title added successfully');
            this.dialogRef.close();
            this.userForm.reset();
            this.getJobTitleList();
        }, error => {
            const err = error.error && error.error.msg;
            this.isUserAdded = false;
            this.toastr.error(err || 'Error in adding Job Title.Please try again.');
        });
    }

    public edit(): void {
        this.isUserAdded = true;
        this.service.editJobTitle(this.userForm.value, this.editId).subscribe(user => {
            this.isUserAdded = false;
            this.toastr.success('Job Title updated successfully');
            // this.modalRef.close();
            this.dialogRef.close();
            this.getJobTitleList();
        }, error => {
            const err = error.error && error.error.msg;
            this.isUserAdded = false;
            this.toastr.error(err || 'Error in updating Job Title.Please try again.');
        });
    }

    public editUser(id): void {
        this.isEdit = true;
        this.isUserAdded = true ;
        this.dialogRef = this.dialog.open(this.addUser, {
            width: '560px',
            height: 'auto'
        });
        this.service.getJobTitleById(id).subscribe(user => {
            if (user && user.data) {
                this.userForm.patchValue(user.data);
                const a = user.data.url;
                this.fileUrl = a;
                this.fileName = a && a.split('/')[a.split('/').length - 1];
                this.fileToEdit = a;
                this.isUserAdded = false ;
             
                this.editId = id;
           
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.isUserAdded = false ;
            this.toastr.error(err || 'Error getting Job Title');
        });
    }

    public deleteJobTitle(): void {
        this.service.deleteMultipleJobTitle(this.selectedCheckBoxList).subscribe(user => {
            if (user) {
                this.close();
                this.getJobTitleList();
                this.toastr.success('Job Title deleted successfully !');
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.close();
            this.getJobTitleList();
            this.toastr.error(err || 'Error deleting Job Title');
        });
        this.selectedCheckBoxList = [];
    }

    public fileEdit() {
        const f = this.fileEditOption;
        console.log(f);
        if (f) {
            if (f === 'keep') {
                this.fileToEdit = this.userForm.value.url;
                const v = this.userForm.value;
                v.url = this.fileToEdit;
                this.userForm.patchValue(v);
                this.replaceFile = false;
                const a = this.fileToEdit;
                this.fileName = a.split('/')[a.split('/').length - 1];
                this.fileUrl = this.fileToEdit;
            }
            if (f === 'replace') {
                const v = this.userForm.value;
                v.url = this.fileToEdit;
                this.userForm.patchValue(v);
                this.replaceFile = true;
                const a = this.fileToEdit;
                this.fileName = a && a.split('/')[a.split('/').length - 1];
                this.fileUrl = this.fileToEdit;
            }
            if (f === 'delete') {
                this.fileToEdit = this.userForm.value.url;
                const v = this.userForm.value;
                v.url = '';
                this.userForm.patchValue(v);
                this.replaceFile = false;
                this.fileName = '';
                this.fileUrl = '';
            }
        }
    }

    downloadFile = (data): void => {
        const blob = new Blob([data]);
        const fileName = this.fileName;
        saveAs(blob, fileName);
    }

    onCheckBoxChange($event: MatCheckboxChange, id: any) {
        if (!this.alreadySelected($event.source) && $event.checked) {
            this.selectedCheckBoxList.push($event.source.value);
        } else if (!$event.checked) {
            this.selectedCheckBoxList = this.selectedCheckBoxList.filter(
                value => {
                    return value !== $event.source.value;
                }
            );
        }
    }

    alreadySelected(item: MatCheckbox): boolean {
        this.selectedCheckBoxList.forEach(
            value => {
                if (item === value) {
                    return true;
                }
            }
        );
        return false;
    }

    onSelectAllClicked() {
        this.selectedCheckBoxList = [];
        this.matCheckBoxes.forEach(
            item => {
                this.selectedCheckBoxList.push(item.value);
                item.checked = true;
            }
        );
    }

    onDeSelectAllClicked() {
        this.selectedCheckBoxList = [];
        this.matCheckBoxes.forEach(
            item => {
                item.checked = false;
            }
        );
    }

    onDeleteSelected() {
        this.dialogRef = this.dialog.open(this.deleteUser, {
            width: '440px',
            height: 'auto'
        });
    }
    onModelChange(value: string) {
        this.dataSource.filter = value.trim().toLocaleLowerCase();
    }
    exportToCsv() {
        const csv = this.dataSource.filteredData.map(
            row => {
                return [row.title].join(',') + '\n';
            }
        );
        csv.unshift(['title'].join(',') + '\n');
        const exportDate = new Date();
        // tslint:disable-next-line:max-line-length
        const exportFileName = `Csv-export-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}.csv`;
        saveAs(new Blob(csv, {type: 'text/csv;charset=utf-8'}), exportFileName);
    }

    saveAsPdf() {
        if (this.dataSource.filteredData.length <= 0) {
            this.toastr.error('No Data to export');
            return;
        }
        const body = [];
        for (let i = 0; i < this.dataSource.filteredData.length; i++) {
            body.push(
                [
                    this.dataSource.filteredData[i].title
                ]);
        }
        const doc = new jsPDF();
        autoTable(doc, {
            head: [['title']],
            body: body
        });
        const exportDate = new Date();
        const exportFileName = `job titles-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}}`;
        doc.save(exportFileName);
    }

    demoClick() {
        this.dialogRef = this.dialog.open(this.demo, {
            width: '560px',
            height: 'auto'
        });
    }

    public loadAttach = false;
    onAddAttachment(id) {
        this.attacherId = id;

        this.loadAttach = true;
        this.dialogRef = this.dialog.open(this.attach, {
            width: '560px',
            height: 'auto'
        });
        this.service.getAttachmentList(this.attacherId).subscribe(
            r => {
                if (r && r.data) {
                    this.attachmentList = r.data;
                    this.loadAttach = false;
                }
            },
            error => {
                this.loadAttach = false;
            }
        );
        this.comment = '';
        this.fileType = '';
        this.fileObj = null;

    }

    fileChange($event, type): void {
        this.uploadedFile = undefined;
        const reader = new FileReader();
        const file = $event.target.files[0];
        this.uploadedFile = file;
        const fileName = file && file.name;
        if (file && (file.size / 1024) < 2048) {
            this.fileObj = {
                added: new Date(),
                addedBy: 'string',
                desc: this.comment,
                docType: 'dd',
                empId: this.attacherId,
                fileName: fileName,
                size: file.size,
                url: ''
            };
        } else {
            this.fileError = 'Max size 2 MB';
            this.toastr.error('Max 2Mb File allowed.');
        }
    }

    onDownloadClicked(attachment: any) {
        const URL = attachment['url'];
        const FILE_NAME = attachment['fileName'];
        window.open(URL);
    }

    onDeleteClicked(attachment: any) {
        const ID = attachment['id'];
        this.service.deleteAttachment(ID).subscribe(
            res => {
                this.service.getAttachmentList(this.attacherId).subscribe(
                    r => {
                        this.attachmentList = r.data;
                        this.toastr.success('Deleted');
                    },
                    error => {
                        console.log(error);
                        this.toastr.error('Failed to delete');
                    }
                );
            }
        );
    }

    saveAttachment() {

        this.loadAttach = true;
        if (this.fileObj !== null) {
            this.service.uploadFile(this.uploadedFile).subscribe(resp => {
                if (resp && resp.data) {
                    this.fileObj['url'] = resp.data.filename;
                    this.fileObj['desc'] = this.comment;
                    this.service.addAttachment(this.fileObj).subscribe(
                        res => {
                            this.service.getAttachmentList(this.attacherId).subscribe(
                                r => {

                                    this.loadAttach = false;
                                    this.attachmentList = r.data;
                                    this.toastr.success('Attachment Added');
                                    this.dialogRef.close();
                                    this.getJobTitleList();
                                },
                                error => {
                                    this.loadAttach = false;
                                    this.toastr.error('Failed to add Attachment');
                                    this.dialogRef.close();
                                }
                            );
                        },
                        error => {
                            this.loadAttach = false;
                            this.toastr.error('Failed to add Attachment');
                            this.dialogRef.close();
                        }
                    );
                }
            }, error => {
                this.loadAttach = false;
                const err = error.error && error.error.msg;
                console.log(err);
                this.toastr.error('Failed to save Attachment', 'Upload Unsuccessful');
                this.dialogRef.close();
            });
        } else {
            this.loadAttach = false;
            this.toastr.error('No file Selected');
        }
    }
}
