import { ENTER } from "@angular/cdk/keycodes";
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { COMMA } from "@angular/cdk/keycodes";
import { of } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { startWith } from "rxjs/operators";
import { DashboardService } from "../../dashboard.service";


@Component({
  selector: "app-get-employee",
  templateUrl: "./get-employee.component.html",
  styleUrls: ["./get-employee.component.scss"],
})
export class GetEmployeeComponent implements OnInit {
  selectedEmployees: Array<any> = [];
  filteredEmployees: Observable<string[]>;
  empCtrl = new FormControl();
  employeeList: Array<any> = [];
  allEmployee = [];

  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild("empInput") empInput: ElementRef;
  @Input() title: string;
  @Output() selectedEmployeesOutput : EventEmitter<Array<any>> = new EventEmitter<Array<any>>();

  constructor(private service: DashboardService) {}

  ngOnInit(): void {
    this.service.getEmployeeDropdownOption().subscribe((resp) => {
      if (resp && resp.data) {
        resp.data.map((e) => {
          this.allEmployee.push({ name: e.id + " " + e.name, id: e.id });
          this.employeeList.push({ name: e.id + " " + e.name, id: e.id });
        });

        this.empCtrl.valueChanges.subscribe((search) => {
          startWith(""),
            (this.filteredEmployees = of(this.employeeList.filter((item) => item.name.toLowerCase().includes(search))));
        });
      }
    });
  }

  removeEmployee(id): void {
    this.selectedEmployees = this.selectedEmployees?.filter((emp) => emp.id !== id);
    this.employeeList = [];
    this.allEmployee?.filter((c) => !this.selectedEmployees.includes(c))?.map((c) => this.employeeList.push(c));
    this.filteredEmployees = of([]);
    this.selectedEmployeesOutput.emit(this.selectedEmployees);
  }

  addEmployee(event: MatAutocompleteSelectedEvent): void {
    this.selectedEmployees.push(event.option.value);
    this.empInput.nativeElement.value = "";
    this.empCtrl.setValue(null);
    this.employeeList = this.employeeList?.filter((emp) => emp.id != event.option.value.id);
    this.filteredEmployees = of([]);
    this.empInput.nativeElement.blur();
    this.selectedEmployeesOutput.emit(this.selectedEmployees);
  }
}
