import { Component, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { FormGroup, FormBuilder, NgForm } from "@angular/forms";
import { NotificationService } from "src/app/core/services/notification.service";
import { saveAs } from 'file-saver-es';
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { MatCheckbox, MatCheckboxChange } from "@angular/material/checkbox";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { LanguageConfig } from "src/app/Language-config";
import { AuthService } from "src/app/core/services/auth.service";
import { CountryDropdownComponent } from "src/app/hrmshared/country-dropdown/country-dropdown.component";
import { PayrollService } from "../payroll.service";

@Component({
  selector: "app-payroll",
  templateUrl: "./payroll.component.html",
  styleUrls: ["./payroll.component.scss"],
})
export class PayrollComponent implements OnInit {
  public filterQuery = "";
  public isLoading: boolean;
  public submitLoading: boolean;
  public generating:boolean;
  public err: string;
  // public modalRef: any;
  public crudForm: FormGroup;
  public isEdit: boolean;
  public editId: string;
  public deleteId: string;
  public selectedCountry: string;

  // for the material dialog
  public dialogRef: any;
  // for material data table
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  public columns = ["select", "name", "country", "edit"];
  public lang = new Map();

  selectedCheckBoxList = [];
  @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
  @ViewChild("add", { static: false }) addModal;
  @ViewChild("delete", { static: false }) deleteModal;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("countryDropdown") countryDropdown: CountryDropdownComponent;
  countryList: Array<any>;
  
  public addTaxForm: FormGroup;
  public taxColumns = ["name", "from", "to", "value", "percentage", "delete"];
  public taxList:Array<any> = [];
  public taxDataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  showAddTax:boolean = false;
  @ViewChild('taxAddFrom') private taxAddFrom: NgForm;

  constructor(
    // private modalService: NgbModal,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private toastr: NotificationService,
    private authService: AuthService,
    private payrollService: PayrollService
  ) {
    this.createForm();
    this.lang = LanguageConfig.map;
  }
  public permissions: any;
  public read = false;
  public write = false;
  public update = false;
  public delete = false;

  checkPermission(module, tabs): void {
    this.permissions = JSON.parse(this.authService.getPermissions());

    this.read = false;
    this.write = false;
    this.delete = false;
    this.update = false;
    if (this.permissions != undefined && this.permissions != null) {
      for (let p of this.permissions) {
        if (p.id === module) {
          if (p.modules != undefined) {
            for (let tab of p.modules) {
              if (tab.id === tabs) {
                for (let move of tab.permits) {
                  if (move.id === "u") {
                    this.update = true;
                  } else if (move.id === "r") {
                    this.read = true;
                  } else if (move.id === "c") {
                    this.write = true;
                  } else if (move.id === "d") {
                    this.delete = true;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  ngOnInit() {
    this.read = false;
    this.write = false;
    this.update = false;
    this.delete = false;
    this.checkPermission("admin", "ad_jb");
    if (!this.delete) {
      this.columns.shift();
    }
    this.isLoading = true;
    this.isEdit = false;
    // this.service.getCountryList().subscribe(resp => {
    //   this.countryList = resp && resp.data;
    this.getAll();
    // });

    this.submitLoading = false;
  }
  public createForm(): void {
    this.crudForm = this.fb.group({
      name: [""],
      countryCode: [""],
      taxes:[]
    });

    this.addTaxForm = this.fb.group({
      name: [""],
      from: "",
      to: "",
      value:"",
      percentage : ""
    });
  }

  public addNew() {
    this.isEdit = false;
    this.dialogRef = this.dialog.open(this.addModal, {
      width: "560px",
      height: "auto",
    });
    this.crudForm.reset();
    this.selectedCountry = "";
  }

  public edit(id): void {
    this.isEdit = true;
    this.submitLoading = true;
    this.dialogRef = this.dialog.open(this.addModal, {
      width: "750px",
      height: "auto",
    });

    this.payrollService.getById(id).subscribe(
      (user) => {
        if (user && user.data) {
          this.crudForm.patchValue(user.data);
          this.submitLoading = false;
          this.editId = id;
          this.selectedCountry = user.data.countryCode;
          this.taxList = user.data.taxes ?  user.data.taxes : new Array();
          this.taxDataSource.data = this.taxList;
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.submitLoading = false;
        this.toastr.error(err || "Error getting Job Title");
      }
    );
  }

  public close(): void {
    this.dialogRef.close();
    this.resetAll();
  }

  private resetAll(){
    this.crudForm.reset();
    this.taxList = [];
    this.showAddTax = false;
    this.addTaxForm.reset();
    if(this.taxAddFrom){
      this.taxAddFrom.resetForm();
    }
  }

  public getAll(): void {
    this.payrollService.getAll().subscribe(
      (obj) => {
        if (obj && obj.data) {
          this.isLoading = false;
          this.dataSource = new MatTableDataSource<any>(obj.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.err = "";
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error while getting Job Title");
        this.isLoading = false;
        this.err = "Error while getting Job Title";
      }
    );
  }

  public save(): void {
    if (this.crudForm.invalid === true) {
      return;
    }
    if (this.isEdit) {
      this.updateObject();
    } else {
      this.addObject();
    }
  }

 

  private addObject(): void {
    this.submitLoading = true;
    this.crudForm.patchValue({ countryCode: this.selectedCountry, taxes:this.taxList });
    this.payrollService.create(this.crudForm.value).subscribe(
      (user) => {
        this.submitLoading = false;
        this.toastr.success("Payroll added successfully");
        this.dialogRef.close();
        this.resetAll();
        this.getAll();
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.submitLoading = false;
        this.toastr.error(err || "Error in adding Payroll.Please try again.");
      }
    );
  }

  private updateObject(): void {
    this.submitLoading = true;
    this.crudForm.patchValue({ countryCode: this.selectedCountry, taxes: this.taxList });
    this.payrollService.update(this.crudForm.value, this.editId).subscribe(
      (user) => {
        this.submitLoading = false;
        this.toastr.success("Payroll updated successfully");
        // this.modalRef.close();
        this.dialogRef.close();
        this.resetAll();
        this.getAll();
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.submitLoading = false;
        this.toastr.error(err || "Error in updating Payroll.Please try again.");
      }
    );
  }

  public deleteObjects(): void {
    this.payrollService.delete(this.selectedCheckBoxList).subscribe(
      (user) => {
        if (user) {
          this.close();
          this.getAll();
          this.toastr.success("Payroll deleted successfully.");
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.close();
        this.getAll();
        this.toastr.error(err || "Error deleting payroll");
      }
    );
    this.selectedCheckBoxList = [];
  }

  onCheckBoxChange($event: MatCheckboxChange, id: any) {
    if (!this.alreadySelected($event.source) && $event.checked) {
      this.selectedCheckBoxList.push($event.source.value);
    } else if (!$event.checked) {
      this.selectedCheckBoxList = this.selectedCheckBoxList.filter((value) => {
        return value !== $event.source.value;
      });
    }
  }

  alreadySelected(item: MatCheckbox): boolean {
    this.selectedCheckBoxList.forEach((value) => {
      if (item === value) {
        return true;
      }
    });
    return false;
  }

  onSelectAllClicked() {
    this.selectedCheckBoxList = [];
    this.matCheckBoxes.forEach((item) => {
      this.selectedCheckBoxList.push(item.value);
      item.checked = true;
    });
  }

  onDeSelectAllClicked() {
    this.selectedCheckBoxList = [];
    this.matCheckBoxes.forEach((item) => {
      item.checked = false;
    });
  }

  onDeleteSelected() {
    this.dialogRef = this.dialog.open(this.deleteModal, {
      width: "440px",
      height: "auto",
    });
  }

  onModelChange(value: string) {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  exportToCsv() {
    const csv = this.dataSource.filteredData.map((row) => {
      return [row.title].join(",") + "\n";
    });
    csv.unshift(["title"].join(",") + "\n");
    const exportDate = new Date();
    // tslint:disable-next-line:max-line-length
    const exportFileName = `Csv-export-${exportDate.getFullYear()}-${
      exportDate.getMonth() + 1
    }-${exportDate.getDate()}.csv`;
    saveAs(new Blob(csv, { type: "text/csv;charset=utf-8" }), exportFileName);
  }

  saveAsPdf() {
    if (this.dataSource.filteredData.length <= 0) {
      this.toastr.error("No Data to export");
      return;
    }
    const body = [];
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      body.push([this.dataSource.filteredData[i].title]);
    }
    const doc = new jsPDF();
    autoTable(doc, {
      head: [["title"]],
      body: body,
    });
    const exportDate = new Date();
    const exportFileName = `payroll-${exportDate.getFullYear()}-${
      exportDate.getMonth() + 1
    }-${exportDate.getDate()}}`;
    doc.save(exportFileName);
  }

  public addTax(){
    if(this.addTaxForm.valid){
      this.taxList.push(this.addTaxForm.value);
      this.taxDataSource.data = this.taxList;
      this.showAddTax = false;
      this.addTaxForm.reset();
      this.taxAddFrom.resetForm();
    }
  }


  deleteTax(row){
    let removeIndx = this.taxList.indexOf(row);
    this.taxList.splice(removeIndx, 1);
    this.taxDataSource.data = this.taxList;
  }
}
