import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pim',
  templateUrl: './pim.component.html',
  styleUrls: ['./pim.component.scss']
})
export class PimComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
