import { Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatCheckbox, MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import * as moment from "moment";
import { NotificationService } from "src/app/core/services/notification.service";
import { AuthService } from "src/app/core/services/auth.service";
import { DashboardService } from "src/app/dashboard/dashboard.service";

@Component({
  selector: "app-salary-info",
  templateUrl: "./salary-info.component.html",
  styleUrls: ["./salary-info.component.scss"],
})
export class SalaryInfo implements OnInit {
  @Input() employeeId: string;
  public grossSalary = "";
  public totalDeductions = "";
  public totalTaxable = "";
  public basicSalary = 0;
  public salaryForm: FormGroup;
  public currentPaygrade: any;
  public currencyCodeName: any;
  public payGradeList: Array<any> = [];
  public currencyList: Array<any> = [];
  public earningList: Array<any> = [];
  public editAttachmentId: string;
  public comment: string;
  public fileObj: any;
  public isLoading: boolean = true;
  public isSaving:boolean;
  public isLoadingAttachment: boolean = true;
  public selectedCheckBoxList: Array<any> = [];
  public defaultPaygrade = "";
  public payCurrencyObj = {
    min: "",
    max: "",
    currencyCode: "",
    currencyName: "",
  };
  public deductionList: any;
  public salaryList: any;
  public dialogRef: any;
  public pid: string;
  public fileUrl: string;
  public fileType: string;
  public fileName: string;
  public deleteAttachmentId: string;
  public salaryResponse: Array<any> = [];
  public salaryRequest: Array<any> = [];
  public sectionsList: Array<any> = [];
  public tempSectionsList: Array<any> = [];
  public saveCutomList: Array<any> = [];
  public saveCBCutomList: Array<any> = [];
  public salaryAttachmentList: MatTableDataSource<any> = new MatTableDataSource([]);
  public salaryHistoryList: MatTableDataSource<any> = new MatTableDataSource([]);
  @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
  @ViewChild("attach", { static: false }) attach;
  @ViewChild("editAttach", { static: false }) editAttach;
  @ViewChild("deleteAttachment", { static: false }) deleteAttachment;
  @ViewChild("deleteSalaryHistory", { static: false }) deleteSalaryHistory;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public columns = ["fileName", "url", "desc", "size", "added", "edit", "delete"];
  public columnsSalary = [
    "select",
    "event",
    "effectiveFrom",
    "effectiveTo",
    "payGrade",
    "currency",
    "annualBasicPayment",
    "earnings",
    "deductions",
  ];
  uploadedFile: any;
  fileError: string;
  tempSalaryForm: any;
  constructor(
    private service: DashboardService,
    private toastr: NotificationService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private authService: AuthService
  ) {
    this.createForm();
  }

  ngOnDestroy() {
    this.dialog = null;
    this.dialogRef = null;
    this.authService = null;
    this.service = null;
    this.fb = null;
    this.toastr = null;
    this.attach = null;
    this.sort = null;
    this.paginator = null;
    this.editAttach = null;
    this.deleteAttachment = null;
    this.matCheckBoxes = null;
    this.salaryForm = null;
    this.tempSalaryForm = null;
    this.salaryHistoryList = null;
    this.salaryAttachmentList = null;
  }
  checkRequiredFieldsInCustomFields(sectionID, customFields) {
    for (let section of this.sectionsList) {
      if (section.id === sectionID) {
        for (let custom of customFields) {
          if (custom.required) {
            if (custom.value === "" || custom.value === null || custom.value === undefined) {
              section.save = true;
              return;
            }
          }
        }

        section.save = false;
      }
    }
  }

  updateCustomField(sectionId, customFields): void {
    this.saveCutomList = [];
    this.saveCBCutomList = [];
    for (let custom of customFields) {
      if (custom.required) {
        if (custom.value === "") {
          this.toastr.error("Please fill the required fields");
          return;
        }
      }
      var obj = {
        fieldId: "",
        fieldValue: "",
      };
      if (custom.type != "cb") {
        let id: string = custom.id;
        let value: string = custom.value;
        if (custom.type == "dt") {
          value = moment(custom.value).format("YYYY-MM-DD").toString();
        }

        obj.fieldId = id;
        obj.fieldValue = value;
        this.saveCutomList.push(obj);
      }

      if (custom.type == "cb") {
        var obj2 = {
          fieldId: "",
          values: [],
        };
        obj2.fieldId = custom.id;
        let values: Array<any> = [];
        obj2.values = values;
        for (let c of custom.values) {
          if (c.booleanValue) {
            obj2.values.push(c.option);
          }
        }
        this.saveCBCutomList.push(obj2);
      }
    }

    this.service.saveCustomFieldsInEditEmployee(sectionId, this.employeeId, this.saveCutomList).subscribe(
      (res) => {
        if (res) {
          this.service
            .saveCustomFieldsCheckBoxesInEditEmployee(sectionId, this.employeeId, this.saveCBCutomList)
            .subscribe(
              (res) => {
                if (res) {
                  this.toastr.success("Updated Successfully!..");
                }
              },
              (error) => {
                this.toastr.error("Error in  updating");
              }
            );
        }
      },
      (error) => {}
    );
  }

  public permissions: any;
  public read = false;
  public write = false;
  public update = false;
  public delete = false;

  checkPermission(module, tabs): void {
    this.permissions = JSON.parse(this.authService.getPermissions());

    this.read = false;
    this.write = false;
    this.delete = false;
    this.update = false;

    if (this.permissions != undefined && this.permissions != null) {
      for (let p of this.permissions) {
        if (p.id === module) {
          if (p.modules != undefined) {
            for (let tab of p.modules) {
              if (tab.id === tabs) {
                for (let move of tab.permits) {
                  if (move.id === "u") {
                    this.update = true;
                  } else if (move.id === "r") {
                    this.read = true;
                  } else if (move.id === "d") {
                    this.delete = true;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ngOnInit(): void {
    this.checkPermission("pdm", "pd_em");
    this.service.getCustomFieldsForEmployee("sa", this.employeeId).subscribe((res) => {
      if (res && res.data) {
        this.sectionsList = res.data;
      }
    });
    this.service.getpaygrades().subscribe((resp) => {
      if (resp && resp.data) {
        this.isLoading = true;
        this.payGradeList = resp.data;
        this.service.getSalaryComponents().subscribe(
          (resp) => {
            this.salaryList = resp && resp.data && resp.data;
            this.salaryList.map((m) => {
              m["value"] = "0";
              m["status"] = false;
            });
            this.salaryList[0].status = true;
            this.deductionList = this.salaryList.filter((s) => {
              return s.type === "D";
            });
            this.earningList = this.salaryList.filter((s) => {
              return s.type === "E";
            });
            this.service.getSalaryInfo(this.employeeId).subscribe((resp) => {
              if (resp && resp.data) {
                this.salaryForm.patchValue(resp.data);
                this.currentPaygrade = resp.data.paygradeid;
                this.payCurrencyObj.currencyCode = resp.data.currencyCode;
                this.payCurrencyObj.currencyName = resp.data.currencyName;
                this.payCurrencyObj.min = resp.data.min;
                this.payCurrencyObj.max = resp.data.max;
                this.pid = this.currentPaygrade;
                if (resp.data.earnings != undefined && resp.data.earnings != null) {
                  this.salaryResponse = resp.data.earnings;
                  for (let s of this.salaryResponse) {
                    if (s.type === "E") {
                      for (let e of this.earningList) {
                        if (s.id === e.id) {
                          e.status = s.status;
                          e.value = s.value;
                        }
                        if (e.basic) {
                          e.status = true;
                        }
                      }
                    }
                    if (s.type === "D") {
                      for (let d of this.deductionList) {
                        if (s.id === d.id) {
                          d.status = s.status;
                          d.value = s.value;
                        }
                      }
                    }
                  }
                }
                this.salaryResponse = resp.data.earnings;

                this.setInitVal();
                this.calcTotal();
                this.tempSalaryForm = this.salaryForm.value;
                this.isLoading = false;
                this.salaryHistoryList = new MatTableDataSource<any>(resp.data.salaryHistory);
                this.salaryHistoryList.paginator = this.paginator;
                this.salaryHistoryList.sort = this.sort;
              }
            });
          },
          (error) => {
            this.isLoading = false;
            this.toastr.error("Error In Getting Salary Informations");
          }
        );
      }
    });

    this.getSalaryAttachmentList();
  }

  disableLine(obj) {
    if (!obj.status) {
      obj.value = "0";
    }
  }
  getSalaryHistory() {
    this.service.getSalaryInfo(this.employeeId).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.salaryHistoryList = new MatTableDataSource<any>(resp.data.salaryHistory);
          this.salaryHistoryList.paginator = this.paginator;
          this.salaryHistoryList.sort = this.sort;
        }
      },
      (error) => {
        this.toastr.error("Error in Getting Salary History");
      }
    );
  }
  public setInitVal() {
    if (!this.payCurrencyObj) {
      this.payCurrencyObj["min"] = "";
      this.payCurrencyObj["max"] = "";
      this.payCurrencyObj["currencyName"] = "";
      this.payCurrencyObj["currencyCode"] = "";
    } else {
      // this.setVal()
      const a = this.payGradeList.filter((t) => {
        return t.id === this.pid;
      })[0];
      if (a) {
        this.currencyList = a.payCurrencies;
      }
      const aa = this.currencyList.filter((a1) => {
        return a1.currencyCode === this.payCurrencyObj.currencyCode;
      })[0];
    }
  }

  public setVal(): void {
    const paygradeid = this.currentPaygrade;
    const a = this.payGradeList.filter((t) => {
      return t.id === paygradeid;
    })[0];
    if (a) {
      this.currencyList = a.payCurrencies;
    }
    if (this.currencyList && this.currencyList.length) {
      this.payCurrencyObj.min = this.currencyList[0].min;
      this.payCurrencyObj.max = this.currencyList[0].max;
      this.payCurrencyObj.currencyCode = this.currencyList[0].currencyCode;
      this.payCurrencyObj.currencyName = this.currencyList[0].currencyName;
      this.currencyCodeName = this.currencyList[0].currencyCode;
      // when I add currencyCode min and max not showed
    } else {
      this.payCurrencyObj["min"] = "";
      this.payCurrencyObj["max"] = "";
      this.currencyCodeName = null;
      this.payCurrencyObj["currencyName"] = null;
      this.payCurrencyObj["currencyCode"] = null;
    }
  }

  public setPayCurrencyValue(): void {
    const currencyCode = this.payCurrencyObj.currencyCode;
    const a = this.currencyList.filter((a1) => {
      return a1.currencyCode === currencyCode;
    })[0];
    this.payCurrencyObj = {
      min: a.min,
      max: a.max,
      currencyCode: a.currencyCode,
      currencyName: a.currencyName,
    };
  }

  public calcTotal() {
    // var basicS = 0;
    var deductions = 0;
    var earnings = 0;
    var taxable = 0;

    this.basicSalary =  this.earningList.find(e => e.basic).value;
   
    this.earningList.forEach((e) => {
      if (e.status && e.value) {
        e.valueType == 'A' ? earnings += parseInt(e.value, 10) : earnings += parseInt(e.value, 10) * (this.basicSalary/100);
        if(e.taxable){
          e.valueType == 'A' ? taxable += parseInt(e.value, 10) : taxable += parseInt(e.value, 10) * (this.basicSalary/100);
        }
      }
    });
    this.deductionList.forEach((e) => {
      if (e.status && e.value) {
        e.valueType == 'A' ? deductions += parseInt(e.value, 10) : deductions += parseInt(e.value, 10) * (this.basicSalary/100);
        if(!e.taxable){
          e.valueType == 'A' ? taxable -= parseInt(e.value, 10) : taxable -= parseInt(e.value, 10) * (this.basicSalary/100);
        }
      }
    });
    // let grossSalary = earnings - deductions;
    this.grossSalary = earnings.toLocaleString();
    this.totalDeductions = deductions.toLocaleString();
    this.totalTaxable = taxable.toLocaleString();
  }


  createForm() {
    this.salaryForm = this.fb.group({
      currencyCode: [""],
      currencyName: [""],
      max: [""],
      min: [""],
      paygradeid: [""],
      earnings: [[]],
    });
  }
  public addAttachment(): void {
    this.editAttachmentId = "";
    this.comment = "";
    this.fileType = "";
    this.fileObj = {};
    this.fileUrl = "";
    this.fileName = "";
    this.dialogRef = this.dialog.open(this.attach, {
      width: "440px",
      height: "auto",
    });
  }
  updateSalaryDetail() {
    this.isSaving = true;
    if (this.currencyList != null) {
      this.currencyList.map((res) => {
        if (res.currencyCode == this.salaryForm.value.currencyCode) {
          this.salaryForm.value.currencyName = res.currencyName;
        }
      });
    }

    for (let e of this.earningList) {
      var obj = {
        id: [""],
        status: [],
        type: [""],
        value: [""],
      };
      obj.id = e.id;
      obj.status = e.status;
      obj.type = e.type;
      obj.value = e.value;
      this.salaryRequest.push(obj);
    }
    for (let d of this.deductionList) {
      var obj = {
        id: [""],
        status: [],
        type: [""],
        value: [""],
      };
      obj.id = d.id;
      obj.status = d.status;
      obj.type = d.type;
      obj.value = d.value;
      this.salaryRequest.push(obj);
    }
    this.salaryForm.value.earnings = this.salaryRequest;
    this.service.updateEmployeeSalaryDetail(this.employeeId, this.salaryForm.value).subscribe(
      (res) => {
        if (res) {
          this.toastr.success("Updated Successfully!..");
          this.salaryRequest = [];
          this.getSalaryHistory();
          this.isSaving = false;
        }
      },
      (error) => {
        this.toastr.error("Error in  updating");
        this.salaryRequest = [];
        this.isSaving = false;
      }
    );
    
  }
  public open(url): void {
    window.open(url);
  }
  getSalaryAttachmentList() {
    this.isLoadingAttachment = true;
    this.service.getNewAttachmentList(this.employeeId, "sa").subscribe((resp) => {
      if (resp && resp.data) {
        this.salaryAttachmentList = new MatTableDataSource<any>(resp.data);
        this.salaryAttachmentList.data = resp.data;
        this.salaryAttachmentList.paginator = this.paginator;
        this.salaryAttachmentList.sort = this.sort;
        this.isLoadingAttachment = false;
      }
    });
    this.isLoadingAttachment = false;
  }
  public editAttachment(row): void {
    this.editAttachmentId = row.id;
    this.fileName = row.fileName;
    this.comment = row.desc;
    this.dialogRef = this.dialog.open(this.editAttach, {
      width: "440px",
      height: "auto",
    });
  }
  deleteAttachmentSelected(id) {
    this.deleteAttachmentId = id;
    this.dialogRef = this.dialog.open(this.deleteAttachment, {
      width: "440px",
      height: "auto",
    });
  }
  public fileChange($event, type): void {
    this.uploadedFile = undefined;
    const reader = new FileReader();
    const file = $event.target.files[0];
    this.uploadedFile = file;
    const fileName = file && file.name;
    if (file && file.size / 1024 < 2048) {
      this.fileObj = {
        added: new Date(),
        addedBy: "string",
        desc: this.comment,
        docType: type,
        empId: this.employeeId,
        fileName: fileName,
        size: file.size,
        section: "",
      };
    } else {
      this.fileError = "Max size 2 MB";
      this.toastr.error("Max 2Mb File allowed.");
    }
  }

  public isAttachLoad = false;
  public saveAttachment(): void {
    this.isAttachLoad = true;
    // this is for the save attachment
    this.service.uploadFile(this.uploadedFile).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.fileObj["url"] = resp.data.filename;
          this.fileObj["desc"] = this.comment;
          this.fileObj.section = "sa";
          this.fileObj.fileName = this.fileName;
          if (this.editAttachmentId) {
          } else {
            this.service.newAddAttachment(this.fileObj).subscribe((resp1) => {
              // this.attachmentList.filteredData.push(resp1.data);
              this.isAttachLoad = false;
              this.getSalaryAttachmentList();

              this.toastr.success("file added successfully!");
              this.dialogRef.close();
            });
          }
        }
      },
      (error) => {
        this.isAttachLoad = false;
        const err = error.error && error.error.msg;
        this.toastr.error("error in adding file!");
      }
    );
  }
  public updateAttachment() {
    this.service.updateNewAttachmentList(this.comment, this.fileName, this.editAttachmentId).subscribe(
      (res) => {
        if (res) {
          this.getSalaryAttachmentList();

          this.toastr.success("Attachment updated Successfully.");
          this.dialogRef.close();
        }
      },
      (error) => {
        this.toastr.error("Error in updating attachment");
      }
    );
  }
  public deleteAttachmentFrom(): void {
    this.service.deleteAttachment(this.deleteAttachmentId).subscribe(
      (resp) => {
        if (resp) {
          this.getSalaryAttachmentList();
          this.dialogRef.close();
          this.toastr.success("Attachment Deleted Successfully...");
        }
      },
      (error) => {
        this.toastr.error("Error in deleting the Attachment");
      }
    );
  }

  onSelectAllClicked() {
    this.selectedCheckBoxList = [];
    this.matCheckBoxes.forEach((item) => {
      this.selectedCheckBoxList.push(item.value);
      item.checked = true;
    });
  }
  onDeSelectAllClicked() {
    this.selectedCheckBoxList = [];
    this.matCheckBoxes.forEach((item) => {
      item.checked = false;
    });
  }
  onDeleteSelected() {
    this.dialogRef = this.dialog.open(this.deleteSalaryHistory, {
      width: "440px",
      height: "auto",
    });
  }
  public close(): void {
    this.dialogRef.close();
    // this.modalRef.close();
  }
  public deleteJobS(): void {
    this.service.deleteMultipleSalaryHsitory(this.employeeId, this.selectedCheckBoxList).subscribe(
      (user) => {
        if (user) {
          this.close();
          this.getSalaryHistory();
          this.toastr.success("Job History deleted successfully !");
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.close();
        this.toastr.error(err || "Error deleting Job Title");
      }
    );
    this.onDeSelectAllClicked();
  }
  onCheckBoxChange($event: MatCheckboxChange, id: any) {
    if (!this.alreadySelected($event.source) && $event.checked) {
      this.selectedCheckBoxList.push($event.source.value);
    } else if (!$event.checked) {
      this.selectedCheckBoxList = this.selectedCheckBoxList.filter((value) => {
        return value !== $event.source.value;
      });
    }
  }

  alreadySelected(item: MatCheckbox): boolean {
    this.selectedCheckBoxList.forEach((value) => {
      if (item === value) {
        return true;
      }
    });
    return false;
  }
}
