import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AnnouncementService} from '../../../../../service/announcement.service';
import { NotificationService } from "src/app/core/services/notification.service";
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {DashboardService} from '../../../../dashboard.service';
import jsPDF from 'jspdf';
import {saveAs} from 'file-saver-es';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {merge, Observable} from 'rxjs';
import autoTable from 'jspdf-autotable';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {LanguageConfig} from 'src/app/Language-config';
import { AuthService } from 'src/app/core/services/auth.service';


class Todo {
    public ackCount: number;
    public acknowledgement: boolean;
    public description: string;
    public hasAttachment: boolean;
    public id: string;
    public myAck: boolean;
    public myAckDate: number;
    public ptAllLocation: boolean;
    public ptAllUserRole: boolean;
    public ptEmployeeStatus: string[];
    public ptEmployees: string[];
    public ptJobTitles: string[];
    public ptLocations: string[];
    public ptLocationsName: string;
    public ptSubUnits: string[];
    public ptUserRoles: string[];
    public ptUserRolesName: string;
    public publishDate: string;
    public published: boolean;
    public topic: string;
    public type: string;
}

@Component({
    selector: 'app-news-list',
    templateUrl: './news-list.component.html',
    styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent implements OnInit {
    public filterNewsForm: FormGroup;
    public categoryList: Array<any> = [];
    public locationList: Array<any> = [];
    @ViewChild('filterNews', {static: false}) filterNewsAndDocuments;
    public acknowladgelist: Array<any>;
    public fileType: string;
    public fileObj: any;
    public uploadedFile: any;
    public fileError: string;
    public attachmentList = [];
    public editId: string;
    public editAttachmentId: string;
    public attacherId: string;
    public show = false;
    public categoryCol = true;
    @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
    @ViewChild('attach', {static: false}) attach;
    @ViewChild('ack', {static: false}) acknowledgmentModal;
    @ViewChild('configure', {static: false}) configureModal;
    @ViewChild('deleteUser', {static: false}) deleteUser;
    @ViewChild('archive', {static: false}) archive;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort: MatSort;
    public lang = new Map();
    constructor(
        private route: Router,
        private service: DashboardService,
        private announcementService: AnnouncementService,
        private dashboardService: DashboardService,
        public dialog: MatDialog,
        private authService : AuthService,
        private toastr: NotificationService,
        private fb: FormBuilder,
        private activatedRoute: ActivatedRoute) {
        this.createForm();
        this.attacherId = '';
        this.lang = LanguageConfig.map;
        this.activatedRoute.data.subscribe(params => {
            if (params.type) {
                if (params.type === 'news') {
                    this.type = 'N';
                    this.typeName = this.lang.get('news');
                }
                if (params.type === 'documents') {
                    this.type = 'D';
                    this.typeName = this.lang.get('documents');
                }
                this.initData();
            }
        });
    }
    displayedColumns = ['Select', 'Topic', 'PublishDate', 'Status', 'PublishedLocations',
        'PublishedUserRoles', 'Attachments', 'AcknowledgmentStatus'];
    displayedAckColumns = ['employeeName', 'acknowledged', 'dateAcknowledged'];
    acknowledgementList = [];
    // regarding the configure work
    public configureForm: FormGroup = new FormGroup({
        select: new FormControl(false),
        topic: new FormControl(true),
        category : new FormControl(true),
        publishDate: new FormControl(true),
        published: new FormControl(true),
        ptLocations: new FormControl(true),
        ptUserRoles: new FormControl(true),
        ptJobTitles: new FormControl(false),
        ptSubUnits: new FormControl(false),
        ptEmployees: new FormControl(false),
        ptEmployeeStatus:  new FormControl(false),
        attachments: new FormControl(true),
        AcknowledgmentStatus: new FormControl(true),
    });
    public select = this.configureForm.get('select');
    public topic = this.configureForm.get('topic');
    public publishDate = this.configureForm.get('publishDate');
    public published = this.configureForm.get('published');
    public ptLocations = this.configureForm.get('ptLocations');
    public ptUserRoles = this.configureForm.get('ptUserRoles');
    public ptJobTitles = this.configureForm.get('ptJobTitles');
    public ptSubUnits = this.configureForm.get('ptSubUnits');
    public ptEmployees = this.configureForm.get('ptEmployees');
    public ptEmployeeStatus =  this.configureForm.get('ptEmployeeStatus');
    public attachments = this.configureForm.get('attachments');
    public AcknowledgmentStatus = this.configureForm.get('AcknowledgmentStatus');
    public category = this.configureForm.get('category');
    public columnDefinitions = [
        {def: 'select', label: 'select', hide: this.select.value , hidden : false },
        {def: 'topic', label: 'Topic', hide: !this.topic.value  , hidden : false },
        {def: 'category', label: 'Category', hide: !this.category.value , hidden : false },
        {def: 'publishDate', label: 'PublishDate', hide: !this.publishDate.value , hidden : false },
        {def: 'published', label: 'Status', hide: !this.published.value , hidden : false },
        {def: 'ptLocations', label: 'PublishedLocations', hide: !this.ptLocations.value , hidden : false },
        {def: 'ptUserRoles', label: 'PublishedUserRoles', hide: !this.ptUserRoles.value , hidden : false },
        {def: 'ptJobTitles', label: 'PublishedJobTitles', hide: !this.ptJobTitles.value , hidden : false },
        {def: 'ptSubUnits', label: 'PublishedSubunits', hide: !this.ptSubUnits.value , hidden : false },
        {def: 'ptEmployees', label: 'PublishedEmployees', hide: !this.ptEmployees.value , hidden : false },
        {def: 'ptEmployeeStatus', label: 'Employment Status', hide: !this.ptEmployeeStatus.value , hidden : false },
        {def: 'attachments', label: 'Attachments', hide: !this.attachments.value , hidden : false },
        {def: 'AcknowledgmentStatus', label: 'AcknowledgmentStatus', hide: !this.AcknowledgmentStatus.value , hidden : false },
    ];
    // ackDataSource: MatTableDataSource<any>;
    public todos: Todo[];
    dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
    paging = {
        pageSize: 10,
        totalRecords: 0,
        pageIndex: 0,
        pageSizeOptions: [10, 25, 50, 100]
    };
    isLoading = false;
    type = '';
    typeName = '';
    filterQuery = '';
    comment: string;
    showSpinner = false;
    private deleteNewsAndDocId: number;
    public selectedNewsDocs = [];
    private modalRef: NgbModalRef;
    private dialogRef: any;
    // for the configure work
    public columns: string[] = [];

    public permissions : any;
    public read = false;
    public write = false ;
    public update = false;
    public delete = false;
    public editClass = "";
    doNothing() : void
    {

        return ;
    }
    checkPermission(module,tabs) : void 
    {
       
        this.permissions = JSON.parse(this.authService.getPermissions());

        this.read = false ;
        this.write = false ;
        this.delete = false ;
        this.update = false;
        if(this.permissions != undefined && this.permissions != null )
        {
            for(let p of this.permissions)
            {
               
                if(p.id === module)
                {
                   
                    if(p.modules != undefined)
                    {
                      
                        for(let tab of p.modules)
                        {
                            if(tab.id === tabs)
                            {
                              
                                for(let move of tab.permits)
                                {
                                    if(move.id === "u")
                                    {
                                        this.update = true;
                                       
                                    }
                                    else if(move.id === "r")
                                    {
                                        this.read = true;
                                    }
                                    else if(move.id === "c")
                                    {
                                      
                                        this.write = true;
                                    }
                                    else if(move.id === "d")
                                    {
                                        this.delete = true;
                                    }
                                }
                            }
                        }
                    }
                   
                }
              
            }
        }
       

    }
    ngOnInit() {

       
        this.editClass = "cursor-pointer";
        this.checkPermission("admin","ad_an");
        if (this.type == 'N') {
            this.categoryCol = false;
            this.category.setValue(false);
        }

        const o1: Observable<boolean> = this.select.valueChanges;
        const o2: Observable<boolean> = this.topic.valueChanges;
        const o3: Observable<boolean> = this.category.valueChanges;
        const o4: Observable<boolean> = this.publishDate.valueChanges;
        const o5: Observable<boolean> = this.published.valueChanges;
        const o6: Observable<boolean> = this.ptLocations.valueChanges;
        const o7: Observable<boolean> = this.ptUserRoles.valueChanges;
        const o8: Observable<boolean> = this.ptJobTitles.valueChanges;
        const o9: Observable<boolean> = this.ptSubUnits.valueChanges;
        const o10: Observable<boolean> = this.ptEmployees.valueChanges;
        const o11: Observable<boolean> = this.ptEmployeeStatus.valueChanges;
        const o12: Observable<boolean> = this.attachments.valueChanges;
        const o13: Observable<boolean> = this.AcknowledgmentStatus.valueChanges;
        merge(o1, o2, o3, o4, o5, o6, o7, o8, o9, o10, o11, o12, o13).subscribe((v) => {
            this.columnDefinitions[0].hide = false;
            this.columnDefinitions[1].hide = !this.topic.value;
            this.columnDefinitions[2].hide = !this.category.value;
            this.columnDefinitions[3].hide = !this.publishDate.value;
            this.columnDefinitions[4].hide = !this.published.value;
            this.columnDefinitions[5].hide = !this.ptLocations.value;
            this.columnDefinitions[6].hide = !this.ptUserRoles.value;
            this.columnDefinitions[7].hide = !this.ptJobTitles.value;
            this.columnDefinitions[8].hide = !this.ptSubUnits.value;
            this.columnDefinitions[9].hide = !this.ptEmployees.value;
            this.columnDefinitions[10].hide = !this.ptEmployeeStatus.value;
            this.columnDefinitions[11].hide = !this.attachments.value;
            this.columnDefinitions[12].hide = !this.AcknowledgmentStatus.value;


            // console.log(this.columnDefinitions);
            this.getDisplayedColumns();
        });
        this.getDisplayedColumns();
        // before configure
        this.initData();
    }

    initData() {
        this.getNewsAndDocList();
        this.getCategories();
        this.dashboardService.getLocationList().subscribe(res => {
            if (res && res.data) {
                res.data.map((item => {
                    this.locationList.push({id: item.id, name: item.location});
                }));
            }
        }, error => {
            this.toastr.error('Error in getting location list');
        });
    }

    addNews() {
        if (this.type === 'D' || this.type === 'N') {
            this.route.navigate([`/admin/announcement/${this.type === 'N' ? 'news' : 'documents'}/add`]);
        }
    }

    editNews(dataObj) {
        this.route.navigate([`/admin/announcement/${this.type === 'N' ? 'news' : 'documents'}/update/${btoa(dataObj.id)}`]);
    }

    pageChange(event) {
        this.paging.pageIndex = event.pageIndex;
        this.paging.pageSize = event.pageSize;
        this.getNewsAndDocList();
    }

    getNewsAndDocList() {
        this.isLoading = true;
        this.announcementService.getNewsAndDocList(this.type).subscribe(res => {
            this.isLoading = false;
            if (res && res.data) {
                console.log(res);
                res.data.forEach((item => {
                    if (item.published === 'pu') {
                        item.published = 'Published';
                    } else if (item.published === 'sa') {
                        item.published = 'Save';
                    } else {
                        item.published = 'Archieve';
                    }
                    if (item.ptAllLocation) {
                        item.ptLocations = ['All'];
                    }
                    if (item.ptAllUserRole) {
                        item.ptUserRoles = ['All'];
                    }
                }));
                this.todos = res.data;
                this.dataSource = new MatTableDataSource(this.todos);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            }
            res.msg = res.msg && this.toastr.error(res.msg);
        }, error => {
            this.isLoading = false;
            this.toastr.error('Error while getting News');
        });
    }

    viewAcknowledgement(row) {
        this.route.navigate([`/admin/announcement/${this.type === 'N' ? 'news' : 'documents'}/acknowledgment_status/`+row.id]);
        // this.announcementService.getNewsAndDocAck(row.id).subscribe(
        //     res => {
        //         if (res && res.data) {
        //             this.acknowladgelist = res.data;
        //         }
        //     }
        // );
        // this.announcementService.getAcknowledgmentListById(row.id).subscribe(
        //     res => {
        //         if (res && res.data) {
        //             this.acknowledgementList = res.data;
        //             this.dialog.open(this.acknowledgmentModal, {
        //                 width: '560px',
        //                 height: 'auto'
        //             });
        //         }
        //     },
        //     error => {
        //         this.acknowledgementList = [];
        //     }
        // );
        // this.modalService.open(this.acknowledgmentModal);

    }

    getDateFormat(strDate) {
        return moment(strDate, 'YYYY-MM-DD').format('ddd, DD MMM YYYY');
    }

    onModelChange(value: string) {
        console.log(value);
        this.dataSource.filter = value.trim().toLocaleLowerCase();
    }

    public loadAttach = false;
    onAddAttachment(id) {
        this.attacherId = id;
        this.showSpinner = true;
        this.loadAttach = true;
        this.dialogRef = this.dialog.open(this.attach, {
            width: '560px',
            height: 'auto'
        });
        this.service.getAttachmentList(this.attacherId).subscribe(
            r => {
                if (r && r.data) {
                    this.loadAttach = false;
                    this.attachmentList = r.data;
                  
                }
                this.showSpinner = false;
            },
            error => {
                this.loadAttach = false;
                this.showSpinner = false;
            }
        );
        this.comment = '';
        this.fileType = '';
        this.fileObj = null;
        // this.modalRef = this.modalService.open(this.attach);

    }

    saveAttachment() {
        this.loadAttach = true;
        if (this.fileObj !== null) {
            this.service.uploadFile(this.uploadedFile).subscribe(resp => {
                if (resp && resp.data) {
                    this.fileObj['url'] = resp.data.filename;
                    this.fileObj['desc'] = this.comment;
                    this.service.addAttachment(this.fileObj).subscribe(
                        res => {
                            this.service.getAttachmentList(this.attacherId).subscribe(
                                r => {
                                    
                                    this.attachmentList = r.data;
                                    this.toastr.success('Attachment Added');
                                    this.loadAttach = false;
                                    this.dialogRef.close();
                                },
                                error => {
                                    this.loadAttach = false;
                                    this.toastr.error('Failed to add Attachment');
                                    this.dialogRef.close();
                                }
                            );
                        },
                        error => {
                            this.toastr.error('Failed to add Attachment');
                            this.loadAttach = false;
                            this.dialogRef.close();
                            
                        }
                    );
                }
            }, error => {
                const err = error.error && error.error.msg;
                console.log(err);
                this.loadAttach = false;
                this.toastr.error('Failed to save Attachment', 'Upload Unsuccessful');
                this.dialogRef.close();
            });
        } else {
            this.loadAttach = false;
            this.toastr.error('No file Selected');
        }
    }

    fileChange($event, type): void {
        this.uploadedFile = undefined;
        const reader = new FileReader();
        const file = $event.target.files[0];
        this.uploadedFile = file;
        const fileName = file && file.name;
        if (file && (file.size / 1024) < 2048) {
            this.fileObj = {
                added: new Date(),
                addedBy: 'string',
                desc: this.comment,
                docType: 'dd',
                empId: this.attacherId,
                fileName: fileName,
                size: file.size,
                url: ''
            };
        } else {
            this.fileError = 'Max size 2 MB';
            this.toastr.error('Max 2Mb File allowed.');
        }
    }

    onDownloadClicked(attachment: any) {
        const URL = attachment['url'];
        const FILE_NAME = attachment['fileName'];
        window.open(URL);
    }

    onDeleteClicked(attachment: any) {
        const ID = attachment['id'];
        this.service.deleteAttachment(ID).subscribe(
            res => {
                this.service.getAttachmentList(this.attacherId).subscribe(
                    r => {
                        this.attachmentList = r.data;
                        this.toastr.success('Deleted');
                    },
                    error => {
                        console.log(error);
                        this.toastr.error('Failed to delete');
                    }
                );
            }
        );

    }

    exportToCsv() {
        const csv = this.dataSource.filteredData.map(
            row => {
                return [
                    row.topic,
                    row.publishDate,
                    row.published ,
                   JSON.stringify(row.ptLocations),
                   row.ptUserRoles ,
                   JSON.stringify(row.ptJobTitles)  , 
                   JSON.stringify(row.ptSubUnits),
                   JSON.stringify(row.ptEmployees) ,
                   JSON.stringify(row.ptEmployeeStatus) 
                ].join(',') + '\n';
            }
        );
        csv.unshift(['Topic', 'PublishDate', 'Status', 'Published Locations', 'Published UserRoles','Published Job Titles'
    ,'Published Subunit','Published Employees','Employment Status'].join(',') + '\n');
        const exportDate = new Date();
        // tslint:disable-next-line:max-line-length
        const exportFileName = `Csv-export-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}.csv`;
        saveAs(new Blob(csv, {type: 'text/csv;charset=utf-8'}), exportFileName);
    }
    saveNewsAsPdf() {
        if (this.dataSource.filteredData.length <= 0) {
            this.toastr.error('No Data to export');
            return;
        }
        const body = [];
        for (let i = 0; i < this.dataSource.filteredData.length; i++) {
            body.push(
                [
                    this.dataSource.filteredData[i].topic,
                    this.dataSource.filteredData[i].publishDate,
                    this.dataSource.filteredData[i].published ? 'Published' : 'Save',
                    this.dataSource.filteredData[i].ptLocations,
                    this.dataSource.filteredData[i].ptUserRoles
                ]);
        }
        const doc = new jsPDF();
        autoTable(doc, {
            head: [['Topic', 'PublishDate', 'Status', 'PublishedLocations', 'PublishedUserRoles']],
            body: body
        });
        const exportDate = new Date();
        const exportFileName = `Pdf Export-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}}`;
        doc.save(exportFileName);
    }
    saveAsPdf() {
        if (this.acknowledgementList.length <= 0) {
            this.toastr.error('No Data to export');
            return;
        }
        const body = [];
        for (let i = 0; i < this.acknowledgementList.length; i++) {
            body.push([this.acknowledgementList[i].empName, this.acknowledgementList[i].id,
                new Date(+this.acknowledgementList[i].date).toDateString()]);
        }
        const doc = new jsPDF();
        autoTable(doc, {
            head: [this.displayedAckColumns],
            body: body
        });
        const exportDate = new Date();
        const exportFileName = `Ack-export-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}}`;
        doc.save(exportFileName);

    }
    saveNewsListAsPdf() {
        if (this.dataSource.filteredData.length <= 0) {
            this.toastr.error('No Data to export');
            return;
        }
        const body = [];
        for (let i = 0; i < this.dataSource.filteredData.length; i++) {
            body.push([
                this.dataSource.filteredData[i].topic,
                this.dataSource.filteredData[i].publishDate,
                this.dataSource.filteredData[i].published ? 'Published' : 'Save',
                this.dataSource.filteredData[i].ptAllLocation ? 'All' : '',
                this.dataSource.filteredData[i].ptAllUserRole ? 'All' : ''
                ]);
        }
        const doc = new jsPDF();
        autoTable(doc, {
            head: [['Topic', 'PublishDate', 'Status', 'PublishedLocations', 'PublishedUserRoles']],
            body: body
        });
        const exportDate = new Date();
        const exportFileName = `Announcement-export-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}}`;
        doc.save(exportFileName);

    }
    saveNewsListAsCsv() {
        const csv = this.acknowledgementList.map(
            row => {
                return [
                    row.topic,
                    row.publishDate,
                    row.published ? 'Published' : 'Save',
                    row.ptAllLocation ? 'All' : '',
                    row.ptAllUserRole ? 'All' : '',
                ].join(',') + '\n';
            }
        );
        csv.unshift(['Topic', 'PublishDate', 'Status', 'PublishedLocations', 'PublishedUserRoles'].join(',') + '\n');
        const exportDate = new Date();
        const exportFileName = `Csv-export-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}.csv`;
        saveAs(new Blob(csv, {type: 'text/csv;charset=utf-8'}), exportFileName);
    }
    saveAsCsv() {
        const csv = this.acknowledgementList.map(
            row => {
                return [row.empName, row.id, row.date].join(',') + '\n';
            }
        );
        csv.unshift(this.displayedAckColumns.join(',') + '\n');
        const exportDate = new Date();
        const exportFileName = `Csv-export-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}.csv`;
        saveAs(new Blob(csv, {type: 'text/csv;charset=utf-8'}), exportFileName);
    }


    deleteRow() {
        this.dialogRef = this.dialog.open(this.deleteUser, {
            width: '440px',
            height: 'auto'
        });
    }

    close() {
        this.dialogRef.close();
    }

    deleteNewsAndDocuments() {
        this.announcementService.deleteNewsAndDocuments(this.deleteNewsAndDocId).subscribe(
            resp => {
                if (resp) {
                    this.toastr.success('Deleted successfully !');
                    this.dialogRef.close();
                    this.getNewsAndDocList();
                }
            },
            error => {
                const err = error.error && error.error.msg;
                this.toastr.error(err || 'Error on deleting');
            }
        );
    }

    onNewDocSelectChange($event: MatCheckboxChange, id: any) {
        if (!this.alreadySelected($event.source) && $event.checked) {
            this.selectedNewsDocs.push($event.source.value);
        } else if (!$event.checked) {
            this.selectedNewsDocs = this.selectedNewsDocs.filter(
                value => {
                    return value !== $event.source.value;
                }
            );
        }
    }

    onSelectAllClicked() {
        this.selectedNewsDocs = [];
        this.matCheckBoxes.forEach(
            item => {
                this.selectedNewsDocs.push(item.value);
                item.checked = true;
            }
        );
    }

    onDeSelectAllClicked() {
        this.selectedNewsDocs = [];
        this.matCheckBoxes.forEach(
            item => {
                item.checked = false;
            }
        );
    }

    alreadySelected(id): boolean {
        this.selectedNewsDocs.forEach(
            value => {
                if (value === id) {
                    return true;
                }
            }
        );
        return false;
    }
    onDeleteAllClicked() {
        this.announcementService.deleteNewsAndDocuments(this.selectedNewsDocs).subscribe(
            response => {
                if (response) {
                    this.selectedNewsDocs = [];
                    this.initData();
                    this.close();
                    this.toastr.success('Removed Successfully');
                }
            },
            error => {
                console.error(error);
                this.toastr.error(`Error: ${error}`);
            }
        );
    }
    onConfigureClicked() {
        // this.modalService.open(this.configureModal);
        this.dialog.open(this.configureModal, {
            width: '768px',
            height: 'auto'
        });

    }
    public getDisplayedColumns() {

        this.columnDefinitions.map(value => {
            if(this.type === 'N')
            {
                if(value.def ==='category')
                {
                    value.hidden = true ;
                }
          
            }
            
        });
     
            this.columns = this.columnDefinitions
            .filter((cd) => !cd.hide)
            .map((cd) => cd.def);

            if (this.categoryCol == false) {
                const index = this.columns.indexOf('category', 0);
                console.log('index');
                console.log(index);
                if (index > -1) {
                    this.columns.splice(index, 1);
                    console.log(' this.columns');
                    console.log(this.columns);
                }
            }


    }
    onArchiveClicked() {
        // this.modalRef = this.modalService.open(this.archive);
        this.dialogRef = this.dialog.open(this.archive, {
            width: '440px',
            height: 'auto'
        });
    }
    archiveNewsAndDocuments() {
        this.announcementService.archiveNewsAndDocuments(this.selectedNewsDocs).subscribe(
            response => {
                if (response) {
                    this.selectedNewsDocs = [];
                    this.initData();
                    this.toastr.success('Archived Successfully');
                    this.close();
                }
            },
            error => {
                this.toastr.error(`Error: ${error}`);
                this.close();
            }
        );
        this.onDeSelectAllClicked();
    }

    createForm() {
        this.filterNewsForm = this.fb.group({
            topic: [''],
            category: [''],
            locations: [['']],
            publishDate: [''],
            status: ['']
        });
    }

    filterNewsAndDocument() {
        // this.modalRef = this.modalService.open(this.filterNewsAndDocuments);
        this.dialogRef = this.dialog.open(this.filterNewsAndDocuments, {
            width: '560px',
            height: 'auto'
        });
    }

    OnFilterReset() {
        this.createForm();
        this.getNewsAndDocList();
    }

    Search() {
        let body;
        if (this.filterNewsForm.value.status == '') {
            this.filterNewsForm.value.status = null;
        }
        if (this.filterNewsForm.value.locations[0] == '') {
            this.filterNewsForm.value.locations = null;
        }
        if (this.type === 'N') {
            body = {
                locations: this.filterNewsForm.value.locations,
                publishDate: this.filterNewsForm.value.publishDate,
                status: this.filterNewsForm.value.status,
                topic: this.filterNewsForm.value.topic,
                type: this.type
            };
        } else {
            body = {
                locations: this.filterNewsForm.value.locations,
                publishDate:  this.filterNewsForm.value.publishDate,
                status: this.filterNewsForm.value.status,
                topic: this.filterNewsForm.value.topic,
                type: this.type,
                category: this.filterNewsForm.value.category
            };
        }
        this.announcementService.filterNewsAndDocuments(body).subscribe(res => {
            if (res.data.length > 0) {
                res.data.forEach((item => {
                    if (item.published === 'pu') {
                        item.published = 'Published';
                    } else if (item.published === 'sa') {
                        item.published = 'Save';
                    } else {
                        item.published = 'Archieve';
                    }
                    if (item.ptAllLocation) {
                        item.ptLocations = ['All'];
                    }
                    if (item.ptAllUserRole) {
                        item.ptUserRoles = ['All'];
                    }
                }));
                this.dataSource = new MatTableDataSource(res.data);
                this.close();
                this.toastr.success('filtered Successfully');
            } else {
                this.toastr.error('No Search found..');
            }
        }, error => {
            this.toastr.error('Error in getting the filtered data..');
        });
    }

    getCategories() {
        this.announcementService.getCategories({limit: 100000}).subscribe(res => {
            if (res && res.data && res.data.length > 0) {
                res.data.map((item => {
                    this.categoryList.push({id: item.id, name: item.name});
                }));
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.toastr.error(err);
        });
    }
}
