import { Injectable } from "@angular/core";

@Injectable()
export class LanguageConfig {
  public static language: string = 'eng';

  public static countryListCache : Array<any> = new Array();
  public static countryMapCache : Map<string,any> = new Map();

  public static map = new Map([
["admin","Admin"],
["update","Update"],
["user management","User Management"],
["users","Users"],
["user","User"],
["new","New"],
["add","Add"],
["filter","Filter"],
["items per page",""],
["employee number","Employee Number"],
["employee name","Employee Name"],
["user name","Username"],
["user role","User Role"],
["location","Location"],
["status","Status"],
["edit","Edit"],
["csv","CSV"],
["pdf","PDF"],
["search","Search"],
["configure","Configure"],
["select all","Select All"],
["deselect all","Deselect All"],
["delete selected","Delete Selected"],
["password","Password"],
["employee","Employee"],
["emp","Employee"],
["active","Active"],
["inactive","Inactive"],
["ess","Ess"],
["email","Email"],
["superviso","Supervisor"],
["change password","Change Password"],
["cancel","Cancel"],
["save","Save"],
["areyousure","Are you sure ?"],
["reset","Reset"],
["reset password", "Reset Password"],
["user roles","User Roles"],
["Confirm Password","Confirm Password"],
["role type","Role Type"],
["user role name","User Role Name"],
["employee action","Employee Action"],
["add employee","Add Employee"],
["terminate employee","Terminate Employment"],
["delete employee","Delete Employee"],
["workflow management","Workflow Management"],
["leave","Leave"],
["vacancy","Vacancy"],
["on-boarding event","On-boarding - Event"],
["travel request","Travel Request"],
["attendance","Attendance"],
["discipline","Discipline"],
["expense claim","Expense Claim"],
["time","Time"],
["performance","Performance"],
["on-boarding task","On-boarding - Task"],
["candidate","Candidate"],
["asset","Asset"],
["data group permissions","Data Group Permissions"],
["admin - employee competencies",""],
["admin - job titles",""],
["admin - ldap configuration",""],
["admin - pay grades",""],
["admin - process eeo filing",""],
["admin - security authentication configuration",""],
["admin - social media configuration",""],
["admin - system users",""],
["advanced training - course list",""],
["advanced training - edit course attachment",""],
["advanced training - edit session attachments",""],
["advanced training - manage report details",""],
["advanced training - participating session list",""],
["advanced training - session list",""],
["asset - brands",""],
["asset - categories",""],
["asset - details",""],
["asset - vendor",""],
["discipline - cases",""],
["discipline - configure - document templates",""],
["leave - bradfordfactor threshold",""],
["leave - calendar configuration",""],
["leave - holidays",""],
["leave - leave bradford factor report",""],
["leave - leave calendar",""],
["leave - leave entitlements",""],
["leave - leave entitlements and usage report",""],
["leave - leave list",""],
["leave - leave list - comments",""],
["leave - leave notifications",""],
["leave - leave period",""],
["leave - leave types",""],
["leave - work week",""],
["leave - working weekends",""],
["maintenance - purge candidate records",""],
["maintenance - purge employee records",""],
["on-boarding - event",""],
["on-boarding - reports",""],
["on-boarding - task",""],
["on-boarding - task types",""],
["performance - appraisal configuration evaluators",""],
["performance - appraisal configuration form format",""],
["performance - appraisal configuration header template",""],
["performance - appraisal configuration questions",""],
["performance - appraisal cycle",""],
["performance - competency mappings",""],
["performance - employee appraisals",""],
["performance - reports",""],
["performance - my appraisals",""],
["performance - goals",""],
["pim - bulk update",""],
["pim - configure - document templates",""],
["pim - configure - add employee wizard",""],
["pim - contact details",""],
["pim - contact details - attachments",""],
["pim - contact details - custom fields",""],
["pim - dependents",""],
["pim - dependents - attachments",""],
["pim - dependents - custom fields",""],
["pim - emergency contacts",""],
["pim - emergency contacts - attachments",""],
["pim - emergency contacts - custom fields",""],
["pim - employee data export",""],
["pim - employee photograph",""],
["pim - employee subordinates",""],
["pim - employee supervisors",""],
["pim - employee supervisors/subordinates - attachment",""],
["pim - employee supervisors/subordinates - custom fields",""],
["pim - immigration",""],
["pim - immigration - attachments",""],
["pim - immigration - custom fields",""],
["pim - job",""],
["pim - job - attachments",""],
["pim - job - custom fields",""],
["pim - job - history",""],
["pim - membership",""],
["pim - membership - attachments",""],
["pim - membership - custom fields",""],
["pim - personal details",""],
["pim - personal details - attachments",""],
["pim - personal details - custom fields",""],
["pim - qualifications - attachments",""],
["pim - qualifications - custom fields",""],
["pim - qualifications - education",""],
["pim - qualifications - languages",""],
["pim - qualifications - license",""],
["pim - qualifications - skills",""],
["pim - qualifications - work experience",""],
["pim - reports",""],
["pim - salary",""],
["pim - salary - attachments",""],
["pim - salary - custom fields",""],
["pim - salary - history",""],
["pim - social media details",""],
["pim - work week",""],
["recruitment - candidates",""],
["recruitment - configuration",""],
["recruitment - equest",""],
["recruitment - reports",""],
["recruitment - vacancies",""],
["reports catalog - employee acknowledgements of news and documents report",""],
["time - activity info - common activities",""],
["time - activity info - customers",""],
["time - activity info - projects",""],
["time - attendance configuration",""],
["time - attendance export to csv",""],
["time - attendance records",""],
["time - attendance summary",""],
["time - configuration - timesheet periods",""],
["time - employee reports",""],
["time - employee timesheets",""],
["time - monthly attendance report",""],
["time - pay code report",""],
["time - project reports",""],
["time - timesheet export to csv",""],
["travel and expense - currency exchange rate",""],
["travel and expense - eligibility groups",""],
["travel and expense - expense claims",""],
["travel and expense - expense type",""],
["travel and expense - reports",""],
["travel and expense - travel request",""],
["other - system configuration - list configuration",""],
["job","Job"],
["jobTitle","Job Titles"],
["salary","Salary"],
["title","Title"],
["jobDescription","Job Description"],
["attachment","Attachment"],
["accepteUpTO2MB","Accept upto 2MB"],
["includeAttachment","Include Attachment"],
["noAttachment","No attachment"],
["attach","Attach"],
["comment","Comment"],
["comments","Comments"],
["noAttachmentFound","No Attachments Found"],
["addedOn" , "Added On:"],
["salaryComponents","Salary Components"],
["componentName","Componenet Name"],
["type","Type"],
["partOfTotalPayable","Part of Total Payable"],
["costToCompany","Cost to Company"],
// from Backend ["earning",""],
// ["deduction",""],
["valueType","Value Type"],
["addTo","Add To"],
// form Backend["amount",""],
// ["percent",""],
["payGrades","Pay Grades"],
["currency","Currency"],
["curCode","Currency Code"],
["payGrade","Pay Grade"],
["assignCurrency","Assign Currency"],
["currencyName","Currency Name"],
["minimumSalary","Minimum Salary"],
["maximumSalary","Maximum Salary"],
["minimumValue","Minimum Value"],
["maximumValue","Maximum Value"],
["delete","Delete"],
["employmentStatus","Employment Status"],
["employeeStatus","Employee Status"],
["jobCatagories","Job Catagories"],
["jobDepartment","Job Department"],
["workShifts","Work Shifts"],
["workShift","Work Shift"],
["from","From"],
["to","To"],
["hoursPerDay","Hours Per Day"],
["terminationReasons","Termination Reasons"],
["terminationReason","Termination Reason"],
["reportingMethods","Reporting Methods"],
["reportingMethod","Reporting Method"],
["documentTemplates","Document Templates"],
["templateName","Template Name"],
["category","Category"],
["template","Template"],
["documentTemplate","Document Template"],
["competencyList","Competency List"],
["competency","Competency"],
["parent","Parent"],
["markAsGroup","Mark As Group"],
["organization",""],
["generalInformation","General Information"],
["name","Name"],
["taxId","Tax ID"],
["nof","Number Of Employees"],
["registrationNumber","Registration Number"],
["establishedDate","Established Date"],
["companyType","Company Type"],
["organizationEmail","organizationEmail"],
["website","Website"],
["country","Country"],
["addressLine1","Address Line 1"],
["addressLine2","Address Line 2"],
["addressLine3","Address Line 3"],
["city","City"],
["state","State"],
["postalCode","PostalCode"],
["landmark","Landmark"],
["telephoneNumber","TelephoneNumber"],
["mobile","Mobile"],
["faxNumber","Fax Number"],
["costCenter","Cost Center"],
["costCenterStatus","Cost Center Status"],
["locations","Locations"],
["location","Location"],
["phone","Phone"],
["address","Address"],
["timeZone","Time Zone"],
["state/province","State/Province"],
["zip/postal code","Zip/Postal code"],
["fax","Fax"],
["region","Region"],
["description","Description"],
["structure","Structure"],
["unitId","Unit Id"],
["headOfUnit","Head Of Unit"],
["costCenters","Cost Centers"],
["costCenterId","Cost Center ID"],
["qualification","Qualification"],
["skills","Skills"],
["skill","skill"],
["education","Education"],
["membership","Membership"],
["licenses","Licenses"],
["license","License"],
["announcements",""],
["news","News"],
["archive","Archive"],
["topic","Topic"],
["publishDate","Published Date"],
["publishedLocations","Published Locations"],
["publishedUserRoles","Published User Roles"],
["publishedJobTitles","Published Job Titles"],
["publishedSubunits","Published Subunits"],
["publishedEmployees","Published Employees"],
["attachments","Attachments"],
["ackStatus","ACK status"],
["acKnowledged","Acknowledged"],
["dateAcknowledged","Date Acknowledged"],
["request acknowledgment",""],
["publish setting",""],
["publish to all user roles",""],
["publish to all location",""],
["sub unit",""],
["job title",""],
["publish",""],
["previous",""],
["documents","Documents"],
["document catagories",""],
["configuration",""],
["directory configuration",""],
["field name",""],
["enable","Enable"],
["disable","Disable"],
["employee id",""],
["street 1 ",""],
["street 2",""],
["province",""],
["employee home telephone",""],
["employee work telephone",""],
["employee mobile",""],
["employee work email",""],
["employee other email",""],
["employee facebook contact",""],
["employee twitter contact",""],
["employee linkedin contact",""],
["email settings",""],
["sender mail",""],
["sender name",""],
["sending method",""],
["smtp host",""],
["smtp port",""],
["use smtp authentication",""],
["smtp user",""],
["smtp password",""],
["use secure connection",""],
["send test email",""],
["required feild",""],
["email notifications",""],
["action based",""],
["date based",""],
["scheduled",""],
["template name",""],
["subscribers",""],
["template settings",""],
["notification",""],
["enabled",""],
["templates",""],
["to/cc",""],
["affected employee",""],
["notify employee",""],
["notified",""],
["subscriber",""],
["localization",""],
["language",""],
["date format",""],
["authentication",""],
["default",""],
["default status",""],
["users can reset the password",""],
["admins can reset the password",""],
["enable secondary password",""],
["notify employee to to set password upon user creation",""],
["block access when failed attempts exceeds",""],
["months",""],
["load",""],
["ldap server",""],
["ldap port",""],
["ldap implementation",""],
["ldap protocol ",""],
["ldap filter fields ",""],
["enable ldap authentication",""],
["use anonymous binding",""],
["audit trail",""],
["date and time",""],
["action owner",""],
["action",""],
["employee",""],
["section",""],
["action description",""],
["module",""],
["longitude","Longitude"],
["latitude","Latitude"],
["punchRadius","Punch In/Out Radius"],
["downloadQRCode","Download QR Code"],
//export timesheet
["empId","Employee ID"],
["empName","Employee Name"],
["day.date","Date"],
["day.duration","Duration (HH:MM)"],
["day.comment","Comment"],
["status", "Status"],
["wsd", "Week Start Date"],
["wed", "Week End Date"],
["customerName", "Customer Name"],
["projectName", "Project Name"],
["activityName", "Activity Name"],
["jobTitle", "Job Title"],  
["joinedDate", "Joined Date"],  
["location", "Location"],  
["supervisor", "Supervisor"],  
["jobTitleName", "Job Title"],  
["locationName", "Location"],  
["supervisorName", "Supervisor"],  
["total", "Total Duration (HH:MM)"],
//attendance records list
["duration", "Duration (HH:MM)"],
["recordBy", "Record By"],
["time", "Time"],
["payroll", "Payroll"],
["payslip", "Payslip"],
["print", "Print"],
["value", "Value"],
["percentage", "Percentage"],
["close", "Close"],
["download", "Download"],
["taxable", "Taxable"],
["attendancePolicy", "Attendance Policy"]
]);
   
}