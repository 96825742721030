<div class="card {{ fullCard }} {{ cardLoad }}" [@cardClose]="cardClose" [ngClass]="cardClass">
  <div class="card-header ripple" *ngIf="title">
    <h5>{{ title }}</h5>
    <span *ngIf="!classHeader">{{ headerContent }}</span>
    <span *ngIf="classHeader">
      <ng-content select=".code-header"></ng-content>
    </span>
    <!--<div class="card-header-right" *ngIf="!cardOptionBlock">-->
      <!--<ul class="list-unstyled card-option" [@cardIconToggle]="cardIconToggle">-->
        <!--<li *ngIf="!isCardToggled" (click)="toggleCardOption()"><i class="fa fa open-card-option fa-wrench"></i></li>-->
        <!--<li *ngIf="isCardToggled" (click)="toggleCardOption()"><i class="fa fa open-card-option fa-times"></i></li>-->
        <!--<li><i class="fa {{ fullCardIcon }} full-card" (click)="fullScreen($event)"></i></li>-->
        <!--<li><i class="fa fa-minus minimize-card" appCardToggleEvent (click)="toggleCard($event)"></i></li>-->
        <!--<li><i class="fa fa-refresh reload-card" (click)="cardRefresh($event)" ></i></li>-->
        <!--<li><i class="fa fa-trash close-card" (click)="closeCard($event)"></i></li>-->
      <!--</ul>-->
    <!--</div>-->
  </div>

  <div [@cardToggle]="cardToggle" class="card-block-hid" style="overflow: unset;">
    <div class="card-block" [ngClass]="blockClass">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="card-loader" *ngIf="loadCard"><i class="fa fa-spinner rotate-refresh"></i></div>
</div>
