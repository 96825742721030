<section class="login-block">
    <div class="container">
        <div class="row">
            <div class="col-md-12" style="display: flex; justify-content: center">
                <img id="login-header" class="img-fluid" src="assets/team.png" />

            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="auth-box card">
                    <div class="card-block">
                        <div class="row">
                            <div class="col-md-12">
                                <img id="login-header" class="img-fluid" src="assets/ibiss.png" />
                            </div>
                        </div>
                    </div>
                    <div class="card-block" *ngIf="!showResult">
                        <form class="full-width-inputs form-material" action="javascript:" [formGroup]="requestForm">

                            <div class="row">
                                <div class="col-md-12">
                                    <p>Thank you for selecting us as your HR partner. Kindly fill out the necessary
                                        details below. A member of our team will reach out to you soon to establish your
                                        business profile and enrol your employees. This process will enable us to
                                        provide you with access to your own HR portal on ScreenHRM.</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Name</mat-label>
                                        <input matInput name="name" type="text" formControlName="name"
                                            class="form-control" pattern="^[a-zA-Z]+[ ][a-zA-Z]+.*$" required
                                            placeholder="firstname lastname" />
                                        <mat-error>Please enter first and last names seperated by space</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Email</mat-label>
                                        <input matInput name="email" type="email" formControlName="email" email
                                            class="form-control" required placeholder="name@company.com" />
                                        <mat-error>Please enter valid email</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Company Name</mat-label>
                                        <input matInput name="company" type="email" formControlName="company"
                                            class="form-control" required />
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Phone No</mat-label>
                                        <input matInput name="phone" type="number" formControlName="phone"
                                            class="form-control" />
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="row m-t-20">
                                <div class="col-md-12">
                                    <button type="submit"
                                        class="btn btn-primary btn-md btn-block waves-light text-center m-b-20 ripple"
                                        (click)="add()" [class.spinner]="isLoading" [disabled]="isLoading">
                                        Submit
                                    </button>
                                </div>
                            </div>
                            <hr />

                        </form>
                    </div>
                    <div class="card-block" *ngIf="showResult">
                        <div>
                            <div class="col-md-12">
                                <p>Thank you, we will contact you soon.</p>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div class="card-block">
                        <div class="row copyright-row">
                            <div class="col-md-12">
                                <p class="text-inverse text-left m-b-0">Screen Hrm</p>
                                <p class="text-inverse text-left m-b-0">©2024 SCREEN HRM. All rights reserved.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>