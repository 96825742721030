import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import moment from "moment";
import { LanguageConfig } from "src/app/Language-config";
import { DashboardService } from "../../dashboard.service";
import { saveAs } from "file-saver-es";
import { NotificationService } from "src/app/core/services/notification.service";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { PayrollService } from "../payroll.service";

@Component({
  selector: "app-payroll-report",
  templateUrl: "./payroll-report.component.html",
  styleUrls: ["./payroll-report.component.scss"],
})
export class PayrollReportComponent implements OnInit {
  public isLoading: boolean;
  public hasResult: boolean = false;
  public months = moment.months();
  public years: Array<any>;
  public selectedMonth = "";
  public selectedYear = "";

  public payrolls: Array<any> = [];
  public selectedPayroll: any;

  // displayedColumns = ["empId", "empName", "grossSalary", "tax", "totalDeductions", "netPay"];
  displayedColumns: Array<string>;
  footerColumns: Array<string>;
  dataSource: MatTableDataSource<any>;
  public totalRecords: number;
  public activePage: number;
  public pageSize: number;

  public lang = new Map();
  public totals: Map<string, any>;
  public all: Array<Map<string, any>>;
  generating: boolean;

  @ViewChild("confirmDialog", { static: false }) confirmDialog;
  @ViewChild("payrollContent", { static: false }) payrollContent: ElementRef;

  private dialogRef: any;

  constructor(private payrollService: PayrollService, private dialog: MatDialog, private toastr: NotificationService) {
    this.lang = LanguageConfig.map;
  }

  ngOnInit(): void {
    this.years = new Array();
    this.years.push(new Date().getFullYear());

    this.payrollService.getAll().subscribe(
      (res) => {
        if (res && res.data) {
          this.payrolls = res.data;
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error while getting payroll configuration");
      }
    );
  }

  public getPayrollReport() {
    this.isLoading = true;
    // let month = this.selectedMonth? this.selectedMonth : '';
    // let year = this.selectedYear? this.selectedYear: '';
    this.payrollService.getPayrollReport(this.selectedPayroll.id, this.selectedMonth, this.selectedYear).subscribe(
      (res) => {
        if (res && res.data && res.data.length > 0) {
          // this.totals = new Map(Object.entries(res.data.shift()));
          // this.footerColumns = Array.from( this.totals.keys());
          this.displayedColumns = Array.from(new Map(Object.entries(res.data[0])).keys());
          this.footerColumns = this.displayedColumns.map((x) => `T_${x}`);
          this.all = res.data;
          this.dataSource = new MatTableDataSource<any>(res.data);
          this.hasResult = true;
          this.isLoading = false;
        } else {
          this.isLoading = false;
          this.hasResult = false;
          this.toastr.error("No report match the below criteria");
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error while getting payroll report");
      }
    );
  }

  public getTotal(col) {
    col = col.replace("T_", "");
    if (col == "Employee Id") {
      return "Totals";
    } else if (col == "Employee Name") {
    } else {
      return this.all.map((t) => t[col]).reduce((acc, value) => acc + value);
    }
    // return this.totals.get(col);
  }

  public getColspan(col) {
    return col === "  Totals" ? "2" : "3";
  }

  handlePageEvent(event: PageEvent) {
    // this.pageSize = event.pageSize;
    // this.activePage = event.pageIndex;
    // this.viewAttendanceSummary();
  }

  public generate() {
    this.generating = true;
    this.isLoading = true;
    this.payrollService.getPayrollReport(this.selectedPayroll.id, this.selectedMonth, this.selectedYear).subscribe(
      (res) => {
        if (res && res.data && res.data.length > 0) {
          this.isLoading = false;
          this.generating = false;
          this.showConfirm();
        } else {
          this.confirmGenerate();
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error while getting payroll report");
      }
    );
  }

  showConfirm() {
    this.dialogRef = this.dialog.open(this.confirmDialog, {
      width: "440px",
      height: "auto",
    });
  }

  close() {
    this.dialogRef.close();
  }

  public confirm() {
    this.close();
    this.confirmGenerate();
  }

  private confirmGenerate() {
    this.generating = true;
    this.isLoading = true;
    this.payrollService.generatePayslips(this.selectedPayroll.id, this.selectedMonth, this.selectedYear).subscribe(
      (res) => {
        if (res) {
          this.generating = false;
          this.toastr.success(res.msg);
          this.getPayrollReport();
        }
      },
      (error) => {
        this.generating = false;
        this.isLoading = false;
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error in generating.");
      }
    );
  }

  public saveAsPDF() {
    this.isLoading = true;
    const pdf = new jsPDF();
    const htmlContent = this.payrollContent.nativeElement;
    html2canvas(htmlContent, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png", 1.0);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Add header text
      pdf.setFontSize(18);
      pdf.setFont("helvetica", "bold");
      pdf.text(this.selectedPayroll.name, pdfWidth / 2, 30, { align: "center" });

      pdf.setFontSize(14);
      pdf.text(this.selectedMonth + " " + this.selectedYear, pdfWidth / 2, 40, { align: "center" });

      // Add image with padding
      const imgWidth = pdfWidth - 20; // Adjust padding
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 10, 60, imgWidth, imgHeight);
      pdf.save(this.selectedMonth + "_" + this.selectedYear + "_payroll.pdf");
      this.isLoading = false;
    });
  }

  exportToCSV() {
    const csv = this.dataSource.filteredData.map((item) => {
      const record: Array<any> = [];
      this.displayedColumns.forEach((col) => {
        record.push(item[col]);
      });
      return record.join(",") + "\n";
    });

    csv.unshift(this.displayedColumns.join(",") + "\n");

    const totals: Array<any> = [];
    this.footerColumns.forEach((col) => {
      totals.push(this.getTotal(col));
    });
    csv.push(totals.join(",") + "\n");
    const exportFileName = this.selectedMonth + "_" + this.selectedYear + "_payroll.csv";
    saveAs(new Blob(csv, { type: "text/csv;charset=utf-8" }), exportFileName);
  }
}
