import { Component, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { DashboardService } from "../../../dashboard.service";
// import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { NotificationService } from "src/app/core/services/notification.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { saveAs } from 'file-saver-es';
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { MatCheckbox, MatCheckboxChange } from "@angular/material/checkbox";
import { LanguageConfig } from "src/app/Language-config";
import { AuthService } from "src/app/core/services/auth.service";

@Component({
  selector: "app-salary",
  templateUrl: "./salary.component.html",
  styleUrls: ["./salary.component.scss"],
})
export class SalaryComponent implements OnInit {
  public filterQuery = "";
  public userList: Array<any>;
  public isLoading: boolean;
  public isUserAdded: boolean;
  public err: string;
  public userForm: FormGroup;
  public isEdit: boolean;
  public editId: string;
  public valueType: Array<any>;
  public addTo: Array<any>;
  public type: Array<any>;
  public taxable: boolean;
  public readonly: boolean;
  public submitted = false;
  public addToString: Array<any>;
  public dialogRef: any;
  selectedUsers: any[] = [];
  public lang = new Map();
  public validationError = {
    name: "Required",
    type: "Required",
    valueType: "Required",
    addTo: "Required",
  };
  selectedCheckBoxList = [];
  @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
  @ViewChild("addUser", { static: false }) addUser;
  @ViewChild("deleteUser", { static: false }) deleteUser;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // material data table
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  public columns = ["select", "name", "type", "valueType", "taxable", "edit"];

  constructor(
    private service: DashboardService,
    // private modalService: NgbModal,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private toastr: NotificationService,
    private authService: AuthService
  ) {
    this.createForm();
    this.lang = LanguageConfig.map;
  }
  public permissions: any;
  public read = false;
  public write = false;
  public update = false;
  public delete = false;

  checkPermission(module, tabs): void {
    this.permissions = JSON.parse(this.authService.getPermissions());

    this.read = false;
    this.write = false;
    this.delete = false;
    this.update = false;
    if (this.permissions != undefined && this.permissions != null) {
      for (let p of this.permissions) {
        if (p.id === module) {
          if (p.modules != undefined) {
            for (let tab of p.modules) {
              if (tab.id === tabs) {
                for (let move of tab.permits) {
                  if (move.id === "u") {
                    this.update = true;
                  } else if (move.id === "r") {
                    this.read = true;
                  } else if (move.id === "c") {
                    this.write = true;
                  } else if (move.id === "d") {
                    this.delete = true;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ngOnInit() {
    this.isLoading = true;
    this.isEdit = false;
    this.isUserAdded = false;
    this.checkPermission("admin", "ad_jb");
    this.getFormValue();
    this.getSalaryList();
  }

  public getFormValue(): void {
    this.service.getSalaryFormList().subscribe(
      (resp) => {
        if (resp && resp.data) {
          const data = resp.data;
          this.valueType = data.valueType;
          this.addTo = data.addTo;
          this.addTo.map((a) => {
            a.checked = "";
          });
          this.type = data.type;
          this.taxable = data.taxable;
          this.readonly = data.basic;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  public createForm(): void {
    this.userForm = this.fb.group({
      name: [""],
      type: [""],
      valueType: [""],
      addTo: [""],
      taxable: "",
    });
  }

  public addNewUser() {
    this.isEdit = false;
    this.dialogRef = this.dialog.open(this.addUser, {
      width: "440px",
      height: "auto",
    });
    this.userForm.reset();
  }

  public close(): void {
    this.dialogRef.close();
  }

  public getSalaryList(): void {
    this.service.getSalaryList().subscribe(
      (user) => {
        if (user && user.data) {
          this.isLoading = false;
          this.userList = user.data;
          this.err = "";
          this.userList.map((u) => {
            u.addToString = "";
            for (let i = 0; i < u.addTo.length; i++) {
              if (u.addToString !== "") {
                u.addToString = u.addToString + "," + u.addTo[i]; // this fails since the
              } else {
                u.addToString = u.addTo[i];
              }
            }
            if (u.addToString.indexOf("C_C") !== -1) {
              u.cc = "Yes";
            } else {
              u.cc = "No";
            }
            if (u.addToString.indexOf("TP") !== -1) {
              u.vt = "Yes";
            } else {
              u.vt = "No";
            }
          });
          this.dataSource = new MatTableDataSource<any>(this.userList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      },
      (error) => {
        const err = error && error.error && error.error.msg;
        this.isLoading = false;
        this.err = err || "Error while getting Salary";
      }
    );
  }

  invalidComponent() {
    return this.submitted && this.userForm.controls.name.errors != null;
  }

  invalidType() {
    return this.submitted && this.userForm.controls.type.errors != null;
  }

  public submitAddSalary(): void {
    this.submitted = true;
    if (this.userForm.invalid === true) {
      return;
    }
    if (this.isEdit) {
      this.edit();
    } else {
      this.add();
    }
  }

  public add(): void {
    this.isUserAdded = true;
    const addTo = [];
    this.addTo.map((a) => {
      if (a.checked) {
        addTo.push(a.id);
      }
    });
    const val = this.userForm.value;
    val.addTo = addTo;
    val.taxable = this.taxable;
    this.service.addSalary(val).subscribe(
      (user) => {
        this.isUserAdded = false;
        this.toastr.success("Salary added successfully");
        this.dialogRef.close();
        // this.modalRef.close();
        this.userForm.reset();
        this.getSalaryList();
      },
      (error) => {
        const err = error && error.error && error.error.msg;
        this.isUserAdded = false;
        this.toastr.error(err || "Error in adding Salary.Please try again.");
      }
    );
  }

  public edit(): void {
    this.isUserAdded = true;
    const addTo = [];
    this.addTo.map((a) => {
      if (a.checked) {
        addTo.push(a.id);
      }
    });
    const val = this.userForm.value;
    val.addTo = addTo;
    val.taxable = this.taxable;
    this.service.editSalary(val, this.editId).subscribe(
      (user) => {
        this.isUserAdded = false;
        this.toastr.success("Salary record updated successfully");
        this.dialogRef.close();
        // this.modalRef.close();
        this.getSalaryList();
      },
      (error) => {
        const err = error && error.error && error.error.msg;
        this.isUserAdded = false;
        this.toastr.error(err || "Error in updating Salary.Please try again.");
      }
    );
  }

  public editUser(id): void {
    this.isEdit = true;
    this.isUserAdded = true;
    this.dialogRef = this.dialog.open(this.addUser, {
      width: "440px",
      height: "auto",
    });
    this.service.getSalaryById(id).subscribe(
      (user) => {
        if (user && user.data) {
          this.userForm.patchValue(user.data);
          this.addTo.map((a) => {
            if (user.data.addTo.indexOf(a.id) !== -1) {
              a.checked = true;
            }
          });
          this.isUserAdded = false;

          // this.modalRef = this.modalService.open(this.addUser);
          this.editId = id;
          this.taxable = user.data.taxable;
          this.readonly = user.data.basic;
        }
      },
      (error) => {
        this.isUserAdded = false;
        const err = error && error.error && error.error.msg;
        this.toastr.error(err || "Error getting Salary");
      }
    );
  }

  public deleteJobTitle(): void {
    this.service.deleteSalaryList(this.selectedCheckBoxList).subscribe(
      (user) => {
        if (user) {
          this.close();
          this.getSalaryList();
          this.toastr.success("Salary record deleted successfully");
        }
      },
      (error) => {
        const err = error && error.error && error.error.msg;
        this.toastr.error(err || "Error deleting Salary");
        this.close();
      }
    );
    this.selectedCheckBoxList = [];
  }

  onCheckBoxChange($event: MatCheckboxChange, id: any) {
    if (!this.alreadySelected($event.source) && $event.checked) {
      this.selectedCheckBoxList.push($event.source.value);
    } else if (!$event.checked) {
      this.selectedCheckBoxList = this.selectedCheckBoxList.filter((value) => {
        return value !== $event.source.value;
      });
    }
  }

  alreadySelected(item: MatCheckbox): boolean {
    this.selectedCheckBoxList.forEach((value) => {
      if (item === value) {
        return true;
      }
    });
    return false;
  }

  onSelectAllClicked() {
    this.selectedCheckBoxList = [];
    this.matCheckBoxes.forEach((item) => {
      this.selectedCheckBoxList.push(item.value);
      item.checked = true;
    });
  }

  onDeSelectAllClicked() {
    this.selectedCheckBoxList = [];
    this.matCheckBoxes.forEach((item) => {
      item.checked = false;
    });
  }

  onDeleteSelected() {
    this.dialogRef = this.dialog.open(this.deleteUser, {
      width: "440px",
      height: "auto",
    });

    // this.modalRef = this.modalService.open(this.deleteUser);
  }
  onUserChanged(user: HTMLInputElement) {
    if (user.checked) {
      this.selectedUsers.push(user);
      return;
    }
    this.selectedUsers = this.selectedUsers.filter((value) => value !== user);
  }
  onModelChange(value: string) {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
  exportToCsv() {
    const csv = this.dataSource.filteredData.map((row) => {
      return [row.name, row.type === "E" ? "Earning" : "Deduction", row.vt, row.cc].join(",") + "\n";
    });
    csv.unshift(["Component Name", "type", "Part of Total Payable", "Cost to Company"].join(",") + "\n");
    const exportDate = new Date();
    const exportFileName = `Csv-export-${exportDate.getFullYear()}-${
      exportDate.getMonth() + 1
    }-${exportDate.getDate()}.csv`;
    saveAs(new Blob(csv, { type: "text/csv;charset=utf-8" }), exportFileName);
  }

  saveAsPdf() {
    if (this.dataSource.filteredData.length <= 0) {
      this.toastr.error("No Data to export");
      return;
    }
    const body = [];
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      body.push([
        this.dataSource.filteredData[i].name,
        this.dataSource.filteredData[i].type === "E" ? "Earning" : "Deduction",
        this.dataSource.filteredData[i].vt,
        this.dataSource.filteredData[i].cc,
      ]);
    }
    const doc = new jsPDF();
    autoTable(doc, {
      head: [["Component Name", "type", "Part of Total Payable", "Cost to Company"]],
      body: body,
    });
    const exportDate = new Date();
    const exportFileName = `salary-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}}`;
    doc.save(exportFileName);
  }
}
