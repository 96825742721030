<div id="header-page" class="ng-star-inserted header-page">
    <div class="page-header ng-star-inserted">
        <div class="page-block ng-star-inserted">
            <div class="row align-items-center">
                <div class="col-md-8">
                    <div class="page-header-title">
                        <span class="ng-star-inserted"></span><span class="ng-star-inserted">
                            <h4 class="m-b-10 ng-star-inserted">Categories</h4>
                        </span>
                    </div>
                </div>
                <div class="col-md-4"></div>
            </div>
        </div>
    </div>
</div>
<div class="page-body no-title">
    <div class="row">
        <div class="col-sm-12">

            <div class="row top-tools">

                <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6 left-tools">
                    <span class="export-icon-btn csv" (click)="exportToCsv()"><i
                            class="material-icons">get_app</i><span>CSV</span>
                    </span>
                    <span class="export-icon-btn pdf" (click)="saveAsPdf()"><i
                            class="material-icons">get_app</i><span>PDF</span>
                    </span>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 right-tools form-material form-group">
                    <div class="relative-position search-filter-con action-buttons">
                        <!-- <span class="icon material-icons ng-tns-c114-0"> search </span>
                        <mat-form-field>
                            <input matInput #inputSearch type="search" [(ngModel)]="filterQuery" maxlength="30"
                            (ngModelChange)="onModelChange(filterQuery)"
                            class="form-control input-sm full-data-search">    
                        </mat-form-field> -->


                        <span class="mytooltip cus-left tooltip-effect-4" [hidden]="!write">
                            <span class="tooltip-item">
                                <button mat-mini-fab class="btn btn-primary light add-rounded margin-button-05"
                                    (click)="openModel()">
                                    <i class="fa fa-plus"></i>
                                </button>
                            </span>
                            <span class="tooltip-content clearfix">
                                <span class="tooltip-text">New</span>
                            </span>
                        </span>
                        <span class="mytooltip cus-left tooltip-effect-4">
                            <span class="tooltip-item">
                                <button mat-mini-fab class="btn btn-primary light add-rounded margin-button-05"
                                    (click)="openFilterForm()">
                                    <i class="fa fa-filter"></i>
                                </button>
                            </span>
                            <span class="tooltip-content clearfix">
                                <span class="tooltip-text">Filter</span>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div id="chart_div">


                <div class="row form-group">
                    <div class="col-md-3">
                        &nbsp;
                        <span>
                            <mat-icon class="in-stock-icon">lens</mat-icon>
                            <span class="aligned-with-icon">In-stock</span>
                        </span>
                    </div>
                    <div class="col-md-3">
                        &nbsp;
                        <mat-icon class="in-use-icon">lens</mat-icon>
                        <span class="aligned-with-icon">In-use</span>
                    </div>
                    <div class="col-md-3">
                        &nbsp;
                        <mat-icon class="decommissioned-icon">lens </mat-icon>
                        <span class="aligned-with-icon">Decommissioned</span>
                    </div>
                    <div class="col-md-3">
                        &nbsp;
                        <mat-icon class="Stolen-icon">lens</mat-icon>

                        <span class="aligned-with-icon">Stolen</span>
                    </div>
                </div>


                <app-card>

                    <mat-card-content>

                        <mat-table [dataSource]="dataSource" matSort>
                            <ng-container matColumnDef="select">
                                <mat-header-cell *matHeaderCellDef [hidden]="!delete">
                                    <a [matMenuTriggerFor]="menu" class="check-all">
                                        <span class="material-icons">
                                            more_vert
                                        </span>
                                    </a>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="onSelectAllClicked()">Select All</button>
                                        <button (click)="onDeSelectAllClicked()" *ngIf="selectedCategories.length > 0"
                                            mat-menu-item>Deselect All
                                        </button>
                                        <button (click)="onDeleteSelected()" *ngIf="selectedCategories.length > 0"
                                            mat-menu-item>Delete Selected
                                        </button>
                                    </mat-menu>
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row" [hidden]="!delete">
                                    <mat-checkbox #matCheckbox (change)="onNewDocSelectChange($event, row.id)"
                                        value="{{row.id}}"></mat-checkbox>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="categoryId">
                                <mat-header-cell mat-sort-header *matHeaderCellDef>ID</mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                    {{row.categoryId}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="name">
                                <mat-header-cell mat-sort-header *matHeaderCellDef>Category</mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                    {{row.name}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="total">
                                <mat-header-cell mat-sort-header *matHeaderCellDef>Total</mat-header-cell>
                                <mat-cell *matCellDef="let row">{{row.total}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Count">
                                <mat-header-cell *matHeaderCellDef>Count</mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                    <div class="bar" [hidden]="row.hideBar">
                                        <div [hidden]="!row.isStock" [style.width.%]="row.inStockWidth" class="fifty">
                                            <p>{{row.inStockCount}}</p>
                                        </div>
                                        <div [hidden]="!row.isInUse" [style.width.%]="row.inUseWidth"
                                            class="twentyfive">
                                            <p>{{row.inUseCount}}</p>
                                        </div>
                                        <div [hidden]="!row.isDec" [style.width.%]="row.decommissionWidth" class="ten">
                                            <p>{{row.decommissionCount}}</p>
                                        </div>
                                        <div [hidden]="!row.isStole" [style.width.%]="row.markStolenWidth"
                                            class="twiny">
                                            <p>{{row.markStolenCount}}</p>
                                        </div>

                                    </div>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="edit">
                                <mat-header-cell *matHeaderCellDef [hidden]="!update">Edit</mat-header-cell>
                                <mat-cell *matCellDef="let row" [hidden]="!update">
                                    <span class="mytooltip tooltip-effect-4">
                                        <span class="tooltip-item"><i class="single-icon-click fa fa-pencil"
                                                (click)="itemClick(row)"></i></span>
                                        <span class="tooltip-content clearfix">
                                            <span class="tooltip-text">Edit</span>
                                        </span>
                                    </span>
                                </mat-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;">
                            </mat-row>
                        </mat-table>
                        <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
                        <!-- <mat-paginator [length]="paging.totalRecords" [pageSize]="paging.pageSize"
                                [pageSizeOptions]="paging.pageSizeOptions"
                                [pageIndex]="paging.pageIndex" (page)="pageChange($event)">
                    </mat-paginator> -->
                        <mat-paginator [pageSizeOptions]="[25, 50,10]" showFirstLastButtons></mat-paginator>
                    </mat-card-content>
                </app-card>

            </div>
        </div>
    </div>
</div>


<ng-template #categoryForm let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">

            <h4 class="mat-title-modal">
                <span [hidden]="this.isEdit">
                    Add
                </span>
                <span [hidden]="!this.isEdit">
                    Edit
                </span>
                Category
            </h4>
        </div>
        <div class="mat-modal-body">
            <form [hidden]="isSaving" class="form-material full-width-inputs" [formGroup]="newForm">
                <div class="row form-group">
                    <div class="col-md-6">
                        <mat-form-field class="form-element">
                            <mat-label> Id </mat-label>
                            <input required matInput class="form-control" formControlName="categoryId">
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field class="form-element">
                            <mat-label> Name </mat-label>
                            <input required matInput class="form-control" formControlName="name">
                        </mat-form-field>
                    </div>
                </div>
            </form>
            <app-loading *ngIf="isSaving" [isLoading]="isSaving"></app-loading>
            <div class="row">
                <div class="multi-btns action-buttons col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close()" *ngIf="!isSaving">
                        <i class="flaticon-cancel"></i> Cancel</button>
                    <button [disabled]="!this.newForm.valid" mat-raised-button color="primary" class="btn-primary"
                        (click)="save()" *ngIf="!isSaving"><i class="flaticon-diskette"></i>Save</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #deleteUserSelected let-modal>
    <!-- <div class="card my-0"> -->
    <div mat-dialog-actions>
        <!-- <div class="header">  -->
        <div class="mat-modal-header">
            <!-- <h4 class="card-title card-title-modal">Delete User</h4>  -->
            <h4 class="mat-title-modal">Delete Categories</h4>
        </div>
        <!-- <div class="card-content px-2 py-2"> -->
        <div class="mat-modal-body">
            <div class="text-left are-u">Are you sure ?</div>
            <div class="row">
                <div class="multi-btns action-buttons col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close()">
                        <i class="flaticon-cancel"></i> Cancel</button>
                    <button class="btn-primary" color="primary" mat-raised-button (click)="deleteMultiple()"><i
                            class="flaticon-trash"></i> Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #FilterForm let-modal>
    <div mat-dialog-action>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">Filter Categories</h4>
        </div>

        <div class="mat-modal-body">
            <form [hidden]="isSaving" class="form-material full-width-inputs" [formGroup]="filterForm">
                <div class="row form-group">
                    <div class="col-md-6">
                        <mat-form-field class="form-element">
                            <input matInput placeholder="Id" class="form-control" formControlName="id">
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field class="form-element">
                            <input matInput placeholder="Name" class="form-control" formControlName="name">
                        </mat-form-field>
                    </div>
                </div>
            </form>
            <app-loading *ngIf="isSaving" [isLoading]="isSaving"></app-loading>
            <div class="col-sm-12 text-right action-buttons multi-btns">
                <button mat-raised-button class="btn-danger reset-btn" (click)="onResetForm()">
                    <i class="flaticon-refresh"></i>Reset</button>
                <button mat-raised-button class="btn-danger" (click)="close()" *ngIf="!isSaving"><i
                        class="flaticon-cancel"></i>Cancel</button>
                <button mat-raised-button color="primary" class="btn-primary" (click)="filterVendor()"
                    *ngIf="!isSaving"><i class="flaticon-diskette"></i>Search</button>
            </div>
        </div>
    </div>
</ng-template>