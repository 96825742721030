import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { Observable } from "rxjs";

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class ClientControlService {
  constructor(private http: HttpClient) {}

  public getAll(body): Observable<any> {
    let url = "api/auth/client/list";
    return this.http.post(`${API_URL}${url}`, body);
  }

  public add(body): Observable<any> {
    let url = "api/auth/client/add";
    return this.http.post(`${API_URL}${url}`, body);
  }

  public delete(body): Observable<any> {
    let url = "api/auth/client/delete";
    return this.http.post(`${API_URL}${url}`, body);
  }

  public newCustomer(body): Observable<any> {
    let url = "api/auth/client/customer";
    return this.http.post(`${API_URL}${url}`, body);
  }
}
