<div class="page-body">
  <div class="row">
    <div class="col-sm-12">
      <app-card [title]="'Language'">
        <div class="dt-responsive" *ngIf="userList">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6">
              <div><label  class="label-control">Show
                  <select class="form-control input-sm full-data-show-entry" [(ngModel)]="rowsOnPage">
                    <option [ngValue]="5">5</option>
                    <option [ngValue]="10">10</option>
                    <option [ngValue]="25">25</option>
                    <option [ngValue]="50">50</option>
                    <option [ngValue]="100">100</option>
                  </select>
                  entries
                </label>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6">
              <div style="text-align: right;">
                <label class="float-label">Search:
                  <input type="search" maxlength="40" [(ngModel)]="filterQuery" class="form-control input-sm full-data-search" placeholder="Search name">
                </label>
                <button raised-button class="btn btn-primary light" (click)="addNewUser()"> + </button>
              </div>
            </div>
          </div>
          <app-loading *ngIf="isLoading && !err" [isLoading]="isLoading && !err"></app-loading>
          <div class="table-responsive">
            <!-- <table class="table table-striped table-bordered full-data-table" [mfData]="userList | dataFilter : filterQuery : 'name'" #mf="mfDataTable"
                   [mfRowsOnPage]="rowsOnPage" [(mfSortBy)]="sortBy" [(mfSortOrder)]="sortOrder">
              <thead>
              <tr>
                <th></th>
                <th>
                  <mfDefaultSorter  by="name">Name</mfDefaultSorter>
                </th>

                <th></th>
                <th></th>
              </tr>
              <tr>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let user of mf.data;let i = index;">
                <td>{{ i + 1 }}</td>
                <td>{{ user.name }}</td>
                <td><i class="fa fa-edit" (click)="editUser(user.id)"></i></td>
                <td><i class="fa fa-trash" (click)="deleteUserForm(user.id)"></i></td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td colspan="6">
                  <mfBootstrapPaginator class="pagination-main f-right"></mfBootstrapPaginator>
                </td>
              </tr>
              </tfoot>
            </table> -->
          </div>
          <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
        </div>
      </app-card>
    </div>
  </div>
</div>
<!-- <div class="card" >
  <div class="card-header card-header-warning" >
    <h4 class="card-title">Languages</h4>
    <p class="card-category">List of Languages</p>
  </div>
  <div class="card-content table-responsive table-full-width">
    <div class="add-user">
      <button raised-button class="btn btn-primary ripple light" (click)="addNewUser()"> + </button>
    </div>

    <app-loading *ngIf="isLoading && !err" [isLoading]="isLoading && !err"></app-loading>
    <table class="table" *ngIf="userList && !err && !isLoading">
      <thead>
        <th>#</th>
        <th *ngIf="tableConfig.name"><div (click)="sortData('name')" class="table-heading" >
          Name <i class="fa fa-sort ml-1"></i></div></th>
        <th>Edit</th>
        <th>Delete</th>
      </thead>
      <tbody>
        <tr *ngFor="let user of userList; let i = index;">
          <td>{{ i + 1 }}</td>
          <td>{{ user.name }}</td>

          <td><i class="fa fa-edit" (click)="editUser(user.id)"></i></td>
          <td><i class="fa fa-trash" (click)="deleteUserForm(user.id)"></i></td>
        </tr>
      </tbody>
    </table>
    <app-pagination (getNextPage)="getNextPage($event)" [activePage]="activePage" [totalRecords]="totalRecords" *ngIf="!isLoading && totalRecords"></app-pagination>
  </div>
  <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
</div> -->

<ng-template #addUser let-modal>
    <div class="card my-0">
      <div class="header">
        <h4 class="card-title card-title-modal"><span *ngIf="!isEdit">Add</span>
        <span *ngIf="isEdit">Edit</span> Languages</h4>
      </div>
      <div class="card-content">
      <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>

      <form class="form-material" action="javascript:" [formGroup]="userForm" *ngIf="!isUserAdded">
        <div class="form-row">
          <div class="form-group form-default">
            <span class="form-bar"></span><label class="float-label" for="title" class="label-after">Name</label>
            <input pattern= "*" type="text" class="form-control" id="name" maxlength="40"  formControlName="name">
            <span class="validation-error" *ngIf="!userForm.controls.name.valid && userForm.controls.name.touched">{{ validationError.required }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-right">
            <button type="submit" class="btn btn-cancel btn-danger" (click)="close()">Cancel</button>
            <button type="submit" class="btn btn-save btn-primary" (click)="submitAddJobCategory()"
            [disabled]="!userForm.valid">Save</button>
          </div>
        </div>
      </form>
      </div>
    </div>

</ng-template>


<ng-template #deleteUser let-modal>
    <div class="card my-0">
      <div class="header">
        <h4 class="card-title card-title-modal">Delete Languages</h4>
      </div>
      <div class="card-content px-2 py-2">
        <div class="text-left are-u">Are you sure ?</div>
        <div class="row">
          <div class="col-sm-12 text-right">
            <button type="submit" class="btn btn-cancel btn-danger" (click)="close()">Cancel</button>
            <button type="submit" class="btn btn-save btn-primary" (click)="deleteJobCategory()">Delete</button>
          </div>
        </div>
      </div>
    </div>
</ng-template>



