<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
    <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="page-header-title">
            <span class="ng-star-inserted"></span
            ><span class="ng-star-inserted"
              ><h4 class="m-b-10 ng-star-inserted">{{ lang.get("salaryComponents") }}</h4></span
            >
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</div>

<div class="page-body no-title">
  <div class="row">
    <div class="col-sm-12">
      <div class="row top-tools">
        <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6 left-tools">
          <span class="export-icon-btn csv" (click)="exportToCsv()"
            ><i class="material-icons">get_app</i><span>{{ lang.get("csv") }}</span>
          </span>
          <span class="export-icon-btn pdf" (click)="saveAsPdf()"
            ><i class="material-icons">get_app</i><span>{{ lang.get("pdf") }}</span>
          </span>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
          <div class="relative-position search-filter-con">
            <span class="icon material-icons ng-tns-c114-0"> search </span>
            <mat-form-field>
              <input
                matInput
                #inputSearch
                type="search"
                [(ngModel)]="filterQuery"
                maxlength="40"
                (ngModelChange)="onModelChange(filterQuery)"
                class="form-control input-sm full-data-search"
              />
            </mat-form-field>
            <span class="mytooltip cus-left tooltip-effect-4" [hidden]="!write">
              <span class="tooltip-item">
                <button mat-mini-fab class="btn btn-primary light add-rounded margin-button-05" (click)="addNewUser()">
                  <i class="fa fa-plus"></i>
                </button>
              </span>
              <span class="tooltip-content clearfix">
                <span class="tooltip-text">{{ lang.get("new") }}</span>
              </span>
            </span>
          </div>
        </div>
      </div>
      <app-card [title]="'Salary'">
        <div class="dt-responsive" *ngIf="dataSource">
          <div class="table-responsive">
            <mat-card-content>
              <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="select">
                  <mat-header-cell [hidden]="!delete" *matHeaderCellDef>
                    <a [matMenuTriggerFor]="menu" class="check-all">
                      <span class="material-icons"> more_vert </span>
                    </a>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="onSelectAllClicked()">"{{ lang.get("select all") }}</button>
                      <button (click)="onDeSelectAllClicked()" *ngIf="selectedCheckBoxList.length > 0" mat-menu-item>
                        {{ lang.get("deselect all") }}
                      </button>
                      <button (click)="onDeleteSelected()" *ngIf="selectedCheckBoxList.length > 0" mat-menu-item>
                        {{ lang.get("delete selected") }}
                      </button>
                    </mat-menu>
                  </mat-header-cell>
                  <mat-cell [hidden]="!delete" *matCellDef="let row">
                    <mat-checkbox
                      *ngIf="!row.basic"
                      #matCheckbox
                      (change)="onCheckBoxChange($event, row.id)"
                      value="{{ row.id }}"
                    ></mat-checkbox>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="name">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> {{ lang.get("componentName") }}</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.name }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="type">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>{{ lang.get("type") }}</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.type == "E" ? "Earning" : "Deduction" }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="valueType">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>{{ lang.get("valueType") }}</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.valueType == "A" ? "Amount" : "Percent" }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="taxable">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>{{ lang.get("taxable") }}</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.taxable ? "Yes" : "No" }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="edit">
                  <mat-header-cell [hidden]="!update" *matHeaderCellDef>{{ lang.get("edit") }}</mat-header-cell>
                  <mat-cell [hidden]="!update" *matCellDef="let row">
                    <span class="mytooltip tooltip-effect-4">
                      <span class="tooltip-item"
                        ><i class="single-icon-click fa fa-pencil" (click)="editUser(row.id)"></i
                      ></span>
                      <span class="tooltip-content clearfix">
                        <span class="tooltip-text">{{ lang.get("edit") }}</span>
                      </span>
                    </span>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columns"> </mat-row>
              </mat-table>
              <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
              <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
            </mat-card-content>
          </div>
          <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
        </div>
      </app-card>
    </div>
  </div>
</div>

<ng-template #addUser let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">
        <span *ngIf="!isEdit">{{ lang.get("add") }}</span>
        <span *ngIf="isEdit">{{ lang.get("edit") }}</span> {{ lang.get("salaryComponents") }}
      </h4>
    </div>
    <div class="mat-modal-body">
      <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>
      <ng-container *ngIf="!isUserAdded">
        <form class="full-width-inputs form-material" action="javascript:" [formGroup]="userForm">
          <div class="row form-group">
            <div class="col-sm-12">
              <mat-form-field class="form-element">
                <mat-label for="name">{{ lang.get("componentName") }} </mat-label>
                <input
                  pattern="*"
                  type="text"
                  matInput
                  class="form-control"
                  maxlength="40"
                  required=""
                  id="name"
                  formControlName="name"
                  [disabled]="readonly"
                />
                <mat-error *ngIf="invalidComponent()">{{ validationError.name }} </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-12">
              <span class="form-bar"></span>
              <div for="valueType">{{ lang.get("type") }}</div>
            </div>

            <ng-container *ngFor="let t of type">
              <div class="col-md-6">
                <mat-radio-group id="{{ t.name }}" name="type" formControlName="type" required="" [disabled]="readonly">
                  <mat-radio-button value="{{ t.id }}">{{ t.name }}</mat-radio-button>
                </mat-radio-group>
              </div>
              <mat-error *ngIf="invalidType()">{{ validationError.type }}</mat-error>
            </ng-container>
          </div>
          <div class="row form-group">
            <div class="col-md-12">
              <span class="form-bar"></span>
              <div for="valueType">
                {{ lang.get("valueType") }}
                <!-- <div class="tooltip">X -->
                  <i class="fa fa-info-circle" style="color: purple;cursor: help;" aria-hidden="true" title="Percentage is based on basic salary only."></i>
                  <!-- <span class="tooltiptext">Tooltip text</span> -->
                <!-- </div> -->
              </div>
            </div>

            <ng-container *ngFor="let t of valueType">
              <div class="col-md-6">
                <mat-radio-group
                  id="{{ t.name }}"
                  name="valueType"
                  formControlName="valueType"
                  required=""
                  [disabled]="readonly"
                >
                  <mat-radio-button value="{{ t.id }}">{{ t.name }}</mat-radio-button>
                </mat-radio-group>
              </div>
            </ng-container>
            <mat-error *ngIf="!userForm.controls.valueType.valid && userForm.controls.valueType.touched"
              >{{ validationError.required }}
            </mat-error>
          </div>
        </form>
        <div class="row form-group">
          <mat-checkbox name="taxable" [color]="primary" [(ngModel)]="taxable" class="pl-2 pr-2">Taxable </mat-checkbox>
        </div>
        <section>
          <div class="row my-2 mx-2">
            <div class="multi-btns col-sm-12 text-right">
              <button mat-raised-button class="btn-danger" (click)="close()">
                <i class="flaticon-cancel"></i>{{ lang.get("cancel") }}
              </button>
              <button
                mat-raised-button
                class="btn-primary"
                color="primary"
                (click)="submitAddSalary()"
                [disabled]="!userForm.valid"
              >
                <i class="flaticon-diskette"></i> {{ lang.get("save") }}
              </button>
            </div>
          </div>
        </section>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #deleteUser let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">{{ lang.get("delete") }} {{ lang.get("salary") }}</h4>
    </div>
    <div class="mat-modal-body">
      <!-- <div class="card-content px-2 py-2"> -->
      <div class="text-left are-u">{{ lang.get("areyousure") }}</div>
      <div class="row">
        <div class="multi-btns col-sm-12 text-right">
          <button mat-raised-button class="btn-danger" (click)="close()">
            <i class="flaticon-cancel"></i>{{ lang.get("cancel") }}
          </button>
          <button mat-raised-button class="btn-primary" color="primary" (click)="deleteJobTitle()">
            <i class="flaticon-trash"></i> {{ lang.get("delete") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
