<!--<div class="structure">
<tree-root *ngIf="data" [nodes]="data">
  <ng-template #treeNodeTemplate let-node let-index="index">
    <div [attr.aria-label]="'toggle ' + node.name" class="row mx-0 w-100">
      <div class="col-sm-10">
        <span>{{ node.data.name }}</span>
      </div>
      <div class="col-sm-1 text-right">
        <div class="add-more text-right" (click)="addNewUser(node)">...</div>
      </div>
    </div>
    &lt;!&ndash; <span>{{ node.data.name }}</span>
    <button (click)="go(node.data)">Custom Action</button> &ndash;&gt;
  </ng-template>
</tree-root>
</div>-->
<!--<div [class.edit_color]="this.isEditStructure" style="width:100%;text-align: end;padding: 10px; background: white;">
  <button mat-raised-button style="background: #448aff;color: white;" (click)="toggleEditMode()" >{{this.isEditStructure ? 'Done':'Edit'}}</button>
</div>-->
<!--<mat-tree [class.edit_color]="this.isEditStructure" [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">-->

<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
      <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
          <div class="col-md-8">
          <div class="page-header-title">
              <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted">Structure</h4></span>
          </div>
          </div>
          <div class="col-md-4"></div>
      </div>
      </div>
  </div>
</div>
<app-loading [isLoading]="this.dataSource && this.dataSource.data === null"></app-loading>
<div class="page-body no-title structure-page" *ngIf="this.dataSource ">
  <div class="row">
      <div class="col-sm-12">
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
      <!-- This is the tree node template for leaf nodes -->
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding matTreeNodePaddingIndent="120">
        <li class="mat-tree-node" style="display: flex;justify-content: space-between">
          <!-- use a disabled button to provide padding for tree leaf -->
          <div>
            <button mat-icon-button disabled></button>
           ({{node?.directEmpCount }}) - {{node?.totalEmpCount}}  {{node.name}}
              <!-- {{node?.totalEmpCount}}-  {{node.name}} -->
          </div>
          <div>
              <button [hidden]="!write" mat-icon-button (click)="onAddUser(node)">
                  <mat-icon>add</mat-icon>
              </button>
              <button [hidden]="!update" *ngIf="!node?.root" mat-icon-button (click)="editUser(node.id)">
                  <mat-icon>edit</mat-icon>
              </button>
              <button [hidden]="!delete" mat-icon-button (click)="deleteUserForm( node.id )"
                      *ngIf="node?.parent">
                  <mat-icon>delete</mat-icon>
              </button>
    <!--        <button mat-icon-button [disabled]="!this.isEditStructure" [matMenuTriggerFor]="optionsMenu">-->
            <!--<button mat-icon-button [matMenuTriggerFor]="optionsMenu">
              <mat-icon>more_vert</mat-icon>
            </button> 
            <mat-menu #optionsMenu="matMenu">
              <button mat-menu-item (click)="onAddUser(); this.parentId = node.id">Add</button>
              <button mat-menu-item (click)="editUser(node.id);">Edit</button>
              <button mat-menu-item (click)="this.deleteUserForm(node.id)" >Delete</button>
            </mat-menu>-->
          </div>
        </li>

      </mat-tree-node>
      <!-- This is the tree node template for expandable nodes -->
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <li>
          <div class="mat-tree-node" style="display: flex;justify-content: space-between">
            <div>
              <button mat-icon-button matTreeNodeToggle
                      [attr.aria-label]="'toggle ' + node.name">
    <!--            this is for the > in the structure-->
                <mat-icon class="mat-icon-rtl-mirror">
                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              </button>
              ({{node?.directEmpCount }}) - {{node?.totalEmpCount}}  {{node.name}}
            </div>
            <div>
                <button [hidden]="!write" mat-icon-button (click)="onAddUser(node)">
                    <mat-icon>add</mat-icon>
                </button>
                <button [hidden]="!update" *ngIf="!node?.root" mat-icon-button (click)="editUser(node.id)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button [hidden]="!delete" mat-icon-button (click)="deleteUserForm( node.id )"
                *ngIf="node?.parent">
                    <mat-icon>delete</mat-icon>
                </button>
    <!--          <button mat-icon-button [disabled]="!this.isEditStructure" [matMenuTriggerFor]="optionsMenu_">-->
              <!--<button mat-icon-button [matMenuTriggerFor]="optionsMenu_">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #optionsMenu_="matMenu">
                <button mat-menu-item (click)="onAddUser(); this.parentId = node.id">Add</button>
                <button mat-menu-item (click)="editUser(node.id);">Edit</button>
                <button mat-menu-item (click)="this.deleteUserForm(node.id)" >Delete</button>
              </mat-menu>-->
            </div>
          </div>
          <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
            <ng-container matTreeNodeOutlet></ng-container>
          </ul>
        </li>
      </mat-nested-tree-node>
      </mat-tree>
    </div>
  </div>
</div>
<ng-template #addUser let-modal>
    <!-- <div class="card my-0"> -->
      <!-- <div class="header"> -->
        <div class="mat-modal-header"> 
        <!-- <h4 class="card-title card-title-modal"> -->
          <h4 class="mat-title-modal">
          <span *ngIf="!isEdit">{{lang.get("add")}}</span>
        <span *ngIf="isEdit">{{lang.get("edit")}}</span> {{lang.get("structure")}}</h4>
      </div>
      <!-- <div class="card-content"> -->
        <div class="mat-modal-body">
      <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>
        <!-- <form class="form-material" action="javascript:" [formGroup]="userForm" *ngIf="!isUserAdded"> -->
          <form class="form-material full-width-inputs" action="javascript:" [formGroup]="userForm" *ngIf="!isUserAdded">
          <!-- <div class="form-row"> -->
          <div class="row form-group">
            <!-- <div class="form-group form-primary col-sm-6"> -->
              <div class="col-sm-6">
                <mat-form-field class="form-element">
                  <mat-label for="unitId">{{lang.get("unitId")}}</mat-label>
                  <input type="text" matInput class="form-control" maxlength="40" id="unitId" formControlName="unitId"
                         required="">
                  <mat-error  *ngIf="!userForm.controls.unitId.valid && userForm.controls.unitId.touched">
                      Required
                  </mat-error>
                  </mat-form-field>      
              <!-- <input type="text" maxlength="40" class="form-control" id="unitId" required="" formControlName="unitId">
              <span class="form-bar"></span>
              <label class="float-label" for="unitId">Unit ID</label> -->
            </div>
            <!-- <div class="form-group form-primary col-sm-6"> -->
              <div class="col-sm-6">
                <mat-form-field class="form-element">
                  <mat-label for="unitId">{{lang.get("name")}}</mat-label>
                  <input type="text" matInput class="form-control" maxlength="40" id="name" formControlName="name"
                         required="">
                  <mat-error  *ngIf="!userForm.controls.unitId.valid && userForm.controls.unitId.touched">
                      Required
                  </mat-error>
                  </mat-form-field>      
                <!-- <input type="text" maxlength="40"  class="form-control" required="" id="name" formControlName="name" required="">
              <span class="form-bar"></span>
              <label class="float-label" for="name">Name</label>
              <span class="validation-error" *ngIf="!userForm.controls.name.valid && userForm.controls.name.touched">{{ validationError.required }}</span> -->
            </div>
            <!-- <div class="form-group form-default col-sm-6"> -->
              <div class="col-sm-6">
              <!--<ng-container>
                <ng-select *ngIf="employeeList" [ngClass]="'ng-select'" [options]="employeeList" formControlName="hodempid"> </ng-select>
              </ng-container>-->
              <!-- <label class="">Head of Unit</label> -->
              <mat-form-field class="form-element">
                <mat-label>{{lang.get("headOfUnit")}}</mat-label>
                <mat-select disableOptionCentering class="form-control" formControlName="hodempid">
                  <mat-option *ngFor="let r of employeeList" [value]="r.value">
                    {{r.label}} 
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="!userForm.controls.hodempid.valid && userForm.controls.hodempid.touched">
                  {{ validationError.required }}
                </mat-error>
            </mat-form-field>                       
              <!-- <select class="form-control" required="" name="hodempid" formControlName="hodempid">
                <ng-container *ngFor="let e of employeeList">
                  <option value="{{e.value}}">{{ e.label }}</option>
                </ng-container>
              </select>
              <span class="form-bar"></span>
              <label class="float-label">Head of Unit</label>
              <span class="validation-error" *ngIf="!userForm.controls.hodempid.valid && userForm.controls.hodempid.touched">{{ validationError.required }}</span> -->

            </div>
            <!-- <div class="form-group form-default col-sm-6" formGroupName="costcenter"> -->
              <div class="col-sm-6">

                <mat-form-field class="form-element">
                  <mat-label>{{lang.get("costCenter")}}</mat-label>
                  <mat-select disableOptionCentering class="form-control">
                    <mat-option *ngFor="let r of costCenterList" [value]="r.id">
                      {{r.name}} 
                    </mat-option>
                  </mat-select>
                  <!-- <mat-error *ngIf="!userForm.controls.costcenterid.valid && userForm.controls.costcenterid.touched">
                    {{ validationError.required }}
                  </mat-error> -->
              </mat-form-field>                       
  
              <!-- <select name="costcenterid" formControlName="costcenterid" required="" class="form-control">
                <ng-container *ngFor="let e of costCenterList">
                  <option value="{{e.id}}">{{ e.name }}</option>
                </ng-container>
              </select>
              <span class="form-bar"></span>
              <label class="float-label">Cost Center</label> -->
            </div> 
            <!-- <div class="form-group form-default col-sm-6"> -->
              <div class="col-sm-12">
                <mat-form-field class="form-element">
                  <mat-label for="description">{{lang.get("description")}}</mat-label>
                  <textarea formControlName="description" maxlength="50" matInput class="form-control"
                  name="name"></textarea>
                 
                   <mat-error  *ngIf="!userForm.controls.description.valid && userForm.controls.description.touched">
                    Required
                </mat-error>
               </mat-form-field>      
                <!-- <input type="text"  maxlength="40" class="form-control" id="description" required="" formControlName="description">
              <span class="form-bar"></span>
              <label class="float-label" >Description</label> -->
            </div>
          </div>
        </form>
        <!-- <div class="row my-2 mx-2"> -->
          <div class="row">
          <!-- <div class="col-sm-12 text-right"> -->
            <div class="action-buttons multi-btns col-sm-12 text-right">
            <button mat-raised-button  class="btn-danger" (click)="close()">
              <i class="flaticon-cancel"></i>{{lang.get("cancel")}}</button>
            <button mat-raised-button  class="btn btn-primary" color="primary" [disabled]="!userForm.valid" (click)="submitAddJobTitle()">
              <i class="flaticon-diskette"></i>{{lang.get("save")}}</button>
          </div>
        </div>
      </div>
    <!-- </div> -->
</ng-template>

<ng-template #deleteUser let-modal>
    <!-- <div class="card my-0"> -->
      <!-- <div class="header"> -->
        <div class="mat-modal-header"> 
        <!-- <h4 class="card-title card-title-modal">Delete Structure</h4> -->
        <h4 class="mat-title-modal">{{lang.get("delete")}} {{lang.get("structure")}}</h4>
      </div>
      <!-- <div class="card-content px-2 py-2"> -->
        <div class="mat-modal-body"> 
        <div class="text-left are-u">{{lang.get("areyousure")}}</div>
        <div class="row">
          <!-- <div class="col-sm-12 text-right"> -->
            <div class="action-buttons multi-btns col-sm-12 text-right">
            <button mat-raised-button class="btn-danger" (click)="close()">
              <i class="flaticon-cancel"></i>{{lang.get("cancel")}}</button>
            <button mat-raised-button class="btn-primary" color="primary" (click)="deleteJobTitle()">
              <i class="flaticon-trash"></i>{{lang.get("delete")}}</button>
          </div>
        </div>
      </div>
    <!-- </div> -->
</ng-template>
