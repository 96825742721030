<!--<div class="card" >-->
<!--<div class="card-header card-header-warning" >-->
<!--<h4 class="card-title">Export Attendance Data As CSV</h4>-->
<!--</div>-->
<!--<div class="card-content table-responsive table-full-width">-->
<!--<form class="form-material" action="javascript:" [formGroup]="userForm" *ngIf="!isUserAdded">-->
<!--<div class="form-row">-->
<!--<div class="form-group col-md-6">-->
<!--<ng-container>-->
<!--<ng-select *ngIf="employeeList" [ngClass]="'ng-select'" [options]="employeeList" formControlName="employeeid"> </ng-select>-->
<!--</ng-container>-->
<!--</div>-->
<!--<div class="form-group col-md-6">-->

<!--<label>Sub Unit</label>-->
<!--<select formControlName="subUnit">-->
<!--<ng-container *ngFor="let s of structureList">-->
<!--<option [value]="s.id">{{s.name}}</option>-->
<!--</ng-container>-->
<!--</select>-->

<!--</div>-->
<!--<div class="form-group col-md-6">-->

<!--<label>Report Type</label>-->
<!--<select formControlName="reportType">-->
<!--<option [value]="'detail'">Detail</option>-->
<!--<option [value]="'summary'">Summary</option>-->
<!--</select>-->

<!--</div>-->
<!--<div class="form-group col-md-6">-->

<!--<label>Employement Status</label>-->
<!--<select formControlName="empStatus">-->
<!--<ng-container *ngFor="let s of empStatusList">-->
<!--<option [value]="s.id">{{s.name}}</option>-->
<!--</ng-container>-->
<!--</select>-->

<!--</div>-->
<!--<div class="form-group col-md-6">-->
<!--<span class="form-bar"></span><label class="float-label" for="dob">Start Date</label>-->
<!--<input type="date" class="form-control" id="dob"  formControlName="startDate">-->
<!--</div>-->
<!--<div class="form-group col-md-6">-->
<!--<span class="form-bar"></span><label class="float-label" for="dob">End Date</label>-->
<!--<input type="date" class="form-control" id="dob"  formControlName="endDate">-->
<!--</div>-->
<!--</div>-->
<!--<div class="row">-->
<!--<div class="col-sm-12 text-right">-->
<!--<button type="submit" class="btn btn-cancel btn-danger" (click)="close()">Cancel</button>-->
<!--<button type="submit" class="btn btn-save btn-primary" (click)="submitAddJobCategory()">Save</button>-->
<!--</div>-->
<!--</div>-->
<!--</form>-->
<!--</div>-->
<!--<div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>-->
<!--</div>-->

<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
    <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="page-header-title">
            <span class="ng-star-inserted"></span><span class="ng-star-inserted">
              <h4 class="m-b-10 ng-star-inserted">Attendance Report</h4>
            </span>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</div>

<div class="page-body no-title">
  <div class="row">
    <div class="col-sm-12">
      <app-card>
        <div class="dt-responsive">
          <div>
            <!-- <app-loading *ngIf="isLoading && !err" [isLoading]="isLoading && !err"></app-loading> -->
            <div class="card-content table-responsive table-full-width">
              <form class="form-material full-width-inputs" action="javascript:" [formGroup]="userForm"
                *ngIf="!isUserAdded">
                <div class="row form-group">
                  <div class="col-md-4">
                    <!-- <mat-form-field class="form-element">
                      <mat-label>Employee Name</mat-label>
                      <mat-select class="form-control" *ngIf="employeeList" formControlName="userId">
                        <mat-option *ngFor="let emp of employeeList" [value]="emp.value">{{ emp.label }}</mat-option>
                      </mat-select>
                    </mat-form-field> -->
                    <app-lazy-employee-dropdown title="Employee" required="false" [selectedEmployee]="selectedEmpId"
                      (employeeSelected)="updateSelectedEmployee($event)"></app-lazy-employee-dropdown>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field class="form-element">
                      <mat-label>Sub Unit</mat-label>
                      <mat-select class="form-control" *ngIf="structureList" formControlName="subUnit">
                        <mat-option *ngFor="let s of structureList" [value]="s.value">{{ s.label }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field class="form-element">
                      <mat-label>Location</mat-label>
                      <mat-select class="form-control" *ngIf="locationList" formControlName="location">
                        <mat-option [value]="'All'">All</mat-option>
                        <mat-option *ngFor="let location of locationList" [value]="location.value">{{
                          location.label
                          }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-4">
                    <mat-form-field class="form-element">
                      <mat-label>Employment Status</mat-label>
                      <mat-select class="form-control" *ngIf="empStatusList" formControlName="empStatus">
                        <mat-option *ngFor="let emps of empStatusList" [value]="emps.value">{{
                          emps.label
                          }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field class="form-element">
                      <mat-label>Report Type</mat-label>
                      <mat-select class="form-control" formControlName="reportType">
                        <!-- <mat-option [value]="'summary'">Summary</mat-option> -->
                        <mat-option [value]="'daily'">Daily</mat-option>
                        <mat-option [value]="'detail'">Detailed</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field class="form-element">
                      <mat-label for="des">Date From/To</mat-label>
                      <br />
                      <mat-date-range-input required [rangePicker]="picker1">
                        <input required matStartDate formControlName="startDate" />
                        <input required matEndDate formControlName="endDate" />
                      </mat-date-range-input>
                      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                      <mat-date-range-picker #picker1></mat-date-range-picker>
                    </mat-form-field>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4">
                      <mat-form-field class="form-element">
                        <mat-label class="col-form-label">Include</mat-label>
                        <mat-select disableOptionCentering class="form-control" formControlName="include">
                          <mat-option *ngFor="let t of includeList" [value]="t.value"> {{ t.label }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="col-md-8">
                      <div>
                        <span class="dot-set-holiday"></span>
                        <span class="holiday-color">H </span>
                        - Holiday &nbsp;
                        <span class="dot-set-leave"></span>
                        <span class="leave-color">L</span>
                        - Leave &nbsp;
                        <span class="dot-set-weekend"></span>
                        <span class="Weekend-color">W</span>
                        - Weekend &nbsp;
                      </div>
                    </div>
                  </div>
                  <br>
                  <div class="form-row">
                    <div class="col-md-8">
                      <br />
                      <mat-label for="des">* Export as CSV exports data based on the selected criteria, click on Seach
                        before exporting
                        data if you want to verify it first.</mat-label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 text-right multi-btns action-buttons">
                    <button mat-raised-button class="btn btn-cancel btn-danger" (click)="reset()">
                      <i class="flaticon-refresh"></i> Reset
                    </button>
                    <button mat-raised-button color="primary" class="btn btn-save btn-primary" (click)="export()"
                      [disabled]="isLoading" [class.spinner]="isLoading">
                      <i class="flaticon-diskette"></i> Export as CSV
                    </button>
                    <button mat-raised-button color="primary" class="btn btn-save btn-primary"
                      (click)="viewAttendanceData(true)" [disabled]="isLoading" [class.spinner]="isLoading">
                      <i class="flaticon-diskette"></i> Search
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
          </div>
        </div>
      </app-card>

      <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
      <div class="example-container mat-elevation-z8" *ngIf="showTable">
        <app-card>
          <mat-table [dataSource]="dataSource" matSort *ngIf="attendanceDataType === 'detail'"
            (matSortChange)="sortData($event)">
            <ng-container matColumnDef="empId">
              <mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isLoading">Employee ID</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.empId }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="empName">
              <mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isLoading">Employee Name</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.empName }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="date">
              <mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isLoading">Date</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span [ngClass]="{
                  'weekend-color': row.dayOff === 'W',
                  'holiday-color': row.dayOff === 'H',
                  'leave-color': row.dayOff === 'L'
                }">
                  {{ row.formattedDate }}</span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="punchInFormattedTime">
              <mat-header-cell *matHeaderCellDef>In Time</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.punchInFormattedTime }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="punchInMethod">
              <mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isLoading">In Method</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.punchInMethod }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="punchInLocationName">
              <mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isLoading">In Location</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.punchInLocationName }}</mat-cell>
            </ng-container>

            <!-- <ng-container matColumnDef="punchOutFormattedDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Punch Out Date</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.punchOutFormattedDate }}</mat-cell>
            </ng-container> -->

            <ng-container matColumnDef="punchOutFormattedTime">
              <mat-header-cell *matHeaderCellDef>Out Time</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.punchOutFormattedTime }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="punchOutMethod">
              <mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isLoading">Out Method</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.punchOutMethod }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="punchOutLocationName">
              <mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isLoading">Out Location</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.punchOutLocationName }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="durationHHmm">
              <mat-header-cell *matHeaderCellDef>Duration HH:mm </mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.durationHHmm }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="percentage">
              <mat-header-cell *matHeaderCellDef> Percentage % </mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.totalDayPct }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.status }}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="detailedReportColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: detailedReportColumns"> </mat-row>
          </mat-table>

          <mat-table [dataSource]="dataSource" matSort *ngIf="attendanceDataType === 'daily'"
            (matSortChange)="sortData($event)">
            <ng-container matColumnDef="empId">
              <mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isLoading">Employee ID</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.empId }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="empName">
              <mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isLoading">Employee Name</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.empName }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="date">
              <mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isLoading">Date</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span [ngClass]="{
                  'weekend-color': row.dayOff === 'W',
                  'holiday-color': row.dayOff === 'H',
                  'leave-color': row.dayOff === 'L'
                }">
                  {{ row.formattedDate }}</span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="totalDayDurationHHmm">
              <mat-header-cell *matHeaderCellDef>Duration</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.totalDayDurationHHmm }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="percentage">
              <mat-header-cell *matHeaderCellDef>Percentage %</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.totalDayPct }}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="dailyReportColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: dailyReportColumns"> </mat-row>
          </mat-table>

          <mat-paginator [pageSize]="pageSize" [pageIndex]="activePage" [pageSizeOptions]="pageSizeOptions"
            showFirstLastButtons [length]="totalRecords" (page)="handlePageEvent($event)" [disabled]="isLoading">
          </mat-paginator>
        </app-card>
      </div>
    </div>
  </div>
</div>