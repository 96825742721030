import { Component, OnInit, ViewChild } from "@angular/core";
import { DashboardService } from "../../../dashboard.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NotificationService } from "src/app/core/services/notification.service";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import * as moment from "moment";
import { saveAs } from 'file-saver-es';
import { downloadFile } from "../../../../service/utils";
import { MatTableDataSource } from "@angular/material/table";
import { PageEvent } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { CrudService } from "src/app/core/services/base-crud.service";

@Component({
  selector: "app-attendance-report",
  templateUrl: "./attendance-report.component.html",
  styleUrls: ["./attendance-report.component.scss"],
  providers:[CrudService],
})
export class AttendanceReportComponent implements OnInit {
  detailedReportColumns = ["empId", "empName", "date", "punchInFormattedTime", "punchInMethod", "punchInLocationName",
    "punchOutFormattedTime", "punchOutMethod", "punchOutLocationName", "durationHHmm", "percentage", "status"];

  dailyReportColumns = ["empId", "empName", "date", "totalDayDurationHHmm", "percentage"];
  
  public userList: Array<any>;
  public isLoading: boolean;
  public isUserAdded: boolean;
  public err: string;
  public modalRef: any;
  public userForm: FormGroup;
  public isEdit: boolean;
  public editId: string;
  public deleteId: string;
  public totalRecords: number;
  public activePage: number;
  public pageSize: number;
  public showTable: boolean;
  public attendanceData: any;
  public attendanceDataType: string;

  public validationError = {
    required: "Required",
  };
  // public employeeList = [];
  public structureList = [];
  public empStatusList = [];
  public filteredOptions: Observable<string[]>;
  public selectedEmpId: any;
  public locationList = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  public sortBy = [];
  @ViewChild(MatSort) sort: MatSort;
  public includeList: Array<any> = [
    { value: "ce", label: "Current Employees Only" },
    { value: "cp", label: "Current and Past Employees" },
    { value: "pe", label: "Past Employees Only" },
  ];

  constructor(
    private service: DashboardService, 
    private modalService: NgbModal,
    private fb: FormBuilder,
    private toastr: NotificationService,
    private locationService: CrudService<any>
  ) {
    this.createForm();
    this.locationService.setEntityName("location");
  }
  ngOnInit() {
    this.isLoading = true;
    this.isEdit = false;
    this.activePage = 0;
    this.pageSize = 10;
    this.totalRecords = 10;
    this.isUserAdded = false;
    this.showTable = false;
    // this.service.getEmployeeDetailList().subscribe(resp => {
    //     if (resp && resp.data) {
    //         this.isLoading = false;
    //         resp.data.map(e => {
    //             this.employeeList.push({label: e.name, value: e.id});
    //         });
    //     }
    // });
    this.service.getEmployeeStatusList().subscribe((resp) => {
      if (resp && resp.data) {
        this.empStatusList.push({ label: "All", value: "All" });
        resp.data.map((e) => {
          this.empStatusList.push({ label: e.name, value: e.id });
        });
        
      }
    });
    this.service.getStructureListWithAll().subscribe((resp) => {
      if (resp && resp.data) {
        resp.data.map((e) => {
          this.structureList.push({ label: e.name, value: e.id });
          this.isLoading = false;
        });
      }
    });

    this.locationService.getAll().subscribe((resp) => {
      if (resp && resp.data) {
        resp.data.map((e) => {
          this.locationList.push({ label: e.location, value: e.id });
          this.isLoading = false;
        });
      }
    })

  }


  public createForm(): void {
    this.userForm = this.fb.group({
      empStatus: ["All"],
      endDate: ["", [Validators.required]],
      reportType: ["detail", [Validators.required]],
      startDate: ["", [Validators.required]],
      subUnit: ["All"],
      userId: [""],
      location:["All"],
      include: ["ce"],
    });

    this.selectedEmpId = { id: "", name: "" };
  }

  public reset(): void {
    this.userForm.reset();
    this.selectedEmpId = { id: "", name: "" };
  }

  public viewAttendanceData(resetSort:boolean): void {
    if (!this.userForm.valid) {
      this.toastr.error("Please fill required fields");
      return;
    }

    if(resetSort && this.sort){
      this.sort.active = '';
      this.sort.direction = '';
    }

    this.isLoading = true;
    const filterObj = this.getFilter();
    this.service.getAttendanceReportData(this.activePage, this.pageSize, this.sortBy, filterObj).subscribe(
      (res) => {
        if (res && res.data) {
          this.showTable = true;
          this.attendanceData = res.data;

          this.dataSource = new MatTableDataSource<any>(this.attendanceData);
          this.totalRecords = res.paging.count;   
          this.isLoading = false;  
          this.attendanceDataType = filterObj.reportType;
        }
      },
      (error) => {
        this.isLoading = false;
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error getting data");
      }
    );
  }

  handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.activePage = event.pageIndex;
    this.viewAttendanceData(false);
  }

  sortData(sort: Sort) {
    console.log(this.sort);
    if(sort.direction){
      this.sortBy = [{'sortBy': sort.active, 'sortDir' : sort.direction }];
    }else{
      this.sortBy = [];
    } 
    this.viewAttendanceData(false);
  }
  public export(): void {
    if (!this.userForm.valid) {
      this.toastr.error("Please fill required fields");
      return;
    }

    this.isLoading = true;
    const filterObj = this.getFilter();

    this.service.exportPunch(filterObj).subscribe(
      (resp) => {
        try {
          downloadFile(resp, "AttendanceReport_" + filterObj.reportType);
        } catch (e) {
          this.toastr.error(e || "Error in getting data");
        }
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        const msg = error && error.error && error.error.msg;
        this.toastr.error(msg || "Error in getting data");
      }
    );
  }

  public updateSelectedEmployee(event) {
    this.selectedEmpId.id = event ? event.id : "";
    this.selectedEmpId.name = event ? event.name : "";
  }

  getFilter() {
    const val = this.userForm.value;
    val.startDate = moment(val.startDate).format("YYYY-MM-DD HH:mm");
    val.endDate = moment(val.endDate).endOf("day").format("YYYY-MM-DD HH:mm");
    let filterObj = {
      empStatus: val.empStatus && val.empStatus != "All" ? val.empStatus : undefined,
      reportType: val.reportType && val.reportType != "All" ? val.reportType : undefined,
      subUnit: val.subUnit && val.subUnit != "All" ? val.subUnit : undefined,
      endDate: val.endDate ? val.endDate : undefined,
      startDate: val.startDate ? val.startDate : undefined,
      userId: this.selectedEmpId.id,
      location: val.location && val.location != "All" ? val.location : undefined,
      include: val.include
    };

    return filterObj;
  }
}
