<div id="header-page" class="ng-star-inserted header-page">
    <div class="page-header ng-star-inserted">
        <div class="page-block ng-star-inserted">
            <div class="row align-items-center">
                <div class="col-md-8">
                    <div class="page-header-title">
                        <span class="ng-star-inserted"></span><span class="ng-star-inserted">
                            <h4 *ngIf="selected==0" class="m-b-10 ng-star-inserted">Course Details</h4>
                        </span>
                        <span class="ng-star-inserted"></span><span class="ng-star-inserted">
                            <h4 *ngIf="selected==1" class="m-b-10 ng-star-inserted">Resources</h4>
                        </span>
                    </div>
                </div>
                <div class="col-md-4"></div>
            </div>
        </div>
    </div>
</div>

<div class="page-body no-title">

    <mat-card class="example-card">
        <app-loading *ngIf="isLoading" [isLoading]="isLoading"></app-loading>
        <mat-card-content>
            <mat-tab-group *ngIf="!isLoading" mat-align-tabs="center" #tabGroup (selectedTabChange)="tabChanged($event)">
                <mat-tab label="Course Details">
                    <mat-card class="example-card">
                        <mat-card-content>
                            <div class="form-config-chart">
                                <form class="form-material full-width-inputs" action="javascript:"
                                    [formGroup]="courseForm" *ngIf="!isLoading">
                                    <div class="row form-group">
                                        <div class="col-sm-6">
                                            <mat-form-field class="form-element">
                                                <mat-label>Title</mat-label>
                                                <input class="form-control" formControlName="title" matInput
                                                    maxlength="40" required="" type="text" readonly>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-sm-6">
                                            <mat-form-field class="form-element">
                                                <mat-label>Coordinator</mat-label>
                                                <input class="form-control" formControlName="coordinatorName" matInput
                                                    maxlength="40" required="" type="text" readonly>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="row">
                                                <div class="col-5">
                                                    <mat-form-field class="form-element">
                                                        <mat-select class="form-control" formControlName="subUnitId"
                                                            disableOptionCentering disabled>
                                                            <mat-option value="">--Select--</mat-option>
                                                            <ng-container *ngFor="let j of temporaryDepartment">
                                                                <mat-option value="{{j.id}}">{{ j.name }}</mat-option>
                                                            </ng-container>
                                                        </mat-select>
                                                        <mat-label for="des">Sub Unit</mat-label>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-3">
                                                    <mat-form-field class="form-element">
                                                        <!-- <mat-select class="form-control" formControlName="version"
                                                            disabled disableOptionCentering>
                                                            <mat-option value='1'>1</mat-option>
                                                            <mat-option value='2'>2</mat-option>
                                                            <mat-option value='3'>3</mat-option>
                                                            <mat-option value='4'>4</mat-option>
                                                            <mat-option value='5'>5</mat-option>
                                                            <mat-option value='6'>6</mat-option>
                                                            <mat-option value='7'>7</mat-option>
                                                            <mat-option value='8'>8</mat-option>
                                                            <mat-option value='9'>9</mat-option>
                                                            <mat-option value='10'>10</mat-option>
                                                        </mat-select> -->
                                                        <mat-label for="des">Version</mat-label>
                                                        <input class="form-control" readonly formControlName="version" matInput>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-4">
                                                    <mat-form-field class="form-element">
                                                        <!-- <mat-select class="form-control" disabled
                                                            formControlName="subVersion" disableOptionCentering>
                                                            <mat-option value='0'>0</mat-option>
                                                            <mat-option value='2'>2</mat-option>
                                                            <mat-option value='3'>3</mat-option>
                                                            <mat-option value='4'>4</mat-option>
                                                            <mat-option value='5'>5</mat-option>
                                                            <mat-option value='6'>6</mat-option>
                                                            <mat-option value='7'>7</mat-option>
                                                            <mat-option value='8'>8</mat-option>
                                                            <mat-option value='9'>9</mat-option>
                                                        </mat-select> -->
                                                        <mat-label>Sub Version</mat-label>
                                                        <input class="form-control" readonly formControlName="subVersion" matInput>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-6">
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-form-field class="form-element">
                                                        <mat-label>Currency</mat-label>
                                                        <!-- <mat-select disabled class="form-control"
                                                            formControlName="currencyName">
                                                            <mat-option *ngFor="let n of currencyList" [value]="n.name">
                                                                {{ n.name }}
                                                            </mat-option>
                                                        </mat-select> -->
                                                        <input class="form-control" readonly formControlName="currencyName" matInput>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-6">
                                                    <mat-form-field class="form-element">
                                                        <mat-label>Cost</mat-label>
                                                        <input class="form-control" formControlName="cost" matInput
                                                            maxlength="40" type="text" readonly>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <mat-form-field class="form-element">
                                                        <mat-label>Company</mat-label>
                                                        <input class="form-control" formControlName="companyName"
                                                            matInput maxlength="40" type="text" readonly>
                                                    </mat-form-field>
                                                </div>
                                                <!-- <mat-form-field class="form-element">
                                                    <mat-label for="des">Date From/To</mat-label>
                                                    <br>
                                                    <mat-date-range-input required [rangePicker]="picker1">
                                                      <input required matStartDate formControlName="startdate">
                                                      <input required matEndDate formControlName="enddate">
                                                    </mat-date-range-input>
                                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                                    <mat-date-range-picker #picker1></mat-date-range-picker>
                                                  </mat-form-field> -->
                                                <div class="col-sm-6">
                                                   <mat-form-field class="form-element">

                                                    <mat-label>Date</mat-label>
                                                    <!-- <br> -->
                                                    <mat-date-range-input readOnly >
                                                        <input readOnly matStartDate formControlName="startDate">
                                                        <input readOnly matEndDate formControlName="endDate">
                                                        
                                                    </mat-date-range-input>
                                                    <br>
                                                    </mat-form-field>
                                                
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <mat-form-field class="form-element">
                                                        <mat-label>duration</mat-label>
                                                        <input class="form-control" formControlName="duration" matInput
                                                            maxlength="40" type="text" readonly>
                                                    </mat-form-field>
                                                </div>

                                                <div class="col-6">
                                                    <mat-form-field class="form-element">
                                                        <mat-label>Status</mat-label>
                                                        <mat-select disabled class="form-control"
                                                            formControlName="status">
                                                            <mat-option value="active">Active</mat-option>
                                                            <mat-option value="archived">Archived</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12">
                                            <mat-form-field class="form-element">
                                                <mat-label>Description</mat-label>
                                                <textarea readonly class="form-control" matInput
                                                    placeholder="Description" formControlName="description"></textarea>
                                            </mat-form-field>
                                        </div>

                                    </div>
                                    <div class="action-buttons multi-btns col-sm-12 text-right">
                                        <button mat-raised-button class="btn-danger" (click)="close()">
                                            <i class="flaticon-cancel"></i>Back
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </mat-tab>

                <mat-tab label="Resources">
                    <div style="margin-top: 33px; width: 99%; margin-left: 1px" class="row">
                        <div class="col-sm-12">
                            <div class="row top-tools">
                                <div class="col-xs-12 col-sm-12 col-md-6 left-tools">
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
                                    <div class="relative-position search-filter-con">
                                        <span class="icon material-icons ng-tns-c114-0"> search </span>
                                        <mat-form-field>
                                            <input matInput #inputSearch type="search" [(ngModel)]="filterQuery3"
                                                maxlength="40" (ngModelChange)="onModelChange2(filterQuery3)"
                                                class="form-control input-sm full-data-search">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="dataSource3">
                                <div>
                                    <mat-card-content>
                                        <mat-table [dataSource]="dataSource3" matSort>
                                            <ng-container matColumnDef="name">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Name
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row">
                                                    {{ row.name }}
                                                </mat-cell>
                                            </ng-container>

                                            <ng-container matColumnDef="type">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Type
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row">
                                                    {{ row.type }}
                                                </mat-cell>
                                            </ng-container>

                                            <ng-container matColumnDef="link">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Link
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row">
                                                    <a target="_blank" [href]="row.link">{{ row.link }}</a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="description">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Description
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row">
                                                    {{ row.description }}
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="order">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Order
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row">
                                                    {{ row.order }}
                                                </mat-cell>
                                            </ng-container>
                                            <mat-header-row *matHeaderRowDef="columns3"></mat-header-row>
                                            <mat-row *matRowDef="let row; columns: columns3;">
                                            </mat-row>
                                        </mat-table>
                                        <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
                                        <mat-paginator #tablePaginator="matPaginator" [pageSize]="5"
                                            [pageSizeOptions]="[10, 20]"></mat-paginator>
                                    </mat-card-content>
                                </div>
                                <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-card-content>
    </mat-card>
</div>


<ng-template #addCourse let-modal>
    <!-- <div class="card my-0"> -->
    <!-- <div class="header"> -->
    <div class="mat-modal-header">
        <!-- <h4 class="card-title card-title-modal"> -->
        <h4 class="mat-title-modal">
            <span>Add Trainer</span>
        </h4>
    </div>
    <!-- <div class="card-content"> -->
    <div class="mat-modal-body">
        <app-loading *ngIf="isTrainerAdded" [isLoading]="isTrainerAdded"></app-loading>
        <!-- <form class="form-material mt-4" action="javascript:" [formGroup]="userForm" *ngIf="!isUserAdded"> -->
        <form class="form-material full-width-inputs" action="javascript:" [formGroup]="trainerForm"
            *ngIf="!isTrainerAdded">
            <div class="row form-group">
                <div class="col-sm-12">
                    <mat-form-field class="form-element">
                        <mat-label>Trainer</mat-label>
                        <input required="" class="form-control" type="text" aria-label="trainerId" matInput
                            #trigger="matAutocompleteTrigger" formControlName="trainerId" [matAutocomplete]="auto">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                            [displayWith]="displayEmployeeByName.bind(this)">
                            <mat-option *ngFor="let r of filteredOptions " [value]="r.value">
                                {{r.label}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error
                            *ngIf="!trainerForm.controls.trainerId.valid && trainerForm.controls.trainerId.touched">
                            please enter trainer
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="action-buttons multi-btns col-sm-12 text-right">
                <button mat-raised-button class="btn-danger" (click)="close1()">
                    <i class="flaticon-cancel"></i>Cancel
                </button>
                <button mat-raised-button class="btn btn-primary" color="primary"
                    [disabled]="!trainerForm.valid ||!this.correct" (click)="submitAddUser(false)"><i
                        class="flaticon-diskette"></i>Save
                </button>
            </div>
        </form>
    </div>
    <!-- </div> -->
</ng-template>


<ng-template #addCourse1 let-modal>
    <!-- <div class="card my-0"> -->
    <!-- <div class="header"> -->
    <div class="mat-modal-header">
        <!-- <h4 class="card-title card-title-modal"> -->
        <h4 class="mat-title-modal">
            <span>Add Participant</span>
        </h4>
    </div>
    <!-- <div class="card-content"> -->
    <div class="mat-modal-body">
        <app-loading *ngIf="isTrainerAdded" [isLoading]="isTrainerAdded"></app-loading>
        <!-- <form class="form-material mt-4" action="javascript:" [formGroup]="userForm" *ngIf="!isUserAdded"> -->
        <form class="form-material full-width-inputs" action="javascript:" [formGroup]="participantForm"
            *ngIf="!isTrainerAdded">
            <div class="row form-group">
                <div class="col-sm-12">
                    <mat-form-field class="form-element">
                        <mat-label>Participant</mat-label>
                        <input required="" class="form-control" type="text" aria-label="participantId" matInput
                            formControlName="participantId" [matAutocomplete]="auto1">
                        <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displayEmployeeByName.bind(this)">
                            <mat-option *ngFor="let r of filteredOptions " [value]="r.value">
                                {{r.label}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error
                            *ngIf="!participantForm.controls.participantId.valid && participantForm.controls.participantId.touched">
                            please enter Participant
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="action-buttons multi-btns col-sm-12 text-right">
                <button mat-raised-button class="btn-danger" (click)="close1()">
                    <i class="flaticon-cancel"></i>Cancel
                </button>
                <button mat-raised-button class="btn btn-primary" color="primary"
                    [disabled]="!participantForm.valid ||!this.correct" (click)="submitAddUser(true)"><i
                        class="flaticon-diskette"></i>Save
                </button>
            </div>
        </form>
    </div>
    <!-- </div> -->
</ng-template>


<ng-template #deleteCourse let-modal>
    <!-- <div class="card my-0"> -->
    <div mat-dialog-actions>
        <!-- <div class="header">  -->
        <div class="mat-modal-header">
            <!-- <h4 class="card-title card-title-modal">Delete User</h4>  -->
            <h4 class="mat-title-modal">Delete </h4>
        </div>
        <!-- <div class="card-content px-2 py-2"> -->
        <div class="mat-modal-body">
            <div class="text-left are-u">Are you sure ?</div>
            <div class="row">
                <div class="multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close1()">
                        <i class="flaticon-cancel"></i>Cancel
                    </button>
                    <button class="btn-primary" color="primary" mat-raised-button (click)="deleteMultiple(false)"><i
                            class="flaticon-trash"></i>Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #deleteCourse1 let-modal>
    <!-- <div class="card my-0"> -->
    <div mat-dialog-actions>
        <!-- <div class="header">  -->
        <div class="mat-modal-header">
            <!-- <h4 class="card-title card-title-modal">Delete User</h4>  -->
            <h4 class="mat-title-modal">Delete </h4>
        </div>
        <!-- <div class="card-content px-2 py-2"> -->
        <div class="mat-modal-body">
            <div class="text-left are-u">Are you sure ?</div>
            <div class="row">
                <div class="multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close1()">
                        <i class="flaticon-cancel"></i>Cancel
                    </button>
                    <button class="btn-primary" color="primary" mat-raised-button (click)="deleteMultiple(true)"><i
                            class="flaticon-trash"></i>Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #addSession let-modal>
    <!-- <div class="card my-0"> -->
    <!-- <div class="header"> -->
    <div class="mat-modal-header">
        <!-- <h4 class="card-title card-title-modal"> -->
        <h4 class="mat-title-modal">
            <span>Add Session</span>
        </h4>
    </div>
    <!-- <div class="card-content"> -->
    <div class="mat-modal-body">
        <app-loading *ngIf="isSessionAdded" [isLoading]="isSessionAdded"></app-loading>
        <!-- <form class="form-material mt-4" action="javascript:" [formGroup]="userForm" *ngIf="!isUserAdded"> -->
        <form class="form-material full-width-inputs" action="javascript:" [formGroup]="sessionForm"
            *ngIf="!isSessionAdded">
            <div class="row form-group">
                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <mat-label>Name</mat-label>
                        <input class="form-control" formControlName="sessionName" matInput maxlength="40" required=""
                            type="text">
                    </mat-form-field>
                </div>
                <div class="col-sm-6" style="margin-top: 20px">
                    <mat-checkbox #useCourseData [(ngModel)]="useCourseData.checked" color="primary">Use Course Data
                    </mat-checkbox>
                </div>
                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <input required="" [matDatepicker]="picker2" class="form-control" formControlName="startDate"
                            matInput>
                        <mat-datepicker-toggle [for]="picker2" matSuffix></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                        <mat-label for="des">Start Date</mat-label>
                    </mat-form-field>
                </div>
                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <input [matDatepicker]="picker3" class="form-control" formControlName="endDate" matInput>
                        <mat-datepicker-toggle [for]="picker3" matSuffix></mat-datepicker-toggle>
                        <mat-datepicker #picker3></mat-datepicker>
                        <mat-label for="endDate">End Date</mat-label>
                    </mat-form-field>
                </div>
                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <mat-label>Delivery Location</mat-label>
                        <input class="form-control" formControlName="deliveryLocation" id="deliveryLocation" matInput
                            maxlength="40" type="text">
                    </mat-form-field>
                </div>

                <div class="col-sm-6">
                    <div class="row">
                        <div class="col-6">
                            <mat-form-field class="form-element">
                                <mat-label>delivery Method</mat-label>
                                <mat-select class="form-control" formControlName="deliveryMethod">
                                    <mat-option value="classRoom">CLass Room</mat-option>
                                    <mat-option value="selfStudy">Self Study</mat-option>
                                    <mat-option value="webEx">Web Ex</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-6">
                            <mat-form-field class="form-element">
                                <mat-label>Status</mat-label>
                                <mat-select class="form-control" formControlName="status">
                                    <mat-option value="pending">Pending</mat-option>
                                    <mat-option value="scheduled">Scheduled</mat-option>
                                    <mat-option value="complete">Complete</mat-option>
                                    <mat-option value="cancelled">Cancelled</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12">
                    <mat-form-field class="form-element">
                        <mat-label>Description</mat-label>
                        <textarea class="form-control" matInput placeholder="Description"
                            formControlName="description"></textarea>
                    </mat-form-field>
                </div>

            </div>
            <div class="action-buttons multi-btns col-sm-12 text-right">
                <button mat-raised-button class="btn-danger" (click)="close1()">
                    <i class="flaticon-cancel"></i>Cancel
                </button>
                <button mat-raised-button class="btn btn-primary" color="primary" [disabled]="!sessionForm.valid"
                    (click)="submitAddUser2()"><i class="flaticon-diskette"></i>Save
                </button>
            </div>
        </form>
    </div>
    <!-- </div> -->
</ng-template>

<ng-template #deleteSession let-modal>
    <!-- <div class="card my-0"> -->
    <div mat-dialog-actions>
        <!-- <div class="header">  -->
        <div class="mat-modal-header">
            <!-- <h4 class="card-title card-title-modal">Delete User</h4>  -->
            <h4 class="mat-title-modal">Delete Session</h4>
        </div>
        <!-- <div class="card-content px-2 py-2"> -->
        <div class="mat-modal-body">
            <div class="text-left are-u">Are you sure ?</div>
            <div class="row">
                <div class="multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close1()">
                        <i class="flaticon-cancel"></i>Cancel
                    </button>
                    <button class="btn-primary" color="primary" mat-raised-button (click)="deleteMultiple2()"><i
                            class="flaticon-trash"></i>Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #addSession1 let-modal>
    <!-- <div class="card my-0"> -->
    <!-- <div class="header"> -->
    <div class="mat-modal-header">
        <!-- <h4 class="card-title card-title-modal"> -->
        <h4 class="mat-title-modal">
            <span>Add Resource</span>
        </h4>
    </div>
    <!-- <div class="card-content"> -->
    <div class="mat-modal-body">
        <app-loading *ngIf="isSessionAdded" [isLoading]="isSessionAdded"></app-loading>
        <!-- <form class="form-material mt-4" action="javascript:" [formGroup]="userForm" *ngIf="!isUserAdded"> -->
        <form class="form-material full-width-inputs" action="javascript:" [formGroup]="resourceForm"
            *ngIf="!isSessionAdded">
            <div class="row form-group">
                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <mat-label>Name</mat-label>
                        <input class="form-control" formControlName="name" matInput maxlength="40" required=""
                            type="text">
                    </mat-form-field>
                </div>

                <div class="col-6">
                    <mat-form-field class="form-element">
                        <mat-label>Type</mat-label>
                        <mat-select class="form-control" formControlName="type">
                            <mat-option value="video">Video</mat-option>
                            <mat-option value="document">Document</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <mat-label>Link</mat-label>
                        <input class="form-control" formControlName="link" id="link" matInput maxlength="40" type="text"
                            required="">
                    </mat-form-field>
                </div>
                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <mat-label>Order</mat-label>
                        <input class="form-control" formControlName="order" id="order" matInput maxlength="40"
                            type="text">
                    </mat-form-field>
                </div>

                <div class="col-sm-12">
                    <mat-form-field class="form-element">
                        <mat-label>Description</mat-label>
                        <textarea class="form-control" matInput placeholder="Description"
                            formControlName="description"></textarea>
                    </mat-form-field>
                </div>

            </div>
            <div class="action-buttons multi-btns col-sm-12 text-right">
                <button mat-raised-button class="btn-danger" (click)="close1()">
                    <i class="flaticon-cancel"></i>Cancel
                </button>
                <button mat-raised-button class="btn btn-primary" color="primary" [disabled]="!resourceForm.valid"
                    (click)="submitAddUser3()"><i class="flaticon-diskette"></i>Save
                </button>
            </div>
        </form>
    </div>
    <!-- </div> -->
</ng-template>


<ng-template #deleteSession3 let-modal>
    <!-- <div class="card my-0"> -->
    <div mat-dialog-actions>
        <!-- <div class="header">  -->
        <div class="mat-modal-header">
            <!-- <h4 class="card-title card-title-modal">Delete User</h4>  -->
            <h4 class="mat-title-modal">Delete Resource</h4>
        </div>
        <!-- <div class="card-content px-2 py-2"> -->
        <div class="mat-modal-body">
            <div class="text-left are-u">Are you sure ?</div>
            <div class="row">
                <div class="multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close1()">
                        <i class="flaticon-cancel"></i>Cancel
                    </button>
                    <button class="btn-primary" color="primary" mat-raised-button (click)="deleteMultiple3()"><i
                            class="flaticon-trash"></i>Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>