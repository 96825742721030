import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { startWith } from "rxjs/operators";
import { LanguageConfig } from "src/app/Language-config";
import { DashboardService } from "src/app/dashboard/dashboard.service";

@Component({
  selector: "app-country-dropdown",
  templateUrl: "./country-dropdown.component.html",
  styleUrls: ["./country-dropdown.component.scss"],
})
export class CountryDropdownComponent implements OnInit {
  @Input() title: string;
  @Input() required: boolean;
  @Input() selectedCountry: string;

  public countryList: Array<any>;
  private countryMap: Map<string, any>;
  public filteredCountries: Array<any>;
  public countryCtrl: FormControl = new FormControl();

  @Output() selectedCountryChange = new EventEmitter<string>();
  correct: boolean;

  constructor(private service: DashboardService) {}

  ngOnInit(): void {
    if (LanguageConfig.countryListCache.length > 0) {
      this.countryList = LanguageConfig.countryListCache;
      this.countryMap = LanguageConfig.countryMapCache;

      this.filteredCountries = this.countryList;

      this.countryCtrl.valueChanges.subscribe((change) => {
        if (change == null || change == undefined) {
          startWith("");
        } // Value inside the input field as soon as it changes
        else {
          this.filterCountry(change);
        }
      });
      
    } else {
      this.service.getCountryList().subscribe((resp) => {
        this.countryList = resp && resp.data;
        this.countryMap = new Map(this.countryList.map((i) => [i.iso, i] as [string, any]));
        //set cache
        LanguageConfig.countryListCache = this.countryList;
        LanguageConfig.countryMapCache = this.countryMap;

        this.filteredCountries = this.countryList;

        this.countryCtrl.valueChanges.subscribe((change) => {
          if (change == null || change == undefined) {
            startWith("");
          } // Value inside the input field as soon as it changes
          else {
            this.filterCountry(change);
          }
        });
      });
    }
  }

  public getCountryByIso(option:string) {
    this.correct = true;
    // return this.countryList.find((x) => x.iso === option).name;
    if (option === "") return "";
    let country = this.countryMap.get(option);
    if(country) return country.name;
  }

  checkCountryChange(change) {
    var x = this.countryMap.get(change);
    // var x = this.filteredCountries.find((x) => x.iso === change);
    if (!x) {
      this.correct = false;
    }
  }

  protected filterCountry(change: String) {
    this.filteredCountries = this.countryList;
    if (!this.countryList) {
      return;
    }

    // get the search keyword
    if (!change) {
      this.filteredCountries = [];
      return;
    } else {
      this.checkCountryChange(change);
      change = change.toLowerCase();
    }
    // filter the search
    this.filteredCountries = this.countryList.filter((co) => co.name.toLowerCase().indexOf(change) > -1);
  }

  selectionChanged(event: any) {
    this.selectedCountryChange.emit(event.option.value);
  }
}
