import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { NotificationService } from "src/app/core/services/notification.service";
import {DashboardService} from '../../dashboard.service';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {MatTableDataSource} from "@angular/material/table";
import {MatCheckbox, MatCheckboxChange} from "@angular/material/checkbox";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import {saveAs} from 'file-saver-es';
import {DatePipe} from "@angular/common";

@Component({
    selector: 'app-sessions',
    templateUrl: './sessions.component.html',
    styleUrls: ['./sessions.component.scss']
})
export class SessionsComponent implements OnInit {

    public sessionList: Array<any>;
    public correct = true;
    public dialogRef: any;
    public filterQuery = '';
    public dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
    public selectedNewsDocs = [];
    public isArchived: boolean = false;
    public isLoading: boolean = true;
    public err: string;
    public employeeList = [];
    public filteredOptions: Array<any>;
    public filteredOptions1: Array<any>;
    public temporaryDepartment: Array<any>;
    public currencyList: Array<any> = [];
    public attacherId: string;
    public attachmentList = [];
    public fileType: string;
    public fileObj: any;
    public comment: string;
    showSpinner = false;
    public uploadedFile: any;
    public fileError: any;
    public columns = ['select', 'sessionName', 'courseName', 'startDate', 'status', 'attachments', 'part'];
    public displayColumns = ['sessionName', 'trainers', 'courseName', 'startDate', 'status', 'attachments'];

    @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
    @ViewChild(MatSort, {static: false}) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('addSession', {static: false}) addSession;
    @ViewChild('deleteSession', {static: false}) deleteSession;
    @ViewChild('attach', {static: false}) attach;
    @ViewChild('archive', {static: false}) archive;
    @ViewChild('filterSession', {static: false}) filterSession;
    @ViewChild('useCourseData', {static: false}) useCourseData: HTMLInputElement;

    public isSessionAdded: boolean;
    public sessionForm: FormGroup;
    public filterForm: FormGroup;


    constructor(private service: DashboardService,
                private route: Router,
                public dialog: MatDialog,
                private modalService: NgbModal,
                private fb: FormBuilder,
                private toastr: NotificationService,
                public datepipe: DatePipe) {
        this.service.getcourseList().subscribe(resp => {
            if (resp && resp.data) {
                resp?.data?.map(value => {
                    this.courseList.push({
                        title: value?.title,
                        id: value?.id
                    })
                });
            }
        });
        this.createForm();

    }

    public createForm(): void {
        this.sessionForm = this.fb.group({
            sessionName: ['', Validators.required],
            courseId: ['', Validators.required],
            startDate: ['', Validators.required],
            endDate: [''],
            deliveryLocation: [''],
            deliveryMethod: ['', Validators.required],
            status: ['', Validators.required],
            description: [''],
            useCourseData: [false]
        });

        this.filterForm = this.fb.group({
            sessionName: [''],
            courseId: [''],
            startDateFrom: [null],
            startDateTo: [null],
            trainerId: [''],
            participantId: [''],
            status: [''],
        });
    }

    public courseList = [];

    ngOnInit() {
        this.getSessionList();


        this.service.getEmployeeDetailList().subscribe(resp => {
            if (resp && resp.data) {
                resp.data.map(e => {
                    this.employeeList.push({label: e.id + ' ' + e.name, value: e.id});
                });
            }
        });
        this.service.getStructureList().subscribe(resp => {
            if (resp && resp.data) {
                this.temporaryDepartment = resp.data;
            }
        });

        this.service.getPayGradeCurrencyList().subscribe(user => {
            if (user && user.data && user.data.currency) {
                user.data.currency.map(value => {
                    this.currencyList.push(value);
                });
                console.log("data c:  ", this.currencyList);
            }
        });

        this.initValuesDetection();
    }

    initValuesDetection() {
        this.filteredOptions = this.employeeList;
        this.filteredOptions1 = this.courseList;

        this.sessionForm.controls['courseId'].valueChanges.subscribe(change => {

            if (change == null || change == undefined) {
                this.correct = true;

            } else {

                this.filterCourse(change);
            }

        });

        this.filterForm.controls['courseId'].valueChanges.subscribe(change => {

            if (change == null || change == undefined) {
                this.correct = true;

            } else {

                this.filterCourse(change);
            }

        });

        this.filterForm.controls['participantId'].valueChanges.subscribe(change => {

            if (change == null || change == undefined) {
                this.correct = true;

            } else {

                this.filterEmployees(change);
            }

        });


        this.filterForm.controls['trainerId'].valueChanges.subscribe(change => {

            if (change == null || change == undefined) {
                this.correct = true;

            } else {

                this.filterEmployees(change);
            }

        });
    }

    protected filterCourse(change: String) {
        this.filteredOptions1 = this.courseList;
        if (!this.courseList) {
            return;
        }

        // get the search keyword
        if (!change) {

            this.filteredOptions1 = this.courseList;
            return;
        } else {
            this.checkCourseChange(change);
            change = change.toLowerCase();
        }
        // filter the search
        this.filteredOptions1 =
            this.courseList.filter(emp => emp.title.toLowerCase().indexOf(change) > -1);

    }

    protected filterEmployees(change: String) {
        this.filteredOptions = this.employeeList;
        if (!this.employeeList) {
            return;
        }

        // get the search keyword
        if (!change) {

            this.filteredOptions = this.employeeList;
            return;
        } else {
            this.checkEmployeeChange(change);
            change = change.toLowerCase();
        }
        // filter the search
        this.filteredOptions =
            this.employeeList.filter(emp => emp.label.toLowerCase().indexOf(change) > -1);

    }

    checkCourseChange(change) {
        let x = this.filteredOptions1.find(x => x.title === change);
        if (!x) {
            this.correct = false;
        }

    }

    checkEmployeeChange(change) {
        let x = this.filteredOptions.find(x => x.value === change);
        if (!x) {
            this.correct = false;
        }

    }

    getSessionTrainer(data: any) {
        return data?.map(e => e.trainerName).join(',');
    }

    public getSessionList(): void {
        this.sessionList = [];
        this.isLoading = true;
        this.service.getsessionList().subscribe(res => {
            if (res && res.data) {
                this.isLoading = false;
                this.sessionList = res.data;

                this.err = '';
                this.dataSource = new MatTableDataSource<any>(this.sessionList);
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
            }
        }, error => {
            const _err = error.error && error.error.msg;
            this.isLoading = false;

            this.err = 'Error while getting Session List details';
            this.toastr.error(_err || this.err);
        });
    }

    public displayEmployeeByName(option) {
        if (!option) {
            return '';
        }
        this.correct = true;

        return this.employeeList.find(x => x.value === option).label;
    }

    public displayCourseByName(option) {
        if (!option) {
            return '';
        }
        this.correct = true;
        const date = this.courseList.find(x => x.id === option);

        return date?.title;
    }


    exportToCsv() {
        const csv = this.sessionList.map(
            row => {
                return [row?.sessionName, this.getSessionTrainer(row?.trainers), row?.courseName, this.datepipe.transform(row.startDate, 'dd-MM-yyyy'), row?.status, row?.hasAttachment].join(',') + '\n';
            }
        );
        csv.unshift(this.displayColumns.join(',') + '\n');
        const exportDate = new Date();
        // tslint:disable-next-line:max-line-length
        const exportFileName = `Csv-export-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}.csv`;
        saveAs(new Blob(csv, {type: 'text/csv;charset=utf-8'}), exportFileName);
    }

    saveAsPdf() {
        if (this.dataSource.filteredData.length <= 0) {
            this.toastr.error('No Data to export');
            return;
        }
        const body = [];
        for (let i = 0; i < this.dataSource.filteredData.length; i++) {
            body.push([
                this.dataSource.filteredData[i].sessionName,
                this.getSessionTrainer(this.dataSource.filteredData[i]?.trainers),
                this.dataSource.filteredData[i].courseName,
                this.datepipe.transform(this.dataSource.filteredData[i].startDate, 'dd-MM-yyyy'),
                this.dataSource.filteredData[i].status,
                this.dataSource.filteredData[i].hasAttachment]);
        }
        const doc = new jsPDF();
        autoTable(doc, {
            head: [this.displayColumns],
            body: body
        });
        const exportDate = new Date();
        const exportFileName = `Sessions-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}`;
        doc.save(exportFileName);
    }

    onModelChange(value: string) {
        this.dataSource.filter = value.trim().toLocaleLowerCase();
    }

    addNewSession() {
        this.sessionForm.reset();
        this.dialogRef = this.dialog.open(this.addSession, {
            width: '720px',
            height: 'auto'
        });
    }

    resetForm() {
        this.isArchived = false;
        this.filterForm.reset();
        this.getSessionList();
    }

    search() {


        this.service.sessionFilterRequest(this.filterForm.value).subscribe(res => {
            if (res.data && res) {
                this.dataSource = new MatTableDataSource<any>(res.data);
                this.dialogRef.close();
                this.toastr.success('filtered Successfully');
            }
        }, error => {
            this.toastr.error(error.error.msg || 'Error in getting the filter Request');
        });

    }

    filtersessionList() {
        this.dialogRef = this.dialog.open(this.filterSession, {
            width: '560px',
            height: 'auto'
        });
    }

    onSelectAllClicked() {
        this.selectedNewsDocs = [];
        this.matCheckBoxes.forEach(
            item => {
                this.selectedNewsDocs.push(item.value);
                item.checked = true;
            }
        );
    }

    onDeSelectAllClicked() {
        this.selectedNewsDocs = [];
        this.matCheckBoxes.forEach(
            item => {
                item.checked = false;
            }
        );
    }

    onDeleteSelected() {
        this.dialogRef = this.dialog.open(this.deleteSession, {
            width: '400px',
            height: 'auto'
        });
    }

    public deleteMultiple(): void {
        this.service.deleteMultipleSession(this.selectedNewsDocs).subscribe(user => {
            if (user) {
                this.close();
                this.getSessionList();
                this.toastr.success('Session deleted !');
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error deleting Session');
        });
        this.selectedNewsDocs = [];
    }

    onArchiveClicked() {
        this.dialogRef = this.dialog.open(this.archive, {
            width: '440px',
            height: 'auto'
        });
    }


    onNewDocSelectChange($event: MatCheckboxChange, id: any) {
        if (!this.alreadySelected($event.source) && $event.checked) {
            this.selectedNewsDocs.push($event.source.value);
        } else if (!$event.checked) {
            this.selectedNewsDocs = this.selectedNewsDocs.filter(
                value => {
                    return value !== $event.source.value;
                }
            );
        }
    }

    alreadySelected(item: MatCheckbox): boolean {
        this.selectedNewsDocs.forEach(
            value => {
                if (item === value) {
                    return true;
                }
            }
        );
        return false;
    }

    submitAddUser() {
        console.log("data : ", this.sessionForm.value);
        this.isSessionAdded = true;
        this.sessionForm.patchValue({'useCourseData': this.useCourseData.checked});
        this.service.addSession(this.sessionForm.value).subscribe(res => {
            if (res && res.data) {
                this.isSessionAdded = false;
                this.err = '';
                this.close();
                this.sessionForm.reset();
                this.toastr.success('Session added successfully');
                this.getSessionList();
            }
        }, error => {
            const _err = error.error && error.error.msg;
            this.isLoading = false;

            this.err = 'Error while adding Session List details';
            this.toastr.error(_err || this.err);
        });

    }

    public close(): void {
        this.dialogRef.close();
        this.sessionForm.reset();
    }

    onAddAttachment(id) {
        this.attacherId = id;
        this.showSpinner = true;

        this.service.getAttachmentList(this.attacherId).subscribe(
            r => {
                if (r && r.data) {
                    this.attachmentList = r.data;
                    this.dialogRef = this.dialog.open(this.attach, {
                        width: '560px',
                        height: 'auto'
                    });
                }
                this.showSpinner = false;
            },
            error => {
                this.showSpinner = false;
            }
        );
        this.comment = '';
        this.fileType = '';
        this.fileObj = null;

    }

    fileChange($event, type): void {
        this.uploadedFile = undefined;
        const reader = new FileReader();
        const file = $event.target.files[0];
        this.uploadedFile = file;
        const fileName = file && file.name;
        if (file && (file.size / 1024) < 2048) {
            this.fileObj = {
                added: new Date(),
                addedBy: 'string',
                desc: this.comment,
                docType: 'dd',
                empId: this.attacherId,
                fileName: fileName,
                size: file.size,
                url: ''
            };
        } else {
            this.fileError = 'Max size 2 MB';
            this.toastr.error('Max 2Mb File allowed.');
        }
    }

    onDeleteClicked(attachment: any) {
        const ID = attachment['id'];
        this.service.deleteAttachment(ID).subscribe(
            res => {
                this.service.getAttachmentList(this.attacherId).subscribe(
                    r => {
                        this.attachmentList = r.data;
                        this.toastr.success('Deleted');
                        this.service.getsessionList().subscribe(res => {
                            if (res && res.data) {
                                this.sessionList = res.data;
                                this.dataSource = new MatTableDataSource<any>(this.sessionList);
                                this.dataSource.sort = this.sort;
                                this.dataSource.paginator = this.paginator;
                            }
                        });
                    },
                    error => {
                        console.log(error);
                        this.toastr.error('Failed to delete');
                    }
                );
            }
        );

    }

    onDownloadClicked(attachment: any) {
        const URL = attachment['url'];
        const FILE_NAME = attachment['fileName'];
        window.open(URL);
    }
    public loadAttach = false;
    saveAttachment() {
        this.loadAttach = true;
        if (this.fileObj !== null) {
            this.service.uploadFile(this.uploadedFile).subscribe(resp => {
                if (resp && resp.data) {
                    this.fileObj['url'] = resp.data.filename;
                    this.fileObj['desc'] = this.comment;
                    this.service.addAttachment(this.fileObj).subscribe(
                        res => {
                            this.service.getAttachmentList(this.attacherId).subscribe(
                                r => {
                                    this.attachmentList = r.data;
                                    this.toastr.success('Attachment Added');
                                    this.dialogRef.close();
                                    this.getSessionList();
                                    this.loadAttach = false;
                                },
                                error => {
                                    this.toastr.error('Failed to add Attachment');
                                    this.loadAttach = false;
                                    this.dialogRef.close();
                                }
                            );
                        },
                        error => {
                            this.toastr.error('Failed to add Attachment');
                            this.loadAttach = false;
                            this.dialogRef.close();
                        }
                    );
                }
            }, error => {
                const err = error.error && error.error.msg;
                console.log(err);
                this.toastr.error('Failed to save Attachment', 'Upload Unsuccessful');
                this.loadAttach = false;
                this.dialogRef.close();
            });
        } else {
            this.toastr.error('No file Selected');
            this.loadAttach = false;
        }
        
    }

    viewAction(id) {
        this.route.navigate(['/training/s/sessions', id]);
    }

    viewAction1(id) {
        this.route.navigate(['/training/s/sessions/viewTrainer', id]);
    }

}
