import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { Observable } from "rxjs";

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})

export class CrudService<T> {
  private apiUrl = "api/auth/crud/";
  private url: string;

  attendanceConfigCache:any = null; 

  constructor(protected httpClient: HttpClient) {
    
  }

  setEntityName(entityName: string): void {
    this.url = API_URL + this.apiUrl + entityName;
  }

  getAll(): Observable<T> {
    return this.httpClient.get<T>(this.url);
  }

  getById(id: string): Observable<T> {
    return this.httpClient.get<T>(`${this.url}/${id}`);
  }

  create(item: T): Observable<T> {
    return this.httpClient.post<T>(this.url, item);
  }

  update(item: T, id:string): Observable<T> {
    return this.httpClient.put<T>(`${this.url}/${id}`, item);
  }

  // delete(id: string): Observable<any> {
  //   return this.httpClient.delete(`${this.url}/${id}`);
  // }

  delete(ids: string[]): Observable<any> {
    return this.httpClient.delete(`${this.url}/${ids}`);
  }
}
