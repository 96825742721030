import { Component, OnInit, ViewChild,ViewChildren,QueryList } from '@angular/core';
import { DashboardService } from '../../../dashboard.service';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotificationService } from "src/app/core/services/notification.service";
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {MatDialog} from '@angular/material/dialog';
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox';
import { AuthService } from 'src/app/core/services/auth.service';
@Component({
  selector: 'app-leave-type',
  templateUrl: './leave-type.component.html',
  styleUrls: ['./leave-type.component.scss']
})
export class LeaveTypeComponent implements OnInit {

  public rowsOnPage = 10;
  public filterQuery = '';
  public sortBy = '';
  public sortOrder = 'desc';
  public  selectedLeaves: any[] = [];
  public userList: Array<any>;
  public isLoading: boolean;
  public isUserAdded: boolean;
  public err: string;
  // public modalRef: any;
  public dialogRef: any;
  public userForm: FormGroup;
  public isEdit: boolean;
  public editId: string;
  public deleteId: string;
  public totalRecords: number;
  public activePage: number;
  public pageSize: number;
  public validationError = {
    required: 'Required'
  };
  public countryList: Array<any>;
  @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
  @ViewChild('addUser',{static:false}) addUser;
  @ViewChild('deleteUser',{static:false}) deleteUser;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  selectedCheckBoxList = [];
  ngbModalOptions: NgbModalOptions = {
    backdrop : 'static',
    keyboard : false
  };
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  paging = {
        pageSize: 10,
        totalRecords: 0,
        pageIndex: 0,
        pageSizeOptions: [10, 25, 50, 100]
    };
    public columns = ['delete','leaveType', 'country', 'empNomineeEnabled', 'showBalance', 'edit'];

  constructor(private service: DashboardService, 
    private readonly authService: AuthService,
              private dialog: MatDialog, 
              private fb: FormBuilder, 
              private toastr: NotificationService) {
    this.createForm();
  }
  public permissions : any;
  public read = false;
  public write = false ;
  public update = false;
  public delete = false;
 
  checkPermission(module,tabs) : void 
  {
      this.permissions = JSON.parse(this.authService.getPermissions());

      this.read = false ;
      this.write = false ;
      this.delete = false ;
      this.update = false;
      if(this.permissions != undefined && this.permissions != null )
      {
          for(let p of this.permissions)
          {
             
              if(p.id === module)
              {
                 
                  if(p.modules != undefined)
                  {
                    
                      for(let tab of p.modules)
                      {
                          if(tab.id === tabs)
                          {
                            
                              for(let move of tab.permits)
                              {
                                  if(move.id === "u")
                                  {
                                      this.update = true;
                                  }
                                  else if(move.id === "r")
                                  {
                                      this.read = true;
                                  }
                                  else if(move.id === "c")
                                  {
                                    
                                      this.write = true;
                                  }
                                  else if(move.id === "d")
                                  {
                                      this.delete = true;
                                  }
                              }
                          }
                      }
                  }
                 
              }
             
          }
      }
     

  }
  public sortDirection = 'asc';
  public tableConfig = {
    name: true
  };
  ngOnInit() {
    this.isLoading = true;
    this.checkPermission("leave","le_ccnf");
    this.isEdit = false;
    this.activePage = 1;
    this.pageSize = 10;
    this.totalRecords = 10;
    this.getJobCategoryList(this.paging.pageIndex + 1, this.paging.pageSize);
    this.isUserAdded = false;
    this.service.getCountriesList().subscribe(resp => {
      this.countryList = resp && resp.data;
    });

  }

  public createForm(): void {
    this.userForm = this.fb.group({
      leaveType: ['', [Validators.required]],
      country: ['', [Validators.required]],
      countryCode: ['', [Validators.required]],
      empNomineeEnabled: [''],
      exReportsIfNoEntitlement: [''],
      inBradfordFactorReport: [''],
      expirySpanInDays:['',[Validators.required]],
      showBalance:['']
    });
  }
  get f() { return this.userForm.controls; }
  public addNewUser() {
    this.isEdit = false;
    //***** */
    this.dialogRef = this.dialog.open(this.addUser,{
      width: '660px',
      autoFocus: false,
      maxHeight: '55vh' 
    });
      // this.ngbModalOptions);
    this.userForm.reset();
  }

  public close(): void {
    this.dialogRef.close();
  }

  public getJobCategoryList(pageIndex, pageSize): void {
    this.activePage = 1;
    this.service.getLeaveTypeList().subscribe(user => {
      if (user && user.data) {
        this.isLoading = false;
        this.userList = user.data;
        this.err = '';
        this.dataSource = new MatTableDataSource<any>(this.userList);
        this.dataSource.sort = this.sort;
        }
      if (user && user.paging) {
        this.totalRecords = user.paging.count || 10;
      }
    }, error => {
      const err  = error && error.error && error.error.msg;
      this.isLoading = false;
      this.err = err || 'Error while getting Leave Type';
    });
  }

  public submitAddJobCategory(): void {
    if (this.isEdit) {
      this.edit();
    } else {
      this.add();
    }
  }

  public add(): void {
    if(this.userForm.value.leaveType.trim()!="")
    {
      this.isUserAdded = true;
      this.service.addLeaveType(this.userForm.value).subscribe(user => {
        this.isUserAdded = false;
        this.toastr.success('Leave Type added successfully');
        this.dialogRef.close();
        this.userForm.reset();
        this.getJobCategoryList(this.paging.pageIndex + 1, this.paging.pageSize);
      }, error => {
        const err  = error && error.error && error.error.msg;
        this.isUserAdded = false;
        this.toastr.error(err || 'Error in adding job category.Please try again.');
      });
    }
    else{
      this.toastr.error('Name should not be empty');
    }
    
  }

  public edit(): void {
    if(this.userForm.value.leaveType.trim()!="")
    {
    this.isUserAdded = true;
    this.service.editLeaveType(this.userForm.value, this.editId).subscribe(user => {
      this.isUserAdded = false;
      this.toastr.success('Leave Type updated successfully');
      this.dialogRef.close();
      this.getJobCategoryList(this.paging.pageIndex + 1, this.paging.pageSize);
    }, error => {
      const err  = error && error.error && error.error.msg;
      this.isUserAdded = false;
      this.toastr.error(err || 'Error in updating Leave Type.Please try again.');
    });
  }
  else{
    this.toastr.error('Name should not be empty');
  }
  }

  public editUser(id): void {
    this.isEdit = true;
    this.service.getLeaveTypeById(id).subscribe(user => {
      if (user && user.data) {
        this.userForm.patchValue(user.data);
        this.dialogRef = this.dialog.open(this.addUser,{
          width: '660px',
          autoFocus: false,
          maxHeight: '55vh' 
        }); 
          // this.ngbModalOptions);
        this.editId = id;
      }
    }, error => {
      const err  = error && error.error && error.error.msg;
      this.toastr.error(err || 'Error getting Leave Type');
    });
  }


  onDeleteSelected() {
    this.dialogRef = this.dialog.open(this.deleteUser, {
        width: '440px',
        height: 'auto'
    });
}

  public deleteUserForm(id): void {
    this.deleteId = id;
    this.dialogRef = this.dialog.open(this.deleteUser,{
      width: '440px',
      height: 'auto'
    }); 
      // this.ngbModalOptions);
  }

  public deleteJobCategory(): void {
    this.service.deleteMultipleLeaveTypes(this.selectedCheckBoxList).subscribe(user => {
      if (user) {
          this.close();
          this.getJobCategoryList(this.paging.pageIndex + 1, this.paging.pageSize);
          this.toastr.success('Leave Type deleted successfully !');
      }
  }, error => {
      const err = error.error && error.error.msg;
      this.close();
      this.getJobCategoryList(this.paging.pageIndex + 1, this.paging.pageSize);
      this.toastr.error(err || 'Error deleting Leave Type');
  });
  this.selectedCheckBoxList = [];
  }

  public fillCountryDetail(): void {
    const val = this.userForm.value;
    const c = val.country;
    const cl = this.countryList.filter(clist => {
      return clist.name === c;
    });
    val['countryCode'] = cl[0].iso;
    this.userForm.patchValue(val);
  }

  onPaginatorClick(mf) {
    if (mf) {
      this.activePage = mf.activePage;
      this.rowsOnPage = mf.rowsOnPage;
    }
  }
  onSelectAllClicked() {
    this.selectedCheckBoxList = [];
    this.matCheckBoxes.forEach(
        item => {
            this.selectedCheckBoxList.push(item.value);
            item.checked = true;
        }
    );
}

onDeSelectAllClicked() {
    this.selectedCheckBoxList = [];
    this.matCheckBoxes.forEach(
        item => {
            item.checked = false;
        }
    );
}

onCheckBoxChange($event: MatCheckboxChange, id: any) {
  if (!this.alreadySelected($event.source) && $event.checked) {
      this.selectedCheckBoxList.push($event.source.value);
  } else if (!$event.checked) {
      this.selectedCheckBoxList = this.selectedCheckBoxList.filter(
          value => {
              return value !== $event.source.value;
          }
      );
  }
}
alreadySelected(item: MatCheckbox): boolean {
  this.selectedCheckBoxList.forEach(
      value => {
          if (item === value) {
              return true;
          }
      }
  );
  return false;
}

  saveAsPdf() {
    if (this.dataSource.filteredData.length <= 0) {
        this.toastr.error('No Data to export');
        return;
    }
    const body = [];
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        body.push([this.dataSource.filteredData[i].leaveType,
            this.dataSource.filteredData[i].country,
            this.dataSource.filteredData[i].exReportsIfNoEntitlement,
            // this.dataSource.filteredData[i].inBradfordFactorReport,
            this.dataSource.filteredData[i].empNomineeEnabled]);
    }
    const doc = new jsPDF();
    autoTable(doc, {
        head: [['leaveType', 'country', 'exReportsIfNoEntitlement', 'inBradfordFactorReport', 'empNomineeEnabled']],        
        body: body
    });
    const exportDate = new Date();
    const exportFileName = `User-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}}`;
    doc.save(exportFileName);

}

exportToCsv(){
  
}

onModelChange(value: string) {
  this.dataSource.filter = value.trim().toLocaleLowerCase();
}

onLeaveChanged(user: HTMLInputElement) {
  
}
onSelectAllUsers(){
  this.selectedLeaves = [];
  const inputs: HTMLCollection = document.getElementsByClassName('user-input');
  Array.from(inputs).forEach(
      value => {
          this.selectedLeaves.push(value);
          (value as HTMLInputElement).checked = true;
      }
  );
}

 
}
