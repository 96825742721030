import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup, NgForm, FormBuilder } from '@angular/forms';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { LanguageConfig } from 'src/app/Language-config';
import { AuthService } from 'src/app/core/services/auth.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { CountryDropdownComponent } from 'src/app/hrmshared/country-dropdown/country-dropdown.component';
import { saveAs } from 'file-saver-es';
import { PolicyService } from '../policy.service';
import { CrudService } from 'src/app/core/services/base-crud.service';

@Component({
  selector: 'app-attendance-policy',
  templateUrl: './attendance-policy.component.html',
  styleUrls: ['./attendance-policy.component.scss'],
  providers: [CrudService],
})
export class AttendancePolicyComponent implements OnInit {

  public filterQuery = "";
  public isLoading: boolean;
  public submitLoading: boolean;
  public generating:boolean;
  public err: string;
  // public modalRef: any;
  public crudForm: FormGroup;
  public isEdit: boolean;
  public editId: string;
  public deleteId: string;
  public selectedCountry: string;

  // for the material dialog
  public dialogRef: any;
  // for material data table
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  public columns = ["select", "name", "country", "edit"];
  public lang = new Map();

  selectedCheckBoxList = [];
  @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
  @ViewChild("add", { static: false }) addModal;
  @ViewChild("delete", { static: false }) deleteModal;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("countryDropdown") countryDropdown: CountryDropdownComponent;
  countryList: Array<any>;
  
  public addTierForm: FormGroup;
  public tiersColumns = ["name", "from", "to", "value", "percentage", "delete"];
  public tierList:Array<any> = [];
  public tierDataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  showAddTier:boolean = false;
  @ViewChild('tierAddFrom') private tierAddFrom: NgForm;

  public excludedEmployeeList:Array<any> = new Array<any>();

  constructor(
    // private modalService: NgbModal,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private toastr: NotificationService,
    private authService: AuthService,
    private crudService : CrudService<any>
  ) {
    this.createForm();
    this.lang = LanguageConfig.map;
    this.crudService.setEntityName("attendancePolicy");
  }
  public permissions: any;
  public read = false;
  public write = false;
  public update = false;
  public delete = false;

  checkPermission(module, tabs): void {
    this.permissions = JSON.parse(this.authService.getPermissions());

    this.read = false;
    this.write = false;
    this.delete = false;
    this.update = false;
    if (this.permissions != undefined && this.permissions != null) {
      for (let p of this.permissions) {
        if (p.id === module) {
          if (p.modules != undefined) {
            for (let tab of p.modules) {
              if (tab.id === tabs) {
                for (let move of tab.permits) {
                  if (move.id === "u") {
                    this.update = true;
                  } else if (move.id === "r") {
                    this.read = true;
                  } else if (move.id === "c") {
                    this.write = true;
                  } else if (move.id === "d") {
                    this.delete = true;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  ngOnInit() {
    this.read = false;
    this.write = false;
    this.update = false;
    this.delete = false;
    this.checkPermission("admin", "ad_jb");
    if (!this.delete) {
      this.columns.shift();
    }
    this.isLoading = true;
    this.isEdit = false;
    this.getAll();

    this.submitLoading = false;
  }
  public createForm(): void {
    this.crudForm = this.fb.group({
      name: [""],
      countryCode: [""],
      excludedEmployees:[],
      tiers:[]
    });

    this.addTierForm = this.fb.group({
      name: [""],
      from: "",
      to: "",
      value:"",
      percentage : ""
    });
  }

  public addNew() {
    this.isEdit = false;
    this.dialogRef = this.dialog.open(this.addModal, {
      width: "700px",
      height: "auto",
    });
    this.crudForm.reset();
    this.excludedEmployeeList = []; // reset
    this.selectedCountry = "";
  }

  public edit(id): void {
    this.isEdit = true;
    this.submitLoading = true;

    this.dialogRef = this.dialog.open(this.addModal, {
      width: "750px",
      height: "auto",
    });
    
    this.crudService.getById(id).subscribe(
      (user) => {
        if (user && user.data) {
          this.crudForm.patchValue(user.data);
          this.submitLoading = false;
          this.editId = id;
          this.selectedCountry = user.data.countryCode;
          this.excludedEmployeeList = []; // reset
          if(user.data.excludedEmployees){
            new Map(Object.entries(user.data.excludedEmployees)).forEach((value, key) => {
              this.excludedEmployeeList.push({ id: key, name: value });
            });
          }
          this.tierList = user.data.tiers ?  user.data.tiers : new Array();
          this.tierDataSource.data = this.tierList;
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.submitLoading = false;
        this.toastr.error(err || "Error getting Job Title");
      }
    );

  }

  public close(): void {
    this.dialogRef.close();
    this.resetAll();
  }

  private resetAll(){
    this.crudForm.reset();
    this.tierList = [];
    this.showAddTier = false;
    this.addTierForm.reset();
    if(this.tierAddFrom){
      this.tierAddFrom.resetForm();
    }
  }

  public getAll(): void {
    this.crudService.getAll().subscribe(
      (obj) => {
        if (obj && obj.data) {
          this.isLoading = false;
          this.dataSource = new MatTableDataSource<any>(obj.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.err = "";
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error while getting Job Title");
        this.isLoading = false;
        this.err = "Error while getting Job Title";
      }
    );
  }

  public save(): void {
    if (this.crudForm.invalid === true) {
      return;
    }
    if (this.isEdit) {
      this.updateObject();
    } else {
      this.addObject();
    }
  }

 

  private addObject(): void {
    this.submitLoading = true;
    const excludedEmployeesMap:{[key:string]:string} = this.excludedEmployeeList.reduce((acc, obj) => {
      acc[obj.id] = obj.name;
      return acc;
    }, {});
    this.crudForm.patchValue({ countryCode: this.selectedCountry, tiers:this.tierList, excludedEmployees : excludedEmployeesMap });
    this.crudService.create(this.crudForm.value).subscribe(
      (user) => {
        this.submitLoading = false;
        this.toastr.success("Attendance policy added successfully.");
        this.dialogRef.close();
        this.resetAll();
        this.getAll();
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.submitLoading = false;
        this.toastr.error(err || "Error in adding attendance policy.Please try again.");
      }
    );
  }

  private updateObject(): void {
    this.submitLoading = true;
    const excludedEmployeesMap:{[key:string]:string} = this.excludedEmployeeList.reduce((acc, obj) => {
      acc[obj.id] = obj.name;
      return acc;
    }, {});
    this.crudForm.patchValue({ countryCode: this.selectedCountry, tiers: this.tierList, excludedEmployees: excludedEmployeesMap });
    this.crudService.update(this.crudForm.value, this.editId).subscribe(
      (user) => {
        this.submitLoading = false;
        this.toastr.success("Attendance policy updated successfully");
        // this.modalRef.close();
        this.dialogRef.close();
        this.resetAll();
        this.getAll();
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.submitLoading = false;
        this.toastr.error(err || "Error in updating attendance policy.Please try again.");
      }
    );
  }

  public deleteObjects(): void {
    this.crudService.delete(this.selectedCheckBoxList).subscribe(
      (user) => {
        if (user) {
          this.close();
          this.getAll();
          this.toastr.success("Attendance policy deleted successfully.");
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.close();
        this.getAll();
        this.toastr.error(err || "Error deleting Attendance policy");
      }
    );
    this.selectedCheckBoxList = [];
  }

  onCheckBoxChange($event: MatCheckboxChange, id: any) {
    if (!this.alreadySelected($event.source) && $event.checked) {
      this.selectedCheckBoxList.push($event.source.value);
    } else if (!$event.checked) {
      this.selectedCheckBoxList = this.selectedCheckBoxList.filter((value) => {
        return value !== $event.source.value;
      });
    }
  }

  alreadySelected(item: MatCheckbox): boolean {
    this.selectedCheckBoxList.forEach((value) => {
      if (item === value) {
        return true;
      }
    });
    return false;
  }

  onSelectAllClicked() {
    this.selectedCheckBoxList = [];
    this.matCheckBoxes.forEach((item) => {
      this.selectedCheckBoxList.push(item.value);
      item.checked = true;
    });
  }

  onDeSelectAllClicked() {
    this.selectedCheckBoxList = [];
    this.matCheckBoxes.forEach((item) => {
      item.checked = false;
    });
  }

  onDeleteSelected() {
    this.dialogRef = this.dialog.open(this.deleteModal, {
      width: "440px",
      height: "auto",
    });
  }

  onModelChange(value: string) {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  exportToCsv() {
    const csv = this.dataSource.filteredData.map((row) => {
      return [row.title].join(",") + "\n";
    });
    csv.unshift(["title"].join(",") + "\n");
    const exportDate = new Date();
    // tslint:disable-next-line:max-line-length
    const exportFileName = `Csv-export-${exportDate.getFullYear()}-${
      exportDate.getMonth() + 1
    }-${exportDate.getDate()}.csv`;
    saveAs(new Blob(csv, { type: "text/csv;charset=utf-8" }), exportFileName);
  }

  saveAsPdf() {
    if (this.dataSource.filteredData.length <= 0) {
      this.toastr.error("No Data to export");
      return;
    }
    const body = [];
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      body.push([this.dataSource.filteredData[i].title]);
    }
    const doc = new jsPDF();
    autoTable(doc, {
      head: [["title"]],
      body: body,
    });
    const exportDate = new Date();
    const exportFileName = `payroll-${exportDate.getFullYear()}-${
      exportDate.getMonth() + 1
    }-${exportDate.getDate()}}`;
    doc.save(exportFileName);
  }

  public addTier(){
    if(this.addTierForm.valid){
      this.tierList.push(this.addTierForm.value);
      this.tierDataSource.data = this.tierList;
      this.showAddTier = false;
      this.addTierForm.reset();
      if(this.tierAddFrom){
        this.tierAddFrom.resetForm();
      }
    }
  }


  deleteTier(row){
    let removeIndx = this.tierList.indexOf(row);
    this.tierList.splice(removeIndx, 1);
    this.tierDataSource.data = this.tierList;
  }

  public updateExcludedEmployee(event) {
    this.excludedEmployeeList = event;
  }

}


