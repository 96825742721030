import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'selectTemplate',
  templateUrl: './select-template.component.html',
  styleUrls: ['./select-template.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class SelectTemplateComponent implements OnInit {

  @Input('name')
  name:string;

  @Input('label')
  label:string;

  @Input()
  validation: boolean = false;

  @Input('validationMessage')
  validationMessage: string;

  @Input()
  options: any[];

  constructor() { }

  ngOnInit(): void {
  }

}
