import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-monthly-attendance',
  templateUrl: './my-monthly-attendance.component.html',
  styleUrls: ['./my-monthly-attendance.component.scss']
})
export class MyMonthlyAttendanceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
