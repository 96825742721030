<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
    <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="page-header-title">
            <span class="ng-star-inserted"></span><span class="ng-star-inserted">
              <h4 class="m-b-10 ng-star-inserted">{{lang.get("locations")}}</h4>
            </span>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div> 
</div>


<div class="page-body no-title">
  <div class="row">
    <div class="col-sm-12">
      <div class="row top-tools">
        <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6 left-tools">
          <span class="export-icon-btn csv" (click)="exportToCsv()"><i
              class="material-icons">get_app</i><span>{{lang.get("csv")}}</span>
          </span>
          <span class="export-icon-btn pdf" (click)="saveAsPdf()"><i
              class="material-icons">get_app</i><span>{{lang.get("pdf")}}</span>
          </span>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
          <div class="relative-position search-filter-con">
            <span class="icon material-icons ng-tns-c114-0"> search </span>
            <mat-form-field>
              <input matInput #inputSearch type="search" [(ngModel)]="filterQuery" maxlength="40"
                (ngModelChange)="onModelChange(filterQuery)" class="form-control input-sm full-data-search">
            </mat-form-field>
            <span class="mytooltip cus-left tooltip-effect-4" [hidden]="!this.write">
              <span class="tooltip-item">
                <button mat-mini-fab class="btn btn-primary  light add-rounded margin-button-05" (click)="addNewUser()">
                  <i class="fa fa-plus"></i>
                </button>
              </span>
              <span class="tooltip-content clearfix">
                <span class="tooltip-text">{{lang.get("new")}}</span>
              </span>
            </span>

          </div>
        </div>
      </div>


      <app-card [title]="'Location'">
        <div class="dt-responsive" *ngIf="dataSource">
          <div class="table-responsive">
            <mat-card-content>
              <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="select">
                  <mat-header-cell [hidden]="!this.delete" *matHeaderCellDef>
                    <a [matMenuTriggerFor]="menu" class="check-all">
                      <span class="material-icons">
                        more_vert
                      </span>
                    </a>

                    <mat-menu #menu="matMenu">
                      <button (click)="onSelectAllClicked()" mat-menu-item> {{lang.get("select all")}}</button>
                      <button (click)="onDeSelectAllClicked()" *ngIf="selectedCheckBoxList.length > 0" mat-menu-item>
                        {{lang.get("deselect all")}}
                      </button>
                      <button (click)="onDeleteSelected()" *ngIf="selectedCheckBoxList.length > 0"
                        mat-menu-item>{{lang.get("delete selected")}}
                      </button>
                    </mat-menu>
                  </mat-header-cell>
                  <mat-cell [hidden]="!this.delete" *matCellDef="let row">
                    <mat-checkbox #matCheckbox (change)="onCheckBoxChange($event, row.id)" value="{{row.id}}">
                    </mat-checkbox>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="location">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>{{lang.get("name")}}</mat-header-cell>
                  <mat-cell (click)="this.update ? editUser(row.id) : doNothing()" class="{{this.editClass}}"
                    *matCellDef="let row">
                    {{ row.location }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="city">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>{{lang.get("city")}}</mat-header-cell>
                  <mat-cell (click)="this.update ? editUser(row.id) : doNothing()" class="{{this.editClass}}"
                    *matCellDef="let row">
                    {{ row.city }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="country">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>{{lang.get("country")}}</mat-header-cell>
                  <mat-cell (click)="this.update ? editUser(row.id) : doNothing()" class="{{this.editClass}}"
                    *matCellDef="let row">
                    {{ row.country }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="phone">
                  <mat-header-cell *matHeaderCellDef>{{lang.get("phone")}}</mat-header-cell>
                  <mat-cell (click)="this.update ? editUser(row.id) : doNothing()" class="{{this.editClass}}"
                    *matCellDef="let row">
                    {{ row.phone }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="numberOfEmployees">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>{{lang.get("nof")}} </mat-header-cell>
                  <mat-cell (click)="this.update ? editUser(row.id) : doNothing()" class="{{this.editClass}}"
                    *matCellDef="let row">
                    {{ row.numberOfEmployees }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="eeoenable">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>EEO enabled</mat-header-cell>
                  <mat-cell (click)="this.update ? editUser(row.id) : doNothing()" class="{{this.editClass}}"
                    *matCellDef="let row">
                    {{ row.eeoenable ? 'Yes' : 'No' }}
                  </mat-cell>
                </ng-container>
                <!--
                <ng-container matColumnDef="edit">
                  <mat-header-cell *matHeaderCellDef>Edit</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                      <span class="mytooltip tooltip-effect-4">
                          <span class="tooltip-item"><i class="single-icon-click fa fa-pencil" (click)="editUser(row.id)"></i></span>
                          <span class="tooltip-content clearfix">
                          <span class="tooltip-text">Edit</span>
                          </span>
                      </span>
                  </mat-cell>
                </ng-container>
                -->
                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columns;">
                </mat-row>
              </mat-table>
              <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
              <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
            </mat-card-content>
          </div>
          <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
        </div>
      </app-card>
    </div>
  </div>
</div>

<ng-template #addUser let-modal>
  <div class="mat-modal-header">
    <h4 class="mat-title-modal">
      <span *ngIf="!isEdit">{{lang.get("add")}}</span>
      <span *ngIf="isEdit">{{lang.get("edit")}}</span> {{lang.get("location")}}
    </h4>
  </div>
  <div class="mat-modal-body">
    <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>
    <form *ngIf="!isUserAdded" [formGroup]="userForm" action="javascript:" class="form-material full-width-inputs">
      <div class="row form-group">

        <div class="col-sm-4">
          <mat-form-field class="form-element">
            <mat-label>{{lang.get("name")}}</mat-label>
            <input class="form-control" formControlName="location" matInput maxlength="40" name="name" required=""
              type="text">
          </mat-form-field>
        </div>

        <div class="col-sm-4">
          <mat-form-field class="form-element">
            <mat-label>{{lang.get("country")}}</mat-label>
            <input required="" class="form-control" type="text" aria-label="Country" matInput
              formControlName="contrycode" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="getCountryByIso.bind(this)">
              <mat-option *ngFor="let r of filteredOptions " [value]="r.iso">
                {{r.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-sm-4">
          <mat-form-field class="form-element">
            <mat-label>{{lang.get("timeZone")}} </mat-label>
            <input class="form-control" type="text" aria-label="Time Zone" matInput formControlName="timezone"
              [matAutocomplete]="auto2">
            <mat-autocomplete #auto2="matAutocomplete" [displayWith]="getTimeZoneByName.bind(this)">
              <mat-option *ngFor="let r of filteredTimeZone " [value]="r">
                {{r}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-sm-4">
          <mat-form-field class="form-element">
            <mat-label>{{lang.get("state/province")}}</mat-label>
            <input pattern="*" class="form-control" formControlName="state" matInput maxlength="40" name="state"
              type="text">
            <mat-error *ngIf="!userForm.controls.state.valid && userForm.controls.state.touched">
              please enter state
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-4">
          <mat-form-field class="form-element">
            <mat-label>{{lang.get("city")}}</mat-label>
            <input pattern="*" class="form-control" formControlName="city" matInput maxlength="40" name="city"
              required="" type="text">
            <mat-error *ngIf="!userForm.controls.city.valid && userForm.controls.city.touched">
              please enter City
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-4">
          <mat-form-field class="form-element">
            <mat-label>{{lang.get("zip/postal code")}} </mat-label>
            <input class="form-control" formControlName="postalcode" matInput maxlength="40" name="postalcode"
              type="text">
            <mat-error *ngIf="!userForm.controls.postalcode.valid && userForm.controls.postalcode.touched">
              please enter Zip/postal code
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-4">
          <mat-form-field class="form-element">
            <mat-label>{{lang.get("phone")}}</mat-label>
            <input pattern="[+0-9]*" type="text" class="form-control" formControlName="phone" maxlength="40" matInput
              name="phone" mixlength="5">

            <mat-error *ngIf="!userForm.controls.phone.valid && userForm.controls.phone.touched">
              {{ validationError.contact }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-4">
          <mat-form-field class="form-element">
            <mat-label>{{lang.get("fax")}}</mat-label>
            <input class="form-control" formControlName="fax" mask="00000000" maxlength="40" matInput name="fax"
              type="text">
            <mat-error *ngIf="!userForm.controls.fax.valid && userForm.controls.fax.touched">
              please enter Fax
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-4">
          <mat-form-field class="form-element">
            <mat-label>{{lang.get("mobile")}}</mat-label>
            <input pattern="[+0-9]*" type="text" maxlength="40" class="form-control" formControlName="mobile" matInput
              name="mobile">
            <mat-error *ngIf="!userForm.controls.address.valid && userForm.controls.address.touched">
              please enter address
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12">
          <mat-form-field class="form-element">
            <mat-label>{{lang.get("address")}}</mat-label>
            <input class="form-control" formControlName="address" matInput maxlength="50" name="address" type="text">
          </mat-form-field>
        </div>
        <div class="col-sm-4">
          <mat-form-field class="form-element">
            <mat-label>{{lang.get("longitude")}}</mat-label>
            <input class="form-control" type="text" aria-label="Longitude" matInput formControlName="longitude" >
          </mat-form-field>
        </div>
        <div class="col-sm-4">
          <mat-form-field class="form-element">
            <mat-label>{{lang.get("latitude")}}</mat-label>
            <input class="form-control" type="text" aria-label="Latitude" matInput formControlName="latitude" >
          </mat-form-field>
        </div>
        <div class="col-sm-4">
          <mat-form-field class="form-element">
            <mat-label>{{lang.get("punchRadius")}}</mat-label>
            <input class="form-control" type="text" aria-label="Punch In/Out Radius" matInput formControlName="radius" >
          </mat-form-field>
        </div>            
        <div class="col-sm-12">
          <span>Punch In/Out QRCode</span>
          <ngx-qrcode  
          [elementType]="qrCodeElementType"
          [errorCorrectionLevel]="qrCodeCorrectionLevel"
          [value]="qrCodeValue"
          cssClass="qrCode"></ngx-qrcode></div>         
      </div>

      <div class="row">
        <div class="action-buttons multi-btns col-sm-12 text-right">
          <button (click)="close()" class="btn-danger" mat-raised-button><i
              class="flaticon-cancel"></i>{{lang.get("cancel")}}
          </button>

          <button mat-raised-button class="btn-primary" color="primary" [disabled]="!userForm.valid||!this.correct"
            (click)="saveLocation()"><i class="flaticon-diskette"></i>{{lang.get("save")}}</button>

          <button mat-raised-button class="btn-primary" color="primary" [disabled]="editId == null"
          (click)="downloadQRCode()">{{lang.get("downloadQRCode")}}</button>
        </div>
      </div>
    </form>
  </div>

</ng-template>


<ng-template #deleteUser let-modal>
  <div mat-dialog-actions>
    <!-- <div class="header"> -->
    <div class="mat-modal-header">
      <!-- <h4 class="card-title card-title-modal">Delete Location</h4> -->
      <h4 class="mat-title-modal">{{lang.get("delete")}} {{lang.get("location")}}</h4>
    </div>
    <!-- <div class="card-content px-2 py-2"> -->
    <div class="mat-modal-body">
      <div class="text-left are-u">{{lang.get("areyousure")}}</div>
      <div class="row">
        <!-- <div class="col-sm-12 text-right"> -->
        <div class="multi-btns col-sm-12 text-right">
          <button mat-raised-button class="btn-danger" (click)="close()">
            <i class="flaticon-cancel"></i>{{lang.get("cancel")}}</button>
          <button mat-raised-button class="btn-primary" color="primary" (click)="deleteJobCategory()">
            <i class="flaticon-trash"></i>{{lang.get("delete")}}</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>