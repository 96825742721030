<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
    <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="page-header-title">
            <span class="ng-star-inserted"></span
            ><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted">Leave Type</h4></span>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</div>
<div class="page-body no-title">
  <div class="row">
    <div class="col-sm-12">
      <div class="row top-tools">
        <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6">
          <span class="export-icon-btn csv"
            ><i class="material-icons" (click)="exportToCsv()">get_app</i><span>CSV</span>
          </span>
          <span class="export-icon-btn pdf" (click)="saveAsPdf()"
            ><i class="material-icons">get_app</i><span>PDF</span>
          </span>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
          <div class="relative-position search-filter-con">
            <span class="icon material-icons ng-tns-c114-0"> search </span>
            <mat-form-field>
              <input
                matInput
                #inputSearch
                type="search"
                [(ngModel)]="filterQuery"
                maxlength="30"
                (ngModelChange)="onModelChange(filterQuery)"
                class="form-control input-sm full-data-search"
              />
            </mat-form-field>
            <span class="mytooltip cus-left tooltip-effect-4" [hidden]="!write">
              <span class="tooltip-item">
                <button mat-mini-fab class="btn btn-primary light add-rounded margin-button-05" (click)="addNewUser()">
                  <i class="fa fa-plus"></i>
                </button>
              </span>
              <span class="tooltip-content clearfix">
                <span class="tooltip-text">New</span>
              </span>
            </span>
          </div>
        </div>
      </div>

      <!-- <app-loading *ngIf="isLoading && !err" [isLoading]="isLoading && !err"></app-loading> -->
      <app-card [title]="'Leave Type'">
        <div class="dt-responsive" *ngIf="dataSource">
          <div class="table-responsive">
            <mat-card-content>
              <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="delete">
                  <mat-header-cell [hidden]="!delete" *matHeaderCellDef>
                    <a [matMenuTriggerFor]="menu" class="check-all">
                      <span class="material-icons"> more_vert </span>
                    </a>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="onSelectAllClicked()">Select All</button>
                      <button (click)="onDeSelectAllClicked()" *ngIf="selectedCheckBoxList.length > 0" mat-menu-item>
                        Deselect All
                      </button>
                      <button (click)="onDeleteSelected()" *ngIf="selectedCheckBoxList.length > 0" mat-menu-item>
                        Delete Selected
                      </button>
                    </mat-menu>
                  </mat-header-cell>
                  <mat-cell [hidden]="!delete" *matCellDef="let row">
                    <mat-checkbox
                      #matCheckbox
                      (change)="onCheckBoxChange($event, row.id)"
                      value="{{ row.id }}"
                    ></mat-checkbox>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="leaveType">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Leave Type</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.leaveType }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="country">
                  <mat-header-cell *matHeaderCellDef>Country</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.country }}
                  </mat-cell>
                </ng-container>
                <!-- <ng-container matColumnDef="exReportsIfNoEntitlement">
                  <mat-header-cell *matHeaderCellDef>Is Entitlement Situational</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.exReportsIfNoEntitlement ? "Yes" : "No" }}
                  </mat-cell>
                </ng-container> -->
                <ng-container matColumnDef="inBradfordFactorReport">
                  <mat-header-cell *matHeaderCellDef>Included In Bradford Factor Report</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.inBradfordFactorReport ? "Yes" : "No" }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="empNomineeEnabled">
                  <mat-header-cell *matHeaderCellDef>Nominate Employee</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.empNomineeEnabled ? "Yes" : "No" }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="showBalance">
                  <mat-header-cell *matHeaderCellDef>Show Balance</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.showBalance ? "Yes" : "No" }}
                  </mat-cell>
                </ng-container>
                <!-- <ng-container matColumnDef="edit">
                      <mat-header-cell *matHeaderCellDef>Edit</mat-header-cell>
                      <mat-cell *matCellDef="let row">
                          <i class="fa fa-edit" (click)="editUser(row.id)"></i>
                      </mat-cell>
                  </ng-container> -->
                <ng-container matColumnDef="edit">
                  <mat-header-cell [hidden]="!update" *matHeaderCellDef>Edit</mat-header-cell>
                  <mat-cell [hidden]="!update" *matCellDef="let row">
                    <span class="mytooltip tooltip-effect-4">
                      <span class="tooltip-item"
                        ><i class="single-icon-click fa fa-pencil" (click)="editUser(row.id)"></i
                      ></span>
                      <span class="tooltip-content clearfix">
                        <span class="tooltip-text">Edit</span>
                      </span>
                    </span>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columns"> </mat-row>
              </mat-table>
              <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
              <mat-paginator
                [length]="paging.totalRecords"
                [pageSize]="paging.pageSize"
                [pageSizeOptions]="paging.pageSizeOptions"
                [pageIndex]="paging.pageIndex"
                (page)="pageChange($event)"
              >
              </mat-paginator>
            </mat-card-content>
          </div>
          <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
        </div>
      </app-card>
    </div>
  </div>
</div>

<ng-template #addUser let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">
        <span *ngIf="!isEdit">Add</span>
        <span *ngIf="isEdit">Edit</span>
        Leave Type
      </h4>
    </div>
    <div class="mat-modal-body">
      <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>
      <form class="form-material full-width-inputs" action="javascript:" [formGroup]="userForm" *ngIf="!isUserAdded">
        <div class="row">
          <div class="col-md-12">
            <mat-form-field class="form-element">
              <mat-label>Name</mat-label>
              <input
                type="text"
                matInput
                class="form-control"
                maxlength="30"
                name="name"
                required=""
                id="pass"
                formControlName="leaveType"
              />
              <mat-error *ngIf="f.leaveType.invalid && (f.leaveType.dirty || f.leaveType.touched)">
                Name is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row" *ngIf="isEdit">
          <div class="col-md-12">
            <mat-form-field class="form-element">
              <mat-label>Country</mat-label>
              <mat-select
                formControlName="country"
                class="form-control"
                (selectionChange)="fillCountryDetail()"
                disabled
              >
                <mat-option *ngFor="let n of countryList" [value]="n.name">{{ n.name }}</mat-option>
              </mat-select>
              <mat-error *ngIf="f.country.invalid && (f.country.dirty || f.country.touched)" class="error-class-red"
                >Country is required.</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div class="row" *ngIf="!isEdit">
          <div class="col-md-12">
            <mat-form-field class="form-element">
              <mat-label>Country</mat-label>
              <mat-select formControlName="country" class="form-control" (selectionChange)="fillCountryDetail()">
                <mat-option *ngFor="let n of countryList" [value]="n.name">{{ n.name }}</mat-option>
              </mat-select>
              <mat-error *ngIf="f.country.invalid && (f.country.dirty || f.country.touched)" class="error-class-red"
                >Country is required.</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-form-field class="form-element">
              <mat-label>Expiry Span (In Days)</mat-label>
              <input
                type="number"
                matInput
                class="form-control"
                maxlength="30"
                name="expirySpanInDays"
                required=""
                id="exp"
                formControlName="expirySpanInDays"
              />
            </mat-form-field>
          </div>
        </div>
        <br />
        <!-- <div class="col-md-4 flex-checkbox">
          <mat-checkbox class="form-control" formControlName="exReportsIfNoEntitlement"
            >Is Entitlement Situational</mat-checkbox
          >
        </div> -->
        <!-- <div class="col-md-4 flex-checkbox">
          <mat-checkbox class="form-control" formControlName="inBradfordFactorReport">Includes In Bradford Factor Report</mat-checkbox>
        </div> -->
        <div class="col-md-4 flex-checkbox">
          <mat-checkbox class="form-control" formControlName="empNomineeEnabled">Nominate Employee</mat-checkbox>
        </div>

        <div class="col-md-4 flex-checkbox">
          <mat-checkbox class="form-control" formControlName="showBalance">Show Balance</mat-checkbox>
        </div>

        <div class="row">
          <div class="multi-btns col-sm-12 text-right">
            <button mat-raised-button class="btn-danger" (click)="close()">
              <i class="flaticon-cancel"></i>Cancel
            </button>
            <button
              mat-raised-button
              class="btn-primary"
              color="primary"
              *ngIf="!editForm"
              [disabled]="!userForm.valid"
              (click)="submitAddJobCategory()"
            >
              <i class="flaticon-diskette"></i>Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #deleteUser let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">Delete Leave Type</h4>
    </div>
    <div class="mat-modal-body">
      <div class="text-left are-u">Are you sure ?</div>
      <div class="row">
        <div class="multi-btns col-sm-12 text-right">
          <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i>Cancel</button>
          <button mat-raised-button class="btn-primary" color="primary" (click)="deleteJobCategory()">
            <i class="flaticon-trash"></i>Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
