import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {DashboardService} from '../../dashboard.service';
import {MatDialog} from '@angular/material/dialog';
import { NotificationService } from "src/app/core/services/notification.service";
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {saveAs} from 'file-saver-es';



@Component({
  selector: 'app-audit-trail',
  templateUrl: './audit-trail.component.html',
  styleUrls: ['./audit-trail.component.scss']
})
export class AuditTrailComponent implements OnInit {

  public rowsOnPage = 10;
  public filterQuery = '';
  public sortBy = '';
  public sortOrder = 'desc';

  public auditList: Array<any> = [];
  public sectionsList: Array<any> = [];
  public employeeList = [];
  public filteredOptions :Array<any>;
  public isLoading: boolean;
  public err: string;
  public dialogRef: any;
  public sortDirection = 'asc';
  public auditFilterForm: FormGroup;
  public empId = new FormControl();
  public fromDate: Date;
  public searchDate;
  public correct : boolean = true;
  @ViewChild('filterAuditTrial', {static: false}) filterAuditTrial;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  
  public columns = ['dateAndTime' , 'actionOwner', 'Action' , 'emp','sec', 'actionDesc' ];
  constructor(
      private service: DashboardService,
      private dialog: MatDialog,
      private fb: FormBuilder,
      private toastr: NotificationService) {
      this.createForm();
  }

  ngOnInit() {
    this.isLoading = true;
    this.getAuditList();
    
    // to get the drop down for the employee list
  //   this.service.getUserList().subscribe(resp => {
  //     if (resp && resp.data) {
  //         resp.data.map(e => {
  //             this.employeeList.push({ label: e.username + ' (' + e.employeeName+')', value: e.employeeid });
  //         });
  //         this.filteredOptions=this.employeeList;
  //     }
  // });
  this.initValuesDetection();
  }
  initValuesDetection()
    {
      this.filteredOptions=this.employeeList;
      
     this.auditFilterForm.controls['ownerId'].valueChanges.subscribe(change => {
        
        if(change == null || change == undefined)
        {
          this.correct=true;
        } // Value inside the input field as soon as it changes
        else{
       
          this.filterUsers(change);
        }
       
      });
     
    }
    protected filterUsers(change : String) {
      this.filteredOptions = this.employeeList;
      if (!this.employeeList) {
          return;
      }
      
      // get the search keyword
      if (!change) {
       
        this.filteredOptions =this.employeeList;
          return;
      } else {
        this.checkUserChange(change);
        change = change.toLowerCase();
      }
      // filter the search
      this.filteredOptions =
          this.employeeList.filter(emp => emp.label.toLowerCase().indexOf(change) > -1);
        
  }
  checkUserChange(change)
  {
    var x = this.filteredOptions.find(x => x.value === change);
    if(!x)
    {
      this.correct=false;
    }
  }
  public displayUserByName(option) {
    
    this.correct =true;
  
  return this.employeeList.find(x=>x.value === option).label;
}
  public getAuditList() {
    this.service.getAuditTrial().subscribe(res => {
      if (res && res.data) {
        
        this.isLoading = false;
        let data = [] ;
        data = res.data ;
        data.map(u => {
          u.date = '';
          u.ao = '';
          u.a = '' ;
          u.e = '';
          u.s = '';
          u.d = '';
        });
       
        this.dataSource = new MatTableDataSource<any>(data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    }, error => {
      this.isLoading = false;
      this.toastr.error('Error in getting the Audit List');
    });
  }
  createForm() {
    this.auditFilterForm = this.fb.group({
      section : [''],
      action :[''],
      module :['Admin'],
      from:[''],
      to:[''],
      ownerId :['']
    });
    this.getSectionOnModuleChange();
  }

  getSectionOnModuleChange()
  {

    var module =  this.auditFilterForm.value.module;
    this.service.getSectionByModuleAudit(module).subscribe(res => {
      if (res && res.data) {
        
        this.sectionsList = res.data;
      }
    }, error => {
      this.toastr.error('Error in getting Sections');
    });

  }
  public close(): void {
    this.dialogRef.close();
  }
  filterAuditList() {
    this.dialogRef = this.dialog.open(this.filterAuditTrial,{
      width: '780px',
      height: 'auto'
    });
  }
  OnAuditSearch() {
    this.service.filterAuditTrial(this.auditFilterForm.value).subscribe(res => {
      if (res.data.length == 0) {
        this.toastr.error('No Record Found');
      } else if (res && res.data) {
        this.dataSource = new MatTableDataSource<any>(res.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.close();
        this.toastr.success('Filtered Successfully!..');
      }
    }, error => {
      this.toastr.error('Error in getting the data..');
    });
  }

  OnReset() {
    this.auditFilterForm.reset();
    this.close();
    this.toastr.success('Audit List Reset Successfully');
    this.getAuditList();
  }
  onModelChange(value: string) {
    console.log(value);
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
  exportToCsv() {
 
    const csv = this.dataSource.filteredData.map(
        row => {
          return [
              row.date = row.timeStamp,
              row.ao = row.actionOwner,
              row.a = row.action,
              row.e = row.effectedEmpsAsString != undefined ? JSON.stringify(row.effectedEmpsAsString).replace('\\n','|') : '',
              row.s = row.section ,
              row.d = JSON.stringify(row.actionDesc).replace('\\n','|')
          ].join(',') + '\n';
        }
    );
    csv.unshift(['Date and Time','Action Owner','Action','Employee','Section','Action Description'].join(',') + '\n');
    const exportDate = new Date();
    const exportFileName = `Csv-export-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}.csv`;
    saveAs(new Blob(csv, {type: 'text/csv;charset=utf-8'}), exportFileName);
  }

  saveAsPdf() {
    if (this.dataSource.filteredData.length <= 0) {
      this.toastr.error('No Data to export');
      return;
    }
    const body = [];
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      body.push(
          [
            this.dataSource.filteredData[i].timeStamp,
            this.dataSource.filteredData[i].actionOwner,
            this.dataSource.filteredData[i].action,
            this.dataSource.filteredData[i].effectedEmpsAsString,
            this.dataSource.filteredData[i].actionDesc
          ]);
    }
    const doc = new jsPDF();
    autoTable(doc, {
      head: [['Date and Time','Action Owner','Action','Employee','Action Description']],
      body: body
    });
    const exportDate = new Date();
    const exportFileName = `Audit trial-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}}`;
    doc.save(exportFileName);
  }
}
