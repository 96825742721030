
<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
      <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
          <div class="col-md-8">
          <div class="page-header-title">
              <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted">Attendance Data Upload</h4></span>
          </div>
          </div>
          <div class="col-md-4"></div>
      </div>
      </div>
  </div>
</div>



<div class="page-body no-title">
  <div class="row">
    <div class="col-sm-12">
      <app-card>
          <div class="dt-responsive">
            <div class="row">

              <div class="col-xs-4 col-sm-4 col-sm-4 col-md-4">
                <form [formGroup]="fileUpload">

                  <label for="file-upload" class="custom-file-upload btn-save btn-primary">
                    Select file
                  </label>
                  <input id="file-upload" type="file"  (change)="onFileChange($event)"  accept=".csv" />


                </form>
              </div>
              <div class="col-xs-4 col-sm-4 col-sm-4 col-md-4">
                <mat-form-field [style.width]="'50%'">
                  <input matInput for="file-upload" disabled="true" value="{{fileUpload.value.fileObj.name}}">
                </mat-form-field>
              </div>
              <div class="col-xs-4 col-sm-4 col-sm-4 col-md-4">

              </div>

              <div class="col-xs-8 col-sm-8 col-sm-8 col-md-8">
                Sample CSV file:
                <a class="badge badge-success" target="_blank" href="https://hrm-dev.s3.ap-south-1.amazonaws.com/sample/attendance_upload.csv">Download</a><br/>
                <span>
                  Upload file type should be CSV
                </span>
              </div>
              <div class="col-xs-4 col-sm-4 col-sm-4 col-md-4">

              </div>

              <div class="col-xs-4 col-sm-4 col-sm-4 col-md-4">

              </div>
              <div class="col-xs-4 col-sm-4 col-sm-4 col-md-4">

              </div>
              <div class="col-sm-12 text-right action-buttons multi-btns">
                <button mat-raised-button class="btn-primary" color="primary" [disabled]="!fileUpload.value.fileObj.name" (click)="onSubmit()" ><i class="flaticon-diskette"></i> Upload</button>
              </div>
            </div>
          </div>
        </app-card>
    </div>
  </div>
</div>
