<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
      <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
          <div class="col-md-8">
          <div class="page-header-title">
              <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted">{{lang.get("payGrades")}}</h4></span>
          </div>
          </div>
          <div class="col-md-4"></div>
      </div>
      </div>
  </div>
  </div>

<div class="page-body no-title">
  <div class="row">
    <div class="col-md-12">
      <div class="row top-tools">    
          <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6 left-tools">
            <span class="export-icon-btn csv" (click)="exportToCsv()"><i class="material-icons">get_app</i><span>{{lang.get("csv")}}</span>
            </span>
            <span class="export-icon-btn pdf" (click)="saveAsPdf()"><i class="material-icons">get_app</i><span>{{lang.get("pdf")}}</span>
            </span>
         </div>
          <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
            <div class="relative-position search-filter-con"> 
              <span class="icon material-icons ng-tns-c114-0"> search </span>
              <mat-form-field>
                <input matInput #inputSearch type="search" [(ngModel)]="filterQuery" maxlength="40"
                       (ngModelChange)="onModelChange(filterQuery)"
                       class="form-control input-sm full-data-search">
              </mat-form-field>
              <span class="mytooltip cus-left tooltip-effect-4" [hidden]="!write">
                <span class="tooltip-item">
                    <button mat-mini-fab class="btn btn-primary  light add-rounded margin-button-05" (click)="addNewUser()">
                        <i class="fa fa-plus"></i>
                    </button>
                </span>
                <span class="tooltip-content clearfix">
                <span class="tooltip-text">{{lang.get("new")}}</span>
                </span>
             </span>
  
            </div>
          </div>
          
        </div>
        
   
      <app-card [title]="'Pay Grades'">
        <div class="dt-responsive" *ngIf="dataSource">
          <div class="table-responsive table hrm-table-style-1 full-data-table table-cols-4">
            <mat-card-content>
              <mat-table [dataSource]="dataSource" matSort>
                  <ng-container matColumnDef="select">
                      <mat-header-cell *matHeaderCellDef [hidden]="!delete">
                          <a [matMenuTriggerFor]="menu" class="check-all">
                                <span class="material-icons">
                                    more_vert
                                </span>
                          </a>

                          <mat-menu #menu="matMenu">
                              <button (click)="onSelectAllClicked()" mat-menu-item>{{lang.get("select all")}}</button>
                              <button (click)="onDeSelectAllClicked()" *ngIf="selectedCheckBoxList.length > 0"
                                      mat-menu-item>{{lang.get("deselect all")}}
                              </button>
                              <button (click)="onDeleteSelected()" *ngIf="selectedCheckBoxList.length > 0"
                                      mat-menu-item>{{lang.get("delete selected")}}
                              </button>
                          </mat-menu>
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row" [hidden]="!delete">
                          <mat-checkbox #matCheckbox (change)="onCheckBoxChange($event, row.id)"
                                        value="{{row.id}}"></mat-checkbox>
                      </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="name">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>{{lang.get("payGrades")}}</mat-header-cell>
                      <mat-cell (click)="editUser(row.id) " class="{{this.classEdit}}" *matCellDef="let row">
                          {{ row.name }}
                      </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="printCurrency">
                      <mat-header-cell *matHeaderCellDef>{{lang.get("currency")}}</mat-header-cell>
                      <mat-cell (click)="editUser(row.id) " class="{{this.classEdit}}" *matCellDef="let row">
                          {{ row.printCurrency }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="printCurrencyCode">
                  <mat-header-cell style="width:35%" *matHeaderCellDef>{{lang.get("curCode")}}</mat-header-cell>
                  <mat-cell   (click)="editUser(row.id) " class="{{this.classEdit}}" *matCellDef="let row">
                      {{ row.printCurrencyCode }}
              </mat-cell>
            </ng-container >
                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columns;">
                </mat-row>
              </mat-table>
              <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
              <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
            </mat-card-content>
          </div>
          <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
        </div>
      </app-card>
    </div>
  </div>
</div>

<ng-template #addUser let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
     <h4 class="mat-title-modal">
      <span *ngIf="!isEdit">{{lang.get("add")}}</span>
      <span *ngIf="isEdit">{{lang.get("edit")}}</span> {{lang.get("payGrade")}}   
     </h4>
    </div>
    <div class="mat-modal-body">
    <!-- <div class="card my-0">
      <div class="header">
        <h4 class="card-title card-title-modal"><span *ngIf="!isEdit">Add</span>
        <span *ngIf="isEdit">Edit</span> Pay Grade</h4>
      </div>
      <div class="card-content"> -->
      <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>

      <form class="full-width-inputs form-material" action="javascript:" [formGroup]="userForm" *ngIf="!isUserAdded">
        <div class="row form-group">
          <div class="col-md-4">
            <mat-form-field class="form-element">
                <mat-label for="name">{{lang.get("name")}}</mat-label>
                <input type="text"  pattern= "*" matInput class="form-control" maxlength="40" id="name" formControlName="name" required="">
                <mat-error  *ngIf="!userForm.controls.name.valid && userForm.controls.name.touched">Required</mat-error>
            </mat-form-field>                       
        </div>
        </div>
        <div class="row form-group">
          <div class="multi-btns col-md-12 text-right">
            <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i> {{lang.get("cancel")}}</button>
            <button [hidden]="!update" mat-raised-button class="btn-primary" color="primary" (click)="assignCurrencies()"><i class="flaticon-right-arrow"></i>{{lang.get("assignCurrency")}}</button>
            <button [hidden]="!update" mat-raised-button class="btn-primary" color="primary" [disabled]="!userForm.valid"
            (click)="submitAddPayGrade()"><i class="flaticon-diskette"></i> {{lang.get("save")}}</button>
          </div>
        </div>
      </form>
      <div class="row form-group">
      <ng-container >
    
      <table class="table" *ngIf="userList && !err && !isLoading && !isUserAdded">
        <thead>
          <!-- <th></th> -->
          <th>{{lang.get("curCode")}}</th>
          <th>{{lang.get("currencyName")}}</th>
          <th>{{lang.get("minimumSalary")}}</th>
          <th>{{lang.get("maximumSalary")}}</th>
          <th>{{lang.get("edit")}}</th>
          <th>{{lang.get("delete")}}</th>
        </thead>
        <tbody>
          <tr *ngFor="let pc of payCurrencies; let i = index;">
            <td>{{ pc.currencyCode }}</td>
            <td>{{ pc.currencyName }}</td>
            <td>{{ pc.min }}</td>
            <td>{{ pc.max }}</td>

            <td><i class="fa fa-edit" (click)="editCurrency(pc, i)"></i></td>
            <td><i class="fa fa-trash" (click)="deleteCurrency(i)"></i></td>
          </tr>
        </tbody>
      </table>
      </ng-container>
    </div>  
      </div>
    </div>
<!-- </div> -->
</ng-template>


<ng-template #deleteUser let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
     <h4 class="mat-title-modal">{{lang.get("delete")}} {{lang.get("payGrade")}}</h4>
    </div>
    <div class="mat-modal-body"> 
    <!-- <div class="card my-0">
      <div class="header">
        <h4 class="card-title card-title-modal">Delete Pay Grade</h4>
      </div>
      <div class="card-content px-2 py-2"> -->
        <div class="text-left are-u">{{lang.get("areyousure")}}</div>
        <div class="row">
          <div class="multi-btns col-md-12 text-right">
            <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i> {{lang.get("cancel")}}</button>
            <button class="btn-primary" color="primary" mat-raised-button
                    (click)="deletePayGrade()"><i class="flaticon-trash"></i> {{lang.get("delete")}}
            </button>
        </div>
        </div>
      </div>
    </div>
</ng-template>

<ng-template #assignCurrency let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
     <h4 class="mat-title-modal"> {{lang.get("assignCurrency")}}</h4>
    </div>
    <div class="mat-modal-body">
    <!-- <div class="card my-0">
      <div class="header">
        <h4 class="card-title card-title-modal">Assign Currency</h4>
      </div>
      <div class="card-content"> -->
        <form class="form-material full-width-inputs " action="javascript:" [formGroup]="currencyForm">
          <div class="row form-group">
            <div class="col-md-12">
                <mat-form-field class="form-element">
                    <mat-label>{{lang.get("currency")}}</mat-label>
                    <input required="" class="form-control" type="text"
                           aria-label="Currency"
                           matInput
                           formControlName="currencyName"
                           [matAutocomplete]="auto"
                           
                          >
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayCurrencyByname.bind(this)">
                      <mat-option *ngFor="let r of filteredOptions " [value]="r.name">
                        {{r.name}}
                      </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
              <!-- <select class="form-control" id="curr" formControlName="currencyName" required="">
                <ng-container *ngFor="let currency of currencyList">
                  <option value="{{ currency.name }}">{{ currency.name }}</option>
                </ng-container>
              </select>
              <span class="form-bar"></span> <label class="col-form-label" 
              for="curr" class="float-label">Currency</label> -->
            </div>

            <div class="col-md-6">
              <mat-form-field>
                  <mat-label for="min">{{lang.get("minimumValue")}}</mat-label>
                  <input type="number" matInput class="form-control" maxlength="40" id="min" formControlName="min" mask="0000000000000" required="">
              </mat-form-field>                       
          </div>
            <div class="col-md-6">
              <mat-form-field>
                  <mat-label for="min">{{lang.get("maximumValue")}} </mat-label>
                  <input type="number" matInput class="form-control" maxlength="40" id="max" formControlName="max" mask="0000000000000" required="">
              </mat-form-field>                       
          </div>
          </div>
        <!-- </form> -->
        <div class="error" *ngIf="minMaxErr">{{ minMaxErr }}</div>
        <div class="row">
          <div class="multi-btns col-md-12 text-right">
            <button mat-raised-button class="btn-danger" (click)="dialogRefAssign.close()"><i class="flaticon-cancel"></i> {{lang.get("cancel")}}</button>
            <button mat-raised-button class="btn-primary" color="primary" [disabled]="!(currencyForm.get('currencyName').value && currencyForm.get('min').value && currencyForm.get('max').value) || minMaxErr||!this.correct"
            (click)="addCurrency()"><i class="flaticon-diskette"></i> {{lang.get("add")}}</button>
        </div>
        </div>
      </form>
      </div>
    </div>
</ng-template>



