<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
    <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="page-header-title">
            <span class="ng-star-inserted"></span
            ><span class="ng-star-inserted">
              <h4 class="m-b-10 ng-star-inserted">Training Report</h4>
            </span>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</div>

<mat-expansion-panel style="margin-bottom: 25px" [expanded]="this.isFilterPnlOpen">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <h5 class="mat-title-modal">Report Criteria</h5>
    </mat-panel-title>
    <button mat-raised-button class="hide btn-expand">Expand</button>
    <button mat-raised-button class="hide btn-collapse">Collapse</button>
  </mat-expansion-panel-header>

  <form [formGroup]="reportForm" action="javascript:" class="form-material full-width-inputs">
    <div class="row form-group">
      <div class="full-width-field col-md-4">
        <app-multi-select-token
          title="Employee"
          #employee
          (selectedItems)="employees = $event"
        ></app-multi-select-token>
      </div>
      <div class="full-width-field col-md-4">
        <mat-form-field class="form-element">
          <mat-label for="des">Participation Type</mat-label>
          <br />
          <mat-select
            style="height: min-content"
            class="form-control"
            formControlName="participatedType"
            disableOptionCentering
            required=""
          >
            <mat-option value="participant">Participant</mat-option>
            <mat-option value="trainer">Trainer</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row form-group">
      <div class="full-width-field col-md-4">
        <mat-form-field class="form-element">
          <mat-label for="des">Courses</mat-label>
          <br />
          <mat-select
            style="height: min-content"
            disableOptionCentering
            class="form-control"
            formControlName="courseId"
            multiple
            disableOptionCentering
          >
            <mat-select-trigger>
              <mat-chip-list>
                <mat-chip
                  *ngFor="let topping of reportForm.value.courseId"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="remove(topping.id, 'courseId')"
                >
                  {{ topping?.title }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
              </mat-chip-list>
            </mat-select-trigger>
            <ng-container>
              <mat-option *ngFor="let userRole of coursesList" [value]="userRole">{{ userRole.title }} </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="full-width-field col-md-4" style="margin-top: -10px">
        <mat-form-field class="form-element">
          <mat-label for="des">Course Version</mat-label>
          <br>
          <input class="form-control" formControlName="courseVersion" matInput type="number" />
        </mat-form-field>
      </div>

      <div class="full-width-field col-md-4">
        <app-multi-select-token
          title="Coordinator"
          #coordinator
          (selectedItems)="coordinators = $event"
        ></app-multi-select-token>
      </div>
      <div class="full-width-field col-md-4">
        <mat-form-field class="form-element">
          <mat-label for="des">currency</mat-label>
          <mat-select
            style="height: min-content"
            disableOptionCentering
            class="form-control"
            formControlName="currency"
            multiple
            disableOptionCentering
          >
            <mat-select-trigger>
              <mat-chip-list>
                <mat-chip
                  *ngFor="let topping of reportForm.value.currency"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="remove(topping, 'currency')"
                >
                  {{ topping }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
              </mat-chip-list>
            </mat-select-trigger>
            <ng-container>
              <mat-option *ngFor="let userRole of currencyList" [value]="userRole">{{ userRole }} </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="full-width-field col-md-4">
        <div class="row" style="margin-top: -30px">
          <div class="full-width-field col-md-4">
            <mat-form-field class="form-element">
              <mat-label>Min Cost</mat-label>
              <input class="form-control" formControlName="courseCostMin" maxlength="40" matInput type="number" />
            </mat-form-field>
          </div>
          <div class="full-width-field col-md-4">
            <mat-form-field class="form-element">
              <mat-label>Max Cost</mat-label>
              <input class="form-control" formControlName="courseCostMax" maxlength="40" matInput type="number" />
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="full-width-field col-md-4">
        <mat-form-field class="form-element">
          <mat-label for="des">Course Status</mat-label>
          <mat-select
            style="height: min-content"
            disableOptionCentering
            class="form-control"
            formControlName="courseStatus"
            multiple
            disableOptionCentering
          >
            <mat-select-trigger>
              <mat-chip-list>
                <mat-chip
                  *ngFor="let topping of reportForm.value.courseStatus"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="remove(topping.value, 'courseStatus')"
                >
                  {{ topping?.name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
              </mat-chip-list>
            </mat-select-trigger>
            <ng-container>
              <mat-option *ngFor="let userRole of courseStatusList" [value]="userRole">{{ userRole.name }} </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="full-width-field col-md-4">
        <mat-form-field class="form-element">
          <mat-label for="createDate">Course Created Date</mat-label>
          <mat-date-range-input [rangePicker]="picker10">
            <input matStartDate formControlName="courseCreatedDateFrom" />
            <input matEndDate formControlName="courseCreatedDateTo" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker10"></mat-datepicker-toggle>
          <mat-date-range-picker #picker10></mat-date-range-picker>
        </mat-form-field>
      </div>
      <div class="full-width-field col-md-4">
        <mat-form-field class="form-element">
          <mat-label for="startDate">Course Start Date</mat-label>
          <mat-date-range-input [rangePicker]="picker11">
            <input matStartDate formControlName="courseStartDateFrom" />
            <input matEndDate formControlName="courseStartDateTo" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker11"></mat-datepicker-toggle>
          <mat-date-range-picker #picker11></mat-date-range-picker>
        </mat-form-field>
      </div>
      <div class="full-width-field col-md-4">
        <mat-form-field class="form-element">
          <mat-label for="startDate">Course End Date</mat-label>
          <mat-date-range-input [rangePicker]="picker12">
            <input matStartDate formControlName="courseEndDateFrom" />
            <input matEndDate formControlName="courseEndDateTo" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker12"></mat-datepicker-toggle>
          <mat-date-range-picker #picker12></mat-date-range-picker>
        </mat-form-field>
      </div>
    </div>

    <div class="action-buttons multi-btns col-sm-12 text-right">
      <button
        mat-raised-button
        class="btn btn-primary"
        color="primary"
        [disabled]="!reportForm.valid"
        (click)="generatReport()"
      >
        <i class="flaticon-diskette"></i>Generate
      </button>
    </div>
  </form>
</mat-expansion-panel>

<app-card>
  <div class="dt-responsive" *ngIf="dataSource">
    <div class="page-body no-title">
      <div class="row top-tools">
        <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6 left-tools">
          <span class="export-icon-btn csv" (click)="exportToCsv()"
            ><i class="material-icons">get_app</i><span>CSV</span>
          </span>
          <span (click)="saveAsPdf()" class="export-icon-btn pdf"
            ><i class="material-icons">get_app</i><span>PDF</span>
          </span>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <mat-card-content>
        <app-loading *ngIf="isLoading" [isLoading]="isLoading"></app-loading>
        <table *ngIf="!isLoading" mat-table [dataSource]="dataSource" multiTemplateDataRows>
          <ng-container matColumnDef="participantName">
            <th mat-header-cell *matHeaderCellDef>Participant's Name</th>
            <td mat-cell *matCellDef="let element">{{ element.participantName }}</td>
          </ng-container>
          <ng-container matColumnDef="trainerName">
            <th mat-header-cell *matHeaderCellDef>Trainer's Name</th>
            <td mat-cell *matCellDef="let element">{{ element.participantName }}</td>
          </ng-container>
          <ng-container matColumnDef="participantId">
            <th mat-header-cell *matHeaderCellDef class="right-border">Participant's Id</th>
            <td mat-cell *matCellDef="let element" class="right-border">{{ element.participantId }}</td>
          </ng-container>
          <ng-container matColumnDef="trainerId">
            <th mat-header-cell *matHeaderCellDef class="right-border">Trainer's Id</th>
            <td mat-cell *matCellDef="let element" class="right-border">{{ element.participantId }}</td>
          </ng-container>

          <ng-container matColumnDef="courseTitle">
            <th mat-header-cell *matHeaderCellDef>Course Title</th>
            <td mat-cell *matCellDef="let element">{{ element?.courseTitle }}</td>
          </ng-container>
          <ng-container matColumnDef="version">
            <th mat-header-cell *matHeaderCellDef>Version</th>
            <td mat-cell *matCellDef="let element">{{ element?.version }}</td>
          </ng-container>

          <ng-container matColumnDef="courseCoordinator">
            <th mat-header-cell *matHeaderCellDef>Coordinator</th>
            <td mat-cell *matCellDef="let element">{{ element?.courseCoordinator }}</td>
          </ng-container>
          <ng-container matColumnDef="courseDate">
            <th mat-header-cell *matHeaderCellDef>Course Date</th>
            <td mat-cell *matCellDef="let element">{{ element?.courseStartDate }} - {{ element?.courseEndDate }}</td>
          </ng-container>
          <ng-container matColumnDef="subunit">
            <th mat-header-cell *matHeaderCellDef>Subunit</th>
            <td mat-cell *matCellDef="let element">{{ element?.subunit }}</td>
          </ng-container>

          <ng-container matColumnDef="company">
            <th mat-header-cell *matHeaderCellDef>Company</th>
            <td mat-cell *matCellDef="let element">{{ element?.company }}</td>
          </ng-container>
          <ng-container matColumnDef="Cost">
            <th mat-header-cell *matHeaderCellDef>Cost</th>
            <td mat-cell *matCellDef="let element">{{ element?.cost }}</td>
          </ng-container>

          <ng-container matColumnDef="currency">
            <th mat-header-cell *matHeaderCellDef>Currency</th>
            <td mat-cell *matCellDef="let element">{{ element?.currency }}</td>
          </ng-container>

          <ng-container matColumnDef="courseStatus">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element">{{ element?.courseStatus }}</td>
          </ng-container>
          <ng-container matColumnDef="courseCreatedUser">
            <th mat-header-cell *matHeaderCellDef>Created User</th>
            <td mat-cell *matCellDef="let element">{{ element?.courseCreatedUser }}</td>
          </ng-container>

          <ng-container matColumnDef="courseCreatedDate">
            <th mat-header-cell *matHeaderCellDef class="right-border">Created Date</th>
            <td mat-cell *matCellDef="let element" class="right-border">{{ element?.courseCreatedDate }}</td>
          </ng-container>

          <tr mat-row *matHeaderRowDef="displayColumns"></tr>
          <tr mat-row *matRowDef="let element; columns: displayColumns"></tr>
        </table>
      </mat-card-content>
    </div>
  </div>
</app-card>
