import { Component, OnInit, ViewChild, Injectable, ElementRef, AfterContentInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { NotificationService } from "src/app/core/services/notification.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
@Injectable()
export class LoginComponent implements OnInit {
  public loginDetail = {
    username: "",
    password: "",
    role: "",
    client: "demo",
  };
  public show: boolean;
  public isLoading: boolean;
  public loginError: string;
  public roleListError: string;
  public userRoleList: Array<any>;
  public forgetFlag: boolean;
  public empId: string;
  public passwordHide: boolean = true;
  public alertMessage: string;

  @ViewChild("roleDiv", { static: false }) role;
  @ViewChild("loginBtn") loginBtn: ElementRef;

  constructor(
    private router: Router,
    private readonly authService: AuthService,
    private toastr: NotificationService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    document.querySelector("body").setAttribute("themebg-pattern", "theme1");
    this.show = false;
    this.getUserRole();

    var domain = document.location.host;
    console.log("domain : " + domain);
    if (domain) {
      this.loginDetail.client = domain.split(".")[0];
      console.log("Client : " + this.loginDetail.client);
    }

    this.route.queryParams.subscribe((params) => {
      this.alertMessage = params["alertMessage"];
    });
  }

  public changeFlag(val) {
    this.loginError = "";
    this.forgetFlag = val;
  }

  public login(): void {
    this.isLoading = true;
    this.loginError = "";
    // this.loginDetail.client = "phantom";
    this.authService.login(this.loginDetail).subscribe(
      (resp) => {
        this.loginError = "";
        // this.router.navigate(['admin/user-management/users']);
        this.router.navigate(["more/dashboard"]);
        this.authService.postLoginRefresh(resp);
        // sessionStorage.setItem("empId", resp.extras.empId);
        //this.authService.setPersmissions(resp.extras.role.dataGroup);
        //const token = this.authService.getDecodedToken();
        this.isLoading = false;
        this.toastr.success("Login successful");
        //sessionStorage.setItem('username', this.loginDetail.username);
      },
      (error) => {
        this.isLoading = false;
        const err = error.error && error.error.msg;
        this.loginError = "Incorrect email or password.";
        this.toastr.error(err || "Incorrect email or password.");
      }
    );
    // } else {
    //   this.toastr.error('Please select a role type');
    // }
  }

  public getUserRole(): void {
    this.authService.getUserRoleList().subscribe(
      (resp) => {
        this.roleListError = "";
        if (resp && resp.data) {
          this.userRoleList = resp.data;
        }
      },
      (error) => {
        this.roleListError = "Error getting roles";
        console.log("Error getting user list");
      }
    );
  }

  public setUsrRole(role: string): void {
    this.loginDetail.role = role;
    this.role.nativeElement.innerText = role;
    this.show = false;
  }

  public toggleDropdown(): void {
    this.show = !this.show;
  }

  public getEmailLink(): void {
    this.loginError = "";
    this.isLoading = true;
    this.authService.forgotPassword(this.loginDetail).subscribe(
      (data) => {
        this.isLoading = false;
        this.toastr.success(`A password reset link has been sent to your email : ${this.loginDetail.username}.`);
      },
      (error) => {
        this.isLoading = false;
        const msg = error && error.error && error.error.msg;
        this.toastr.error(msg || "Email does not exists.");
        this.loginError = "Email does not exists.";
      }
    );
  }
}
