import { Component, Input, NgZone, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatCheckbox, MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import * as moment from "moment";
import { NotificationService } from "src/app/core/services/notification.service";
import { AuthService } from "src/app/core/services/auth.service";
import { DashboardService } from "src/app/dashboard/dashboard.service";

@Component({
  selector: "app-job",
  templateUrl: "./job-info.component.html",
  styleUrls: ["./job-info.component.scss"],
})
export class JobInfo implements OnInit {
  @Input() employeeId: string;
  public jobForm: FormGroup;
  public terminateForm: FormGroup;
  public jobCategoryList: Array<any>;
  public locationList: Array<any>;
  public costCenterList: Array<any>;
  public workShiftList: Array<any>;
  public employeeStatusList: Array<any>;
  public jobTitleList: Array<any>;
  public countryList: Array<any>;
  public regionList: Array<any>;
  public temporaryDepartment: Array<any>;
  public comment: string;
  public fileObj: any;
  public jobAttachmentList: MatTableDataSource<any> = new MatTableDataSource([]);
  public jobHistoryList: MatTableDataSource<any> = new MatTableDataSource([]);
  public adjustLeaveEntitlmentsList: MatTableDataSource<any> = new MatTableDataSource([]);
  @ViewChild("jobPagination", { static: false }) jobPaginator: MatPaginator;
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  public fileType = "";
  public editAttachmentId: string;
  public deleteAttachmentId: string;
  public fileUrl: string;
  public fileName = "";
  public dialogRef: any;
  public isLoading = true;
  public isLoadingAttachment = true;
  public isLoadingJobHistory = true;
  public isLoadingRequired: boolean = true;
  public ent = [];
  @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
  @ViewChild("terminate", { static: false }) terminate;
  @ViewChild("attach", { static: false }) attach;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("adjusLeaveEntitlment", { static: false }) adjusLeaveEntitlment;
  @ViewChild("editAttach", { static: false }) editAttach;
  @ViewChild("deleteAttachment", { static: false }) deleteAttachment;
  @ViewChild("deleteJobHistory", { static: false }) deleteJobHistory;
  public columnss = [
    "select",
    "event",
    "effectiveFrom",
    "effectiveTo",
    "jobTitle",
    "employementStatus",
    "jobCategory",
    "subUnit",
    "location",
  ];
  public columns = ["fileName", "url", "desc", "size", "added", "edit", "delete"];
  public columnAjust = ["leaveType", "entitlment", "adjustTo"];
  uploadedFile: any;
  terminationList: any;
  dateOfTermination: Date;
  public requiredJob: any = {
    commentAvailable: true,
    commentRequired: false,
    contractEndDateAvailable: true,
    contractEndDateRequired: false,
    contractStartDateAvailable: true,
    contractStartDateRequired: false,
    costCenterAvailable: true,
    costCenterRequired: false,
    employmentStatusAvailable: true,
    employmentStatusRequired: false,
    jobCategoryAvailable: true,
    jobCategoryRequired: false,
    jobTitleAvailable: true,
    jobTitleRequired: false,
    probationEndDateAvailable: true,
    probationEndDateRequired: false,
    selected: true,
    workShiftAvailable: true,
    workShiftRequired: false,
  };

  futureTermination = false;
  terminated = false;
  adjustEntitlment = false;
  terminationDa: Date = new Date();
  public selectedCheckBoxList: Array<any> = [];
  public sectionsList: Array<any> = [];
  public saveCBCutomList: Array<any> = [];
  public saveCutomList: Array<any> = [];

  public submitLoading : boolean;

  constructor(
    private service: DashboardService,
    private toastr: NotificationService,
    private fb: FormBuilder,
    private authService: AuthService,
    private dialog: MatDialog
  ) {
    this.isLoading = true;
    this.createForm();
    this.createTerminateForm();
  }

  ngOnDestroy() {
    this.dialog = null;
    this.dialogRef = null;
    this.authService = null;
    this.service = null;
    this.fb = null;
    this.toastr = null;
    this.attach = null;
    this.sort = null;
    this.paginator = null;
    this.editAttach = null;
    this.deleteAttachment = null;
    this.jobForm = null;
    this.terminateForm = null;
    this.matCheckBoxes = null;
    this.terminate = null;
    this.attach = null;
    this.paginator = null;
    this.adjusLeaveEntitlment = null;
    this.editAttach = null;
    this.deleteAttachment = null;
    this.deleteJobHistory = null;
    this.jobAttachmentList = null;
    this.jobHistoryList = null;
    this.adjustLeaveEntitlmentsList = null;
  }
  public permissions: any;
  public read = false;
  public write = false;
  public update = false;
  public delete = false;

  checkPermission(module, tabs): void {
    this.permissions = JSON.parse(this.authService.getPermissions());

    this.read = false;
    this.write = false;
    this.delete = false;
    this.update = false;

    if (this.permissions != undefined && this.permissions != null) {
      for (let p of this.permissions) {
        if (p.id === module) {
          if (p.modules != undefined) {
            for (let tab of p.modules) {
              if (tab.id === tabs) {
                for (let move of tab.permits) {
                  if (move.id === "u") {
                    this.update = true;
                  } else if (move.id === "r") {
                    this.read = true;
                  } else if (move.id === "d") {
                    this.delete = true;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ngOnInit(): void {
    this.isLoading = true;
    this.isLoadingRequired = true;
    this.checkPermission("pdm", "pd_em");
    this.service.getRequiredFieldsBySection("jb").subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.requiredJob = resp.data;
          this.isLoadingRequired = false;
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.toastr.error(err || "No Internt Connection !");
      }
    );
    this.service.getCustomFieldsForEmployee("jb", this.employeeId).subscribe((res) => {
      if (res && res.data) {
        this.sectionsList = res.data;
      }
    });
    this.service.getEmployeeDropdown().subscribe((resp) => {
      this.jobCategoryList = resp[0].data;
      this.locationList = resp[1].data;
      this.costCenterList = resp[2].data;
      this.workShiftList = resp[3].data;
      this.jobTitleList = resp[4].data;
      this.employeeStatusList = resp[5].data;
      this.countryList = resp[6].data;
      this.regionList = resp[7].data;
    });
    this.service.getEmployeeJobInfo(this.employeeId).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.isLoading = true;
          const val = resp.data;
          val.effectivefrom = moment(val.effectivefrom).format("YYYY-MM-DD");
          val.dateofpermanency = moment(val.dateofpermanency).format("YYYY-MM-DD");
          val.contractenddate = moment(val.contractenddate).format("YYYY-MM-DD");
          val.contractstartdate = moment(val.contractstartdate).format("YYYY-MM-DD");
          val.endDate = moment(val.endDate).format("YYYY-MM-DD");
          this.jobForm.patchValue(val);
          this.isLoading = false;
          this.jobHistoryList = new MatTableDataSource<any>(resp.data.jobHistory);
          this.jobHistoryList.paginator = this.jobPaginator;
          this.jobHistoryList.sort = this.sort.toArray()[1];
        }
      },
      (error) => {
        this.toastr.error("Error in Getting Job Informations");
      }
    );

    this.service.getStructureList().subscribe((resp) => {
      if (resp && resp.data) {
        this.temporaryDepartment = resp.data;
      }
    });
    this.getJobAttachmentList();
    this.service.getTerminationResons().subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.terminationList = resp.data;
        }
      },
      (error) => {
        this.toastr.error("Error in Getting Termination Resons Dropdown");
      }
    );

    this.getTermination();
  }

  checkRequiredFieldsInCustomFields(sectionID, customFields) {
    for (let section of this.sectionsList) {
      if (section.id === sectionID) {
        for (let custom of customFields) {
          if (custom.required) {
            if (custom.value === "" || custom.value === null || custom.value === undefined) {
              section.save = true;
              return;
            }
          }
        }

        section.save = false;
      }
    }
  }

  updateCustomField(sectionId, customFields): void {
    this.saveCutomList = [];
    this.saveCBCutomList = [];
    for (let custom of customFields) {
      if (custom.required) {
        if (custom.value === "") {
          this.toastr.error("Please fill the required fields");
          return;
        }
      }
      var obj = {
        fieldId: "",
        fieldValue: "",
      };
      if (custom.type != "cb") {
        let id: string = custom.id;
        let value: string = custom.value;
        if (custom.type == "dt") {
          value = moment(custom.value).format("YYYY-MM-DD").toString();
        }

        obj.fieldId = id;
        obj.fieldValue = value;
        this.saveCutomList.push(obj);
      }

      if (custom.type == "cb") {
        var obj2 = {
          fieldId: "",
          values: [],
        };
        obj2.fieldId = custom.id;
        let values: Array<any> = [];
        obj2.values = values;
        for (let c of custom.values) {
          if (c.booleanValue) {
            obj2.values.push(c.option);
          }
        }
        this.saveCBCutomList.push(obj2);
      }
    }

    this.service.saveCustomFieldsInEditEmployee(sectionId, this.employeeId, this.saveCutomList).subscribe(
      (res) => {
        if (res) {
          this.service
            .saveCustomFieldsCheckBoxesInEditEmployee(sectionId, this.employeeId, this.saveCBCutomList)
            .subscribe(
              (res) => {
                if (res) {
                  this.toastr.success("Updated Successfully!..");
                }
              },
              (error) => {
                this.toastr.error("Error in  updating");
              }
            );
        }
      },
      (error) => {}
    );
  }

  getAcvtivate() {
    this.service.getActivateEmployee(this.employeeId).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.getTermination();
          this.getJobHistory();
          this.terminated = false;
          this.futureTermination = false;
          this.toastr.success("Employee Activated Successfully!..");
        }
      },
      (error) => {
        this.toastr.error("Error in Employee Activation");
      }
    );
  }

  getTermination() {
    this.service.getTerminationInfo(this.employeeId).subscribe((resp) => {
      if (resp && resp.data) {
        this.dateOfTermination = resp.data.terminationDate;
        this.futureTermination = resp.data.willBeTerminated;
        this.terminated = resp.data.terminated;
        this.adjustEntitlment = resp.data.adjustLeaveEntitlement;
      }

      // }, error => {
      //   this.toastr.error('Error in Getting Termination Resons Dropdown');
    });
  }

  getTerminationInfo() {
    this.service.getTerminationInfo(this.employeeId).subscribe(
      (resp) => {
        this.terminateForm.reset();
        if (resp && resp.data) {
          const val = resp.data;
          val.terminationDate = moment(val.terminationDate).format("YYYY-MM-DD");
          this.terminateForm.patchValue(val);

          this.dialogRef = this.dialog.open(this.terminate, {
            width: "560px",
            height: "auto",
          });
        }
      },
      (error) => {
        this.toastr.error("Error in Getting Termination Information");
      }
    );
  }

  getJobHistory() {
    this.service.getEmployeeJobInfo(this.employeeId).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.jobHistoryList = new MatTableDataSource<any>(resp.data.jobHistory);
          this.jobHistoryList.paginator = this.jobPaginator;
          this.jobHistoryList.sort = this.sort.toArray()[1];
        }
      },
      (error) => {
        this.toastr.error("Error in Getting Job History");
      }
    );
  }

  createForm() {
    this.jobForm = this.fb.group({
      jobcategoryid: [""],
      jobtitelid: [""],
      contractenddate: [""],
      contractstartdate: [""],
      costcenterid: [""],
      dateofpermanency: [""],
      effectivefrom: [""],
      employeestatus: [""],
      workshiftid: [""],
      joinedgdate: [""],
      endDate: [""],
      ppId: [""],
      locationid: [""],
      // paygradeid: [''],
      comment: [""],
      region: [""],
      fte: [""],
      temporarydepartment: [""],
    });
  }
  public createTerminateForm(): void {
    this.terminateForm = this.fb.group({
      terminationDate: [""],
      reason: [""],
      note: [""],
      adjustLeaveEntitlement: [],
    });
  }

  OnTerminateEmployment() {
    this.dialogRef = this.dialog.open(this.terminate, {
      width: "560px",
      height: "auto",
    });
    this.terminateForm.reset();
  }
  onCheckBoxChange($event: MatCheckboxChange, id: any) {
    if (!this.alreadySelected($event.source) && $event.checked) {
      this.selectedCheckBoxList.push($event.source.value);
    } else if (!$event.checked) {
      this.selectedCheckBoxList = this.selectedCheckBoxList.filter((value) => {
        return value !== $event.source.value;
      });
    }
  }

  alreadySelected(item: MatCheckbox): boolean {
    this.selectedCheckBoxList.forEach((value) => {
      if (item === value) {
        return true;
      }
    });
    return false;
  }

  updateJobDetail() {
    this.submitLoading = true;
    this.service.updateEmployeeJobDetail(this.employeeId, this.jobForm.value).subscribe(
      (res) => {
        if (res) {
          this.toastr.success("Updated Successfully!..");
          this.getJobHistory();
          this.submitLoading = false;
        }
      },
      (error) => {
        this.toastr.error("Error in  updating");
        this.submitLoading = false;
      }
    );
  }

  public addAttachment(): void {
    this.editAttachmentId = "";
    this.comment = "";
    this.fileType = "";
    this.fileObj = {};
    this.fileUrl = "";
    this.fileName = "";
    this.dialogRef = this.dialog.open(this.attach, {
      width: "440px",
      height: "auto",
    });
  }
  getJobAttachmentList() {
    this.service.getNewAttachmentList(this.employeeId, "jo").subscribe((resp) => {
      if (resp && resp.data) {
        this.isLoadingAttachment = true;
        this.jobAttachmentList = new MatTableDataSource<any>(resp.data);
        this.jobAttachmentList.data = resp.data;
        this.jobAttachmentList.paginator = this.paginator;
        this.jobAttachmentList.sort = this.sort.toArray()[0];
        this.isLoadingAttachment = false;
      }
    });
  }

  public editAttachment(row): void {
    this.editAttachmentId = row.id;
    this.fileName = row.fileName;
    this.comment = row.desc;
    this.dialogRef = this.dialog.open(this.editAttach, {
      width: "440px",
      height: "auto",
    });
  }
  public open(url): void {
    window.open(url);
  }
  deleteAttachmentSelected(id) {
    this.deleteAttachmentId = id;
    this.dialogRef = this.dialog.open(this.deleteAttachment, {
      width: "440px",
      height: "auto",
    });
  }
  public hideTerminate(): void {
    this.dialogRef.close();
  }
  public terminateEmployee(): void {
    this.service.terminateEmployment(this.terminateForm.value, this.employeeId).subscribe(
      (res) => {
        this.toastr.success("Employment Terminated successfully!");
        this.dialogRef.close();
        this.getJobHistory();
        // this.ngZone.run(() => {
        //   void this.router.navigate(['/pim/employees']);
        // });
        this.getTermination();
      },
      (error) => {
        const msg = error && error.error.msg;
        this.toastr.error(msg || "Error in Employment Termination");
      }
    );
  }
  public updateAttachment() {
    this.service.updateNewAttachmentList(this.comment, this.fileName, this.editAttachmentId).subscribe(
      (res) => {
        if (res) {
          this.getJobAttachmentList();
          this.toastr.success("Attachment updated Successfully.");
          this.dialogRef.close();
        }
      },
      (error) => {
        this.toastr.error("Error in updating attachment");
      }
    );
  }
  public deleteAttachmentFrom(): void {
    this.service.deleteAttachment(this.deleteAttachmentId).subscribe(
      (resp) => {
        if (resp) {
          this.getJobAttachmentList();
          this.dialogRef.close();
          this.toastr.success("Attachment Deleted Successfully...");
        }
      },
      (error) => {
        this.toastr.error("Error in deleting the Attachment");
      }
    );
  }
  public fileChange($event, type): void {
    this.uploadedFile = undefined;
    const reader = new FileReader();
    const file = $event.target.files[0];
    this.uploadedFile = file;
    const fileName = file && file.name;
    if (file && file.size / 1024 < 2048) {
      this.fileObj = {
        added: new Date(),
        addedBy: "string",
        desc: this.comment,
        docType: type,
        empId: this.employeeId,
        fileName: fileName,
        size: file.size,
        section: "",
      };
    } else {
      this.toastr.error("Max 2Mb File allowed.");
    }
  }

  public isSaveAttachment = false;
  public saveAttachment(): void {
    this.isSaveAttachment = true;
    // this is for the save attachment
    this.service.uploadFile(this.uploadedFile).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.fileObj["url"] = resp.data.filename;
          this.fileObj["desc"] = this.comment;
          this.fileObj.section = "jo";
          this.fileObj.fileName = this.fileName;
          if (this.editAttachmentId) {
          } else {
            this.service.newAddAttachment(this.fileObj).subscribe((resp1) => {
              // this.attachmentList.filteredData.push(resp1.data);
              this.toastr.success("file added successfully!");
              this.dialogRef.close();
              this.isSaveAttachment = false;
              this.getJobAttachmentList();
            });
          }
        }
      },
      (error) => {
        this.isSaveAttachment = false;
        const err = error.error && error.error.msg;
        this.toastr.error("error in adding file!");
      }
    );
  }
  onSelectAllClicked() {
    this.selectedCheckBoxList = [];
    this.matCheckBoxes.forEach((item) => {
      this.selectedCheckBoxList.push(item.value);
      item.checked = true;
    });
  }
  onDeSelectAllClicked() {
    this.selectedCheckBoxList = [];
    this.matCheckBoxes.forEach((item) => {
      item.checked = false;
    });
  }
  onDeleteSelected() {
    this.dialogRef = this.dialog.open(this.deleteJobHistory, {
      width: "440px",
      height: "auto",
    });
  }
  public close(): void {
    this.dialogRef.close();
    // this.modalRef.close();
  }
  public deleteJobH(): void {
    this.service.deleteMultipleJobHsitory(this.employeeId, this.selectedCheckBoxList).subscribe(
      (user) => {
        if (user) {
          this.close();
          this.getJobHistory();
          this.toastr.success("Job History deleted successfully !");
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.close();
        this.toastr.error(err || "Error deleting Job Title");
      }
    );
    this.onDeSelectAllClicked();
  }

  public checkAdjustLeaveEntitlment(): void {
    this.service.getAdjustToInfo(this.employeeId).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.ent = resp.data;

          this.ent.map((res) => {
            if (res.adjustTo === 0) {
              res.adjustTo = "";
            }
          });

          this.adjustLeaveEntitlmentsList = new MatTableDataSource<any>(this.ent);

          this.dialogRef = this.dialog.open(this.adjusLeaveEntitlment, {
            width: "560px",
            height: "auto",
          });
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error in getting the Informations");
        this.dialogRef.close();
      }
    );
  }

  adjustToChanged(event, leave) {
    for (const e of this.ent) {
      if (leave.entitlementNumber === e.entitlementNumber) {
        e.adjustTo = event;
      }
    }
  }

  saveAdjust() {
    this.dialogRef.close();
    this.ent.map((res) => {
      if (res.adjustTo === "") {
        res.adjustTo = -1;
      }
    });
    this.service.setAdjustTo(this.employeeId, this.ent).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.close();
          this.toastr.success("Changes Saved Successfully.");
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error in Saving Changes");
        this.dialogRef.close();
      }
    );
  }
}
