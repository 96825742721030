<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
    <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="page-header-title">
            <span class="ng-star-inserted"></span
            ><span class="ng-star-inserted">
              <h4 class="m-b-10 ng-star-inserted">My Leave List</h4>
            </span>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</div>

<mat-expansion-panel #expansionPanel [expanded]="isExpanded">
  <mat-expansion-panel-header class="right-aligned-header">
    <mat-panel-title>
      <span class="accordion-head panel-title"> Search Leaves </span>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <form #f="ngForm" class="form-material full-width-inputs" action="javascript:">
    <div class="row form-group">
      <div class="full-width-field col-md-6">
        <mat-form-field class="form-element">
          <input
            [ngModelOptions]="{ standalone: true }"
            matInput
            name="startDate"
            [matDatepicker]="picker1"
            [(ngModel)]="startDate"
            required=""
            class="form-control"
            #start="ngModel"
            placeholder=""
          />
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
          <mat-label for="startDate">From</mat-label>
        </mat-form-field>
      </div>
      <div class="full-width-field col-md-6">
        <mat-form-field class="form-element">
          <input
            [ngModelOptions]="{ standalone: true }"
            matInput
            name="endDate"
            [matDatepicker]="picker2"
            [(ngModel)]="endDate"
            [attr.min]="startDate"
            [min]="startDate"
            required=""
            class="form-control"
            #end="ngModel"
            [value]="endDate"
          />
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
          <mat-label for="endDate">To</mat-label>
        </mat-form-field>
      </div>
    </div>

    <div class="row form-group">
      <div class="full-width-field col-md-12">
        <mat-label>
          <h3>show leave with status</h3>
        </mat-label>
      </div>
    </div>
    <div class="row form-group">
      <div *ngFor="let status of statusList">
        <div class="col-md-2">
          <mat-checkbox
            class="form-control"
            color="primary"
            #checkboxs
            [checked]="status.checked"
            (change)="OnStatusChanged($event, status.value)"
            >{{ status.label }}</mat-checkbox
          >
        </div>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-12">
        <div class="action-buttons multi-btns col-sm-12 text-right">
          <!-- <button mat-raised-button class="btn btn-danger" color="danger" (click)="onReset()">
            <i class="flaticon-refresh"></i>Reset
          </button> -->
          <button (click)="search()" class="btn btn-primary" color="primary" mat-raised-button>
            <i class="flaticon-magnifiying-glass"></i>Search
          </button>
        </div>
      </div>
    </div>
  </form>
</mat-expansion-panel>

<br /><br />
<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<div class="card do-not-print">
  <div class="card-content table-responsive table-full-width">
    <ng-container>
      <div class="row top-tools">
        <div class="col-xs-12 col-sm-12 col-md-6 left-tools">
          <span class="export-icon-btn csv" (click)="exportToCsv()"
            ><i class="material-icons">get_app</i><span>CSV</span>
          </span>
          <span class="export-icon-btn pdf" (click)="saveAsPdf()"
            ><i class="material-icons">get_app</i><span>PDF</span>
          </span>
        </div>

        <div [hidden]="!update" class="col-xs-12 col-sm-12 col-md-6 right-tools">
          <div class="relative-position search-filter-con">
            <button (click)="saveBulkActions()" class="btn-primary" color="primary" mat-raised-button>
              <i class="flaticon-diskette"></i>Save
            </button>
          </div>
        </div>
      </div>
      <div class="card-content table-responsive table-full-width" style="white-space: pre-line">
        <mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="employeeName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Employee </mat-header-cell>
            <mat-cell (click)="openLeaveDays(row.employeeId, row.leaveId)" *matCellDef="let row">
              {{ row.employeeName }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
            <mat-cell (click)="openLeaveDays(row.employeeId, row.leaveId)" *matCellDef="let row">
              {{ row.date }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="appliedOn">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Applied On </mat-header-cell>
            <mat-cell (click)="openLeaveDays(row.employeeId, row.leaveId)" *matCellDef="let row">
              {{ row.appliedOn | date : "dd-MM-yyyy" }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="leaveType">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Leave Type</mat-header-cell>
            <mat-cell (click)="openLeaveDays(row.employeeId, row.leaveId)" *matCellDef="let row">
              {{ row.leaveType }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="netLeaveBalance">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Net Leave Balance (Days) </mat-header-cell>
            <mat-cell (click)="openLeaveDays(row.employeeId, row.leaveId)" *matCellDef="let row">
              {{ row.netLeaveBalance }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="numberOfDays">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Number of Days</mat-header-cell>
            <mat-cell (click)="openLeaveDays(row.employeeId, row.leaveId)" *matCellDef="let row">
              {{ row.numberOfDays }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
            <mat-cell (click)="openLeaveDays(row.employeeId, row.leaveId)" *matCellDef="let row">
              {{ row.status }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="actionTaken">
            <mat-header-cell [hidden]="!update" *matHeaderCellDef>Actions</mat-header-cell>
            <mat-cell [hidden]="!update" *matCellDef="let row">
              <span
                (click)="openLeaveDays(row.employeeId, row.leaveId)"
                class="mytooltip tooltip-effect-4"
                *ngIf="row.actionTaken"
              >
                <span class="tooltip-item">
                  <mat-icon aria-hidden="false"> more_horiz</mat-icon>
                </span>
                <span class="tooltip-content clearfix">
                  <span class="tooltip-text">Go To Detailed view </span>
                </span>
              </span>

              <mat-form-field class="form-element" *ngIf="!row.actionTaken">
                <mat-select
                  (ngModelChange)="this.addActionsToList(row.leaveId, row.employeeId, $event)"
                  ngModel="{{ selected }}"
                  disableOptionCentering
                >
                  <mat-option value="">Select action</mat-option>

                  <mat-option value="Cancelled">Cancel</mat-option>
                </mat-select>
              </mat-form-field>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="icons">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span
                class="mytooltip tooltip-effect-4"
                *ngIf="row.nomineesEnabled"
                (click)="openNominnesDialog(row.leaveId, row.employeeId, row.employeeLeaveNominateds)"
              >
                <span class="tooltip-item">
                  <mat-icon aria-hidden="false" [class.icon_has_value]="row.employeeLeaveNominateds.length > 0">
                    people
                  </mat-icon>
                </span>
                <span class="tooltip-content clearfix">
                  <span class="tooltip-text">Nominees ({{ row.employeeLeaveNominateds.length }}) </span>
                </span>
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="icons2">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span class="mytooltip tooltip-effect-4">
                <span
                  class="tooltip-item"
                  (click)="addComment(row.leaveId, row.employeeId, row.employeeName, row.imgPath)"
                >
                  <mat-icon aria-hidden="false" [class.icon_has_value]="row.leavecomments.length > 0">
                    add_comment
                  </mat-icon>
                </span>
                <span class="tooltip-content clearfix">
                  <span class="tooltip-text">Comments ({{ row.leavecomments.length }}) </span>
                </span>
              </span>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedLeaveListColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedLeaveListColumns" class="cursor-pointer"> </mat-row>
        </mat-table>
        <mat-paginator
          [pageSize]="pageSize"
          [pageIndex]="pageIndex"
          [pageSizeOptions]="[10, 20, 50]"
          showFirstLastButtons
          [length]="totalElements"
          (page)="handlePageEvent($event)"
          [disabled]="isLoading"
        ></mat-paginator>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #addComments let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">Leave Request Comments</h4>
    </div>
    <div class="mat-modal-body">
      <app-loading *ngIf="isLodaingCo" [isLoading]="isLodaingCo"></app-loading>

      <form
        class="form-material full-width-inputs"
        action="javascript:"
        [formGroup]="this.commentForm"
        *ngIf="!isLodaingCo"
      >
        <div class="row form-group">
          <div class="col-sm-6">
            <p class="m-b-10 ng-star-inserted cus-flex-tools">
              <span>
                <img *ngIf="this.employeeImage" class="img-p" src="{{ this.employeeImage }}" />
                <img *ngIf="!this.employeeImage" class="img-p" />
                {{ this.employeeName }}
              </span>
            </p>
          </div>
        </div>
        <div class="row form-group" [hidden]="!update">
          <div class="col-sm-12">
            <mat-form-field class="form-element">
              <mat-label>Comment</mat-label>
              <textarea formControlName="commnet" maxlength="500" matInput required></textarea>
            </mat-form-field>
          </div>
        </div>
      </form>

      <div class="row my-2 mx-2">
        <div class="action-buttons multi-btns col-sm-12 text-right">
          <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i>Cancel</button>
          <button
            [hidden]="!update"
            [disabled]="!this.commentForm.valid"
            (click)="submitComment()"
            class="btn-primary"
            color="primary"
            mat-raised-button
          >
            <i class="flaticon-diskette"></i>Save
          </button>
        </div>
      </div>

      <div *ngFor="let comment of this.commentList">
        <div class="row form-group" *ngIf="comment.commnet != undefined">
          <div class="col-sm-6">
            <p class="m-b-10 ng-star-inserted cus-flex-tools">
              <span>
                <mat-icon aria-hidden="false" aria-label="Example home icon">comment</mat-icon> &nbsp;
                {{ comment.commentby }}: {{ comment.commentdate | date : "E, d MMM yyyy HH:mm" }}
              </span>
            </p>
            <p class="m-b-10 ng-star-inserted cus-flex-tools">
              <span>
                {{ comment.commnet }}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #addNominees let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">Nominees</h4>
    </div>
    <div class="mat-modal-body">
      <app-loading *ngIf="isLoadingNom" [isLoading]="isLoadingNom"></app-loading>

      <form [hidden]="!update" class="form-material full-width-inputs" action="javascript:" *ngIf="!isLoadingNom">
        <div class="row form-group" *ngIf="!viewNominees">
          <div class="col-md-6">
            <!-- <mat-form-field>
              <mat-label> Employee</mat-label>
              <mat-select
                disableOptionCentering
                name="emp1"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="nominatedempid1"
                class="form-control"
              >
                <mat-option>
                  <ngx-mat-select-search [formControl]="userFilterCtrl4" placeholderLabel="Search">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let t of filteredNoimnees" [value]="t.value">
                  {{ t.label }}
                </mat-option>
              </mat-select>
            </mat-form-field> -->
            <app-lazy-employee-dropdown
              title="Employee"
              required="false"
              [selectedEmployee]="nominatedempid1"
              (employeeSelected)="updateNominatedempid1($event)"
            ></app-lazy-employee-dropdown>
          </div>

          <div class="col-md-6">
            <mat-form-field>
              <mat-label>Note</mat-label>
              <input
                [readonly]="nominatedempid1 === ''"
                [ngModelOptions]="{ standalone: true }"
                matInput
                name="note"
                [(ngModel)]="comment1"
                [readonly]="this.nominatedempid1 === ''"
                class="form-control"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="row form-group" *ngIf="!viewNominees">
          <div class="col-md-6">
            <!-- <mat-form-field>
              <mat-label> Employee</mat-label>
              <mat-select
                disableOptionCentering
                name="emp2"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="nominatedempid2"
                class="form-control"
              >
                <mat-option>
                  <ngx-mat-select-search [formControl]="userFilterCtrl2" placeholderLabel="Search">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let t of filteredNoimnees2" [value]="t.value">
                  {{ t.label }}
                </mat-option>
              </mat-select>
            </mat-form-field> -->
            <app-lazy-employee-dropdown
              title="Employee"
              required="false"
              [selectedEmployee]="nominatedempid2"
              (employeeSelected)="updateNominatedempid2($event)"
            ></app-lazy-employee-dropdown>
          </div>

          <div class="col-md-6">
            <mat-form-field>
              <mat-label>Note</mat-label>
              <input
                [readonly]="nominatedempid2 === ''"
                [ngModelOptions]="{ standalone: true }"
                matInput
                name="note2"
                [(ngModel)]="comment2"
                [readonly]="this.nominatedempid2 === ''"
                class="form-control"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="row form-group" *ngIf="!viewNominees">
          <div class="col-md-6">
            <!-- <mat-form-field>
              <mat-label> Employee</mat-label>
              <mat-select
                disableOptionCentering
                name="emp3"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="nominatedempid3"
                class="form-control"
              >
                <mat-option>
                  <ngx-mat-select-search [formControl]="userFilterCtrl3" placeholderLabel="Search">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let t of filteredNoimnees3" [value]="t.value">
                  {{ t.label }}
                </mat-option>
              </mat-select>
            </mat-form-field> -->
            <app-lazy-employee-dropdown
              title="Employee"
              required="false"
              [selectedEmployee]="nominatedempid3"
              (employeeSelected)="updateNominatedempid1($event)"
            ></app-lazy-employee-dropdown>
          </div>

          <div class="col-md-6">
            <mat-form-field>
              <mat-label>Note</mat-label>
              <input
                [readonly]="nominatedempid3 === ''"
                [ngModelOptions]="{ standalone: true }"
                matInput
                name="note3"
                [(ngModel)]="comment3"
                [readonly]="this.nominatedempid3 === ''"
                class="form-control"
              />
            </mat-form-field>
          </div>
        </div>
      </form>

      <div
        class="row form-group"
        *ngIf="(viewNominees && this.nomineesList.length === 0 && !isLoadingNom) || (!update && !isLoadingNom)"
      >
        <div class="col-sm-6">
          <p class="m-b-10 ng-star-inserted cus-flex-tools">
            <span>
              <img class="img-p" />
              &nbsp; No Nominees Added
            </span>
          </p>
        </div>
      </div>
      <div *ngFor="let nom of this.nomineesList">
        <div class="row form-group" *ngIf="viewNominees && this.nomineesList.length != 0 && !isLoadingNom">
          <div class="col-sm-6">
            <p class="m-b-10 ng-star-inserted cus-flex-tools">
              <span>
                <img *ngIf="nom.imgPath" class="img-p" src="{{ nom.imgPath }}" />
                <img *ngIf="!nom.imgPath" class="img-p" />
                &nbsp;
                {{ nom.nominatedempname }}
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                {{ nom.comment }}
              </span>
            </p>
          </div>
        </div>
      </div>

      <div class="row my-2 mx-2">
        <div class="action-buttons multi-btns col-sm-12 text-right">
          <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i>Cancel</button>
          <button
            [hidden]="!update"
            *ngIf="!viewNominees"
            (click)="viewNomineesEnable()"
            class="btn-primary"
            color="primary"
            mat-raised-button
          >
            View
          </button>
          <button
            [hidden]="!update"
            *ngIf="!viewNominees"
            (click)="saveNominees()"
            class="btn-primary"
            color="primary"
            mat-raised-button
          >
            <i class="flaticon-diskette"></i>Save
          </button>
          <button
            [hidden]="!update"
            *ngIf="viewNominees"
            (click)="viewNomineesEnable()"
            class="btn-primary"
            color="primary"
            mat-raised-button
          >
            Edit Nominees
          </button>
        </div>
      </div>
      <!-- 
  -->
    </div>
  </div>
</ng-template>
<ng-template #empDetail let-modal>
  <div class="card my-0">
    <div class="header">
      <h4 class="card-title card-title-modal">
        <span *ngIf="!isEdit">Add</span>
        <span *ngIf="isEdit">Edit</span>
        Employee Leaves Detail
      </h4>
    </div>
    <div class="card-content">
      <table
        mat-table
        [dataSource]="leaveList"
        class="table"
        *ngIf="leaveList && leaveList.length > 0 && !err && !isLoading"
      >
        <ng-container matColumnDef="rowDate">
          <th mat-header-cell *matHeaderCellDef>Date</th>
          <td mat-cell *matCellDef="let user">{{ user.leavedate | date }}</td>
        </ng-container>
        <ng-container matColumnDef="rowLeaveType">
          <th mat-header-cell *matHeaderCellDef>Leave Type</th>
          <td mat-cell *matCellDef="let user">{{ user.leavetype }}</td>
        </ng-container>
        <ng-container matColumnDef="leaveBalance">
          <th mat-header-cell *matHeaderCellDef>Leave Balance</th>
          <td mat-cell *matCellDef="let user">{{ user.leavebalance }}</td>
        </ng-container>
        <ng-container matColumnDef="NoOfDays">
          <th mat-header-cell *matHeaderCellDef>No of Days</th>
          <td mat-cell *matCellDef="let user">{{ user.noofdays }}</td>
        </ng-container>
        <ng-container matColumnDef="rowStatus">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let user">
            <ng-container *ngIf="user.status">
              <!-- <div>Pending: {{ user.status['Pending'] }}</div> -->
              <div>Approved: {{ user.status["Approve"] }}</div>
              <div>Cancelled: {{ user.status["Cancelled"] }}</div>
              <div>Cancelled: {{ user.status["Rejected"] }}</div>
            </ng-container>
          </td>
        </ng-container>
        <ng-container matColumnDef="Actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let user">
            <select placeholder="Actions" [(ngModel)]="user.action" class="form-control">
              <option value="" selected disabled hidden>Select action</option>
              <option value="Approve">Approve</option>
              <option value="Cancelled">Cancel</option>
              <option value="Rejected">Reject</option>
            </select>
          </td>
        </ng-container>
        <ng-container matColumnDef="buttons">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let user">
            <i class="icon-info-alt tooltip-effect-1" (click)="getLeaveDetail(user.id)"></i>
            <i class="icon-comments" (click)="getLeaveComment(user)"></i>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let user; columns: columns"></tr>
      </table>
      <!--<table class="table" *ngIf="leaveList && leaveList.length > 0 && !err && !isLoading">
                <thead>
             
                <th>Date</th>
                <th>Leave Type</th>
                <th>Leave Balance</th>
                <th>Status</th>
                <th>Actions</th>
                </thead>
                <tbody>
                <tr *ngFor="let user of employeeDetailList">
                    <td>{{ user.leavedate | date }}</td>
                    <td>{{ user.leaveType }}</td>
                    <td>{{ user.leavebalance }}</td>
                    <td>{{ user.leavestatustype }}</td>
                    <td>
                        <select placeholder="Actions" [(ngModel)]="user.action" class="form-control">
                            <option disabled>Select action</option>
                            <option value="Approve">Approve</option>
                            <option value="Cancelled">Cancel</option>
                            <option value="Rejected">Reject</option>
                        </select>
                    </td>
                    <td>
                        <i class="icon-comments" (click)="getLeaveDetailComment(user)">
                        </i>
                    </td>
                </tr>
                </tbody>
            </table>-->
      <div class="row">
        <div class="col-md-12 text-right" *ngIf="leaveList && leaveList.length > 0 && !err && !isLoading">
          <button mat-raised-button name="button" class="btn btn-save btn-primary" (click)="setAnyDetail()">
            <i class="flaticon-diskette"></i>Save
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #empComment let-modal>
  <div class="card my-0">
    <div class="header">
      <h4 class="card-title card-title-modal">Leaves Comment</h4>
    </div>
    <div class="card-content">
      <div class="row form-group">
        <div class="form-default col-md-12">
          <mat-form-field class="form-element">
            <mat-label>Leave a comment</mat-label>
            <textarea matinput class="form-control" [(ngModel)]="leaveComment"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="row" *ngIf="leaveData.leavecomments">
        <div class="col-md-12">Previous Comment</div>
        <ng-container *ngFor="let l of leaveData.leavecomments">
          <div class="col-md-12"><i class="icon-comments pr-2"> </i> {{ l.commnet }}</div>
        </ng-container>
      </div>
      <div class="row">
        <div class="col-md-12 text-right" *ngIf="leaveList && leaveList.length > 0 && !err && !isLoading">
          <button mat-raised-button name="button" class="btn btn-save btn-primary" (click)="saveLeaveComment()">
            <i class="flaticon-diskette"></i>Save
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #empDetailComment let-modal>
  <div class="card my-0">
    <div class="header">
      <h4 class="card-title card-title-modal">Leaves Comment</h4>
    </div>
    <div class="card-content">
      <div class="row">
        <div class="form-group form-default col-md-12">
          <mat-label>Leave a comment</mat-label>
          <textarea matinput class="form-control" [(ngModel)]="leaveDetailComment"></textarea>
        </div>
      </div>
      <div class="row" *ngIf="leaveDetailData.leavecomments">
        <div class="col-md-12">Previous Comment</div>
        <ng-container *ngFor="let l of leaveDetailData.leavecomments">
          <div class="col-md-12"><i class="icon-comments pr-2"> </i> {{ l.commnet }}</div>
        </ng-container>
      </div>
      <div class="row">
        <div
          class="action-buttons multi-btns col-md-12 text-right"
          *ngIf="leaveList && leaveList.length > 0 && !err && !isLoading"
        >
          <button mat-raised-button name="button" class="btn btn-save btn-primary" (click)="saveLeaveDetailComment()">
            <i class="flaticon-diskette"></i> Save
          </button>
        </div>

        <!--<div class="col-sm-12 text-right" *ngIf="leaveList && leaveList.length > 0 && !err && !isLoading">
                    <button type="button" name="button" class="btn btn-save btn-primary"
                            (click)="saveLeaveDetailComment()">Save
                    </button>
                </div>-->
      </div>
    </div>
  </div>
</ng-template>

<ng-template #configure let-modal>
  <div class="card my-0" style="min-width: 600px">
    <div class="header">
      <h4 class="card-title card-title-modal">Configure</h4>
    </div>
    <div class="card-content">
      <form [formGroup]="configureForm">
        <div class="row">
          <ng-container *ngFor="let cd of columnDefinitions; let i = index">
            <div class="col-md-4">
              <mat-checkbox [formControlName]="cd.def" color="primary">{{ cd.label }}</mat-checkbox>
            </div>
          </ng-container>
        </div>
      </form>
    </div>
  </div>
</ng-template>
