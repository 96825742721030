import {AfterViewInit, Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { NotificationService } from "src/app/core/services/notification.service";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import {FormControl} from '@angular/forms';
import {MatSelect} from '@angular/material/select';
import {ReplaySubject, Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {DashboardService} from '../../dashboard.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogHrmComponent} from 'src/app/dialog-hrm/dialog-hrm.component';
import {Router, ActivatedRoute} from '@angular/router';
import {DatePipe} from "@angular/common";
import {saveAs} from 'file-saver-es';
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
    selector: 'app-my-actions',
    templateUrl: './my-actions.component.html',
    styleUrls: ['./my-actions.component.scss']
})
export class MyActionsComponent implements OnInit {

    // displayedColumns = ['SNo', 'employee', 'action', 'description', 'dueDate', 'status', 'completed'];//'document'
    displayedColumns = ['employeeId' , 'employeeName' , 'ownerName' , 'actionName' , 'dueDate',  'actionBy' , 'statusName' , 'completedOn' , 'attachments'];
    displayedColumns1 = ['employeeId' , 'employeeName' , 'ownerName' , 'actionName' , 'dueDate',  'actionBy' , 'statusName' , 'completedOn'];
    userList: MatTableDataSource<any>;
    @ViewChild(MatSort, {static: false}) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    public userFilterCtrl: FormControl = new FormControl();
    // displayedColumns1 = ['documentName', 'cretedBy', 'createdOn'];
    // @ViewChild('addDisciplines') addDisciplines;
    public totalRecords: number;
    public activePage: number;
    public pageSize: number;
    public disciplineForm: FormGroup;
    isEdit: boolean;
    disTemplateData: any;
    public pageSizeOptions: number[] = [5, 10, 25, 100];
    public isLoading: boolean;
    public actionData: any[];
    public showTable: boolean;
    public empIds: any[];

    public load : boolean = false ;
    public filterQuery = '';
    employeeList : Array<any> = [] ;
    public attacherId: string;
    public attachmentList = [];
    private dialogRef: any;
    public fileType: string;
    public fileObj: any;
    public comment: string;

    @ViewChild('singleSelect',{static:false}) singleSelect: MatSelect;
    @ViewChild('deleteUser',{static:false}) deleteUser;
    @ViewChild('disActions',{static:false}) disActions;
    @ViewChild('disTemplate',{static:false}) disTemplate;
    @ViewChild('attach', {static: false}) attach;

    protected _onDestroy = new Subject<void>();
    empDisciplinesExtra: any;
    disciplineActionForm: FormGroup;
    dataSource: MatTableDataSource<any> = new MatTableDataSource([]);;
    employeeData: any[];
    filteredUsers: any[];
    id: any;
    editData: any;
    showForm: boolean;
    actionDataNew: any;
    deleteId: any;
    modalRef: any;
    pageIndex: number;
    actions: any[];
    disAction: any;
    DocumentTemp: any[];
    documentData: any[] = [];
    editActionForm: boolean;
    public caseId : String  = "" ;
    public disciplineForm2: FormGroup;
    public employeeOptions:  Array<any> = [] ;
    @ViewChild('addDisciplinary' , {static: false}) addDisciplinary;
    constructor(  private authService : AuthService ,private ngZone: NgZone, private router: Router,
        private service: DashboardService, private routeA: ActivatedRoute, private route: Router, public dialog: MatDialog, private modalService: NgbModal, private fb: FormBuilder, private toastr: NotificationService , public datepipe: DatePipe) {
        this.showForm = false;
        this.editActionForm = false;
        this.DocumentTemp = [];
        this.actions = [];
        this.routeA.params.subscribe(params => {
            this.id = params['id'];
            console.log("id", this.id);
        });

        this.showTable = false;
        this.empIds = [];
        this.actionData = [];
        this.employeeData = [];
        this.filteredUsers = [];
        this.disciplineActionForm = this.fb.group({
            empId: ['', Validators.required],
            caseName: ['', Validators.required],
            desc: ['',],
            actionBy: [''],
            dueDate: [''],
            completedOn: [''],
            document: [''],
            owner: ['', Validators.required],
            status: [''],
            action: [''],
            ownerName : ['']
        });
        this.disciplineForm2 = this.fb.group({
            empId: ['', Validators.required],
            caseName: ['', Validators.required],
            desc: [''],
            createdBy: [''],
            createdOn: [new Date()],
            status: ['in_progress'],
            action : [''],
            dueDate : ['']
          });
        // if (this.id == 2) {
        //     this.showForm = true;
        //     this.editData = JSON.parse(sessionStorage.getItem("disActionSave"));
        //     console.log("editData", this.editData);
        //     this.disciplineActionForm.patchValue({
        //         empId: this.editData.empId,
        //         caseName: this.editData.caseName,
        //         desc: this.editData.desc,
        //         actionBy: this.editData.actionBy,
        //         dueDate: '',
        //         completedOn: '',
        //         document: '',
        //         owner: '',
        //         status: this.editData.status,
        //         action: this.editData.action.name
        //     });
        //     this.disAction = this.editData.action;
        //     this.getDisciplineTemplateForm();
        // }
    }
    public correct : boolean = true ;
    initValuesDetection() {
        this.employeeOptions = this.employeeList ;
    
        this.disciplineForm2.controls['empId'].valueChanges.subscribe(change => {
    
          // tslint:disable-next-line:triple-equals
          if (change == null || change == undefined) {
            this.correct = true;
          } else {
    
            this.filterEmployees2(change);
          }
    
        });
    
      }
      protected filterEmployees2(change: String) {

        if (!this.employeeList) {
          return;
        }
    
        // get the search keyword
        if (!change) {
          this.employeeOptions = [];
          return;
        } else {
          this.checkEmployeeChange2(change);
          change = change.toLowerCase();
        }
        // filter the search
        this.employeeOptions =
            this.employeeList.filter(emp => emp.label.toLowerCase().indexOf(change) > -1);
    
      }
      checkEmployeeChange2(change) {
        // tslint:disable-next-line:no-shadowed-variable
        const x = this.employeeOptions.find(x => x.value === change);
        if (!x) {
          this.correct = false;
        }
    
      }
      public displayEmployeeByName2(option) {

        if (!option) {
        
            return '';
        }
  
      this.correct = true;
  
      return this.employeeList.find(x => x.value === option).label;
    }
    public isLoad : boolean = false ;
    openViewDispline () 
    {
         this.isLoad = true ;
         this.dialogRef = this.dialog.open(this.addDisciplinary, {
            width: '560px',
            data: {}
          });
         this.service. getDesciplineById(this.caseId).subscribe(res => {
           
            if (res && res.data) {
        
                this.disciplineForm2.patchValue({
                    empId: res.data.empId,
                    caseName: res.data.caseName,
                    desc: res.data.desc,
                    createdBy: res.data.createdBy,
                    createdOn: this.datepipe.transform(res.data.createdOn , 'dd-MM-yyyy'),
                  });
              this.isLoad = false ;
            }
          }, error => {
            const err = error.error && error.error.msg;
            this.isLoad = false;
            this.toastr.error(err || 'Error in getting Disciplinary Cases.');
           
          });
       
    }
    public tableConfig = {
        title: true,
        desc: true
    };

    showSpinner = false;
    onAddAttachment(id) {
        this.attacherId = id;
        this.showSpinner = true;

        this.service.getAttachmentList(this.attacherId).subscribe(
            r => {
                if (r && r.data) {
                    this.attachmentList = r.data;
                    this.dialogRef = this.dialog.open(this.attach, {
                        width: '560px',
                        height: 'auto'
                    });
                }
                this.showSpinner = false;
            },
            error => {
                this.showSpinner = false;
            }
        );
        this.comment = '';
        this.fileType = '';
        this.fileObj = null;
        // this.modalRef = this.modalService.open(this.attach);

    }
    public uploadedFile: any;
    public fileError : any;
    public showDisCase(): void {
        this.ngZone.run(() => {
            void this.router.navigate(['/discipline/disciplinary-cases/' +this.caseId ], {queryParams: {action: 'add'}});
        });
    }
    fileChange($event, type): void {
        this.uploadedFile = undefined;
        const reader = new FileReader();
        const file = $event.target.files[0];
        this.uploadedFile = file;
        const fileName = file && file.name;
        if (file && (file.size / 1024) < 2048) {
            this.fileObj = {
                added: new Date(),
                addedBy: 'string',
                desc: this.comment,
                docType: 'dd',
                empId: this.attacherId,
                fileName: fileName,
                size: file.size,
                url: ''
            };
        } else {
            this.fileError = 'Max size 2 MB';
            this.toastr.error('Max 2Mb File allowed.');
        }
    }



    saveAttachment() {
        this.load = true ;
        if (this.fileObj !== null) {
            console.log("data : " , this.disTemplateData);
            this.service.uploadFile(this.uploadedFile).subscribe(resp => {
                if (resp && resp.data) {
                    this.fileObj['url'] = resp.data.filename;
                    this.fileObj['desc'] = this.comment;
                    this.service.addAttachment(this.fileObj).subscribe(
                        res => {
                            this.service.getAttachmentList(this.attacherId).subscribe(
                                r => {
                                    this.attachmentList = r.data;
                                    this.toastr.success('Document Added');
                                    this.getAllActions();
                                    this.dialogRef.close();
                                    this.load = false ;
                                },
                                error => {
                                    this.toastr.error('Failed to add Document');
                                    this.load = false ;
                                    this.dialogRef.close();
                                }
                            );
                        },
                        error => {
                            this.toastr.error('Failed to add Document');
                            this.load = false ;
                            this.dialogRef.close();
                        }
                    );
                }
            }, error => {
                const err = error.error && error.error.msg;
                console.log(err);
                this.toastr.error('Failed to save Document', 'Upload Unsuccessful');
                this.load = false ;
                this.dialogRef.close();
            });
        } else {
            this.load = false ;
            this.toastr.error('No file Selected');
        }
    }

    onDeleteClicked(attachment: any) {
        const ID = attachment['id'];
        this.service.deleteAttachment(ID).subscribe(
            res => {
                this.service.getAttachmentList(this.attacherId).subscribe(
                    r => {
                        this.attachmentList = r.data;
                        this.toastr.success('Deleted');
                        this.getAllActions();
                    },
                    error => {
                        console.log(error);
                        this.toastr.error('Failed to delete');
                    }
                );
            }
        );

    }

    onDownloadClicked(attachment: any) {
        const URL = attachment['url'];
        const FILE_NAME = attachment['fileName'];
        window.open(URL);
    }
    public permissions : any;
    public read = false;
    public write = false ;
    public update = false;
    public delete = false;
   
    checkPermission(module,tabs) : void 
    {
        this.permissions = JSON.parse(this.authService.getPermissions());

        this.read = false ;
        this.write = false ;
        this.delete = false ;
        this.update = false;
        if(this.permissions != undefined && this.permissions != null )
        {
            for(let p of this.permissions)
            {
               
                if(p.id === module)
                {
                   
                    if(p.modules != undefined)
                    {
                      
                        for(let tab of p.modules)
                        {
                            if(tab.id === tabs)
                            {
                              
                                for(let move of tab.permits)
                                {
                                    if(move.id === "u")
                                    {
                                        this.update = true;
                                    }
                                    else if(move.id === "r")
                                    {
                                        this.read = true;
                                    }
                                    else if(move.id === "c")
                                    {
                                      
                                        this.write = true;
                                    }
                                    else if(move.id === "d")
                                    {
                                        this.delete = true;
                                    }
                                }
                            }
                        }
                    }
                   
                }
               
            }
        }
       

    }
    ngOnInit() {
        this.checkPermission("discipline","ds_da");
        this.activePage = 1;
        this.pageSize = 10;
        this.totalRecords = 0;
        this.pageIndex = 0;
        if (this.id) {
            this.getActionById(this.id);
        } else {
            this.getAllActions()
        }


        this.getEmployeeList();
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.userList.filter = filterValue;
    }

    public getActionById(id): void {
        this.isLoading = true;
        let data : any = [];
        this.service.getDisciplineActionsBydisId(id).subscribe(res => {
            if (res && res.data) {
                this.isLoading = false;
                this.showTable = true;
                this.actionData = res.data;
                this.actionData?.map((value :any ) => {
                    const obj = {
                        ...value,
                        "employeeName" : value?.disciplineCaseInfo?.employeeName,
                        "employeeId" : value?.disciplineCaseInfo?.employeeId
                    };
                    data.push(obj);
                });
                this.empDisciplinesExtra = res.extras;
                this.dataSource = new MatTableDataSource(data);
                this.dataSource.paginator = this.paginator ; 
                this.dataSource.sort = this.sort ;
                this.totalRecords = res.paging.count;
                // this.actionData.forEach(element => {
                //   element['checked'] = false;
                // });
            }
        }, error => {
            this.isLoading = false;
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error getting action');
        });
    }

    public getAllActions(): void {
        this.isLoading = true;
        let data : any = [];
        this.service.getDisciplineActions(this.pageIndex + 1, this.pageSize).subscribe(res => {
            this.isLoading = false;
            if (res && res.data) {
                console.log("res : " , res);
                this.showTable = true;
                this.actionData = res.data;
                this.actionData?.map((value :any ) => {
                   const obj = {
                       ...value,
                       "employeeName" : value?.disciplineCaseInfo?.employeeName,
                       "employeeId" : value?.disciplineCaseInfo?.employeeId
                   };
                   data.push(obj);
                });
                this.empDisciplinesExtra = res.extras;
                // this.actionData.forEach(element => {
                //   element['checked'] = false;
                // });
                this.dataSource = new MatTableDataSource(data);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                this.totalRecords = res.paging.count;
            }
        }, error => {
            this.isLoading = false;
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error getting disciplinary actions');
        });
    }

    pageChange(event) {
        this.pageIndex = event.pageIndex;
        this.getAllActions();
    }

    addDisciplinesForm(): void {
        const dialogRef = this.dialog.open(DialogHrmComponent, {
            width: '50vw',
            data: {view: 'editcase', data: this.service.editEditCaseData()}
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }

    public setSelected(ind, data, event) {
        console.log("checked", event.checked);
        if (event.checked == true) {
            this.empIds.push({"empId": data.empId});
        }
        else {
            var indexVal = this.empIds.findIndex(record => record.empId == data.empId)
            this.empIds.splice(indexVal, 1);
        }
        console.log("this.empIds", this.empIds);
    }

    public selectAll(event) {
        if (event.checked == true) {
            this.actionData.forEach((element, ind) => {
                element.checked = true;
                this.empIds.push({"empId": element.empId})
            });
        }
        else {
            this.actionData.forEach(element => {
                element.checked = false;
            });
            this.empIds = [];
        }
        console.log("this.empIds", this.empIds);
    }

    public getEmployeeList(): void {
        // this.isLoading=true;
        this.service.getEmployeeDetailList().subscribe(res => {
            this.isLoading = false;
            if (res && res.data) {
                this.employeeData = res.data;
                this.filteredUsers = this.employeeData.slice();
               
                // listen for search field value changes
                this.userFilterCtrl.valueChanges
                    .pipe(takeUntil(this._onDestroy))
                    .subscribe(() => {
                        this.filterUsers();
                    });
            }
        }, error => {
            this.isLoading = false;
        });

        this.service.getEmployeeDetailList().subscribe(res => {
           
            if (res && res.data) {
        
              res.data.map(e => {
                this.employeeList.push({label: e.id + ' ' + e.name, value: e.id});
              });
              this.initValuesDetection();
        
           
            }
          }, error => {
            this.isLoading = false;
          });
    }

    protected filterUsers() {
        if (!this.employeeData) {
            return;
        }
        // get the search keyword
        let search = this.userFilterCtrl.value;
        if (!search) {
            this.filteredUsers = this.employeeData.slice();
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the search
        this.filteredUsers =
            this.employeeData.filter(emp => emp.name.toLowerCase().indexOf(search) > -1);
    }

    takeDisciplineAction() {
        // this.modalRef = this.modalService.open(this.disActions);
        // console.log("dasdaf",this.editData.action);
        // this.disAction=this.editData.action;
        this.addDisciplinesForm();
    }

    createDocument() {
        this.modalRef = this.modalService.open(this.disTemplate);
        this.getDocumentTemplate();
    }

//   equals(o1: any, o2: any):boolean
// { 
//   console.log("01",o1,"o2",o2);
//   return o1 && o2 ? o1.id == o2.id : o1 == o2; 
// };

    getDocumentTemplate() {
        this.isLoading = true;
        this.service.getDisciplineTemplateList(0, 1000).subscribe(user => {
            this.isLoading = false;
            if (user && user.data) {
                this.isLoading = false;
                this.DocumentTemp = user.data;
            }
        }, error => {
            this.isLoading = false;
            //this.err = 'Error while getting discipline template details';
        });
    }

    saveCase() {
        if(this.disciplineActionForm.controls.owner.invalid) {
            this.disciplineActionForm.controls.owner.markAsTouched({onlySelf: true});
            this.toastr.error("Please fill required field.")
            return;
        }

        this.isLoading = true;
        var data = {
            "action": this.editData.action.id,
            "description": this.disciplineActionForm.value.desc,
            "dueDate": this.disciplineActionForm.value.dueDate,
            "empDisId": this.editData.id,
            "owner": this.disciplineActionForm.value.owner,
            "status": this.disciplineActionForm.value.status
        };
        this.service.addDisciplineAction(data).subscribe(res => {
            this.isLoading = false;
            if (res && res.data) {
                this.actionDataNew = res.data;
                this.getAllActions();
                this.showForm = false;
                this.toastr.success('Disciplinary action added successfully');
                console.log("filterdata", this.filteredUsers);
            }
        }, error => {
            this.isLoading = false;
            this.toastr.error('Error in adding Disciplinary action');
        });
    }

    public deleteActionById(id): void {
        this.isLoading = true;
        this.service.deleteDescipline(id).subscribe(res => {
            this.isLoading = false;
            if (res) {
                this.toastr.success('Action Deleted Successfully');
                this.actionData.forEach((element, i) => {
                    if (element.id == id) {
                        this.actionData.splice(i, 1);
                        this.dataSource = new MatTableDataSource(this.actionData);
                        this.dataSource.paginator = this.paginator;
                        this.dataSource.sort = this.sort;
                        this.paginator.pageIndex = 0;
                    }
                });
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.isLoading = false;
            this.toastr.error(err || 'Error in deleting action');
        });
    }

    public deleteActionPopUp(id): void {
        this.deleteId = id;
        this.modalRef = this.modalService.open(this.deleteUser);
    }

    public close(): void {
        this.modalRef.close();
    }
    public closeDia(): void {
        this.dialogRef.close();
    }
    onNoClick() {
        this.showForm = false;
        this.editData = null;
        this.editActionForm = false;
        this.documentData = null;
    }

    selectAction() {
        this.editData.action.id = this.disAction.id;
        this.editData.action.id = this.disAction.name;
        this.disciplineActionForm.patchValue({action: this.disAction.name});
        this.modalRef.close();
    }

    getDisciplineTemplateForm() {
        this.service.getDisciplineTemplateForm().subscribe(resp => {
            if (resp && resp.data) {
                this.actions = resp.data.actions;
                console.log("actions", this.actions);
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error in getting form detail');
        });

    }

    selectDocument() {
        this.isLoading = true;
        var data = {
            "daId": this.editData.id,
            "ddtId": this.disTemplateData.id,
        };
        this.service.createDisciplineActionDocument(data).subscribe(res => {
            this.isLoading = false;
            if (res && res.data) {
                this.getDocumentActionData(this.editData.id);
                this.close();
                this.toastr.success('Action Document added successfully');
            }
        }, error => {
            this.isLoading = false;
            this.toastr.error('Error in adding action Document');
        });
    }

    getDocumentActionData(id) {
        this.service.getDisciplineActionDocuments(id).subscribe(res => {
            if (res && res.data) {
                this.documentData = res.data;
            }
        }, error => {
            this.toastr.error('Error in getting Document Data');
        });
    }

    editAction(data) {
        this.showForm = true;
        this.editData = data;
        this.caseId = "" ; 
        this.caseId = this.editData?.disciplineCaseInfo?.caseId;
        this.editActionForm = true;
        this.disciplineActionForm.patchValue({
            empId: this.editData.empName,
            caseName: `${this.editData?.disciplineCaseInfo?.employeeName} - ${this.editData?.disciplineCaseInfo?.caseName}`,
            desc: this.editData.description,
            actionBy: this.editData.actionBy,
            dueDate: new Date(this.editData.dueDate) ,
            completedOn: '',
            document: '',
            owner: this.editData.owner,
            ownerName : this.editData.ownerName,
            status: this.editData.status,
            action: this.editData.action
        });
        console.log("edit : " , this.editData);
        this.getDocumentActionData(this.editData.id);
    }

    editActionCase() {
        this.isLoading = true;
        var data = {
            "action": this.editData.action,
            "description": this.disciplineActionForm.value.desc,
            "dueDate": this.disciplineActionForm.value.dueDate,
            "empDisId": this.editData.empDisId,
            "owner": this.disciplineActionForm.value.owner,
            "status": this.disciplineActionForm.value.status
        };
        this.service.updateDisciplineAction(this.editData.id, data).subscribe(res => {
            this.isLoading = false;
            if (res && res.data) {
                this.actionDataNew = res.data;
                if (this.id) {
                    this.getActionById(this.id);
                } else {
                    this.getAllActions()
                }
                this.showForm = false;
                this.toastr.success('Disciplinary action Updated successfully');
            }
        }, error => {
            this.isLoading = false;
            this.toastr.error('Error in updating Disciplinary action');
        });
    }

    onModelChange(value: string) {
        this.dataSource.filter = value.trim().toLocaleLowerCase();
    }

    exportToCsv(){

        const csv = this.actionData.map(
            row => {
                return [row?.disciplineCaseInfo?.employeeId, row?.disciplineCaseInfo?.employeeName,
                     row?.ownerName , row.actionName, row.dueDate ? moment(row.dueDate).format('DD/MM/yyyy')  : ""   , row.actionBy, row.statusName ,
                     row.completedOn ? moment(row.completedOn).format('DD/MM/yyyy')  : ""  ].join(',') + '\n';
            }
        );
        csv.unshift(this.displayedColumns1.join(',') + '\n');
        const exportDate = new Date();
        // tslint:disable-next-line:max-line-length
        const exportFileName = `Csv-export-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}.csv`;
        saveAs(new Blob(csv, {type: 'text/csv;charset=utf-8'}), exportFileName);
    }

    saveAsPdf(){
        if (this.dataSource.filteredData.length <= 0) {
            this.toastr.error('No Data to export');
            return;
        }
        const body = [];
        for (let i = 0; i < this.dataSource.filteredData.length; i++) {
            body.push([
                this.dataSource.filteredData[i].disciplineCaseInfo?.employeeId,
                this.dataSource.filteredData[i].disciplineCaseInfo?.employeeName,
                this.dataSource.filteredData[i].actionName,
                this.dataSource.filteredData[i].ownerName,
                this.datepipe.transform(this.dataSource.filteredData[i].actionDate , 'dd-MM-yyyy'),
                this.datepipe.transform(this.dataSource.filteredData[i].dueDate , 'dd-MM-yyyy'),
                this.dataSource.filteredData[i].actionBy,
                this.dataSource.filteredData[i].statusName]);
        }
        const doc = new jsPDF();
        autoTable(doc, {
            head: [['employeeId' , 'employeeName', 'Action', 'Owner' , 'ActionDate', 'DueDate', 'actionBy', 'status']],
            body: body
        });
        const exportDate = new Date();
        const exportFileName = `Actions-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}`;
        doc.save(exportFileName);
    }
}

