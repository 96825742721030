<app-loading [isLoading]="this.isLoading === true"></app-loading>

<div class="page-body no-title" *ngIf="this.isLoading === false">
  <div class="row">
    <div class="col-sm-12">
      <h4 class="mat-title-modal" style="margin-bottom: 26px; margin-top: 22px">
        Enable Work Week
        <span style="margin-left: 4%">
          <mat-slide-toggle [disabled]="!update" [(ngModel)]="enabled" color="primary"></mat-slide-toggle>
        </span>
      </h4>

      <app-card [title]="'Work Week'">
        <div class="dt-responsive" *ngIf="dataSource">
          <div class="table-responsive">
            <mat-card-content>
              <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="day">
                  <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-200'">Day</mat-header-cell>
                  <mat-cell *matCellDef="let row" [ngClass]="'w-200'">
                    {{ row.day }}
                    <br />
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="type">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <mat-form-field class="form-element" style="width: 75%">
                      <mat-select
                        [disabled]="!update"
                        disableOptionCentering
                        ngModel="{{ row.type }}"
                        (valueChange)="onTypeChange(row, $event)"
                      >
                        <mat-option *ngFor="let t of workingType" [value]="t">
                          {{ t }}
                        </mat-option>
                        "
                      </mat-select>
                    </mat-form-field>
                    <br />
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="shiftIn">
                  <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-90'"
                    ><i class="flaticon-clock-time"> Shift In</i></mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" [ngClass]="'w-90'">
                    <ng-container *ngIf="row.type !== 'Non-Working Day'">
                      <mat-form-field class="form-element" style="width: 50%">
                        <input
                          [readonly]="!update"
                          (ngModelChange)="shiftInChanged($event, row)"
                          [format]="24"
                          [ngxTimepicker]="picker"
                          matInput
                          ngModel="{{ row.shiftIn }}"
                        />
                        <ngx-material-timepicker #picker></ngx-material-timepicker>
                      </mat-form-field>
                      <!-- <mat-error  *ngIf="row.s">Shift in should be before Shift out</mat-error>   -->
                    </ng-container>
                    <br />
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="shiftOut">
                  <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-90'"
                    ><i class="flaticon-clock-time"> Shift Out </i></mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" [ngClass]="'w-90'">
                    <ng-container *ngIf="row.type !== 'Non-Working Day'">
                      <mat-form-field class="form-element" style="width: 50%">
                        <input
                          [min]="row.shiftIn"
                          [readonly]="!update"
                          (ngModelChange)="shiftOutChanged($event, row)"
                          [format]="24"
                          [ngxTimepicker]="picker2"
                          matInput
                          ngModel="{{ row.shiftOut }}"
                        />
                        <ngx-material-timepicker #picker2></ngx-material-timepicker>
                      </mat-form-field>
                      <!-- <mat-error  *ngIf="row.s">Shift out should be after  Shift in</mat-error> -->
                    </ng-container>
                    <br />
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="lunchIn" [ngClass]="'w-90'">
                  <mat-header-cell *matHeaderCellDef mat-sort-header
                    ><i class="flaticon-clock-time">Lunch In </i></mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" [ngClass]="'w-90'">
                    <ng-container *ngIf="row.type !== 'Non-Working Day'">
                      <mat-form-field class="form-element" style="width: 50%">
                        <input
                         
                          [readonly]="!update"
                          (ngModelChange)="lunchInChanged($event, row)"
                          [format]="24"
                          [ngxTimepicker]="picker3"
                          matInput
                          ngModel="{{ row.lunchIn }}"
                        />
                        <ngx-material-timepicker #picker3></ngx-material-timepicker>
                      </mat-form-field>
                    </ng-container>
                    <br />
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="lunchOut">
                  <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-90'"
                    ><i class="flaticon-clock-time">Lunch Out </i>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" [ngClass]="'w-90'">
                    <ng-container *ngIf="row.type !== 'Non-Working Day'">
                      <mat-form-field class="form-element" style="width: 50%">
                        <input
                          
                          [readonly]="!update"
                          (ngModelChange)="lunchOutChanged($event, row)"
                          [format]="24"
                          [ngxTimepicker]="picker4"
                          matInput
                          ngModel="{{ row.lunchOut }}"
                        />
                        <ngx-material-timepicker #picker4></ngx-material-timepicker>
                      </mat-form-field>
                    </ng-container>
                    <br />
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="duration">
                  <mat-header-cell *matHeaderCellDef mat-sort-header
                    ><i class="flaticon-clock-time">Duration</i>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <ng-container *ngIf="row.type !== 'Non-Working Day'">
                      <mat-form-field class="form-element">
                        <input [readonly]="true" [value]="row.duration" matInput />
                      </mat-form-field>
                    </ng-container>
                    <br />
                  </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columns"> </mat-row>
              </mat-table>
            </mat-card-content>
          </div>
          <div class="row" [hidden]="!update">
            <div class="multi-btns col-md-12 text-right">
              <button mat-raised-button class="btn-primary" (click)="save()" color="primary">
                <i class="flaticon-diskette"></i> Save
              </button>
            </div>
          </div>
        </div>
      </app-card>
    </div>
  </div>
</div>
