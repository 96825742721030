import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DashboardService} from '../../../../dashboard.service';
import { NotificationService } from "src/app/core/services/notification.service";
import {
    Calendar,
    CalendarOptions,
    DateSelectArg,
    EventApi,
    EventClickArg,
    EventInput,
    FullCalendarComponent
} from '@fullcalendar/angular';

import {DatePipe} from "@angular/common";
import {MatDialog} from "@angular/material/dialog";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
    selector: 'app-leave-calendar-month',
    templateUrl: './leave-calendar-month.component.html',
    styleUrls: ['./leave-calendar-month.component.scss'],
})
export class LeaveCalendarMonthComponent implements OnInit {

    public dialogRef: any;
    @ViewChild('addCourse', {static: false}) addCourse;
    @ViewChild('addCourse1', {static: false}) addCourse1;
    public filterYear: FormGroup;
    public filteredOptions: Array<any>;
    public employeeList = [];
    public temporaryDepartment: Array<any>;
    public EmployeeStatus: Array<any>;
    public leaveTypes: Array<any>;
    public locations: Array<any>;
    public correct = false;
    public err = "";
    public selectedDate = new Date(2021, 1, 1);
    public isLoading = false;
    public isNotShow = true;
    public legends = [];
    public employeeFilterCtrl: FormControl = new FormControl();
    protected _onDestroy = new Subject<void>();
    public filteredEmployees: Array<any> = [];

    public chipList: any = {
        empId: [],
        subUnitId: [],
        leaveTypeId: [],
        leaveStatus: []

    };

    public months  = [];

    public years = [];

    constructor(public dialog: MatDialog, private service: DashboardService, private fb: FormBuilder, private toastr: NotificationService, public datepipe: DatePipe) {
        this.createForm();

        this.service.getEmployeeDetailList().subscribe(resp => {
            if (resp && resp.data) {
                resp.data.map(e => {
                    this.employeeList.push({label: e.id + ' ' + e.name, id: e.id});
                });
            }
        });

        this.months = [{text: 'This Month', value: null},
            {text: 'January', value: 0},
            {text: 'February', value: 1},
            {text: 'March', value: 2},
            {text: 'April', value: 3},
            {text: 'May', value: 4},
            {text: 'June', value: 5},
            {text: 'July', value: 6},
            {text: 'August', value: 7},
            {text: 'September', value: 8},
            {text: 'October', value: 9},
            {text: 'November', value: 10},
            {text: 'December', value: 11}];

        this.years = [{text: 'This Year', value: null},
            {text: '2022', value: 2022},
            {text: '2021', value: 2021},
            {text: '2020', value: 2020},
            {text: '2019', value: 2019},
            {text: '2018', value: 2018},
            {text: '2017', value: 2017},
            {text: '2016', value: 2016},
            {text: '2015', value: 2015},
            {text: '2014', value: 2014},
            {text: '2013', value: 2013},];

        this.service.getLegend().subscribe(resp => {
            if (resp && resp.data) {
                console.log("col : ", resp.data);
                this.legends = resp?.data;
            }
        });
    }


    change(op, name) {
        if (name === 'subUnitId') {
            var result = this.temporaryDepartment?.filter(item => op?.value?.includes(item.id));
            this.chipList[name] = result;
        } else if (name === 'empId') {
            var result = this.employeeList?.filter(item => op?.value?.includes(item.id));
            this.chipList[name] = result;
        } else if (name === 'leaveTypeId') {
            var result = this.leaveTypes?.filter(item => op?.value?.includes(item.id));
            this.chipList[name] = result;
        } else if (name === 'leaveStatus') {
            var re = this.leaveStatus?.filter(item => op?.value?.includes(item.id));
            this.chipList[name] = re;
        }
    }


    remove(id, name) {
        this.chipList[name] = this.chipList[name]?.filter(item => item?.id !== id);
        let res = this.filterYear.value[name]?.filter(item => item !== id);
        let newObj = {};
        newObj[name] = res;
        this.filterYear.patchValue(newObj);
    }


    public createForm(): void {
        this.filterYear = this.fb.group({
            includePastEmployees: [false],
            year: null,
            empId: [[]],
            makeDefaultFilter: [false],
            hideLeavesOfSupervisors: [false],
            subUnitId: [[]],
            month: [null],
            leaveTypeId: [[]],
            employementStatus: [''],
            locationId: [''],
            leaveStatus : []
        });
    }


    ngOnInit() {


        this.service.getStructureList().subscribe(resp => {
            if (resp && resp.data) {
                this.temporaryDepartment = resp.data;
            }
        });

        this.service.getLeaveTypeList().subscribe(resp => {
            if (resp && resp.data) {
                this.leaveTypes = resp.data;
            }
        });

        this.service.getEmployeeStatusList().subscribe(resp => {
            if (resp && resp.data) {
                this.EmployeeStatus = resp.data;
                  this.service.getCalandarFilter('M').subscribe(res => {
            if (res && res.data) {
                if (res?.data?.makeDefaultFilter) {
                    let result = [];
                    result = this.employeeList?.filter(item => res.data.empId?.includes(item.id));
                    this.chipList['empId'] = result;
                    result = this.temporaryDepartment?.filter(item => res.data.subUnitId?.includes(item.id));
                    this.chipList['subUnitId'] = result;
                    result = this.leaveTypes?.filter(item => res.data.leaveTypeId?.includes(item.id));
                    this.chipList['leaveTypeId'] = result;
                    result = this.leaveStatus?.filter(item => res.data.leaveTypeId?.includes(item.id));
                    this.chipList['leaveStatus'] = result;

                    // console.log("chis : " , this.chipList);
                    
                    this.filterYear.patchValue(res.data);
                    this.filterYear.patchValue({  makeDefaultFilter: false})

                }
            }
        });
            }
        });

        this.service.getLocationList().subscribe(resp => {
            if (resp && resp.data) {
                this.locations = resp.data;
            }
        });

        this.service.getCalandarFilter('M').subscribe(res => {
            if (res && res.data) {
                if (res?.data?.makeDefaultFilter) {
                    let result = [];
                    result = this.employeeList?.filter(item => res.data.empId?.includes(item.id));
                    this.chipList['empId'] = result;
                    result = this.temporaryDepartment?.filter(item => res.data.subUnitId?.includes(item.id));
                    this.chipList['subUnitId'] = result;
                    result = this.leaveTypes?.filter(item => res.data.leaveTypeId?.includes(item.id));
                    this.chipList['leaveTypeId'] = result;
                    result = this.leaveStatus?.filter(item => res.data.leaveTypeId?.includes(item.id));
                    this.chipList['leaveStatus'] = result;

                    // console.log("chis : " , this.chipList);

                    this.filterYear.patchValue(res.data);
                    this.filterYear.patchValue({  makeDefaultFilter: false})

                }
            }
        });

        this.filteredEmployees = this.employeeList;
        this.employeeFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterEmployees();
            });

        this.initValuesDetection();
    }

    protected filterEmployees() {
        if (!this.employeeList) {
            return;
        }
        // get the search keyword
        let search = this.employeeFilterCtrl.value;
        if (!search) {
            this.filteredEmployees = this.employeeList;
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the search
        this.filteredEmployees =
            this.employeeList.filter(emp => emp.label.toLowerCase().indexOf(search) > -1);
    }

    initValuesDetection() {

    }


    public rest() {
        this.filterYear.reset();
        this.filterYear = this.fb.group({
            includePastEmployees: false,
            year: null,
            empId: [[]],
            makeDefaultFilter: true,
            hideLeavesOfSupervisors: false,
            subUnitId: [[]],
            month: null,
            leaveTypeId: [],
            employementStatus: '',
            locationId: '',
            leaveStatus : []
        });
        this.submitAddUser();
        this.filteredOptions = this.employeeList;
        this.filterYear = this.fb.group({
            includePastEmployees: false,
            year: null,
            empId: [[]],
            makeDefaultFilter: false,
            hideLeavesOfSupervisors: false,
            subUnitId: [[]],
            month: null,
            leaveTypeId: [],
            employementStatus: '',
            locationId: '',
            leaveStatus : []
        });
    }

    submitAddUser() {
        this.isLoading = true;
        this.selectedDate = new Date(this.filterYear.value.year ? parseInt(this.filterYear.value.year) : 2021, 1, 1);

        var date = new Date(), y = this.filterYear.value.year || date.getFullYear(), m = this.filterYear.value.month || date.getMonth();
        var firstDay = new Date(y, m  , 1);
        var lastDay = new Date(y, m + 1, 0);

        this.service.getLeaveCalendar({...this.filterYear.value ,  startDate : firstDay,
            endDate : lastDay , mod : 'M'}).subscribe(res => {
            if (res && res.data) {
                let events = [];
                res.data.map(d => {
                    const obj = {title: `${d?.leaveType} / ${d?.leaveStatus}`, allDay : true , start: new Date(d?.date) , backgroundColor : d?.color , data : d};
                    events.push(obj);
                });

                let dataOptions: CalendarOptions = {
                    headerToolbar: {
                        left: 'prev,next',
                        center: 'title',
                    },
                    initialView: 'dayGridMonth',
                    events: events,
                    weekends: true,
                    editable: true,
                    selectable: true,
                    selectMirror: true,
                    dayMaxEvents: true,
                    select: this.handleDateSelect.bind(this),
                    eventClick: this.handleEventClick.bind(this),
                    eventsSet: this.handleEvents.bind(this)
                };

                this.calendarOptions = dataOptions;

                this.isLoading = false;
                this.isNotShow = false;
            }
        }, error => {
            const _err = error.error && error.error.msg;
            this.err = 'Error while adding Calender';
            this.toastr.error(_err || this.err);
            this.isLoading = false;
            this.isNotShow = false;
        });

    }






    public leaveStatus = [
        {text: 'pending Approval', id: 'pendingApproval'},
        {text: 'Rejected', id: 'rejected'},
        {text: 'Scheduled', id: 'scheduled'},
        {text: 'Taken', id: 'taken'},
    ];

    @ViewChild('calendar', {static: false}) calendarComponent: FullCalendarComponent;

    events = [];
    calendarEvents: EventInput[] = [];
    calendarOptions: CalendarOptions = {};
    calendarVisible = true;

    currentEvents: EventApi[] = [];

    // public isLoading: boolean;


    public handleDateClick($event): void {

    }

    handleCalendarToggle() {
        this.calendarVisible = !this.calendarVisible;
    }

    handleWeekendsToggle() {
        const {calendarOptions} = this;
        calendarOptions.weekends = !calendarOptions.weekends;
    }

    handleDateSelect(selectInfo: DateSelectArg) {
        // const title = prompt('Please enter a new title for your event');
        // const calendarApi = selectInfo.view.calendar;

        // calendarApi.unselect(); // clear date selection

        // if (title) {
        //   calendarApi.addEvent({
        //     id: createEventId(),
        //     title,
        //     start: selectInfo.startStr,
        //     end: selectInfo.endStr,
        //     allDay: selectInfo.allDay
        //   });
        // }
    }

    dataEvent: any = {
        data: {}
    };
    handleEventClick(clickInfo: EventClickArg) {
        // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
        //     clickInfo.event.remove();
        // }
        console.log("event : " , clickInfo.event.extendedProps)
        this.dataEvent = clickInfo.event.extendedProps;
        this.dialogRef = this.dialog.open(this.addCourse1, {
            width: '420px',
            height: 'auto'
        });
    }

    handleEvents(events: EventApi[]) {
        this.currentEvents = events;
    }


    addNewCourse() {
        this.dialogRef = this.dialog.open(this.addCourse, {
            width: '420px',
            height: 'auto'
        });

    }

    calendarApi: Calendar;

    ngAfterViewInit(): void {
        this.calendarApi = this.calendarComponent.getApi();
    }

    // public save(): void {
    //     this.calendarApi.gotoDate("2020-01-24");
    //     console.log("end" , this.calendarApi.view.currentEnd);
    //     console.log("start" , this.calendarApi.view.currentStart);
    // }


    public close(): void {
        this.dialogRef.close();
    }

    public isChange: boolean = false;

    public save(): void {
        this.isChange = true;
    }

    public save1(): void {
        this.service.changeLegend(this.legends).subscribe(res => {
            if (res && res.data) {
                this.dialogRef.close();
                // if(this.filterYear.value?.empId){
                //     // this.getCalander();
                // }
                this.isChange = false;
            }
        }, error => {
            const _err = error.error && error.error.msg;
            this.err = 'Error while changing Legend';
            this.toastr.error(_err || this.err);
            this.isLoading = false;
            this.isNotShow = true;
        });

    }


}
