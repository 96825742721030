import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {DashboardService} from '../../../dashboard.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotificationService } from "src/app/core/services/notification.service";
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {saveAs} from 'file-saver-es';
import jsPDF from 'jspdf';
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox';
import autoTable from 'jspdf-autotable';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import { LanguageConfig } from 'src/app/Language-config';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-pay-grade',
  templateUrl: './pay-grade.component.html',
  styleUrls: ['./pay-grade.component.scss']
})
export class PayGradeComponent implements OnInit {

  public filterQuery = '';
  public minMaxErr: string;
  public userList: Array<any>;
  public isLoading: boolean;
  public isUserAdded: boolean;
  public currencyList: Array<any>;
  public err: string;
  public dialogRef: any;
  public dialogRefAssign: any;
  public userForm: FormGroup;
  public currencyForm: FormGroup;
  public isEdit: boolean;
  public isEditCurr: boolean;
  public isCurrId: number;
  public editId: string;
  public payCurrencies: Array<any> = [];
  public filteredOptions:Array<any>;
  public correct : boolean = true ;

  selectedUsers: any[] = [];
  selectedCheckBoxList = [];
  public lang = new Map();
  // for material data table
  @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  //public columns = ['select', 'name', 'printCurrency', 'edit'];
  public columns = ['select', 'name', 'printCurrency','printCurrencyCode'];
  @ViewChild('addUser', {static: false}) addUser;
  @ViewChild('deleteUser', {static: false}) deleteUser;
  @ViewChild('assignCurrency', {static: false}) assignCurrency;

  constructor(private service: DashboardService,
              //  private modalService: NgbModal,
              private dialog: MatDialog,
              private fb: FormBuilder,
              private toastr: NotificationService,
              private authService : AuthService) {
    this.createForm();
    this.createAssignCurrencyForm();
    this.lang = LanguageConfig.map;
  }
  public permissions : any;
  public read = false;
  public write = false ;
  public update = false;
  public delete = false;
  public classEdit = "";
 
  checkPermission(module,tabs) : void 
  {
      this.permissions = JSON.parse(this.authService.getPermissions());
  
      this.read = false ;
      this.write = false ;
      this.delete = false ;
      this.update = false;
      if(this.permissions != undefined && this.permissions != null )
      {
          for(let p of this.permissions)
          {
             
              if(p.id === module)
              {
                 
                  if(p.modules != undefined)
                  {
                    
                      for(let tab of p.modules)
                      {
                          if(tab.id === tabs)
                          {
                            
                              for(let move of tab.permits)
                              {
                                  if(move.id === "u")
                                  {
                                      this.update = true;
                                  }
                                  else if(move.id === "r")
                                  {
                                      this.read = true;
                                  }
                                  else if(move.id === "c")
                                  {
                                    
                                      this.write = true;
                                  }
                                  else if(move.id === "d")
                                  {
                                      this.delete = true;
                                  }
                              }
                          }
                      }
                  }
                 
              }
        
          }
      }
    

  }

  doNOthing() : void
  {
    return ;
  } 
  ngOnInit() {
    this.isLoading = true;
    this.classEdit = "cursor-pointer";
    this.isEdit = false;
    this.isEditCurr = false;
    this.checkPermission("admin","ad_jb");
    this.getPayGradeList();
    this.getPayGradeCurrencyList();
    this.isUserAdded = false;
    this.currencyForm.valueChanges.subscribe(val => {
      if (parseInt(val.min, 10) > parseInt(val.max, 10)) {
        this.minMaxErr = 'Maximum value should be greater then minimum value';
      } else {
        this.minMaxErr = undefined;
      }
    });
    this.initValuesDetection();
  }

  public createForm(): void {
    this.userForm = this.fb.group({
      name: ['', Validators.required]
    });
  }

  public createAssignCurrencyForm(): void {
    this.currencyForm = this.fb.group({
      currencyName: ['', Validators.required],
      min: ['', Validators.required],
      max: ['', Validators.required],
      currencyCode: ['', ]
    });
  }

  public assignCurrencies(): void {
    this.currencyForm.reset();
    this.isEditCurr = false;
    this.dialogRefAssign = this.dialog.open(this.assignCurrency, {
      width: '560px',
      height: 'auto',
      autoFocus : false,
    });
  }

  public addCurrency(): void {
    const _obj = this.currencyForm.value;
    const currencyCode = this.currencyList.filter(res => {
      return res.name === this.currencyForm.value.currencyName;
    });
    _obj.currencyCode = currencyCode && currencyCode[0].id;
    if (this.isEditCurr) {
      this.payCurrencies[this.isCurrId] = (this.currencyForm.value);
    } else {
      this.payCurrencies.push(this.currencyForm.value);

    }
    // this.modalRefAssign.close();
    this.dialogRefAssign.close();

  }
  public addNewUser() {
    this.payCurrencies = [];
    this.isEdit = false;
    this.dialogRef = this.dialog.open(this.addUser, {
      width: '768px',
      height: 'auto'
    });
    this.userForm.reset();
  }

  public close(): void {
    // this.modalRef.close();
    this.dialogRef.close();
  }

  public getPayGradeList(): void {
    this.service.getPayGradeList().subscribe(user => {
      if (user && user.data) {
        this.isLoading = false;
        this.userList = user.data;
        this.userList.map(u => {
          u.printCurrency = '';
          u.printCurrencyCode = '';
          if (u.payCurrencies) {
            u.payCurrencies.map(v => {
              u.printCurrency = u.printCurrency + v.currencyName + ' , ' ;
            });
            u.printCurrency= u.printCurrency.slice(0, -2);
            u.payCurrencies.map(v => {
              u.printCurrencyCode = u.printCurrencyCode + v.currencyCode + ' , ' ;
            });
            u.printCurrencyCode= u.printCurrencyCode.slice(0, -2);
          }
          console.log(this.userList);
        });
        this.err = '';
      }
      this.dataSource = new MatTableDataSource<any>(this.userList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, error => {
      const err = error.error && error.error.msg;

      this.toastr.error(err || 'Error while getting Pay Grade');
      this.isLoading = false;
      this.err = 'Error while getting Pay Grade';
    });
  }
  initValuesDetection()
  {
    this.filteredOptions=this.currencyList;
    this.currencyForm.controls['currencyName'].valueChanges.subscribe(change => {
      if(change == null || change == undefined)
      {
       this.correct=true;
      
      } // Value inside the input field as soon as it changes
      else{
     
      this.filterCurrency(change);
      }
    });
    
  }
  protected filterCurrency(change : String) {
    this.filteredOptions = this.currencyList;
    if (!this.currencyList) {
        return;
    }
    
    // get the search keyword
    if (!change) {
     
      this.filteredOptions =this.currencyList;
        return;
    } else {
      this.checkCurrencyChange(change);
      change = change.toLowerCase();
    }
    // filter the search
    this.filteredOptions =
        this.currencyList.filter(co => co.name.toLowerCase().indexOf(change) > -1);
      
}

 checkCurrencyChange(change)
  {
    var x = this.filteredOptions.find(x => x.name === change);
    if(!x)
    {
      this.correct=false;
    }
    
  }

  public displayCurrencyByname(option) {
    
    this.correct =true;
  
  return this.currencyList.find(x=>x.name === option).name;
}
  public getPayGradeCurrencyList(): void {
    this.service.getPayGradeCurrencyList().subscribe(user => {
      if (user && user.data && user.data.currency) {
        this.isLoading = false;
        this.currencyList = user.data.currency;
        this.err = '';
        this.filteredOptions=this.currencyList;
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error while getting Pay Grade Currency List');
      this.isLoading = false;
      this.err = 'Error while getting Pay Grade Currency List';
    });
  }

  public submitAddPayGrade(): void {
    if (this.isEdit) {
      this.edit();
    } else {
      this.add();
    }
  }

  public add(): void {
   if (this.userForm.value.name.trim() !== '') {
    this.userForm.value.payCurrencies = this.payCurrencies;
    this.isUserAdded = true;
    this.service.addPayGrade(this.userForm.value).subscribe(user => {
      this.isUserAdded = false;
      this.toastr.success('Pay Grade added successfully');
      // this.modalRef.close();
      this.dialogRef.close();
      this.userForm.reset();
      this.getPayGradeList();
    }, error => {
      const err = error.error && error.error.msg;
      this.isUserAdded = false;
      this.toastr.error(err || 'Error in adding Pay Grade.Please try again.');
    });
  } else {
    this.toastr.error('Field not be empty.');
  }
  }

  public edit(): void {
    if (this.userForm.value.name.trim() !== '') {
    this.isUserAdded = true;
    this.userForm.value.payCurrencies = this.payCurrencies;
    this.service.editPayGrade(this.userForm.value, this.editId).subscribe(user => {
      this.isUserAdded = false;
      this.toastr.success('Pay Grade updating successfully');
      // this.modalRef.close();
      this.dialogRef.close();
      this.getPayGradeList();
    }, error => {
      const err = error.error && error.error.msg;
      this.isUserAdded = false;
      this.toastr.error(err || 'Error in adding Pay Grade.Please try again.');
    });
  } else {
    this.toastr.error('Field not be empty.');
  }
  }

  public editUser(id): void {
    this.isEdit = true;
    this.isUserAdded = true;
      // this.modalRef = this.modalService.open(this.addUser, { size: 'lg' });
      this.dialogRef = this.dialog.open(this.addUser, {
        width: '768px',
        height: 'auto'
      });
    this.service.getPayGradeById(id).subscribe(user => {
      if (user && user.data) {
        this.userForm.patchValue(user.data);
        this.payCurrencies = (user.data && user.data.payCurrencies) || [];
        this.isUserAdded = false;
      
        this.editId = id;
      }
    }, error => {
      this.isUserAdded = false;
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error getting Pay Grade');
    });
  }
  public deletePayGrade(): void {
    this.service.deletePayGradeList(this.selectedCheckBoxList).subscribe(user => {
      if (user) {
        this.close();
        this.getPayGradeList();
        this.toastr.success('Pay Grade deleted !');
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.close();
      this.getPayGradeList();
      this.toastr.error(err || 'Error deleting Pay Grade');
    });
    this.selectedCheckBoxList = [];
  }

  public editCurrency(curr, i): void {
    this.currencyForm.patchValue(curr);
    this.isCurrId = i;
    this.isEditCurr = true;
    this.dialogRefAssign = this.dialog.open(this.assignCurrency, {
      width: '560px',
      height: 'auto',
      autoFocus : false,
    });
  }

  public deleteCurrency(i): void {
    this.payCurrencies.splice(i);
  }

  onCheckBoxChange($event: MatCheckboxChange, id: any) {
    if (!this.alreadySelected($event.source) && $event.checked) {
      this.selectedCheckBoxList.push($event.source.value);
    } else if (!$event.checked) {
      this.selectedCheckBoxList = this.selectedCheckBoxList.filter(
          value => {
            return value !== $event.source.value;
          }
      );
    }
  }

  alreadySelected(item: MatCheckbox): boolean {
    this.selectedCheckBoxList.forEach(
        value => {
          if (item === value) {
            return true;
          }
        }
    );
    return false;
  }

  onSelectAllClicked() {
    this.selectedCheckBoxList = [];
    this.matCheckBoxes.forEach(
        item => {
          this.selectedCheckBoxList.push(item.value);
          item.checked = true;
        }
    );
  }

  onDeSelectAllClicked() {
    this.selectedCheckBoxList = [];
    this.matCheckBoxes.forEach(
        item => {
          item.checked = false;
        }
    );
  }

  onDeleteSelected() {
    this.dialogRef = this.dialog.open(this.deleteUser, {
      width: '440px',
      height: 'auto'
    });
  }
  onUserChanged(user: HTMLInputElement) {
    if (user.checked) {
        this.selectedUsers.push(user);
        return;
    }
    this.selectedUsers = this.selectedUsers.filter(value => value !== user);
}
  onModelChange(value: string) {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
  exportToCsv() {
    const csv = this.dataSource.filteredData.map(
        row => {
          return [
              row.name,
              row.printCurrency,
              row.printCurrencyCode,
          ].join(',') + '\n';
        }
    );
    csv.unshift(['Pay Grades', 'Currency','Currency Code'].join(',') + '\n');
    const exportDate = new Date();
    const exportFileName = `Csv-export-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}.csv`;
    saveAs(new Blob(csv, {type: 'text/csv;charset=utf-8'}), exportFileName);
  }

  saveAsPdf() {
    if (this.dataSource.filteredData.length <= 0) {
      this.toastr.error('No Data to export');
      return;
    }
    const body = [];
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      body.push(
          [
              this.dataSource.filteredData[i].name,
              this.dataSource.filteredData[i].printCurrency,
              this.dataSource.filteredData[i].printCurrencyCode,
          ]);
    }
    const doc = new jsPDF();
    autoTable(doc, {
      head: [['Pay Grades', 'Currency','Currency Code']],
      body: body
    });
    const exportDate = new Date();
    const exportFileName = `Pay grades-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}}`;
    doc.save(exportFileName);
  }
}
