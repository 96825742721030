import {Component, Input, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DashboardService} from '../../../dashboard.service';
import { NotificationService } from "src/app/core/services/notification.service";
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment';
import { startWith } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-report-to',
  templateUrl: './report-to.component.html',
  styleUrls: ['./report-to.component.scss']
})
export class ReportToComponent implements OnInit {
  @Input() employeeId: string;
  public dialogRef: any;
  public reportingList: Array<any> = [];
  public reportingListSuper: Array<any> = [];
  public supervisorDropdownList: Array<any> = [];
  public subordinateDropdownList: Array<any> = [];
  public addSupervisorForm: FormGroup;
  public addSubordinateForm: FormGroup;
  public isLoading : boolean = true;
  public edit = false;
  supervisorList: MatTableDataSource<any> = new MatTableDataSource([]);
  subOrdinateList: MatTableDataSource<any> = new MatTableDataSource([]);
  public reportid : String ;
  public subordinateId : String = null ;
  public filteredOptions:  Array<any>;
  public filteredOptions2:  Array<any>;
  public superList:  Array<any>;
  public subList:  Array<any>;
  public relationId : string;
  public oldSuperVisior : string;
  public oldSubordinateId : string;
  public removedSupervisor = [];
  public removedSubordinate = [];
  selectedCheckBoxListSuper = [];
  selectedCheckBoxListSub = [];
  isUserAdded = false;
  @ViewChildren('matCheckBoxesSuper') matCheckBoxesSuper: QueryList<MatCheckbox>;
  @ViewChildren('matCheckBoxesSub') matCheckBoxesSub: QueryList<MatCheckbox>;
  columnsSuper = ['select','name','reportingMethod.name', 'edit'];
  columnsSub = ['select','subordinateName','reportingMethod.name', 'edit'];
  columnAttach = ['fileName', 'url', 'desc', 'size', 'added', 'edit', 'delete'];
  @ViewChild('addSupervisor', {static: false}) addSupervisor;
  @ViewChild('addSubordinate', {static: false}) addSubordinate;
  @ViewChild('subDeletion', {static: false}) subDeletion;
  @ViewChild('superVisiorDelete', {static: false}) superVisiorDelete;
  @ViewChild('superPagination', {static: false}) superPagination: MatPaginator;
  @ViewChild('t1Sort') t1Sort: MatSort;
  @ViewChild('t2Sort') t2Sort: MatSort;
  @ViewChild('t3Sort') t3Sort: MatSort;
  @ViewChild('subPaginator') subPaginator: MatPaginator;
  @ViewChild('attachPaginator') attachPaginator: MatPaginator;
  isLoadingRAttachment: boolean = true;
  reportAttachmentList: MatTableDataSource<any> = new MatTableDataSource([]);fileName: any;
  comment: any;
  @ViewChild('editAttach', {static: false}) editAttach;
  @ViewChild('deleteAttachment', {static: false}) deleteAttachment;
  @ViewChild('attach', {static: false}) attach;
  deleteAttachmentId: any;
  editAttachmentId: any;
  public fileObj: any;
  fileType: string;
  fileUrl: string;
  public sectionsList : Array<any> = [];
  public tempSectionsList : Array<any> = [];
  public saveCutomList : Array<any> = [];
  public saveCBCutomList : Array<any> = [];
  uploadedFile: any;
  constructor(private dialog: MatDialog,
              private service: DashboardService,
              private fb: FormBuilder,
              private toastr: NotificationService,
              private authService : AuthService) {
    this.createForm();
  }

  ngOnDestroy() {
    this.supervisorList = null ;
    this.subOrdinateList = null ;
    this.matCheckBoxesSub = null ;
    this.matCheckBoxesSuper = null ;
    this.reportAttachmentList = null ;
    this.dialog = null ;
    this.dialogRef = null ;
    this.authService = null ;
    this.service = null ;
    this.fb = null ;
    this.toastr = null ;
    this.attachPaginator = null ;
    this.subPaginator = null ;
    this.t3Sort = null ;
    this.t1Sort = null ;
    this.t2Sort = null ;
    this.superPagination = null ;
    this.superVisiorDelete = null ;
    this.addSupervisor = null ;
    this.addSubordinate = null ;
    this.subDeletion = null ;
    this.attach = null ;
    this.deleteAttachment; 
    this.editAttach = null ;
    this.reportAttachmentList = null ;

    this.addSupervisorForm = null ;
    this.addSubordinateForm = null ;
    
  }
  public permissions : any;
  public read = false;
  public write = false ;
  public update = false;
  public delete = false;

  checkPermission(module,tabs) : void 
  {
      this.permissions = JSON.parse(this.authService.getPermissions());

      this.read = false ;
      this.write = false ;
      this.delete = false ;
      this.update = false;

      if(this.permissions != undefined && this.permissions != null )
      {
          for(let p of this.permissions)
          {
             
              if(p.id === module)
              {
                 
                  if(p.modules != undefined)
                  {
                    
                      for(let tab of p.modules)
                      {
                          if(tab.id === tabs)
                          {
                            
                              for(let move of tab.permits)
                              {
                                  if(move.id === "u")
                                  {
                                      this.update = true;
                                     
                                  }
                                  else if(move.id === "r")
                                  {
                                      this.read = true;
                                  }
                                 
                                  else if(move.id === "d")
                                  {
                                      this.delete = true;
                                  }
                              }
                          }
                      }
                  }
                 
              }
             
          }
      }
     

  }
  checkRequiredFieldsInCustomFields(sectionID,customFields)
  {
    
    for(let section of this.sectionsList)
    {
      if(section.id === sectionID)
     {
      for(let custom of customFields)
      {
        if(custom.required)
        {
          if(custom.value === "" || custom.value === null || custom.value === undefined)
          {
            section.save=true;
            return ;
          }
        }
      }

      section.save=false;
     } 
    }
   

  }

  updateCustomField(sectionId ,customFields) : void
  {
    
  
  
    this.saveCutomList = [];
    this.saveCBCutomList = [];
    for(let custom of customFields)
    {
      if(custom.required)
      {
        if(custom.value === "")
        {
          this.toastr.error('Please fill the required fields');
          return ;
        }
      }
      var obj = 
      {
         fieldId : "",
	       fieldValue: ""  
      }
      if(custom.type != "cb")
      {
        let id : string = custom.id;
        let value : string = custom.value;
        if(custom.type == "dt")
        {
          value = moment(custom.value).format('YYYY-MM-DD').toString();
        }
        
        obj.fieldId = id;
        obj.fieldValue = value;
        this.saveCutomList.push(obj);
      }


      if(custom.type == "cb")
      {

        var obj2 = 
        {
           fieldId : "",
           values: []
        }
        obj2.fieldId = custom.id;
        let values : Array<any> = [];
        obj2.values = values;
        for(let c of custom.values)
        {
          if(c.booleanValue)
          {
            
            obj2.values.push(c.option);

          }
        }
        this.saveCBCutomList.push(obj2);
        
      }

     
    }
    
    this.service.saveCustomFieldsInEditEmployee(sectionId,this.employeeId,this.saveCutomList).subscribe(res => {
      if (res) {
      
        this.service.saveCustomFieldsCheckBoxesInEditEmployee(sectionId,this.employeeId,this.saveCBCutomList).subscribe(res => {
          if (res) {
            this.toastr.success('Updated Successfully!..');
          }
        }, error => {
          this.toastr.error('Error in  updating');
        });
      }
    }, error => {
      
    });
  }

  ngOnInit(): void {
    this.checkPermission("pdm","pd_em");
    this.service.getCustomFieldsForEmployee('rt',this.employeeId).subscribe(res => {
      if (res && res.data) {
      
        this.sectionsList = res.data;

       
    
      }
  });   
    
    this.getSupervisorAndSubordinateList();
    this.service.getReportingList().subscribe(res => {
      if (res) {
        this.reportingList = res.data;
        this.getSubordinateDropdown(this.edit, this.employeeId, this.reportingList[0].id,this.subordinateId);
        // for by default value
        this.addSubordinateForm.patchValue({
          reportingMethod: this.reportingList[0].id
        });
      }
    }, error => {
      this.toastr.error('Error in getting the reporting List');
    });
    this.getReportToAttachmentList();
   
  }


  createForm() {
    this.addSupervisorForm = this.fb.group({
      reportingMethod: [''],
      supervisorId: [''],
      subordinateId: [''],

    });
    this.addSubordinateForm = this.fb.group({
      reportingMethod: [''],
      supervisorId: [''],
      subordinateId: [''],
  
    });
  }

  getReportToAttachmentList() {
    this.service.getNewAttachmentList(this.employeeId, 'su').subscribe(resp => {
      if (resp && resp.data) {
        this.isLoadingRAttachment =  true;
        this.reportAttachmentList = new MatTableDataSource<any>(resp.data);
        this.reportAttachmentList.data = resp.data;
          this.reportAttachmentList.paginator = this.attachPaginator;
          this.reportAttachmentList.sort = this.t3Sort;
          
          
          this.isLoadingRAttachment = false;
          
      }
    });
  }
  
  getSupervisorAndSubordinateList() {
    this.service.getSupervisorAndSubordinateList(this.employeeId).subscribe(res => {
      if (res) {
        this.isLoading = true;
        this.supervisorList = new MatTableDataSource<any>(res.data.supervisorsList);
        this.supervisorList.paginator = this.superPagination;
        this.supervisorList.sortingDataAccessor = (item, property) => {
          switch(property) {
            case 'reportingMethod.name': return item.reportingMethod.name;
            default: return item[property];
          }
        };
        this.supervisorList.sort = this.t1Sort;
        
        this.subOrdinateList = new MatTableDataSource<any>(res.data.subordinatesList);
        this.subOrdinateList.paginator = this.subPaginator;
        this.subOrdinateList.sortingDataAccessor = (item, property) => {
          switch(property) {
            case 'reportingMethod.name': return item.reportingMethod.name;
            default: return item[property];
          }
        };
        this.subOrdinateList.sort = this.t2Sort;
        this.superList = res.data.supervisorsList;
       
        this.subList = res.data.subordinatesList;
        this.isLoading = false;
      }
    }, error => {
      this.toastr.error('Error in getting supervisor and subordinate list');
    });
  }

  getSupervisorDropdown(edit, empId, reportingMethodId) : any  {
    
    this.supervisorDropdownList = [];
    this.addSupervisorForm.patchValue(
      {
        supervisorId : ""
      }
    );
    this.service.getSupervisorDropdown(edit, empId, reportingMethodId).subscribe(res => {
      if (res) {
        res.data.map(e => {
          this.supervisorDropdownList.push({ name: e.id + ' ' + e.name, id: e.id });
      });
      this.initValuesDetection();
     
      }
    }, error => {
      
      this.toastr.error('Error in getting the supervisor');
     
    });
  }


  getSupervisorDropdownForEdit(edit, empId, reportingMethodId,reprotId,superId) : any  {
    this.isUserAdded = true;
    this.supervisorDropdownList = [];
    this.addSupervisorForm.patchValue(
      {
        supervisorId : ""
      }
    );
    this.service.getSupervisorDropdown(edit, empId, reportingMethodId).subscribe(res => {
      if (res) {
        res.data.map(e => {
          this.supervisorDropdownList.push({ name: e.id + ' ' + e.name, id: e.id });
      });

      this.addSupervisorForm.patchValue({
        reportingMethod: reprotId,
        supervisorId: superId
      });

      this.initValuesDetection();
      this.isUserAdded = false;
     
      }
    }, error => {
      
      this.toastr.error('Error in getting the supervisor');
      this.isUserAdded = false;
    });
  }


  initValuesDetection() {
    this.filteredOptions = [];
    this.filteredOptions2 = [];
   
    
    this.addSupervisorForm.controls['supervisorId'].valueChanges.subscribe(change => {
        startWith("");
        if (change == null || change == undefined) {
           
            this.filteredOptions = [];
        } else {

            this.filterEmployees(change);
        }

    });

    this.addSubordinateForm.controls['subordinateId'].valueChanges.subscribe(change => {
      startWith("");
      if (change == null || change == undefined) {
         
          this.filteredOptions2 = [];
      } else {

          this.filterEmployees2(change);
      }

  });
  }

  public displayEmployeeByName(option) {
    if (!option) {
        return '';
    }
  
    return this.supervisorDropdownList.find(x => x.id === option).name;
}

public displayEmployeeByName2(option) {
  if (!option) {
      return '';
  }

  return this.subordinateDropdownList.find(x => x.id === option).name;
}
  protected filterEmployees(change: String) {
    this.filteredOptions = this.supervisorDropdownList;
    if (!this.supervisorDropdownList) {
        return;
    }

    // get the search keyword
    if (!change) {

        this.filteredOptions = [];
        return;
    } else {
       
        change = change.toLowerCase();
    }
    // filter the search
    this.filteredOptions =
        this.supervisorDropdownList.filter(emp => emp.name.toLowerCase().indexOf(change) > -1);

}

protected filterEmployees2(change: String) {
  this.filteredOptions2 = this.subordinateDropdownList;
  if (!this.subordinateDropdownList) {
      return;
  }

  // get the search keyword
  if (!change) {

      this.filteredOptions2 = [];
      return;
  } else {
     
      change = change.toLowerCase();
  }
  // filter the search
  this.filteredOptions2 =
      this.subordinateDropdownList.filter(emp => emp.name.toLowerCase().indexOf(change) > -1);

}
  getSubordinateDropdown(edit, empId, reportingMethodId,subordinateId) {
    this.subordinateDropdownList = [];
    this.addSubordinateForm.patchValue(
      {
        subordinateId : ""
      }
    );
    this.service.getSubordinateDropdown(edit, empId, reportingMethodId,subordinateId).subscribe(res => {
      if (res) {
        res.data.map(e => {
          this.subordinateDropdownList.push({ name: e.id + ' ' + e.name, id: e.id });
      });

      this.initValuesDetection();
      }
    }, error => {
      this.toastr.error('Error in getting the subordinate');
      this.subordinateDropdownList = [];
    });
  }


  getSubordinateDropdownForEdit(edit, empId, reportingMethodId,subordinateId,reprotId,subId) {
    this.isUserAdded = true;
    this.subordinateDropdownList = [];
    this.addSubordinateForm.patchValue(
      {
        subordinateId : ""
      }
    );
    this.service.getSubordinateDropdown(edit, empId, reportingMethodId,subordinateId).subscribe(res => {
      if (res) {
        res.data.map(e => {
          this.subordinateDropdownList.push({ name: e.id + ' ' + e.name, id: e.id });
      });
      this.addSubordinateForm.patchValue({
        reportingMethod: reprotId,
        subordinateId: subId,
      });
      this.initValuesDetection();
      this.isUserAdded = false;
      }
    }, error => {
      this.toastr.error('Error in getting the subordinate');
      this.subordinateDropdownList = [];
      this.isUserAdded = false;
    });
  }

  openAddSuperVisorDialog(edit) {
    this.edit = edit;
    if(!this.edit)
    {
      this.addSupervisorForm.reset();
      this.dialogRef = this.dialog.open(this.addSupervisor, {
        width: '560px',
        height: 'auto'
      });
      
      this.service.getReportingMethodsForSS(this.employeeId).subscribe(res => {
        if (res) {
          this.reportingListSuper = res.data;
         
         
         
          this.getSupervisorDropdown(this.edit, this.employeeId, this.reportingListSuper[0].id);
          // for by default value
         
          
    
        }
      }, error => {
        this.toastr.error('Error in getting the reporting List');
      });
    }
    if(this.edit)
    {
      this.dialogRef = this.dialog.open(this.addSupervisor, {
        width: '560px',
        height: 'auto'
      });

    }
    
    
  }

  openAddSubordinateDialog(edit) {
    
   
    this.edit = edit;
    if(this.edit === false)
    {
      this.addSubordinateForm.reset();
    }
    this.dialogRef = this.dialog.open(this.addSubordinate, {
      width: '560px',
      height: 'auto'
    });
  }

  close() {
    this.dialogRef.close()
    
   
  }

  onSupervisorChange(event) {
    this.getSupervisorDropdown(this.edit, this.employeeId, event);
  }

  onSubordinateChange(event) {
    this.getSubordinateDropdown(this.edit, this.employeeId, event,this.subordinateId);
  }

  saveSupervisor() {
    this.isUserAdded = true;
    if (!this.edit) {
     
      this.service.addSupervisor(this.addSupervisorForm.value.reportingMethod,
          this.employeeId, this.addSupervisorForm.value.supervisorId).subscribe(res => {
            this.toastr.success('Supervisor Added Successfully');
            this.close();
            this.isUserAdded = false;
        this.getSupervisorAndSubordinateList();

       
      },
      error => {
        const err = error.error && error.error.msg;
        this.isUserAdded = false;
        this.close();
        this.toastr.error(err || 'Error in Saving the supervisor');
      });
      this.addSupervisorForm.reset();
    } else {
     
      this.service.updateSupervisor(this.addSupervisorForm.value.reportingMethod,
          this.employeeId, this.addSupervisorForm.value.supervisorId,this.relationId,this.oldSuperVisior).subscribe(res => {
            this.toastr.success('Supervisor updated Successfully');
            this.close();
            this.isUserAdded = false;
        this.getSupervisorAndSubordinateList();
        this.edit = false;
        
      }, 
      error => {
        const err = error.error && error.error.msg;
        this.isUserAdded = false;
        this.close();
        this.toastr.error(err || 'Error in updating the supervisor');
      });
    }
  }

  saveSubordinate() {
    this.isUserAdded = true;
    if (!this.edit) {
      
      this.service.addSubordiante(this.addSubordinateForm.value.reportingMethod,
          this.addSubordinateForm.value.subordinateId, this.employeeId).subscribe(res => {
            this.toastr.success('Supervisor Added Successfully');
            this.isUserAdded = false;
            this.close();
        this.getSupervisorAndSubordinateList();
       
      }
      ,
      error => {
        const err = error.error && error.error.msg;
        this.isUserAdded = false;
        this.close();
        this.toastr.error(err || 'Error in saving the subordinate');
      });
    } else {
      this.service.updateSubordinate(this.addSubordinateForm.value.reportingMethod,
          this.addSubordinateForm.value.subordinateId, this.employeeId,this.relationId,this.oldSubordinateId).subscribe(res => {
            this.toastr.success('Supervisor updated Successfully');
            this.isUserAdded = false;
        this.close();
        this.getSupervisorAndSubordinateList();
        this.edit = false;
       
      },
      error => {
        const err = error.error && error.error.msg;
        this.isUserAdded = false;
        this.close();
        this.toastr.error(err || 'in updating the subordinate');
      });
    }
  }

  public deleteSuperVisior(): void {
    this.isUserAdded = true ;
    this.removedSupervisor = [];

  for(let sub of this.selectedCheckBoxListSuper)
  {
    var subInfo = 
    {
       targetId : this.employeeId,
	
	     deleteId : sub.supervisorid,
	
	     relationId : sub.relationId ,
	
	     reportingMethodId : sub.reportingMethod.id
    }

    this.removedSupervisor.push(subInfo);
  }
    this.service.deleteMultipleSuperVisor(this.removedSupervisor).subscribe(user => {
      if (user) {
        this.close();
      
        this.isUserAdded = false ;
        this.toastr.success('Supervisor Deleted Successfully');
        this.getSupervisorAndSubordinateList();
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.close();
      this.isUserAdded = false ;
      this.toastr.error(err || 'Error in Deleteing Supervisor');
    });
    this.selectedCheckBoxListSuper = [];
  }

  public deleteSubordinate(): void {
    this.isUserAdded = true ;

  this.removedSubordinate = [];
  for(let sub of this.selectedCheckBoxListSub)
  {
    var subInfo = 
    {
       targetId : this.employeeId,
	
	     deleteId : sub.subordinateid,
	
	     relationId : sub.relationId ,
	
	     reportingMethodId : sub.reportingMethod.id
    }

    this.removedSubordinate.push(subInfo);
  }
    this.service.deleteMultipleSubordinates(this.removedSubordinate).subscribe(user => {
      if (user) {
        this.close();
        this.toastr.success('Subordinate Deleted Successfully');
        this.getSupervisorAndSubordinateList();
        this.isUserAdded = false ;
      
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.close();
      this.isUserAdded = false ;
      this.toastr.error(err || 'Error in Deleting Subordinate');
    });
    this.selectedCheckBoxListSub = [];
  }

   onSupervisorEdit(row) {
    this.edit = true;
      this.getSupervisorDropdownForEdit(this.edit, this.employeeId, row.reportingMethod.id,row.reportingMethod.id, row.supervisorid);
     
      this.oldSuperVisior = row.supervisorid;
      this.relationId = row.relationId;
      this.openAddSuperVisorDialog(true);

    
    
    
   
  }

  onSubordinateEdit(row) {
    this.edit = true;
    this.subordinateId=row.subordinateid;
    this.getSubordinateDropdownForEdit(this.edit, this.employeeId, row.reportingMethod.id,this.subordinateId,row.reportingMethod.id,row.subordinateid);
    this.oldSubordinateId= row.subordinateid;
    this.relationId = row.relationId;
    this.subordinateId=null;
    this.openAddSubordinateDialog(true);
  }
  onSelectAllClickedSuper() {
    this.selectedCheckBoxListSuper = [];
    
    this.superList.forEach(
      supervisior => {
          this.selectedCheckBoxListSuper.push(supervisior);
         
      }
  );
    this.matCheckBoxesSuper.forEach(
        item => {
            item.checked = true;
        }
    );
}
onDeSelectAllClickedSuper() {
  this.selectedCheckBoxListSuper = [];
  this.matCheckBoxesSuper.forEach(
      item => {
          item.checked = false;
      }
  );
}

onDeleteSelectedSuper() {
  this.dialogRef = this.dialog.open(this.superVisiorDelete, {
    width: '440px',
    height: 'auto'
  });
}

alreadySelectedSuper(item: any): boolean {
  this.selectedCheckBoxListSuper.forEach(
      value => {
          if (item.relationId === value.relationId) {
              return true;
          }
      }
  );
  return false;
}

onSelectAllClickedSub() {
  this.selectedCheckBoxListSub = [];

  this.subList.forEach(
    subordiante => {
        this.selectedCheckBoxListSub.push(subordiante);
       
    }
);
  this.matCheckBoxesSub.forEach(
      item => {
          item.checked = true;
      }
  );
}
onDeSelectAllClickedSub() {
this.selectedCheckBoxListSub = [];
this.matCheckBoxesSub.forEach(
    item => {
        item.checked = false;
    }
);
}

onDeleteSelectedSub() {
  this.dialogRef = this.dialog.open(this.subDeletion, {
    width: '440px',
    height: 'auto'
  });

}

onCheckBoxChangeSuper($event: MatCheckboxChange, id: any) {
  if (!this.alreadySelectedSuper(id) && $event.checked) {
      this.selectedCheckBoxListSuper.push(id);
  } else if (!$event.checked) {
      this.selectedCheckBoxListSuper = this.selectedCheckBoxListSuper.filter(
          value => {
              return value.relationId !== id.relationId;
          }
      );
  }
}
onCheckBoxChangeSub($event: MatCheckboxChange, id: any) {
if (!this.alreadySelectedSub(id) && $event.checked) {
    this.selectedCheckBoxListSub.push(id);
} else if (!$event.checked) {
    this.selectedCheckBoxListSub = this.selectedCheckBoxListSub.filter(
        value => {
            return value.relationId !== id.relationId;
        }
    );
}
}
alreadySelectedSub(item: any): boolean {
this.selectedCheckBoxListSub.forEach(
    value => {
        if (item.relationId === value.relationId) {
            return true;
        }
    }
);
return false;
}

public editAttachment(row): void {
  console.log(row);
  this.editAttachmentId = row.id;
  this.fileName = row.fileName;
  this.comment = row.desc;
  this.dialogRef = this.dialog.open(this.editAttach, {
    width: '440px',
    height: 'auto'
  });
}
public open(url): void {
  window.open(url);
}
deleteAttachmentSelected(id) {
  this.deleteAttachmentId = id;
  this.dialogRef = this.dialog.open(this.deleteAttachment, {
    width: '440px',
    height: 'auto'
  });
}

public addAttachment(): void {
  this.editAttachmentId = '';
  this.comment = '';
  this.fileType = '';
  this.fileObj = {};
  this.fileUrl = '';
  this.fileName = '';
  this.dialogRef = this.dialog.open(this.attach, {
    width: '440px',
    height: 'auto'
  });
}
public updateAttachment() {
  this.service.updateNewAttachmentList(this.comment, this.fileName, this.editAttachmentId).subscribe(res => {
    if (res) {
      this.getReportToAttachmentList();
      this.toastr.success('Attachment updated Successfully.');
      this.dialogRef.close();
    }
  }, error => {
    this.toastr.error('Error in updating attachment');
  });
}

public deleteAttachmentFrom(): void {
  this.service.deleteAttachment(this.deleteAttachmentId).subscribe(resp => {
    if (resp) {


        this.getReportToAttachmentList();
      this.dialogRef.close();
      this.toastr.success('Attachment Deleted Successfully...');
    }
  }, error => {
    this.toastr.error('Error in deleting the Attachment');
  });
}

public fileChange($event, type): void {
  this.uploadedFile = undefined;
  const reader = new FileReader();
  const file = $event.target.files[0];
  this.uploadedFile = file;
  const fileName = file && file.name;
  if (file && (file.size / 1024) < 2048) {
    this.fileObj = {
      added: new Date(),
      addedBy: 'string',
      desc: this.comment,
      docType: type,
      empId: this.employeeId,
      fileName: fileName,
      size: file.size,
      section: ''
    };
  } else {
    this.toastr.error('Max 2Mb File allowed.');
  }
}

public saveAttachment(): void {
  // this is for the save attachment
  this.service.uploadFile(this.uploadedFile).subscribe(resp => {
    if (resp && resp.data) {
      this.fileObj['url'] = resp.data.filename;
      this.fileObj['desc'] = this.comment;
      this.fileObj.section = 'su';
      this.fileObj.fileName = this.fileName;
      if (this.editAttachmentId) {

      } else {
        this.service.newAddAttachment(this.fileObj).subscribe(resp1 => {
          // this.attachmentList.filteredData.push(resp1.data);

            this.getReportToAttachmentList();;

            this.toastr.success('file added successfully!');
          this.dialogRef.close();
        });
      }
    }
  }, error => {
    const err = error.error && error.error.msg;
    this.toastr.error('error in adding file!');
  });
}
}
