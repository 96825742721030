import {Component, OnInit, ViewChild, ViewChildren, QueryList, Input} from '@angular/core';
import {DashboardService} from '../../../dashboard.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { NotificationService } from "src/app/core/services/notification.service";
import jsPDF from 'jspdf';
import {saveAs} from 'file-saver-es';
import autoTable from 'jspdf-autotable';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox';
import * as moment from 'moment';
import { AuthService } from 'src/app/core/services/auth.service';
@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.scss']
})
export class SocialMediaComponent implements OnInit {

  public filterQuery = '';
  selectedUsers: any[] = [];
  public userList: Array<any>;
  public isLoading: boolean = true;
  public isUserAdded: boolean;
  selectedCheckBoxList = [];
  public err: string;
  public modalRef: any;
  public userForm: FormGroup;
  public isEdit: boolean;
  public editId: string;
  public deleteId: string;
  public sectionsList : Array<any> = [];
  public tempSectionsList : Array<any> = [];
  public saveCutomList : Array<any> = [];
  public saveCBCutomList : Array<any> = [];
  public dialogRef: any;

  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  public columns = ['select', 'name', 'type', 'link', 'edit'];
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public validationError = {
    required: 'Required'
  };
  @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
  @Input() employeeId: string;
  @ViewChild('addUser', {static: false}) addUser;
  @ViewChild('deleteUser', {static: false}) deleteUser;

  ngOnDestroy() {
    this.dialog = null ;
    this.dialogRef = null ;
    this.authService = null ;
    this.service = null ;
    this.fb = null ;
    this.toastr = null ;
    this.sort = null ;
    this.paginator = null ;
    this.userForm = null ;
    this.matCheckBoxes = null ;
    this.dataSource = null ;

  }

  constructor(private service: DashboardService,
              private modalService: NgbModal,
              private dialog: MatDialog,
              private fb: FormBuilder,
              private toastr: NotificationService,
              private authService : AuthService) {
    this.createForm();
  }
  public permissions : any;
  public read = false;
  public write = false ;
  public update = false;
  public delete = false;
  checkPermission(module,tabs) : void 
  {
      this.permissions = JSON.parse(this.authService.getPermissions());

      this.read = false ;
      this.write = false ;
      this.delete = false ;
      this.update = false;

      if(this.permissions != undefined && this.permissions != null )
      {
          for(let p of this.permissions)
          {
             
              if(p.id === module)
              {
                 
                  if(p.modules != undefined)
                  {
                    
                      for(let tab of p.modules)
                      {
                          if(tab.id === tabs)
                          {
                            
                              for(let move of tab.permits)
                              {
                                  if(move.id === "u")
                                  {
                                      this.update = true;
                                     
                                  }
                                  else if(move.id === "r")
                                  {
                                      this.read = true;
                                  }
                                 
                                  else if(move.id === "d")
                                  {
                                      this.delete = true;
                                  }
                              }
                          }
                      }
                  }
                 
              }
             
          }
      }
     

  }
  checkRequiredFieldsInCustomFields(sectionID,customFields)
  {
    
    for(let section of this.sectionsList)
    {
      if(section.id === sectionID)
     {
      for(let custom of customFields)
      {
        if(custom.required)
        {
          if(custom.value === "" || custom.value === null || custom.value === undefined)
          {
            section.save=true;
            return ;
          }
        }
      }

      section.save=false;
     } 
    }
   

  }

  updateCustomField(sectionId ,customFields) : void
  {
    
  
  
    this.saveCutomList = [];
    this.saveCBCutomList = [];
    for(let custom of customFields)
    {
      if(custom.required)
      {
        if(custom.value === "")
        {
          this.toastr.error('Please fill the required fields');
          return ;
        }
      }
      var obj = 
      {
         fieldId : "",
	       fieldValue: ""  
      }
      if(custom.type != "cb")
      {
        let id : string = custom.id;
        let value : string = custom.value;
        if(custom.type == "dt")
        {
          value = moment(custom.value).format('YYYY-MM-DD').toString();
        }
        
        obj.fieldId = id;
        obj.fieldValue = value;
        this.saveCutomList.push(obj);
      }


      if(custom.type == "cb")
      {

        var obj2 = 
        {
           fieldId : "",
           values: []
        }
        obj2.fieldId = custom.id;
        let values : Array<any> = [];
        obj2.values = values;
        for(let c of custom.values)
        {
          if(c.booleanValue)
          {
            
            obj2.values.push(c.option);

          }
        }
        this.saveCBCutomList.push(obj2);
        
      }

     
    }
    
    this.service.saveCustomFieldsInEditEmployee(sectionId,this.employeeId,this.saveCutomList).subscribe(res => {
      if (res) {
      
        this.service.saveCustomFieldsCheckBoxesInEditEmployee(sectionId,this.employeeId,this.saveCBCutomList).subscribe(res => {
          if (res) {
            this.toastr.success('Updated Successfully!..');
          }
        }, error => {
          this.toastr.error('Error in  updating');
        });
      }
    }, error => {
      
    });
  }

  ngOnInit() {
    this.isLoading = true;
    this.checkPermission("pdm","pd_em")
    this.service.getCustomFieldsForEmployee('sm',this.employeeId).subscribe(res => {
      if (res && res.data) {
      
        this.sectionsList = res.data;

       
    
      }
  });
      this.getJobCategoryList();
    this.isEdit = false;
    this.isUserAdded = false;

  }

  public createForm(): void {
    this.userForm = this.fb.group({
      name: [''],
      type: [''],
      link: ['']
    });
  }

  public addNewUser() {
    this.isEdit = false;
    this.dialogRef = this.dialog.open(this.addUser, {
      width: '440px',
      height: 'auto'
  });
    this.userForm.reset();
  }

  public close(): void {
    this.dialogRef.close();
  }

  public getJobCategoryList(): void {
    this.service.getSocialMediaList(this.employeeId).subscribe(user => {
      if (user && user.data) {
        user.data.forEach((item => {
            if (item.type === 'fb') {
                item.type = 'Facebook';
            } else if (item.type === 'tw') {
                item.type = 'Twitter';
            } else if (item.type === 'li') {
                item.type = 'LinkedIn';
            }
            setInterval(res => {
                this.isLoading = false;
            }, 500);
            this.dataSource = new MatTableDataSource<any>(user.data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.isLoading = false;
        }));
        this.isLoading = false;
        this.err = '';
      }
    }, error => {
        this.isLoading = false;
        this.err = 'Error while getting Social Media';
    });
  }

  public submitAddJobCategory(): void {
    if (this.isEdit) {
      this.edit();
    } else {
      this.add();
    }
  }

  public add(): void {
    this.userForm.value['empId'] = this.employeeId;
    this.isUserAdded = true;

    this.service.addSocialMedia(this.userForm.value).subscribe(user => {
      this.isUserAdded = false;
      this.toastr.success('Social Media added successfully');
      this.dialogRef.close();
      this.userForm.reset();
      this.getJobCategoryList();
    }, error => {
      this.isUserAdded = false;
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error in adding Social Media.Please try again.');
    });
  }

  public edit(): void {
    this.isUserAdded = true;
    this.userForm.value['empId'] = this.employeeId;
    this.service.editSocialMedia(this.userForm.value, this.editId).subscribe(user => {
      this.isUserAdded = false;
      this.toastr.success('Social Media updated successfully');
      this.dialogRef.close();
      this.getJobCategoryList();
    }, error => {
      this.isUserAdded = false;
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error in updating Social Media.Please try again.');
    });
  }

  public editUser(id): void {
    this.isEdit = true;
    this.service.getSocialMediaById(this.employeeId, id).subscribe(user => {
      if (user && user.data) {
        this.userForm.patchValue(user.data);
        this.dialogRef = this.dialog.open(this.addUser, {
          width: '560px',
          height: 'auto'
        });
        this.editId = id;
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error getting Social Media');
    });
  }

  public deleteUserForm(id): void {
    this.deleteId = id;
    this.modalRef = this.modalService.open(this.deleteUser);
  }

  public deleteJobCategory(): void {

    this.service.deleteMultipleSocialMedia(this.employeeId, this.selectedCheckBoxList).subscribe(user => {
      if (user) {
        this.close();
        this.getJobCategoryList();
        this.toastr.success('Social Media deleted !');
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error in deleting Social Media');
    });
    this.onDeSelectAllClicked();

  }
  onDeSelectAllClicked() {
    this.selectedCheckBoxList = [];
    this.matCheckBoxes.forEach(
        item => {
            item.checked = false;
        }
    );
}
  onSelectAllClicked() {
    this.selectedCheckBoxList = [];
    this.matCheckBoxes.forEach(
        item => {
            this.selectedCheckBoxList.push(item.value);
            item.checked = true;
        }
    );
}
  onSelectAllUsers() {
    this.selectedUsers = [];
    const inputs: HTMLCollection = document.getElementsByClassName('user-input');
    Array.from(inputs).forEach(
        value => {
          this.selectedUsers.push(value);
          (value as HTMLInputElement).checked = true;
        }
    );
  }


  onCheckBoxChange($event: MatCheckboxChange, id: any) {
    if (!this.alreadySelected($event.source) && $event.checked) {
        this.selectedCheckBoxList.push($event.source.value);
    } else if (!$event.checked) {
        this.selectedCheckBoxList = this.selectedCheckBoxList.filter(
            value => {
                return value !== $event.source.value;
            }
        );
    }
}
alreadySelected(item: MatCheckbox): boolean {
    this.selectedCheckBoxList.forEach(
        value => {
            if (item === value) {
                return true;
            }
        }
    );
    return false;
}

  onDeleteSelected() {
    this.dialogRef = this.dialog.open(this.deleteUser, {
      width: '440px',
      height: 'auto'
  });
  }

  onUserChanged(user: HTMLInputElement) {
    if (user.checked) {
      this.selectedUsers.push(user);
      return;
    }
    this.selectedUsers = this.selectedUsers.filter(value => value !== user);
  }

  onModelChange(value: string) {
    console.log(value);
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  exportToCsv() {
    const csv = this.dataSource.filteredData.map(
        row => {
          return [
            row.name,
            row.type,
            row.link
          ].join(',') + '\n';
        }
    );
    csv.unshift(['name', 'type', 'link'].join(',') + '\n');
    const exportDate = new Date();
    // tslint:disable-next-line:max-line-length
    const exportFileName = `Csv-export-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}.csv`;
    saveAs(new Blob(csv, {type: 'text/csv;charset=utf-8'}), exportFileName);
  }

  saveAsPdf() {
    if (this.dataSource.filteredData.length <= 0) {
      this.toastr.error('No Data to export');
      return;
    }
    const body = [];
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      body.push(
          [
            this.dataSource.filteredData[i].name,
            this.dataSource.filteredData[i].type,
            this.dataSource.filteredData[i].link
          ]);
    }
    const doc = new jsPDF();
    autoTable(doc, {
      head: [['name', 'type', 'link']],
      body: body
    });
    const exportDate = new Date();
    const exportFileName = `Social-media-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}}`;
    doc.save(exportFileName);
  }
}

