<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
    <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="page-header-title">
            <span class="ng-star-inserted"></span
            ><span class="ng-star-inserted"
              ><h4 class="m-b-10 ng-star-inserted">{{ this.my }} Leave Entitlements and Usage Report</h4></span
            >
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</div>

<div class="page-body no-title">
  <div class="row">
    <div class="col-sm-12">
      <!-- <app-loading *ngIf="isLoading && !err" [isLoading]="isLoading && !err"></app-loading> -->
      <app-card [title]="'Time Line'">
        <div class="row form-group">
          <div class="col-md-12">
            <h2><b>Timeline</b></h2>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-md-2">
            <p><b>ُEmployee Name</b> {{ this.employeeName }}</p>
          </div>

          <div class="col-md-2">
            <p><b>Leave Type</b> {{ this.leaveType }}</p>
          </div>

          <div class="col-md-2">
            <p><b>Leave Period</b> {{ this.leavePeriodId }}</p>
          </div>
        </div>
      </app-card>
    </div>
  </div>
</div>

<div class="page-body no-title">
  <div class="row">
    <div class="col-sm-12">
      <!-- <app-loading *ngIf="isLoading && !err" [isLoading]="isLoading && !err"></app-loading> -->
      <app-card [title]="'Leave Time Line'">
        <div class="dt-responsive" *ngIf="dataSource">
          <div class="table-responsive">
            <mat-card-content>
              <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="startdate">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.startdate | date: "dd-MM-yyyy" }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="enddate">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>End Date </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.enddate | date: "dd-MM-yyyy" }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="total">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Total (Days)</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.noofdays }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="status">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.status }}
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedTimeLineColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedTimeLineColumns" class="cursor-pointer"> </mat-row>
              </mat-table>
              <mat-progress-bar mode="indeterminate" *ngIf="isLoadingLine"></mat-progress-bar>
              <mat-paginator [pageSizeOptions]="[20, 50]" showFirstLastButtons></mat-paginator>
            </mat-card-content>
          </div>
          <div class="error-msg" *ngIf="!isLoadingLine && err">{{ err }}</div>
        </div>
      </app-card>
    </div>
  </div>
</div>
