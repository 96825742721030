<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
    <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="page-header-title">
            <span class="ng-star-inserted"></span><span class="ng-star-inserted">
              <h4 class="m-b-10 ng-star-inserted">Holidays</h4>
            </span>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</div>

<div class="page-body no-title">
  <div class="row">
    <div class="col-sm-12">
      <div class="row top-tools">
        <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6">
          <span class="export-icon-btn csv"><i class="material-icons"
              (click)="exportToCsv()">get_app</i><span>CSV</span>
          </span>
          <span class="export-icon-btn pdf" (click)="saveAsPdf()"><i class="material-icons">get_app</i><span>PDF</span>
          </span>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
          <div class="relative-position search-filter-con">
            <!-- <span class="icon material-icons ng-tns-c114-0"> search </span> -->
            <!-- <mat-form-field> 
                    <input matInput #inputSearch type="search" [(ngModel)]="filterQuery" maxlength="30"
                    (ngModelChange)="onModelChange(filterQuery)"
                    class="form-control input-sm full-data-search" >
                          
                  </mat-form-field> -->
            <span class="mytooltip cus-left tooltip-effect-4" [hidden]="!write">
              <span class="tooltip-item">
                <button mat-mini-fab class="btn btn-primary  light add-rounded margin-button-05" (click)="addNewUser()">
                  <i class="fa fa-plus"></i>
                </button>
              </span>
              <span class="tooltip-content clearfix">
                <span class="tooltip-text">New</span>
              </span>
            </span>

            <span class="mytooltip cus-left tooltip-effect-4">
              <span class="tooltip-item">
                <button mat-mini-fab class="btn btn-primary light add-rounded margin-button-05" (click)="openfilter()">
                  <i class="fa fa-filter"></i>
                </button>
              </span>
              <span class="tooltip-content clearfix">
                <span class="tooltip-text">filter</span>
              </span>
            </span>
          </div>
        </div>

      </div>
      <!-- <app-loading *ngIf="isLoading && !err" [isLoading]="isLoading && !err"></app-loading> -->
      <app-card [title]="'Holiday'">
        <div class="dt-responsive" *ngIf="dataSource">
          <div class="table-responsive">
            <mat-card-content>
              <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="delete">
                  <mat-header-cell [hidden]="!delete" *matHeaderCellDef>
                    <a [matMenuTriggerFor]="menu" class="check-all">
                      <span class="material-icons">
                        more_horiz
                      </span>
                    </a>

                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="onSelectAllClicked()">Select All</button>
                      <button (click)="onDeSelectAllClicked()" *ngIf="selectedCheckBoxList.length > 0" mat-menu-item>
                        Deselect All
                      </button>
                      <button (click)="onDeleteSelectedAre()" *ngIf="selectedCheckBoxList.length > 0" mat-menu-item>
                        Delete Selected
                      </button>
                    </mat-menu>
                  </mat-header-cell>
                  <mat-cell [hidden]="!delete" *matCellDef="let row">
                    <mat-checkbox #matCheckbox (change)="onCheckBoxChange($event, row.id)"
                      value="{{row.id}}"></mat-checkbox>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="holiday">
                  <mat-header-cell mat-sort-header *matHeaderCellDef>Name</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.holiday}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="holidaydate">
                  <mat-header-cell mat-sort-header *matHeaderCellDef>Date</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.holidaydate | date : 'dd-MM-yyyy' }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="recurring">
                  <mat-header-cell mat-sort-header *matHeaderCellDef>Repeats Annually</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.recurring }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="countryName">
                  <mat-header-cell mat-sort-header *matHeaderCellDef>Country</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.countryName }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="locationName">
                  <mat-header-cell mat-sort-header *matHeaderCellDef>Locations</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.locationName }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="edit">
                  <mat-header-cell [hidden]="!update" *matHeaderCellDef>Edit</mat-header-cell>
                  <mat-cell [hidden]="!update" *matCellDef="let row">
                    <span class="mytooltip tooltip-effect-4">
                      <span class="tooltip-item"><i class="single-icon-click fa fa-pencil"
                          (click)="editUser(row.id,row.recurring)"></i></span>
                      <span class="tooltip-content clearfix">
                        <span class="tooltip-text">Edit</span>
                      </span>
                    </span>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columns;">
                </mat-row>
              </mat-table>
              <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
              <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
            </mat-card-content>

          </div>
          <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
        </div>
      </app-card>
    </div>
  </div>
</div>


<ng-template #addUser let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">
        <span *ngIf="!isEdit">Add</span>
        <span *ngIf="isEdit">Edit</span> Holiday
      </h4>
    </div>
    <div class="mat-modal-body">

      <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>

      <form class="form-material full-width-inputs" action="javascript:" [formGroup]="userForm" *ngIf="!isUserAdded">
        <div class="row form-group ">
          <div class="col-md-6">
            <mat-form-field class="form-element">
              <mat-label>Name</mat-label>
              <input required matInput type="text" class="form-control" id="holiday" maxlength="30"
                formControlName="holiday">
              <mat-error *ngIf="invalidHolidayName()" class="warning-color">Holiday name required!</mat-error>
            </mat-form-field>

          </div>
          <div class="col-md-6">
            <mat-form-field class="form-element">
              <input required (ngModelChange)="onDateChanged()" id="holidaydate" class="form-control"
                [matDatepicker]="picker1" formControlName="holidaydate" matInput>
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
              <mat-label *ngIf="!this.userForm.value.recurring" for="dob">Date</mat-label>
              <mat-label *ngIf="this.userForm.value.recurring" for="dob">Start Date</mat-label>
              <mat-error *ngIf="invalidHolidayDate()" class="warning-color"> Date required!</mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-6">
            <mat-form-field class="form-element">
              <mat-label>Country</mat-label>
              <mat-select required [(ngModel)]="countrySelected" disableOptionCentering
                (ngModelChange)="onCountryChange()" class="form-control" formControlName="operational_country_code">
                <mat-option [value]="''">--Select--</mat-option>
                <mat-option *ngFor="let c of countryList" [value]="c.iso">{{c.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-6">
            <mat-form-field style="height: min-content" class="form-element">
              <mat-label>Location</mat-label>

              <mat-select class="form-control" formControlName="locations" multiple disableOptionCentering
                (selectionChange)="change($event , 'locations')">
                <mat-select-trigger>
                  <mat-chip-list>
                    <mat-chip *ngFor="let topping of chipList.locations" [selectable]="true" [removable]="true"
                      (removed)="remove(topping.id , 'locations')">
                      {{ topping?.text }}
                      <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                  </mat-chip-list>
                </mat-select-trigger>
                <ng-container *ngFor="let j of locationList">
                  <mat-option value="{{j.id}}">{{ j.text }}</mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-6 form-check">
            <mat-checkbox #checkbox formControlName="recurring" id="es">Repeats Annually
              {{this.showDate}}</mat-checkbox>
          </div>

        </div>

        <div class="row">
          <div class="multi-btns col-sm-12 text-right">
            <button mat-raised-button class="btn btn-cancel btn-danger" (click)="close()"><i
                class="flaticon-cancel"></i>Cancel</button>
            <button mat-raised-button class="btn btn-save btn-primary" color="primary" *ngIf="!editForm"
              [disabled]="!userForm.valid" (click)="submitAddJobTitle()"><i class="flaticon-diskette"></i>Save</button>
          </div>



        </div>
      </form>
    </div>
  </div>

</ng-template>





<ng-template #areYouSure let-modal>
  <div mat-dialog-actions>

    <div class="mat-modal-body">

      <div class="text-center are-u">This holiday repeats annually.<br>Altering this record will change all holidays in
        the sequence.<br>Are you sure you want to continue?<br></div>
      <div class="row">
        <div class="col-sm-12 text-right">
          <button mat-raised-button class="btn-danger" (click)="close()"> No, Cancel</button>
          <button mat-raised-button class="btn-primary" color="primary" (click)="editHolidays()">Yes, Edit</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #areYouSureDelete let-modal>
  <div mat-dialog-actions>

    <div class="mat-modal-body">

      <div class="text-center are-u">These holiday(s) repeat annually. Deleting these records will remove all holiday(s)
        in the sequence. Are you sure you want to continue?<br></div>
      <div class="row">
        <div class="col-sm-12 text-right">
          <button mat-raised-button class="btn-danger" (click)="close()"> No, Cancel</button>
          <button mat-raised-button class="btn-primary" color="primary" (click)="deleteMultiple()"><i
              class="flaticon-trash"></i> Yes, Delete</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #deleteUser let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">Delete Holiday</h4>
    </div>
    <div class="mat-modal-body">
      <div class="text-left are-u">Are you sure ?</div>
      <div class="row">
        <div class="multi-btns col-sm-12 text-right">
          <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i>Cancel</button>
          <button mat-raised-button class="btn-primary" (click)="deleteMultiple()" color="primary"><i
              class="flaticon-trash"></i>Delete</button>
        </div>

      </div>
    </div>
  </div>
</ng-template>


<ng-template #filterHolidays let-modal>
  <div class="mat-modal-header">
    <h4 class="mat-title-modal">Filter Holidays</h4>
  </div>
  <div class="mat-modal-body">
    <form [formGroup]="filterForm" action="javascript:" class="form-material full-width-inputs">
      <div class="row form-group">

        <div class="col-md-6">
          <mat-form-field class="form-element">
            <input id="dob" class="form-control" [matDatepicker]="picker1" formControlName="dateFrom" matInput>
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
            <mat-label for="dob">From</mat-label>
          </mat-form-field>
        </div>

        <div class="col-md-6">
          <mat-form-field class="form-element">
            <input id="dob" class="form-control" [matDatepicker]="picker2" formControlName="dateTo" matInput>
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
            <mat-label for="dob">To</mat-label>
          </mat-form-field>
        </div>


        <div class="col-md-6">
          <mat-form-field class="form-element">
            <mat-label>Country</mat-label>
            <mat-select (ngModelChange)="onCountryChangeForFilter()" [(ngModel)]="countrySelectedFilter"
              disableOptionCentering formControlName="countryIso" class="form-control">
              <mat-option value="">--Select--</mat-option>
              <mat-option *ngFor="let c of countryList" [value]="c.iso">{{c.name}}</mat-option>
            </mat-select>

          </mat-form-field>
        </div>
        <div class="col-md-6">




          <mat-form-field class="form-element">
            <mat-label>Location</mat-label>

            <mat-select style="height: min-content" class="form-control" formControlName="locations" multiple
              disableOptionCentering (selectionChange)="change($event , 'locationss')">
              <mat-select-trigger>
                <mat-chip-list>
                  <mat-chip *ngFor="let topping of chipList.locationss" [selectable]="true" [removable]="true"
                    (removed)="remove(topping.id , 'locationss')">
                    {{ topping?.text }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip>
                </mat-chip-list>
              </mat-select-trigger>
              <br>
              <ng-container *ngFor="let j of locationList">
                <mat-option value="{{j.id}}">{{ j.text }}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>

        </div>
      </div>


      <div class="row">
        <div class="action-buttons multi-btns col-sm-12 text-right">
          <button (click)="OnFilterReset()" class="btn-danger reset-btn" mat-raised-button>
            <i class="flaticon-refresh"></i>Reset
          </button>
          <button (click)="close()" class="btn-danger" mat-raised-button>
            <i class="flaticon-cancel"></i>Cancel
          </button>
          <button (click)="Search()" class="btn btn-primary" color="primary" mat-raised-button type="submit">
            <i class="flaticon-magnifiying-glass"></i>Search
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>