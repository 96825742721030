import { Component, NgZone, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationService } from "src/app/core/services/notification.service";
import { Subject } from "rxjs";
import { DashboardService } from "src/app/dashboard/dashboard.service";
import { OnboardingService } from "../../onboarding.service";
import {takeUntil} from 'rxjs/operators';
import * as moment from "moment";
@Component({
    selector: 'app-edit-employee-tasks',
    templateUrl: './edit-tasks.component.html',
    styleUrls: ['./edit-tasks.component.scss']
})
export class EditEmployeeTasksComponent implements OnInit {
    public userForm: FormGroup;
    public employeeList : Array<any> = [];
    public employeeList2 : Array<any> = [];
    public eventsList : Array<any> = [];
    public taskTypeList : Array<any> = [];
    public filteredEmployees: Array<any> = [];
    public userFilterCtrl: FormControl = new FormControl();
    protected _onDestroy = new Subject<void>();
    public filteredEmployees2: Array<any> = [];
    public userFilterCtrl2: FormControl = new FormControl();
    protected _onDestroy2 = new Subject<void>();
    public eventId = "";
    public taskId = "";
    public isLoading : boolean = true;
    public complete : boolean = true;
    public validationError = {
        required: 'Required',
        number: 'Should be a valid number',
        email: 'Please provide a valid email'
    };
    public isEdit : boolean = true;
    constructor(private activatedRoute: ActivatedRoute,private ngZone: NgZone,private router: Router,private service: DashboardService, private onboardingService: OnboardingService, private fb: FormBuilder, private toastr: NotificationService) {
        this.createForm();
    }
    ngOnInit(): void {

        this.isEdit= true;
        this.activatedRoute.params.subscribe(params => {
            if (params.id && params.eventId) {
                this.isLoading = true;
               this.eventId = params.eventId;
               this.taskId = params.id;
        
               this.service.getEmployeeDetailList().subscribe(resp => {
                if (resp && resp.data) {
                    resp.data.map(e => {
                        this.employeeList.push({label: e.id+' '+e.name, value: e.id});
                        this.filteredEmployees = this.employeeList.slice();
                       
                    });
                    this.userFilterCtrl.valueChanges
                    .pipe(takeUntil(this._onDestroy))
                    .subscribe(() => {
                        this.filterUsers();
                    });

                    this.service.getEmployeesByEventsOnBooarding(this.eventId).subscribe(resp => {
                        if (resp && resp.data) {
                            this.employeeList2 = [] ;
                            this.filteredEmployees2 = [];
                            resp.data.map(e => {
                                
                                this.employeeList2.push({label: e.id + ' ' +e.firstLastName, value: e.id});
                               
                                          
                            });
                            this.filteredEmployees2 = this.employeeList2.slice();
                            this.userFilterCtrl2.valueChanges
                                        .pipe(takeUntil(this._onDestroy2))
                                        .subscribe(() => {
                                            this.filterUsers2();
                                        });
                            this.onboardingService.getOnboardingEmployeeTasksById(this.taskId,this.eventId).subscribe(resp => {
                                if (resp && resp.data) {
                            
                                resp.data.effictiveDate = moment(resp.data.effictiveDate).format('YYYY-MM-DD');
                                resp.data.dueDate= moment(resp.data.dueDate).format('YYYY-MM-DD');
                                this.userForm.patchValue(resp.data);
                                this.complete = resp.data.completed;
                                this.isLoading = false;
                                  
                                }
                                
                                
                            }, error => {
                                this.isLoading = false;
                                const err = error.error && error.error.msg;
                                this.toastr.error(err || 'Error in getting task informations !');
                            });
                         
                        }
                    }, error => {
                        const err = error.error && error.error.msg;
                        this.toastr.error(err || 'Error in getting employees by Occasions');
                    });
                   
                }
            });
            this.onboardingService.getOnboardingEventsList(this.userForm.value).subscribe(resp => {
                if (resp && resp.data) {
                    resp.data.map(e => {
                        this.eventsList.push({label: e.name, value: e.id});
                    });
                }
            });
            this.onboardingService.getOnboardingTasksList().subscribe(resp => {
                if (resp && resp.data) {
                    this.taskTypeList = resp.data;
                }
            });
        }
        });
       
       
    }

    protected filterUsers() {
        if (!this.employeeList) {
            return;
        }
        // get the search keyword
        let search = this.userFilterCtrl.value;
        if (!search) {
            this.filteredEmployees = this.employeeList.slice();
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the search
        this.filteredEmployees =
            this.employeeList.filter(emp => emp.label.toLowerCase().indexOf(search) > -1);
    }

    protected filterUsers2() {
        if (!this.employeeList2) {
            return;
        }
        // get the search keyword
        let search = this.userFilterCtrl2.value;
        if (!search) {
            this.filteredEmployees2 = this.employeeList2.slice();
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the search
        this.filteredEmployees2 =
            this.employeeList2.filter(emp => emp.label.toLowerCase().indexOf(search) > -1);
    }
    
    public createForm(): void {
        this.userForm = this.fb.group({
            id: '' ,
            name: '' ,
            notifiedBefore: ['', [Validators.pattern('^[0-9]?[0-9]$')]],
            notifyEmail : '',
            notifiedBeforePeriod : 'days',
            description: '',
            comment: '',
            completedDate: '',
            creationType: 'false',
            dueDate: '',
            effictiveDate: '',
            eventId:'',
            ownerId: '',
            participantId:'',
            status: '',
            taskType: '',
            priority: 'low' 
        });
    }

    getEmployeesByEvents(event) : void 
    {
        this.employeeList2 = [] ;
        this.filteredEmployees2 = [];
        this.filteredEmployees2.push({label: 'Loading ..', value: null});
        this.userForm.patchValue({
            participantId : ''
        });
        if(event === null || event === undefined || event === '')
        {
            this.userForm.patchValue({
                participantId : ''
            });
            this.employeeList2 = [] ;
            this.filteredEmployees2 = [];
          
            return ;
        }
        this.service.getEmployeesByEventsOnBooarding(event).subscribe(resp => {
            if (resp && resp.data) {
                this.employeeList2 = [] ;
                this.filteredEmployees2 = [];
                resp.data.map(e => {
                   
                    this.employeeList2.push({label: e.id + ' ' +e.firstLastName, value: e.id});
                 
                });
                this.filteredEmployees2 = this.employeeList2.slice();
                this.userFilterCtrl2.valueChanges
                            .pipe(takeUntil(this._onDestroy2))
                            .subscribe(() => {
                                this.filterUsers2();
                            });
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error in getting employees by events');
        });

    }

    selectTaskType(event) : void {
        if(event === null || event === undefined || event === '')
        {
            this.userForm.patchValue({
                name: '',
                notifiedBefore:'',
                notifyEmail: '',
                notifiedBeforePeriod:'days',
                description: ''
            });
            return ;
        }
        const val = JSON.parse(event);
        this.userForm.patchValue({
            name: val && val.name,
            notifiedBefore: val && val.notifiedBefore,
            notifyEmail: val && val.notifyEmail,
            notifiedBeforePeriod: val && val.notifiedBeforePeriod,
            description: val && val.description
        });
    }

    clickNew() : void 
    {
        this.userForm.patchValue({
            name: '',
            notifiedBefore:'',
            notifyEmail: '',
            notifiedBeforePeriod:'days',
            description: ''
        });
        return ;
    }

    clickSelect() : void 
    {
        
        this.userForm.patchValue({
            taskType : ''
        });
    }


    saveEmployeeTask() : void 
    {
        this.onboardingService.editOnboardingEmployeeTask(this.userForm.value,this.taskId,this.eventId).subscribe(user => {
            this.toastr.success('Employee Task Edited successfully');
        }, error => {
            this.toastr.error('Error in editing employee task.Please try again.');
        });
    }

    completeTask() : void 
    {
        this.onboardingService.completeEmployeeTask(this.taskId,this.eventId).subscribe(user => {
            if(user && user.data)
            {
                this.complete = user.data.completed;
                this.userForm.patchValue({
                    completedDate :  user.data.completedDate
                });
                this.toastr.success('successfully updated');
            }
        }, error => {
            this.toastr.error('Error in updating employee task.Please try again.');
        });
    }

    inCompleteTask() : void 
    {
        this.onboardingService.unCompleteEmployeeTask(this.taskId,this.eventId).subscribe(user => {
            if(user && user.data)
            {
                this.complete = user.data.completed;
                this.userForm.patchValue({
                    completedDate : ''
                });
                this.toastr.success('successfully updated');
            }
            
        }, error => {
            this.toastr.error('Error in updating employee task.Please try again.');
        });
    }

    openEdit() : void 
    {
        this.isEdit = false;
    }

    cancel() : void 
    {
        this.ngZone.run(() => {
            void this.router.navigate(['/onboarding/employee-tasks']);
          });
    }

}