import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AdminComponent } from "./layout/admin/admin.component";
import { ChangePasswordComponent } from "./core/change-password/change-password.component";
import { LoginComponent } from "./core/login/login.component";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { HrmAdminComponent } from "./dashboard/admin/admin.component";
import { PimComponent } from "./dashboard/pim/pim.component";
import { MyInfoComponent } from "./dashboard/my-info/my-info.component";
import { LeaveComponent } from "./dashboard/leave/leave.component";
import { MyLeaveComponent } from "./dashboard/leave/my-leave/my-leave.component";
import { DisciplineComponent } from "./dashboard/discipline/discipline.component";

import { MaintainenceComponent } from "./dashboard/maintainence/maintainence.component";
import { UserComponent } from "./dashboard/admin/user-management/user/user.component";
import { UserRoleComponent } from "./dashboard/admin/user-management/user-role/user-role.component";
import { EmployeeListComponent } from "./dashboard/pim/employee-list/employee-list.component";
import { AddEmployeeComponent } from "./dashboard/pim/add-employee/add-employee.component";
import { JobTitleComponent } from "./dashboard/admin/job/job-title/job-title.component";
import { JobCategoryComponent } from "./dashboard/admin/job/job-category/job-category.component";
import { PayGradeComponent } from "./dashboard/admin/job/pay-grade/pay-grade.component";
import { SalaryComponent } from "./dashboard/admin/job/salary/salary.component";
import { WorkShiftComponent } from "./dashboard/admin/job/work-shift/work-shift.component";
import { EmployeeStatusComponent } from "./dashboard/admin/job/employee-status/employee-status.component";
import { ReportingMethodComponent } from "./dashboard/pim/configuration/reporting-method/reporting-method.component";
import { TerminationReasonComponent } from "./dashboard/pim/configuration/termination-reason/termination-reason.component";
import { DocumentTemplateComponent } from "./dashboard/pim/configuration/document-template/document-template.component";
import { PayrollComponent } from "./dashboard/payroll/payroll/payroll.component";
import { AssignLeaveComponent } from "./dashboard/leave/assign-leave/assign-leave.component";
import { ApplyLeaveComponent } from "./dashboard/leave/apply-leave/apply-leave.component";
import { GeneralInformationComponent } from "./dashboard/admin/organization/general-information/general-information.component";
import { LeavePeriodComponent } from "./dashboard/leave/configure/leave-period/leave-period.component";
import { HolidaysComponent } from "./dashboard/admin/organization/holidays/holidays.component";
import { LeaveListComponent } from "./dashboard/leave/leave-list/leave-list.component";
import { EntitlementListComponent } from "./dashboard/leave/entitlement/entitlement-list/entitlement-list.component";
import { AddEntitlementComponent } from "./dashboard/leave/entitlement/add-entitlement/add-entitlement.component";
import { StructureComponent } from "./dashboard/admin/organization/structure/structure.component";
import { CostCenterComponent } from "./dashboard/admin/organization/cost-center/cost-center.component";
import { SkillsComponent } from "./dashboard/admin/qualification/skills/skills.component";
import { LicenseComponent } from "./dashboard/admin/qualification/license/license.component";
import { EducationComponent } from "./dashboard/admin/qualification/education/education.component";
import { MembershipComponent } from "./dashboard/admin/qualification/membership/membership.component";
import { LocationsComponent } from "./dashboard/admin/organization/locations/locations.component";
import { RegionComponent } from "./dashboard/admin/organization/region/region.component";
import { LeaveTypeComponent } from "./dashboard/leave/configure/leave-type/leave-type.component";
import { BftComponent } from "./dashboard/leave/configure/bft/bft.component";
import { WorkingWeekendComponent } from "./dashboard/leave/configure/working-weekend/working-weekend.component";
import { LeaveCalendarComponent } from "./dashboard/leave/configure/leave-calendar/leave-calendar.component";
import { LeaveCComponent } from "./dashboard/leave/leave-c/leave-c.component";
import { BulkAssignComponent } from "./dashboard/leave/bulk-assign/bulk-assign.component";
import { LeaveUsageComponent } from "./dashboard/leave/reports/leave-usage/leave-usage.component";
import { BradfordThresholdComponent } from "./dashboard/leave/reports/bradford-threshold/bradford-threshold.component";
import { WorkWeekLeaveComponent } from "./dashboard/admin/organization/work-week/work-week.component";
import { MyLeaveUsageComponent } from "./dashboard/leave/reports/my-leave-usage/my-leave-usage.component";
import { AuthGuard } from "./core/services/auth.guard";
import { BulkUpdateComponent } from "./dashboard/pim/manage-data/bulk-update/bulk-update.component";
import { OptionalFieldsComponent } from "./dashboard/pim/configuration/optional-fields/optional-fields.component";
import { CustomeFieldsComponent } from "./dashboard/pim/configuration/custome-fields/custome-fields.component";
import { AddEmployeeWizardComponent } from "./dashboard/pim/configuration/add-employee-wizard/add-employee-wizard.component";
import { ReportsComponent } from "./dashboard/pim/reports/reports.component";
import { EmployeeRecordsComponent } from "./dashboard/maintainence/purge-records/employee-records/employee-records.component";
import { CandidateRecordsComponent } from "./dashboard/maintainence/purge-records/candidate-records/candidate-records.component";
import { ViewReportComponent } from "./dashboard/pim/reports/view-report/view-report.component";
import { AddReportComponent } from "./dashboard/pim/reports/add-report/add-report.component";
import { AuditTrailComponent } from "./dashboard/admin/audit-trail/audit-trail.component";
import { LeavePercentageComponent } from "./dashboard/leave/reports/leave-percentage/leave-percentage.component";
import { CompetencyListComponent } from "./dashboard/admin/competency/competency-list/competency-list.component";
import { EditEmployeeComponent } from "./dashboard/pim/edit-employee/edit-employee.component";
import { AddDocumentTemplateComponent } from "./dashboard/pim/configuration/add-document-template/add-document-template.component";
import { EditCustomeFieldsComponent } from "./dashboard/pim/configuration/custome-fields/edit-custom-field/edit-custome-fields.component";
import { DisciplineAdminComponent } from "./dashboard/discipline-admin/discipline-admin.component";
import { DisciplinaryCasesComponent } from "./dashboard/discipline-admin/disciplinary-cases/disciplinary-cases.component";
import { MyActionsComponent } from "./dashboard/discipline-admin/my-actions/my-actions.component";
import { TrainingComponent } from "./dashboard/training/training.component";
import { CoursesComponent } from "./dashboard/training/courses/courses.component";
import { ParticipatingSessionReportComponent } from "./dashboard/training/participating-session-report/participating-session-report.component";
import { ViewCourseComponent } from "./dashboard/training/view-course/view-course.component";
import { MyCoursesComponent } from "./dashboard/training/my-courses/my-courses.component";
import { ViewMyCourseComponent } from "./dashboard/training/view-my-course/view-my-course.component";
import { LeaveDaysComponent } from "./dashboard/leave/leave-list/leave-days/leave-days.component";
import { LeavePeriodsComponent } from "./dashboard/leave/reports/leave-usage/leave-period/leave-period.component";
import { MyTrainingCoursesComponent } from "./dashboard/training/my-training-courses/my-training-courses.component";
import { ParticipatingCoursesComponent } from "./dashboard/training/courses/participating-courses/participating-courses.component";
import { LeaveNotificationComponent } from "./dashboard/leave/configure/leave-notification/leave-notification.component";
import { AddAssetsComponent } from "./dashboard/more/assets/add-assets/add-assets.component";
import { AssetsComponent } from "./dashboard/more/assets/assets/assets.component";
import { BrandsComponent } from "./dashboard/more/assets/brands/brands.component";
import { CategoriesComponent } from "./dashboard/more/assets/categories/categories.component";
import { VendorsComponent } from "./dashboard/more/assets/vendors/vendors.component";
import { EmployeeRecordComponent } from "./dashboard/time/attendance/employee-record/employee-record.component";
import { ConfigurationComponent } from "./dashboard/time/attendance/configuration/configuration.component";
import { DataUploadComponent } from "./dashboard/time/attendance/data-upload/data-upload.component";
import { AttendanceReportComponent } from "./dashboard/time/attendance/attendance-report/attendance-report.component";
import { MyRecordComponent } from "./dashboard/time/attendance/my-record/my-record.component";
import { PunchInComponent } from "./dashboard/time/attendance/punch-in/punch-in.component";
import { AttendanceSummaryComponent } from "./dashboard/time/reports/attendance-summary/attendance-summary.component";
import { MonthlyAttendanceComponent } from "./dashboard/time/reports/monthly-attendance/monthly-attendance.component";
import { MyMonthlyAttendanceComponent } from "./dashboard/time/reports/my-monthly-attendance/my-monthly-attendance.component";
import { PayslipComponent } from "./dashboard/payroll/payslip/payslip.component";
import { PayrollReportComponent } from "./dashboard/payroll/payroll-report/payroll-report.component";
import { AttendancePolicyComponent } from "./dashboard/payroll/attendance-policy/attendance-policy.component";
import { ControlAdminComponent } from "./layout/control-admin/control-admin.component";
import { ViewClientsComponent } from "./dashboard/control/view-clients/view-clients.component";
import { AddClientComponent } from "./dashboard/control/add-client/add-client.component";
import { NewCustomerComponent } from "./dashboard/control/new-customer/new-customer.component";
const routes: Routes = [
  { path: "login", component: LoginComponent },
  { path: "reset", component: ChangePasswordComponent },
  { path: "change-password", component: ChangePasswordComponent },
  { path: "new-customer", component: NewCustomerComponent },
  {
    path: "control",
    component: ControlAdminComponent,
    children: [
      { path: "", redirectTo: "list", pathMatch: "full" },
      { path: "list", component: ViewClientsComponent, pathMatch: "full", canActivate: [AuthGuard] },
      { path: "add", component: AddClientComponent, pathMatch: "full", canActivate: [AuthGuard] },
    ],
  },
  {
    path: "",
    component: AdminComponent,
    children: [
      { path: "", redirectTo: "admin", pathMatch: "full" },
      {
        path: "admin",
        component: HrmAdminComponent,
        canActivate: [AuthGuard],
        children: [
          { path: "", redirectTo: "user-management", pathMatch: "full" },
          {
            path: "user-management",
            children: [
              { path: "", redirectTo: "users", pathMatch: "full" },
              { path: "users", component: UserComponent },
              { path: "user-roles", component: UserRoleComponent },
            ],
          },
          {
            path: "job",
            children: [
              { path: "", redirectTo: "job-titles", pathMatch: "full" },
              { path: "job-titles", component: JobTitleComponent },
              { path: "job-categories", component: JobCategoryComponent },
              // { path: "pay-grades", component: PayGradeComponent },
              // { path: "salary-component", component: SalaryComponent },
              { path: "work-shifts", component: WorkShiftComponent },
              { path: "employment-status", component: EmployeeStatusComponent },
              { path: "termination-reasons", component: TerminationReasonComponent },
              { path: "reporting-methods", component: ReportingMethodComponent },
              { path: "document-template", component: DocumentTemplateComponent },
              { path: "document-template/add", component: AddDocumentTemplateComponent },
              { path: "document-template/add/:id", component: AddDocumentTemplateComponent },
              { path: "competency-list", component: CompetencyListComponent },
            ],
          },
          // {
          //     path: 'competency',
          //     loadChildren: './dashboard/admin/competency/admin-competency.module#AdminCompetencyModule'
          // },
          // {path: 'nationalities', component: NationalitiesComponent},
          {
            path: "announcement",
            loadChildren: () =>
              import("./dashboard/admin/announcement/announcement.module").then((m) => m.AnnouncementModule),
          },
          {
            path: "configurations",
            loadChildren: () =>
              import("./dashboard/admin/configurations/configurations.module").then((m) => m.ConfigurationsModule),
          },
          // {path: 'company', component: CompanyComponent},
          {
            path: "organization",
            children: [
              { path: "", redirectTo: "general-information", pathMatch: "full" },
              { path: "general-information", component: GeneralInformationComponent },
              { path: "structure", component: StructureComponent },
              { path: "cost-centers", component: CostCenterComponent },
              { path: "locations", component: LocationsComponent },
              { path: "holidays", component: HolidaysComponent },
              { path: "region", component: RegionComponent },
              { path: "work-week", component: WorkWeekLeaveComponent },
              // {path: 'eeo-filling', component: EeoFillingComponent}
            ],
          },
          {
            path: "qualification",
            children: [
              { path: "", redirectTo: "education", pathMatch: "full" },
              { path: "education", component: EducationComponent },
              { path: "skills", component: SkillsComponent },
              { path: "membership", component: MembershipComponent },
              { path: "licenses", component: LicenseComponent },
              // {path: 'language', component: LanguagesComponent}
            ],
          },
          { path: "audit-trial", component: AuditTrailComponent },
        ],
      },
      {
        path: "pim",
        component: PimComponent,
        canActivate: [AuthGuard],
        children: [
          { path: "", redirectTo: "employees", pathMatch: "full" },
          {
            path: "configuration",
            children: [
              { path: "", redirectTo: "reporting-method", pathMatch: "full" },
              { path: "optional-fields", component: OptionalFieldsComponent },
              {
                path: "custom-fields",
                children: [
                  { path: "", component: CustomeFieldsComponent },
                  { path: ":id", component: EditCustomeFieldsComponent },
                ],
              },

              // {path: 'reporting-methods', component: ReportingMethodComponent},
              { path: "add-employee-wizard", component: AddEmployeeWizardComponent },
              // {
              //     path: 'directory-configurations',
              //     component: DirectoryConfigurationComponent,
              // }
              // {path: 'termination-reasons', component: TerminationReasonComponent},
              // {path: 'document-template', component: DocumentTemplateComponent}
            ],
          },
          { path: "employees", component: EmployeeListComponent },
          { path: "employees/:id", component: EditEmployeeComponent },
          { path: "reports", component: ReportsComponent },
          { path: "reports/add/:reportId", component: AddReportComponent },
          { path: "reports/:id/:reportName", component: ViewReportComponent },
          { path: "add-employee", component: AddEmployeeComponent },
          { path: "bulk-update", component: BulkUpdateComponent },
          // {
          //     path: 'manage-data', children: [
          //         {path: '', redirectTo: 'bulk-update', pathMatch: 'full'},
          //         {path: 'bulk-update', component: BulkUpdateComponent}
          //         // {path: 'employee-data-export', component: EmployeeDataExportComponent}
          //     ]
          // }
        ],
      },

      // {
      //     path: 'discipline', component: DisciplineComponent, canActivate: [AuthGuard], children: [
      //         {path: '', redirectTo: 'disciplinary-cases', pathMatch: 'full'},
      //         {path: 'disciplinary-cases', component: DisciplinaryCasesComponent},
      //         {path: 'disciplinary-document', component: DiciplineDocumentComponent},
      //         {path: 'my-actions', component: MyActionsComponent}
      //     ]
      // },
      {
        path: "leave",
        component: LeaveComponent,
        canActivate: [AuthGuard],
        children: [
          { path: "", redirectTo: "employee-leave", pathMatch: "full" },
          { path: "assign-leave", component: AssignLeaveComponent },
          { path: "bulk-assign", component: BulkAssignComponent },
          { path: "leave-calendar", component: LeaveCalendarComponent },
          { path: "apply-leave", component: ApplyLeaveComponent },
          {
            path: "leave-list",
            children: [
              { path: "", component: LeaveListComponent },
              { path: ":empId/:id", component: LeaveDaysComponent },
            ],
          },
          {
            path: "my-leave",
            children: [
              { path: "", component: MyLeaveComponent },
              { path: ":empId/:id", component: LeaveDaysComponent },
            ],
          },
          {
            path: "entitlement",
            children: [
              { path: "", redirectTo: "add-entitlement", pathMatch: "full" },
              { path: "add-entitlement", component: AddEntitlementComponent },
              { path: "entitlement-list", component: EntitlementListComponent },
              { path: "my-entitlements", component: EntitlementListComponent, data: { entitlement: "my" } },
            ],
          },
          {
            path: "configure",
            children: [
              { path: "", redirectTo: "holiday", pathMatch: "full" },
              { path: "leave-period", component: LeavePeriodComponent },
              { path: "leave-type", component: LeaveTypeComponent },
              { path: "calendar-config", component: LeaveCComponent },
              { path: "bft", component: BftComponent },
              { path: "working-weekend", component: WorkingWeekendComponent },
              { path: "notifications", component: LeaveNotificationComponent },
            ],
          },
          {
            path: "reports",
            children: [
              { path: "", redirectTo: "holiday", pathMatch: "full" },
              {
                path: "my-leave-usage",
                children: [
                  { path: "", component: MyLeaveUsageComponent },
                  { path: ":empId/:typeId/:periodId", component: LeavePeriodsComponent },
                ],
              },
              {
                path: "leave-usage",
                children: [
                  { path: "", component: LeaveUsageComponent },
                  { path: ":empId/:id", component: LeavePeriodsComponent },
                ],
              },
              { path: "leave-percentage", component: LeavePercentageComponent },
              { path: "bradfactor-threshold", component: BradfordThresholdComponent },
              { path: "bft", component: BftComponent },
            ],
          },
          // { path: 'entitlement', redirectTo: '', children: [
          //   { path: '', redirectTo: 'entitlement-list', pathMatch: 'full'},
          //   { path: 'entitlement-list', component: EntitlementListComponent },
          //   { path: 'add-entitlement', component: AddEntitlementComponent }
          // ]}
        ],
      },
      {
        path: "payroll",
        canActivate: [AuthGuard],
        children: [
          { path: "payslip", component: PayslipComponent },
          { path: "payrollReport", component: PayrollReportComponent },
          {
            path: "configuration",
            children: [
              { path: "payroll", component: PayrollComponent },
              { path: "pay-grades", component: PayGradeComponent },
              { path: "salary-component", component: SalaryComponent },
              { path: "attendance-policy", component: AttendancePolicyComponent },
            ],
          },
        ],
      },
      {
        path: "discipline",
        component: DisciplineAdminComponent,
        children: [
          {
            path: "disciplinary-cases",
            component: DisciplinaryCasesComponent,
          },
          {
            path: "disciplinary-cases/:id",
            component: DisciplinaryCasesComponent,
          },
          {
            path: "my-actions/:id",
            component: MyActionsComponent,
          },
          {
            path: "my-actions",
            component: MyActionsComponent,
          },
          {
            path: "configurations",
            children: [
              {
                path: "document-template",
                component: DocumentTemplateComponent,
              },
            ],
          },
        ],
      },
      {
        path: "recruitment",
        canActivate: [AuthGuard],
        loadChildren: () => import("./dashboard/recruitment/recruitment.module").then((m) => m.RecruitmentModule),
      },
      { path: "info", component: MyInfoComponent },
      {
        path: "time",
        loadChildren: () => import("./dashboard/time/time.module").then((m) => m.TimeModule),
      },
      {
        path: "training",
        component: TrainingComponent,
        children: [
          {
            path: "courses",
            component: CoursesComponent,
          },
          {
            path: "my-courses",
            component: MyCoursesComponent,
          },
          {
            path: "training-courses",
            component: MyTrainingCoursesComponent,
          },
          {
            path: "courses/:id",
            component: ViewCourseComponent,
          },
          {
            path: "my-courses/:id",
            component: ViewMyCourseComponent,
          },
          {
            path: "courses/viewParticipating/:id",
            component: ParticipatingCoursesComponent,
          },
          {
            path: "training-courses/:id",
            component: ViewMyCourseComponent,
          },
          {
            path: "training-report",
            component: ParticipatingSessionReportComponent,
          },
        ],
      },
      // {
      //     path: 'discipline',
      //     loadChildren: () => import('./dashboard/discipline-admin/discipline-admin.module').then(m => m.DisciplineModule)
      // },
      // {
      //     path: 'training',
      //     loadChildren: () => import('./dashboard/training/training.module').then(m => m.TrainingModule)
      // },
      {
        path: "performance",
        loadChildren: () => import("./dashboard/performance/performance.module").then((m) => m.PerformanceModule),
      },
      {
        path: "succession-and-development",
        loadChildren: () =>
          import("./dashboard/succession-and-development/succession-and-development.module").then(
            (m) => m.SuccessionAndDevelopmentModule
          ),
      },
      {
        path: "onboarding",
        loadChildren: () => import("./dashboard/onboarding/onboarding.module").then((m) => m.OnboardingModule),
      },
      // { path: 'change-password', component: ChangePasswordComponent },
      { path: "discipline", component: DisciplineComponent },
      // {path: 'training', component: TrainingComponent},
      // {path: 'performance', component: PerformanceComponent},
      // {path: 'onboarding', component: OnboardingComponent},
      {
        path: "expense",
        loadChildren: () => import("./dashboard/expense/expense.module").then((m) => m.ExpenseModule),
      },
      // {path: 'report', component: ReportCatalogComponent},
      {
        path: "assets",
        children: [
          {
            path: "assets",
            component: AssetsComponent,
          },
          {
            path: "assets/add",
            component: AddAssetsComponent,
          },
          {
            path: "assets/update/:updateId",
            component: AddAssetsComponent,
          },
          {
            path: "brands",
            component: BrandsComponent,
          },
          {
            path: "categories",
            component: CategoriesComponent,
          },
          {
            path: "vendors",
            component: VendorsComponent,
          },
        ],
      },
      {
        path: "attendance",
        children: [
          {
            path: "employee-records",
            component: EmployeeRecordComponent,
          },
          {
            path: "configuration",
            component: ConfigurationComponent,
          },
          {
            path: "punch-in",
            component: PunchInComponent,
          },
          {
            path: "data-upload",
            component: DataUploadComponent,
          },
          {
            path: "my-records",
            component: MyRecordComponent,
          },
          {
            path: "reports",
            children: [
              {
                path: "attendance-summary",
                component: AttendanceSummaryComponent,
              },
              {
                path: "monthly-attendance",
                component: MonthlyAttendanceComponent,
              },
              {
                path: "my-monthly-attendance",
                component: MyMonthlyAttendanceComponent,
              },
              {
                path: "export",
                component: AttendanceReportComponent,
              },
            ],
          },
        ],
      },
      {
        path: "more",
        loadChildren: () => import("./dashboard/more/more.module").then((m) => m.MoreModule),
      },
      {
        path: "maintenance",
        component: MaintainenceComponent,
        canActivate: [AuthGuard],
        children: [
          { path: "", redirectTo: "maintenance", pathMatch: "full" },
          {
            path: "maintenance",
            children: [
              { path: "", redirectTo: "purge_employee_records", pathMatch: "full" },
              { path: "purge_employee_records", component: EmployeeRecordsComponent },
              { path: "purge_candidate_records", component: CandidateRecordsComponent },
            ],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
  providers: [AuthGuard, { provide: LocationStrategy, useClass: HashLocationStrategy }],
})
export class AppRoutingModule {}
