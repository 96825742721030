<div id="header-page" class="ng-star-inserted header-page">
    <div class="page-header ng-star-inserted">
        <div class="page-block ng-star-inserted">
            <div class="row align-items-center">
                <div class="col-md-8">
                    <div class="page-header-title">
                        <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4
                            class="m-b-10 ng-star-inserted">{{name}}</h4></span>
                    </div>
                </div>
                <div class="col-md-4"></div>
            </div>
        </div>
    </div>
</div>

<div class="page-body no-title">

    <mat-card class="example-card">
        <app-loading *ngIf="isLoading" [isLoading]="isLoading"></app-loading>
        <mat-card-content>
            <mat-tab-group *ngIf="!isLoading" mat-align-tabs="center">
                <mat-tab  label="Trainers">
                    <div style="margin-top: 33px; width: 99%; margin-left: 1px" class="row">
                        <div class="col-sm-12">
                            <div *ngIf="dataSource">
                                <div >
                                    <mat-card-content>
                                        <mat-table [dataSource]="dataSource" matSort>
                                            <ng-container matColumnDef="id">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Trainer Id
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row">
                                                    <span > {{ row.participantId }} </span>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="trainer">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Trainer
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row">
                                                   <span class="cursor-pointer" (click)="viewAction(row)"> {{ row.participantName }} </span>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="sessionStatus">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Session Status
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row">
                                                    <mat-checkbox [checked]="row.sessionStatus" disabled></mat-checkbox>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="rating">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Rating
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row">
                                                    <div *ngIf="row.rating == 'poor'">Poor</div>
                                                    <div *ngIf="row.rating == 'fair'">Fair</div>
                                                    <div *ngIf="row.rating == 'good'">Good</div>
                                                    <div *ngIf="row.rating == 'veryGood'">VeryGood</div>
                                                    <div *ngIf="row.rating == 'excellent'">Excellent</div>
                                                </mat-cell>
                                            </ng-container>

                                            <ng-container matColumnDef="comment">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Comment
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row">
                                                    {{row?.comment}}
                                                </mat-cell>
                                            </ng-container>

                                            <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                                            <mat-row *matRowDef="let row; columns: columns;">
                                            </mat-row>
                                        </mat-table>
                                        <mat-progress-bar mode="indeterminate"
                                                          *ngIf="isLoading"></mat-progress-bar>
                                        <mat-paginator
                                            #TableOnePaginator="matPaginator"
                                            [pageSize]="5"
                                            [pageSizeOptions]="[10, 20]"
                                        ></mat-paginator>

                                    </mat-card-content>
                                </div>
                                <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Employees">
                    <div style="margin-top: 33px; width: 99%; margin-left: 1px" class="row">
                        <div class="col-sm-12">
                            <div *ngIf="dataSource1">
                                <div >
                                    <mat-card-content>
                                        <mat-table [dataSource]="dataSource1" matSort>
                                            <ng-container matColumnDef="id">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Employee Id
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row">
                                                    <span > {{ row.participantId }} </span>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="participants">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Employee
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row">
                                                    <span class="cursor-pointer" (click)="viewAction1(row)"> {{ row.participantName }} </span>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="sessionStatus">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Session Status
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row">
                                                    <mat-checkbox [checked]="row.sessionStatus" disabled></mat-checkbox>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="rating">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Rating
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row">
                                                    <div *ngIf="row.rating == 'poor'">Poor</div>
                                                    <div *ngIf="row.rating == 'fair'">Fair</div>
                                                    <div *ngIf="row.rating == 'good'">Good</div>
                                                    <div *ngIf="row.rating == 'veryGood'">VeryGood</div>
                                                    <div *ngIf="row.rating == 'excellent'">Excellent</div>
                                                </mat-cell>
                                            </ng-container>

                                            <ng-container matColumnDef="comment">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Comment
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row">
                                                    {{row?.comment}}
                                                </mat-cell>
                                            </ng-container>
                                            <mat-header-row *matHeaderRowDef="columns1"></mat-header-row>
                                            <mat-row *matRowDef="let row; columns: columns1;">
                                            </mat-row>
                                        </mat-table>
                                        <mat-progress-bar mode="indeterminate"
                                                          *ngIf="isLoading"></mat-progress-bar>
                                        <mat-paginator
                                            #TableOnePaginator1="matPaginator"
                                            [pageSize]="5"
                                            [pageSizeOptions]="[10, 20]"
                                        ></mat-paginator>
                                    </mat-card-content>
                                </div>
                                <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-card-content>
    </mat-card>
</div>


<ng-template #addCourse let-modal>
    <!-- <div class="card my-0"> -->
    <!-- <div class="header"> -->
    <div class="mat-modal-header">
        <!-- <h4 class="card-title card-title-modal"> -->
        <h4 class="mat-title-modal">
            <span>Session Rating</span>
        </h4>
    </div>
    <!-- <div class="card-content"> -->
    <div class="mat-modal-body">
        <app-loading *ngIf="isTrainerAdded" [isLoading]="isTrainerAdded"></app-loading>
        <!-- <form class="form-material mt-4" action="javascript:" [formGroup]="userForm" *ngIf="!isUserAdded"> -->
        <form class="form-material full-width-inputs" action="javascript:" [formGroup]="trainerForm"
              *ngIf="!isTrainerAdded">
            <div class="row form-group">
                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <mat-label>Trainer</mat-label>
                        <input class="form-control" formControlName="trainerName" id="trainerName"
                               matInput maxlength="40" readonly type="text">
                    </mat-form-field>
                </div>
                <div class="col-sm-6">
<!--                    <mat-form-field class="form-element">-->
<!--                        <mat-label></mat-label>-->
                        <mat-checkbox style="padding-top: 25px" [disabled]="isSessionStatus" formControlName="sessionStatus" >Session Status</mat-checkbox>
<!--                    </mat-form-field>-->
                </div>
                <div class="col-sm-6">

                    <mat-form-field class="form-element">
                        <mat-label>Rating </mat-label>
                        <mat-select
                                [disabled]="isSessionStatus"
                                class="form-control"
                                formControlName="rating">
                            <mat-option value="poor">Poor</mat-option>
                            <mat-option value="fair">Fair</mat-option>
                            <mat-option value="good">Good</mat-option>
                            <mat-option value="veryGood">VeryGood</mat-option>
                            <mat-option value="excellent">Excellent</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-sm-6">

                </div>
                <div class="col-sm-12">
                    <mat-form-field class="form-element">
                        <mat-label>Comment </mat-label>
                        <textarea [readonly]="isSessionStatus" class="form-control" matInput placeholder="Comment"
                                  formControlName="comment"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="action-buttons multi-btns col-sm-12 text-right">
                <button mat-raised-button class="btn-danger" (click)="close1()">
                    <i class="flaticon-cancel"></i>Cancel
                </button>
                <button mat-raised-button class="btn btn-primary" color="primary"
                        [disabled]="!trainerForm.valid ||!this.correct || isSessionStatus"
                        (click)="submitAddUser(false)"><i class="flaticon-diskette"></i>Save
                </button>
            </div>
        </form>
    </div>
    <!-- </div> -->
</ng-template>


<ng-template #addCourse1 let-modal>
    <!-- <div class="card my-0"> -->
    <!-- <div class="header"> -->
    <div class="mat-modal-header">
        <!-- <h4 class="card-title card-title-modal"> -->
        <h4 class="mat-title-modal">
            <span>Session Rating</span>
        </h4>
    </div>
    <!-- <div class="card-content"> -->
    <div class="mat-modal-body">
        <app-loading *ngIf="isTrainerAdded" [isLoading]="isTrainerAdded"></app-loading>
        <!-- <form class="form-material mt-4" action="javascript:" [formGroup]="userForm" *ngIf="!isUserAdded"> -->
        <form class="form-material full-width-inputs" action="javascript:" [formGroup]="participantForm"
              *ngIf="!isTrainerAdded">
            <div class="row form-group">
                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <mat-label>participant</mat-label>
                        <input class="form-control" formControlName="participantName" id="participantName"
                               matInput maxlength="40" disabled type="text">
                    </mat-form-field>
                </div>
                <div class="col-sm-6">
                    <mat-checkbox style="padding-top: 25px" [disabled]="isSessionStatus1" formControlName="sessionStatus" >Session Status</mat-checkbox>
                </div>
                <div class="col-sm-6">
                    <mat-form-field class="form-element">
                        <mat-label>Rating </mat-label>
                        <mat-select
                                [disabled]="isSessionStatus1"
                                class="form-control"
                                formControlName="rating">
                            <mat-option value="poor">Poor</mat-option>
                            <mat-option value="fair">Fair</mat-option>
                            <mat-option value="good">Good</mat-option>
                            <mat-option value="veryGood">VeryGood</mat-option>
                            <mat-option value="excellent">Excellent</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-sm-12">
                    <mat-form-field class="form-element">
                        <mat-label>Comment </mat-label>
                        <textarea [readonly]="isSessionStatus1" class="form-control" matInput placeholder="Comment"
                                  formControlName="comment"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="action-buttons multi-btns col-sm-12 text-right">
                <button mat-raised-button class="btn-danger" (click)="close1()">
                    <i class="flaticon-cancel"></i>Cancel
                </button>
                <button mat-raised-button class="btn btn-primary" color="primary"
                        [disabled]="!participantForm.valid ||!this.correct || isSessionStatus1"
                        (click)="submitAddUser(true)"><i class="flaticon-diskette"></i>Save
                </button>
            </div>
        </form>
    </div>
    <!-- </div> -->
</ng-template>
