<section class="login-block">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <img class="img-fluid logo-img" src="assets/team.png" alt="Team Hrm" />
      </div>
    </div>
    <div class="row">
      <app-card class="reset">
        <mat-card-content>
          <form [formGroup]="updateForm" class="form-material full-width-inputs">
            <div class="password-guide">
              <div class="password-guide-title">Password requirements:</div>
              <div class="password-guide-text">
                <div>
                  <span><i class="fa fa-check" *ngIf="passwordValidationError.length"></i></span>- Use 8-14 characters
                </div>
                <div>
                  <span><i class="fa fa-check" *ngIf="passwordValidationError.letterAndNumber"></i></span>- Use letters
                  & numbers
                </div>
                <div>
                  <span><i class="fa fa-check" *ngIf="passwordValidationError.specialSymbol"></i></span>- Use special
                  characters (eg. @)
                </div>
                <div>
                  <span><i class="fa fa-check" *ngIf="passwordValidationError.alphabet"></i></span>- Mix lower &
                  uppercase
                </div>
              </div>
            </div>

            <br />
            <div class="row form-group">
              <div class="col-md-12" *ngIf="!isReset">
                <mat-form-field class="form-element">
                  <mat-label>Current Password</mat-label>
                  <input
                  matInput
                  class="form-control" 
                  placeholder="Current Password"
                  [type]="currentHide ? 'password' : 'text'"
                  formControlName="currentPassword"
                  required
                />
                <mat-icon matSuffix (click)="currentHide = !currentHide">{{ currentHide ? "visibility_off" : "visibility" }}</mat-icon>
                </mat-form-field>
              </div>

              <div class="col-md-12">
                <mat-form-field class="form-element">
                  <mat-label>New Password</mat-label>
                  <input
                    matInput
                    class="form-control" 
                    placeholder="Password"
                    [type]="passwordHide ? 'password' : 'text'"
                    formControlName="password"
                    required
                  />
                  <mat-icon matSuffix (click)="passwordHide = !passwordHide">{{ passwordHide ? "visibility_off" : "visibility" }}</mat-icon>
                </mat-form-field>
              </div>

              <div class="col-md-12">
                <mat-form-field class="form-element">
                  <mat-label>Confirm Password</mat-label>
                  <input
                    matInput
                    class="form-control" 
                    placeholder="Confirm Password"
                    [type]="confirmHide ? 'password' : 'text'"
                    formControlName="confirmPassword"
                    required
                  />
                  <mat-icon matSuffix (click)="confirmHide = !confirmHide">{{ confirmHide ? "visibility_off" : "visibility" }}</mat-icon>
                  <mat-error
                    class="error-messages"
                    *ngIf="!isPasswordMatch && updateForm.controls.confirmPassword.touched"
                  >
                    The passwords do not match.
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-sm-12 text-right action-buttons multi-btns">
                <button
                  *ngIf="!isReset"
                  mat-raised-button
                  (click)="back()"
                  class="btn-danger"
                >
                 Back
                </button>
                <button
                  *ngIf="!isReset"
                  mat-raised-button
                  color="primary"
                  [disabled]="!updateForm.valid || !isPasswordMatch || isLoading"
                  (click)="updatePassword()"
                  class="btn-primary"
                  [class.spinner]="isLoading"
                >
                  Submit Password
                </button>
                <button
                  *ngIf="isReset"
                  mat-raised-button
                  [disabled]="!updateForm.value.password || !updateForm.value.confirmPassword || !isPasswordMatch || isLoading"
                  (click)="updatePassword()"
                  color="primary"
                  class="btn-primary"
                  [class.spinner]="isLoading"
                >
                  Submit Password
                </button>
              </div>
            </div>
            <span class="err">{{errorMsg}}</span>
          </form>
        </mat-card-content>
      </app-card>
    </div>
  </div>
</section>
