<div id="header-page" class="ng-star-inserted header-page">
    <div class="page-header ng-star-inserted">
        <div class="page-block ng-star-inserted">
        <div class="row align-items-center">
            <div class="col-md-8">
            <div class="page-header-title">
                <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted">User Role</h4></span>
            </div>
            </div>
            <div class="col-md-4"></div>
        </div>
        </div>
    </div>
    </div>
    
    <div class="page-body no-title">
    <div class="row">
        <div class="col-sm-12">
            <div class="row top-tools">
                <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6 left-tools">
                    <span class="export-icon-btn csv" (click)="exportToCsv()">
                        <i class="material-icons">get_app</i><span>CSV</span>
                    </span>
                    <span class="export-icon-btn pdf" (click)="saveAsPdf()">
                        <i class="material-icons">get_app</i><span>PDF</span>
                    </span>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
                    <div class="relative-position search-filter-con">
                        <span class="icon material-icons ng-tns-c114-0"> search </span>
                        <mat-form-field>
                            <input matInput type="search" [(ngModel)]="filterQuery" maxlength="40"
                            (ngModelChange)="onModelChange(filterQuery)"
                            class="form-control input-sm full-data-search">
                        
                        </mat-form-field>
                        <span class="mytooltip cus-left tooltip-effect-4" [hidden]="!write">
                            <span class="tooltip-item">
                                <button mat-mini-fab class="btn btn-primary light add-rounded margin-button-05" (click)="addNewUser()">
                                    <i class="fa fa-plus"></i>
                                </button>
                            </span>
                            <span class="tooltip-content clearfix">
                            <span class="tooltip-text">New</span>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <app-card [title]="'User Role'">
                <div class="dt-responsive" *ngIf="dataSource">
                    <div class="table-responsive table hrm-table-style-1 full-data-table table-cols-4">
                      <mat-card-content>
                        <mat-table [dataSource]="dataSource" matSort>
                          <ng-container matColumnDef="select">
                            <mat-header-cell [hidden]="!delete" *matHeaderCellDef>
                              <a [matMenuTriggerFor]="menu" class="check-all">
                                <span class="material-icons">
                                    more_vert
                                </span>
                              </a>

                              <mat-menu #menu="matMenu">
                                  <button mat-menu-item (click)="onSelectAllClicked()">Select All</button>
                                  <button (click)="onDeSelectAllClicked()" *ngIf="selectedCheckBoxList.length > 0"
                                          mat-menu-item>Deselect All
                                  </button>
                                  <button (click)="onDeleteSelected()" *ngIf="selectedCheckBoxList.length > 0"
                                          mat-menu-item>Delete Selected
                                  </button>
                              </mat-menu>
                            </mat-header-cell>
                              <mat-cell [hidden]="!delete" *matCellDef="let row">
                                  <mat-checkbox #matCheckbox (change)="onCheckBoxChange($event, row.id)"
                                                value="{{row.id}}" disabled="{{row.readOnly}}"></mat-checkbox>
                              </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
                            <mat-cell *matCellDef="let row">
                              {{ row.name }}
                            </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="roleType">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Role Type</mat-header-cell>
                            <mat-cell *matCellDef="let row">
                              {{ row.roleType}}
                            </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="edit">
                            <mat-header-cell [hidden]="!update" *matHeaderCellDef>Edit</mat-header-cell>
                            <mat-cell [hidden]="!update" *matCellDef="let row">
                                <span class="mytooltip tooltip-effect-4">
                                    <span class="tooltip-item"><i class="single-icon-click fa fa-pencil" (click)="editUser(row.id,row.roleType)"></i></span>
                                    <span class="tooltip-content clearfix">
                                    <span class="tooltip-text">Edit</span>
                                    </span>
                                </span>
                            </mat-cell>
                          </ng-container>
                          <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                          <mat-row *matRowDef="let row; columns: columns;">
                          </mat-row>
                        </mat-table>
                        <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
                          <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                      </mat-card-content>
                    </div>
                    <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
                  </div>
                </app-card>
        </div>
    </div>
</div>

<ng-template #addUser let-modal>
    <div mat-dialog-actions>  
        <div class="mat-modal-header">
          <h4 class="mat-title-modal">
            <span *ngIf="!isEdit">Add</span>
            <span *ngIf="isEdit">Edit</span> User Role
          </h4>
        </div>
        <div class="mat-modal-body"> 
    <!-- <div class="card my-0">
        <div class="header">
            <h4 class="card-title card-title-modal">
                <span *ngIf="!isEdit">Add</span><span *ngIf="isEdit">Edit</span> User Role</h4>
        </div>
        <div class="card-content"> -->
            <!-- scrolling-div -->
            <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>

            <form class="form-material full-width-inputs" action="javascript:" *ngIf="!isUserAdded">
                <div class="row form-group">
                
                    <div class="col-md-6">
                        <mat-form-field class="form-element">  
                            <mat-label for="selectedRole">Role</mat-label>
                            <mat-select disableOptionCentering class="form-control" name="selectedRole" (onSelectionChange)="changeRole()" required=""
                            [(ngModel)]="selectedRole">
                                <mat-option value="" [selected]="!isEdit">==Select==</mat-option>
                                <mat-option *ngFor="let role of rolesList" value="{{role}}">{{ role }}</mat-option>                        
                            </mat-select>
                            <mat-error *ngIf="!selectedRole">
                                User role required!
                            </mat-error> 
                        </mat-form-field>                       
                    </div>
                  
                    <!-- <div class="form-group form-default col-sm-6">
                        <!- <label class="col-form-label">Role</label> ->
                        <select class="form-control" name="selectedRole" (change)="changeRole()" required=""
                                [(ngModel)]="selectedRole">
                                <option value="" [selected]="!isEdit"></option>
                            <ng-container *ngFor="let role of rolesList">
                                <option value="{{role}}">{{ role }}</option>
                            </ng-container>                          
                        </select>
                        <span class="form-bar"></span>
                        <label class="float-label">Role</label>
                    </div> -->
                       
                    <div class="col-md-6">
                        <mat-form-field class="form-element">
                            <mat-label for="name">User role Name</mat-label>
                            <input pattern= "*" type="text" matInput class="form-control" maxlength="40" required="" [(ngModel)]="name" name="name">
                            <mat-error *ngIf="!name">
                                User role name required!
                            </mat-error> 
                        </mat-form-field>  
                    </div>
                  
                    <!-- <div class="col-md-6">
                        <input type="text" class="form-control" maxlength="40" [(ngModel)]="name" name="name" required="">
                        <span class="form-bar"></span>
                        <label class="float-label">User role Name</label>
                        <p *ngIf="!name" style="color:red;">User role name required!</p>
                    </div> -->

                </div>

                <div *ngIf="permissionList">
                 
                    <div class="employee-action" >
                        <p>Employee Action</p>
                        <ng-container *ngFor="let emp of employeeAction; let i = index;">
                            <div class="col-md-12">
                                <mat-checkbox class="example-margin"
                                              color="primary" [(ngModel)]="emp.checked" name="{{emp.checked}}{{i}}">
                                              {{ emp.name }}
                                </mat-checkbox>
                            </div>
                        </ng-container>
                    </div>
                
                    <!-- <div class="ml-25">
                                <div class="">
                                    <input class="form-check-input" type="checkbox" [(ngModel)]="emp.checked"
                                           name="{{emp.checked}}{{i}}">
                                </div>
                                <div class="">
                                    <span>{{ emp.name }}</span>
                                </div>
                            </div> -->
                            <hr>
                    <div class="workflow-management" *ngIf="workflowAction.length > 0">
                        <p>Workflow Management</p>
                       
                     
                        <ng-container *ngFor="let w of workflowAction; let i = index;">
                            <div class="ml-25">
                                <mat-checkbox class="example-margin"
                                     color="primary" [(ngModel)]="w.checked" name="{{w.checked}}{{i}}">
                                     {{ w.name }}
                                </mat-checkbox>

                                <!-- <div class="">
                                    <input class="form-check-input" type="checkbox" [(ngModel)]="w.checked"
                                           name="{{w.checked}}{{i}}">
                                </div>
                                <div class="">
                                    <span>{{ w.name }}</span>
                                </div> -->
                            </div>
                        </ng-container>
                    

                    </div>
                    <hr>
                    <div class="data-group-permissions">
                        <div><p>Data Group Permissions</p></div>
                        <ng-container>
                            <ng-container *ngFor="let d of dataGroup;let i = index;">
                                <div class="">
                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <mat-checkbox #c [checked]="checkAllChecked(d)" [indeterminate]="checkForIndeterminate(d)" (click)="$event.stopPropagation()"
                                                        (change)="checkAll(c,d)">{{d.name}}</mat-checkbox>
                                                </mat-panel-title>
                                        </mat-expansion-panel-header>                                       
                                        <div class="overflowX">
                                            <table class="table">
                                                <tbody>
                                                <tr *ngFor="let m of d.modules">
                                                    <td>{{m.name}}</td>
                                                    <td *ngFor="let p of m.permits">
                                                        <mat-checkbox [disabled]="p.disabled" [checked]="isChecked(p)" (change)="checkChild(p , m.permits )" (click)="$event.stopPropagation();">
                                                            {{p.name}}
                                                        </mat-checkbox>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>                         
                                    </mat-expansion-panel>
                                </div>
                            </ng-container>
                        </ng-container>

                    </div>
                </div>

              
            </form>
        </div>
        <br>
        <div class=" col-md-12 text-right">          
            <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i>Cancel</button>
            <button mat-raised-button class="btn-primary" color="primary" (click)="submitAddJobTitle()"
                    [disabled]="!name"><i class="flaticon-diskette"></i>Save
            </button>
        </div>
    </div>
</ng-template>


<ng-template #deleteUser let-modal>
    <div mat-dialog-actions>  
        <div class="mat-modal-header">
          <h4 class="mat-title-modal">
            <span *ngIf="!isEdit">Add</span>
            <span *ngIf="isEdit">Edit</span> User Role
          </h4>
        </div>
        <div class="mat-modal-body"> 
    <!-- <div class="card my-0">
        <div class="header">
            <h4 class="card-title card-title-modal">Delete User Role</h4>
        </div>
        <div class="card-content px-2 py-2"> -->
            <div class="text-left are-u">Are you sure ?</div>
            <div class="row">
                <div class="col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i>Cancel</button>
                    <button mat-raised-button class="btn-primary" color="primary" (click)="deleteJobTitle()"><i class="flaticon-trash"></i>Delete</button> 
                </div>
            </div>
        </div>
    </div>
</ng-template>



