<!-- <app-loading [isLoading]="this.isLoading === true || isLoadingRequired"></app-loading> -->
<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div [hidden]="!this.isLoading === false || isLoadingRequired" class="card cus-card">
  <form [formGroup]="personalForm" class="form-material full-width-inputs">
    <div [@fadeInOutTranslate]>
      <div class="row">
        <div class="col-md-4">
          <mat-form-field class="form-element">
            <mat-label>First Name</mat-label>
            <input
              [readonly]="!update"
              class="form-control"
              maxlength="40"
              formControlName="firstname"
              matInput
              required=""
              type="text"
            />
          </mat-form-field>
        </div>
        <div class="col-md-4" [hidden]="!requiredPersonal.middleNameAvailable">
          <mat-form-field class="form-element">
            <mat-label>Middle Name</mat-label>
            <input
              [readonly]="!update"
              class="form-control"
              maxlength="40"
              formControlName="middlename"
              matInput
              type="text"
              required="{{requiredPersonal.middleNameRequired}}"
            />
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="form-element">
            <mat-label>Last Name</mat-label>
            <input
              [readonly]="!update"
              class="form-control"
              maxlength="40"
              formControlName="lastname"
              matInput
              required=""
              type="text"
            />
          </mat-form-field>
        </div>
        <div class="col-md-4" [hidden]="!requiredPersonal.dateOfBirthAvailable">
          <mat-form-field class="form-element">
            <mat-label for="dob">DOB</mat-label>
            <input
              [readonly]="!update"
              [matDatepicker]="picker1"
              class="form-control"
              formControlName="dob"
              matInput
              required="{{requiredPersonal.dateOfBirthRequired}}"
            />
            <mat-datepicker-toggle [disabled]="!update" [for]="picker1" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-4" [hidden]="!requiredPersonal.militaryServiceAvailable">
          <mat-form-field class="form-element">
            <mat-label>Military Service</mat-label>
            <mat-select
              [disabled]="!update"
              disableOptionCentering
              class="form-control"
              formControlName="militaryservice"
              required="{{requiredPersonal.militaryServiceRequired}}"
            >
              <mat-option value="">--Select--</mat-option>
              <mat-option [value]="'yes'"> Yes</mat-option>
              <mat-option [value]="'no'">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-4" [hidden]="!requiredPersonal.martialStatusAvailable">
          <mat-form-field class="form-element">
            <mat-label>Maritial Status</mat-label>
            <mat-select
              [disabled]="!update"
              required="{{requiredPersonal.martialStatusRequired}}"
              disableOptionCentering
              class="form-control"
              formControlName="maritalstatus"
            >
              <mat-option value="">--Select--</mat-option>
              <mat-option value="Single">Single</mat-option>
              <mat-option value="Married">Married</mat-option>
              <mat-option value="Other">Other</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="row form-group">
            <div class="col-md-4 flex-checkbox" [hidden]="!requiredPersonal.smokerAvailable">
              <mat-form-field class="form-element">
                <mat-label>Smoker</mat-label>

                <mat-select
                  [disabled]="!update"
                  disableOptionCentering
                  class="form-control"
                  formControlName="smoking"
                  required="{{requiredPersonal.smokerRequired}}"
                >
                  <mat-option value="">--Select--</mat-option>
                  <mat-option value="yes">Yes</mat-option>
                  <mat-option value="no">No</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4" [hidden]="!requiredPersonal.genderAvailable">
              <mat-form-field class="form-element">
                <mat-select
                  [disabled]="!update"
                  required="{{requiredPersonal.genderRequired}}"
                  disableOptionCentering
                  class="form-control"
                  formControlName="gender"
                >
                  <mat-option value="">--Select--</mat-option>
                  <mat-option value="Male">Male </mat-option>
                  <mat-option value="Female">Female </mat-option>
                  <mat-option value="Other">Other </mat-option>
                </mat-select>
                <mat-label for="des">Gender</mat-label>
              </mat-form-field>
            </div>
            <div class="col-md-4" [hidden]="!requiredPersonal.licenseExpiryDateAvailable">
              <mat-form-field class="form-element">
                <input
                  [readonly]="!update"
                  [matDatepicker]="picker"
                  class="form-control"
                  formControlName="licenseexpirydate"
                  matInput
                  required="{{requiredPersonal.licenseExpiryDateRequired}}"
                />
                <mat-datepicker-toggle [disabled]="!update" [for]="picker" matSuffix> </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-label for="expiry">Driving License Expiry Date </mat-label>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field class="form-element">
                <mat-label>Nationality</mat-label>
                <input
                  [readonly]="!update"
                  class="form-control"
                  type="text"
                  aria-label="Nationality"
                  maxlength="40"
                  required=""
                  matInput
                  formControlName="nationality"
                  [matAutocomplete]="auto"
                  placeholder="Type for hints .."
                />
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayNationlaityByID.bind(this)">
                  <mat-option (click)="changeStatus()" *ngFor="let r of filteredOptions " [value]="r.id">
                    {{r.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <mat-error class="error-messages" [hidden]="this.correct">
                please Select nationality from the dropdown !
              </mat-error>
            </div>
            <div class="col-md-4" [hidden]="!requiredPersonal.nickNameAvailable">
              <mat-form-field class="form-element">
                <input
                  [readonly]="!update"
                  class="form-control"
                  maxlength="40"
                  formControlName="nickname"
                  matInput
                  type="text"
                  required="{{requiredPersonal.nickNameRequired}}"
                />
                <span class="form-bar"></span>
                <mat-label for="title">Nickname</mat-label>
              </mat-form-field>
            </div>
            <div class="col-md-4" [hidden]="!requiredPersonal.driverLicenseNumberAvailable">
              <mat-form-field class="form-element">
                <input
                  [readonly]="!update"
                  class="form-control"
                  maxlength="40"
                  formControlName="drivinglicenseno"
                  matInput
                  type="text"
                  required="{{requiredPersonal.driverLicenseNumberRequired}}"
                />
                <span class="form-bar"></span>
                <mat-label for="title">Driver's License No </mat-label>
              </mat-form-field>
            </div>
          </div>
        </div>
        <!-- <div class="col-sm-2">
                    <div class="text-right">
                        <mat-spinner *ngIf="imageUploading"
                                     style="z-index: 5;position: absolute;width: 100;    height: 100;margin: 30px;">
                        </mat-spinner>
                        <div class="upload-btn-wrapper border">
                            <img *ngIf="personalForm.value.image"
                                 class="profile-h"
                                 src={{personalForm.value.image}}/>
                            <i *ngIf="!personalForm.value.image"
                               class="fa fa-user"></i>
                            <input (change)="profileChange($event)"
                                   class="form-control profile-img"
                                   name="image"
                                   type="file" >
                        </div>
                        <div *ngIf="personalForm.value.image">
                        </div>
                    </div>
                </div> -->
      </div>

      <div class="card-sub" [hidden]="!this.showHealth">
        <h4>Health</h4>
        <div class="row form-group">
          <div class="col-md-4">
            <mat-form-field class="form-element">
              <mat-select [disabled]="!update" disableOptionCentering class="form-control" formControlName="bloodgroup">
                <ng-container>
                  <mat-option value="">--Select--</mat-option>
                  <mat-option value="A+">A+</mat-option>
                  <mat-option value="A-">A-</mat-option>
                  <mat-option value="B+">B+</mat-option>
                  <mat-option value="B-">B-</mat-option>
                  <mat-option value="AB+">AB+</mat-option>
                  <mat-option value="AB-">AB-</mat-option>
                  <mat-option value="O+">O+</mat-option>
                  <mat-option value="O-">O-</mat-option>
                </ng-container>
              </mat-select>
              <mat-label for="des">Blood Group</mat-label>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field class="form-element">
              <input
                [readonly]="!update"
                class="form-control"
                maxlength="40"
                formControlName="disability"
                matInput
                type="text"
              />
              <span class="form-bar"></span>
              <mat-label for="title">Disability </mat-label>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field class="form-element">
              <input class="form-control" maxlength="40" formControlName="medicalIssues" matInput type="text" />
              <span class="form-bar"></span>
              <mat-label for="title">Medical Status </mat-label>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="card-sub" [hidden]="!this.showPrefrence">
        <h4>Preference</h4>
        <ng-container>
          <div class="row form-group">
            <div class="col-md-4">
              <mat-form-field class="form-element">
                <mat-select [disabled]="!update" disableOptionCentering class="form-control" formControlName="food">
                  <ng-container>
                    <mat-option value="">--Select--</mat-option>
                    <mat-option value="veg">Veg </mat-option>
                    <mat-option value="nonveg">Non-Veg </mat-option>
                  </ng-container>
                </mat-select>
                <mat-label for="des">Food</mat-label>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field class="form-element">
                <mat-label for="des">Hobbies</mat-label>
                <input [readonly]="!update" matInput class="form-control" formControlName="activities" />
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field class="form-element">
                <mat-label for="des">Sports</mat-label>
                <input [readonly]="!update" matInput class="form-control" formControlName="sports" />
              </mat-form-field>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="action-buttons multi-btns col-sm-12 text-right" [hidden]="!update">
        <button
          [hidden]="!update"
          [disabled]="!personalForm.valid || !this.correct"
          (click)="updatePersonalDetail()"
          class="btn-primary"
          color="primary"
          mat-raised-button
        >
          <i class="flaticon-diskette"></i>Save
        </button>
        <br />
        <br />
        <br />
      </div>
    </div>
  </form>

  <div class="form-material full-width-inputs" [hidden]="this.isLoading || isLoadingRequired">
    <div *ngFor="let section of this.sectionsList">
      <div *ngIf="section.customFields.length != 0" class="card-sub">
        <h4>{{section.name}}</h4>
        <ng-container>
          <div class="row form-group">
            <div class="col-4" *ngFor="let custom of section.customFields; index as i">
              <mat-form-field class="form-element" *ngIf="custom.type === 'dd' ">
                <mat-select
                  [disabled]="!update"
                  disableOptionCentering
                  class="form-control"
                  [(ngModel)]="custom.value"
                  required="{{custom.required}}"
                  (ngModelChange)="checkRequiredFieldsInCustomFields(section.id,section.customFields)"
                >
                  <ng-container>
                    <mat-option value="">--Select--</mat-option>
                    <mat-option *ngFor="let option of custom.options " [value]="option" [(ngModel)]="custom.value">
                      {{option}}
                    </mat-option>
                  </ng-container>
                </mat-select>
                <mat-label>{{custom.name}}</mat-label>
              </mat-form-field>

              <mat-form-field class="form-element" *ngIf="custom.type === 'st' ">
                <mat-label>{{custom.name}}</mat-label>
                <input
                  [readonly]="!update"
                  matInput
                  class="form-control"
                  [(ngModel)]="custom.value"
                  required="{{custom.required}}"
                  (keyup)="checkRequiredFieldsInCustomFields(section.id,section.customFields)"
                />
              </mat-form-field>

              <mat-form-field class="form-element" *ngIf="custom.type === 'num' ">
                <mat-label>{{custom.name}}</mat-label>
                <input
                  [readonly]="!update"
                  matInput
                  class="form-control"
                  [(ngModel)]="custom.value"
                  type="number"
                  required="{{custom.required}}"
                  (keyup)="checkRequiredFieldsInCustomFields(section.id,section.customFields)"
                />
              </mat-form-field>
              <mat-form-field class="form-element" *ngIf="custom.type === 'dt' ">
                <mat-label>{{custom.name}}</mat-label>
                <input
                  [readonly]="!update"
                  class="form-control"
                  matInput
                  [matDatepicker]="i"
                  [attr.id]="dtPicker + i"
                  [(ngModel)]="custom.value"
                  required="{{custom.required}}"
                  (ngModelChange)="checkRequiredFieldsInCustomFields(section.id,section.customFields)"
                />
                <mat-datepicker-toggle [disabled]="!update" matSuffix [for]="i"></mat-datepicker-toggle>
                <mat-datepicker #i></mat-datepicker>
              </mat-form-field>

              <div *ngIf="custom.type === 'cb' ">
                <h5>{{custom.name}}:</h5>
                <div *ngFor="let option of custom.values">
                  <mat-checkbox [disabled]="!update" [(ngModel)]="option.booleanValue">{{option.option}}</mat-checkbox>
                  &nbsp;
                </div>
              </div>
            </div>

            <div class="action-buttons multi-btns col-sm-12 text-right" [hidden]="!update">
              <button
                [disabled]="section.save"
                (click)="updateCustomField(section.id,section.customFields)"
                class="btn-primary"
                color="primary"
                mat-raised-button
              >
                <i class="flaticon-diskette"></i>Save
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="card-sub">
    <div class="row">
      <div class="col-sm-10">
        <h4>Personal Information Attachment</h4>
      </div>
      <div class="col-sm-2">
        <div class="text-right single-add">
          <span class="mytooltip cus-left tooltip-effect-4" [hidden]="!update">
            <span class="tooltip-item">
              <button (click)="addAttachment()" class="btn btn-primary light add-rounded margin-button-05" mat-mini-fab>
                <i class="fa fa-plus"></i>
              </button>
            </span>
            <span class="tooltip-content clearfix">
              <span class="tooltip-text">New</span>
            </span>
          </span>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <mat-card-content>
        <mat-table #table [dataSource]="attachmentList" matSort>
          <ng-container matColumnDef="fileName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>File Name </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{ row.fileName }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="url">
            <mat-header-cell *matHeaderCellDef>File </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span class="mytooltip tooltip-effect-4">
                <span class="tooltip-item"
                  ><i (click)="open(row.url)" class="single-icon-click flaticon-attachment" style="color: #666"></i
                ></span>
                <span class="tooltip-content clearfix">
                  <span class="tooltip-text">Attachment</span>
                </span>
              </span>
              <!--                                                                            <a (click)="open(row.url)"-->
              <!--                                                                               data-toggle="lightbox">-->
              <!--                                                                                <div *ngIf="row.url"-->
              <!--                                                                                     [ngStyle]="{'background-image': 'url(' + row.url + ')'}"-->
              <!--                                                                                     class="image blank pointer">-->
              <!--                                                                                </div>-->
              <!--                                                                            </a>-->
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="desc">
            <mat-header-cell *matHeaderCellDef>Comment </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{ row.desc }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="size">
            <mat-header-cell *matHeaderCellDef>Size </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{ row.size }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="added">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Date Added </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{ row.added | date }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="edit">
            <mat-header-cell *matHeaderCellDef [hidden]="!update">Edit </mat-header-cell>
            <mat-cell *matCellDef="let row" [hidden]="!update">
              <span class="mytooltip tooltip-effect-4">
                <span class="tooltip-item"
                  ><i (click)="editattach(row)" class="single-icon-click fa fa-pencil"></i
                ></span>
                <span class="tooltip-content clearfix">
                  <span class="tooltip-text">Edit</span>
                </span>
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="delete">
            <mat-header-cell *matHeaderCellDef [hidden]="!delete">Delete </mat-header-cell>
            <mat-cell *matCellDef="let row" [hidden]="!delete">
              <span class="mytooltip tooltip-effect-4">
                <span class="tooltip-item"
                  ><i (click)="deleteAttachmentSelected(row.id)" class="single-icon-click fa fa-trash"></i
                ></span>
                <span class="tooltip-content clearfix">
                  <span class="tooltip-text">Delete</span>
                </span>
              </span>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: columns;"> </mat-row>
        </mat-table>
        <mat-progress-bar mode="indeterminate" *ngIf="isLoadingattach"></mat-progress-bar>
        <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
      </mat-card-content>
    </div>
  </div>
</div>

<ng-template #attach let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">Attachment</h4>
    </div>
    <div class="mat-modal-body">
      <app-loading *ngIf="!isDone" [isLoading]="!isDone"></app-loading>
      <form class="form-material full-width-inputs" *ngIf="isDone">
        <div class="row form-group">
          <div class="col-sm-12">
            <div class="upload-btn-wrapper">
              <button class="upload-btn">Attach</button>
              <input
                (change)="fileChange($event, 'dd')"
                [(ngModel)]="fileClean"
                class="form-control"
                name="myfile"
                type="file"
              />
              <div class="fileName">
                {{this.fileObj && this.fileObj.fileName}}<i *ngIf="fileUrl" class="fa fa-check success"></i>
              </div>
            </div>
            <div class="upload-btn-wrapper"></div>
            <div *ngIf="!fileUrl && fileError" class="file-error">{{fileError}}</div>
            <div class="help-text">Accept upto 2MB</div>
          </div>
          <div class="col-md-12">
            <mat-form-field class="form-element">
              <mat-label>File Name</mat-label>
              <input
                [(ngModel)]="fileName"
                [ngModelOptions]="{standalone: true}"
                class="form-control"
                maxlength="40"
                matInput
              />
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <mat-form-field class="form-element">
              <mat-label>Comment</mat-label>
              <textarea [(ngModel)]="comment" class="form-control" maxlength="50" matInput name="name"></textarea>
            </mat-form-field>
          </div>
          <div class="action-buttons multi-btns col-sm-12 text-right" *ngIf="isDone">
            <button
              (click)="saveAttachment()"
              [disabled]="!this.fileObj.fileName || !isDone"
              class="btn-primary"
              color="primary"
              mat-raised-button
              name="button"
            >
              <i class="flaticon-diskette"></i>Save
            </button>
          </div>
        </div>
      </form>
      <!-- </div> -->
    </div>
  </div>
</ng-template>
<ng-template #editAttach let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">Edit Attachment</h4>
    </div>
    <div class="mat-modal-body">
      <form class="form-material full-width-inputs">
        <div class="row form-group">
          <div class="col-md-12">
            <mat-form-field class="form-element">
              <mat-label>File Name</mat-label>
              <input
                [(ngModel)]="fileName"
                [ngModelOptions]="{standalone: true}"
                class="form-control"
                maxlength="40"
                matInput
              />
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <mat-form-field class="form-element">
              <textarea [(ngModel)]="comment" maxlength="50" matInput class="form-control" name="name"></textarea>
              <mat-label>Comment</mat-label>
            </mat-form-field>
          </div>
          <div class="col-sm-12 text-right action-buttons multi-btns">
            <button mat-raised-button color="primary" (click)="updateAttachment()" class="btn-primary" name="button">
              <i class="flaticon-diskette"></i>
              Update
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<ng-template #deleteAttachment let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">Delete Attachment</h4>
    </div>
    <div class="mat-modal-body">
      <div class="text-left are-u">Are you sure ?</div>
      <div class="row">
        <div class="multi-btns col-sm-12 text-right">
          <button (click)="this.dialogRef.close()" class="btn-danger" mat-raised-button>
            <i class="flaticon-cancel"></i>Cancel
          </button>
          <button (click)="deleteAttachmentFrom()" class="btn-primary" color="primary" mat-raised-button>
            <i class="flaticon-trash"></i>Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
