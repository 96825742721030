<div id="header-page" class="ng-star-inserted header-page">
    <div class="page-header ng-star-inserted">
        <div class="page-block ng-star-inserted">
            <div class="row align-items-center">
                <div class="col-md-8">
                    <div class="page-header-title">
                        <span class="ng-star-inserted"></span><span class="ng-star-inserted">
                            <h4 class="m-b-10 ng-star-inserted">Vendors</h4>
                        </span>
                    </div>
                </div>
                <div class="col-md-4"></div>
            </div>
        </div>
    </div>
</div>
<div class="page-body no-title">
    <div class="row">
        <div class="col-sm-12">

            <div class="row top-tools">

                <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6 left-tools">
                    <span class="export-icon-btn csv" (click)="exportToCsv()"><i
                            class="material-icons">get_app</i><span>CSV</span>
                    </span>
                    <span class="export-icon-btn pdf" (click)="saveAsPdf()"><i
                            class="material-icons">get_app</i><span>PDF</span>
                    </span>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
                    <div class="relative-position search-filter-con">
                        <!-- <span class="icon material-icons ng-tns-c114-0"> search </span>
                        <mat-form-field>
                            <input matInput #inputSearch type="search" [(ngModel)]="filterQuery" maxlength="30"
                            (ngModelChange)="onModelChange(filterQuery)"
                            class="form-control input-sm full-data-search">    
                        </mat-form-field> -->

                        <span class="mytooltip cus-left tooltip-effect-4" [hidden]="!write">
                            <span class="tooltip-item">
                                <button mat-mini-fab class="btn btn-primary light add-rounded margin-button-05"
                                    (click)="openModel()">
                                    <i class="fa fa-plus"></i>
                                </button>
                            </span>
                            <span class="tooltip-content clearfix">
                                <span class="tooltip-text">New</span>
                            </span>
                        </span>
                        <span class="mytooltip cus-left tooltip-effect-4">
                            <span class="tooltip-item">
                                <button mat-mini-fab class="btn btn-primary light add-rounded margin-button-05"
                                    (click)="openFilterForm()">
                                    <i class="fa fa-filter"></i>
                                </button>
                            </span>
                            <span class="tooltip-content clearfix">
                                <span class="tooltip-text">Filter</span>
                            </span>
                        </span>
                    </div>
                </div>
            </div>

            <app-card>
                <mat-card-content>
                    <mat-table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="select">
                            <mat-header-cell *matHeaderCellDef [hidden]="!delete">
                                <a [matMenuTriggerFor]="menu" class="check-all">
                                    <span class="material-icons">
                                        more_vert
                                    </span>
                                </a>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item (click)="onSelectAllClicked()">Select All</button>
                                    <button (click)="onDeSelectAllClicked()" *ngIf="selectedVendors.length > 0"
                                        mat-menu-item>Deselect All
                                    </button>
                                    <button (click)="onDeleteSelected()" *ngIf="selectedVendors.length > 0"
                                        mat-menu-item>Delete Selected
                                    </button>
                                </mat-menu>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row" [hidden]="!delete">
                                <mat-checkbox #matCheckbox (change)="onNewDocSelectChange($event, row.id)"
                                    value="{{row.id}}"></mat-checkbox>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="vendorId">
                            <mat-header-cell mat-sort-header *matHeaderCellDef>ID</mat-header-cell>
                            <mat-cell (click)="itemClick(row)" class="cursor-pointer" *matCellDef="let row">
                                {{row.vendorId}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="name">
                            <mat-header-cell mat-sort-header *matHeaderCellDef>Vendor</mat-header-cell>
                            <mat-cell (click)="itemClick(row)" class="cursor-pointer" *matCellDef="let row">
                                {{row.name}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="contactNumber">
                            <mat-header-cell *matHeaderCellDef>Contact No</mat-header-cell>
                            <mat-cell (click)="itemClick(row)" class="cursor-pointer" *matCellDef="let row">
                                {{row.contactNumber }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="email">
                            <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
                            <mat-cell (click)="itemClick(row)" class="cursor-pointer" *matCellDef="let row">
                                {{row.email}}</mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;">
                        </mat-row>
                    </mat-table>
                    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
                    <mat-paginator [pageSizeOptions]="[25, 50,10]" showFirstLastButtons></mat-paginator>
                </mat-card-content>
            </app-card>
        </div>
    </div>
</div>


<ng-template #categoryForm let-modal>
    <div mat-dialog-action>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">Vendor</h4>
        </div>

        <div class="mat-modal-body">
            <form [hidden]="isSaving" class="form-material full-width-inputs" [formGroup]="newForm">
                <div class="row form-group">
                    <div class="col-md-6">
                        <mat-form-field class="form-element">
                            <input required matInput placeholder="Id" class="form-control" formControlName="vendorId">
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field class="form-element">
                            <input required matInput placeholder="Name" class="form-control" formControlName="name">
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field class="form-element">
                            <input matInput placeholder="Contact No" class="form-control"
                                formControlName="contactNumber">
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field class="form-element">
                            <input matInput placeholder="Email" class="form-control" formControlName="email">
                            <mat-error class="error-messages" *ngIf="!newForm.controls.email.valid">
                                please provide valid Email
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-md-12">
                        <mat-form-field class="form-element">
                            <input matInput placeholder="website" class="form-control" formControlName="webSite">
                        </mat-form-field>
                    </div>
                    <div class="col-md-12">
                        <mat-form-field class="form-element">
                            <textarea matInput class="form-control" placeholder="Address"
                                formControlName="address"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </form>
            <app-loading *ngIf="isSaving" [isLoading]="isSaving"></app-loading>
            <div class="col-sm-12 text-right action-buttons multi-btns">
                <button mat-raised-button class="btn-danger" (click)="close()" *ngIf="!isSaving"><i
                        class="flaticon-cancel"></i>Cancel</button>
                <button [disabled]="!this.newForm.valid" mat-raised-button color="primary" class="btn-primary"
                    (click)="save()" *ngIf="!isSaving && !this.hide"><i class="flaticon-diskette"></i>Save</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #FilterForm let-modal>
    <div mat-dialog-action>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">Filter Vendor</h4>
        </div>

        <div class="mat-modal-body">
            <form [hidden]="isSaving" class="form-material full-width-inputs" [formGroup]="filterForm">
                <div class="row form-group">
                    <div class="col-md-6">
                        <mat-form-field class="form-element">
                            <input matInput placeholder="Id" class="form-control" formControlName="id">
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field class="form-element">
                            <input matInput placeholder="Name" class="form-control" formControlName="name">
                        </mat-form-field>
                    </div>
                </div>
            </form>
            <app-loading *ngIf="isSaving" [isLoading]="isSaving"></app-loading>
            <div class="col-sm-12 text-right action-buttons multi-btns">
                <button mat-raised-button class="btn-danger reset-btn" (click)="onResetForm()">
                    <i class="flaticon-refresh"></i>Reset</button>
                <button mat-raised-button class="btn-danger" (click)="close()" *ngIf="!isSaving"><i
                        class="flaticon-cancel"></i>Cancel</button>
                <button mat-raised-button color="primary" class="btn-primary" (click)="filterVendor()"
                    *ngIf="!isSaving">Search</button>
            </div>
        </div>
    </div>
</ng-template>
<!-- <ng-template #categoryForm let-modal>
    <mat-card>
        <mat-card-header class="d-flex justify-content-between">
            <mat-card-title>Vendor</mat-card-title>
            <a (click)="closeModel()">
                <mat-icon aria-label="More detail">close</mat-icon>
            </a>
        </mat-card-header>
        <form class="example-form" [formGroup]="newForm">
            <div class="row">
                <div class="col-6">
                    <mat-form-field class="example-full-width">
                        <input matInput placeholder="Name" formControlName="name">
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field class="example-full-width">
                        <input matInput placeholder="Contact No" formControlName="contactNo">
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field class="example-full-width">
                        <input matInput placeholder="Email" formControlName="email">
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field class="example-full-width">
                        <input matInput placeholder="Website" formControlName="website">
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <mat-form-field class="example-full-width">
                        <textarea matInput placeholder="Address" formControlName="address"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </form>
        <app-loading *ngIf="isSaving" [isLoading]="isSaving"></app-loading>
        <div class="d-flex justify-content-end">
            <button mat-raised-button color="primary" (click)="save()" *ngIf="!isSaving">Save</button>
        </div>
    </mat-card>
</ng-template> -->


<ng-template #deleteUserSelected let-modal>
    <!-- <div class="card my-0"> -->
    <div mat-dialog-actions>
        <!-- <div class="header">  -->
        <div class="mat-modal-header">
            <!-- <h4 class="card-title card-title-modal">Delete User</h4>  -->
            <h4 class="mat-title-modal">Delete Asset</h4>
        </div>
        <!-- <div class="card-content px-2 py-2"> -->
        <div class="mat-modal-body">
            <div class="text-left are-u">Are you sure ?</div>
            <div class="row">
                <div class="multi-btns action-buttons col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close()">
                        <i class="flaticon-cancel"></i> Cancel</button>
                    <button class="btn-primary" color="primary" mat-raised-button (click)="deleteMultiple()"><i
                            class="flaticon-trash"></i> Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- 

<ng-template #deleteUser let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">Delete User</h4>
        </div>
        <div class="mat-modal-body">
            <div class="text-left are-u">Are you sure ?</div>
            <div class="row">
                <div class="col-sm-12 action-buttons multi-btns text-right">
                    <button mat-raised-button class="btn-danger" (click)="close()">
                        <i class="flaticon-cancel"></i> Cancel</button>
                    <button class="btn-primary" color="primary" mat-raised-button
                            (click)="deleteVendors()"><i class="flaticon-trash"></i> Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template> -->

<ng-template #deleteUser let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">Delete User</h4>
        </div>
        <div class="mat-modal-body">
            <div class="text-lft are-u">Are you sure ?</div>
            <div class="row">
                <div class="col-sm-12 action-buttons multi-btns text-right">
                    <button mat-raised-button class="btn btn-cancel btn-danger" (click)="close()"> <i
                            class="flaticon-cancel"></i>Cancel</button>
                    <button mat-raised-button class="btn btn-save btn-primary" color="primary"
                        (click)="deleteVendors()"> <i class="flaticon-trash"></i>Delete</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>