import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {

  constructor(private http: HttpClient) { }

  public getOnboardingTasksList(): Observable<any> {
    return this.http.get(environment.apiUrl + 'api/auth/onboarding/TaskTypes');
  }

  public getOnboardingTasksById(id): Observable<any> {
    return this.http.get(environment.apiUrl + 'api/auth/onboarding/TaskTypes/' + id);
  }
  public getOnboardingEmployeeTasksById(id,eventId): Observable<any> {
    return this.http.get(environment.apiUrl + 'api/auth/onboarding/Tasks/getById/' + eventId +'/'+id);
  }
  public addNeWOnboardingTasks(body): Observable<any> {
    return this.http.post(environment.apiUrl + 'api/auth/onboarding/TaskTypes', body);
  }

  public editOnboardingTasks(body): Observable<any> {
    return this.http.put(environment.apiUrl + 'api/auth/onboarding/TaskTypes' , body);
  }

  public deleteOnboardingTasks(ids): Observable<any> {
    return this.http.post(environment.apiUrl + 'api/auth/onboarding/Tasks/bulkDeleteTasks' , ids);
  }

  public getOnboardingEventsList(body): Observable<any> {
    return this.http.post(environment.apiUrl + 'api/auth/onboarding/Events/filter',body);
  }

  public getOnboardingMyEventsList(body): Observable<any> {
    return this.http.post(environment.apiUrl + 'api/auth/onboarding/MyEvents/filter',body);
  }
  public generateEventsReport(body): Observable<any> {
    return this.http.post(environment.apiUrl + 'api/auth/onboarding/Reports/eventProgress',body);
  }

  public generateParticipantsProgressReport(body): Observable<any> {
    return this.http.post(environment.apiUrl + 'api/auth/onboarding/Reports/participantsProgress',body);
  }

  public getOnboardingEventsById(id): Observable<any> {
    return this.http.get(environment.apiUrl + 'api/auth/onboarding/Events/' + id);
  }

  public getOnboardingTasksEventsById(id): Observable<any> {
    return this.http.get(environment.apiUrl + 'api/auth/onboarding/Tasks/eventTasks/' + id);
  }

  public addNeWOnboardingEvents(body): Observable<any> {
    return this.http.post(environment.apiUrl + 'api/auth/onboarding/Events', body);
  }

  public editOnboardingEvent(body): Observable<any> {
    return this.http.put(environment.apiUrl + 'api/auth/onboarding/Events', body);
  }

  public activateNeWOnboardingEvents(id,body): Observable<any> {
    return this.http.post(environment.apiUrl + 'api/auth/onboarding/Events/activate/'+id, body);
  }

  // to remove
  public editOnboardingEvents(body, id): Observable<any> {
    return this.http.put(environment.apiUrl + 'api/auth/onboarding/event/' + id, body);
  }

  public deleteOnboardingEvents(ids): Observable<any> {
    return this.http.post(environment.apiUrl + 'api/auth/onboarding/Events/bulkDelete',ids);
  }

  public archiveOnboardingEvents(ids): Observable<any> {
    return this.http.post(environment.apiUrl + 'api/auth/onboarding/Events/bulkArchive',ids);
  }

  public getOnboardingMyEvents(): Observable<any> {
    return this.http.get(environment.apiUrl + 'api/auth/onboarding/myEvents');
  }

  public getOnboardingMyTasks(body): Observable<any> {
    return this.http.post(environment.apiUrl + 'api/auth/onboarding/Tasks/myTasks/filter',body);
  }

  public getOnboardingEmployeeTasks(body): Observable<any> {
    return this.http.post(environment.apiUrl + 'api/auth/onboarding/Tasks/filter',body);
  }

  public getOnboardingEventsTasks(id): Observable<any> {
    return this.http.get(environment.apiUrl + 'api/auth/onboarding/Tasks/eventTasks/'+id);
  }
  public addNeWOnboardingEmployeeTask(body): Observable<any> {
    return this.http.post(environment.apiUrl + 'api/auth/onboarding/employeeTask', body);
  }

  public addNeWOnboardingEmployeesTask(body): Observable<any> {
    return this.http.post(environment.apiUrl + 'api/auth/onboarding/Tasks/add', body);
  }

  public editOnboardingEmployeeTask(body, id,eventId): Observable<any> {
    return this.http.post(environment.apiUrl + 'api/auth/onboarding/Tasks/updateTask/'+eventId+'/' + id, body);
  }

  public bulkUpdateTasks(body): Observable<any> {
    return this.http.post(environment.apiUrl + 'api/auth/onboarding/Tasks/bulkUpdateTasks',body);
  }


  public completeEmployeeTask(id,eventId): Observable<any> {
    return this.http.get(environment.apiUrl + 'api/auth/onboarding/Tasks/completeTask/'+eventId+'/' + id);
  }

  public unCompleteEmployeeTask(id,eventId): Observable<any> {
    return this.http.get(environment.apiUrl + 'api/auth/onboarding/Tasks/unCompleteTask/'+eventId+'/' + id);
  }

  public getEmployeesByLocation(id): Observable<any> {
    return this.http.get(environment.apiUrl + 'api/auth/onboarding/getEmployeesByLocation/'+ id);
  }
}
