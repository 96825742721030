import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DashboardService} from '../../../dashboard.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { NotificationService } from "src/app/core/services/notification.service";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import {FormControl} from '@angular/forms';
import {MatSelect} from '@angular/material/select';
import {ReplaySubject, Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {MatChipInputEvent} from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {downloadFile} from "../../../../service/utils";

@Component({
    selector: 'app-monthly-attendance',
    templateUrl: './monthly-attendance.component.html',
    styleUrls: ['./monthly-attendance.component.scss']
})
export class MonthlyAttendanceComponent implements OnInit {

    displayedColumns = ['empName', 'time', 'subUnit', 'location', 'noOfWork', 'noOfHLeave', 'reason'];
    userList: MatTableDataSource<any>;
    @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;
    @ViewChild(MatSort,{static:false}) sort: MatSort;
    public totalRecords: number;
    public activePage: number;
    public pageSize: number;
    public monthlyAttendanceForm: FormGroup;
    public pageSizeOptions: number[] = [5, 10, 25, 100];
    public isLoading: boolean;
    public employeeTimeData: any;
    public showTable: boolean;
    public userFilterCtrlEmpStatus: FormControl = new FormControl();
    public userFilterCtrl: FormControl = new FormControl();
    projectData: any[];
    employeeData: any[];
    public filteredUsers: any[];
    public selectedEmployeeList: any[];
    @ViewChild('singleSelect',{static:false}) singleSelect: MatSelect;
    /** Subject that emits when the component has been destroyed. */
    protected _onDestroy = new Subject<void>();
    activityData: any[];
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    employeeStatus: any[];
    subUnitData: any[];
    locationData: any[];
    public filterEmpStatus: any[];
    public dateFilter: any[];
    showFile: boolean;
    optionYears = []
    isDownloading = false;
    ableToDownloadReport = false;

    constructor(private service: DashboardService, private modalService: NgbModal, private fb: FormBuilder, private toastr: NotificationService) {
        this.showTable = false;
        this.showFile = false;
        this.dateFilter = [{id: 'between', name: 'Between'}, {id: 'greater', name: 'Greater Than'}, {id: 'less', name: 'Less Than'}];
        this.employeeStatus = [];
        this.employeeData = [];
        this.filteredUsers = [];
        this.filterEmpStatus = [];
        this.subUnitData = [];
        this.locationData = [];
        this.selectedEmployeeList = [];

        let currentMonth = new Date().getMonth() + 1;
        let currentYear = 2017;
        while (currentYear < new Date().getFullYear()) {
            currentYear++;
            this.optionYears.push(currentYear);
        }

        this.monthlyAttendanceForm = this.fb.group({
            empid: ['', Validators.required],
            empstatusid: [''],
            hronlvperday: [''],
            hrwrperday: [''],
            limit: [''],
            page: [''],
            subunitid: [''],
            locationid: [''],
            month: ['' + currentMonth, [Validators.required]],
            year: ['' + currentYear, [Validators.required]],
            activityId: ['']
        });
    }

    public tableConfig = {
        title: true,
        desc: true
    };

    ngOnInit() {
        this.activePage = 1;
        this.pageSize = 10;
        this.totalRecords = 10;
        this.getEmployeeList();
        this.getSubUnitData();
        this.getLocationList();
        this.getEmplyeeStatus();
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.userList.filter = filterValue;
    }

    public getMonthlyAttendanceData(): void {
        this.monthlyAttendanceForm.markAsTouched()
        if(this.monthlyAttendanceForm.invalid) {
            return
        }
        var empstatus = [];
        console.log(this.selectedEmployeeList);
        for (var i = 0; i < this.selectedEmployeeList.length; i++) {
            empstatus.push(this.selectedEmployeeList[i].id.toString());
        }
        let value = this.monthlyAttendanceForm.value;
        value = {...value,
            limit: this.pageSize,
            page: this.activePage,
            empstatus: empstatus.length > 0 ? empstatus : null
        }
        this.isLoading = true;
        this.service.getMonthlyAttendanceData(value).subscribe(res => {
            this.isLoading = false;

            this.ableToDownloadReport = res && res.data && res.data.length > 0;

            if (res && res.data) {
                this.showTable = true;
                this.showFile = true;
                this.employeeTimeData = res.data;
            }
        }, error => {
            this.isLoading = false;
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error while getting report data');
        });
    }

    public getMonthlyAttendanceDataExport(): void {
        this.monthlyAttendanceForm.markAsTouched()
        if(this.monthlyAttendanceForm.invalid) {
            return
        }
        var empstatus = [];
        console.log(this.selectedEmployeeList);
        for (var i = 0; i < this.selectedEmployeeList.length; i++) {
            empstatus.push(this.selectedEmployeeList[i].id.toString());
        }

        let value = this.monthlyAttendanceForm.value;
        value = {...value,
            limit: this.pageSize,
            page: this.activePage,
            empstatus: empstatus.length > 0 ? empstatus : null
        }
        this.isDownloading = true;
        this.service.getMonthlyAttendanceDataExport(value).subscribe(res => {
            this.isDownloading = false;
            if (res) {
                downloadFile(res, "Attendance-Report-")
                this.toastr.success("Successfully downloaded.")
            }
        }, error => {
            this.isDownloading = false;
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error while exporting report');
        });
    }


    public getEmplyeeStatus(): void {
        this.service.getEmployeeStatusList().subscribe(res => {
            if (res && res.data) {
                this.employeeStatus = res.data;
                this.filterEmpStatus = this.employeeStatus.slice();
                this.userFilterCtrlEmpStatus.valueChanges
                    .pipe(takeUntil(this._onDestroy))
                    .subscribe(() => {
                        this.filterEmp();
                    });
            }
        }, error => {
        });
    }

    public getSubUnitData(): void {
        this.service.getEmployeeStatusList().subscribe(res => {
            if (res && res.data) {
                this.subUnitData = res.data;
            }
        }, error => {
        });
    }

    public getLocationList(): void {
        this.service.getLocationList().subscribe(res => {
            if (res && res.data) {
                this.locationData = res.data;
            }
        }, error => {
        });
    }

    public getEmployeeList(): void {
        this.service.getEmployeeDropdownOption().subscribe(res => {
            if (res && res.data) {
                this.employeeData = res.data;
                this.filteredUsers = this.employeeData.slice();
                console.log('filterdata', this.filteredUsers);
                // listen for search field value changes
                this.userFilterCtrl.valueChanges
                    .pipe(takeUntil(this._onDestroy))
                    .subscribe(() => {
                        this.filterUsers();
                    });
            }

        }, error => {
        });
    }

    protected filterUsers() {
        if (!this.employeeData) {
            return;
        }
        // get the search keyword
        let search = this.userFilterCtrl.value;
        if (!search) {
            this.filteredUsers = this.employeeData.slice();
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the search
        this.filteredUsers =
            this.employeeData.filter(emp => emp.name.toLowerCase().indexOf(search) > -1);
    }

    protected filterEmp() {
        if (!this.employeeStatus) {
            return;
        }
        // get the search keyword
        let search = this.userFilterCtrlEmpStatus.value;
        if (!search) {
            this.filterEmpStatus = this.employeeStatus.slice();
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the search
        this.filterEmpStatus =
            this.employeeStatus.filter(emp => emp.name.toLowerCase().indexOf(search) > -1);
    }

    add(data): void {
        const index = this.selectedEmployeeList.indexOf(data);
        if (index === -1) {
            this.selectedEmployeeList.push(data);
        }
        this.monthlyAttendanceForm.patchValue({'empstatusid': ''});
    }

    remove(data) {
        console.log('remove', data);
        const index = this.selectedEmployeeList.indexOf(data);
        if (index >= 0) {
            this.selectedEmployeeList.splice(index, 1);
        }
    }

    resetData() {
        this.monthlyAttendanceForm.reset();
        this.selectedEmployeeList = [];

    }
}

