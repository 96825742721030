<div [hidden] = "isLoad" id="header-page" class="ng-star-inserted header-page">
    <div class="page-header ng-star-inserted">
        <div class="page-block ng-star-inserted">
        <div class="row align-items-center">
            <div class="col-md-8">
            <div class="page-header-title">
                <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted" >  {{this.type === 'N' ? 'News' : 'Documents'}} > {{this.name}} </h4></span>
            </div>
            </div>
            <div class="col-md-4"></div>
        </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isLoad" [isLoading]="isLoad"></app-loading>

<mat-card-content [hidden] = "isLoad" >
    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header >
                <mat-panel-title>
                    <div class="row">
                        <div class="col-12">
                            {{this.type === 'N' ? 'News' : 'Documents'}} Acknowledgment Status
                        </div>
                        <!-- <div class="col-12" style="color: #9c9c9c">
                            Published on : {{getDateFormat(row.publishDate)}}
                        </div> -->
                    </div>
                </mat-panel-title>
            
            </mat-expansion-panel-header>
           
            <br>
            <div>
                <mat-checkbox [ngModelOptions]="{standalone: true}" [(ngModel)]="checkEmp" >
                   Show Acknowledgements of Past Employees 
                </mat-checkbox>
            </div>
            <br>
            <div class="action-buttons col-md-12 text-right">
                <button mat-raised-button class="btn-primary"(click)="generateAsk()" color="primary">Generate</button>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</mat-card-content>


<br><br>
<div class="page-body no-title " [hidden]="isLoad">
    <div class="row">
        <div class="col-sm-12">
            <div class="row top-tools">
             
        
            </div>
            <app-card >
                <div class="dt-responsive" >
                    <div class="table-responsive">
                        <mat-card-content>
                            <mat-table [dataSource]="dataSource" matSort >
                              
                                <ng-container matColumnDef="empName">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Employee Name</mat-header-cell>
                                    <mat-cell   *matCellDef="let row">
                                            {{row.empName}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="state">
                                    <mat-header-cell *matHeaderCellDef>Acknowledged</mat-header-cell>
                                    <mat-cell   *matCellDef="let row" style="font-size: 12px">
                                        {{row.state  ? 'Yes' : 'No'}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="ackDate">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>	Date Acknowledged </mat-header-cell>
                                    <mat-cell   *matCellDef="let row" style="font-size: 12px">
                                        {{row.ackDate  | date : 'dd-MM-yyyy'}}
                                    </mat-cell>
                                </ng-container>
                           
                               
                      
                               
                                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: columns;">
                                </mat-row>
                            </mat-table>
                            <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
                            <mat-paginator [pageSizeOptions]="[50, 75]" showFirstLastButtons></mat-paginator>
                        </mat-card-content>
                    </div>
                </div>
            </app-card>
        </div>
    </div>
</div>