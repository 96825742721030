<div id="header-page" class="ng-star-inserted header-page">
    <div class="page-header ng-star-inserted">
        <div class="page-block ng-star-inserted">
            <div class="row align-items-center">
                <div class="col-md-8">
                    <div class="page-header-title">
                        <span class="ng-star-inserted"></span><span class="ng-star-inserted">
                        <h4 class="m-b-10 ng-star-inserted" *ngIf="!isEdit">{{lang.get("add")}} {{lang.get("documentTemplate")}} </h4>
                        <h4 class="m-b-10 ng-star-inserted" *ngIf="isEdit">{{lang.get("edit")}} {{lang.get("documentTemplate")}} </h4>
                    </span>
                    </div>
                </div>
                <div class="col-md-4"></div>
            </div>
        </div>
    </div>
</div>
<app-loading [isLoading]="this.loadData == true"></app-loading>
<div class="card " *ngIf="this.loadData == false">
    <div class="card-content table-responsive table-full-width" >

                <form class="form-material full-width-inputs " action="javascript:" [formGroup]="userForm" *ngIf="!isUserAdded">
                    <div class="row form-group">
                        <div class="col-md-6">
                            <mat-form-field>
                                <mat-label>{{lang.get("name")}}</mat-label>
                                <input [readonly] = "!update"  pattern= "*" type="text" matInput class="form-control" maxlength="40" required="" id="name" formControlName="name">
                                <mat-error  *ngIf="!userForm.controls.name.valid && userForm.controls.name.touched">
                                    {{ validationError.required }}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-md-6">
                            <mat-form-field>
                                <mat-label>{{lang.get("category")}}</mat-label>
                                <input [readonly] = "!update" pattern= "*" type="text" matInput class="form-control" maxlength="40" required="" id="name" formControlName="category">
                                <mat-error  *ngIf="!userForm.controls.name.valid && userForm.controls.name.touched">
                                    {{ validationError.required }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-md-12">
                            <span class="form-bar"></span>
                            <mat-label><h5>{{lang.get("template")}}</h5></mat-label>
                            <angular-editor  formControlName="template" [config]="config"></angular-editor>
                            <span class="validation-error"
                                  *ngIf="!userForm.controls.template.valid && userForm.controls.template.touched">{{ validationError.required }}</span>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="multi-btns col-md-12 text-right">
                            <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i> {{lang.get("cancel")}}</button>
                            <button [hidden]="!update" mat-raised-button class="btn-primary"  (click)="submitAddJobTitle()" color="primary" [disabled]="!userForm.valid"><i class="flaticon-diskette"></i>
                            <ng-container *ngIf="!isEdit">{{lang.get("save")}}</ng-container>
                            <ng-container  *ngIf="isEdit">{{lang.get("update")}}</ng-container>
                            </button>
                        </div>
                    </div>
<!--                    <ng-container formGroupName="pageProperty">-->
<!--                        <div class="col-md-6">-->
<!--                            <mat-form-field>-->
<!--                                <mat-label>Page Size</mat-label>-->
<!--                                <mat-select class="form-control" required="" formControlName="size" >-->
<!--                                    <mat-option value="" disabled></mat-option>-->
<!--                                    <mat-option value="A4">A4</mat-option>-->
<!--                                    <mat-option value="A3">A3</mat-option>-->
<!--                                    <mat-option value="B5">B5</mat-option>-->
<!--                                </mat-select>-->
<!--                            </mat-form-field>-->
<!--                        </div>-->
<!--                        <div class="col-md-6">-->
<!--                            <mat-form-field>-->
<!--                                <mat-label>Margin left</mat-label>-->
<!--                                <input type="number" matInput class="form-control" required="" id="ml" formControlName="ml">-->
<!--                            </mat-form-field>-->
<!--                        </div>-->
<!--                        <div class="col-md-6">-->
<!--                            <mat-form-field>-->
<!--                                <mat-label>Margin Right</mat-label>-->
<!--                                <input type="number" matInput class="form-control" required="" id="mr" formControlName="mr">-->
<!--                            </mat-form-field>-->
<!--                        </div>-->
<!--                        <div class="col-md-6">-->
<!--                            <mat-form-field>-->
<!--                                <mat-label>Margin Top</mat-label>-->
<!--                                <input type="number" matInput class="form-control" required="" id="mt" formControlName="mt" >-->
<!--                            </mat-form-field>-->
<!--                        </div>-->
<!--                        <div class="col-md-6">-->
<!--                            <mat-form-field>-->
<!--                                <mat-label>Margin Bottom</mat-label>-->
<!--                                <input type="number" matInput class="form-control" required="" id="mb" formControlName="mb"-->
<!--                                       required="">-->
<!--                            </mat-form-field>-->
<!--                        </div>-->
<!--                    </ng-container>-->
                   
                </form>
<!--            </app-card>-->
<!--        </div>-->
    </div>
</div>
