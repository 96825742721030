import { NgModule, NO_ERRORS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './services/interceptor.service';
import { AuthService } from './services/auth.service';
import { NavComponent } from './nav/nav.component';
import { HrmSharedModule } from '../hrmshared/hrmshared.module';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
  NavComponent,
  // ChangePasswordComponent
  ],
  exports: [
    NavComponent
  ],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule
    // SharedModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    AuthService
  ],
  schemas: [ NO_ERRORS_SCHEMA ]
})
export class CoreModule { }
