import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { AbstractControl, FormControl, FormGroupDirective, NgForm, ValidatorFn } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { debounceTime, map, startWith, takeUntil, tap } from "rxjs/operators";
import { DashboardService } from "src/app/dashboard/dashboard.service";

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

function autocompleteObjectValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (typeof control.value === "string" && control.value) {
      return { invalidAutocompleteObject: { value: control.value } };
    }
    return null; /* valid option selected */
  };
}

@Component({
  selector: "app-lazy-employee-dropdown",
  templateUrl: "./lazy-employee-dropdown.component.html",
  styleUrls: ["./lazy-employee-dropdown.component.scss"],
})
export class LazyEmployeeDropdownComponent implements OnInit {
  @Input() title: string;
  @Input() required: boolean;
  //one
  @Input() selectedEmployee: any;
  @Output() employeeSelected = new EventEmitter<any>();
  //multi
  @Input() multiTokens: boolean = false;
  @Input() selectedEmployees: Array<any> = [];
  @Output() employeesSelected = new EventEmitter<Array<any>>();

  @ViewChild("employeeInput") employeeInput: ElementRef;

  public empCtrl: FormControl = new FormControl("", { validators: [autocompleteObjectValidator()] });
  public searching: boolean = false;
  public filteredEmployees = [];

  matcher = new MyErrorStateMatcher();

  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(private service: DashboardService) {}

  public validation_msgs = {
    empCtrl: [
      {
        type: "invalidAutocompleteObject",
        message: "Employee name not recognized. Click one of the autocomplete options.",
      },
      { type: "required", message: "Employee is required." },
    ],
  };

  ngOnInit() {
    this.empCtrl.valueChanges.subscribe((change) => {
      startWith("");
      if (change == null || change == undefined) {
        this.filteredEmployees = [];
      } else {
        if (typeof change === "string") {
          this.filter(change as string);
        }
      }
    });
  }

  private filter(filterBy: string) {
    if (!filterBy) {
      this.filteredEmployees = [];
      return;
    }

    if (filterBy.length >= 2) {
      this.searching = true;
      this.service.getFilteredEmployeeDropdown(filterBy.toLowerCase()).subscribe((resp) => {
        let employeeList = [];
        if (resp && resp.data) {
          resp.data.map((e) => {
            employeeList.push({ name: e.name, id: e.id });
          });

          if(this.multiTokens && this.selectedEmployees){
            employeeList = employeeList.filter(emp => this.selectedEmployees.every(s => s.id != emp.id));
          }
          this.filteredEmployees = employeeList;
          this.searching = false;
        }
      });
    }
  }

  keyup(event) {
    if (!this.empCtrl.value) {
      this.employeeSelected.emit(null);
      this.empCtrl.setValue(null);
      this.selectedEmployee = null;
    }
  }

  selectionChanged(event: any) {
    if (this.multiTokens) {
      //add token
      this.selectedEmployees.push(event.option.value);
      this.employeeInput.nativeElement.value = "";
      this.empCtrl.setValue(null);
      this.employeeInput.nativeElement.blur();
      this.employeesSelected.emit(this.selectedEmployees);
    } else {
      this.employeeSelected.emit(event.option.value);
    }
  }

  removeItem(id): void {
    this.selectedEmployees = this.selectedEmployees?.filter((emp) => emp.id !== id);
    this.employeesSelected.emit(this.selectedEmployees);
  }

  public displayEmployeeByName(option) {
    if (!option) {
      return "";
    } else {
      if (option.name && this.filteredEmployees.length == 0) {
        this.filteredEmployees.push(option);
      }
      return option.name;
    }
  }

  public reset(){
    this.selectedEmployees = [];
    this.selectedEmployee = '';
  }
}
