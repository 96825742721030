import { Component, NgZone, OnInit, ViewChild } from "@angular/core";
import { DashboardService } from "../../../dashboard.service";
import { NotificationService } from "src/app/core/services/notification.service";
import { FormGroup, FormBuilder } from "@angular/forms";
import { saveAs } from "file-saver-es";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort, Sort } from "@angular/material/sort";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { Router } from "@angular/router";
@Component({
  selector: "app-my-leave-usage",
  templateUrl: "./my-leave-usage.component.html",
  styleUrls: ["./my-leave-usage.component.scss"],
})
export class MyLeaveUsageComponent implements OnInit {
  public filterForm: FormGroup;
  public employeeList = [];
  public leaveTypeList = [];
  public jobTitleList = [];
  public locationList = [];
  public subUnitList = [];
  public leavePeriodList = [];
  public leaveUsageList = [];
  public rowsOnPage = 100;
  public filterQuery = "";
  public isLoading: boolean;
  public err: string;
  public isExpanded: boolean = true;
  public period: string = "";
  public periodEnum: string = "";
  public next: boolean = true;
  public prev: boolean = true;
  // @ViewChild(MatSort, {static: false}) sort: MatSort;
  // @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  // paging = {
  //     pageSize: 10,
  //     totalRecords: 0,
  //     pageIndex: 0,
  //     pageSizeOptions: [10, 25, 50, 100]
  // };
  public columns = [
    "employeeId",
    "employeeName",
    "leaveTypeName",
    "leavePeriod",
    "entitlements",
    "pending",
    "scheduled",
    "taken",
    "available",
    "overdrawn",
    "netBalance",
    // "icons",
  ];

  pageSize: number = 10;
  pageIndex: number = 0;
  total: number = 0;

  public sortBy = [];

  constructor(
    private router: Router,
    private ngZone: NgZone,
    public toastr: NotificationService,
    public service: DashboardService,
    public fb: FormBuilder
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.period = " Current Period";
    this.periodEnum = "cp";
    this.next = true;
    this.prev = true;

    this.service.getLeavePeriodForLeaveUsage().subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.leavePeriodList = resp.data;
          this.getLeaveUsage();
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
      }
    );
  }

  public createForm(): void {
    this.filterForm = this.fb.group({
      // employeeId: null,
      // eligibleEmployeeOnly: false,
      // includePastEmployees: false,
      // jobTitleId: null,
      // leaveTypeId: null,
      // locationId: null,
      // subUnitId: null,
      // generateFor: null,
      leavePeriodId: "",
    });
  }

  public getLeaveUsage(): void {
    this.isLoading = true;

    let leavePeriodId = "";
    switch (this.periodEnum) {
      case "cp":
        leavePeriodId = this.leavePeriodList[1].id;
        break;
      case "pr":
        leavePeriodId = this.leavePeriodList[0].id;
        break;
      case "np":
        leavePeriodId = this.leavePeriodList[2].id;
        break;
    }
    this.filterForm.patchValue({
      leavePeriodId: leavePeriodId,
    });
    this.err = "";

    this.service.getMyLeaveUsage(this.filterForm.value, this.pageIndex, this.pageSize, this.sortBy).subscribe(
      (resp) => {
        if (resp && resp.data && resp.data.totalElements > 0) {
          this.total = resp.data.totalElements;
          this.dataSource.data = resp.data.content;
        } else {
          this.dataSource.data = [];
          this.toastr.error("No search result found");
        }
        this.isLoading = false;
      },
      (error) => {
        this.toastr.error("Error in getting leave usage");
        this.isLoading = false;
      }
    );
  }

  handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getLeaveUsage();
  }

  sortData(sort: Sort) {
    if (sort.direction) {
      this.sortBy = [{ sortBy: sort.active, sortDir: sort.direction }];
    } else {
      this.sortBy = [];
    }
    this.getLeaveUsage();
  }

  downloadFile = (data): void => {
    const blob = new Blob([data], { type: "text/csv" });
    saveAs(blob, "leaveusage.csv");
  };

  public generateCSV(): void {
    this.isLoading = true;
    this.service.exportLeaveUsage(this.filterForm.value).subscribe(
      (resp) => {
        this.isLoading = false;
        if (resp && resp.data) {
          this.downloadFile(resp.data);
        }
      },
      (error) => {
        this.toastr.error("Error in getting leave usage");
        this.isLoading = false;
      }
    );
  }

  public getData(label, value, data): any {
    const arr = [];
    data.map((d) => {
      const obj = {};
      obj["label"] = d[label];
      obj["value"] = d[value];
      arr.push(obj);
    });

    return arr;
  }

  public onNext(): void {
    if (this.periodEnum === "pr") {
      this.period = " Current Period";
      this.periodEnum = "cp";
      this.next = true;
      this.prev = true;
      this.getLeaveUsage();
    } else if (this.periodEnum === "cp") {
      this.period = "Next Period";
      this.periodEnum = "np";
      this.next = false;
      this.prev = true;
      this.getLeaveUsage();
    } else if (this.periodEnum === "np") {
      this.period = "Next Period";
      this.periodEnum = "np";
      this.next = false;
      this.prev = true;
    }
  }

  public onPrev(): void {
    if (this.periodEnum === "pr") {
      this.period = " Previous Period";
      this.periodEnum = "pr";
      this.prev = false;
      this.next = true;
    } else if (this.periodEnum === "cp") {
      this.period = " Previous Period";
      this.periodEnum = "pr";
      this.prev = false;
      this.next = true;
      this.getLeaveUsage();
    } else if (this.periodEnum === "np") {
      this.period = " Current Period";
      this.periodEnum = "cp";
      this.prev = true;
      this.next = true;
      this.getLeaveUsage();
    }
  }

  public openTimeLine(empId, LeaveId, leavePeriodId): void {
    this.ngZone.run(() => {
      void this.router.navigate(["/leave/reports/my-leave-usage/" + empId + "/" + LeaveId + "/" + leavePeriodId]);
    });
  }
}
