import * as moment from 'moment';
import {saveAs} from 'file-saver-es';
import {FormGroup} from "@angular/forms";

export const downloadFile = (data, name?): void => {
    name = name ? name : 'Download';
    let csvContent = "data:text/csv;charset=utf-8,";
    const time = moment().endOf('day').format('YYYY-MM-DD_HHmmss')
    const blob = new Blob([data], {type: csvContent});
    const fileName = `${name}-${time}.csv`;
    saveAs(blob, fileName);
}

export const markFormGroupTouched = (formGroup: FormGroup) => {
    (<any>Object).values(formGroup.controls).forEach(control => {
        control.markAsTouched();

        if (control.controls) {
            markFormGroupTouched(control);
        }
    });
}
