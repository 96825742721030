import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {DashboardService} from '../../../dashboard.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotificationService } from "src/app/core/services/notification.service";
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {saveAs} from 'file-saver-es';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {MatPaginator} from '@angular/material/paginator';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import {MatDialog} from '@angular/material/dialog';
import { map, startWith } from 'rxjs/operators';
import { LanguageConfig } from 'src/app/Language-config';
import { AuthService } from 'src/app/core/services/auth.service';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels, NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { CrudService } from 'src/app/core/services/base-crud.service';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss'],
  providers: [CrudService],
})
export class LocationsComponent implements OnInit {

  public dialogRef: any;
  public filterQuery = '';
  public userList: Array<any>;
  public isLoading: boolean;
  public isUserAdded: boolean;
  public err: string;
  public modalRef: any;
  public submitted = false;
  public correct : boolean = true;

  public userForm: FormGroup;
  public isEdit: boolean;
  public editId: string;
  public countryName : string;
  selectedUsers: any[] = [];
  selectedCheckBoxList = [];
  public countryList: Array<any>;
  public timeZoneList: Array<any>;
  public filteredOptions:  Array<any>;
  public filteredTimeZone:  Array<any>;
  public lang = new Map();
  public validationError = {
    required: 'Required'
  };
  public longitude: number;
  public latitude: number;
  public radius: number;

  @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
  @ViewChild('addUser', {static: false}) addUser;
  @ViewChild('deleteUser', {static: false}) deleteUser;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  //public columns = ['select' , 'location', 'city', 'country', 'phone', 'numberOfEmployees', 'edit' ];
  public columns = ['select' , 'location', 'city', 'country', 'phone', 'numberOfEmployees'];
  constructor(private service: DashboardService,
              private  authService: AuthService,
              private fb: FormBuilder,
              private toastr: NotificationService,
              private dialog: MatDialog,
              private crudeService: CrudService<any>) {
    this.createForm();
    this.lang=LanguageConfig.map;
    this.countryList=[];
    this.crudeService.setEntityName("location");
  }
  public tableConfig = {
    name: true
  };

  public permissions : any;
  public read = false;
  public write = false ;
  public update = false;
  public delete = false;
  public editClass = "";
  // QR Code configuration
  public qrCodeElementType = NgxQrcodeElementTypes.URL;
  public qrCodeCorrectionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  public qrCodeValue = 'https://www.techiediaries.com/';  
    
  checkPermission(module,tabs) : void 
  {
    this.editClass = "";
      this.permissions = JSON.parse(this.authService.getPermissions());

      this.read = false ;
      this.write = false ;
      this.delete = false ;
      this.update = false;
      if(this.permissions != undefined && this.permissions != null )
      {
          for(let p of this.permissions)
          {
             
              if(p.id === module)
              {
                 
                  if(p.modules != undefined)
                  {
                    
                      for(let tab of p.modules)
                      {
                          if(tab.id === tabs)
                          {
                            
                              for(let move of tab.permits)
                              {
                                  if(move.id === "u")
                                  {
                                      this.update = true;
                                      this.editClass="cursor-pointer";
                                  }
                                  else if(move.id === "r")
                                  {
                                      this.read = true;
                                  }
                                  
                                  else if(move.id === "d")
                                  {
                                      this.delete = true;
                                  }
                                  else if( move.id === "c")
                                  {
                                    this.write = true;
                                  }
                              }
                          }
                      }
                  }
                 
              }
            
          }
      }
     

  }

  doNothing() : void 
  {
    return ;
  }
  ngOnInit() {
    this.isLoading = true;
    this.isEdit = false;
    this.isUserAdded = false;
    this.checkPermission("admin","ad_og");
    this.service.getCountryList().subscribe(resp => {
      this.countryList = resp && resp.data;
      this.getJobCategoryList();
    });
    
   
  }
  ngAfterViewInit()
  {
    this.service.getTimeZoneList().subscribe(resp => {
      this.timeZoneList = resp && resp.data;
    });
    this.initValuesDetection();
  }

  initValuesDetection()
  {
    this.filteredOptions=this.countryList;
    
    this.userForm.controls['contrycode'].valueChanges.subscribe(change => {
      if(change == null || change == undefined)
      {
        startWith('');
      
      } // Value inside the input field as soon as it changes
      else{
     
      this.filterCountry(change);
      }
    });
    this.filteredTimeZone=this.timeZoneList;
    this.userForm.controls['timezone'].valueChanges.subscribe(change => { // Value inside the input field as soon as it changes
     
      if(change == null || change == undefined)
      {
        startWith('');
        this.correct=true;
      } // Value inside the input field as soon as it changes
      else{
     
        this.filterTimeZones(change);
      }
    });
  }
  public getCountryByIso(option) {
    
      this.correct =true;
    
    return this.countryList.find(x=>x.iso === option).name;
  }

  public getTimeZoneByName(option) {
    
    this.correct =true;
    return this.timeZoneList.find(x=>x === option);
}
  checkCountryChange(change )
  {
    var x = this.filteredOptions.find(x => x.iso === change);
    if(!x)
    {
      this.correct=false;
    }
    
  }

  checkTimeZonesChange(change )
  {
    if(!this.timeZoneList.includes(change))
    {
      this.correct=false;
    }
  }
  protected filterCountry(change : String) {
    this.filteredOptions = this.countryList;
    if (!this.countryList) {
        return;
    }
    
    // get the search keyword
    if (!change) {
     
      this.filteredOptions =[];
        return;
    } else {
      this.checkCountryChange(change);
      change = change.toLowerCase();
    }
    // filter the search
    this.filteredOptions =
        this.countryList.filter(co => co.name.toLowerCase().indexOf(change) > -1);
      
}


protected filterTimeZones(change : String) {
  if (!this.timeZoneList) {
      return;
  }
  
  // get the search keyword
  if (!change) {
      this.correct= true;
      this.filteredTimeZone = [];
      return;
  } else {
    
    this.checkTimeZonesChange(change)
    change = change.toLowerCase();
  }
  // filter the search
  this.filteredTimeZone=
      this.timeZoneList.filter(ti => ti.toLowerCase().indexOf(change) > -1);
}

  public createForm(): void {
    this.userForm = this.fb.group({
      name: [''],
      address: [''],
      city: [''],
      contrycode: [''],
      description: [''],
      eeoenable: [true],
      fax: [''],
      location: [''],
      mobile: [''],
      phone: [''],
      postalcode: [''],
      state: [''],
      status: [''],
      timezone: ['s'],
      longitude:  [''], //, Validators.pattern("^[.][0-9]{10}$")]
      latitude: [''],
      radius: [''],
    });
  }

  public addNewUser() {
    this.dialogRef = this.dialog.open(this.addUser, {
      width: '768px',
      height: 'auto'
    });
    this.isEdit = false;

    
    this.userForm.reset();
  }
 


  
  public close(): void {
    // this.modalRef.close();
    this.dialogRef.close();
  }

  public getJobCategoryList(): void {
    this.crudeService.getAll().subscribe(user => {
      if (user && user.data) {
        this.isLoading = false;
        this.userList = user.data;
        this.userList.map(user1 => {
          const c = this.countryList.filter(f => {
            return f.iso === user1.contrycode;
          })[0];
          user1.country = c && c.name;
        });
        this.err = '';
        this.dataSource = new MatTableDataSource<any>(this.userList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    }, error => {
      this.isLoading = false;
      this.err = 'Error while getting Location';
    });
  }


  public saveLocation(): void {
    this.submitted = true;

    if (this.userForm.invalid === true) {
        return;
    }
    if (this.isEdit) {
      this.edit();
    } else {
      this.add();
    }
  }

  public add(): void {
    this.isUserAdded = true;
    this.crudeService.create(this.userForm.value).subscribe(user => {
      this.isUserAdded = false;
      this.toastr.success('Location added successfully');
      // this.modalRef.close();
      this.dialogRef.close();
      this.userForm.reset();
      this.getJobCategoryList();
    }, error => {
      const err = error.error && error.error.msg;
      this.isUserAdded = false;
      this.toastr.error(err || 'Error in adding job category.Please try again.');
    });
  }

  public edit(): void {
    this.isUserAdded = true;
    this.crudeService.update(this.userForm.value, this.editId).subscribe(user => {
      this.isUserAdded = false;
      this.toastr.success('Location updated successfully');
      // this.modalRef.close();
      this.dialogRef.close();
      this.getJobCategoryList();
    }, error => {
      const err = error.error && error.error.msg;
      this.isUserAdded = false;
      this.toastr.error(err || 'Error in updating job category.Please try again.');
    });
  }

  public editUser(id): void {
    this.isEdit = true;
    this.isUserAdded = true;
    this.dialogRef = this.dialog.open(this.addUser, {
      width: '768px',
      height: 'auto'
    });
    this.crudeService.getById(id).subscribe(user => {
      if (user && user.data) {
        this.userForm.patchValue(user.data);
        this.isUserAdded = false;
       
        this.editId = id;
      }
    }, error => {
      this.isUserAdded = false;
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error getting Location');
    });

    this.service.getLocationQRCodeInformation(id).subscribe(result => {
      if (result) {
        this.qrCodeValue = result.data;
      }
    }, error => {
      this.isUserAdded = false;
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error getting Location QR Code Information');
    });    
  }


  public deleteJobCategory(): void {
    this.crudeService.delete(this.selectedCheckBoxList).subscribe(user => {
      if (user) {
        this.close();
        this.getJobCategoryList();
        this.toastr.success('Location deleted !');
      }
    }, error => {
      console.log(error);
      const err = error.error && error.error.msg;
      this.close();
      this.toastr.error(err || 'Error in deleting Location');
    });
    this.selectedCheckBoxList = [];
  }

  onCheckBoxChange($event: MatCheckboxChange, id: any) {
    if (!this.alreadySelected($event.source) && $event.checked) {
      this.selectedCheckBoxList.push($event.source.value);
    } else if (!$event.checked) {
      this.selectedCheckBoxList = this.selectedCheckBoxList.filter(
          value => {
            return value !== $event.source.value;
          }
      );
    }
  }

  alreadySelected(item: MatCheckbox): boolean {
    this.selectedCheckBoxList.forEach(
        value => {
          if (item === value) {
            return true;
          }
        }
    );
    return false;
  }
 

  onSelectAllClicked() {
    this.selectedCheckBoxList = [];
    this.matCheckBoxes.forEach(
        item => {
          this.selectedCheckBoxList.push(item.value);
          item.checked = true;
        }
    );
  }

  onDeSelectAllClicked() {
    this.selectedCheckBoxList = [];
    this.matCheckBoxes.forEach(
        item => {
          item.checked = false;
        }
    );
  }

  onDeleteSelected() {
    this.dialogRef = this.dialog.open(this.deleteUser, {
      width: '400px',
      height: 'auto'
    });
    
  }
  onModelChange(value: string) {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
  exportToCsv() {
    const csv = this.dataSource.filteredData.map(
        row => {
          return [
              row.location,
              row.city,
              row.country,
              row.phone,
              row.numberOfEmployees,
          ].join(',') + '\n';
        }
    );
    csv.unshift(['location', 'city', 'countryCode', 'phone', 'numberOfEmployees'].join(',') + '\n');
    const exportDate = new Date();
    const exportFileName = `Csv-export-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}.csv`;
    saveAs(new Blob(csv, {type: 'text/csv;charset=utf-8'}), exportFileName);
  }

  saveAsPdf() {
    if (this.dataSource.filteredData.length <= 0) {
      this.toastr.error('No Data to export');
      return;
    }
    const body = [];
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      body.push(
          [
            this.dataSource.filteredData[i].location,
            this.dataSource.filteredData[i].city,
            this.dataSource.filteredData[i].country,
            this.dataSource.filteredData[i].phone,
            this.dataSource.filteredData[i].numberOfEmployees
          ]);
    }
    const doc = new jsPDF();
    autoTable(doc, {
      head: [['location', 'city', 'countryCode', 'phone', 'numberOfEmployees']],
      body: body
    });
    const exportDate = new Date();
    const exportFileName = `location-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}}`;
    doc.save(exportFileName);
  }

  downloadQRCode(): void {
    const fileNameToDownload = this.userForm + 'qrcode';
     const base64Img = document.getElementsByClassName('qrCode')[0].children[0]['src'];
     fetch(base64Img)
        .then(res => res.blob())
        .then((blob) => {
           // IE
           if (window.navigator && (window.navigator as any).msSaveOrOpenBlob){
            (window.navigator as any).msSaveOrOpenBlob(blob,fileNameToDownload);
           } else { // Chrome
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              link.download = fileNameToDownload;
              link.click();
           }
        })
      }   
}

