<div class="page-body">
  <div class="row">
    <div class="col-sm-12">
      <app-card [title]="'List of Company'">
        <div class="dt-responsive" *ngIf="userList">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6">
              <div><label  class="label-control">Show
                  <select class="form-control input-sm full-data-show-entry" [(ngModel)]="rowsOnPage">
                    <mat-option [ngValue]="5">5</mat-option>
                    <mat-option [ngValue]="10">10</mat-option>
                    <mat-option [ngValue]="25">25</mat-option>
                    <mat-option [ngValue]="50">50</mat-option>
                    <mat-option [ngValue]="100">100</mat-option>
                  </select>
                  entries
                </label>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6">
              <div style="text-align: right;">
                <label class="float-label">Search:
                  <input type="search"  maxlength="30" [(ngModel)]="filterQuery" class="form-control input-sm full-data-search" placeholder="Search name">
                </label>
                <button raised-button class="btn btn-primary ripple light" (click)="addNewUser()"> + </button>
              </div>
            </div>
          </div>
          <app-loading *ngIf="isLoading && !err" [isLoading]="isLoading && !err"></app-loading>
          <div class="table-responsive">
            <!-- <table class="table table-striped table-bordered full-data-table" [mfData]="userList | dataFilter : filterQuery : 'name'" #mf="mfDataTable"
                   [mfRowsOnPage]="rowsOnPage" [(mfSortBy)]="sortBy" [(mfSortOrder)]="sortOrder">
              <thead>
              <tr>
                <th>#</th>
                <th>
                  <mfDefaultSorter  by="name">Name</mfDefaultSorter>
                </th>
                <th>
                  <mfDefaultSorter  by="companyemail">Company Email</mfDefaultSorter>
                </th>
                <th>
                  <mfDefaultSorter  by="status">Status</mfDefaultSorter>
                </th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
              <tr>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let user of mf.data;let i = index;">
                <td>{{ i + 1}}</td>
                <td>{{ user.name }}</td>
                <td>{{ user.companyemail }}</td>
                <td>{{ user.status }}</td>
                <td><i class="fa fa-edit" (click)="editUser(user.id)"></i></td>
                <td><i class="fa fa-trash" (click)="deleteUserForm(user.id)"></i></td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td colspan="6">
                  <mfBootstrapPaginator class="pagination-main f-right"></mfBootstrapPaginator>
                </td>
              </tr>
              </tfoot>
            </table> -->
          </div>
          <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
        </div>
      </app-card>
    </div>
  </div>
</div>

<ng-template #addUser let-modal>
  <div class="mat-modal-header">
    <h4 class="mat-title-modal">
      <span *ngIf="!isEdit">Add</span>
      <span *ngIf="isEdit">Edit</span>
      Company
    </h4>
  </div>
      
      <div class="mat-modal-body">
      <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>

      <form class="form-material full-width-inputs" action="javascript:" [formGroup]="userForm" *ngIf="!isUserAdded">
        <div class="form-row">
          <div class="row ml-2"><h4>Personal Detail</h4></div>
            <div class="form-row">
              <div class="col-md-4">
                <mat-form-field class="form-element">
                  <input class="form-control" maxlength="30"  formControlName="name" matInput type="text">
                  <span class="form-bar"></span>
                  <mat-label for="title">Name</mat-label>
                  <mat-error class="validation-error" *ngIf="!userForm.controls.name.valid && userForm.controls.name.touched">{{ validationError.required }}</mat-error>
                </mat-form-field>
              </div>
              <div class="form-group col-md-4">
                <mat-form-field class="form-element">
                  <mat-select class="form-control" formControlName="organisationid">
                    <ng-container *ngFor="let o of organizationList;let i = index;">
                      <mat-option value="{{o.id}}" *ngIf="i === 0">{{o.name}}</mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-label>Organization</mat-label>
                  </mat-form-field>
                <!-- <span class="validation-error" *ngIf="!userForm.controls.organisationid.valid && userForm.controls.organisationid.touched">{{ validationError.required }}</span> -->
              </div>
              <div class="col-md-4">
                <mat-form-field class="form-element">
                  <mat-select class="form-control" formControlName="region">
                    <ng-container *ngFor="let r of regionList">
                      <mat-option value="{{r.id}}">{{r.name}}</mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-label>Region</mat-label>
                  <mat-error class="validation-error" *ngIf="!userForm.controls.region.valid && userForm.controls.region.touched">{{ validationError.required }}</mat-error>
                  </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field class="form-element">
                <mat-select formControlName="status" class="form-control">
                  <mat-option [value]="'enable'"> Enable</mat-option>
                  <mat-option [value]="'disable'">Disable</mat-option>
                </mat-select>
                <mat-label class="float-label label-after">Status</mat-label>
                <mat-error class="validation-error" *ngIf="!userForm.controls.status.valid && userForm.controls.status.touched">{{ validationError.required }}</mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-4">
                <mat-form-field class="form-element">
                <input formControlName="companyemail"  maxlength="30" matIput class="form-control" required="">
                <span class="form-bar"></span>
                <mat-label class="float-label label-after" for="title">Company Email</mat-label>
                <mat-error class="validation-error" *ngIf="!userForm.controls.companyemail.valid && userForm.controls.companyemail.touched">{{ validationError.required }}</mat-error>
                </mat-form-field>
              </div>
            </div>
          <div class="row ml-2 my-2"><h4>Address</h4></div>
          <div class="form-row" formArrayName="addresses" *ngFor="let item of userForm.get('addresses').controls; let i = index;">
              <ng-container [formGroupName]="i">
                <div class="col-md-4">
                  <mat-form-field class="form-element">
                  <input formControlName="addresslineone"  maxlength="30" matInput class="form-control" required="">
                  <span class="form-bar"></span>
                  <mat-label class="float-label label-after" for="title">Address Line 1</mat-label>
                  <mat-error class="validation-error" *ngIf="!userForm.controls.addresses.controls[i].controls.addresslineone.valid && userForm.controls.addresses.controls[i].controls.addresslineone.touched">{{ validationError.required }}</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field class="form-element">
                  <input formControlName="addresslinetwo" maxlength="30"  matInput class="form-control" required="">
                  <span class="form-bar"></span>
                  <mat-label class="float-label label-after">Address Line 2</mat-label>
                  <mat-error class="validation-error" *ngIf="!userForm.controls.addresses.controls[i].controls.addresslinetwo.valid && userForm.controls.addresses.controls[i].controls.addresslinetwo.touched">{{ validationError.required }}</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field class="form-element">
                  <input formControlName="city"matInput class="form-control"  maxlength="30" required="">
                  <span class="form-bar"></span>
                  <mat-label class="float-label label-after" for="title">City</mat-label>
                  <mat-error class="validation-error" *ngIf="!userForm.controls.addresses.controls[i].controls.city.valid && userForm.controls.addresses.controls[i].controls.city.touched">{{ validationError.alphabets }}</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field class="form-element">
                  <input formControlName="contactone" matInput class="form-control" maxlength="30"  required="" mask="0000000000">
                  <span class="form-bar"></span>
                  <mat-label class="float-label label-after" for="title">Contact 1</mat-label>
                  <mat-error class="validation-error" *ngIf="!userForm.controls.addresses.controls[i].controls.contactone.valid && userForm.controls.addresses.controls[i].controls.contactone.touched">{{ validationError.contact }}</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field class="form-element">
                  <input formControlName="contacttwo" matInput class="form-control" maxlength="30"  required="" mask="0000000000">
                  <span class="form-bar"></span>
                  <mat-label class="float-label label-after" for="title">Contact 2</mat-label>
                  <mat-error class="validation-error" *ngIf="!userForm.controls.addresses.controls[i].controls.addresslinetwo.valid && userForm.controls.addresses.controls[i].controls.addresslinetwo.touched">{{ validationError.contact }}</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field class="form-element">
                  <mat-select class="form-control dropdown-height" formControlName="country" (change)="fillCountryDetail()">
                     <ng-container *ngFor="let n of countryList">
                       <mat-option value="{{n.name}}">{{n.name}}</mat-option>
                     </ng-container>
                  </mat-select>
                  <mat-label class="float-label label-after" for="title">Country</mat-label>
                  <mat-error class="validation-error" *ngIf="!userForm.controls.addresses.controls[i].controls.country.valid && userForm.controls.addresses.controls[i].controls.country.touched">{{ validationError.required }}</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field class="form-element">
                    <input formControlName="countryiso" matInput required="" maxlength="30"  class="form-control" disabled="" type="text">
                    <span class="form-bar"></span>
                    <mat-label class="float-label" for="title">{{userForm.value.countryiso}}</mat-label>
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field class="form-element">
                  <input matInput formControlName="countrycode" maxlength="30"   required="" class="form-control" disabled="" type="text">
                  <span class="form-bar"></span>
                  <mat-label class="float-label" for="title">{{userForm.value.countrycode}}</mat-label>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field class="form-element">
                  <input formControlName="landmark" matInput maxlength="30"  class="form-control" required="">
                  <span class="form-bar"></span>
                  <mat-label class="float-label label-after" for="title">Landmark</mat-label>
                  <mat-error class="validation-error" *ngIf="!userForm.controls.addresses.controls[i].controls.landmark.valid && userForm.controls.addresses.controls[i].controls.landmark.touched">{{ validationError.required }}</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field class="form-element">
                  <input formControlName="mobile" matInput maxlength="30"  class="form-control" mask="0000000000" required="">
                  <span class="form-bar"></span>
                  <mat-label class="float-label label-after">Mobile</mat-label>
                  <mat-error class="validation-error" *ngIf="!userForm.controls.addresses.controls[i].controls.mobile.valid && userForm.controls.addresses.controls[i].controls.mobile.touched">{{ validationError.contact }}</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field class="form-element">
                  <input formControlName="otheremail" maxlength="30"  matInput class="form-control" required="">
                  <span class="form-bar"></span>
                  <mat-label class="float-label label-after" for="title">Other Email</mat-label>
                  <mat-error class="validation-error" *ngIf="!userForm.controls.addresses.controls[i].controls.otheremail.valid && userForm.controls.addresses.controls[i].controls.otheremail.touched">{{ validationError.email }}</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field class="form-element">
                  <input formControlName="postalcode" maxlength="30"  matInput class="form-control" required="">
                  <span class="form-bar"></span>
                  <mat-label class="float-label label-after" for="title">Postal Code</mat-label>
                  </mat-form-field>
                  <!-- <span class="validation-error" *ngIf="!userForm.controls.addresses.controls[i].controls.postalcode.valid && userForm.controls.addresses.controls[i].controls.postalcode.touched">{{ validationError.postalcode }}</span> -->
                </div>
                <div class="col-md-4">
                  <mat-form-field class="form-element">
                  <input formControlName="state" maxlength="30"  matInput class="form-control" required="">
                  <span class="form-bar"></span>
                  <mat-label class="float-label label-after" for="title">State</mat-label>
                  <mat-error class="validation-error" *ngIf="!userForm.controls.addresses.controls[i].controls.state.valid && userForm.controls.addresses.controls[i].controls.state.touched">{{ validationError.required }}</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field class="form-element">
                  <mat-select formControlName="type" class="form-control">
                    <mat-option [value]="'primary'"> Primary</mat-option>
                    <mat-option [value]="'secondary'">Secondary</mat-option>
                  </mat-select>
                  <mat-label class="float-label label-after">Type</mat-label>
                  <mat-error class="validation-error" *ngIf="!userForm.controls.addresses.controls[i].controls.type.valid && userForm.controls.addresses.controls[i].controls.type.touched">{{ validationError.required }}</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field class="form-element">
                  <input formControlName="workemail" matInput  maxlength="30" class="form-control" required="">
                  <span class="form-bar"></span>
                  <mat-label class="float-label label-after">Work Email</mat-label>
                  <mat-error class="validation-error" *ngIf="!userForm.controls.addresses.controls[i].controls.workemail.valid && userForm.controls.addresses.controls[i].controls.workemail.touched">{{ validationError.email }}</mat-error>
                  </mat-form-field>
                </div>
              </ng-container>
          </div>
        </div>
        </form>
        <div class="row my-2 mx-2">
          <div class="action-buttons multi-btns col-sm-12 text-right">
            <button mat-raised-button  class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i> Cancel</button>
          <button mat-raised-button  class="btn btn-primary" color="primary"  [disabled]="!userForm.valid" (click)="submitAddJobTitle()"><i class="flaticon-diskette"></i> Save</button>
          </div>
        </div>
      </div>
</ng-template>

<ng-template #deleteUser let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">Delete Company</h4>
    </div>
    <div class="mat-modal-body">
        <div class="text-left are-u">Are you sure ?</div>
        <div class="row">
          <div class="action-buttons multi-btns col-sm-12 text-right">
            <button mat-raised-button class="btn-danger" (click)="close()">
                <i class="flaticon-cancel"></i> Cancel</button>
            <button class="btn-primary" color="primary" mat-raised-button
            (click)="deleteJobTitle()"><i class="flaticon-trash"></i> Delete
            </button>
          </div>
        </div>
      </div>
    </div>
</ng-template>
