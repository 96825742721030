import {Component, Injectable, OnInit, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {DashboardService} from '../../../dashboard.service';
import { NotificationService } from "src/app/core/services/notification.service";
import jsPDF from "jspdf";
import html2canvas from 'html2canvas';
import autoTable from 'jspdf-autotable';
import {MatDialog} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {saveAs} from 'file-saver-es';

import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { CostCenterComponent } from 'src/app/dashboard/admin/organization/cost-center/cost-center.component';



@Component({
    selector: 'app-view-report',
    templateUrl: './view-report.component.html',
    styleUrls: ['./view-report.component.scss']
})

@Injectable()
export class ViewReportComponent implements OnInit {
    public chartSettingForm: FormGroup;
    public reportId: '';
    public reportName: '';
    public dataSource: Array<any> = [];
    public dataSource2: Array<any> = [];
    public grouByList: Array<any> = [];
    public aggregateByList: Array<any> = [];
    public headers = [];
    public divideLine = false;
    public validation: boolean = false;
    public divder = "";
    public loadingReport: boolean;
    public panelOpenState = false;
    public dialogRef: any;
    public chartData: Array<any> = [];
    public browserRefresh : boolean = false;
    myData = [['No Data', 0]];
    title = 'Report Chart';
    description = '';
    mouseIn = false;
    myOptions: any;


    public static res : any = null ;
    public static source : string = "";
    public paging = {
        pageSize: 50,
        totalRecords: 0,
        pageIndex: 0,
    };
    // dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
    public displayedColumns: Array<any> = [];
    public displayedColumns2: Array<any> = [];
    public temp: Array<any> = [];
    public includeList: Array<any> = [
        {value: 'ce', label: 'Current Employees Only'},
        {value: 'cpe', label: 'Current and Past Employees'},
        {value: 'pe', label: 'Past Employees Only'},
    ];
    public includeSelected = 'ce';
    public groupSelected: string = "";
    public aggregateSelected: string = "";
    public aggregateBySelected = 'Count';
    public isClicked: boolean = false;
    public chartGenerated = false;
    public chartHidden = false;
    public chartType = false;
    public detailedTable = false;
    public summaryTable = false;
    public isEdit = false;
    public firstTime = false;

    public aggregateTypeList: Array<any> = [];
    public chartListDataSource: MatTableDataSource<any> = new MatTableDataSource([]);
    public typeOfAggregate: string = "";
    public summaryList: Array<any> = [];
    @ViewChild('addUser', {static: false}) addUser;
    @ViewChild(MatSort, {static: false}) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('deleteChart', {static: false}) deleteChart;
    @ViewChild('generateSettings', {static: false}) generateSettings;
    public summaryColumns = ['name', 'count'];

    subscription: Subscription;
    public customFields = [];

    constructor(private location: Location,
                private activatedRoute: ActivatedRoute,
                private toastr: NotificationService,
                private router: Router,
                private dashboardService: DashboardService, 
                private dialog: MatDialog, private fb: FormBuilder,
                private authService : AuthService) {
        this.loadingReport = true;
        this.includeSelected = 'ce';
        this.aggregateBySelected = "Count";
        this.subscription = router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
              this.browserRefresh = !router.navigated;
            }
        });
        this.createChartSetting();
    }

    ngOnDestroy() {
        window.clearInterval(1);
        this.toastr = null ;
        this.activatedRoute = null ;
        this.location = null ;
        this.dialog = null ;
        this.authService = null ;
        this.dashboardService = null ;
        this.subscription.unsubscribe () ;
        this.chartSettingForm = null ;
        this.reportId = null ;
        this.reportName = null ;
        this.chartSettingForm = null ;
        this.reportId = null ;
        this.dataSource = null;
        this.dataSource2 = null ;
        this.grouByList = null ;
        this.aggregateByList = null ;
        this.headers = null ;
        this.divideLine = null ;
        this.validation = null ;
        this.loadingReport = null;
        this.panelOpenState = null ;
        this.dialogRef = null ;
        this.chartData = null ;
        this.browserRefresh = null ;
        this.myData = null ;
        this.title = null ;
        this.description = null ;
        this.mouseIn = null ;
        this.myOptions = null ;
        this.paging = null ;
        this.displayedColumns = null ;
        this.displayedColumns2 = null ;
        this.temp = null ;
        this.includeList = null ;
        this.includeSelected = null ;
        this.groupSelected = null ;
        this.aggregateSelected = null ;
        this.aggregateBySelected = null ;
        this.isClicked = null; 
        this.chartGenerated = null ;
        this.chartHidden = null ;
        this.chartType = null ;
        this.detailedTable = null ;
        this.summaryTable = null ;
        this.isEdit = null ;
        this.firstTime = null ;
        this.aggregateTypeList = null;
        this.chartListDataSource = null ;
        this.typeOfAggregate = null ;
        this.summaryTable = null ;
        this.summaryList = null ;
        this.addUser = null ;
        this.sort = null ;
        this.paginator = null ;
        this.deleteChart = null ;
        this.generateSettings = null ;
        this.summaryColumns = null ;
        this.subscription = null ;
        this.customFields = null ;
        this.permissions = null ;
        this.read = null ; 
    }



   
    // dataSource: MatTableDataSource<any> = new MatTableDataSource([]);

  
  
   

      public permissions : any;
      public read = false;
      public write = false ;
      public update = false;
      public delete = false;
     
      checkPermission(module,tabs) : void 
      {
          this.permissions = JSON.parse(this.authService.getPermissions());
  
          this.read = false ;
          this.write = false ;
          this.delete = false ;
          this.update = false;
          if(this.permissions != undefined && this.permissions != null )
          {
              for(let p of this.permissions)
              {
                 
                  if(p.id === module)
                  {
                     
                      if(p.modules != undefined)
                      {
                        
                          for(let tab of p.modules)
                          {
                              if(tab.id === tabs)
                              {
                                
                                  for(let move of tab.permits)
                                  {
                                      if(move.id === "u")
                                      {
                                          this.update = true;
                                      }
                                      else if(move.id === "r")
                                      {
                                          this.read = true;
                                      }
                                      else if(move.id === "c")
                                      {
                                        
                                          this.write = true;
                                      }
                                      else if(move.id === "d")
                                      {
                                          this.delete = true;
                                      }
                                  }
                              }
                          }
                      }
                     
                  }
                 
              }
          }
         
  
      }
    ngOnInit() {
        this.loadingReport = true;
        this.isEdit = false;

       
        this.checkPermission("pdm","pd_rp");
       
        this.activatedRoute.params.subscribe(params => {
            if (params.id) {
                this.reportId = params.id;
                console.log(" this.reportId : ", this.reportId)
                this.reportName = params.reportName;
                console.log(" this.reportName : ", this.reportName)

            }

           
        });

       

        console.log(ViewReportComponent.source);
        console.log(ViewReportComponent.res);
   
          if(ViewReportComponent.source === "")
          {
  
              this.getTheFullReportById(this.reportId);
          }
          else{
              this.getFullReportById();
          }
        
        

        // this.functionNr1();

    }


    messageSuccess: boolean = true;

    getTheFullReportById(reportId) {
        this.loadingReport = true;
        var obj = 
      {
        report   : null ,
        reportId : reportId
      }
        this.dashboardService.saveReport(obj).subscribe(res => {
            if (res.data && res) {
             var customList = [];
             var allCustomList = [];
             var obj = {}
             var i  = 0;
             this.reportId = res.data.reportId;
             if(res.data.customFields != undefined)
             {
                this.customFields =res.data.customFields;
                 if(this.customFields.length != 0)
                 {
                    for(let cu of this.customFields)
                    {
                        customList = [];
                    
                        for(let c of cu)
                        {
                            obj = {}
                            
                            for(let elem of c.elements)
                            {
                             
                                obj [elem.fieldName] = elem.fieldValue;
                               
                               
                            }
                            customList.push(obj);
                        }
                        
                        allCustomList.push(customList);
                        
                    }
                   
                    for(let custom of allCustomList)
                    {
                        res.data.res["z"+i] = custom;
                        i++;
                    }
                   
                 }
              
             }
                
                this.headers = res.data.headers;
                const result = this.headers?.filter(word => word.length > 1);
                if (result.length > 0) {
                    this.showHeader = true;
                }
                this.chartGenerated = res.data.chartGenerated;
                this.chartHidden = res.data.chartHidden;
                this.includeSelected = res.data.employeesState
                if (this.chartGenerated) {


                    this.firstTime = true;
                    this.getChartDeatilsForEdit();


                }
                for (let head of this.headers) {
                    if (head != " ") {
                        this.divideLine = true;
                    }
                }
                if (this.divideLine) {
                    this.divder = "mat-elevation-z8";
                } else {
                    this.divder = "";
                }
                // this.reportName = res.data.reportName;
                this.dataSource = [];
                Object.keys(res.data.res).map(resp => {
                    // console.log("test : " , Object.keys(res.data.res[resp][0]));
                    let temp = [];
                    Object.keys(res.data.res[resp][0]).map(value => {
                        temp.push(this.toTable(value));
                    });
                    this.displayedColumns.push(Object.keys(res.data.res[resp][0]));
                    this.displayedColumns2.push(temp);
                    this.dataSource.push(res.data.res[resp]);
                   
                    let i = 0;
                    res.data.res[resp].map(a => {
                        this.temp[i] = Object.assign({}, this.temp[i], a);
                        i = i + 1;
                    });
                });

                this. paging = {
                    pageSize: 50,
                    totalRecords: 0,
                    pageIndex: 0,
                };
                this.doList(this.paging);
                
               
          
                this.loadingReport = false;
                // this.dataSource = new MatTableDataSource<any>(this.temp);
            }
        }, error => {
            this.loadingReport = false;
            this.toastr.error('error in getting the reports');
        });
    }

    public dataToShow : Array<any> = [];
    public index : number  = 0;
    doList(event) : void 
  {
       
        this.paging.pageIndex = event.pageIndex;
        this.paging.pageSize =  event.pageSize ;
        this.index =  (event.pageIndex + 1) * 50 ;
        this.dataSource2 = [] ;
      
      for(let data of this.dataSource)
      {
        this.paging.totalRecords = data.length;
        let minu = data.length - this.index;
        if(this.index > data.length )
        {
            minu = data.length - (this.index-50);
         
            this.dataToShow = [];
            for(let i = (data.length - minu) ; i < data.length ; i++)
            {
                this.dataToShow.push(data[i]);
                

            }

       
           this.dataSource2.push(this.dataToShow);
        }
        else if(this.index  < data.length )
        {
          
          
            this.dataToShow = [];
             for (let i = (this.index - 50) ; i< this.index ; i++)
             {
                this.dataToShow .push(data[i]);
             }
             this.dataSource2.push(this.dataToShow);
            
        }
      }
      
    }
    pageChange(event) : void  {
        this.paging.pageIndex = event.pageIndex;
        this.paging.pageSize =  event.pageSize ;
        this.index =  (event.pageIndex + 1) * 50 ;
        this.dataSource2 = [] ;
      
      for(let data of this.dataSource)
      {
        this.paging.totalRecords = data.length;
        let minu = data.length - this.index;
        if(this.index > data.length )
        {
            minu = data.length - (this.index-50);
            this.dataToShow = [];
            for(let i = (data.length - minu) ; i < data.length ; i++)
            {
                this.dataToShow.push(data[i]);
                

            }
       
           this.dataSource2.push(this.dataToShow);
        }
        else if(this.index  < data.length )
        {
          
            this.dataToShow = [];
             for (let i = (this.index - 50) ; i< this.index ; i++)
             {
                this.dataToShow .push(data[i]);
             }
             this.dataSource2.push(this.dataToShow);
            
        }
      }
        
    }
    onDeleteClicked() {
        this.dialogRef = this.dialog.open(this.deleteChart, {
            width: '440px',
            height: 'auto'
        });
    }

    ngAfterViewInit() {
        this.getChartReportDropDownById(this.reportId);
        this.getChartReportAggregateById(this.reportId);
    }

    onChartTypeChange() {
        if (this.isClicked === false) {
            this.isClicked = true;
            this.chartSettingForm.value.chartType = this.isClicked;
        } else {
            this.isClicked = false;
            this.chartSettingForm.value.chartType = this.isClicked;

        }
    }

    public createChartSetting(): void {
        this.chartSettingForm = this.fb.group({
            reportId: [''],
            groupByHeader: [''],
            groupByOption: [''],
            aggregateHeader: [''],
            aggregateOption: [''],
            aggregateType: ['Count'],
            chartType: false,
            summaryTable: true,
            detailedTable: false
        });
    }


    // expandPanel(matExpansionPanel, event): void {
    //   event.stopPropagation(); // Preventing event bubbling

    //   if (!this._isExpansionIndicator(event.target)) {
    //     matExpansionPanel.close(); // Here's the magic
    //   }
    // }

    // private _isExpansionIndicator(target: EventTarget): boolean {
    //   const expansionIndicatorClass = 'mat-expansion-indicator';

    //   return (target['classList'] && target['classList'].contains(expansionIndicatorClass) );
    // }
    showHeader: boolean = false;
  
    getFullReportById() {
        this.loadingReport = true;
            if (ViewReportComponent.res) {
             var customList = [];
             var allCustomList = [];
             var obj = {}
             var i  = 0;
             this.reportId = ViewReportComponent.res.reportId;
             if(ViewReportComponent.res.customFields != undefined)
             {
                this.customFields =ViewReportComponent.res.customFields;
                 if(this.customFields.length != 0)
                 {
                    for(let cu of this.customFields)
                    {
                        customList = [];
                    
                        for(let c of cu)
                        {
                            obj = {}
                            
                            for(let elem of c.elements)
                            {
                             
                                obj [elem.fieldName] = elem.fieldValue;
                               
                               
                            }
                            customList.push(obj);
                        }
                        
                        allCustomList.push(customList);
                        
                    }
                   
                    for(let custom of allCustomList)
                    {
                        ViewReportComponent.res.res["z"+i] = custom;
                        i++;
                    }
                   
                 }
              
             }
                
                this.headers = ViewReportComponent.res.headers;
                const result = this.headers?.filter(word => word.length > 1);
                if (result.length > 0) {
                    this.showHeader = true;
                }
                this.chartGenerated =ViewReportComponent.res.chartGenerated;
                this.chartHidden = ViewReportComponent.res.chartHidden;
                this.includeSelected = ViewReportComponent.res.employeesState
                if (this.chartGenerated) {


                    this.firstTime = true;
                    this.getChartDeatilsForEdit();


                }
                for (let head of this.headers) {
                    if (head != " ") {
                        this.divideLine = true;
                    }
                }
                if (this.divideLine) {
                    this.divder = "mat-elevation-z8";
                } else {
                    this.divder = "";
                }
                // this.reportName = res.data.reportName;
                this.dataSource = [];
                Object.keys(ViewReportComponent.res.res).map(resp => {
                    // console.log("test : " , Object.keys(res.data.res[resp][0]));
                    let temp = [];
                    Object.keys(ViewReportComponent.res.res[resp][0]).map(value => {
                        temp.push(this.toTable(value));
                    });
                    this.displayedColumns.push(Object.keys(ViewReportComponent.res.res[resp][0]));
                    this.displayedColumns2.push(temp);
                    this.dataSource.push(ViewReportComponent.res.res[resp]);
                   
                    let i = 0;
                    ViewReportComponent.res.res[resp].map(a => {
                        this.temp[i] = Object.assign({}, this.temp[i], a);
                        i = i + 1;
                    });
                });
                
                this. paging = {
                    pageSize: 50,
                    totalRecords: 0,
                    pageIndex: 0,
                };
                this.doList(this.paging);
                
               
          
                this.loadingReport = false;
                // this.dataSource = new MatTableDataSource<any>(this.temp);
            }
        
            this.loadingReport = false;
            
       
    }

    getChartReportDropDownById(reportId) {
        this.dashboardService.getChartGroupById(reportId).subscribe(res => {
            if (res.data && res) {

                this.grouByList = res.data;

                
                for(let group of this.grouByList)
                {
                    var elements = [];
                   for (let elem of group.elements)
                    
                   {
                       var object = {
                           name : '',
                           value : ''
                       }
                       object.name = this.toTable(elem);
                       object.value = elem;
                       elements.push (object);
                   }
                        

                   group.elements = elements;
                   
                }
                // this.dataSource = new MatTableDataSource<any>(this.temp);
            }
        }, error => {

            this.toastr.error('error in getting group by dropdown');
        });
    }

    getChartReportAggregateById(reportId) {
        this.dashboardService.getChartAggregateById(reportId).subscribe(res => {
            if (res.data && res) {

                this.aggregateByList = res.data;
                for(let aggre of this.aggregateByList)
                {
                    aggre.aggregators.map(u => {
                    u.label = '';
                    if (u.name) {
                     u.label = this.toTable(u.name);
                    }
                   
                  });
                }
               
                // this.dataSource = new MatTableDataSource<any>(this.temp);
            }
        }, error => {

            this.toastr.error('error in getting aggregation dropdowns');
        });
    }

    gnenerateChart(chatType) {
     
        this.messageSuccess = true;
        this.myOptions = {
            colors: ['#4682B4', "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39"],
            legend: 'none',
            hAxis: {
                title: 'h axis',
                viewWindowMode: 'explicit'
            },
            vAxis: {
                title: 'v axis',
                format: '0',
                gridlines: {
                    count: 0
                },

                viewWindow:{
                    max:0,
                    min:0
                  }
            },
            allowHtml: true,
            chartArea: {
                // leave room for y-axis labels
                width: '90%',
                height: '57%',

            },
            sort: 'event',
            sortAscending: false,
            sortColumn: 2,
            is3D: true,
            bar: {
                groupWidth: '50%'
            },
            tooltip: {isHtml: true},

            width: "100%",
        };
        this.chartSettingForm.value.reportId = this.reportId;
        if (!this.isEdit) {
            this.chartSettingForm.value.groupByHeader = this.groupSelected;
            this.chartSettingForm.value.aggregateHeader = this.aggregateSelected;
        }
        this.chartSettingForm.value.chartType = chatType;
        if( this.chartSettingForm.value.aggregateOption === "")
        {
            this.chartSettingForm.value.aggregateHeader = "" 
            this.chartSettingForm.value.aggregateType = "Count";
            this.aggregateBySelected = "Count"; 
        }
        this.dashboardService.saveChartReport(this.chartSettingForm.value).subscribe(res => {
            if (res.data && res) {
                const myData = [];
                this.chartData = res.data.chartData;
                let max = 0 ;
                  
               
                if (this.chartData != undefined) {
                    this.chartData.map(item => {
                        myData.push([item.name ? item.name : 'Undefined', item.count]);
                        if(item.count > max)
                        {
                            max = item.count;
                        }

                    });

                
                    this.myOptions.vAxis.viewWindow.max = max ;
                }


                this.myOptions.hAxis.title = this.toTable(this.chartSettingForm.value.groupByOption);
                if(this.chartSettingForm.value.aggregateOption != "")
                {
                    this.myOptions.vAxis.title = this.toTable(this.chartSettingForm.value.aggregateOption);
                }
                
                // this.myOptions.vAxis.gridlines = { max:12, min:0};
                this.myData = myData;
                this.chartGenerated = res.data.chartGenerated;
                this.chartHidden = res.data.chartHidden;
                this.chartType = res.data.chartType;
               
                if (this.chartType == true) {
                    this.myOptions = {
                      
                        colors: ['#4682B4', "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39"],
                        legend: 'bottom',
                        hAxis: {
                            title: this.chartSettingForm.value.groupByOption,
                            // viewWindowMode: 'explicit',
                        },
                        vAxis: {
                            title: this.chartSettingForm.value.aggregateOption,
                            format: 'none',

                            viewWindowMode:'explicit',
                            viewWindow:{
                              max:6,
                              min:0
                            }
                            // viewWindow:{
                            //     max:12,
                            //     min:0
                            // },
                            // gridlines: {
                            //     count: 4
                            // },
                            // viewWindow:{
                            //     min: 0,
                            //     max: 5
                            // },
                        },
                        allowHtml: false,
                        chartArea: {
                            // leave room for y-axis labels
                            width: '90%',
                            height: '57%',
                            

                        },
                       
                        bar: {groupWidth: '100%'},
                        sort: 'event',
                        sortAscending: false,
                        sortColumn: 2,
                        is3D: false,
                       
                        tooltip: {isHtml: false},

                        width: "100%",
                      
                    };

                } else {

                }
                this.detailedTable = res.data.detailedTable;
                this.summaryTable = res.data.summaryTable;
                this.typeOfAggregate = "Count";
                if (res.data.aggregationType === "Sum") {
                    this.typeOfAggregate = "Sum";

                }
                if (res.data.aggregationType === "Average") {
                    this.typeOfAggregate = "Average";
                }

                this.summaryList = res.data.chartData;
                for (let summ of this.summaryList) {
                    if (summ.name === "" || summ.name === undefined) {
                        summ.name = "Undefined"
                    }
                }
                this.chartListDataSource = new MatTableDataSource<any>(this.summaryList);
                setTimeout(() => this.chartListDataSource.paginator = this.paginator);
                // this.chartListDataSource.paginator = this.paginator;
                this.chartListDataSource.sort = this.sort;
                if (!this.firstTime) {
                    this.toastr.success('Chart genereted successfully');
                    
                } else {
                    this.firstTime = false;
                }
                this.loadingReport = false;
                this.messageSuccess = false;
                this.close();
            }
        }, error => {

            this.toastr.error('Error in generating chart');
            this.loadingReport = false;
        });
    }

    getChartDeatilsForEdit() {
        // this.isEdit = true;
        this.dashboardService.getCharDetailsbyId(this.reportId).subscribe(res => {
            if (res.data && res) {
                this.chartSettingForm = this.fb.group({
                    reportId: [''],
                    groupByHeader: [''],
                    groupByOption: [''],
                    aggregateHeader: [''],
                    aggregateOption: [''],
                    aggregateType: ['Count'],
                    chartType: false,
                    summaryTable: true,
                    detailedTable: false
                });

                this.groupSelected = res.data.groupByHeader;
                this.aggregateSelected = res.data.aggregateHeader;

                this.chartSettingForm.patchValue(
                    {
                        groupByHeader: res.data.groupByHeader,
                        groupByOption: res.data.groupByOption,
                        aggregateHeader: res.data.aggregateHeader,
                        aggregateOption: res.data.aggregateOption,
                        aggregateType: res.data.aggregateType,
                        chartType: res.data.chartType,
                        summaryTable: res.data.summaryTable,
                        detailedTable: res.data.detailedTable,

                    }
                );
                console.log("res?.data?.chartType : ",res?.data?.chartType);
                this.gnenerateChart(res?.data?.chartType);

            }
        });


    }

    public deleteCharts(): void {
        this.dashboardService.deleteChart(this.reportId).subscribe(res => {
            if (res.data && res) {
                this.toastr.success('Chart deleted successfully');
                this.detailedTable = false;
                this.close();
                this.ngOnInit();

            }


        }, error => {

            this.toastr.error('Error in deleting chart');
        });


    }

    getChartDataForEdit() {
        this.isEdit = true;
        this.dashboardService.getCharDetailsbyId(this.reportId).subscribe(res => {
            if (res.data && res) {
                this.onAggregateByHeaderChange(res.data.aggregateHeader, res.data.aggregateOption);
                this.chartSettingForm = this.fb.group({
                    reportId: [''],
                    groupByHeader: [''],
                    groupByOption: [''],
                    aggregateHeader: [''],
                    aggregateOption: [''],
                    aggregateType: ['Count'],
                    chartType: false,
                    summaryTable: true,
                    detailedTable: false
                });
                this.isClicked = res.data.chartType;
                this.groupSelected = res.data.groupByHeader;
                this.aggregateSelected = res.data.aggregateHeader;
                this.chartSettingForm.patchValue(
                    {
                        groupByHeader: res.data.groupByHeader,
                        groupByOption: res.data.groupByOption,
                        aggregateHeader: res.data.aggregateHeader,
                        aggregateOption: res.data.aggregateOption,
                        aggregateType: res.data.aggregateType,
                        chartType: res.data.chartType,
                        summaryTable: res.data.summaryTable,
                        detailedTable: res.data.detailedTable,

                    }
                );
                this.aggregateBySelected = res.data.aggregateType;
                this.dialogRef = this.dialog.open(this.addUser, {
                    width: '720px',
                    autoFocus: false,
                    height: '720px' 
                });
            }

        }, error => {

            this.toastr.error('Error in geeting chart details');
        });
    }

    saveChart() {
        this.loadingReport = true;

        this.gnenerateChart(this.isClicked);
    }

    generateReport() {

        this.close();
        this.loadingReport = true;
        this.dashboardService.generateReportbyState(this.reportId, this.includeSelected).subscribe(res => {
            if (res.data && res) {
                this.toastr.success('Report generated successfully');
                this.ngOnInit();
                this.ngAfterViewInit();

            }


        }, error => {
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error in generating report');
            this.loadingReport = false;
        });

    }

    onGroupByHeaderChange(group) {
        this.chartSettingForm.value.groupByHeader = group;
        this.groupSelected = group;

    }

    validateFalse()
    {
        this.validation = false;
    }

    validateChartTwo(group, elem) : void {
        
        
      
        if (this.groupSelected === group) {
            if (this.chartSettingForm.value.groupByOption === elem) {
                this.validation = true;
            } else {
                this.validation = false;
            }
        } else {
            this.validation = false;
        }


    }

    validateChartOne(group, elem) {
        if(elem === "")
        {
            this.validation = false;
            return ;
        }
        if (this.aggregateSelected === group) {
            if (this.chartSettingForm.value.aggregateOption === elem) {
                this.validation = true;
            } else {
                this.validation = false;
            }

        } else {
            this.validation = false;
        }

    }


    onAggChange()
    {

    
           
            
        
    }
    onAggregateByHeaderChange(header, name) : void {

        if(this.chartSettingForm.value.aggregateOption === "")
        {
            this.chartSettingForm.value.aggregateHeader = ""
            this.chartSettingForm.value.aggregateType = "Count";
            this.aggregateBySelected = "Count";
            var typeList = [] ;
            typeList.push("Count");
            this.aggregateSelected = "";
            this.aggregateTypeList = typeList;
            return ;
        }

        this.chartSettingForm.value.aggregateHeader = header;
       
        this.aggregateSelected = header;

        for (let agg of this.aggregateByList) {
            if (agg.header === header) {
                for (let elem of agg.aggregators) {
                    if (elem.name === name) {

                        this.aggregateTypeList = elem.aggregationTypes;
                        this.aggregateBySelected = 'Count';
                    }
                }
            }
        }

    }


    onBackPressed() {
        this.location.back();
    }

    onEditReportClicked() {
        this.router.navigate([`/pim/reports/add/${this.reportId}`]);
    }


    saveAsCSV() {

        const data = [];
        this.temp.forEach(obj => {
            const arr = [];
            Object.keys(this.temp[0]).forEach(col => {
                arr.push(obj[col]);
            });
            data.push(arr);
        });

        const csv = data?.map(
            row => {
                return row.join(',') + '\n';
            }
        );
        csv.unshift([Object.keys(this.temp[0])].join(',') + '\n');
        const exportDate = new Date();
        // tslint:disable-next-line:max-line-length
        const exportFileName = `Csv-export-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}.csv`;
        saveAs(new Blob(csv, {type: 'text/csv;charset=utf-8'}), exportFileName);
    }

    static disabledBtn: boolean = false;


    saveAsPdf() {
        // for the testing of the pdf

        if(this.chartGenerated)
        {
            let element = document.getElementById(this.chartType ? 'chart_div_2' : 'chart_div');

            let data = [];
            this.temp.forEach(obj => {
                const arr = [];
                Object.keys(this.temp[0]).forEach(col => {
                    arr.push(obj[col]);
                });
                data.push(arr);
            });
    
            html2canvas(element).then((canvas) => {
                const image = canvas.toDataURL('image/jpeg', 1.0);
                const doc = new jsPDF('p', 'px', 'a4');
                const pageWidth = doc.internal.pageSize.getWidth();
                const pageHeight = doc.internal.pageSize.getHeight();
            
                const widthRatio = pageWidth / canvas.width;
                const heightRatio = pageHeight / canvas.height;
                const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
            
                const canvasWidth = canvas.width * ratio;
                const canvasHeight = canvas.height * ratio;
            
                const marginX = (pageWidth - canvasWidth) / 2  + 10;
                const marginY = (pageHeight - canvasHeight) / 2 + 10;
            
                doc.addImage(image, 'JPEG', marginX, marginY, canvasWidth, canvasHeight);
                
              
                if(this.chartSettingForm.value.summaryTable && this.chartListDataSource.filteredData.length > 0){
                    doc.addPage();
                    const body = [];
                    for (let i = 0; i < this.chartListDataSource.filteredData.length; i++) {
                        body.push([this.chartListDataSource.filteredData[i].name,
                            this.chartListDataSource.filteredData[i].count,
                        ]);
                    }
                    autoTable(doc, {
                        head: [['Name', 'Count']],
                        body: body
                    });
                }
                doc.addPage();
                autoTable(doc, {
                    head: [Object.keys(this.temp[0])],
                    body: data
                });
    
                const exportDate = new Date();
                const exportFileName = `${this.reportName}-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}`;
                doc.save(exportFileName);
            });
    
        }

        else 
        {   var doc = new jsPDF();
            let data = [];
            this.temp.forEach(obj => {
                const arr = [];
                Object.keys(this.temp[0]).forEach(col => {
                    arr.push(obj[col]);
                });
                data.push(arr);
            });

            autoTable(doc, {
                head: [Object.keys(this.temp[0])],
                body: data
            });

            const exportDate = new Date();
            const exportFileName = `${this.reportName}-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}`;
            doc.save(exportFileName);
        }

       

        //   ViewReportComponent.disabledBtn = true;
        //
        // const data
        // this.temp.forEach(obj => {
        //     const arr = [];
        //     Object.keys(this.temp[0]).forEach(col => {
        //         arr.push(obj[col]);
        //     });
        //     data.push(arr);
        // });

        //   var chart = new google.visualization.ColumnChart(document.getElementById('chart_div'));
        //
        //   console.log("chart : " , chart);
        //
        // //   var content = document.createElement('img');
        // // await google.visualization.events.addListener(chart, 'ready', async function () {
        // //       // content = `<img src=${chart.getImageURI()}>`;
        // //       content.src = await chart.getImageURI();
        // //   });
        //
        //   const url = await chart.getImageURI();
        //   console.log("data : " , url);
        //
        //   const doc = new jsPDF();
        //   // doc.setFont("helvetica");
        //   // doc.setFontSize(9);
        //   doc.addImage(url, 'png', 40, 40, 530, 350);

        //   setTimeout(function() {
              const exportDate = new Date();
              const exportFileName = `report-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}}`;
        //       doc.save(exportFileName);
        //   }, 4000);
    }

    public generateSetting() {
        this.dialogRef = this.dialog.open(this.generateSettings, {
            width: '400px',
            height: 'auto'
        });
    }

    public addNewUser() {

        this.isEdit = false;
        this.isClicked = false;
        this.createChartSetting();
        this.aggregateTypeList = [];
        this.aggregateTypeList.push("Count");
        this.aggregateBySelected = "Count";
        this.dialogRef = this.dialog.open(this.addUser, {
            width: '720px',
            autoFocus: false,
            height: '720px' 
        });
    }


    openGenerateSettings() {

        this.dialogRef = this.dialog.open(this.generateSettings, {
            width: '440px',
            height: 'auto'
        });
    }

    hideAndShowChart() {
        if (this.chartHidden == true) {
            this.chartHidden = false;
        } else if (this.chartHidden == false) {
            this.chartHidden = true;
        }

        this.dashboardService.hideAndShowChart(this.reportId, this.chartHidden).subscribe(res => {
            if (res.data && res) {
                this.chartHidden = res.data;

            }


        });
    }

    close() {
        this.dialogRef.close();
    }

    onSelect($event) {
        if ($event && $event.length > 0 && $event[0].row != undefined) {
            const name = this.myData[$event[0].row][0];
            const value = this.myData[$event[0].row][1];
            const description = name + ' = ' + value;
            this.description = description;
        }
    }

    onMouseLeave() {
        this.mouseIn = false;
        this.description = '';
    }

    onMouseEnter() {
        this.mouseIn = true;
    }

    changeIncludeSelected(event) {
        this.includeSelected = event?.value;
    }

    toTable(word: any) {

        word = word.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g).map(x => x.toLowerCase()).join(' ');

        word = word.replace(/\w\S*/g, m => m.charAt(0).toUpperCase() + m.substr(1).toLowerCase());
     
        return word;
    }
}


// getFullReportById(reportId) {
//     this.dashboardService.getFullReportById(reportId).subscribe(res => {
//         if (res.data && res) {
//             // this.reportName = res.data.reportName;
//             Object.keys(res.data).map(resp => {
//                 this.displayedColumns.push(Object.keys(res.data[resp][0]));
//                 this.dataSource.push(res.data[resp]);
//                 let i = 0;
//                 res.data[resp].map(a => {
//                     this.temp[i] = Object.assign({}, this.temp[i], a);
//                     i = i + 1;
//                 });
//             });
//             // this.dataSource = new MatTableDataSource<any>(this.temp);
//         }
//     }, error => {
//         this.toastr.error('error in getting the reports');
//     });
// }
