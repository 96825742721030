import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {DashboardService} from '../../../dashboard.service';
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {MatDialog} from '@angular/material/dialog';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { NotificationService } from "src/app/core/services/notification.service";
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {saveAs} from 'file-saver-es';
import jsPDF from 'jspdf';
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox';
import autoTable from 'jspdf-autotable';
import {MatPaginator} from '@angular/material/paginator';
import { LanguageConfig } from 'src/app/Language-config';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-job-category',
  templateUrl: './job-category.component.html',
  styleUrls: ['./job-category.component.scss']
})
export class JobCategoryComponent implements OnInit {
  public filterQuery = '';
  public userList: Array<any>;
  public isLoading: boolean;
  public isUserAdded: boolean;
  public err: string;
  public dialogRef: any;
  public userForm: FormGroup;
  public isEdit: boolean;
  public editId: string;
  selectedUsers: any[] = [];
  public selectedCheckBoxList = [];
  public lang = new Map();
  public validationError = {
    required: 'Required'
  };
  @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
  @ViewChild('addUser', {static: false}) addUser;
  @ViewChild('deleteUser', {static: false}) deleteUser;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // for material data table
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  public columns = ['select', 'name', 'edit'];
  public permissions : any;
  public read = false;
  public write = false ;
  public update = false;
  public delete = false;
  constructor(private service: DashboardService,
              // private modalService: NgbModal,
              private dialog: MatDialog,
              private fb: FormBuilder,
              private toastr: NotificationService,
              private authService : AuthService) {
    this.createForm();
    this.lang= LanguageConfig.map;
  }
  checkPermission(module,tabs) : void 
  {

    this.permissions = JSON.parse(this.authService.getPermissions());
    this.read = false ;
    this.write = false ;
    this.delete = false ;
    this.update = false;

      if(this.permissions != undefined && this.permissions != null )
      {
          for(let p of this.permissions)
          {
             
              if(p.id === module)
              {
                 
                  if(p.modules != undefined)
                  {
                    
                      for(let tab of p.modules)
                      {
                          if(tab.id === tabs)
                          {
                            
                              for(let move of tab.permits)
                              {
                                  if(move.id === "u")
                                  {
                                      this.update = true;
                                  }
                                  else if(move.id === "r")
                                  {
                                      this.read = true;
                                  }
                                  else if(move.id === "c")
                                  {
                                    
                                      this.write = true;
                                  }
                                  else if(move.id === "d")
                                  {
                                      this.delete = true;
                                  }
                              }
                          }
                      }
                  }
                 
              }
        
          }
      }
     

  }
  ngOnInit() {
    this.isLoading = true;
    this.isEdit = false;
    this.getJobCategoryList();
    this.checkPermission("admin","ad_jb");
    this.isUserAdded = false;
  }

  public createForm(): void {
    this.userForm = this.fb.group({
      name: ['', Validators.required]
    });
  }

  public addNewUser() {
    this.isEdit = false;
    this.dialogRef = this.dialog.open(this.addUser, {
      width: '440px',
      height: 'auto'
    });
    this.userForm.reset();
  }

  public close(): void {
    // this.modalRef.close();
    this.dialogRef.close();
  }

  public getJobCategoryList(): void {
    this.service.getJobCategoryList().subscribe(user => {
      if (user && user.data) {
        this.isLoading = false;
        this.dataSource = new MatTableDataSource<any>(user.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.err = '';
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.isLoading = false;
      this.err = err || 'Error while getting Job Department';
    });
  }

  public submitAddJobCategory(): void {
    if (this.isEdit) {
      this.edit();
    } else {
      this.add();
    }
  }

  public add(): void {

    if (this.userForm.value.name.trim() !== '') {
      this.isUserAdded = true;
    this.service.addJobCategory(this.userForm.value).subscribe(user => {
      this.isUserAdded = false;
      this.toastr.success('Job Department added successfully');
      // this.modalRef.close();
      this.dialogRef.close();
      this.userForm.reset();
      this.getJobCategoryList();
    }, error => {
      const err = error.error && error.error.msg;
      this.isUserAdded = false;
      this.toastr.error(err || 'Error in adding job Department.Please try again.');
    });
  } else {
    this.toastr.error('Job Department Name Not be Null');
    this.userForm.reset();
  }
  }

  public edit(): void {
    if (this.userForm.value.name.trim() !== '') {
    this.isUserAdded = true;
    this.service.editJobCategory(this.userForm.value, this.editId).subscribe(user => {
      this.isUserAdded = false;
      this.toastr.success('Job Department updated successfully');
      // this.modalRef.close();
      this.dialogRef.close();
      this.getJobCategoryList();
    }, error => {
      const err = error.error && error.error.msg;
      this.isUserAdded = false;
      this.toastr.error(err || 'Error in updating Job Department.Please try again.');
    });
  } else {
    this.toastr.error('Job Department Name Not be Null');
    this.userForm.reset();
  }
  }

  public editUser(id): void {
    this.isEdit = true;
    this.isUserAdded = true ;
    this.dialogRef = this.dialog.open(this.addUser, {
      width: '440px',
      height: 'auto'
    });
    this.service.getJobCategoryById(id).subscribe(user => {
      if (user && user.data) {
        this.userForm.patchValue(user.data);
        // this.modalRef = this.modalService.open(this.addUser);
        this.isUserAdded = false ;
        this.editId = id;
      }
    }, error => {
      this.isUserAdded = false ;
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error getting Job Department');
    });
  }

  public deleteJobCategory(): void {
    this.service.deleteMultipleJobCategory(this.selectedCheckBoxList).subscribe(user => {
      if (user) {
        this.close();
        this.getJobCategoryList();
        this.toastr.success('Job Department deleted');
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.close();
      this.toastr.error(err || 'Error in deleting Job Department');
    });
    this.selectedCheckBoxList = [];
  }

  onModelChange(value: string) {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  onCheckBoxChange($event: MatCheckboxChange, id: any) {
    if (!this.alreadySelected($event.source) && $event.checked) {
      this.selectedCheckBoxList.push($event.source.value);
    } else if (!$event.checked) {
      this.selectedCheckBoxList = this.selectedCheckBoxList.filter(
          value => {
            return value !== $event.source.value;
          }
      );
    }
  }

  alreadySelected(item: MatCheckbox): boolean {
    this.selectedCheckBoxList.forEach(
        value => {
          if (item === value) {
            return true;
          }
        }
    );
    return false;
  }

  onSelectAllClicked() {
    this.selectedCheckBoxList = [];
    this.matCheckBoxes.forEach(
        item => {
          this.selectedCheckBoxList.push(item.value);
          item.checked = true;
        }
    );
  }

  onDeSelectAllClicked() {
    this.selectedCheckBoxList = [];
    this.matCheckBoxes.forEach(
        item => {
          item.checked = false;
        }
    );
  }

  onDeleteSelected() {
    this.dialogRef = this.dialog.open(this.deleteUser, {
      width: '440px',
      height: 'auto'
    });
  }
  exportToCsv() {
    const csv = this.dataSource.filteredData.map(
        row => {
          return [row.name].join(',') + '\n';
        }
    );
    csv.unshift(['name'].join(',') + '\n');
    const exportDate = new Date();
    // tslint:disable-next-line:max-line-length
    const exportFileName = `Csv-export-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}.csv`;
    saveAs(new Blob(csv, {type: 'text/csv;charset=utf-8'}), exportFileName);
  }

  saveAsPdf() {
    if (this.dataSource.filteredData.length <= 0) {
      this.toastr.error('No Data to export');
      return;
    }
    const body = [];
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      body.push(
          [this.dataSource.filteredData[i].name
          ]);
    }
    const doc = new jsPDF();
    autoTable(doc, {
      head: [['name']],
      body: body
    });
    const exportDate = new Date();
    const exportFileName = `job category-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}}`;
    doc.save(exportFileName);
  }
}
