import { Component, NgZone, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { NotificationService } from "src/app/core/services/notification.service";
import { DashboardService } from "src/app/dashboard/dashboard.service";
import { OnboardingService } from "../../onboarding.service";
import { takeUntil } from 'rxjs/operators';
import { Subject } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { MatCheckbox, MatCheckboxChange } from "@angular/material/checkbox";
@Component({
    selector: 'app-view-events',
    templateUrl: './edit-events.component.html',
    styleUrls: ['./edit-events.component.scss']
})
export class EditEventsComponent implements OnInit {
    public validationError = {
        required: 'Required'
    };
    public userForm: FormGroup;
    public employeeList: Array<any> = [];
    public locationList: Array<any> = [];
    public filteredEmployees: Array<any> = [];
    public employeeFilterCtrl: FormControl = new FormControl();
    protected _onDestroy = new Subject<void>();
    public filteredEmployees2: Array<any> = [];
    public employeeFilterCtrl2: FormControl = new FormControl();
    protected _onDestroy2 = new Subject<void>();
    public minDate : Date = new Date();
    public isLoading : boolean = true ;
    public isEdit : boolean = false ;
    public activated : boolean = false ;
    public isLoadingAct : boolean = false ;
    public dialogRef: any;
    public editId : string = "";
    public selectedCheckBoxList : Array<any> = [];
    activeSource: MatTableDataSource<any> = new MatTableDataSource([]);
    public taskTypesList : Array<any> = [];
    public taskNames : Array<any> = [];
    public isUserAdded : boolean = true ;
    public columns = ['select', 'name'];
    @ViewChild('activationTab',{static:false}) activationTab;
    @ViewChild('generateTab',{static:false}) generateTab;
    @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
    constructor(private dialog: MatDialog, private activatedRoute: ActivatedRoute,private ngZone: NgZone,private router: Router,private service: DashboardService, private onboardingService: OnboardingService, private fb: FormBuilder, private toastr: NotificationService) {
        this.createForm();
    }
    ngOnInit(): void {
        this.isEdit = false ;
        this.isLoading = true ;
        this.activatedRoute.params.subscribe(params => {
            if (params.id ) {
             
                this.editId = params.id  ;
                this.service.getLocationList().subscribe(resp => {
                    this.locationList = resp && resp.data;
                });
                this.service.getEmployeeDetailList().subscribe(resp => {
                    if (resp && resp.data) {
                        resp.data.map(e => {
                            this.employeeList.push({label:e.id +' '+ e.name, value: e.id});
                        });
                        this.filteredEmployees = this.employeeList;
        
                        this.employeeFilterCtrl.valueChanges
                            .pipe(takeUntil(this._onDestroy))
                            .subscribe(() => {
                                this.filterEmployees();
                            });
        
                            this.filteredEmployees2 = this.employeeList;
        
                            this.employeeFilterCtrl2.valueChanges
                                .pipe(takeUntil(this._onDestroy2))
                                .subscribe(() => {
                                    this.filterEmployees2();
                                });
                                this.onboardingService.getOnboardingEventsById(params.id).subscribe(resp => {
            
                                    if(resp && resp.data)
                                    {
                                     var owners = [] ;
                                 
                                     var participants = [];
                                     for( let id of resp.data.owners)
                                     {
                                         for (let emp of this.employeeList)
                                         {
                                         if(id === emp.value)
                                            {
                                                owners.push(emp);
                                                continue;
                                            }
                                         }
                                     }

                                     for( let id of resp.data.participants)
                                     {
                                         for (let emp of this.employeeList)
                                         {
                                         if(id === emp.value)
                                            {
                                                participants.push(emp);
                                                continue;
                                            }
                                         }
                                     }
                                     this.activated = resp.data.activated ;
                                     this.userForm.patchValue(
                                        {
                                            id : this.editId ,
                                            owners : owners ,
                                            name : resp.data.name ,
                                            locationId : resp.data.locationId ,
                                            participants : participants ,
                                            dueDate : moment(resp.data.dueDate).format('YYYY-MM-DD')
                                        }
                                     );
                                     this.isLoading = false ;
                                    }
                                     
                                 }, error => {
                                    this.isLoading = false ;
                                     const err = error.error && error.error.msg;
                                     this.toastr.error(err || 'Error in getting events details');
                                 });
                    }
                    
                }
                , error => {
                    this.isLoading = false ;
                    const err = error.error && error.error.msg;
                    this.toastr.error(err || 'Error in getting events details');
                });

               
        
            }
        });
       
      
   
    }

    public showTasks() {
        this.ngZone.run(() => {
            void this.router.navigate(['/onboarding/employee-tasks/view/'+this.userForm.value.id]);
          });
    }
    onSelectAllClicked() {
        this.selectedCheckBoxList = [];
        this.matCheckBoxes.forEach(
            item => {
                this.selectedCheckBoxList.push(item.value);
                item.checked = true;
            }
        );
    }

    onDeSelectAllClicked() {
        this.selectedCheckBoxList = [];
        this.matCheckBoxes.forEach(
            item => {
                item.checked = false;
            }
        );
    }

    onCheckBoxChange($event: MatCheckboxChange, id: any) {
        if (!this.alreadySelected($event.source) && $event.checked) {
            this.selectedCheckBoxList.push($event.source.value);
        } else if (!$event.checked) {
            this.selectedCheckBoxList = this.selectedCheckBoxList.filter(
                value => {
                    return value !== $event.source.value;
                }
            );
        }
    }

    alreadySelected(item: MatCheckbox): boolean {
        this.selectedCheckBoxList.forEach(
            value => {
                if (item === value) {
                    return true;
                }
            }
        );
        return false;
    }
    protected filterEmployees() {
        if (!this.employeeList) {
            return;
        }
        // get the search keyword
        let search = this.employeeFilterCtrl.value;
        if (!search) {
            this.filteredEmployees = this.employeeList;
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the search
        this.filteredEmployees =
            this.employeeList.filter(emp => emp.label.toLowerCase().indexOf(search) > -1);
    }

    protected filterEmployees2() {
        if (!this.employeeList) {
            return;
        }
        // get the search keyword
        let search = this.employeeFilterCtrl2.value;
        if (!search) {
            this.filteredEmployees2 = this.employeeList;
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the search
        this.filteredEmployees2 =
            this.employeeList.filter(emp => emp.label.toLowerCase().indexOf(search) > -1);
    }

    public createForm(): void {
        this.userForm = this.fb.group({
            dueDate: ['', [Validators.required]],
            locationId: ['', [Validators.required]],
            name: ['', [Validators.required]],
            owners: [[], [Validators.required]],
            participants: [[], [Validators.required]],
            id : ''
        });
    }

    public add(): void {
        let participants = [] ;
        let owners = [];
      
        this.userForm.value.participants?.map(value => {
            participants.push(value.value);
        });
        this.userForm.value.owners?.map(value => {
            owners.push(value.value);
        });
        let obj = 
        {
             id : this.editId ,
             dueDate :   this.userForm.value.dueDate,
             name : this.userForm.value.name,
             locationId : this.userForm.value.locationId,
             participants : participants ,
             owners : owners
        }
        this.onboardingService.editOnboardingEvent(obj).subscribe(user => {
            
            this.toastr.success('Event edited successfully');
            
        }, error => {
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error in editing event.Please try again.');
        });
    }

    remove(id, name) : void {
     if (name === 'parti') {
            let res = this.userForm.value?.participants?.filter(item => item.value !== id);
            this.userForm.patchValue({participants: res});
        }
        else if(name === "ow")
        {
            let res = this.userForm.value?.owners?.filter(item => item.value !== id);
            this.userForm.patchValue({owners: res});
        }
    
    }

    public cancell() {
        this.ngZone.run(() => {
          void this.router.navigate(['/onboarding/occasions'], {queryParams: {action: 'add'}});
        });
    
    }

    public changeEdit() : void 
    {
        if(this.isEdit)
        {
            this.isEdit = false ;
        }
        else 
        {
            this.isEdit = true;
        }

    }

    public openActivationTab() : void 
    {
        this.isLoadingAct = true;
        this.selectedCheckBoxList = [];
        this.onDeSelectAllClicked();
        this.taskTypesList = [];
        this.activeSource = new MatTableDataSource<any>(this.taskTypesList);
        this.onboardingService.getOnboardingTasksList().subscribe(resp => {
            
            if(resp && resp.data)
            {
             this.taskTypesList = resp.data
             this.activeSource = new MatTableDataSource<any>(resp.data);
             this.isLoadingAct = false ;
            }
             
         }, error => {
            this.isLoadingAct = false ;
             const err = error.error && error.error.msg;
             this.toastr.error(err || 'Error in getting tasks !');
         });
        

          this.dialogRef = this.dialog.open(this.activationTab, {
            width: '660px',
            autoFocus: false,
            maxHeight: '90vh' 
            
        });
    }
    public closeModal() : void 
    {
        this.dialogRef.close();
    }

    generateTasks() : void 
    {
        if(this.selectedCheckBoxList.length === 0)
        {
            return ;
        }
        this.isUserAdded = false;
        this.closeModal();
        this.taskNames = [];
        for(let id of this.selectedCheckBoxList)
        {
            for(let task of this.taskTypesList)
            {
                if(id === task.id)
                {
                    this.taskNames.push(task.name);
                    continue;
                }
            }
        }
        this.dialogRef = this.dialog.open(this.generateTab, {
            width: '660px',
            autoFocus: false,
            maxHeight: '90vh' 
            
        });
    }


    public activateEvent() : void 
    {
        this.isUserAdded = true;
        this.onboardingService.activateNeWOnboardingEvents(this.editId,this.selectedCheckBoxList).subscribe(resp => {
            
            if(resp && resp.data)
            {
                this.toastr.success('Event activated successfully');
                this.isUserAdded = false;
                this.closeModal();
                this.activated = true;
                
            }
             
         }, error => {
             this.isUserAdded = false;
             const err = error.error && error.error.msg;
             this.toastr.error(err || 'Error in Activating event !');
         });
        
    }
}