import { Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class UsersService{
    constructor(){}
    private user = new BehaviorSubject<string>('');
    castUser = this.user.asObservable();

    private userSaid = new BehaviorSubject<string>('');
    castUserSaid = this.userSaid.asObservable();

    editUser(newUser){
        this.user.next(newUser);
    }

    editUserSaid(newUser){
        this.userSaid.next(newUser);
    }
}