import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from '../../dashboard.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotificationService } from "src/app/core/services/notification.service";

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {
  public rowsOnPage = 10;
  public filterQuery = '';
  public regionList: Array<any>;
  public userList: Array<any>;
  public isLoading: boolean;
  public isUserAdded: boolean;
  public err: string;
  public modalRef: any;
  public userForm: FormGroup;
  public isEdit: boolean;
  public editId: string;
  public deleteId: string;
  public validationError = {
    required: 'Required',
    email: 'Please provide a valid email',
    password: 'Please provide a password of atleast 8 digits',
    postalcode: 'Should be of 6 digits',
    alphabets: 'Should contain only alphabets',
    contact: 'Please provide a contact of 10 digits'
  };
  public tableConfig = {
    title: true,
    desc: true
  };
  public organizationList = [];
  public countryList = [];
  @ViewChild('addUser',{static:false}) addUser;
  @ViewChild('deleteUser',{static:false}) deleteUser;
  constructor(private service: DashboardService, private modalService: NgbModal, private fb: FormBuilder, private toastr: NotificationService) {
    this.createForm();
  }

  ngOnInit() {
    this.isLoading = true;
    this.isEdit = false;
    this.getJobTitleList();
    this.isUserAdded = false;
    this.service.getOrganizationList()
    .subscribe(resp => {
      if (resp && resp.data) {
        this.organizationList = resp.data;
      }
    });
    this.service.getRegionList().subscribe(resp => {
      if (resp && resp.data) {
        this.regionList = resp.data;
      }
    });
    this.service.getCountryList()
    .subscribe(resp => {
      if (resp && resp.data) {
        this.countryList = resp.data;
      }
    });
  }
  public createForm(): void {
    const num = '^[0-9]*$';
    const alphabets = '^[a-zA-Z\\s]*$';
    this.userForm = this.fb.group({
      name: ['', Validators.required],
      organisationid: ['', Validators.required],
      region: ['', Validators.required],
      status: ['', Validators.required],
      companyemail: ['', [Validators.required, Validators.email]],
      id: [''],
      addresses: this.fb.array([
        this.fb.group({
          addresslineone: ['', [Validators.required]],
          addresslinetwo: ['', [Validators.required]],
          city: ['', [Validators.required, Validators.pattern(alphabets)]],
          contactone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
          contacttwo: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
          country: ['', [Validators.required]],
          countrycode: ['', [Validators.required]],
          countryiso: ['', [Validators.required]],
          id: [''],
          landmark: ['', [Validators.required]],
          mobile: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
          otheremail: ['', [Validators.required]],
          postalcode: [''],
          state: ['', [Validators.required]],
          type: ['', [Validators.required]],
          workemail: ['', [Validators.required, Validators.email]]
        }),
      ])
    });
  }

  public addNewUser() {
    this.isEdit = false;
    this.modalRef = this.modalService.open(this.addUser, {size: 'lg'});
    this.userForm.reset();
  }

  public close(): void {
    this.modalRef.close();
  }

  public getJobTitleList(): void {
    this.service.getCompanyList().subscribe(user => {
      if (user && user.data) {
        this.isLoading = false;
        this.userList = user.data;
        this.err = '';
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error while getting Company');
      this.isLoading = false;
      this.err = 'Error while getting Company';
    });
  }

  public submitAddJobTitle(): void {
    if (this.isEdit) {
      this.edit();
    } else {
      this.add();
    }
  }

  public add(): void {
    this.isUserAdded = true;
    this.service.addCompany(this.userForm.value).subscribe(user => {
      this.isUserAdded = false;
      this.toastr.success('Company added successfully');
      this.modalRef.close();
      this.userForm.reset();
      this.getJobTitleList();
    }, error => {
      const err = error.error && error.error.msg;
      this.isUserAdded = false;
      this.toastr.error(err || 'Error in adding Company.Please try again.');
    });
  }

  public edit(): void {
    this.isUserAdded = true;
      // console.log(this.userForm.controls.region.valid);
      //   console.log(this.regionList);
    this.service.editCompany(this.editId, this.userForm.value).subscribe(user => {
      this.isUserAdded = false;
      this.toastr.success('Company updated successfully');
      this.modalRef.close();
      this.getJobTitleList();
    }, error => {
      const err = error.error && error.error.msg;
      this.isUserAdded = false;
      this.toastr.error(err || 'Error in updating Company.Please try again.');
    });
  }

  public editUser(id): void {
    this.isEdit = true;
    this.service.getCompanyListById(id).subscribe(user => {
      if (user && user.data) {
        this.userForm.patchValue(user.data);
        this.modalRef = this.modalService.open(this.addUser, {size: 'lg'});
        this.editId = id;
        console.log(this.userForm.value);
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error getting Company');
    });
  }

  public deleteUserForm(id): void {
    this.deleteId = id;
    this.modalRef = this.modalService.open(this.deleteUser);
  }

  public deleteJobTitle(): void {
    this.service.deleteCompany(this.deleteId).subscribe(user => {
      if (user) {
        this.close();
        this.getJobTitleList();
        this.toastr.success('Company deleted !');
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error deleting Company');
    });
  }

  public fillCountryDetail(): void {
    const val = this.userForm.value;
    const c = val.addresses[0].country;
    const cl = this.countryList.filter(clist => {
      return clist.name === c;
    });
    val.addresses[0].countrycode = cl[0].code;
    val.addresses[0].countryiso = cl[0].iso;
    val.organisationid = this.organizationList[0].id;
    this.userForm.patchValue(val);
  }

}
