<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Assign history
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="select">
                <mat-header-cell *matHeaderCellDef>
                    <a [matMenuTriggerFor]="menu" class="check-all">
                        <span class="material-icons">
                            more_vert
                        </span>
                      </a>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="onSelectAllClicked()">Select All</button>
                        <button (click)="onDeSelectAllClicked()" *ngIf="selectedCategories.length > 0"
                                mat-menu-item>Deselect All
                        </button>
                        <button (click)="onDeleteSelected()" *ngIf="selectedCategories.length > 0"
                                mat-menu-item>Delete Selected
                        </button>
                    </mat-menu>
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <mat-checkbox #matCheckbox (change)="onNewDocSelectChange($event, row.id)"
                                  value="{{row.id}}"></mat-checkbox>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="assignedToId">
                <mat-header-cell *matHeaderCellDef  mat-sort-header>Employee Id</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    {{row.assignedToId}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="assignedToName">
                <mat-header-cell *matHeaderCellDef  mat-sort-header>Assign To</mat-header-cell>
                <mat-cell *matCellDef="let row">
            {{row.assignedToName}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="assignedFrom">
                <mat-header-cell *matHeaderCellDef  mat-sort-header>From</mat-header-cell>
                <mat-cell *matCellDef="let row">{{row.assignedFrom | date : 'dd-MM-yyyy'}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="assignedTo">
                <mat-header-cell *matHeaderCellDef  mat-sort-header>To</mat-header-cell>
                <mat-cell *matCellDef="let row">{{row.assignedTo| date : 'dd-MM-yyyy'}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="assignedByName">
                <mat-header-cell *matHeaderCellDef  mat-sort-header>Assign By</mat-header-cell>
                <mat-cell *matCellDef="let row">{{row.assignedByName}}</mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;">
            </mat-row>
        </mat-table>
        <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
        <mat-paginator [pageSizeOptions]="[25, 50,100]" showFirstLastButtons></mat-paginator>
    </mat-expansion-panel>
</mat-accordion>



<ng-template #deleteUserSelected let-modal>
    <!-- <div class="card my-0"> -->
    <div mat-dialog-actions>
         <!-- <div class="header">  -->
         <div class="mat-modal-header"> 
             <!-- <h4 class="card-title card-title-modal">Delete User</h4>  -->
          <h4 class="mat-title-modal">Delete Assets</h4> 
        </div>
     <!-- <div class="card-content px-2 py-2"> -->
         <div class="mat-modal-body"> 
          <div class="text-left are-u">Are you sure ?</div>
            <div class="row">
                <div class="multi-btns action-buttons col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close()">
                        <i class="flaticon-cancel"></i> Cancel</button>
                    <button class="btn-primary" color="primary" mat-raised-button
                            (click)="deleteMultiple()"><i class="flaticon-trash"></i> Delete
                    </button>
                </div>
            </div>
          </div>
        </div>
</ng-template>