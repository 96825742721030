<input
  #hoursInput
  [disabled]="disabled"
  type="number"
  [(value)]="hours"
  style="width: 2em"
  min="0"
  max="23"
  maxlength="2"
  placeholder="H"
  (change)="updateHours($event)"
  (keydown)="validateHours($event)"
/>
<input
  name="minutes"
  [disabled]="disabled"
  type="number"
  [value]="minutes"
  style="width: 2em"
  min="0"
  max="59"
  maxlength="2"
  placeholder="M"
  (change)="updateMinutes($event)"
  (keydown)="validateMinutes($event)"
/>
