<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
    <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="page-header-title">
            <span class="ng-star-inserted"></span
            ><span class="ng-star-inserted"
              ><h4 class="m-b-10 ng-star-inserted">{{ this.typeName }}</h4></span
            >
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</div>
<app-loading [isLoading]="this.loadingData == true"></app-loading>
<div class="card">
  <div class="card-content table-responsive table-full-width" *ngIf="this.loadingData == false">
    <mat-horizontal-stepper #stepper [linear]="true">
      <mat-step
        [completed]="false"
        [stepControl]="generalInformationForm"
        errorMessage="Name and location is required."
        label="General Information"
      >
        <form [formGroup]="generalInformationForm" action="javascript:" class="form-material full-width-inputs">
          <ng-container>
            <div class="row form-group">
              <!-- <div class="col-md-2"></div> -->
              <div class="col-md-6">
                <mat-form-field class="form-element">
                  <mat-label>Name</mat-label>
                  <input
                    [readonly]="!update"
                    pattern="*"
                    matInput
                    class="form-control"
                    formControlName="topic"
                    id="title"
                    maxlength="40"
                    required=""
                    type="text"
                  />
                  <mat-error *ngIf="!generalInformationForm.controls.topic.valid && generalInformationForm.touched">
                    {{ validationError.required }}
                  </mat-error>
                </mat-form-field>
                <!-- <input class="form-control" formControlName="topic" id="title" maxlength="40"
                                       required="" type="text">
                                <span class="form-bar"></span>
                                <label class="float-label" for="title">Name</label>
                                <span *ngIf="!generalInformationForm.controls.topic.valid && generalInformationForm.touched"
                                      class="validation-error">{{ validationError.required }}
                                </span> -->
              </div>
              <div *ngIf="this.type === 'D'" class="col-md-6">
                <mat-form-field class="form-element">
                  <mat-label>Category</mat-label>
                  <input
                    [readonly]="!update"
                    required=""
                    class="form-control"
                    type="text"
                    aria-label="Category"
                    matInput
                    formControlName="category"
                    [matAutocomplete]="auto"
                  />
                  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayCategoryNamebyId.bind(this)">
                    <mat-option *ngFor="let r of filteredCategory" [value]="r.id">
                      {{ r.name }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>
            <div class="row form-group">
              <div class="form-default col-md-12">
                <mat-label><h6>Description</h6></mat-label>
              </div>
              <div class="form-default col-md-12">
                <angular-editor class="form-control" [config]="config" formControlName="description"></angular-editor>
                <span class="form-bar"></span>
                <span
                  *ngIf="
                    !generalInformationForm.controls.description.valid &&
                    generalInformationForm.controls.description.touched
                  "
                  class="validation-error"
                >
                  {{ validationError.required }}
                </span>
              </div>
              <div class="col-md-4">
                <mat-checkbox
                  [disabled]="!update"
                  class="example-margin"
                  color="primary"
                  formControlName="acknowledgement"
                  >Request Acknowledgment
                </mat-checkbox>
              </div>
            </div>
            <div class="multi-btns col-md-12 text-right">
              <button mat-raised-button (click)="close()" class="btn-danger">
                <i class="flaticon-cancel"></i>Cancel
              </button>
              <button mat-raised-button (click)="goForward(stepper)" class="btn-primary" color="primary">
                <i class="flaticon-right-arrow"></i>Next
              </button>
            </div>
          </ng-container>
        </form>
      </mat-step>

      <mat-step [stepControl]="publishSettingsForm" errorMessage="required fileds are missing" label="Publish Setting">
        <ng-template matStepperIcon="edit">
          <mat-icon>edit</mat-icon>
        </ng-template>
        <form
          [formGroup]="publishSettingsForm"
          action="javascript:"
          class="form-material full-width-inputs cus-actions-margin"
        >
          <ng-container>
            <app-loading *ngIf="isLoadForm" [isLoading]="isLoadForm"></app-loading>
            <div class="row">
              <div class="col-6">
                <div class="row">
                  <div class="col-12">
                    <mat-form-field class="form-element">
                      <input
                        [readonly]="!update"
                        [matDatepicker]="picker"
                        formControlName="publishDate"
                        matInput
                        placeholder="Choose a date"
                        class="form-control"
                      />
                      <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="col-12">
                    <mat-checkbox
                      [disabled]="!update"
                      style="margin-top: 20px"
                      #AllUserRoles
                      class="form-control"
                      color="primary"
                      formControlName="ptAllUserRole"
                    >
                      Publish To - All User Roles
                    </mat-checkbox>
                    <ng-container *ngIf="!AllUserRoles.checked">
                      <mat-form-field class="form-element">
                        <mat-label for="des">User Role</mat-label>
                        <mat-select
                          [disabled]="!update"
                          style="height: min-content"
                          disableOptionCentering
                          class="form-control"
                          formControlName="ptUserRoles"
                          multiple
                          disableOptionCentering
                        >
                          <mat-select-trigger>
                            <mat-chip-list>
                              <mat-chip
                                *ngFor="let topping of publishSettingsForm.value.ptUserRoles"
                                [selectable]="true"
                                [removable]="this.update"
                                (removed)="remove(topping.id, 'ptUserRoles')"
                              >
                                {{ topping?.name }}
                                <mat-icon matChipRemove>cancel</mat-icon>
                              </mat-chip>
                            </mat-chip-list>
                          </mat-select-trigger>
                          <ng-container>
                            <mat-option *ngFor="let userRole of userRoleList" [value]="userRole">{{
                              userRole.name
                            }}</mat-option>
                          </ng-container>
                        </mat-select>
                      </mat-form-field>
                    </ng-container>
                  </div>
                  <div class="col-12">
                    <div>
                      <mat-checkbox
                        [disabled]="!update"
                        style="margin-top: 20px"
                        #AllLocations
                        class="form-control"
                        color="primary"
                        formControlName="ptAllLocation"
                      >
                        Publish To - All Location
                      </mat-checkbox>
                    </div>
                    <ng-container *ngIf="!AllLocations.checked">
                      <mat-form-field class="form-element">
                        <mat-label>Location</mat-label>
                        <mat-select
                          [disabled]="!update"
                          style="height: min-content"
                          disableOptionCentering
                          class="form-control"
                          formControlName="ptLocations"
                          multiple
                          disableOptionCentering
                        >
                          <mat-select-trigger>
                            <mat-chip-list>
                              <mat-chip
                                *ngFor="let topping of publishSettingsForm.value.ptLocations"
                                [selectable]="true"
                                [removable]="this.update"
                                (removed)="remove(topping.id, 'ptLocations')"
                              >
                                {{ topping?.name }}
                                <mat-icon matChipRemove>cancel</mat-icon>
                              </mat-chip>
                            </mat-chip-list>
                          </mat-select-trigger>
                          <mat-option>
                            <ngx-mat-select-search
                              [formControl]="locationFilterCtrl"
                              placeholderLabel="Search"
                            ></ngx-mat-select-search>
                          </mat-option>
                          <mat-option *ngFor="let item of filteredLocations" [value]="item">
                            {{ item.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </ng-container>
                  </div>
                  <div class="col-12">
                    <mat-form-field class="form-element">
                      <mat-label>Job title</mat-label>
                      <mat-select
                        [disabled]="!update"
                        style="height: min-content"
                        disableOptionCentering
                        class="form-control"
                        formControlName="ptJobTitles"
                        multiple
                        disableOptionCentering
                      >
                        <mat-select-trigger>
                          <mat-chip-list>
                            <mat-chip
                              *ngFor="let topping of publishSettingsForm.value.ptJobTitles"
                              [selectable]="true"
                              [removable]="this.update"
                              (removed)="remove(topping.id, 'ptJobTitles')"
                            >
                              {{ topping?.name }}
                              <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                          </mat-chip-list>
                        </mat-select-trigger>
                        <mat-option>
                          <ngx-mat-select-search
                            [formControl]="jobTitleFilterCtrl"
                            placeholderLabel="Search"
                          ></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let item of filteredJobTitle" [value]="item">
                          {{ item.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-12"></div>
                  <div class="col-12">
                    <!-- <mat-form-field class="example-chip-list">
                      <mat-label>Employee </mat-label>
                      <mat-chip-list #chipList>
                        <mat-chip
                          *ngFor="let fruit of fruits"
                          [selectable]="selectable"
                          [removable]="removable"
                          (removed)="removes(fruit.id)"
                        >
                          {{ fruit.name }}
                          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                        </mat-chip>
                        <input
                          placeholder="type for hints..."
                          #fruitInput
                          [formControl]="fruitCtrl"
                          [matAutocomplete]="auto"
                          [matChipInputFor]="chipList"
                          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                          [matChipInputAddOnBlur]="addOnBlur"
                        />
                      </mat-chip-list>
                      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                        <mat-option *ngFor="let fruit of filteredFruits | async" [value]="fruit">
                          {{ fruit.name }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field> -->
                    <app-lazy-employee-dropdown
                      title="Employee"
                      required="true"
                      multiTokens="true"
                      [selectedEmployees]="employeeList"
                      (employeesSelected)="updateEmployee($event)"
                    ></app-lazy-employee-dropdown>
                  </div>
                  <div class="col-12">
                    <mat-form-field class="form-element">
                      <mat-label>Employee Status</mat-label>
                      <mat-select
                        [disabled]="!update"
                        style="height: min-content"
                        disableOptionCentering
                        class="form-control"
                        formControlName="ptEmployeeStatus"
                        multiple
                        disableOptionCentering
                      >
                        <mat-select-trigger>
                          <mat-chip-list>
                            <mat-chip
                              *ngFor="let topping of publishSettingsForm.value.ptEmployeeStatus"
                              [selectable]="true"
                              [removable]="this.update"
                              (removed)="remove(topping.id, 'ptEmployeeStatus')"
                            >
                              {{ topping?.name }}
                              <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                          </mat-chip-list>
                        </mat-select-trigger>
                        <mat-option>
                          <ngx-mat-select-search
                            [formControl]="employeeStatusFilterCtrl"
                            placeholderLabel="Search"
                          ></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let item of filteredEmployeeStatus" [value]="item">
                          {{ item.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-12">
                    <mat-form-field class="form-element">
                      <mat-label>Sub Unit</mat-label>
                      <mat-select
                        [disabled]="!update"
                        style="height: min-content"
                        disableOptionCentering
                        class="form-control"
                        formControlName="ptSubUnits"
                        multiple
                        disableOptionCentering
                      >
                        <mat-select-trigger>
                          <mat-chip-list>
                            <mat-chip
                              *ngFor="let topping of publishSettingsForm.value.ptSubUnits"
                              [selectable]="true"
                              [removable]="this.update"
                              (removed)="remove(topping.id, 'ptSubUnits')"
                            >
                              {{ topping?.name }}
                              <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                          </mat-chip-list>
                        </mat-select-trigger>
                        <mat-option>
                          <ngx-mat-select-search
                            [formControl]="subUnitFilterCtrl"
                            placeholderLabel="Search"
                          ></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let item of filteredSubUnit" [value]="item">
                          {{ item.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>

            <!--                        <div class="row row-margin">-->
            <!--                            <div class="col-sm-6">-->
            <!--                                <mat-form-field class="percent-width-fuoptionSelectedll">-->
            <!--                                    <input [matDatepicker]="picker" formControlName="publishDate" matInput-->
            <!--                                           placeholder="Choose a date">-->
            <!--                                    <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>-->
            <!--                                    <mat-datepicker #picker></mat-datepicker>-->
            <!--                                </mat-form-field>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                        <div class="row row-margin">-->
            <!--                            <div class="col-12">-->
            <!--                                <mat-checkbox #AllUserRoles class="form-control" color="primary"-->
            <!--                                              formControlName="ptAllUserRole">-->
            <!--                                    Publish To - All User Roles-->
            <!--                                </mat-checkbox>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                        <div class="row">-->
            <!--                            <div class="col-6">-->
            <!--                                <ng-container *ngIf="!AllUserRoles.checked">-->
            <!--                                    <mat-form-field class="form-element">-->
            <!--                                        <mat-label for="des">User Role</mat-label>-->
            <!--                                        <mat-select disableOptionCentering class="form-control"-->
            <!--                                                    formControlName="ptUserRoles" multiple disableOptionCentering>-->
            <!--                                            <ng-container>-->
            <!--                                                <mat-option-->
            <!--                                                        *ngFor="let userRole of userRoleList"-->
            <!--                                                        [value]="userRole.id">{{userRole.name}}</mat-option>-->
            <!--                                            </ng-container>-->
            <!--                                        </mat-select>-->
            <!--                                    </mat-form-field>-->
            <!--                                </ng-container>-->
            <!--                            </div>-->
            <!--                            <div class="col-6">-->

            <!--                                &lt;!&ndash;                                <mat-form-field class="form-element">&ndash;&gt;-->
            <!--                                &lt;!&ndash;                                    <mat-label>Employee</mat-label>&ndash;&gt;-->
            <!--                                &lt;!&ndash;                                    <mat-select style="height: min-content" class="form-control" formControlName="ptEmployees" multiple disableOptionCentering>&ndash;&gt;-->
            <!--                                &lt;!&ndash;                                        <mat-select-trigger>&ndash;&gt;-->
            <!--                                &lt;!&ndash;                                            <mat-chip-list>&ndash;&gt;-->
            <!--                                &lt;!&ndash;                                                <mat-chip *ngFor="let topping of publishSettingsForm.value.ptEmployees">&ndash;&gt;-->
            <!--                                &lt;!&ndash;                                                    {{ topping?.name }}&ndash;&gt;-->
            <!--                                &lt;!&ndash;                                                </mat-chip>&ndash;&gt;-->
            <!--                                &lt;!&ndash;                                            </mat-chip-list>&ndash;&gt;-->
            <!--                                &lt;!&ndash;                                        </mat-select-trigger>&ndash;&gt;-->
            <!--                                &lt;!&ndash;                                        <mat-option>&ndash;&gt;-->
            <!--                                &lt;!&ndash;                                            <ngx-mat-select-search [formControl]="employeeFilterCtrl"&ndash;&gt;-->
            <!--                                &lt;!&ndash;                                                                   placeholderLabel="Search" ></ngx-mat-select-search>&ndash;&gt;-->
            <!--                                &lt;!&ndash;                                        </mat-option>&ndash;&gt;-->
            <!--                                &lt;!&ndash;                                        <mat-option *ngFor="let item of filteredEmployees" [value]="item">&ndash;&gt;-->
            <!--                                &lt;!&ndash;                                            {{item.name}}&ndash;&gt;-->
            <!--                                &lt;!&ndash;                                        </mat-option>&ndash;&gt;-->
            <!--                                &lt;!&ndash;                                    </mat-select>&ndash;&gt;-->
            <!--                                &lt;!&ndash;                                </mat-form-field>&ndash;&gt;-->
            <!--                                <mat-form-field class="form-element">-->
            <!--                                    <mat-label>Employee</mat-label>-->
            <!--                                    <mat-select disableOptionCentering class="form-control"-->
            <!--                                                formControlName="ptEmployees" multiple disableOptionCentering>-->
            <!--                                        <mat-option>-->
            <!--                                            <ngx-mat-select-search [formControl]="employeeFilterCtrl"-->
            <!--                                                                   placeholderLabel="Search"></ngx-mat-select-search>-->
            <!--                                        </mat-option>-->
            <!--                                        <mat-option *ngFor="let item of filteredEmployees" [value]="item.id">-->
            <!--                                            {{item.name}}-->
            <!--                                        </mat-option>-->
            <!--                                    </mat-select>-->
            <!--                                </mat-form-field>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                        <div class="row">-->
            <!--                            <div class="col-12">-->
            <!--                                <mat-checkbox #AllLocations class="form-control" color="primary"-->
            <!--                                              formControlName="ptAllLocation">-->
            <!--                                    Publish To - All Location-->
            <!--                                </mat-checkbox>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                        <div class="row">-->
            <!--                            <div class="col-6">-->
            <!--                                <ng-container *ngIf="!AllLocations.checked">-->
            <!--                                    <mat-form-field class="form-element">-->
            <!--                                        <mat-label>Location</mat-label>-->
            <!--                                        <mat-select disableOptionCentering class="form-control"-->
            <!--                                                    formControlName="ptLocations" multiple disableOptionCentering>-->
            <!--                                            <mat-option>-->
            <!--                                                <ngx-mat-select-search [formControl]="locationFilterCtrl"-->
            <!--                                                                       placeholderLabel="Search"></ngx-mat-select-search>-->
            <!--                                            </mat-option>-->
            <!--                                            <mat-option *ngFor="let item of filteredLocations" [value]="item.id">-->
            <!--                                                {{item.name}}-->
            <!--                                            </mat-option>-->
            <!--                                        </mat-select>-->
            <!--                                    </mat-form-field>-->

            <!--                                </ng-container>-->
            <!--                            </div>-->
            <!--                            <div class="col-6">-->
            <!--                                <mat-form-field class="form-element">-->
            <!--                                    <mat-label>Employee Status</mat-label>-->
            <!--                                    <mat-select disableOptionCentering class="form-control"-->
            <!--                                                formControlName="ptEmployeeStatus" multiple disableOptionCentering>-->
            <!--                                        <mat-option>-->
            <!--                                            <ngx-mat-select-search [formControl]="employeeStatusFilterCtrl"-->
            <!--                                                                   placeholderLabel="Search"></ngx-mat-select-search>-->
            <!--                                        </mat-option>-->
            <!--                                        <mat-option *ngFor="let item of filteredEmployeeStatus" [value]="item.id">-->
            <!--                                            {{item.name}}-->
            <!--                                        </mat-option>-->
            <!--                                    </mat-select>-->
            <!--                                </mat-form-field>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                        <div class="row ">-->
            <!--                            <div class="col-6">-->
            <!--                                <mat-form-field class="form-element">-->
            <!--                                    <mat-label>Job title</mat-label>-->
            <!--                                    <mat-select disableOptionCentering class="form-control"-->
            <!--                                                formControlName="ptJobTitles" multiple disableOptionCentering>-->
            <!--                                        <mat-option>-->
            <!--                                            <ngx-mat-select-search [formControl]="jobTitleFilterCtrl"-->
            <!--                                                                   placeholderLabel="Search"></ngx-mat-select-search>-->
            <!--                                        </mat-option>-->
            <!--                                        <mat-option *ngFor="let item of filteredJobTitle" [value]="item.id">-->
            <!--                                            {{item.name}}-->
            <!--                                        </mat-option>-->
            <!--                                    </mat-select>-->
            <!--                                </mat-form-field>-->
            <!--                            </div>-->
            <!--                            <div class="col-6">-->
            <!--                                <mat-form-field class="form-element">-->
            <!--                                    <mat-label>Sub Unit</mat-label>-->
            <!--                                    <mat-select disableOptionCentering class="form-control" formControlName="ptSubUnits"-->
            <!--                                                multiple disableOptionCentering>-->
            <!--                                        <mat-option>-->
            <!--                                            <ngx-mat-select-search [formControl]="subUnitFilterCtrl"-->
            <!--                                                                   placeholderLabel="Search"></ngx-mat-select-search>-->
            <!--                                        </mat-option>-->
            <!--                                        <mat-option *ngFor="let item of filteredSubUnit" [value]="item.id">-->
            <!--                                            {{item.name}}-->
            <!--                                        </mat-option>-->
            <!--                                    </mat-select>-->
            <!--                                </mat-form-field>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <br />
            <br />
            <br />
            <br />
            <div class="row">
              <div class="action-buttons multi-btns col-md-12 text-left">
                <button (click)="goBack(stepper)" class="btn-danger" mat-raised-button>
                  <i class="flaticon-left-arrow"></i>Previous
                </button>
                <button
                  [hidden]="!update"
                  *ngIf="this.type != 'D'"
                  [disabled]="!generalInformationForm.valid"
                  (click)="submitNewsAndDocuments('sa')"
                  class="btn-primary"
                  color="primary"
                  mat-raised-button
                >
                  <i class="flaticon-diskette"></i>Save
                </button>

                <button
                  [hidden]="!update"
                  [disabled]="!generalInformationForm.valid || !this.correct"
                  *ngIf="this.type === 'D'"
                  (click)="submitNewsAndDocuments('sa')"
                  class="btn-primary"
                  color="primary"
                  mat-raised-button
                >
                  <i class="flaticon-diskette"></i>Save
                </button>

                <button
                  [hidden]="!update"
                  mat-raised-button
                  (click)="submitNewsAndDocuments('pu')"
                  class="btn-primary"
                  color="primary"
                  [disabled]="this.publishSettingsForm.value.publishDate == null"
                >
                  <i class="flaticon-refresh"></i>Publish
                </button>
              </div>
            </div>
          </ng-container>
        </form>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>
