<div id="header-page" class="ng-star-inserted header-page">
    <div class="page-header ng-star-inserted">
        <div class="page-block ng-star-inserted">
            <div class="row align-items-center">
                <div class="col-md-8">
                    <div class="page-header-title">
                        <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4
                            class="m-b-10 ng-star-inserted">{{name}}</h4></span>
                    </div>
                </div>
                <div class="col-md-4"></div>
            </div>
        </div>
    </div>
</div>

<div class="page-body no-title">

    <mat-card class="example-card">

        <mat-card-content>
            <mat-tab-group *ngIf="!isLoading" mat-align-tabs="center">
                <mat-tab label="Session Details">
                    <mat-card class="example-card">
                        <mat-card-content>
                            <app-session-details></app-session-details>
                        </mat-card-content>
                    </mat-card>
                </mat-tab>
                <mat-tab label="Trainers">
                  <app-view-trainers></app-view-trainers>
                </mat-tab>
                <mat-tab label="Employees">
                   <app-sessions-employees></app-sessions-employees>
                </mat-tab>
            </mat-tab-group>
        </mat-card-content>
    </mat-card>
</div>


<ng-template #addCourse let-modal>
    <!-- <div class="card my-0"> -->
    <!-- <div class="header"> -->
    <div class="mat-modal-header">
        <!-- <h4 class="card-title card-title-modal"> -->
        <h4 class="mat-title-modal">
            <span>Add Trainer</span>
        </h4>
    </div>
    <!-- <div class="card-content"> -->
    <div class="mat-modal-body">
        <app-loading *ngIf="isTrainerAdded" [isLoading]="isTrainerAdded"></app-loading>
        <!-- <form class="form-material mt-4" action="javascript:" [formGroup]="userForm" *ngIf="!isUserAdded"> -->
        <form class="form-material full-width-inputs" action="javascript:" [formGroup]="trainerForm"
              *ngIf="!isTrainerAdded">
            <div class="row form-group">
                <div class="col-sm-12">
                    <mat-form-field class="form-element">
                        <mat-label>Trainer</mat-label>
                        <input required="" class="form-control" type="text"
                               aria-label="trainerId"
                               matInput
                               formControlName="trainerId"
                               [matAutocomplete]="auto"

                        >
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayEmployeeByName.bind(this)">
                            <mat-option *ngFor="let r of filteredOptions " [value]="r.value">
                                {{r.label}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error
                                *ngIf="!trainerForm.controls.trainerId.valid && trainerForm.controls.trainerId.touched">
                            please enter trainer
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="action-buttons multi-btns col-sm-12 text-right">
                <button mat-raised-button class="btn-danger" (click)="close1()">
                    <i class="flaticon-cancel"></i>Cancel
                </button>
                <button mat-raised-button class="btn btn-primary" color="primary"
                        [disabled]="!trainerForm.valid ||!this.correct"
                        (click)="submitAddUser(false)"><i class="flaticon-diskette"></i>Save
                </button>
            </div>
        </form>
    </div>
    <!-- </div> -->
</ng-template>


<ng-template #addCourse1 let-modal>
    <!-- <div class="card my-0"> -->
    <!-- <div class="header"> -->
    <div class="mat-modal-header">
        <!-- <h4 class="card-title card-title-modal"> -->
        <h4 class="mat-title-modal">
            <span>Add Employee</span>
        </h4>
    </div>
    <!-- <div class="card-content"> -->
    <div class="mat-modal-body">
        <app-loading *ngIf="isTrainerAdded" [isLoading]="isTrainerAdded"></app-loading>
        <!-- <form class="form-material mt-4" action="javascript:" [formGroup]="userForm" *ngIf="!isUserAdded"> -->
        <div class="row" *ngIf="!isTrainerAdded">
            <div class="col-sm-6">
                <mat-checkbox (change)="onChangeCheck($event)"  color="primary">Add Multiple
                    Employees
                </mat-checkbox>
            </div>
            <div class="col-sm-6" *ngIf="bulkAddParticipant">
                Matches ({{numberOfEmployees?.length}}) Employee(s)
            </div>
        </div>

        <div *ngIf="bulkAddParticipant && !isTrainerAdded">
            <form class="form-material full-width-inputs" action="javascript:">
                <div class="row form-group">
                    <div class="col-6">
                        <mat-form-field class="form-element">
                            <mat-select (selectionChange)="changeLocation($event)" class="form-control" disableOptionCentering>
                                <mat-option value="">--Select--</mat-option>
                                <ng-container *ngFor="let n of locationList">
                                    <mat-option
                                            value="{{n.id}}"> {{ n.location }} </mat-option>
                                </ng-container>
                            </mat-select>
                            <mat-label>Location</mat-label>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field class="form-element">
                            <mat-select (selectionChange)="changesubUnit($event)" class="form-control"
                                        disableOptionCentering>
                                <mat-option value="">--Select--</mat-option>
                                <ng-container
                                        *ngFor="let j of temporaryDepartment">
                                    <mat-option
                                            value="{{j.id}}">{{ j.name }}</mat-option>
                                </ng-container>
                            </mat-select>
                            <mat-label for="des">Sub Unit</mat-label>
                        </mat-form-field>
                    </div>
                </div>
                <div class="action-buttons multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close1()">
                        <i class="flaticon-cancel"></i>Cancel
                    </button>
                    <button mat-raised-button class="btn btn-primary" color="primary"
                            [disabled]="numberOfEmployees.length <= 0"
                            (click)="submitAddUser4()"><i class="flaticon-diskette"></i>Save
                    </button>
                </div>
            </form>
        </div>


        <form class="form-material full-width-inputs" action="javascript:" [formGroup]="participantForm"
              *ngIf="!isTrainerAdded && !bulkAddParticipant">
            <div class="row form-group">
                <div class="col-sm-12">
                    <mat-form-field class="form-element">
                        <mat-label>Employee</mat-label>
                        <input required="" class="form-control" type="text"
                               aria-label="participantId"
                               matInput
                               formControlName="participantId"
                               [matAutocomplete]="auto1"

                        >
                        <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displayEmployeeByName.bind(this)">
                            <mat-option *ngFor="let r of filteredOptions " [value]="r.value">
                                {{r.label}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error
                                *ngIf="!participantForm.controls.participantId.valid && participantForm.controls.participantId.touched">
                            please enter Employee
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="action-buttons multi-btns col-sm-12 text-right">
                <button mat-raised-button class="btn-danger" (click)="close1()">
                    <i class="flaticon-cancel"></i>Cancel
                </button>
                <button mat-raised-button class="btn btn-primary" color="primary"
                        [disabled]="!participantForm.valid ||!this.correct"
                        (click)="submitAddUser(true)"><i class="flaticon-diskette"></i>Save
                </button>
            </div>
        </form>
    </div>
    <!-- </div> -->
</ng-template>


<ng-template #deleteCourse let-modal>
    <!-- <div class="card my-0"> -->
    <div mat-dialog-actions>
        <!-- <div class="header">  -->
        <div class="mat-modal-header">
            <!-- <h4 class="card-title card-title-modal">Delete User</h4>  -->
            <h4 class="mat-title-modal">Delete </h4>
        </div>
        <!-- <div class="card-content px-2 py-2"> -->
        <div class="mat-modal-body">
            <div class="text-left are-u">Are you sure ?</div>
            <div class="row">
                <div class="multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close1()">
                        <i class="flaticon-cancel"></i>Cancel
                    </button>
                    <button class="btn-primary" color="primary" mat-raised-button
                            (click)="deleteMultiple(false)"><i class="flaticon-trash"></i>Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #deleteCourse1 let-modal>
    <!-- <div class="card my-0"> -->
    <div mat-dialog-actions>
        <!-- <div class="header">  -->
        <div class="mat-modal-header">
            <!-- <h4 class="card-title card-title-modal">Delete User</h4>  -->
            <h4 class="mat-title-modal">Delete </h4>
        </div>
        <!-- <div class="card-content px-2 py-2"> -->
        <div class="mat-modal-body">
            <div class="text-left are-u">Are you sure ?</div>
            <div class="row">
                <div class="multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close1()">
                        <i class="flaticon-cancel"></i>Cancel
                    </button>
                    <button class="btn-primary" color="primary" mat-raised-button
                            (click)="deleteMultiple(true)"><i class="flaticon-trash"></i>Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
