import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'specialButton',
  templateUrl: './new-template.component.html',
  styleUrls: ['./new-template.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class NewTemplateComponent implements OnInit {

  @Output()
  buttonClick: EventEmitter<any> = new EventEmitter();

  @Input('type')
  type: string = 'new';

  constructor() { }

  click() {
    this.buttonClick.emit();
  }

  getClass() {
    if(this.type == 'new') 
      return 'fa fa-plus';
    return 'fa fa-filter'
  }

  ngOnInit(): void {
  }

}
