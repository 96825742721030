import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import {AnnouncementService} from "../../../../service/announcement.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import { Router} from "@angular/router";
import { NotificationService } from "src/app/core/services/notification.service";
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox';
import {MatDialog} from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import {saveAs} from 'file-saver-es';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { startWith } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import * as moment from 'moment';
@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss']
})
export class AssetsComponent implements OnInit {

    public selectedAssets = [];

    public dialogRef: any;
    public filterQuery = '';
    public newForm: FormGroup;
    public modalRef: any;
    public deleteId: string;
    public filterForm : FormGroup
    public locationList: Array<any> = [] ;
    public categorys: Array<any> = [] ;
    public employeeList : Array<any> = [] ;
    public statusList : Array<any> = []
    @ViewChild('deleteUser',{static:false}) deleteUser;
    @ViewChild('deleteUserSelected',{static:false}) deleteUserSelected;
    @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
    @ViewChild('FilterForm',{static:false}) FilterForm;
    dataSource: MatTableDataSource<any>;
    displayedColumns = ['select','assetId', 'catName', 'locationName', 'serialNumber', 'model', 'status',
'assignedToId','assignedToName','assignedFrom','warrantyEnd'];
    isLoading = false;
    isSaving = false;
    paging = {
        pageSize: 10,
        totalRecords: 0,
        pageIndex: 0,
        pageSizeOptions: [10, 25, 50, 100]
    }
    @ViewChild(MatSort, {static: false}) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    filteredOptions = [] ;
    public correct1 : boolean = true ;
    filteredOptions2 = [] ;
    public correct2 : boolean = true ;
    filteredOptions3 = [] ;
    public correct3 : boolean = true ;
    filteredOptions4 = [] ;
    public correct4 : boolean = true ;
    filteredOptions5 = [] ;
    public correct5 : boolean = true ;
    constructor(
        private announcementService: AnnouncementService,
        private router: Router,
        private fb: FormBuilder,
        private toastr: NotificationService,
        private dashBoardService: DashboardService,
        private dialog: MatDialog,
        private authService : AuthService
    ) {
        this.newForm = this.fb.group({
            assetId : null ,
	
            serialNumber : null ,
            
            locationId : null ,
            
            status : null ,
        
            model : null,
            
            catId : null,
            
            subordinateOfId : null ,
            
            employeeId : null
        });


        this.createFilterForm();
        
    }


    createFilterForm() : void 
    {
        this.filterForm = this.fb.group({
            assetId : '' ,
	
            serialNumber: '',
            
            locationId  : '' ,
            
            status : '',
        
            model : '',
            
            catId : '' ,
            
            subordinateOfId : '' 
            ,
            employeeId : ''
        });
    }
    public permissions : any;
    public read = false;
    public write = false ;
    public update = false;
    public delete = false;
   
    checkPermission(module,tabs) : void 
    {
        this.permissions = JSON.parse(this.authService.getPermissions());

        this.read = false ;
        this.write = false ;
        this.delete = false ;
        this.update = false;
        if(this.permissions != undefined && this.permissions != null )
        {
            for(let p of this.permissions)
            {
               
                if(p.id === module)
                {
                   
                    if(p.modules != undefined)
                    {
                      
                        for(let tab of p.modules)
                        {
                            if(tab.id === tabs)
                            {
                              
                                for(let move of tab.permits)
                                {
                                    if(move.id === "u")
                                    {
                                        this.update = true;
                                    }
                                    else if(move.id === "r")
                                    {
                                        this.read = true;
                                    }
                                    else if(move.id === "c")
                                    {
                                      
                                        this.write = true;
                                    }
                                    else if(move.id === "d")
                                    {
                                        this.delete = true;
                                    }
                                }
                            }
                        }
                    }
                   
                }
               
            }
        }
       

    }
    ngOnInit() {
        this.dataSource = new MatTableDataSource([]);
        this. read = false;
        this. write = false ;
        this. update = false;
        this. delete = false;
        this.checkPermission("assets","assets_va");
        this.getAssets();
        this.getEmployees();
        this.getVendorIdsandSerialNUmbers();
        this.getStatusList();
        this.getLocations();
        this.getCategories();
        this.initValuesDetection();
    }


    public assetsIds  = [] ;
    public serials = [] ;

    getEmployees() : void 
    {
        this.dashBoardService.getEmployeeDetailList().subscribe(resp => {
            if (resp && resp.data) {
                resp.data.map(e => {
                    this.employeeList.push({label: e.id + ' ' + e.name, value: e.id});
                });

             
             
            }
        });
    }
    getCategories() 
    {
        this.announcementService.getAssetsCategories().subscribe(resp => {
            if (resp && resp.data) {
                resp.data.map(e => {
                    this.categorys.push({label:e.name, value: e.id});
                });
            }
        }, error => {
            this.isLoading = false;
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error in getting categories list');
        });
    }
    getStatusList() : void 
    {
        this.statusList.push(
            {
                value : "InStock",
                label : "In-stock",
                hidden : false 

            },
            {
                value : "Decommission",
                label : "Decommissioned",
                hidden : false 

            },
            {
                value : "Assign",
                label : "In-use",
                hidden : false 

            },
            {
                value : "MarkStolen",
                label : "Stolen",
                hidden : false 

            }
        )
    
}
    getLocations() : void 
    {
        this.dashBoardService.getLocationList().subscribe(resp => {
            if (resp && resp.data) {
              this.locationList = resp.data;
            }
          });
    }
    getVendorIdsandSerialNUmbers() 
    {
        this.serials = [] ;
        this.assetsIds = [] ;
        this.announcementService.getAssetIdAndSerialNUmbers().subscribe(res => {
            this.isLoading = false;
            if (res && res.data) {
              

                this.serials = res.data.serials;
                this.assetsIds = res.data.assetsIds;
             
            }
           
        }, error => {
            const err = error.error && error.error.msg;
            this.isLoading = false;
            this.toastr.error('Error in gettin Assets ..!');
        });
    }

    getAssets() {
        this.isLoading = true;
        var obj = {
            assetId : null ,
	
            serialNumber : null ,
            
            locationId : null ,
            
            status : null ,
        
            model : null,
            
            catId : null,
            
            subordinateOfId : null ,
            
            employeeId : null
        }
        this.announcementService.filterAssest(obj).subscribe(res => {
            this.isLoading = false;
            if (res && res.data) {
                const list = res.data;
                for (let t of list)
                {
                    if(t.status === "MarkStolen")
                    {
                        t.status = "Stolen"
                    }
                    else  if(t.status === "InUse")
                    {
                        t.status = "In-use"
                    }

                    else  if(t.status === "InStock")
                    {
                        t.status = "In-stock"
                    }

                   
                }

                this.dataSource = new MatTableDataSource(list);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            }
           
        }, error => {
            const err = error.error && error.error.msg;
            this.isLoading = false;
            this.toastr.error('Error in gettin Assets ..!');
        });
    }


    openModel() {
        this.router.navigate([`/assets/assets/add`]);
    }

    itemClick(item) {
        this.router.navigate([`/assets/assets/update/${btoa(item.id)}`]);
    }
    deleteAssets(item){
        this.deleteId = item.id;
        this.dialogRef = this.dialog.open(this.deleteUser,{
            width:'440px',
            height:'auto'
        });
    }

    onSelectAllClicked() {
        this.selectedAssets = [];
        this.matCheckBoxes.forEach(
            item => {
                this.selectedAssets.push(item.value);
                item.checked = true;
            }
        );
    }

    onDeSelectAllClicked() {
        this.selectedAssets = [];
        this.matCheckBoxes.forEach(
            item => {
                item.checked = false;
            }
        );
    }

    public deleteMultiple(): void {
        this.announcementService.deleteAssets(this.selectedAssets).subscribe(user => {
            if (user) {
                this.close();
               this.ngOnInit();
                this.toastr.success('Assets deleted successfully');
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error deleting Assets');
        });
        this.onDeSelectAllClicked();
        this.selectedAssets = [];
    }

    onDeleteSelected() {
        // this.dmodalRef = this.modalService.open(this.deleteUser);
        this.dialogRef = this.dialog.open(this.deleteUserSelected, {
            width: '440px',
            height: 'auto'
        });
    }

    onNewDocSelectChange($event: MatCheckboxChange, id: any) {
        if (!this.alreadySelected($event.source) && $event.checked) {
            this.selectedAssets.push($event.source.value);
        } else if (!$event.checked) {
            this.selectedAssets = this.selectedAssets.filter(
                value => {
                    return value !== $event.source.value;
                }
            );
        }
    }

    alreadySelected(item: MatCheckbox): boolean {
        this.selectedAssets.forEach(
            value => {
                if (item === value) {
                    return true;
                }
            }
        );
        return false;
    }

    deleteAsset(){
        const ids = [this.deleteId];
        this.announcementService.deleteAssetById(ids).subscribe(
            user => {
                if(user) {
                    this.close();
                    this.getAssets();
                    this.toastr.success('Asset deleted !');
                }
            }, error => {
                const err = error.error && error.error.msg;
                this.toastr.error(err || 'Error deleting Asset');
                this.close();
            }
        );
    }
    public close(): void {
        this.dialogRef.close();
    }

    exportToCsv() {

        const csv = this.dataSource.filteredData.map(
            row => {
                return [row.assetId,row.catName,row.locationName,row.serialNumber,
                    row.model,row.status,row.assignedToId,row.assignedToName,
                    row.assignedFrom ? moment(row.assignedFrom).format('DD/MM/yyyy')  : "" ,
                    row.warrantyEnd ? moment(row.warrantyEnd).format('DD/MM/yyyy')  : "" ].join(',') + '\n';
            }
        );
        csv.unshift(['Asset Id','Category','Location','Serial Number','Model'
    ,'status','Assigned Employee Id','Assigned Employee','Assigned On','Warranty Ends'].join(',') + '\n');
        const exportDate = new Date();
        // tslint:disable-next-line:max-line-length
        const exportFileName = `Csv-export-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}.csv`;
        saveAs(new Blob(csv, {type: 'text/csv;charset=utf-8'}), exportFileName);
    }

    saveAsPdf() {
        if (this.dataSource.filteredData.length <= 0) {
            this.toastr.error('No Data to export');
            return;
        }
        const body = [];
      
        for (let i = 0; i < this.dataSource.filteredData.length; i++) {
            body.push(
                [
                  
                    this.dataSource.filteredData[i].assetId,
                    this.dataSource.filteredData[i].catName,
                    this.dataSource.filteredData[i].locationName,
                    this.dataSource.filteredData[i].serialNumber,
                    this.dataSource.filteredData[i].model,
                    this.dataSource.filteredData[i].status,
                    this.dataSource.filteredData[i].assignedToId,
                    this.dataSource.filteredData[i].assignedToName,
                    this.dataSource.filteredData[i].assignedFrom ? moment(this.dataSource.filteredData[i].assignedFrom).format('DD/MM/yyyy')  : "",
                    this.dataSource.filteredData[i].warrantyEnd ? moment(this.dataSource.filteredData[i].warrantyEnd).format('DD/MM/yyyy')  : "",
                    
                ]);
        }
        const doc = new jsPDF('portrait', 'pt', 'a4');
        autoTable(doc, {
            head: [['Asset Id','Category','Location','Serial Number','Model'
            ,'status','Assigned Employee Id','Assigned Employee','Assigned On','Warranty Ends']],
            body: body
        });
        const exportDate = new Date();
        const exportFileName = `Assests-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}}`;
        doc.save(exportFileName);
    }


    onModelChange(value: string) {
        this.dataSource.filter = value.trim().toLocaleLowerCase();
    }


    openFilterForm() : void 
    {
        this.dialogRef=this.dialog.open(this.FilterForm,{
            width:'580px',
            height:'auto'
        });
    }
    onResetForm() : void 
    {
        this.filterForm.reset();
        this.getAssets();
        this.getCategories();
        this.initValuesDetection();
    }

    filterVendor () : void 
    {
        this.close();
        this.isLoading = true;
        this.announcementService.filterAssest(this.filterForm.value).subscribe(res => {
            this.isLoading = false;
            if (res && res.data) {
                let list = res.data;
              
                this.dataSource = new MatTableDataSource(list);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            }
            if (res && res.paging) {
                this.paging.totalRecords = res.paging.count;
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.isLoading = false;
            this.toastr.error(err || "Error while getting vendors.") ;
        });
    }


    initValuesDetection() {

        this.filteredOptions = [];
    
        this.filteredOptions2 = [] ;
        this.filteredOptions3 = [] ;
        this.filterForm.controls['assetId'].valueChanges.subscribe(change => {
            startWith("");
            if (change == null || change == undefined) {
                this.correct1 = true;
                this.filteredOptions = [];
            } else {

                this.filterAssetIds(change);
            }
        });

            this.filterForm.controls['serialNumber'].valueChanges.subscribe(change => {
                startWith("");
                if (change == null || change == undefined) {
                    this.correct2 = true;
                    this.filteredOptions2 = [];
                } else {
    
                    this.filterSerialNumbers(change);
                }

        });

        this.filterForm.controls['catId'].valueChanges.subscribe(change => {
            startWith("");
            if (change == null || change == undefined) {
                this.correct3 = true;
                this.filteredOptions3 = [];
            } else {

                this.filterCategories(change);
            }

    });


    this.filterForm.controls['subordinateOfId'].valueChanges.subscribe(change => {
        startWith("");
        if (change == null || change == undefined) {
            this.correct4 = true;
            this.filteredOptions4 = [];
        } else {

            this.filterSub(change);
        }

});


this.filterForm.controls['employeeId'].valueChanges.subscribe(change => {
    startWith("");
    if (change == null || change == undefined) {
        this.correct5 = true;
        this.filteredOptions5 = [];
    } else {

        this.filterEmployee(change);
    }

});
    }
  


protected filterAssetIds(change: String) {
    this.filteredOptions = this.assetsIds;
    if (!this.assetsIds) {
        return;
    }

    // get the search keyword
    if (!change) {
        this.correct1 = true;
        this.filteredOptions = [];
        return;
    } else {
        this.checkAssetChange(change);
        change = change.toLowerCase();
    }
    // filter the search
    this.filteredOptions =
        this.assetsIds.filter(emp => emp.toLowerCase().indexOf(change) > -1);

}

checkAssetChange(change) {
    let x = this.filteredOptions.find(x => x === change );
    if (!x) {
        this.correct1 = false;
    }

}

public displayAssetByName(option) {
    if (!option) {
        return '';
    }
    this.correct1 = true;
    return this.assetsIds.find(x => x === option);
}



protected filterSerialNumbers(change: String) {
    this.filteredOptions2 = this.serials;
    if (!this.serials) {
        return;
    }

    // get the search keyword
    if (!change) {
        this.correct2 = true;
        this.filteredOptions2 = [];
        return;
    } else {
        this.checkSrialChange(change);
        change = change.toLowerCase();
    }
    // filter the search
    this.filteredOptions2 =
        this.serials.filter(emp => emp.toLowerCase().indexOf(change) > -1);

}

checkSrialChange(change) {
    let x = this.filteredOptions2.find(x => x === change );
    if (!x) {
        this.correct2 = false;
    }

}

public displaySerialByName(option) {
    if (!option) {
        return '';
    }
    this.correct2 = true;
    return this.serials.find(x => x === option);
}


protected filterCategories(change: String) {
    this.filteredOptions3 = this.categorys;
    if (!this.categorys) {
        return;
    }

    // get the search keyword
    if (!change) {
        this.correct3 = true;
        this.filteredOptions3 = [];
        return;
    } else {
        this.checkCategoryChange(change);
        change = change.toLowerCase();
    }
    // filter the search
    this.filteredOptions3 =
        this.categorys.filter(emp => emp.label.toLowerCase().indexOf(change) > -1);

}


checkCategoryChange(change) {
    let x = this.filteredOptions3.find(x => x.value === change);
    if (!x) {
        this.correct3 = false;
    }

}

public displayCategoryByName(option) {
    if (!option) {
        return '';
    }
    this.correct3 = true;

    return this.categorys.find(x => x.value === option).label;
}


protected filterSub(change: String) {
    this.filteredOptions4 = this.employeeList;
    if (!this.employeeList) {
        return;
    }

    // get the search keyword
    if (!change) {
        this.correct4 = true;
        this.filteredOptions4 = [];
        return;
    } else {
        this.checkSubChange(change);
        change = change.toLowerCase();
    }
    // filter the search
    this.filteredOptions4 =
        this.employeeList.filter(emp => emp.label.toLowerCase().indexOf(change) > -1);

}


checkSubChange(change) {
    let x = this.filteredOptions4.find(x => x.value === change);
    if (!x) {
        this.correct4 = false;
    }

}

public displaySubByName(option) {
    if (!option) {
        return '';
    }
    this.correct4 = true;

    return this.employeeList.find(x => x.value === option).label;
}


protected filterEmployee(change: String) {
    this.filteredOptions5 = this.employeeList;
    if (!this.employeeList) {
        return;
    }

    // get the search keyword
    if (!change) {
        this.correct5 = true;
        this.filteredOptions5 = [];
        return;
    } else {
        this.checkEmployeeChange(change);
        change = change.toLowerCase();
    }
    // filter the search
    this.filteredOptions5 =
        this.employeeList.filter(emp => emp.label.toLowerCase().indexOf(change) > -1);

}


checkEmployeeChange(change) {
    let x = this.filteredOptions5.find(x => x.value === change);
    if (!x) {
        this.correct5 = false;
    }

}

public displayEmployeeByName(option) {
    if (!option) {
        return '';
    }
    this.correct5 = true;

    return this.employeeList.find(x => x.value === option).label;
}


resetFilter() : void
{
    this.correct1 = true ;
    this.correct2 = true ;
    this.correct3 = true ;
    this.correct4 = true ;
    this.correct5 = true ;
    this.filterForm.reset();
    this.createFilterForm();
    this.toastr.success('Reseted successfully. ');
    this.getAssets();
    this.categorys = [];
    this.announcementService.getAssetsCategories().subscribe(resp => {
        if (resp && resp.data) {
            resp.data.map(e => {
                this.categorys.push({label:e.name, value: e.id});
            });
           this.initValuesDetection();
        }
    }, error => {
        this.isLoading = false;
        const err = error.error && error.error.msg;
        this.toastr.error(err || 'Error in getting categories list');
    });
}


filterAsset() : void 
{
    this.isLoading = true;
   
    this.close();
    if(this.filterForm.value.status === '' )
    {
        this.filterForm.patchValue({status:null});
    }

    this.announcementService.filterAssest(this.filterForm.value).subscribe(res => {
        this.isLoading = false;
        if (res && res.data) {
            const list = res.data;
            this.dataSource = new MatTableDataSource(list);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
           
        }
       
    }, error => {
        const err = error.error && error.error.msg;
        this.isLoading = false;
        this.toastr.error('Error in gettin Assets ..!');
    });
}


}
