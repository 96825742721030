
<div class="row top-tools">
     <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6 left-tools">
        <span class="export-icon-btn csv" (click)="exportToCsv()"><i class="material-icons">get_app</i><span>CSV</span>
        </span>
        <span class="export-icon-btn pdf" (click)="saveAsPdf()"><i class="material-icons">get_app</i><span>PDF</span>
        </span>
     </div>
     <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
        <div class="relative-position search-filter-con"> 
          <span class="icon material-icons ng-tns-c114-0"> search </span>
          <mat-form-field> 
            <input matInput #inputSearch type="search" [(ngModel)]="filterQuery" maxlength="40"
                   (ngModelChange)="onModelChange(filterQuery)"
                   class="form-control input-sm full-data-search" >
          </mat-form-field>
            <span class="mytooltip cus-left tooltip-effect-4">
            <span class="tooltip-item">
                <button mat-mini-fab class="btn btn-primary light add-rounded margin-button-05" (click)="addNewCategory()">
                    <i class="fa fa-plus"></i>
                </button>
            </span>
            <span class="tooltip-content clearfix">
            <span class="tooltip-text">New</span>
            </span>
            </span>
        </div>
     </div>
</div>

<ng-template #addCategory let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
         <h4 class="mat-title-modal"> 
            <span *ngIf="!isEdit">Add </span>
            <span *ngIf="isEdit">Edit </span>
        Document Category</h4>
        </div>
      <div class="mat-modal-body">
        <app-loading *ngIf="apiCalling" [isLoading]="apiCalling"></app-loading>
      
            <form class="form-material full-width-inputs" action="javascript:" [formGroup]="userForm">
               <div class="row form-group">
                <div class="col-md-12">
                    <!-- <div class="form-group"> -->
                        <mat-form-field class="form-element">
                            <mat-label>Name</mat-label>
                            <input pattern= "*" matInput maxlength="40" type="text" class="form-control" id="name" formControlName="name" required="">
                        </mat-form-field> 
                        <!-- <label class="float-label" class="label-after">Name</label>
                        <input maxlength="40"  type="text" class="form-control" id="name" formControlName="name">
                        <span
                                class="validation-error"
                                *ngIf="!userForm.controls.name.valid && userForm.controls.name.touched">
                            {{ validationError.required }}
                        </span> -->
                </div>  
                </div>
                <div class="row">
                    <div class="action-buttons multi-btns col-md-12 text-right">
                        <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i>Cancel</button>
                        <button mat-raised-button class="btn-primary" (click)="submitAddJobCategory()" color="primary"
                        [disabled]="!userForm.valid"><i class="flaticon-diskette"></i>Save
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>