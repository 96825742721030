<div (window:resize)="onResize($event)" [attr.fream-type]="freamType" [attr.layout-type]="layoutType"
  [attr.nav-type]="navType" [attr.pcoded-device-type]="pcodedDeviceType" [attr.sidebar-img-type]="sidebarImgType"
  [attr.sidebar-img]="sidebarImg" [attr.theme-layout]="themeLayout" [attr.vertical-effect]="verticalEffect"
  [attr.vertical-layout]="verticalLayout" [attr.vertical-nav-type]="verticalNavType"
  [attr.vertical-placement]="verticalPlacement" [attr.vnavigation-view]="vnavigationView" [ngClass]="animateSidebar"
  class="pcoded" id="pcoded" (window:beforeunload)="doBeforeUnload()">
  <div class="pcoded-overlay-box"></div>
  <div class="pcoded-container navbar-wrapper">
    <nav class="navbar header-navbar pcoded-header" [attr.header-theme]="headerTheme"
      [attr.pcoded-header-position]="pcodedHeaderPosition" style="z-index: 100000">
      <div class="navbar-wrapper">
        <div #navLogo class="navbar-logo">
          <a #imageLogo class="light logo-img-container">
            <img class="img-fluid logo-img" src="assets/team.png" />
          </a>
        </div>
      </div>
    </nav>

    <div class="pcoded-main-container" [style.margin-top]="headerFixedMargin">
      <div class="pcoded-wrapper">
        <nav id="main_navbar" class="pcoded-navbar sidebar-nav" style="background: white"
          (clickOutside)="onClickedOutsideSidebar($event)" [exclude]="'#mobile-collapse'"
          [attr.navbar-theme]="navBarTheme" [attr.active-item-theme]="activeItemTheme" sub-item-theme="theme2"
          active-item-style="style0" [attr.pcoded-header-position]="pcodedHeaderPosition"
          [attr.pcoded-navbar-position]="pcodedSidebarPosition" [style.top]="headerFixedTop"
          [style.height]="sidebarFixedNavHeight">

          <div appAccordion class="pcoded-inner-navbar main-menu" style="background: transparent">
            <!-- navbar Header -->
            <div id="sidebar-userinfo" class="custom-perfect-scrollbar">
              <div class="custom-scrollbar">
                <div class="main-menu-header">
                  
                </div>
              </div>
            </div>
            <!-- navbar -->
            <perfect-scrollbar [config]="config" [ngClass]="perfectDisable"
              [style.max-height]="scrollSidebarFixedHeight" [style.max-width]="'235px'" [style.scrollbar-width]="'40px'"
              id="p-scrollbar" style="background: white">
              <div #navContent id="navDiv" class="d-color"
                [class]="desktopScrollHeight">
                

                <ul id="nav-main-item" class="pcoded-item pcoded-left-item" [attr.item-border]="itemBorder"
                  [attr.item-border-style]="itemBorderStyle" [attr.subitem-border]="subItemBorder"
                  appAccordionLink group="list">
                  <li #linkType routerLinkActive="active pcoded-trigger" 
                    appAccordionLink group="list" (click)="onLinkTypeClicked(linkType)">
                    <a [routerLinkActive]="['active']" class=""
                      [routerLink]="['/', 'control', 'list']"
                      appAccordionToggle>
                      <span class="pcoded-micon" style="font-size: 18px !important;"><i class="flaticon-user-1"></i><b>L</b></span>
                      <span class="pcoded-mtext">List of clients</span>
                    </a>
                  </li>
                </ul>

                <ul id="nav-main-item" class="pcoded-item pcoded-left-item" [attr.item-border]="itemBorder"
                  [attr.item-border-style]="itemBorderStyle" [attr.subitem-border]="subItemBorder"
                  appAccordionLink group="add">
                  <li #linkType routerLinkActive="active pcoded-trigger" 
                    appAccordionLink group="add" (click)="onLinkTypeClicked(linkType)">
                    <a [routerLinkActive]="['active']" class=""
                      [routerLink]="['/', 'control', 'add']"
                      appAccordionToggle>
                      <span class="pcoded-micon" style="font-size: 18px !important;"><i class="flaticon-diskette"></i><b>A</b></span>
                      <span class="pcoded-mtext">Add client</span>
                    </a>
                  </li>
                </ul>

              </div>
            </perfect-scrollbar>
          </div>
          <!-- </perfect-scrollbar> -->
        </nav>
        <div class="pcoded-content">
          <app-breadcrumbs></app-breadcrumbs>
          <div class="pcoded-inner-content">
            <!-- Main-body start -->
            <div class="main-body">
              <div class="page-wrapper">
                <router-outlet>
                  <title></title>
                  <app-spinner></app-spinner>
                </router-outlet>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
