import { Component, OnInit, Input, ViewChild, Renderer2, ElementRef, AfterViewInit } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'headerTemplate',
  templateUrl: './header-template.component.html',
  styleUrls: ['./header-template.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class HeaderTemplateComponent implements AfterViewInit {

  @ViewChild('header') header: ElementRef;

  @Input('class')
  class: string = '';

  @Input('title')
  title: string;

  constructor(private renderer: Renderer2) {
    // this.renderer.addClass(this.header.nativeElement, 'header-page');
   }

  ngAfterViewInit(): void {

    
  }

}
