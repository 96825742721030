<div id="header-page" class="ng-star-inserted header-page">
    <div class="page-header ng-star-inserted">
        <div class="page-block ng-star-inserted">
        <div class="row align-items-center">
            <div class="col-md-8">
            <div class="page-header-title">
                <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted">Work Weekend</h4></span>
            </div>
            </div>
            <div class="col-md-4"></div>
        </div>
        </div>
    </div>
  </div>

<div class="page-body no-title">
    <div class="row">
        <div class="col-sm-12">
            <div class="row top-tools">
                <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6">
                    <span class="export-icon-btn csv" ><i class="material-icons" (click)="exportToCsv()">get_app</i><span>CSV</span>
                    </span>
                    <span class="export-icon-btn pdf" (click)="saveAsPdf()"><i class="material-icons">get_app</i><span>PDF</span>
                    </span>
                </div>


                <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
                    <div class="relative-position search-filter-con"> 
                      <!-- <span class="icon material-icons ng-tns-c114-0"> search </span> -->
                      <!-- <mat-form-field> 
                        <input matInput #inputSearch type="search" [(ngModel)]="filterQuery" maxlength="30"
                               (ngModelChange)="onModelChange(filterQuery)"
                               class="form-control input-sm full-data-search" >
                      </mat-form-field> -->
                      <span class="mytooltip cus-left tooltip-effect-4" [hidden]="!write">
                        <span class="tooltip-item">
                            <button mat-mini-fab class="btn btn-primary  light add-rounded margin-button-05" (click)="addNewUser()">
                                <i class="fa fa-plus"></i>
                            </button>
                        </span>
                        <span class="tooltip-content clearfix">
                        <span class="tooltip-text">New</span>
                        </span>
                     </span>
                     <span class="mytooltip cus-left tooltip-effect-4">
                        <span class="tooltip-item">
                            <button mat-mini-fab class="btn btn-primary light add-rounded margin-button-05" (click)="openfilter()">
                                <i class="fa fa-filter"></i>
                            </button>
                        </span>
                        <span class="tooltip-content clearfix">
                        <span class="tooltip-text">filter</span>
                        </span>
                     </span>
                    </div>
                  </div>
                                <!-- <label class="float-label">Search:
                                    <input type="search" [(ngModel)]="filterQuery"
                                           class="form-control input-sm full-data-search" placeholder="Search name">
                                </label> -->
                            
                                <!-- <button raised-button class="btn btn-primary ripple light" (click)="addNewUser()"> +
                                </button> -->
                      
                    </div>
                    <app-card [title]="'Work Weekend'">
                        <div class="dt-responsive">
                    <div class="table-responsive">
                        <mat-card-content>
                            <mat-table [dataSource]="dataSource" matSort>
                                <ng-container matColumnDef="delete">
                                    <mat-header-cell [hidden]="!delete" *matHeaderCellDef>
                                        <a [matMenuTriggerFor]="menu" class="check-all">
                                                    <span class="material-icons">
                                                        more_horiz
                                                    </span>
                                        </a>
                    
                                        <mat-menu #menu="matMenu">
                                          <button mat-menu-item (click)="onSelectAllClicked()">Select All</button>
                                          <button (click)="onDeSelectAllClicked()" *ngIf="selectedCheckBoxList.length > 0" mat-menu-item>
                                            Deselect All
                                          </button>
                                          <button (click)="onDeleteSelected()" *ngIf="selectedCheckBoxList.length > 0" mat-menu-item>
                                          Delete Selected
                                          </button>
                                        </mat-menu>
                                      </mat-header-cell>
                                      <mat-cell [hidden]="!delete" *matCellDef="let row">
                                        <mat-checkbox #matCheckbox (change)="onCheckBoxChange($event, row.id)"
                                                      value="{{row.id}}"></mat-checkbox>
                                      </mat-cell>
                                </ng-container>
                               
                                <ng-container matColumnDef="name">
                                    <mat-header-cell  mat-sort-header *matHeaderCellDef >Name</mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        {{ row.name }}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="date">
                                    <mat-header-cell  mat-sort-header *matHeaderCellDef>Date</mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        {{ row.date |date : 'yyyy-MM-dd' }}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="dayType">
                                    <mat-header-cell  mat-sort-header *matHeaderCellDef>Full Day/Half Day</mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        {{ row.dayType }}
                                    </mat-cell>
                                </ng-container>
                                <ng-container   matColumnDef="countryName">
                                    <mat-header-cell mat-sort-header *matHeaderCellDef>Country</mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        {{ row.countryName }}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="locationName">
                                    <mat-header-cell  mat-sort-header  *matHeaderCellDef>Locations</mat-header-cell>
                                    <mat-cell *matCellDef="let row">
                                        {{ row.locationName }}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="edit">
                                    <mat-header-cell [hidden]="!update" *matHeaderCellDef>Edit</mat-header-cell>
                                    <mat-cell [hidden]="!update" *matCellDef="let row">
                                        <span class="mytooltip tooltip-effect-4">
                                        <span class="tooltip-item"><i class="single-icon-click fa fa-pencil" (click)="editUser(row.id)"></i></span>
                                        <span class="tooltip-content clearfix">
                                        <span class="tooltip-text">Edit</span>
                                        </span>
                                        </span>
                                    </mat-cell>
                                  </ng-container>
                                
                                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: columns;">
                                </mat-row>
                            </mat-table>
                            <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
                            <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                        
                        </mat-card-content>
                        
                    </div>
                </div>
            </app-card>
        </div>
    </div>
</div>

<ng-template #addUser let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
          <h4 class="mat-title-modal">
            <span *ngIf="!isEdit">Add</span>
            <span *ngIf="isEdit">Edit</span>
            <span> Working Weekend</span>

        </h4>
        </div>
        <div class="mat-modal-body">
    <!-- <div class="card my-0">
        <div class="header">
            <h4 class="card-title card-title-modal">
                <span *ngIf="!isEdit">Add</span>
                <span *ngIf="isEdit">Edit</span>
                <span> Working Weekend</span>

            </h4>
        </div>
        <div class="card-content"> -->
            <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>
            <form class="form-material full-width-inputs"  *ngIf="!isUserAdded" action="javascript:" [formGroup]="userForm"
                  >
                <div class="row form-group">
                    <div class="col-md-6"> 
                        <mat-form-field class="form-element">
                          <mat-label>Name</mat-label>
                            <input required matInput type="text" class="form-control" maxlength="30" id="date" formControlName="name">
                            <mat-error *ngIf="(f.name.invalid && (f.name.dirty || f.name.touched)) && f.name.errors.required">
                                Name is required.
                            </mat-error>
                        </mat-form-field> 
                      </div>
                    <div class="col-md-6"> 
                        <mat-form-field class="form-element">
                            <input required  id="dob" class="form-control" [matDatepicker]="picker" formControlName="date"
                               matInput>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-label for="dob">Date</mat-label>
                            <mat-error  *ngIf="(f.date.invalid && (f.date.dirty || f.date.touched)) && (f.date.errors.required)">
                                Date is required.
                            </mat-error>
                        </mat-form-field>
                      </div>

                      <div class="col-md-6">
                        <mat-form-field class="form-element">
                            <mat-label>Half Day/Full Day</mat-label>
                            <mat-select required [(ngModel)]="fullSelected" formControlName="dayType" class="form-control"> 
                                <mat-option [value]="'HalfDay'">Half Day</mat-option> 
                                <mat-option [value]="'FullDay'">Full Day</mat-option> 
                            </mat-select>
                            <mat-error *ngIf="(f.dayType.invalid && (f.dayType.dirty || f.dayType.touched)) && f.dayType.errors.required">
                                Half Day/Full Day is  Required.
                            </mat-error>
                        </mat-form-field>  
                      
                    </div>
                    <div class="col-md-6">
                        <mat-form-field class="form-element">
                            <mat-label>Country</mat-label>
                            <mat-select required (ngModelChange)="onCountryChange()" [(ngModel)]="countrySelected" disableOptionCentering formControlName="countryISO" class="form-control"> 
                                <mat-option  value="">--Select--</mat-option> 
                                <mat-option *ngFor="let c of countryList" [value]="c.iso">{{c.name}}</mat-option> 
                            </mat-select>
                            <mat-error *ngIf="(f.countryISO.invalid && (f.countryISO.dirty || f.countryISO.touched)) && f.countryISO.errors.required">
                                    Country  is required.</mat-error>
                        </mat-form-field>  
                    </div>
                    <div class="col-md-12">
                        <mat-form-field class="form-element">
                            <mat-label>Location</mat-label>
                            <mat-select required disableOptionCentering formControlName="locations" class="form-control" multiple> 
                                <mat-option *ngFor="let c of locationList" [value]="c.value">{{c.label}}</mat-option> 
                            </mat-select>
                        </mat-form-field>
                       
                    </div>

                   
                </div>

                <div class="multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i>Cancel</button>
                    <button mat-raised-button class="btn-primary" [disabled]="!userForm.valid" color="primary" (click)="submitAddUser()"><i class="flaticon-diskette"></i>Save</button>
                  </div>
                <!-- <div class="mt-4 mb-2">
                    <button type="submit" class="btn btn-cancel btn-danger" (click)="close()">Cancel</button>
                    <button type="submit" class="btn btn-save btn-primary" 
                            (click)="submitAddUser()">Save
                    </button>  <!- [disabled]="!userForm.valid" ->
                </div> -->
            </form>
        </div>
    </div>
</ng-template>

<ng-template #deleteUser let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
          <h4 class="mat-title-modal">Delete Work Week</h4>
        </div>
        <div class="mat-modal-body">
    <!-- <div class="card my-0">
        <div class="header">
            <h4 class="card-title card-title-modal">Delete User</h4>
        </div>
        <div class="card-content px-2 py-2"> -->
            <div class="text-left are-u">Are you sure ?</div>
            <div class="row">
                <div class="multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i>Cancel</button>
                    <button mat-raised-button class="btn-primary" (click)="deleteJobTitle()" color="primary" ><i class="flaticon-trash"></i>Delete</button>
                  </div>
                <!-- <div class="col-sm-12 text-right">
                    <button type="submit" class="btn btn-cancel btn-danger" (click)="close()">Cancel</button>
                    <button type="submit" class="btn btn-save btn-primary" (click)="deleteJobTitle()">Delete</button>
                </div> -->
            </div>
        </div>
    </div>
</ng-template>

<ng-template #configure let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
          <h4 class="mat-title-modal">Configure</h4>
        </div>
        <div class="mat-modal-body">
            
    <!-- <div class="card my-0">
        <div class="header">
            <h4 class="card-title card-title-modal">Configure</h4>
        </div>
        <div class="card-content px-2 py-2"> -->
            <div class="row form-group">
                <!-- <div class=" type-input"> -->
                    <div class="col-md-4 ">
                        <ng-container>
                            <section calss="form-control" >
                                <mat-checkbox [(ngModel)]="tableConfig.userName" id="tuserName" [color]="primary">User Name</mat-checkbox> 
                            </section>
                            <!-- <input type="checkbox" class="" [(ngModel)]="tableConfig.userName"
                            id="tuserName">
                            <span class="form-bar"></span><label class="float-label" for="tuserName">User Name</label> -->
                        </ng-container >
                    </div>
              
                <div class="col-md-4">
                    <ng-container>
                    <section calss="form-control">
                        <mat-checkbox [(ngModel)]="tableConfig.status" id="tstatus" [color]="primary">Status</mat-checkbox> 
                    </section>
                    <!-- <input type="checkbox" class="" [(ngModel)]="tableConfig.status"
                           id="tstatus">
                    <span class="form-bar"></span><label class="float-label" for="tstatus">Status</label> -->
                </ng-container>
                </div>
                <div class="col-md-4">
                    <section calss="form-control">
                        <mat-checkbox [(ngModel)]="tableConfig.region" id="tregion" [color]="primary">Region</mat-checkbox> 
                    </section>
                    <!-- <input type="checkbox" class="" [(ngModel)]="tableConfig.region"
                           id="tregion">
                    <span class="form-bar"></span><label class="float-label" for="tregion">Region</label> -->
                </div>
                <div class="col-md-4">
                    <section calss="form-control">
                        <mat-checkbox [(ngModel)]="tableConfig.userRole" id="tuserRole" [color]="primary">User Role</mat-checkbox> 
                    </section>   
                    <!-- <input type="checkbox" class="" [(ngModel)]="tableConfig.userRole"
                           id="tuserRole">
                    <span class="form-bar"></span><label class="float-label" for="tuserRole">User Role</label> -->
                </div>
                <div class="col-md-4">
                    <section calss="form-control">
                        <mat-checkbox [(ngModel)]="tableConfig.email" id="temail" [color]="primary">Email</mat-checkbox> 
                    </section>       
                    <!-- <input type="checkbox" class="" [(ngModel)]="tableConfig.email" id="temail">
                    <span class="form-bar"></span><label class="float-label" for="temail">Email</label> -->
                </div>
                <!-- </div> -->
            </div>
            <div class="row">
                <div class="col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-ok"></i>Ok</button>
                    <!-- <button type="submit" class="btn btn-cancel btn-danger" (click)="close()">Ok</button> -->
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #filterWorkWeek let-modal>
    <div class="mat-modal-header">
        <h4 class="mat-title-modal">Filter Working Weekends</h4>
    </div>
    <div class="mat-modal-body">
        
        <form [formGroup]="filterForm" action="javascript:" class="form-material full-width-inputs">
            <div class="row form-group">
               
                <div class="col-md-6"> 
                    <mat-form-field class="form-element">
                        <input   id="dob" class="form-control" [matDatepicker]="picker1" formControlName="dateFrom"
                           matInput>
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                        <mat-label for="dob">From</mat-label>
                    </mat-form-field>
                  </div>

                  <div class="col-md-6"> 
                    <mat-form-field class="form-element">
                        <input   id="dob" class="form-control" [matDatepicker]="picker2" formControlName="dateTo"
                           matInput>
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                        <mat-label for="dob">To</mat-label>
                    </mat-form-field>
                  </div>
                  
               
                <div class="col-md-6">
                    <mat-form-field class="form-element">
                        <mat-label>Country</mat-label>
                        <mat-select  (ngModelChange)="onCountryChangeForFilter()" [(ngModel)]="countrySelectedFilter" disableOptionCentering formControlName="countryIso" class="form-control"> 
                            <mat-option  value="">--Select--</mat-option> 
                            <mat-option *ngFor="let c of countryList" [value]="c.iso">{{c.name}}</mat-option> 
                        </mat-select>
                    
                    </mat-form-field>  
                </div>
                <div class="col-md-6">
                    <mat-form-field class="form-element">
                        <mat-label>Location</mat-label>
                        <mat-select  disableOptionCentering formControlName="locations" class="form-control" multiple> 
                            <mat-option *ngFor="let c of locationList" [value]="c.value">{{c.label}}</mat-option> 
                        </mat-select>
                    </mat-form-field>
                   
                </div>
            </div>

           
            <div class="row">
                <div class="action-buttons multi-btns col-sm-12 text-right">
                    <button (click)="OnFilterReset()" class="btn-danger reset-btn" mat-raised-button>
                        <i class="flaticon-refresh"></i>Reset
                    </button>
                    <button (click)="close()" class="btn-danger" mat-raised-button>
                        <i class="flaticon-cancel"></i>Cancel
                    </button>
                    <button (click)="Search()" class="btn btn-primary" color="primary" mat-raised-button type="submit">
                        <i class="flaticon-magnifiying-glass"></i>Search
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>