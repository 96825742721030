import {AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { NotificationService } from "src/app/core/services/notification.service";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import {FormControl} from '@angular/forms';
import {MatSelect} from '@angular/material/select';
import {ReplaySubject, Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {DashboardService} from '../../dashboard.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogHrmComponent} from 'src/app/dialog-hrm/dialog-hrm.component';
import {Router} from '@angular/router';
import {TrainerDialogComponent} from 'src/app/trainer-dialog/trainer-dialog.component';
import {MatCheckbox, MatCheckboxChange} from "@angular/material/checkbox";


@Component({
    selector: 'app-participating-session',
    templateUrl: './my-training-session.component.html',
    styleUrls: ['./my-training-session.component.scss']
})
export class MyTrainingSessionComponent implements OnInit {


    @ViewChild('addCourse1', {static: false}) addCourse1;

    public isSessionStatus1 : boolean;
    public participantForm: FormGroup;
    public courseList: Array<any>;
    public courseList1: Array<any>;
    public correct = true;
    public dialogRef: any;
    public filterQuery = '';
    public dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
    public selectedNewsDocs = [];
    public isArchived: boolean = false;
    public isLoading: boolean = true;
    public err: string;
    public employeeList = [];
    public filteredOptions: Array<any>;
    public temporaryDepartment: Array<any>;
    public currencyList: Array<any> = [];
    public attacherId: string;
    public attachmentList = [];
    public fileType: string;
    public fileObj: any;
    public comment: string;
    showSpinner = false;
    public uploadedFile: any;
    public fileError : any;
    @ViewChild('attach', {static: false}) attach;



    public columns = ['sessionName', 'trainingCourse', 'startDate', 'Status' , 'attachments'];


    @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
    @ViewChild(MatSort, {static: false}) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('filterCourse', {static: false}) filterCourse;

    public filterForm: FormGroup;

    constructor(private service: DashboardService, private route: Router, public dialog: MatDialog, private modalService: NgbModal, private fb: FormBuilder, private toastr: NotificationService) {

        this.createForm();
    }

    public createForm(): void {
        this.filterForm = this.fb.group({
            sessionName : [''],
            courseName : [''],
            startDate : [''],
            status : ['']
        });

        this.participantForm = this.fb.group({
            trainerId: [''],
            trainerName : [''],
            sessionId : [''],
            rating : ['' , Validators.required],
            sessionStatus :[false],
            comment : ['']
        });
    }


    onDownloadClicked(attachment: any) {
        const URL = attachment['url'];
        const FILE_NAME = attachment['fileName'];
        window.open(URL);
    }

    onAddAttachment(id) {
        this.attacherId = id;
        this.showSpinner = true;

        this.service.getAttachmentList(this.attacherId).subscribe(
            r => {
                if (r && r.data) {
                    this.attachmentList = r.data;
                    this.dialogRef = this.dialog.open(this.attach, {
                        width: '560px',
                        height: 'auto'
                    });
                }
                this.showSpinner = false;
            },
            error => {
                this.showSpinner = false;
            }
        );
        this.comment = '';
        this.fileType = '';
        this.fileObj = null;

    }

    ngOnInit() {
        this.getcourseList();
        this.service.getEmployeeDetailList().subscribe(resp => {
            if (resp && resp.data) {
                resp.data.map(e => {
                    this.employeeList.push({label: e.id + ' ' + e.name, value: e.id});
                });
            }
        });

        this.service.getcourseList().subscribe(resp => {
            if (resp && resp.data) {
                this.courseList1  = resp.data;
            }
        });

        this.service.getStructureList().subscribe(resp => {
            if (resp && resp.data) {
                this.temporaryDepartment = resp.data;
            }
        });

        this.service.getPayGradeCurrencyList().subscribe(user => {
            if (user && user.data && user.data.currency) {
                user.data.currency.map(value => {
                    this.currencyList.push(value);
                });
                console.log("data c:  " , this.currencyList);
            }
        });

        this.initValuesDetection();
    }

    initValuesDetection() {
        this.filteredOptions = this.employeeList;

        this.filterForm.controls['coordinatorId'].valueChanges.subscribe(change => {

            if (change == null || change == undefined) {
                this.correct = true;

            } else {

                this.filterEmployees(change);
            }

        });
    }

    protected filterEmployees(change: String) {
        this.filteredOptions = this.employeeList;
        if (!this.employeeList) {
            return;
        }

        // get the search keyword
        if (!change) {

            this.filteredOptions = this.employeeList;
            return;
        } else {
            this.checkEmployeeChange(change);
            change = change.toLowerCase();
        }
        // filter the search
        this.filteredOptions =
            this.employeeList.filter(emp => emp.label.toLowerCase().indexOf(change) > -1);

    }

    checkEmployeeChange(change) {
        let x = this.filteredOptions.find(x => x.value === change);
        if (!x) {
            this.correct = false;
        }

    }

    public isTrainerAdded = false;

    public getcourseList(): void {
        this.courseList = [];
        this.isLoading = true;
        this.service.getTrainerSessionsLit().subscribe(res => {
            if (res && res.data) {
                this.isLoading = false;
                this.courseList = res.data;
                console.log("ddd : " , res.data);
                this.err = '';
                this.dataSource = new MatTableDataSource<any>(this.courseList);
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
            }
        }, error => {
            const _err = error.error && error.error.msg;
            this.isLoading = false;

            this.err = 'Error while getting Sessions List details';
            this.toastr.error(_err || this.err);
        });
    }

    public displayEmployeeByName(option) {
        if (!option) {
            return '';
        }
        this.correct = true;

        return this.employeeList.find(x => x.value === option).label;
    }





    onModelChange(value: string) {
        this.dataSource.filter = value.trim().toLocaleLowerCase();
    }


    resetForm() {
        this.filterForm.reset();
        this.getcourseList();
    }

    search(){

        this.service.filterSessionTraining(this.filterForm.value).subscribe(res => {
            if (res.data && res) {
                this.dataSource = new MatTableDataSource<any>(res.data);
                this.dialogRef.close();
                this.toastr.success('filtered Successfully');
            }
        }, error => {
            this.toastr.error(error.error.msg || 'Error in getting the filter Request');
        });

    }

    filterCourseList(){
        this.dialogRef = this.dialog.open(this.filterCourse, {
            width: '560px',
            height: 'auto'
        });
    }


    public close(): void {
        this.dialogRef.close();
        this.filterForm.reset();
        this.participantForm.reset();
    }

    viewAction(id) {
        this.route.navigate(['/training/course', id]);
    }

    viewAction1(data){
        this.route.navigate(['/training/s/participating-courses/rate', data?.id]);
    }


    submitAddUser() {
        this.isTrainerAdded = true;
            this.service.addSessionRateTraining(this.participantForm.value).subscribe(res => {
                if (res && res.data) {
                    this.isTrainerAdded = false;
                    this.err = '';
                    this.close();
                    this.participantForm.reset();
                    this.toastr.success('Trainer Rated successfully');
                    this.getcourseList();
                }
            }, error => {
                const _err = error.error && error.error.msg;
                this.isLoading = false;
                this.isTrainerAdded = false;
                this.err = 'Error while adding Rate ';
                this.toastr.error(_err || this.err);
            });
        }
}
