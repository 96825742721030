import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { NotificationService } from "src/app/core/services/notification.service";
import { DashboardService } from "src/app/dashboard/dashboard.service";

@Component({
  selector: "app-leave-period",
  templateUrl: "./leave-period.component.html",
  styleUrls: ["./leave-period.component.scss"],
})
export class LeavePeriodsComponent implements OnInit {
  public employeeId: string = "";
  public leaveTypeId: string = "";
  public leavePeriodId: string = "";
  public employeeName: string = "";
  public leaveType: string = "";
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public isLoadingLine: boolean = true;
  public dataList: Array<any> = [];
  public err: string = "";
  public my: string = "";
  displayedTimeLineColumns = [
    "startdate",
    "enddate",
    "total",
    "status",
    
  ];
  constructor(
    private activatedRoute: ActivatedRoute,
    private service: DashboardService,
    private toastr: NotificationService
  ) {}
  ngOnInit(): void {
    this.employeeName = "";
    this.leaveType = "";
    this.my = "";
    this.isLoadingLine = true;
    this.activatedRoute.params.subscribe((params) => {
      this.employeeId = params.empId;
      this.leaveTypeId = params.typeId;
      this.leavePeriodId = params.periodId;
      this.getLeaveTimeLine();
    });
  }

  getLeaveTimeLine() {
    this.isLoadingLine = true;
    this.err = "";
    var obj = {
      employeeId: this.employeeId,
      leaveTypeId: this.leaveTypeId,
      leavePeriodId: this.leavePeriodId,
    };

    this.service.getTimeLineForLeave(obj).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.employeeName = resp.data.employeeName;
          this.leaveType = resp.data.leaveTypeName;
          this.dataList = resp.data.result;
          if (this.dataList.length === 0) {
            this.err = "No Records Found";
          }
          this.dataSource = new MatTableDataSource<any>(this.dataList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.isLoadingLine = false;
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error in getting TimeLine Informations !");
        this.isLoadingLine = false;
      }
    );
  }
}
