<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
      <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
          <div class="col-md-8">
          <div class="page-header-title">
              <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted">Monthly Attendance Report</h4></span>
          </div>
          </div>
          <div class="col-md-4"></div>
      </div>
      </div>
  </div>
  </div>




<div class="page-body no-title">
  <div class="row">
    <div class="col-sm-12">
      <app-card>
        <div class="dt-responsive" >
          
          <div class="file-set" *ngIf="ableToDownloadReport">
              <mat-spinner *ngIf="isDownloading" diameter="80"></mat-spinner>
              <a (click)="getMonthlyAttendanceDataExport()" *ngIf="!isDownloading"><img height="25" src="assets/images/file-csv-solid.svg"/></a>
              <!-- <a href="{{payHourData.pdf}}" ><img height="25" src="assets/images/file-pdf-solid.svg"/></a> -->
          </div>

          <form [formGroup]="monthlyAttendanceForm" class="form-material full-width-inputs">
              <div class="row form-group">
                  <!-- <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6">
                  </div> -->
                  <div class="col-xs-12 col-sm-12 col-md-12 set-searchbar">
                    <div class="col-md-4">  
                         <mat-form-field class="form-element">
                           <mat-label>Employee</mat-label>
                          <mat-select formControlName="empid" class="form-control"  #singleSelect>
                            <mat-option>
                              <ngx-mat-select-search [formControl]="userFilterCtrl"  placeholderLabel="Search"></ngx-mat-select-search><!-- -->
                            </mat-option>
                            <mat-option *ngFor="let item of filteredUsers" [value]="item.id">
                                <span class="d-flex justify-content-between">
                                  <span>{{item.name}}</span>
                                  <span style="display: none">-</span>
                                  <span><span class="badge badge-primary">{{item.empid}}</span></span>
                                </span>
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                        <div class="col-md-4">
                            <mat-form-field class="form-element">
                                <mat-label>Sub Unit</mat-label>
                                <mat-select class="form-control" formControlName="subunitid">
                                  <mat-option *ngFor="let sData of subUnitData" [value]="sData.id">
                                    {{sData.name}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                          </div>
                          <div class="col-md-4">
                            <mat-form-field class="form-element">
                                <mat-label>Location</mat-label>
                                <mat-select class="form-control"  formControlName="locationid">
                                  <mat-option *ngFor="let pData of locationData" [value]="pData.id">
                                    {{pData.location}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                          </div>
                  </div>
                </div>
          <div class="row form-group">
            <!-- <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6">
            </div> -->
            <div class="col-xs-12 col-sm-12 col-md-12 set-searchbar margin-top-set">
                <div class="col-md-4">
                    <mat-form-field class="form-element">
                      <mat-label>Employee Status</mat-label>
                        <mat-select formControlName="empstatusid" class="form-control"  (selectionChange)="add(monthlyAttendanceForm.get('empstatusid').value)" #singleSelect >
                          <mat-option>
                            <ngx-mat-select-search [formControl]="userFilterCtrlEmpStatus" placeholderLabel="Search"></ngx-mat-select-search><!-- -->
                          </mat-option>
                          <mat-option *ngFor="let item of filterEmpStatus" [value]="item">
                            {{item.name}}    {{item.id}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="example-chip-list" *ngIf="selectedEmployeeList.length!=0">
                          <mat-chip-list #chipList aria-label="Fruit selection">
                            <mat-chip *ngFor="let emp of selectedEmployeeList" [selectable]="selectable"
                                     [removable]="removable" (removed)="remove(emp)">
                              {{emp.name}}
                              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                            </mat-chip>
                            <input 
                                   [matChipInputFor]="chipList"
                                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                   [matChipInputAddOnBlur]="addOnBlur"
                                   ><!--  (matChipInputTokenEnd)="add($event)" -->
                          </mat-chip-list>
                        </mat-form-field>           
                  </div>
                <div class="col-md-4">
                  <mat-form-field class="form-element">
                    <mat-label>Year</mat-label>
                    <mat-select class="form-control" formControlName="year">
                      <mat-option *ngFor="let year of optionYears" [value]="year">
                        {{year}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field class="form-element">
                      <mat-label>Month</mat-label>
                      <mat-select formControlName="month" class="form-control">
                          <mat-option value="12">12</mat-option>
                          <mat-option value="11">11</mat-option>
                          <mat-option value="10">10</mat-option>
                          <mat-option value="09">09</mat-option>
                          <mat-option value="08">08</mat-option>
                          <mat-option value="07">07</mat-option>
                          <mat-option value="06">06</mat-option>
                          <mat-option value="05">05</mat-option>
                          <mat-option value="04">04</mat-option>
                          <mat-option value="03">03</mat-option>
                          <mat-option value="02">02</mat-option>
                          <mat-option value="01">01</mat-option>
                      </mat-select>
                    </mat-form-field>
                    </div>
                    
            </div>
          </div>


          <div class="row form-group">
            <div class="col-xs-12 col-sm-12 col-md-12 set-searchbar margin-top-set">
                <div class="col-md-4">
                    <mat-form-field class="form-element">
                        <mat-label>Hours Worked Per Day</mat-label>
                        <mat-select class="form-control" formControlName="hrwrperday">
                            <mat-option [value]="''">-Select-</mat-option>
                          <mat-option *ngFor="let aData of dateFilter" [value]="aData.id">
                            {{aData.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                  </div>
                <div class="col-md-2" *ngIf="monthlyAttendanceForm.get('hrwrperday').value!=''">
                  <mat-form-field class="form-element">
                    <mat-label>Hours (min)</mat-label>
                    <mat-select class="form-control" formControlName="activityId"> <!-- formControlName="activityId" -->
<!--                      <mat-option *ngFor="let aData of activityData" [value]="aData.id">-->
<!--                        {{aData.name}}-->
<!--                      </mat-option>-->
                        <mat-option value="180">180</mat-option>
                        <mat-option value="120">120</mat-option>
                        <mat-option value="60">60</mat-option>
                    </mat-select>
                  </mat-form-field>
                  </div>
                  <div class="col-md-2" *ngIf="monthlyAttendanceForm.get('hrwrperday').value=='between'">
                    <mat-form-field class="form-element">
                      <mat-label>Hours (max)</mat-label>
                      <mat-select class="form-control"  formControlName="activityId"> <!-- formControlName="activityId" -->
<!--                        <mat-option *ngFor="let aData of activityData" [value]="aData.id">-->
<!--                          {{aData.name}}-->
<!--                        </mat-option>-->
                          <mat-option value="180">180</mat-option>
                          <mat-option value="120">120</mat-option>
                          <mat-option value="60">60</mat-option>
                      </mat-select>
                    </mat-form-field>
                    </div>
                    
            </div>
            </div>

            <div class="row form-group">
            <!-- <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6">
            </div> -->
            <div class="col-xs-12 col-sm-12 col-md-12 set-searchbar margin-top-set">
                <div class="col-md-4" >
                    <mat-form-field class="form-element">
                        <mat-label>Hours on Leave Per Day</mat-label>
                        <mat-select class="form-control"  formControlName="hronlvperday">
                            <mat-option [value]="''">-Select-</mat-option>
                          <mat-option *ngFor="let aData of dateFilter" [value]="aData.id">
                            {{aData.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                  </div>
                <div class="col-md-2" *ngIf="monthlyAttendanceForm.get('hronlvperday').value!=''">
                  <mat-form-field class="form-element">
                    <mat-label>Hours (min)</mat-label>
                    <mat-select class="form-control" formControlName="activityId">
                        <mat-option value="180">180</mat-option>
                        <mat-option value="120">120</mat-option>
                        <mat-option value="60">60</mat-option>
                    </mat-select>
                  </mat-form-field>
                  </div>
                  <div class="col-md-2" *ngIf="monthlyAttendanceForm.get('hronlvperday').value=='between'">
                    <mat-form-field class="form-element">
                      <mat-label>Hours (max)</mat-label>
                      <mat-select class="form-control" formControlName="activityId">
                          <mat-option value="180">180</mat-option>
                          <mat-option value="120">120</mat-option>
                          <mat-option value="60">60</mat-option>
                      </mat-select>
                    </mat-form-field>
                    </div>
                    
            </div>
            </div>

            <div class="row form-group">
                <div class="col-xs-12 col-sm-12 col-md-12 set-searchbar-view">
                  <div class="col-sm-4">
                    <div class="form-element">
                      <div class="form-control">* Required field</div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-element">
                      <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
                    </div>
                  </div>
                  <div class="action-buttons multi-btns text-right col-sm-4" *ngIf="!isLoading">
                    <button mat-raised-button  class="btn-danger rest-btn" (click)="resetData()"><i class="flaticon-refresh"></i>Reset</button>
                    <button mat-raised-button color="primary" class="btn btn-primary" (click)="getMonthlyAttendanceData()"><i class="flaticon-refresh"></i>View</button>
                  </div>
                </div>
            </div>
          </form>

        </div>
      </app-card>

          <div class="example-container mat-elevation-z8" *ngIf="showTable">
            <app-loading *ngIf="isLoading" [isLoading]="isLoading"></app-loading>
            <app-card>
            <mat-table [dataSource]="employeeTimeData" matSort >
            
              <ng-container matColumnDef="empName">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Employee Name</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.empName}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="time">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
                  <mat-cell *matCellDef="let row"> {{row.date}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="subUnit">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Sub Unit</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.subUnit}}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="location">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>Location</mat-header-cell>
                      <mat-cell *matCellDef="let row"> {{row.location}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="noOfWork">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>No. of Hours Worked</mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.noOfHoursWrk}}</mat-cell>
                      </ng-container>

              <ng-container matColumnDef="noOfHLeave">
                <mat-header-cell *matHeaderCellDef mat-sort-header>No. of Hours on Leave</mat-header-cell>
                <mat-cell *matCellDef="let row">{{row.noOfHoursLv}}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="reason">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Reason</mat-header-cell>
                  <mat-cell *matCellDef="let row">{{row.reason}}
                  </mat-cell>
                </ng-container>
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;">
              </mat-row>
            </mat-table>

            <mat-paginator [length]="totalRecords"
                           [pageSize]="pageSize"
                           [pageSizeOptions]="pageSizeOptions"
                           [pageIndex]="pageIndex"
                           (page)="getMonthlyAttendanceData($event)">
            </mat-paginator>
          </app-card>
          </div>
      
    </div>
  </div>
</div>





