import { Injectable, Injector, NgZone } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
// import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { tap } from "rxjs/operators";
import { AuthService } from "./auth.service";

@Injectable()
export class InterceptorService implements HttpInterceptor {
  constructor(
    private readonly injector: Injector,
    private readonly router: Router,
    private readonly ngZone: NgZone,
    private authService: AuthService
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //bypass the request that refreshes the token
    if (request.url.includes("/refreshToken")) {
      // If it's a refresh request, don't add the token
      return next.handle(request);
    }

    if (this.authService.isTokenExpired() && !this.authService.isRefTokenExpired()) {
      return this.authService.refreshToken(request, next);
    } else {
      request = this.authService.addToken(request);
    }

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
          }
        },
        (error: any) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              const router = this.injector.get<Router>(Router);
              const errMessage = error && error.error;
              sessionStorage.clear();
              this.ngZone.run(() => {
                void router.navigate(["login"], {
                  queryParams: {
                    alertMessage: "Your session has timed out or been invalidated. Please login again.",
                  },
                });
              });
            }
          }
        }
      )
    );
  }
}
