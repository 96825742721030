<app-loading [isLoading]="this.isLoading === true"></app-loading>
<div [hidden]="isLoading" class="page-body multi-data-tables  minus-top-margin-1">
    <div class="row">
     
        
            <div class="col-sm-6 pl-4 pt-4">
               
            </div>
            <div class="col-sm-6 text-right" [hidden]="!update">
                
                <button mat-mini-fab class="btn btn-primary light add-rounded margin-button-05" (click)="openAddSuperVisorDialog(false)">
                    <i class="fa fa-plus"></i>
                </button>
            </div>
       
  
      
          <div class="col-sm-12">
            <h4 class="mat-title-modal">Supervisor</h4>
              <div class="dt-responsive">
                <div class="table-responsive" >
                  <div class="table-responsive">
                    <mat-card-content>
                      <mat-table [dataSource]="supervisorList" #t1Sort="matSort" matSort>
                        <ng-container matColumnDef="select">
                          <mat-header-cell [hidden]="!delete" *matHeaderCellDef>
                            <a [matMenuTriggerFor]="menu" class="check-all">
                                        <span class="material-icons">
                                            more_vert
                                        </span>
                            </a>
        
                            <mat-menu #menu="matMenu">
                              <button mat-menu-item (click)="onSelectAllClickedSuper()">Select All</button>
                              <button (click)="onDeSelectAllClickedSuper()" *ngIf="selectedCheckBoxListSuper.length > 0" mat-menu-item>
                                Deselect All
                              </button>
                              <button (click)="onDeleteSelectedSuper()" *ngIf="selectedCheckBoxListSuper.length > 0" mat-menu-item>
                                Delete Selected
                                
                              </button>
                            </mat-menu>
                          </mat-header-cell>
                          <mat-cell [hidden]="!delete" *matCellDef="let row">
                            <mat-checkbox #matCheckbox (change)="onCheckBoxChangeSuper($event, row)"
                                          value="{{row}}" #matCheckBoxesSuper></mat-checkbox>
                          </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="name">
                          <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
                          <mat-cell *matCellDef="let row">
                              {{ row.name }}
                          </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="reportingMethod.name">
                          <mat-header-cell *matHeaderCellDef mat-sort-header>Reporting Method</mat-header-cell>
                          <mat-cell *matCellDef="let row">
                              {{ row.reportingMethod.name }}
                          </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="edit">
                          <mat-header-cell [hidden]="!update" *matHeaderCellDef>Edit</mat-header-cell>
                          <mat-cell [hidden]="!update" *matCellDef="let row">
                                        <span class="mytooltip tooltip-effect-4">
                                        <span class="tooltip-item"><i (click)="onSupervisorEdit(row)"
                                                                      class="single-icon-click fa fa-pencil"></i></span>
                                        <span class="tooltip-content clearfix">
                                        <span class="tooltip-text">Edit</span>
                                        </span>
                                        </span>
                          </mat-cell>
                      </ng-container>
                    
                        <mat-header-row *matHeaderRowDef="columnsSuper"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: columnsSuper;">
                        </mat-row>
                      </mat-table>
                      <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
                      <mat-paginator #superPagination  [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons></mat-paginator>
                    </mat-card-content>
                  </div>
                 
                </div>
            </div>
          </div>

                  <div class="col-sm-6 pl-4 pt-4">

                  </div>
                  <div class="col-sm-6 text-right" [hidden]="!update">
                      <button mat-mini-fab class="btn btn-primary light add-rounded margin-button-05" (click)="openAddSubordinateDialog(false)">
                          <i class="fa fa-plus"></i>
                      </button>
                  </div>




          <div class="col-sm-12">
            <h4 class="mat-title-modal">Subordinate</h4>
              <div class="dt-responsive">
                <div class="table-responsive" >
                  <div class="table-responsive">
                    <mat-card-content>
                      <mat-table [dataSource]="subOrdinateList" #t2Sort="matSort" matSort>
                        <ng-container matColumnDef="select">
                            <mat-header-cell [hidden]="!delete" *matHeaderCellDef>
                              <a [matMenuTriggerFor]="menu" class="check-all">
                                          <span class="material-icons">
                                              more_vert
                                          </span>
                              </a>

                              <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="onSelectAllClickedSub()">Select All</button>
                                <button (click)="onDeSelectAllClickedSub()" *ngIf="selectedCheckBoxListSub.length > 0" mat-menu-item>
                                  Deselect All
                                </button>
                                <button (click)="onDeleteSelectedSub()" *ngIf="selectedCheckBoxListSub.length > 0" mat-menu-item>
                                  Delete Selected

                                </button>
                              </mat-menu>
                            </mat-header-cell>
                            <mat-cell [hidden]="!delete" *matCellDef="let row">
                              <mat-checkbox #matCheckbox2 (change)="onCheckBoxChangeSub($event, row)"
                                            value="{{row}}" #matCheckBoxesSub></mat-checkbox>
                            </mat-cell>
                          </ng-container>
                        <ng-container matColumnDef="subordinateName">
                          <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
                          <mat-cell *matCellDef="let row">
                              {{ row.subordinateName }}
                          </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="reportingMethod.name">
                          <mat-header-cell *matHeaderCellDef mat-sort-header>Reporting Method</mat-header-cell>
                          <mat-cell *matCellDef="let row">
                              {{ row.reportingMethod.name}}
                          </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="edit">
                          <mat-header-cell [hidden]="!update" *matHeaderCellDef>Edit</mat-header-cell>
                          <mat-cell [hidden]="!update" *matCellDef="let row">
                                        <span class="mytooltip tooltip-effect-4">
                                        <span class="tooltip-item"><i (click)="onSubordinateEdit(row)"
                                                                      class="single-icon-click fa fa-pencil"></i></span>
                                        <span class="tooltip-content clearfix">
                                        <span class="tooltip-text">Edit</span>
                                        </span>
                                        </span>
                          </mat-cell>
                      </ng-container>


                        <mat-header-row *matHeaderRowDef="columnsSub"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: columnsSub;">
                        </mat-row>
                      </mat-table>
                      <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
                      <mat-paginator #subPaginator  [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons></mat-paginator>
                    </mat-card-content>
                  </div>

                </div>
            </div>
          </div>
      </div>




    </div>
    
    <div class="form-material full-width-inputs"  [hidden]="this.isLoading">
        <div *ngFor="let section of this.sectionsList">
            <div *ngIf="section.customFields.length != 0" class="card-sub">
                <h4>{{section.name}}</h4>
                <ng-container>
                 
                    <div class="row form-group">
                        <div class="col-4"  *ngFor="let custom of section.customFields; index as i">

                           
                            <mat-form-field class="form-element" *ngIf="custom.type === 'dd' ">
                                <mat-select [disabled]="!update" disableOptionCentering class="form-control"
                                [(ngModel)]="custom.value"  required="{{custom.required}}" (ngModelChange)="checkRequiredFieldsInCustomFields(section.id,section.customFields)" >
                                            
                                    <ng-container>
                                        <mat-option value="">--Select--</mat-option>
                                        <mat-option *ngFor="let option of custom.options " [value]="option"  [(ngModel)]="custom.value">
                                            {{option}}
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                                <mat-label>{{custom.name}}</mat-label>
                            </mat-form-field>
    
    
                            <mat-form-field class="form-element" *ngIf="custom.type === 'st' ">
                                <mat-label>{{custom.name}}</mat-label>
                                <input [readonly]="!update" matInput class="form-control"   [(ngModel)]="custom.value" required="{{custom.required}}"  (keyup)="checkRequiredFieldsInCustomFields(section.id,section.customFields)">
                            </mat-form-field>
    
                            <mat-form-field class="form-element" *ngIf="custom.type === 'num' ">
                                <mat-label>{{custom.name}}</mat-label>
                                <input [readonly]="!update" matInput class="form-control"   [(ngModel)]="custom.value" type="number" required="{{custom.required}}" (keyup)="checkRequiredFieldsInCustomFields(section.id,section.customFields)">
                            </mat-form-field>
                            <mat-form-field class="form-element" *ngIf="custom.type === 'dt' ">
                                <mat-label >{{custom.name}}</mat-label>
                            <input [readonly]="!update" class="form-control" matInput [matDatepicker]="i" [attr.id]="dtPicker + i" [(ngModel)]="custom.value" required="{{custom.required}}" (ngModelChange)="checkRequiredFieldsInCustomFields(section.id,section.customFields)">
                            <mat-datepicker-toggle [disabled]="!update" matSuffix [for]="i"></mat-datepicker-toggle>
                            <mat-datepicker #i></mat-datepicker>   
                          </mat-form-field>
    
                          <div *ngIf="custom.type === 'cb' " >
                              <h5>{{custom.name}}:</h5>
                            <div  *ngFor="let option of custom.values">
                                <mat-checkbox  [disabled]="!update" [(ngModel)]="option.booleanValue" >{{option.option}}</mat-checkbox> &nbsp;
                            </div>
                            
                          </div>

    
                        
                     </div>
    
                     
    
                        <div class="action-buttons multi-btns col-sm-12 text-right">
                            <button [hidden]="!update" [disabled]="section.save" (click)="updateCustomField(section.id,section.customFields)"
                                    class="btn-primary"
                                    color="primary"
                                    mat-raised-button><i class="flaticon-diskette"></i>Save
                            </button>
                        </div>    
                    </div>   
                               
                    
                </ng-container>
            </div>
           
        </div>
    
    
    </div>
    <div [hidden]="isLoading" class="card-sub">
      <div class="row">
          <div class="col-sm-10">
              <h4>Report To Attachment</h4>
          </div>
          <div class="col-sm-2">
              <div class="text-right single-add">
                      <span class="mytooltip cus-left tooltip-effect-4" [hidden]="!update">
                          <span class="tooltip-item">
                              <button (click)="addAttachment()"
                                      class="btn btn-primary light add-rounded margin-button-05"
                                      mat-mini-fab
                              >
                                  <i class="fa fa-plus"></i>
                              </button>
                          </span>
                          <span class="tooltip-content clearfix">
                          <span class="tooltip-text">New</span>
                          </span>
                      </span>
              </div>
          </div>
      </div>
    <div class="table-responsive">
      <mat-card-content>
          <mat-table  [dataSource]="reportAttachmentList" #t3Sort="matSort" matSort>
              <ng-container matColumnDef="fileName">
                  <mat-header-cell *matHeaderCellDef
                                   mat-sort-header>FileName
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                      {{ row.fileName }}
                  </mat-cell>
              </ng-container>

              <ng-container matColumnDef="url">
                  <mat-header-cell *matHeaderCellDef
                                  >File
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                          <span class="mytooltip tooltip-effect-4">
                     <span class="tooltip-item"><i
                             (click)="open(row.url)"
                             class="single-icon-click flaticon-attachment"
                             style="color: #666"></i></span>
                       <span class="tooltip-content clearfix">
                     <span class="tooltip-text">Attachment</span>
                    </span>
                      </span>
                      <!--                                                                            <a (click)="open(row.url)"-->
                      <!--                                                                               data-toggle="lightbox">-->
                      <!--                                                                                <div *ngIf="row.url"-->
                      <!--                                                                                     [ngStyle]="{'background-image': 'url(' + row.url + ')'}"-->
                      <!--                                                                                     class="image blank pointer">-->
                      <!--                                                                                </div>-->
                      <!--                                                                            </a>-->
                  </mat-cell>
              </ng-container>

              <ng-container matColumnDef="desc">
                  <mat-header-cell *matHeaderCellDef
                                   >Comment
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                      {{ row.desc }}
                  </mat-cell>
              </ng-container>

              <ng-container matColumnDef="size">
                  <mat-header-cell *matHeaderCellDef>Size
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                      {{ row.size }}
                  </mat-cell>
              </ng-container>

              <ng-container matColumnDef="added">
                  <mat-header-cell *matHeaderCellDef
                                   mat-sort-header>Date Added
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                      {{ row.added | date }}
                  </mat-cell>
              </ng-container>

              <ng-container  matColumnDef="edit">
                  <mat-header-cell [hidden]="!update" *matHeaderCellDef
                                  >Edit
                  </mat-header-cell>
                  <mat-cell [hidden]="!update" *matCellDef="let row">
                      <span class="mytooltip tooltip-effect-4">
                          <span class="tooltip-item"><i
                                  (click)="editAttachment(row)"
                                  class="single-icon-click fa fa-pencil"></i></span>
                          <span class="tooltip-content clearfix">
                          <span class="tooltip-text">Edit</span>
                          </span>
                      </span>
                  </mat-cell>
              </ng-container>

              <ng-container matColumnDef="delete">
                  <mat-header-cell [hidden]="!delete" *matHeaderCellDef
                                   >Delete
                  </mat-header-cell>
                  <mat-cell [hidden]="!delete" *matCellDef="let row">
                      <span class="mytooltip tooltip-effect-4">
                          <span class="tooltip-item"><i
                                  (click)="deleteAttachmentSelected(row.id)"
                                  class="single-icon-click fa fa-trash"></i></span>
                          <span class="tooltip-content clearfix">
                          <span class="tooltip-text">Delete</span>
                          </span>
                      </span>
                  </mat-cell>
              </ng-container>
              <mat-header-row
                      *matHeaderRowDef="columnAttach"></mat-header-row>
              <mat-row *matRowDef="let row; columns: columnAttach;">
              </mat-row>
          </mat-table>
          <mat-progress-bar 
                            mode="indeterminate" *ngIf="this.isLoadingRAttachment"></mat-progress-bar>
          <mat-paginator #attachPaginator [pageSizeOptions]="[5,10, 20]"
                         showFirstLastButtons></mat-paginator>
      </mat-card-content>
  </div>
</div>


<ng-template #addSupervisor let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">
                <span *ngIf="!this.edit">Add</span>
                <span *ngIf="this.edit">Edit</span>
                Supervisor
            </h4>
        </div>
        <div class="mat-modal-body">
            <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>
            <form *ngIf="!isUserAdded" [formGroup]="addSupervisorForm" action="javascript:" class="form-material full-width-inputs">
                <div class="row form-group">
                    <div class="col-sm-6" *ngIf="!this.edit">
                        <mat-form-field class="form-element">
                            <mat-label>Reporting Method</mat-label>
                            <mat-select disableOptionCentering disableOptionCentering (valueChange)="onSupervisorChange($event)" class="form-control"
                                        formControlName="reportingMethod" required="">
                                <mat-option *ngFor="let r of reportingListSuper" [value]="r.id">
                                    {{r.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6" *ngIf="this.edit">
                        <mat-form-field class="form-element">
                            <mat-label>Reporting Method</mat-label>
                            <mat-select disableOptionCentering disableOptionCentering (valueChange)="onSupervisorChange($event)" class="form-control"
                                        formControlName="reportingMethod" disabled>
                                <mat-option *ngFor="let r of reportingList" [value]="r.id">
                                    {{r.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6">
                       

                        <mat-form-field class="form-element">
                            <mat-label>Supervisor</mat-label>
                            <input placeholder="Type for hints .." required="" class="form-control" type="text"
                            aria-label="Employee"
                            matInput
                            formControlName="supervisorId"
                            [matAutocomplete]="auto"
    
                           >
                         <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayEmployeeByName.bind(this)">
                           <mat-option *ngFor="let r of filteredOptions " [value]="r.id">
                         {{r.name}}
                          </mat-option>
                         </mat-autocomplete>
                           
                        </mat-form-field>
                    </div>
                </div>
            </form>
            <div class="row my-2 mx-2">
                <div class="action-buttons multi-btns col-sm-12 text-right">
                    <button (click)="close()" class="btn-danger" mat-raised-button><i class="flaticon-cancel"></i>Cancel
                    </button>
                    <button mat-raised-button  class="btn btn-primary" color="primary"  (click)="saveSupervisor()" [disabled]="!addSupervisorForm.valid "><i class="flaticon-diskette"></i> Save</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #addSubordinate let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">
                <span *ngIf="!this.edit">Add</span>
                <span *ngIf="this.edit">Edit</span>
                Subordinate
            </h4>
        </div>
        <div class="mat-modal-body">
            <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>
            <form *ngIf="!isUserAdded" [formGroup]="addSubordinateForm" action="javascript:" class="form-material full-width-inputs">
                <div class="row form-group">
                    <div class="col-sm-6" *ngIf="!this.edit">
                        <mat-form-field class="form-element">
                            <mat-label>Reporting Method</mat-label>
                            <mat-select disableOptionCentering disableOptionCentering (valueChange)="onSubordinateChange($event)" class="form-control"
                                        formControlName="reportingMethod" required="">
                                <mat-option *ngFor="let r of reportingList" [value]="r.id">
                                    {{r.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6" *ngIf="this.edit">
                        <mat-form-field class="form-element">
                            <mat-label>Reporting Method</mat-label>
                            <mat-select disableOptionCentering disableOptionCentering (valueChange)="onSubordinateChange($event)" class="form-control"
                                        formControlName="reportingMethod"  disabled>
                                <mat-option *ngFor="let r of reportingList" [value]="r.id"  disabled>
                                    {{r.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6">
                        <mat-form-field class="form-element">
                            <mat-label>Subordinate</mat-label>
                            <input placeholder="Type for hints .." required="" class="form-control" type="text"
                            aria-label="Employee"
                            matInput
                            formControlName="subordinateId"
                            [matAutocomplete]="auto"
    
                           >
                         <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayEmployeeByName2.bind(this)">
                           <mat-option *ngFor="let r of filteredOptions2 " [value]="r.id">
                         {{r.name}}
                          </mat-option>
                         </mat-autocomplete>
                           
                        </mat-form-field>
                        
                    </div>
                </div>
            </form>
            <div class="row my-2 mx-2">
                <div class="action-buttons multi-btns col-sm-12 text-right">
                    <button (click)="close()" class="btn-danger" mat-raised-button><i class="flaticon-cancel"></i>Cancel
                    </button>
                    <button mat-raised-button  class="btn btn-primary" color="primary"  (click)="saveSubordinate()" [disabled]="!this.addSubordinateForm.valid"><i class="flaticon-diskette"></i> Save</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #superVisiorDelete let-modal>
    <div mat-dialog-actions>
         <div class="mat-modal-header"> 
          
          <h4 class="mat-title-modal">Delete Supervisor </h4> 
        </div>
         <div class="mat-modal-body"> 
            <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>
          <div [hidden]="isUserAdded" class="text-left are-u">Are you sure ?</div>
            <div class="row" [hidden]="isUserAdded">
              <div class="action-buttons multi-btns col-md-12 text-right">
                <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i> Cancel</button>
                <button mat-raised-button class="btn-primary" (click)="deleteSuperVisior()" color="primary"><i class="flaticon-trash"></i>Delete</button>
              </div>
            </div>
          </div>
        </div>
  </ng-template>

  <ng-template #subDeletion let-modal>
    <div mat-dialog-actions>
         <div class="mat-modal-header"> 
          <h4 class="mat-title-modal">Delete Subordinate</h4> 
        </div>
         <div class="mat-modal-body"> 
            <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>
          <div [hidden]="isUserAdded" class="text-left are-u">Are you sure ?</div>
            <div [hidden]="isUserAdded" class="row">
              <div class="action-buttons multi-btns col-md-12 text-right">
                <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i> Cancel</button>
                <button mat-raised-button class="btn-primary" (click)="deleteSubordinate()" color="primary"><i class="flaticon-trash"></i>Delete</button>
              </div>
            </div>
          </div>
        </div>
  </ng-template>

  <ng-template #editAttach let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">
                Edit Attachment
            </h4>
        </div>
        <div class="mat-modal-body">
            <form class="form-material full-width-inputs">
                <div class="row form-group">
                    <div class="col-md-12">
                        <mat-form-field class="form-element">
                            <mat-label>File Name</mat-label>
                            <input [(ngModel)]="fileName" [ngModelOptions]="{standalone: true}" class="form-control" maxlength="40" 
                                   matInput>
                        </mat-form-field>
                    </div>
                    <div class="col-md-12">
                        <mat-form-field class="form-element">
                            <textarea [(ngModel)]="comment" maxlength="50" matInput class="form-control" name="name"></textarea>
                            <mat-label>Comment</mat-label>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 text-right action-buttons multi-btns">
                        <button mat-raised-button color="primary" (click)="updateAttachment()" class="btn-primary" name="button">
                            <i class="flaticon-diskette"></i>
                            Update
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>
<ng-template #deleteAttachment let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">Delete Attachment</h4>
        </div>
        <div class="mat-modal-body">
            <div class="text-left are-u">Are you sure ?</div>
            <div class="row">
                <div class="multi-btns col-sm-12 text-right">
                    <button (click)="this.dialogRef.close()" class="btn-danger" mat-raised-button>
                        <i class="flaticon-cancel"></i>Cancel
                    </button>
                    <button (click)="deleteAttachmentFrom()" class="btn-primary" color="primary" mat-raised-button>
                        <i class="flaticon-trash"></i>Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #attach let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">
                Attachment
            </h4>
        </div>
        <div class="mat-modal-body">
            <form class="form-material full-width-inputs">
                <div class="row form-group">
                    <div class="col-sm-12">
                        <div class="upload-btn-wrapper">
                            <button class="upload-btn">Attach</button>
                            <input (change)="fileChange($event, 'dd')" [(ngModel)]="fileClean" class="form-control"
                                   name="myfile"
                                   type="file">
                            <div class="fileName">{{this.fileObj && this.fileObj.fileName}}<i *ngIf="fileUrl"
                                                                                              class="fa fa-check success"></i>
                            </div>
                        </div>
                        <div class="upload-btn-wrapper">
                        </div>
                        <div *ngIf="!fileUrl && fileError" class="file-error">{{fileError}}</div>
                        <div class="help-text">Accept upto 2MB</div>
                    </div>
                    <div class="col-md-12">
                        <mat-form-field class="form-element">
                            <mat-label>File Name</mat-label>
                            <input [(ngModel)]="fileName" [ngModelOptions]="{standalone: true}" class="form-control" maxlength="40" 
                                   matInput>
                        </mat-form-field>
                    </div>
                    <div class="col-md-12">
                        <mat-form-field class="form-element">
                            <mat-label>Comment</mat-label>
                            <textarea [(ngModel)]="comment" maxlength="50" class="form-control" matInput name="name"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="action-buttons multi-btns col-sm-12 text-right">
                        <button (click)="saveAttachment()" [disabled]="!this.fileObj.fileName" class="btn-primary"
                                color="primary" mat-raised-button
                                name="button"><i class="flaticon-diskette"></i>Save
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>