import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DashboardService } from "../../../dashboard.service";
import  TimeUtils  from "../../../../shared/utils/TimeUtils";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NotificationService } from "src/app/core/services/notification.service";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import * as moment from "moment";
import { FormControl } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { ReplaySubject, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { MultiSelectTokenComponent } from "src/app/hrmshared/multi-select-token/multi-select-token.component";
import { downloadFile } from "../../../../service/utils";

@Component({
  selector: "app-attendance-summary",
  templateUrl: "./attendance-summary.component.html",
  styleUrls: ["./attendance-summary.component.scss"],
})
export class AttendanceSummaryComponent implements OnInit {
  displayedColumns = ["employeeId", "employeeName", "totalDurationInMinutes"];
  userList: MatTableDataSource<any>;
  // @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  public totalRecords: number;
  public activePage: number;
  public pageSize: number;
  public attendanceSummaryForm: FormGroup;
  public pageSizeOptions: number[] = [10, 25, 50];
  public isLoading: boolean;
  public employeeTimeData: any;
  public showTable: boolean;
  public userFilterCtrl: FormControl = new FormControl();
  employeeStatus: any[];
  allEmployees: any[];
  subUnitData: any[];
  jobTitleData: any[];
  public filteredUsers: any[];

  @ViewChild("singleSelect", { static: false }) singleSelect: MatSelect;

  selectedEmployees: any[] = [];
  selectedJobTitles: any[] = [];
  selectedSubunits: any[] = [];
  selectedEmployeeStatuses: any[] = [];

  @ViewChild("employeeDropdown") employeeDropdown: MultiSelectTokenComponent;
  @ViewChild("jobTitleDropdown") jobTitleDropdown: MultiSelectTokenComponent;
  @ViewChild("subunitDropdown") subunitDropdown: MultiSelectTokenComponent;
  @ViewChild("employeeStatusDropdown") employeeStatusDropdown: MultiSelectTokenComponent;
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);

  public sortBy = [];
  @ViewChild(MatSort) sort: MatSort;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();


  constructor(
    private service: DashboardService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private toastr: NotificationService
  ) {
    this.showTable = false;
    this.employeeStatus = [];
    this.allEmployees = [];
    this.filteredUsers = [];
    this.subUnitData = [];
    this.jobTitleData = [];

    this.attendanceSummaryForm = this.fb.group({
      empIds: [""],
      empStatusIds: [""],
      fromDate: ["", Validators.required],
      toDate: ["", Validators.required],
      jobTitleIds: [""],
      subunitIds: [""],
      fromDateAsStr:[""],
      toDateAsStr:[""],
    });
  }

  public tableConfig = {
    title: true,
    desc: true,
  };

  ngOnInit() {
    this.activePage = 0;
    this.pageSize = 10;
    this.totalRecords = 10;
    //this.isLoading = true;
    // this.getEmployeeList();
    this.getSubUnitData();
    this.getJobTitleList();
    this.getEmplyeeStatus();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.userList.filter = filterValue;
  }

  public export(): void {
    if (!this.attendanceSummaryForm.valid) {
      this.toastr.error("Please fill required fields");
      return;
    }

    this.isLoading = true;
    const filterObj = this.getFilter();

    this.service.exportAttendanceSummary(this.activePage, this.pageSize, this.attendanceSummaryForm.value).subscribe(
      (resp) => {
        try {
          downloadFile(resp, "AttendanceSummary_" + filterObj.reportType);
        } catch (e) {
          console.log(e);
          this.toastr.error(e || "Error in getting data");
        }
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        const msg = error && error.error && error.error.msg;
        this.toastr.error(msg || "Error in getting data");
      }
    );
  }

  public viewAttendanceSummary(resetSort:boolean): void {
    this.isLoading = true;

    const filterObj = this.getFilter();

    if(resetSort && this.sort){
      this.sort.active = '';
      this.sort.direction = '';

      this.sortBy = [];
    }

    this.service.getAttendanceSummary(this.activePage, this.pageSize, this.sortBy, this.attendanceSummaryForm.value).subscribe(
      (res) => {
        if (res && res.data) {
          this.showTable = true;
          this.employeeTimeData = res.data;

          this.dataSource = new MatTableDataSource<any>(this.employeeTimeData);
          this.totalRecords = res.paging.count;   
          this.isLoading = false;  
        }
      },
      (error) => {
        this.isLoading = false;
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error getting activity");
      }
    );
  }

  getFilter() {
    this.attendanceSummaryForm.patchValue({
      empIds: this.selectedEmployees,
      jobTitleIds: this.selectedJobTitles.map(jobTitleInfo => jobTitleInfo.id).join(','),
      empStatusIds: this.selectedEmployeeStatuses.map(employeeStatusInfo => employeeStatusInfo.id).join(','),
      subunitIds: this.selectedSubunits.map(selectedSubUnitInfo => selectedSubUnitInfo.id).join(','),
      fromDateAsStr: moment(new Date(this.attendanceSummaryForm.value.fromDate)).format("YYYY-MM-DD") + ' 00:00',
      toDateAsStr: moment(new Date(this.attendanceSummaryForm.value.toDate)).format("YYYY-MM-DD")+ ' 23:59',
    });

    return this.attendanceSummaryForm.value;
  }

  handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.activePage = event.pageIndex;
    this.viewAttendanceSummary(false);
  }

  public getEmplyeeStatus(): void {
    this.service.getEmployeeStatusList().subscribe(
      (res) => {
        if (res && res.data) {
          res.data.map((e) => {
            this.employeeStatus.push({ name: e.name, id: e.id });
          });
          this.employeeStatusDropdown.setData(this.employeeStatus);
          this.isLoading = false;          
        }
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  public getSubUnitData(): void {
    this.isLoading = true;    
    this.service.getStructureListWithAll().subscribe(
      (res) => {
        if (res && res.data ) {
          res.data.map((e) => {
            this.subUnitData.push({ name: e.name, id: e.id });
          });
          this.subunitDropdown.setData(this.subUnitData);
          this.isLoading = false;          
        }
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  public getJobTitleList(): void {
    this.isLoading = true;
    this.service.getJobTitleList().subscribe(
      (res) => {
        if (res && res.data) {
          res.data.map((e) => {
            this.jobTitleData.push({ name: e.title, id: e.id });
          });
          this.jobTitleDropdown.setData(this.jobTitleData);
          this.isLoading = false;
        }
        },
        (error) => {
          this.isLoading = false;
        }
    );
  }

  // public getEmployeeList(): void {
  //   this.isLoading = true;
  //   this.service.getEmployeeDetailList().subscribe(
  //     (res) => {
  //       res.data.map((e) => {
  //         this.allEmployees.push({ name: e.id + " " + e.name, id: e.id });
  //       });

  //       this.employeeDropdown.setData(this.allEmployees);
  //       this.isLoading = false;
  //     },
  //     (error) => {
  //       this.isLoading = false;
  //     }
  //   );
  // }

  formatTime(minutes){
    return TimeUtils.toHoursAndMinutes(minutes);
  }

  public updateEmployeeInForm($event : Array<any>) {
    const empIds: Array<string> = $event.map(item => item.id);
    this.selectedEmployees = empIds;
  }

  sortData(sort: Sort) {
    if(sort.direction){
      this.sortBy = [{'sortBy': sort.active, 'sortDir' : sort.direction }];
    }else{
      this.sortBy = [];
    } 
    this.viewAttendanceSummary(false);
  }

}
