<mat-form-field class="app-lazy-employee-dropdown form-element" >
  <mat-label>{{ title }}</mat-label>
  <!-- one value -->
  <input
    *ngIf="!multiTokens"
    placeholder="Type for hints .."
    [required]="required"
    class="form-control"
    type="text"
    matInput
    [matAutocomplete]="auto"
    [formControl]="empCtrl"
    style="width: 94%;"
    [(ngModel)] = "selectedEmployee"
    (keyup)="keyup($event.target.value)"
  />
  <!-- multi values -->
  <mat-chip-list #chipList  *ngIf="multiTokens" style="max-width: 94% !important; " >
    <mat-chip
      *ngFor="let item of selectedEmployees"
      [selectable]="selectable"
      [removable]="removable"
      (removed)="removeItem(item.id)"
    >
      {{ item.name }}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input
      placeholder="type for hints..."
      #employeeInput
      [formControl]="empCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
    />
  </mat-chip-list>

  <mat-spinner *ngIf="searching" style="display: inline-block;" class="mat-select-search-spinner" diameter="16"></mat-spinner>

  <mat-autocomplete #auto [displayWith]="displayEmployeeByName.bind(this)" (optionSelected)="selectionChanged($event)">
    <mat-option *ngFor="let emp of filteredEmployees" [value]="emp" >
      {{ emp.name }}
    </mat-option>
  </mat-autocomplete>

  <mat-error *ngFor="let validation of validation_msgs.empCtrl">
    <div *ngIf="empCtrl.hasError(validation.type)">
      {{validation.message}}
    </div>
  </mat-error>
 
</mat-form-field>
