import { Component, OnInit, ViewChild, NgZone, ViewChildren, QueryList } from "@angular/core";
import { DashboardService } from "../../../dashboard.service";
import { NotificationService } from "src/app/core/services/notification.service";
import { ActivatedRoute } from "@angular/router";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { FormGroup, Validators, FormBuilder, FormControl } from "@angular/forms";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { saveAs } from 'file-saver-es';
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { Subject } from "rxjs";
import { startWith, takeUntil } from "rxjs/operators";
import { MatCheckbox, MatCheckboxChange } from "@angular/material/checkbox";
import { AuthService } from "src/app/core/services/auth.service";
import * as moment from "moment";
@Component({
  selector: "app-entitlement-list",
  templateUrl: "./entitlement-list.component.html",
  styleUrls: ["./entitlement-list.component.scss"],
})
export class EntitlementListComponent implements OnInit {
  public entitlementList: Array<any> = [];
  public filterUserForm: FormGroup;
  public searchForm: FormGroup;
  public userForm: FormGroup;
  public leavePeriodList: Array<any>;
  public leaveTypeList: Array<any>;
  public empId: string = "ok";
  public leaveperiodid: string = "";
  public leavetypeid: string;
  public isLoading: boolean = false;
  public isLoadings: boolean = false;
  public err: string;
  public myEntitlement: boolean;
  public totall = 0;
  public title = "Employees Entitlement";
  public filterQuery = "";
  public rowsOnPage = 10;
  public activePage = 1;
  public editId: string;
  public checkmyentitlment: boolean;
  public isUserAdded: boolean;
  public dialogRef: any;
  public isExpanded: boolean = true;
  public userFilterCtrl: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();
  public tempId: string = "";
  public isAdminRole: boolean = false;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild("filterUser", { static: false }) filterUser;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("addUser", { static: false }) addUser;
  @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  @ViewChild("deleteUser", { static: false }) deleteUser;

  public columns = [];

  selectedCheckBoxList: Array<any> = [];
  public selectedEmployee: any;

  constructor(
    private readonly authService: AuthService,
    private service: DashboardService,
    private toastr: NotificationService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private router: Router,
    private ngZone: NgZone,
    private activeRoute: ActivatedRoute
  ) {
    this.createForm();
    // this.filteredEmployees = [];
  }
  public permissions: any;
  public read = false;
  public write = false;
  public update = false;
  public delete = false;

  checkPermission(module, tabs): void {
    this.permissions = JSON.parse(this.authService.getPermissions());
    this.isAdminRole = this.authService.isAdmin();

    this.read = false;
    this.write = false;
    this.delete = false;
    this.update = false;
    if (this.permissions != undefined && this.permissions != null) {
      for (let p of this.permissions) {
        if (p.id === module) {
          if (p.modules != undefined) {
            for (let tab of p.modules) {
              if (tab.id === tabs) {
                for (let move of tab.permits) {
                  if (move.id === "u") {
                    this.update = true;
                  } else if (move.id === "r") {
                    this.read = true;
                  } else if (move.id === "c") {
                    this.write = true;
                  } else if (move.id === "d") {
                    this.delete = true;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  ngOnInit() {
    this.checkPermission("leave", "le_ets");
    this.selectedEmployee = { name: "", id: "" };
    this.empId = "";
    this.leaveperiodid = "";
    this.leavetypeid = "";
    this.isUserAdded = false;
    if (this.activeRoute.snapshot && this.activeRoute.snapshot.data && this.activeRoute.snapshot.data.entitlement) {
      const entitlement = this.activeRoute.snapshot.data.entitlement;
      this.myEntitlement = entitlement === "my" ? true : false;
      this.title = entitlement === "my" ? "My Entitlements" : "Employees Entitlement";
    }
    

    if (this.myEntitlement) {
      this.search();

      // this.columns = ["leavetype", "location", "entitlementtype", "credited_date", "leaveperiod", "entitlementday"];
    } else {
      if(this.isAdminRole){
        this.columns.push("delete");
      }
      this.columns.push(
        "employeeId",
        "employeeName"
      );

      this.service.getLeavePeriodForLeaveUsage().subscribe(
        (resp) => {
          if (resp && resp.data) {
            this.leavePeriodList = resp.data;
          }
        },
        (error) => {
          const err = error.error && error.error.msg;
          this.toastr.error(err || "Error in leave period");
        }
      );
    }

    this.columns.push(
      "leavetype",
      "location",
      "entitlementtype",
      "credited_date",
      "leaveperiod",
      "entitlementday",
    );
  }

  onSelectAllClicked() {
    this.selectedCheckBoxList = [];
    this.matCheckBoxes.forEach((item) => {
      this.selectedCheckBoxList.push(item.value);
      item.checked = true;
    });
  }

  ngOnDestroy(): void {
    this.userFilterCtrl = null;
    this.userForm = null;
    this.searchForm = null;
  }

  onDeSelectAllClicked() {
    this.selectedCheckBoxList = [];
    this.matCheckBoxes.forEach((item) => {
      item.checked = false;
    });
  }

  onDeleteSelected() {
    this.dialogRef = this.dialog.open(this.deleteUser, {
      width: "440px",
      height: "auto",
    });
  }

  onCheckBoxChange($event: MatCheckboxChange, id: any) {
    if (!this.alreadySelected($event.source) && $event.checked) {
      this.selectedCheckBoxList.push($event.source.value);
    } else if (!$event.checked) {
      this.selectedCheckBoxList = this.selectedCheckBoxList.filter((value) => {
        return value !== $event.source.value;
      });
    }
  }

  alreadySelected(item: MatCheckbox): boolean {
    this.selectedCheckBoxList.forEach((value) => {
      if (item === value) {
        return true;
      }
    });
    return false;
  }

  public deleteMultiple(): void {
    let deleteObjects = [];
    for (let ent of this.entitlementList) {
      for (let id of this.selectedCheckBoxList) {
        if (ent.entitlementNumber === id) {
          var obj = {
            employeeId: ent.employeeId,
            entitlementId: ent.entitlementNumber,
          };
          deleteObjects.push(obj);
          break;
        }
      }
    }
    this.service.deleteMultipleEntitlments(deleteObjects).subscribe(
      (user) => {
        if (user) {
          this.close();
          this.search();
          this.toastr.success("Entitlments deleted successfully !");
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.close();
        this.search();
        this.toastr.error(err || "Error deleting entitlments");
      }
    );
    this.selectedCheckBoxList = [];
    this.onDeSelectAllClicked();
  }

  public addNewUser() {
    this.ngZone.run(() => {
      void this.router.navigate(["/leave/entitlement/add-entitlement"], { queryParams: { action: "add" } });
    });
  }

  public createForm(): void {
    this.filterUserForm = this.fb.group({
      leavetypeId: [""],
    });
    this.userForm = this.fb.group({
      entitlementNumber: [""],
      leavetypeName: [""],
      leaveperiodName: [""],
      employeeName: [""],
      entitlementtype: [""],
      credited_date: [""],
      total: [""],
      comment: [""],
      empid: [""],
    });

    this.searchForm = this.fb.group({
      empId: "",
      leavetypeid: "",
      leaveperiodid: "",
    });
  }

  exportToCsv() {
    let csv = [];
    if (this.myEntitlement) {
      csv = this.entitlementList.map((row) => {
        return (
          [
            row.leavetype.leaveType,
            row.entitlementtype,
            row.credited_date,
            row.leaveperiod.currentleaveperiod,
            row.expired,
            row.total,
          ].join(",") + "\n"
        );
      });
      csv.unshift(this.columns.join(",") + "\n");
    } else {
      csv = this.entitlementList.map((row) => {
        return (
          [
            row.employeeId,
            row.employeeName,
            row.leavetype.leaveType,
            row.entitlementtype,
            row.credited_date,
            row.leaveperiod.currentleaveperiod,
            row.expired,
            row.total,
          ].join(",") + "\n"
        );
      });
      csv.unshift(
        [
          "employeeId",
          "employeeName",
          "leavetype",
          "entitlementtype",
          "credited_date",
          "leaveperiod",
          "expired",
          "entitlementday",
        ].join(",") + "\n"
      );
    }

    const exportDate = new Date();
    // tslint:disable-next-line:max-line-length
    const exportFileName = `Csv-export-${exportDate.getFullYear()}-${
      exportDate.getMonth() + 1
    }-${exportDate.getDate()}.csv`;
    saveAs(new Blob(csv, { type: "text/csv;charset=utf-8" }), exportFileName);
  }

  saveAsPdf() {
    if (this.dataSource.filteredData.length <= 0) {
      this.toastr.error("No Data to export");
      return;
    }
    const body = [];
    for (let i = 0; i < this.dataSource.filteredData.length; i++) {
      body.push([
        this.dataSource.filteredData[i].leavetype.leaveType,
        this.dataSource.filteredData[i].entitlementtype,
        this.dataSource.filteredData[i].credited_date,
        this.dataSource.filteredData[i].leaveperiod.currentleaveperiod,
        this.dataSource.filteredData[i].total,
      ]);
    }
    const doc = new jsPDF();
    autoTable(doc, {
      head: [["leavetype", "entitlementtype", "credited_date", "leaveperiod", "entitlementday"]],
      body: body,
    });
    const exportDate = new Date();
    const exportFileName = `User-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}}`;
    doc.save(exportFileName);
  }

  filterUserList() {
    this.dialogRef = this.dialog.open(this.filterUser, {
      width: "560px",
      height: "auto",
    });
  }

  onModelChange(value: string) {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  public close(): void {
    this.dialogRef.close();
  }

  onResetForm() {
    this.dialogRef.close();
    this.filterUserForm.reset();
    this.service.getMyEntitlment().subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.entitlementList = resp.data;
          this.totall = 0;
          this.entitlementList.map((user) => {
            this.totall = this.totall + parseInt(user.total);
          });
          this.isLoading = false;
          this.dataSource = new MatTableDataSource<any>(this.entitlementList);
          this.dataSource.sort = this.sort;
          this.checkmyentitlment = true;
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.isLoading = false;
        this.err = "Error in getting entitlement";
        this.toastr.error(err || "Error in getting entitlement");
      }
    );
    this.toastr.success("Reset Successfully");
  }

  SearchFilterUser() {
    this.isLoading = true;
    this.service.getMyEntitlmentFilter(this.filterUserForm.value.leavetypeId).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.entitlementList = resp.data;
          this.totall = 0;
          this.entitlementList.map((user) => {
            this.totall = this.totall + parseInt(user.total);
          });
          this.isLoading = false;
          this.dataSource = new MatTableDataSource<any>(this.entitlementList);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.close();
          this.checkmyentitlment = true;
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.isLoading = false;
        this.err = "Error in getting entitlement";
        this.toastr.error(err || "Error in getting entitlement");
      }
    );
  }

  public submitAddJobTitle(): void {
    this.edit();
  }
  public edit(): void {
    this.isUserAdded = true;
    this.service.editEntitlment(this.userForm.value).subscribe(
      (user) => {
        this.isUserAdded = false;
        this.toastr.success("Entitlment updated successfully");
        this.dialogRef.close();
        this.isLoading = true;
        this.service.getEntitlementList(this.empId, this.leaveperiodid, this.leavetypeid).subscribe(
          (resp) => {
            if (resp && resp.data) {
              this.entitlementList = resp.data;
              this.totall = 0;
              this.isLoading = false;
              this.dataSource = new MatTableDataSource<any>(this.entitlementList);
              this.dataSource.sort = this.sort;
              this.entitlementList.map((user) => {
                this.totall = this.totall + parseInt(user.total);
              });
              this.checkmyentitlment = true;
            }
            this.isLoading = false;
          },
          (error) => {
            const err = error.error && error.error.msg;
            this.isLoading = false;
            this.err = "Error in getting entitlement";
            this.toastr.error(err || "Error in getting entitlement");
          }
        );
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.isUserAdded = false;
        this.toastr.error(err || "Error in updating Entitlment.Please try again.");
      }
    );
  }
  public editUser(id): void {
    this.isUserAdded = true;
    this.dialogRef = this.dialog.open(this.addUser, {
      width: "560px",
      height: "auto",
    });
    this.service.getEntitlmentById(id).subscribe(
      (user) => {
        if (user && user.data) {
          user.data.credited_date = moment(user.data.credited_date).format("YYYY-MM-DD");
          this.userForm.patchValue(user.data);
          this.isUserAdded = false;
          this.editId = id;
        }
      },
      (error) => {
        this.isUserAdded = false;
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error getting Entitlments");
      }
    );
  }

  public search(): void {
    this.isLoading = true;
    this.err = undefined;
    if (this.myEntitlement) {
      this.service.getMyEntitlment().subscribe(
        (resp) => {
          if (resp && resp.data) {
            this.entitlementList = resp.data;
            this.totall = 0;
            this.entitlementList.map((user) => {
              this.totall = this.totall + parseInt(user.total);
            });

            this.isLoading = false;
            this.dataSource = new MatTableDataSource<any>([]);
            this.dataSource.data = this.entitlementList;
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.checkmyentitlment = true;
          }
        },
        (error) => {
          const err = error.error && error.error.msg;
          this.isLoading = false;
          this.err = "Error in getting entitlement";
          this.toastr.error(err || "Error in getting entitlement");
        }
      );
    } else {
      this.searchForm.patchValue({ empId: this.selectedEmployee.id, leavetypeid: this.leavetypeid });
      this.service
        .getEntitlementList(this.searchForm.value.empId, this.leaveperiodid, this.searchForm.value.leavetypeid)
        .subscribe(
          (resp) => {
            if (resp && resp.data) {
              this.entitlementList = resp.data;
              this.totall = 0;

              this.tempId = this.searchForm.value.empId;
              this.dataSource = new MatTableDataSource<any>(this.entitlementList);
              this.dataSource.sort = this.sort;
              this.entitlementList.map((user) => {
                this.totall = this.totall + parseInt(user.total);
              });
              this.checkmyentitlment = true;
              this.isLoading = false;
            }
          },
          (error) => {
            const err = error.error && error.error.msg;
            this.isLoading = false;
            this.err = "Error in getting entitlement";
            this.toastr.error(err || "Error in getting entitlement");
          }
        );
    }
  }

  changeLeavePeriodValue(event): void {
    this.leaveperiodid = event;
  }

  public updateSelectedEmployee(event) {
    this.selectedEmployee.id = event ? event.id : "";
    this.selectedEmployee.name = event ? event.name : "";

    this.service.getLeaveTypeListForEmployee(this.selectedEmployee.id).subscribe(
      (resp) => {
        if (resp && resp.data) {
          this.leaveTypeList = resp.data;
        }
      },
      (error) => {
        const err = error.error && error.error.msg;
        this.toastr.error(err || "Error in leave Type");
      }
    );
  }
}
