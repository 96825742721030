<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
    <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="page-header-title">
            <span class="ng-star-inserted"></span
            ><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted">Leave Notifications</h4></span>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</div>

<div class="card my-0">
  <div class="card-content">
    <mat-horizontal-stepper [linear]="false" #stepper>
      <mat-step label="Action Based">
        <table mat-table [dataSource]="actionBasedData" class="">
          <!-- Position Column -->
          <ng-container matColumnDef="notification">
            <th mat-header-cell *matHeaderCellDef>Notification</th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
          </ng-container>
          <ng-container matColumnDef="checked">
            <th mat-header-cell *matHeaderCellDef>Enabled</th>
            <td mat-cell *matCellDef="let element">
              <mat-checkbox
                [checked]="getChecked(element)"
                (change)="onStatusChange($event, element.code)"
              ></mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="templates">
            <th mat-header-cell *matHeaderCellDef>Edit</th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button (click)="edit(element.code)">
                <mat-icon>settings</mat-icon>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </mat-step>

      <mat-step label="Date Based">
        <table mat-table [dataSource]="dateBasedData" class="">
          <!-- Position Column -->
          <ng-container matColumnDef="notification">
            <th mat-header-cell *matHeaderCellDef>Notification</th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
          </ng-container>
          <ng-container matColumnDef="checked">
            <th mat-header-cell *matHeaderCellDef>Enabled</th>
            <td mat-cell *matCellDef="let element">
              <mat-checkbox [checked]="getChecked(element)" (change)="onStatusChange($event)"></mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="templates">
            <th mat-header-cell *matHeaderCellDef>Settings</th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button routerLink="templates/{{ element.code }}">
                <mat-icon>settings</mat-icon>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>

<ng-template #editNotification let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">Edit Leave Notification</h4>
    </div>

    <div class="mat-modal-body">
      <form class="form-material full-width-inputs" action="javascript:" [formGroup]="form">
        <div class="row">
          <div class="col-md-12">
            <mat-form-field class="form-element">
              <mat-label>Report Method</mat-label>
              <mat-select class="form-control" formControlName="reportingMethods" multiple>
                <mat-option *ngFor="let rm of allReportingMethods" [value]="rm.id">
                  {{ rm.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('reportMethod')?.hasError('required')">
                Please select Report Method
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <mat-form-field class="form-element">
              <mat-label>Template</mat-label>
              <textarea class="form-control" formControlName="template" matInput rows="15" cols="50"></textarea>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="multi-btns col-sm-12 text-right">
            <button mat-raised-button class="btn-danger" (click)="close()">
              <i class="flaticon-cancel"></i>Cancel
            </button>
            <button mat-raised-button class="btn-primary" color="primary" (click)="submit()">
              <i class="flaticon-diskette"></i>Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
