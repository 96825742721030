import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AnnouncementComponent} from './announcement.component';
import {NewsListComponent} from './news/news-list/news-list.component';
import {CategoryListComponent} from './category/category-list/category-list.component';
import {AddNewsTabComponent} from './news/add-news-tab/add-news-tab.component';
import { AcksListComponent } from './news/ackList/ack-list.component';

const routes: Routes = [{
    path: '',
    component: AnnouncementComponent,
    children: [{
        path: 'news',
         
        children: [
        {
            path:'',
            component: NewsListComponent,
            data: {type: 'news'}
        }  , 
        { 
            path: 'acknowledgment_status/:id',
            component: AcksListComponent, 
        } ]
    }, {
        path: 'documents',
        children: [
            {
                path:'',
                component: NewsListComponent,
                data: {type: 'documents'}
            }  , 
            { 
                path: 'acknowledgment_status/:id',
                component: AcksListComponent, 
            } ]
        
    }, {
        path: ':type/:action',
        component: AddNewsTabComponent
    }, {
        path: ':type/:action/:updateId',
        component: AddNewsTabComponent
    }, {
        path: 'document_categories',
        component: CategoryListComponent
    }]
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AnnouncementRoutingModule {
}
