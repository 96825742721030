import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from '../../../dashboard.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotificationService } from "src/app/core/services/notification.service";
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss']
})
export class LanguagesComponent implements OnInit {

  public dialogRef: any;
  public rowsOnPage = 10;
  public filterQuery = '';
  public sortBy = '';
  public sortOrder = 'desc';
  public userList: Array<any>;
  public isLoading: boolean;
  public isUserAdded: boolean;
  public err: string;
  public modalRef: any;
  public userForm: FormGroup;
  public isEdit: boolean;
  public editId: string;
  public deleteId: string;
  public totalRecords: number;
  public activePage: number;
  public pageSize: number;
  public validationError = {
    required: 'Required'
  };
  @ViewChild('addUser',{static:false}) addUser;
  @ViewChild('deleteUser',{static:false}) deleteUser;
  constructor(private service: DashboardService, 
    private modalService: NgbModal, 
    private fb: FormBuilder, 
    private toastr: NotificationService,
    private dialog: MatDialog) {
    this.createForm();
  }
  public sortDirection = 'asc';
  public tableConfig = {
    name: true
  };

  toTimestamp(strDate): number {
    const datum = Date.parse(strDate);
    return datum / 1000;
  }

  public sortData(key): void {

    this.userList.sort((a, b): number => {
      const A = a;
      const B = b;

      if (typeof (A[key]) === 'string') {
        const _A = A[key] || ' ';
        const _B = B[key] || ' ';
        const first = _A.toLowerCase();
        const sec = _B.toLowerCase();

        return this.sortDirection === 'asc' ? (first >= sec ? 1 : -1) : (first <= sec ? 1 : -1);
      } else if (typeof (A[key]) === 'number') {
        const _A = A[key] || -1;
        const _B = B[key] || -1;
        if (typeof _A === 'number' && typeof _B === 'number') {
          return (this.sortDirection === 'asc' ? (_A - _B) : (_B - _A));
        } else {
          return (this.sortDirection === 'asc' ?
            (parseInt(_A, 10) - parseInt(_B, 10)) : (parseInt(_B, 10) - parseInt(_A, 10)));
        }
      } else {
        const _A = A[key];
        const _B = B[key];
        const dateA = this.toTimestamp(new Date(_A));
        const dateB = this.toTimestamp(new Date(_B));

        return this.sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
      }
  });

    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
  }

  ngOnInit() {
    this.isLoading = true;
    this.isEdit = false;
    this.activePage = 1;
    this.pageSize = 10;
    this.totalRecords = 10;
    this.getJobCategoryList();
    this.isUserAdded = false;

  }

  public createForm(): void {
    this.userForm = this.fb.group({
      name: ['', [Validators.required]]
    });
  }

  public addNewUser() {
    this.isEdit = false;
    this.modalRef = this.modalService.open(this.addUser);
    this.userForm.reset();
  }

  public close(): void {
    this.modalRef.close();
  }

  public getJobCategoryList(): void {
    this.service.getLanguageList(this.activePage, this.pageSize).subscribe(user => {
      if (user && user.data) {
        this.isLoading = false;
        this.userList = user.data;
        this.err = '';
      }
      if (user && user.paging) {
        this.totalRecords = user.paging.count || 10;
      }
    }, error => {
      this.isLoading = false;
      this.err = 'Error while getting Language';
    });
  }

  public submitAddJobCategory(): void {
    if (this.isEdit) {
      this.edit();
    } else {
      this.add();
    }
  }

  public add(): void {
    this.isUserAdded = true;
    this.service.addNeWLanguage(this.userForm.value).subscribe(user => {
      this.isUserAdded = false;
      this.toastr.success('Language added successfully');
      this.modalRef.close();
      this.userForm.reset();
      this.getJobCategoryList();
    }, error => {
      const err = error.error && error.error.msg;
      this.isUserAdded = false;
      this.toastr.error(err || 'Error in adding Language.Please try again.');
    });
  }

  public edit(): void {
    this.isUserAdded = true;
    this.service.editLanguage(this.userForm.value, this.editId).subscribe(user => {
      this.isUserAdded = false;
      this.toastr.success('Language updated successfully');
      this.modalRef.close();
      this.getJobCategoryList();
    }, error => {
      const err = error.error && error.error.msg;
      this.isUserAdded = false;
      this.toastr.error(err || 'Error in updating Language.Please try again.');
    });
  }

  public editUser(id): void {
    this.isEdit = true;
    this.isUserAdded = true ;
    this.modalRef = this.modalService.open(this.addUser);
    this.service.getLanguageById(id).subscribe(user => {
      if (user && user.data) {
        this.userForm.patchValue(user.data);
       
        this.editId = id;
        this.isUserAdded = false ;
      }
    }, error => {
      this.isUserAdded = false ;
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error getting Language');
    });
  }

  public deleteUserForm(id): void {
    this.deleteId = id;
    this.modalRef = this.modalService.open(this.deleteUser);
  }

  public deleteJobCategory(): void {
    this.service.deleteLanguage(this.deleteId).subscribe(user => {
      if (user) {
        this.close();
        this.getJobCategoryList();
        this.toastr.success('Language deleted !');
      }
    }, error => {
      const err = error.error && error.error.msg;
      this.toastr.error(err || 'Error in deleting Language');
    });
  }

}

