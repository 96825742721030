import {AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox';
import {MatPaginator} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {AnnouncementService} from '../../../../../service/announcement.service';
import { NotificationService } from "src/app/core/services/notification.service";
import {AddCategoryComponent} from '../add-category/add-category.component';
import {saveAs} from 'file-saver-es';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {MatDialog} from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/auth.service';


@Component({
    selector: 'app-category-list',
    templateUrl: './category-list.component.html',
    styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit, AfterViewInit {
    @ViewChild('addCategoryComponent', {static: false}) addCategoryComponent: AddCategoryComponent;
    @ViewChild('deleteUser', {static: false}) deleteUser;
    displayedColumns = ['select', 'name', 'Edit'];
    selectedUsers: any[] = [];
    dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
    selectedCheckBoxList = [];

    @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
    @ViewChild(MatSort, {static: false}) sort: MatSort;
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    paging = {
        pageSize: 10,
        totalRecords: 0,
        pageIndex: 0,
        pageSizeOptions: [2, 25, 50, 100]
    };
    isLoading = false;
    private dialogRef: any;

    constructor(
        private toastr: NotificationService,
        private announcementService: AnnouncementService,
        private dialog: MatDialog,
        private authService : AuthService,
    ) {
    }
    public permissions : any;
    public read = false;
    public write = false ;
    public update = false;
    public delete = false;
    doNothing() : void
    {

        return ;
    }
    checkPermission(module,tabs) : void 
    {
        this.permissions = JSON.parse(this.authService.getPermissions());

        this.read = false ;
        this.write = false ;
        this.delete = false ;
        this.update = false;
        if(this.permissions != undefined && this.permissions != null )
        {
            for(let p of this.permissions)
            {
               
                if(p.id === module)
                {
                   
                    if(p.modules != undefined)
                    {
                      
                        for(let tab of p.modules)
                        {
                            if(tab.id === tabs)
                            {
                              
                                for(let move of tab.permits)
                                {
                                    if(move.id === "u")
                                    {
                                        this.update = true;
                                    }
                                    else if(move.id === "r")
                                    {
                                        this.read = true;
                                    }
                                    else if(move.id === "c")
                                    {
                                      
                                        this.write = true;
                                    }
                                    else if(move.id === "d")
                                    {
                                        this.delete = true;
                                    }
                                }
                            }
                        }
                    }
                   
                }
               
            }
        }
       

    }
    ngOnInit() {
        this.checkPermission("admin","ad_an");
        this.isLoading = true;
        this.getCategories(this.paging.pageIndex + 1, this.paging.pageSize);
    }

    editCategory = (dataObj) => {
       
        this.addCategoryComponent.editCategory(dataObj);
    }

    reloadData($event) {
        this.getCategories(this.paging.pageIndex + 1, this.paging.pageSize);
    }

    pageChange(event) {
        this.paging.pageIndex = event.pageIndex;
        this.paging.pageSize = event.pageSize;
        this.getCategories(event.pageIndex + 1, event.pageSize);
    }

    getCategories(pageIndex, pageSize) {
        const obj = {
            page: pageIndex,
            limit: pageSize
        };
        this.announcementService.getCategories(obj).subscribe(res => {
            console.log(res);
            this.isLoading = false;
            if (res && res.data) {
                this.dataSource = new MatTableDataSource<any>(res.data);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            }
            if (res && res.paging) {
                this.paging.totalRecords = res.paging.count;
            }
            res.msg && this.toastr.error(res.msg);
        }, error => {
            this.isLoading = false;
            this.toastr.error('Error while getting categories');
        });
    }
    close() {
        this.dialogRef.close();
    }

    deleteJobTitle() {
        this.announcementService.deleteDocumentCategoriesByIds(this.selectedCheckBoxList).subscribe(
            resp => {
                if (resp) {
                    this.toastr.success('Document category deleted successfully !');
                    this.dialogRef.close();
                    this.getCategories(this.paging.pageIndex + 1, this.paging.pageSize);
                }
            },
            error => {
                const err = error.error.msg;
                this.close();
                this.getCategories(this.paging.pageIndex + 1, this.paging.pageSize);
                this.toastr.error(err || 'Error deleting Document category');
            }
        );
        this.selectedCheckBoxList = [];
    }

    onCheckBoxChange($event: MatCheckboxChange, id: any) {
        if (!this.alreadySelected($event.source) && $event.checked) {
            this.selectedCheckBoxList.push($event.source.value);
        } else if (!$event.checked) {
            this.selectedCheckBoxList = this.selectedCheckBoxList.filter(
                value => {
                    return value !== $event.source.value;
                }
            );
        }
    }

    alreadySelected(item: MatCheckbox): boolean {
        this.selectedCheckBoxList.forEach(
            value => {
                if (item === value) {
                    return true;
                }
            }
        );
        return false;
    }

    onSelectAllClicked() {
        this.selectedCheckBoxList = [];
        this.matCheckBoxes.forEach(
            item => {
                this.selectedCheckBoxList.push(item.value);
                item.checked = true;
            }
        );
    }

    onDeSelectAllClicked() {
        this.selectedCheckBoxList = [];
        this.matCheckBoxes.forEach(
            item => {
                item.checked = false;
            }
        );
    }

    ngAfterViewInit(): void {
        this.sort.disableClear = true;
    }

    exportToCsv() {
        const csv = this.dataSource.filteredData.map(
            row => {
                return [row.name].join(',') + '\n';
            }
        );
        csv.unshift(['name'].join(',') + '\n');
        const exportDate = new Date();
        const exportFileName = `Csv-export-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}.csv`;
        saveAs(new Blob(csv, {type: 'text/csv;charset=utf-8'}), exportFileName);
    }

    saveAsPdf() {
        if (this.dataSource.filteredData.length <= 0) {
            this.toastr.error('No Data to export');
            return;
        }
        const body = [];
        for (let i = 0; i < this.dataSource.filteredData.length; i++) {
            body.push(
                [
                    this.dataSource.filteredData[i].name
                ]);
        }
        const doc = new jsPDF();
        autoTable(doc, {
            head: [['name']],
            body: body
        });
        const exportDate = new Date();
        const exportFileName = ` -${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}}`;
        doc.save(exportFileName);
    }


    onDeleteSelected() {
        this.dialogRef = this.dialog.open(this.deleteUser, {
            width: '440px',
            height: 'auto'
        });
    }

    onModelChange(value: string) {
        console.log('list' + value);
        this.dataSource.filter = value.trim().toLocaleLowerCase();
    }

}
