import {Component, Input, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from "@angular/material/table";
import { NotificationService } from "src/app/core/services/notification.service";
import { AnnouncementService } from 'src/app/service/announcement.service';

@Component({
    selector: 'app-asset-assign-history',
    templateUrl: './asset-assign-history.component.html',
    styleUrls: ['./asset-assign-history.component.scss']
})
export class AssetAssignHistoryComponent implements OnInit {
    dataSource: MatTableDataSource<any>;
    @Input() assetsId: string 
    public isLoading : boolean = false ;
    displayedColumns = ['select','assignedToId', 'assignedToName', 'assignedFrom', 'assignedTo', 'assignedByName'];
    @ViewChild('deleteUserSelected',{static:false}) deleteUserSelected;
    @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
    public selectedCategories = [];
    public dialogRef: any;
    isSaving = false;
    paging = {
        pageSize: 10,
        totalRecords: 0,
        pageIndex: 0,
        pageSizeOptions: [10, 25, 50, 100]
    }

    @ViewChild(MatSort, {static: false}) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor( private dialog: MatDialog ,private announcementService: AnnouncementService,private toastr: NotificationService) {
    }

    ngOnInit() {

        this.isLoading = true;

       

        
        this.announcementService.getAssetHistory(this.assetsId).subscribe(resp => {
            if (resp && resp.data) {
            this.dataSource = new MatTableDataSource( resp.data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
              this.isLoading = false;
            }
        }, error => {
            this.isLoading = false;
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error in getting Assest History');
        });
    
    }

    pageChange($event) {

    }

    onSelectAllClicked() {
        this.selectedCategories = [];
        this.matCheckBoxes.forEach(
            item => {
                this.selectedCategories.push(item.value);
                item.checked = true;
            }
        );
    }

    onDeSelectAllClicked() {
        this.selectedCategories = [];
        this.matCheckBoxes.forEach(
            item => {
                item.checked = false;
            }
        );
    }
    public close(): void {
        if(this.dialogRef!=null)
            this.dialogRef.close();
    }

    public deleteMultiple(): void {
        this.announcementService.deleteAssetsHistory(this.assetsId,this.selectedCategories).subscribe(user => {
            if (user) {
                this.close();
               this.ngOnInit();
                this.toastr.success(' deleted successfully ..!');
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error in deleting ');
        });
        this.onDeSelectAllClicked();
        this.selectedCategories = [];
    }

    onNewDocSelectChange($event: MatCheckboxChange, id: any) {
        if (!this.alreadySelected($event.source) && $event.checked) {
            this.selectedCategories.push($event.source.value);
        } else if (!$event.checked) {
            this.selectedCategories = this.selectedCategories.filter(
                value => {
                    return value !== $event.source.value;
                }
            );
        }
    }

    alreadySelected(item: MatCheckbox): boolean {
        this.selectedCategories.forEach(
            value => {
                if (item === value) {
                    return true;
                }
            }
        );
        return false;
    }

    onDeleteSelected() {
        // this.dmodalRef = this.modalService.open(this.deleteUser);
        this.dialogRef = this.dialog.open(this.deleteUserSelected, {
            width: '440px',
            height: 'auto'
        });
    }

}
