import * as _ from 'lodash';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'dataExactFilter'
})

export class DataExactFilterPipe implements PipeTransform {

    transform(array: any[], query: string, search: string): any {
        if (query) {
            // array.map(a => {
            //   a[search] = a[search].toLowerCase();
            // });
            return _.filter(array, row => row[search] === query);
        }
        return array;
    }
}
