<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
      <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
          <div class="col-md-8">
          <div class="page-header-title">
              <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted">{{lang.get("costCenters")}}</h4></span>
          </div>
          </div>
          <div class="col-md-4"></div>
      </div>
      </div>
  </div>
  </div>
  
  <div class="page-body no-title">
  <div class="row">
      <div class="col-sm-12">
          <div class="row top-tools">
              <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6 left-tools">
                  <span class="export-icon-btn csv" (click)="exportToCsv()">
                      <i class="material-icons">get_app</i><span>{{lang.get("csv")}}</span>
                  </span>
                  <span class="export-icon-btn pdf" (click)="saveAsPdf()">
                      <i class="material-icons">get_app</i><span>{{lang.get("pdf")}}</span>
                  </span>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
                <div class="relative-position search-filter-con"> 
                  <span class="icon material-icons ng-tns-c114-0"> search </span>
                    <mat-form-field> 
                      <input matInput #inputSearch type="search" [(ngModel)]="filterQuery" maxlength="40"
                            (ngModelChange)="onModelChange(filterQuery)"
                            class="form-control input-sm full-data-search" >
                      </mat-form-field>

                      <span class="mytooltip cus-left tooltip-effect-4" [hidden]="!write">
                          <span class="tooltip-item">
                              <button mat-mini-fab class="btn btn-primary light add-rounded margin-button-05" (click)="addNewUser()">
                                  <i class="fa fa-plus"></i>
                              </button>
                          </span>
                          <span class="tooltip-content clearfix">
                          <span class="tooltip-text">{{lang.get("new")}}</span>
                          </span>
                      </span>
                  </div>
              </div>
          </div>
          <app-card [title]="'Cost Center'">
              <div class="dt-responsive" *ngIf="dataSource">
                <div class="table-responsive">
                    <mat-card-content>
                      <mat-table [dataSource]="dataSource" matSort>
                          <ng-container matColumnDef="select">
                              <mat-header-cell [hidden]="!delete" *matHeaderCellDef>
                                  <a [matMenuTriggerFor]="menu" class="check-all">
                                <span class="material-icons">
                                    more_vert
                                </span>
                                  </a>

                                  <mat-menu #menu="matMenu">
                                      <button (click)="onSelectAllClicked()" mat-menu-item>{{lang.get("select all")}} </button>
                                      <button (click)="onDeSelectAllClicked()" *ngIf="selectedCheckBoxList.length > 0"
                                              mat-menu-item>{{lang.get("deselect all")}} 
                                      </button>
                                      <button (click)="onDeleteSelected()" *ngIf="selectedCheckBoxList.length > 0"
                                              mat-menu-item>{{lang.get("delete selected")}} 
                                      </button>
                                  </mat-menu>
                              </mat-header-cell>
                              <mat-cell [hidden]="!delete" *matCellDef="let row">
                                  <mat-checkbox #matCheckbox (change)="onCheckBoxChange($event, row.id)"
                                                value="{{row.id}}"></mat-checkbox>
                              </mat-cell>
                          </ng-container>

                          <ng-container matColumnDef="costcenterid">
                              <mat-header-cell *matHeaderCellDef mat-sort-header>{{lang.get("costCenterId")}}</mat-header-cell>
                              <mat-cell   *matCellDef="let row">
                                  {{ row.costcenterid  }}
                              </mat-cell>
                          </ng-container>

                          <ng-container matColumnDef="name">
                              <mat-header-cell *matHeaderCellDef mat-sort-header>{{lang.get("name")}}</mat-header-cell>
                          <mat-cell   *matCellDef="let row">
                            {{ row.name }}
                          </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="desc">
                          <mat-header-cell *matHeaderCellDef mat-sort-header>{{lang.get("description")}}</mat-header-cell>
                          <mat-cell   *matCellDef="let row">
                            {{ row.desc}}
                          </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="status">
                          <mat-header-cell *matHeaderCellDef >{{lang.get("status")}}</mat-header-cell>
                          <mat-cell   *matCellDef="let row">
                            {{ row.status }}
                          </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="edit">
                          <mat-header-cell [hidden]="!update" *matHeaderCellDef>{{lang.get("edit")}}</mat-header-cell>
                          <mat-cell [hidden]="!update" *matCellDef="let row">
                              <span class="mytooltip tooltip-effect-4">
                              <span class="tooltip-item"><i class="single-icon-click fa fa-pencil" (click)="editUser(row.id)"></i></span>
                              <span class="tooltip-content clearfix">
                              <span class="tooltip-text">{{lang.get("edit")}}</span>
                              </span>
                              </span>
                          </mat-cell>
                        </ng-container>
          
                        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: columns;">
                        </mat-row>
                      </mat-table>
                      <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
                        <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                    </mat-card-content>
                  </div>
                  <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
                </div>
              </app-card>
      </div>
  </div>
</div>

<ng-template #addUser let-modal>
  <!-- <div class="card my-0"> -->
    <!-- <div class="header"> -->
      <div class="mat-modal-header"> 
      <!-- <h4 class="card-title card-title-modal"> -->
        <h4 class="mat-title-modal"> 
        <span *ngIf="!isEdit">{{lang.get("add")}}</span>
        <span *ngIf="isEdit">{{lang.get("edit")}}</span>
         {{lang.get("costCenter")}} </h4>
    </div>

    <!-- <div class="card-content"> -->
   <div class="mat-modal-body">
    <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>

    <!-- <form class="form-material mt-4" action="javascript:" [formGroup]="userForm" *ngIf="!isUserAdded"> -->
      <form class="form-material full-width-inputs" action="javascript:" [formGroup]="userForm" *ngIf="!isUserAdded">
     <!-- <div class="form-row"> -->
      <div class="row form-row">
        <div class="col-sm-6">
            <mat-form-field class="form-element">
                <mat-label>{{lang.get("costCenterId")}}</mat-label>
                <input [pattern]="'[a-z A-Z 0-9]*'" class="form-control" formControlName="costcenterid" id="title"
                       matInput maxlength="40" required="" type="text">
                <mat-error
                        *ngIf="!userForm.controls.costcenterid.valid && userForm.controls.costcenterid.touched">{{ validationError.required }}</mat-error>
            </mat-form-field>

            <!-- <input type="text" class="form-control" id="title"  formControlName="costcenterid" required="">
            <span class="form-bar"></span>
            <label class="float-label label-after" for="title">Cost Center ID</label>
  
            <span class="validation-error" *ngIf="!userForm.controls.costcenterid.valid && userForm.controls.costcenterid.touched">{{ validationError.required }}</span> -->

        </div>

          <div class="col-sm-6">
              <mat-form-field class="form-element">
                  <mat-label for="notes">{{lang.get("name")}}</mat-label>
                  <input pattern= "*" class="form-control" formControlName="name" id="name" matInput
                         maxlength="40" required="" type="text">
                  <mat-error
                          *ngIf="!userForm.controls.name.valid && userForm.controls.name.touched">{{ validationError.required }}</mat-error>
              </mat-form-field>
              <!-- <input type="text" class="form-control" id="name"  formControlName="name" required="">
              <span class="form-bar"></span>
              <label class="float-label label-after" for="notes">Name</label>
              <span class="validation-error" *ngIf="!userForm.controls.name.valid && userForm.controls.name.touched">{{ validationError.required }}</span> -->
          </div>

         


          <div class="col-sm-12">
              <mat-form-field class="form-element">
                  <mat-label>{{lang.get("status")}}</mat-label>
                  <mat-select disableOptionCentering class="form-control" formControlName="status"  required="">
                      <mat-option [value]="'active'">{{lang.get("active")}}</mat-option>
                      <mat-option [value]="'deactive'">{{lang.get("inactive")}}</mat-option>
                  </mat-select>
                  <mat-error
                          *ngIf="!userForm.controls.status.valid && userForm.controls.status.touched">{{ validationError.required }}</mat-error>
              </mat-form-field>
              <!-- <label class="col-form-label">Status</label>
              <select formControlName="status" class="form-control">
                <option [value]="'active'">ACTIVE</option>
                <option [value]="'deactive'">DE-ACTIVE</option>
                </select>
              <span class="validation-error" *ngIf="!userForm.controls.status.valid && userForm.controls.status.touched">{{ validationError.required }}</span> -->

          </div>

          <div class="col-sm-12">
            <mat-form-field class="form-element">
                <mat-label>{{lang.get("description")}}</mat-label>
              
                       <textarea formControlName="desc" maxlength="50" matInput class="form-control"
                       name="name"></textarea>
                      
            </mat-form-field>


            <!-- <input type="text" class="form-control" id="desc"  formControlName="desc" required="">
            <span class="form-bar"></span>
            <label class="float-label label-after" for="des">Description</label>
           <span class="validation-error" *ngIf="!userForm.controls.desc.valid && userForm.controls.desc.touched">{{ validationError.required }}</span>                    -->
        </div>
      </div>

      <div class="row">
          <div class="action-buttons multi-btns col-sm-12 text-right">
              <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i>{{lang.get("cancel")}}
              </button>
            
              <button mat-raised-button class="btn-primary" color="primary" [disabled]="!userForm.valid" (click)="submitAddJobTitle()"><i class="flaticon-diskette"></i>{{lang.get("save")}}</button>
          </div>
      </div>
  </form>
    </div>
  <!-- </div> -->

</ng-template>


<ng-template #deleteUser let-modal>
  <!-- <div class="card my-0"> -->
    <div mat-dialog-actions>    
    <!-- <div class="header"> -->
      <div class="mat-modal-header"> 
      <!-- <h4 class="card-title card-title-modal">Delete Cost Center</h4> -->
      <h4 class="mat-title-modal">{{lang.get("delete")}} {{lang.get("costCenter")}} </h4>
    </div>
    <!-- <div class="card-content px-2 py-2"> -->
      <div class="mat-modal-body"> 
      <div class="text-left are-u">{{lang.get("areyousure")}}</div>
      <div class="row">
        <!-- <div class="col-sm-12 text-right"> -->
          <div class="multi-btns col-sm-12 text-right">
          <button mat-raised-button class="btn-danger" (click)="close()">
            <i class="flaticon-cancel"></i>{{lang.get("cancel")}}</button>
          <button mat-raised-button  class="btn-primary" color="primary" (click)="deleteJobTitle()">
            <i class="flaticon-trash"></i>{{lang.get("delete")}}</button>
        </div>
      </div>
    </div>
    </div>
  <!-- </div> -->
</ng-template>



