import {Component, NgZone, OnInit,QueryList,ViewChild, ViewChildren} from '@angular/core';
import {DashboardService} from '../../../dashboard.service';
import { NotificationService } from "src/app/core/services/notification.service";
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import autoTable from 'jspdf-autotable';
import {saveAs} from 'file-saver-es';
import jsPDF from 'jspdf';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
    selector: 'app-custome-fields',
    templateUrl: './custome-fields.component.html',
    styleUrls: ['./custome-fields.component.scss']
})

export class CustomeFieldsComponent implements OnInit {
    public filterQuery = '';
    selectedCheckBoxList = [];
    public sectionsList : Array<any> = [];
    public isLoading: boolean = true;
    public dialogRef: any;
    public selectedValue = "";
    public sectionForm: FormGroup;
    isUserAdded : boolean = false ;
    @ViewChild(MatSort, {static: false}) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
    @ViewChild('addSection', {static: false}) addSection;
    @ViewChild('deleteSection', {static: false}) deleteSection;
    dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
    public columns = ['select', 'name', 'screen'];
    public screenList = 
    [
        {
            label : "Personal Detail",
            value : "pe"
        },
        {
            label : "Job",
            value : "jb"
        },
        {
            label : "Salary",
            value : "sa"
        },
        {
            label : "Qualification",
            value : "qu"
        },
        {
            label : "Contact Details",
            value : "cd"
        },
        {
            label : "Social Media Details",
            value : "sm"
        },
        {
            label : "Emergency Contacts",
            value : "ec"
        },
        {
            label : "Dependents",
            value : "dp"
        },
        {
            label : "Immigration",
            value : "im"
        },
        {
            label : "Report To",
            value : "rt"
        },
        {
            label : "Bank Info",
            value : "bi"
        },
        
    ]
    public err: string = "No Data Found";

    constructor(public service: DashboardService,private router: Router,
        private ngZone: NgZone, private authService : AuthService,private fb: FormBuilder, private toastr: NotificationService,private dialog: MatDialog) {
        this.createSectionForm();
    }
    public permissions : any;
    public read = false;
    public write = false ;
    public update = false;
    public delete = false;
   

    checkPermission(module,tabs) : void 
    {
        this.permissions = JSON.parse(this.authService.getPermissions());

        this.read = false ;
        this.write = false ;
        this.delete = false ;
        this.update = false;

        if(this.permissions != undefined && this.permissions != null )
        {
            for(let p of this.permissions)
            {
               
                if(p.id === module)
                {
                   
                    if(p.modules != undefined)
                    {
                      
                        for(let tab of p.modules)
                        {
                            if(tab.id === tabs)
                            {
                              
                                for(let move of tab.permits)
                                {
                                    if(move.id === "u")
                                    {
                                        this.update = true;
                                       
                                    }
                                    else if(move.id === "r")
                                    {
                                        this.read = true;
                                    }
                                    else if(move.id === "c")
                                    {
                                      
                                        this.write = true;
                                    }
                                    else if(move.id === "d")
                                    {
                                        this.delete = true;
                                    }
                                }
                            }
                        }
                    }
                   
                }
               
            }
        }
       

    }
    ngOnInit() {
        this.checkPermission("pdm","pd_cf");
     this.getAllSections();

    }


    public getAllSections()
    {
        this.isLoading = true;
        this.service.getAllSections().subscribe(res => {
            if (res && res.data) {
                this.isLoading = false;
                this.sectionsList = res.data ;
                for(let section of this.sectionsList)
                {

                    if(section.header === "jb")
                    {
                        section.header = "Job";
                    }

                    if(section.header === "pe")
                    {
                        section.header = "Personal Detail";
                    }
                    
                    if(section.header === "sa")
                    {
                        section.header = "Salary";
                    }

                    if(section.header === "qu")
                    {
                        section.header = "Qualification";
                    }

                    if(section.header === "cd")
                    {
                        section.header = "Contact Details";
                    }

                    if(section.header === "sm")
                    {
                        section.header = "Social Media Details";
                    }

                    if(section.header === "ec")
                    {
                        section.header = "Emergency Contacts";
                    }

                    if(section.header === "dp")
                    {
                        section.header = "Dependents";
                    }

                    if(section.header === "im")
                    {
                        section.header = "Immigration";
                    }

                    if(section.header === "rt")
                    {
                        section.header = "Report To";
                    }

                    if(section.header === "bi")
                    {
                        section.header = "Bank Info";
                    }
                }
                this.dataSource = new MatTableDataSource<any>(this.sectionsList);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                this.err = '';
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error while getting Sections');
            this.isLoading = false;
            this.err = 'No Data Found';
        });
    }
    
    public createSectionForm(): void {
        this.selectedValue = "";
        this.sectionForm = this.fb.group({
            header: [''],
            name: ['']
        });
    }

    public deleteSections(): void {
        this.isUserAdded = true ;
        this.service.deleteSections(this.selectedCheckBoxList).subscribe(user => {
            if (user) {
                this.close();
                this.isUserAdded = false ;
                this.getAllSections();
                this.toastr.success('Sections deleted successfully !');
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.close();
            this.isUserAdded = false ;
            this.getAllSections();
            this.toastr.error(err || 'Error in deleting Sections');
        });
        this.selectedCheckBoxList = [];
    }

    public addSections(): void {
        this.isUserAdded = true ;
        this.service.saveSection(this.sectionForm.value).subscribe(resp => {
            if (resp) {
                this.close();
                this.getAllSections();
                this.isUserAdded = false; 
                this.toastr.success('Section Added successfully !');
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.close();
            this.isUserAdded = false; 
            this.toastr.error(err || 'Error in adding Section');
        });
    }

    
    public close(): void {
        this.dialogRef.close();
    }
    public addNewSection() {
        this.sectionForm.reset();
        this.selectedValue = "";
        this.dialogRef = this.dialog.open(this.addSection, {
            width: '560px',
            height: 'auto'
        });
      
    }
    onCheckBoxChange($event: MatCheckboxChange, id: any) {
        if (!this.alreadySelected($event.source) && $event.checked) {
            this.selectedCheckBoxList.push($event.source.value);
        } else if (!$event.checked) {
            this.selectedCheckBoxList = this.selectedCheckBoxList.filter(
                value => {
                    return value !== $event.source.value;
                }
            );
        }
    }

    alreadySelected(item: MatCheckbox): boolean {
        this.selectedCheckBoxList.forEach(
            value => {
                if (item === value) {
                    return true;
                }
            }
        );
        return false;
    }

    onSelectAllClicked() {
        this.selectedCheckBoxList = [];
        this.matCheckBoxes.forEach(
            item => {
                this.selectedCheckBoxList.push(item.value);
                item.checked = true;
            }
        );
    }

    onDeSelectAllClicked() {
        this.selectedCheckBoxList = [];
        this.matCheckBoxes.forEach(
            item => {
                item.checked = false;
            }
        );
    }

    onDeleteSelected() {
        this.dialogRef = this.dialog.open(this.deleteSection, {
            width: '440px',
            height: 'auto'
        });
    }

    onModelChange(value: string) {
        this.dataSource.filter = value.trim().toLocaleLowerCase();
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    exportToCsv() {
        const csv = this.dataSource.filteredData.map(
            row => {
                return [
                    row.name,
                    row.header
                ].join(',') + '\n';
            }
        );
        
        csv.unshift(['name','screen'].join(',') + '\n');
        const exportDate = new Date();
        // tslint:disable-next-line:max-line-length
        const exportFileName = `Csv-export-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}.csv`;
        saveAs(new Blob(csv, {type: 'text/csv;charset=utf-8'}), exportFileName);
    }

    saveAsPdf() {
        if (this.dataSource.filteredData.length <= 0) {
            this.toastr.error('No Data to export');
            return;
        }
        const body = [];
        for (let i = 0; i < this.dataSource.filteredData.length; i++) {
            body.push(
                [
                    this.dataSource.filteredData[i].name,
                    this.dataSource.filteredData[i].header
                ]);
        }
        const doc = new jsPDF();
        autoTable(doc, {
            head: [['name', 'screen']],
            body: body
        });
        const exportDate = new Date();
        const exportFileName = `custom fields-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}}`;
        doc.save(exportFileName);
    }

    updateSection(id) : void
    {
        this.ngZone.run(() => {
            void this.router.navigate(['/pim/configuration/custom-fields/'+id]);
          });
    }
}
