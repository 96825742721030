import {Component, ElementRef, NgZone, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {animate, AUTO_STYLE, state, style, transition, trigger} from '@angular/animations';
import {MenuItems} from '../../shared/menu-items/menu-items';
import {AuthService} from '../../core/services/auth.service';
import {DashboardService} from '../../dashboard/dashboard.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from "src/app/core/services/notification.service";
import {AnnouncementService} from '../../service/announcement.service';
import { MatMenuTrigger } from '@angular/material/menu';
import {MatDialog} from '@angular/material/dialog';
import {UsersService} from "../../dashboard/user.service";
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/common-service.component';
import { Router } from '@angular/router';


@Component({
  selector: "app-control-admin",
  templateUrl: "./control-admin.component.html",
  styleUrls: ["./control-admin.component.scss"],
    animations: [
        trigger('notificationBottom', [
            state('an-off, void',
                style({
                    overflow: 'hidden',
                    height: '0px',
                })
            ),
            state('an-animate',
                style({
                    overflow: 'visible',
                    height: AUTO_STYLE,
                })
            ),
            transition('an-off <=> an-animate', [
                animate('400ms ease-in-out')
            ])
        ]),
        trigger('slideInOut', [
            state('in', style({
                width: '280px',
                // transform: 'translate3d(0, 0, 0)'
            })),
            state('out', style({
                width: '0',
                // transform: 'translate3d(100%, 0, 0)'
            })),
            transition('in => out', animate('400ms ease-in-out')),
            transition('out => in', animate('400ms ease-in-out'))
        ]),
        trigger('mobileHeaderNavRight', [
            state('nav-off, void',
                style({
                    overflow: 'hidden',
                    height: '0px',
                })
            ),
            state('nav-on',
                style({
                    height: AUTO_STYLE,
                })
            ),
            transition('nav-off <=> nav-on', [
                animate('400ms ease-in-out')
            ])
        ]),
        trigger('fadeInOutTranslate', [
            transition(':enter', [
                style({opacity: 0}),
                animate('400ms ease-in-out', style({opacity: 1}))
            ]),
            transition(':leave', [
                style({transform: 'translate(0)'}),
                animate('400ms ease-in-out', style({opacity: 0}))
            ])
        ]),
        trigger('mobileMenuTop', [
            state('no-block, void',
                style({
                    overflow: 'hidden',
                    height: '0px',
                })
            ),
            state('yes-block',
                style({
                    height: AUTO_STYLE,
                })
            ),
            transition('no-block <=> yes-block', [
                animate('400ms ease-in-out')
            ])
        ])
    ]
})
export class ControlAdminComponent implements OnInit, OnDestroy {
    public currentUserRole = 'Admin';
    public dialogRef: any;
    public aboutData: any;
    public username: string;
    public animateSidebar: string;
    public navType: string;
    public themeLayout: string;
    public verticalPlacement: string;
    public verticalLayout: string;
    public pcodedDeviceType: string;
    public verticalNavType: string;
    public verticalEffect: string;
    public vnavigationView: string;
    public freamType: string;
    public sidebarImg: string;
    public sidebarImgType: string;
    public layoutType: string;
    public scrollSidebarFixedHeight: string;
    public headerTheme: string;
    public pcodedHeaderPosition: string;

    public liveNotification: string;
    public liveNotificationClass: string;

    public profileNotification: string;
    public profileNotificationClass: string;

    public chatSlideInOut: string;
    public innerChatSlideInOut: string;

    public searchWidth: number;
    public searchWidthString: string;

    public navRight: string;
    public windowWidth: number;
    public chatTopPosition: string;

    public toggleOn: boolean;
    public toggleIcon: string;
    public navBarTheme: string;
    public activeItemTheme: string;
    public pcodedSidebarPosition: string;

    public headerFixedTop: string;

    public menuTitleTheme: string;
    public dropDownIcon: string;
    public subItemIcon: string;

    public configOpenRightBar: string;
    public displayBoxLayout: string;
    public isVerticalLayoutChecked: boolean;
    public isSidebarChecked: boolean;
    public isHeaderChecked: boolean;
    public headerFixedMargin: string;
    public sidebarFixedHeight: string;
    public sidebarFixedNavHeight: string;
    public itemBorderStyle: string;
    public subItemBorder: boolean;
    public itemBorder: boolean;

    public isCollapsedSideBar: string;
    public psDisabled: string;
    public perfectDisable: string;

    public empImage: string;
    public jobtitle: string;
    public empName: string;
    public eid: any;
    public subMenuState: string;

    @ViewChild('aboutModal', {static: false}) aboutModal;
    @ViewChild('navLogo', {static: false}) navLogo: ElementRef;
    @ViewChild('navContent', {static: false}) navContent: ElementRef;
    @ViewChild('liMain', {static: false}) liMain: ElementRef;

    public config: any;
    public searchInterval: any;
    public desktopScrollHeight: string;

    public modalRef: any;
    public mouseEnter = false;
    public currentSelectedItem: HTMLElement = null;

    public isCollapsed: boolean;
    public isCodeTriggered = true;

    goToUrl(section : string) : void 
    {
        if(section === 'll')
        {
            this.ngZone.run(() => {
                void this.router.navigate(['/leave/leave-list']);
              });
        }
        else if(section === 'lc' )
        {
            this.ngZone.run(() => {
                void this.router.navigate(['/leave/leave-calendar']);
              });
        }
        else if(section === 'al')
        {
            this.ngZone.run(() => {
                void this.router.navigate(['/leave/assign-leave']);
              });
        }
        else if(section === 'apl')
        {
            this.ngZone.run(() => {
                void this.router.navigate(['/leave/apply-leave']);
              });
        }
       

    }

    scroll = (): void => {
        const scrollPosition = window.pageYOffset;
        if (scrollPosition > 56) {
            if (this.isSidebarChecked === true) {
                this.pcodedSidebarPosition = 'fixed';
            }
            if (this.pcodedDeviceType === 'desktop') {
                this.headerFixedTop = '0';
            }
            this.sidebarFixedNavHeight = '100%';
        } else {
            if (this.pcodedDeviceType === 'desktop') {
                this.headerFixedTop = 'auto';
            }
            this.pcodedSidebarPosition = 'absolute';
            this.sidebarFixedNavHeight = '';
        }
    }

    public navBar : Array<any> = [];
    constructor(public menuItems: MenuItems,
                private renderer: Renderer2,
                private readonly service: AuthService,
                private modalService: NgbModal,
                public dashboardService: DashboardService,
                public announcementService: AnnouncementService,
                private toastr: NotificationService,
                private dialog: MatDialog,
                private Service: CommonService,
                private userService: UsersService , 
                private router: Router,
        private ngZone: NgZone) {
                    this.subscriptionName= this.Service.getUpdate().subscribe
                    (message => { //message contains the data sent from service
                    this.empImage = message.text;
                    console.log('test' + message.text);
                    });
        this.animateSidebar = '';
        this.navType = 'st2';
        this.themeLayout = 'vertical';
        this.verticalPlacement = 'left';
        this.verticalLayout = 'wide';
        this.pcodedDeviceType = 'desktop';
        this.verticalNavType = 'expanded';
        this.verticalEffect = 'shrink';
        this.vnavigationView = 'view1';
        this.freamType = 'theme1';
        this.sidebarImg = 'false';
        this.sidebarImgType = 'img1';
        this.layoutType = 'light'; // light(default) dark(dark)

        this.headerTheme = 'theme1'; // theme1(default)
        this.pcodedHeaderPosition = 'fixed';

        this.headerFixedTop = 'auto';

        this.liveNotification = 'an-off';
        this.profileNotification = 'an-off';

        this.chatSlideInOut = 'out';
        this.innerChatSlideInOut = 'out';

        this.searchWidth = 0;

        this.navRight = 'nav-on';

        this.toggleOn = true;
        this.toggleIcon = 'icon-toggle-right';
        this.navBarTheme = 'themelight1'; // themelight1(default) theme1(dark)
        this.activeItemTheme = 'theme1';
        this.pcodedSidebarPosition = 'fixed';
        this.menuTitleTheme = 'theme1'; // theme1(default) theme10(dark)
        this.dropDownIcon = 'style3';
        this.subItemIcon = 'style7';

        this.displayBoxLayout = 'd-none';
        this.isVerticalLayoutChecked = false;
        this.isSidebarChecked = true;
        this.isHeaderChecked = true;
        this.headerFixedMargin = '56px'; // 56px
        this.sidebarFixedHeight = 'calc(100vh - 60px)'; // calc(100vh - 190px)
        this.scrollSidebarFixedHeight = this.verticalNavType == 'expanded' ? 'calc(100vh - 180px)' : 'calc(100vh - 60px)';
        this.itemBorderStyle = 'none';
        this.subItemBorder = true;
        this.itemBorder = true;
        this.isCollapsedSideBar = 'no-block';

        this.perfectDisable = '';

        this.windowWidth = window.innerWidth;

        this.setMenuAttributes(this.windowWidth);
        this.setHeaderAttributes(this.windowWidth);

        this.navBar = this.menuItems.getControlMenu();
        // dark theme
        /*this.setLayoutType('dark');*/

        // light-dark
        /*this.setLayoutType('dark');
        this.setNavBarTheme('themelight1');*/

        // dark-light theme
        /*this.setNavBarTheme('theme1');*/

        // box layout
        /*this.setHeaderPosition();
        this.setSidebarPosition();
        this.setVerticalLayout();*/

        // sidebar img
        /*this.setLayoutType('img');*/

    }
    messageReceived: any;
    private subscriptionName: Subscription;

    ngOnInit() {

        // const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        // this.currentUserRole = currentUser.userRole;
        // console.log(this.currentUserRole);
        // const empId = sessionStorage.getItem('empId');
        // this.setBackgroundPattern('theme1');
        // this.username = this.service.getUserName();
        // this.userService.castUserSaid.subscribe(user => (this.empName = user));
        // // for the profile picture
        
        // this.dashboardService.getEmployeeDetailListById(empId).subscribe(e => {
        //     // this.toDataURL(e.data.image, function (dataUrl) {
        //     //     this.empImage = dataUrl
        //     //         })

        //     let dataUser  = "";
            
        //     sessionStorage.setItem('img', e.data.personalForm.imageFullPath);
        //     console.log('test' + sessionStorage.getItem('img'));
        //     this.empImage = sessionStorage.getItem('img');

        //     if(e.data.personalForm.middlename ! = undefined)
        //     {
        //         dataUser = e.data.personalForm.firstname + ' ' +
        //         e.data.personalForm.middlename + ' ' + e.data.personalForm.lastname;
        //     }

        //     else 
        //     {
        //         dataUser = e.data.personalForm.firstname +' ' + e.data.personalForm.lastname;
        //     }
            

        //     this.userService.editUserSaid(dataUser);
            

        // });

    }

    toDataURL(url, callback) {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
            const reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            };
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    onResize(event) {
        this.windowWidth = event.target.innerWidth;
        this.setHeaderAttributes(this.windowWidth);

        let reSizeFlag = true;
        if (this.pcodedDeviceType === 'tablet' && this.windowWidth >= 768 && this.windowWidth <= 992) {
            reSizeFlag = false;
        } else if (this.pcodedDeviceType === 'phone' && this.windowWidth < 768) {
            reSizeFlag = false;
        }
        /* for check device */
        if (reSizeFlag) {
            this.setMenuAttributes(this.windowWidth);
        }
    }

    setHeaderAttributes(windowWidth) {
        if (windowWidth <= 992) {
            this.navRight = 'nav-off';
        } else {
            this.navRight = 'nav-on';
        }
    }

    setMenuAttributes(windowWidth) {
        if (windowWidth >= 768 && windowWidth <= 992) {
            this.pcodedDeviceType = 'tablet';
            this.verticalNavType = 'offcanvas';
            this.verticalEffect = 'overlay';
            this.toggleIcon = 'icon-toggle-left';
            this.headerFixedTop = 'auto';
            this.headerFixedMargin = '0';
        } else if (windowWidth < 768) {
            this.pcodedDeviceType = 'phone';
            this.verticalNavType = 'offcanvas';
            this.verticalEffect = 'overlay';
            this.toggleIcon = 'icon-toggle-left';
            this.headerFixedTop = 'auto';
            this.headerFixedMargin = '0';
        } else if (!this.isCollapsed) {
            // this.renderer.addClass(this.navLogo.nativeElement, 'margin-r');

            this.pcodedDeviceType = 'desktop';
            this.verticalNavType = 'expanded';
            this.verticalEffect = 'shrink';
            this.toggleIcon = 'icon-toggle-right';
            this.headerFixedMargin = '56px';
            this.desktopScrollHeight = 'desktop-scroll-height';

        } else {
            this.pcodedDeviceType = 'desktop';
            this.verticalNavType = 'collapsed';
            this.verticalEffect = 'shrink';
            this.toggleIcon = 'icon-toggle-left';
            this.headerFixedMargin = '56px';
            this.desktopScrollHeight = 'desktop-scroll-height';
        }

        /*else if (windowWidth >= 1024 && windowWidth < 1366) {
          this.pcodedDeviceType = 'desktop';
          this.verticalNavType = 'collapsed';
          this.verticalEffect = 'shrink';
          this.toggleIcon = 'icon-toggle-left';
          this.perfectDisable = 'disabled';
          this.sidebarFixedHeight = '100%';
        }*/
    }

    toggleHeaderNavRight() {
        this.navRight = this.navRight === 'nav-on' ? 'nav-off' : 'nav-on';
        this.chatTopPosition = this.chatTopPosition === 'nav-on' ? '112px' : '';
        if (this.navRight === 'nav-off' && this.innerChatSlideInOut === 'in') {
            this.toggleInnerChat();
        }
        if (this.navRight === 'nav-off' && this.chatSlideInOut === 'in') {
            this.toggleChat();
        }
    }

    toggleLiveNotification() {
        if (this.profileNotification === 'an-animate') {
            this.toggleProfileNotification();
        }

        this.liveNotification = this.liveNotification === 'an-off' ? 'an-animate' : 'an-off';
        this.liveNotificationClass = this.liveNotification === 'an-animate' ? 'active' : '';

        if (this.liveNotification === 'an-animate' && this.innerChatSlideInOut === 'in') {
            this.toggleInnerChat();
        }
        if (this.liveNotification === 'an-animate' && this.chatSlideInOut === 'in') {
            this.toggleChat();
        }
    }

    toggleProfileNotification() {
        if (this.liveNotification === 'an-animate') {
            this.toggleLiveNotification();
        }

        this.profileNotification = this.profileNotification === 'an-off' ? 'an-animate' : 'an-off';
        this.profileNotificationClass = this.profileNotification === 'an-animate' ? 'active' : '';

        if (this.profileNotification === 'an-animate' && this.innerChatSlideInOut === 'in') {
            this.toggleInnerChat();
        }
        if (this.profileNotification === 'an-animate' && this.chatSlideInOut === 'in') {
            this.toggleChat();
        }
    }

    notificationOutsideClick(ele: string) {
        if (ele === 'live' && this.liveNotification === 'an-animate') {
            this.toggleLiveNotification();
        } else if (ele === 'profile' && this.profileNotification === 'an-animate') {
            this.toggleProfileNotification();
        }
    }

    toggleChat() {
        if (this.innerChatSlideInOut === 'in') {
            this.innerChatSlideInOut = 'out';
        } else {
            this.chatSlideInOut = this.chatSlideInOut === 'out' ? 'in' : 'out';
        }
    }

    toggleInnerChat() {
        this.innerChatSlideInOut = this.innerChatSlideInOut === 'out' ? 'in' : 'out';
    }

    searchOn() {
        document.querySelector('#main-search').classList.add('open');
        this.searchInterval = setInterval(() => {
            if (this.searchWidth >= 200) {
                clearInterval(this.searchInterval);
                return false;
            }
            this.searchWidth = this.searchWidth + 15;
            this.searchWidthString = this.searchWidth + 'px';
        }, 35);
    }

    searchOff() {
        this.searchInterval = setInterval(() => {
            if (this.searchWidth <= 0) {
                document.querySelector('#main-search').classList.remove('open');
                clearInterval(this.searchInterval);
                return false;
            }
            this.searchWidth = this.searchWidth - 15;
            this.searchWidthString = this.searchWidth + 'px';
        }, 35);
    }

    ngOnDestroy() {
        if (this.searchInterval) {
            clearInterval(this.searchInterval);
        }
        this.subscriptionName.unsubscribe();
    }


    toggleOpened(e) {

        if (this.windowWidth > 992) {
            if (!this.navLogo.nativeElement.classList.contains('margin-r')) {
                this.renderer.addClass(this.navLogo.nativeElement, 'margin-r');
                this.isCollapsed = true;
            } else {
                this.renderer.removeClass(this.navLogo.nativeElement, 'margin-r');
                this.isCollapsed = false;
            }
        }
        if (this.windowWidth <= 992) {
            this.toggleOn = this.verticalNavType === 'offcanvas' ? true : this.toggleOn;
            if (this.navRight === 'nav-on') {
                this.toggleHeaderNavRight();
            }
            this.verticalNavType = this.verticalNavType === 'expanded' ? 'offcanvas' : 'expanded';

        } else {
            this.verticalNavType = this.verticalNavType === 'expanded' ? 'collapsed' : 'expanded';
        }
        this.toggleIcon = this.verticalNavType === 'expanded' ? 'icon-toggle-right' : 'icon-toggle-left';
        this.animateSidebar = 'pcoded-toggle-animate';

        if (this.verticalNavType === 'collapsed') {
            this.perfectDisable = 'disabled';
            this.sidebarFixedHeight = '100%';
        } else {
            this.perfectDisable = '';
        }

        if (this.verticalNavType === 'collapsed' && this.isHeaderChecked === false) {
            this.setSidebarPosition();
        }
        if (this.verticalNavType == 'collapsed') {
            this.scrollSidebarFixedHeight = 'calc(100vh - 60px)';
        } else {
            this.scrollSidebarFixedHeight = 'calc(100vh - 180px)';
        }

        if (this.verticalNavType == 'collapsed') {
            this.navContent.nativeElement.querySelectorAll('.pcoded-trigger').forEach(element => {
                this.renderer.removeClass(element, 'pcoded-trigger');
            });
        } else if (this.verticalNavType == 'expanded') {
            this.navContent.nativeElement.querySelectorAll('.active').forEach(element => {
                this.renderer.addClass(element, 'pcoded-trigger');
            });
        }

        setTimeout(() => {
            this.animateSidebar = '';
        }, 500);
    }

    onClickedOutsideSidebar(e: Event) {
        // if ((this.windowWidth <= 992 && this.toggleOn && this.verticalNavType !== 'offcanvas') || this.verticalEffect === 'overlay') {
        //     this.toggleOn = true;
        //     this.verticalNavType = 'offcanvas';
        //     this.toggleIcon = 'icon-toggle-left';
        // }
    }

    toggleRightbar() {
        this.configOpenRightBar = this.configOpenRightBar === 'open' ? '' : 'open';
    }

    setNavBarTheme(theme: string) {
        if (theme === 'themelight1') {
            this.navBarTheme = 'themelight1';
            this.menuTitleTheme = 'theme1';
            this.sidebarImg = 'false';
        } else {
            this.menuTitleTheme = 'theme9';
            this.navBarTheme = 'theme1';
            this.sidebarImg = 'false';
        }
    }

    setLayoutType(type: string) {
        if (type === 'dark') {
            this.headerTheme = 'theme1';
            this.navBarTheme = 'theme1';
            this.activeItemTheme = 'theme1';
            this.freamType = 'theme1';
            document.querySelector('body').classList.add('dark');
            this.setBackgroundPattern('theme1');
            this.menuTitleTheme = 'theme9';
            this.layoutType = type;
            this.sidebarImg = 'false';
        } else if (type === 'light') {
            this.headerTheme = 'theme1';
            this.navBarTheme = 'themelight1';
            this.menuTitleTheme = 'theme1';
            this.activeItemTheme = 'theme1';
            this.freamType = 'theme1';
            document.querySelector('body').classList.remove('dark');
            this.setBackgroundPattern('theme1');
            this.layoutType = type;
            this.sidebarImg = 'false';
        } else if (type === 'img') {
            this.sidebarImg = 'true';
            this.navBarTheme = 'themelight1';
            this.menuTitleTheme = 'theme1';
            this.freamType = 'theme1';
            document.querySelector('body').classList.remove('dark');
            this.setBackgroundPattern('theme1');
            this.activeItemTheme = 'theme1';
        }
    }

    setVerticalLayout() {
        this.isVerticalLayoutChecked = !this.isVerticalLayoutChecked;
        if (this.isVerticalLayoutChecked) {
            this.verticalLayout = 'box';
            this.displayBoxLayout = '';
            this.pcodedHeaderPosition = 'relative';
            this.pcodedSidebarPosition = 'absolute';
            this.headerFixedMargin = '';
        } else {
            this.verticalLayout = 'wide';
            this.displayBoxLayout = 'd-none';
            this.pcodedHeaderPosition = 'fixed';
            this.pcodedSidebarPosition = 'fixed';
            this.headerFixedMargin = '56px';
        }
    }

    setBackgroundPattern(pattern: string) {
        document.querySelector('body').setAttribute('themebg-pattern', pattern);
        // this.menuTitleTheme = this.freamType = this.activeItemTheme = this.headerTheme = pattern;
    }

    setSidebarPosition() {
        if (this.verticalNavType !== 'collapsed') {
            this.isSidebarChecked = !this.isSidebarChecked;
            this.pcodedSidebarPosition = this.isSidebarChecked === true ? 'fixed' : 'absolute';
            this.sidebarFixedHeight = this.isSidebarChecked === true ? 'calc(100vh - 56px)' : '100%';
            if (this.isHeaderChecked === false) {
                window.addEventListener('scroll', this.scroll, true);
                window.scrollTo(0, 0);
            }
        }
    }

    setHeaderPosition() {
        this.isHeaderChecked = !this.isHeaderChecked;
        this.pcodedHeaderPosition = this.isHeaderChecked === true ? 'fixed' : 'relative';
        this.headerFixedMargin = this.isHeaderChecked === true ? '56px' : '';
        if (this.isHeaderChecked === false) {
            window.addEventListener('scroll', this.scroll, true);
            window.scrollTo(0, 0);
        } else {
            window.removeEventListener('scroll', this.scroll, true);
            if (this.pcodedDeviceType === 'desktop') {
                this.headerFixedTop = 'auto';
            }
            this.pcodedSidebarPosition = 'fixed';
            if (this.verticalNavType !== 'collapsed') {
                this.sidebarFixedHeight = this.isSidebarChecked === true ? 'calc(100vh - 56px)' : 'calc(100vh + 56px)';
            }
        }
    }

    toggleOpenedSidebar() {
        this.isCollapsedSideBar = this.isCollapsedSideBar === 'yes-block' ? 'no-block' : 'yes-block';
        if (this.verticalNavType !== 'collapsed') {
            this.sidebarFixedHeight = this.isCollapsedSideBar === 'yes-block' ? 'calc(100vh - 56px)' : 'calc(100vh - 56px)';
            this.scrollSidebarFixedHeight = this.isCollapsedSideBar === 'yes-block' ? 'calc(100vh - 310px)' : 'calc(100vh - 180px)';
        }
    }
 /*
    hoverOutsideSidebar() {
        if (this.verticalNavType === 'collapsed') {
            const mainEle = document.querySelectorAll('.pcoded-trigger');
            for (let i = 0; i < mainEle.length; i++) {
                mainEle[i].classList.remove('pcoded-trigger');
            }
        }
    }

    fireClick(e) {
        if (this.verticalNavType === 'collapsed') {
            const parentEle = e.target.parentNode.parentNode;
            if (parentEle.classList.contains('pcoded-trigger')) {
                const subEle = parentEle.querySelectorAll('.pcoded-hasmenu');
                for (let i = 0; i < subEle.length; i++) {
                    if (subEle[i].classList.contains('pcoded-trigger')) {
                        subEle[i].classList.remove('pcoded-trigger');
                    }
                }
            } else {
                e.target.click();
            }
        }
    }

    fireClickLeave(e) {
        if (this.verticalNavType === 'collapsed') {
            const parentEle = <HTMLElement>e.target.parentNode.parentNode;
            const subEle = parentEle.querySelectorAll('.pcoded-hasmenu');
            for (let i = 0; i < subEle.length; i++) {
                if (subEle[i].classList.contains('pcoded-trigger')) {
                    subEle[i].classList.remove('pcoded-trigger');
                }
            }
        }
    }*/

    public logout(): void {
        this.service.logout();
    }

    public about() {
        this.dashboardService.getAboutData().subscribe(resp => {
            this.aboutData = resp && resp.data;
            this.dialogRef = this.dialog.open(this.aboutModal, {
                width: '440px',
                height: 'auto'
            });
        }, error => {
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error in getting about data');
        });
    }

    closeAbout() {
        this.dialogRef.close();
    }

// pcoded-submenu

    b4ClickCustom(e) {

        if (e.target.parentElement.parentElement.classList.contains('pcoded-submenu')) {
            if (e.target.parentElement.classList.contains('active') && e.target.parentElement.classList.contains('pcoded-trigger')) {
                this.subMenuState = 'expanded';
            } else if (e.target.parentElement.classList.contains('active') && !e.target.parentElement.classList.contains('pcoded-trigger')) {
                this.subMenuState = 'collapsed';
            }
        }
    }

    onLiClickCustom(e) {

        if (this.verticalNavType == 'collapsed' && !e.target.parentElement.parentElement.classList.contains('pcoded-submenu')) {
            e.target.parentElement.classList.remove('pcoded-trigger');
        }
        if (e.target.parentElement.parentElement.classList.contains('pcoded-submenu')) {
            if (e.target.parentElement.classList.contains('active') && e.target.parentElement.classList.contains('pcoded-trigger')) {
                if (this.subMenuState == 'expanded') {
                    e.target.parentElement.classList.remove('pcoded-trigger');
                } else if (this.subMenuState == 'collapsed') {
                    e.target.parentElement.classList.add('pcoded-trigger');
                }
            } else if (e.target.parentElement.classList.contains('active') && !e.target.parentElement.classList.contains('pcoded-trigger')) {
                if (this.subMenuState == 'expanded') {
                    e.target.parentElement.classList.remove('pcoded-trigger');
                } else if (this.subMenuState == 'collapsed') {
                    e.target.parentElement.classList.add('pcoded-trigger');
                }
            }
        }
    }

    onLiClick(e, target: HTMLElement) {
        e.preventDefault();
        if (target !== null) {
            if (this.currentSelectedItem !== null && this.currentSelectedItem.classList.contains('pcoded-trigger')) {
                this.currentSelectedItem.classList.remove('pcoded-trigger');
            }
            this.currentSelectedItem = target;
            this.currentSelectedItem.classList.add('pcoded-trigger');
            console.log('clicked');
            console.log(target);

            if (this.verticalNavType == 'collapsed') {
                // this.renderer.removeClass(this.liMain.nativeElement,'pcoded-trigger');
                // console.log(this.liMain.nativeElement);
                if (target.parentElement.parentElement.classList.contains('pcoded-trigger')) {
                    target.parentElement.parentElement.classList.remove('pcoded-trigger');
                }
            }
        }
    }

    enterMouse(e) {
        /*if (document.getElementById('p-scrollbar').classList.contains('disabled')) {
            this.mouseEnter = true;
            this.toggleOpened(e);
        }*/
    }

    mouseLeave(e) {
        /*if (!document.getElementById('p-scrollbar').classList.contains('disabled') && this.mouseEnter === true) {
            this.mouseEnter = false;
            this.toggleOpened(e);
        }*/
    }


    /*
    Nav State Handlers
     */
    onLinkTypeClicked(linkType: HTMLLIElement) {
        if (this.currentSelectedItem !== null && this.currentSelectedItem.classList.contains('pcoded-trigger')) {
            this.currentSelectedItem.classList.remove('pcoded-trigger');
        }
    }

    onNoStateClicked(noStateChild: HTMLLIElement) {
        if (this.currentSelectedItem !== null && this.currentSelectedItem.classList.contains('pcoded-trigger')) {
            this.currentSelectedItem.classList.remove('pcoded-trigger');
        }

        // console.log('another 2 click');
        if (this.verticalNavType == 'collapsed') {
            noStateChild.parentElement.parentElement.classList.remove('pcoded-trigger');
            // console.log(noStateChild.parentElement.parentElement);
        }
    }

    open(clickMenuTrigger: MatMenuTrigger) {
        console.log('clicked');
        clickMenuTrigger.openMenu();
    }

    doBeforeUnload(){
        // this.logout();
    }
}
