import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox';
import {MatTableDataSource} from '@angular/material/table';
import { NotificationService } from "src/app/core/services/notification.service";
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardService} from '../../dashboard.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import {saveAs} from 'file-saver-es';
import { ViewReportComponent } from './view-report/view-report.component';
import { AuthService } from 'src/app/core/services/auth.service';

export interface PeriodicElement {
    name: string;
    position: number;
    weight: number;
    symbol: string;
}

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
    public filterQuery = "";
    displayedColumns = ['select' , 'reportName', 'edit'];
    dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
    @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('deleteUser', {static: false}) deleteUser;
    selectedReports: MatCheckbox[];
    public dialogRef: any;
    public selectedNewsDocs = [];
    public loadReportTable : boolean;
    public reportList : any = [];

    ngOnDestroy() {
        this.dialog = null ;
        this.dialogRef = null ;
        this.authService = null ;
        this.dashboardService = null ;
        this.toastr = null ;
        this.paginator = null ;
        this.matCheckBoxes = null ;
        this.route = null ;
        this.toast = null ;
        this.selectedReports = null ;
        this.dataSource = null ;
      
      }
    constructor(private toast: NotificationService,
                private router: Router,
                private route: ActivatedRoute,
                private toastr: NotificationService,
                private dialog: MatDialog,
                private dashboardService: DashboardService,
                private authService : AuthService) {
        this.selectedReports = new Array<MatCheckbox>();
        this.loadReportTable = true;
    }
    public permissions : any;
    public read = false;
    public write = false ;
    public update = false;
    public delete = false;
   
    checkPermission(module,tabs) : void 
    {
        this.permissions = JSON.parse(this.authService.getPermissions());

        this.read = false ;
        this.write = false ;
        this.delete = false ;
        this.update = false;
        if(this.permissions != undefined && this.permissions != null )
        {
            for(let p of this.permissions)
            {
               
                if(p.id === module)
                {
                   
                    if(p.modules != undefined)
                    {
                      
                        for(let tab of p.modules)
                        {
                            if(tab.id === tabs)
                            {
                              
                                for(let move of tab.permits)
                                {
                                    if(move.id === "u")
                                    {
                                        this.update = true;
                                    }
                                    else if(move.id === "r")
                                    {
                                        this.read = true;
                                    }
                                    else if(move.id === "c")
                                    {
                                      
                                        this.write = true;
                                    }
                                    else if(move.id === "d")
                                    {
                                        this.delete = true;
                                    }
                                }
                            }
                        }
                    }
                   
                }
               
            }
        }
       

    }
    ngOnInit() {
        this.checkPermission("pdm","pd_rp");
        this.getReportList();
    }

    getReportList() {
        this.loadReportTable = true;
        this.dashboardService.getReportList().subscribe(res => {
            if (res && res.data) {
                this.reportList = res.data;
                this.dataSource = new MatTableDataSource<any>(res.data);
                this.dataSource.paginator = this.paginator;
                this.loadReportTable = false;
            }
        }, error => {
            this.loadReportTable = false;
            this.toast.error('Error in getting Reports');
        });
    }
    alreadySelected(item: MatCheckbox): boolean {
        this.selectedNewsDocs.forEach(
            value => {
                if (item === value) {
                    return true;
                }
            }
        );
        return false;
    }

    onDeleteSelectedClicked() {
        this.dashboardService.deleteMultiReportList(this.selectedNewsDocs).subscribe(res => {
            this.toast.success('Deleted Successfully');
            this.getReportList();
            this.close();
        }, error => {
            this.toast.error('Error in Deleting Report');
        });
        this.selectedNewsDocs = [];
    }

    onReportClicked(element: any) {
        ViewReportComponent.res= null;
        ViewReportComponent.source="";
        this.router.navigate([`${element.id}/${element.reportName}`], {relativeTo: this.route});
    }

    onAddReportClicked() {
        const reportId = 'abc';
        this.router.navigate([`add/${reportId}`], {relativeTo: this.route});
    }

    onNewDocSelectChange($event: MatCheckboxChange, id: any) {
        if (!this.alreadySelected($event.source) && $event.checked) {
            this.selectedNewsDocs.push($event.source.value);
        } else if (!$event.checked) {
            this.selectedNewsDocs = this.selectedNewsDocs.filter(
                value => {
                    return value !== $event.source.value;
                }
            );
        }
    }

    onSelectAllClicked() {
        this.selectedNewsDocs = [];
        this.matCheckBoxes.forEach(
            item => {
                this.selectedNewsDocs.push(item.value);
                item.checked = true;
            }
        );
    }

    onDeSelectAllClicked() {
        this.selectedNewsDocs = [];
        this.matCheckBoxes.forEach(
            item => {
                item.checked = false;
            }
        );
    }

    onEditReportClicked(reportId) {
        this.router.navigate([`/pim/reports/add/${reportId}`]);
    }

    onDeleteSelected() {
        this.dialogRef = this.dialog.open(this.deleteUser, {
            width: '440px',
            height: 'auto'
        });
    }

    close() {
        this.dialogRef.close();
    }

    onModelChange(value: string) {
        this.dataSource.filter = value.trim().toLocaleLowerCase();
    }

    onSearchChange(filterQuery: string) {
        this.dataSource.filter = filterQuery.trim().toLocaleLowerCase();
    }

    exportToCsv() {
        const csv = this.reportList.map(
            row => {
                return row.reportName + '\n';
            }
        );
        csv.unshift('Reports' + '\n');
        const exportDate = new Date();
        // tslint:disable-next-line:max-line-length
        const exportFileName = `Reports-Csv-export-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}.csv`;
        saveAs(new Blob(csv, {type: 'text/csv;charset=utf-8'}), exportFileName);
    }

    saveAsPdf() {
        if (this.dataSource.filteredData.length <= 0) {
            this.toastr.error('No Data to export');
            return;
        }
        const body = [];
        for (let i = 0; i < this.dataSource.filteredData.length; i++) {
            body.push([this.dataSource.filteredData[i].reportName,
            ]);
        }
        const doc = new jsPDF();
        autoTable(doc, {
            head: [['Reports']],
            body: body
        });
        const exportDate = new Date();
        const exportFileName = `Reports-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}}`;
        doc.save(exportFileName);

    }
}
