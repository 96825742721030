import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {DashboardService} from '../../../dashboard.service';
// import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MatDialog} from '@angular/material/dialog';
import {FormGroup, FormBuilder, Validators, AbstractControl} from '@angular/forms';
import { NotificationService } from "src/app/core/services/notification.service";
import * as moment from 'moment';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {saveAs} from 'file-saver-es';
import jsPDF from 'jspdf';
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox';
import autoTable from 'jspdf-autotable';
import {MatPaginator} from '@angular/material/paginator';
import { LanguageConfig } from 'src/app/Language-config';
import { AuthService } from 'src/app/core/services/auth.service';


@Component({
    selector: 'app-work-shift',
    templateUrl: './work-shift.component.html',
    styleUrls: ['./work-shift.component.scss']
})
export class WorkShiftComponent implements OnInit {

    public filterQuery = '';
    public userList: Array<any>;
    selectedUsers: any[] = [];
    public isLoading: boolean;
    public isUserAdded: boolean;
    public err: string;
    // public modalRef: any;
    public dialogRef: any;
    public userForm: FormGroup;
    public isEdit: boolean;
    public editId: string;
    public lang = new Map();
    // for material data table
    selectedCheckBoxList = [];
    dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
    @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
    @ViewChild(MatSort, {static: false}) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    public columns = ['select' , 'name', 'fromstring', 'tostring', 'fullday', 'edit' ];
    public validationError = {
        invalid: 'Invalid',
        required: 'Required',
        email: 'Please provide a valid email',
        password: 'Please provide a password of atleast 8 digits',
        postalcode: 'Should be of 6 digits',
        alphabets: 'Should contain only alphabets'
    };
    @ViewChild('addUser', {static: false}) addUser;
    @ViewChild('deleteUser', {static: false}) deleteUser;


    constructor(private service: DashboardService,
                private dialog: MatDialog,
                private fb: FormBuilder,
                private toastr: NotificationService,
                private authService : AuthService) {
        this.createForm();
        this.lang = LanguageConfig.map;
    }

    public permissions : any;
    public read = false;
    public write = false ;
    public update = false;
    public delete = false;
   
    checkPermission(module,tabs) : void 
    {
        this.permissions = JSON.parse(this.authService.getPermissions());

        this.read = false ;
        this.write = false ;
        this.delete = false ;
        this.update = false;
        if(this.permissions != undefined && this.permissions != null )
        {
            for(let p of this.permissions)
            {
               
                if(p.id === module)
                {
                   
                    if(p.modules != undefined)
                    {
                      
                        for(let tab of p.modules)
                        {
                            if(tab.id === tabs)
                            {
                              
                                for(let move of tab.permits)
                                {
                                    if(move.id === "u")
                                    {
                                        this.update = true;
                                    }
                                    else if(move.id === "r")
                                    {
                                        this.read = true;
                                    }
                                    else if(move.id === "c")
                                    {
                                      
                                        this.write = true;
                                    }
                                    else if(move.id === "d")
                                    {
                                        this.delete = true;
                                    }
                                }
                            }
                        }
                    }
                   
                }
            
            }
        }
       

    }

    ngOnInit() {
        this.isLoading = true;
        this.isEdit = false;
        this.checkPermission("admin","ad_jb");
        this.getJobTitleList();
        this.isUserAdded = false;
    }

    public createForm(): void {
        this.userForm = this.fb.group({
                name: ['', Validators.required],
                from: ['', Validators.required],
                to: ['', Validators.required],
                hourPerDay: ['']
            }, {validator: checkIfEndDateAfterStartDate}
        );
    }

    public addNewUser() {
        this.isEdit = false;
        // this.modalRef = this.modalService.open(this.addUser);
        this.dialogRef = this.dialog.open(this.addUser, {
            width: '560px',
            height: 'auto'
        });
        this.userForm.reset();
    }

    public close(): void {
        this.dialogRef.close();
    }

    public getJobTitleList(): void {
        this.service.getWorkShiftList().subscribe(user => {
            if (user && user.data) {
                this.isLoading = false;
                this.userList = user.data;
                this.userList.map(u => {
                    u.fromstring = (u.from.hour < 10 ? ('0' + u.from.hour).slice(-2) :
                        u.from.hour) + ':' + (u.from.minute < 10 ? ('0' + u.from.minute).slice(-2) : u.from.minute);
                    u.tostring = (u.to.hour < 10 ? ('0' + u.to.hour).slice(-2) :
                        u.to.hour) + ':' + (u.to.minute < 10 ? ('0' + u.to.minute).slice(-2) : u.to.minute);
                    u.fullday = (u.hourPerDay.hour < 10 ? ('0' + u.hourPerDay.hour).slice(-2) :
                        u.hourPerDay.hour) + ':' + (u.hourPerDay.minute < 10 ? ('0' + u.hourPerDay.minute).slice(-2) : u.hourPerDay.minute);
                });
                this.err = '';
                this.dataSource = new MatTableDataSource<any>(this.userList);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.isLoading = false;
            this.toastr.error(err || 'Error while getting Work Shift');
            this.err = err || 'Error while getting Work Shift';
        });
    }

    public submitAddJobTitle(): void {
        if (this.isEdit) {
            this.edit();
        } else {
            this.add();
        }
    }

    public add(): void {
        if (this.userForm.invalid) {
            return;
        }
        this.isUserAdded = true;
        this.service.addWorkShift(this.prepareFormBody(this.userForm.value)).subscribe(user => {
            this.isUserAdded = false;
            this.toastr.success('Work Shift added successfully');
            this.dialogRef.close();
            this.userForm.reset();
            this.getJobTitleList();
        }, error => {
            const err = error.error && error.error.msg;
            this.isUserAdded = false;
            this.toastr.error(err || 'Error in adding Work Shift.Please try again.');
        });
    }

    public edit(): void {
        this.isUserAdded = true;
        this.service.editWorkShift(this.prepareFormBody(this.userForm.value), this.editId).subscribe(user => {
            this.isUserAdded = false;
            this.toastr.success('Work shift updated added successfully');
            this.dialogRef.close();
            this.getJobTitleList();
        }, error => {
            const err = error.error && error.error.msg;
            this.isUserAdded = false;
            this.toastr.error(err || 'Error in updating Work Shift.Please try again.');
        });
    }

    public editUser(id): void {
        this.isEdit = true;
        this.isUserAdded = true ;
        this.dialogRef = this.dialog.open(this.addUser, {
            width: '560px',
            height: 'auto'
        });
        this.service.getWorkShiftById(id).subscribe(user => {
            if (user && user.data) {
                const data = user.data;
                const from = moment(`${user.data.from.hour}:${user.data.from.minute}`, ['hh:mm A']).format('HH:mm');
                const to = moment(`${user.data.to.hour}:${user.data.to.minute}`, ['hh:mm A']).format('HH:mm');

                const obj = {
                    name: data.name,
                    from,
                    to
                };
                this.userForm.patchValue(obj);
                this.isUserAdded = false ;
                this.editId = id;
            }
        }, error => {
            this.isUserAdded = false ;
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error getting Work Shift');
        });
    }
    public deleteJobTitle(): void {
        this.service.deleteWorkShift(this.selectedCheckBoxList).subscribe(user => {
            if (user) {
                this.close();
                this.getJobTitleList();
                this.toastr.success('Work Shift deleted!');
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.close();
            this.getJobTitleList();
            this.toastr.error(err || 'Error deleting Work Shift');
        });
        this.selectedCheckBoxList = [];
    }

    public prepareFormBody(val): void {
        const from = moment(val.from, ['h:mm A']).format('HH:mm');
        const to = moment(val.to, ['h:mm A']).format('HH:mm');
        const objFrom = {
            hour: parseInt(from.split(':')[0], 10),
            minute: parseInt(from.split(':')[1], 10)
        };
        const objTo = {
            hour: parseInt(to.split(':')[0], 10),
            minute: parseInt(to.split(':')[1], 10)
        };
        const objHrsPerDay = this.getTotalHrs(from, to);
        val.from = objFrom;
        val.to = objTo;
        val.hourPerDay = objHrsPerDay;

        return val;
    }

    public getTotalHrs(from, to): any {
        if (from && to) {
            const startTime = moment(from, 'hh:mm');
            const endTime = moment(to, 'hh:mm');
            const min = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))).format("mm")
            return {
                hour: parseInt(('0' + endTime.diff(startTime, 'hours')).slice(-2), 10),
                minute: min 
            };
        }
    }


    onCheckBoxChange($event: MatCheckboxChange, id: any) {
        if (!this.alreadySelected($event.source) && $event.checked) {
            this.selectedCheckBoxList.push($event.source.value);
        } else if (!$event.checked) {
            this.selectedCheckBoxList = this.selectedCheckBoxList.filter(
                value => {
                    return value !== $event.source.value;
                }
            );
        }
    }

    alreadySelected(item: MatCheckbox): boolean {
        this.selectedCheckBoxList.forEach(
            value => {
                if (item === value) {
                    return true;
                }
            }
        );
        return false;
    }

    onSelectAllClicked() {
        this.selectedCheckBoxList = [];
        this.matCheckBoxes.forEach(
            item => {
                this.selectedCheckBoxList.push(item.value);
                item.checked = true;
            }
        );
    }

    onDeSelectAllClicked() {
        this.selectedCheckBoxList = [];
        this.matCheckBoxes.forEach(
            item => {
                item.checked = false;
            }
        );
    }

    onDeleteSelected() {
        this.dialogRef = this.dialog.open(this.deleteUser, {
            width: '440px',
            height: 'auto'
        });
    }
    onModelChange(value: string) {
        this.dataSource.filter = value.trim().toLocaleLowerCase();
    }
    exportToCsv() {
        const csv = this.dataSource.filteredData.map(
            row => {
                return [
                    row.name,
                    row.fromstring,
                    row.tostring,
                    row.fullday,
                ].join(',') + '\n';
            }
        );
        
        csv.unshift([ 'Work Shift', 'From', 'To', 'Hours Per Day' ].join(',') + '\n');
        const exportDate = new Date();
        const exportFileName = `Csv-export-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}.csv`;
        saveAs(new Blob(csv, {type: 'text/csv;charset=utf-8'}), exportFileName);
    }

    saveAsPdf() {
        if (this.dataSource.filteredData.length <= 0) {
            this.toastr.error('No Data to export');
            return;
        }
        const body = [];
        for (let i = 0; i < this.dataSource.filteredData.length; i++) {
            body.push(
                [
                    this.dataSource.filteredData[i].name,
                    this.dataSource.filteredData[i].fromstring,
                    this.dataSource.filteredData[i].tostring,
                    this.dataSource.filteredData[i].fullday,
                ]);
        }
        const doc = new jsPDF();
        autoTable(doc, {
            head: [[ 'Work Shift', 'From', 'To', 'Hours Per Day' ]],
            body: body
        });
        const exportDate = new Date();
        const exportFileName = `job titles-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}}`;
        doc.save(exportFileName);
    }
}


export function checkIfEndDateAfterStartDate(c: AbstractControl) {

    if (!c.get('from').value || !c.get('to').value) {
        return { invalidDate: true };
    }
    const end: string[] = c.get('to').value.split(':');
    const start: string[] = c.get('from').value.split(':');

    // tslint:disable-next-line:radix
    if (parseInt(start[0]) > parseInt(end[0])) {
        c.get('to').setErrors({ invalidDate: true });
        c.get('from').setErrors({ invalidDate: true });
        return { invalidDate: true };
    }

    // tslint:disable-next-line:radix
    if (parseInt(start[0]) === parseInt(end[0]) && parseInt(start[1]) >= parseInt(end[1])) {
        c.get('to').setErrors({ invalidDate: true });
        c.get('from').setErrors({ invalidDate: true });
        return { invalidDate: true };
    }

    c.get('to').setErrors(null);
    c.get('from').setErrors(null);
    return null;
}
