import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import {AnnouncementService} from "../../../../service/announcement.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { NotificationService } from "src/app/core/services/notification.service";
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox';
import {MatDialog} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {saveAs} from 'file-saver-es';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { AuthService } from 'src/app/core/services/auth.service';
@Component({
    selector: 'app-vendors',
    templateUrl: './vendors.component.html',
    styleUrls: ['./vendors.component.scss']
})
export class VendorsComponent implements OnInit {
    @ViewChild('categoryForm',{static:false}) categoryForm;
    @ViewChild('deleteUser',{static:false}) deleteUser;
    @ViewChild('deleteUserSelected',{static:false}) deleteUserSelected;
    @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
    @ViewChild('FilterForm',{static:false}) FilterForm;

    public modalRef: any;
    public dialogRef: any;
    public newForm: FormGroup;
    public deleteId: string;

    public filterForm : FormGroup ;
    public selectedVendors = [];

    public isEdit : boolean = false ;
    public filterQuery = '';
    @ViewChild(MatSort, {static: false}) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource: MatTableDataSource<any> = new MatTableDataSource([]); 
    displayedColumns = ['select','vendorId', 'name', 'contactNumber', 'email'];
    isLoading = false;
    isSaving = false;
    paging = {
        pageSize: 10,
        totalRecords: 0,
        pageIndex: 0,
        pageSizeOptions: [10, 25, 50, 100]
    }

    constructor(
        private announcementService: AnnouncementService,
        private fb: FormBuilder,
        private toastr: NotificationService,
        private dialog: MatDialog,
        private authService : AuthService 
    ) {
        this.newForm = this.fb.group({
            name: '',
            email: ['', [Validators.email]],
            contactNumber : '',
            webSite : '',
            address: '',
            vendorId : '',
            id : ''
        });


        this.filterForm = this.fb.group({
            name: '',
            id : ''
        });
    }
    public permissions : any;
    public read = false;
    public write = false ;
    public update = false;
    public delete = false;
   
    checkPermission(module,tabs) : void 
    {
        this.permissions = JSON.parse(this.authService.getPermissions());

        this.read = false ;
        this.write = false ;
        this.delete = false ;
        this.update = false;
        if(this.permissions != undefined && this.permissions != null )
        {
            for(let p of this.permissions)
            {
               
                if(p.id === module)
                {
                   
                    if(p.modules != undefined)
                    {
                      
                        for(let tab of p.modules)
                        {
                            if(tab.id === tabs)
                            {
                              
                                for(let move of tab.permits)
                                {
                                    if(move.id === "u")
                                    {
                                        this.update = true;
                                    }
                                    else if(move.id === "r")
                                    {
                                        this.read = true;
                                    }
                                    else if(move.id === "c")
                                    {
                                      
                                        this.write = true;
                                    }
                                    else if(move.id === "d")
                                    {
                                        this.delete = true;
                                    }
                                }
                            }
                        }
                    }
                   
                }
               
            }
        }
       

    }
    ngOnInit() {
        this.dataSource = new MatTableDataSource([]);
        this.checkPermission("assets","assets_ven");
        this.getVendors()
    }

    getVendors() {
        this.isLoading = true;
        this.announcementService.getAssetsVendors().subscribe(res => {
            this.isLoading = false;
            if (res && res.data) {
                let list = res.data;
              
                this.dataSource = new MatTableDataSource(list);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            }
            if (res && res.paging) {
                this.paging.totalRecords = res.paging.count;
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.isLoading = false;
            this.toastr.error(err || "Error while getting vendors.") ;
        });
    }


    openFilterForm() : void 
    {
        this.dialogRef=this.dialog.open(this.FilterForm,{
            width:'580px',
            height:'auto'
        });
    }
    onResetForm() : void 
    {
        this.filterForm.reset();
        this.getVendors();
    }

    filterVendor () : void 
    {
        this.closeModel();
        this.isLoading = true;
        this.announcementService.filterAssetsVendors(this.filterForm.value).subscribe(res => {
            this.isLoading = false;
            if (res && res.data) {
                let list = res.data;
              
                this.dataSource = new MatTableDataSource(list);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            }
            if (res && res.paging) {
                this.paging.totalRecords = res.paging.count;
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.isLoading = false;
            this.toastr.error(err || "Error while getting vendors.") ;
        });
    }
    public hide : boolean = false ;
    itemClick(item) {
        this.isEdit = true; 
        this.hide = false ;
        if(!this.update)
        {
            this.hide = true ;
        }
        else 
        {
            this.hide = false;
        }
        this.newForm.reset();
        this.isSaving = true;
        this.announcementService.getAssetsVendorsById(item.id).subscribe(res => {
            this.isLoading = false;
            if (res && res.data) {
                this.newForm.patchValue(res.data);
                this.isSaving = false;
            }
          
        }, error => {
            const err = error.error && error.error.msg;
            this.toastr.error(err || "Error while getting vendor.") ;
            this.isLoading = false;
            this.isSaving = false;
        });
       
        this.dialogRef=this.dialog.open(this.categoryForm,{
            width:'580px',
            height:'auto'
        });
    }

    pageChange(event) {
        this.paging.pageIndex = event.pageIndex + 1;
        this.paging.pageSize = event.pageSize;
        this.getVendors() ;

    }

    openModel() {
        this.isEdit = false ;
        this.newForm.reset();
        this.hide = false ;
        // this.modalRef = this.modalService.open(this.categoryForm);
        this.dialogRef=this.dialog.open(this.categoryForm,{
            width:'580px',
            height:'auto'
        });
        this.isSaving = true ;
        this.announcementService.generateIDForAllAssets('vendors').subscribe(user => {
            if (user && user.data) {
             this.newForm.patchValue({
                vendorId : user.data 
             });
             this.isSaving = false ;
             
            }
        }, error => {
            this.isSaving = false ;
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error in generating Id');
        });
    }

    closeModel() {
    
        this.dialogRef && this.dialogRef.close();
    }

    save() {
        this.newForm.markAsTouched();
        if (this.newForm.invalid) {
            return;
        }
        this.isSaving = true;
        (this.isEdit ?
                this.announcementService.updateAssetsVendor(this.newForm.value) :
                this.announcementService.saveAssetsVendor(this.newForm.value)
        ).subscribe(res => {
            if (res && res.data) {
                if (this.isEdit) {
                    this.toastr.success("Vendor updated successfully ")
                   
                } else {
                    this.toastr.success("Vendor saved successfully")
                   
                }
                this.getVendors();
                this.closeModel()
            }
            this.isSaving = false;
        }, error => {
            const err = error.error && error.error.msg;
            this.isSaving = false;
         
            (this.isEdit ? this.toastr.error(err || "Error while editing vendor.") :
            this.toastr.error(err || "Error while saving vendor.") );
        });
    }
    deleteVendor(item) {
        this.deleteId = item.id;
        this.dialogRef = this.dialog.open(this.deleteUser,{
            width:'440px',
            height:'auto'
        });
    }
    deleteVendors(){
        const ids = [this.deleteId];
        console.log(ids);
        this.announcementService.deleteVendors(ids).subscribe(
            user => {
                if(user) {
                    this.close();
                    this.getVendors();
                    this.toastr.success('Vendor deleted ');
                }
            }, error => {
                const err = error.error && error.error.msg;
                this.toastr.error(err || 'Error deleting Vendor');
                this.getVendors();
                this.close();
            }
        );
    }

    public close(): void {
        if(this.dialogRef!=null)
            this.dialogRef.close();
        if(this.modalRef!=null)
            this.modalRef.close();
    }

    onSelectAllClicked() {
        this.selectedVendors = [];
        this.matCheckBoxes.forEach(
            item => {
                this.selectedVendors.push(item.value);
                item.checked = true;
            }
        );
    }

    onDeSelectAllClicked() {
        this.selectedVendors = [];
        this.matCheckBoxes.forEach(
            item => {
                item.checked = false;
            }
        );
    }

    public deleteMultiple(): void {
        this.announcementService.deleteVendors(this.selectedVendors).subscribe(user => {
            if (user) {
                this.close();
                
                this.toastr.success('Vendors deleted successfully');
                this.getVendors();

            }
        }, error => {
            const err = error.error && error.error.msg;
            this.onDeSelectAllClicked();
            this.close();
            this.toastr.error(err || 'Error in deleting Vendors ');
        });
       
        this.selectedVendors = [];
    }

    onNewDocSelectChange($event: MatCheckboxChange, id: any) {
        if (!this.alreadySelected($event.source) && $event.checked) {
            this.selectedVendors.push($event.source.value);
        } else if (!$event.checked) {
            this.selectedVendors = this.selectedVendors.filter(
                value => {
                    return value !== $event.source.value;
                }
            );
        }
    }

    alreadySelected(item: MatCheckbox): boolean {
        this.selectedVendors.forEach(
            value => {
                if (item === value) {
                    return true;
                }
            }
        );
        return false;
    }

    onDeleteSelected() {
        // this.dmodalRef = this.modalService.open(this.deleteUser);
        this.dialogRef = this.dialog.open(this.deleteUserSelected, {
            width: '440px',
            height: 'auto'
        });
    }

    
    exportToCsv() {
        // const csv = this.userList.map(
            const csv = this.dataSource.filteredData.map(
                row => {
                    return [row.vendorId , row.name,row.contactNumber,row.email].join(',') + '\n';
                }
            );
            csv.unshift(['Id','Vendor','Contact No','Email'].join(',') + '\n');
            const exportDate = new Date();
            // tslint:disable-next-line:max-line-length
            const exportFileName = `Csv-export-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}.csv`;
            saveAs(new Blob(csv, {type: 'text/csv;charset=utf-8'}), exportFileName);
    }

    saveAsPdf() {
        if (this.dataSource.filteredData.length <= 0) {
            this.toastr.error('No Data to export');
            return;
        }
        const body = [];
        for (let i = 0; i < this.dataSource.filteredData.length; i++) {
            body.push(
                [
                    this.dataSource.filteredData[i].vendorId,
                    this.dataSource.filteredData[i].name,
                    this.dataSource.filteredData[i].contactNumber,
                    this.dataSource.filteredData[i].email
                ]);
        }
        const doc = new jsPDF();
        autoTable(doc, {
            head: [['Id','Vendor','Contact No','Email']],
            body: body
        });
        const exportDate = new Date();
        const exportFileName = `Vendors-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}}`;
        doc.save(exportFileName);

    }

    onModelChange(value: string) {
        this.dataSource.filter = value.trim().toLocaleLowerCase();
    }
}
