import {AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { NotificationService } from "src/app/core/services/notification.service";
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import * as moment from 'moment';
import {FormControl} from '@angular/forms';
import {MatSelect} from '@angular/material/select';
import {ReplaySubject, Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {DashboardService} from '../../../dashboard.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogHrmComponent} from 'src/app/dialog-hrm/dialog-hrm.component';
import {ActivatedRoute, Router} from '@angular/router';
import {TrainerDialogComponent} from 'src/app/trainer-dialog/trainer-dialog.component';
import {MatCheckbox, MatCheckboxChange} from "@angular/material/checkbox";

@Component({
    selector: 'app-course-session',
    templateUrl: './course-session.component.html',
    styleUrls: ['./course-session.component.scss']
})
export class CourseSessionComponent implements OnInit {


    public course: string;
    public sessionForm: FormGroup;
    public isLoading: boolean = true;
    public filteredOptions: Array<any>;
    public correct = true;
    public employeeList = [];
    public temporaryDepartment: Array<any>;
    public currencyList: Array<any> = [];
    public err: string;
    id: any;

    public filterQuery1 = '';

    public dataSource: MatTableDataSource<any> = new MatTableDataSource([]);

    public dialogRef: any;
    public isTrainerAdded = false;

    public selectedNewsDocs2 = [];
    public isSessionAdded: boolean;


    @ViewChild('TableOnePaginator1', {static: false}) TableOnePaginator1: MatPaginator;

    @ViewChild('TableOneSort', {static: false}) tableOneSort: MatSort;


    @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
    @ViewChildren(MatCheckbox) matCheckBoxes1: QueryList<MatCheckbox>;
    @ViewChildren(MatCheckbox) matCheckBoxes2: QueryList<MatCheckbox>;
    @ViewChildren(MatCheckbox) matCheckBoxes3: QueryList<MatCheckbox>;

    @ViewChild('addSession', {static: false}) addSession;
    @ViewChild('deleteSession', {static: false}) deleteSession;
    @ViewChild('useCourseData', {static: false}) useCourseData: HTMLInputElement;


    public columns2 = ['select', 'sessionName', 'startDate', 'status', 'part' , 'attachments'];



    constructor(private service: DashboardService, private routeA: ActivatedRoute, private route: Router, public dialog: MatDialog, private modalService: NgbModal, private fb: FormBuilder, private toastr: NotificationService) {
        this.createForm();

        this.service.getEmployeeDetailList().subscribe(resp => {
            if (resp && resp.data) {
                resp.data.map(e => {
                    this.employeeList.push({label: e.id + ' ' + e.name, value: e.id});
                });
            }
        });

        this.routeA.params.subscribe(params => {
            this.id = params['id'];
            console.log("id", this.id);
        });
    }

    public locationList: Array<any>;







    public deleteMultiple2(): void {
        this.service.deleteMultipleSession(this.selectedNewsDocs2).subscribe(user => {
            if (user) {
                this.close1();
                this.getCourseById();
                this.toastr.success('Session deleted !');
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error deleting Session');
        });
        this.selectedNewsDocs2 = [];
    }



    public createForm(): void {

        this.sessionForm = this.fb.group({
            sessionName: ['', Validators.required],
            courseId: [this.id],
            startDate: ['', Validators.required],
            endDate: [''],
            deliveryLocation: [''],
            deliveryMethod: [''],
            status: [''],
            description: [''],
            useCourseData: [false]
        });




    }

    getCourseById(): void {
        this.isLoading = true;
        if (this.id) {
            this.service.getSessionListByCourseId(this.id).subscribe(res => {
                if (res && res.data) {
                    this.dataSource = new MatTableDataSource<any>(res.data);
                    setTimeout(() => this.dataSource.paginator = this.TableOnePaginator1);
                    this.isLoading = false;
                }
            }, error => {
                const _err = error.error && error.error.msg;
                this.isLoading = false;

                this.err = 'Error while getting Session List';
                this.toastr.error(_err || this.err);
            });
        } else {
            this.toastr.error('Error while getting Session List');
        }

    }

    ngOnInit() {
        this.getCourseById();

    }







    close() {
        this.route.navigate(['/training/c/courses']);
    }





    onModelChange1(value: string) {
        this.dataSource.filter = value.trim().toLocaleLowerCase();
    }
















    public close1(): void {
        this.dialogRef.close();
        this.sessionForm.reset();
    }





    viewAction1(id) {
        this.route.navigate(['/training/sessions/viewTrainer', id]);
    }

    viewAction(id) {
        this.route.navigate(['/training/sessions', id]);
    }

    onSelectAllClicked2() {
        this.selectedNewsDocs2 = [];
        this.matCheckBoxes2.forEach(
            item => {
                this.selectedNewsDocs2.push(item.value);
                item.checked = true;
            }
        );
    }

    onDeSelectAllClicked2() {
        this.selectedNewsDocs2 = [];
        this.matCheckBoxes2.forEach(
            item => {
                item.checked = false;
            }
        );
    }

    onNewDocSelectChange2($event: MatCheckboxChange, id: any) {
        if (!this.alreadySelected2($event.source) && $event.checked) {
            this.selectedNewsDocs2.push($event.source.value);
        } else if (!$event.checked) {
            this.selectedNewsDocs2 = this.selectedNewsDocs2.filter(
                value => {
                    return value !== $event.source.value;
                }
            );
        }
    }

    alreadySelected2(item: MatCheckbox): boolean {
        this.selectedNewsDocs2.forEach(
            value => {
                if (item === value) {
                    return true;
                }
            }
        );
        return false;
    }

    onDeleteSelected2() {
        this.dialogRef = this.dialog.open(this.deleteSession, {
            width: '400px',
            height: 'auto'
        });
    }

    addNewSession() {
        this.sessionForm.reset();
        this.dialogRef = this.dialog.open(this.addSession, {
            width: '720px',
            height: 'auto'
        });
    }



    isEdit = false;
    resourceId = null;



    submitAddUser2() {
        console.log("data : ", this.sessionForm.value);
        this.isSessionAdded = true;
        this.sessionForm.patchValue({'courseId': this.id});
        this.sessionForm.patchValue({useCourseData: this.useCourseData.checked});
        this.service.addSession(this.sessionForm.value).subscribe(res => {
            if (res && res.data) {
                this.isSessionAdded = false;
                this.err = '';
                this.close1();
                this.sessionForm.reset();
                this.toastr.success('Session added successfully');
                this.getCourseById();
            }
        }, error => {
            const _err = error.error && error.error.msg;
            this.isLoading = false;
            this.isSessionAdded = false;
            this.close1();
            this.err = 'Error while adding Session List details';
            this.toastr.error(_err || this.err);
        });

    }






    @ViewChild('attach', {static: false}) attach;
    @ViewChild('attach1', {static: false}) attach1;

    public attacherId: string;
    public attachmentList = [];
    public fileType: string;
    public fileObj: any;
    public comment: string;
    showSpinner = false;
    public uploadedFile: any;
    public fileError : any;

    onAddAttachment(id , withA) {
        this.attacherId = id;
        this.showSpinner = true;

        this.service.getAttachmentList(this.attacherId).subscribe(
            r => {
                if (r && r.data) {
                    this.attachmentList = r.data;
                    if(withA){
                        this.dialogRef = this.dialog.open(this.attach1, {
                            width: '560px',
                            height: 'auto'
                        });
                    }else {
                        this.dialogRef = this.dialog.open(this.attach, {
                            width: '560px',
                            height: 'auto'
                        });
                    }
                }
                this.showSpinner = false;
            },
            error => {
                this.showSpinner = false;
            }
        );
        this.comment = '';
        this.fileType = '';
        this.fileObj = null;

    }

    onDownloadClicked(attachment: any) {
        const URL = attachment['url'];
        const FILE_NAME = attachment['fileName'];
        window.open(URL);
    }

    fileChange($event, type): void {
        this.uploadedFile = undefined;
        const reader = new FileReader();
        const file = $event.target.files[0];
        this.uploadedFile = file;
        const fileName = file && file.name;
        if (file && (file.size / 1024) < 2048) {
            this.fileObj = {
                added: new Date(),
                addedBy: 'string',
                desc: this.comment,
                docType: 'dd',
                empId: this.attacherId,
                fileName: fileName,
                size: file.size,
                url: ''
            };
        } else {
            this.fileError = 'Max size 2 MB';
            this.toastr.error('Max 2Mb File allowed.');
        }
    }

    onDeleteClicked(attachment: any) {
        const ID = attachment['id'];
        this.service.deleteAttachment(ID).subscribe(
            res => {
                this.service.getAttachmentList(this.attacherId).subscribe(
                    r => {
                        this.attachmentList = r.data;
                        this.toastr.success('Deleted');
                        this.service.getSessionListByCourseId(this.id).subscribe(res => {
                            if (res && res.data) {
                                this.dataSource = new MatTableDataSource<any>(res.data);
                                setTimeout(() => this.dataSource.paginator = this.TableOnePaginator1);
                            }
                        });
                    },
                    error => {
                        console.log(error);
                        this.toastr.error('Failed to delete');
                    }
                );
            }
        );

    }

    public isClicked = false;

    saveAttachment() {
        this.isClicked = true;
        if (this.fileObj !== null) {
            this.service.uploadFile(this.uploadedFile).subscribe(resp => {
                if (resp && resp.data) {
                    this.fileObj['url'] = resp.data.filename;
                    this.fileObj['desc'] = this.comment;
                    this.service.addAttachment(this.fileObj).subscribe(
                        res => {
                            this.service.getAttachmentList(this.attacherId).subscribe(
                                r => {
                                    this.attachmentList = r.data;
                                    this.toastr.success('Attachment Added');
                                    this.dialogRef.close();
                                    this.getCourseById();
                                },
                                error => {
                                    this.toastr.error('Failed to add Attachment');
                                    this.dialogRef.close();
                                }
                            );
                            this.isClicked = false;
                        },
                        error => {
                            this.toastr.error('Failed to add Attachment');
                            this.isClicked = false;
                            this.dialogRef.close();
                        }
                    );
                }
            }, error => {
                this.isClicked = false;
                const err = error.error && error.error.msg;
                console.log(err);
                this.toastr.error('Failed to save Attachment', 'Upload Unsuccessful');
                this.dialogRef.close();
            });
        } else {
            this.isClicked = false;
            this.toastr.error('No file Selected');
        }
    }

}
