<h2 mat-dialog-title>{{ data.title }}</h2>
<div mat-dialog-content>
    <div class="text-center are-u">{{ data.message }}</div>
</div>
<div mat-dialog-actions>
    <div class="action-buttons multi-btns col-sm-12 text-right">
        <button class="btn btn-danger" (click)="onCancel()">
            <i class="fa fa-ban fa-lg pull-lef"></i>Cancel</button>
        <button class="btn btn-primary" (click)="onConfirm()">
            <i class="fa fa-check-circle fa-lg pull-lef"></i>Confirm</button>
    </div>

</div>