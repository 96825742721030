import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {DashboardService} from '../../../dashboard.service';
import {FormGroup, FormBuilder} from '@angular/forms';
import { NotificationService } from "src/app/core/services/notification.service";
import {Observable} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
@Component({
  selector: 'app-leave-percentage',
  templateUrl: './leave-percentage.component.html',
  styleUrls: ['./leave-percentage.component.scss']
})
export class LeavePercentageComponent implements OnInit {
  filteredOptions: Observable<string[]>;
  public leaveForm: FormGroup;
  public leaveTypeList = [];
  public leavePercentageList = [];
  public modalRef: any;
  public isLoading: boolean;
  public err: string;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  paging = {
      pageSize: 10,
      totalRecords: 0,
      pageIndex: 0,
      pageSizeOptions: [10, 25, 50, 100]
  };
  public columns = ['leaveType','percentage'];
  constructor(
      private service: DashboardService, private fb: FormBuilder,
      private toastr: NotificationService, private modalService: NgbModal) {
    this.createForm();
  }

  ngOnInit() {
    this.service.getLeaveTypeList().subscribe(resp => {
      if (resp && resp.data) {
        this.leaveTypeList = resp.data;
      }
    });
  }

  public createForm(): void {
    this.leaveForm = this.fb.group({
      leavetypeid: [],
      startdate: [],
      enddate: []
    });
  }
  public submitLeave() {
    this.getLeavePer();
    console.log(this.leaveForm);
  }

  public getLeavePer(): void {
    this.isLoading = true;
    const leavetype = this.leaveForm.value.leavetypeid;
    var start = "" ;
    var end ="";
    if(this.leaveForm.value.startdate != null)
    {
      start = this.leaveForm.value.startdate;

    }
    
    if(this.leaveForm.value.startdate != null)
    {
      end = this.leaveForm.value.enddate;

    }
     
    this.service.getLeavePercentage(leavetype,start,end).subscribe(resp => {
      if (resp && resp.data) {
        this.leavePercentageList = resp.data;
      }
      this.dataSource = new MatTableDataSource<any>(this.leavePercentageList);
                this.dataSource.sort = this.sort;
      this.isLoading = false;
    }, error => {
      this.err = (error && error.error && error.error.msg) || 'Error in getting leave usage';
      this.isLoading = false;
    });
  }

  pageChange(event) {
    this.paging.pageIndex = event.pageIndex;
    this.paging.pageSize = event.pageSize;
}
}
