<mat-form-field class="form-element">
  <mat-label>{{ title }}</mat-label>
  <input
    [required]="required"
    class="form-control"
    type="text"
    matInput
    [formControl] = "countryCtrl"
    [matAutocomplete]="auto"
    [value]="getCountryByIso(selectedCountry)"
  />
  <mat-autocomplete #auto [displayWith]="getCountryByIso.bind(this)" (optionSelected)="selectionChanged($event)">
    <mat-option *ngFor="let c of filteredCountries" [value]="c.iso">
      {{ c.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
