<div id="header-page" class="ng-star-inserted header-page">
    <div class="page-header ng-star-inserted">
        <div class="page-block ng-star-inserted">
            <div class="row align-items-center">
                <div class="col-md-8">
                    <div class="page-header-title">
                        <span class="ng-star-inserted"></span><span class="ng-star-inserted">
                            <h4 class="m-b-10 ng-star-inserted">AddClient</h4>
                        </span>
                    </div>
                </div>
                <div class="col-md-4"></div>
            </div>
        </div>
    </div>
</div>

<div class="page-body">
    <div class="row">
        <div class="col-sm-12">
            <app-card>
                <div class="dt-responsive form-material full-width-inputs">
                    <form class="full-width-inputs form-material" action="javascript:" [formGroup]="requestForm">
                        <div class="row form-group">
                            <div class="col-md-4">
                                <mat-form-field class="form-element">
                                    <mat-label>Security Code </mat-label>
                                    <input matInput [type]="currentHide ? 'password' : 'text'"
                                        autocomplete="new-password" required formControlName="securityCode"
                                        class="form-control" />
                                    <mat-icon matSuffix (click)="currentHide = !currentHide">{{ currentHide ?
                                        "visibility_off" : "visibility" }}</mat-icon>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <mat-form-field class="form-element">
                                    <mat-label>Client Id </mat-label>
                                    <input matInput class="form-control" formControlName="client" required />
                                </mat-form-field>
                            </div>

                            <div class="col-md-4">
                                <mat-form-field class="form-element">
                                    <mat-label>Organization Name</mat-label>
                                    <input matInput class="form-control" formControlName="orgName" required />
                                </mat-form-field>
                            </div>


                            <div class="col-md-4">
                                <mat-form-field class="form-element">
                                    <mat-label>First Name </mat-label>
                                    <input matInput class="form-control" formControlName="firstname" required />
                                </mat-form-field>
                            </div>

                            <div class="col-md-4">
                                <mat-form-field class="form-element">
                                    <mat-label>Last Name </mat-label>
                                    <input matInput class="form-control" formControlName="lastname" required />
                                </mat-form-field>
                            </div>

                            <div class="col-md-4">
                                <mat-form-field class="form-element">
                                    <mat-label>Primary Email </mat-label>
                                    <input matInput class="form-control" formControlName="email" required />
                                </mat-form-field>
                            </div>

                            <div class="col-md-4">
                                <mat-form-field class="form-element">
                                    <mat-label>Location </mat-label>
                                    <input matInput class="form-control" formControlName="location" required />
                                </mat-form-field>
                            </div>

                            <div class="col-md-4">
                                <mat-form-field class="form-element">
                                    <mat-label>Region </mat-label>
                                    <input matInput class="form-control" formControlName="region" required />
                                </mat-form-field>
                            </div>

                            <div class="col-md-4">
                                <mat-form-field class="form-element">
                                    <mat-label>Country Code</mat-label>
                                    <input matInput class="form-control" formControlName="countrycode" required
                                        [matAutocomplete]="auto">
                                    <mat-autocomplete #auto="matAutocomplete"
                                        [displayWith]="getCountryByIso.bind(this)">
                                        <mat-option *ngFor="let r of filteredOptions " [value]="r.iso">
                                            {{r.name}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>

                            <div class="col-md-4">
                                <mat-form-field class="form-element">
                                    <mat-label>Job Title </mat-label>
                                    <input matInput class="form-control" formControlName="jobTitle" required />
                                </mat-form-field>
                            </div>

                            <div class="col-md-4">
                                <mat-form-field class="form-element">
                                    <mat-label>Emloyement Status </mat-label>
                                    <input matInput class="form-control" formControlName="emloyementStatus" required />
                                </mat-form-field>
                            </div>

                            <div class="col-md-4">
                                <mat-form-field class="form-element">
                                    <mat-label>Department </mat-label>
                                    <input matInput class="form-control" formControlName="department" required />
                                </mat-form-field>
                            </div>

                            <div class="col-sm-12 text-right action-buttons multi-btns">
                                <button mat-raised-button color="primary" class="btn-primary" (click)="addClient()"
                                    [class.spinner]="isLoading" [disabled]="isLoading || requestForm.invalid">
                                    Add
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </app-card>
        </div>
    </div>
</div>