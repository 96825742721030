import { Component, NgZone, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationService } from "src/app/core/services/notification.service";
import { DashboardService } from "src/app/dashboard/dashboard.service";
import autoTable from 'jspdf-autotable';
import { saveAs } from 'file-saver-es';
import jsPDF from 'jspdf';
import { MatCheckbox, MatCheckboxChange } from "@angular/material/checkbox";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { AuthService } from "src/app/core/services/auth.service";
@Component({
    selector: 'app-edit-custome-fields',
    templateUrl: './edit-custome-fields.component.html',
    styleUrls: ['./edit-custome-fields.component.scss']
})

export class EditCustomeFieldsComponent implements OnInit {

    public isLoadingCustom: boolean = true;
    public isLoadingEdit: boolean = false;
    public sectionId: string = "";
    public options: string = "";
    public validCheck: boolean = false;
    public filterQuery = '';
    selectedCheckBoxList = [];
    public locationList: Array<any> = [];
    public dialogRef: any;
    public sectionForm: FormGroup;
    public customFieldForm: FormGroup;
    public availableSelected: boolean = true;
    public typeSelected: string = "";
    public customFieldList: Array<any> = [];
    public customLocationsList: Array<any> = [];
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
    @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
    @ViewChild('deleteCustomFields', { static: false }) deleteCustomFields;
    @ViewChild('addCustomField', { static: false }) addCustomField;
    public columns = ['select', 'name', 'type', 'required', 'allLocations'];
    public isEdit: boolean = false;
    public screenList =
        [
            {
                label: "Personal Detail",
                value: "pe"
            },
            {
                label: "Job",
                value: "jb"
            },
            {
                label: "Salary",
                value: "sa"
            },
            {
                label: "Qualification",
                value: "qu"
            },
            {
                label: "Contact Details",
                value: "cd"
            },
            {
                label: "Social Media Details",
                value: "sm"
            },
            {
                label: "Emergency Contacts",
                value: "ec"
            },
            {
                label: "Dependents",
                value: "dp"
            },
            {
                label: "Immigration",
                value: "im"
            },
            {
                label: "Report To",
                value: "rt"
            },
            {
                label: "Bank Info",
                value: "bi"
            },

        ]
    public typesList =
        [
            {
                label: "Text",
                value: "st"
            },
            {
                label: "Number",
                value: "num"
            },
            {
                label: "Drop Down",
                value: "dd"
            },
            {
                label: "Date",
                value: "dt"
            },
            {
                label: "Checkboxes",
                value: "cb"
            },
        ]

    public availablesList =
        [

            {
                label: "All Employees",
                value: true
            },
            {
                label: "Employees in the following locations",
                value: false
            }

        ]
    public err: string = "No Data Found";
    constructor(
        private activatedRoute: ActivatedRoute,
        private toastr: NotificationService,
        private dashboardService: DashboardService, private router: Router,
        private ngZone: NgZone, private dialog: MatDialog, private fb: FormBuilder,
        private authService: AuthService,) {
        this.createSectionForm();
        this.createCustomFieldForm();


    }
    public permissions: any;
    public read = false;
    public write = false;
    public update = false;
    public delete = false;
    public classSelected = "";
    doNothing(): void {
        return;
    }
    checkPermission(module, tabs): void {
        this.permissions = JSON.parse(this.authService.getPermissions());
        this.classSelected = "";
        this.read = false;
        this.write = false;
        this.delete = false;
        this.update = false;
        if (this.permissions != undefined && this.permissions != null) {
            for (let p of this.permissions) {

                if (p.id === module) {

                    if (p.modules != undefined) {

                        for (let tab of p.modules) {
                            if (tab.id === tabs) {

                                for (let move of tab.permits) {
                                    if (move.id === "u") {
                                        this.update = true;
                                        this.classSelected = "cursor-pointer";
                                    }
                                    else if (move.id === "r") {
                                        this.read = true;
                                    }
                                    else if (move.id === "c") {

                                        this.write = true;
                                    }
                                    else if (move.id === "d") {
                                        this.delete = true;
                                    }
                                }
                            }
                        }
                    }

                }

            }
        }


    }
    ngOnInit(): void {
        this.checkPermission("pdm", "pd_cf");
        this.activatedRoute.params.subscribe(params => {
            if (params.id) {
                this.sectionId = params.id;
                this.isLoadingCustom = true;
                this.dashboardService.getLocationList().subscribe(res => {
                    if (res && res.data) {
                        this.locationList = res.data;
                        this.getSectionById(this.sectionId);
                    }
                }, error => {
                    this.isLoadingCustom = false;
                    this.toastr.error('Error while getting Section details');
                });


            }
        });


    }

    getCustomFields() {
        this.dashboardService.getSectionById(this.sectionId).subscribe(res => {
            if (res && res.data) {
                // this.isLoading = false;
                this.customFieldList = res.data.customFields;
                for (let custom of this.customFieldList) {
                    if (custom.required === true) {
                        custom.required = "Yes";
                    }

                    if (custom.required === false) {
                        custom.required = "No";
                    }
                    if (custom.type === "st") {
                        custom.type = "Text"
                    }

                    if (custom.type === "num") {
                        custom.type = "Number"
                    }
                    if (custom.type === "dd") {
                        custom.type = "Drop Down"
                    }

                    if (custom.type === "dt") {
                        custom.type = "Date"
                    }

                    if (custom.type === "cb") {
                        custom.type = "Checkboxes"
                    }
                    if (custom.allLocations === true) {
                        custom.allLocations = "All Employees"
                    }

                    if (custom.allLocations === false) {
                        custom.allLocations = ""
                        var tempLocations = [];
                        if (custom.locations != undefined) {
                            this.customLocationsList = custom.locations;
                            for (let location of this.customLocationsList) {
                                for (let loc of this.locationList) {
                                    if (location === loc.id) {
                                        location = loc.location;
                                        tempLocations.push(location);
                                    }
                                }
                            }
                            custom.allLocations = ""
                            for (let temp of tempLocations) {
                                var te = temp + ","
                                custom.allLocations = custom.allLocations + te;
                            }

                            custom.allLocations = custom.allLocations.slice(0, -1)
                        }

                    }
                }

                this.dataSource = new MatTableDataSource<any>(this.customFieldList);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                this.err = '';
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error while getting fields');
            // this.isLoading = false;
            this.err = 'No Data Found';
        });
    }

    checkFieldName() {
        this.validCheck = false;
        for (let field of this.customFieldList) {
            if (field.name.toLocaleLowerCase().replace(/\s/g, "") === this.customFieldForm.value.name.toLocaleLowerCase().replace(/\s/g, "")) {
                this.validCheck = true;
            }
        }
    }
    getSectionById(id): void {
        this.dashboardService.getSectionById(id).subscribe(res => {
            if (res && res.data) {
                // this.isLoading = false;
                this.sectionForm.patchValue(res.data);
                this.customFieldList = res.data.customFields;
                for (let custom of this.customFieldList) {
                    if (custom.required === true) {
                        custom.required = "Yes";
                    }

                    if (custom.required === false) {
                        custom.required = "No";
                    }
                    if (custom.type === "st") {
                        custom.type = "Text"
                    }

                    if (custom.type === "num") {
                        custom.type = "Number"
                    }
                    if (custom.type === "dd") {
                        custom.type = "Drop Down"
                    }

                    if (custom.type === "dt") {
                        custom.type = "Date"
                    }

                    if (custom.type === "cb") {
                        custom.type = "Checkboxes"
                    }
                    if (custom.allLocations === true) {
                        custom.allLocations = "All Employees"
                    }

                    if (custom.allLocations === false) {
                        custom.allLocations = ""
                        var tempLocations = [];
                        if (custom.locations != undefined) {
                            this.customLocationsList = custom.locations;
                            for (let location of this.customLocationsList) {
                                for (let loc of this.locationList) {
                                    if (location === loc.id) {
                                        location = loc.location;
                                        tempLocations.push(location);
                                    }
                                }
                            }
                            custom.allLocations = ""
                            for (let temp of tempLocations) {
                                var te = temp + ","
                                custom.allLocations = custom.allLocations + te;
                            }

                            custom.allLocations = custom.allLocations.slice(0, -1)
                        }

                    }
                }

                this.dataSource = new MatTableDataSource<any>(this.customFieldList);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                this.err = '';
                this.isLoadingCustom = false;
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error while getting section details');
            // this.isLoading = false;
            this.isLoadingCustom = false;
            this.err = 'No Data Found';
        });
    }

    public createSectionForm(): void {
        this.sectionForm = this.fb.group({
            id: this.sectionId,
            header: [''],
            name: ['']
        });
    }

    public createCustomFieldForm(): void {
        this.customFieldForm = this.fb.group({
            id: [''],
            allLocations: true,
            header: [''],
            conditionalValue: [''],
            locations: [[]],
            name: [''],
            required: false,
            type: [''],
            value: [''],
            options: [[]]
        });
    }
    public updateSection(): void {
        this.dashboardService.updateSection(this.sectionForm.value).subscribe(res => {
            if (res && res.data) {
                // this.isLoading = false;
                this.toastr.success('Section updated Successfully ..!');
                // this.dataSource = new MatTableDataSource<any>(this.sectionsList);
                // this.dataSource.paginator = this.paginator;
                // this.dataSource.sort = this.sort;
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error while updating section');
            // this.isLoading = false;
        });
    }
    public addNewCustomField() {
        this.isLoadingEdit = false;
        this.validCheck = false;
        this.isEdit = false;
        this.createCustomFieldForm();
        this.availableSelected = true;
        this.typeSelected = "";
        this.options = "";
        this.dialogRef = this.dialog.open(this.addCustomField, {
            width: '600px',
            height: 'auto'
        });

    }
    public updateCustom(): void {
        this.isLoadingEdit = true;
        if (this.validCheck) {
            this.toastr.error('Field Name is already exist in this section !');
            return;
        }
        if (this.customFieldForm.value.type === "st") {
            this.dashboardService.updateTextCustomField(this.sectionId, this.customFieldForm.value).subscribe(resp => {
                if (resp) {
                    this.close();
                    this.getCustomFields();
                    this.isLoadingEdit = false;
                    this.toastr.success('Field Edited successfully !');
                }
            }, error => {
                const err = error.error && error.error.msg;
                this.isLoadingEdit = false;
                this.toastr.error(err || 'Error in editing custom field');
            });
        }
        if (this.customFieldForm.value.type === "cb") {
            this.customFieldForm.value.required = false;
            this.customFieldForm.value.options = [];
            this.options = this.options.replace(/\s/g, "");
            var str = "";
            for (let i = 0; i <= this.options.length; i++) {
                if (this.options[i] === ",") {

                    if (str != "") {
                        this.customFieldForm.value.options.push(str);
                    }
                    str = "";
                }
                if (i === this.options.length && this.options[i] != ",") {
                    if (str != "") {
                        this.customFieldForm.value.options.push(str);
                    }

                    str = "";
                }
                if (this.options[i] != ",") {
                    str = str + this.options[i];
                }


            }
            this.dashboardService.updateCheckBoxCustomField(this.sectionId, this.customFieldForm.value).subscribe(resp => {
                if (resp) {
                    this.close();
                    this.getCustomFields();
                    this.isLoadingEdit = false;
                    this.toastr.success('Field Edited successfully !');
                }
            }, error => {
                const err = error.error && error.error.msg;
                this.isLoadingEdit = false;
                this.toastr.error(err || 'Error in editing custom field');
            });
        }
        if (this.customFieldForm.value.type === "dt") {
            this.dashboardService.updateDateCustomField(this.sectionId, this.customFieldForm.value).subscribe(resp => {
                if (resp) {
                    this.close();
                    this.getCustomFields();
                    this.isLoadingEdit = false;
                    this.toastr.success('Field Edited successfully !');
                }
            }, error => {
                const err = error.error && error.error.msg;
                this.isLoadingEdit = false;
                this.toastr.error(err || 'Error in editing custom field');
            });
        }
        if (this.customFieldForm.value.type === "dd") {
            this.customFieldForm.value.options = [];
            var str = "";
            for (let i = 0; i <= this.options.length; i++) {
                if (this.options[i] === ",") {

                    if (str != "") {
                        this.customFieldForm.value.options.push(str.trim());
                    }
                    str = "";
                }
                if (i === this.options.length && this.options[i] != ",") {
                    if (str != "") {
                        this.customFieldForm.value.options.push(str.trim());
                    }

                    str = "";
                }
                if (this.options[i] != ",") {
                    str = str + this.options[i];
                }


            }
            this.dashboardService.updateDropDownCustomField(this.sectionId, this.customFieldForm.value).subscribe(resp => {
                if (resp) {
                    this.close();
                    this.getCustomFields();
                    this.isLoadingEdit = false;
                    this.toastr.success('Field Edited successfully !');
                }
            }, error => {
                const err = error.error && error.error.msg;
                this.isLoadingEdit = false;
                this.toastr.error(err || 'Error in editing custom field');
            });
        }
        if (this.customFieldForm.value.type === "num") {
            this.dashboardService.updateNumberCustomField(this.sectionId, this.customFieldForm.value).subscribe(resp => {
                if (resp) {
                    this.close();
                    this.getCustomFields();
                    this.isLoadingEdit = false;
                    this.toastr.success('Field Edited successfully !');
                }
            }, error => {
                const err = error.error && error.error.msg;
                this.isLoadingEdit = false;
                this.toastr.error(err || 'Error in editing custom field');
            });
        }

    }
    // api/auth/customFields/getCustomField/{sectionId}/{fieldId}
    public updateCustomField(id) {
        this.isEdit = true;
        this.isLoadingEdit = true;
        this.createCustomFieldForm();
        this.availableSelected = true;
        this.typeSelected = "";
        this.options = "";
        this.dashboardService.getCustomFieldById(this.sectionId, id).subscribe(res => {
            if (res && res.data) {
                this.customFieldForm.patchValue(res.data);
                this.customFieldForm.value.name = res.data.name;

                this.typeSelected = res.data.type;
                this.availableSelected = res.data.allLocations;
                if (res.data.options != undefined) {
                    this.options = res.data.options.toString();
                }

                this.isLoadingEdit = false;

            }
        }, error => {
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error while getting field details');
            this.isLoadingEdit = false;
            // this.isLoading = false;
        });
        this.dialogRef = this.dialog.open(this.addCustomField, {
            width: '560px',
            height: 'auto'
        });


        //  this.customFieldForm.patchValue();




    }
    onCheckBoxChange($event: MatCheckboxChange, id: any) {
        if (!this.alreadySelected($event.source) && $event.checked) {
            this.selectedCheckBoxList.push($event.source.value);
        } else if (!$event.checked) {
            this.selectedCheckBoxList = this.selectedCheckBoxList.filter(
                value => {
                    return value !== $event.source.value;
                }
            );
        }
    }

    alreadySelected(item: MatCheckbox): boolean {
        this.selectedCheckBoxList.forEach(
            value => {
                if (item === value) {
                    return true;
                }
            }
        );
        return false;
    }

    onSelectAllClicked() {
        this.selectedCheckBoxList = [];
        this.matCheckBoxes.forEach(
            item => {
                this.selectedCheckBoxList.push(item.value);
                item.checked = true;
            }
        );
    }

    onDeSelectAllClicked() {
        this.selectedCheckBoxList = [];
        this.matCheckBoxes.forEach(
            item => {
                item.checked = false;
            }
        );
    }

    onDeleteSelected() {
        this.dialogRef = this.dialog.open(this.deleteCustomFields, {
            width: '440px',
            height: 'auto'
        });
    }

    onModelChange(value: string) {
        this.dataSource.filter = value.trim().toLocaleLowerCase();
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    exportToCsv() {

        const csv = this.dataSource.filteredData.map(
            row => {
                return [
                    row.name,
                    row.type,
                    row.required,
                    row.allLocations
                ].join(',') + '\n';
            }
        );

        csv.unshift(['Custom Field Name', 'Field Type', 'Required', 'Availability'].join(',') + '\n');
        const exportDate = new Date();
        // tslint:disable-next-line:max-line-length
        const exportFileName = `Csv-export-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}.csv`;
        saveAs(new Blob(csv, { type: 'text/csv;charset=utf-8' }), exportFileName);
    }

    saveAsPdf() {
        if (this.dataSource.filteredData.length <= 0) {
            this.toastr.error('No Data to export');
            return;
        }
        const body = [];
        for (let i = 0; i < this.dataSource.filteredData.length; i++) {
            body.push(
                [
                    this.dataSource.filteredData[i].name,
                    this.dataSource.filteredData[i].type,
                    this.dataSource.filteredData[i].required,
                    this.dataSource.filteredData[i].allLocations

                ]);
        }
        const doc = new jsPDF();
        autoTable(doc, {
            head: [['Custom Field Name', 'Field Type', 'Required', 'Availability']],
            body: body
        });
        const exportDate = new Date();
        const exportFileName = `custom fields-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}}`;
        doc.save(exportFileName);
    }

    public addCustomFields(): void {
        this.isLoadingEdit = true;
        if (this.validCheck) {
            this.toastr.error('Field Name is already exist in this section !');
            return;
        }
        if (this.customFieldForm.value.type === "st") {
            this.dashboardService.saveTextCustomField(this.sectionId, this.customFieldForm.value).subscribe(resp => {
                if (resp) {
                    this.close();
                    this.getCustomFields();
                    this.isLoadingEdit = false;
                    this.toastr.success('Field Added successfully !');
                }
            }, error => {
                const err = error.error && error.error.msg;
                this.isLoadingEdit = false;
                this.toastr.error(err || 'Error in adding cutom field');
            });
        }
        if (this.customFieldForm.value.type === "cb") {

            this.customFieldForm.value.required = false;
            this.customFieldForm.value.options = [];
            this.options = this.options.replace(/\s/g, "");
            var str = "";
            for (let i = 0; i <= this.options.length; i++) {
                if (this.options[i] === ",") {

                    if (str != "") {
                        this.customFieldForm.value.options.push(str);
                    }
                    str = "";
                }
                if (i === this.options.length && this.options[i] != ",") {
                    if (str != "") {
                        this.customFieldForm.value.options.push(str);
                    }

                    str = "";
                }
                if (this.options[i] != ",") {
                    str = str + this.options[i];
                }


            }
            this.dashboardService.saveCheckBoxCustomField(this.sectionId, this.customFieldForm.value).subscribe(resp => {
                if (resp) {
                    this.close();
                    this.getCustomFields();
                    this.isLoadingEdit = false;
                    this.toastr.success('Field Added successfully !');
                }
            }, error => {
                const err = error.error && error.error.msg;
                this.isLoadingEdit = false;
                this.toastr.error(err || 'Error in adding custom field');
            });
        }
        if (this.customFieldForm.value.type === "dt") {
            this.dashboardService.saveDateCustomField(this.sectionId, this.customFieldForm.value).subscribe(resp => {
                if (resp) {
                    this.close();
                    this.getCustomFields();
                    this.isLoadingEdit = false;
                    this.toastr.success('Field Added successfully !');
                }
            }, error => {
                const err = error.error && error.error.msg;
                this.isLoadingEdit = false;
                this.toastr.error(err || 'Error in adding cutom field');
            });
        }
        if (this.customFieldForm.value.type === "dd") {
            this.customFieldForm.value.options = [];
            this.options = this.options.replace(/\s/g, "");;
            var str = "";
            for (let i = 0; i <= this.options.length; i++) {
                if (this.options[i] === ",") {

                    if (str != "") {
                        this.customFieldForm.value.options.push(str);
                    }
                    str = "";
                }
                if (i === this.options.length && this.options[i] != ",") {
                    if (str != "") {
                        this.customFieldForm.value.options.push(str);
                    }

                    str = "";
                }
                if (this.options[i] != ",") {
                    str = str + this.options[i];
                }


            }
            this.dashboardService.saveDropDownCustomField(this.sectionId, this.customFieldForm.value).subscribe(resp => {
                if (resp) {
                    this.close();
                    this.getCustomFields();
                    this.isLoadingEdit = false;
                    this.toastr.success('Field Added successfully !');
                }
            }, error => {
                const err = error.error && error.error.msg;
                this.isLoadingEdit = false;
                this.toastr.error(err || 'Error in adding cutom field');
            });
        }
        if (this.customFieldForm.value.type === "num") {
            this.dashboardService.saveNumberCustomField(this.sectionId, this.customFieldForm.value).subscribe(resp => {
                if (resp) {
                    this.close();
                    this.getCustomFields();
                    this.isLoadingEdit = false;
                    this.toastr.success('Field Added successfully !');
                }
            }, error => {
                const err = error.error && error.error.msg;
                this.isLoadingEdit = false;
                this.toastr.error(err || 'Error in adding cutom field');
            });
        }

    }
    public deleteCustomField(): void {
        this.isLoadingEdit = true;
        this.dashboardService.deleteCustomFields(this.sectionId, this.selectedCheckBoxList).subscribe(user => {
            if (user) {
                this.close();
                this.isLoadingEdit = false;
                this.getCustomFields();
                this.isLoadingEdit = false;
                this.toastr.success('Fields deleted successfully !');
            }
        }, error => {
            this.isLoadingEdit = false;
            const err = error.error && error.error.msg;
            this.close();
            // this.getAllSections();
            this.isLoadingEdit = false;
            this.toastr.error(err || 'Error in deleting Fields');
        });
        this.selectedCheckBoxList = [];
    }

    public close(): void {
        this.dialogRef.close();
    }

    public cancelSectionDetails(): void {
        this.ngZone.run(() => {
            void this.router.navigate(['/pim/configuration/custom-fields']);
        });
    }
    public tempLocations = [];

    changeLoc(op) {
        var result = this.locationList?.filter(item => op?.value?.includes(item.id));
        this.tempLocations = result;

    }
 
}