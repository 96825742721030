import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'inputTemplate',
  templateUrl: './input-template.component.html',
  styleUrls: ['./input-template.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class InputTemplateComponent implements OnInit {

  // pattern: string = '';

  @Input('name')
  name:string;

  @Input('label')
  label:string;

  @Input()
  validation: boolean = false;

  @Input('validationMessage')
  validationMessage: string;

  @Input('type')
  type: string = 'text';

  constructor() {
   }

   getPattern(): string {
     if(this.type === "email")
        return '[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
      else if(this.type === "number")
        return '[0-9]*';
      else
        return '[a-zA-Z]+[0-9]*';
   }

  ngOnInit(): void {
  }

}
