  import {Component, ElementRef, NgZone, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {DashboardService} from '../../../dashboard.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import { NotificationService } from "src/app/core/services/notification.service";
import { MatTableDataSource } from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
    selector: 'app-bulk-update',
    templateUrl: './bulk-update.component.html',
    styleUrls: ['./bulk-update.component.scss']
})
export class BulkUpdateComponent implements OnInit {

    public rowsOnPage = 5;
    public isLoading: boolean;
    public isExpanded: boolean;
    public err: string;
    public userForm: FormGroup;
    public updateForm: FormGroup;
    public employeeList: Array<any> = [];
    public employeeList2: Array<any> = [];
    public jobCatagoryList: Array<any> = [];
    public jobTitleList: Array<any> = [];
    public locationList: Array<any> = [];
    public countryList: Array<any> = [];
    public workShiftList: Array<any> = [];
    public structureList: Array<any> = [];
    public enableIndividual : boolean = false;
    public size : number = 0;
    public employeesList = [];
    public employeeTable = [];
    public employeesIds = [];
    selectedCheckBoxList = [];
    public subSelected:string = "";
    public jobCategorySelected:string = "";
    public employeeStatusSlected : string = "";
    public jobTitleSelected : string = "";
    public probationEndDateSelected = "";
    public locationSelected = "";
    public workShiftSelected = "";
    public effictiveFromSelected="";
    public checkValidation : boolean = false;
    public filteredOptions2: Array<any> = [];
    public correct : boolean = false ;

    dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
    @ViewChild(MatSort, {static: false}) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChildren(MatCheckbox) matCheckBoxes: QueryList<MatCheckbox>;
    @ViewChildren('matCheckBoxesSub') matCheckBoxesSub: QueryList<MatCheckbox>;
    public columns = ['select', 'id','firstLastName', 'temporarydepartment','jobcategoryid', 'jobtitelid','workshiftid','employeestatus','locationid','effectivefrom','endDate'];
    public joinedDateList: Array<any> = [
        {label: 'Undefined', value: ''},
        {label: 'After', value: 'After'},
        {label: 'Before', value: 'Before'},
        {label: 'Between', value: 'Between'}
    ];
    public subunitData: object[] = [];
    public supervisorList = [
        {value: 'fadi', viewValue: 'fadi'}
    ];
    public includeList = [
        {value: 'ce', viewValue: 'Current Employees only'},
        {value: 'cpe', viewValue: 'Current and past Employees only'},
        {value: 'pe', viewValue: 'past Employees only'},
    ];
    public employeeStatusList = [];
    public validationError = {
        required: 'Required'
    };
    public data = {
        employeeList: {},
        jobCategoryList: {},
        jobTitleList: {},
        locationList: {},
        temporaryDepartmentList: {},
        workShiftList: {},
        employeeStatusList: {}
    };
    dummy: ['Option1', 'Option2', 'Option3'];
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = false;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    fruitCtrl = new FormControl();
    filteredFruits: Observable<string[]>;
    fruits: Array<any> = [];
    tempEmployees : Array<any> = [];
    @ViewChild("fruitInput") fruitInput: ElementRef;
    constructor(private service: DashboardService,
                private authService : AuthService,
                private fb: FormBuilder,
                private toastr: NotificationService,
                private router: Router,
                private ngZone: NgZone) {
    }
    public permissions : any;
    public read = false;
    public write = false ;
    public update = false;
    public delete = false;
    initValuesDetection() {
        this.filteredOptions2 = [];

        this.userForm.controls['supervisorsIds'].valueChanges.subscribe(change => {
            startWith("");
            if (change == null || change == undefined) {
                this.correct = true;
                this.filteredOptions2 = [];
            } else {

                this.filterEmployees(change);
            }

        });
    }


    protected filterEmployees(change: String) {
        this.filteredOptions2 = this.employeeList2;
        if (!this.employeeList2) {
            return;
        }

        // get the search keyword
        if (!change) {

            this.filteredOptions2 = [];
            return;
        } else {
            this.checkEmployeeChange(change);
            change = change.toLowerCase();
        }
        // filter the search
        this.filteredOptions2 =
            this.employeeList2.filter(emp => emp.label.toLowerCase().indexOf(change) > -1);

    }
    
    checkEmployeeChange(change) {
        let x = this.filteredOptions2.find(x => x.value === change);
        if (!x) {
            this.correct = false;
        }

    }

    public displayEmployeeByName2(option) {
        if (!option) {
            return '';
        }
        this.correct = true;
        return this.employeeList2.find(x => x.value === option).label;
    }
    ngOnDestroy() {
        
        window.clearInterval();
        this.router = null ;
        this.fb = null ;
        this.toastr = null ;
        this.ngZone = null ;
        this.authService = null ;
        this.service = null ;
        this.rowsOnPage = 5;
        this.isLoading = null;
        this.isExpanded = null;
        this.err = null;
        this.userForm = null;
        this. updateForm = null;
        this. employeeList = null;
        this. jobCatagoryList = null;
        this. jobTitleList = null;
        this. locationList = null;
        this. countryList = null;
        this. workShiftList = null;
        this. structureList = null;
        this. enableIndividual = null;
        this. size = null;
        this. employeesList = null;
        this. employeeTable= null;
        this. employeesIds= null;
        this.selectedCheckBoxList= null;
        this. subSelected = null;
        this. jobCategorySelected= null;
        this. employeeStatusSlected = null;
        this. jobTitleSelected = null;
        this. probationEndDateSelected = null;
        this. locationSelected = null;
        this. workShiftSelected = null;
        this. effictiveFromSelected  = null;
        this. checkValidation = null;
        this.dataSource = null
        this.sort = null ;
        this.paginator = null;
        this.matCheckBoxes = null ;
        this.matCheckBoxesSub = null ;
        this.columns= null ;
        this.joinedDateList = null ;
        this.subunitData = null ;
        this.supervisorList = null ;
        this.includeList = null ;
        this.employeeStatusList = null ;
        this.validationError = null;
        this.data = null ;
        this.dummy = null ;
        this.visible = null ;
        this.selectable = null ;
        this.removable = null ;
        this.addOnBlur = null ;
        this.separatorKeysCodes = null ;
        this.fruitCtrl = null; 
        this.filteredFruits = null ;
        this.tempEmployees = null ;
        this.fruitInput = null ;
     
        
      }
    checkPermission(module,tabs) : void 
    {
        this.permissions = JSON.parse(this.authService.getPermissions());

        this.read = false ;
        this.write = false ;
        this.delete = false ;
        this.update = false;
        if(this.permissions != undefined && this.permissions != null )
        {
            for(let p of this.permissions)
            {
               
                if(p.id === module)
                {
                   
                    if(p.modules != undefined)
                    {
                      
                        for(let tab of p.modules)
                        {
                            if(tab.id === tabs)
                            {
                              
                                for(let move of tab.permits)
                                {
                                    if(move.id === "u")
                                    {
                                        this.update = true;
                                    }
                                    else if(move.id === "r")
                                    {
                                        this.read = true;
                                    }
                                    else if(move.id === "c")
                                    {
                                      
                                        this.write = true;
                                    }
                                    else if(move.id === "d")
                                    {
                                        this.delete = true;
                                    }
                                }
                            }
                        }
                    }
                   
                }
               
            }
        }
       

    }
    ngOnInit() {
        this.checkPermission("pdm","pd_bm");
        this.isExpanded = true;
        this.createForm();
        this.createUpdateForm();
        this.service.getEmployeeStatusList().subscribe(user => {
            this.employeeStatusList = user.data;
        });
        this.service.getEmployeeDetailList().subscribe(resp => {
            if (resp && resp.data) {
               
                resp.data.map(e => {
                    this.employeeList.push({name: e.id+' '+ e.name, id: e.id});
                });

                resp.data.map(e => {
                    this.employeeList2.push({label: e.id+' '+ e.name, value: e.id});
                });
                this.initValuesDetection();
               
                this.fruitCtrl.valueChanges.subscribe(search => {
                    startWith(''), 
                    this.filteredFruits = of(this.employeeList.filter(item =>
                      item.name.toLowerCase().includes(search)
                    ));
                  });
                
                   
            }
        }, error => {
            const err = error.error && error.error.msg;
            
            this.toastr.error(err || 'Error in getting dropdown');
        });
        // get the value for the drop down for the job catagory
        this.service.getJobCategoryList().subscribe(resp => {
            if (resp && resp.data) {
                this.jobCatagoryList = resp.data;
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.err = err || 'Error while getting Job Department';
        });
        // getting the value for the drop down for the job title
        this.service.getJobTitleList().subscribe(resp => {
            if (resp && resp.data) {
                this.jobTitleList = resp.data;
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error while getting Job Title');
            this.err = 'Error while getting Job Title';
        });
        // here we are getting the data for the drop down of the subunit
        this.service.getStructureList().subscribe(resp => {
            if (resp && resp.data) {
                resp.data.map(e => {
                    this.structureList.push({label: e.name, value: e.id});
                });
            }
        });
        // here we are getting the value for the drop down for the location
        this.service.getCountryList().subscribe(resp => {
            this.countryList = resp && resp.data;
            this.getJobCategoryList();
        });
        // here we are getting the data for the work shift
        this.service.getWorkShiftList().subscribe(user => {
            if (user && user.data) {
                this.workShiftList = user.data;
            }
        }, error => {
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Error while getting Work Shift');
            this.err = err || 'Error while getting Work Shift';
        });
    }
    remove(id): void {
        let res = this.fruits?.filter(item => item.id !== id);
        this.fruits = res ;
        let res2 = []
        if(this.tempEmployees.length != 0)
        {
            for(let emp of this.tempEmployees)
            {
                if(emp.id === id)
                {
                    this.employeeList.push(emp);
                }
                else 
                {
                    res2.push(emp);
                }
    
            }
            this.tempEmployees = res2 ;
        }

      
       
    }
  
    selected(event: MatAutocompleteSelectedEvent): void {
      this.fruits.push(event.option.value);
      this.tempEmployees.push(event.option.value);
      this.fruitInput.nativeElement.value = "";
      this.fruitCtrl.setValue(null);
      let res = this.employeeList?.filter(item => item.id !== event.option.value.id);
      this.employeeList = res ;
     
      this.fruitInput.nativeElement.blur();
    }

    public getJobCategoryList(): void {
        this.service.getLocationList().subscribe(user => {
            if (user && user.data) {
                this.locationList = user.data;
                this.locationList.map(user1 => {
                    const c = this.countryList.filter(f => {
                        return f.iso === user1.contrycode;
                    })[0];
                    user1.country = c && c.name;
                });
                this.err = '';
            }
        }, error => {
            this.err = 'Error while getting Location';
        });
    }
    public createForm(): void {
        this.userForm = this.fb.group({
            createdDate : ['all'],
            createdDateFrom: [''],
            createdDateBa: [''],
            createdDateTo: [''],
            createdDateCheckBox: [false],
            employeeStatusId: ['all'],
            employeesIds: [[]],
            employmentStatusCheckBox: [false],
            include: ['ce'],
            joTitleCheckBox: [false],
            jobCategoryCheckBox: [false],
            jobCategoryId: ['all'],
            jobTitleId: ['all'],
            locationCheckBox: [false],
            locationId: ['all'],
            probationDate: ['all'],
            probationDateBa: [''],
            probationDateFrom: [''],
            probationDateTo: [''],
            probationDateCheckBox: [false],
            subUnitCheckBox: [false],
            subUnitId: ['all'],
            supervisorNameCheckBox: [false],
            supervisorsIds: [''],
            workShiftCheckBox: [false],
            workShiftId: ['all']
        });
    }
    
    public createUpdateForm(): void {
        this.updateForm = this.fb.group({
            createdDateCheckBox: [false],
            employeeStatusCheckBox: [false],
            jobTitleCheckBox : [false],
            jobCategoryCheckBox: [false],
            locationCheckBox : [false],
            probationEndDateCheckBox : [false],
            subUnitCheckBox : [false],
            supervisorNameCheckBox: [false],
            workShiftCheckBox  : [false],
            effictiveFromCheckBox:[false],
            jobCategoryId : [''],
            employeeStatusId : [''],
            jobTitleId : [''],
            subUnitId : [''],
            locationId : [''],
            workShiftId : [''],
            probationEndDate : [''],
            effictiveFrom:[''],
            employeesIds:[[]]
           
        });
    }

    onCheckBoxChangeSub($event: MatCheckboxChange)
    {
        if($event.checked)
        {
            this.subSelected="";
            
        }
    }
    onCheckBoxChangeJobCat($event: MatCheckboxChange)
    {
        if($event.checked)
        {
            this.jobCategorySelected="";
            
        }
    }
    onCheckBoxChangeStatus($event: MatCheckboxChange)
    {
        if($event.checked)
        {
            this.employeeStatusSlected="";
            
        }
    }
    onCheckBoxChangeJobTitle($event: MatCheckboxChange)
    {
        if($event.checked)
        {
            this.jobTitleSelected="";
            
        }
    }
    onCheckBoxChangeWorkShif($event: MatCheckboxChange)
    {
        if($event.checked)
        {
            this.workShiftSelected="";
            
        }
    }
    onCheckBoxChangeLocation($event: MatCheckboxChange)
    {
        if($event.checked)
        {
            this.locationSelected="";
            
        }
    }

    onCheckBoxChangeEffectiveFrom($event: MatCheckboxChange)
    {
        if($event.checked)
        {
            this.effictiveFromSelected="";
            
        }
    }

    onCheckBoxChangeProbation($event: MatCheckboxChange)
    {
        if($event.checked)
        {
            this.probationEndDateSelected="";
            
        }
    }

    close() {
    }

    enableIndUpdate()
    {
        
        if(!this.enableIndividual)
        {
           this.onDeSelectAllClicked();
            this.enableIndividual = true;
        }
        
    }
   
    disableIndUpdate()
    {
        if(this.enableIndividual)
        {
            this.enableIndividual = false;
        }
    }
    submitBulkUpdate() {
        // console.log(this.userForm.value);
        // stepper.next();
        let ids = [];
        this.userForm.patchValue(
            {
                employeesIds : []
            }
        );
        this.fruits?.map(value => {
            ids.push(value.id);
            });

            this.userForm.patchValue(
                {
                    employeesIds : ids
                }
            );
        this.service.updateAsABatch(this.userForm.value).subscribe(res => {
           
            this.size = res.data.size;
            this.employeesList = res.data.employees;
            for(let emp of this.employeesList)
            {
                this.employeesIds.push(emp.id);
            }
            this.employeeTable =  res.data.employees;
            if(this.employeeTable != [])
            {
                for(let emp of this.employeeTable)
                {
                    this.getLocationById(emp,emp.locationid);
                
                    this.getSubUnitById(emp,emp.temporarydepartment);
    
                    this.getWorkShiftById(emp,emp.workshiftid);
    
                    this.getJobCategoryById(emp,emp.jobcategoryid);
    
                    this.getJobTitleById(emp,emp.jobtitelid);
    
                    this.getEmployeeStatus(emp,emp.employeestatus);
                   
    
                }
            }
           
            this.dataSource = new MatTableDataSource<any>(this.employeeTable);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
        }, error => {
            this.toastr.error('Error in getting Employee List');
        });
    }

    executeBulkUpdate() {
      this.checkValidation = false;
        if(
            !this.updateForm.value.employeeStatusCheckBox &&
            !this.updateForm.value.jobTitleCheckBox &&
            !this.updateForm.value.jobCategoryCheckBox &&
            !this.updateForm.value.locationCheckBox &&
            !this.updateForm.value.probationEndDateCheckBox && 
            !this.updateForm.value.subUnitCheckBox && 
            !this.updateForm.value.supervisorNameCheckBox &&
            !this.updateForm.value.workShiftCheckBox &&
            !this.updateForm.value.effictiveFromCheckBox )
            {
                this.toastr.error('Please choose at least one criteria to update it !');
                return;
            }
            if(this.updateForm.value.employeeStatusCheckBox)
            {
                if(this.employeeStatusSlected === "")
                {
                    this.checkValidation = true;
                }
            }

            if(this.updateForm.value.jobTitleCheckBox)
            {
                if(this.jobTitleSelected === "")
                {
                    this.checkValidation = true;
                }
            }
            if(this.updateForm.value.jobCategoryCheckBox)
            {
                if(this.jobCategorySelected === "")
                {
                    this.checkValidation = true;
                }
            }
            if(this.updateForm.value.locationCheckBox)
            {
                if(this.locationSelected === "")
                {
                    this.checkValidation = true;
                }
            }
            if(this.updateForm.value.probationEndDateCheckBox)
            {
                if(this.probationEndDateSelected === "")
                {
                    this.checkValidation = true;
                }
            }
            if(this.updateForm.value.subUnitCheckBox)
            {
                if(this.subSelected === "")
                {
                    this.checkValidation = true;
                }
            }
            if(this.updateForm.value.workShiftCheckBox)
            {
                if(this.workShiftSelected === "")
                {
                    this.checkValidation = true;
                }
            }
            if(this.updateForm.value.effictiveFromCheckBox)
            {
                if(this.effictiveFromSelected === "")
                {
                    this.checkValidation = true;
                }
            }

            if(this.checkValidation)
            {
                this.toastr.error('Please fill all the fields you have selected it for the updatation');
                return;
            }
            this.updateForm.value.employeesIds = this.employeesIds;
        this.service.exeBatch(this.updateForm.value).subscribe(res => {
           if(res && res.data)
           {
           this.toastr.success('Updated successfully..!');
           this.ngZone.run(() => {
            void this.router.navigate(['/pim/employees']);
          });
           }
          
        }, error => {
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Failed To update!');
        });
    }

    executeIndivdulaUpdate() {
      this.checkValidation = false;
        if(
            !this.updateForm.value.employeeStatusCheckBox &&
            !this.updateForm.value.jobTitleCheckBox &&
            !this.updateForm.value.jobCategoryCheckBox &&
            !this.updateForm.value.locationCheckBox &&
            !this.updateForm.value.probationEndDateCheckBox && 
            !this.updateForm.value.subUnitCheckBox && 
            !this.updateForm.value.supervisorNameCheckBox &&
            !this.updateForm.value.workShiftCheckBox &&
            !this.updateForm.value.effictiveFromCheckBox )
            {
                this.toastr.error('Please choose at least one criteria to update it !');
                return;
            }

                if(this.updateForm.value.employeeStatusCheckBox)
                {
                    if(this.employeeStatusSlected === "")
                    {
                        this.checkValidation = true;
                    }
                }

                if(this.updateForm.value.jobTitleCheckBox)
                {
                    if(this.jobTitleSelected === "")
                    {
                        this.checkValidation = true;
                    }
                }
                if(this.updateForm.value.jobCategoryCheckBox)
                {
                    if(this.jobCategorySelected === "")
                    {
                        this.checkValidation = true;
                    }
                }
                if(this.updateForm.value.locationCheckBox)
                {
                    if(this.locationSelected === "")
                    {
                        this.checkValidation = true;
                    }
                }
                if(this.updateForm.value.probationEndDateCheckBox)
                {
                    if(this.probationEndDateSelected === "")
                    {
                        this.checkValidation = true;
                    }
                }
                if(this.updateForm.value.subUnitCheckBox)
                {
                    if(this.subSelected === "")
                    {
                        this.checkValidation = true;
                    }
                }
                if(this.updateForm.value.workShiftCheckBox)
                {
                    if(this.workShiftSelected === "")
                    {
                        this.checkValidation = true;
                    }
                }
                if(this.updateForm.value.effictiveFromCheckBox)
                {
                    if(this.effictiveFromSelected === "")
                    {
                        this.checkValidation = true;
                    }
                }

                if(this.selectedCheckBoxList.length === 0)
                {
                    this.toastr.error('Please select the employees you want to Update !');
                    return;
                }

                if(this.checkValidation)
                {
                    this.toastr.error('Please fill all the fields you have selected it for updatation !');
                    return;
                }
            this.updateForm.value.employeesIds = this.selectedCheckBoxList;
        this.service.exeIndvivdual(this.updateForm.value).subscribe(res => {
           if(res && res.data)
           {
            this.toastr.success('Updated successfully..!');
            this.ngZone.run(() => {
                void this.router.navigate(['/pim/employees']);
              });
           }
          
        }, error => {
            const err = error.error && error.error.msg;
            this.toastr.error(err || 'Failed To update!');
        });
    }
    
    onSelectAllClicked() {
        this.selectedCheckBoxList = [];
       
        this.matCheckBoxes.forEach(
            item => {
                if(item._elementRef.nativeElement.innerText === "Sub Unit" ||
                item._elementRef.nativeElement.innerText === "Job Category" ||
                item._elementRef.nativeElement.innerText === "Employment Status" ||
                item._elementRef.nativeElement.innerText === "Job Title" ||
                item._elementRef.nativeElement.innerText === "Probation end date" ||
                item._elementRef.nativeElement.innerText === "Location" ||
                item._elementRef.nativeElement.innerText === "Work Shift" ||
                item._elementRef.nativeElement.innerText === "Effective from" )
                {
                    item.checked = item.checked;
                }
                else
                {
                    this.selectedCheckBoxList.push(item.value);
                   item.checked = true;
                }
               
            }
        );
    }

    onDeSelectAllClicked() {
        this.selectedCheckBoxList = [];
        this.matCheckBoxes.forEach(
            item => {
               
                if(item._elementRef.nativeElement.innerText === "Sub Unit" ||
                item._elementRef.nativeElement.innerText === "Job Category" ||
                item._elementRef.nativeElement.innerText === "Employment Status" ||
                item._elementRef.nativeElement.innerText === "Job Title" ||
                item._elementRef.nativeElement.innerText === "Probation end date" ||
                item._elementRef.nativeElement.innerText === "Location" ||
                item._elementRef.nativeElement.innerText === "Work Shift" ||
                item._elementRef.nativeElement.innerText === "Effective from" )
                {
                    item.checked = item.checked;
                }
                else
                {
                    item.checked = false;
                }
              
            }
        );
    }


    onCheckBoxChange($event: MatCheckboxChange) {
        if (!this.alreadySelected($event.source) && $event.checked) {
            this.selectedCheckBoxList.push($event.source.value);
        } else if (!$event.checked) {
            this.selectedCheckBoxList = this.selectedCheckBoxList.filter(
                value => {
                    return value !== $event.source.value;
                }
            );
        }
    }

    alreadySelected(item: MatCheckbox): boolean {
        this.selectedCheckBoxList.forEach(
            value => {
                if (item === value) {
                    return true;
                }
            }
        );
        return false;
    }

    getLocationById(emp,id)
    {
        for(let location of this.locationList)
        {
            if(id === location.id)
            {
                emp.locationid = location.location;
            }
        }
    }

    getSubUnitById(emp,id)
    {
        for(let subUnit of this.structureList)
        {
            if(id === subUnit.value)
            {
                emp.temporarydepartment = subUnit.label;
            }
        }
    }

    getWorkShiftById(emp,id)
    {
        for(let workShift of this.workShiftList)
        {
            if(id === workShift.id)
            {
                emp.workshiftid = workShift.name;
            }
        }
    }

    getJobCategoryById(emp,id)
    {
        for(let jobCategory of this.jobCatagoryList)
        {
            if(id === jobCategory.id)
            {
                emp.jobcategoryid = jobCategory.name;
            }
        }
    }


    getJobTitleById(emp,id)
    {
        for(let jobTitle of this.jobTitleList)
        {
            if(id === jobTitle.id)
            {
                emp.jobtitelid = jobTitle.title;
            }
        }
    }


    getEmployeeStatus(emp,id)
    {
        for(let status of this.employeeStatusList)
        {
            if(id === status.id)
            {
                emp.employeestatus = status.name;
            }
        }
    }
}
