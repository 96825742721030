import {Component, OnInit, ViewChild} from '@angular/core';
import {FlatTreeControl} from '@angular/cdk/tree';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import {UtilService} from '../../../../service/util.service';
// import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import { NotificationService } from "src/app/core/services/notification.service";
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CompetencyService} from '../../../../service/competency.service';
import {RequireMatch} from '../../../performance/helper-obj';
import jsPDF from 'jspdf';
import {saveAs} from 'file-saver-es';
import autoTable from 'jspdf-autotable';
import * as cloneDeep from 'lodash-es/cloneDeep'
import { LanguageConfig } from 'src/app/Language-config';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
    selector: 'app-competency-list',
    templateUrl: './competency-list.component.html',
    styleUrls: ['./competency-list.component.scss'],
})
export class CompetencyListComponent implements OnInit {
    public lang = new Map();
    @ViewChild('deleteUser', {static: false}) deleteUser;
    constructor(
        private competencyService: CompetencyService,
    
        // private modalService: NgbModal,
        private dialog: MatDialog,
        private fb: FormBuilder,
        private toastr: NotificationService,
        private authService : AuthService
         
    ) {
        this.newForm = this.fb.group({
            name: [''],
            desc: [''],
            parentComp: [undefined, this.parentComp.f],
            markAsGroup: [false],
            id: [undefined]
        });
        this.newForm.get('markAsGroup').valueChanges.subscribe(value => {
            if (value) {
                this.newForm.get('parentComp').setValidators([]);
            } else {
                this.newForm.get('parentComp').setValidators(this.validators);
            }
        });
        this.lang = LanguageConfig.map;
    }
    @ViewChild('competencyForm', {static: false}) competencyForm;
    // private modalRef: any;
    private dialogRef: any;
    public newForm: FormGroup;
    public filterdParent : Array<any>;
    isSaving = false;
    public isLoading :boolean  ;
    validators = [RequireMatch];
    parentComp: AutoCompleteObj = {l: [], f: new FormControl(undefined, this.validators)};
    selectedItem = {};
    treeControl;
    treeFlattener;
    dataSource;
    clonedTree : any;
    private competencyList;
    private _transformer = (node: FoodNode, level: number) => {
        return {
            expandable: !!node.children && node.children.length > 0,
            name: node.name,
            level: level,
            node: node
        };
    }
    public permissions : any;
    public read = false;
    public write = false ;
    public update = false;
    public delete = false;
   
    doNothing() : void
    {

        return null ;
    }
    checkPermission(module,tabs) : void 
    {
        this.permissions = JSON.parse(this.authService.getPermissions());

        this.read = false ;
        this.write = false ;
        this.delete = false ;
        this.update = false;
        if(this.permissions != undefined && this.permissions != null )
        {
            for(let p of this.permissions)
            {
               
                if(p.id === module)
                {
                   
                    if(p.modules != undefined)
                    {
                      
                        for(let tab of p.modules)
                        {
                            if(tab.id === tabs)
                            {
                              
                                for(let move of tab.permits)
                                {
                                    if(move.id === "u")
                                    {
                                        this.update = true;
                                    }
                                    else if(move.id === "r")
                                    {
                                        this.read = true;
                                    }
                                    else if(move.id === "c")
                                    {
                                      
                                        this.write = true;
                                    }
                                    else if(move.id === "d")
                                    {
                                        this.delete = true;
                                    }
                                }
                            }
                        }
                    }
                   
                }
          
            }
        }
       

    }
    ngOnInit() {
        this.isLoading = true;
        this.checkPermission("admin","ad_jb");
        this.getParents();
        this.competencyService.getCompetencies({tree: false}).subscribe(res => {
           this.competencyList = res.data;
        });
        this.treeControl = new FlatTreeControl<ExampleFlatNode>(
            node => node.level, node => node.expandable);
        this.treeFlattener = new MatTreeFlattener(
            this._transformer, node => node.level, node => node.expandable, node => node.children);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
        this.isLoading = false;
    }

    hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

    checkChange($event, item) {
        if (item.noEditable) {
            return;
        }
        this.selectedItem[item.id] = $event.checked;
    }

    changeAll(select: boolean) {
        Object.keys(this.selectedItem).forEach((id) => {
            this.selectedItem[id] = select;
        });
    }

    isSelectedAll() {
        const key = Object.keys(this.selectedItem).find(id => this.selectedItem[id] === false);
        return key === undefined;
    }

    isAnySelected() {
        return !!Object.keys(this.selectedItem).find(id => this.selectedItem[id] === true);
    }

    onDeleteSelected() {
        this.dialogRef = this.dialog.open(this.deleteUser, {
            width: '440px',
            height: 'auto'
        });
    }

    deleteSelected() {
        const seleted = Object.keys(this.selectedItem).filter(id => this.selectedItem[id] === true);
        this.competencyService.deleteCompetency({ids: seleted}).subscribe(res => {
            if (res && res.data) {
                this.getParents();
            }
            this.dialogRef.close();
            this.toastr.success('Deleted Successfully');
        }, error => {
            const err = error.error && error.error.msg;
            this.isSaving = false;
            this.toastr.error(err || 'Error while delete Competency.');
        });
    }

    itemClick(item) {
        if (item.noEditable) {
            this.toastr.error(item.getName() + ' competency can\'t update.');
            return;
        }
        this.openModel();
        this.newForm.patchValue({
            id: item.id,
            name: item.name,
            desc: item.desc,
            markAsGroup: item.markAsGroup ? true : false,
            parentComp: this.parentComp.l.find(t => t.id === item.parentid)
        });
        this.getParents();
        

    }

    openModel() {
        this.newForm.patchValue({id: null, name: '', desc: '', markAsGroup: false, parentComp: null});
        this.getParents();
        this.dialogRef = this.dialog.open(this.competencyForm,{
            width: '440px',
            height: 'auto'
        });
    }

    closeModel() {
        this.newForm.patchValue({id: null, name: ''});
        this.dialogRef.close();
    }

    save() {
        console.log(this.newForm);
        this.newForm.markAsTouched();
        if (this.newForm.invalid) {
            return;
        }
        const value = this.newForm.value;
        if (!value.markAsGroup) {
            value.parentid = (value.parentComp && value.parentComp.id) ? value.parentComp.id : null;
        }
        delete value['parentComp'];

        this.isSaving = true;
        console.log(value);

        (value.id ?
                this.competencyService.updateCompetency(value, value.id) :
                this.competencyService.saveCompetency(value)
        ).subscribe(res => {
            if (res && res.data) {
                if (value.id) {
                    this.toastr.success('Competency updated.');
                } else {
                    this.toastr.success('Competency save.');
                }
                this.closeModel();
                this.getParents();
            }
            this.isSaving = false;
        }, error => {
            const err = error.error && error.error.msg;
            this.isSaving = false;
            this.toastr.error(err || 'Error while saving Competency.');
        });
    }

    private getParents() {
        this.selectedItem = {};
        this.competencyService.getCompetencies({tree: true}).subscribe(res => {
            if (res && res.data) {
                console.log(res.data);
                this.filterdParent = res.data;
                this.dataSource.data = res.data;
                this.clonedTree = cloneDeep(this.dataSource.data)
                this.RemoveElementFromObjectArray(this.newForm.controls['id'].value);
                this.parentComp.l = this.getApiData({data: this.filterdParent.filter(t => t.markAsGroup)}).map((item => {
                    return {id: item.id, name: item.name} as Pair;
                }));
                this.parentComp.fi = this.vco(this.parentComp.f.valueChanges, this.parentComp.l.slice());
                res.data.map(t => {
                    !t.noEditable && (this.selectedItem[t.id] = false);
                    if (t.children) {
                        t.children.map(ch => {
                            !t.noEditable && (this.selectedItem[ch.id] = false);
                        });
                    }
                });
                
                

            }
            this.isSaving = false;
            this.treeControl.expandAll();
        }, error => {
            const err = error.error && error.error.msg;
            this.isSaving = false;
            this.toastr.error(err || 'Error while getting Competency.');
        });
    }
   
    private getApiData(res) {
        return (res && res.data && res.data.length > 0) ? res.data : [];
    }
    public  RemoveElementFromObjectArray(key: number) {
        this.filterdParent.forEach((value,index)=>{
            if(value.id==key) this.filterdParent.splice(index,1);
        });
    } 
    vco(controllerChange: Observable<any>, options: Pair[]): Observable<any> {
        return controllerChange.pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name ? this._filter(options, name) : options.slice())
        );
    }

    displayFn(pair?: Pair): string | undefined {
        return pair ? pair.name : undefined;
    }

    private _filter(options: Pair[], name: string): Pair[] {
        const filterValue = name.toLowerCase();
        return options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }
    exportToCsv() {
        const csv = this.competencyList.map(
            row => {
                return [row.name, row.desc].join(',') + '\n';
            }
        );
        csv.unshift(['Name', 'Description'].join(',') + '\n');
        const exportDate = new Date();
        // tslint:disable-next-line:max-line-length
        const exportFileName = `Csv-export-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}.csv`;
        saveAs(new Blob(csv, {type: 'text/csv;charset=utf-8'}), exportFileName);
    }

    saveAsPdf() {
        if (this.competencyList.length <= 0) {
            this.toastr.error('No Data to export');
            return;
        }
        const body = [];
        for (let i = 0; i < this.competencyList.length; i++) {
            body.push(
                [
                    this.competencyList[i].name,
                    this.competencyList[i].desc,
                ]);
        }
        const doc = new jsPDF();
        autoTable(doc, {
            head: [['Name', 'Description']],
            body: body
        });
        const exportDate = new Date();
        const exportFileName = `Competency List-${exportDate.getFullYear()}-${exportDate.getMonth() + 1}-${exportDate.getDate()}}`;
        doc.save(exportFileName);
    }

    searchString : string

    public filterChanged(searchStr){
        this.searchString = searchStr
        const clonedTreeLocal = cloneDeep(this.clonedTree);
        this.recursiveNodeEliminator(clonedTreeLocal);
        this.dataSource.data = clonedTreeLocal;
        this.treeControl.expandAll();
    }

    recursiveNodeEliminator(tree: Array<FoodNode>): boolean {
        
        for (let index = tree.length - 1; index >= 0; index--) {
          const node = tree[index];
          if (node.children) {
            const parentCanBeEliminated = this.recursiveNodeEliminator(node.children);
            if (parentCanBeEliminated) {
              if (node.name.toLocaleLowerCase().indexOf(this.searchString.toLocaleLowerCase()) === -1) {
                tree.splice(index, 1);
              }
            }
          } else {
            // Its a leaf node. No more branches.
            if (node.name.toLocaleLowerCase().indexOf(this.searchString.toLocaleLowerCase()) === -1) {
              tree.splice(index, 1);
            }
          }
        }
        return tree.length === 0;
      }
}

interface FoodNode {
    name: string;
    children?: FoodNode[];
}

interface ExampleFlatNode {
    expandable: boolean;
    name: string;
    level: number;
    node: FoodNode;
}

interface AutoCompleteObj {
    l: Pair[];
    f: FormControl;
    fi?: Observable<Pair[]>;
}


interface Pair {
    name: string;
    id: string;
}
