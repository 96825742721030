<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
      <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
          <div class="col-md-8">
          <div class="page-header-title">
              <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted">Bradford Factor Threshold</h4></span>
          </div>
          </div>
          <div class="col-md-4"></div>
      </div>
      </div>
  </div>
</div>
<div class="page-body no-title">
  <div class="row">
    <div class="col-sm-12">
        <div class="dt-responsive" *ngIf="userList">
          <!-- <div class="row" style="position: absolute; z-index: 2; width: 100%; padding: 10px"> -->
            <div class="row top-tools">
              <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6 left-tools">
                  <span class="export-icon-btn csv" (click)="exportToCsv()"><i class="material-icons">get_app</i><span>CSV</span>
                  </span>
                  <span class="export-icon-btn pdf" (click)="saveAsPdf()"><i class="material-icons">get_app</i><span>PDF</span>
                   </span>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
                  <div class="relative-position search-filter-con"> 
                      <span class="icon material-icons ng-tns-c114-0"> search </span>
                      <mat-form-field>
                          <input matInput #inputSearch type="search" [(ngModel)]="filterQuery" maxlength="30"
                          (ngModelChange)="onModelChange(filterQuery)"
                          class="form-control input-sm full-data-search">    
                      </mat-form-field>
                      <span class="mytooltip cus-left tooltip-effect-4">
                        <span class="tooltip-item">
                            <button mat-mini-fab class="btn btn-primary light add-rounded margin-button-05" (click)="addNewUser()">
                                <i class="fa fa-plus"></i>
                            </button>
                        </span>
                        <span class="tooltip-content clearfix">
                        <span class="tooltip-text">New</span>
                        </span>
                     </span>
                      <span class="mytooltip cus-left tooltip-effect-4">
                        <span class="tooltip-item">
                            <button mat-mini-fab class="btn btn-primary light add-rounded margin-button-05">
                              <!-- (click)="filterUserList()" -->
                                <i class="fa fa-filter"></i>
                            </button>
                        </span>
                        <span class="tooltip-content clearfix">
                        <span class="tooltip-text">filter</span>
                        </span>
                     </span>
                  </div>
              </div>
          </div>          <!-- <app-loading *ngIf="isLoading && !err" [isLoading]="isLoading && !err"></app-loading> -->
      <app-card [title]="'Bradford Factor Threshold'">
        <div class="dt-responsive" *ngIf="dataSource">
          <div class="table-responsive">
            <mat-card-content>
              <mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="delete">
                  <mat-header-cell *matHeaderCellDef>
                      <a [matMenuTriggerFor]="menu" class="check-all">
                          <span class="material-icons">
                              more_vert
                          </span>
                        </a>
                        <mat-menu #menu="matMenu">
                          <button mat-menu-item (click)="onSelectAllUsers()">Select All</button>
                          <button mat-menu-item (click)="onDeselectAllUsers()" >Deselect All</button>
                          <!-- *ngIf="selectedUsers.length > 0" -->
                          <button mat-menu-item (click)="deleteUserForm(row.id)">Delete Selected</button>
                            <!-- *ngIf="selectedUsers.length > 0" -->
                      </mat-menu> 
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                      <mat-checkbox [value]="row.id" (change)="onbtfChanged(userSelect)"></mat-checkbox>                 
                      <!--  (change)="deleteUserForm(row.id)" -->
                  </mat-cell>
              </ng-container>


                <!-- <ng-container matColumnDef="delete">
                  <mat-header-cell *matHeaderCellDef></mat-header-cell>
                  <mat-cell *matCellDef="let row">
                      <i class="fa fa-trash" (click)="deleteUserForm(row.id)"></i>
                  </mat-cell>
                </ng-container> -->
                  <ng-container matColumnDef="name">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
                      <mat-cell *matCellDef="let row">
                          {{ row.name }}
                      </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="threshold">
                      <mat-header-cell *matHeaderCellDef>Threshold</mat-header-cell>
                      <mat-cell *matCellDef="let row">
                          {{ row.threshold}}
                      </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="edit">
                    <mat-header-cell *matHeaderCellDef>Edit</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <span class="mytooltip tooltip-effect-4">
                        <span class="tooltip-item"><i class="single-icon-click fa fa-pencil" (click)="editUser(row.id)"></i></span>
                        <span class="tooltip-content clearfix">
                        <span class="tooltip-text">Edit</span>
                        </span>
                        </span>
                    </mat-cell>
                  </ng-container>
                  
                  <!-- <ng-container matColumnDef="edit">
                      <mat-header-cell *matHeaderCellDef></mat-header-cell>
                      <mat-cell *matCellDef="let row">
                          <i class="fa fa-edit" (click)="editUser(row.id)"></i>
                      </mat-cell>
                  </ng-container> -->
                  <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: columns;">
                  </mat-row>
              </mat-table>
              <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
              <mat-paginator [length]="paging.totalRecords" [pageSize]="paging.pageSize"
                             [pageSizeOptions]="paging.pageSizeOptions"
                             [pageIndex]="paging.pageIndex" (page)="pageChange($event)">
              </mat-paginator>
          </mat-card-content>
          </div>
          <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
        </div>
      </app-card>
    </div>
  </div>
</div>


<ng-template #addUser let-modal>
    <!-- <div class="card my-0"> -->
      <!-- <div class="header"> -->
        <!-- <div class="mat-modal-header"> -->
          <div class="mat-modal-header">
        <!-- <h4 class="card-title card-title-modal"> -->
          <h4 class="mat-title-modal">
          <span *ngIf="!isEdit">Add</span>
          <span *ngIf="isEdit">Edit</span>
         Bradfator Threshold</h4>
      </div>
      <!-- <div class="card-content"> -->
        <div class="mat-modal-body">
      <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>

      <form class="form-material full-width-inputs" action="javascript:" [formGroup]="userForm" *ngIf="!isUserAdded">
        <div class="row form-group">
          <!-- <div class="full-width-field type-input col-md-6">  -->
            <div class="col-md-6"> 
            <mat-form-field class="form-element">
              <mat-label>Name</mat-label>
                <input type="text" matInput class="form-control" maxlength="30" required="" id="name" formControlName="name">
                <mat-error *ngIf="!userForm.controls.name.valid && userForm.controls.name.touched">
                  {{ validationError.required }}
                </mat-error>
            </mat-form-field> 
            <!-- <input type="text" class="form-control" id="name"  formControlName="name" required="">
            <span class="form-bar"></span>
            <label class="float-label label-after" for="name">Name</label>
            <span class="validation-error" *ngIf="!userForm.controls.name.valid && userForm.controls.name.touched">{{ validationError.required }}</span> -->
          </div>
          <!-- <div class="full-width-field type-input col-md-6">  -->
            <div class="col-md-6"> 
            <mat-form-field class="form-element">
              <mat-label>Threshold</mat-label>
                <input type="text" matInput class="form-control" maxlength="30" required="" id="Threshold" formControlName="threshold">
                <mat-error *ngIf="!userForm.controls.threshold.valid && userForm.controls.threshold.touched">
                  {{ validationError.number }}
                </mat-error>
            </mat-form-field> 
            <!-- <input type="text" class="form-control" id="name"  formControlName="threshold" required="">
            <span class="form-bar" mask="000"></span>
            <label class="float-label label-after" for="name">Threshold</label>
            <span class="validation-error" *ngIf="!userForm.controls.threshold.valid && userForm.controls.threshold.touched">{{ validationError.number }}</span> -->
          </div>
        </div>
        <div class="row">
          <div class="action-buttons multi-btns col-sm-12 text-right">
            <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i>Cancel</button>
            <button mat-raised-button class="btn btn-primary" color="primary" (click)="submitAddJobTitle()" [disabled]="!userForm.valid">
              <i class="flaticon-diskette"></i>Save</button>
            <!-- [disabled]="!userForm.valid" -->
          </div>
          <!-- <div class="col-sm-12 text-right">
            <button type="submit" class="btn btn-cancel btn-danger" (click)="close()">Cancel</button>
            <button type="submit" class="btn btn-save btn-primary" (click)="submitAddJobTitle()" [disabled]="!userForm.valid">Save</button>
          </div> -->
        </div>
      </form>
      </div>
    <!-- </div> -->

</ng-template>


<ng-template #deleteUser let-modal>
    <div class="card my-0">
      <div class="header">
        <h4 class="card-title card-title-modal">Delete Bradfator Threshold</h4>
      </div>
      <div class="card-content px-2 py-2">
        <div class="text-left are-u">Are you sure ?</div>
        <div class="row">
          <div class="multi-btns col-sm-12 text-right">
            <button mat-raised-button class="btn-primary" color="primary" (click)="close()"><i class="flaticon-cancel"></i>Cancel</button>
            <button mat-raised-button class="btn-danger"  (click)="deleteJobTitle()"><i class="flaticon-delete"></i>Delete</button>
          </div>  
          <!-- <div class="col-sm-12 text-right">
            <button type="submit" class="btn btn-cancel btn-danger" (click)="close()">Cancel</button>
            <button type="submit" class="btn btn-save btn-primary" (click)="deleteJobTitle()">Delete</button>
          </div> -->
        </div>
      </div>
    </div>
</ng-template>





