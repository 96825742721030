<div class="d-flex justify-content-center">
  <nav class="my-4 pt-2">
    <ul class="pagination pagination-circle mb-0">
      <li class="nav-item dropdown">
          <a class="nav-link mr-2" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Page Size : {{ recordPerPage  }}<i class="material-icons icon-sm">keyboard_arrow_down</i>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
              <a class="dropdown-item" (click)="setRecordsPerPage(10)">10</a>
              <a class="dropdown-item" (click)="setRecordsPerPage(50)">50</a>
              <a class="dropdown-item" (click)="setRecordsPerPage(100)">100</a>
          </div>
      </li>
      <!--First-->
      <li class="page-item clearfix d-none d-md-block" (click)="firstPage()" [ngClass]="{disabled: activePage == firstPageNo}">
        <a class="page-link">{{ prevPageLabel }}</a>
      </li>
      <!--Arrow left-->
      <li *ngIf="totalRecords" class="page-item" (click)="previousPage($event)" [ngClass]="{disabled: activePage == firstPageNo}">
        <a class="page-link" aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
          <span class="sr-only">Previous</span>
        </a>
      </li>
      <!--Numbers-->
      <li *ngFor="let page of paginators; let i = index" class="page-item" [ngClass]="{active: ((page == activePage))}">
        <a class="page-link waves-light" (click)="changePage($event)" mdbWavesEffect>{{ page }}</a>
      </li>
      <!--Arrow right-->
      <li *ngIf="totalRecords" class="page-item" (click)="nextPage($event)" [ngClass]="{disabled: activePage == lastPageNo}">
        <a class="page-link" aria-label="Next">
          <span aria-hidden="true">&raquo;</span>
          <span class="sr-only">Next</span>
        </a>
      </li>
      <!--First-->
      <li class="page-item clearfix d-none d-md-block" (click)="lastPage()" [ngClass]="{disabled: activePage == lastPageNo}">
        <a class="page-link">{{ nextPageLabel }}</a>
      </li>
    </ul>
  </nav>
</div>
<!--/Pagination -->
