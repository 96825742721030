<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
    <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="page-header-title">
            <span class="ng-star-inserted"></span
            ><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted">Add Entitlement</h4></span>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</div>
<!-- <app-loading [isLoading]="this.isLoading"></app-loading> -->
<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<div class="card">
  <div class="card-content">
    <form class="form-material full-width-inputs" action="javascript:" [formGroup]="leaveForm" style="padding: 30px">
      <div class="row form-group">
        <div class="col-md-6" [hidden]="checkbox.checked">
          <app-lazy-employee-dropdown
            title="Employee"
            required="true"
            [selectedEmployee]="selectedEmployee"
            (employeeSelected)="updateSelectedEmployee($event)"
          ></app-lazy-employee-dropdown>
        </div>

        <div class="col-md-6" [hidden]="checkbox.checked">
          <mat-form-field>
            <mat-label>Leave Type</mat-label>
            <mat-select disableOptionCentering formControlName="leavetypeid" class="form-control" required>
              <mat-option *ngFor="let t of leaveTypeList" [value]="t.id">{{ t.leaveType }}</mat-option>
            </mat-select>
            <mat-error *ngIf="!leaveForm.controls.leavetypeid.valid && leaveForm.controls.leavetypeid.touched"
              >Required</mat-error
            >
          </mat-form-field>
        </div>
        <div class="col-md-6" [hidden]="!checkbox.checked">
          <mat-form-field>
            <mat-label>Leave Type</mat-label>
            <mat-select
              disableOptionCentering
              formControlName="leavetypeid"
              class="form-control"
              (ngModelChange)="getLocationAndSubUnitForLeaveType(); getEmployeesCount()"
              required
            >
              <mat-option *ngFor="let t of leaveTypeList" [value]="t.id">{{ t.leaveType }}</mat-option>
            </mat-select>
            <mat-error *ngIf="!leaveForm.controls.leavetypeid.valid && leaveForm.controls.leavetypeid.touched"
              >Required</mat-error
            >
          </mat-form-field>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-6 form-check">
          <section>
            <mat-checkbox #checkbox formControlName="entitlementSituational" id="es" (ngModelChange)="reset()"
              >Add To Multiple Employees
              <span [hidden]="!this.leaveForm.value.entitlementSituational">
                (Matches {{ this.count }} employee(s))</span
              ></mat-checkbox
            >
          </section>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-6" [hidden]="!checkbox.checked">
          <mat-form-field>
            <mat-label>Location</mat-label>
            <mat-select
              disableOptionCentering
              formControlName="locationId"
              [(ngModel)]="modeselect"
              (ngModelChange)="getSubUnitForLocation(); getEmployeesCount()"
              class="form-control"
            >
              <mat-option *ngFor="let t of locationsList" [value]="t.id">{{ t.location }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6" [hidden]="!checkbox.checked">
          <mat-form-field>
            <mat-label>Sub Unit</mat-label>
            <mat-select
              disableOptionCentering
              formControlName="subUnit"
              [(ngModel)]="modeselect2"
              (ngModelChange)="getEmployeesCount()"
              class="form-control"
            >
              <mat-option *ngFor="let t of subUnitList" [value]="t.id">{{ t.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Leave Period</mat-label>
            <mat-select disableOptionCentering formControlName="leaveperiodid" class="form-control" required>
              <mat-option *ngFor="let t of leavePeriodList" [value]="t.id">{{ t.currentleaveperiod }}</mat-option>
            </mat-select>
            <mat-error *ngIf="!leaveForm.controls.leaveperiodid.valid && leaveForm.controls.leaveperiodid.touched"
              >Required</mat-error
            >
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Entitlement Day</mat-label>
            <input name="ed" matInput type="number" class="form-control" formControlName="total" required="required" />
          </mat-form-field>
        </div>
        <br />
        <div class="col-md-12">
          <mat-form-field>
            <mat-label>Comment</mat-label>
            <input name="dd" matInput type="text" class="form-control" formControlName="comment" />
          </mat-form-field>
        </div>

        <!-- <div class="col-md-12">
          <mat-form-field>
            <mat-label>Entitlement Type</mat-label>
            <input
              name="ss"
              matInput
              type="text"
              class="form-control"
              formControlName="entitlementtype"
              readonly
              required="required"
            />
          </mat-form-field>
        </div> -->
      </div>
      <div class="row" [hidden]="!write">
        <div class="multi-btns col-md-12 text-right" *ngIf="this.leaveForm.value.entitlementSituational">
          <button
            mat-raised-button
            class="btn btn-save btn-primary"
            [class.spinner]="submitLoading"
            [disabled]="
              this.leaveForm.value.locationId === '' ||
              this.leaveForm.value.subUnit === '' ||
              this.leaveForm.value.total === '' ||
              this.leaveForm.value.total === null ||
              this.leaveForm.value.leavetypeid === '' ||
              submitLoading
            "
            (click)="addEntitlement()"
            color="primary"
          >
            <i class="flaticon-diskette"></i>Save
          </button>
        </div>
        <div class="multi-btns col-md-12 text-right" *ngIf="!this.leaveForm.value.entitlementSituational">
          <button
            mat-raised-button
            class="btn btn-save btn-primary"
            [disabled]="!leaveForm.valid || submitLoading"
            [class.spinner]="submitLoading"
            (click)="addEntitlement()"
            color="primary"
          >
            <i class="flaticon-diskette"></i>Save
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<ng-template #balanceDialog let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">Add Entitlements</h4>
    </div>
    <div class="mat-modal-body">
      <mat-card-content>
        <div class="dt-responsive">
          <div class="table-responsive">
            <mat-table [dataSource]="balanceSource" matSort>
              <ng-container matColumnDef="employeeName">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Employee Name</mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{ row.employeeName }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="oldEntitlement">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Old Entitlement</mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{ row.oldEntitlement }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="newEntitlement">
                <mat-header-cell *matHeaderCellDef mat-sort-header>New Entitlement</mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{ row.newEntitlement }}
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="balanceCloumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: balanceCloumns"> </mat-row>
            </mat-table>
          </div>
        </div>
        <br />
      </mat-card-content>

      <div class="row my-2 mx-2">
        <div class="action-buttons multi-btns col-sm-12 text-right">
          <button mat-raised-button class="btn-danger" (click)="close()">Close</button>
          <button mat-raised-button class="btn-danger" (click)="saveEntitlments()">Confirm</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
