<app-loading [isLoading]="this.isLoading === true"></app-loading>
<div [hidden]="isLoading" class="page-body no-title minus-top-margin-1">
    <div class="row">
        <div class="col-sm-12">
            <div class="row top-tools">
                <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6 left-tools">
          <span (click)="exportToCsv()" class="export-icon-btn csv"><i
                  class="material-icons">get_app</i><span>CSV</span>
          </span>
                    <span (click)="saveAsPdf()" class="export-icon-btn pdf"><i class="material-icons">get_app</i><span>PDF</span>
          </span>
                </div>
        <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
        <div class="relative-position search-filter-con"> 
                  <span class="icon material-icons ng-tns-c114-0"> search </span>
                        <mat-form-field>
                            <input matInput #inputSearch type="search" [(ngModel)]="filterQuery" maxlength="40"
                            (ngModelChange)="onModelChange(filterQuery)"
                            class="form-control input-sm full-data-search">    
                        </mat-form-field> 
                      <span class="mytooltip cus-left tooltip-effect-4" [hidden]="!update">
                        <span class="tooltip-item">
                            <button mat-mini-fab class="btn btn-primary light add-rounded margin-button-05" (click)="addNewUser()">
                                <i class="fa fa-plus"></i>
                            </button>
                        </span>
                        <span class="tooltip-content clearfix">
                        <span class="tooltip-text">New</span>
                        </span>
                      </span>
          </div>
        </div>
      </div>
      <app-card [title]="'MemberShip'">
          <div class="dt-responsive">
              <div class="table-responsive">
                  <mat-card-content>
                      <mat-table [dataSource]="dataSource" matSort>
                          <ng-container matColumnDef="select">
                              <mat-header-cell [hidden]="!delete" *matHeaderCellDef>
                                  <a [matMenuTriggerFor]="menu" class="check-all">
                      <span class="material-icons">
                        more_vert
                      </span>
                                  </a>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="onSelectAllClicked()">Select All</button>
                      <button (click)="onDeSelectAllClicked()" *ngIf="selectedCheckBoxList.length > 0" mat-menu-item>
                        Deselect All
                      </button>
                      <button (click)="onDeleteSelected()" *ngIf="selectedCheckBoxList.length > 0" mat-menu-item>Delete
                        Selected
                      </button>
                  
                    </mat-menu>
                  </mat-header-cell>
                  <mat-cell [hidden]="!delete" *matCellDef="let row">
                    <mat-checkbox #matCheckbox (change)="onCheckBoxChange($event, row.id)"
                                                      value="{{row.id}}"></mat-checkbox>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="name">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.name }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="type">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.type }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="link">
                  <mat-header-cell *matHeaderCellDef >Link</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.link }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="edit">
                  <mat-header-cell [hidden]="!update" *matHeaderCellDef>Edit</mat-header-cell>
                  <mat-cell [hidden]="!update" *matCellDef="let row">
                      <span class="mytooltip tooltip-effect-4">
                      <span class="tooltip-item"><i (click)="editUser(row.id)"
                                                    class="single-icon-click fa fa-pencil"></i></span>
                      <span class="tooltip-content clearfix">
                      <span class="tooltip-text">Edit</span>
                      </span>
                      </span>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columns;">
                </mat-row>
              </mat-table>
              <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
              <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
            </mat-card-content>
          </div>
          <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
        </div>
      </app-card>
    </div>
  </div>
</div>

<div class="form-material full-width-inputs"  [hidden]="this.isLoading">
  <div *ngFor="let section of this.sectionsList">
      <div *ngIf="section.customFields.length != 0" class="card-sub">
          <h4>{{section.name}}</h4>
          <ng-container>
           
              <div class="row form-group">
                  <div class="col-4" *ngFor="let custom of section.customFields; index as i">

                     
                      <mat-form-field class="form-element" *ngIf="custom.type === 'dd' ">
                          <mat-select [disabled]="!update" disableOptionCentering class="form-control"
                          [(ngModel)]="custom.value"  required="{{custom.required}}" (ngModelChange)="checkRequiredFieldsInCustomFields(section.id,section.customFields)" >
                                      
                              <ng-container>
                                  <mat-option value="">--Select--</mat-option>
                                  <mat-option *ngFor="let option of custom.options " [value]="option"  [(ngModel)]="custom.value">
                                      {{option}}
                                  </mat-option>
                              </ng-container>
                          </mat-select>
                          <mat-label>{{custom.name}}</mat-label>
                      </mat-form-field>


                      <mat-form-field class="form-element" *ngIf="custom.type === 'st' ">
                          <mat-label>{{custom.name}}</mat-label>
                          <input [readonly]="!update"matInput class="form-control"   [(ngModel)]="custom.value" required="{{custom.required}}"  (keyup)="checkRequiredFieldsInCustomFields(section.id,section.customFields)">
                      </mat-form-field>

                      <mat-form-field class="form-element" *ngIf="custom.type === 'num' ">
                          <mat-label>{{custom.name}}</mat-label>
                          <input [readonly]="!update" matInput class="form-control"   [(ngModel)]="custom.value" type="number" required="{{custom.required}}" (keyup)="checkRequiredFieldsInCustomFields(section.id,section.customFields)">
                      </mat-form-field>
                      <mat-form-field class="form-element" *ngIf="custom.type === 'dt' ">
                          <mat-label >{{custom.name}}</mat-label>
                      <input [readonly]="!update" class="form-control" matInput [matDatepicker]="i" [attr.id]="dtPicker + i" [(ngModel)]="custom.value" required="{{custom.required}}" (ngModelChange)="checkRequiredFieldsInCustomFields(section.id,section.customFields)">
                      <mat-datepicker-toggle [disabled]="!update" matSuffix [for]="i"></mat-datepicker-toggle>
                      <mat-datepicker #i></mat-datepicker>   
                    </mat-form-field>

                    <div *ngIf="custom.type === 'cb' " >
                        <h5>{{custom.name}}:</h5>
                      <div  *ngFor="let option of custom.values">
                          <mat-checkbox [disabled]="!update" [(ngModel)]="option.booleanValue" >{{option.option}}</mat-checkbox> &nbsp;
                      </div>
                      
                    </div>


                  
               </div>

               

                  <div class="action-buttons multi-btns col-sm-12 text-right">
                      <button [hidden]="!update" [disabled]="section.save" (click)="updateCustomField(section.id,section.customFields)"
                              class="btn-primary"
                              color="primary"
                              mat-raised-button><i class="flaticon-diskette"></i>Save
                      </button>
                  </div>    
              </div>   
                         
              
          </ng-container>
      </div>
     
  </div>


</div>


<ng-template #addUser let-modal>
    <div class="mat-modal-header">
    <h4 class="mat-title-modal">
      <span *ngIf="!isEdit">Add</span>
      <span *ngIf="isEdit">Edit</span>
      Social Media
    </h4>
  </div>
      
      <div class="mat-modal-body">
          <!--      <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>-->

      <form class="form-material full-width-inputs" action="javascript:" [formGroup]="userForm" *ngIf="!isUserAdded">
        <div class="row form-group">
        <div class="col-md-12">
            <mat-form-field class="form-element">
                <mat-select disableOptionCentering class="form-control" formControlName="type" required="">
                    <mat-option value="fb">Facebook</mat-option>
                    <mat-option value="tw">Twitter</mat-option>
                    <mat-option value="li">LinkedIn</mat-option>
                </mat-select>
                <mat-label>Type</mat-label>
            </mat-form-field>
        </div>

          <div class="col-md-12">
              <mat-form-field class="form-element">
                  <input class="form-control" formControlName="name" matInput type="text" maxlength="40"  required="">
                  <mat-label>Name</mat-label>
                  <mat-error *ngIf="!userForm.controls.name.valid && userForm.controls.name.touched"
                        class="validation-error">Required</mat-error>
              </mat-form-field>
          </div>

          <div class="col-md-12">
              <mat-form-field class="form-element">
                  <input class="form-control" formControlName="link" matInput type="text" maxlength="40"  required="">
                  <mat-label>Link</mat-label>
                  <mat-error *ngIf="!userForm.controls.link.valid && userForm.controls.link.touched"
                        class="validation-error">Please provide a valid link</mat-error>
              </mat-form-field>
          </div>
        
          <div class="action-buttons multi-btns col-sm-12 text-right">
            <button mat-raised-button  class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i> Cancel</button>
          <button mat-raised-button  class="btn btn-primary" color="primary"  (click)="submitAddJobCategory()" [disabled]="!userForm.valid"><i class="flaticon-diskette"></i> Save</button>
        </div>
        </div>
      </form>
      </div>
</ng-template>


<ng-template #deleteUser let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">Delete Social Media</h4>
    </div>
    <div class="mat-modal-body">
        <div class="text-left are-u">Are you sure ?</div>
        <div class="row">
          <div class="action-buttons multi-btns col-sm-12 text-right">
            <button mat-raised-button  class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i> Cancel</button>
          <button mat-raised-button  class="btn btn-primary" color="primary" (click)="deleteJobCategory()"><i class="flaticon-diskette"></i> Save</button>
            </div>
        </div>
      </div>
    </div>
</ng-template>



