<app-loading *ngIf="isLoadForm" [isLoading]="isLoadForm"></app-loading>

<div class="container" style="padding: 20px">
    <form class="form-material" action="javascript:" [formGroup]="formGroup">
        <div class="row row-margin">
            <div class="col-sm-6">
                <mat-form-field class="form-element">
                    <input class="form-control" matInput [matDatepicker]="picker" placeholder="Choose a date"
                           [formControl]="formGroup.controls.publishDateStr">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div class="row row-margin">
            <div class="col-12">
                <mat-checkbox [formControl]="formGroup.controls.ptAllUserRole">
                    Publish To - All User Roles
                </mat-checkbox>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div *ngIf="!formGroup.value.ptAllUserRole" class="percent-width-full">
                    <mat-form-field class="percent-width-full">
                        <input type="text" placeholder="User Roles" maxlength="40"  aria-label="User Roles" matInput
                               [formControl]="formGroup.controls.userRole"
                               [matAutocomplete]="userRoleAuto">
                        <mat-autocomplete #userRoleAuto="matAutocomplete" [displayWith]="displayFn"
                                          (optionSelected)="optionSelected($event, formGroup.controls.userRole, userRole.selectedMap)">
                            <mat-option *ngFor="let option of userRole.fi | async" [value]="option">
                                {{option.name}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-error *ngIf="formGroup.controls.userRole.touched && isObjectEmpty(userRole.selectedMap)">
                        Please select user role
                    </mat-error>
                    <div class="tag" *ngFor="let item of userRole.selectedMap | keyvalue">
                        <span>{{item.value.name}}</span>
                        <input type="button" class="close-button" value=" X "
                               (click)="removeSelectedItem(item.value, userRole.selectedMap)"/>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <mat-form-field class="percent-width-full">
                    <input type="text" placeholder="Employee" aria-label="Employee" matInput maxlength="40" 
                           [formControl]="formGroup.controls.employee"
                           [matAutocomplete]="employeeAuto">
                    <mat-autocomplete #employeeAuto="matAutocomplete" [displayWith]="displayFn"
                                      (optionSelected)="optionSelected($event, formGroup.controls.employee, employee.selectedMap)">
                        <mat-option *ngFor="let option of employee.fi | async" [value]="option">
                            {{option.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <div class="tag" *ngFor="let item of employee.selectedMap | keyvalue">
                    <span>{{item.value.name}}</span>
                    <input type="button" class="close-button" value=" X "
                           (click)="removeSelectedItem(item.value, employee.selectedMap)"/>
                </div>
            </div>
        </div>
        <div class="row row-margin">
            <div class="col-12">
                <mat-checkbox [formControl]="formGroup.controls.ptAllLocation">
                    Publish To - All Location
                </mat-checkbox>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div *ngIf="!formGroup.value.ptAllLocation" class="percent-width-full">
                    <mat-form-field class="percent-width-full">
                        <input type="text" placeholder="Location" aria-label="Location" matInput maxlength="40" 
                               [formControl]="formGroup.controls.location"
                               [matAutocomplete]="locationAuto">
                        <mat-autocomplete #locationAuto="matAutocomplete" [displayWith]="displayFn"
                                          (optionSelected)="optionSelected($event, formGroup.controls.location, location.selectedMap)">
                            <mat-option *ngFor="let option of location.fi | async" [value]="option">
                                {{option.name}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-error *ngIf="formGroup.controls.location.touched && isObjectEmpty(location.selectedMap)">
                        Please select location
                    </mat-error>
                    <div class="tag" *ngFor="let item of location.selectedMap | keyvalue">
                        <span>{{item.value.name}}</span>
                        <input type="button" class="close-button" value=" X "
                               (click)="removeSelectedItem(item.value, location.selectedMap)"/>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <mat-form-field class="percent-width-full">
                    <input type="text" placeholder="Employement Status" maxlength="40"  aria-label="Employement Status" matInput
                           [formControl]="formGroup.controls.emStatus"
                           [matAutocomplete]="emStatusAuto">
                    <mat-autocomplete #emStatusAuto="matAutocomplete" [displayWith]="displayFn"
                                      (optionSelected)="optionSelected($event, formGroup.controls.emStatus, emStatus.selectedMap)">
                        <mat-option *ngFor="let option of emStatus.fi | async" [value]="option">
                            {{option.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <div class="tag" *ngFor="let item of emStatus.selectedMap | keyvalue">
                    <span>{{item.value.name}}</span>
                    <input type="button" class="close-button" value=" X "
                           (click)="removeSelectedItem(item.value, emStatus.selectedMap)"/>
                </div>
            </div>
        </div>
        <div class="row row-margin">
            <div class="col-6">
                <mat-form-field class="percent-width-full">
                    <input type="text" placeholder="Job Titles" aria-label="Job Titles" maxlength="40"  matInput
                           [formControl]="formGroup.controls.jobTitle"
                           [matAutocomplete]="jobTitleAuto">
                    <mat-autocomplete #jobTitleAuto="matAutocomplete" [displayWith]="displayFn"
                                      (optionSelected)="optionSelected($event, formGroup.controls.jobTitle, jobTitle.selectedMap)">
                        <mat-option *ngFor="let option of jobTitle.fi | async" [value]="option">
                            {{option.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <div class="tag" *ngFor="let item of jobTitle.selectedMap | keyvalue">
                    <span>{{item.value.name}}</span>
                    <input type="button" class="close-button" value=" X "
                           (click)="removeSelectedItem(item.value, jobTitle.selectedMap)"/>
                </div>
            </div>
            <div class="col-6">
                <mat-form-field class="percent-width-full">
                    <input maxlength="40"  type="text" placeholder="Sub Units" aria-label="Sub Units" matInput
                           [formControl]="formGroup.controls.subUnitt"
                           [matAutocomplete]="subUnittAuto">
                    <mat-autocomplete #subUnittAuto="matAutocomplete" [displayWith]="displayFn"
                                      (optionSelected)="optionSelected($event, formGroup.controls.subUnitt, subUnitt.selectedMap)">
                        <mat-option *ngFor="let option of subUnitt.fi | async" [value]="option">
                            {{option.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <div class="tag" *ngFor="let item of subUnitt.selectedMap | keyvalue">
                    <span>{{item.value.name}}</span>
                    <input type="button" class="close-button" value=" X "
                           (click)="removeSelectedItem(item.value, subUnitt.selectedMap)"/>
                </div>
            </div>
        </div>

        <app-loading *ngIf="isSaving" [isLoading]="isSaving"></app-loading>
        <div class="row row-margin" *ngIf="!isSaving">
            <div class="col-sm-12 text-right">
                <button type="submit" class="btn btn-cancel btn-danger" (click)="Back()">Back</button>
                <button type="submit" class="btn btn-save btn-primary" (click)="saveForm()" *ngIf="updateObj">Save
                </button>
                <button type="submit" class="btn btn-save btn-primary" (click)="publish()" *ngIf="updateObj">
                    {{this.updateObj.published ? 'Un-Publish' : 'Publish'}}
                </button>
            </div>
        </div>
    </form>
</div>
