<div id="header-page" class="ng-star-inserted header-page">
    <div class="page-header ng-star-inserted">
        <div class="page-block ng-star-inserted">
        <div class="row align-items-center">
            <div class="col-md-8">
            <div class="page-header-title">
                <span class="ng-star-inserted"></span><span class="ng-star-inserted"><h4 class="m-b-10 ng-star-inserted">Reports</h4></span>
            </div>
            </div>
            <div class="col-md-4"></div>
        </div>
        </div>
    </div>
</div>


<div class="page-body no-title">
    <div class="row">
        <div class="col-sm-12">

            <div class="row top-tools">
                <div class="col-xs-12 col-sm-12 col-md-6 left-tools">
                    <span class="export-icon-btn csv" (click)="exportToCsv()"><i class="material-icons">get_app</i><span>CSV</span>
                    </span>
                    <span class="export-icon-btn pdf" (click)="saveAsPdf()"><i class="material-icons">get_app</i><span>PDF</span>
                     </span>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
                    <div class="relative-position search-filter-con">
                        <span class="icon material-icons ng-tns-c114-0"> search </span>
                        <mat-form-field>
                            <input matInput #inputSearch type="search" [(ngModel)]="filterQuery" maxlength="40"
                                   (ngModelChange)="onModelChange(filterQuery)"
                                   class="form-control input-sm full-data-search">
                        </mat-form-field>
                        <span [hidden]="!this.write" class="mytooltip cus-left tooltip-effect-4">
                          <span class="tooltip-item">
                              <button mat-mini-fab class="btn btn-primary light add-rounded margin-button-05" (click)="onAddReportClicked()">
                                  <i class="fa fa-plus"></i>
                              </button>
                          </span>
                          <span class="tooltip-content clearfix">
                          <span class="tooltip-text">New</span>
                          </span>
                       </span>
                    </div>
                </div>
            </div>
            <app-card [title]="'Reports'">
                <div class="dt-responsive" *ngIf="dataSource">
                    <div class="table-responsive">
                        <mat-card-content>
                            <mat-table [dataSource]="dataSource" matSort>
                                <ng-container matColumnDef="select">
                                    <mat-header-cell [hidden]="!this.delete" *matHeaderCellDef [ngClass]="'w-30'">
                                        <a [matMenuTriggerFor]="menu" class="check-all">
                                            <span class="material-icons">
                                                more_vert
                                            </span>
                                        </a>
                                        <mat-menu #menu="matMenu">
                                            <button mat-menu-item (click)="onSelectAllClicked()">Select All</button>
                                            <button (click)="onDeSelectAllClicked()" *ngIf="selectedNewsDocs.length > 0"
                                                    mat-menu-item>Deselect all
                                            </button>
                                            <button (click)="onDeleteSelected()" *ngIf="selectedNewsDocs.length > 0"
                                                    mat-menu-item>Delete selected
                                            </button>
                                        </mat-menu>
                                    </mat-header-cell>
                                    <mat-cell [hidden]="!this.delete" *matCellDef="let row" [ngClass]="'w-30'">
                                        <mat-checkbox #matCheckbox (change)="onNewDocSelectChange($event, row.id)"
                                                      value="{{row.id}}"></mat-checkbox>
                                        <!-- <input [value]="row.id" class="user-input" #userSelect type="checkbox" (change)="onUserChanged(userSelect)"/> -->
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="reportName">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Reports</mat-header-cell>
                                    <mat-cell *matCellDef="let row" (click)="onReportClicked(row)"
                                              style="cursor: pointer"><a>{{row.reportName}}</a></mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="edit">
                                    <mat-header-cell [hidden]="!this.update" *matHeaderCellDef>Edit</mat-header-cell>
                                    <mat-cell [hidden]="!this.update" *matCellDef="let row">
                                        <span class="mytooltip tooltip-effect-4">
                                        <span class="tooltip-item"><i class="single-icon-click fa fa-pencil" (click)="onEditReportClicked(row.id)"></i></span>
                                        <span class="tooltip-content clearfix">
                                        <span class="tooltip-text">Edit</span>
                                        </span>
                                        </span>
                                    </mat-cell>
                                </ng-container>
                                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumns;">
                                </mat-row>
                            </mat-table>
                            <mat-progress-bar mode="indeterminate" *ngIf="loadReportTable"></mat-progress-bar>
                            <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                        </mat-card-content>
                    </div>
                </div>
            </app-card>
        </div>
    </div>
</div>


<ng-template #deleteUser let-modal>
    <div mat-dialog-actions>
        <div class="mat-modal-header">
            <h4 class="mat-title-modal">Delete Report</h4>
        </div>
        <div class="mat-modal-body">
            <div class="text-left are-u">Are you sure ?</div>
            <div class="row">
                <div class="multi-btns col-sm-12 text-right">
                    <button (click)="close()" class="btn-danger" mat-raised-button>
                        <i class="flaticon-cancel"></i>Cancel
                    </button>
                    <button (click)="onDeleteSelectedClicked()" class="btn-primary" color="primary" mat-raised-button>
                        <i class="flaticon-trash"></i>Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
